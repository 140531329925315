import actionTypes from "../actions/actionTypes";
const initState = {
    baohiemnhanvien: [],
    msg: '',
    count: 0,
    newbaohiemnhanvien: []
}

const baohiemnhanvienReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.GET_LIST_BAO_HIEM_NHAN_VIEN:
            return {
                ...state,
                baohiemnhanvien: action?.baohiemnhanvien?.rows || [],
                msg: 'Ok' || '',
                count: action?.baohiemnhanvien?.count || 0
            }
        case actionTypes.POST_NEW_BAO_HIEM_NHAN_VIEN:
        case actionTypes.PUT_EDIT_BAO_HIEM_NHAN_VIEN:
        case actionTypes.DELETE_BAO_HIEM_NHAN_VIEN:
            return {
                ...state,
                msg: action.msg || '',
                newbaohiemnhanvien: action?.newbaohiemnhanvien || []
            }
        default:
            return state;
    }

}

export default baohiemnhanvienReducer