import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputForm, Button } from '~/components/Form';
import * as actions from '~/store/actions';
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { dataSelectActive, dataSelectStatus } from '~/utils/Datacustom';
import { validateTaiKhoanNganHang } from '~/request/validateTaiKhoanNganHang';
import { toast } from 'react-toastify';
import { NewTaiKhoanNganHang } from '~/store/actions';

const ModalAddBank = ({ show, setShow, idNhanvien }) => {
    // console.log('console', idNhanvien);
    const [invalidFields, setInvalidFields] = useState([]);
    const dispatch = useDispatch();
    const [dataNganHang, setDataNganHang] = useState([]);
    const [listActive, setlistActive] = useState([]);
    const fieldList = {
        NhanvienId: idNhanvien,
        tennganhang: '',
        sotaikhoan: '',
        nguoihuongthu: '',
        TrangthaiId: '',
    };
    const [formValue, setformValue] = React.useState(fieldList);

    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateTaiKhoanNganHang(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateTaiKhoanNganHang(item[0], item[1])]);
                invalids++;
            }
        });
        return invalids;
    };
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setformValue((prev) => ({ ...prev, [statename]: selected }));
    };
    const handleSelect = (inputData, type) => {
        let result = [];
        if (type === 'status') {
            inputData?.map((item, index) => {
                let object = {};
                object.label = item.label;
                object.value = item.value;
                result.push(object);
            });
        }
        return result;
    };

    useEffect(() => {
        let dataActive = handleSelect(dataSelectActive, 'status');
        setlistActive(dataActive);
    }, [dataSelectActive]);
    const handleSubmit = async () => {
        // console.log('formValue', formValue);
        let invalids = validate(formValue);
        if (invalids === 0) {
            let data = {
                data: [
                    {
                        NhanvienId: idNhanvien,
                        tennganhang: formValue.tennganhang,
                        sotaikhoan: formValue.sotaikhoan,
                        nguoihuongthu: formValue.nguoihuongthu,
                        TrangthaiId: Number(formValue?.TrangthaiId?.value),
                    },
                ],
            };

            let response = dispatch(actions.NewTaiKhoanNganHang(data));
            response.status === 200 ? setShow(false) : setShow(true);
        }
    };
    // console.log('dataNganHang',dataNganHang)
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">Thêm tài khoản ngân hàng</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-4">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Tên ngân hàng'}
                            value={formValue.tennganhang}
                            setValue={setformValue}
                            keyPayload={'tennganhang'}
                            type="text"
                        />
                    </div>
                    <div className="form-group col-md-4">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Số tài khoản'}
                            value={formValue.sotaikhoan}
                            setValue={setformValue}
                            keyPayload={'sotaikhoan'}
                            type="text"
                        />
                    </div>
                    <div className="form-group col-md-4">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Người thụ hưởng'}
                            value={formValue.nguoihuongthu}
                            setValue={setformValue}
                            keyPayload={'nguoihuongthu'}
                            type="text"
                        />
                    </div>
                    <div className="form-group col-md-4">
                        <div className="form-group">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                Trạng thái
                            </label>
                            <Select
                                value={formValue?.TrangthaiId}
                                onChange={handleOnSelect}
                                options={listActive}
                                placeholder={'Trạng thái hoạt động'}
                                name="TrangthaiId"
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'TrangthaiId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'TrangthaiId')?.message}
                                </small>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-5">
                    <Button text="Cập nhật" bClass="btn btn-primary px-5" textColor onClick={handleSubmit} />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalAddBank;
