import axiosConfig from '../axiosConfig'
import axios from 'axios'
export const apiGetNhanvien = (query) => new Promise(async (resolve, reject) => {
    // console.log('query',query)
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlynhanvien/nhanvien`,
            params: query,
        })
        
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiNhanvienByCondition = (query) => new Promise(async (resolve, reject) => {
    // console.log('query',query)
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlynhanvien/danh-sach-nhan-vien/nhanvien`,
            params: query,
        })
        
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiGetNhanvienSetOvt = (query) => new Promise(async (resolve, reject) => {
    // console.log('query',query)
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlynhanvien/nhan-vien-ovt/nhanvien`,
            params: query,
        })
        
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
export const apiGetSetuser = () => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlyhethong/maychamcong/setuser/quanlycong`,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiNewNhanvien = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlynhanvien/nhanvien',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiEditNhanvien = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlynhanvien/nhanvien',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiDeleteNhanvien = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlynhanvien/nhanvien',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiGetNhanvienById = (query) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlynhanvien/one/nhanvien`,
            params: query,
            // method: 'get',
            // url: '/api/v2/quanlynhanvien/one/nhanvien?id='+payload.id,
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
export const apiUploadImages = (images) => new Promise(async (resolve, reject) => {
    try {
        const response = await axios({
            method: 'post',
            url: `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload/`,
            data: images,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiAvatar = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlynhanvien/avatars/nhanvien',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})


export const apiGetNhomnv = () => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlynhanvien/nhomnv/nhanvien`,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiGetNhomInNhanVien = (payload) => new Promise(async (resolve, reject) => {
   console.log('payload',payload)
    try {
        const response = await axiosConfig({
            method: 'get',
            url: '/api/v2/quanlynhanvien/nhanviennhomnv/nhanvien?NhomId='+payload,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiGetNhomByNhanVien = (payload) => new Promise(async (resolve, reject) => {
   // console.log('payloghad',payload)
    try {
        const response = await axiosConfig({
            method: 'get',
            url: '/api/v2/quanlynhanvien/nhombynhanvien/nhanvien?NhanvienId='+payload,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiNewNhomnv = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlynhanvien/nhomnv/nhanvien',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiEditNhomnv = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlynhanvien/nhomnv/nhanvien',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiDeleteNhomnv = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlynhanvien/nhomnv/nhanvien',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
//-----------------------------------------------------------------------------------------
export const apiGetQuyetdinh = (payload) => new Promise(async (resolve, reject) => {
    // console.log('payloghad',payload)
     try {
         const response = await axiosConfig({
             method: 'get',
             url: '/api/v2/quanlynhanvien/quyetdinh/nhanvien',
         })
         resolve(response)
 
     } catch (error) {
         reject(error)
     }
 })
 export const apiNewQuyetdinh = (payload) => new Promise(async (resolve, reject) => {
     try {
         const response = await axiosConfig({
             method: 'post',
             url: '/api/v2/quanlynhanvien/quyetdinh/nhanvien',
             data: payload
         })
         resolve(response)
 
     } catch (error) {
         reject(error)
     }
 })
 export const apiEditQuyetdinh = (payload) => new Promise(async (resolve, reject) => {
     try {
         const response = await axiosConfig({
             method: 'put',
             url: '/api/v2/quanlynhanvien/quyetdinh/nhanvien',
             data: payload
         })
         resolve(response)
 
     } catch (error) {
         reject(error)
     }
 })
 export const apiDeleteQuyetdinh = (payload) => new Promise(async (resolve, reject) => {
     try {
         const response = await axiosConfig({
             method: 'delete',
             url: '/api/v2/quanlynhanvien/quyetdinh/nhanvien',
             data: payload
         })
         resolve(response)
 
     } catch (error) {
         reject(error)
     }
 })