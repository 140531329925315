import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '~/store/actions';
import { ModalMauhopdong, ModalEditMauhopdong } from './Modal';
import Swal from 'sweetalert2';
import Head from '~/layout/head/Head';
import Content from '~/layout/content/Content';
import { Block } from '~/components/block/Block';
import { Button, DataTable, Icon, PermissionUtils } from '~/components/Component';
import { Card, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { MenuHopdong } from './MenuTab/Menucontent';

const Mauhopdong = () => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [showEditMauhopdong, setShowEditMauhopdong] = useState(false);
    useEffect(() => {
        dispatch(actions.getListMauhopdong());
        dispatch(actions.getListLoaihopdong());
    }, []);
    //lấy giá trị từ thằng redux trả về
    const { mauhopdong } = useSelector((state) => state.hopdonglaodong);
    const [data, setData] = useState(mauhopdong);
    const fieldList = {
        // TrangthaiId: '',
        mota: '',
        myfilesdocs: '',
        linkfile: '',
        LoaihopdongId: '',
        TrangthaiId: '',
    };

    const [showPhanquyen, setShowPhanquyen] = useState({});
    let dataPermissionUtils = PermissionUtils('hopdonglaodong', 'all');
    useEffect(() => {
        if (dataPermissionUtils) {
            setShowPhanquyen(dataPermissionUtils);
        }
    }, [dataPermissionUtils]);



    useEffect(() => {
        if (mauhopdong && mauhopdong.length > 0) {
            setData(mauhopdong);
        }
    }, [mauhopdong]);


    const [onSearchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    useEffect(() => {
        if (onSearchText !== '') {
            const filteredObject = data.filter((item) => {
                return (
                    item.ho.toLowerCase().includes(onSearchText.toLowerCase()) ||
                    item.ten.toLowerCase().includes(onSearchText.toLowerCase())
                );
            });
            setData([...filteredObject]);
        } else {
            setData([...data]);
        }
    }, [onSearchText, setData]);
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    useEffect(() => {
        (mauhopdong && setShow(false)) || setShowEditMauhopdong(false);
    }, [mauhopdong]);
    // set state để mở đóng Modal
    const handleAddNewMauhopdong = async () => {
        setShow(true);
    };
    const [dataIdMauhopdong, setDataIdMauhopdong] = useState([]);
    const handleEditMauhopdong = async (data) => {
        setShowEditMauhopdong(true);
        await setDataIdMauhopdong(data);
    };
    const handleDeleteMauhopdong = (data) => {
        Swal.fire({
            title: 'Bạn có chắc không?',
            text: 'Bạn sẽ không thể lấy lại khi xóa!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Không, bỏ qua!',
            confirmButtonText: 'Vâng, xóa nó đi!',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(actions.DeleteMauhopdong(data));
            }
        });
    };
    // Download a folder/file
    const downloadFile = (file) => {

        const downloadLink = document.createElement('a');
        downloadLink.href = `${process.env.REACT_APP_SERVER_URL}${file.linkfile}`;
        // downloadLink.download = file.mota;
        downloadLink.click();
    };
    return (
        <React.Fragment>
            {/*<Head title="User List - Regular"></Head>*/}
            <Content>
                <Card className="">
                <DataTable className="card-stretch border-0">
                        <div className="card-inner card-inner-lg px-4 mt-3">
                            <h4>Mẫu hợp đồng</h4>
                            <MenuHopdong />
                        </div>
                        </DataTable>
                </Card>
                <Block>
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner position-relative card-tools-toggle">
                            <div className="card-title-group">
                                <div className="card-title">
                                    {+showPhanquyen?.them === 1 && (
                                        <Button color="primary" onClick={handleAddNewMauhopdong}>
                                            <Icon name="plus"></Icon> Thêm mới mẫu hợp đồng
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="nk-files nk-files-view-grid my-5 mx-3">
                            <div className="nk-files-list">
                                {data.map((item) => (
                                    <div className="nk-file-item nk-file " key={item.id}>
                                        <div className="nk-file-info">
                                            <div className="nk-file-title">
                                                <div className="nk-file-icon">
                                                    <a
                                                        href="#link"
                                                        onClick={(ev) => ev.preventDefault()}
                                                        className="nk-file-icon-link"
                                                    >
                                                        <span className="nk-file-icon-type">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
                                                                <path
                                                                    fill="#599def"
                                                                    d="M50 61H22a6 6 0 01-6-6V22l9-11h25a6 6 0 016 6v38a6 6 0 01-6 6z"
                                                                ></path>
                                                                <path
                                                                    fill="#c2e1ff"
                                                                    d="M25 20.556A1.444 1.444 0 0123.556 22H16l9-11z"
                                                                ></path>
                                                                <rect
                                                                    width="18"
                                                                    height="2"
                                                                    x="27"
                                                                    y="31"
                                                                    fill="#fff"
                                                                    rx="1"
                                                                    ry="1"
                                                                ></rect>
                                                                <rect
                                                                    width="18"
                                                                    height="2"
                                                                    x="27"
                                                                    y="36"
                                                                    fill="#fff"
                                                                    rx="1"
                                                                    ry="1"
                                                                ></rect>
                                                                <rect
                                                                    width="18"
                                                                    height="2"
                                                                    x="27"
                                                                    y="41"
                                                                    fill="#fff"
                                                                    rx="1"
                                                                    ry="1"
                                                                ></rect>
                                                                <rect
                                                                    width="12"
                                                                    height="2"
                                                                    x="27"
                                                                    y="46"
                                                                    fill="#fff"
                                                                    rx="1"
                                                                    ry="1"
                                                                ></rect>
                                                            </svg>
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="nk-file-name">
                                                    <div className="nk-file-name-text">
                                                        <a
                                                            href="#link"
                                                            onClick={(ev) => ev.preventDefault()}
                                                            className="title"
                                                        >
                                                            {item.mota}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="nk-file-actions">
                                            <UncontrolledDropdown>
                                                <DropdownToggle
                                                    tag="a"
                                                    // href="#folder"
                                                    //   onClick={(ev) => ev.preventDefault()}
                                                    className="dropdown-toggle btn btn-sm btn-icon btn-trigger"
                                                >
                                                    <Icon name="more-h"></Icon>
                                                </DropdownToggle>
                                                <DropdownMenu end>
                                                    <ul className="link-list-opt no-bdr">
                                                        {/* <li>
                                                            <DropdownItem
                                                                tag="a"
                                                                onClick={(ev) => {
                                                                    ev.preventDefault();
                                                                    //   setDetailModal(true);
                                                                }}
                                                                href="#item"
                                                            >
                                                                <Icon name="eye"></Icon>
                                                                <span>Xem chi tiết</span>
                                                            </DropdownItem>
                                                        </li> */}
                                                        <li onClick={() => handleEditMauhopdong(item)}>
                                                            <DropdownItem
                                                                tag="a"
                                                                href="#edit"
                                                                onClick={(ev) => {
                                                                    ev.preventDefault();
                                                                }}
                                                            >
                                                                <Icon name="edit"></Icon>
                                                                <span>Sửa</span>
                                                            </DropdownItem>
                                                        </li>
                                                        <li onClick={() => handleDeleteMauhopdong(item)}>
                                                            <DropdownItem
                                                                tag="a"
                                                                href="#trash"
                                                                onClick={(ev) => {
                                                                    ev.preventDefault();
                                                                }}
                                                            >
                                                                <Icon name="trash"></Icon>
                                                                <span>Xóa</span>
                                                            </DropdownItem>
                                                        </li>
                                                        <li onClick={() => downloadFile(item)}>
                                                            <DropdownItem
                                                                tag="a"
                                                                onClick={(ev) => {
                                                                    ev.preventDefault();
                                                                }}
                                                                href="#download"
                                                                className="file-dl-toast"
                                                            >
                                                                <Icon name="download"></Icon>
                                                                <span>Download</span>
                                                            </DropdownItem>
                                                        </li>
                                                    </ul>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </DataTable>
                </Block>

                <ModalMauhopdong show={show} fieldList={fieldList} setShow={() => setShow(false)} />
                <ModalEditMauhopdong
                    show={showEditMauhopdong}
                    fieldList={fieldList}
                    setShow={() => setShowEditMauhopdong(false)}
                    dataIdMauhopdong={dataIdMauhopdong}
                />
            </Content>
        </React.Fragment>
    );
};

export default Mauhopdong;
