export const validateTamUngLuong = (fieldName, value) => {
    let regNumber = /^[0-9\b]+$/;
    switch (fieldName) {
        case 'NhanvienId':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nhân viên không được để trống.'
                }
            }
            break;
        case 'ngaydexuat':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Ngày đề xuất không được để trống.'
                }
            }
            break;
        case 'giodexuat':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Giờ đề xuất không được để trống.'
                }
            }
            break;
        case 'mucdexuat':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Mức đề xuất không được để trống.'
                }
            }
            break;
        case 'ghichu':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Ghi chú không được để trống.'
                }
            }
            break;
        
        default:
            break;
    }
}