import React, {useEffect, useMemo, useState} from 'react';
import {
    apiDeleteThanhphancongthuc,
    apiGetThanhphancongthuc, apiGetThanhphanluong, apiUpdateThanhphancongthuc,
} from '~/services';
import Content from '~/layout/content/Content';
import {Block} from '~/components/block/Block';
import {Button, DataTable, Icon,} from '~/components/Component';
import {Card} from 'reactstrap';

import {toast} from "react-toastify";
import {MaterialReactTable, useMaterialReactTable} from "material-react-table";
import {MRT_Localization_VI} from 'material-react-table/locales/vi';
import {Link, useSearchParams} from "react-router-dom";
import {Box, IconButton, Tooltip} from "@mui/material";
import {ExportToCsv} from "export-to-csv";
import config from "~/config";
import {dataPagination, dataTypeSalary,dataConfirm} from "~/utils/Datacustom";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import ModalAddThanhPhanCongThuc from "~/pages/Admin/Modal/CongThucTinhLuong/ModalAddThanhPhanCongThuc";


const Thanhphancongthuc = () => {
    const [data, setData] = useState([]);
    const [thanhPhanLuong, setthanhPhanLuong] = useState([]);
    const [dataCongThuc, setDataCongThuc] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});
    const [show, setShow] = useState(false);
    const [congthucId, setCongthucId] = useState(0);
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const fieldList = {
        ThanhphanluongId: '',
        codeCustom: '',
        CongthucId: '',
        TrangthaiId: '',
    }
    const [searchParams] = useSearchParams();
    useEffect(() => {
        setCongthucId(searchParams.get('congthuctinhluong'));
    }, [congthucId]);
    
    useEffect(() => {
        if (congthucId !== 0) {
            fetchData(Number(congthucId)).then((r) => 'Error');
            fetchThanhPhanLuong().then(r => r)
        }
    }, [congthucId, pagination.pageIndex, pagination.pageSize, show]);

    
    // console.log('editedUsers',editedUsers)
    async function fetchData(dataInput) {
        try {
            const datapayloads = {
                typeGet: 2, // 1: lấy theo điều kiện phân trang, 2: lấy tất cả và không cần truyền d liệu phân trang
                start: pagination.pageIndex || 0,
                size: pagination.pageSize || 10,
                globalFilter: 'id',
                sorting: 'desc',
                congthucId: dataInput,
            };
            const response = await apiGetThanhphancongthuc(datapayloads);
            console.log('thanh phan',response);
            setData(response?.data?.data?.rows);
            setDataCongThuc(response?.data?.congThuc?.rows)
            
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    async function fetchThanhPhanLuong() {
        try {
            const datapayloads = {
                typeGet: 2, // 1: lấy theo điều kiện phân trang, 2: lấy tất cả và không cần truyền d liệu phân trang
            };
            const response = await apiGetThanhphanluong(datapayloads);
            setthanhPhanLuong(response?.data?.data?.rows);
            // setDataCongThuc(response?.data?.congThuc?.rows)
            
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
   
    const handleAddNew = async () => {
        setShow(true);
        fieldList.CongthucId = dataCongThuc?.id;
        // await fetchThanhPhanLuong().then(r => r)
    };
    const handleDelete = async (data) => {
        Swal.fire({
            title: 'Bạn có chắc không?',
            text: 'Bạn sẽ không thể lấy lại khi xóa!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Không, bỏ qua!',
            confirmButtonText: 'Vâng, xóa nó đi!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                // console.log('data', data)
                const response = await apiDeleteThanhphancongthuc(data)
                if (response?.data.success === 200) {
                    toast.success('Xóa thành công');
                    fetchData(Number(congthucId)).then(r => r)
                    
                } else {
                    toast.error('Xóa thất bại');
                }
                
            }
        });
    };
    
    
    const Example = () => {
      
        const [dataAb, setDataAb] = useState(data);
        console.log('================================',dataAb);
        const handleEdit = () => {
            const datEdit = {data: dataAb};
            // console.log('================================',datEdit);
            apiUpdateThanhphancongthuc(datEdit).then((res) => {
                if (res.data.success === 200) {
                    toast.success('Cập nhật thành công');
                    fetchData(Number(congthucId)).then(r => r)
                } else {
                    toast.error('Cập nhật thất bại');
                }
            });
        };
        const dataExport = dataAb?.map((item, index) => ({
            ...item,
            rowNumber: index + 1,
            hoten: item?.Nhanvien?.hoten,
            PhongbanId: item?.Nhanvien?.Phongban?.phongban,
            ChucvuId: item?.Nhanvien?.Chucvu?.chucvu,
        }));
        const TypeActive = (cell) => ( // Function show trạng thái
            <Box
                component="span"
                sx={(theme) => ({
                    backgroundColor: '#e65100',
                    fontSize: '12px',
                    borderRadius: '0.25rem',
                    color: '#fff',
                    p: '0.25rem',
                })}
            >
                {dataTypeSalary.find(x => x.value === cell)?.label || 'Không xác định'}
            </Box>
        );
        
        const columns = useMemo(
            () => [
                {
                    Header: 'STT', // Tiêu đề cột số thứ tự
                    header: 'STT', // Tiêu đề cột số thứ tự
                    id: 'rowNumber', // ID của cột
                    Cell: ({row}) => row.index + 1, // Giá trị của cột là số thứ tự
                    enableSorting: false,
                    size: 80,
                    ennableResizing: false,
                    enableEditing: false,
                },
                {
                    accessorKey: 'Thanhphanluong.name',
                    header: 'Tên thành phần',
                    enablePinning: true,
                    size: 250,
                    enableEditing: false,
                },
                {
                    accessorKey: 'Thanhphanluong.code',
                    header: 'Mã thành phần',
                    size: 250,
                    enablePinning: true,
                    enableEditing: false,
                    Cell: ({row}) => (
                        <Tooltip title={row.original.Thanhphanluong.calcDefault}>
                            <span>{row.original.Thanhphanluong.code}</span>
                        </Tooltip>
                    ),
                },
                {
                    accessorKey: 'Thanhphanluong.type',
                    enablePinning: true,
                    size: 150,
                    header: 'Tính chất',
                    Cell: ({cell}) => TypeActive(Number(cell?.getValue()) || 0),
                    enableEditing: false,
                },
                {
                    accessorKey: 'codeCustom',
                    header: 'Công thức tùy chỉnh',
                    enablePinning: true,
                    enableEditing: true,
                    size: 500,
                    muiEditTextFieldProps: ({cell, row}) => ({
                        type: 'text',
                        required: true,
                        error: !!validationErrors?.[cell.id],
                        helperText: validationErrors?.[cell.id],
                        onBlur: (event) => {
                            setDataAb((prev) => {
                                const data = [...prev];
                                data[row.index] = {
                                    ...data[row.index],
                                    ['codeEdit']: event.target.value,
                                };
                                return data;
                            });
                            
                        },
                    }),
                },
                {
                    accessorKey: 'TrangthaiId',
                    header: 'Trạng thái',
                    editVariant: 'select',
                    editSelectOptions: dataConfirm,
                    size: 150,
                    muiEditTextFieldProps: ({ row }) => ({
                        select: true,
                        error: !!validationErrors?.state,
                        helperText: validationErrors?.state,
                        onChange: (event) =>
                            setDataAb((prev) => {
                                const data = [...prev];
                                data[row.index] = {
                                    ...data[row.index],
                                    ['TrangthaiId']: event.target.value,
                                };
                                return data;
                            }),
                    }),
                },
            
            ],
            [dataAb],
        );
        // console.log('setData', data)
        const csvOptions = {
            fileName: 'csv-data',
            fieldSeparator: ',',
            filename: Thanhphancongthuc?.tieude,
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            useBom: true,
            useKeysAsHeaders: false,
            headers: columns.map((c) => c.header),
        };
        
        const csvExporter = new ExportToCsv(csvOptions);
        const handleExportData = () => {
            if (dataExport.length === 0) {
                toast.error("Không có dữ liệu để xuất!")
                return;
            }
            csvExporter.generateCsv(dataExport);
        };

        const rowActions = ({row, table}) => ( // Thêm nút sửa xóa vào hàng
            <Box sx={{display: 'flex', gap: '1rem'}}>
                <Tooltip title="Delete">
                    <IconButton color="error" onClick={() => handleDelete({
                        id: row.original.id,
                    })}>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            </Box>
        );
        const topToolbarCustomActions = ({table}) => ( // Thêm nút thêm mới và xuất dữ liệu
            <Box
                sx={{display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap'}}
            >
                
                <Button
                    color="danger"
                    variant="contained"
                >
                    <Link
                        className={`nav-link`}
                        to={`${config.he_thong}${config.cau_hinh_cong_thuc_tinh_luong}`}
                    >
                        <span className="fs-10px me-2"><Icon className="ni ni-back-arrow-fill"></Icon></span>Quay
                        lại
                    </Link>
                </Button>
                
                <Button
                    color="primary"
                    onClick={handleAddNew}
                    variant="contained"
                >
                    <Icon name="plus me-2"></Icon> Thêm thành phần
                </Button>
                <Button
                    color="success"
                    onClick={handleEdit}
                    variant="contained"
                >
                    <Icon name="save me-2"></Icon> Lưu
                </Button>
            
            
            </Box>
        );
        
        const state = { // Trạng thái của bảng
            expanded: true,
            columnFilters,
            globalFilter,
            isLoading: dataAb?.length === 0,
            pagination,
            sorting,
        };
        const displayColumnDefOptions = { // Các cột hiển thị
            'mrt-row-actions': {
                sx: {width: '100px', paddingRight: '10px'},
                header: 'Thao tác',
                enableOrdering: true,
                enablePinning: true,
                enableColumnActions: true,
            }
        };
        const initialState = { // Các trạng thái ban đầu của bảng đc gim
            columnPinning: {left: ['rowNumber', 'Thanhphanluong.name','Thanhphanluong.code'], right: ['TrangthaiId','mrt-row-actions']},
            density: 'compact'
        };
        
        const table = useMaterialReactTable({
                columns,
                data,
                editDisplayMode: 'table', // ('modal', 'row', 'cell', and 'custom' are also
                enableEditing: true,
                enableColumnResizing: false,
                enableDensityToggle: false,
                initialState: initialState,
                displayColumnDefOptions: displayColumnDefOptions,
                state: state,
                defaultDisplayColumn: {enableResizing: true},
                manualFiltering: true,
                manualPagination: true,
                manualSorting: true,
                onPaginationChange: setPagination,
                onColumnFiltersChange: setColumnFilters,
                onGlobalFilterChange: setGlobalFilter,
                onSortingChange: setSorting,
                localization: MRT_Localization_VI,
                enableRowActions: true,
                enableColumnPinning: true,
                renderTopToolbarCustomActions: topToolbarCustomActions,
                renderRowActions: rowActions,
                muiPaginationProps: {
                    rowsPerPageOptions: dataPagination,
                },
            }
        );
        
        
        return (
            <MaterialReactTable
                table={table}
            />
        );
    };
    
    
    return (
        <React.Fragment>
            {/*<Head title="User List - Regular"></Head>*/}
            <Content>
                <Card className="mt-0">
                    <div className="card-aside-wrap row">
                        <div className="card-inner card-inner-lg px-4 pt-1 pb-0">
                            <h4 className="mt-2 ms-2"> {dataCongThuc[0]?.name}</h4>
                        
                        </div>
                    
                    </div>
                </Card>
                <Block>
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner position-relative card-tools-toggle">
                            <Example/>
                        </div>
                    </DataTable>
                </Block>
                <ModalAddThanhPhanCongThuc
                    show={show}
                    fieldList={fieldList}
                    dataThanhPhan={thanhPhanLuong}
                    dataExits={data}
                    congthucId={congthucId}
                    setShow={() => setShow(false)}/>
            
            </Content>
        </React.Fragment>
    );
};

export default Thanhphancongthuc;
