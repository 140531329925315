import React, {useContext, useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {InputForm, Button} from '~/components/Form'
import * as actions from '~/store/actions'
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import {validatePhongBan} from "~/request/validatePhongBan";
import { FileAllCodeContext } from '../../user-manage/FileAllCodeContext';
import Select from "react-select";
import {dataSelectActive} from "~/utils/Datacustom";
const ModalPhongban = ({show, setShow, fieldList, listPhongBan}) => {
    const dispatch = useDispatch()
    const [invalidFields, setInvalidFields] = useState([])
    const [payload, setPayload] = useState(fieldList)
    const [dataPhongBan, setDataPhongBan] = useState([])
    const { handleChangeDataSocket } = useContext(FileAllCodeContext);
    useEffect(() => {
        show && setPayload(fieldList)
        if (listPhongBan) {
            let dataSelectPhongban = listPhongBan.map((item) => ({ label: item.phongban, value: item.id }));
            setDataPhongBan(dataSelectPhongban);
        }
    }, [show,listPhongBan])

    const handleSubmit = async () => {
        let invalids = validate(payload)
        if (invalids === 0) {
            let datapayload = {
                data: [
                    {
                        phongban: payload?.phongban,
                        motavitri: payload?.motavitri,
                        parentId: payload?.parentId?.value,
                    }
                ]
            }
            // console.log('datapayload', datapayload)
            dispatch(actions.NewPhongban(datapayload))
            handleChangeDataSocket('phongban');
        }
    }
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayload((prev) => ({...prev, [statename]: selected}));
    };
    const validate = (payload) => {
        let invalids = 0
        let fields = Object.entries(payload)
        fields.forEach(item => {
            if (validatePhongBan(item[0], item[1]) !== undefined) {
                setInvalidFields(prev => [...prev, validatePhongBan(item[0], item[1])])
                invalids++
            }
        })
        
        return invalids
    }
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Thêm mới phòng ban
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Phòng ban'}
                            value={payload.phongban}
                            setValue={setPayload}
                            keyPayload={'phongban'}
                            type='text'
                            validate
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="inputZip" className="mb-2">Thuộc phòng ban</label>
                        <Select
                            value={payload.parentId}
                            onChange={handleOnSelect}
                            options={dataPhongBan}
                            placeholder={'Thuộc phòng ban'}
                            name='parentId'
                            onFocus={() => setInvalidFields([])}
                        />
                        {invalidFields.length > 0 && invalidFields.some(i => i.name === 'parentId') && <small
                            className='text-danger'>{invalidFields.find(i => i.name === 'parentId')?.message}</small>}
                    </div>
                    <div className="form-group col-md-12">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Mô tả vị trí'}
                            value={payload.motavitri}
                            setValue={setPayload}
                            keyPayload={'motavitri'}
                            type='text'
                            validate
                        />
                    </div>
                </div>
                <div className='col-12 mt-5'>
                    <Button
                        text='Thêm phòng ban'
                        bClass='btn btn-primary px-5'
                        textColor
                        onClick={handleSubmit}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ModalPhongban;

