import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputForm } from '~/components/Form';
import { Icon, Button, SelectNhanvien } from '~/components/Component';
import { Modal, ModalBody } from 'reactstrap';
import { matusinhResult } from '~/utils/Utils';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { apiEditTaisan, apiNewTaisanNhanvien, apiGetTaisanNhanvien, apiUpdateTaisanNhanvien } from '~/services';
import * as actions from '~/store/actions';

const ModalCtb = ({ showCtb, setShowCtb, showHandleCtb, dataIdTaisan }) => {
    // console.log('dataIdTaisan', dataIdTaisan)
    const dispatch = useDispatch();
    const [invalidFields, setInvalidFields] = useState([]);
    const [listNhanvien, setlistNhanvien] = useState([]);
    const [listTaiSanCapPhat, setlistTaiSanCapPhat] = useState([]);
    const [showSelect, setShowSelect] = useState(false);
    const [payload, setPayloadTaisan] = useState({
        mataisannhanvien: '',
        mataisannhanvienCapPhat: '',
        TaisanId: '',
        Tentaisan: '',
        NhanvienId: '',
        TrangthaiId: '',
        soluong: 1,
        tonkho: '',
    });
    // console.log('listNhanvien',listNhanvien)
    useEffect(() => {
        if (showCtb) {
            async function fetchData() {
                try {
                    let dataFin = { TaisanId: dataIdTaisan.id, TrangthaiId: 0 };
                    let dataTsnv = await apiGetTaisanNhanvien(dataFin);
                    let dataTaisanCapPhat = dataTsnv?.data?.data?.rows;
                    // console.log('dataTaisanCapPhat', dataTaisanCapPhat)
                    // let soluongCP = 1;
                    // setlistNhanvien(dataTaisanCapPhat.map((item) => ({
                    //   item
                    // })))
                    setlistTaiSanCapPhat(
                        dataTaisanCapPhat.map((item) => ({
                            label: item.mataisannhanvien,
                            value: item.NhanvienId,
                            id: item.id,
                            NhanvienId: item.Nhanvien,
                            soluong: item.soluong,
                        })),
                    );
                    let dataTaisan = dataTsnv?.data?.data?.rows?.map((item) => ({ matusinh: item.mataisannhanvien }));

                    const mats = matusinhResult(
                        dataTaisan && dataTaisan.length > 0 ? dataTaisan : [{}],
                        showHandleCtb === 'Capphat' ? 'MCP' : showHandleCtb === 'Thuhoi' ? 'MTH' : 'MBNBH',
                    );
                    // console.log('listTaiSanCapPhat', listTaiSanCapPhat)
                    dataIdTaisan &&
                        setPayloadTaisan({
                            mataisannhanvien: mats,
                            mataisannhanvienCapPhat: '',
                            TaisanId: dataIdTaisan?.id,
                            Tentaisan: dataIdTaisan?.tentaisan,
                            soluong: '',
                            tonkho: `${dataIdTaisan?.soluongconlai}/${dataIdTaisan?.soluong}`,
                            NhanvienId: '',
                        });
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
            fetchData();
        }
    }, [showCtb, dataIdTaisan, listNhanvien]);
    //sử lý cấp phát
    // console.log('payload', payload)
    // console.log('listTaiSanCapPhat',listTaiSanCapPhat)
    const handleOnSelect = async (selected, name) => {
        // console.log('selected', selected)
        let statename = name.name;
        // setPayloadTaisan(selected);
        setPayloadTaisan((prev) => ({
            ...prev,
            [statename]: selected,
            ['NhanvienId']: { label: selected?.NhanvienId?.hoten, value: selected?.NhanvienId?.id },
        }));
    };
    // console.log('payload', payload)
    const addCapphat = async () => {
        if (payload.soluong !== '' && +payload.soluong <= dataIdTaisan?.soluongconlai) {
            let datapayload = {
                data: [
                    {
                        mataisannhanvien: payload.mataisannhanvien,
                        TaisanId: payload.TaisanId,
                        NhanvienId: listNhanvien[0].value,
                        soluong: payload.soluong,
                        TrangthaiId: 0,
                    },
                ],
            };
            const response = await apiNewTaisanNhanvien(datapayload);
            if (response?.data.success === 200) {
                let payloadts = {
                    id: dataIdTaisan?.id,
                    soluongconlai: Number(dataIdTaisan?.soluongconlai) - Number(payload?.soluong),
                    TrangthaiId: 1,
                };

                const editTs = await apiEditTaisan(payloadts);
                if (editTs?.data.success === 200) {
                    toast.success('Cấp phát tài sản thành công!');
                    dispatch(actions.getListTaisan());
                    setShowCtb();
                }
            }
        } else {
            toast.error('Số lượng chưa đúng!');
        }
    };
    // console.log('payload',payload)
    const addThuHoi = async () => {
        // console.log('addThuHoi', payload);
        let datapayload = {
            data: [
                {
                    mataisannhanvien: payload.mataisannhanvien + '-' + payload.mataisannhanvienCapPhat.label,
                    TaisanId: payload.TaisanId,
                    NhanvienId: payload.NhanvienId.value,
                    soluong: payload.mataisannhanvienCapPhat.soluong,
                    TrangthaiId: 1,
                },
            ],
        };
        const response = await apiNewTaisanNhanvien(datapayload);
        if (response?.data.success === 200) {
            let payloadts = {
                id: dataIdTaisan?.id,
                soluongconlai: Number(dataIdTaisan?.soluongconlai) + Number(payload.mataisannhanvienCapPhat.soluong),
                TrangthaiId: 1,
            };
            const editTs = await apiEditTaisan(payloadts);

            let payloaduts = {
                data: [
                    {
                        id: payload.mataisannhanvienCapPhat.id,
                        mataisannhanvien: payload.mataisannhanvienCapPhat.label,
                        TaisanId: payload.TaisanId,
                        NhanvienId: payload.NhanvienId.value,
                        soluong: payload.mataisannhanvienCapPhat.soluong,
                        TrangthaiId: 3,
                    },
                ],
            };
            const edituTs = await apiUpdateTaisanNhanvien(payloaduts);
            if (editTs?.data.success === 200) {
                toast.success('Thu hồi tài sản thành công!');
                dispatch(actions.getListTaisan());
                setShowCtb();
            }
        }
    };
    const addBaoMat = async () => {
        // console.log('addThuHoi', payload);
        let datapayload = {
            data: [
                {
                    mataisannhanvien: payload.mataisannhanvien + '-' + payload.mataisannhanvienCapPhat.label,
                    TaisanId: payload.TaisanId,
                    NhanvienId: payload.NhanvienId.value,
                    soluong: payload.mataisannhanvienCapPhat.soluong,
                    TrangthaiId: 2,
                },
            ],
        };
        const response = await apiNewTaisanNhanvien(datapayload);
        if (response?.data.success === 200) {
            let payloadts = {
                data: [
                    {
                        id: payload.mataisannhanvienCapPhat.id,
                        mataisannhanvien: payload.mataisannhanvienCapPhat.label,
                        TaisanId: payload.TaisanId,
                        NhanvienId: payload.NhanvienId.value,
                        soluong: payload.mataisannhanvienCapPhat.soluong,
                        TrangthaiId: 4,
                    },
                ],
            };
            const editTs = await apiUpdateTaisanNhanvien(payloadts);
            if (editTs?.data.success === 200) {
                toast.success('Cấp phát tài sản thành công!');
                dispatch(actions.getListTaisan());
                setShowCtb();
            }
        }
    };
    // console.log('dataIdTaisan', dataIdTaisan);
    // console.log('payload1212', payload);
    // console.log('dataNhanvien', dataNhanvien);
    return (
        <React.Fragment>
            <Modal isOpen={showCtb} size="lg" toggle={setShowCtb}>
                <ModalBody>
                    <Icon name="cross-sm float-end fs-2" onClick={setShowCtb}></Icon>
                    <h5 className="title mb-5">
                        {showHandleCtb === 'Capphat'
                            ? 'Cấp phát tài sản'
                            : showHandleCtb === 'Thuhoi'
                            ? 'Thu hồi tài sản'
                            : 'Báo mất / Báo hỏng'}
                    </h5>
                    <div className="row">
                        <div className="form-group col-md-6">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={
                                    showHandleCtb === 'Capphat'
                                        ? 'Mã cấp phát'
                                        : showHandleCtb === 'Thuhoi'
                                        ? 'Mã thu hồi'
                                        : 'Mã báo cáo'
                                }
                                value={payload.mataisannhanvien}
                                setValue={setPayloadTaisan}
                                keyPayload={'mataisannhanvien'}
                                placeholder={'Tên tài sản'}
                                type="text"
                                disabled={true}
                            />
                        </div>
                        {showHandleCtb === 'Thuhoi' || showHandleCtb === 'Baomat' ? (
                            <div className="form-group col-md-6">
                                <label className="mb-2">Chọn Mã Cấp Phát</label>

                                <Select
                                    // value={payload.mataisannhanvienCapPhat}
                                    onChange={handleOnSelect}
                                    options={listTaiSanCapPhat}
                                    value={payload.mataisannhanvienCapPhat}
                                    name="mataisannhanvienCapPhat"
                                    onFocus={() => setInvalidFields([])}
                                />
                            </div>
                        ) : (
                            ''
                        )}
                        <div className="form-group col-md-6">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Tài sản'}
                                value={payload.Tentaisan}
                                setValue={setPayloadTaisan}
                                keyPayload={'Tentaisan'}
                                placeholder={'Tài sản'}
                                type="text"
                                disabled={true}
                            />
                        </div>

                        <div className="form-group col-md-6">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Số lượng'}
                                value={
                                    showHandleCtb === 'Capphat'
                                        ? payload?.soluong
                                        : payload?.mataisannhanvienCapPhat?.soluong
                                }
                                setValue={setPayloadTaisan}
                                keyPayload={'soluong'}
                                placeholder={'Số lượng'}
                                type="number"
                                disabled={
                                    showHandleCtb === 'Capphat' ? false : showHandleCtb === 'Thuhoi' ? true : true
                                }
                            />
                        </div>
                        <div className="form-group col-md-6">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Tồn kho / Tổng'}
                                value={payload.tonkho}
                                placeholder={'Tồn kho'}
                                type="text"
                                disabled={true}
                            />
                        </div>
                        <div
                            className="form-group col-md-12"
                            onClick={() => showHandleCtb === 'Capphat' && setShowSelect(true)}
                        >
                            <label htmlFor="inputZip" className="mb-2">
                                Chọn nhân viên
                            </label>
                            <Select
                                value={showHandleCtb === 'Capphat' ? listNhanvien : payload?.NhanvienId}
                                name="NhanvienId"
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'NhanvienId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'NhanvienId')?.message}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className="nk-modal-action justify-end">
                        <ul className="btn-toolbar g-4 align-center">
                            <li>
                                {showHandleCtb === 'Capphat' ? (
                                    <Button color="primary" onClick={() => addCapphat()}>
                                        Cấp phát
                                    </Button>
                                ) : showHandleCtb === 'Thuhoi' ? (
                                    <Button color="secondary" onClick={() => addThuHoi()}>
                                        Thu hồi
                                    </Button>
                                ) : (
                                    <Button color="danger" onClick={() => addBaoMat()}>
                                        Khai báo
                                    </Button>
                                )}
                            </li>
                        </ul>
                    </div>
                </ModalBody>
            </Modal>
            <SelectNhanvien
                showSelect={showSelect}
                // typeSelect = { ismultiple: "Lấy nhiều" , single: "lấy một" }
                setlistNhanvien={setlistNhanvien}
                setShowSelect={() => setShowSelect(false)}
            />
        </React.Fragment>
    );
};

export default ModalCtb;
