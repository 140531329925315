import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Content from '~/layout/content/Content';
import { Block } from '~/components/block/Block';
import {
    Button,
    DataTable,
    Icon, PermissionUtils,
} from '~/components/Component';

import {Card} from 'reactstrap';
import * as actions from '~/store/actions';

import { MaterialReactTable } from 'material-react-table';
import Swal from 'sweetalert2';
import { ModalEditBangchotcong, ModalBangchotcong } from '~/pages/Admin/Modal';
import { Box, IconButton, Stack } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon , Visibility as VisibilityIcon} from '@mui/icons-material';

import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import {MenuBangCong} from "~/pages/Admin/MenuTab/Menucontent";
import { Link } from 'react-router-dom';
import config from '~/config';
import { format, parseISO } from 'date-fns';
import {MRT_Localization_VI} from 'material-react-table/locales/vi';
import {toast} from "react-toastify";

const Bangchotcong = () => {
    const dispatch = useDispatch();
    const [showPhanquyen, setShowPhanquyen] = useState({});
    let dataPermissionUtils = PermissionUtils('quanlycong', 'all');
    useEffect(() => {
        if (dataPermissionUtils) {
            setShowPhanquyen(dataPermissionUtils);
        }
    }, [dataPermissionUtils]);

    const [data, setData] = useState([]);
    // console.log('data', data);
    const [show, setShow] = useState(false);
    const [showEditBangchotcong, setShowEditBangchotcong] = useState(false);
    const [dataIdBangchotcong, setDataIdBangchotcong] = useState([]);

    const {bangchotcong} = useSelector(state => state.bangchotcong)
    const fieldList = {
        tieude: '',
        mota: '',
        ngaybatdau: '',
        ngayketthuc: '',
        nguoitao: 1,
        TrangthaiId: '',
    };
    useEffect(() => {
        dispatch(actions.getListBangchotcong());
    }, []);
    useEffect(() => {
        if (bangchotcong) {
            setData(bangchotcong);
        }
    }, [bangchotcong]);
    const handleAddNewBangchotcong = async () => {
        setShow(true);
    };
    const handleEditBangchotcong = async (data) => {
        setShowEditBangchotcong(true);
        await setDataIdBangchotcong(data);
    };
    const handleDeleteBangchotcong = (id) => {
        Swal.fire({
            title: 'Bạn có chắc không?',
            text: 'Bạn sẽ không thể lấy lại khi xóa!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Không, bỏ qua!',
            confirmButtonText: 'Vâng, xóa nó đi!',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(actions.DeleteBangchotcong({ id }));
            }
        });
    };
    const Example = () => {
        const dataExport = data?.map((item) => {
            let abc = {}
            abc.tieude = item.tieude
            abc.mota = item.mota
            abc.ngaybatdau = item?.ngaybatdau
            abc.ngayketthuc = item?.ngayketthuc
            abc.TrangthaiId = item.TrangthaiId === 1 ? 'Đã khóa' : 'Chưa khóa'
            return abc
        })
        const columns = useMemo(
            () => [
                {
                    accessorKey: 'tieude',
                    header: 'Tên bảng công',
                    enablePinning: true,
                    enableColumnActions: false,
                    Cell: ({ cell, row }) => (
                        <Link to={`${config.he_thong}${config.cham_cong}?bangcong=${row.original.id}`}>
                            {cell.getValue()}
                        </Link>
                    ),
                },
                {
                    accessorKey: 'mota',
                    header: 'Mô tả',
                },
                {
                    accessorFn: (row) => `${format(parseISO(row?.ngaybatdau), 'dd/MM/yyyy')}`,
                    id: 'ngaybatdau',
                    header: 'Ngày bắt đầu',
                    size: 160,
                },
                {
                    accessorFn: (row) => `${format(parseISO(row?.ngayketthuc), 'dd/MM/yyyy')}`,
                    id: 'ngayketthuc',
                    header: 'Ngày kết thúc',
                    size: 160,
                },
                {
                    accessorKey: 'TrangthaiId',
                    header: 'Trạng thái',
                    size: 180,
                    Cell: ({ cell }) => (
                        <Box
                            component="span"
                            sx={(theme) => ({
                                backgroundColor:
                                    cell.getValue() === 1 ? theme.palette.success.dark : theme.palette.warning.dark,
                                borderRadius: '0.25rem',
                                color: '#fff',
                                maxWidth: '9ch',
                                p: '0.25rem',
                            })}
                        >
                            {cell.getValue() === 1 ? 'Đã khóa' : 'Chưa khóa'}
                        </Box>
                    ),
                },
            ],
            [],
        );
        const csvOptions = { fileName: 'csv-data',
            fieldSeparator: ',',
            quoteStrings: '"',
            filename: 'Danh_sach_cham_cong_chi_tiet',
            decimalSeparator: '.',
            showLabels: true,
            useBom: true,
            useKeysAsHeaders: false,
            headers: columns.map((c) => c.header),
        };

        const csvExporter = new ExportToCsv(csvOptions);
  
        const handleExportData = () => {
            if (dataExport.length === 0) {
                toast.error("Không có dữ liệu để xuất!")
                return;
            }
            csvExporter.generateCsv(dataExport);
        };
        return (
            <MaterialReactTable
                columns={columns}
                data={data}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                enableRowNumbers
                enableRowActions
                positionActionsColumn="last"
                enableRowSelection
                renderTopToolbarCustomActions={({ table }) => (
                    <Box sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}>
                        {+showPhanquyen?.them === 1 && (
                        <Button
                            color="success"
                            onClick={handleAddNewBangchotcong}
                            variant="contained"
                        >
                                + Thêm mới
                        </Button>
                         )}
                        <Button
                            color="primary"
                            onClick={handleExportData}
                            startIcon={<FileDownloadIcon />}
                            variant="contained"
                        >
                            <Icon name="download-cloud me-2"></Icon> Xuất dữ liệu
                        </Button>
                    </Box>
                )}
                renderRowActions={({ row, table }) => (
                    <Box sx={{ display: 'block', flexWrap: 'nowrap', gap: '8px', width: '150px' }}>
                         <Link to={`${config.he_thong}${config.cham_cong}?bangcong=${row.original.id}`}>
                        <IconButton
                            color="primary"
                            width="20px"
                        >
                            <VisibilityIcon />
                        </IconButton>
                        </Link>
                        {+showPhanquyen?.sua === 1 && (
                        <IconButton
                            color="secondary"
                            width="20px"
                            onClick={() => {
                                handleEditBangchotcong(row.original);
                            }}
                        >
                            <EditIcon />
                        </IconButton>
                        )}
                        {+showPhanquyen?.xoa === 1 && (
                        <IconButton
                            color="error"
                            onClick={() => {
                                handleDeleteBangchotcong(row.original.id);
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                        )}
                    </Box>
                )}
                muiTableHeadCellProps={{
                    sx: {
                        backgroundColor: 'rgb(111, 224, 224)',
                    },
                }}
                localization={MRT_Localization_VI}
            />
        );

    }

    return (
        <React.Fragment>
            {/*<Head title="User List - Regular"></Head>*/}
            <Content>
                <Card className="mt-0">
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner card-inner-lg px-4 pt-1 pb-0">
                            <h4 className="mt-2">Quản lý chấm công</h4> 
                            <MenuBangCong />
                            <h5 className="mt-4"> Bảng chấm công chi tiết</h5>
                        </div>
                    </DataTable>
                </Card>
                <Block>
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner position-relative card-tools-toggle">
                         <Example />
                        </div>
                    </DataTable>
                </Block>
                <ModalBangchotcong show={show} fieldList={fieldList} setShow={() => setShow(false)} />
                <ModalEditBangchotcong
                    show={showEditBangchotcong}
                    fieldList={fieldList}
                    setShow={() => setShowEditBangchotcong(false)}
                    dataIdBangchotcong={dataIdBangchotcong}
                />
            </Content>
        </React.Fragment>
    );
};

export default Bangchotcong;
