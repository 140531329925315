import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '~/store/actions';
import { ModalSuatan, ModalEditSuatan, ModalLoaisuatan, ModalEditLoaisuatan } from '../Admin/Modal';
import Swal from 'sweetalert2';
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalBody,
    UncontrolledDropdown,
    Spinner,
    Card,
} from 'reactstrap';
import Head from '~/layout/head/Head';
import Content from '~/layout/content/Content';
import {
    Button,
    Col,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    Icon,
    PaginationComponent,
    Row,
    RSelect,
    PermissionUtils,
    TooltipComponent,
} from '~/components/Component';
import {
    apiGetLoaisuatan,
    apiGetPhieusuatan,
    apiDeleteLoaisuatan,
    apiDeletePhieusuatan,
    apiEditPhieusuatan,
} from '~/services';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import config from '~/config';
import { format, parseISO } from 'date-fns';
import { NumericFormat } from 'react-number-format';
import { Block } from '~/components/block/Block';

const Suatan = () => {
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [data, setData] = useState([]);
    const [dataLoaisuatan, setDataLoaisuatan] = useState([]);
    const [dataPhieusuatan, setDataPhieusuatan] = useState([]);
    const [modalTab, setModalTab] = useState('bangtonghopsuatan');
    useEffect(() => {
        if (show === false && showEdit === false) {
            const fetchData = async () => {
                try {
                    const response = await apiGetLoaisuatan();
                    setDataLoaisuatan(response?.data?.data?.rows);
                    const response1 = await apiGetPhieusuatan();
                    setDataPhieusuatan(response1?.data?.data?.rows);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };
            fetchData().catch(console.error);
        }
    }, [show, showEdit]);
    useEffect(() => {
        fetchData(modalTab);
    }, [modalTab, dataLoaisuatan, dataPhieusuatan]);
    async function fetchData(modalTab) {
        try {
            if (modalTab === 'bangtonghopsuatan') {
            } else if (modalTab === 'phieusuatan') {
                await setData(dataPhieusuatan);
            } else if (modalTab === 'loaisuatan') {
                await setData(dataLoaisuatan);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const [showPhanquyen, setShowPhanquyen] = useState({});
    let dataPermissionUtils = PermissionUtils('suatan', 'all');
    useEffect(() => {
        if (dataPermissionUtils) {
            setShowPhanquyen(dataPermissionUtils);
        }
    }, [dataPermissionUtils]);

    const fieldList = {
        tieude: '',
        giatri: '',
        ghichu: '',
        TrangthaiId: '',
    };
    const [sm, updateSm] = useState(false);
    const [tablesm, updateTableSm] = useState(false);
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [sort, setSortState] = useState('');
    // onChange function for searching name
    const onFilterChange = (e) => {
        setSearchText(e.target.value);
    };
    // function to toggle the search option
    const toggle = () => setonSearch(!onSearch);
    // function to change the check property of an item
    const selectorCheck = (e) => {
        let newData;
        newData = data.map((item) => {
            item.checked = e.currentTarget.checked;
            return item;
        });
        setData([...newData]);
    };
    // function to change the selected property of an item
    const onSelectChange = (e, id) => {
        let newData = data;
        let index = newData.findIndex((item) => item.id === id);
        newData[index].checked = e.currentTarget.checked;
        setData([...newData]);
    };
    // Changing state value when searching name
    useEffect(() => {
        if (onSearchText !== '') {
            const filteredObject = data.filter((item) => {
                return item.maphieu.toLowerCase().includes(onSearchText.toLowerCase());
            });
            setData([...filteredObject]);
        } else {
            setData([...dataPhieusuatan]);
        }
    }, [onSearchText, setData]);
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    // console.log('datammmmmmmmmmmmm', data)

    const [dataId, setDataId] = useState([]);
    const handleAddNew = async () => {
        setShow(true);
    };
    const handleEdit = async (data) => {
        if (data !== null) {
            setShowEdit(true);
            setDataId(data);
        } else {
            toast.error('Không có dữ liệu vui lòng kiểm tra phiếu suất ăn!');
        }
    };
    const handleDelete = async (data) => {
        Swal.fire({
            title: 'Bạn có chắc không?',
            text: 'Bạn sẽ không thể lấy lại khi xóa!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Không, bỏ qua!',
            confirmButtonText: 'Vâng, xóa nó đi!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (modalTab === 'phieusuatan') {
                    const response = await apiDeletePhieusuatan(data);
                    if (response?.data?.success === 200) {
                        toast.success('Xóa thông tin thành công!');
                        const response1 = await apiGetPhieusuatan();
                        setDataPhieusuatan(response1?.data?.data?.rows);
                    }
                }
                if (modalTab === 'loaisuatan') {
                    const response = await apiDeleteLoaisuatan(data);
                    if (response?.data?.success === 200) {
                        toast.success('Xóa thông tin thành công!');
                        const response = await apiGetLoaisuatan();
                        setDataLoaisuatan(response?.data?.data?.rows);
                    }
                }
            }
        });
    };

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // +1 vì tháng trong JavaScript bắt đầu từ 0
    const defaultValue = `${year}-${month}`;

    const [inputValue, setInputValue] = useState(defaultValue);
    const [daysInMonth, setDaysInMonth] = useState([]);
    const vietnameseDaysOfWeek = ['Chủ Nhật', 'Thứ Hai', 'Thứ Ba', 'Thứ Tư', 'Thứ Năm', 'Thứ Sáu', 'Thứ Bảy'];

    const dataitemdate = (date) => {
        const foundItem = dataPhieusuatan?.find((item) => {
            const formattedDate = item.ngaytao ? format(parseISO(item.ngaytao), 'dd-MM-yyyy') : null;
            return formattedDate === date;
        });
        let a = null;
        if (foundItem !== undefined) {
            const coumtSL = (dataSL) => {
                let tongSoLuong = 0;
                for (let i = 0; i < dataSL.length; i++) {
                    const phanTu = dataSL[i];
                    tongSoLuong += parseInt(phanTu.Soluong, 10);
                }
                return tongSoLuong;
            };
            const coumtCp = (dataCp) => {
                let tongchiphi = 0;
                dataCp.forEach((item) => {
                    const laoiSa = dataLoaisuatan.find((itemSa) => {
                        return itemSa && Number(itemSa.id) === Number(item?.LoaisuatanId?.value);
                    });
                    if (laoiSa) {
                        tongchiphi += laoiSa.giatri * item?.Soluong || 0;
                    }
                });
                return tongchiphi;
            };
            // console.log('foundItem', foundItem);
            a = {
                id: foundItem?.id,
                ngaytao: foundItem?.ngaytao,
                nguoitao: foundItem?.User?.username,
                TrangthaiId: foundItem?.TrangthaiId,
                maphieu: foundItem?.maphieu,
                noidung: foundItem?.noidung,
                tongsoluong: coumtSL(foundItem?.noidung ? JSON.parse(foundItem?.noidung) : []),
                tongchiphi: coumtCp(foundItem?.noidung ? JSON.parse(foundItem?.noidung) : []),
            };
        }
        return a;
    };
    useEffect(() => {
        const [year, month] = inputValue.split('-');
        const firstDayOfMonth = new Date(year, month - 1, 1);
        const lastDay = new Date(year, month, 0);

        const daysOfMonth = [];
        for (let day = firstDayOfMonth; day <= lastDay; day.setDate(day.getDate() + 1)) {
            // if (day.getDay() !== 0) {
            let days = new Date(day);
            const dayString = days.getDate().toString().padStart(2, '0');
            const monthString = (days.getMonth() + 1).toString().padStart(2, '0');
            const yearString = days.getFullYear();
            const formattedDate = `${dayString}-${monthString}-${yearString}`;

            daysOfMonth.push({
                ngay: formattedDate,
                thu: vietnameseDaysOfWeek[days.getDay()],
                itemphieu: dataitemdate(formattedDate),
            });
            // }
        }
        const sumSl = daysOfMonth.reduce((total, item) => total + (item?.itemphieu?.tongsoluong || 0), 0);
        const sumCp = daysOfMonth.reduce((total, item) => total + (item?.itemphieu?.tongchiphi || 0), 0);
        setCuontTongsoluong(sumSl);
        setCuontChiphi(sumCp);
        setDaysInMonth(daysOfMonth);
    }, [inputValue, dataPhieusuatan]);
    const [countTongsoluong, setCuontTongsoluong] = useState(0);
    const [CountTongchiphi, setCuontChiphi] = useState(0);
    // console.log('daysInMonth', daysInMonth);
    // console.log('countTongsoluong', countTongsoluong);
    // console.log('CountTongchiphi', CountTongchiphi);
    // console.log('dataPhieusuatan', dataPhieusuatan);
    const handleLock = async (datalock) => {
        let datapayload = null;
        if (datalock !== 'All') {
            datapayload = {
                data: [
                    {
                        id: datalock?.id,
                        TrangthaiId: 2,
                    },
                ],
            };
        } else {
            let arr = daysInMonth.map((item) => {
                if (item?.itemphieu !== null) {
                    return {
                        id: item.itemphieu.id,
                        TrangthaiId: 2,
                    };
                } else {
                    return null;
                }
            });
            arr = arr.filter((item) => item !== null);
            datapayload = {
                data: arr,
            };
        }
        // console.log('datapayload', datapayload)
        const response = await apiEditPhieusuatan(datapayload);
        if (response?.data?.success === 200) {
            toast.success('Khóa phiếu thành công!');
            const response1 = await apiGetPhieusuatan();
            setDataPhieusuatan(response1?.data?.data?.rows);
        } else {
            toast.error('Khóa phiếu thất bại!');
        }
    };
    const itemsPerPageOptions = [10, 20, 30, 40, 50, 100, 300, 500];
    return (
        <React.Fragment>
            {/*<Head title="User List - Regular"></Head>*/}
            <Content>
                <Card className="card-bordered border-0">
                <DataTable className="card-stretch border-0">
                        <div className="card-inner card-inner-lg px-4">
                            <h4>Quản lý suất ăn</h4>
                            <div>
                                <ul className="nk-nav nav nav-tabs">
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link ${modalTab === 'bangtonghopsuatan' && 'active'}`}
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                setModalTab('bangtonghopsuatan');
                                                // onClickTabAnChinh();
                                            }}
                                            href="#bangtonghopsuatan"
                                        >
                                            Bảng tổng hợp suất ăn
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link ${modalTab === 'phieusuatan' && 'active'}`}
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                setModalTab('phieusuatan');
                                                // onClickTabTangCa();
                                            }}
                                            href="#phieusuatan"
                                        >
                                            Phiếu suất ăn
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link ${modalTab === 'loaisuatan' && 'active'}`}
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                setModalTab('loaisuatan');
                                                // onClickTabTangCa();
                                            }}
                                            href="#loaisuatan"
                                        >
                                            Loại suất ăn
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </DataTable>
                </Card>
                <Block>
                    {modalTab !== 'bangtonghopsuatan' ? (
                        <DataTable className="card-stretch border-0">
                            <div className="card-inner position-relative card-tools-toggle">
                                <div className="card-title-group">
                                    <div className="card-title">
                                        <h5 className="title">
                                            {modalTab === 'bangtonghopsuatan'
                                                ? 'Bảng tổng hợp suất ăn'
                                                : modalTab === 'phieusuatan'
                                                ? 'Phiếu suất ăn'
                                                : 'Loại suất ăn'}
                                        </h5>
                                        {+showPhanquyen?.them === 1 && modalTab !== 'bangtonghopsuatan' && (
                                            <Button
                                                color="primary"
                                                className="btn-icon px-4 mt-2"
                                                onClick={handleAddNew}
                                            >
                                                <Icon name="plus"></Icon> Thêm mới
                                            </Button>
                                        )}
                                    </div>
                                    {modalTab !== 'loaisuatan' && (
                                    <div className="card-tools me-n1">
                                        <ul className="btn-toolbar gx-1">
                                            <li>
                                                <a
                                                    href="#search"
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        toggle();
                                                    }}
                                                    className="btn btn-icon search-toggle toggle-search"
                                                >
                                                    <Icon name="search"></Icon>
                                                </a>
                                            </li>
                                            <li className="btn-toolbar-sep"></li>
                                            <li>
                                                <div className="toggle-wrap">
                                                    <Button
                                                        className={`btn-icon btn-trigger toggle ${
                                                            tablesm ? 'active' : ''
                                                        }`}
                                                        onClick={() => updateTableSm(true)}
                                                    >
                                                        <Icon name="menu-right"></Icon>
                                                    </Button>
                                                    <div
                                                        className={`toggle-content ${tablesm ? 'content-active' : ''}`}
                                                    >
                                                        <ul className="btn-toolbar gx-1">
                                                            <li className="toggle-close">
                                                                <Button
                                                                    className="btn-icon btn-trigger toggle"
                                                                    onClick={() => updateTableSm(false)}
                                                                >
                                                                    <Icon name="arrow-left"></Icon>
                                                                </Button>
                                                            </li>
                                                            <li>
                                                                <UncontrolledDropdown>
                                                                    <DropdownToggle
                                                                        color="tranparent"
                                                                        className="btn btn-trigger btn-icon dropdown-toggle"
                                                                    >
                                                                        <Icon name="setting"></Icon>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu end className="dropdown-menu-xs">
                                                                        <ul className="link-check">
                                                                            <li>
                                                                                <span>Show</span>
                                                                            </li>
                                                                            {itemsPerPageOptions.map((item, index) => {
                                                                            return (
                                                                                <li
                                                                                    className={
                                                                                        itemPerPage === item
                                                                                            ? 'active'
                                                                                            : ''
                                                                                    }
                                                                                    key={index}
                                                                                >
                                                                                    <DropdownItem
                                                                                        tag="a"
                                                                                        href="#dropdownitem"
                                                                                        onClick={(ev) => {
                                                                                            ev.preventDefault();
                                                                                            setItemPerPage(item);
                                                                                            // handleReParamsClick();
                                                                                        }}
                                                                                    >
                                                                                        {item}
                                                                                    </DropdownItem>
                                                                                </li>
                                                                            );
                                                                        })}
                                                                        </ul>
                                                                        <ul className="link-check">
                                                                            <li>
                                                                                <span>Order</span>
                                                                            </li>
                                                                            <li
                                                                                className={
                                                                                    sort === 'dsc' ? 'active' : ''
                                                                                }
                                                                            >
                                                                                <DropdownItem
                                                                                    tag="a"
                                                                                    href="#dropdownitem"
                                                                                    onClick={(ev) => {
                                                                                        ev.preventDefault();
                                                                                        setSortState('dsc');
                                                                                        //   sortFunc("dsc");
                                                                                    }}
                                                                                >
                                                                                    DESC
                                                                                </DropdownItem>
                                                                            </li>
                                                                            <li
                                                                                className={
                                                                                    sort === 'asc' ? 'active' : ''
                                                                                }
                                                                            >
                                                                                <DropdownItem
                                                                                    tag="a"
                                                                                    href="#dropdownitem"
                                                                                    onClick={(ev) => {
                                                                                        ev.preventDefault();
                                                                                        setSortState('asc');
                                                                                        //   sortFunc("asc");
                                                                                    }}
                                                                                >
                                                                                    ASC
                                                                                </DropdownItem>
                                                                            </li>
                                                                        </ul>
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                            
                                        </ul>
                                    </div>
                                    )}
                                </div>
                                <div className={`card-search search-wrap ${!onSearch && 'active'}`}>
                                    <div className="card-body">
                                        <div className="search-content">
                                            <Button
                                                className="search-back btn-icon toggle-search active"
                                                onClick={() => {
                                                    setSearchText('');
                                                    toggle();
                                                }}
                                            >
                                                <Icon name="arrow-left"></Icon>
                                            </Button>
                                            <input
                                                type="text"
                                                className="border-transparent form-focus-none form-control"
                                                placeholder="Tìm kiếm"
                                                value={onSearchText}
                                                onChange={(e) => onFilterChange(e)}
                                            />
                                            <Button className="search-submit btn-icon">
                                                <Icon name="search"></Icon>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ overflowY: 'hidden' }}>
                            <DataTableBody>
                                <DataTableHead>
                                    <DataTableRow>
                                        <span className="sub-text">Stt</span>
                                    </DataTableRow>
                                    <DataTableRow className="nk-tb-col-check">
                                        <div className="custom-control custom-control-sm custom-checkbox notext">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                onChange={(e) => selectorCheck(e)}
                                                id="uid"
                                            />
                                            <label className="custom-control-label" htmlFor="uid"></label>
                                        </div>
                                    </DataTableRow>
                                    {modalTab === 'phieusuatan' ? (
                                        <>
                                            <DataTableRow>
                                                <span className="sub-text w-150px">Mã phiếu suất ăn</span>
                                            </DataTableRow>
                                            <DataTableRow>
                                                <span className="sub-text w-150px">Ngày tạo</span>
                                            </DataTableRow>
                                            <DataTableRow>
                                                <span className="sub-text w-150px">Tổng suất ăn</span>
                                            </DataTableRow>
                                        </>
                                    ) : (
                                        <>
                                            <DataTableRow>
                                                <span className="sub-text w-150px">Tên loại hợp đồng</span>
                                            </DataTableRow>
                                            <DataTableRow>
                                                <span className="sub-text w-150px">Giá trị</span>
                                            </DataTableRow>
                                            <DataTableRow>
                                                <span className="sub-text w-150px">Ghi chú</span>
                                            </DataTableRow>
                                        </>
                                    )}

                                    <DataTableRow>
                                        <span className="sub-text w-150px">Trạng thái</span>
                                    </DataTableRow>
                                    <DataTableRow></DataTableRow>
                                </DataTableHead>
                                {currentItems.length > 0
                                    ? currentItems.map((item, index) => {
                                          let tongSoLuong = 0;
                                          if (modalTab === 'phieusuatan' && item?.noidung) {
                                              const par = JSON.parse(item?.noidung);
                                              for (let i = 0; i < par.length; i++) {
                                                  const phanTu = par[i];
                                                  tongSoLuong += parseInt(phanTu.Soluong, 10);
                                              }
                                          }
                                          const displayedIndex =  itemPerPage * (currentPage - 1) + index + 1;
                                          return (
                                              <DataTableItem key={item.id}>
                                                  <DataTableRow className="nk-tb-col-check">
                                                      <span className="sub-text">{displayedIndex}</span>
                                                  </DataTableRow>
                                                  <DataTableRow>
                                                      <div className="custom-control custom-control-sm custom-checkbox notext">
                                                          <input
                                                              type="checkbox"
                                                              className="custom-control-input"
                                                              defaultChecked={item.checked}
                                                              id={item.id + 'uid1'}
                                                              key={Math.random()}
                                                              onChange={(e) => onSelectChange(e, item.id)}
                                                          />
                                                          <label
                                                              className="custom-control-label"
                                                              htmlFor={item.id + 'uid1'}
                                                          ></label>
                                                      </div>
                                                  </DataTableRow>
                                                  {modalTab === 'phieusuatan' ? (
                                                      <>
                                                          <DataTableRow>
                                                              <span className="tb-amount">{item?.maphieu}</span>
                                                          </DataTableRow>
                                                          <DataTableRow>
                                                              <span className="tb-amount">
                                                                  {item?.ngaytao
                                                                      ? format(parseISO(item?.ngaytao), 'dd-MM-yyyy')
                                                                      : 'NNA'}
                                                              </span>
                                                          </DataTableRow>
                                                          <DataTableRow>
                                                              <span className="tb-amount">{tongSoLuong}</span>
                                                          </DataTableRow>
                                                      </>
                                                  ) : (
                                                      <>
                                                          <DataTableRow>
                                                              <span className="tb-amount">{item?.tieude}</span>
                                                          </DataTableRow>
                                                          <DataTableRow>
                                                              <span className="tb-amount">{item?.giatri}</span>
                                                          </DataTableRow>
                                                          <DataTableRow>
                                                              <span className="tb-amount">
                                                                  {item?.ghichu ? item?.ghichu : 'NNA'}
                                                              </span>
                                                          </DataTableRow>
                                                      </>
                                                  )}

                                                  <DataTableRow>
                                                      <span
                                                          className={`${
                                                              item?.TrangthaiId === 0 ? 'bg-danger' : 'bg-success'
                                                          } ms-0 badge text-white`}
                                                      >
                                                          {item?.TrangthaiId === 0
                                                              ? 'Chưa Duyệt'
                                                              : item?.TrangthaiId === 1
                                                              ? 'Đã duyệt'
                                                              : 'Đã khóa'}
                                                      </span>
                                                  </DataTableRow>
                                                  <DataTableRow className="nk-tb-col-tools">
                                                      <ul className="nk-tb-actions gx-1">
                                                          {/* {+showPhanquyen?.sua === 1 && (
                                                                                <li
                                                                                    className="nk-tb-action-hidden"
                                                                                    onClick={() => handleEdit(item)}
                                                                                >
                                                                                    <TooltipComponent
                                                                                        tag="a"
                                                                                        containerClassName="btn btn-trigger btn-icon"
                                                                                        id={'edit' + item.id}
                                                                                        icon="edit-alt-fill"
                                                                                        direction="top"
                                                                                        text="Sửa"
                                                                                    />
                                                                                </li>
                                                                            )}
                                                                            {+showPhanquyen?.xoa === 1 &&
                                                                                item?.is_default !== 1 && (
                                                                                    <React.Fragment>
                                                                                        <li
                                                                                            className="nk-tb-action-hidden"
                                                                                            onClick={() =>
                                                                                                handleDelete(item)
                                                                                            }
                                                                                        >
                                                                                            <TooltipComponent
                                                                                                tag="a"
                                                                                                containerClassName="btn btn-trigger btn-icon"
                                                                                                id={
                                                                                                    'suspend' +
                                                                                                    item.id
                                                                                                }
                                                                                                icon="user-cross-fill"
                                                                                                direction="top"
                                                                                                text="Xóa"
                                                                                            />
                                                                                        </li>
                                                                                    </React.Fragment>
                                                                                )} */}
                                                          {modalTab === 'phieusuatan' && (
                                                              <>
                                                                  <li onClick={() => handleEdit(item)}>
                                                                      <Button
                                                                          color={
                                                                              item.TrangthaiId !== 2 ? 'light' : 'info'
                                                                          }
                                                                          size="sm"
                                                                          className="btn-icon btn-white btn-dim"
                                                                      >
                                                                          <Icon
                                                                              name={
                                                                                  item.TrangthaiId !== 2
                                                                                      ? 'unlock-fill'
                                                                                      : 'lock-fill'
                                                                              }
                                                                          ></Icon>
                                                                      </Button>
                                                                  </li>
                                                                  <Link
                                                                      to={`${process.env.PUBLIC_URL}/print-phieu-suat-an/${item.id}`}
                                                                      target="_blank"
                                                                  >
                                                                      <li>
                                                                          <Button
                                                                              color="primary"
                                                                              size="sm"
                                                                              className="btn-icon btn-white btn-dim"
                                                                          >
                                                                              <Icon name="printer-fill"></Icon>
                                                                          </Button>
                                                                      </li>
                                                                  </Link>
                                                              </>
                                                          )}
                                                          <li>
                                                              <UncontrolledDropdown>
                                                                  {(+showPhanquyen?.sua === 1 ||
                                                                      +showPhanquyen?.xoa === 1) && (
                                                                      <DropdownToggle
                                                                          tag="a"
                                                                          className="dropdown-toggle btn btn-icon btn-trigger"
                                                                      >
                                                                          <Icon name="more-h"></Icon>
                                                                      </DropdownToggle>
                                                                  )}
                                                                  <DropdownMenu end>
                                                                      <ul className="link-list-opt no-bdr">
                                                                          {+showPhanquyen?.sua === 1 && (
                                                                              <li onClick={() => handleEdit(item)}>
                                                                                  <DropdownItem
                                                                                      tag="a"
                                                                                      href="#edit"
                                                                                      onClick={(ev) => {
                                                                                          ev.preventDefault();
                                                                                      }}
                                                                                  >
                                                                                      <Icon name="edit"></Icon>
                                                                                      <span>Sửa</span>
                                                                                  </DropdownItem>
                                                                              </li>
                                                                          )}
                                                                          {+showPhanquyen?.xoa === 1 &&
                                                                              item?.is_default !== 1 &&
                                                                              item?.TrangthaiId === 2 && (
                                                                                  <React.Fragment>
                                                                                      <li className="divider"></li>
                                                                                      <li
                                                                                          onClick={() =>
                                                                                              handleDelete(item)
                                                                                          }
                                                                                      >
                                                                                          <DropdownItem
                                                                                              tag="a"
                                                                                              href="#suspend"
                                                                                              onClick={(ev) => {
                                                                                                  ev.preventDefault();
                                                                                              }}
                                                                                          >
                                                                                              <Icon name="na"></Icon>
                                                                                              <span>Xóa</span>
                                                                                          </DropdownItem>
                                                                                      </li>
                                                                                  </React.Fragment>
                                                                              )}
                                                                      </ul>
                                                                  </DropdownMenu>
                                                              </UncontrolledDropdown>
                                                          </li>
                                                      </ul>
                                                  </DataTableRow>
                                              </DataTableItem>
                                          );
                                      })
                                    : null}
                            </DataTableBody>
                            </div>
                            <div className="card-inner">
                                {currentItems.length > 0 ? (
                                    <PaginationComponent
                                        itemPerPage={itemPerPage}
                                        totalItems={data.length}
                                        paginate={paginate}
                                        currentPage={currentPage}
                                    />
                                ) : (
                                    <div className="text-center">
                                        <div>
                                            <Spinner color="primary" />
                                        </div>
                                        <span className="text-silent">Chưa có dữ liệu!</span>
                                    </div>
                                )}
                            </div>
                        </DataTable>
                    ) : (
                        <>
                            <DataTable className="card-stretch border-0">
                                <div className="card-inner position-relative card-tools-toggle pt-0">
                                    <div className="card-title-group row">
                                        <div className="col-lg-8 card-title mt-2">
                                            <h5 className="title">Tra cứu theo tháng</h5>
                                            <input
                                                type="month"
                                                id="start"
                                                className="form-control w-200px"
                                                min="2018-03"
                                                value={inputValue}
                                                onChange={(e) => setInputValue(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-lg-4 card-tools me-n1 mt-2">
                                            <ul className="btn-toolbar gx-1 float-lg-end">
                                                <li onClick={() => handleLock('All')}>
                                                    <Button
                                                        size="md"
                                                        color="info"
                                                        outline
                                                        className="btn-icon btn-white btn-dim"
                                                        style={{ paddingRight: '10px' }}
                                                    >
                                                        <Icon name="lock-fill"></Icon> Khóa tất cả
                                                    </Button>
                                                </li>
                                                <li>
                                                    <Link
                                                        to={`${process.env.PUBLIC_URL}/print-bang-tong-hop-suat-an?date=${inputValue}`}
                                                        target="_blank"
                                                    >
                                                        <Button
                                                            size="md"
                                                            color="primary"
                                                            outline
                                                            className="btn-icon btn-white btn-dim"
                                                            // onClick={() => window.print()}
                                                        >
                                                            <Icon name="printer-fill"></Icon>
                                                        </Button>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ overflowY: 'hidden' }}>
                                <DataTableBody>
                                    <DataTableHead>
                                        <DataTableRow>
                                            <span className="sub-text">Stt</span>
                                        </DataTableRow>
                                        <DataTableRow>
                                            <span className="sub-text w-150px">Ngày tạo phiếu</span>
                                        </DataTableRow>
                                        <DataTableRow>
                                            <span className="sub-text w-100px">Mã phiếu</span>
                                        </DataTableRow>
                                        <DataTableRow>
                                            <span className="sub-text w-100px">Người tạo</span>
                                        </DataTableRow>
                                        <DataTableRow>
                                            <span className="sub-text w-150px">Số lượng suất ăn</span>
                                        </DataTableRow>
                                        <DataTableRow>
                                            <span className="sub-text w-100px">Chi phí</span>
                                        </DataTableRow>
                                        <DataTableRow className="nk-tb-col-tools text-end">
                                            <UncontrolledDropdown>
                                                <DropdownToggle
                                                    color="tranparent"
                                                    className="btn btn-xs btn-outline-light btn-icon dropdown-toggle"
                                                >
                                                    <Icon name="plus"></Icon>
                                                </DropdownToggle>
                                                <DropdownMenu end className="dropdown-menu-xs"></DropdownMenu>
                                            </UncontrolledDropdown>
                                        </DataTableRow>
                                    </DataTableHead>
                                    {daysInMonth.length > 0
                                        ? daysInMonth.map((item, index) => {
                                            const displayedIndex =  itemPerPage * (currentPage - 1) + index + 1;
                                              return (
                                                  <DataTableItem key={item.id}>
                                                      <DataTableRow >
                                                          <span className="tb-amount">{displayedIndex}</span>
                                                      </DataTableRow>

                                                      <DataTableRow>
                                                          <span className="tb-amount">
                                                              {item?.thu}
                                                              {' ngày '}
                                                              {item?.ngay}
                                                          </span>
                                                      </DataTableRow>
                                                      <DataTableRow>
                                                          <span className="tb-amount">
                                                              {item?.itemphieu?.maphieu
                                                                  ? item?.itemphieu?.maphieu
                                                                  : '_ _ _ _'}
                                                          </span>
                                                      </DataTableRow>
                                                      <DataTableRow>
                                                          <span className="tb-amount">
                                                              {item?.itemphieu?.nguoitao
                                                                  ? item?.itemphieu?.nguoitao
                                                                  : '_ _ _ _ '}
                                                          </span>
                                                      </DataTableRow>
                                                      <DataTableRow>
                                                          <span className="tb-amount">
                                                              {item?.itemphieu?.tongsoluong
                                                                  ? item?.itemphieu?.tongsoluong
                                                                  : 0}
                                                          </span>
                                                      </DataTableRow>
                                                      <DataTableRow>
                                                          <span className="tb-amount">
                                                              {item?.itemphieu?.tongchiphi ? (
                                                                  <NumericFormat
                                                                      value={item?.itemphieu?.tongchiphi}
                                                                      displayType={'text'}
                                                                      thousandSeparator={true}
                                                                      suffix={'đ'}
                                                                  />
                                                              ) : (
                                                                  0
                                                              )}
                                                          </span>
                                                      </DataTableRow>
                                                      <DataTableRow className="nk-tb-col-tools">
                                                          <ul className="nk-tb-actions gx-1">
                                                              {item?.itemphieu !== null && (
                                                                  <li onClick={() => handleLock(item.itemphieu)}>
                                                                      <Button
                                                                          color={
                                                                              item.itemphieu.TrangthaiId !== 2
                                                                                  ? 'light'
                                                                                  : 'info'
                                                                          }
                                                                          size="sm"
                                                                          className="btn-icon btn-white btn-dim"
                                                                      >
                                                                          <Icon
                                                                              name={
                                                                                  item.itemphieu.TrangthaiId !== 2
                                                                                      ? 'unlock-fill'
                                                                                      : 'lock-fill'
                                                                              }
                                                                          ></Icon>
                                                                      </Button>
                                                                  </li>
                                                              )}
                                                              <li onClick={() => handleEdit(item.itemphieu)}>
                                                                  <Button
                                                                      color="primary"
                                                                      size="sm"
                                                                      className="btn-icon btn-white btn-dim"
                                                                  >
                                                                      <Icon name="eye-alt-fill"></Icon>
                                                                  </Button>
                                                              </li>
                                                          </ul>
                                                      </DataTableRow>
                                                  </DataTableItem>
                                              );
                                          })
                                        : null}
                                    {daysInMonth.length > 0 && (
                                        <DataTableItem>
                                            <DataTableRow ></DataTableRow>
                                            <DataTableRow >
                                                <span className="tb-amount">Tổng cộng</span>
                                            </DataTableRow>
                                            <DataTableRow ></DataTableRow>
                                            <DataTableRow ></DataTableRow>
                                            <DataTableRow>
                                                <span className="tb-amount">{countTongsoluong}</span>
                                            </DataTableRow>
                                            <DataTableRow>
                                                <span className="tb-amount">
                                                    {CountTongchiphi && (
                                                        <NumericFormat
                                                            value={CountTongchiphi}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            suffix={'đ'}
                                                        />
                                                    )}
                                                </span>
                                            </DataTableRow>
                                            <DataTableRow className="nk-tb-col-tools">
                                                <ul className="nk-tb-actions gx-1">
                                                    <li>
                                                        <Link
                                                            to={`${process.env.PUBLIC_URL}/print-bang-tong-hop-suat-an?date=${inputValue}`}
                                                            target="_blank"
                                                        >
                                                            <Button
                                                                size="md"
                                                                color="primary"
                                                                outline
                                                                className="btn-icon btn-white btn-dim"
                                                                // onClick={() => window.print()}
                                                            >
                                                                <Icon name="printer-fill"></Icon>
                                                            </Button>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </DataTableRow>
                                        </DataTableItem>
                                    )}
                                </DataTableBody>
                                </div>
                                <div className="card-inner">
                                    {daysInMonth.length > 0 ? (
                                        <></>
                                    ) : (
                                        <div className="text-center">
                                            <div>
                                                <Spinner color="primary" />
                                            </div>
                                            <span className="text-silent">Chưa có dữ liệu!</span>
                                        </div>
                                    )}
                                </div>
                            </DataTable>
                        </>
                    )}
                </Block>
                {modalTab !== 'loaisuatan' ? (
                    <>
                        <ModalSuatan
                            show={show}
                            setShow={() => setShow(false)}
                            fieldList={fieldList}
                            dataPhieusuatan={dataPhieusuatan}
                            dataLoaisuatan={dataLoaisuatan}
                        />
                        <ModalEditSuatan
                            show={showEdit}
                            setShow={() => setShowEdit(false)}
                            fieldList={fieldList}
                            dataPhieusuatan={dataPhieusuatan}
                            dataLoaisuatan={dataLoaisuatan}
                            dataId={dataId}
                        />
                    </>
                ) : (
                    <>
                        <ModalLoaisuatan show={show} setShow={() => setShow(false)} />
                        <ModalEditLoaisuatan show={showEdit} setShow={() => setShowEdit(false)} dataId={dataId} />
                    </>
                )}
            </Content>
        </React.Fragment>
    );
};

export default Suatan;
