import actionTypes from './actionTypes'
import { apiGetCongty, apiNewCongty, apiEditCongty, apiDeleteCongty } from '~/services/congty'
import { toast } from "react-toastify";
export const getListCongTy = () => async (dispatch) => {
    try {
        const response = await apiGetCongty()
        // console.log('chek api lisst congty', response)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_CONG_TY,
                congty: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_CONG_TY,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_CONG_TY,
            congty: null
        })
    }
}

export const NewCongty = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiNewCongty(payload)
            // console.log('chek api apiNewCongty', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_CONG_TY,
                    msg: 'OK'
                });
                dispatch(getListCongTy());
                toast.success("Thêm mới công ty thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_CONG_TY,
                    msg: 'ERR'
                })
                toast.error("Thêm mới công ty thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_CONG_TY,
                congty: null
            })
        }
    }
}
export const EditCongty = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditCongty(payload)
            console.log('chek api apiEditCongty', response)
            if (response?.data.success === 200) {
                dispatch(getListCongTy());
                toast.success("Sửa thông tin công ty thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_CONG_TY,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_CONG_TY,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin công ty thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_CONG_TY,
                congty: null
            })
        }
    }
}
export const DeleteCongty = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteCongty(payload)
            // console.log('chek api apiDeleteCongty', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListCongTy());
                toast.success("Xóa thông tin công ty thành công!")
                dispatch({
                    type: actionTypes.DELETE_CONG_TY,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_CONG_TY,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin công ty thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_CONG_TY,
                congty: null
            })
        }
    }
}