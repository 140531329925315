import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actions from '~/store/actions';
import { ModalPhucap, ModalEditPhucap } from './Modal';
import Swal from 'sweetalert2';
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalBody,
    UncontrolledDropdown,
    Spinner,
    Card,
    Alert,
} from 'reactstrap';
import Head from '~/layout/head/Head';
import Content from '~/layout/content/Content';
import { Block } from '~/components/block/Block';
import {
    Button,
    Col,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    Icon,
    PaginationComponent,
    Row,
    RSelect,
    TooltipComponent,
    PermissionUtils,
    ExportXlsx,
} from '~/components/Component';
import { dataSelectActive } from '~/utils/Datacustom';
import { MenuLuongCapThuong } from './MenuTab/Menucontent';
import config from '~/config';

const Phucap = () => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [showEditPhucap, setShowEditPhucap] = useState(false);

    const [dataTmp, setDataTmp] = useState([]);
    const [payload, setPayload] = useState();
    const [listTrangthaiSelect, setlistTrangthaiSelect] = useState([]);
    const { phucap } = useSelector((state) => state.phucap);
    // console.log('phucap', phucap)
    const [data, setData] = useState(phucap);
    const fieldList = {
        title: '',
        mota: '',
        loaiphucap: '',
        maphucap: '',
        apdungdongbaohiem: false,
        apdungdongthue: false,
        TrangthaiId: '',
    };
    useEffect(() => {
        dispatch(actions.getListPhucap());
    }, []);
    useEffect(() => {
        if (phucap && phucap.length > 0) {
            setData([...phucap]);
            setDataTmp([...phucap]);
            let TrangthaiId = dataSelectActive.map((item) => ({ label: item.label, value: item.value }));
            setlistTrangthaiSelect(TrangthaiId);
        }
    }, [phucap]);

    const [showPhanquyen, setShowPhanquyen] = useState({});
    let dataPermissionUtils = PermissionUtils('phucap', 'all');
    useEffect(() => {
        if (dataPermissionUtils) {
            setShowPhanquyen(dataPermissionUtils);
        }
    }, [dataPermissionUtils]);

    const namefile = 'Thông tin phụ cấp';
    const header = ['Stt', 'Phụ cấp', 'Mã phụ cấp', 'Trạng thái', 'Đóng bảo hiểm'];
    const [columns, setColumns] = useState(() => {
        const d = header.map((item, index) => index <= 6);
        return d;
    });
    const [dataFileMau, setDataFileMau] = useState([]);
    // console.log('data', data);
    useEffect(() => {
        if (data.length > 0) {
            const datafilemau = data.map((item, index) => {
                return {
                    ...(columns[0] && { Stt: index + 1 }),
                    ...(columns[1] && { 'Phụ cấp': item?.title }),
                    ...(columns[2] && { 'Mã phụ cấp': item?.maphucap }),
                    ...(columns[3] && { 'Trạng thái': item?.TrangthaiId === 1 ? 'Hoạt động' : 'Không hoạt động' }),
                    ...(columns[4] && { 'Đóng bảo hiểm': item?.apdungdongbaohiem === true ? 'Có' : 'Không' }),
                };
            });
            setDataFileMau(datafilemau);
        }
    }, [data, columns]);

    const [sm, updateSm] = useState(false);
    const [tablesm, updateTableSm] = useState(false);
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [sort, setSortState] = useState('');
    // onChange function for searching name
    const onFilterChange = (e) => {
        setSearchText(e.target.value);
    };
    const toggle = () => setonSearch(!onSearch);
    const selectorCheck = (e) => {
        let newData;
        newData = data.map((item) => {
            item.checked = e.currentTarget.checked;
            return item;
        });
        setData([...newData]);
    };
    const onSelectChange = (e, id) => {
        let newData = data;
        let index = newData.findIndex((item) => item.id === id);
        newData[index].checked = e.currentTarget.checked;
        setData([...newData]);
    };
    useEffect(() => {
        let filteredObject = [];

        if (onSearchText !== '') {
            filteredObject = dataTmp.filter((item) => {
                return (
                    item.title.toLowerCase().includes(onSearchText.toLowerCase()) 
                );
            });
            setData([...filteredObject]);
        } else {
            switch (true) {
                case payload?.TrangthaiId?.value !== undefined:
                    let stId = payload?.TrangthaiId?.value;
                    filteredObject = dataTmp.filter((item) => Number(item.TrangthaiId) === stId);
                    break;
                default:
                    filteredObject = dataTmp;
                    break;
            }
            setData([...filteredObject]);
        }
    }, [onSearchText, setData, payload]);
    // Get current list, pagination
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    useEffect(() => {
        (phucap && setShow(false)) || setShowEditPhucap(false);
    }, [phucap]);
    const handleAddNewPhucap = async () => {
        setShow(true);
    };
    const [dataIdPhucap, setDataIdPhucap] = useState([]);
    const handleEditPhucap = async (data) => {
        setShowEditPhucap(true);
        await setDataIdPhucap(data);
    };
    const handleDeletePhucap = (data) => {
        Swal.fire({
            title: 'Bạn có chắc không?',
            text: 'Bạn sẽ không thể lấy lại khi xóa!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Không, bỏ qua!',
            confirmButtonText: 'Vâng, xóa nó đi!',
        }).then((result) => {
            if (result.isConfirmed) {
                const abc = dispatch(actions.DeletePhucap(data));
                console.log('abc', abc);
            }
        });
    };
    return (
        <React.Fragment>
            <Head title="Lương - Phụ Cấp - Thưởng"></Head>
            <Content>
                <Card className="">
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner card-inner-lg px-4 mt-3">
                            <h4>Lương - Phụ Cấp - Thưởng</h4>
                            <MenuLuongCapThuong />
                        </div>
                    </DataTable>
                </Card>
                <Block>
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner position-relative card-tools-toggle pt-0">
                            <div className="card-title-group row">
                                <div className="card-title col-7">
                                    <h5 className="title">Phụ cấp</h5>
                                    {+showPhanquyen?.them === 1 && (
                                        <Button
                                            color="primary"
                                            className="btn-icon px-4 mt-2"
                                            onClick={handleAddNewPhucap}
                                        >
                                            <Icon name="plus"></Icon> Thêm mới
                                        </Button>
                                    )}
                                </div>
                                <div className="col-5 card-tools me-n1">
                                    <ul className="btn-toolbar gx-1 float-end">
                                        <li>
                                            <a
                                                href="#search"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    toggle();
                                                }}
                                                className="btn btn-icon search-toggle toggle-search"
                                            >
                                                <Icon name="search"></Icon>
                                            </a>
                                        </li>
                                        <li className="btn-toolbar-sep"></li>
                                        <li>
                                            <div className="toggle-wrap">
                                                <Button
                                                    className={`btn-icon btn-trigger toggle ${tablesm ? 'active' : ''}`}
                                                    onClick={() => updateTableSm(true)}
                                                >
                                                    <Icon name="menu-right"></Icon>
                                                </Button>
                                                <div className={`toggle-content ${tablesm ? 'content-active' : ''}`}>
                                                    <ul className="btn-toolbar gx-1">
                                                        <li className="toggle-close">
                                                            <Button
                                                                className="btn-icon btn-trigger toggle"
                                                                onClick={() => updateTableSm(false)}
                                                            >
                                                                <Icon name="arrow-left"></Icon>
                                                            </Button>
                                                        </li>
                                                        <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle
                                                                    tag="a"
                                                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                                                >
                                                                    <div className="dot dot-primary"></div>
                                                                    <Icon name="filter-alt"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu
                                                                    end
                                                                    className="filter-wg dropdown-menu-xl"
                                                                    style={{ overflow: 'visible' }}
                                                                >
                                                                    <div className="dropdown-head">
                                                                        <span className="sub-title dropdown-title">
                                                                            Bộ lọc
                                                                        </span>
                                                                        <div className="dropdown">
                                                                            <a
                                                                                href="#more"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                }}
                                                                                className="btn btn-sm btn-icon"
                                                                            >
                                                                                <Icon name="more-h"></Icon>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    <div className="dropdown-body dropdown-body-rg">
                                                                        <Row className="gx-6 gy-3">
                                                                            <Col size="12">
                                                                                <div className="form-group">
                                                                                    <label className="overline-title overline-title-alt">
                                                                                        Trạng thái
                                                                                    </label>
                                                                                    <RSelect
                                                                                        // value={payload.TrangthaiId}
                                                                                        options={listTrangthaiSelect}
                                                                                        onChange={(selected) => {
                                                                                            setPayload([]);
                                                                                            setPayload((prev) => ({
                                                                                                ...prev,
                                                                                                ['TrangthaiId']:
                                                                                                    selected,
                                                                                            }));
                                                                                        }}
                                                                                        placeholder="Trạng thái"
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                    <div className="dropdown-foot between">
                                                                        <a
                                                                            href="#reset"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();
                                                                            }}
                                                                            className="clickable"
                                                                        >
                                                                            Cập nhật bộ lọc
                                                                        </a>
                                                                        <a
                                                                            href="#save"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();
                                                                            }}
                                                                        >
                                                                            Lưu bộ lọc
                                                                        </a>
                                                                    </div>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                        <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle
                                                                    color="tranparent"
                                                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                                                >
                                                                    <Icon name="setting"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu end className="dropdown-menu-xs">
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Show</span>
                                                                        </li>
                                                                        <li
                                                                            className={
                                                                                itemPerPage === 10 ? 'active' : ''
                                                                            }
                                                                        >
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setItemPerPage(10);
                                                                                }}
                                                                            >
                                                                                10
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li
                                                                            className={
                                                                                itemPerPage === 15 ? 'active' : ''
                                                                            }
                                                                        >
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setItemPerPage(15);
                                                                                }}
                                                                            >
                                                                                15
                                                                            </DropdownItem>
                                                                        </li>
                                                                    </ul>
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Order</span>
                                                                        </li>
                                                                        <li className={sort === 'dsc' ? 'active' : ''}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setSortState('dsc');
                                                                                    //   sortFunc("dsc");
                                                                                }}
                                                                            >
                                                                                DESC
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li className={sort === 'asc' ? 'active' : ''}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setSortState('asc');
                                                                                    //   sortFunc("asc");
                                                                                }}
                                                                            >
                                                                                ASC
                                                                            </DropdownItem>
                                                                        </li>
                                                                    </ul>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                        <li>
                                                            <div className="toggle-wrap nk-block-tools-toggle">
                                                                <Button
                                                                    className={`btn-icon btn-trigger toggle-expand me-n1 ${
                                                                        sm ? 'active' : ''
                                                                    }`}
                                                                    onClick={() => updateSm(!sm)}
                                                                >
                                                                    <Icon name="upload-cloud"></Icon>
                                                                </Button>
                                                                <div
                                                                    className="toggle-expand-content-cloud"
                                                                    style={{ display: sm ? 'block' : 'none' }}
                                                                >
                                                                    <ul className="nk-block-tools g-3">
                                                                        <li className="nk-block-tools-opt">
                                                                            <ExportXlsx
                                                                                dataFileMau={dataFileMau}
                                                                                title={header}
                                                                                namefile={namefile}
                                                                                btnname={'Xuất dữ liệu'}
                                                                            />
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-12 ">
                                    <Link
                                        className="alert-link"
                                        to={`${config.he_thong}${config.cau_hinh_tham_nien}`}
                                    >
                                        <Alert className="alert-icon mt-2" style={{ zIndex: -1 }} color="warning">
                                        {/* <Alert className="alert-icon mt-2" color="warning"> */}
                                            <Icon name="alert-circle" />
                                            Bạn không được thiết lập phụ cấp thâm niên tại đây. Vui lòng thiết lập{' '}
                                            <a className='success'>Tại đây</a>
                                        </Alert>
                                    </Link>
                                </div>
                            </div>
                            <div className={`card-search search-wrap ${!onSearch && 'active'}`}>
                                <div className="card-body">
                                    <div className="search-content">
                                        <Button
                                            className="search-back btn-icon toggle-search active"
                                            onClick={() => {
                                                setSearchText('');
                                                toggle();
                                            }}
                                        >
                                            <Icon name="arrow-left"></Icon>
                                        </Button>
                                        <input
                                            type="text"
                                            className="border-transparent form-focus-none form-control"
                                            placeholder="Tìm kiếm theo tên và mô tả"
                                            value={onSearchText}
                                            onChange={(e) => onFilterChange(e)}
                                        />
                                        <Button className="search-submit btn-icon">
                                            <Icon name="search"></Icon>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ overflowY: 'hidden' }}>
                            <DataTableBody>
                                <DataTableHead>
                                    {columns[0] && (
                                        <DataTableRow>
                                            <span className="sub-text">Stt</span>
                                        </DataTableRow>
                                    )}
                                    <DataTableRow className="nk-tb-col-check">
                                        <div className="custom-control custom-control-sm custom-checkbox notext">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                onChange={(e) => selectorCheck(e)}
                                                id="uid"
                                            />
                                            <label className="custom-control-label" htmlFor="uid"></label>
                                        </div>
                                    </DataTableRow>
                                    {columns[1] && (
                                        <DataTableRow>
                                            <span className="sub-text w-150px">Phụ cấp</span>
                                        </DataTableRow>
                                    )}
                                    {columns[2] && (
                                        <DataTableRow>
                                            <span className="sub-text w-150px">Mã Phụ Cấp</span>
                                        </DataTableRow>
                                    )}
                                    {columns[3] && (
                                        <DataTableRow>
                                            <span className="sub-text w-100px">Trạng thái</span>
                                        </DataTableRow>
                                    )}
                                    {columns[4] && (
                                        <DataTableRow>
                                            <span className="sub-text w-150px">Đóng bảo hiểm</span>
                                        </DataTableRow>
                                    )}
                                    <DataTableRow className="nk-tb-col-tools text-end">
                                        <UncontrolledDropdown>
                                            <DropdownToggle
                                                color="tranparent"
                                                className="btn btn-xs btn-outline-light btn-icon dropdown-toggle"
                                            >
                                                <Icon name="plus"></Icon>
                                            </DropdownToggle>
                                            <DropdownMenu end className="dropdown-menu-xs">
                                                <ul className="link-tidy sm no-bdr">
                                                    {header.length > 0 ? (
                                                        header.map((item, index) => (
                                                            <li key={index}>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="custom-control-input"
                                                                        id={`column-${index}`}
                                                                        checked={columns[index]}
                                                                        onChange={(e) =>
                                                                            setColumns((prev) => {
                                                                                const newColumns = [...prev];
                                                                                newColumns[index] = e.target.checked;
                                                                                return newColumns;
                                                                            })
                                                                        }
                                                                    />
                                                                    <label
                                                                        className="custom-control-label"
                                                                        htmlFor={`column-${index}`}
                                                                    >
                                                                        {item}
                                                                    </label>
                                                                </div>
                                                            </li>
                                                        ))
                                                    ) : (
                                                        <li>No data</li>
                                                    )}
                                                </ul>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </DataTableRow>
                                </DataTableHead>
                                {currentItems.length > 0
                                    ? currentItems.map((item, index) => {
                                          return (
                                              <DataTableItem key={item.id}>
                                                  {columns[0] && (
                                                      <DataTableRow size=" nk-tb-col-check mb">
                                                          <span className="tb-amount">{index + 1}</span>
                                                      </DataTableRow>
                                                  )}
                                                  <DataTableRow className="nk-tb-col-check">
                                                      <div className="custom-control custom-control-sm custom-checkbox notext">
                                                          <input
                                                              type="checkbox"
                                                              className="custom-control-input"
                                                              defaultChecked={item.checked}
                                                              id={item.id + 'uid1'}
                                                              key={Math.random()}
                                                              onChange={(e) => onSelectChange(e, item.id)}
                                                          />
                                                          <label
                                                              className="custom-control-label"
                                                              htmlFor={item.id + 'uid1'}
                                                          ></label>
                                                      </div>
                                                  </DataTableRow>
                                                  {columns[1] && (
                                                      <DataTableRow>
                                                          <span className="tb-amount">{item?.title}</span>
                                                      </DataTableRow>
                                                  )}
                                                  {columns[2] && (
                                                      <DataTableRow>
                                                          <span className="tb-amount">{item?.maphucap}</span>
                                                      </DataTableRow>
                                                  )}
                                                  {columns[3] && (
                                                      <DataTableRow>
                                                          <span
                                                              className={`${
                                                                  item?.TrangthaiId === 1 ? 'bg-success' : 'bg-danger'
                                                              } ms-0 badge text-white`}
                                                          >
                                                              {item?.TrangthaiId === 1
                                                                  ? 'Hoạt động'
                                                                  : 'Không hoạt động'}
                                                          </span>
                                                      </DataTableRow>
                                                  )}
                                                  {columns[4] && (
                                                      <DataTableRow>
                                                          <span
                                                              className={`${
                                                                  item?.apdungdongbaohiem === true
                                                                      ? 'bg-success'
                                                                      : 'bg-danger'
                                                              } ms-0 badge text-white`}
                                                          >
                                                              {item?.apdungdongbaohiem === true ? 'Có' : 'Không'}
                                                          </span>
                                                      </DataTableRow>
                                                  )}
                                                  <DataTableRow className="nk-tb-col-tools">
                                                      <ul className="nk-tb-actions gx-1">
                                                          {+showPhanquyen?.sua === 1 && (
                                                              <li
                                                                  className="nk-tb-action-hidden"
                                                                  onClick={() => handleEditPhucap(item)}
                                                              >
                                                                  <TooltipComponent
                                                                      tag="a"
                                                                      containerClassName="btn btn-trigger btn-icon"
                                                                      id={'edit' + item.id}
                                                                      icon="edit-alt-fill"
                                                                      direction="top"
                                                                      text="Sửa"
                                                                  />
                                                              </li>
                                                          )}
                                                          {+showPhanquyen?.xoa === 1 && (
                                                              <React.Fragment>
                                                                  <li
                                                                      className="nk-tb-action-hidden"
                                                                      onClick={() => handleDeletePhucap(item)}
                                                                  >
                                                                      <TooltipComponent
                                                                          tag="a"
                                                                          containerClassName="btn btn-trigger btn-icon"
                                                                          id={'suspend' + item.id}
                                                                          icon="user-cross-fill"
                                                                          direction="top"
                                                                          text="Xóa"
                                                                      />
                                                                  </li>
                                                              </React.Fragment>
                                                          )}
                                                          <li>
                                                              <UncontrolledDropdown>
                                                                  {(+showPhanquyen?.sua === 1 ||
                                                                      +showPhanquyen?.xoa === 1) && (
                                                                      <DropdownToggle
                                                                          tag="a"
                                                                          className="dropdown-toggle btn btn-icon btn-trigger"
                                                                      >
                                                                          <Icon name="more-h"></Icon>
                                                                      </DropdownToggle>
                                                                  )}
                                                                  <DropdownMenu end>
                                                                      <ul className="link-list-opt no-bdr">
                                                                          {+showPhanquyen?.sua === 1 && (
                                                                              <li
                                                                                  onClick={() => handleEditPhucap(item)}
                                                                              >
                                                                                  <DropdownItem
                                                                                      tag="a"
                                                                                      href="#edit"
                                                                                      onClick={(ev) => {
                                                                                          ev.preventDefault();
                                                                                      }}
                                                                                  >
                                                                                      <Icon name="edit"></Icon>
                                                                                      <span>Sửa</span>
                                                                                  </DropdownItem>
                                                                              </li>
                                                                          )}
                                                                          {+showPhanquyen?.xoa === 1 && (
                                                                              <React.Fragment>
                                                                                  <li className="divider"></li>
                                                                                  <li
                                                                                      onClick={() =>
                                                                                          handleDeletePhucap(item)
                                                                                      }
                                                                                  >
                                                                                      <DropdownItem
                                                                                          tag="a"
                                                                                          href="#suspend"
                                                                                          onClick={(ev) => {
                                                                                              ev.preventDefault();
                                                                                          }}
                                                                                      >
                                                                                          <Icon name="na"></Icon>
                                                                                          <span>Xóa</span>
                                                                                      </DropdownItem>
                                                                                  </li>
                                                                              </React.Fragment>
                                                                          )}
                                                                      </ul>
                                                                  </DropdownMenu>
                                                              </UncontrolledDropdown>
                                                          </li>
                                                      </ul>
                                                  </DataTableRow>
                                              </DataTableItem>
                                          );
                                      })
                                    : null}
                            </DataTableBody>
                        </div>
                        <div className="card-inner">
                            {currentItems.length > 0 ? (
                                <PaginationComponent
                                    itemPerPage={itemPerPage}
                                    totalItems={phucap.length}
                                    paginate={paginate}
                                    currentPage={currentPage}
                                />
                            ) : (
                                <div className="text-center">
                                    <div>
                                        <Spinner color="primary" />
                                    </div>
                                    <span className="text-silent">Chưa có dữ liệu!</span>
                                </div>
                            )}
                        </div>
                    </DataTable>
                </Block>

                <ModalPhucap show={show} fieldList={fieldList} setShow={() => setShow(false)} />
                <ModalEditPhucap
                    show={showEditPhucap}
                    fieldList={fieldList}
                    setShow={() => setShowEditPhucap(false)}
                    dataIdPhucap={dataIdPhucap}
                />
            </Content>
        </React.Fragment>
    );
};

export default Phucap;
