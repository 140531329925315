import actionTypes from "../actions/actionTypes";
const initState = {
    chinhanh: [],
    msg: '',
    count: 0,
    newchinhanh: []
}

const chinhanhReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.GET_LIST_CHI_NHANH:
            return {
                ...state,
                chinhanh: action?.chinhanh?.rows || [],
                msg: 'Ok' || '',
                count: action?.chinhanh?.count || 0
            }
        case actionTypes.POST_NEW_CHI_NHANH:
        case actionTypes.PUT_EDIT_CHI_NHANH:
        case actionTypes.DELETE_CHI_NHANH:
            return {
                ...state,
                msg: action.msg || '',
                newchinhanh: action?.newchinhanh || []
            }
        default:
            return state;
    }

}

export default chinhanhReducer