import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Button, InputForm} from '~/components/Form'
import * as actions from '~/store/actions'

import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import {dataSelectStatus} from "~/utils/Datacustom";
import {validateCauHinhNghiLe} from "~/request/validateCauHinhNghiLe";
import Textarea from "~/components/Form/Textarea";

const ModalEditCauhinhnghile = ({show, setShow, dataIdCauhinhnghile, fieldList}) => {
    // console.log('dataIdCauhinhnghile', dataIdCauhinhnghile)
    const {phongban} = useSelector(state => state.phongban)
    const {nhanvien} = useSelector((state) => state.nhanvien);
    // console.log(phongban)
    const dispatch = useDispatch()
    const [invalidFields, setInvalidFields] = useState([])
    const [payload, setPayload] = useState(fieldList)
    const [listPhongban, setlistPhongban] = useState([])
    const [listNhanvien, setListnhanvien] = useState([]);
    useEffect(() => {
        dispatch(actions.getListNhanvien());
    }, []);
    useEffect(() => {
        // setlistPhongban(
        //     dataIdCauhinhnghile?.Phongbans?.map((item) => ({
        //         value: item.id,
        //         label: item.phongban,
        //     })),
        // );
        
        
        let PhongbanId = dataIdCauhinhnghile?.Phongbans?.map((item) => ({
            value: item.id,
            label: item.phongban,
        }))
        let TrangthaiId = dataSelectStatus?.find(item => {
            return item && item.value === Number(dataIdCauhinhnghile?.TrangthaiId)
        })
        // console.log('TrangthaiId',TrangthaiId)
        show && dataIdCauhinhnghile && setPayload({
            tieude: dataIdCauhinhnghile.tieude,
            mota: dataIdCauhinhnghile.mota,
            ngaybatdau: dataIdCauhinhnghile.ngaybatdau,
            ngayketthuc: dataIdCauhinhnghile.ngayketthuc,
            // giobatdau: dataIdCauhinhnghile.giobatdau,
            // gioketthuc: dataIdCauhinhnghile.gioketthuc,
            apdungtinhluong: dataIdCauhinhnghile.apdungtinhluong,
            PhongbanId: PhongbanId,
            TrangthaiId: {label: TrangthaiId?.label, value: TrangthaiId?.value},
            
        })
    }, [dataIdCauhinhnghile, show])
    
    
    useEffect(() => {
        if (phongban) {
            let dataSelectPhongban = phongban.map((item) => ({label: item.phongban, value: item.id}));
            setlistPhongban(dataSelectPhongban)
        }
        if (nhanvien) {
            setListnhanvien(nhanvien.filter(i => Number(i.TrangthaiId) === 1));
        }
    }, [phongban, nhanvien])
    const handleSubmit = async () => {
        let invalids = validate(payload)
        if (invalids === 0) {
            let listNhanvienbyphongban = [];
            let listPhongban = [];
            const dataPhongban = payload.PhongbanId;
            if (dataPhongban) {
                dataPhongban.forEach((phongban) => {
                    listNhanvien.filter((i) => i.PhongbanId === phongban.value).map(item => listNhanvienbyphongban.push(item.id));
                });
                listPhongban = dataPhongban.map(item => item.value)
            }
            let datapayload = {
                data: [
                    {
                        id: dataIdCauhinhnghile.id,
                        tieude: payload.tieude,
                        ngaybatdau: payload.ngaybatdau,
                        ngayketthuc: payload.ngayketthuc,
                        // giobatdau: payload.giobatdau,
                        // gioketthuc: payload.gioketthuc,
                        NhanvienId: listNhanvienbyphongban,
                        PhongbanId: listPhongban,
                        Khaibaocalamviec: payload.Khaibaocalamviec,
                        mota: payload.mota,
                        apdungtinhluong: payload.apdungtinhluong === true ? '1' : '0',
                        TrangthaiId: payload.TrangthaiId.value,
                    },
                ],
                
            }
            dispatch(actions.EditCauhinhnghile(datapayload))
        }
    }
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayload(prev => ({...prev, [statename]: selected}))
    }
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateCauHinhNghiLe(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateCauHinhNghiLe(item[0], item[1])]);
                invalids++;
            }
        });
        
        return invalids;
    };
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Chỉnh sửa
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-12">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Tiêu đề'}
                            value={payload.tieude}
                            setValue={setPayload}
                            keyPayload={'tieude'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-12">
                        <Textarea
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Nội dung mô tả'}
                            value={payload.mota}
                            setValue={setPayload}
                            keyPayload={'mota'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-9">
                        <label htmlFor="inputZip" className="mb-2">
                            Phòng ban <b className="text-danger">(*)</b>
                        </label>
                        <Select
                            value={payload.PhongbanId}
                            onChange={handleOnSelect}
                            isMulti
                            options={listPhongban}
                            placeholder={'Chọn phòng ban'}
                            name="PhongbanId"
                            onFocus={() => setInvalidFields([])}
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'PhongbanId') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'PhongbanId')?.message}
                            </small>
                        )}
                    </div>
                    <div className="form-group col-md-3">
                        <label htmlFor="inputZip" className="mb-2">
                            Trạng thái <b className="text-danger">(*)</b>
                        </label>
                        <Select
                            value={payload.TrangthaiId}
                            onChange={handleOnSelect}
                            options={dataSelectStatus}
                            placeholder={'Chọn trạng thái'}
                            name="TrangthaiId"
                            onFocus={() => setInvalidFields([])}
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'TrangthaiId') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'TrangthaiId')?.message}
                            </small>
                        )}
                    </div>
                    <div className="form-group col-md-3 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ngày bắt đầu'}
                            value={payload.ngaybatdau}
                            setValue={setPayload}
                            keyPayload={'ngaybatdau'}
                            type="date"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ngày kết thúc'}
                            value={payload.ngayketthuc}
                            setValue={setPayload}
                            keyPayload={'ngayketthuc'}
                            type="date"
                            validate
                        />
                    </div>
                    <div className="form-group col-lg-12">
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            defaultChecked
                                            id="apdungtinhluong"
                                            name="apdungtinhluong"
                                            onChange={(e) =>
                                                setPayload((prev) => ({
                                                    ...prev,
                                                    ['apdungtinhluong']: e.target.checked,
                                                }))
                                            }
                                            checked={payload?.apdungtinhluong}
                                        />
                                        <label className="custom-control-label" htmlFor="apdungtinhluong">
                                        Áp dụng tính lương lễ
                                        </label>
                                    </div>
                                </div>
                    {/* <div className="form-group col-md-3 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Giờ bắt đầu'}
                            value={payload.giobatdau}
                            setValue={setPayload}
                            keyPayload={'giobatdau'}
                            type="time"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Giờ kết thúc'}
                            value={payload.gioketthuc}
                            setValue={setPayload}
                            keyPayload={'gioketthuc'}
                            type="time"
                            validate
                        />
                    </div> */}
    
    
                </div>
                
                <div className='col-12 mt-5'>
                    <Button
                        text='Cập nhật'
                        bClass='btn btn-primary px-5'
                        textColor
                        onClick={handleSubmit}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ModalEditCauhinhnghile;

