import React, {useState, useEffect, useMemo} from 'react';
import {ModalVanthu, ModalEditVanthu} from './Modal';
import Swal from 'sweetalert2';
import {
    Card,
} from 'reactstrap';
import Content from '~/layout/content/Content';
import {Block} from '~/components/block/Block';
import {
    Button,
    DataTable,
    Icon,
} from '~/components/Component';
import {dataSelectLoaiVanThu} from "~/utils/Datacustom";
import {apiDeleteVanthu, apiGetVanthu} from "~/services";
import {toast} from "react-toastify";
import {Box, IconButton, Tooltip} from "@mui/material";
import {ExportToCsv} from "export-to-csv";
import {MaterialReactTable, useMaterialReactTable} from "material-react-table";
import {MRT_Localization_VI} from "material-react-table/locales/vi";
import {Delete as DeleteIcon, Edit as EditIcon} from "@mui/icons-material";
//
const Vanthu = () => {
    const [data, setData] = useState([]);
    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    
    async function fetchData() {
        try {
            const datapayloads = {
                typeGet: 1, // 1: lấy theo điều kiện phân trang, 2: lấy tất cả và không cần truyền d liệu phân trang
                start: pagination.pageIndex || 0,
                size: pagination.pageSize || 10,
                globalFilter: 'id',
                sorting: 'desc',
            };
            const response = await apiGetVanthu(datapayloads);
            // const response = resData.data.data.rows;
            if (response?.data?.success === 200) {
                setData(response?.data?.data?.rows);
                setTotalRowCount(response?.data?.data?.count);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    
    useEffect(() => {
        fetchData().then(r => 'err');
    }, [pagination.pageIndex, pagination.pageSize, show, showEdit]);

    const fieldList = {
        LoaivanthuId: '',
        mavanthu: '',
        douutien: '',
        ngaygui: '',
        ngaynhan: '',
        nguoigui: '',
        nguoinhan: '',
        ghichu: '',
        chiphi: '',
        TrangthaiId: '',
    };
    // set state để mở đóng Modal
    const handleAddNew = async () => {
        setShow(true);
    };
    const [dataIdVanthu, setDataIdVanthu] = useState([]);
    const handleEdit = async (data) => {
        setShowEdit(true);
        await setDataIdVanthu(data);
    };
    const handleDelete = (data) => {
        Swal.fire({
            title: 'Bạn có chắc không?',
            text: 'Bạn sẽ không thể lấy lại khi xóa!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Không, bỏ qua!',
            confirmButtonText: 'Vâng, xóa nó đi!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const response = await apiDeleteVanthu(data);
                // console.log('chek api apiDeleteVanthu', response?.data.success)
                if (response?.data.success === 200) {
                    fetchData().then(r => 'err');
                    toast.success("Xóa thông tin văn thư thành công!")
                } else {
                    toast.error("Xóa thông tin văn thư thất bại!")
                }
                
            }
        });
    };
    const Example = () => {
        
        let dataAb = data;
        console.log('dataAb', dataAb)
        // Tạo dữ liêu export
        const formatCurrency = (value) => (
            (Number(value) || 0).toLocaleString('vi-VN', {style: 'currency', currency: 'VND'})
        );
        const getCat = (value) => ( // Lấy giá trị của LoaivanthuId
            dataSelectLoaiVanThu.find((item) => item.value === value)?.label
        );
        const columns = useMemo(
            () => [
                {
                    Header: '#', // Tiêu đề cột số thứ tự
                    header: 'STT', // Tiêu đề cột số thứ tự
                    id: 'rowNumber', // ID của cột
                    size: 50,
                    Cell: ({row}) => <div>{row?.index + 1}</div>, // Giá trị của cột là số thứ tự
                    enableSorting: false,
                    enablePinning: true,
                },
                {
                    accessorKey: 'LoaivanthuId',
                    Cell: ({cell}) => (
                        <Box
                            component="span"
                            sx={(theme) => ({
                                backgroundColor: cell?.getValue() === 1 ? '#1ee0ac' : '#e65100',
                                fontSize: '10px',
                                borderRadius: '0.25rem',
                                color: '#fff',
                                p: '0.25rem',
                            })}
                        >
                            {getCat(cell.getValue() || 0)}
                        </Box>
                    ),
                    
                    enablePinning: true,
                    header: 'Loại văn thư',
                },
                {
                    accessorFn: (row) => <p><span>Người gửi: {row.nguoigui || 'Trống'}</span><br/><span
                        className={'text-primary'}>{row.ngaygui}</span></p>,
                    enablePinning: true,
                    header: 'Thông tin gửi',
                },
                {
                    accessorFn: (row) => <p><span>Người nhận: {row.nguoinhan || 'Trống'}</span><br/><span
                        className={'text-primary'}>{row.ngaynhan}</span></p>,
                    enablePinning: true,
                    header: ' Thông tin nhận',
                },
                {
                    accessorKey: 'chiphi',
                    enablePinning: true,
                    Cell: ({cell}) => formatCurrency(cell.getValue() || 0),
                    header: 'Chi phí',
                },
                {
                    accessorKey: 'TrangthaiId',
                    header: 'Tình trạng',
                    enablePinning: true,
                    Cell: ({cell}) => (
                        <Box
                            component="span"
                            sx={(theme) => ({
                                backgroundColor: cell?.getValue() === 1 ? '#1ee0ac' : '#e65100',
                                fontSize: '10px',
                                borderRadius: '0.25rem',
                                color: '#fff',
                                p: '0.25rem',
                            })}
                        >
                            {cell?.getValue() === 1 ? 'Hoàn thành' : 'Chưa hoàn thành'}
                        </Box>
                    ),
                },
            
            
            ],
            [],
        );
        const csvOptions = { fileName: 'csv-data',
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            useBom: true,
            useKeysAsHeaders: false,
            headers: columns.map((c) => c.header),
        };
        
        
        const csvExporter = new ExportToCsv(csvOptions);
        
        const handleExportData = () => {
            
            const dataExport = dataAb?.map((item, indexx) => {
                let abc = {}
                abc.rowNumber = indexx + 1
                abc.LoaivanthuId = item?.LoaivanthuId
                abc.nguoigui = item?.nguoigui
                abc.ngaynhan = item?.ngaynhan
                abc.chiphi = item?.chiphi
                abc.TrangthaiId = item?.TrangthaiId
                
                return abc
            })
            if (dataExport.length === 0) {
                toast.error("Không có dữ liệu để xuất!")
                return;
            }
            csvExporter.generateCsv(dataExport);
        };
        
        const table = useMaterialReactTable({
                columns,
                data,
                enableColumnResizing: false,
                enableDensityToggle: false,
                initialState: {
                    columnPinning: {left: ['rowNumber', 'title'], right: ['mrt-row-actions']},
                    density: 'compact'
                },
                
                state: {
                    expanded: true,
                    columnFilters,
                    globalFilter,
                    isLoading: dataAb.length === 0,
                    pagination,
                    sorting,
                },
                displayColumnDefOptions: {
                    'mrt-row-actions': {
                        sx: {width: '200px', paddingRight: '10px'},
                        header: 'Thao tác',
                        enableOrdering: true,
                        enablePinning: true,
                        enableColumnActions: true,
                    },
                },
                defaultDisplayColumn: {enableResizing: true},
                rowCount: totalRowCount,
                manualFiltering: true,
                manualPagination: true,
                manualSorting: true,
                onPaginationChange: setPagination,
                onColumnFiltersChange: setColumnFilters,
                onGlobalFilterChange: setGlobalFilter,
                onSortingChange: setSorting,
                localization: MRT_Localization_VI,
                positionActionsColumn: 'first',
                enableRowActions: true,
                enableColumnPinning: true,
                renderTopToolbarCustomActions: ({table}) => (
                    <Box sx={{display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap'}}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddNew}
                            sx={{mr: 2}}
                        >
                            + Thêm mới
                        </Button>
                        <Button
                            color="primary"
                            onClick={handleExportData}
                            variant="contained"
                        >
                            <Icon name="download-cloud me-2"></Icon> Xuất dữ liệu
                        </Button>
                    </Box>
                ),
                renderRowActions: ({row, table}) => (
                    <Box sx={{display: 'flex', gap: '1rem'}}>
                        <Tooltip title="Edit">
                            <IconButton onClick={() => handleEdit(row.original)}>
                                <EditIcon/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <IconButton color="error" onClick={() => handleDelete({
                                id: row.original.id,
                                status: row.original.TrangthaiId
                            })}>
                                <DeleteIcon/>
                            </IconButton>
                        </Tooltip>
                    
                    </Box>
                ),
            }
        );
        
        
        return (
            <MaterialReactTable
                table={table}
            />
        );
    };
    
    return (
        <React.Fragment>
            {/*<Head title="User List - Regular"></Head>*/}
            <Content>
                <Card className="mt-0">
                    <div className="card-aside-wrap row">
                        <div className="card-inner card-inner-lg px-4 pt-1 pb-0">
                            <h4>Quản lý văn thư</h4>
                        
                        </div>
                    </div>
                </Card>
                <Block>
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner position-relative card-tools-toggle">
                            <Example/>
                        </div>
                    </DataTable>
                </Block>
                
                <ModalVanthu show={show} fieldList={fieldList} setShow={() => setShow(false)}/>
                <ModalEditVanthu
                    show={showEdit}
                    fieldList={fieldList}
                    setShow={() => setShowEdit(false)}
                    dataIdVanthu={dataIdVanthu}
                />
            </Content>
        </React.Fragment>
    );
};

export default Vanthu;
