import React, {useEffect, useState} from 'react';
import Content from '~/layout/content/Content';
import {Card} from 'reactstrap';
import {
    Button,
    Icon,
} from '~/components/Component';
import './chitietnhanvien.scss';
import {
    apiDataBienDongNhanSu, apiDataBienDongNhanSuByChucVu, apiDataChiPhiNhanSu,
    apiDataChuyenCan, apiDataDaoTaoNhanSu, apiDataThamNien, apiDataThuNhapBQ,
    apiDataTongQuat,
    apiDataTuyenDung,
} from "~/services";
// import 'jspdf-autotable';
import {format, parseISO} from "date-fns";
import {InputForm} from "~/components/Form";
import {dataSelectHocVan} from "~/utils/Datacustom";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {toast} from "react-toastify";
import {ComponentThamNien} from "~/pages/Admin/Modal/Baocao/ComponentThamNien";
import {
    ComponentBienDongNhanSuTongQuan
} from "~/pages/Admin/Modal/Baocao/ComponentBienDongNhanSu";
import {ComponentBienDongNhanSuByChucVu} from "~/pages/Admin/Modal/Baocao/ComponentBienDongNhanSuByChucVu";
import {ComponentDaoTaoNhanSuTop} from "~/pages/Admin/Modal/Baocao/ComponentDaoTaoNhanSuTop";
import {ComponentThuNhapBinhQuan} from "~/pages/Admin/Modal/Baocao/ComponentThuNhapBinhQuan";
import {ComponentChiPhiNhanSu} from "~/pages/Admin/Modal/Baocao/ComponentChiPhiNhanSu";
import {ComponentChuyenCan} from "~/pages/Admin/Modal/Baocao/ComponentChuyenCan";
import {ComponentTongQuat} from "~/pages/Admin/Modal/Baocao/ComponentTongQuat";
import {ComponentTuyenDung} from "~/pages/Admin/Modal/Baocao/ComponentTuyenDung";
// import jsPDF from "jspdf";


const Baocao = () => {
        const [modalTab, setModalTab] = useState('1');
        // Tab tuyển dụng
        const [invalidFields, setInvalidFields] = useState([])
        const [data, setData] = useState([]);
        
        const [payload, setPayload] = useState({ngaybatdau: '', ngayketthuc: '', reportType: ''})
        
        function getMonthYear(dataDate) {
            const date = new Date(dataDate);
            const currentYear = date.getFullYear();
            let currentMonth = 12;
            if (date.getMonth() + 1 < 10) {
                currentMonth = '0' + (date.getMonth() + 1)
            } else {
                currentMonth = date.getMonth() + 1
            }
            return currentYear + '-' + currentMonth
        }
        
        useEffect(() => {
            if (modalTab === '1') {
                onClickTabBaoCaoTongQuat().then(r => 'err');
            }
        }, []);
        
        const handleSubmit = async () => {
            let reportType = payload?.reportType
            let dataSubmit = {
                ngaybatdau: payload?.ngaybatdau,
                ngayketthuc: payload?.ngayketthuc,
            }
            switch (reportType) {
                case 'bao_cao_tong_quat':
                    await onClickTabBaoCaoTongQuat(dataSubmit);
                    break;
                case 'tuyen_dung_nhan_su':
                    await onClickTabTuyenDung(dataSubmit);
                    break;
                case 'tinh_hinh_chuyen_can':
                    await onClickTabChuyenCan(dataSubmit);
                    break;
                case 'bien_dong_nhan_su':
                    await onClickTabBienDongNhanSu(dataSubmit);
                    break;
                case 'tham_nien':
                    await onClickTabThamNien(dataSubmit);
                    break;
                case 'bien_dong_vi_tri_cong_viec':
                    await onClickTabBienDongNhanSuCongViec(dataSubmit);
                    break;
                case 'dao_tao_nhan_su':
                    await onClickTabDaoTaoNhanSu(dataSubmit);
                    break;
                case 'thu_thap_binh_quan':
                    await onClickTabThuNhapBinhQuan(dataSubmit);
                    break;
                case 'chi_phi_nhan_su':
                    await onClickTabChiPhiNhanSu(dataSubmit);
                    break;
                default:
                    await onClickTabBaoCaoTongQuat(dataSubmit);
            }
        }
        /**
         * Dữ liệu trong báo cáo tổng quát
         * @return {object} Giá trị trong phần báo cáo tổng quát sau khi onClick onClickTabBaoCaoTongQuat
         * @param data
         */
            // console.log('dataTongQuat', dataTongQuat)
        const onClickTabBaoCaoTongQuat = async (data) => {
                try {
                    const date = data !== undefined ? new Date(data.ngayketthuc) : new Date();
                    const setDateCurrentFilter = data !== undefined ? getMonthYear(format(parseISO(data.ngayketthuc), 'yyyy-MM-dd')) : getMonthYear(new Date());
                    setPayload({
                        ...payload,
                        ['reportType']: 'bao_cao_tong_quat',
                        ['ngayketthuc']: setDateCurrentFilter,
                    });
                    let dataPayload = {
                        data: [
                            {
                                date: date,
                                dataSelectHocVan: dataSelectHocVan,
                                
                            }
                        ]
                    }
                    // Lấy dữ liệu về báo cáo tổng quát
                    const dataRes = apiDataTongQuat(dataPayload);
                    const [dataRess] = await Promise.all([dataRes]);
                    setData(dataRess.data.data[0])
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
                
            };
        /**
         * Dữ liệu trong báo Tuyển dụng
         * @return {object} Giá trị trong phần báo cáo về tuyển dụng sau khi onClick onClickTabTuyenDung và sẽ đẩy sang : TableTuyenDungBottom && TableTuyenDungLeft
         * @param data
         */
        const onClickTabTuyenDung = async (data) => {
            try {
                setPayload({
                    ...payload,
                    ['reportType']: 'tuyen_dung_nhan_su',
                });
                let date = new Date();
                if (data !== undefined) {
                    let dateView = data.ngayketthuc
                    date = new Date(dateView)
                }
                // Lấy dữ liệu về báo cáo tuyển dụng
                let dataPayload = {
                    data: [
                        {
                            date: date,
                        }
                    ]
                }
                const dataRes = apiDataTuyenDung(dataPayload);
                const [dataRess] = await Promise.all([dataRes]);
                if (dataRess.data.success === 500) {
                    toast.warning('Dữ liệu không có gì để hiển thị')
                    return;
                }
                
                setData(dataRess?.data?.data)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        
        //??: Báo cáo Báo cáo Tuyển dụng ---------------------------------------------- ??//
        /**
         * Dữ liệu trong báo cáo chuyên cần
         * @return {object} Giá trị trong phần báo cáo tổng quát sau khi onClick onClickTabChuyenCan
         * @param data
         */
        const onClickTabChuyenCan = async (data) => {
            try {
                const date = data !== undefined ? new Date(data.ngayketthuc) : new Date();
                const setDateCurrentFilter = data !== undefined ? getMonthYear(format(parseISO(data.ngayketthuc), 'yyyy-MM-dd')) : getMonthYear(new Date());
                setPayload({
                    ...payload,
                    ['reportType']: 'tinh_hinh_chuyen_can',
                    ['ngayketthuc']: setDateCurrentFilter,
                });
                // Lấy dữ liệu về báo cáo tuyển dụng
                let dataPayload = {
                    data: [
                        {
                            date: date,
                        }
                    ]
                }
                const dataRes = apiDataChuyenCan(dataPayload);
              
                const [dataRess] = await Promise.all([dataRes]);
                console.log('dataRes',dataRess.data.data)
                setData(dataRess.data.data)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        /**
         * Dữ liệu trong báo cáo biê động nhân sự
         * @return {object} Giá trị trong phần báo cáo tổng quát sau khi onClick onClickTabBienDongNhanSu
         * @param data
         */
        const onClickTabBienDongNhanSu = async (data) => {
            try {
                const date = data !== undefined ? new Date(data.ngayketthuc) : new Date();
                const setDateCurrentFilter = data !== undefined ? getMonthYear(format(parseISO(data.ngayketthuc), 'yyyy-MM-dd')) : getMonthYear(new Date());
                setPayload({
                    ...payload,
                    ['reportType']: 'bien_dong_nhan_su',
                    ['ngayketthuc']: setDateCurrentFilter,
                });
                let dataPayload = {
                    data: [
                        {
                            date: date,
                        }
                    ]
                }
                const dataRes = apiDataBienDongNhanSu(dataPayload);
                const [dataRess] = await Promise.all([dataRes]);
                setData(dataRess?.data?.data)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        /**
         * Dữ liệu trong báo cáo Thâm niên
         * @return {object} Giá trị trong phần báo cáo tổng quát sau khi onClick onClickTabThamNien
         * @param data
         */
        const onClickTabThamNien = async (data) => {
            try {
                const date = data !== undefined ? new Date(data.ngayketthuc) : new Date();
                const setDateCurrentFilter = data !== undefined ? getMonthYear(format(parseISO(data.ngayketthuc), 'yyyy-MM-dd')) : getMonthYear(new Date());
                setPayload({
                    ...payload,
                    ['reportType']: 'tham_nien',
                    ['ngayketthuc']: setDateCurrentFilter,
                });
                let dataPayload = {
                    data: [
                        {
                            date: date,
                        }
                    ]
                }
                const dataRes = apiDataThamNien(dataPayload);
                const [dataRess] = await Promise.all([dataRes]);
                console.log('dataRess',dataRess.data.data)
                setData(dataRess?.data?.data)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        /**
         * Dữ liệu trong báo cáo Biến động nhân sự công việc
         * @return {object} Giá trị trong phần báo cáo tổng quát sau khi onClick onClickTabBienDongNhanSuCongViec
         * @param data
         */
        const onClickTabBienDongNhanSuCongViec = async (data) => {
            try {
                const date = data !== undefined ? new Date(data.ngayketthuc) : new Date();
                const setDateCurrentFilter = data !== undefined ? getMonthYear(format(parseISO(data.ngayketthuc), 'yyyy-MM-dd')) : getMonthYear(new Date());
                setPayload({
                    ...payload,
                    ['reportType']: 'bien_dong_vi_tri_cong_viec',
                    ['ngayketthuc']: setDateCurrentFilter,
                });
                let dataPayload = {
                    data: [
                        {
                            date: date,
                        }
                    ]
                }
                const dataRes = apiDataBienDongNhanSuByChucVu(dataPayload);
                const [dataRess] = await Promise.all([dataRes]);
                setData(dataRess?.data?.data);
                
                
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        
        /**
         * Dữ liệu trong báo cáo đào tạo nhân sự
         * @return {object} Giá trị trong phần báo cáo tổng quát sau khi onClick onClickTabDaoTaoNhanSu
         * @param data
         */
        const onClickTabDaoTaoNhanSu = async (data) => {
            try {
                const date = data !== undefined ? new Date(data.ngayketthuc) : new Date();
                const setDateCurrentFilter = data !== undefined ? getMonthYear(format(parseISO(data.ngayketthuc), 'yyyy-MM-dd')) : getMonthYear(new Date());
                setPayload({
                    ...payload,
                    ['reportType']: 'dao_tao_nhan_su',
                    ['ngayketthuc']: setDateCurrentFilter,
                });
                let dataPayload = {
                    data: [
                        {
                            date: date,
                        }
                    ]
                }
                const dataRes = apiDataDaoTaoNhanSu(dataPayload);
                const [dataRess] = await Promise.all([dataRes]);
                setData(dataRess?.data?.data)
                
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        /**
         * Dữ liệu trong báo cáo thu nhập bình quân
         * @return {object} Giá trị trong phần báo cáo tổng quát sau khi onClick onClickTabThuNhapBinhQuan
         * @param data
         */
        const onClickTabThuNhapBinhQuan = async (data) => {
            try {
                const date = data !== undefined ? new Date(data.ngayketthuc) : new Date();
                const setDateCurrentFilter = data !== undefined ? getMonthYear(format(parseISO(data.ngayketthuc), 'yyyy-MM-dd')) : getMonthYear(new Date());
                setPayload({
                    ...payload,
                    ['reportType']: 'thu_thap_binh_quan',
                    ['ngayketthuc']: setDateCurrentFilter,
                });
                let dataPayload = {
                    data: [
                        {
                            date: date,
                        }
                    ]
                }
                const dataRes = apiDataThuNhapBQ(dataPayload);
                
                const [dataRess] = await Promise.all([dataRes]);
                // console.log('dataRes',dataRess?.data?.data)
                setData(dataRess?.data?.data)
                
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        /**
         * Dữ liệu trong báo cáo Chi phí nhân sự
         * @return {object} Giá trị trong phần báo cáo tổng quát sau khi onClick onClickTabChiPhiNhanSu
         * @param data
         */
        const onClickTabChiPhiNhanSu = async (data) => {
            try {
                const date = data !== undefined ? new Date(data.ngayketthuc) : new Date();
                const setDateCurrentFilter = data !== undefined ? getMonthYear(format(parseISO(data.ngayketthuc), 'yyyy-MM-dd')) : getMonthYear(new Date());
                setPayload({
                    ...payload,
                    ['reportType']: 'chi_phi_nhan_su',
                    ['ngayketthuc']: setDateCurrentFilter,
                });
                let dataPayload = {
                    data: [
                        {
                            date: date,
                        }
                    ]
                }
                const dataRes = apiDataChiPhiNhanSu(dataPayload);
                const [dataRess] = await Promise.all([dataRes]);
                setData(dataRess?.data?.data)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        
        const listMenuTop = [ // Menu top List item
            {
                name: 'Tổng quát lao động',
                id: '1',
                href: '#tongquatlaodong',
                icon: 'file-text',
                onClick: () => {
                    setModalTab('1');
                    onClickTabBaoCaoTongQuat().then(r => 'err');
                }
            },
            {
                name: 'Tuyển dụng nhân sự',
                id: '2',
                href: '#tuyendungnhansu',
                icon: 'file-text',
                onClick: () => {
                    setModalTab('2');
                    onClickTabTuyenDung().then(r => 'err');
                }
            },
            {
                name: 'Tình hình chuyên cần',
                id: '3',
                href: '#tinhhinhchuyencan',
                icon: 'file-text',
                onClick: () => {
                    setModalTab('3');
                    onClickTabChuyenCan().then(r => 'err');
                }
            },
            {
                name: 'Biến động nhân sự',
                id: '4',
                href: '#biendongnhansu',
                icon: 'file-text',
                onClick: () => {
                    setModalTab('4');
                    onClickTabBienDongNhanSu().then(r => 'err');
                }
            },
            {
                name: 'Thâm niên',
                id: '5',
                href: '#thamnien',
                icon: 'file-text',
                onClick: () => {
                    setModalTab('5');
                    onClickTabThamNien().then(r => 'err');
                }
            },
            {
                name: 'Biến động theo vị trí công việc',
                id: '6',
                href: '#biendongvitricongviec',
                icon: 'file-text',
                onClick: () => {
                    setModalTab('6');
                    onClickTabBienDongNhanSuCongViec().then(r => 'err');
                }
            },
            {
                name: 'Đào tạo nhân sự',
                id: '7',
                href: '#daotaonhansu',
                icon: 'file-text',
                onClick: () => {
                    setModalTab('7');
                    onClickTabDaoTaoNhanSu().then(r => 'err');
                }
            },
            {
                name: 'Thu nhập bình quân',
                id: '8',
                href: '#thunhapbinhquan',
                icon: 'file-text',
                onClick: () => {
                    setModalTab('8');
                    onClickTabThuNhapBinhQuan().then(r => 'err');
                }
            },
            {
                name: 'Chi phí nhân sự',
                id: '9',
                href: '#chiphinhansu',
                icon: 'file-text',
                onClick: () => {
                    setModalTab('9');
                    onClickTabChiPhiNhanSu().then(r => 'err');
                }
            },
        ]
        return (
            <React.Fragment>
                <Content>
                    <Card className="card-bordered px-0">
                        <div className="card-aside-wrap">
                            <div className="card-inner card-inner-lg p-2">
                                <div className="p-2">
                                    <ul className="nk-nav nav nav-tabs">
                                        {listMenuTop.map((item, index) => {
                                            return (
                                                <li className="nav-item" key={index}>
                                                    <a
                                                        className={`nav-link ${modalTab === item.id && 'active'}`}
                                                        onClick={item.onClick}
                                                        href={item.href}
                                                    >
                                                        <Icon name={item.icon} className="me-2"></Icon>
                                                        {item.name}
                                                    </a>
                                                </li>
                                            )
                                        })}
                                    
                                    </ul>
                                    <div className="tab-content">
                                        <div className={`tab-pane ${modalTab === '1' ? 'active' : ''}`}
                                             id="tongquatlaodong">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="pb-0">
                                                        <h6 className="overline-title text-primary">
                                                            Báo cáo tổng quát lao động
                                                        </h6>
                                                    </div>
                                                
                                                </div>
                                                <div className="col-12 row">
                                                    <div className="form-group col-md-4 my-1">
                                                        <InputForm
                                                            setInvalidFields={setInvalidFields}
                                                            invalidFields={invalidFields}
                                                            label={'Chọn năm cần xem báo cáo'}
                                                            value={payload?.ngayketthuc}
                                                            setValue={setPayload}
                                                            keyPayload={'ngayketthuc'}
                                                            className={"my-0 pb-1"}
                                                            type='month'
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-4 my-auto text-start pt-4">
                                                        <Button color="primary" className="btn-icon px-4"
                                                                onClick={handleSubmit}>
                                                            Tra cứu
                                                        </Button>
                                                    </div>
                                                    <div className="form-group col-md-4 my-auto text-end">
                                                        <ReactHTMLTableToExcel
                                                            className="btn btn-secondary buttons-html5 download-table-xls-button px-2 me-2"
                                                            table="table-to-xls-tongquat"
                                                            filename="bao-cao-tong-quat"
                                                            sheet="tablexls"
                                                            icon="tablexls"
                                                            buttonText="Tải xuống báo cáo tổng quát"
                                                        />
                                                    </div>
                                                </div>
                                                <ComponentTongQuat dataTongQuat={data}/>
                                            
                                            </div>
                                        </div>
                                        <div
                                            className={`tab-pane ${modalTab === '2' ? 'active' : ''}`} // Báo cáo Tuyển dụng nhân sự
                                            id="tuyendungnhansu">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="pb-0">
                                                        <h6 className="overline-title text-primary">Báo cáo Tuyển dụng nhân
                                                            sự</h6>
                                                    </div>
                                                </div>
                                                <ComponentTuyenDung dataTuyenDung={data}/>
                                            </div>
                                        </div>
                                        <div
                                            className={`tab-pane ${modalTab === '3' ? 'active' : ''}`} // Báo cáo Tình hình chuyên cần
                                            id="tinhhinhchuyencan">
                                            <div className="row my-1">
                                                <div className="col-md-12">
                                                    <div className="pb-0">
                                                        <h6 className="overline-title text-primary">
                                                            Báo cáo Tình hình chuyên cần</h6>
                                                    </div>
                                                </div>
                                                <div className="col-8 row mb-4">
                                                    <div className="form-group col-md-4 my-0">
                                                        <InputForm
                                                            setInvalidFields={setInvalidFields}
                                                            invalidFields={invalidFields}
                                                            label={'Chọn năm cần xem báo cáo'}
                                                            value={payload?.ngayketthuc}
                                                            setValue={setPayload}
                                                            keyPayload={'ngayketthuc'}
                                                            className={"my-0"}
                                                            type='month'
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-4 my-auto text-center mb-0">
                                                        <Button color="primary" className="btn-icon px-4"
                                                                onClick={handleSubmit}>
                                                            Tra cứu
                                                        </Button>
                                                    </div>
                                                </div>
                                            
                                            </div>
                                            <div className="row">
                                                <ComponentChuyenCan dataChuyenCan={data}/>
                                            </div>
                                        </div>
                                        <div className={`tab-pane ${modalTab === '4' ? 'active' : ''}`}
                                             id="biendongnhansu">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="pb-0">
                                                        <h6 className="overline-title text-primary">Báo cáo Biến động nhân
                                                            sự trong năm</h6>
                                                    </div>
                                                </div>
                                                <div className="col-8 row my-1">
                                                    <div className="form-group col-md-4 my-0">
                                                        <InputForm
                                                            setInvalidFields={setInvalidFields}
                                                            invalidFields={invalidFields}
                                                            label={'Chọn năm cần xem báo cáo'}
                                                            value={payload?.ngayketthuc}
                                                            setValue={setPayload}
                                                            keyPayload={'ngayketthuc'}
                                                            className={"my-0"}
                                                            type='month'
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-4 my-auto text-center mb-0">
                                                        <Button color="primary" className="btn-icon px-4"
                                                                onClick={handleSubmit}>
                                                            Tra cứu
                                                        </Button>
                                                    </div>
                                                </div>
                                                <ComponentBienDongNhanSuTongQuan dataBienDongNS={data}/>
                                            </div>
                                        </div>
                                        
                                        <div className={`tab-pane ${modalTab === '5' ? 'active' : ''}`} id="thamnien">
                                            <div className="row">
                                                <div className="col-md-12 mt-3 row">
                                                    <div className="col-12 my-1">
                                                        <div className="pb-0">
                                                            <h6 className="overline-title text-primary">
                                                                Báo cáo biến động theo thâm niên
                                                            </h6>
                                                        </div>
                                                        <div className="col-8 row mb-4">
                                                            <div className="form-group col-md-4 my-0">
                                                                <InputForm
                                                                    setInvalidFields={setInvalidFields}
                                                                    invalidFields={invalidFields}
                                                                    label={'Chọn năm cần xem báo cáo'}
                                                                    value={payload?.ngayketthuc}
                                                                    setValue={setPayload}
                                                                    keyPayload={'ngayketthuc'}
                                                                    className={"my-0"}
                                                                    type='month'
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-4 my-auto text-center mb-0">
                                                                <Button color="primary" className="btn-icon px-4"
                                                                        onClick={handleSubmit}>
                                                                    Tra cứu
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ComponentThamNien dataBienDongNSTN={data}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`tab-pane ${modalTab === '6' ? 'active' : ''}`}
                                             id="biendongvitricongviec">
                                            <div className="row">
                                                
                                                <div className="col-12 my-1">
                                                    <div className="pb-0">
                                                        <h6 className="overline-title text-primary">
                                                            Báo cáo biến động theo vị trí công việc
                                                        </h6>
                                                    </div>
                                                    <div className="col-8 row mb-4">
                                                        <div className="form-group col-md-4 my-0">
                                                            <InputForm
                                                                setInvalidFields={setInvalidFields}
                                                                invalidFields={invalidFields}
                                                                label={'Chọn năm cần xem báo cáo'}
                                                                value={payload?.ngayketthuc}
                                                                setValue={setPayload}
                                                                keyPayload={'ngayketthuc'}
                                                                className={"my-0"}
                                                                type='month'
                                                            />
                                                        </div>
                                                        <div className="form-group col-md-4 my-auto text-center mb-0">
                                                            <Button color="primary" className="btn-icon px-4"
                                                                    onClick={handleSubmit}>
                                                                Tra cứu
                                                            </Button>
                                                        </div>
                                                    </div>
                                                
                                                </div>
                                                <ComponentBienDongNhanSuByChucVu dataBienDongNSCV={data}/>
                                            </div>
                                        </div>
                                        <div className={`tab-pane ${modalTab === '7' ? 'active' : ''}`} id="daotaonhansu">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="pb-0">
                                                        <h6 className="overline-title text-primary">
                                                            Báo cáo Đào tạo nhân sự
                                                        </h6>
                                                    </div>
                                                </div>
                                                <div className="col-8 row my-1">
                                                    <div className="form-group col-md-4 my-0">
                                                        
                                                        <InputForm
                                                            setInvalidFields={setInvalidFields}
                                                            invalidFields={invalidFields}
                                                            label={'Chọn năm cần xem báo cáo'}
                                                            value={payload?.ngayketthuc}
                                                            setValue={setPayload}
                                                            keyPayload={'ngayketthuc'}
                                                            className={"my-0"}
                                                            type='month'
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-4 my-auto text-center mb-0">
                                                        <Button color="primary" className="btn-icon px-4"
                                                                onClick={handleSubmit}>
                                                            Tra cứu
                                                        </Button>
                                                    </div>
                                                </div>
                                                <ComponentDaoTaoNhanSuTop dataDaoTaoNhanSu={data}/>
                                            
                                            </div>
                                        </div>
                                        <div className={`tab-pane ${modalTab === '8' ? 'active' : ''}`}
                                             id="thunhapbinhquan">
                                            <div className="row">
                                                <div className="col-12 my-1">
                                                    <div className="pb-4">
                                                        <h6 className="overline-title text-primary">Báo cáo thu nhập bình
                                                            quân</h6>
                                                    
                                                    </div>
                                                    <div className="col-8 row pb-4">
                                                        <div className="form-group col-md-4 my-0">
                                                            
                                                            <InputForm
                                                                setInvalidFields={setInvalidFields}
                                                                invalidFields={invalidFields}
                                                                label={'Chọn năm cần xem báo cáo'}
                                                                value={payload?.ngayketthuc}
                                                                setValue={setPayload}
                                                                keyPayload={'ngayketthuc'}
                                                                className={"my-0"}
                                                                type='month'
                                                            />
                                                        </div>
                                                        <div className="form-group col-md-4 my-auto text-center mb-0">
                                                            <Button color="primary" className="btn-icon px-4"
                                                                    onClick={handleSubmit}>
                                                                Tra cứu
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ComponentThuNhapBinhQuan dataThuNhapBinhQuan={data}/>
                                            
                                            </div>
                                        </div>
                                        <div className={`tab-pane ${modalTab === '9' ? 'active' : ''}`} id="chiphinhansu">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="pb-0">
                                                        <h6 className="overline-title text-primary">Báo cáo chi phí nhân
                                                            sự</h6>
                                                    </div>
                                                </div>
                                                <div className="col-8 row my-1">
                                                    <div className="form-group col-md-4 my-0">
                                                        <InputForm
                                                            setInvalidFields={setInvalidFields}
                                                            invalidFields={invalidFields}
                                                            label={'Chọn năm cần xem báo cáo'}
                                                            value={payload?.ngayketthuc}
                                                            setValue={setPayload}
                                                            keyPayload={'ngayketthuc'}
                                                            className={"my-0"}
                                                            type='month'
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-4 my-auto text-center mb-0">
                                                        <Button color="primary" className="btn-icon px-4"
                                                                onClick={handleSubmit}>
                                                            Tra cứu
                                                        </Button>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <ComponentChiPhiNhanSu dataChiPhiNhanSu={data}/>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                    </Card>
                </Content>
            </React.Fragment>
        );
    }
;

export default Baocao;
