export const validateUngVien = (fieldName, value) => {
    let regNumber = /^[0-9\b]+$/;
    switch (fieldName) {
        case 'tenthuonggoi':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Họ tên không được để trống'
                }
            }
            break;
        case 'VitrituyendungId':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Hãy chọn vị trí tuyển dụng'
                }
            }
            break;
        case 'PhongbanId':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Hãy chọn Phòng ban'
                }
            }
            break;
        case 'diachihientai':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Địa chỉ không được để trống.'
                }
            }
            break;
        case 'dienthoai':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Số điện thoại không được bỏ trống trường này.'
                }
            } else if (!regNumber.test(value)) {
                return {
                    name: fieldName,
                    message: 'Số điện thoại không đúng định dạng'
                }
            } else if (value.length < 10) {
                return {
                    name: fieldName,
                    message: 'Số điện thoại có độ dài từ 10-12'
                }
                
            }
            break;
        default:
            break;
    }
}