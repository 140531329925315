import config from '~/config';

// Pages
// import Home from '~/pages/Home';
// import Following from '~/pages/Following';
import Blog from '~/pages/Blog';
import {Dashboard, Nhanvien, Nhomnv, Maychamcong, TestApi, Congty,
    Kieuluong, Phongban, Chucvu, 
    Loaiquydinhphucloi, Ungvien, Danhgia, Quydinhthuongphat,
    Nghiphepcongtac, Vanban, Suatan, Suatantangca, Bocauhoiphongvan, Vitrituyendung,
    Cauhinhnghile, Giaoviec, Hopdonglaodong, Tinhluong, Chamcong, ChiTietNhanVien, Binhluan, 
    Dexuat, Taisan, Phucap, Cauhinhtangca,Cauhinhtinhluongtangca, Cauhinhtrangthai, Loaihopdong, Mauhopdong,
    Chinhanh, Baohiemnhanvien, Bangchamcong, Tamungluong, Cauhinhcalamviec, Phieudaychuyen, CauHinhDoanhNghiep, CauHinhBHXH_BHYT_BHTN,
    QuanLyNghi ,Cauhinhthuethunhapcnlt, Danhgianhanvien, Hoatdongsukien, Kiemdinhatvsbhld, Loaikiemdinhatvsbhld,
    Moiquanhelaodong, Moiquanhenhanvien, Nhanvienkiemdinhatvsbhld, Phuongtien,Loaiphuongtien,Phiphu,Kehoachcuaxe, Quanlyquythuong, Quanlythamnien, Baocao,
    Thongbaonoibo, Vanthu, Xeploai, Xeploainhanvien, Cauhinhnghiphep, Lydonghiphep, Baoduongphuongtien,Dangkiemphuongtien,
     Phieutangca, Emailserver, Thongbao, Phanca, Phancatonghop, Themmoinhanvien, Dulieuchamcong, Cauhinhpheduyet, Nhanvien_quyetdinh
} from '~/pages/Admin/';
import UserProfileRegularPage from '~/pages/Admin/user-manage/UserProfileRegular';
import UserProfileNotificationPage from '~/pages/Admin/user-manage/UserProfileNotification';
import UserProfileSettingPage from '~/pages/Admin/user-manage/UserProfileSetting';
import UserListAllPage from '~/pages/Admin/user-manage/UserListAll';
import FunctionUser from '~/pages/Admin/user-manage/FunctionUser';
import ModuleUser from '~/pages/Admin/user-manage/ModuleUser';
import Calender from '~/pages/Admin/calender/Calender';
import Banggiaoviec from '~/pages/Admin//Banggiaoviec/Kanban';


import Login from '~/pages/Auth/Login';
import Register from '~/pages/Auth/Register';
import VerifyEmail from '~/pages/Auth/VerifyEmail';
import AuthReset from '~/pages/Auth/AuthReset';
import AuthResetPassword from '~/pages/Auth/AuthResetPassword';
import CongThucTinhLuong from "~/pages/Admin/CongThucTinhLuong";
import Bangchotcong from "~/pages/Admin/Bangchotcong";
import Chinhsuachamcong from "~/pages/Admin/Chinhsuachamcong";
import Bangchamcongtonghop from "~/pages/Admin/Bangchamcongtonghop";
import Chamcongtonghop from "~/pages/Admin/Chamcongtonghop";
// import Chamcongtangca from "~/pages/Admin/Chamcongtangca";
import CauHinhChuyenCan from "~/pages/Admin/CauHinhChuyenCan";
import CauHinhThamNien from "~/pages/Admin/CauHinhThamNien";
import Danhsachbangluong from "~/pages/Admin/Danhsachbangluong";
import Cauhinhdaychuyen from "~/pages/Admin/Cauhinhdaychuyen";
import Cauhinhsanluong from "~/pages/Admin/Cauhinhsanluong";
import Sanluongnhanvien from "~/pages/Admin/Sanluongnhanvien";
import ThanhPhanLuong from "~/pages/Admin/ThanhPhanLuong";
import Thanhphancongthuc from "~/pages/Admin/Thanhphancongthuc";

// import Blog from '~/pages/Auth/Blog';
// Public routes
const publicRoutes = [
    { path: config.home, component: Login, layout: null},
    // { path: config.following, component: Following },
    { path: config.blog, component: Blog, layout: null},
    // { path: config.login, component: Login, layout: null},
    
    { path: config.register, component: Register, layout: null},
    { path: config.verify_email, component: VerifyEmail, layout: null}, 
    { path: config.auth_reset, component: AuthReset, layout: null}, 
    { path: config.auth_reset_password, component: AuthResetPassword, layout: null}, 
    // { path: config.routes.live, component: Live },
    // { path: config.routes.profile, component: Profile },
    // { path: config.routes.upload, component: Upload, layout: HeaderOnly },
    // { path: config.routes.search, component: Search, layout: null },
];

const privateRoutes = [
    { path: config.system, component: Dashboard },
    { path: config.nhan_vien, component: Nhanvien },
    { path: config.quyet_dinh, component: Nhanvien_quyetdinh },
    { path: config.nhom_nhan_vien, component: Nhomnv },
    { path: config.chi_tiet_nhan_vien, component: ChiTietNhanVien },
    { path: config.them_moi_nhan_vien, component: Themmoinhanvien },
    { path: config.may_cham_cong, component: Maychamcong },
    { path: config.tinh_luong, component: Tinhluong },
    { path: config.danh_sach_bang_luong, component: Danhsachbangluong },
    { path: config.cham_cong, component: Chamcong },
    // { path: config.cham_cong_tang_ca, component: Chamcongtangca },
    { path: config.cham_cong_tong_hop, component: Chamcongtonghop },
    { path: config.san_luong_nhan_vien, component: Sanluongnhanvien },
    { path: config.thanh_phan_cong_thuc, component: Thanhphancongthuc },
    { path: config.chinh_sua_cham_cong, component: Chinhsuachamcong },
    { path: config.du_lieu_cham_cong, component: Dulieuchamcong },
    { path: config.bang_chot_cong, component: Bangchotcong },
    { path: config.bang_cham_cong_tong_hop, component: Bangchamcongtonghop },
    { path: config.test_api, component: TestApi },
    { path: config.cong_ty, component: Congty },
    { path: config.kieu_luong, component: Kieuluong },
    { path: config.phong_ban, component: Phongban },
    { path: config.chuc_vu, component: Chucvu },
    { path: config.bao_cao, component: Baocao },
    { path: config.loai_quy_dinh_phuc_loi, component: Loaiquydinhphucloi },
    { path: config.ung_vien, component: Ungvien },
    { path: config.danh_gia, component: Danhgia },
    { path: config.phieu_day_chuyen, component: Phieudaychuyen },
    { path: config.cau_hinh_ca_lam_viec, component: Cauhinhcalamviec },
    { path: config.quy_dinh_thuong_phat, component: Quydinhthuongphat },
    { path: config.nghi_phep_cong_tac, component: Nghiphepcongtac },
    { path: config.van_ban, component: Vanban },
    { path: config.suat_an, component: Suatan },
    { path: config.suat_an_tang_ca, component: Suatantangca },
    { path: config.bo_cau_hoi_phong_van, component: Bocauhoiphongvan },
    { path: config.vi_tri_tuyen_dung, component: Vitrituyendung },
    { path: config.cau_hinh_nghi_le, component: Cauhinhnghile },
    { path: config.giao_viec, component: Giaoviec },
    { path: config.bang_giao_viec, component: Banggiaoviec },
    { path: config.hop_dong_lao_dong, component: Hopdonglaodong },
    { path: config.loai_hop_dong, component: Loaihopdong },
    { path: config.mau_hop_dong, component: Mauhopdong },
    { path: config.lich, component: Calender },
    { path: config.de_xuat, component: Dexuat },
    { path: config.binh_luan, component: Binhluan },
    { path: config.tai_san, component: Taisan },
    { path: config.phu_cap, component: Phucap },
    { path: config.cau_hinh_tang_ca, component: Cauhinhtangca },
    { path: config.cau_hinh_tinh_luong_tang_ca, component: Cauhinhtinhluongtangca },
    { path: config.cau_hinh_trang_thai, component: Cauhinhtrangthai },
    { path: config.chi_nhanh, component: Chinhanh },
    { path: config.bang_cham_cong, component: Bangchamcong },
    { path: config.bao_hiem_nhan_vien, component: Baohiemnhanvien },
    { path: config.danh_gia_nhan_vien, component: Danhgianhanvien },
    { path: config.tam_ung_luong, component: Tamungluong },
    { path: config.quan_ly_nghi, component: QuanLyNghi },
    { path: config.cau_hinh_doanh_nghiep, component: CauHinhDoanhNghiep },
    { path: config.cau_hinh_cong_thuc_tinh_luong, component: CongThucTinhLuong },
    { path: config.cau_hinh_thanh_phan_luong, component: ThanhPhanLuong },
    { path: config.cau_hinh_chuyen_can, component: CauHinhChuyenCan },
    { path: config.cau_hinh_tham_nien, component: CauHinhThamNien },
    { path: config.cau_hinh_day_chuyen, component: Cauhinhdaychuyen },
    { path: config.cau_hinh_san_luong, component: Cauhinhsanluong },
    { path: config.cau_hinh_bhxh_bhyt_bhtn, component: CauHinhBHXH_BHYT_BHTN },
    { path: config.cau_hinh_thue_thu_nhap_cnlt, component: Cauhinhthuethunhapcnlt },
    { path: config.hoat_dong_su_kien, component: Hoatdongsukien },
    { path: config.kiem_dinh_atvsbhld, component: Kiemdinhatvsbhld },
    { path: config.loai_kiem_dinh_atvsbhld, component: Loaikiemdinhatvsbhld },
    { path: config.moi_quan_he_lao_dong, component: Moiquanhelaodong },
    { path: config.moi_quan_he_nhan_vien, component: Moiquanhenhanvien },
    { path: config.nhan_vien_kiem_dinh_atvsbhld, component: Nhanvienkiemdinhatvsbhld },
    { path: config.phuong_tien, component: Phuongtien },
    { path: config.loai_phuong_tien, component: Loaiphuongtien },
    { path: config.phi_phu, component: Phiphu },
    { path: config.ke_hoach_cua_xe, component: Kehoachcuaxe },
    { path: config.bao_duong_phuong_tien, component: Baoduongphuongtien },
    { path: config.dang_kiem_phuong_tien, component: Dangkiemphuongtien },
    { path: config.quan_ly_quy_thuong, component: Quanlyquythuong },
    { path: config.quan_ly_tham_nien, component: Quanlythamnien },
    { path: config.thong_bao_noi_bo, component: Thongbaonoibo },
    { path: config.van_thu, component: Vanthu },
    { path: config.xep_loai, component: Xeploai },
    { path: config.xep_loai_nhan_vien, component: Xeploainhanvien },
    { path: config.cau_hinh_nghi_phep, component: Cauhinhnghiphep },
    { path: config.cau_hinh_phe_duyet, component: Cauhinhpheduyet },
    { path: config.ly_do_nghi_phep, component: Lydonghiphep },
    { path: config.phieu_tang_ca, component: Phieutangca },
    { path: config.phieu_tang_ca, component: Phieutangca },
    { path: config.email_server, component: Emailserver },
    { path: config.thong_bao, component: Thongbao },
    { path: config.phan_ca, component: Phanca },
    { path: config.phan_ca_tong_hop, component: Phancatonghop },
    
    
    { path: config.user_profile_regular, component: UserProfileRegularPage , user:'user'},
    { path: config.user_profile_notification, component: UserProfileNotificationPage, user:'user'},
    { path: config.user_profile_setting, component: UserProfileSettingPage, user:'user'},
    { path: config.user_list_all, component: UserListAllPage, user:'user'},
    { path: config.module_user, component: ModuleUser, user:'user'},
    { path: config.function_user, component: FunctionUser, user:'user'},
];

export { publicRoutes, privateRoutes };
