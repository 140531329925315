import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputForm, Button } from '~/components/Form';
import * as actions from '~/store/actions';

import '../Modal.scss';
import { Input, Modal, ModalBody } from 'reactstrap';
import Select from 'react-select';
import { format, parseISO } from 'date-fns';
import { Icon, SelectFinNhanvienfix } from '~/components/Component';
import { dataThuethunhapcanhan, dataStatus } from '~/utils/Datacustom';

const ModalEditHopdonglaodong = ({ show, setShow, dataIdHopdonglaodong, fieldList }) => {
    // console.log('dataIdHopdonglaodong', dataIdHopdonglaodong);
    const { loaihopdong } = useSelector((state) => state.hopdonglaodong);
    const { phongban } = useSelector((state) => state.phongban);
    const { chinhanh } = useSelector((state) => state.chinhanh);
    const { chucvu } = useSelector((state) => state.chucvu);
    const { userall } = useSelector((state) => state.app);
    const { mauhopdong } = useSelector((state) => state.hopdonglaodong);

    const dispatch = useDispatch();
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayload] = useState(fieldList);
    const [showSelect, setShowSelect] = useState(false);
    const [hide, setHide] = useState(false);
    const [listNV, setlistNV] = useState([]);
    const [listNhanvien, setlistNhanvien] = useState([]);

    useEffect(() => {
        let dataTTNCN = dataThuethunhapcanhan.find((item) => {
            return item && item.value === dataIdHopdonglaodong?.ThuethunhapcanhanId;
        });
        let dataTrangthai = dataStatus.find((item) => {
            return item && item.value === dataIdHopdonglaodong?.TrangthaiId;
        });
   
        let ngayHetHan = dataIdHopdonglaodong?.ngayhethan
        if (dataIdHopdonglaodong.LoaihopdongId === 3){
            ngayHetHan = null
            setHide(true)
        }
        show &&
            dataIdHopdonglaodong &&
            setPayload({
                NhanvienId: { label: dataIdHopdonglaodong?.Nhanvien?.hoten, value: dataIdHopdonglaodong?.NhanvienId },
                PhongbanId: {
                    label: dataIdHopdonglaodong?.Phongban?.phongban,
                    value: dataIdHopdonglaodong?.PhongbanId,
                },
                LoaihopdongId: {
                    label: dataIdHopdonglaodong?.Loaihopdong?.tenloaihopdong,
                    value: dataIdHopdonglaodong?.LoaihopdongId,
                },
                MauhopdongId: {
                    label: dataIdHopdonglaodong?.Mauhopdong?.mota,
                    value: dataIdHopdonglaodong?.MauhopdongId,
                },
                ChucvuId: {
                    label: dataIdHopdonglaodong?.Chucvu?.chucvu,
                    value: dataIdHopdonglaodong?.ChucvuId,
                },
                ChinhanhId: {
                    label: dataIdHopdonglaodong?.Chinhanh?.diachi,
                    value: dataIdHopdonglaodong?.ChinhanhId,
                },
                UserId: {
                    label: dataIdHopdonglaodong?.User?.username,
                    value: dataIdHopdonglaodong?.UserId,
                },
                mahopdong: dataIdHopdonglaodong?.mahopdong,
                motahopdong: dataIdHopdonglaodong?.motahopdong,
                ngayhethan: ngayHetHan ? format(parseISO(ngayHetHan), 'yyyy-MM-dd') : '',
                ngaytaohopdong: format(parseISO(dataIdHopdonglaodong?.ngaytaohopdong), 'yyyy-MM-dd'),
                ThuethunhapcanhanId: { label: dataTTNCN?.label, value: dataTTNCN?.value },
                // thoigianthuviec: dataIdHopdonglaodong?.thoigianthuviec,
                diaiemlamviec: dataIdHopdonglaodong?.diaiemlamviec,
                ngaybatdaulamviec: format(parseISO(dataIdHopdonglaodong?.ngaybatdaulamviec), 'yyyy-MM-dd'),
                // quyettoanthaythue: dataIdHopdonglaodong?.quyettoanthaythue == 0 ? true : false,
                TrangthaiId: {
                    label: dataTrangthai?.label,
                    value: dataTrangthai?.value,
                },
            });
            setlistNV([{ label: dataIdHopdonglaodong?.Nhanvien?.hoten, value: dataIdHopdonglaodong?.NhanvienId }]);
    }, [dataIdHopdonglaodong, show]);
    const [listPhongban, setlistPhongban] = useState([]);
    const [listLoaihopdong, setlistLoaihopdong] = useState([]);
    const [listChinhanh, setlistChinhanh] = useState([]);
    const [listChucvu, setlistChucvu] = useState([]);
    const [listUserAll, setlistUserAll] = useState([]);
    const [listMauhopdong, setlistMauhopdong] = useState([]);

    useEffect(() => {
        if (phongban && loaihopdong && chinhanh && chucvu && userall && mauhopdong) {
            let dataSelectPhongban = phongban.map((item) => ({ label: item.phongban, value: item.id }));
            let dataSelectLoaihopdong = loaihopdong.map((item) => ({ label: item.tenloaihopdong, value: item.id }));
            let dataSelectChinhanh = chinhanh.map((item) => ({ label: item.diachi, value: item.id }));
            let dataSelectChucvu = chucvu.map((item) => ({ label: item.chucvu, value: item.id }));
            let dataSelectUserall = userall.map((item) => ({ label: item.username, value: item.id }));
            const dataSelectMauhopdong = mauhopdong.filter((item) => Number(item.TrangthaiId) === 0).map((item) => ({ label: item.mota, value: item.id }));
            setlistPhongban(dataSelectPhongban);
            setlistLoaihopdong(dataSelectLoaihopdong);
            setlistChinhanh(dataSelectChinhanh);
            setlistChucvu(dataSelectChucvu);
            setlistUserAll(dataSelectUserall);
            setlistMauhopdong(dataSelectMauhopdong);
            listNhanvien?.length > 0 && setPayload((prev) => ({ ...prev, ['NhanvienId']: listNhanvien[0] }));
            listNhanvien?.length > 0 && setPayload((prev) => ({ ...prev, ['ngaybatdaulamviec']: listNhanvien[0].ngayvaolamviec }));
            listNhanvien?.length > 0 && setPayload((prev) => ({ ...prev, ['ChucvuId']: {label:listNhanvien[0]?.Chucvu?.chucvu, value:listNhanvien[0]?.ChucvuId} }));
            listNhanvien?.length > 0 && setPayload((prev) => ({ ...prev, ['ChinhanhId']: {label:listNhanvien[0]?.Chinhanh?.diachi, value:listNhanvien[0]?.ChinhanhId} }));
            listNhanvien?.length > 0 && setPayload((prev) => ({ ...prev, ['PhongbanId']: {label:listNhanvien[0]?.Phongban?.phongban, value:listNhanvien[0]?.PhongbanId} }));
        }
    }, [phongban, loaihopdong, chinhanh, chucvu, userall, listNhanvien]);
    const handleSubmit = async () => {
        if (payload.LoaihopdongId.value === 3) {
            delete payload.ngayhethan;
        }
        let invalids = validate(payload);

        if (invalids === 0) {
            let datapayload = {
                id: dataIdHopdonglaodong.id,
                PhongbanId: payload.PhongbanId.value,
                ChinhanhId: payload.ChinhanhId.value,
                NhanvienId: payload.NhanvienId.value,
                LoaihopdongId: payload.LoaihopdongId.value,
                MauhopdongId: payload.MauhopdongId.value,
                ChucvuId: payload.ChucvuId.value,
                UserId: payload.UserId.value,
                motahopdong: payload.motahopdong,
                ngayhethan: payload.ngayhethan,
                ngaytaohopdong: payload.ngaytaohopdong,
                ThuethunhapcanhanId: payload.ThuethunhapcanhanId.value,
                diaiemlamviec: payload.diaiemlamviec,
                ngaybatdaulamviec: payload.ngaybatdaulamviec,
                TrangthaiId: payload.TrangthaiId.value,
            };
            //   console.log('check payload', datapayload)
            dispatch(actions.EditHopdonglaodong(datapayload));
        }
    };
    const handleOnSelectLoaiHD = async (selected, name) => {
        if(name.name === 'LoaihopdongId' && selected.value === 3){
            setHide(true)
            setPayload((prev) => ({ ...prev, ['ngayhethan']: '' }));
        } else {
            setHide(false)
        }
        let statename = name.name;
        setPayload((prev) => ({ ...prev, [statename]: selected }));
    };
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayload((prev) => ({ ...prev, [statename]: selected }));
    };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (item[1] === '') {
                setInvalidFields((prev) => [
                    ...prev,
                    {
                        name: item[0],
                        message: 'Bạn không được bỏ trống trường này.',
                    },
                ]);
                invalids++;
            }
        });

        return invalids;
    };
    return (
        <React.Fragment>
            <Modal isOpen={show} size="lg" toggle={setShow} className="modal-90w">
                <ModalBody>
                    <Icon name="cross-sm float-end fs-2" onClick={setShow}></Icon>
                    <h5 className="title mb-5">Sửa hợp đồng lao động</h5>
                    <div className="row">
                        <div className="form-group col-md-6 " onClick={() => setShowSelect(true)}>
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                <span>
                                    Nhân viên <b className="text-danger">(*)</b>
                                </span>
                            </label>
                            <Select
                                value={payload?.NhanvienId}
                                // isMulti
                                name="NhanvienId"
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'NhanvienId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'NhanvienId')?.message}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-6">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Mã hợp đồng'}
                                value={payload.mahopdong}
                                setValue={setPayload}
                                keyPayload={'mahopdong'}
                                type="text"
                                validate
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-12">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                <span>
                                    Loại thuế thu nhập cá nhân <b className="text-danger">(*)</b>
                                </span>
                            </label>
                            <Select
                                value={payload.ThuethunhapcanhanId}
                                onChange={handleOnSelect}
                                options={dataThuethunhapcanhan}
                                placeholder={'Chọn loại thuế'}
                                name="ThuethunhapcanhanId"
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 &&
                                invalidFields.some((i) => i.name === 'ThuethunhapcanhanId') && (
                                    <small className="text-danger">
                                        {invalidFields.find((i) => i.name === 'ThuethunhapcanhanId')?.message}
                                    </small>
                                )}
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                <span>
                                    Loại hợp đồng <b className="text-danger">(*)</b>
                                </span>
                            </label>
                            <Select
                                value={payload.LoaihopdongId}
                                onChange={handleOnSelectLoaiHD}
                                options={listLoaihopdong}
                                placeholder={'Chọn loại hợp đồng'}
                                name="LoaihopdongId"
                                onFocus={() => setInvalidFields([])}
                                // onAddOption={onAddOption}
                                // components={{ Menu }}
                                // isDisabled={true}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'LoaihopdongId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'LoaihopdongId')?.message}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                <span>
                                    Mẫu hợp đồng <b className="text-danger">(*)</b>
                                </span>
                            </label>
                            <Select
                                value={payload.MauhopdongId}
                                onChange={handleOnSelect}
                                options={listMauhopdong}
                                placeholder={'Chọn mẫu hợp đồng'}
                                name="MauhopdongId"
                                onFocus={() => setInvalidFields([])}
                                // onAddOption={onAddOption}
                                // components={{ Menu }}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'MauhopdongId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'MauhopdongId')?.message}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-6">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                <span>
                                    Phòng ban <b className="text-danger">(*)</b>
                                </span>
                            </label>
                            <Select
                                value={payload.PhongbanId}
                                onChange={handleOnSelect}
                                options={listPhongban}
                                placeholder={'Chọn phòng ban'}
                                name="PhongbanId"
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'PhongbanId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'PhongbanId')?.message}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                <span>
                                    Chức vụ <b className="text-danger">(*)</b>
                                </span>
                            </label>
                            <Select
                                value={payload.ChucvuId}
                                onChange={handleOnSelect}
                                options={listChucvu}
                                placeholder={'Chọn chức vụ'}
                                name="ChucvuId"
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'ChucvuId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'ChucvuId')?.message}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-6">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                <span>
                                    Chi nhánh <b className="text-danger">(*)</b>
                                </span>
                            </label>
                            <Select
                                value={payload.ChinhanhId}
                                onChange={handleOnSelect}
                                options={listChinhanh}
                                placeholder={'Chọn chi nhánh'}
                                name="ChinhanhId"
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'ChinhanhId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'ChinhanhId')?.message}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                <span>
                                    Người ký <b className="text-danger">(*)</b>
                                </span>
                            </label>
                            <Select
                                value={payload.UserId}
                                onChange={handleOnSelect}
                                options={listUserAll}
                                placeholder={'Chọn người ký'}
                                name="UserId"
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'UserId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'UserId')?.message}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-4">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Ngày tạo hợp đồng'}
                                value={payload.ngaytaohopdong}
                                setValue={setPayload}
                                keyPayload={'ngaytaohopdong'}
                                type="date"
                                validate
                            />
                        </div>
                        <div className="form-group col-md-4 ">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Ngày bắt đầu làm việc'}
                                value={payload.ngaybatdaulamviec}
                                setValue={setPayload}
                                keyPayload={'ngaybatdaulamviec'}
                                type="date"
                                validate
                            />
                        </div>
                        <div className="form-group col-md-4">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Ngày hết hạn hợp đồng'}
                                value={payload.ngayhethan}
                                setValue={setPayload}
                                keyPayload={'ngayhethan'}
                                disabled={hide}
                                type="date"
                                validate
                            />
                        </div>
                    </div>
                  
                    <div className="row ">
                        <div className="form-group col-md-12">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Địa điểm làm việc'}
                                value={payload.diaiemlamviec}
                                setValue={setPayload}
                                keyPayload={'diaiemlamviec'}
                                type="text"
                                validate
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-12 mt-2">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                <span>
                                    Mô tả hợp đồng <b className="text-danger">(*)</b>
                                </span>
                            </label>
                            <Input
                                value={payload.motahopdong}
                                onChange={(e) =>
                                    setPayload((prev) => ({ ...prev, ['motahopdong']: e.target.value }))
                                }
                                rows={'2'}
                                name="motahopdong"
                                type="textarea"
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'motahopdong') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'motahopdong')?.message}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                <span>
                                    Trạng thái <b className="text-danger">(*)</b>
                                </span>
                            </label>
                            <Select
                                value={payload.TrangthaiId}
                                onChange={handleOnSelect}
                                options={dataStatus}
                                placeholder={'Chọn trạng thái'}
                                name="TrangthaiId"
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'TrangthaiId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'TrangthaiId')?.message}
                                </small>
                            )}
                        </div>
                    </div>

                    <div className="col-12 mt-5">
                        <Button text="Cập nhật" bClass="btn btn-primary px-5" textColor onClick={handleSubmit} />
                    </div>
                </ModalBody>
            </Modal>
            <SelectFinNhanvienfix
                showSelect={showSelect}
                // typeSelect = {
                //  ismultiple: "Lấy nhiều"
                //  single: "lấy một"
                // }
                // typeSelect
                listNhanvien={listNV}
                setlistNhanvien={setlistNhanvien}
                setShowSelect={() => setShowSelect(false)}
            />
        </React.Fragment>
    );
};

export default ModalEditHopdonglaodong;
