import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { InputForm, Button } from '~/components/Form';
import * as actions from '~/store/actions';
import { validatePhuongTien } from '~/request/validatePhuongTien';
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import {dataSelectPhuongtien} from "~/utils/Datacustom";

const ModalPhuongtien = ({ show, setShow, fieldList }) => {
    const [listTrangthai, setlistTrangthai] = useState([]);
    const {loaiphuongtien} = useSelector((state) => state.quanly);
    const [listLoaiphuongtien, setlistLoaiphuongtien] = useState(loaiphuongtien);
    
    const dispatch = useDispatch();
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayload] = useState(fieldList);
    useEffect(() => {
        show && setPayload(fieldList);
    }, [show]);
    useEffect(() => {
        dispatch(actions.getListLoaiphuongtien());
    }, []);
    useEffect(() => {
        if (loaiphuongtien && loaiphuongtien.length > 0) {
        let LoaiphuongtienId = loaiphuongtien.map((item) => ({label: item.tenloaiphuongtien, value: item.id}));
            setlistLoaiphuongtien(LoaiphuongtienId);
        let TrangthaiId = dataSelectPhuongtien.map((item) => ({label: item.label, value: item.value}));
        setlistTrangthai(TrangthaiId);
        }
    }, [loaiphuongtien]);
    const handleSubmit = async () => {
        let invalids = validate(payload);
        if (invalids === 0) {
            let datapayload = {
                data: [
                    {
                        biensoxe: payload.biensoxe,
                        noidangky: payload.noidangky,
                        tinhtrangxe: payload.tinhtrangxe,
                        // ngaydangkiem: payload.ngaydangkiem,
                        // handangkiem: payload.handangkiem,
                        nuocsanxuat: payload.nuocsanxuat,
                        namsanxuat: payload.namsanxuat,
                        sokhung: payload.sokhung,
                        nhanhieu: payload.nhanhieu,
                        ngaycapgiaychungnhan: payload.ngaycapgiaychungnhan,
                        magps: payload.magps,
                        magpsmobile: payload.magpsmobile,
                        TrangthaiId: payload.TrangthaiId.value,
                        LoaiphuongtienId: payload.LoaiphuongtienId.value,
                    },
                ],
            };
            // console.log('check payload', datapayload)
            dispatch(actions.NewPhuongtien(datapayload));
        }
    };
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayload((prev) => ({ ...prev, [statename]: selected }));
    };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validatePhuongTien(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validatePhuongTien(item[0], item[1])]);
                invalids++;
            }
        });
        return invalids;
    };
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">Thêm mới phương tiện</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Biển số xe'}
                            value={payload.biensoxe}
                            setValue={setPayload}
                            keyPayload={'biensoxe'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                                Loại phương tiện<b className="text-danger">(*)</b>
                            </span>
                        </label>
                        <Select
                            value={payload.LoaiphuongtienId}
                            onChange={handleOnSelect}
                            options={listLoaiphuongtien}
                            placeholder={'Loại phương tiện'}
                            name="LoaiphuongtienId"
                            onFocus={() => setInvalidFields([])}
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'LoaiphuongtienId') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'LoaiphuongtienId')?.message}
                            </small>
                        )}
                    </div>
                    <div className="form-group col-md-3">
                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                                Trạng thái <b className="text-danger">(*)</b>
                            </span>
                        </label>
                        <Select
                            value={payload.TrangthaiId}
                            onChange={handleOnSelect}
                            options={listTrangthai}
                            placeholder={'Trạng thái'}
                            name="TrangthaiId"
                            onFocus={() => setInvalidFields([])}
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'TrangthaiId') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'TrangthaiId')?.message}
                            </small>
                        )}
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Nơi đăng ký'}
                            value={payload.noidangky}
                            setValue={setPayload}
                            keyPayload={'noidangky'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Tình trạng xe'}
                            value={payload.tinhtrangxe}
                            setValue={setPayload}
                            keyPayload={'tinhtrangxe'}
                            type="text"
                            validate
                        />
                    </div>
                    {/*<div className="form-group col-md-3">*/}
                    {/*    <InputForm*/}
                    {/*        setInvalidFields={setInvalidFields}*/}
                    {/*        invalidFields={invalidFields}*/}
                    {/*        label={'Ngày đăng kiểm'}*/}
                    {/*        value={payload.ngaydangkiem}*/}
                    {/*        setValue={setPayload}*/}
                    {/*        keyPayload={'ngaydangkiem'}*/}
                    {/*        type="date"*/}
                    {/*        validate*/}
                    {/*    />*/}
                    {/*</div>*/}
                    {/*<div className="form-group col-md-3">*/}
                    {/*    <InputForm*/}
                    {/*        setInvalidFields={setInvalidFields}*/}
                    {/*        invalidFields={invalidFields}*/}
                    {/*        label={'Hạn đăng kiểm'}*/}
                    {/*        value={payload.handangkiem}*/}
                    {/*        setValue={setPayload}*/}
                    {/*        keyPayload={'handangkiem'}*/}
                    {/*        type="date"*/}
                    {/*        validate*/}
                    {/*    />*/}
                    {/*</div>*/}
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Nước sản xuất'}
                            value={payload.nuocsanxuat}
                            setValue={setPayload}
                            keyPayload={'nuocsanxuat'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Năm sản xuất'}
                            value={payload.namsanxuat}
                            setValue={setPayload}
                            keyPayload={'namsanxuat'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Số khung'}
                            value={payload.sokhung}
                            setValue={setPayload}
                            keyPayload={'sokhung'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Nhãn hiệu'}
                            value={payload.nhanhieu}
                            setValue={setPayload}
                            keyPayload={'nhanhieu'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ngày cấp giấy CN'}
                            value={payload.ngaycapgiaychungnhan}
                            setValue={setPayload}
                            keyPayload={'ngaycapgiaychungnhan'}
                            type="date"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Mã GPS'}
                            value={payload.magps}
                            setValue={setPayload}
                            keyPayload={'magps'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Mã GPS Mobile'}
                            value={payload.magpsmobile}
                            setValue={setPayload}
                            keyPayload={'magpsmobile'}
                            type="text"
                            validate
                        />
                    </div>
                </div>
        
                <div className="col-12 mt-5">
                    <Button text="Thêm mới" bClass="btn btn-primary px-5" textColor onClick={handleSubmit} />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalPhuongtien;
