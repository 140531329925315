import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Block,
    BlockContent,
    BlockDes,
    BlockHead,
    BlockTitle,
    Icon,
    Button,
    PreviewCard,
} from "~/components/Component";
import { Link } from "react-router-dom";
import Logo from "~/images/logo.png";
import LogoDark from "~/images/logo-dark-color.png";
import PageContainer from "~/layout/page-container/PageContainer";
import Head from "~/layout/head/Head";
import { Form, Spinner, Alert } from "reactstrap";
import { AuthResetServices } from '~/services';
import Swal from 'sweetalert2';
const AuthReset = () => {
    const dispatch = useDispatch()
    // const navigate = useNavigate()
    // const { isLoggedIn } = useSelector(state => state.auth)
    // console.log('isLoggedIn', isLoggedIn)
    const [invalidFields, setInvalidFields] = useState([])
    const [payload, setPayloadLogin] = useState({
        email: '',
    })
    useEffect(() => {
        if (invalidFields.length === 0) {
            setLoading(false);
        }
    }, [invalidFields]);
    const [loading, setLoading] = useState(false);
    const handleSubmit = async () => {
        setLoading(true);
        let invalids = validate(payload)
        if (invalids === 0) {
            const fetchData = async () => {
                const response = await AuthResetServices(payload);
                // console.log('response', response);
                if (response.data.err === 0) {
                    // setLoading(false);
                    Swal.fire({
                        icon: 'success',
                        title: response.data.mes,
                        showConfirmButton: false,
                        timer: 3500000
                      })
                }else{
                    Swal.fire({
                        title: 'Có lỗi đã xảy ra?',
                        text: "Xin vui lòng liên hệ kỹ thuật viên để được tư vấn!",
                        icon: 'warning',
                        showConfirmButton: false,
                        timer: 3500000
                })
                }
            };
            fetchData().catch(console.error);
        }
    }
    const validate = (payload) => {
        let invalids = 0
        let fields = Object.entries(payload)
        fields.forEach(item => {
            if (item[1] === '') {
                setInvalidFields(prev => [...prev, {
                    name: item[0],
                    message: 'Bạn không được bỏ trống trường này.'
                }])
                invalids++
            }
        })
        fields.forEach(item => {
            switch (item[0]) {
                case 'email':
                    const validateEmail = (email) => {
                        return email.match(
                          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        );
                      };
                    if (validateEmail(item[1]) === null) {
                        setInvalidFields((prev) => [
                            ...prev,
                            {
                                name: item[0],
                                message: 'Email không hợp lệ.',
                            },
                        ]);
                        invalids++;
                    }
                    break;
                default:
                    break;
            }
        })
        return invalids
    }

    return (
        <React.Fragment>
        {/*<Head title="AuthReset" />*/}
        <PageContainer>
          <Block className="nk-block-middle nk-auth-body  wide-xs">
            <div className="brand-logo pb-4 text-center">
              <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
                <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
                <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
              </Link>
            </div>
            <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h5">Đặt lại mật khẩu</BlockTitle>
                <BlockDes>
                  <p>Nếu bạn quên mật khẩu, chúng tôi sẽ gửi email cho bạn hướng dẫn đặt lại mật khẩu.</p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
             
              <Form className="is-alter" >
                <div className="form-group">
                  <div className="form-label-group">
                    <label className="form-label" htmlFor="default-01">
                      Email
                    </label>
                  </div>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      id="default-01"
                      name="name"
                      placeholder="Nhập địa chỉ Email"
                      className="form-control-lg form-control"
                      value={payload.email}
                      onChange={(e) => setPayloadLogin(prev => ({ ...prev, ['email']: e.target.value }))}
                      onFocus={() => setInvalidFields([])}
                    />
                    {invalidFields.length > 0 && invalidFields.some(i => i.name === 'email') && <small className='text-danger' >{invalidFields.find(i => i.name === 'email')?.message}</small>}
                  </div>
                </div>
                <div className="form-group">
                                {loading ? (
                                    <Button size="lg" className="btn-block" type="button" color="primary">
                                        <Spinner size="sm" color="light" /> Đặt lại mật khẩu
                                    </Button>
                                ) : (
                                    <Button
                                        size="lg"
                                        className="btn-block"
                                        type="button"
                                        color="primary"
                                        onClick={handleSubmit}
                                    >
                                        Đặt lại mật khẩu
                                    </Button>
                                )}
                            </div>
              </Form>
              <div className="form-note-s2 text-center pt-4">
              <Link to={`${process.env.PUBLIC_URL}/login`}>
                <strong>Trở về trang đăng nhập</strong>
              </Link>
            </div>
            
            </PreviewCard>
          </Block>
        </PageContainer>
      </React.Fragment>
    );
}

export default AuthReset;
