import actionTypes from './actionTypes'
import { apiGetPhongban, apiNewPhongban, apiEditPhongban, apiDeletePhongban } from '~/services/phongban'
import { toast } from "react-toastify";
export const getListPhongban = () => async (dispatch) => {
    try {
        const response = await apiGetPhongban()
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_PHONG_BAN,
                phongban: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_PHONG_BAN,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_PHONG_BAN,
            phongban: null
        })
    }
}

export const NewPhongban = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiNewPhongban(payload)
            // console.log('chek api apiNewPhongban', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_PHONG_BAN,
                    msg: 'OK'
                });
                dispatch(getListPhongban());
                toast.success("Thêm mới phòng ban thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_PHONG_BAN,
                    msg: 'ERR'
                })
                toast.error("Thêm mới phòng ban thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_PHONG_BAN,
                phongban: null
            })
        }
    }
}
export const EditPhongban = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditPhongban(payload)
            // console.log('chek api apiEditPhongban', response)
            if (response?.data.success === 200) {
                dispatch(getListPhongban());
                toast.success("Sửa thông tin phòng ban thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_PHONG_BAN,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_PHONG_BAN,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin phòng ban thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_PHONG_BAN,
                phongban: null
            })
        }
    }
}
export const DeletePhongban = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeletePhongban(payload)
            // console.log('chek api apiDeletePhongban', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListPhongban());
                toast.success("Xóa thông tin phòng ban thành công!")
                dispatch({
                    type: actionTypes.DELETE_PHONG_BAN,
                    msg: 'OK',
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_PHONG_BAN,
                    msg: 'ERR'
                })
                toast.error(response?.data.data)
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_PHONG_BAN,
                phongban: null
            })
        }
    }
}