import actionTypes from '../actions/actionTypes';
const initState = {
    msg: '',
    user: [],
    userall: [],
    FunctionUser: [],
    donvihanhchinh: ''
};
const appReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.GET_USER:
            return {
                ...state,
                user: action?.user || [],
                FunctionUser: action?.FunctionUser || [],
                msg: action?.msg || '',
            };
        // case actionTypes.GET_FUN_USER:
        //     return {
        //         ...state,
        //         FunctionUser: action?.FunctionUser.rows || [],
        //         msg: action?.msg || '',
        //     }
        case actionTypes.GET_USER_ALL:
            return {
                ...state,
                userall: action?.userall?.rows || [],
                msg: action?.msg || '',
            };
        case actionTypes.GET_DON_VI_HANH_CHINH:
            return {
                ...state,
                msg: action?.msg || '',
                donvihanhchinh: action?.donvihanhchinh || '',
            };
        default:
            return state;
    }
};

export default appReducer;
