import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {InputForm, Button} from '~/components/Form'
import * as actions from '~/store/actions'

import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import {NewLoaiquydinhphucloi} from "~/store/actions";

const ModalLoaiquydinhphucloi = ({show, setShow, fieldList}) => {
    const dispatch = useDispatch()
    const [invalidFields, setInvalidFields] = useState([])
    const [payload, setPayload] = useState(fieldList)
    // set dữ liệu về [] khi click lưu mới
    useEffect(() => {
        show && setPayload(fieldList)
    }, [show])
    const handleSubmit = async () => {
        let invalids = validate(payload)
        if (invalids === 0) {
            let datapayload = {
                mota: payload.mota,
                noidung: payload.noidung,
                giatri: payload.giatri,
                sothutu: payload.sothutu,
                status: payload.status,
            }
            // lấy dữ liệu từ form đẩy lên redux sử lý thêm
            dispatch(actions.NewLoaiquydinhphucloi(datapayload))
        }
    }
    const validate = (payload) => {
        let invalids = 0
        let fields = Object.entries(payload)
        fields.forEach(item => {
            if (item[1] === '') {
                setInvalidFields(prev => [...prev, {
                    name: item[0],
                    message: 'Bạn không được bỏ trống trường này.'
                }])
                invalids++
            }
        })
        fields.forEach(item => {
            switch (item[0]) {
                case 'masothue':
                    if (item[1].length < 6) {
                        setInvalidFields(prev => [...prev, {
                            name: item[0],
                            message: 'Mã số thuế phải có tối thiểu 6 kí tự.'
                        }])
                        invalids++
                    }
                    break;
                // case 'phone':
                //     if (!+item[1]) {
                //         setInvalidFields(prev => [...prev, {
                //             name: item[0],
                //             message: 'Số điện thoại không hợp lệ.'
                //         }])
                //         invalids++
                //     }
                //     break
                
                default:
                    break;
            }
        })
        return invalids
    }
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Thêm mới
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Mô tả'}
                            value={payload.mota}
                            setValue={setPayload}
                            keyPayload={'mota'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-6 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Nội dung'}
                            value={payload.noidung}
                            setValue={setPayload}
                            keyPayload={'noidung'}
                            type='text'
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Giá trị'}
                            value={payload.giatri}
                            setValue={setPayload}
                            keyPayload={'giatri'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-6 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Số thứ tự'}
                            value={payload.sothutu}
                            setValue={setPayload}
                            keyPayload={'sothutu'}
                            type='text'
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Trạng thái'}
                            value={payload.status}
                            setValue={setPayload}
                            keyPayload={'status'}
                            type='text'
                        />
                    </div>
                
                </div>
                
                <div className='col-12 mt-5'>
                    <Button
                        text='Thêm loại quy định phúc lợi'
                        bClass='btn btn-primary px-5'
                        textColor
                        onClick={handleSubmit}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ModalLoaiquydinhphucloi;