import React, { useState, createContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '~/store/actions';
import { dataThuethunhapcanhan } from '~/utils/Datacustom';
import { w3cwebsocket as WebSocket } from 'websocket';
import Swal from 'sweetalert2';
export const FileAllCodeContext = createContext();
const FileAllCodeContextProvider = (props) => {
    const { user } = useSelector((state) => state.app);
    // console.log('user------FileAllCodeContextProvider', user)
    const [userdata, setUser] = useState(null);
    useEffect(() => {
        setUser(user);
    }, [user]);
    const [systemPermission, setSystemPermission] = useState('close');
    const [changeData, setChangeData] = useState('unloading');
    const [thongbaoData, setThongbaoData] = useState(null);
    // console.log('thongbaoData', thongbaoData)
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        const connectToServer = () => {
            const socketURL = process.env.REACT_APP_SOCKET;
            // console.log('socketURL' , socketURL)
            const firstFiveCharacters = socketURL.slice(0, 5);
            let socketURL1 = '';
            if(firstFiveCharacters === 'http:'){
                socketURL1 = `ws://${socketURL.replace('http://', '')}`;
            }else{
                socketURL1 = `wss://${socketURL.replace('https://', '')}`;
            }
            // console.log('socketURL1' , socketURL1)
            const newSocket = new WebSocket(socketURL1);
            // const newSocket = new WebSocket('ws://localhost:8900/');
            // console.log('newSocket', newSocket);
            newSocket.onopen = () => {
                console.log('Connected to server');
            };
            newSocket.onmessage = (event) => {
                const dataFromServer = JSON.parse(event.data);
                // console.log('dataFromServer', dataFromServer)
                if (dataFromServer.type === `permission_change_${user?.id}`) {
                    setSystemPermission(dataFromServer.permission);
                }
                if (dataFromServer.type === `changeData_${user?.CongtyId}`) {
                    setChangeData(dataFromServer.msg);
                }
                if (dataFromServer.type === `thongbaoData_${user?.CongtyId}`) {
                    // console.log('dataFromServer.msg', dataFromServer.msg)
                    setThongbaoData(dataFromServer.msg);
                }
            };

            newSocket.onclose = () => {
                console.log('Disconnected from server');
                setSocket(null);
            };

            setSocket(newSocket);
        };
        connectToServer();
        return () => {
            if (socket) {
                socket.close();
            }
        };
    }, [user]);

    // socket trả về user_id == với user_id user thì sẽ bắt tài khoản đăng xuất
    useEffect(() => {
        if (systemPermission !== `close`) {
            if (Number(systemPermission) === Number(user?.id)) {
                Swal.fire({
                    title: 'Phiên đăng nhập của bạn đã kết thúc?',
                    text: 'Tài khoản của bạn đã được cấp quyền mới vui lòng đăng xuất và đăng nhập lại!',
                    icon: 'warning',
                    timer: 5000,
                    confirmButtonText: 'Đăng xuất!',
                }).then((result) => {
                    setSystemPermission('close');
                    dispatch(actions.logout());
                });
            }
        }
    }, [systemPermission, user]);
    useEffect(() => {
        if (changeData !== `unloading`) {
            if (changeData === 'phongban') {
                dispatch(actions.getListPhongban());
                setChangeData('unloading');
            }
            if (changeData === 'chucvu') {
                dispatch(actions.getListChucvu());
                setChangeData('unloading');
            }
        }
        if (thongbaoData !== null) {
            // setTimeout(() => {
            thongbaoData?.split('|').map((item) => {
                if (Number(item) === Number(userdata.id)) {
                    dispatch(
                        actions.getListThongbao({
                            TrangthaiId: 0,
                            UserId: userdata.id,
                            page: '1',
                            limit: '20',
                            order: 'desc',
                            by: 'id',
                            view: 0
                        }),
                    );
                    dispatch(actions.getListNghiphepcongtac({ order: 'desc', by: 'id' }));
                    setThongbaoData(null);
                }
            });
            // }, 2000);
            // let tees = "1"
            // tees?.split('|').map((item) => {
            //     if(Number(item) === Number(userdata.id)){
            //         dispatch(actions.getListThongbao({ TrangthaiId: 0, UserId: userdata.id }));
            //         alert('Ok tsset');
            //     }
            // });
            // dispatch(actions.getListThongbao({ TrangthaiId: 0, UserId: userdata.id }));
        }
    }, [changeData, thongbaoData]);
    //Đoạn rút gọn
    // useEffect(() => {
    //     if (changeData !== 'unloading') {
    //       if (changeData === 'phongban' || changeData === 'chucvu') {
    //         dispatch(actions[`getList${changeData.charAt(0).toUpperCase() + changeData.slice(1)}`]());
    //         setChangeData('unloading');
    //       }
    //     }
    //   }, [changeData]);
    const handlePermissionChange = (user_id) => {
        if (socket) {
            const message = {
                type: `permission_change_${user_id}`,
                permission: user_id ? user_id : null,
            };
            socket.send(JSON.stringify(message));
        } else {
            console.error('Socket is null or not initialized.');
        }
    };
    const handleChangeDataSocket = (data) => {
        if (socket) {
            const message1 = {
                type: `changeData_${user?.CongtyId}`,
                msg: data ? data : null,
            };
            // console.log(message1)
            socket.send(JSON.stringify(message1));
        } else {
            console.error('Socket is null or not initialized.');
        }
    };
    const handleThongbaoDataSocket = (data) => {
        if (socket) {
            const message2 = {
                type: `thongbaoData_${user?.CongtyId}`,
                msg: data ? data : null,
            };
            // console.log('messagevvvvv', message2);
            socket.send(JSON.stringify(message2));
        } else {
            console.error('Socket is null or not initialized.');
        }
    };

    // const [data, setData] = useState(null);
    const dispatch = useDispatch();
    // const { loaihopdong } = useSelector((state) => state.hopdonglaodong);
    // useEffect(() => {
    //     if (loaihopdong) {
    //         let dataLoaihopdong = loaihopdong.map((item) => ({ tenloaihopdong: item.tenloaihopdong, id: item.id }));
    //         setlistLoaihopdong(dataLoaihopdong);
    //     }
    // }, [loaihopdong]);
    // const [listLoaihopdong, setlistLoaihopdong] = useState([]);
    // const handleFind = (inputData, type) => {
    //     if (type === 'Loaihopdong') {
    //         const listdata = listLoaihopdong.find((item) => item?.tenloaihopdong === inputData);
    //         return listdata?.id;
    //     }
    //     if (type === 'Thuethunhapcanhan') {
    //         const listdata = dataThuethunhapcanhan.find((item) => item?.label === inputData);
    //         return listdata?.value;
    //     }
    // };
    // console.log('listLoaihopdong', listLoaihopdong)
    // const createFileHopdong = async (files) => {
    //     // console.log('files', files);

    //     let data = [];
    //     files.length > 0 &&
    //         files.map((item) => {
    //             let dataInsert = {
    //                 NhanvienId: +item.id,
    //                 CongtyId: 1,
    //                 ChinhanhId: +item.ChinhanhId,
    //                 PhongbanId: 1,
    //                 LoaihopdongId: handleFind(item.LoaihopdongId, 'Loaihopdong'),
    //                 mahopdong: item.mahopdong,
    //                 motahopdong: item.motahopdong,
    //                 ngayhethan: item.ngayhethan,
    //                 ThuethunhapcanhanId: handleFind(item.ThuethunhapcanhanId, 'Thuethunhapcanhan'),
    //                 thoigianthuviec: 0,
    //                 diaiemlamviec: item.diaiemlamviec,
    //                 ngaybatdaulamviec: item.ngaybatdaulamviec,
    //                 quyettoanthaythue: item.quyettoanthaythue === 'on' ? 0 : 1,
    //             };
    //             data.push(dataInsert);
    //         });
    //     let datapayload = { data };
    //     // console.log('datapayload', datapayload)
    //     dispatch(actions.NewHopdonglaodong(datapayload));
    //     // setData({success: 200, msg:'Thêm danh sách thành công'})
    // };
    // const deletemes = () => {
    //     setData(null);
    // };
    return (
        <React.Fragment>
            <FileAllCodeContext.Provider
                value={{
                    // contextData: data,
                    // createFileHopdong: createFileHopdong,
                    // deletemes: deletemes,
                    handlePermissionChange: handlePermissionChange,
                    handleChangeDataSocket: handleChangeDataSocket,
                    handleThongbaoDataSocket: handleThongbaoDataSocket,
                }}
            >
                {props.children}
            </FileAllCodeContext.Provider>
            {/* <p>System Permission: {systemPermission}</p> */}
            {/* <button onClick={() => handlePermissionChange('admin')}>Set Admin</button>
      <button onClick={() => handlePermissionChange('user')}>Set User</button> */}
        </React.Fragment>
    );
};

export default FileAllCodeContextProvider;
