import React, {useState, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {InputForm, Button} from '~/components/Form'
import * as actions from '~/store/actions'
import '../Modal.scss';
import {validateKieuLuong} from "~/request/validateKieuLuong";
import Select from 'react-select';
import {SelectFinNhanvien} from "~/components/Component";
import Content from "~/layout/content/Content";
import {Modal, ModalBody} from 'reactstrap';
import Icon from "~/components/icon/Icon";

const ModalNhanVienInCongThucLuong = ({show, setShow, dataCongThucLuong, fieldList}) => {
    const dispatch = useDispatch()
    const [invalidFields, setInvalidFields] = useState([])
    const [payloads, setPayloads] = useState(fieldList)
    const [listNhanvien, setlistNhanvien] = useState([]);
    const [showSelect, setShowSelect] = useState(false);
   

    useEffect(() => {
        show && dataCongThucLuong && setPayloads({
            tencongthuc: dataCongThucLuong?.tencongthuc,
            cachtinh: dataCongThucLuong?.cachtinh,
            cachtinhtangca: dataCongThucLuong?.cachtinhtangca,
            ghichu: dataCongThucLuong?.ghichu,
        })
        
    }, [dataCongThucLuong, show])
    useEffect(() => {
        setPayloads((prev) => ({
            ...prev,
            ['NhanvienId']: listNhanvien,
        }));
    }, [listNhanvien]);
    const handleSubmit = async () => {
        let listNhanvienID = payloads.NhanvienId;
        
        if (listNhanvienID.length > 0) {
            let datapayloads = {};
            let data = [];
            listNhanvienID?.map(async (item, i) => {
                data.push({
                    id: item.value,
                    CongthucId: dataCongThucLuong?.id,
                })
            })
            datapayloads.data = data;
            let response = dispatch(actions.EditNhanvien(datapayloads));
            response.status === 200 ? setShow(false) : setShow(true);
        }
    }
    const validate = (payloads) => {
        let invalids = 0
        let fields = Object.entries(payloads)
        fields.forEach(item => {
            if (validateKieuLuong(item[0], item[1]) !== undefined) {
                setInvalidFields(prev => [...prev, validateKieuLuong(item[0], item[1])])
                invalids++
            }
        })
        
        return invalids
    }
    return (
        <React.Fragment>
            <Content>
                <Modal isOpen={show} toggle={setShow} className="modal-dialog-centered modal-90w">
                    <ModalBody>
                        <Icon name="cross-sm float-end fs-2" onClick={setShow}></Icon>
                        <h5 className="title mb-5">Cấu hình nhân viên sử dụng</h5>
                        
                        <div className="row">
                            <div className="form-group col-md-12">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Tên công thức'}
                                    value={payloads.tencongthuc}
                                    setValue={setPayloads}
                                    keyPayload={'tencongthuc'}
                                    readOnly={true}
                                    type="text"
                                    validate
                                />
                            </div>
                            <div className="form-group col-md-12">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Ghi chú'}
                                    value={payloads.ghichu}
                                    setValue={setPayloads}
                                    readOnly={true}
                                    keyPayload={'ghichu'}
                                    type="text"
                                    validate
                                />
                            </div>
                            <div className="form-group col-md-12">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Cách tính'}
                                    value={payloads.cachtinh}
                                    setValue={setPayloads}
                                    readOnly={true}
                                    keyPayload={'cachtinh'}
                                    type="text"
                                    validate
                                />
                            </div>
                         
                        </div>
                        <div className="row">
                            <div className="form-group col-md-12" onClick={() => setShowSelect(true)}>
                                <label htmlFor="inputZip" className="mb-2 fw-bold">
                                    Chọn Nhân Viên <b className="text-danger">(*)</b>
                                </label>
                                <Select
                                    value={payloads.NhanvienId}
                                    name="NhanvienId"
                                    isMulti
                                    onFocus={() => setInvalidFields([])}
                                />
                                {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'NhanvienId') && (
                                    <small className="text-danger">
                                        {invalidFields.find((i) => i.name === 'NhanvienId')?.message}
                                    </small>
                                )}
                            </div>
                        </div>
                        <div className='col-12 mt-5'>
                            <Button
                                text='Cập nhật'
                                bClass='btn btn-primary px-5'
                                textColor
                                onClick={handleSubmit}
                            />
                        </div>
                    </ModalBody>
                </Modal>
            </Content>
            <SelectFinNhanvien
                showSelect={showSelect}
                typeSelect={true}
                listNhanvien={listNhanvien}
                setlistNhanvien={setlistNhanvien}
                setShowSelect={() => setShowSelect(false)}
            />
            
        
        </React.Fragment>
    );
}

export default ModalNhanVienInCongThucLuong;

