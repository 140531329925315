import actionTypes from './actionTypes'
import {
    apiDeleteCauHinhCaLamViec,
    apiGetCauHinhCaLamViec,
    apiPostCauHinhCaLamViec,
    apiPutCauHinhCaLamViec
} from '~/services/cauhinhcalamviec'
import { toast } from "react-toastify";
export const getListCauhinhcalamviec = () => async (dispatch) => {
    try {
        const response = await apiGetCauHinhCaLamViec()
        // console.log('response', response)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_CAU_HINH_CA_LAM_VIEC,
                cauhinhcalamviec: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_CAU_HINH_CA_LAM_VIEC,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_CAU_HINH_CA_LAM_VIEC,
            cauhinhcalamviec: null
        })
    }
}

export const NewCauhinhcalamviec = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewCauhinhcalamviec', payload)
            const response = await apiPostCauHinhCaLamViec(payload)
            // console.log('chek api apiNewCauhinhcalamviec', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_CAU_HINH_CA_LAM_VIEC,
                    msg: 'OK'
                });
                dispatch(getListCauhinhcalamviec());
                toast.success("Thêm mới cấu hình ca làm việc thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_CAU_HINH_CA_LAM_VIEC,
                    msg: 'ERR'
                })
                toast.error("Thêm mới cấu hình ca làm việc thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_CAU_HINH_CA_LAM_VIEC,
                cauhinhcalamviec: null
            })
        }
    }
}
export const EditCauhinhcalamviec = (payload) => {
    return async (dispatch) => {
        try {
            
            const response = await apiPutCauHinhCaLamViec(payload)
            // console.log('chek api apiEditCauhinhcalamviec', response)
            if (response?.data.success === 200) {
                dispatch(getListCauhinhcalamviec());
                toast.success("Sửa thông tin cấu hình ca làm việc thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_CAU_HINH_CA_LAM_VIEC,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_CAU_HINH_CA_LAM_VIEC,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin cấu hình ca làm việc thất bại!")
            }

        } catch (error) {
            console.log('chek api  TB')
            dispatch({
                type: actionTypes.PUT_EDIT_CAU_HINH_CA_LAM_VIEC,
                cauhinhcalamviec: null
            })
        }
    }
}
export const DeleteCauhinhcalamviec = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteCauHinhCaLamViec(payload)
            // console.log('chek api apiDeleteCauhinhcalamviec', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListCauhinhcalamviec());
                toast.success("Xóa thông tin cấu hình cấu hình ca làm việc thành công!")
                dispatch({
                    type: actionTypes.DELETE_CAU_HINH_CA_LAM_VIEC,
                    msg: 'OK'
                });

            } else {
                dispatch({
                    type: actionTypes.DELETE_CAU_HINH_CA_LAM_VIEC,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin cấu hình ca làm việc thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_CAU_HINH_CA_LAM_VIEC,
                cauhinhcalamviec: null
            })
        }
    }
}