import actionTypes from './actionTypes'
import { apiGetLoaiphuongtien, apiNewLoaiphuongtien, apiEditLoaiphuongtien, apiDeleteLoaiphuongtien } from '~/services/loaiphuongtien'
import { toast } from "react-toastify";
export const getListLoaiphuongtien = () => async (dispatch) => {
    try {
        const response = await apiGetLoaiphuongtien()
        console.log('chek api lisst Loaiphuongtien', response)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_LOAI_PHUONG_TIEN,
                loaiphuongtien: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_LOAI_PHUONG_TIEN,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_LOAI_PHUONG_TIEN,
            loaiphuongtien: null
        })
    }
}

export const NewLoaiphuongtien = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewLoaiphuongtien', payload)
            const response = await apiNewLoaiphuongtien(payload)
            // console.log('chek api apiNewLoaiphuongtien', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_LOAI_PHUONG_TIEN,
                    msg: 'OK'
                });
                dispatch(getListLoaiphuongtien());
                toast.success("Thêm mới phương tiện thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_LOAI_PHUONG_TIEN,
                    msg: 'ERR'
                })
                toast.error("Thêm mới phương tiện thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_LOAI_PHUONG_TIEN,
                loaiphuongtien: null
            })
        }
    }
}
export const EditLoaiphuongtien = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditLoaiphuongtien(payload)
            // console.log('chek api apiEditLoaiphuongtien', response)
            if (response?.data.success === 200) {
                dispatch(getListLoaiphuongtien());
                toast.success("Sửa thông tin phương tiện thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_LOAI_PHUONG_TIEN,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_LOAI_PHUONG_TIEN,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin phương tiện thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_LOAI_PHUONG_TIEN,
                loaiphuongtien: null
            })
        }
    }
}
export const DeleteLoaiphuongtien = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteLoaiphuongtien(payload)
            // console.log('chek api apiDeleteLoaiphuongtien', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListLoaiphuongtien());
                toast.success("Xóa thông tin phương tiện thành công!")
                dispatch({
                    type: actionTypes.DELETE_LOAI_PHUONG_TIEN,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_LOAI_PHUONG_TIEN,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin phương tiện thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_LOAI_PHUONG_TIEN,
                loaiphuongtien: null
            })
        }
    }
}