import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { InputForm, Button } from '~/components/Form'
import * as actions from '~/store/actions'

import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
const ModalEditDanhgia = ({ show, setShow, dataIdDanhgia, fieldList }) => {
    console.log('dataIdDanhgia',dataIdDanhgia)
  const { ungvien } = useSelector(state => state.ungvien)
  const { phongban } = useSelector(state => state.phongban)
  // console.log('ungvien',ungvien)
  const dispatch = useDispatch()
  const [invalidFields, setInvalidFields] = useState([])
  const [payload, setPayloadDanhgia] = useState(fieldList)
  useEffect(() => {
    let UngvienId = ungvien?.find(item => {
        return item && item.id === dataIdDanhgia?.Thongtinungvien?.id
      })
    let PhongbanId = phongban?.find(item => {
      return item && item.id === dataIdDanhgia?.Phongban?.id
    })
    show && dataIdDanhgia && setPayloadDanhgia({
      // UngvienId: {label: UngvienId?.loaihinhungvien, value: UngvienId?.id},
      PhongbanId: { label: PhongbanId?.phongban, value: PhongbanId?.id },
      ThongtinungvienId: {label: UngvienId?.tenthuonggoi, value: UngvienId?.id},
      nguoidanhgia: '',
      danhgia: dataIdDanhgia?.danhgia,
      nhanxet: dataIdDanhgia?.nhanxet,
      ghichu: dataIdDanhgia?.ghichu,
    })
  }, [dataIdDanhgia, show])

  const [listUngvien, setlistUngvien] = useState([])
  const [listPhongban, setlistPhongban] = useState([])
  useEffect(() => {
    if(ungvien && phongban){
      let dataSelectPhongban = phongban.map((item) => ({ label: item.phongban, value: item.id }));
      let dataSelectUngvien = ungvien.map((item) => ({ label: item.tenthuonggoi, value: item.id }));
    setlistUngvien(dataSelectUngvien)
    setlistPhongban(dataSelectPhongban)
    }
  }, [ungvien, phongban])
  const handleSubmit = async () => {
    let invalids = validate(payload)

    if (invalids === 0) {
      let datapayload = {
        id: dataIdDanhgia.id,
        PhongbanId: payload.PhongbanId.value,
        ThongtinungvienId: payload.ThongtinungvienId.value,
        nguoidanhgia: 1,
        danhgia: payload.danhgia,
        nhanxet: payload.nhanxet,
        ghichu: payload.ghichu,
      }
      // console.log('check payload', datapayload)
      dispatch(actions.EditDanhgia(datapayload))
    }
  }
  const handleOnSelect = async (selected, name) => {
    let statename = name.name;
    setPayloadDanhgia(prev => ({ ...prev, [statename]: selected }))
  }
  const validate = (payload) => {
    let invalids = 0
    let fields = Object.entries(payload)
    fields.forEach(item => {
      if (item[1] === '') {
        setInvalidFields(prev => [...prev, {
          name: item[0],
          message: 'Bạn không được bỏ trống trường này.'
        }])
        invalids++
      }
    })

    return invalids
  }
  return (
    <Modal
      show={show}
      onHide={setShow}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Sửa thông tin đánh giá
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="form-group col-md-6">
          <label htmlFor="inputZip" className="mb-2">Thông tin ứng viên</label>
            <Select
              value={payload.ThongtinungvienId}
              onChange={handleOnSelect}
              options={listUngvien}
              placeholder={'Chọn ứng viên'}
              name='ThongtinungvienId'
              onFocus={() => setInvalidFields([])}
            />
          </div>
          <div className="form-group col-md-6 ">
            <label htmlFor="inputZip" className="mb-2">Phòng ban</label>
            <Select
              value={payload.PhongbanId}
              onChange={handleOnSelect}
              options={listPhongban}
              placeholder={'Chọn phòng ban'}
              name='PhongbanId'
              onFocus={() => setInvalidFields([])}
            />
            {invalidFields.length > 0 && invalidFields.some(i => i.name === 'PhongbanId') && <small className='text-danger' >{invalidFields.find(i => i.name === 'PhongbanId')?.message}</small>}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <InputForm
              setInvalidFields={setInvalidFields}
              invalidFields={invalidFields}
              label={'Người đánh giá'}
              value={payload.nguoidanhgia}
              setValue={setPayloadDanhgia}
              keyPayload={'nguoidanhgia'}
              type='text'
            />
          </div>
          <div className="form-group col-md-6 ">
            <InputForm
              setInvalidFields={setInvalidFields}
              invalidFields={invalidFields}
              label={'Đánh giá'}
              value={payload.danhgia}
              setValue={setPayloadDanhgia}
              keyPayload={'danhgia'}
              type='text'
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <InputForm
              setInvalidFields={setInvalidFields}
              invalidFields={invalidFields}
              label={'Nhận xét'}
              value={payload.nhanxet}
              setValue={setPayloadDanhgia}
              keyPayload={'nhanxet'}
              type='text'
            />
          </div>
          <div className="form-group col-md-6 ">
            <InputForm
              setInvalidFields={setInvalidFields}
              invalidFields={invalidFields}
              label={'Chi chú'}
              value={payload.ghichu}
              setValue={setPayloadDanhgia}
              keyPayload={'ghichu'}
              type='text'
            />
          </div>
        </div>

        <div className='col-12 mt-5'>
          <Button
            text='Sửa đánh giá'
            bClass='btn btn-primary px-5'
            textColor
            onClick={handleSubmit}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalEditDanhgia;

