import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { InputForm } from '~/components/Form';
import * as actions from '~/store/actions';
import { validateQuyetDinh } from '~/request/validateQuyetDinh';
import '../Modal.scss';
import Select from 'react-select';
import { NumericFormat } from 'react-number-format';
import { dataStatusNghiphep } from '~/utils/Datacustom';
import {
    Block,
    BlockBetween,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    DataTableBody,
    DataTableHead,
    DataTableRow,
    DataTableItem,
    DataTable,
    Button,
    Icon,
    SelectFinNhanvienfix,
} from '~/components/Component';
import { FileAllCodeContext } from '../../user-manage/FileAllCodeContext';
import { Modal, ModalBody, Input, Alert } from 'reactstrap';
import { apiEditQuyetdinh } from '~/services';
import { toast } from 'react-toastify';
import {matusinhResult} from '~/utils/Utils';

const ModalEditQuyetdinh = ({ show, setShow, fieldList, dataIdQuyetdinh, title, type, }) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.app);
    const { handleThongbaoDataSocket } = useContext(FileAllCodeContext);
    // console.log('dataIdQuyetdinh', dataIdQuyetdinh)
    // const { phongban } = useSelector((state) => state.phongban);
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayload] = useState(fieldList);
    const [formPC, setFormPC] = useState([]);

    const [quytrinh, setQuytrinh] = useState(true);
    const [dataduyet, setDataDuyet] = useState({nvid: '', capip: '', arrduyet: ''});

    useEffect(() => {
        let TrangthaiId = dataStatusNghiphep.find((items) => {
            return items && items.value === dataIdQuyetdinh?.TrangthaiId;
        });
        show &&  
        setPayload({
            matusinh: dataIdQuyetdinh.matusinh,
            ChucvuId: {label: dataIdQuyetdinh?.Chucvu?.chucvu, value: dataIdQuyetdinh?.ChucvuId},
            note: dataIdQuyetdinh.note,
            NhanvienId: {label: dataIdQuyetdinh?.Nhanvien?.hoten, value: dataIdQuyetdinh?.NhanvienId},
            mucluongformat: dataIdQuyetdinh.mucluong,
            // data_phucap: JSON.stringify(formPC),
            type: dataIdQuyetdinh.type,
            ngayhieuluc: dataIdQuyetdinh.ngayhieuluc,
            CongthucId: {label: dataIdQuyetdinh?.Congthuc?.tencongthuc , value: dataIdQuyetdinh?.CongthucId},
            PheduyetId: {label: dataIdQuyetdinh?.Pheduyet?.title, value: dataIdQuyetdinh?.PheduyetId},
            TrangthaiId: TrangthaiId,
        });
        let dataPC = dataIdQuyetdinh?.data_phucap ? JSON.parse(dataIdQuyetdinh?.data_phucap) : [];
        setFormPC(dataPC);

        setQuytrinh(dataIdQuyetdinh.TrangthaiId === 2 ? false : true);
        if(dataIdQuyetdinh.ob !== null){
            setDataDuyet(dataIdQuyetdinh.ob);
        }
    }, [show]);


    // console.log('formPC', formPC);
    // const [formEditNPT, setFormEditNPT] = useState({ id: 0, trangthai: false });
    const handleChangePC = (i, e) => {
        let newFormValues = [...formPC];
        newFormValues[i]['giatri'] = e.value;
        newFormValues[i]['giatriformat'] = e.formattedValue;
        setFormPC(newFormValues);
    };
    const handleSelectChangePC = (index, selected) => {
        const updatedFormValues = [...formPC];
        updatedFormValues[index] = {
            ...updatedFormValues[index],
            PhucapId: selected,
        };
        setFormPC(updatedFormValues);
    };
    const addFormFieldsPC = () => {
        let mang = [
            ...formPC,
            {
                PhucapId: '',
                giatri: '',
                giatriformat: '',
            },
        ];
        setFormPC(mang);
    };
    const removeFormFieldsPC = (i) => {
        let newFormValues = [...formPC];
        newFormValues.splice(i, 1);
        setFormPC(newFormValues);
    };
    // không cho phụ cấp trùng nhau
    // useEffect(() => {
    //     if (formPC && formPC.length > 0 && phucap) {
    //         const c = phucap.filter(
    //             (itemB) => !formPC.some((itemA) => Number(itemA?.PhucapId?.value) === Number(itemB.id)),
    //         );
    //         setlistPhucap(c.map((item) => ({ label: item.title, value: item.id })));
    //     } else {
    //     }
    // }, [formPC, formPC.length, phucap]);

 

    // console.log('payloadaaaaaaaaaaaaaaaaaa', payload)
    const [invalidPc, setInvalidFieldsPc] = useState([]);
    const handleSubmit = async () => {
        let invalids = validate(payload);
        const invalidsPC = validatePC(formPC)
        if (invalids === 0 && invalidsPC === 0) {
            let datapayload = {
                id: dataIdQuyetdinh.id,
                type: dataIdQuyetdinh.type,
                TrangthaiId:  payload?.TrangthaiId?.value,
                userId: user?.id,
                dataduyet: dataduyet,
            };
            try {
                let re = await apiEditQuyetdinh(datapayload);
                if(re?.data?.success === 200){
                    if(re?.data?.aruser !== 0){
                        let arr = [re?.data?.aruser];
                        handleThongbaoDataSocket(arr.join('|'));
                    }
                    toast.success('Cập nhật thành công');
                    setShow(false);
                    dispatch(actions.getListQuyetdinh());
                }else{
                    toast.error('Cập nhật thất bại');
                }
            } catch (error) {
                console.log(error)
            }
        }
    };
    const validatePC = (arr) => {
        let newArray = {};
        arr.forEach((item, index) => {
            newArray[`PhucapId_${index}`] = item.PhucapId;
            newArray[`giatri_${index}`] = item.giatri;
        });
        let invalids = 0;
        let fields = Object.entries(newArray);
        fields.forEach((item) => {
            if (item[1] === '') {
                setInvalidFieldsPc((prev) => [
                    ...prev,
                    {
                        name: item[0],
                        message: 'Bạn không được bỏ trống trường này.',
                    },
                ]);
                invalids++;
            }
        });

        return invalids;
    };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateQuyetDinh(item[0], item[1]) !== undefined ) {
                setInvalidFields((prev) => [...prev, validateQuyetDinh(item[0], item[1])]);
                invalids++;
            }
        });
        return invalids;
    };
    return (
        <Modal isOpen={show} className="modal-dialog-centered modal-90w" size="lg" toggle={setShow}>
            <ModalBody>
                <Icon name="cross-sm float-end fs-2" onClick={setShow}></Icon>
                <h5 className="title mb-3">Thêm mới {title?.name}</h5>
                <div className="row">
                    <div className="form-group col-md-4">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={`Mã ${title?.name}`}
                            value={payload.matusinh}
                            // setValue={setPayload}
                            keyPayload={'title'}
                            type="text"
                            validate
                            disabled={true}
                        />
                    </div>
                    <div className="form-group col-md-4" >
                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                                Nhân viên <b className="text-danger">(*)</b>
                            </span>
                        </label>
                        <Select
                            value={payload.NhanvienId}
                            // onChange={handleOnSelect}
                            // options={listNhanvien}
                            placeholder={'Chọn nhân viên'}
                            name="NhanvienId"
                            onFocus={() => setInvalidFields([])}
                            isDisabled
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'NhanvienId') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'NhanvienId')?.message}
                            </small>
                        )}
                    </div>
                    {type != 'nghi-viec' && (
                        <div className="form-group col-md-4" >
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                <span>
                                    Chức vụ {title?.name}
                                </span>
                            </label>
                            <Select
                                value={payload.ChucvuId}
                                onChange={(selected) => {
                                    setPayload((prev) => ({
                                        ...prev,
                                        ['ChucvuId']: selected,
                                    }));
                                }}
                                // options={listChucvu}
                                placeholder={'Chọn chức vụ'}
                                name="ChucvuId"
                                onFocus={() => setInvalidFields([])}
                                isDisabled
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'ChucvuId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'ChucvuId')?.message}
                                </small>
                            )}
                        </div>
                    )}
                    <div className="form-group col-md-12 ">
                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>Ghi chú</span>
                        </label>
                        <textarea
                            value={payload.note}
                            className="form-control"
                            id="event-description"
                            name="note"
                            onChange={(e) => setPayload((prev) => ({ ...prev, ['note']: e.target.value }))}
                            onFocus={() => setInvalidFields([])}
                            disabled
                        ></textarea>

                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'note') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'note')?.message}
                            </small>
                        )}
                    </div>
                    {type != 'nghi-viec' && (
                        <>
                            <div className="form-group col-md-3">
                                <div className="form-group">
                                    <label htmlFor="inputZip" className="mb-2 fw-bold">
                                        <span>
                                            Mức lương 
                                        </span>
                                    </label>
                                    <div className="form-control-wrap">
                                        <div className="form-text-hint">
                                            <span className="overline-title">VND</span>
                                        </div>
                                        <NumericFormat
                                            className="form-control px-2"
                                            value={payload.mucluongformat}
                                            // displayType={'text'}
                                            thousandSeparator={true}
                                            // suffix={'đ'}
                                            name="mucluong"
                                            onValueChange={(values) => {
                                                const { formattedValue, value } = values;
                                                setPayload((prev) => ({
                                                    ...prev,
                                                    ['mucluong']: value,
                                                    ['mucluongformat']: formattedValue,
                                                }));
                                                // setPayload((prev) => ({ ...prev, ['mucluongformat']: formattedValue }));
                                            }}
                                            onFocus={() => setInvalidFields([])}
                                            disabled
                                        />
                                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'mucluong') && (
                                            <small className="text-danger">
                                                {invalidFields.find((i) => i.name === 'mucluong')?.message}
                                            </small>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group col-md-3" >
                                <label htmlFor="inputZip" className="mb-2 fw-bold">
                                    <span>
                                        Công thức tính lương {title?.name}
                                    </span>
                                </label>
                                <Select
                                    value={payload.CongthucId}
                                    onChange={(selected) => {
                                        setPayload((prev) => ({
                                            ...prev,
                                            ['CongthucId']: selected,
                                        }));
                                    }}
                                    // options={listCongthuc}
                                    placeholder={'Chọn công thức'}
                                    name="CongthucId"
                                    onFocus={() => setInvalidFields([])}
                                    isDisabled
                                />
                                {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'CongthucId') && (
                                    <small className="text-danger">
                                        {invalidFields.find((i) => i.name === 'CongthucId')?.message}
                                    </small>
                                )}
                            </div>
                        </>
                    )}
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ngày hiệu lực'}
                            value={payload.ngayhieuluc}
                            setValue={setPayload}
                            keyPayload={'ngayhieuluc'}
                            type="date"
                            validate
                            disabled={true}
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                                Quy trình phê duyệt <b className="text-danger">(*)</b>
                            </span>
                        </label>
                        <Select
                            value={payload.PheduyetId}
                            onChange={(selected) => {
                                setPayload((prev) => ({
                                    ...prev,
                                    ['PheduyetId']: selected,
                                }));
                            }}
                            // options={listPheduyet}
                            placeholder={'Phê duyệt'}
                            name="PheduyetId"
                            onFocus={() => setInvalidFields([])}
                            isDisabled
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'PheduyetId') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'PheduyetId')?.message}
                            </small>
                        )}
                    </div>
                    
                </div>
                {type != 'nghi-viec' && (
                    <div className="mt-3">
                        <div className="form-group col-md-12">
                            <div className="pb-0">
                                <h6 className="text-primary">Các khoản phụ cấp</h6>
                                {/* {formPC?.length < phucap?.length && (
                                    <Button
                                        size="md"
                                        color="light"
                                        outline
                                        className="btn-white btn-dim btn-icon px-4 mt-2"
                                        onClick={() => addFormFieldsPC()}
                                    >
                                        Thêm phụ cấp <Icon name="plus"></Icon>
                                    </Button>
                                )} */}
                            </div>
                        </div>
                        {formPC?.length > 0 && (
                            <DataTable className="card-stretch">
                                <div style={{ overflowY: 'hidden' }}>
                                    <DataTableBody>
                                        <DataTableHead>
                                            <DataTableRow>
                                                <span className="sub-text">Stt</span>
                                            </DataTableRow>
                                            <DataTableRow>
                                                <span className="sub-text w-250px">Phụ cấp nhân viên</span>
                                            </DataTableRow>
                                            <DataTableRow>
                                                <span className="sub-text w-250px">Giá trị</span>
                                            </DataTableRow>
                                            <DataTableRow className="nk-tb-col-tools text-end"></DataTableRow>
                                        </DataTableHead>
                                        {formPC?.map((item, it) => {
                                            return (
                                                <DataTableItem key={it}>
                                                    <DataTableRow>
                                                        <span className="sub-text">{it + 1}</span>
                                                    </DataTableRow>
                                                    <DataTableRow>
                                                        <Select
                                                            value={item.PhucapId}
                                                            onChange={(selected) => handleSelectChangePC(it, selected)}
                                                            // options={listPhucap}
                                                            placeholder={'Chọn loại phụ cấp'}
                                                            name="PhucapId"
                                                            menuPosition="fixed"
                                                            onFocus={() => setInvalidFieldsPc([])}
                                                            isDisabled
                                                        />
                                                        {invalidPc.length > 0 &&
                                                            invalidPc.some((i) => i.name === `PhucapId_${it}`) && (
                                                                <small className="text-danger">
                                                                    {
                                                                        invalidPc.find((i) => i.name === `PhucapId_${it}`)
                                                                            ?.message
                                                                    }
                                                                </small>
                                                            )}
                                                    </DataTableRow>
                                                    <DataTableRow>
                                                        <div className="form-control-wrap">
                                                            <div className="form-text-hint">
                                                                <span className="overline-title">VND</span>
                                                            </div>
                                                            <NumericFormat
                                                                value={item.giatriformat}
                                                                className="form-control px-2"
                                                                thousandSeparator={true}
                                                                name="giatri"
                                                                onValueChange={(e) => handleChangePC(it, e)}
                                                                onFocus={() => setInvalidFieldsPc([])}
                                                                disabled
                                                            />
                                                            {invalidPc.length > 0 &&
                                                                invalidPc.some((i) => i.name === `giatri_${it}`) && (
                                                                    <small className="text-danger">
                                                                        {
                                                                            invalidPc.find((i) => i.name === `giatri_${it}`)
                                                                                ?.message
                                                                        }
                                                                    </small>
                                                                )}
                                                        </div>
                                                    </DataTableRow>

                                                    <DataTableRow className="nk-tb-col-tools ">
                                                        <ul className="nk-tb-actions gx-4">
                                                            {/* <button
                                                                                        onClick={() => handleEditNPT(it)}
                                                                                        className={
                                                                                            'btn btn-primary  badge text-white p-1'
                                                                                        }
                                                                                    >
                                                                                        <em className="icon ni ni-edit"></em>
                                                                                    </button> */}
                                                            {/* <>
                                                                <button
                                                                    onClick={() => removeFormFieldsPC(it)}
                                                                    className={'btn btn-danger  badge text-white p-1'}
                                                                >
                                                                    <em className="icon ni ni-trash-alt"></em>
                                                                </button>
                                                            </> */}
                                                        </ul>
                                                    </DataTableRow>
                                                </DataTableItem>
                                            );
                                        })}
                                    </DataTableBody>
                                </div>
                            </DataTable>
                        )}
                    </div>
                )}
                <div className="form-group col-md-3 mt-3">
                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                                Trạng thái 
                            </span>
                        </label>
                        <Select
                            value={payload.TrangthaiId}
                            onChange={(selected) => {
                                setPayload((prev) => ({
                                    ...prev,
                                    ['TrangthaiId']: selected,
                                }));
                            }}
                            options={dataStatusNghiphep}
                            placeholder={'Trạng thái'}
                            name="TrangthaiId"
                            onFocus={() => setInvalidFields([])}
                            isDisabled={quytrinh}
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'TrangthaiId') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'TrangthaiId')?.message}
                            </small>
                        )}
                    </div>
                <div className="col-12 mt-5">
                    <Button color="primary" onClick={handleSubmit} disabled={quytrinh}>
                        Lưu thông tin
                    </Button>
                </div>
               
            </ModalBody>
        </Modal>
    );
};

export default ModalEditQuyetdinh;
