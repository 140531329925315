import axiosConfig from '../axiosConfig'
//////////////////// Cấu hình nghỉ phép ////////////////////
export const apiGetCauhinhnghiphep = () => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlyhethong/cauhinhnghiphep`,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiNewCauhinhnghiphep = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlyhethong/cauhinhnghiphep',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiEditCauhinhnghiphep = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlyhethong/cauhinhnghiphep',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiDeleteCauhinhnghiphep = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlyhethong/cauhinhnghiphep',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
//////////////////// Cấu hình chuyên cần ////////////////////

export const apiGetCauhinhchuyencan = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlyhethong/cau-hinh-chuyen-can/cauhinhdoanhnghiep`,
            params: payload,
        })
        
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
export const apiNewCauhinhchuyencan = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlyhethong/cau-hinh-chuyen-can/cauhinhdoanhnghiep',
            data: payload
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
export const apiEditCauhinhchuyencan = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlyhethong/cau-hinh-chuyen-can/cauhinhdoanhnghiep',
            data: payload
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
export const apiDeleteCauhinhchuyencan = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlyhethong/cau-hinh-chuyen-can/cauhinhdoanhnghiep',
            data: payload
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})


//////////////////// Cấu hình thâm niên ////////////////////

export const apiGetCauhinhthamnien = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlyhethong/cau-hinh-tham-nien/cauhinhdoanhnghiep`,
            params: payload,
        })
        
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
export const apiNewCauhinhthamnien = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlyhethong/cau-hinh-tham-nien/cauhinhdoanhnghiep',
            data: payload
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
export const apiEditCauhinhthamnien = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlyhethong/cau-hinh-tham-nien/cauhinhdoanhnghiep',
            data: payload
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
export const apiDeleteCauhinhthamnien = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlyhethong/cau-hinh-tham-nien/cauhinhdoanhnghiep',
            data: payload
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
//////////////////// Cấu hình khac ////////////////////

export const apiGetCauhinhdaychuyen = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlyhethong/cau-hinh-day-chuyen/cauhinhdoanhnghiep`,
            params: payload,
        })
        
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
export const apiNewCauhinhdaychuyen = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlyhethong/cau-hinh-day-chuyen/cauhinhdoanhnghiep',
            data: payload
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
export const apiEditCauhinhdaychuyen = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlyhethong/cau-hinh-day-chuyen/cauhinhdoanhnghiep',
            data: payload
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
export const apiDeleteCauhinhdaychuyen = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlyhethong/cau-hinh-tham-nien/cauhinhdoanhnghiep',
            data: payload
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
//////////////////// Cấu hình sản lương ////////////////////

export const apiGetCauhinhsanluong = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlyhethong/cau-hinh-san-luong/cauhinhdoanhnghiep`,
            params: payload,
        })
        
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
export const apiGetSanluongnhanvien = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlyhethong/cau-hinh-san-luong-nhan-vien/cauhinhdoanhnghiep`,
            params: payload,
        })
        
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
export const apiNewCauhinhsanluong = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlyhethong/cau-hinh-san-luong/cauhinhdoanhnghiep',
            data: payload
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
export const apiEditCauhinhsanluong = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlyhethong/cau-hinh-san-luong/cauhinhdoanhnghiep',
            data: payload
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
export const apiEditSanluongnhanvien = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlyhethong/cau-hinh-san-luong-nhan-vien/cauhinhdoanhnghiep',
            data: payload
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
export const apiAddSanluongnhanvien = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlyhethong/cau-hinh-san-luong-nhan-vien/cauhinhdoanhnghiep',
            data: payload
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
export const apiDeleteSanluongnhanvien = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlyhethong/cau-hinh-san-luong-nhan-vien/cauhinhdoanhnghiep',
            data: payload
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
export const apiDeleteCauhinhsanluong = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlyhethong/cau-hinh-san-luong/cauhinhdoanhnghiep',
            data: payload
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})