import daygrid from "@fullcalendar/daygrid";
import interaction from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timegrid from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import viLocale from "@fullcalendar/core/locales/vi";
import { convertSolar2Lunar, findEvents, findEventsDuongLich } from "./amlich";
// import "../styles/main.css";
// import "~/assets/styles/main.css"
import { React } from "react";

const handleDateClick = (info) => {
  alert("Clicked on: " + info.dateStr);
  info.dayEl.style.backgroundColor = "rgb(175, 221, 142)";
  console.log(info);
};

const renderEventContent = (eventInfo) => {
  return (
    <div>
      {eventInfo.timeText} {eventInfo.event.title}
    </div>
  );
};

const renderAmLich = (info) => {
  const date = convertSolar2Lunar(
    Number(info.el.attributes["data-date"]["nodeValue"].split("-")[2]),
    Number(info.el.attributes["data-date"]["nodeValue"].split("-")[1]),
    Number(info.el.attributes["data-date"]["nodeValue"].split("-")[0]),
    7
  );
  if (
    findEvents(date[0], date[1]) !== undefined &&
    findEvents(date[0], date[1])[1] === 1
  ) {
    info.el.style.backgroundColor = "rgba(255, 167, 0, 0.15)";
    info.el.innerHTML = `<div class='licham'>${date[0]}-${
      date[1]
    }</div> <div class='ngayle'>${
      findEvents(date[0], date[1]) === undefined
        ? ""
        : findEvents(date[0], date[1])[0]
    }</div>`;
  } else if (
    findEventsDuongLich(
      Number(info.el.attributes["data-date"]["nodeValue"].split("-")[2]),
      Number(info.el.attributes["data-date"]["nodeValue"].split("-")[1])
    ) !== undefined &&
    findEventsDuongLich(
      Number(info.el.attributes["data-date"]["nodeValue"].split("-")[2]),
      Number(info.el.attributes["data-date"]["nodeValue"].split("-")[1])
    )[1] === 1
  ) {
    info.el.style.backgroundColor = "rgba(92, 255, 0, 0.15)";
    info.el.innerHTML = `<div class='licham'>${date[0]}-${
      date[1]
    }</div> <div class='ngayle'>${
      findEventsDuongLich(
        Number(info.el.attributes["data-date"]["nodeValue"].split("-")[2]),
        Number(info.el.attributes["data-date"]["nodeValue"].split("-")[1])
      ) === undefined
        ? ""
        : findEventsDuongLich(
            Number(info.el.attributes["data-date"]["nodeValue"].split("-")[2]),
            Number(info.el.attributes["data-date"]["nodeValue"].split("-")[1])
          )[0]
    }</div>`;
  } else {
    info.el.innerHTML = `<div class='licham'>${date[0]}-${
      date[1]
    }</div> <div class='ngayle'>${
      findEvents(date[0], date[1]) === undefined
        ? ""
        : findEvents(date[0], date[1])[0]
    }</div>`;
  }
};

function Calender() {
  return (
    <div>
      <FullCalendar
        dayCellDidMount={renderAmLich}
        timeZone={"local"}
        locale={viLocale}
        plugins={[daygrid, interaction, timegrid, listPlugin]}
        initialView={"dayGridMonth"}
        selectable={true}
        editable={true}
        navLinks={true}
        headerToolbar={{
          start: "today prev,next",
          center: "title",
          end: "listWeek,dayGridMonth,timeGridWeek,timeGridDay",
        }}
        height={"90vh"}
        dateClick={handleDateClick}
        events={[
          {
            title: " BCH237",
            start: "2023-02-28T10:30:00",
            end: "2023-03-01T11:30:00",
            extendedProps: {
              department: "BioChemistry",
            },
            description: "Lecture",
          },
          {
            title: "All Day Event",
            description: "description for All Day Event",
            start: "2023-02-01",
          },
          {
            title: "Long Event",
            description: "description for Long Event",
            start: "2023-02-07",
            end: "2023-02-10",
          },
          {
            groupId: "999",
            title: "Repeating Event",
            description: "description for Repeating Event",
            start: "2023-02-09T16:00:00",
          },
          {
            groupId: "999",
            title: "Repeating Event",
            description: "description for Repeating Event",
            start: "2023-02-16T16:00:00",
          },
          {
            title: "Conference",
            description: "description for Conference",
            start: "2023-02-11",
            end: "2023-02-13",
          },
          {
            title: "Meeting",
            description: "description for Meeting",
            start: "2023-02-12T10:30:00",
            end: "2023-02-12T12:30:00",
          },
          {
            title: "Lunch",
            description: "description for Lunch",
            start: "2023-02-12T12:00:00",
          },
          {
            title: "Meeting",
            description: "description for Meeting",
            start: "2023-02-12T14:30:00",
          },
          {
            title: "Birthday Party",
            description: "description for Birthday Party",
            start: "2023-02-13T07:00:00",
          },
          {
            title: "Click for Google",
            description: "description for Click for Google",
            url: "http://google.com/",
            start: "2023-02-28",
          },
        ]}
        eventClick={function (arg) {
          console.log(arg.el.textContent);
        }}
        eventContent={renderEventContent}
      />
    </div>
  );
}

export default Calender;
