import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '~/store/actions';
import { Spinner, Card } from 'reactstrap';
import Head from '~/layout/head/Head';
import Content from '~/layout/content/Content';
import { Block } from '~/components/block/Block';
import {
    Button,
    Icon,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    PaginationComponent,
} from '~/components/Component';
import { differenceInHours, differenceInMinutes, parseISO, formatDistanceToNow } from 'date-fns';
import { vi } from 'date-fns/locale';
import { Link } from 'react-router-dom';
import config from '~/config';

const Thongbao = () => {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const { thongbao } = useSelector((state) => state.quanly);
    // console.log('thongbao', thongbao)
    const { user } = useSelector((state) => state.app);
    useEffect(() => {
        if (thongbao && user) {
            setData([...thongbao]);
            setUser(user);
        }
    }, [thongbao, user]);
    const [userdata, setUser] = useState(null);
    // console.log('userdata', userdata);
    useEffect(() => {
        if (userdata !== null) {
            dispatch(
                actions.getListThongbao({
                    TrangthaiId: 0,
                    UserId: userdata.id,
                    page: '1',
                    limit: '20',
                    order: 'desc',
                    by: 'id',
                }),
            );
        }
    }, [userdata]);

    const PostNotification = ({ postTime }) => {
        const [notification, setNotification] = useState('');

        useEffect(() => {
            const calculateTimeDifference = () => {
                const currentTime = new Date();
                const diffHours = differenceInHours(currentTime, postTime);
                const diffMinutes = differenceInMinutes(currentTime, postTime) % 60;

                if (diffHours === 0) {
                    setNotification(`${diffMinutes} phút trước`);
                } else if (diffHours < 24) {
                    setNotification(`${diffHours} giờ ${diffMinutes} phút trước`);
                } else if (diffHours < 720) {
                    // 720 hours = 1 month
                    setNotification(formatDistanceToNow(postTime, { locale: vi }) + ' trước');
                } else {
                    setNotification(`${diffHours} giờ ${diffMinutes} phút trước`);
                }
            };

            calculateTimeDifference();
        }, [postTime]);

        return <div>{notification}</div>;
    };
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);

    // Get current list, pagination
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const hendEditThongbao = (idtb, dispatch, UserId) => {
        dispatch(actions.EditThongbao({ id: idtb, TrangthaiId: 1, UserId: UserId }));
    };
    const Danhdaudadoc = () => {
        data.map((item) => {
            dispatch(actions.EditThongbao({ id: item.id, TrangthaiId: 1, UserId: userdata.id }));
        });
    };
    return (
        <React.Fragment>
            {/*<Head title="Doanh nghiệp, Chi nhánh, Phòng ban, Chức Vụ"></Head>*/}
            <Content>
                <Card className="mt-0">
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner card-inner-lg px-4">
                            <h4>Thông báo</h4>
                            <Button color="primary" className="btn-icon px-4 mt-2" onClick={Danhdaudadoc}>
                                Đánh dấu đã đọc
                            </Button>
                        </div>
                    </DataTable>
                </Card>
                <Block>
                    <DataTable className="card-stretch border-0">
                        <div style={{ overflowY: 'hidden' }}>
                            <DataTableBody>
                                <DataTableHead className="col-12 overflow-scroll">
                                    <DataTableRow>
                                        <span className="sub-text">Stt</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text w-300px">Nội dung</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text w-100px">Thời gian</span>
                                    </DataTableRow>
                                    <DataTableRow></DataTableRow>
                                </DataTableHead>
                                {currentItems.length > 0
                                    ? currentItems.map((item, index) => {
                                          return (
                                              <DataTableItem key={item.id}>
                                                  <DataTableRow size=" nk-tb-col-check mb">
                                                      <span className="tb-amount">{index + 1}</span>
                                                  </DataTableRow>
                                                  <DataTableRow>
                                                      <span
                                                          className={
                                                              item.TrangthaiId === 0 ? 'tb-amount' : 'text-muted'
                                                          }
                                                      >
                                                          {item.noidung}
                                                      </span>
                                                  </DataTableRow>
                                                  <DataTableRow>
                                                      <span className="tb-amount">
                                                          <PostNotification postTime={parseISO(item?.createdAt)} />
                                                      </span>
                                                  </DataTableRow>
                                                  <DataTableRow className="nk-tb-col-tools ">
                                                      <ul className="nk-tb-actions ">
                                                          <Link
                                                              to={`${ config.he_thong + item?.url
                                                              }?thong-bao=${item?.loaiId}&xem=${Math.floor(
                                                                  Math.random() * 9999999,
                                                              )}`}
                                                              className="link link-sm"
                                                          >
                                                              <button
                                                                  onClick={() =>
                                                                      hendEditThongbao(item?.id, dispatch, item?.UserId)
                                                                  }
                                                                  className={
                                                                      'btn btn-primary ms-0 badge text-white p-1'
                                                                  }
                                                              >
                                                                  <Icon name="eye-fill"></Icon>
                                                              </button>
                                                          </Link>
                                                      </ul>
                                                  </DataTableRow>
                                              </DataTableItem>
                                          );
                                      })
                                    : null}
                            </DataTableBody>
                        </div>
                        <div className="card-inner">
                            {currentItems.length > 0 ? (
                                <PaginationComponent
                                    itemPerPage={itemPerPage}
                                    totalItems={data.length}
                                    paginate={paginate}
                                    currentPage={currentPage}
                                />
                            ) : (
                                <div className="text-center">
                                    <div>
                                        <Spinner color="primary" />
                                    </div>
                                    <span className="text-silent">Chưa có dữ liệu!</span>
                                </div>
                            )}
                        </div>
                    </DataTable>
                </Block>
            </Content>
        </React.Fragment>
    );
};

export default Thongbao;
