import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {InputForm} from '~/components/Form';
import * as actions from '~/store/actions';
import {validatePhanCa} from '~/request/validatePhanCa';
import '../Modal.scss';
import {Modal, ModalBody} from 'reactstrap';
import Select from 'react-select';
import Content from '~/layout/content/Content';
import {Button, Icon, SelectFinNhanvienfix} from '~/components/Component';
import CalenderPhanca from '~/components/partials/calender/Calender_phanca';
import {addMonths, differenceInWeeks, format, isBefore} from 'date-fns';
import {toast} from 'react-toastify';

const ModalPhanca = ({ show, setShow, fieldList }) => {
    const dispatch = useDispatch();
    const [invalidFields, setInvalidFields] = useState([]);
    const { cauhinhcalamviec } = useSelector((state) => state.cauhinhcalamviec);
    const [payload, setPayload] = useState(fieldList);
    const [dataCalamviec, setDataCalamviec] = useState([]);
    const [showSelect, setShowSelect] = useState(false);
    const [listNhanvien, setlistNhanvien] = useState([]);
    const [open, setOpen] = useState(false);
    const tuan = ['Thứ 2', 'Thứ 3', 'Thứ 4', 'Thứ 5', 'Thứ 6', 'Thứ 7', 'Chủ nhật'];
    const [columnTuan, setColumnTuan] = useState(() => {
        return tuan.map((item, index) => index < 0);
    });
    useEffect(() => {
        show && setPayload(fieldList);
        setlistNhanvien([]);
        setOpen(false);
        setColumnTuan(() => {
            return tuan.map((item, index) => index < 0);
        });
    }, [show]);
    useEffect(() => {
        const dataca = cauhinhcalamviec.map((item) => ({ label: item.tieude, value: item.id, maca: item.macalamviec }));
        setDataCalamviec(dataca);
    }, [cauhinhcalamviec]);
    useEffect(() => {
        setPayload((prev) => ({
            ...prev,
            ['NhanvienId']: listNhanvien,
            ['arr']: columnTuan,
        }));
    }, [listNhanvien, columnTuan]);

    // console.log('columnTuan', columnTuan)
    const dataVaongay = Array.from({ length: 31 }, (_, index) => ({ label: (index + 1).toString(), value: index + 1 }));
    const handleSubmit = async () => {
        let invalids = validate(payload);
        if (invalids === 0) {
            const bd = payload?.ngaybatdau?.split('-');
            const kt = payload?.ngayketthuc?.split('-');
            if (bd && bd.length === 3 && kt && kt.length === 3) {
                if (bd[0] >= '2000' && bd[0] <= '2100' && kt[0] >= '2000' && kt[0] <= '2100') {
                    const ngayBatDau = new Date(payload.ngaybatdau);
                    const ngayKetThuc = new Date(payload.ngayketthuc);
                    if (ngayBatDau <= ngayKetThuc) {
                        const khoangThoiGian = ngayKetThuc.getFullYear() - ngayBatDau.getFullYear();
                        if (khoangThoiGian <= 5) {
                            let datapayload = {
                                data: [
                                    {
                                        tieude: payload.tieude,
                                        ngaybatdau: payload.ngaybatdau,
                                        ngayketthuc: payload.ngayketthuc,
                                        laptheo: payload.laptheo.value,
                                        chukilap: Number(payload.chukilap) !== 0 ? payload.chukilap : 1,
                                        arr: columnTuan,
                                        vaongay: payload?.vaongay?.value,
                                        NhanvienId: listNhanvien,
                                        KhaibaocalamviecId: payload.KhaibaocalamviecId,
                                    },
                                ],
                            };
                            // console.log('check payload', datapayload)
                            dispatch(actions.NewPhanca(datapayload));
                        }else{
                            toast.error("Ngày kết thúc và ngày bắt đầu không được vượt quá 5 năm");
                        }
                    }else{
                        toast.error("Ngày kết thúc phải lớn hơn hoặc bằng ngày bắt đầu");
                    }
                }else{
                    toast.error("Ngày bắt đầu Chỉ áp dụng từ năm 2000 đến 2100.");
                }
            }
        }
    };

    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validatePhanCa(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validatePhanCa(item[0], item[1])]);
                invalids++;
            }
        });
        return invalids;
    };
    const dataLaptheo = [
        {
            label: 'Theo ngày',
            value: 0,
        },
        {
            label: 'Theo tuần',
            value: 1,
        },
        {
            label: 'Theo tháng',
            value: 2,
        },
    ];

    // useEffect(() => {
    //     if (payload.ngaybatdau !== '' && payload.ngayketthuc !== '') {
    //         const ngayBatDau = new Date(payload.ngaybatdau);
    //         const ngayKetThuc = new Date(payload.ngayketthuc);
    //         const soTuan = differenceInWeeks(ngayKetThuc, ngayBatDau);
    //         const newEvents = [];
    //         for (let date = ngayBatDau; date <= ngayKetThuc; date.setDate(date.getDate() + 1)) {
    //             const ngay = new Date(date);
    //             if (payload.KhaibaocalamviecId.length > 0) {
    //                 for (const day of payload.KhaibaocalamviecId) {
    //                     const macalv = dataCalamviec.find((item) => item && item.value === day.value);
    //                     const chukilap = Number(payload.chukilap) !== 0 ? payload.chukilap : 1;
    //                     const laptheo = payload?.laptheo?.value;
    //                     if (Number(laptheo) === 1) {
    //                         const vietnameseDaysOfWeek = [7, 1, 2, 3, 4, 5, 6];
    //                         const h = vietnameseDaysOfWeek[ngay.getDay()];
    //                         const cc = payload.arr;
    //                         const checkthudilam = cc[h - 1];

    //                         if (checkthudilam === true) {
    //                             const ngayVao = new Date(payload.ngaybatdau);
    //                             const tuanCuaNgay = differenceInWeeks(ngay, ngayVao) + 1;
    //                             const filterArray = (arr, chuki) => {
    //                                 const filteredArray = [];
    //                                 for (let i = 0; i < arr.length; i++) {
    //                                     if (i % chuki === 0) {
    //                                         filteredArray.push(arr[i]);
    //                                     }
    //                                 }
    //                                 return filteredArray;
    //                             }
    //                             const arrnum = Array.from({ length: soTuan }, (_, index) => index + 1);
    //                             const abb = filterArray(arrnum, chukilap);
    //                             let chukidkphanca = abb.every((value) => Number(value) !== Number(tuanCuaNgay));
    //                             if (chukidkphanca === false) {
    //                                 const filteredArr = {
    //                                     id: 'default-event-id-' + Math.floor(Math.random() * 9999999),
    //                                     title: macalv.maca,
    //                                     start: format(ngay, 'yyyy-MM-dd'),
    //                                 };

    //                                 newEvents.push(filteredArr);
    //                             }
    //                         }
    //                     } else if (Number(laptheo) === 0) {
    //                         const arr = [];
    //                         const laptheoBatDau = new Date(payload.ngaybatdau);
    //                         const laptheoKetThuc = new Date(payload.ngayketthuc);
    //                         while (laptheoBatDau <= laptheoKetThuc) {
    //                             arr.push(laptheoBatDau.toISOString().split('T')[0]);
    //                             laptheoBatDau.setDate(laptheoBatDau.getDate() + chukilap);
    //                         }
    //                         const chukidkphanca1 = arr.every((value) => value !== ngay.toISOString().split('T')[0]);

    //                         if (!chukidkphanca1) {
    //                             const filteredArr = {
    //                                 id: 'default-event-id-' + Math.floor(Math.random() * 9999999),
    //                                 title: macalv.maca,
    //                                 start: ngay.toISOString().split('T')[0],
    //                             };
    //                             newEvents.push(filteredArr);
    //                         }
    //                     } else if (Number(laptheo) === 2) {
    //                         const ngaytheothang = format(ngay, 'yyyy-MM-dd').split('-');
    //                         const ngaytheothangbatdau = new Date(payload.ngaybatdau);
    //                         const ngaytheothangkethuc = new Date(payload.ngayketthuc);
    //                         let ar = [];
    //                         let current_date = ngaytheothangbatdau;
    //                         while (
    //                             isBefore(current_date, ngaytheothangkethuc) ||
    //                             format(current_date, 'M') === format(ngaytheothangkethuc, 'M')
    //                         ) {
    //                             ar.push(format(current_date, 'M'));
    //                             current_date = addMonths(current_date, 1);
    //                         }
    //                         const filterArrayth = (arr, chuki) =>{
    //                             const filteredArray = [];
    //                             for (let i = 0; i < arr.length; i++) {
    //                               if (i % chuki === 0) {
    //                                 filteredArray.push(arr[i]);
    //                               }
    //                             }
    //                             return filteredArray;
    //                           }
    //                           const th = filterArrayth(ar, chukilap);
    //                           let chukidkphancath = th.every(
    //                             (value) => Number(value) !== Number(ngaytheothang[1])
    //                           );
    //                           if (Number(payload?.vaongay?.value) === Number(ngaytheothang[2])) {
    //                             if (chukidkphancath === false) {
    //                                 const filteredArr = {
    //                                     id: 'default-event-id-' + Math.floor(Math.random() * 9999999),
    //                                     title: macalv.maca,
    //                                     start: ngay.toISOString().split('T')[0],
    //                                 };
    //                                 newEvents.push(filteredArr);
    //                             }
    //                           }
    //                     }
    //                 }
    //             }
    //         }
    //         updateEvent(newEvents);
        
    //     } else {
    //         updateEvent([]);
    //     }
    // }, [payload]);
    useEffect(() => {
        if (payload.ngaybatdau !== '' && payload.ngayketthuc !== '') {
            const bd = payload?.ngaybatdau?.split('-');
            const kt = payload?.ngayketthuc?.split('-');
            if (bd && bd.length === 3 && kt && kt.length === 3) {
                if (bd[0] >= '2000' && bd[0] <= '2100' && kt[0] >= '2000' && kt[0] <= '2100') {
                    const ngayBatDau = new Date(payload.ngaybatdau);
                    const ngayKetThuc = new Date(payload.ngayketthuc);
                    if (ngayBatDau <= ngayKetThuc) {
                        const khoangThoiGian = ngayKetThuc.getFullYear() - ngayBatDau.getFullYear();
                        if (khoangThoiGian <= 2) {
                            // toast.success('ok');
                            const ngayBatDau = new Date(payload.ngaybatdau);
                            const ngayKetThuc = new Date(payload.ngayketthuc);
                            const soTuan = differenceInWeeks(ngayKetThuc, ngayBatDau);
                            const newEvents = [];
                            for (let date = ngayBatDau; date <= ngayKetThuc; date.setDate(date.getDate() + 1)) {
                                const ngay = new Date(date);
                                if (payload.KhaibaocalamviecId.length > 0) {
                                    for (const day of payload.KhaibaocalamviecId) {
                                        const macalv = dataCalamviec.find((item) => item && item.value === day.value);
                                        const chukilap = Number(payload.chukilap) !== 0 ? payload.chukilap : 1;
                                        const laptheo = payload?.laptheo?.value;
                                        if (Number(laptheo) === 1) {
                                            const vietnameseDaysOfWeek = [7, 1, 2, 3, 4, 5, 6];
                                            const h = vietnameseDaysOfWeek[ngay.getDay()];
                                            const cc = payload.arr ? payload.arr : [];
                                            const checkthudilam = cc[h - 1];

                                            if (checkthudilam === true) {
                                                const ngayVao = new Date(payload.ngaybatdau);
                                                const tuanCuaNgay = differenceInWeeks(ngay, ngayVao) + 1;
                                                const filterArray = (arr, chuki) => {
                                                    const filteredArray = [];
                                                    for (let i = 0; i < arr.length; i++) {
                                                        if (i % chuki === 0) {
                                                            filteredArray.push(arr[i]);
                                                        }
                                                    }
                                                    return filteredArray;
                                                };
                                                const arrnum = Array.from({ length: soTuan + 1 }, (_, index) => index + 1);
                                                const abb = filterArray(arrnum, chukilap);
                                                let chukidkphanca = abb.every(
                                                    (value) => Number(value) !== Number(tuanCuaNgay),
                                                );
                                                if (chukidkphanca === false) {
                                                    const filteredArr = {
                                                        id: 'default-event-id-' + Math.floor(Math.random() * 9999999),
                                                        title: macalv.maca,
                                                        start: format(ngay, 'yyyy-MM-dd'),
                                                    };

                                                    newEvents.push(filteredArr);
                                                }
                                            }
                                        } else if (Number(laptheo) === 0) {
                                            const arr = [];
                                            const laptheoBatDau = new Date(payload.ngaybatdau);
                                            const laptheoKetThuc = new Date(payload.ngayketthuc);
                                            while (laptheoBatDau <= laptheoKetThuc) {
                                                arr.push(laptheoBatDau.toISOString().split('T')[0]);
                                                laptheoBatDau.setDate(laptheoBatDau.getDate() + chukilap);
                                            }
                                            const chukidkphanca1 = arr.every(
                                                (value) => value !== ngay.toISOString().split('T')[0],
                                            );

                                            if (!chukidkphanca1) {
                                                const filteredArr = {
                                                    id: 'default-event-id-' + Math.floor(Math.random() * 9999999),
                                                    title: macalv.maca,
                                                    start: ngay.toISOString().split('T')[0],
                                                };
                                                newEvents.push(filteredArr);
                                            }
                                        } else if (Number(laptheo) === 2) {
                                            const ngaytheothang = format(ngay, 'yyyy-MM-dd').split('-');
                                            const ngaytheothangbatdau = new Date(payload.ngaybatdau);
                                            const ngaytheothangkethuc = new Date(payload.ngayketthuc);
                                            let ar = [];
                                            let current_date = ngaytheothangbatdau;
                                            while (
                                                isBefore(current_date, ngaytheothangkethuc) ||
                                                format(current_date, 'M') === format(ngaytheothangkethuc, 'M')
                                            ) {
                                                ar.push(format(current_date, 'M'));
                                                current_date = addMonths(current_date, 1);
                                            }
                                            const filterArrayth = (arr, chuki) => {
                                                const filteredArray = [];
                                                for (let i = 0; i < arr.length; i++) {
                                                    if (i % chuki === 0) {
                                                        filteredArray.push(arr[i]);
                                                    }
                                                }
                                                return filteredArray;
                                            };
                                            const th = filterArrayth(ar, chukilap);
                                            let chukidkphancath = th.every(
                                                (value) => Number(value) !== Number(ngaytheothang[1]),
                                            );
                                            if (Number(payload?.vaongay?.value) === Number(ngaytheothang[2])) {
                                                if (chukidkphancath === false) {
                                                    const filteredArr = {
                                                        id: 'default-event-id-' + Math.floor(Math.random() * 9999999),
                                                        title: macalv.maca,
                                                        start: ngay.toISOString().split('T')[0],
                                                    };
                                                    newEvents.push(filteredArr);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            updateEvent(newEvents);
                        } else {
                            // toast.error("Ngày kết thúc vượt quá 2 năm");
                            updateEvent([]);
                        }
                    } else {
                        updateEvent([]);
                        // toast.error("Ngày kết thúc phải lớn hơn hoặc bằng ngày bắt đầu");
                    }
                }else{
                    updateEvent([]);
                }
            }

        }else{
            updateEvent([]);
        }
    }, [payload]);
    const [mockEvents, updateEvent] = useState([]);

    // console.log(mockEvents);
    return (
        <React.Fragment>
            <Content>
                <Modal
                    isOpen={show}
                    className="modal-dialog-centered modal-90w"
                    size="lg"
                    toggle={() => setShow(false)}
                >
                    <ModalBody>
                        <a
                            href="#dropdownitem"
                            onClick={(ev) => {
                                ev.preventDefault();
                                setShow(false);
                            }}
                            className="close"
                        >
                            <Icon name="cross-sm"></Icon>
                        </a>
                        <h5 className="title mb-5">Thêm mới</h5>
                        <div className="row">
                            <div className="form-group col-md-4">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Tiêu đề'}
                                    value={payload.tieude}
                                    setValue={setPayload}
                                    keyPayload={'tieude'}
                                    type="text"
                                    validate
                                />
                            </div>
                            <div className="form-group col-md-4">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Ngày bắt đầu'}
                                    value={payload.ngaybatdau}
                                    setValue={setPayload}
                                    keyPayload={'ngaybatdau'}
                                    type="date"
                                    validate
                                />
                            </div>
                            <div className="form-group col-md-4">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Ngày kết thúc'}
                                    value={payload.ngayketthuc}
                                    setValue={setPayload}
                                    keyPayload={'ngayketthuc'}
                                    type="date"
                                    validate
                                />
                            </div>
                            <div className="form-group col-md-12">
                                <label htmlFor="inputZip" className="mb-2 fw-bold">
                                    <span>Ca làm việc <b className="text-danger">(*)</b></span>
                                </label>
                                <Select
                                    value={payload.KhaibaocalamviecId}
                                    onChange={(Select) =>
                                        setPayload((prev) => ({ ...prev, ['KhaibaocalamviecId']: Select }))
                                    }
                                    options={dataCalamviec}
                                    placeholder={'Ca làm việc'}
                                    name="KhaibaocalamviecId"
                                    onFocus={() => setInvalidFields([])}
                                    isMulti
                                />
                                {invalidFields.length > 0 &&
                                    invalidFields.some((i) => i.name === 'KhaibaocalamviecId') && (
                                        <small className="text-danger">
                                            {invalidFields.find((i) => i.name === 'KhaibaocalamviecId')?.message}
                                        </small>
                                    )}
                            </div>
                            <div className="form-group col-md-12" onClick={() => setShowSelect(true)}>
                                <label className="mb-2 fw-bold">
                                    <span>
                                        Đối tượng áp dụng <b className="text-danger">(*)</b>
                                    </span>
                                </label>
                                <Select
                                    value={payload?.NhanvienId}
                                    isMulti
                                    name="NhanvienId"
                                    onFocus={() => setInvalidFields([])}
                                />
                                {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'NhanvienId') && (
                                    <small className="text-danger">
                                        {invalidFields.find((i) => i.name === 'NhanvienId')?.message}
                                    </small>
                                )}
                            </div>
                            <div className="form-group col-md-6 ">
                                <label htmlFor="inputZip" className="mb-2 fw-bold">
                                    <span>
                                        Lặp theo 
                                    </span>
                                </label>
                                <Select
                                    value={payload.laptheo}
                                    onChange={(Select) => setPayload((prev) => ({ ...prev, ['laptheo']: Select }))}
                                    options={dataLaptheo}
                                    placeholder={'Lặp theo'}
                                    name="laptheo"
                                    onFocus={() => setInvalidFields([])}
                                />
                                {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'laptheo') && (
                                    <small className="text-danger">
                                        {invalidFields.find((i) => i.name === 'laptheo')?.message}
                                    </small>
                                )}
                            </div>
                            <div className="form-group col-md-6 ">
                                <div className="form-group">
                                    <label htmlFor="inputZip" className="mb-2 fw-bold">
                                        <span>Chu kì lặp</span>
                                    </label>
                                    <div className="form-control-wrap">
                                        <div className="form-text-hint">
                                            <span className="overline-title">
                                                {payload?.laptheo?.value === 0
                                                    ? 'Ngày'
                                                    : payload?.laptheo?.value === 1
                                                    ? 'Tuần'
                                                    : 'Tháng'}
                                            </span>
                                        </div>
                                        <input
                                            value={Number(payload.chukilap) !== 0 ? payload.chukilap : 1}
                                            type="number"
                                            className="form-control px-2"
                                            id="chukilap"
                                            onChange={(e) =>
                                                setPayload((prev) => ({ ...prev, ['chukilap']: e.target.value }))
                                            }
                                        />
                                        {invalidFields.length > 0 &&
                                            invalidFields.some((i) => i.name === 'chukilap') && (
                                                <small className="text-danger">
                                                    {invalidFields.find((i) => i.name === 'chukilap')?.message}
                                                </small>
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {payload?.laptheo?.value === 1 ? (
                            <>
                                <div className="row mt-1">
                                    <div className="card-tools me-n1">
                                        <ul className="btn-toolbar gx-5">
                                            {tuan.length > 0 ? (
                                                tuan.map((item, index) => (
                                                    <li key={index}>
                                                        <div
                                                            className="custom-control custom-checkbox mt-2"
                                                            style={{ zIndex: 0 }}
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                id={`columnTuan-${index}`}
                                                                checked={columnTuan[index]}
                                                                onChange={(e) =>
                                                                    setColumnTuan((prev) => {
                                                                        const newColumns = [...prev];
                                                                        newColumns[index] = e.target.checked;
                                                                        return newColumns;
                                                                    })
                                                                }
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor={`columnTuan-${index}`}
                                                            >
                                                                {item}
                                                            </label>
                                                        </div>
                                                    </li>
                                                ))
                                            ) : (
                                                <></>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </>
                        ) : payload?.laptheo?.value === 2 ? (
                            <>
                                <div className="row ">
                                    <div className="form-group col-md-6 ">
                                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                                            <span>Vào ngày</span>
                                        </label>
                                        <Select
                                            value={payload.vaongay}
                                            onChange={(Select) =>
                                                setPayload((prev) => ({ ...prev, ['vaongay']: Select }))
                                            }
                                            options={dataVaongay}
                                            name="vaongay"
                                            onFocus={() => setInvalidFields([])}
                                        />
                                    </div>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                        <div className="col-12 mt-5 text-end">
                            <Button color="s" onClick={() => setOpen(!open)}>
                                {open === true ? 'Bỏ xem trước phân ca' : 'Xem trước phân ca'}
                            </Button>
                            {open === true && <CalenderPhanca events={mockEvents} />}
                        </div>
                        <div className="col-12 mt-5">
                            <Button color="primary" onClick={handleSubmit}>
                                Lưu lại
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
            </Content>

            <SelectFinNhanvienfix
                showSelect={showSelect}
                typeSelect={true}
                // listNhanvien={listNhanvien}
                setlistNhanvien={setlistNhanvien}
                setShowSelect={() => setShowSelect(false)}
            />
        </React.Fragment>
    );
};

export default ModalPhanca;
