import actionTypes from "../actions/actionTypes";
const initState = {
    taikhoannganhang: [],
    msg: '',
    count: 0,
    newtaikhoannganhang: []
}

const nganhangReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.GET_LIST_NGAN_HANG:
            return {
                ...state,
                taikhoannganhang: action?.taikhoannganhang?.rows || [],
                msg: 'Ok' || '',
                count: action?.taikhoannganhang?.count || 0
            }
        case actionTypes.POST_NEW_NGAN_HANG:
        case actionTypes.PUT_EDIT_NGAN_HANG:
        case actionTypes.DELETE_NGAN_HANG:
            return {
                ...state,
                msg: action.msg || '',
                newtaikhoannganhang: action?.newtaikhoannganhang || []
            }
        default:
            return state;
    }

}

export default nganhangReducer