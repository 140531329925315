import React, { useState } from "react";
import UserAvatar from "~/components/user/UserAvatar";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import { Icon } from "~/components/Component";
import { LinkList, LinkItem } from "~/components/links/Links";
import config from '~/config';
// import { handleSignout } from "../../../../utils/Utils";
import { useDispatch } from 'react-redux'
import * as actions from '~/store/actions'
const User = ({user}) => {

  const [open, setOpen] = useState(false);
  // console.log(open)
  const toggle = () => setOpen((prevState) => !prevState);
  // console.log('first', toggle)
  const dispatch = useDispatch()
  return (
    <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#toggle"
        className="dropdown-toggle"
        onClick={(ev) => {
          ev.preventDefault();
        }}
      >
        <div className="user-toggle">
          <UserAvatar icon="user-alt" className="sm" />
          <div className="user-info d-none d-xl-block">
            <div
              className={`user-status ${
                window.location.pathname.split("/")[2] === "invest" ? "user-status-unverified" : ""
              }`}
            >
              {window.location.pathname.split("/")[2] === "invest" ? "Unverified" : "Admininstrator"}
            </div>
            <div className="user-name dropdown-indicator">{user.username}</div>
          </div>
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-md dropdown-menu-s1">
        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
          <div className="user-card sm">
            <div className="user-avatar">
              <span>AB</span>
            </div>
            <div className="user-info">
              <span className="lead-text">{user.username}</span>
              <span className="sub-text">{user.email}</span>
            </div>
          </div>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <LinkItem
              link={config.user_profile_regular}
              icon="user-alt"
              // onClick={toggle}
            >
              Xem hồ sơ
            </LinkItem>
            {+user.phanquyen === 0 &&
            <LinkItem
              link={config.user_list_all}
              icon="setting-alt"
              onClick={toggle}
            >
              Quản lý người dùng
            </LinkItem>
            }
            
            {/* <LinkItem
              link={
                window.location.pathname.split("/")[2] === "invest"
                  ? "/invest/profile-activity"
                  : "/user-profile-activity"
              }
              icon="activity-alt"
              onClick={toggle}
            >
              Login Activity aa
            </LinkItem> */}
          </LinkList>
        </div>
        <div className="dropdown-inner" onClick={() => {dispatch(actions.logout())}}>
          <LinkList>
          <a href='#'>
              <Icon name="signout"></Icon>
              <span>Đăng xuất</span>
            </a>
          </LinkList>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default User;
