import React, {useState, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {InputForm, Button} from '~/components/Form'
import * as actions from '~/store/actions'
import {validateBangChamCong} from "~/request/validateBangChamCong";
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';

const ModalBangchotcong = ({show, setShow, fieldList}) => {
    const dispatch = useDispatch()
    const [invalidFields, setInvalidFields] = useState([])
    const [payload, setPayload] = useState(fieldList)
    const [alertErr, setAlertErr] = useState('')
    useEffect(() => {
        show && setPayload(fieldList)
    }, [show])

    const handleSubmit = async () => {
        let invalids = validate(payload)
        if (invalids === 0) {
            if (checkDate(payload.ngaybatdau, payload.ngayketthuc)) {
                let datapayload = {
                    tieude: payload.tieude,
                    mota: payload.mota,
                    ngaybatdau: payload.ngaybatdau,
                    ngayketthuc: payload.ngayketthuc,
                    nguoitao: 1,
                    TrangthaiId: payload.TrangthaiId.value,
                }
                setAlertErr('')
                dispatch(actions.NewBangchotcong(datapayload))
                setShow(true)
            } else {
                setAlertErr('Ngày kết thúc không được nhỏ hơn ngay bắt đầu và phải cùng trong 1 tháng, 1 năm')
            }
        }
    }
 
    const validate = (payload) => {
        let invalids = 0
        let fields = Object.entries(payload)
        fields.forEach(item => {
            if (validateBangChamCong(item[0], item[1]) !== undefined) {
                setInvalidFields(prev => [...prev, validateBangChamCong(item[0], item[1])])
                invalids++
            }
        })
        return invalids
    }
    const checkDate = (startDate, finishDate) => {
        const nowStart = new Date(startDate);
        let yearStart = Number(nowStart.getFullYear());
        let monthStart = Number(nowStart.getMonth() + 1);
        
        const nowFinish = new Date(finishDate);
        let yearFinish = Number(nowFinish.getFullYear());
        let monthFinish = Number(nowFinish.getMonth() + 1);
        return nowStart < nowFinish && monthStart === monthFinish && yearStart === yearFinish;
    };
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Thêm mới bảng chấm công
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Tên bảng công'}
                            value={payload.tieude}
                            setValue={setPayload}
                            keyPayload={'tieude'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Mô tả'}
                            value={payload.mota}
                            setValue={setPayload}
                            keyPayload={'mota'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-6 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ngày bắt đầu'}
                            value={payload.ngaybatdau}
                            setValue={setPayload}
                            keyPayload={'ngaybatdau'}
                            type="date"
                            validate
                        />
                    </div>
                    <div className="form-group col-6 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ngày kết thúc'}
                            value={payload.ngayketthuc}
                            setValue={setPayload}
                            keyPayload={'ngayketthuc'}
                            type="date"
                            validate
                        />
                    </div>
                    
                    <div className="form-group col-md-12" id="alertDateErr">
                        {alertErr}
                    </div>
                </div>
                
                <div className='col-12 mt-5'>
                    <Button
                        text='Lưu'
                        bClass='btn btn-primary px-5'
                        textColor
                        onClick={handleSubmit}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ModalBangchotcong;

