import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {InputForm, Button} from '~/components/Form'
import * as actions from '~/store/actions'
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import {
    dataSelectActive,
} from '~/utils/Datacustom'
import {validateTaiKhoanNganHang} from "~/request/validateTaiKhoanNganHang";

const ModalEditNPT = ({show, setShow, dataNPT}) => {
    const [invalidFields, setInvalidFields] = useState([])
    const dispatch = useDispatch()
    const [formValue, setformValue] = useState([]);
    
    useEffect(() => {
        
        show && dataNPT && setformValue({
            id: dataNPT?.id,
            NhanvienId: dataNPT?.NhanvienId,
            hoten: dataNPT?.hoten,
            sodienthoai: dataNPT?.sodienthoai,
            diachi: dataNPT?.diachi,
            ngaysinh: dataNPT?.ngaysinh,
            thoidiemtinhgiamtrutu: dataNPT?.thoidiemtinhgiamtrutu,
            thoidiemtinhgiamtruden: dataNPT?.thoidiemtinhgiamtruden,
            quoctich: dataNPT?.quoctich,
            cmnd: dataNPT?.cmnd,
            giayto: dataNPT?.giayto,
            so: dataNPT?.so,
            quyenso: dataNPT?.quyenso,
            quocgia: dataNPT?.quocgia,
            tinh: dataNPT?.tinh,
            quanhuyen: dataNPT?.quanhuyen,
            quanhevsnhanvien: dataNPT?.quanhevsnhanvien,
            xaphuong: dataNPT?.xaphuong,
            masothue: dataNPT?.masothue,
            
        })
    }, [dataNPT, show])
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setformValue(prev => ({...prev, [statename]: selected}))
        
    }
    const validate = (payload) => {
        let invalids = 0
        let fields = Object.entries(payload)
        fields.forEach(item => {
            if (validateTaiKhoanNganHang(item[0], item[1]) !== undefined) {
                setInvalidFields(prev => [...prev, validateTaiKhoanNganHang(item[0], item[1])])
                invalids++
            }
        })
        return invalids
    }
    
    
    const handleSubmit = async () => {
        let invalids = validate(formValue)
        if (invalids === 0) {
            let data = {
                "data": [
                    {
                        id: dataNPT?.id,
                        hoten: formValue.hoten,
                        sodienthoai: formValue.sodienthoai,
                        diachi: formValue.diachi,
                        ngaysinh: formValue.ngaysinh,
                        thoidiemtinhgiamtrutu: formValue.thoidiemtinhgiamtrutu,
                        thoidiemtinhgiamtruden: formValue.thoidiemtinhgiamtruden,
                        quoctich: formValue.quoctich,
                        cmnd: formValue.cmnd,
                        giayto: formValue.giayto,
                        so: formValue.so,
                        quyenso: formValue.quyenso,
                        quocgia: formValue.quocgia,
                        tinh: formValue.tinh,
                        quanhuyen: formValue.quanhuyen,
                        xaphuong: formValue.xaphuong,
                        masothue: formValue.masothue,
                        quanhevsnhanvien: formValue.quanhevsnhanvien,
                    }
                ]
            }
            let response = dispatch(actions.EditNguoiPhuThuoc(data))
            response.status === 200 ? setShow(false) : setShow(true)
        }
    }
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Sửa tài thông tin người phụ thuộc
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Họ tên NPT'}
                            value={formValue.hoten}
                            setValue={setformValue}
                            keyPayload={'hoten'}
                            type='text'
                            validate
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Địa chỉ'}
                            value={formValue.diachi}
                            setValue={setformValue}
                            keyPayload={'diachi'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Mã số thuế NPT'}
                            value={formValue.masothue}
                            setValue={setformValue}
                            keyPayload={'masothue'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Quan hệ với nhân viên'}
                            value={formValue.quanhevsnhanvien}
                            setValue={setformValue}
                            keyPayload={'quanhevsnhanvien'}
                            type='text'
                            validate
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Số điện thoại'}
                            value={formValue.sodienthoai}
                            setValue={setformValue}
                            keyPayload={'sodienthoai'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ngày sinh'}
                            value={formValue.ngaysinh}
                            setValue={setformValue}
                            keyPayload={'ngaysinh'}
                            type='date'
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Bắt đầu tính giảm trừ'}
                            value={formValue.thoidiemtinhgiamtrutu}
                            setValue={setformValue}
                            keyPayload={'thoidiemtinhgiamtrutu'}
                            type='date'
                            validate
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Kết thúc tinh giảm trừ'}
                            value={formValue.thoidiemtinhgiamtruden}
                            setValue={setformValue}
                            keyPayload={'thoidiemtinhgiamtruden'}
                            type='date'
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Quốc tịch'}
                            value={formValue.quoctich}
                            setValue={setformValue}
                            keyPayload={'quoctich'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'CMT/CCCD'}
                            value={formValue.cmnd}
                            setValue={setformValue}
                            keyPayload={'cmnd'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Giấy tờ'}
                            value={formValue.giayto}
                            setValue={setformValue}
                            keyPayload={'giayto'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Số'}
                            value={formValue.so}
                            setValue={setformValue}
                            keyPayload={'so'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Quyển số'}
                            value={formValue.quyenso}
                            setValue={setformValue}
                            keyPayload={'quyenso'}
                            type='number'
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Quốc gia'}
                            value={formValue.quocgia}
                            setValue={setformValue}
                            keyPayload={'quocgia'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Tỉnh'}
                            value={formValue.tinh}
                            setValue={setformValue}
                            keyPayload={'tinh'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Quận huyện'}
                            value={formValue.quanhuyen}
                            setValue={setformValue}
                            keyPayload={'quanhuyen'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Xã phường'}
                            value={formValue.xaphuong}
                            setValue={setformValue}
                            keyPayload={'xaphuong'}
                            type='text'
                        />
                    </div>
                    
                
                </div>
                <div className='col-12 mt-5'>
                    <Button
                        text='Cập nhật'
                        bClass='btn btn-primary px-5'
                        textColor
                        onClick={handleSubmit}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ModalEditNPT;

