export const validateLoaiKiemDinh = (fieldName, value) => {
    let regNumber = /^[0-9\b]+$/;
    switch (fieldName) {
        case 'tenloaikiemdinh':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Tên kiểm định không được để trống'
                }
            }
            break;
        case 'ghichu':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Ghi chú không được để trống'
                }
            }
            break;
        
        default:
            break;
    }
}