export const validateNhomnv = (fieldName, value) => {
    switch (fieldName) {
        case 'tennhom':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'motanhom':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        
        default:
            break;
    }
}