import {Table} from "reactstrap";
import React from "react";
import {PreviewAltCard} from "~/components/preview/Preview";
import AudienceOverview from "~/components/partials/analytics/audience-overview/AudienceOverview";
import {SetDatadrawLineChart} from "~/pages/Admin/Modal/Baocao/ComponentChart";

export const ComponentBienDongNhanSuTongQuan = (data) => { //Table 1 : Bảng tông quan
    const dataBienDongNhanSu = data?.dataBienDongNS?.dataBienDongNhanSu
    const datatotal_month = data?.dataBienDongNS?.total_month
    const colorLine = ['#ff0000', '#95ff87', '#02c7ff', '#9d72ff', '#ffdd11', '#FF9100FF', '#003A07FF', '#11dd55']
    const dataChartLine = dataBienDongNhanSu?.map((item, ind) => (
        {
            data: item?.data,
            color: colorLine[ind],
            label: item?.title,
        }
    ))
   
    const chartData = SetDatadrawLineChart(dataChartLine, datatotal_month, '')
    const settingChart = {
        description: 'Mô tả sự biến động nhân sự trong năm',
        title: 'Biến động nhân sự trong năm',
        title_out1: '',
        title_out2: '',
        data: chartData,
    }
    return <div className="col-md-12 mt-3 row">
        
        <div className="col-12">
            
            <Table className="table table-bordered table-striped table-hover">
                <thead>
                <tr>
                    <th className="w-25">#</th>
                    {datatotal_month?.length > 0
                        ? datatotal_month?.map((item) => {
                            return (
                                <th className="text-center ">{item}</th>
                            )
                        })
                        : null}
                    <th className="text-center bg-danger text-white">Tổng</th>
                </tr>
                </thead>
                <tbody>
                {dataBienDongNhanSu?.length > 0
                    ? dataBienDongNhanSu?.map((item, index) => {
                        return (
                            <tr>
                                <th className="w-25">{item?.title}</th>
                                {item?.data?.length > 0
                                    ? item?.data?.map((items, indexs) => {
                                        // console.log('dataBienDongVitriss',dataBienDongVitriss)
                                        return (
                                            <td className="text-center">{items}</td>
                                        )
                                    })
                                    : null}
                                <td className="text-center fw-bold bg-danger text-white">{item?.data?.reduce((a, v) => a = a + v, 0)}</td>
                            </tr>
                        )
                    })
                    : null}
                
                </tbody>
            </Table>
        </div>
        <div className="col-12">
            <PreviewAltCard className="h-100">
                <AudienceOverview
                    dataChart={settingChart?.data}
                    titleChart={settingChart?.title}
                    descriptionChart={settingChart?.description}
                    titleOut={[settingChart?.title_out1, settingChart?.title_out2]}
                />
            </PreviewAltCard>
        </div>
        <div className="col-md-12 mt-3 row">
            <div className="col-12">
                <div className="pb-0">
                    <h6 className="overline-title text-primary">So sánh nhân sự
                        tuyển dụng và nhân sự nghỉ việc trong năm</h6>
                </div>
                <Table className="table table-bordered table-striped table-hover">
                    <thead>
                    <tr>
                        <th className="w-25">#</th>
                        {datatotal_month?.length > 0
                            ? datatotal_month?.map((item) => {
                                return (
                                    <th className="text-center ">{item}</th>
                                )
                            })
                            :
                            null
                        }
                        <th className="text-center bg-danger text-white">Tổng</th>
                    </tr>
                    </thead>
                    <tbody>
                    {dataBienDongNhanSu?.length > 0
                        ? dataBienDongNhanSu?.map((item, index) => {
                            if (index === 1 || index === 2 || index === 4) {
                                return (
                                    <>
                                        <tr>
                                            <th className="w-25">{item?.title}</th>
                                            {item?.data?.length > 0
                                                ? item?.data?.map((items) => {
                                                    return (
                                                        <td className="text-center">{items}</td>
                                                    )
                                                })
                                                : null}
                                            <td className="text-center fw-bold bg-danger text-white">{item?.data?.reduce((a, v) => a + v, 0)}</td>
                                        </tr>
                                    
                                    </>
                                
                                )
                            }
                        })
                        : null}
                    
                    </tbody>
                </Table>
            </div>
            <div className="col-12 mt-3">
                <div className="pb-0">
                    <h6 className="overline-title text-primary">
                        Tuyển dụng nhân sự trong năm
                    </h6>
                </div>
                <Table className="table table-bordered table-striped table-hover">
                    <thead>
                    <tr>
                        <th className="w-25">#</th>
                        {datatotal_month?.length > 0
                            ? datatotal_month?.map((item) => {
                                return (
                                    <th className="text-center ">{item}</th>
                                )
                            })
                            : null}
                        <th className="text-center bg-danger text-white">Tổng</th>
                    </tr>
                    </thead>
                    <tbody>
                    {dataBienDongNhanSu?.length > 0
                        ? dataBienDongNhanSu?.map((item, index) => {
                            if (index === 1) {
                                return (
                                    <>
                                        <tr>
                                            <th className="w-25">{item?.title}</th>
                                            {item?.data?.length > 0
                                                ? item?.data?.map((items, indexs) => {
                                                    return (
                                                        <td className="text-center">{items}</td>
                                                    )
                                                })
                                                : null}
                                            <td className="text-center fw-bold bg-danger text-white">{item?.data?.reduce((a, v) => a + v, 0)}</td>
                                        </tr>
                                        <tr>
                                            <th className="w-25">Tỷ lệ</th>
                                            {item?.data?.length > 0
                                                ? item?.data?.map((items, indexs) => {
                                                    let abcNhanSu = 0
                                                    item?.data?.reduce((a, v) => a + v, 0) === 0 ? abcNhanSu = 0 : abcNhanSu = (items * 100 / (item?.data?.reduce((a, v) => a + v, 0))).toFixed(2)
                                                    return (
                                                        <td className="text-center">{abcNhanSu}%</td>
                                                    )
                                                })
                                                : null}
                                            <td className="text-center fw-bold bg-danger text-white"></td>
                                        </tr>
                                    </>
                                
                                )
                            }
                        })
                        : null}
                    
                    </tbody>
                </Table>
            </div>
            <div className="col-12 mt-3">
                <div className="pb-0">
                    <h6 className="overline-title text-primary">Nhân sự nghỉ việc
                        trong năm</h6>
                </div>
                <Table className="table table-bordered table-striped table-hover">
                    <thead>
                    <tr>
                        <th className="w-25">#</th>
                        {datatotal_month?.length > 0
                            ? datatotal_month?.map((item) => {
                                return (
                                    <th className="text-center ">{item}</th>
                                )
                            })
                            : null}
                        <th className="text-center bg-danger text-white">Tổng</th>
                    </tr>
                    </thead>
                    <tbody>
                    {dataBienDongNhanSu?.length > 0
                        ? dataBienDongNhanSu?.map((item, index) => {
                            if (index === 2) {
                                return (
                                    <>
                                        <tr>
                                            <th className="w-25">{item?.title}</th>
                                            {item?.data?.length > 0
                                                ? item?.data?.map((items, indexs) => {
                                                    // console.log('dataBienDongVitriss',dataBienDongVitriss)
                                                    return (
                                                        <td className="text-center">{items}</td>
                                                    )
                                                })
                                                : null}
                                            <td className="text-center fw-bold bg-danger text-white">{item?.data?.reduce((a, v) => a + v, 0)}</td>
                                        </tr>
                                        <tr>
                                            <th className="w-25">Tỷ lệ</th>
                                            {item?.data?.length > 0
                                                ? item?.data?.map((items, indexs) => {
                                                    // console.log('dataBienDongVitriss',dataBienDongVitriss)
                                                    let abcNhanSu = 0
                                                    item?.data?.reduce((a, v) => a + v, 0) === 0 ? abcNhanSu = 0 : abcNhanSu = (items * 100 / (item?.data?.reduce((a, v) => a + v, 0))).toFixed(2)
                                                    return (
                                                        <td className="text-center">{abcNhanSu}%</td>
                                                    )
                                                })
                                                : null}
                                            <td className="text-center fw-bold bg-danger text-white"></td>
                                        </tr>
                                    </>
                                
                                )
                            }
                        })
                        : null}
                    
                    </tbody>
                </Table>
            </div>
        </div>
    </div>
};
