import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import * as actions from '~/store/actions'
// import {ModalCauhinhtrangthai, ModalEditCauhinhtrangthai} from './Modal'
import Swal from 'sweetalert2'
import exportFromJSON from "export-from-json";
import {DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, UncontrolledDropdown, Spinner} from "reactstrap";
import Head from "~/layout/head/Head";
import Content from "~/layout/content/Content";
import {Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle} from "~/components/block/Block";
import {
    Button,
    Col,
    DataTable,
    DataTableBody,
    DataTableHead, DataTableItem,
    DataTableRow,
    Icon, PaginationComponent,
    Row,
    RSelect, TooltipComponent
} from "~/components/Component";
import {moduleName, valueStatus} from "~/utils/Datacustom";
import {Link} from "react-router-dom";
import config from "~/config";

const Cauhinhtrangthai = () => {
        const dispatch = useDispatch()
        const [show, setShow] = useState(false);
        const [showEditCauhinhtrangthai, setShowEditCauhinhtrangthai] = useState(false);
        const [listModule, setlistModule] = useState([])
        const [listModu, setlistModu] = useState([])
        //lấy giá trị từ thằng redux trả về
        const {cauhinhtrangthai} = useSelector(state => state.cauhinhtrangthai)
        // console.log('cauhinhtrangthai', cauhinhtrangthai)
        // console.log('listModule', listModule)
        const [data, setData] = useState(cauhinhtrangthai);
        const fieldList = {
            title: '',
            mota: '',
            giatri: '',
            module: '',
            type: '',
        }
        const handleSelect = (inputData, type) => {
            let result = [];
            if (type === 'module') {
                inputData?.map((item, index) => {
                    let object = {};
                    object.label = item.name;
                    object.value = item.value;
                    object.type = item.type;
                    result.push(object)
                })
            }
            if (type === 'type') {
                inputData?.map((item, index) => {
                    let object = {};
                    object.label = item.name;
                    object.value = item.value;
                    result.push(object)
                })
            }
            
            return result;
        }
        useEffect(() => {
            if (module) {
                let SelectmoduleName = handleSelect(moduleName, 'module');
                setlistModule(SelectmoduleName)
            }
        }, [module])
        useEffect(() => {
            if (cauhinhtrangthai && cauhinhtrangthai.length > 0) {
                setData([...cauhinhtrangthai]);
            }
        }, [cauhinhtrangthai]);
        
        const [sm, updateSm] = useState(false);
        const [tablesm, updateTableSm] = useState(false);
        const [onSearch, setonSearch] = useState(true);
        const [onSearchText, setSearchText] = useState("");
        const [modal, setModal] = useState({
            edit: false,
            add: false,
        });
        const [actionText, setActionText] = useState("");
        const [currentPage, setCurrentPage] = useState(1);
        const [itemPerPage, setItemPerPage] = useState(10);
        const [sort, setSortState] = useState("");
        // onChange function for searching name
        const onFilterChange = (e) => {
            setSearchText(e.target.value);
        };
        // function to toggle the search option
        const toggle = () => setonSearch(!onSearch);
        // function to change the check property of an item
        const selectorCheck = (e) => {
            let newData;
            newData = data.map((item) => {
                item.checked = e.currentTarget.checked;
                return item;
            });
            setData([...newData]);
        };
        // function to change the selected property of an item
        const dataSt = () => {
            let c = []
            listModule?.map((module, m) => {
                const moduleList = []
                module.type.map((i_type, i_t) => {
                    const moduleData = []
                    const objData = {}
                    data?.map((item, i) => {
                        if (module.value === item.module && i_type.value === item.type) {
                            let obj = {}
                            obj = item
                            moduleData?.push(obj);
                        }
                    })
                    objData.data = moduleData
                    objData.type_name = i_type.name
                    objData.module = module.value
                    objData.type = i_type.value
                    objData.name = module.label;
                    moduleList?.push(objData);
                })
                // c.data = moduleList
                c.push({moduleList});
            })
            
            return c
        };
        // dataSt()?.map((item, index) => {
        //     let abc =item.moduleList
        //     abc.map((itemss, indexs) => {
        //         console.log('-------------',itemss)
        //     })
        //
        // })
        
        
        const onSelectChange = (e, id) => {
            let newData = data;
            let index = newData.findIndex((item) => item.id === id);
            newData[index].checked = e.currentTarget.checked;
            setData([...newData]);
        };
        // Changing state value when searching name
        useEffect(() => {
            if (onSearchText !== "") {
                const filteredObject = data.filter((item) => {
                    return (
                        item.ho.toLowerCase().includes(onSearchText.toLowerCase()) ||
                        item.ten.toLowerCase().includes(onSearchText.toLowerCase())
                    );
                });
                setData([...filteredObject]);
            } else {
                setData([...data]);
            }
        }, [onSearchText, setData]);
        // Get current list, pagination
        const indexOfLastItem = currentPage * itemPerPage;
        const indexOfFirstItem = indexOfLastItem - itemPerPage;
        // console.log('listModule',listModule)
        // console.log('data',data)
        
        
        const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
        // let objj = {}
        
        const onFillter = (dataByModule, type) => {
            // console.log('dataByModule',dataByModule)
            return dataByModule.reduce((acc, obj) => {
                const key = obj[type];
                const curGroup = acc[key] ?? [];
                return {...acc, [key]: [...curGroup, obj]};
                const paginate = (pageNumber) => setCurrentPage(pageNumber);
            });
        }
        
        const Export = ({data}) => {
            const [modal, setModal] = useState(false);
            
            useEffect(() => {
                if (modal === true) {
                    setTimeout(() => setModal(false), 2000);
                }
            }, [modal]);
            
            const fileName = "Danh-sach-cong-ty";
            
            const exportCSV = () => {
                const exportType = exportFromJSON.types.csv;
                exportFromJSON({data, fileName, exportType});
            };
            
            const exportExcel = () => {
                const exportType = exportFromJSON.types.xls;
                exportFromJSON({data, fileName, exportType});
            };
            
            return (
                <React.Fragment>
                    <div className="dt-export-buttons d-flex align-center">
                        <div className="dt-export-title d-none d-md-inline-block">Xuất dữ liệu</div>
                        <div className="dt-buttons btn-group flex-wrap">
                            
                            <button className="btn btn-secondary buttons-csv buttons-html5" type="button"
                                    onClick={() => exportCSV()}>
                                <span>CSV</span>
                            </button>
                            {" "}
                            <button className="btn btn-secondary buttons-excel buttons-html5" type="button"
                                    onClick={() => exportExcel()}>
                                <span>Excel</span>
                            </button>
                            {" "}
                        </div>
                    </div>
                    <Modal isOpen={modal} className="modal-dialog-centered text-center" size="sm">
                        <ModalBody className="text-center m-2">
                            <h5>Copied to clipboard</h5>
                        </ModalBody>
                        <div className="p-3 bg-light">
                            <div className="text-center">Copied {data.length} rows to clipboard</div>
                        </div>
                    </Modal>
                </React.Fragment>
            );
        };
        // console.log('list cong ty', trangthai)
        useEffect(() => {
            cauhinhtrangthai && setShow(false) || setShowEditCauhinhtrangthai(false)
        }, [cauhinhtrangthai])
        // set state để mở đóng Modal
        const handleAddNewCauhinhtrangthai = async () => {
            setShow(true)
        }
        const [dataIdCauhinhtrangthai, setDataIdCauhinhtrangthai] = useState([]);
        const handleEditCauhinhtrangthai = async (module, type, modata) => {
            // console.log('handleEdit',modata.length)
            console.log('abc',module,type,modata)
            setShowEditCauhinhtrangthai(true)
            await setDataIdCauhinhtrangthai([module, type, modata])
        }
        
        const handleDeleteCauhinhtrangthai = (data) => {
            Swal.fire({
                title: 'Bạn có chắc không?',
                text: "Bạn sẽ không thể lấy lại khi xóa!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Không, bỏ qua!',
                confirmButtonText: 'Vâng, xóa nó đi!'
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch(actions.DeleteCauhinhtangca(data));
                }
            })
        }
        
        return (
            <React.Fragment>
                {/*<Head title="User List - Regular"></Head>*/}
                <Content>
                    <BlockHead size="sm">
                        <BlockBetween>
                            <BlockHeadContent>
                                <BlockTitle tag="h3" page>
                                    Cấu hình trạng thái
                                </BlockTitle>
                                {/* <BlockDes className="text-soft">
                        <p>Danh sách nhân viên</p>
                    </BlockDes> */}
                            </BlockHeadContent>
                            <BlockHeadContent>
                                <div className="toggle-wrap nk-block-tools-toggle">
                                    <Button
                                        className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                                        onClick={() => updateSm(!sm)}
                                    >
                                        <Icon name="menu-alt-r"></Icon>
                                    </Button>
                                    <div className="toggle-expand-content" style={{display: sm ? "block" : "none"}}>
                                        <ul className="nk-block-tools g-3">
                                            <li>
                                                <Export data={data}/>
                                            </li>
                                            <li className="nk-block-tools-opt">
                                                <Button color="primary" className="btn-icon px-1"
                                                        onClick={handleAddNewCauhinhtrangthai}>
                                                    <Icon name="plus"></Icon> Thêm mới trạng thái
                                                </Button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </BlockHeadContent>
                        </BlockBetween>
                    </BlockHead>
                    <Block>
                        <DataTable className="card-stretch">
                            <div className="card-inner position-relative card-tools-toggle">
                                <div className="card-title-group">
                                    <div className="card-title">
                                        <h5 className="title">Danh sách trạng thái cho từng chức năng</h5>
                                    </div>
                                    <div className="card-tools me-n1">
                                        <ul className="btn-toolbar gx-1">
                                            <li>
                                                <a
                                                    href="#search"
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        toggle();
                                                    }}
                                                    className="btn btn-icon search-toggle toggle-search"
                                                >
                                                    <Icon name="search"></Icon>
                                                </a>
                                            </li>
                                            <li className="btn-toolbar-sep"></li>
                                            <li>
                                                <div className="toggle-wrap">
                                                    <Button
                                                        className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                                                        onClick={() => updateTableSm(true)}
                                                    >
                                                        <Icon name="menu-right"></Icon>
                                                    </Button>
                                                    <div
                                                        className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                                                        <ul className="btn-toolbar gx-1">
                                                            <li className="toggle-close">
                                                                <Button className="btn-icon btn-trigger toggle"
                                                                        onClick={() => updateTableSm(false)}>
                                                                    <Icon name="arrow-left"></Icon>
                                                                </Button>
                                                            </li>
                                                            <li>
                                                                <UncontrolledDropdown>
                                                                    <DropdownToggle tag="a"
                                                                                    className="btn btn-trigger btn-icon dropdown-toggle">
                                                                        <div className="dot dot-primary"></div>
                                                                        <Icon name="filter-alt"></Icon>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu
                                                                        end
                                                                        className="filter-wg dropdown-menu-xl"
                                                                        style={{overflow: "visible"}}
                                                                    >
                                                                        <div className="dropdown-head">
                                                                        <span
                                                                            className="sub-title dropdown-title">Bộ lọc</span>
                                                                            <div className="dropdown">
                                                                                <a
                                                                                    href="#more"
                                                                                    onClick={(ev) => {
                                                                                        ev.preventDefault();
                                                                                    }}
                                                                                    className="btn btn-sm btn-icon"
                                                                                >
                                                                                    <Icon name="more-h"></Icon>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="dropdown-body dropdown-body-rg">
                                                                            <Row className="gx-6 gy-3">
                                                                                <Col size="6">
                                                                                    <div
                                                                                        className="custom-control custom-control-sm custom-checkbox">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            className="custom-control-input"
                                                                                            id="hasBalance"
                                                                                        />
                                                                                        <label
                                                                                            className="custom-control-label"
                                                                                            htmlFor="hasBalance">
                                                                                            {" "}
                                                                                            Have Balance
                                                                                        </label>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col size="6">
                                                                                    <div
                                                                                        className="custom-control custom-control-sm custom-checkbox">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            className="custom-control-input"
                                                                                            id="hasKYC"
                                                                                        />
                                                                                        <label
                                                                                            className="custom-control-label"
                                                                                            htmlFor="hasKYC">
                                                                                            {" "}
                                                                                            KYC Verified
                                                                                        </label>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col size="6">
                                                                                    <div className="form-group">
                                                                                        <label
                                                                                            className="overline-title overline-title-alt">Role</label>
                                                                                        <RSelect
                                                                                            placeholder="Any Role"/>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col size="6">
                                                                                    <div className="form-group">
                                                                                        <label
                                                                                            className="overline-title overline-title-alt">Status</label>
                                                                                        <RSelect
                                                                                            placeholder="Any Status"/>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col size="12">
                                                                                    <div className="form-group">
                                                                                        <button type="button"
                                                                                                className="btn btn-secondary">
                                                                                            Filter
                                                                                        </button>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                        <div className="dropdown-foot between">
                                                                            <a
                                                                                href="#reset"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                }}
                                                                                className="clickable"
                                                                            >
                                                                                Cập nhật bộ lọc 
                                                                            </a>
                                                                            <a
                                                                                href="#save"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                }}
                                                                            >
                                                                                Lưu bộ lọc 
                                                                            </a>
                                                                        </div>
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </li>
                                                            <li>
                                                                <UncontrolledDropdown>
                                                                    <DropdownToggle color="tranparent"
                                                                                    className="btn btn-trigger btn-icon dropdown-toggle">
                                                                        <Icon name="setting"></Icon>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu end className="dropdown-menu-xs">
                                                                        <ul className="link-check">
                                                                            <li>
                                                                                <span>Show</span>
                                                                            </li>
                                                                            <li className={itemPerPage === 10 ? "active" : ""}>
                                                                                <DropdownItem
                                                                                    tag="a"
                                                                                    href="#dropdownitem"
                                                                                    onClick={(ev) => {
                                                                                        ev.preventDefault();
                                                                                        setItemPerPage(10);
                                                                                    }}
                                                                                >
                                                                                    10
                                                                                </DropdownItem>
                                                                            </li>
                                                                            <li className={itemPerPage === 15 ? "active" : ""}>
                                                                                <DropdownItem
                                                                                    tag="a"
                                                                                    href="#dropdownitem"
                                                                                    onClick={(ev) => {
                                                                                        ev.preventDefault();
                                                                                        setItemPerPage(15);
                                                                                    }}
                                                                                >
                                                                                    15
                                                                                </DropdownItem>
                                                                            </li>
                                                                        </ul>
                                                                        <ul className="link-check">
                                                                            <li>
                                                                                <span>Order</span>
                                                                            </li>
                                                                            <li className={sort === "dsc" ? "active" : ""}>
                                                                                <DropdownItem
                                                                                    tag="a"
                                                                                    href="#dropdownitem"
                                                                                    onClick={(ev) => {
                                                                                        ev.preventDefault();
                                                                                        setSortState("dsc");
                                                                                        //   sortFunc("dsc");
                                                                                    }}
                                                                                >
                                                                                    DESC
                                                                                </DropdownItem>
                                                                            </li>
                                                                            <li className={sort === "asc" ? "active" : ""}>
                                                                                <DropdownItem
                                                                                    tag="a"
                                                                                    href="#dropdownitem"
                                                                                    onClick={(ev) => {
                                                                                        ev.preventDefault();
                                                                                        setSortState("asc");
                                                                                        //   sortFunc("asc");
                                                                                    }}
                                                                                >
                                                                                    ASC
                                                                                </DropdownItem>
                                                                            </li>
                                                                        </ul>
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                                    <div className="card-body">
                                        <div className="search-content">
                                            <Button
                                                className="search-back btn-icon toggle-search active"
                                                onClick={() => {
                                                    setSearchText("");
                                                    toggle();
                                                }}
                                            >
                                                <Icon name="arrow-left"></Icon>
                                            </Button>
                                            <input
                                                type="text"
                                                className="border-transparent form-focus-none form-control"
                                                placeholder="Tìm kiếm"
                                                value={onSearchText}
                                                onChange={(e) => onFilterChange(e)}
                                            />
                                            <Button className="search-submit btn-icon">
                                                <Icon name="search"></Icon>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <DataTableBody>
                                <DataTableHead>
                                    <DataTableRow className="nk-tb-col-check">
                                        <div className="custom-control custom-control-sm custom-checkbox notext">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                onChange={(e) => selectorCheck(e)}
                                                id="uid"
                                            />
                                            <label className="custom-control-label" htmlFor="uid"></label>
                                        </div>
                                    </DataTableRow>
                                    <DataTableRow size="mb">
                                        <span className="sub-text">Module chức năng</span>
                                    </DataTableRow>
                                    <DataTableRow size="mb">
                                        <span className="sub-text">Mô tả</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text">Nhóm trạng thái trực thuộc</span>
                                    </DataTableRow>
                                </DataTableHead>
                                {
                                    
                                    dataSt()?.map((items, key) => {
                                        let moduleData = items.moduleList
                                        let art = []
                                        const listItems = moduleData.map((numbers) => {
                                            let dsabc = []
                                            let dss = []
                                            let artObj = {}
                                            let Obj = {}
                                            const number = numbers?.data;
                                            if (number.length > 0) {
                                                let ds = []
                                                let objects = {};
                                                number?.map((numb) => {
                                                    // console.log('---numb-------', numb)
                                                    let object = {};
                                                    object.id = numb.id
                                                    object.title = numb.title
                                                    object.mota = numb.mota
                                                    object.giatri = numb.giatri
                                                    object.module = numb.module
                                                    object.type = numb.type
                                                    ds.push(object)
                                                    
                                                })
                                                console.log('---numb-------', ds)
                                                artObj.module = numbers.module
                                                artObj.name = numbers.name
                                                artObj.type = numbers.type
                                                artObj.type_name = numbers.type_name
                                                artObj.data = ds
                                                dss.push(artObj)
                                            }
                                            
                                            // console.log('---dss-------', dss)
                                            if (dss.length > 0) {
                                                Obj.das = dss
                                                dsabc.push(Obj)
                                            }
                                            return dsabc
                                            //
                                        });
                                        // console.log('listItems',listItems)
                                        if (listItems[0].length > 0) {
                                            let dateStatus = listItems[0][0].das[0].data;
                                            return (
                                                <DataTableItem key={key}>
                                                    <DataTableRow className="nk-tb-col-check">
                                                        <div
                                                            className="custom-control custom-control-sm custom-checkbox notext">
                                                            <input
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                onChange={(e) => selectorCheck(e)}
                                                                id="uid"
                                                            />
                                                            <label className="custom-control-label"
                                                                   htmlFor="uid"></label>
                                                        </div>
                                                    </DataTableRow>
                                                    <DataTableRow size="mb">
                                                        <span className="sub-text">{listItems[0][0].das[0].module}</span>
                                                    </DataTableRow>
                                                    <DataTableRow size="mb">
                                                        <span className="sub-text">{listItems[0][0].das[0].name}</span>
                                                    </DataTableRow>
                                                    <DataTableRow>
                                                        {listItems.map((nub) => {
                                                            // console.log('listItems',listItems)
                                                            if (nub.length > 0) {
                                                                let type_i = nub[0].das[0].type
                                                                let module_i = nub[0].das[0].module
                                                                let type_name_i = nub[0].das[0].type_name
                                                                let type_data_i = nub
                                                                // console.log('nub',nub)
                                                                // console.log('nub[0]',nub[0])
                                                                // console.log('nub[1]',nub[1])
                                                                // console.log('abc1',type_data_i)
                                                                return (
                                                                    <Link className="sub-text"
                                                                          onClick={() => handleEditCauhinhtrangthai(module_i,type_i,type_data_i)}
                                                                          to={'#'}>{type_i}: {type_name_i}
                                                                        <em className="icon ni ni-edit-alt-fill ms-4 border rounded-pill p-1 text-danger"></em>
                                                                    </Link>
                                                                )
                                                            }
                                                            
                                                        })}
                                                    </DataTableRow>
                                                   
                                                </DataTableItem>
                                            );
                                        }
                                        
                                        
                                    })
                                    
                                }
                            </DataTableBody>
                        </DataTable>
                    </Block>
                    {/* <ModalCauhinhtrangthai
                        show={show}
                        setShow={() => setShow(false)}
                    />
                    <ModalEditCauhinhtrangthai
                        show={showEditCauhinhtrangthai}
                        fieldList={fieldList}
                        setShow={() => setShowEditCauhinhtrangthai(false)}
                        dataIdCauhinhtrangthai={dataIdCauhinhtrangthai}
                    /> */}
                </Content>
            </React.Fragment>
        
        )
            ;
        
    }
;
export default Cauhinhtrangthai;