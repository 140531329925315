import axiosConfig from '../axiosConfig'

export const apiGetSuatan = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: '/api/v2/quanlynhanvien/suatan',
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiNewSuatan = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlynhanvien/suat-an/nhanvien',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiEditSuatan = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlynhanvien/suat-an/nhanvien',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiDeleteSuatan = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlynhanvien/suat-an/nhanvien',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})

//Loại suất ăn
export const apiGetLoaisuatan = (query) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: '/api/v2/quanlynhanvien/loai-suat-an/suatan',
            params: query,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiNewLoaisuatan = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlynhanvien/loai-suat-an/suatan',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiEditLoaisuatan = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlynhanvien/loai-suat-an/suatan',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiDeleteLoaisuatan = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlynhanvien/loai-suat-an/suatan',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})

//Phiếu suất ăn
export const apiGetPhieusuatan = (query) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: '/api/v2/quanlynhanvien/phieu-suat-an/suatan',
            params: query,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiNewPhieusuatan = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlynhanvien/phieu-suat-an/suatan',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiEditPhieusuatan = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlynhanvien/phieu-suat-an/suatan',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiDeletePhieusuatan = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlynhanvien/phieu-suat-an/suatan',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
