import actionTypes from './actionTypes';
import {
    apiDeleteTangCa,
    apiGetListTangCa,
    apiInsertTangCa,
    apiUpdateTangCaById,
    apiNewPhieutangca,
    apiEditPhieutangca,
    apiDeletePhieutangca,
    apiGetPhieutangca,
} from '~/services/tangca';
import { toast } from 'react-toastify';
import { apiDeleteCongty } from '~/services';
import { getListCongTy } from '~/store/actions/congty';
import { getListNhanvienOne } from './nhanvien';
export const getListTangCa = (payload) => async (dispatch) => {
    try {
        const response = await apiGetListTangCa(payload);
        // console.log('response',response)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_TANG_CA,
                tangca: response?.data.data,
            });
        } else {
            dispatch({
                type: actionTypes.GET_LIST_TANG_CA,
                msg: response.data.msg,
            });
        }
    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_TANG_CA,
            tangca: null,
        });
    }
};
export const NewTangCa = (payload, uuid) => {
    return async (dispatch) => {
        try {
            const response = await apiInsertTangCa(payload);
            // console.log('chek api apiDeleteCongty', response?.data.success)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_TANG_CA,
                    msg: 'OK',
                });
                dispatch(getListTangCa());
                toast.success('Thêm mới thông tin tăng ca thành công!');
                if (uuid) {
                    dispatch(getListNhanvienOne({ uuid: uuid }));
                }
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_TANG_CA,
                    msg: 'ERR',
                });
                toast.error('Thêm mới thông tin công ty thất bại!');
            }
        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_TANG_CA,
                tangca: null,
            });
        }
    };
};
export const DeleteTangCa = (payload, uuid) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteTangCa(payload);
            // console.log('chek api apiDeleteCongty', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListTangCa());
                toast.success('Xóa thông tin tăng ca thành công!');
                dispatch({
                    type: actionTypes.DELETE_TANG_CA,
                    msg: 'OK',
                });
                if (uuid) {
                    dispatch(getListNhanvienOne({ uuid: uuid }));
                }
            } else {
                dispatch({
                    type: actionTypes.DELETE_TANG_CA,
                    msg: 'ERR',
                });
                toast.error('Xóa thông tin công ty thất bại!');
            }
        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_CONG_TY,
                tangca: null,
            });
        }
    };
};
export const EditTangCa = (payload, uuid) => {
    return async (dispatch) => {
        try {
            const response = await apiUpdateTangCaById(payload);
            // console.log('chek api apiDeleteCongty', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListTangCa());
                toast.success('Sửa thông tin tăng ca thành công!');
                dispatch({
                    type: actionTypes.PUT_EDIT_TANG_CA,
                    msg: 'OK',
                });
                if (uuid) {
                    dispatch(getListNhanvienOne({ uuid: uuid }));
                }
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_TANG_CA,
                    msg: 'ERR',
                });
                toast.error('Sửa thông tin công ty thất bại!');
            }
        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_TANG_CA,
                tangca: null,
            });
        }
    };
};

export const getListPhieutangca = () => async (dispatch) => {
    try {
        const response = await apiGetPhieutangca()
        // console.log('chek api apiNewPhieutangca', response)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_PHIEU_TANG_CA,
                phieutangca: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_PHIEU_TANG_CA,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_PHIEU_TANG_CA,
            phieutangca: null
        })
    }
}

export const NewPhieutangca = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiNewPhieutangca(payload)
            // console.log('chek api apiNewPhieutangca', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_PHIEU_TANG_CA,
                    msg: 'OK'
                });
                dispatch(getListPhieutangca());
                toast.success("Thêm mới thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_PHIEU_TANG_CA,
                    msg: 'ERR'
                })
                toast.error("Thêm mới thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_PHIEU_TANG_CA,
                phieutangca: null
            })
        }
    }
}
export const EditPhieutangca = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditPhieutangca(payload)
            if (response?.data.success === 200) {
                dispatch(getListPhieutangca());
                toast.success(response?.data.message);
                dispatch({
                    type: actionTypes.PUT_EDIT_PHIEU_TANG_CA,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_PHIEU_TANG_CA,
                    msg: 'ERR'
                })
                toast.error(response?.data.message)
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_PHIEU_TANG_CA,
                phieutangca: null
            })
        }
    }
}
export const DeletePhieutangca = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeletePhieutangca(payload)
            console.log('chek api apiDeletePhieutangca', response)
            if (response?.data.success === 200) {
                dispatch(getListPhieutangca());
                toast.success(response?.data.message)
                dispatch({
                    type: actionTypes.DELETE_PHIEU_TANG_CA,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_PHIEU_TANG_CA,
                    msg: 'ERR'
                })
                toast.error(response?.data.message)
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_PHIEU_TANG_CA,
                phieutangca: null
            })
        }
    }
}