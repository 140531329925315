import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '~/store/actions'
import config from '~/config';
import {
    Block,
    BlockContent,
    BlockDes,
    BlockHead,
    BlockTitle,
    Icon,
    Button,
    PreviewCard,
} from "~/components/Component";
import { Link } from "react-router-dom";
import Logo from "~/images/logo.png";
import LogoDark from "~/images/logo-dark-color.png";
import PageContainer from "~/layout/page-container/PageContainer";
import Head from "~/layout/head/Head";
import { Form, Spinner, Alert } from "reactstrap";
const Login = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { isLoggedIn } = useSelector(state => state.auth)
    // console.log('isLoggedIn', isLoggedIn)
    const [invalidFields, setInvalidFields] = useState([])
    const [payloadLogin, setPayloadLogin] = useState({
        email: '',
        password: '',
    })
    useEffect(() => {
        isLoggedIn && navigate(`${config.he_thong + config.system}`)
        setLoading(false);
        if(invalidFields === null){
            setLoading(false);
        }
    }, [isLoggedIn, invalidFields])
    const [loading, setLoading] = useState(false);
    const [passState, setPassState] = useState(false);
    const handleSubmit = async () => {

        setLoading(true);
        let invalids = validate(payloadLogin)
        if (invalids === 0) {
            dispatch(actions.login(payloadLogin))
        }
    }
    const validate = (payloadLogin) => {
        let invalids = 0
        let fields = Object.entries(payloadLogin)
        fields.forEach(item => {
            if (item[1] === '') {
                setInvalidFields(prev => [...prev, {
                    name: item[0],
                    message: 'Bạn không được bỏ trống trường này.'
                }])
                invalids++
            }
        })
        fields.forEach(item => {
            switch (item[0]) {
                case 'password':
                    if (item[1].length < 6) {
                        setInvalidFields(prev => [...prev, {
                            name: item[0],
                            message: 'Mật khẩu phải có tối thiểu 6 kí tự.'
                        }])
                        invalids++
                    }
                    break;

                default:
                    break;
            }
        })
        return invalids
    }
    // Đăng nhập bằng nút enter
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        handleSubmit();
        }
  }
    return (
        <React.Fragment>
        {/*<Head title="Login" />*/}
        <PageContainer>
          <Block className="nk-block-middle nk-auth-body  wide-xs">
            <div className="brand-logo pb-4 text-center">
              <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
                <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
                <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
              </Link>
            </div>
  
            <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
              <BlockHead>
                <BlockContent>
                  <BlockTitle tag="h4">Đăng nhập</BlockTitle>
                  <BlockDes>
                    <p>Truy cập bằng email và mật khẩu của bạn.</p>
                  </BlockDes>
                </BlockContent>
              </BlockHead>
             
              <Form className="is-alter" >
                <div className="form-group">
                  <div className="form-label-group">
                    <label className="form-label" htmlFor="default-01">
                      Email
                    </label>
                  </div>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      id="default-01"
                      name="name"
                      placeholder="Enter your email address "
                      className="form-control-lg form-control"
                      value={payloadLogin.email}
                      onChange={(e) => setPayloadLogin(prev => ({ ...prev, ['email']: e.target.value }))}
                      onFocus={() => setInvalidFields([])}
                    />
                    {invalidFields.length > 0 && invalidFields.some(i => i.name === 'email') && <small className='text-danger' >{invalidFields.find(i => i.name === 'email')?.message}</small>}
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-label-group">
                    <label className="form-label" htmlFor="password">
                      Mật khẩu
                    </label>
                    <Link className="link link-primary link-sm" to={`${process.env.PUBLIC_URL}/auth-reset`}>
                      Quyên mật khẩu?
                    </Link>
                  </div>
                  <div className="form-control-wrap">
                    <a
                      href="#password"
                      onClick={(ev) => {
                        ev.preventDefault();
                        setPassState(!passState);
                      }}
                      className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
                    >
                      <Icon name="eye" className="passcode-icon icon-show"></Icon>
  
                      <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                    </a>
                    <input
                      type={passState ? "text" : "password"}
                      id="password"
                      name="password"
                      placeholder="Enter your passcode"
                      className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                      value={payloadLogin.password}
                      onChange={(e) => setPayloadLogin(prev => ({ ...prev, ['password']: e.target.value }))}
                      onFocus={() => setInvalidFields([])}
                      onKeyDown={(event) => handleKeyDown(event)}
                    />
                    {invalidFields.length > 0 && invalidFields.some(i => i.name === 'password') && <small className='text-danger' >{invalidFields.find(i => i.name === 'password')?.message}</small>}
                     
                  </div>
                </div>
                <div className="form-group">
                  <Button size="lg" className="btn-block" type="button" color="primary" onClick={handleSubmit}>
                    {loading ? <Spinner size="sm" color="light" /> : "Đăng nhập"}
                  </Button>
                </div>
              </Form>
              {/* <div className="form-note-s2 text-center pt-4">
                {" "}
                Bạn chưa có tài khoản? <Link to={`${process.env.PUBLIC_URL}/register`}>Đăng ký tài khoản</Link>
              </div> */}
              {/* <div className="text-center pt-4 pb-3">
                <h6 className="overline-title overline-title-sap">
                  <span>OR</span>
                </h6>
              </div>
              <ul className="nav justify-center gx-4">
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#socials"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    Facebook
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#socials"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    Google
                  </a>
                </li>
              </ul> */}
            </PreviewCard>
          </Block>
        </PageContainer>
      </React.Fragment>
    );
}

export default Login;
