import actionTypes from "../actions/actionTypes";
const initState = {
    cauhinhtrangthai: [],
    msg: '',
    count: 0,
    newtrangthai: []
}

const trangthaiReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.GET_LIST_CAU_HINH_TRANG_THAI:
            return {
                ...state,
                cauhinhtrangthai: action?.cauhinhtrangthai?.rows || [],
                msg: 'Ok' || '',
                count: action?.cauhinhtrangthai?.count || 0
            }
        case actionTypes.POST_NEW_CAU_HINH_TRANG_THAI:
        case actionTypes.PUT_EDIT_CAU_HINH_TRANG_THAI:
        case actionTypes.DELETE_CAU_HINH_TRANG_THAI:
            return {
                ...state,
                msg: action.msg || '',
                newtrangthai: action?.newtrangthai || []
            }
        default:
            return state;
    }

}

export default trangthaiReducer