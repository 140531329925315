import React, { useState, useEffect } from 'react';
import { InputForm, Button } from '~/components/Form';
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { validateAtvsBhld } from '~/request/validateAtvsBhld';
import Textarea from "~/components/Form/Textarea";
import {apiEditKiemdinhatvsbhld, apiGetLoaikiemdinhatvsbhld} from "~/services";
import {toast} from "react-toastify";

const ModalEditKiemdinhatvsbhld = ({ show, setShow, dataIdKiemdinhatvsbhld, fieldList }) => {
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayload] = useState(fieldList);
    const [listLoaiKiemDinh, setlistLoaiKiemDinh] = useState([]);
    async function fetchDataLoaiKiemDinh() {
        try {
            const datapayloads = {
                typeGet: 2,
            };
            const response = await apiGetLoaikiemdinhatvsbhld(datapayloads);
            // const response = resData.data.data.rows;
            if (response?.data?.success === 200) {
                let LoaiKiemDinhId = response?.data?.data?.rows?.map((item) => ({label: item.tenloaikiemdinh, value: item.id}));
                setlistLoaiKiemDinh(LoaiKiemDinhId);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    
    
    useEffect(() => {
        fetchDataLoaiKiemDinh().then(r => 'err')
    }, [show]);
    useEffect(() => {
        show &&
            dataIdKiemdinhatvsbhld &&
            setPayload({
                LoaikiemdinhatvsbhldId: {
                    label: dataIdKiemdinhatvsbhld.Loaikiemdinhatvsbhld.tenloaikiemdinh,
                    value: dataIdKiemdinhatvsbhld.Loaikiemdinhatvsbhld.id,
                },
                chukykiemdinh: dataIdKiemdinhatvsbhld?.chukykiemdinh,
                makiemdinh: dataIdKiemdinhatvsbhld?.makiemdinh,
                tenkiemdinh: dataIdKiemdinhatvsbhld?.tenkiemdinh,
                ghichu: dataIdKiemdinhatvsbhld?.ghichu,
            });
    }, [dataIdKiemdinhatvsbhld, show]);
    const handleSubmit = async () => {
        let invalids = validate(payload);

        if (invalids === 0) {
            let datapayload = {
                data: [
                    {
                        id: dataIdKiemdinhatvsbhld.id,
                        LoaikiemdinhatvsbhldId: payload.LoaikiemdinhatvsbhldId.value,
                        chukykiemdinh: payload.chukykiemdinh,
                        tenkiemdinh: payload.tenkiemdinh,
                        ghichu: payload.ghichu,
                    },
                ],
            };
            // console.log('check payload', datapayload)
            const response = await apiEditKiemdinhatvsbhld(datapayload)
            if (response?.data.success === 200) {
                setShow(false);
                toast.success("Sửa thông tin kiểm định an toàn vệ sinh thành công!")
            } else {
                toast.error("Sửa thông tin kiểm định an toàn vệ sinh thất bại!")
            }
        }
    };
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayload((prev) => ({ ...prev, [statename]: selected }));
    };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateAtvsBhld(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateAtvsBhld(item[0], item[1])]);
                invalids++;
            }
        });
        return invalids;
    };
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">Sửa thông tin Kiểm định</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Tên kiểm định'}
                            value={payload.tenkiemdinh}
                            setValue={setPayload}
                            keyPayload={'tenkiemdinh'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Mã kiểm định'}
                            value={payload.makiemdinh}
                            setValue={setPayload}
                            keyPayload={'makiemdinh'}
                            disabled={true}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Chu kỳ kiểm định (Ngày)'}
                            value={payload.chukykiemdinh}
                            setValue={setPayload}
                            keyPayload={'chukykiemdinh'}
                            type="integer"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                            Loại kiểm định
                        </label>
                        <Select
                            value={payload.LoaikiemdinhatvsbhldId}
                            onChange={handleOnSelect}
                            options={listLoaiKiemDinh}
                            placeholder={'Loại kiểm định'}
                            name="LoaikiemdinhatvsbhldId"
                            onFocus={() => setInvalidFields([])}
                        />

                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'LoaikiemdinhatvsbhldId') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'LoaikiemdinhatvsbhldId')?.message}
                            </small>
                        )}
                    </div>
                    <div className="form-group col-md-12">
                        <Textarea
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ghi chú về kiểm định'}
                            value={payload.ghichu}
                            setValue={setPayload}
                            keyPayload={'ghichu'}
                            type="text"
                            validate
                        />
                    </div>
                </div>

                <div className="col-12 mt-5">
                    <Button text="Cập nhật" bClass="btn btn-primary px-5" textColor onClick={handleSubmit} />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalEditKiemdinhatvsbhld;
