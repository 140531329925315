import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Content from '~/layout/content/Content';
import { Card, Modal, ModalBody, Tooltip } from 'reactstrap';
import {
    Block,
    BlockBetween,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    DataTableBody,
    DataTableHead,
    DataTableRow,
    DataTableItem,
    DataTable,
    Button,
    Icon,
} from '~/components/Component';
import { useSearchParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { InputForm } from '~/components/Form';
import Select from 'react-select';
import * as actions from '~/store/actions';
import { validateNhanVien } from '~/request/validateNhanVien';
import { dataSelectGioitinh, statusCustommerActive, dataKieuPhepNhanVien, dataSelectLock } from '~/utils/Datacustom';
import Swal from 'sweetalert2';
import LogoLight2x from '../../images/avatar/avatar.jpg';
import './chitietnhanvien.scss';
import AvatarCrop from './Modal/Nhanvien/AvatarCrop';
import { NumericFormat } from 'react-number-format';
import { validateNhanVienChiTiet } from '~/request/validateNhanVienChiTiet';
import { apiGetCauhinhdoanhnghiep } from '~/services/cauhinhdoanhnghiep';
import config from '~/config';
import { Link, useNavigate } from 'react-router-dom';

const CreateNhanVien = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [payloadCauhinh, setPayloadCauhinh] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const cauHinhDoanhNghiep = await apiGetCauhinhdoanhnghiep();
            const response = cauHinhDoanhNghiep.data.data.rows[0];
            setPayloadCauhinh(response);
        };
        fetchData().catch(console.error);
    }, []);
    useEffect(() => {
        dispatch(actions.getListCongthuctinhluong());
        dispatch(actions.getListPhucap());
        dispatch(actions.getListNhomnv());
        dispatch(actions.getListMayChamCong());
        dispatch(actions.getDonvihanhchinh());
    }, []);
    const { nhomnv, nhanvienone } = useSelector((state) => state.nhanvien);
    const { phongban } = useSelector((state) => state.phongban);
    const { maychamcong } = useSelector((state) => state.maychamcong);
    const { kieuluong } = useSelector((state) => state.kieuluong);
    const { congthuctinhluong } = useSelector((state) => state.congthuctinhluong);
    const { chucvu } = useSelector((state) => state.chucvu);
    const { chinhanh } = useSelector((state) => state.chinhanh);
    const { phucap } = useSelector((state) => state.phucap);
    const { donvihanhchinh } = useSelector((state) => state.app);
    // console.log('donvihanhchinh',donvihanhchinh)
    const [invalidFields, setInvalidFields] = useState([]);
    // console.log('invalidFields',invalidFields)
    // const [listCongty, setlistCongty] = useState([]);
    const [listChinhanh, setlistChinhanh] = useState([]);
    const [listPhongban, setlistPhongban] = useState([]);
    const [listKieuluong, setlistKieuluong] = useState([]);
    const [listCongthuc, setlistCongthuc] = useState([]);
    const [listChucvu, setlistChucvu] = useState([]);
    const [listGioitinh, setlistGioitinh] = useState([]);
    const [listStatus, setlistStatus] = useState([]);
    const [listMayChamCong, setlistMayChamCong] = useState([]);
    // const [listNhomnhanvien, setlistNhomnhanvien] = useState([]);
    const [listPhucap, setlistPhucap] = useState([]);
    // console.log('listTinhthanh', listTinhthanh)

    const [payload, setPayloadNhanvien] = useState({
        ChinhanhId: '',
        PhongbanId: '',
        ChucvuId: '',
        KieuluongId: '',
        GioitinhId: '',
        TrangthaiId: '',
        // MaychamcongId: '',
        NhomnvId: '',
        // KhaibaocalamviecId: '',
        ngayvaolamviec: '',
        hoten: '',
        uuid: '',
        mucluong: '',
        diachihientai: '',
        dienthoai: '',
        email: '',
        ngaysinh: '',
        noisinh: '',
        nguyenquan: '',
        socmnd: '',
        ngaycap: '',
        noicap: '',
        dantoc: '',
        quoctich: '',
        nguoilienhe: '',
        sodienthoainglh: '',
        quanhevsnv: '',
        diachinglh: '',
        nghipheptieuchuan: 12,
        type_nghiphep: '',
        sophepton: 0,
        masothue: '',
    });
    // console.log('payload', payload)

    // const [dataNhanvien, setDataNhanvien] = useState(nhanvienone);
    const [dataUrlAvatar, setDataUrlAvatar] = useState(null);
    const [searchParams, setsearchParams] = useSearchParams();

    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayloadNhanvien((prev) => ({ ...prev, [statename]: selected }));
    };

    useEffect(() => {
        if (phongban && kieuluong && congthuctinhluong && chucvu && chinhanh && maychamcong && phucap) {
            let dataSelectChinhanh = chinhanh.map((item) => ({ label: item.diachi, value: item.id }));
            let dataSelectMayChamCong = maychamcong
                .filter((item) => item?.status === 1)
                .map((item) => ({ label: item?.tenmaychamcong, value: item?.id }));
            let dataSelectPhongban = phongban.map((item) => ({ label: item.phongban, value: item.id }));
            let dataSelectKieuluong = kieuluong.map((item) => ({ label: item.tieude, value: item.id }));
            let dataSelectCongthuc = congthuctinhluong.map((item) => ({ label: item.tencongthuc, value: item.id }));
            let dataSelectChucvu = chucvu.map((item) => ({ label: item.chucvu, value: item.id }));
            // let dataSelectNhomnv = dataNhanvien?.Nhomnhanviens?.map((item) => ({
            //     label: item?.Nhomnv?.tennhom,
            //     value: item?.Nhomnv?.id,
            // }));

            setlistPhongban(dataSelectPhongban);
            setlistMayChamCong(dataSelectMayChamCong);
            setlistKieuluong(dataSelectKieuluong);
            setlistCongthuc(dataSelectCongthuc);
            setlistChucvu(dataSelectChucvu);
            // setlistNhomnhanvien(dataSelectNhomnv);
            setlistGioitinh(dataSelectGioitinh);
            setlistStatus(statusCustommerActive);
            setlistChinhanh(dataSelectChinhanh);
            setlistPhucap(phucap.map((item) => ({ label: item.title, value: item.id })));
        }
    }, [phongban, kieuluong, congthuctinhluong, chucvu, chinhanh, maychamcong, phucap]);
    // useEffect(() => {
    //     if (nhanvienone && nhanvienone.length > 0) {
    //         setDataNhanvien(nhanvienone[0]);
    //     }
    // }, [nhanvienone]);

    const [modalAvatar, setmodalAvatar] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const [tooltipOpen1, setTooltipOpen1] = useState(false);
    const toggle1 = () => setTooltipOpen1(!tooltipOpen1);

    const [listTinhthanh, setlistTinhThanh] = useState([]);
    // console.log('listTinhthanh', listTinhthanh)
    const [listQuanhuyen, setlistQuanhuyen] = useState([]);
    const [listXaphuong, setlistXaphuong] = useState([]);
    const [selectedProvince, setSelectedProvince] = useState('');
    const [selectedDistrict, setSelectedDistrict] = useState('');
    const [selectedWard, setSelectedWard] = useState('');
    const handleProvinceChange = (selected) => {
        setSelectedProvince(selected);
        setSelectedDistrict('');
        setSelectedWard('');
    };
    const handleDistrictChange = (selected) => {
        setSelectedDistrict(selected);
        setSelectedWard('');
    };
    const handleWardChange = (selected) => {
        setSelectedWard(selected);
    };
    useEffect(() => {
        const dataTT = donvihanhchinh?.data?.province
            ? JSON.parse(donvihanhchinh.data.province).map((item) => ({
                  label: item.name,
                  value: item.id,
              }))
            : [];
        setlistTinhThanh(dataTT);
        if (selectedProvince) {
            const dataQH =
                donvihanhchinh?.data?.district &&
                JSON.parse(donvihanhchinh.data.district)
                    .filter((it) => it?.province_id === Number(selectedProvince.value))
                    .map((item) => ({ label: item.name, value: item.id }));
            setlistQuanhuyen(dataQH);
        } else {
            setlistQuanhuyen([]);
        }
        if (selectedDistrict) {
            const dataXP =
                donvihanhchinh?.data?.wards &&
                JSON.parse(donvihanhchinh.data.wards)
                    .filter((it) => it?.district_id === Number(selectedDistrict.value))
                    .map((item) => ({ label: item.name, value: item.id }));
            setlistXaphuong(dataXP);
        } else {
            setlistXaphuong([]);
        }
    }, [donvihanhchinh, selectedProvince, selectedDistrict]);

    const [formNPT, setFormNPT] = useState([]);
    // console.log('formNPT', formNPT);
    // const [formEditNPT, setFormEditNPT] = useState({ id: 0, trangthai: false });
    const handleChangeNPT = (i, e) => {
        let newFormValues = [...formNPT];
        newFormValues[i][e.target.name] = e.target.value;
        setFormNPT(newFormValues);
    };
    // const handleSelectChangeNPT = (index, selected) => {
    //     const updatedFormValues = [...formNPT];
    //     updatedFormValues[index] = {
    //         ...updatedFormValues[index],
    //         LoaisuatanId: selected,
    //     };
    //     setFormNPT(updatedFormValues);
    // };
    const addFormFieldsNPT = () => {
        let mang = [
            ...formNPT,
            {
                hoten: '',
                diachi: '',
                masothue: '',
                quanhevsnhanvien: '',
                sodienthoai: '',
                ngaysinh: '',
                thoidiemtinhgiamtrutu: '',
                thoidiemtinhgiamtruden: '',
                cmnd: '',
            },
        ];
        setFormNPT(mang);
        // setFormEditNPT((prev) => ({ ...prev, ['id']: mang?.length }));
        // setFormEditNPT((prev) => ({ ...prev, ['trangthai']: false }));
    };
    // const handleEditNPT = (i) => {
    //     setFormEditNPT((prev) => ({ ...prev, ['id']: Number(i + 1) }));
    //     setFormEditNPT((prev) => ({ ...prev, ['trangthai']: true }));
    // };
    const removeFormFieldsNPT = (i) => {
        let newFormValues = [...formNPT];
        newFormValues.splice(i, 1);
        setFormNPT(newFormValues);
    };
    const [formPC, setFormPC] = useState([]);
    // console.log('formPC', formPC);
    // const [formEditNPT, setFormEditNPT] = useState({ id: 0, trangthai: false });
    const handleChangePC = (i, e) => {
        let newFormValues = [...formPC];
        newFormValues[i]['giatri'] = e.value;
        newFormValues[i]['giatriformat'] = e.formattedValue;
        setFormPC(newFormValues);
    };
    const handleSelectChangePC = (index, selected) => {
        const updatedFormValues = [...formPC];
        updatedFormValues[index] = {
            ...updatedFormValues[index],
            PhucapId: selected,
        };
        setFormPC(updatedFormValues);
    };
    const addFormFieldsPC = () => {
        let mang = [
            ...formPC,
            {
                PhucapId: '',
                giatri: '',
                // giatriformat: '',
            },
        ];
        setFormPC(mang);
    };
    const removeFormFieldsPC = (i) => {
        let newFormValues = [...formPC];
        newFormValues.splice(i, 1);
        setFormPC(newFormValues);
    };
    // không cho phụ cấp trùng nhau
    useEffect(() => {
        if (formPC && formPC.length > 0 && phucap) {
            const c = phucap.filter(
                (itemB) => !formPC.some((itemA) => Number(itemA?.PhucapId?.value) === Number(itemB.id)),
            );
            setlistPhucap(c.map((item) => ({ label: item.title, value: item.id })));
        } else {
        }
    }, [formPC, formPC.length, phucap]);

    const [formNH, setFormNH] = useState([]);
    // console.log('formNH', formNH);
    // const [formEditNH, setFormEditNH] = useState({ id: 0, trangthai: false });
    const handleChangeNH = (i, e) => {
        let newFormValues = [...formNH];
        newFormValues[i][e.target.name] = e.target.value;
        setFormNH(newFormValues);
    };
    // const handleSelectChangeNH = (index, selected) => {
    //     const updatedFormValues = [...formNH];
    //     updatedFormValues[index] = {
    //         ...updatedFormValues[index],
    //         LoaisuatanId: selected,
    //     };
    //     setFormNH(updatedFormValues);
    // };
    const addFormFieldsNH = () => {
        let mang = [
            ...formNH,
            {
                tennganhang: '',
                sotaikhoan: '',
                nguoihuongthu: '',
            },
        ];
        setFormNH(mang);
    };
    const removeFormFieldsNH = (i) => {
        let newFormValues = [...formNH];
        newFormValues.splice(i, 1);
        setFormNH(newFormValues);
    };

    const [formMCC, setFormMCC] = useState([]);
    // console.log('formMCC', formMCC);
    // const [formEditNPT, setFormEditNPT] = useState({ id: 0, trangthai: false });
    const handleChangeMCC = (i, e) => {
        let newFormValues = [...formMCC];
        newFormValues[i][e.target.name] = e.target.value;
        setFormMCC(newFormValues);
    };
    const handleSelectChangeMCC = (index, selected) => {
        const updatedFormValues = [...formMCC];
        updatedFormValues[index] = {
            ...updatedFormValues[index],
            MaychamcongId: selected,
        };
        setFormMCC(updatedFormValues);
    };
    const addFormFieldsMCC = () => {
        let mang = [
            ...formMCC,
            {
                MaychamcongId: '',
                machamcong: '',
                tenchamcong: '',
                mathe: '',
            },
        ];
        setFormMCC(mang);
    };
    const removeFormFieldsMCC = (i) => {
        let newFormValues = [...formMCC];
        newFormValues.splice(i, 1);
        setFormMCC(newFormValues);
    };
    // không cho máy chấm công trùng nhau
    useEffect(() => {
        if (formMCC && formMCC.length > 0 && maychamcong) {
            const c = maychamcong
                .filter((item) => item?.status === 1)
                .filter((itemB) => !formMCC.some((itemA) => Number(itemA?.MaychamcongId?.value) === Number(itemB.id)));
            setlistMayChamCong(c.map((item) => ({ label: item.tenmaychamcong, value: item.id })));
        }
    }, [formMCC, formMCC.length, maychamcong]);

    const [invalidPc, setInvalidFieldsPc] = useState([]);
    const [invalidNh, setInvalidFieldsNh] = useState([]);
    const [invalidNpt, setInvalidFieldsNpt] = useState([]);
    const [invalidMcc, setInvalidFieldsMcc] = useState([]);
    const [invalidDvhc, setInvalidFieldsDvhc] = useState([]);
    const validateNPT = (arr) => {
        let newArray = {};
        arr.forEach((item, index) => {
            newArray[`hoten_${index}`] = item.hoten;
            newArray[`diachi_${index}`] = item.diachi;
            newArray[`masothue_${index}`] = item.masothue;
            newArray[`quanhevsnhanvien_${index}`] = item.quanhevsnhanvien;
            // newArray[`sodienthoai_${index}`] = item.sodienthoai;
            newArray[`ngaysinh_${index}`] = item.ngaysinh;
            newArray[`thoidiemtinhgiamtrutu_${index}`] = item.thoidiemtinhgiamtrutu;
            // newArray[`thoidiemtinhgiamtruden_${index}`] = item.thoidiemtinhgiamtruden;
            // newArray[`cmnd_${index}`] = item.cmnd;
        });
        let invalids = 0;
        let fields = Object.entries(newArray);
        fields.forEach((item) => {
            if (item[1] === '') {
                setInvalidFieldsNpt((prev) => [
                    ...prev,
                    {
                        name: item[0],
                        message: 'Bạn không được bỏ trống trường này.',
                    },
                ]);
                invalids++;
            }
        });

        return invalids;
    };
    const validatePC = (arr) => {
        let newArray = {};
        arr.forEach((item, index) => {
            newArray[`PhucapId_${index}`] = item.PhucapId;
            newArray[`giatri_${index}`] = item.giatri;
        });
        let invalids = 0;
        let fields = Object.entries(newArray);
        fields.forEach((item) => {
            if (item[1] === '') {
                setInvalidFieldsPc((prev) => [
                    ...prev,
                    {
                        name: item[0],
                        message: 'Bạn không được bỏ trống trường này.',
                    },
                ]);
                invalids++;
            }
        });

        return invalids;
    };
    const validateNH = (arr) => {
        let newArray = {};
        arr.forEach((item, index) => {
            newArray[`tennganhang_${index}`] = item.tennganhang;
            newArray[`sotaikhoan_${index}`] = item.sotaikhoan;
            newArray[`nguoihuongthu_${index}`] = item.nguoihuongthu;
        });
        let invalids = 0;
        let fields = Object.entries(newArray);
        fields.forEach((item) => {
            if (item[1] === '') {
                setInvalidFieldsNh((prev) => [
                    ...prev,
                    {
                        name: item[0],
                        message: 'Bạn không được bỏ trống trường này.',
                    },
                ]);
                invalids++;
            }
        });

        return invalids;
    };
    const validateMCC = (arr) => {
        let newArray = {};
        arr.forEach((item, index) => {
            newArray[`MaychamcongId_${index}`] = item.MaychamcongId;
            newArray[`machamcong_${index}`] = item.machamcong;
        });
        // console.log('newArray', newArray)
        let invalids = 0;
        let fields = Object.entries(newArray);
        fields.forEach((item) => {
            if (item[1] === '') {
                setInvalidFieldsMcc((prev) => [
                    ...prev,
                    {
                        name: item[0],
                        message: 'Bạn không được bỏ trống trường này.',
                    },
                ]);
                invalids++;
            }
        });

        return invalids;
    };
    const validateDVHC = (arr) => {
        let invalids = 0;
        let fields = Object.entries(arr);
        fields.forEach((item) => {
            if (item[1] === '') {
                setInvalidFieldsDvhc((prev) => [
                    ...prev,
                    {
                        name: item[0],
                        message: 'Bạn không được bỏ trống trường này.',
                    },
                ]);
                invalids++;
            }
        });

        return invalids;
    };
    const handleSubmit = async () => {
        let invalids = validate(payload);
        const invalidsPC = validatePC(formPC);
        const invalidsNH = validateNH(formNH);
        const invalidsNPT = validateNPT(formNPT);
        const invalidsMCC = validateMCC(formMCC);
        const valiDVHC = { TinhthanhId: selectedProvince, QuanhuyenId: selectedDistrict, PhuongxaId: selectedWard };
        const invalidsDVHC = validateDVHC(valiDVHC);
        // console.log('invalidsPC2vvvvvvvvvvvvvvvvv', invalids, invalidsPC, invalidsNH, invalidsNPT, invalidsMCC, invalidsDVHC);
        if (
            invalids === 0 &&
            invalidsPC === 0 &&
            invalidsNH === 0 &&
            invalidsNPT === 0 &&
            invalidsMCC === 0 &&
            invalidsDVHC === 0
        ) {
            let datapayload = {
                data: [
                    {
                        ChinhanhId: payload?.ChinhanhId?.value,
                        PhongbanId: payload?.PhongbanId?.value,
                        ChucvuId: payload?.ChucvuId?.value,
                        // MaychamcongId: payload?.MaychamcongId?.value,
                        kieuluong: Number(payload?.KieuluongId?.value),
                        CongthucId: Number(payload?.CongthucId?.value),
                        GioitinhId: payload?.GioitinhId?.value,
                        TrangthaiId: payload?.TrangthaiId?.value,
                        // NhomnvId: payload?.NhomnvId?.value,
                        // KhaibaocalamviecId: payload?.KhaibaocalamviecId?.value,
                        // CauhinhtangcaId: payload?.CauhinhtangcaId?.value,
                        ngayvaolamviec: payload?.ngayvaolamviec,
                        hoten: payload?.hoten,
                        mucluong: payload?.mucluong,
                        diachihientai: payload?.diachihientai,
                        dienthoai: payload?.dienthoai,
                        email: payload?.email,
                        ngaysinh: payload?.ngaysinh,
                        noisinh: payload?.noisinh,
                        nguyenquan: payload?.nguyenquan,
                        socmnd: payload?.socmnd,
                        ngaycap: payload?.ngaycap,
                        noicap: payload?.noicap,
                        dantoc: payload?.dantoc,
                        quoctich: payload?.quoctich,
                        nguoilienhe: payload?.nguoilienhe,
                        sodienthoainglh: payload?.sodienthoainglh,
                        quanhevsnv: payload?.quanhevsnv,
                        diachinglh: payload?.diachinglh,
                        nghipheptieuchuan: payload?.nghipheptieuchuan,
                        type_nghiphep: payload?.type_nghiphep?.value,
                        formNPT: formNPT,
                        formPC: formPC,
                        formNH: formNH,
                        formMCC: formMCC,
                        avatar: dataUrlAvatar?.avatarUrl,
                        ProvinceId: selectedProvince?.value,
                        DistrictId: selectedDistrict?.value,
                        Xa_phuongId: selectedWard?.value,
                        sophepton: payload?.sophepton,
                        masothue: payload?.masothue,
                    },
                ],
            };
            // console.log('cccccccccccccccccc', datapayload);
            dispatch(actions.NewNhanvien(datapayload, navigate));
        }
    };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateNhanVienChiTiet(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateNhanVienChiTiet(item[0], item[1])]);
                invalids++;
            }
        });
        return invalids;
    };

    return (
        <React.Fragment>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                Thêm mới nhân viên 
                            </BlockTitle>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <Block>
                    <Card className="card-bordered">
                        <div className="card-inner card-inner-lg">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="pb-0">
                                        <h6 className="text-primary">Thông tin cơ bản</h6>
                                    </div>
                                </div>
                                <div className="py-3">
                                    <div className="avatar-container border rounded-4">
                                        <img src={dataUrlAvatar != null ? URL.createObjectURL(dataUrlAvatar?.avatarFile) : LogoLight2x} alt="Avatar Image" className="rounded-4" />
                                        {/* {dataNhanvien?.Thongtinnhanvien?.avatar === null ? (
                                                                <img
                                                                    src={LogoLight2x}
                                                                    alt="Avatar Image"
                                                                    className="rounded-4"
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={dataNhanvien?.Thongtinnhanvien?.avatar}
                                                                    alt="Avatar Image"
                                                                    className="rounded-4"
                                                                />
                                                            )} */}
                                        <div className="camera-icon" onClick={() => setmodalAvatar(true)}>
                                            <Icon name="camera-fill" htmlFor="inputImge"></Icon>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Họ và tên'}
                                        value={payload?.hoten}
                                        setValue={setPayloadNhanvien}
                                        keyPayload={'hoten'}
                                        type="text"
                                        validate
                                    />
                                </div>

                                <div className="form-group col-md-4">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Ngày sinh'}
                                        value={payload?.ngaysinh}
                                        setValue={setPayloadNhanvien}
                                        keyPayload={'ngaysinh'}
                                        type="date"
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                                            Giới tính
                                        </label>
                                        <Select
                                            value={payload?.GioitinhId}
                                            onChange={handleOnSelect}
                                            options={listGioitinh}
                                            placeholder={'Chọn giới tính'}
                                            name="GioitinhId"
                                            onFocus={() => setInvalidFields([])}
                                        />
                                        {invalidFields.length > 0 &&
                                            invalidFields.some((i) => i.name === 'GioitinhId') && (
                                                <small>
                                                    {invalidFields.find((i) => i.name === 'GioitinhId')?.message}
                                                </small>
                                            )}
                                    </div>
                                </div>
                                {/* <div className="form-group col-md-3">
                                                <InputForm
                                                    setInvalidFields={setInvalidFields}
                                                    invalidFields={invalidFields}
                                                    label={'Mã Nhân Viên'}
                                                    value={payload?.uuid}
                                                    setValue={setPayloadNhanvien}
                                                    keyPayload={'uuid'}
                                                    type="text"
                                                    disabled={true}
                                                />
                                            </div> */}
                            </div>
                            <div className="row">
                                <div className="col-md-12 mt-3">
                                    <div className="pb-0">
                                        <h6 className="text-primary">Liên hệ</h6>
                                    </div>
                                </div>

                                <div className="form-group col-md-3">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Email'}
                                        value={payload?.email}
                                        setValue={setPayloadNhanvien}
                                        keyPayload={'email'}
                                        type="text"
                                        validate
                                        // disabled={true}
                                    />
                                </div>
                                <div className="form-group col-md-3">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Số điện thoại'}
                                        value={payload?.dienthoai}
                                        setValue={setPayloadNhanvien}
                                        keyPayload={'dienthoai'}
                                        type="number"
                                        validate
                                    />
                                </div>
                                <div className="form-group col-md-3">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Quốc tịch'}
                                        value={payload?.quoctich}
                                        setValue={setPayloadNhanvien}
                                        keyPayload={'quoctich'}
                                        type="text"
                                    />
                                </div>
                                <div className="form-group col-md-3">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Nơi sinh'}
                                        value={payload?.noisinh}
                                        setValue={setPayloadNhanvien}
                                        keyPayload={'noisinh'}
                                        type="text"
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                                            Tỉnh thành <b className="text-danger">(*)</b>
                                        </label>
                                        <Select
                                            value={selectedProvince}
                                            onChange={handleProvinceChange}
                                            options={listTinhthanh}
                                            placeholder={'Chọn Tỉnh thành'}
                                            name="TinhthanhId"
                                            onFocus={() => setInvalidFieldsDvhc([])}
                                        />
                                        {invalidDvhc.length > 0 &&
                                            invalidDvhc.some((i) => i.name === 'TinhthanhId') && (
                                                <small className="text-danger">
                                                    {invalidDvhc.find((i) => i.name === 'TinhthanhId')?.message}
                                                </small>
                                            )}
                                    </div>
                                </div>
                                <div className="form-group col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                                            Quận/Huyện <b className="text-danger">(*)</b>
                                        </label>
                                        <Select
                                            value={selectedDistrict}
                                            onChange={handleDistrictChange}
                                            options={listQuanhuyen}
                                            placeholder={'Chọn Quận/Huyện'}
                                            name="QuanhuyenId"
                                            onFocus={() => setInvalidFieldsDvhc([])}
                                        />
                                        {invalidDvhc.length > 0 &&
                                            invalidDvhc.some((i) => i.name === 'QuanhuyenId') && (
                                                <small className="text-danger">
                                                    {invalidDvhc.find((i) => i.name === 'QuanhuyenId')?.message}
                                                </small>
                                            )}
                                    </div>
                                </div>
                                <div className="form-group col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                                            Phường/Xã <b className="text-danger">(*)</b>
                                        </label>
                                        <Select
                                            value={selectedWard}
                                            onChange={handleWardChange}
                                            options={listXaphuong}
                                            placeholder={'Chọn Phường/Xã'}
                                            name="PhuongxaId"
                                            onFocus={() => setInvalidFieldsDvhc([])}
                                        />
                                        {invalidDvhc.length > 0 && invalidDvhc.some((i) => i.name === 'PhuongxaId') && (
                                            <small className="text-danger">
                                                {invalidDvhc.find((i) => i.name === 'PhuongxaId')?.message}
                                            </small>
                                        )}
                                    </div>
                                </div>

                                {/* <div className="form-group col-md-3">
                                                <InputForm
                                                    setInvalidFields={setInvalidFields}
                                                    invalidFields={invalidFields}
                                                    label={'Nguyên quán'}
                                                    value={payload?.nguyenquan}
                                                    setValue={setPayloadNhanvien}
                                                    keyPayload={'nguyenquan'}
                                                    type="text"
                                                />
                                            </div> */}

                                <div className="form-group col-md-3">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Mã số thuế cá nhân'}
                                        value={payload?.masothue}
                                        setValue={setPayloadNhanvien}
                                        keyPayload={'masothue'}
                                        type="text"
                                    />
                                </div>
                                <div className="form-group col-md-3">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Dân tộc'}
                                        value={payload?.dantoc}
                                        setValue={setPayloadNhanvien}
                                        keyPayload={'dantoc'}
                                        type="text"
                                    />
                                </div>
                                <div className="form-group col-md-3">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Địa chỉ hiện tại'}
                                        value={payload?.diachihientai}
                                        setValue={setPayloadNhanvien}
                                        keyPayload={'diachihientai'}
                                        type="text"
                                    />
                                </div>
                                <div className="form-group col-md-3">
                                    <label htmlFor="inputZip" className="mb-2 fw-bolder">
                                        Trạng thái <b className="text-danger">(*)</b>
                                    </label>
                                    <Select
                                        value={payload?.TrangthaiId}
                                        onChange={handleOnSelect}
                                        options={listStatus}
                                        placeholder={'Chọn trạng tháí'}
                                        name="TrangthaiId"
                                        onFocus={() => setInvalidFields([])}
                                    />
                                    {invalidFields.length > 0 &&
                                        invalidFields.some((i) => i.name === 'TrangthaiId') && (
                                            <small>
                                                {invalidFields.find((i) => i.name === 'TrangthaiId')?.message}
                                            </small>
                                        )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mt-3">
                                    <div className="pb-0">
                                        <h6 className="text-primary">Thông tin định danh</h6>
                                    </div>
                                </div>

                                <div className="form-group col-md-4">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Số CMND/CCCD'}
                                        value={payload?.socmnd}
                                        setValue={setPayloadNhanvien}
                                        keyPayload={'socmnd'}
                                        type="text"
                                        validate
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Ngày cấp'}
                                        value={payload?.ngaycap}
                                        setValue={setPayloadNhanvien}
                                        keyPayload={'ngaycap'}
                                        type="date"
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Nơi cấp'}
                                        value={payload?.noicap}
                                        setValue={setPayloadNhanvien}
                                        keyPayload={'noicap'}
                                        type="text"
                                    />
                                </div>
                                {/* <div className="form-group col-md-3">
                                                <label htmlFor="inputZip" className="mb-2 fw-bolder">
                                                    Chọn máy chấm công
                                                </label>
                                                <Select
                                                    value={payload?.MaychamcongId}
                                                    onChange={handleOnSelect}
                                                    options={listMayChamCong}
                                                    placeholder={'Chọn máy chấm công'}
                                                    name="MaychamcongId"
                                                    onFocus={() => setInvalidFields([])}
                                                />
                                                {invalidFields.length > 0 &&
                                                    invalidFields.some((i) => i.name === 'MaychamcongId') && (
                                                        <small>
                                                            {invalidFields.find((i) => i.name === 'MaychamcongId')?.message}
                                                        </small>
                                                    )}
                                            </div> */}
                            </div>
                            <div className="row">
                                <div className="col-md-12 mt-3">
                                    <div className="pb-0">
                                        <h6 className="text-primary">Liên hệ khẩn cấp</h6>
                                    </div>
                                </div>

                                <div className="form-group col-md-3">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Tên người LH khẩn cấp'}
                                        value={payload?.nguoilienhe}
                                        setValue={setPayloadNhanvien}
                                        keyPayload={'nguoilienhe'}
                                        type="text"
                                    />
                                </div>
                                <div className="form-group col-md-3">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Số điện thoại LH khẩn cấp'}
                                        value={payload?.sodienthoainglh}
                                        setValue={setPayloadNhanvien}
                                        keyPayload={'sodienthoainglh'}
                                        type="text"
                                    />
                                </div>
                                <div className="form-group col-md-3">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Quan hệ với nhân viên'}
                                        value={payload?.quanhevsnv}
                                        setValue={setPayloadNhanvien}
                                        keyPayload={'quanhevsnv'}
                                        type="text"
                                    />
                                </div>
                                <div className="form-group col-md-3">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Địa chỉ khẩn cấp'}
                                        value={payload?.diachinglh}
                                        setValue={setPayloadNhanvien}
                                        keyPayload={'diachinglh'}
                                        type="text"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mt-3">
                                    <div className="pb-0">
                                        <h6 className="text-primary">Tình trạng</h6>
                                    </div>
                                </div>

                                <div className="form-group col-md-4">
                                    <label htmlFor="inputZip" className="mb-2 fw-bold">
                                        <span>
                                            Chi nhánh <b className="text-danger">(*)</b>
                                        </span>
                                    </label>
                                    <Select
                                        value={payload?.ChinhanhId}
                                        onChange={handleOnSelect}
                                        options={listChinhanh}
                                        placeholder={'Chọn Chi nhánh'}
                                        name="ChinhanhId"
                                        onFocus={() => setInvalidFields([])}
                                    />
                                    {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'ChinhanhId') && (
                                        <small>{invalidFields.find((i) => i.name === 'ChinhanhId')?.message}</small>
                                    )}
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="inputZip" className="mb-2 fw-bold">
                                        <span>
                                            Phòng ban <b className="text-danger">(*)</b>
                                        </span>
                                    </label>
                                    <Select
                                        value={payload?.PhongbanId}
                                        onChange={handleOnSelect}
                                        options={listPhongban}
                                        placeholder={'Chọn Phòng Ban'}
                                        name="PhongbanId"
                                        onFocus={() => setInvalidFields([])}
                                    />
                                    {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'PhongbanId') && (
                                        <small>{invalidFields.find((i) => i.name === 'PhongbanId')?.message}</small>
                                    )}
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="inputZip" className="mb-2 fw-bold">
                                        <span>
                                            Chức vụ <b className="text-danger">(*)</b>
                                        </span>
                                    </label>
                                    <Select
                                        value={payload?.ChucvuId}
                                        onChange={handleOnSelect}
                                        options={listChucvu}
                                        placeholder={'Chọn chức vụ'}
                                        name="ChucvuId"
                                        onFocus={() => setInvalidFields([])}
                                    />
                                    {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'ChucvuId') && (
                                        <small>{invalidFields.find((i) => i.name === 'ChucvuId')?.message}</small>
                                    )}
                                </div>

                                <div className="form-group col-md-4">
                                    <label htmlFor="inputZip" className="mb-2 fw-bold">
                                        Kiểu lương <b className="text-danger">(*)</b>
                                    </label>
                                    <Select
                                        value={payload?.KieuluongId}
                                        onChange={handleOnSelect}
                                        options={listKieuluong}
                                        placeholder={'Hình thức làm việc'}
                                        name="KieuluongId"
                                        onFocus={() => setInvalidFields([])}
                                    />
                                    {invalidFields.length > 0 &&
                                        invalidFields.some((i) => i.name === 'KieuluongId') && (
                                            <small>
                                                {invalidFields.find((i) => i.name === 'KieuluongId')?.message}
                                            </small>
                                        )}
                                </div>

                                <div className="form-group col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                                            <span>
                                                Mức lương <b className="text-danger">(*)</b>
                                            </span>
                                        </label>
                                        <div className="form-control-wrap">
                                            <div className="form-text-hint">
                                                <span className="overline-title">VND</span>
                                            </div>
                                            <NumericFormat
                                                className="form-control px-2"
                                                value={payload.mucluongformat}
                                                // displayType={'text'}
                                                thousandSeparator={true}
                                                // suffix={'đ'}
                                                name="mucluong"
                                                onValueChange={(values) => {
                                                    const { formattedValue, value } = values;
                                                    setPayloadNhanvien((prev) => ({
                                                        ...prev,
                                                        ['mucluong']: value,
                                                    }));
                                                    setPayloadNhanvien((prev) => ({
                                                        ...prev,
                                                        ['mucluongformat']: formattedValue,
                                                    }));
                                                }}
                                            />
                                            {invalidFields.length > 0 &&
                                                invalidFields.some((i) => i.name === 'mucluong') && (
                                                    <small>
                                                        {invalidFields.find((i) => i.name === 'mucluong')?.message}
                                                    </small>
                                                )}
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group col-md-4">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Ngày vào làm việc'}
                                        value={payload?.ngayvaolamviec}
                                        setValue={setPayloadNhanvien}
                                        keyPayload={'ngayvaolamviec'}
                                        type="date"
                                        validate
                                    />
                                </div>
                                <div className="form-group col-md-4 mb-2">
                                    <label htmlFor="inputZip" className="mb-2 fw-bold">
                                        Công thức tính lương <b className="text-danger">(*)</b>
                                    </label>
                                    <Select
                                        value={payload?.CongthucId}
                                        onChange={handleOnSelect}
                                        options={listCongthuc}
                                        placeholder={'Chọn công thức tính lương'}
                                        name="CongthucId"
                                        onFocus={() => setInvalidFields([])}
                                    />
                                    {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'CongthucId') && (
                                        <small>{invalidFields.find((i) => i.name === 'CongthucId')?.message}</small>
                                    )}
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="inputZip" className="mb-2 fw-bold">
                                        Kiểu phép <b className="text-danger">(*)</b>
                                    </label>

                                    <Select
                                        value={payload?.type_nghiphep}
                                        onChange={handleOnSelect}
                                        options={dataKieuPhepNhanVien}
                                        placeholder={'Kiểu phép'}
                                        name="type_nghiphep"
                                        onFocus={() => setInvalidFields([])}
                                    />
                                    {invalidFields.length > 0 &&
                                        invalidFields.some((i) => i.name === 'type_nghiphep') && (
                                            <small>
                                                {invalidFields.find((i) => i.name === 'type_nghiphep')?.message}
                                            </small>
                                        )}
                                </div>
                                <div className="form-group col-md-4 mb-2">
                                    <>
                                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                                            Số ngày phép <Icon name="help-fill" id="songayphep"></Icon>
                                            <Tooltip isOpen={tooltipOpen} target="songayphep" toggle={toggle}>
                                                Không bao gôm ngày phép được tăng theo thâm niên!
                                            </Tooltip>
                                        </label>
                                    </>
                                    <input
                                        id="nghipheptieuchuan"
                                        name="nghipheptieuchuan"
                                        className="form-control"
                                        value={payload?.nghipheptieuchuan}
                                        onChange={(e) =>
                                            setPayloadNhanvien((prev) => ({
                                                ...prev,
                                                ['nghipheptieuchuan']: e.target.value,
                                            }))
                                        }
                                        onFocus={() => setInvalidFields([])}
                                    />
                                </div>
                                <div className="form-group col-md-4 mb-2">
                                    <>
                                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                                            Tồn phép cũ <Icon name="help-fill" id="sophepton"></Icon>
                                            <Tooltip isOpen={tooltipOpen1} target="sophepton" toggle={toggle1}>
                                                Tồn phép cũ trước khi tạo nhân viên trên hệ thống!
                                            </Tooltip>
                                        </label>
                                    </>
                                    <input
                                        id="sophepton"
                                        name="sophepton"
                                        className="form-control"
                                        type="number"
                                        value={payload?.sophepton}
                                        onChange={(e) =>
                                            setPayloadNhanvien((prev) => ({
                                                ...prev,
                                                ['sophepton']: e.target.value,
                                            }))
                                        }
                                        onFocus={() => setInvalidFields([])}
                                    />
                                </div>
                            </div>
                            <div className="mt-3">
                                <div className="form-group col-md-12">
                                    <div className="pb-0">
                                        <h6 className="text-primary">Thông tin người phụ thuộc</h6>
                                        <Button
                                            size="md"
                                            color="light"
                                            outline
                                            className="btn-white btn-dim btn-icon px-4 mt-2"
                                            onClick={() => addFormFieldsNPT()}
                                        >
                                            Thêm thông tin <Icon name="plus"></Icon>
                                        </Button>
                                    </div>
                                </div>
                                {formNPT?.length > 0 && (
                                    <DataTable className="card-stretch">
                                        <div  style={{ overflowY: 'hidden' }}>
                                            <DataTableBody>
                                                <DataTableHead className="bg-secondary">
                                                    <DataTableRow>
                                                        <span className="sub-text">Stt</span>
                                                    </DataTableRow>
                                                    <DataTableRow>
                                                        <span className="sub-text w-150px">Họ tên</span>
                                                    </DataTableRow>
                                                    <DataTableRow>
                                                        <span className="sub-text w-100px">Địa chỉ</span>
                                                    </DataTableRow>
                                                    <DataTableRow>
                                                        <span className="sub-text w-100px">Mã số thuế</span>
                                                    </DataTableRow>
                                                    <DataTableRow>
                                                        <span className="sub-text w-100px">Quan hệ với nhân viên</span>
                                                    </DataTableRow>
                                                    <DataTableRow>
                                                        <span className="sub-text w-100px">Số điện thoại</span>
                                                    </DataTableRow>
                                                    <DataTableRow>
                                                        <span className="sub-text w-100px">Ngày sinh</span>
                                                    </DataTableRow>
                                                    <DataTableRow>
                                                        <span className="sub-text w-100px">Ngày tính giảm trừ</span>
                                                    </DataTableRow>
                                                    <DataTableRow>
                                                        <span className="sub-text w-100px">Ngày kết thúc giảm trừ</span>
                                                    </DataTableRow>
                                                    <DataTableRow>
                                                        <span className="sub-text w-100px">CMT/CCCD</span>
                                                    </DataTableRow>
                                                    <DataTableRow className="nk-tb-col-tools text-end"></DataTableRow>
                                                </DataTableHead>
                                                {formNPT?.map((item, it) => {
                                                    return (
                                                        <DataTableItem key={it}>
                                                            <DataTableRow>
                                                                <span className="sub-text">{it + 1}</span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <input
                                                                    type="text"
                                                                    name="hoten"
                                                                    className="form-control"
                                                                    value={item.hoten || ''}
                                                                    onChange={(e) => handleChangeNPT(it, e)}
                                                                    onFocus={() => setInvalidFieldsNpt([])}
                                                                />
                                                                {invalidNpt.length > 0 &&
                                                                    invalidNpt.some(
                                                                        (i) => i.name === `hoten_${it}`,
                                                                    ) && (
                                                                        <small className="text-danger">
                                                                            {
                                                                                invalidNpt.find(
                                                                                    (i) => i.name === `hoten_${it}`,
                                                                                )?.message
                                                                            }
                                                                        </small>
                                                                    )}
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <input
                                                                    type="text"
                                                                    name="diachi"
                                                                    className="form-control"
                                                                    value={item.diachi || ''}
                                                                    onChange={(e) => handleChangeNPT(it, e)}
                                                                    onFocus={() => setInvalidFieldsNpt([])}
                                                                />
                                                                {invalidNpt.length > 0 &&
                                                                    invalidNpt.some(
                                                                        (i) => i.name === `diachi_${it}`,
                                                                    ) && (
                                                                        <small className="text-danger">
                                                                            {
                                                                                invalidNpt.find(
                                                                                    (i) => i.name === `diachi_${it}`,
                                                                                )?.message
                                                                            }
                                                                        </small>
                                                                    )}
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <input
                                                                    type="text"
                                                                    name="masothue"
                                                                    className="form-control"
                                                                    value={item.masothue || ''}
                                                                    onChange={(e) => handleChangeNPT(it, e)}
                                                                    onFocus={() => setInvalidFieldsNpt([])}
                                                                />
                                                                {invalidNpt.length > 0 &&
                                                                    invalidNpt.some(
                                                                        (i) => i.name === `masothue_${it}`,
                                                                    ) && (
                                                                        <small className="text-danger">
                                                                            {
                                                                                invalidNpt.find(
                                                                                    (i) => i.name === `masothue_${it}`,
                                                                                )?.message
                                                                            }
                                                                        </small>
                                                                    )}
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <input
                                                                    type="text"
                                                                    name="quanhevsnhanvien"
                                                                    className="form-control"
                                                                    value={item.quanhevsnhanvien || ''}
                                                                    onChange={(e) => handleChangeNPT(it, e)}
                                                                    onFocus={() => setInvalidFieldsNpt([])}
                                                                />
                                                                {invalidNpt.length > 0 &&
                                                                    invalidNpt.some(
                                                                        (i) => i.name === `quanhevsnhanvien_${it}`,
                                                                    ) && (
                                                                        <small className="text-danger">
                                                                            {
                                                                                invalidNpt.find(
                                                                                    (i) =>
                                                                                        i.name ===
                                                                                        `quanhevsnhanvien_${it}`,
                                                                                )?.message
                                                                            }
                                                                        </small>
                                                                    )}
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <input
                                                                    type="number"
                                                                    name="sodienthoai"
                                                                    className="form-control"
                                                                    value={item.sodienthoai || ''}
                                                                    onChange={(e) => handleChangeNPT(it, e)}
                                                                    onFocus={() => setInvalidFieldsNpt([])}
                                                                />
                                                                {invalidNpt.length > 0 &&
                                                                    invalidNpt.some(
                                                                        (i) => i.name === `sodienthoai_${it}`,
                                                                    ) && (
                                                                        <small className="text-danger">
                                                                            {
                                                                                invalidNpt.find(
                                                                                    (i) =>
                                                                                        i.name === `sodienthoai_${it}`,
                                                                                )?.message
                                                                            }
                                                                        </small>
                                                                    )}
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <input
                                                                    type="date"
                                                                    name="ngaysinh"
                                                                    className="form-control"
                                                                    value={item.ngaysinh || ''}
                                                                    onChange={(e) => handleChangeNPT(it, e)}
                                                                    onFocus={() => setInvalidFieldsNpt([])}
                                                                />
                                                                {invalidNpt.length > 0 &&
                                                                    invalidNpt.some(
                                                                        (i) => i.name === `ngaysinh_${it}`,
                                                                    ) && (
                                                                        <small className="text-danger">
                                                                            {
                                                                                invalidNpt.find(
                                                                                    (i) => i.name === `ngaysinh_${it}`,
                                                                                )?.message
                                                                            }
                                                                        </small>
                                                                    )}
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <input
                                                                    type="date"
                                                                    name="thoidiemtinhgiamtrutu"
                                                                    className="form-control"
                                                                    value={item.thoidiemtinhgiamtrutu || ''}
                                                                    onChange={(e) => handleChangeNPT(it, e)}
                                                                    onFocus={() => setInvalidFieldsNpt([])}
                                                                />
                                                                {invalidNpt.length > 0 &&
                                                                    invalidNpt.some(
                                                                        (i) => i.name === `thoidiemtinhgiamtrutu_${it}`,
                                                                    ) && (
                                                                        <small className="text-danger">
                                                                            {
                                                                                invalidNpt.find(
                                                                                    (i) =>
                                                                                        i.name ===
                                                                                        `thoidiemtinhgiamtrutu_${it}`,
                                                                                )?.message
                                                                            }
                                                                        </small>
                                                                    )}
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <input
                                                                    type="date"
                                                                    name="thoidiemtinhgiamtruden"
                                                                    className="form-control"
                                                                    value={item.thoidiemtinhgiamtruden || ''}
                                                                    onChange={(e) => handleChangeNPT(it, e)}
                                                                    onFocus={() => setInvalidFieldsNpt([])}
                                                                />
                                                                {invalidNpt.length > 0 &&
                                                                    invalidNpt.some(
                                                                        (i) =>
                                                                            i.name === `thoidiemtinhgiamtruden_${it}`,
                                                                    ) && (
                                                                        <small className="text-danger">
                                                                            {
                                                                                invalidNpt.find(
                                                                                    (i) =>
                                                                                        i.name ===
                                                                                        `thoidiemtinhgiamtruden_${it}`,
                                                                                )?.message
                                                                            }
                                                                        </small>
                                                                    )}
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <input
                                                                    type="text"
                                                                    name="cmnd"
                                                                    className="form-control"
                                                                    value={item.cmnd || ''}
                                                                    onChange={(e) => handleChangeNPT(it, e)}
                                                                    onFocus={() => setInvalidFieldsNpt([])}
                                                                />
                                                                {invalidNpt.length > 0 &&
                                                                    invalidNpt.some((i) => i.name === `cmnd_${it}`) && (
                                                                        <small className="text-danger">
                                                                            {
                                                                                invalidNpt.find(
                                                                                    (i) => i.name === `cmnd_${it}`,
                                                                                )?.message
                                                                            }
                                                                        </small>
                                                                    )}
                                                            </DataTableRow>
                                                            {/* <DataTableRow>
                                                                            {Number(it + 1) === Number(formEditNPT.id) ? (
                                                                                <Select
                                                                                    value={item.LoaisuatanId}
                                                                                    onChange={(selected) =>
                                                                                        handleSelectChangeNPT(it, selected)
                                                                                    }
                                                                                    // options={Loaisuatan}
                                                                                    placeholder={'Chọn loại suất ăn'}
                                                                                    name="LoaisuatanId"
                                                                                />
                                                                            ) : (
                                                                                <span className="sub-text">
                                                                                    {item?.LoaisuatanId?.label}
                                                                                </span>
                                                                            )}
                                                                        </DataTableRow> */}
                                                            <DataTableRow className="nk-tb-col-tools ">
                                                                <ul className="nk-tb-actions gx-4">
                                                                    {/* <button
                                                                                    onClick={() => handleEditNPT(it)}
                                                                                    className={
                                                                                        'btn btn-primary  badge text-white p-1'
                                                                                    }
                                                                                >
                                                                                    <em className="icon ni ni-edit"></em>
                                                                                </button> */}
                                                                    <>
                                                                        <button
                                                                            onClick={() => removeFormFieldsNPT(it)}
                                                                            className={
                                                                                'btn btn-danger  badge text-white p-1'
                                                                            }
                                                                        >
                                                                            <em className="icon ni ni-trash-alt"></em>
                                                                        </button>
                                                                    </>
                                                                </ul>
                                                            </DataTableRow>
                                                        </DataTableItem>
                                                    );
                                                })}
                                            </DataTableBody>
                                        </div>
                                    </DataTable>
                                )}
                            </div>
                            <div className="mt-3">
                                <div className="form-group col-md-12">
                                    <div className="pb-0">
                                        <h6 className="text-primary">Các khoản phụ cấp</h6>
                                        {formPC?.length < phucap?.length && (
                                            <Button
                                                size="md"
                                                color="light"
                                                outline
                                                className="btn-white btn-dim btn-icon px-4 mt-2"
                                                onClick={() => addFormFieldsPC()}
                                            >
                                                Thêm phụ cấp <Icon name="plus"></Icon>
                                            </Button>
                                        )}
                                    </div>
                                </div>
                                {formPC?.length > 0 && (
                                    <DataTable className="card-stretch">
                                         <div style={{ overflowY: 'hidden' }}>
                                            <DataTableBody>
                                                <DataTableHead>
                                                    <DataTableRow>
                                                        <span className="sub-text">Stt</span>
                                                    </DataTableRow>
                                                    <DataTableRow>
                                                        <span className="sub-text w-250px">Phụ cấp nhân viên</span>
                                                    </DataTableRow>
                                                    <DataTableRow>
                                                        <span className="sub-text w-250px">Giá trị</span>
                                                    </DataTableRow>
                                                    <DataTableRow className="nk-tb-col-tools text-end"></DataTableRow>
                                                </DataTableHead>
                                                {formPC?.map((item, it) => {
                                                    return (
                                                        <DataTableItem key={it}>
                                                            <DataTableRow>
                                                                <span className="sub-text">{it + 1}</span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <Select
                                                                    value={item.PhucapId}
                                                                    onChange={(selected) =>
                                                                        handleSelectChangePC(it, selected)
                                                                    }
                                                                    options={listPhucap}
                                                                    placeholder={'Chọn loại phụ cấp'}
                                                                    name="PhucapId"
                                                                    menuPosition="fixed"
                                                                    onFocus={() => setInvalidFieldsPc([])}
                                                                />
                                                                {invalidPc.length > 0 &&
                                                                    invalidPc.some(
                                                                        (i) => i.name === `PhucapId_${it}`,
                                                                    ) && (
                                                                        <small className="text-danger">
                                                                            {
                                                                                invalidPc.find(
                                                                                    (i) => i.name === `PhucapId_${it}`,
                                                                                )?.message
                                                                            }
                                                                        </small>
                                                                    )}
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <div className="form-control-wrap">
                                                                    <div className="form-text-hint">
                                                                        <span className="overline-title">VND</span>
                                                                    </div>
                                                                    <NumericFormat
                                                                        className="form-control px-2"
                                                                        thousandSeparator={true}
                                                                        name="giatri"
                                                                        onValueChange={(e) => handleChangePC(it, e)}
                                                                        onFocus={() => setInvalidFieldsPc([])}
                                                                    />
                                                                    {invalidPc.length > 0 &&
                                                                        invalidPc.some(
                                                                            (i) => i.name === `giatri_${it}`,
                                                                        ) && (
                                                                            <small className="text-danger">
                                                                                {
                                                                                    invalidPc.find(
                                                                                        (i) =>
                                                                                            i.name === `giatri_${it}`,
                                                                                    )?.message
                                                                                }
                                                                            </small>
                                                                        )}
                                                                </div>
                                                            </DataTableRow>

                                                            <DataTableRow className="nk-tb-col-tools ">
                                                                <ul className="nk-tb-actions gx-4">
                                                                    {/* <button
                                                                                    onClick={() => handleEditNPT(it)}
                                                                                    className={
                                                                                        'btn btn-primary  badge text-white p-1'
                                                                                    }
                                                                                >
                                                                                    <em className="icon ni ni-edit"></em>
                                                                                </button> */}
                                                                    <>
                                                                        <button
                                                                            onClick={() => removeFormFieldsPC(it)}
                                                                            className={
                                                                                'btn btn-danger  badge text-white p-1'
                                                                            }
                                                                        >
                                                                            <em className="icon ni ni-trash-alt"></em>
                                                                        </button>
                                                                    </>
                                                                </ul>
                                                            </DataTableRow>
                                                        </DataTableItem>
                                                    );
                                                })}
                                            </DataTableBody>
                                        </div>
                                    </DataTable>
                                )}
                            </div>
                            <div className="mt-3">
                                <div className="form-group col-md-12">
                                    <div className="pb-0">
                                        <h6 className="text-primary">Tài khoản ngân hàng</h6>
                                        <Button
                                            size="md"
                                            color="light"
                                            outline
                                            className="btn-white btn-dim btn-icon px-4 mt-2"
                                            onClick={() => addFormFieldsNH()}
                                        >
                                            Thêm tài khoản <Icon name="plus"></Icon>
                                        </Button>
                                    </div>
                                </div>

                                {formNH?.length > 0 && (
                                    <DataTable className="card-stretch">
                                        <div style={{ overflowY: 'hidden' }}>
                                            <DataTableBody>
                                                <DataTableHead>
                                                    <DataTableRow>
                                                        <span className="sub-text">Stt</span>
                                                    </DataTableRow>
                                                    <DataTableRow>
                                                        <span className="sub-text w-150px">Tên ngân hàng</span>
                                                    </DataTableRow>
                                                    <DataTableRow>
                                                        <span className="sub-text w-150px">Số tài khoản</span>
                                                    </DataTableRow>
                                                    <DataTableRow>
                                                        <span className="sub-text w-150px">Người thụ hưởng</span>
                                                    </DataTableRow>
                                                    {/* <DataTableRow>
                                                                    <span className="sub-text">Trạng thái</span>
                                                                </DataTableRow> */}
                                                    <DataTableRow className="nk-tb-col-tools text-end"></DataTableRow>
                                                </DataTableHead>
                                                {formNH?.map((item, it) => {
                                                    return (
                                                        <DataTableItem key={it}>
                                                            <DataTableRow>
                                                                <span className="sub-text">{it + 1}</span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <input
                                                                    type="text"
                                                                    name="tennganhang"
                                                                    className="form-control"
                                                                    value={item.tennganhang || ''}
                                                                    onChange={(e) => handleChangeNH(it, e)}
                                                                    onFocus={() => setInvalidFieldsNh([])}
                                                                />
                                                                {invalidNh.length > 0 &&
                                                                    invalidNh.some(
                                                                        (i) => i.name === `tennganhang_${it}`,
                                                                    ) && (
                                                                        <small className="text-danger">
                                                                            {
                                                                                invalidNh.find(
                                                                                    (i) =>
                                                                                        i.name === `tennganhang_${it}`,
                                                                                )?.message
                                                                            }
                                                                        </small>
                                                                    )}
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <input
                                                                    type="number"
                                                                    name="sotaikhoan"
                                                                    className="form-control"
                                                                    value={item.sotaikhoan || ''}
                                                                    onChange={(e) => handleChangeNH(it, e)}
                                                                    onFocus={() => setInvalidFieldsNh([])}
                                                                />
                                                                {invalidNh.length > 0 &&
                                                                    invalidNh.some(
                                                                        (i) => i.name === `sotaikhoan_${it}`,
                                                                    ) && (
                                                                        <small className="text-danger">
                                                                            {
                                                                                invalidNh.find(
                                                                                    (i) =>
                                                                                        i.name === `sotaikhoan_${it}`,
                                                                                )?.message
                                                                            }
                                                                        </small>
                                                                    )}
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <input
                                                                    type="text"
                                                                    name="nguoihuongthu"
                                                                    className="form-control"
                                                                    value={item.nguoihuongthu || ''}
                                                                    onChange={(e) => handleChangeNH(it, e)}
                                                                    onFocus={() => setInvalidFieldsNh([])}
                                                                />
                                                                {invalidNh.length > 0 &&
                                                                    invalidNh.some(
                                                                        (i) => i.name === `nguoihuongthu_${it}`,
                                                                    ) && (
                                                                        <small className="text-danger">
                                                                            {
                                                                                invalidNh.find(
                                                                                    (i) =>
                                                                                        i.name ===
                                                                                        `nguoihuongthu_${it}`,
                                                                                )?.message
                                                                            }
                                                                        </small>
                                                                    )}
                                                            </DataTableRow>

                                                            {/* <DataTableRow>
                                                                                <Select
                                                                                    value={item.TrangthaiId}
                                                                                    onChange={(selected) =>
                                                                                        handleSelectChangeNH(it, selected)
                                                                                    }
                                                                                    // options={Loaisuatan}
                                                                                    placeholder={'Chọn trạng thái'}
                                                                                    name="TrangthaiId"
                                                                                />
                                                                        
                                                                        </DataTableRow> */}
                                                            <DataTableRow className="nk-tb-col-tools ">
                                                                <ul className="nk-tb-actions gx-4">
                                                                    {/* <button
                                                                                    onClick={() => handleEditNPT(it)}
                                                                                    className={
                                                                                        'btn btn-primary  badge text-white p-1'
                                                                                    }
                                                                                >
                                                                                    <em className="icon ni ni-edit"></em>
                                                                                </button> */}
                                                                    <>
                                                                        <button
                                                                            onClick={() => removeFormFieldsNH(it)}
                                                                            className={
                                                                                'btn btn-danger  badge text-white p-1'
                                                                            }
                                                                        >
                                                                            <em className="icon ni ni-trash-alt"></em>
                                                                        </button>
                                                                    </>
                                                                </ul>
                                                            </DataTableRow>
                                                        </DataTableItem>
                                                    );
                                                })}
                                            </DataTableBody>
                                        </div>
                                    </DataTable>
                                )}
                            </div>
                            <div className="mt-3">
                                <div className="form-group col-md-12">
                                    <div className="pb-0">
                                        <h6 className="text-primary">Máy chấm công</h6>
                                        {formMCC?.length < maychamcong.length && (
                                            <Button
                                                size="md"
                                                color="light"
                                                outline
                                                className="btn-white btn-dim btn-icon px-4 mt-2"
                                                onClick={() => addFormFieldsMCC()}
                                            >
                                                Thêm máy <Icon name="plus"></Icon>
                                            </Button>
                                        )}
                                    </div>
                                </div>

                                {formMCC?.length > 0 && (
                                    <DataTable className="card-stretch">
                                        <div style={{ overflowY: 'hidden' }}>
                                            <DataTableBody>
                                                <DataTableHead>
                                                    <DataTableRow>
                                                        <span className="sub-text">Stt</span>
                                                    </DataTableRow>
                                                    <DataTableRow>
                                                        <span className="sub-text w-150px">
                                                            Tên/Vị trí máy chấm công
                                                        </span>
                                                    </DataTableRow>
                                                    <DataTableRow>
                                                        <span className="sub-text w-150px">
                                                            Mã chấm công nhân viên
                                                        </span>
                                                    </DataTableRow>
                                                    <DataTableRow>
                                                        <span className="sub-text w-150px">
                                                            Tên chấm công
                                                        </span>
                                                    </DataTableRow>
                                                    <DataTableRow>
                                                        <span className="sub-text w-150px">
                                                            Mã thẻ
                                                        </span>
                                                    </DataTableRow>
                                                    <DataTableRow className="nk-tb-col-tools text-end"></DataTableRow>
                                                </DataTableHead>
                                                {formMCC?.map((item, it) => {
                                                    return (
                                                        <DataTableItem key={it}>
                                                            <DataTableRow>
                                                                <span className="sub-text">{it + 1}</span>
                                                            </DataTableRow>

                                                            <DataTableRow>
                                                                <Select
                                                                    value={item.MaychamcongId}
                                                                    onChange={(selected) =>
                                                                        handleSelectChangeMCC(it, selected)
                                                                    }
                                                                    options={listMayChamCong}
                                                                    placeholder={'Chọn máy chấm công'}
                                                                    name="MaychamcongId"
                                                                    menuPosition="fixed"
                                                                    onFocus={() => setInvalidFieldsMcc([])}
                                                                />
                                                                {invalidMcc.length > 0 &&
                                                                    invalidMcc.some(
                                                                        (i) => i.name === `MaychamcongId_${it}`,
                                                                    ) && (
                                                                        <small className="text-danger">
                                                                            {
                                                                                invalidMcc.find(
                                                                                    (i) =>
                                                                                        i.name ===
                                                                                        `MaychamcongId_${it}`,
                                                                                )?.message
                                                                            }
                                                                        </small>
                                                                    )}
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <input
                                                                    type="number"
                                                                    name="machamcong"
                                                                    className="form-control"
                                                                    value={item.machamcong || ''}
                                                                    onChange={(e) => handleChangeMCC(it, e)}
                                                                    onFocus={() => setInvalidFieldsMcc([])}
                                                                />
                                                                {invalidMcc.length > 0 &&
                                                                    invalidMcc.some(
                                                                        (i) => i.name === `machamcong_${it}`,
                                                                    ) && (
                                                                        <small className="text-danger">
                                                                            {
                                                                                invalidMcc.find(
                                                                                    (i) =>
                                                                                        i.name === `machamcong_${it}`,
                                                                                )?.message
                                                                            }
                                                                        </small>
                                                                    )}
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <input
                                                                    type="text"
                                                                    name="tenchamcong"
                                                                    className="form-control"
                                                                    value={item.tenchamcong || ''}
                                                                    onChange={(e) => handleChangeMCC(it, e)}
                                                                    onFocus={() => setInvalidFieldsMcc([])}
                                                                />
                                                                {invalidMcc.length > 0 &&
                                                                    invalidMcc.some(
                                                                        (i) => i.name === `tenchamcong_${it}`,
                                                                    ) && (
                                                                        <small className="text-danger">
                                                                            {
                                                                                invalidMcc.find(
                                                                                    (i) =>
                                                                                        i.name === `tenchamcong_${it}`,
                                                                                )?.message
                                                                            }
                                                                        </small>
                                                                    )}
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <input
                                                                    type="text"
                                                                    name="mathe"
                                                                    className="form-control"
                                                                    value={item.mathe || ''}
                                                                    onChange={(e) => handleChangeMCC(it, e)}
                                                                    onFocus={() => setInvalidFieldsMcc([])}
                                                                />
                                                                {invalidMcc.length > 0 &&
                                                                    invalidMcc.some(
                                                                        (i) => i.name === `mathe_${it}`,
                                                                    ) && (
                                                                        <small className="text-danger">
                                                                            {
                                                                                invalidMcc.find(
                                                                                    (i) =>
                                                                                        i.name === `mathe_${it}`,
                                                                                )?.message
                                                                            }
                                                                        </small>
                                                                    )}
                                                            </DataTableRow>

                                                            <DataTableRow className="nk-tb-col-tools ">
                                                                <ul className="nk-tb-actions gx-4">
                                                                    {/* <button
                                                                                    onClick={() => handleEditNPT(it)}
                                                                                    className={
                                                                                        'btn btn-primary  badge text-white p-1'
                                                                                    }
                                                                                >
                                                                                    <em className="icon ni ni-edit"></em>
                                                                                </button> */}
                                                                    <>
                                                                        <button
                                                                            onClick={() => removeFormFieldsMCC(it)}
                                                                            className={
                                                                                'btn btn-danger  badge text-white p-1'
                                                                            }
                                                                        >
                                                                            <em className="icon ni ni-trash-alt"></em>
                                                                        </button>
                                                                    </>
                                                                </ul>
                                                            </DataTableRow>
                                                        </DataTableItem>
                                                    );
                                                })}
                                            </DataTableBody>
                                        </div>
                                    </DataTable>
                                )}
                            </div>
                            <div className="col-12 mt-5 text-end">
                                {payloadCauhinh?.id ? (
                                    <Button color="primary" onClick={handleSubmit}>
                                        Lưu thông tin
                                    </Button>
                                ) : (
                                    ''
                                )}
                                {payloadCauhinh?.id ? (
                                    ''
                                ) : (
                                    <Link
                                        className="nav-link active btn-warning px-5 py-1 btn"
                                        to={`${config.he_thong}${config.cau_hinh_doanh_nghiep}`}
                                    >
                                        Hãy vào đây để cập nhật "Cấu hình doanh nghiệp" trước khí thêm nhân viên
                                    </Link>
                                )}
                            </div>
                        </div>
                    </Card>
                </Block>
            </Content>

            <Modal
                isOpen={modalAvatar}
                toggle={() => setmodalAvatar(false)}
                className="modal-dialog-centered"
                size="md"
            >
                <ModalBody>
                    <a href="#cancel" onClick={(ev) => { ev.preventDefault(); setmodalAvatar(false)}} className="close">
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h5 className="title">Cập nhật ảnh đại diện</h5>
                        <div className="mt-4">
                            <AvatarCrop
                                setmodalAvatar={setmodalAvatar}
                                dataNhanvien={null}
                                setDataNhanvien={null}
                                setDataUrlAvatar={setDataUrlAvatar}
                            />
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};
export default CreateNhanVien;
