import actionTypes from './actionTypes'
import { apiGetGiaoviec, apiNewGiaoviec, apiEditGiaoviec, apiDeleteGiaoviec, 
    apiGetGiaoviecbang, apiNewGiaoviecbang, apiEditGiaoviecbang, apiDeleteGiaoviecbang, apiGetGiaoviecbangone } from '~/services/giaoviec'
import { toast } from "react-toastify";
export const getListGiaoviec = () => async (dispatch) => {
    try {
        const response = await apiGetGiaoviec()
        
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_GIAO_VIEC,
                giaoviec: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_GIAO_VIEC,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_GIAO_VIEC,
            giaoviec: null
        })
    }
}

export const NewGiaoviec = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiNewGiaoviec(payload)
            if (response?.data.success === 200) {
                dispatch(getListGiaoviec());
                dispatch(getListGiaoviecbangone());
                toast.success("Thêm mới giao việc thành công!")
                dispatch({
                    type: actionTypes.POST_NEW_GIAO_VIEC,
                    msg: 'OK'
                });
                
                
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_GIAO_VIEC,
                    msg: 'ERR'
                })
                toast.error("Thêm mới giao việc thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_GIAO_VIEC,
                giaoviec: null
            })
        }
    }
}
export const EditGiaoviec = (payload, typevv) => {
    return async (dispatch) => {
        try {
            const response = await apiEditGiaoviec(payload)
            // console.log('chek api apiEditGiaoviec', response)
            // console.log('chek api typevv', typevv)
            if (response?.data.success === 200) {
                dispatch(getListGiaoviec());
                dispatch(getListGiaoviecbangone());
                if(!typevv){
                    toast.success("Sửa thông tin giao việc thành công!")
                }
                dispatch({
                    type: actionTypes.PUT_EDIT_GIAO_VIEC,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_GIAO_VIEC,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin giao việc thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_GIAO_VIEC,
                giaoviec: null
            })
        }
    }
}
export const DeleteGiaoviec = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteGiaoviec(payload)
            // console.log('chek api apiDeleteGiaoviec', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListGiaoviec());
                dispatch(getListGiaoviecbangone());
                toast.success("Xóa thông tin giao việc thành công!")
                dispatch({
                    type: actionTypes.DELETE_GIAO_VIEC,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_GIAO_VIEC,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin giao việc thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_GIAO_VIEC,
                giaoviec: null
            })
        }
    }
}

export const getListGiaoviecbang = () => async (dispatch) => {
    try {
        const response = await apiGetGiaoviecbang()
        // console.log('responsegiaaao', response)
        if (response?.data.success === 200) {
            // console.log('responsegiaaao response?.data', response?.data)
            dispatch({
                type: actionTypes.GET_LIST_GIAO_VIEC_BANG,
                giaoviecbang: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_GIAO_VIEC_BANG,
                msgbang: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_GIAO_VIEC_BANG,
            giaoviecbang: null
        })
    }
}

export const NewGiaoviecbang = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiNewGiaoviecbang(payload)
            if (response?.data.success === 200) {
                // dispatch(getListGiaoviec());
                dispatch(getListGiaoviecbang());
                dispatch(getListGiaoviecbangone());
                toast.success("Thêm mới thành công!")
                dispatch({
                    type: actionTypes.POST_NEW_GIAO_VIEC_BANG,
                    msgbang: 'OK'
                });
                
                
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_GIAO_VIEC_BANG,
                    msgbang: 'ERR'
                })
                toast.error("Thêm mới thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_GIAO_VIEC_BANG,
                giaoviecbang: null
            })
        }
    }
}
export const EditGiaoviecbang = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditGiaoviecbang(payload)
            // console.log('chek api apiEditGiaoviec', response)
            if (response?.data.success === 200) {
                // dispatch(getListGiaoviec());
                dispatch(getListGiaoviecbang());
                dispatch(getListGiaoviecbangone());
                toast.success("Sửa thông tin thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_GIAO_VIEC_BANG,
                    msgbang: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_GIAO_VIEC_BANG,
                    msgbang: 'ERR'
                })
                toast.error("Sửa thông tin thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_GIAO_VIEC_BANG,
                giaoviecbang: null
            })
        }
    }
}
export const DeleteGiaoviecbang = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteGiaoviecbang(payload)
            // console.log('chek api apiDeleteGiaoviec', response?.data.success)
            if (response?.data.success === 200) {
                // dispatch(getListGiaoviec());
                dispatch(getListGiaoviecbang());
                dispatch(getListGiaoviecbangone());
                toast.success("Xóa thông tin thành công!")
                dispatch({
                    type: actionTypes.DELETE_GIAO_VIEC_BANG,
                    msgbang: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_GIAO_VIEC_BANG,
                    msgbang: 'ERR'
                })
                toast.error("Xóa thông tin thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_GIAO_VIEC_BANG,
                giaoviecbang: null
            })
        }
    }
}
export const getListGiaoviecbangone = () => async (dispatch) => {
    try {
        const response = await apiGetGiaoviecbangone()
        // console.log('responsegiaaao', response)
        if (response?.data.success === 200) {
            // console.log('responsegiaaao response?.data', response?.data)
            dispatch({
                type: actionTypes.GET_LIST_GIAO_VIEC_BANG_ONE,
                giaoviecbangone: response?.data.data,
                giaoviecbangonecolumn: response?.data.column
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_GIAO_VIEC_BANG_ONE,
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_GIAO_VIEC_BANG_ONE,
            giaoviecbangone: null
        })
    }
}