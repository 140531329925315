const data = {
  title: "Notifications",
  notification: [
    {
      id: "note106",
      icon: "bell-fill",
      iconStyle: "bg-warning-dim",
      text: "You have requested to Widthdrawl",
      time: "2023-07-03T04:02:22.000Z",
    },
    {
      id: "note105",
      icon: "curve-down-left",
      iconStyle: "bg-success-dim",
      text: "Your Deposit Order is placed",
      time: "2023-07-02T04:02:22.000Z",
    },
    {
      id: "note104",
      icon: "curve-down-right",
      iconStyle: "bg-warning-dim",
      text: "You have requested to Widthdrawl",
      time: "2023-07-01T04:02:22.000Z",
    },
    {
      id: "note103",
      icon: "curve-down-left",
      iconStyle: "bg-success-dim",
      text: "Your Deposit Order is placed",
      time: "2023-06-16T04:02:22.000Z",
    },
    {
      id: "note102",
      icon: "curve-down-right",
      iconStyle: "bg-warning-dim",
      text: "You have requested to Widthdrawl",
      time: "2023-06-16T04:02:22.000Z",
    },
    {
      id: "note101",
      icon: "curve-down-left",
      iconStyle: "bg-success-dim",
      text: "Your Deposit Order is placed",
      time: "2023-06-16T04:02:22.000Z",
    },
  ],
};
export default data;
