import actionTypes from './actionTypes'
import { apiGetNghiphepcongtac, apiNewNghiphepcongtac, apiEditNghiphepcongtac, apiDeleteNghiphepcongtac, apiDuyetphep } from '~/services/nghiphepcongtac'
import { toast } from "react-toastify";
import { getListBangtonghopphep } from './bangtonghopphep';
export const getListNghiphepcongtac = (payload) => async (dispatch) => {
    try {
        const response = await apiGetNghiphepcongtac(payload)
        // console.log('chek api lisst Nghiphepcongtac', response)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_NGHI_PHEP_CONG_TAC,
                nghiphepcongtac: response?.data,
                // cuont: response?.cuont,
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_NGHI_PHEP_CONG_TAC,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_NGHI_PHEP_CONG_TAC,
            nghiphepcongtac: null
        })
    }
}

export const NewNghiphepcongtac = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewNghiphepcongtac', payload)
            const response = await apiNewNghiphepcongtac(payload)
            // console.log('chek api apiNewNghiphepcongtac', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_NGHI_PHEP_CONG_TAC,
                    msg: 'OK'
                });
                dispatch(getListNghiphepcongtac({order: 'desc', by: 'id'}));
                toast.success("Thêm mới nghỉ phép công tác thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_NGHI_PHEP_CONG_TAC,
                    msg: 'ERR'
                })
                toast.error("Thêm mới nghỉ phép công tác thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_NGHI_PHEP_CONG_TAC,
                nghiphepcongtac: null
            })
        }
    }
}
export const EditNghiphepcongtac = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditNghiphepcongtac(payload)
            if (response?.data.success === 200) {
                dispatch(getListNghiphepcongtac({order: 'desc', by: 'id'}));
                dispatch(getListBangtonghopphep());
                toast.success("Sửa thông tin nghỉ phép công tác thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_NGHI_PHEP_CONG_TAC,
                    msg: 'OK',
                    // editnghiphepcongtac: response?.data?.aruser,
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_NGHI_PHEP_CONG_TAC,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin nghỉ phép công tác thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_NGHI_PHEP_CONG_TAC,
                nghiphepcongtac: null
            })
        }
    }
}
export const DeleteNghiphepcongtac = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteNghiphepcongtac(payload)
            // console.log('chek api apiDeleteNghiphepcongtac', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListNghiphepcongtac({order: 'desc', by: 'id'}));
                dispatch(getListBangtonghopphep());
                toast.success("Xóa thông tin nghỉ phép công tác thành công!")
                dispatch({
                    type: actionTypes.DELETE_NGHI_PHEP_CONG_TAC,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_NGHI_PHEP_CONG_TAC,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin nghỉ phép công tác thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_NGHI_PHEP_CONG_TAC,
                nghiphepcongtac: null
            })
        }
    }
}