import axiosConfig from '../axiosConfig'
import axios from "~/axiosConfig";

export const apiGetTaisan = (query) => new Promise(async (resolve, reject) => {
    // console.log('query',query)
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlyhethong/taisan`,
            params: query,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiGetTaisanNhanvien = (query) => new Promise(async (resolve, reject) => {
    // console.log('query',query)
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlyhethong/nhanvien/taisan`,
            params: query,
        })
        // console.log('response', response)
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiNewTaisan = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlyhethong/taisan',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiNewTaisanNhanvien = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlyhethong/nhanvien/taisan',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiUpdateTaisanNhanvien = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlyhethong/nhanvien/taisan',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiEditTaisan = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlyhethong/taisan',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiDeleteTaisan = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlyhethong/taisan',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiListTaiSanByNhanvien = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axios({
            method: 'get',
            url: `/api/v2/quanlyhethong/onenhanvien/taisan`,
            params: {
                NhanvienId: payload,
            },
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})