import React, {useState, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as actions from '~/store/actions';
import {ModalHoatdongsukien, ModalEditHoatdongsukien} from './Modal';
import Swal from 'sweetalert2';
import {
    Card,
} from 'reactstrap';
import Head from '~/layout/head/Head';
import Content from '~/layout/content/Content';
import {Block} from '~/components/block/Block';
import {
    Button,
    DataTable,
    Icon,
    PermissionUtils,
} from '~/components/Component';
import {dataSelectLoaiSuKien} from '~/utils/Datacustom';
import {ExportToCsv} from "export-to-csv";
import {toast} from "react-toastify";
import {MaterialReactTable, useMaterialReactTable} from "material-react-table";
import {MRT_Localization_VI} from "material-react-table/locales/vi";
import {Box, IconButton, Tooltip} from "@mui/material";
import {Delete as DeleteIcon, Edit as EditIcon} from "@mui/icons-material";

const Hoatdongsukien = () => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [showEditHoatdongsukien, setShowEditHoatdongsukien] = useState(false);
    const {hoatdongsukien} = useSelector((state) => state.quanly);
    // console.log('hoatdongsukien', hoatdongsukien)
    const [data, setData] = useState(hoatdongsukien);
    const [dataTmp, setDataTmp] = useState([]);
    const [listLoaisukien, setlistLoaisukien] = useState([]);
    useEffect(() => {
        dispatch(actions.getListHoatdongsukien());
        // dispatch(actions.getListNhanvien());
    }, []);
    
    const [showPhanquyen, setShowPhanquyen] = useState({});
    let dataPermissionUtils = PermissionUtils('hoatdongsukien', 'all');
    useEffect(() => {
        if (dataPermissionUtils) {
            setShowPhanquyen(dataPermissionUtils);
        }
    }, [dataPermissionUtils]);
    const handleFilter = async (typeFilter) => {
        let filterABC = dataTmp;
        if (typeFilter !== 'ALL') {
            filterABC = dataTmp.filter((item) => {
                return item.loaisukien === typeFilter;
            });
        }
        
        setData([...filterABC]);
    };
    const fieldList = {
        tensukien: '',
        mota: '',
        ngaybatdau: '',
        ngayketthuc: '',
        loaisukien: '',
        NhanvienId: '',
        chiphi: '',
        TrangthaiId: '',
    };
    useEffect(() => {
        setlistLoaisukien(dataSelectLoaiSuKien);
        if (hoatdongsukien && hoatdongsukien.length > 0) {
            setData([...hoatdongsukien]);
            setDataTmp([...hoatdongsukien]);
        }
    }, [hoatdongsukien]);
    const [modalTab, setModalTab] = useState(0);
    
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    
    const [dataIdHoatdongsukien, setDataIdHoatdongsukien] = useState([]);
    useEffect(() => {
        (hoatdongsukien && setShow(false)) || setShowEditHoatdongsukien(false);
    }, [hoatdongsukien]);
    const handleAddNew = async () => {
        setShow(true);
    };
    const handleEdit = async (data) => {
        setShowEditHoatdongsukien(true);
        await setDataIdHoatdongsukien(data);
    };
    const handleDelete = (data) => {
        Swal.fire({
            title: 'Bạn có chắc không?',
            text: 'Bạn sẽ không thể lấy lại khi xóa!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Không, bỏ qua!',
            confirmButtonText: 'Vâng, xóa nó đi!',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(actions.DeleteHoatdongsukien(data));
            }
        });
    };
    const formatVND = (data) => {
        return Number(data ?? 0).toLocaleString('it-IT', {
            style: 'currency',
            currency: 'vnd',
        });
    };
    
    const Example = () => {
        
        let dataAb = data;
        // Tạo dữ liêu export
        const columns = useMemo(
            () => [
                {
                    Header: '#', // Tiêu đề cột số thứ tự
                    header: 'STT', // Tiêu đề cột số thứ tự
                    id: 'rowNumber', // ID của cột
                    size: 50,
                    Cell: ({row}) => <div>{row?.index + 1}</div>, // Giá trị của cột là số thứ tự
                    enableSorting: false,
                    enablePinning: true,
                },
                {
                    accessorKey: 'tensukien',
                    enablePinning: true,
                    header: 'Tên sự kiện',
                },
                {
                    accessorKey: 'chiphi',
                    enablePinning: true,
                    header: 'Chi phí',
                    Cell: ({row}) => <div>{formatVND(row?.original?.chiphi)}</div>,
                },
                {
                    accessorKey: 'ngaybatdau',
                    enablePinning: true,
                    header: 'Ngày bắt đầu',
                },
                {
                    accessorKey: 'ngayketthuc',
                    enablePinning: true,
                    header: 'Ngày kết thúc',
                },
                {
                    accessorKey: 'loaisukien',
                    enablePinning: true,
                    header: 'Loại sự kiện',
                },
                {
                    accessorKey: 'TrangthaiId',
                    enablePinning: true,
                    header: 'Trạng thái',
                    Cell: ({row}) => (
                        <div
                            className={`${
                                row?.original?.TrangthaiId === 1 ? 'bg-success' : 'bg-danger'
                            } ms-0 badge text-white`}
                        >
                            {row?.original?.TrangthaiId === 1 ? ' Hoạt động' : 'Chưa hoạt động'}
                        </div>
                    ),
                },
                {
                    accessorKey: 'mrt-row-actions',
                    header: 'Thao tác',
                    enableSorting: false,
                    enablePinning: true,
                    Cell: ({row}) => (
                        <Box sx={{display: 'flex', gap: '1rem'}}>
                            {+showPhanquyen?.sua === 1 && (
                            <Tooltip title="Edit">
                                <IconButton onClick={() => handleEdit(row.original)}>
                                    <EditIcon/>
                                </IconButton>
                            </Tooltip>
                            )}
                            {+showPhanquyen?.xoa === 1 && (
                            <Tooltip title="Delete">
                                <IconButton color="error" onClick={() => handleDelete({
                                    id: row.original.id,
                                    status: row.original.TrangthaiId
                                })}>
                                    <DeleteIcon/>
                                </IconButton>
                            </Tooltip>
                            )}
                        </Box>
                    ),
                },
            
            
            ],
            [],
        );
        const csvOptions = {
            fileName: 'csv-data',
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            useBom: true,
            useKeysAsHeaders: false,
            headers: columns.map((c) => c.header),
        };
        
        
        const csvExporter = new ExportToCsv(csvOptions);
        
        const handleExportData = () => {
            const dataExport = dataAb?.map((item, indexx) => {
                let abc = {}
                abc.rowNumber = indexx + 1
                abc.tensukien = item.tensukien
                abc.chiphi = formatVND(item.chiphi)
                abc.ngaybatdau = item.ngaybatdau
                abc.ngayketthuc = item.ngayketthuc
                abc.loaisukien = item.loaisukien
                abc.TrangthaiId = item.TrangthaiId === 1 ? 'Đã duyệt' : 'Chưa duyệt'
                
                
                return abc
            })
            if (dataExport.length === 0) {
                toast.error("Không có dữ liệu để xuất!")
                return;
            }
            
            csvExporter.generateCsv(dataExport);
        };
        
        const table = useMaterialReactTable({
                columns,
                data,
                enableColumnResizing: false,
                enableDensityToggle: false,
                initialState: {
                    columnPinning: {left: ['rowNumber'], right: ['mrt-row-actions']},
                    density: 'compact'
                },
                
                state: {
                    expanded: true,
                    columnFilters,
                    globalFilter,
                    isLoading: dataAb.length === 0,
                    pagination,
                    sorting,
                },
                displayColumnDefOptions: {
                    'mrt-row-actions': {
                        sx: {width: '200px', paddingRight: '10px'},
                        header: 'Thao tác',
                        enableOrdering: true,
                        enablePinning: true,
                        enableColumnActions: true,
                    },
                },
                defaultDisplayColumn: {enableResizing: true},
                rowCount: totalRowCount,
                manualFiltering: true,
                manualPagination: true,
                manualSorting: true,
                onPaginationChange: setPagination,
                onColumnFiltersChange: setColumnFilters,
                onGlobalFilterChange: setGlobalFilter,
                onSortingChange: setSorting,
                localization: MRT_Localization_VI,
                positionActionsColumn: 'first',
                enableRowActions: true,
                enableColumnPinning: true,
                renderTopToolbarCustomActions: ({table}) => (
                    <Box sx={{display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap'}}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddNew}
                            sx={{mr: 2}}
                        >
                            + Thêm mới
                        </Button>
                        <Button
                            color="primary"
                            onClick={handleExportData}
                            variant="contained"
                        >
                            <Icon name="download-cloud me-2"></Icon> Xuất dữ liệu
                        </Button>
                    </Box>
                ),
                renderRowActions: ({row, table}) => (
                    <Box sx={{display: 'flex', gap: '1rem'}}>
                        <Tooltip title="Edit">
                            <IconButton onClick={() => handleEdit(row.original)}>
                                <EditIcon/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <IconButton color="error" onClick={() => handleDelete({
                                id: row.original.id,
                                status: row.original.TrangthaiId
                            })}>
                                <DeleteIcon/>
                            </IconButton>
                        </Tooltip>
                    
                    </Box>
                ),
            }
        );
        
        
        return (
            <MaterialReactTable
                table={table}
            />
        );
    };
    
    
    return (
        <React.Fragment>
            <Head title="Hoạt động sự kiện doanh nghiệp"></Head>
            <Content>
                <Card className="mt-0">
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner card-inner-lg px-4">
                            <h4>Quản lý hoạt động, Sự kiện</h4>
                            <div>
                                <ul className="nk-nav nav nav-tabs">
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link ${modalTab === 0 && 'active'}`}
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                setModalTab(0);
                                                handleFilter('ALL');
                                            }}
                                            href="#canhan"
                                        >
                                            Tất cả
                                        </a>
                                    </li>
                                    {listLoaisukien.length > 0
                                        ? listLoaisukien.map((item, index) => {
                                            return (
                                                <li className="nav-item " key={index}>
                                                    <a
                                                        className={`nav-link ${modalTab === item.order && 'active'}`}
                                                        onClick={(ev) => {
                                                            ev.preventDefault();
                                                            setModalTab(item.order);
                                                            handleFilter(item.value);
                                                        }}
                                                        href="#canhan"
                                                    >
                                                        {item.label}
                                                    </a>
                                                </li>
                                            );
                                        })
                                        : null}
                                </ul>
                            </div>
                        </div>
                    </DataTable>
                </Card>
                <Block>
                    <DataTable className="card-stretch border-0">
                        <div style={{overflowY: 'hidden'}}>
                            <DataTable className="card-stretch border-0">
                                <div className="card-inner position-relative card-tools-toggle">
                                    <Example/>
                                </div>
                            </DataTable>
                        </div>
                    </DataTable>
                </Block>
                
                <ModalHoatdongsukien show={show} fieldList={fieldList} setShow={() => setShow(false)}/>
                <ModalEditHoatdongsukien
                    show={showEditHoatdongsukien}
                    fieldList={fieldList}
                    setShow={() => setShowEditHoatdongsukien(false)}
                    dataIdHoatdongsukien={dataIdHoatdongsukien}
                />
            </Content>
        </React.Fragment>
    );
};

export default Hoatdongsukien;
