export const validateNhanVienKiemDinh = (fieldName, value) => {
    switch (fieldName) {
        case 'NhanvienId':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'KiemdinhatvsbhldId':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'chiphi':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'soluong':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'dongia':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        
        case 'ngaybatdau':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'ngayketthuc':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'TrangthaiId':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'ghichu':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        
        default:
            break;
    }
}