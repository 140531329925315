import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { InputForm, Button } from '~/components/Form';
import * as actions from '~/store/actions';
import { validatePhuCap } from '~/request/validatePhuCap';
import '../Modal.scss';
import { formatVietnameseToString } from '~/utils/Common/formatVietnameseToString'
import Modal from 'react-bootstrap/Modal';
import { Input } from 'reactstrap';
import {dataLoaiPhuCap} from "~/utils/Datacustom";
import Select from "react-select";
import Textarea from "~/components/Form/Textarea";
import { dataSelectStatus } from '~/utils/Datacustom';

const ModalPhucap = ({ show, setShow, fieldList }) => {
    const dispatch = useDispatch();
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayloadPhucap] = useState(fieldList);
    // console.log('payload', payload)
    const [listLoaiPhuCap, setListLoaiPhuCap] = useState(fieldList);
    useEffect(() => {
        setListLoaiPhuCap(dataLoaiPhuCap);
    }, [dataLoaiPhuCap]);
    useEffect(() => {
        show && setPayloadPhucap(fieldList);
    }, [show]);

    
    const handleSubmit = async () => {
        let invalids = validate(payload);
        if (invalids === 0) {
            let title = payload?.title
            let loaiPhuCap = payload?.loaiPhuCap?.value
            let maPhuCap = ''
                if (loaiPhuCap === 'PC_CHUYEN_CAN'){
                    maPhuCap = loaiPhuCap
                } else {
                    maPhuCap = 'PC_'+formatVietnameseToString(title)
                }
            let datapayload = {
                data: [
                    {
                        title: title,
                        loaiphucap: loaiPhuCap,
                        mota: payload.mota,
                        maphucap: maPhuCap.toUpperCase(),
                        apdungdongbaohiem: payload.apdungdongbaohiem === true ? '1' : '0',
                        apdungdongthue: payload.apdungdongthue === true ? '1' : '0',
                        TrangthaiId: payload.TrangthaiId.value,
                    },
                ],
            };
            // console.log('check payload', datapayload)
            dispatch(actions.NewPhucap(datapayload));
        }
    };
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayloadPhucap((prev) => ({ ...prev, [statename]: selected }));
        setPayloadPhucap((prev) => ({ ...prev, ['noidung']: selected.description }));
    };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validatePhuCap(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validatePhuCap(item[0], item[1])]);
                invalids++;
            }
        });
        return invalids;
    };
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">Thêm mới phụ cấp</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-12">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Tiêu đề'}
                            value={payload.title}
                            setValue={setPayloadPhucap}
                            keyPayload={'title'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-12">
                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                            Loại phụ cấp <b className="text-danger">(*)</b>
                        </label>
                        <Select
                            value={payload.loaiPhuCap}
                            onChange={handleOnSelect}
                            options={listLoaiPhuCap}
                            placeholder={'Loại phụ cấp'}
                            name="loaiPhuCap"
                            onFocus={() => setInvalidFields([])}
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'loaiPhuCap') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'loaiPhuCap')?.message}
                            </small>
                        )}
                    </div>
                    <div className="form-group col-md-12 mb-2">
                        <Textarea
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Mô tả loại phụ cấp'}
                            value={payload.noidung}
                            disabled={true}
                            setValue={setPayloadPhucap}
                            keyPayload={'noidung'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-12 mb-2">
                        <label htmlFor="inputZip" className="mb-2">
                            <span>
                                Mô tả <b className="text-danger">(*)</b>
                            </span>
                        </label>
                        <Input
                            value={payload.mota}
                            onChange={(e) => setPayloadPhucap((prev) => ({ ...prev, ['mota']: e.target.value }))}
                            rows={'2'}
                            name="mota"
                            type="textarea"
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'mota') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'mota')?.message}
                            </small>
                        )}
                    </div>
                    <div className=" mt-3 row">
                        <div className="form-group col-md-4">
                            <div className="custom-control custom-checkbox">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="apdungdongbaohiem"
                                    name="apdungdongbaohiem"
                                    onChange={(e) =>
                                        setPayloadPhucap((prev) => ({
                                            ...prev,
                                            ['apdungdongbaohiem']: e.target.checked,
                                        }))
                                    }
                                />
                                <label className="custom-control-label" htmlFor="apdungdongbaohiem">
                                    Áp dụng đóng bảo hiểm{' '}
                                </label>
                            </div>
                        </div>
                        <div className="form-group col-md-4">
                            <div className="custom-control custom-checkbox">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="apdungdongthue"
                                    name="apdungdongthue"
                                    onChange={(e) =>
                                        setPayloadPhucap((prev) => ({
                                            ...prev,
                                            ['apdungdongthue']: e.target.checked,
                                        }))
                                    }
                                />
                                <label className="custom-control-label" htmlFor="apdungdongthue">
                                    Áp dụng đóng thuế thu nhập cá nhân{' '}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                    <div className="form-group col-md-4">
                        <label className="mb-2 fw-bold">
                            <span>
                                Trạng thái <b className="text-danger">(*)</b>
                            </span>
                        </label>
                        <Select
                            value={payload.TrangthaiId}
                            onChange={(selected) =>
                                setPayloadPhucap((prev) => ({ ...prev, ['TrangthaiId']: selected }))
                            }
                            options={dataSelectStatus}
                            name="TrangthaiId"
                            onFocus={() => setInvalidFields([])}
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'TrangthaiId') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'TrangthaiId')?.message}
                            </small>
                        )}
                    </div>
                </div>
                </div>

                <div className="col-12 mt-5">
                    <Button text="Thêm phụ cấp" bClass="btn btn-primary px-5" textColor onClick={handleSubmit} />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalPhucap;
