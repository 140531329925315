import actionTypes from './actionTypes'
import { apiGetMoiquanhelaodong, apiNewMoiquanhelaodong, apiEditMoiquanhelaodong, apiDeleteMoiquanhelaodong } from '~/services/moiquanhelaodong'
import { toast } from "react-toastify";
export const getListMoiquanhelaodong = () => async (dispatch) => {
    try {
        const response = await apiGetMoiquanhelaodong()
        // console.log('chek api lisst Moiquanhelaodong', response?.data.success)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_MOI_QUAN_HE_LAO_DONG,
                moiquanhelaodong: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_MOI_QUAN_HE_LAO_DONG,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_MOI_QUAN_HE_LAO_DONG,
            moiquanhelaodong: null
        })
    }
}

export const NewMoiquanhelaodong = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewMoiquanhelaodong', payload)
            const response = await apiNewMoiquanhelaodong(payload)
            // console.log('chek api apiNewMoiquanhelaodong', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_MOI_QUAN_HE_LAO_DONG,
                    msg: 'OK'
                });
                dispatch(getListMoiquanhelaodong());
                toast.success("Thêm mới thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_MOI_QUAN_HE_LAO_DONG,
                    msg: 'ERR'
                })
                toast.error("Thêm mới thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_MOI_QUAN_HE_LAO_DONG,
                moiquanhelaodong: null
            })
        }
    }
}
export const EditMoiquanhelaodong = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditMoiquanhelaodong(payload)
            // console.log('chek api apiEditMoiquanhelaodong', response)
            if (response?.data.success === 200) {
                dispatch(getListMoiquanhelaodong());
                toast.success("Sửa thông tin thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_MOI_QUAN_HE_LAO_DONG,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_MOI_QUAN_HE_LAO_DONG,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_MOI_QUAN_HE_LAO_DONG,
                moiquanhelaodong: null
            })
        }
    }
}
export const DeleteMoiquanhelaodong = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteMoiquanhelaodong(payload)
            // console.log('chek api apiDeleteMoiquanhelaodong', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListMoiquanhelaodong());
                toast.success("Xóa thông tin thành công!")
                dispatch({
                    type: actionTypes.DELETE_MOI_QUAN_HE_LAO_DONG,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_MOI_QUAN_HE_LAO_DONG,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_MOI_QUAN_HE_LAO_DONG,
                moiquanhelaodong: null
            })
        }
    }
}