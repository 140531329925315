import {Card, Table} from "reactstrap";
import React from "react";
import ColumnChart from "~/components/partials/analytics/chart-column/ColumnChart";

export const ComponentBienDongNhanSuByChucVu = (data) => { //Table 1 : Bảng tông quan
    const dataShow = data?.dataBienDongNSCV?.total_tondau_cv
    const datatotal_month = data?.dataBienDongNSCV?.total_month
    const dataChart = dataShow?.flatMap((item) => item?.data || []);
    return <div className="col-12">
        <Table className="table table-bordered table-striped table-hover">
            <thead>
            <tr>
                <th className="w-25">Phòng ban</th>
                <th className="">Vị trí làm việc</th>
                {datatotal_month?.length > 0
                    ? datatotal_month?.map((item) => {
                        return (
                            <th className="text-center ">{item}</th>
                        )
                    })
                    : null}
            </tr>
            </thead>
            <tbody>
            {dataShow?.length > 0
                ? dataShow?.map((item) => {
                    return (
                        <tr>
                            <th className="w-25">{item?.Idchucvu}</th>
                            <th className="w-25">{item?.namePhongban}</th>
                            {item?.data?.length > 0
                                ? item?.data?.map((items) => {
                                    return (
                                        <td className="text-center">{items.value}</td>
                                    )
                                })
                                : null}
                        </tr>
                    )
                })
                : null}
            
            </tbody>
        </Table>
        <div className="py-3">
            <h6 className="overline-title text-primary">
                Biểu đồ biến động theo vị trí công việc
            </h6>
        </div>
        <Card className="pt-4">
            <ColumnChart
                columnChart={dataChart}
            />
        </Card>
    </div>
};
