import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { InputForm, Button } from '~/components/Form';
import * as actions from '~/store/actions';
import { validateLoaiPhuongTien } from '~/request/validateLoaiPhuongTien';
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import {dataSelectActive} from "~/utils/Datacustom";
import Textarea from "~/components/Form/Textarea";

const ModalLoaiphuongtien = ({ show, setShow, fieldList }) => {
    const [listTrangthai, setlistTrangthai] = useState([]);
    const dispatch = useDispatch();
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayload] = useState(fieldList);
    useEffect(() => {
        show && setPayload(fieldList);
    }, [show]);
    useEffect(() => {
        let TrangthaiId = dataSelectActive.map((item) => ({label: item.label, value: item.value}));
        setlistTrangthai(TrangthaiId);
    }, []);
    const handleSubmit = async () => {
        let invalids = validate(payload);
        console.log('payload', payload)
        if (invalids === 0) {
            let datapayload = {
                data: [
                    {
                        tenloaiphuongtien: payload.tenloaiphuongtien,
                        noidung: payload.noidung,
                        TrangthaiId: payload.TrangthaiId.value,
                    },
                ],
            };
            // console.log('check payload', datapayload)
            dispatch(actions.NewLoaiphuongtien(datapayload));
        }
    };
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayload((prev) => ({ ...prev, [statename]: selected }));
    };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateLoaiPhuongTien(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateLoaiPhuongTien(item[0], item[1])]);
                invalids++;
            }
        });
        return invalids;
    };
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">Thêm Loại phương tiện</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-8">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Loại phương tiện'}
                            value={payload.tenloaiphuongtien}
                            setValue={setPayload}
                            keyPayload={'tenloaiphuongtien'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                                Trạng thái <b className="text-danger">(*)</b>
                            </span>
                        </label>
                        <Select
                            value={payload.TrangthaiId}
                            onChange={handleOnSelect}
                            options={listTrangthai}
                            placeholder={'Trạng thái'}
                            name="TrangthaiId"
                            onFocus={() => setInvalidFields([])}
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'TrangthaiId') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'TrangthaiId')?.message}
                            </small>
                        )}
                    </div>
                    <div className="form-group col-md-12">
                        <Textarea
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Mô tả'}
                            value={payload.noidung}
                            setValue={setPayload}
                            keyPayload={'noidung'}
                            type="text"
                            validate
                        />
                    </div>
                </div>
        
                <div className="col-12 mt-5">
                    <Button text="Thêm mới" bClass="btn btn-primary px-5" textColor onClick={handleSubmit} />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalLoaiphuongtien;
