import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { InputForm } from '~/components/Form';
import * as actions from '~/store/actions';
import '../Modal.scss';
import Select from 'react-select';
import { validateSukien } from '~/request/validateSukien';
import { Modal, ModalBody, Input } from 'reactstrap';
import { Icon, SelectFinNhanvienfix } from '~/components/Component';
import { dataSelectActive, dataSelectLoaiSuKien } from '~/utils/Datacustom';

const ModalEditHoatdongsukien = ({ show, setShow, dataIdHoatdongsukien, fieldList }) => {
    const [listTrangthai, setlistTrangthai] = useState([]);
    const [listLoaisukien, setlistLoaisukien] = useState([]);
    const dispatch = useDispatch();
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayload] = useState(fieldList);
    const [listNhanvien, setlistNhanvien] = useState([]);
    const [showSelect, setShowSelect] = useState(false);

    useEffect(() => {
        let TrangthaiId = dataSelectActive.find((items) => {
            return items && items.value === dataIdHoatdongsukien?.TrangthaiId;
        });
        let loaisukien = dataSelectLoaiSuKien.find((items) => {
            return items && items.value === dataIdHoatdongsukien?.loaisukien;
        });
        show &&
            dataIdHoatdongsukien &&
            setPayload({
                tensukien: dataIdHoatdongsukien.tensukien,
                mota: dataIdHoatdongsukien.mota,
                ngaybatdau: dataIdHoatdongsukien.ngaybatdau,
                ngayketthuc: dataIdHoatdongsukien.ngayketthuc,
                chiphi: dataIdHoatdongsukien.chiphi || 0,
                TrangthaiId: { label: TrangthaiId?.label, value: TrangthaiId?.value },
                loaisukien: { label: loaisukien?.label, value: loaisukien?.value },
            });
        setlistNhanvien(
            dataIdHoatdongsukien?.Nhanviensukiens?.map((item) => ({
                value: item.NhanvienId,
                label: item.Nhanvien.hoten,
            })),
        );
    }, [dataIdHoatdongsukien, show]);
    useEffect(() => {
        setPayload((prev) => ({
            ...prev,
            ['NhanvienId']: listNhanvien,
        }));
    }, [listNhanvien]);

    useEffect(() => {
        setlistTrangthai(dataSelectActive);
        setlistLoaisukien(dataSelectLoaiSuKien);
    }, [dataSelectActive, dataSelectLoaiSuKien]);
    const handleSubmit = async () => {
        let invalids = validate(payload);
        if (invalids === 0) {
            let dataNhanhvien = listNhanvien.map((item) => ({ NhanvienId: item.value }));
            let datapayload = {
                data: [
                    {
                        id: dataIdHoatdongsukien.id,
                        tensukien: payload.tensukien,
                        mota: payload.mota,
                        ngaybatdau: payload.ngaybatdau,
                        ngayketthuc: payload.ngayketthuc,
                        chiphi: payload.chiphi || 0,
                        NhanvienId: dataNhanhvien,
                        TrangthaiId: payload?.TrangthaiId.value,
                        loaisukien: payload?.loaisukien.value,
                    },
                ],
            };
            // console.log('check payload', datapayload);
            dispatch(actions.EditHoatdongsukien(datapayload));
        }
    };
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayload((prev) => ({ ...prev, [statename]: selected }));
    };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateSukien(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateSukien(item[0], item[1])]);
                invalids++;
            }
        });
        return invalids;
    };
    return (
        <React.Fragment>
            <Modal isOpen={show} className="modal-dialog-centered modal-90w" size="lg" toggle={setShow}>
                <ModalBody>
                    <Icon name="cross-sm float-end fs-2" onClick={setShow}></Icon>
                    <h5 className="title mb-3">Cập nhật</h5>
                    <div className="row">
                        <div className="form-group col-md-12">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Tên sự kiện'}
                                value={payload.tensukien}
                                setValue={setPayload}
                                keyPayload={'tensukien'}
                                placeholder={'Tên sự kiện'}
                                type="text"
                                validate
                            />
                        </div>

                        <div className="form-group col-md-12 mb-2">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                <span>
                                    Mô tả <b className="text-danger">(*)</b>
                                </span>
                            </label>
                            <Input
                                value={payload.mota}
                                onChange={(e) =>
                                    setPayload((prev) => ({ ...prev, ['mota']: e.target.value }))
                                }
                                rows={'2'}
                                name="mota"
                                type="textarea"
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'mota') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'mota')?.message}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-12" onClick={() => setShowSelect(true)}>
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                Chọn Nhân Viên <b className="text-danger">(*)</b>
                            </label>
                            <Select
                                value={payload.NhanvienId}
                                name="NhanvienId"
                                isMulti
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'NhanvienId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'NhanvienId')?.message}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                Trạng thái <b className="text-danger">(*)</b>
                            </label>
                            <Select
                                value={payload.TrangthaiId}
                                onChange={handleOnSelect}
                                options={listTrangthai}
                                placeholder={'Trạng thái sự kiện'}
                                name="TrangthaiId"
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'TrangthaiId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'TrangthaiId')?.message}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                Loại sự kiện <b className="text-danger">(*)</b>
                            </label>
                            <Select
                                value={payload.loaisukien}
                                onChange={handleOnSelect}
                                options={listLoaisukien}
                                placeholder={'Loại sự kiện'}
                                name="loaisukien"
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'loaisukien') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'loaisukien')?.message}
                                </small>
                            )}
                        </div>

                        <div className="form-group col-md-4">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Ngày bắt đầu'}
                                value={payload.ngaybatdau}
                                setValue={setPayload}
                                keyPayload={'ngaybatdau'}
                                placeholder={'Ngày bắt đầu'}
                                type="date"
                                validate
                            />
                        </div>
                        <div className="form-group col-md-4">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Ngày kết thúc'}
                                value={payload.ngayketthuc}
                                setValue={setPayload}
                                keyPayload={'ngayketthuc'}
                                placeholder={'Ngày kết thúc'}
                                type="date"
                                validate
                            />
                        </div>
                        <div className="form-group col-md-4">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Chi phí'}
                                value={payload.chiphi || 0}
                                setValue={setPayload}
                                keyPayload={'chiphi'}
                                placeholder={'Chi Phí'}
                                type="number"
                            />
                        </div>
                    </div>
                    <div className="btn-group my-2">
                        <button className="btn btn-primary px-5" onClick={handleSubmit}>
                            Cập nhật
                        </button>
                    </div>
                </ModalBody>
            </Modal>
            <SelectFinNhanvienfix
                showSelect={showSelect}
                typeSelect={true}
                listNhanvien={listNhanvien}
                cauHinhId={dataIdHoatdongsukien?.id}
                typeSetting={'hoatdongsukien'} // 'chuyencan' || 'thamnien' || 'congthuc' || 'hoatdongsukien'
                setlistNhanvien={setlistNhanvien}
                setShowSelect={() => setShowSelect(false)}
            />
        </React.Fragment>
    );
};

export default ModalEditHoatdongsukien;
