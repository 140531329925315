import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { InputForm, Button } from '~/components/Form'
import * as actions from '~/store/actions'

import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
const ModalVanban = ({ show, setShow }) => {
  const { phongban } = useSelector(state => state.phongban)
  // console.log(phongban)
  const dispatch = useDispatch()
  const [invalidFields, setInvalidFields] = useState([])
  const [payload, setPayloadVanban] = useState({
    PhongbanId: '',
    vanban: '',
    motavitri: '',
  })
  useEffect(() => {
    show && setPayloadVanban({
      PhongbanId: '',
      vanban: '',
      motavitri: '',
    })
  }, [show])

  const handleSelect = (inputData, type) => {
    let result = [];
   
    if (type === 'phongban') {
      inputData.map((item, index) => {
        let object = {};
        object.label = item.phongban;
        object.value = item.id;
        result.push(object)
      })
    }

    return result;
  }
  const [listPhongban, setlistPhongban] = useState([])
 
  useEffect(() => {
    if ( phongban) {
      let dataSelectPhongban = handleSelect(phongban, 'phongban');
      setlistPhongban(dataSelectPhongban)
    }
  }, [phongban])
  const handleSubmit = async () => {
    let invalids = validate(payload)

    if (invalids === 0) {
      let datapayload = {
        PhongbanId: payload.PhongbanId.value,
        vanban: payload.vanban,
        motavitri: payload.motavitri,
      }
      // console.log('check payload', datapayload)
      dispatch(actions.NewVanban(datapayload))
    }
  }
  const handleOnSelect = async (selected, name) => {
    let statename = name.name;
    setPayloadVanban(prev => ({ ...prev, [statename]: selected }))
    // let stateCopy = {...payload};
    // stateCopy[statename] = selected;
    // setPayloadVanban({
    //   ...stateCopy
    // })
  }
  const validate = (payload) => {
    let invalids = 0
    let fields = Object.entries(payload)
    fields.forEach(item => {
      if (item[1] === '') {
        setInvalidFields(prev => [...prev, {
          name: item[0],
          message: 'Bạn không được bỏ trống trường này.'
        }])
        invalids++
      }
    })

    return invalids
  }
  return (
    <Modal
      show={show}
      onHide={setShow}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Thêm mới chức vụ
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
         
          <div className="form-group col-md-6 ">
            <label htmlFor="inputZip" className="mb-2">Phòng ban</label>
            <Select
              value={payload.PhongbanId}
              onChange={handleOnSelect}
              options={listPhongban}
              placeholder={'Chọn phòng ban'}
              name='PhongbanId'
              onFocus={() => setInvalidFields([])}
            />
            {invalidFields.length > 0 && invalidFields.some(i => i.name === 'PhongbanId') && <small className='text-danger' >{invalidFields.find(i => i.name === 'PhongbanId')?.message}</small>}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <InputForm
              setInvalidFields={setInvalidFields}
              invalidFields={invalidFields}
              label={'Chức vụ'}
              value={payload.vanban}
              setValue={setPayloadVanban}
              keyPayload={'vanban'}
              type='text'
            />
          </div>
          <div className="form-group col-md-6 ">
            <InputForm
              setInvalidFields={setInvalidFields}
              invalidFields={invalidFields}
              label={'Mô tả vị trí'}
              value={payload.motavitri}
              setValue={setPayloadVanban}
              keyPayload={'motavitri'}
              type='text'
            />
          </div>
        </div>

        <div className='col-12 mt-5'>
          <Button
            text='Thêm chức vụ'
            bClass='btn btn-primary px-5'
            textColor
            onClick={handleSubmit}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalVanban;

