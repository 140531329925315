import actionTypes from './actionTypes'
import { apiGetThongbaonoibo, apiNewThongbaonoibo, apiEditThongbaonoibo, apiDeleteThongbaonoibo } from '~/services/thongbaonoibo'
import { toast } from "react-toastify";
export const getListThongbaonoibo = () => async (dispatch) => {
    try {
        const response = await apiGetThongbaonoibo()
        // console.log('chek api lisst Thongbaonoibo', response?.data.success)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_THONG_BAO_NOI_BO,
                thongbaonoibo: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_THONG_BAO_NOI_BO,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_THONG_BAO_NOI_BO,
            thongbaonoibo: null
        })
    }
}

export const NewThongbaonoibo = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewThongbaonoibo', payload)
            const response = await apiNewThongbaonoibo(payload)
            // console.log('chek api apiNewThongbaonoibo', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_THONG_BAO_NOI_BO,
                    msg: 'OK'
                });
                dispatch(getListThongbaonoibo());
                toast.success("Thêm mới thông báo nội bộ thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_THONG_BAO_NOI_BO,
                    msg: 'ERR'
                })
                toast.error("Thêm mới thông báo nội bộ thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_THONG_BAO_NOI_BO,
                thongbaonoibo: null
            })
        }
    }
}
export const EditThongbaonoibo = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditThongbaonoibo(payload)
            // console.log('chek api apiEditThongbaonoibo', response)
            if (response?.data.success === 200) {
                dispatch(getListThongbaonoibo());
                toast.success("Sửa thông tin thông báo nội bộ thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_THONG_BAO_NOI_BO,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_THONG_BAO_NOI_BO,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin thông báo nội bộ thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_THONG_BAO_NOI_BO,
                thongbaonoibo: null
            })
        }
    }
}
export const DeleteThongbaonoibo = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteThongbaonoibo(payload)
            // console.log('chek api apiDeleteThongbaonoibo', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListThongbaonoibo());
                toast.success("Xóa thông tin thông báo nội bộ thành công!")
                dispatch({
                    type: actionTypes.DELETE_THONG_BAO_NOI_BO,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_THONG_BAO_NOI_BO,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin thông báo nội bộ thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_THONG_BAO_NOI_BO,
                thongbaonoibo: null
            })
        }
    }
}