import React, {useState, useEffect} from 'react';
import {InputForm, Button} from '~/components/Form';
import '../Modal.scss';
import Select from 'react-select';
import {validateQuanHeLaoDong} from '~/request/validateQuanHeLaoDong';
import {dataSelectStatus, typeProblemImportant} from '~/utils/Datacustom';
import {Modal, ModalBody} from 'reactstrap';
import {apiEditMoiquanhelaodong} from "~/services";
import {toast} from "react-toastify";
import Content from "~/layout/content/Content";
import Icon from "~/components/icon/Icon";
import Textarea from "~/components/Form/Textarea";
import {SelectFinNhanvienfix} from "~/components/Component";

const ModalEditMoiquanhelaodong = ({show, setShow, dataEdit, fieldList}) => {
    console.log('check dataEdit', dataEdit)
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayload] = useState(fieldList);
    const [showSelect, setShowSelect] = useState(false);
    const [listNhanvien, setlistNhanvien] = useState([]);
    useEffect(() => {
        let TrangthaiId = dataSelectStatus.find((items) => {
            return items && items.value === dataEdit?.TrangthaiId;
        });
        let type_problem = typeProblemImportant.find((items) => {
            return items && items.value === dataEdit?.type_problem;
        });
        show &&
        dataEdit &&
        setPayload({
            TrangthaiId: {label: TrangthaiId?.label, value: TrangthaiId?.value},
            type_problem: {label: type_problem?.label, value: type_problem?.value},
            tenvande: dataEdit?.tenvande,
            mota: dataEdit?.mota,
            ngaybatdau: dataEdit?.ngaybatdau,
            giatri: dataEdit?.giatri,
        });
        setlistNhanvien(
            dataEdit?.Moiquanhenhanviens?.map((item) => ({
                value: item?.Nhanvien?.id,
                label: item?.Nhanvien?.hoten,
            })),
        );
    }, [dataEdit, show]);
    const [listStatus, setlistStatus] = useState([]);
    const [dataType, setDataType] = useState([]);
    useEffect(() => {
        setlistStatus(dataSelectStatus)
        setDataType(typeProblemImportant)
    }, [dataSelectStatus, typeProblemImportant])
    useEffect(() => {
        setPayload((prev) => ({
            ...prev,
            ['NhanvienId']: listNhanvien,
        }));
    }, [listNhanvien]);
    const handleSubmit = async () => {
        let invalids = validate(payload);
        
        if (invalids === 0) {
            const dataNhanVien = listNhanvien?.map((item) => {
                return item.value
            });
            let datapayload = {
                data: [
                    {
                        id: dataEdit.id,
                        tenvande: payload.tenvande,
                        mota: payload.mota,
                        giatri: payload.giatri,
                        ngaybatdau: payload.ngaybatdau,
                        TrangthaiId: payload?.TrangthaiId.value,
                        type_problem: payload?.type_problem.value,
                        NhanvienId: dataNhanVien,
                    },
                ],
            };
            //   console.log('check payload', datapayload)
            const response = await apiEditMoiquanhelaodong(datapayload)
            // console.log('chek api apiNewMoiquanhelaodong', response)
            if (response?.data.success === 200) {
                setShow(false);
                toast.success("Cập nhật thành công!")
            } else {
                toast.error("Cập nhật thất bại!")
            }
            
        }
    };
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayload((prev) => ({...prev, [statename]: selected}));
    };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateQuanHeLaoDong(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateQuanHeLaoDong(item[0], item[1])]);
                invalids++;
            }
        });
        return invalids;
    };
    return (
        <React.Fragment>
            <Content>
                <Modal isOpen={show} toggle={setShow} className="modal-dialog-centered modal-90w">
                    <ModalBody>
                        <Icon name="cross-sm float-end fs-2" onClick={setShow}></Icon>
                        <h5 className="title my-2"> Thêm mới các khoản phát sinh khấu trừ</h5>
                        <div className="row">
                            <div className="form-group col-md-12">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Vấn đề'}
                                    value={payload.tenvande}
                                    setValue={setPayload}
                                    keyPayload={'tenvande'}
                                    type="text"
                                    validate
                                />
                            </div>
                            <div className="form-group col-md-12" onClick={() => setShowSelect(true)}>
                                <label htmlFor="inputZip" className="mb-2 fw-bold">
                                    Chọn Nhân Viên <b className="text-danger">(*)</b>
                                </label>
                                <Select
                                    value={payload.NhanvienId}
                                    name="NhanvienId"
                                    isMulti
                                    onFocus={() => setInvalidFields([])}
                                />
                                {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'NhanvienId') && (
                                    <small className="text-danger">
                                        {invalidFields.find((i) => i.name === 'NhanvienId')?.message}
                                    </small>
                                )}
                            </div>
                            <div className="form-group col-md-6 ">
                                <label htmlFor="inputZip" className="mb-2">Loại vấn đề phát sinh</label>
                                <Select
                                    value={payload.type_problem}
                                    onChange={handleOnSelect}
                                    options={dataType}
                                    placeholder={'Trạng thái'}
                                    name='status'
                                    onFocus={() => setInvalidFields([])}
                                />
                                {invalidFields.length > 0 && invalidFields.some(i => i.name === 'type_problem') &&
                                    <small
                                        className='text-danger'>{invalidFields.find(i => i.name === 'type_problem')?.message}</small>}
                            </div>
                            <div className="form-group col-md-6 ">
                                <label htmlFor="inputZip" className="mb-2">Trạng thái</label>
                                <Select
                                    value={payload.TrangthaiId}
                                    onChange={handleOnSelect}
                                    options={listStatus}
                                    placeholder={'Trạng thái'}
                                    name='TrangthaiId'
                                    onFocus={() => setInvalidFields([])}
                                />
                                {invalidFields.length > 0 && invalidFields.some(i => i.name === 'TrangthaiId') && <small
                                    className='text-danger'>{invalidFields.find(i => i.name === 'TrangthaiId')?.message}</small>}
                            
                            </div>
                            <div className="form-group col-md-6">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Giá trị (VNĐ)'}
                                    value={payload.giatri}
                                    setValue={setPayload}
                                    keyPayload={'giatri'}
                                    type="text"
                                    validate
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Ngày bắt đầu thực hiện'}
                                    value={payload.ngaybatdau}
                                    setValue={setPayload}
                                    keyPayload={'ngaybatdau'}
                                    className={"my-0"}
                                    type='DATE'
                                    validate
                                />
                            </div>
                            <div className="form-group col-md-12 mt-3 mb-2">
                                <Textarea
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Mô tả'}
                                    value={payload.mota}
                                    setValue={setPayload}
                                    keyPayload={'mota'}
                                    type="text"
                                    validate
                                />
                            </div>
                            
                            <div className="col-12 mt-5">
                                <Button text="Thêm mới" bClass="btn btn-primary px-5" textColor onClick={handleSubmit}/>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </Content>
            <SelectFinNhanvienfix
                showSelect={showSelect}
                typeSelect={true}
                listNhanvien={listNhanvien}
                setlistNhanvien={setlistNhanvien}
                cauHinhId={dataEdit?.id}
                typeSetting={'quanhenhanvien'} // 'chuyencan' || 'thamnien' || 'congthuc' || 'hoatdongsukien' || daychuyen
                setShowSelect={() => setShowSelect(false)}
            />
        
        </React.Fragment>
    );
};

export default ModalEditMoiquanhelaodong;
