import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '~/store/actions';
import { ModalMaychamcong, ModalEditMaychamcong } from '../Admin/Modal';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalBody,
    UncontrolledDropdown,
    Spinner,
    Card,
    Alert,
} from 'reactstrap';
import Head from '~/layout/head/Head';
import Content from '~/layout/content/Content';
import { Block } from '~/components/block/Block';
import {
    Button,
    Col,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    Icon,
    PaginationComponent,
    Row,
    RSelect,
    TooltipComponent,
    PermissionUtils,
    ExportXlsx,
} from '~/components/Component';
import { InputForm } from '~/components/Form';
import {
    apiEditCauhinhdoanhnghiep,
    apiGetCauhinhdoanhnghiep,
    apiNewCauhinhdoanhnghiep,
} from '~/services/cauhinhdoanhnghiep';
import { toast } from 'react-toastify';
import config from '~/config';

const Maychamcong = () => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [showEditMaychamcong, setShowEditMaychamcong] = useState(false);

    const { maychamcong } = useSelector((state) => state.maychamcong);
    const [data, setData] = useState(maychamcong);
    const fieldList = {
        ipmaychamcong: '',
        port: '',
        timeout: '',
        inport: '',
        tenmaychamcong: '',
        status: '',
        loaimay: '',
    };
    useEffect(() => {
        dispatch(actions.getListMayChamCong());
        // dispatch(actions.getListNguoiPhuThuoc());
    }, []);

    useEffect(() => {
        if (maychamcong && maychamcong.length > 0) {
            setData(maychamcong);
        }
    }, [maychamcong]);
    const [showPhanquyen, setShowPhanquyen] = useState({});

    let dataPermissionUtils = PermissionUtils('maychamcong', 'all');
    useEffect(() => {
        if (dataPermissionUtils) {
            setShowPhanquyen(dataPermissionUtils);
        }
    }, [dataPermissionUtils]);

    const namefile = 'Thông tin máy chấm công';
    const header = ['Stt', 'Tên máy', 'Ip', 'Port', 'Timeout', 'InPort', 'Trạng thái'];
    const [columns, setColumns] = useState(() => {
        const d = header.map((item, index) => index <= 8);
        return d;
    });
    const [dataFileMau, setDataFileMau] = useState([]);
    useEffect(() => {
        if (data.length > 0) {
            const datafilemau = data.map((item, index) => {
                return {
                    ...(columns[0] && { Stt: index + 1 }),
                    ...(columns[1] && { 'Tên máy': item?.tenmaychamcong }),
                    ...(columns[2] && { Ip: item?.ipmaychamcong }),
                    ...(columns[3] && { Port: item.port }),
                    ...(columns[4] && { Timeout: item.timeout }),
                    ...(columns[5] && { InPort: item.inport }),
                    ...(columns[6] && { 'Trạng thái': item?.status === '1' ? 'Hoạt Động' : 'Dừng Hoạt Động' }),
                };
            });
            setDataFileMau(datafilemau);
        }
    }, [data, columns]);

    const [sm, updateSm] = useState(false);
    const [tablesm, updateTableSm] = useState(false);
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [sort, setSortState] = useState('');
    // onChange function for searching name
    const onFilterChange = (e) => {
        setSearchText(e.target.value);
    };
    const toggle = () => setonSearch(!onSearch);
    const selectorCheck = (e) => {
        let newData;
        newData = data.map((item) => {
            item.checked = e.currentTarget.checked;
            return item;
        });
        setData([...newData]);
    };
    const onSelectChange = (e, id) => {
        let newData = data;
        let index = newData.findIndex((item) => item.id === id);
        newData[index].checked = e.currentTarget.checked;
        setData([...newData]);
    };
    useEffect(() => {
        if (onSearchText !== '') {
            const filteredObject = data.filter((item) => {
                return (
                    item.tenmaychamcong.toLowerCase().includes(onSearchText.toLowerCase())
                );
            });
            setData([...filteredObject]);
        } else {
            setData([...maychamcong]);
        }
    }, [onSearchText, setData]);
    // Get current list, pagination
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    useEffect(() => {
        (maychamcong && setShow(false)) || setShowEditMaychamcong(false);
    }, [maychamcong]);
    const handleAddNewMaychamcong = async () => {
        setShow(true);
    };
    const [dataIdMaychamcong, setDataIdMaychamcong] = useState([]);
    const handleEditMaychamcong = async (data) => {
        setShowEditMaychamcong(true);
        await setDataIdMaychamcong(data);
    };
    const handleDeleteMaychamcong = (data) => {
        Swal.fire({
            title: 'Bạn có chắc không?',
            text: 'Bạn sẽ không thể lấy lại khi xóa!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Không, bỏ qua!',
            confirmButtonText: 'Vâng, xóa nó đi!',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(actions.DeleteMayChamCong(data));
            }
        });
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const cauHinhDoanhNghiep = await apiGetCauhinhdoanhnghiep();
                const response = cauHinhDoanhNghiep.data.data.rows[0];
                // console.log('response', response);
                const datas = {
                    id: response?.id,
                    cron_capnhatcong: response?.cron_capnhatcong === 0 ? false : true,
                    cron_time: response?.cron_time,
                };
                setPayload(datas);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData().catch(console.error);
    }, []);
    const [modalTab, setModalTab] = useState('1');
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayload] = useState([
        {
            cron_capnhatcong: false,
            cron_time: '',
        },
    ]);
    const handleSubmit = async () => {
        const datapayload = {
            data: [
                {
                    cron_capnhatcong: payload?.cron_capnhatcong === true ? 1 : 0,
                    cron_time: payload?.cron_time,
                },
            ],
        };
        if (payload.id) {
            datapayload.data[0].id = payload.id;
            const response = await apiEditCauhinhdoanhnghiep(datapayload);
            response?.data.length === 0
                ? toast.error('Sửa thông tin không thành công!')
                : toast.success('Sửa thông tin thành công!');
        } else {
            // console.log('datapayload', datapayload)
            const response = await apiNewCauhinhdoanhnghiep(datapayload);
            response?.data.length === 0
                ? toast.error('Thêm thông tin không thành công!')
                : toast.success('Thêm thông tin thành công!');
        }
    };
    return (
        <React.Fragment>
            <Head title="Doanh nghiệp, Chi nhánh, Phòng ban, Chức Vụ"></Head>
            <Content>
                <Card className="mt-0">
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner card-inner-lg px-4">
                            <h4>Cấu hình Máy chấm công</h4>
                            <div>
                                <ul className="nk-nav nav nav-tabs">
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link ${modalTab === '1' && 'active'}`}
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                setModalTab('1');
                                                // onClickTabBangTongHopPhep();
                                            }}
                                            href="#maychamcong"
                                        >
                                            Máy chấm công
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link ${modalTab === '2' && 'active'}`}
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                setModalTab('2');
                                                // onClickTabNghiPhep();
                                            }}
                                            href="#congcuketnoi"
                                        >
                                            Cài đặt công cụ kết nối máy chấm công
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </DataTable>
                </Card>
                <div className="tab-content">
                    <div className={`tab-pane ${modalTab === '1' ? 'active' : ''}`} id="maychamcong">
                        <Block>
                            <DataTable className="card-stretch border-0">
                                <div className="card-inner position-relative card-tools-toggle pt-0">
                                    <div className="card-title-group">
                                        <div className="card-title">
                                            <h5 className="title"> Máy chấm công</h5>
                                            {+showPhanquyen?.them === 1 && (
                                                <Button
                                                    color="primary"
                                                    className="btn-icon px-4 mt-2"
                                                    onClick={handleAddNewMaychamcong}
                                                >
                                                    <Icon name="plus"></Icon> Thêm mới
                                                </Button>
                                            )}
                                        </div>
                                        <div className="card-tools">
                                            <ul className="btn-toolbar gx-1">
                                                <li>
                                                    <a
                                                        href="#search"
                                                        onClick={(ev) => {
                                                            ev.preventDefault();
                                                            toggle();
                                                        }}
                                                        className="btn btn-icon search-toggle toggle-search"
                                                    >
                                                        <Icon name="search"></Icon>
                                                    </a>
                                                </li>
                                                <li className="btn-toolbar-sep"></li>
                                                <li>
                                                        <div className="toggle-wrap nk-block-tools-toggle">
                                                            <Button
                                                                className={`btn-icon btn-trigger toggle-expand me-n1 ${
                                                                    sm ? 'active' : ''
                                                                }`}
                                                                onClick={() => updateSm(!sm)}
                                                            >
                                                                <Icon name="upload-cloud"></Icon>
                                                            </Button>
                                                            <div
                                                                className="toggle-expand-content-cloud"
                                                                style={{ display: sm ? 'block' : 'none' }}
                                                            >
                                                                <ul className="nk-block-tools g-3">
                                                                    <li className="nk-block-tools-opt">
                                                                        <ExportXlsx
                                                                            dataFileMau={dataFileMau}
                                                                            title={header}
                                                                            namefile={namefile}
                                                                            btnname={'Xuất dữ liệu'}
                                                                        />
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className={`card-search search-wrap ${!onSearch && 'active'}`}>
                                        <div className="card-body">
                                            <div className="search-content">
                                                <Button
                                                    className="search-back btn-icon toggle-search active"
                                                    onClick={() => {
                                                        setSearchText('');
                                                        toggle();
                                                    }}
                                                >
                                                    <Icon name="arrow-left"></Icon>
                                                </Button>
                                                <input
                                                    type="text"
                                                    className="border-transparent form-focus-none form-control"
                                                    placeholder="Tìm kiếm"
                                                    value={onSearchText}
                                                    onChange={(e) => onFilterChange(e)}
                                                />
                                                <Button className="search-submit btn-icon">
                                                    <Icon name="search"></Icon>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ overflowY: 'hidden' }}>
                                <DataTableBody>
                                    <DataTableHead>
                                        {columns[0] && (
                                            <DataTableRow>
                                                <span className="sub-text">Stt</span>
                                            </DataTableRow>
                                        )}
                                        {columns[1] && (
                                            <DataTableRow>
                                                <span className="sub-text w-200px">Tên Máy</span>
                                            </DataTableRow>
                                        )}
                                        {columns[2] && (
                                            <DataTableRow>
                                                <span className="sub-text w-150px">IP</span>
                                            </DataTableRow>
                                        )}
                                        {columns[3] && (
                                            <DataTableRow>
                                                <span className="sub-text w-80px">Port</span>
                                            </DataTableRow>
                                        )}
                                        {columns[4] && (
                                            <DataTableRow>
                                                <span className="sub-text w-80px">Timeout</span>
                                            </DataTableRow>
                                        )}
                                        {columns[5] && (
                                            <DataTableRow>
                                                <span className="sub-text w-80px">InPort</span>
                                            </DataTableRow>
                                        )}
                                        {columns[6] && (
                                            <DataTableRow>
                                                <span className="sub-text w-80px">Loại máy</span>
                                            </DataTableRow>
                                        )}
                                        {columns[7] && (
                                            <DataTableRow>
                                                <span className="sub-text w-100px">Trạng thái</span>
                                            </DataTableRow>
                                        )}
                                        <DataTableRow className="nk-tb-col-tools text-end">
                                            <UncontrolledDropdown>
                                                <DropdownToggle
                                                    color="tranparent"
                                                    className="btn btn-xs btn-outline-light btn-icon dropdown-toggle"
                                                >
                                                    <Icon name="plus"></Icon>
                                                </DropdownToggle>
                                                <DropdownMenu end className="dropdown-menu-xs">
                                                    <ul className="link-tidy sm no-bdr">
                                                        {header.length > 0 ? (
                                                            header.map((item, index) => (
                                                                <li key={index}>
                                                                    <div className="custom-control custom-control-sm custom-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="custom-control-input"
                                                                            id={`column-${index}`}
                                                                            checked={columns[index]}
                                                                            onChange={(e) =>
                                                                                setColumns((prev) => {
                                                                                    const newColumns = [...prev];
                                                                                    newColumns[index] =
                                                                                        e.target.checked;
                                                                                    return newColumns;
                                                                                })
                                                                            }
                                                                        />
                                                                        <label
                                                                            className="custom-control-label"
                                                                            htmlFor={`column-${index}`}
                                                                        >
                                                                            {item}
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                            ))
                                                        ) : (
                                                            <li>No data</li>
                                                        )}
                                                    </ul>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </DataTableRow>
                                    </DataTableHead>
                                    {currentItems.length > 0
                                        ? currentItems.map((item, index) => {
                                              return (
                                                  <DataTableItem key={item.id}>
                                                      {columns[0] && (
                                                          <DataTableRow size=" nk-tb-col-check mb">
                                                              <span className="tb-amount">{index + 1}</span>
                                                          </DataTableRow>
                                                      )}
                                                      {columns[1] && (
                                                          <DataTableRow>
                                                              <span className="tb-amount">{item?.tenmaychamcong}</span>
                                                          </DataTableRow>
                                                      )}
                                                      {columns[2] && (
                                                          <DataTableRow>
                                                              <span className="tb-amount">{item?.ipmaychamcong}</span>
                                                          </DataTableRow>
                                                      )}
                                                      {columns[3] && (
                                                          <DataTableRow>
                                                              <span className="tb-amount">{item.port}</span>
                                                          </DataTableRow>
                                                      )}
                                                      {columns[4] && (
                                                          <DataTableRow>
                                                              <span className="tb-amount">{item.timeout}</span>
                                                          </DataTableRow>
                                                      )}
                                                      {columns[5] && (
                                                          <DataTableRow>
                                                              <span className="tb-amount">{item.inport}</span>
                                                          </DataTableRow>
                                                      )}
                                                      {columns[6] && (
                                                          <DataTableRow>
                                                              <span className="tb-amount">{item.loaimay}</span>
                                                          </DataTableRow>
                                                      )}
                                                      {columns[7] && (
                                                          <DataTableRow>
                                                              <span
                                                                  className={`${
                                                                      item?.status === 1 ? 'bg-success' : 'bg-danger'
                                                                  } ms-0 badge text-white`}
                                                              >
                                                                  {item?.status === 1 ? 'Hoạt động' : 'Không hoạt động'}
                                                              </span>
                                                          </DataTableRow>
                                                      )}
                                                      <DataTableRow className="nk-tb-col-tools">
                                                          <ul className="nk-tb-actions gx-1">
                                                              {+showPhanquyen?.sua === 1 && (
                                                                  <li
                                                                      className="nk-tb-action-hidden"
                                                                      onClick={() => handleEditMaychamcong(item)}
                                                                  >
                                                                      <TooltipComponent
                                                                          tag="a"
                                                                          containerClassName="btn btn-trigger btn-icon"
                                                                          id={'edit' + item.id}
                                                                          icon="edit-alt-fill"
                                                                          direction="top"
                                                                          text="Sửa"
                                                                      />
                                                                  </li>
                                                              )}
                                                              {+showPhanquyen?.xoa === 1 && (
                                                                  <React.Fragment>
                                                                      <li
                                                                          className="nk-tb-action-hidden"
                                                                          onClick={() => handleDeleteMaychamcong(item)}
                                                                      >
                                                                          <TooltipComponent
                                                                              tag="a"
                                                                              containerClassName="btn btn-trigger btn-icon"
                                                                              id={'suspend' + item.id}
                                                                              icon="user-cross-fill"
                                                                              direction="top"
                                                                              text="Xóa"
                                                                          />
                                                                      </li>
                                                                  </React.Fragment>
                                                              )}
                                                              <li>
                                                                  <UncontrolledDropdown>
                                                                      {(+showPhanquyen?.sua === 1 ||
                                                                          +showPhanquyen?.xoa === 1) && (
                                                                          <DropdownToggle
                                                                              tag="a"
                                                                              className="dropdown-toggle btn btn-icon btn-trigger"
                                                                          >
                                                                              <Icon name="more-h"></Icon>
                                                                          </DropdownToggle>
                                                                      )}
                                                                      <DropdownMenu end>
                                                                          <ul className="link-list-opt no-bdr">
                                                                              {+showPhanquyen?.sua === 1 && (
                                                                                  <li
                                                                                      onClick={() =>
                                                                                          handleEditMaychamcong(item)
                                                                                      }
                                                                                  >
                                                                                      <DropdownItem
                                                                                          tag="a"
                                                                                          href="#edit"
                                                                                          onClick={(ev) => {
                                                                                              ev.preventDefault();
                                                                                          }}
                                                                                      >
                                                                                          <Icon name="edit"></Icon>
                                                                                          <span>Sửa</span>
                                                                                      </DropdownItem>
                                                                                  </li>
                                                                              )}
                                                                              {+showPhanquyen?.xoa === 1 && (
                                                                                  <React.Fragment>
                                                                                      <li className="divider"></li>
                                                                                      <li
                                                                                          onClick={() =>
                                                                                              handleDeleteMaychamcong(
                                                                                                  item,
                                                                                              )
                                                                                          }
                                                                                      >
                                                                                          <DropdownItem
                                                                                              tag="a"
                                                                                              href="#suspend"
                                                                                              onClick={(ev) => {
                                                                                                  ev.preventDefault();
                                                                                              }}
                                                                                          >
                                                                                              <Icon name="na"></Icon>
                                                                                              <span>Xóa</span>
                                                                                          </DropdownItem>
                                                                                      </li>
                                                                                  </React.Fragment>
                                                                              )}
                                                                          </ul>
                                                                      </DropdownMenu>
                                                                  </UncontrolledDropdown>
                                                              </li>
                                                          </ul>
                                                      </DataTableRow>
                                                  </DataTableItem>
                                              );
                                          })
                                        : null}
                                </DataTableBody>
                                </div>
                                <div className="card-inner">
                                    {currentItems.length > 0 ? (
                                        <PaginationComponent
                                            itemPerPage={itemPerPage}
                                            totalItems={maychamcong.length}
                                            paginate={paginate}
                                            currentPage={currentPage}
                                        />
                                    ) : (
                                        <div className="text-center">
                                            <div>
                                                <Spinner color="primary" />
                                            </div>
                                            <span className="text-silent">Chưa có dữ liệu!</span>
                                        </div>
                                    )}
                                </div>
                            </DataTable>
                        </Block>
                    </div>
                    <div className={`tab-pane ${modalTab === '2' ? 'active' : ''}`} id="cauhinhcron">
                        <Block>
                            <DataTable className="card-stretch border-0">
                                <div className="card-inner position-relative card-tools-toggle pt-0">
                                    <div className="card-title-group">
                                        <div className="card-title">
                                            <h5 className="title"> Cài đặt công cụ kết nối máy chấm công</h5>
                                            <Button color="primary mt-1">
                                                <Icon name="download-cloud"></Icon> <span>Tải công cụ</span>
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="row mt-2 my-5 mt-3">
                                        <div className="form-group col-md-4">
                                            <div className="preview-block">
                                                <Alert className="alert-icon mt-2" color="warning">
                                                    <Icon name="alert-circle" />
                                                    Kết nối công cụ máy chấm công và đồng bộ dữ liệu{' '}
                                                    <Link
                                                        className="alert-link"
                                                        to={`${config.he_thong}${config.cau_hinh_doanh_nghiep}`}
                                                    >
                                                        Xem tại đây
                                                    </Link>
                                                </Alert>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </DataTable>
                        </Block>
                    </div>
                    {/* <div className={`tab-pane ${modalTab === '3' ? 'active' : ''}`} id="cauhinhcron">
                        <Block>
                            <DataTable className="card-stretch border-0">
                                <div className="card-inner position-relative card-tools-toggle pt-0">
                                    <div className="card-title-group">
                                        <div className="card-title">
                                            <h5 className="title"> Tự động cập nhật dữ liệu máy chấm công</h5>
                                            <span
                                                className="text-white ms-0 px-5 py-2 badge justify-content-center btn btn-primary"
                                                onClick={(e) => handleSubmit(e)}
                                            >
                                                Cập nhật
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row mt-2 my-5">
                                        <div className="form-group col-md-3 ">
                                            <div className="preview-block">
                                                <span className="preview-title fw-bold">Tự động cập nhật</span>
                                                <div className="custom-control custom-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id="customCheck2"
                                                        onChange={(e) =>
                                                            setPayload((prev) => ({
                                                                ...prev,
                                                                ['cron_capnhatcong']: e.target.checked,
                                                            }))
                                                        }
                                                        checked={payload.cron_capnhatcong || false}
                                                    />
                                                    <label className="custom-control-label" htmlFor="customCheck2">
                                                        Không / Có
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        {payload.cron_capnhatcong === true && (
                                            <div className="form-group col-md-3">
                                                <InputForm
                                                    setInvalidFields={setInvalidFields}
                                                    invalidFields={invalidFields}
                                                    label={'Thời gian cập nhật'}
                                                    value={payload.cron_time}
                                                    setValue={setPayload}
                                                    keyPayload={'cron_time'}
                                                    type="time"
                                                    validate
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </DataTable>
                        </Block>
                    </div> */}
                </div>
                <ModalMaychamcong show={show} fieldList={fieldList} setShow={() => setShow(false)} />
                <ModalEditMaychamcong
                    show={showEditMaychamcong}
                    fieldList={fieldList}
                    setShow={() => setShowEditMaychamcong(false)}
                    dataIdMaychamcong={dataIdMaychamcong}
                />
            </Content>
        </React.Fragment>
    );
};

export default Maychamcong;
