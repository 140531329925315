import actionTypes from './actionTypes'
import {
    apiGetBangchamcongtonghop,
    apiNewBangchamcongtonghop,
    apiEditBangchamcongtonghop,
    apiDeleteBangchamcongtonghop,
} from '~/services/bangchamcongtonghop'
import { toast } from "react-toastify";
export const getListBangchamcongtonghop = () => async (dispatch) => {
    try {
        const response = await apiGetBangchamcongtonghop()
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_BANG_CHAM_CONG_TONG_HOP,
                bangchamcongtonghop: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_BANG_CHAM_CONG_TONG_HOP,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_BANG_CHAM_CONG_TONG_HOP,
            bangchamcongtonghop: null
        })
    }
}
export const NewBangchamcongtonghop = (payload) => {
    return async (dispatch) => {
        try {
           
            const response = await apiNewBangchamcongtonghop(payload)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_BANG_CHAM_CONG_TONG_HOP,
                    msg: 'OK'
                });
                dispatch(getListBangchamcongtonghop());
                toast.success(response?.data.message)
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_BANG_CHAM_CONG_TONG_HOP,
                    msg: 'ERR'
                })
                toast.error(response?.data.message)
            }
        return response
        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_BANG_CHAM_CONG_TONG_HOP,
                bangchamcongtonghop: null
            })
        }
    }
}
export const EditBangchamcongtonghop = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditBangchamcongtonghop(payload)
            if (response?.data.success === 200) {
                dispatch(getListBangchamcongtonghop());
                toast.success(response?.data.message)
                dispatch({
                    type: actionTypes.PUT_EDIT_BANG_CHAM_CONG_TONG_HOP,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_BANG_CHAM_CONG_TONG_HOP,
                    msg: 'ERR'
                })
                toast.error(response?.data.message)
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_BANG_CHAM_CONG_TONG_HOP,
                bangchamcongtonghop: null
            })
        }
    }
}
export const DeleteBangchamcongtonghop = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteBangchamcongtonghop(payload)
            if (response?.data.success === 200) {
                dispatch(getListBangchamcongtonghop());
                toast.success(response?.data.message)
                dispatch({
                    type: actionTypes.DELETE_BANG_CHAM_CONG_TONG_HOP,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_BANG_CHAM_CONG_TONG_HOP,
                    msg: 'ERR'
                })
                toast.error(response?.data.message)
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_BANG_CHAM_CONG_TONG_HOP,
                bangchamcongtonghop: null
            })
        }
    }
}