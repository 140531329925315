import React, {useEffect, useState} from "react";
import { DropdownToggle, DropdownMenu, UncontrolledDropdown, DropdownItem } from "reactstrap";
import { TCDoughnut } from "../../charts/analytics/AnalyticsCharts";
import {apiDashboardThoiGianChamCongRange} from "~/services";

const TrafficDougnut = () => {
    
    const [traffic, setTraffic] = useState("Tháng trước");
    const [thang, setThang] = useState(null);
    const [trafficData, setTrafficData] = useState({
        labels: ["Đi muộn", "Về sớm"],
        dataUnit: "People",
        legend: false,
        datasets: [
            {
                borderColor: "#fff",
                backgroundColor: ["#00aeef", "#185581"],
                data: [0, 0],
            },
        ],
    });
    const fetchDataThoiGianChamCongRange = async () => {
        try {
    let pbId = '';
    // Lấy ngày tháng hiện tại
    const currentDate = new Date();
    // Lấy tháng của ngày hiện tại
    const currentMonthValue = currentDate.getMonth() + 1;
    const currentYearValue = currentDate.getFullYear();
    // Lấy tháng của tháng trước đó
    const previousMonthValue = currentMonthValue === 1 ? 12 : currentMonthValue - 1;
    const previousYearValue = currentMonthValue === 1 ? currentYearValue - 1 : currentYearValue;

    const addLeadingZero = (value) => {
      return value < 10 ? `0${value}` : value;
    };
    if (traffic === 'Tháng trước') {
      pbId = `${previousYearValue}-${addLeadingZero(previousMonthValue)}-01`;
      setThang(`${addLeadingZero(previousMonthValue)}-${previousYearValue}`);
    } else {
      pbId = `${currentYearValue}-${addLeadingZero(currentMonthValue)}-01`;
      setThang(`${addLeadingZero(currentMonthValue)}-${currentYearValue}`);
    }
    // console.log('pbId', pbId)
            const dataRes = apiDashboardThoiGianChamCongRange(pbId);
            const [dataRess] = await Promise.all([dataRes]);
            let dataShow = dataRess?.data?.data?.Bangchamcongs
            if(dataShow){
            const sumTongdimuon = dataShow.reduce((acc, item) => acc + item.tongdimuon, 0);
            const sumTongvesom = dataShow.reduce((acc, item) => acc + item.tongvesom, 0);
            let dataChart = {
                labels: ["Đi muộn", "Về sớm"],
                dataUnit: "People",
                legend: false,
                datasets: [
                    {
                        borderColor: "#fff",
                        backgroundColor: ["#00aeef", "#185581"],
                        data: [sumTongdimuon, sumTongvesom],
                    },
                ],
            };
            setTrafficData(dataChart)
          }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    useEffect(() => {
        fetchDataThoiGianChamCongRange().catch(console.error)
    }, [traffic])
  return (
    <React.Fragment>
      {" "}
      <div className="card-title-group">
        <div className="card-title card-title-sm">
          <h6 className="title">Thống kê số lần nhân viên đi muộn về sớm theo tháng {thang}</h6>
        </div>
        <UncontrolledDropdown>
          <DropdownToggle className="dropdown-toggle dropdown-indicator btn btn-sm btn-outline-light btn-white">
            {traffic}
          </DropdownToggle>
          <DropdownMenu end className="dropdown-menu-xs">
            <ul className="link-list-opt no-bdr">
                <li className={traffic === "Tháng này" ? "active" : ""}>
                    <DropdownItem
                        href="#dropdownitem"
                        onClick={(e) => {
                            e.preventDefault();
                            setTraffic("Tháng này");
                        }}
                    >
                        <span>Tháng này</span>
                    </DropdownItem>
                </li>
              <li className={traffic === "Tháng trước" ? "active" : ""}>
                <DropdownItem
                  href="#dropdownitem"
                  onClick={(e) => {
                    e.preventDefault();
                    setTraffic("Tháng trước");
                  }}
                >
                  <span>Tháng trước</span>
                </DropdownItem>
              </li>
              
              {/* <li className={traffic === "30" ? "active" : ""}>
                <DropdownItem
                  href="#dropdownitem"
                  onClick={(e) => {
                    e.preventDefault();
                    setTraffic("30");
                  }}
                >
                  <span>30 Ngày</span>
                </DropdownItem>
              </li> */}
            </ul>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
      <div className="traffic-channel">
        <div className="traffic-channel-doughnut-ck">
          <TCDoughnut state={traffic} dataChart={trafficData} className="analytics-doughnut"></TCDoughnut>
        </div>
        <div className="traffic-channel-group g-2">
          <div className="traffic-channel-data">
            <div className="title">
              <span className="dot dot-lg sq" style={{ background: "#00aeef" }}></span>
              <span>Đi muộn</span>
            </div>
            <div className="amount">
              {trafficData?.datasets[0].data[0]} <small>Lần</small>
            </div>
          </div>
          <div className="traffic-channel-data">
            <div className="title">
              <span className="dot dot-lg sq" style={{ background: "#185581" }}></span>
              <span>Về sớm</span>
            </div>
            <div className="amount">
              {trafficData?.datasets[0].data[1]} <small>lần</small>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default TrafficDougnut;
