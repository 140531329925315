import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {InputForm, Button} from '~/components/Form'
import * as actions from '~/store/actions'
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import {
    dataSelectActive,
} from '~/utils/Datacustom'
import {validateNguoiPhuThuoc} from "~/request/validateNguoiPhuThuoc";
import {toast} from "react-toastify";
import {NewNguoiPhuThuoc} from "~/store/actions";

const ModalAddNPT = ({show, setShow, idNhanvien}) => {
    const [invalidFields, setInvalidFields] = useState([])
    const dispatch = useDispatch()
    const [dataNPT, setDataNPT] = useState([]);
    const [listActive, setlistActive] = useState([])
    const fieldList = {
        NhanvienId: idNhanvien,
        hoten: '',
        sodienthoai: '',
        diachi: '',
        ngaysinh: '',
        thoidiemtinhgiamtrutu: '',
        thoidiemtinhgiamtruden: '',
        quoctich: '',
        cmnd: '',
        giayto: '',
        so: '',
        quyenso: '',
        quocgia: '',
        tinh: '',
        quanhuyen: '',
        xaphuong: '',
        quanhevsnhanvien: '',
    }
    const [payload, setPayload] = useState(fieldList);
    useEffect(() => {
        
        show && setPayload(fieldList)
    }, [show])
    
    
    // console.log('payaaaaaaaaaaaaaa',payload)
    const validate = (payload) => {
        let invalids = 0
        let fields = Object.entries(payload)
        fields.forEach(item => {
            if (validateNguoiPhuThuoc(item[0], item[1]) !== undefined) {
                setInvalidFields(prev => [...prev, validateNguoiPhuThuoc(item[0], item[1])])
                invalids++
            }
        })
        return invalids
    }
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayload(prev => ({...prev, [statename]: selected}))
        
    }
    const handleSelect = (inputData, type) => {
        let result = [];
        if (type === 'status') {
            inputData?.map((item, index) => {
                let object = {};
                object.label = item.label;
                object.value = item.value;
                result.push(object)
            })
        }
        return result;
    }
    
    useEffect(() => {
        let dataActive = handleSelect(dataSelectActive, 'status');
        setlistActive(dataActive)
    }, [dataSelectActive])
    const handleSubmit = async () => {
        let invalids = validate(payload)
        if (invalids === 0) {
            let data = {
                "data": [
                    {
                        NhanvienId: idNhanvien,
                        hoten: payload.hoten,
                        sodienthoai: payload.sodienthoai,
                        diachi: payload.diachi,
                        ngaysinh: payload.ngaysinh,
                        thoidiemtinhgiamtrutu: payload.thoidiemtinhgiamtrutu,
                        thoidiemtinhgiamtruden: payload.thoidiemtinhgiamtruden,
                        quoctich: payload.quoctich,
                        cmnd: payload.cmnd,
                        giayto: payload.giayto,
                        so: payload.so,
                        quyenso: payload.quyenso,
                        quocgia: payload.quocgia,
                        tinh: payload.tinh,
                        masothue: payload.masothue,
                        quanhuyen: payload.quanhuyen,
                        xaphuong: payload.xaphuong,
                        quanhevsnhanvien: payload.quanhevsnhanvien,
                    }
                ]
            }
       
        let response = dispatch(actions.NewNguoiPhuThuoc(data))
        response.status === 200 ? setShow(false) : setShow(true)
    
        }
    }
    
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Thêm Người Phụ Thuộc
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Họ tên NPT'}
                            value={payload.hoten}
                            setValue={setPayload}
                            keyPayload={'hoten'}
                            type='text'
                            validate
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Địa chỉ'}
                            value={payload.diachi}
                            setValue={setPayload}
                            keyPayload={'diachi'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Mã số thuế NPT'}
                            value={payload.masothue}
                            setValue={setPayload}
                            keyPayload={'masothue'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Quan hệ với nhân viên'}
                            value={payload.quanhevsnhanvien}
                            setValue={setPayload}
                            keyPayload={'quanhevsnhanvien'}
                            type='text'
                            validate
                        />
                    </div>
                    
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Số điện thoại'}
                            value={payload.sodienthoai}
                            setValue={setPayload}
                            keyPayload={'sodienthoai'}
                            type='text'
                        />
                    </div>
                   
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ngày sinh'}
                            value={payload.ngaysinh}
                            setValue={setPayload}
                            keyPayload={'ngaysinh'}
                            type='date'
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Bắt đầu tính giảm trừ'}
                            value={payload.thoidiemtinhgiamtrutu}
                            setValue={setPayload}
                            keyPayload={'thoidiemtinhgiamtrutu'}
                            type='date'
                            validate
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Kết thúc tinh giảm trừ'}
                            value={payload.thoidiemtinhgiamtruden}
                            setValue={setPayload}
                            keyPayload={'thoidiemtinhgiamtruden'}
                            type='date'
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Quốc tịch'}
                            value={payload.quoctich}
                            setValue={setPayload}
                            keyPayload={'quoctich'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'CMT/CCCD'}
                            value={payload.cmnd}
                            setValue={setPayload}
                            keyPayload={'cmnd'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Giấy tờ'}
                            value={payload.giayto}
                            setValue={setPayload}
                            keyPayload={'giayto'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Số'}
                            value={payload.so}
                            setValue={setPayload}
                            keyPayload={'so'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Quyền số'}
                            value={payload.quyenso}
                            setValue={setPayload}
                            keyPayload={'quyenso'}
                            type='number'
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Quốc gia'}
                            value={payload.quocgia}
                            setValue={setPayload}
                            keyPayload={'quocgia'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Tỉnh'}
                            value={payload.tinh}
                            setValue={setPayload}
                            keyPayload={'tinh'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Quận huyện'}
                            value={payload.quanhuyen}
                            setValue={setPayload}
                            keyPayload={'quanhuyen'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Xã phường'}
                            value={payload.xaphuong}
                            setValue={setPayload}
                            keyPayload={'xaphuong'}
                            type='text'
                        />
                    </div>
                 
                
                </div>
                <div className='col-12 mt-5'>
                    <Button
                        text='Cập nhật'
                        bClass='btn btn-primary px-5'
                        textColor
                        onClick={handleSubmit}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ModalAddNPT;

