import * as excelJs from "exceljs";

const ExportSalaryXlsx = ({namefile, headers, dataGr, footerData}) => {
    // console.log('headers',{data, namefile, headers, dataGr, footerData})
    return async () => {
        const workbook = new excelJs.Workbook();
        const ws = workbook.addWorksheet('Bảng lương XXX');
        
        const dataTmpKeys = Object.keys(dataGr[0].data[0]);
        const dataKeysFooter = dataTmpKeys.map(key =>{
            const keyTmp = key.split('.');
            return keyTmp[keyTmp.length - 1];
        });
        // console.log('dataKeysFooter',dataKeysFooter)
        const fills = {
            yellowDarkVertical: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'FFFF00'}, // Yellow background
                font30Bold: {
                    size: 30, // Set font size to 20
                    name: 'Times New Roman',
                    bold: true,
                    alignment: {
                        horizontal: 'center',
                        vertical: 'middle',
                        wrapText: true
                    },
                },
            },
            blueDarkVertical: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: '1ee0ac'}, // Blue background
                font30Bold: {
                    size: 30, // Set font size to 20
                    name: 'Times New Roman',
                    bold: true,
                    alignment: {
                        horizontal: 'center',
                        vertical: 'middle',
                        wrapText: true
                    },
                },
            },
            redDarkVertical: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'ff2d55'} // red background
            },
            
        };
        
        
        const font20Bold = {
            size: 20, // Set font size to 20
            name: 'Times New Roman',
            bold: true,
            alignment: {
                horizontal: 'center',
                vertical: 'middle',
                wrapText: true
            },
        };
        // const font30Bold = {
        //     size: 30, // Set font size to 20
        //     name: 'Times New Roman',
        //     bold: true,
        //     alignment: {
        //         horizontal: 'center',
        //         vertical: 'middle',
        //     },
        // };
        // const font16 = {
        //     size: 16, // Set font size to 14
        //     name: 'Times New Roman',
        //     alignment: {
        //         horizontal: 'center',
        //         vertical: 'middle',
        //         wrapText: true
        //     },
        // };
        // const font10 = {
        //     size: 10, // Set font size to 14
        //     name: 'Times New Roman',
        //     alignment: {
        //         horizontal: 'center',
        //         vertical: 'middle',
        //         wrapText: true
        //     },
        // };
        const font10Bold = {
            size: 10, // Set font size to 14
            name: 'Times New Roman',
            bold: true,
            alignment: {
                horizontal: 'center',
                vertical: 'middle',
                wrapText: true
            },
        };
        headers.forEach((headerRow, rowIndex) => {
            if(rowIndex < 5){ // 5 is the number of header rows is rowspan
                let columnIndex = 1;
                let rowI = rowIndex + 1;
                headerRow.forEach((header) => {
                    const cell = ws.getCell(rowI, columnIndex);
                    ws.getRow(rowI).height = 40;
                    ws.getRow(rowI).alignment = {wrapText: true};
                    let kbc = header.rowspan === 1 ? 0 : header.rowspan - 1;
                    cell.value = header.value;
                    const range = {
                        top: rowI,
                        left: columnIndex,
                        bottom: rowI + kbc,
                        right: columnIndex + header.colspan - 1
                    };
                    if (!ws.getCell(range.top, range.left).isMerged) {
                        ws.mergeCells(range);
                    }
                    cell.font = font10Bold;
                    columnIndex += header.colspan;
                });
            } else { // != 5 is the number of header rows is not rowspan
                let columnIndex = 1;
                let rowI = rowIndex + 1;
                headerRow.forEach((header) => {
                    const cell = ws.getCell(rowI, columnIndex);
                    cell.value = header.value;
                    ws.getRow(rowI).height = 40;
                    ws.getRow(rowI).alignment = {wrapText: true};
                    const range = {
                        top: rowI,
                        left: columnIndex,
                        bottom: rowI,
                        right: columnIndex + header.colspan - 1
                    };
                    // console.log('range', range)
                    if (!ws.getCell(range.top, range.left).isMerged) {
                        ws.mergeCells(range);
                    }
                    cell.font = font10Bold;
                    columnIndex += header.colspan;
                });
            }
            
        });
        const cellFormatting = {
            font: {
                size: 8,
                name: 'Times New Roman',
            },
            border: {
                top: {style: 'thin', color: {argb: '111111'}},
                left: {style: 'thin', color: {argb: '111111'}},
                bottom: {style: 'thin', color: {argb: '111111'}},
                right: {style: 'thin', color: {argb: '111111'}}
                
            },
            alignment: {
                horizontal: 'center',
                vertical: 'middle',
                wrapText: true,
            },
        };
        
        ws.eachRow((row) => {
            row.eachCell((cell) => {
                Object.assign(cell, cellFormatting);
            });
        });
        ws.pageSetup.paperSize = 9;
        ws.pageSetup.orientation = 'landscape';
        ws.pageSetup.margins = {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            header: 0.25,
            footer: 0
        };
        ws.pageSetup.fitToPage = true;
        ws.pageSetup.fitToWidth = 1;
        // ws.pageSetup.fitToHeight = 1;
        ws.eachRow({includeEmpty: true}, function (row, rowNumber) {
            if (rowNumber === 1) {
                row.eachCell(function (cell, colNumber) {
                    cell.fill = fills.yellowDarkVertical;
                    cell.font = font20Bold;
                    cell.alignment = font20Bold.alignment
                });
            }
            if (rowNumber > 1 && rowNumber <= 5) {
                row.eachCell(function (cell, colNumber) {
                    cell.font = font10Bold;
                    cell.alignment = font10Bold.alignment
                });
            }
            
        });
        dataGr.forEach((items, indexs) => {
            const dataTmp = items.data; // Data of the data group
            const dataTitle = items.titlePhongban; // Title of the data group
            const dataTitleParent = items.titleParent; // Title of the data group
            const dataTotal = items.total;  // Total of the data group
            // const totalRows = items.totalRows; // Total rows of the data group
            let rowIndex = headers.length + 1;
            for (let i = indexs; i > 0; i--) {
                const checkParentId = dataGr[i - 1].titleParent ? 1 : 0;
                rowIndex += dataGr[i - 1].data.length + 2 + checkParentId;
            }
            
            if (dataTitleParent) {
                const cell = ws.getCell(rowIndex, 1);
                cell.value = dataTitleParent;
                cell.fill = fills.blueDarkVertical;
                cell.font = {size: 10, name: 'Times New Roman', bold: true};
                cell.alignment = font10Bold.alignment
                const range = {top: rowIndex, left: 1, bottom: rowIndex, right: dataTmpKeys.length};
                if (!ws.getCell(range.top, range.left).isMerged) {
                    ws.mergeCells(range);
                }
                rowIndex++;
            }
            if (dataTitle) {
                const cell = ws.getCell(rowIndex, 1);
                cell.value = dataTitle;
                if (dataTitleParent.length === 0) {
                    cell.fill = fills.blueDarkVertical;
                    cell.font = font10Bold;
                    cell.alignment = font10Bold.alignment
                } else {
                    cell.fill = fills.yellowDarkVertical;
                    cell.font = font10Bold;
                    cell.alignment = font10Bold.alignment
                }
                
                const range = {top: rowIndex, left: 1, bottom: rowIndex, right: dataTmpKeys.length};
                if (!ws.getCell(range.top, range.left).isMerged) {
                    ws.mergeCells(range);
                }
                rowIndex++;
            }
            
            dataTmp.forEach((item, index) => {
                const row = ws.getRow(rowIndex + index);
                dataTmpKeys.forEach((key, keyIndex) => {
                    let value = isNaN(Number(item[key])) ? item[key] : Number(item[key])
                    row.getCell(keyIndex + 1).value = value || 0;
                    row.getCell(keyIndex + 1).border = {
                        top: {style: 'dotted', color: {argb: '111111'}},
                        left: {style: 'dotted', color: {argb: '111111'}},
                        bottom: {style: 'dotted', color: {argb: '111111'}},
                        right: {style: 'dotted', color: {argb: '111111'}}
                    };
                    row.getCell(keyIndex + 1).numFmt = '#,##0';
                });
            });
            
            rowIndex += dataTmp.length;
            dataTotal.forEach((item, index) => {
                dataTmpKeys.forEach((key, keyIndex) => {
                    const cell = ws.getCell(rowIndex + index, 1)
                    cell.font = font10Bold;
                    cell.value = 'Cộng';
                    cell.fill = fills.redDarkVertical;
                    cell.alignment = font10Bold.alignment
                    
                    const range = {
                        top: rowIndex + index,
                        left: 1,
                        bottom: rowIndex + index,
                        right: 4
                    };
                    if (!ws.getCell(range.top, range.left).isMerged) {
                        ws.mergeCells(range);
                    }
                    ws.getCell(rowIndex + index, keyIndex + 1).value = Number(item[key] || 0);
                    ws.getCell(rowIndex + index, keyIndex + 1).numFmt = '#,##0';
                });
            });
            rowIndex = dataTotal.length + 1;
        });
        const lastRowIndex = ws.rowCount;

// Now you can use this index to add your data at the end of the table
        console.log('dataTmpKeys',dataTmpKeys)
        console.log('footerData',footerData)
        footerData.forEach((item, index) => {
            dataKeysFooter.forEach((key, keyIndex) => {
                const rowIndex = lastRowIndex + index + 1; // Add 1 to move to the next row after the last one
                const cell = ws.getCell(rowIndex, 1)
                ws.getRow(rowIndex).height = 20;
                cell.font = {size: 10, name: 'Times New Roman', bold: true};
                cell.value = 'Tổng Cộng';
                cell.alignment = font10Bold.alignment
                const range = {
                    top: rowIndex,
                    left: 1,
                    bottom: rowIndex,
                    right: 4
                };
                if (!ws.getCell(range.top, range.left).isMerged) {
                    ws.mergeCells(range);
                }
                ws.getCell(rowIndex, keyIndex + 1).fill = fills.blueDarkVertical
                ws.getCell(rowIndex, keyIndex + 1).font = {size: 12, name: 'Times New Roman', bold: true};
                
                ws.getCell(rowIndex, keyIndex + 1).value = Number(item[key] || 0);
                ws.getCell(rowIndex, keyIndex + 1).numFmt = '#,##0';
            });
        });
        
        
        const excelBlob = await workbook.xlsx.writeBuffer();
        const excelUrl = URL.createObjectURL(
            new Blob([excelBlob], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
        );
        
        const link = document.createElement('a');
        link.href = excelUrl;
        link.download = `${namefile}.xlsx`;
        document.body.appendChild(link);
        link.click();
        
        URL.revokeObjectURL(excelUrl);
        document.body.removeChild(link);
    }
};
export default ExportSalaryXlsx;