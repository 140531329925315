import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { apiBlog } from '~/services';

const Blog = () => {
  const { slug } = useParams();
    console.log("slug", slug)
  // Sử dụng slug để tải dữ liệu bài viết từ API hoặc từ một nguồn dữ liệu khác
  // Ví dụ: fetch(`/api/posts/${slug}`)
  useEffect(() => {
    const fetchData = async () => {
        try {
            // let re = await apiBlog();
            // console.log('object', re)
        } catch (error) {
            console.error(error);
        }
    };
    fetchData().catch(console.error);
}, []);
  return (
    <div>
      <h1>Post Slug: {slug}</h1>
      {/* Hiển thị nội dung bài viết dựa trên slug */}
    </div>
  );
};

export default Blog;