import {Table} from "reactstrap";
import React from "react";
import {PreviewAltCard} from "~/components/preview/Preview";
import AudienceOverview from "~/components/partials/analytics/audience-overview/AudienceOverview";
import {SetDatadrawLineChart} from "~/pages/Admin/Modal/Baocao/ComponentChart";

export const ComponentThamNien = (data) => {
    const dataBienDongNSTN = data?.dataBienDongNSTN?.dataThamNien
    const datatotal_month = data?.dataBienDongNSTN?.total_month
    const colorLine = ['#ff0000', '#95ff87', '#02c7ff', '#9d72ff', '#ffdd11', '#FF9100FF', '#003A07FF', '#11dd55']
    
    const dataChartLine = dataBienDongNSTN?.map((item, ind) => (
        {
            data: item?.data,
            color: colorLine[ind],
            label: item?.title,
        }
    ))
    const chartData = SetDatadrawLineChart(dataChartLine, datatotal_month, '')
    
    const settingChart = {
        description: 'Mô tả biến động nhân sự theo thâm niên',
        title: 'Báo ca nhân sự theo thâm niên',
        title_out1: '',
        title_out2: 'Biểu đồ biến động thâm niên\n',
        data: chartData,
    }
    return <div className="col-12">
        <Table className="table table-bordered table-striped table-hover">
            <thead key={'thead'}>
            <tr>
                <th className="w-25">Thâm niên</th>
                {datatotal_month?.length > 0
                    ? datatotal_month?.map((item) => {
                        return (
                            <td className="text-center ">{item}</td>
                        )
                    })
                    : null}
            </tr>
            </thead>
            {/*setdataBienDongNSTN*/}
            <tbody key={'tbody'}>
            {dataBienDongNSTN?.length > 0
                ? dataBienDongNSTN?.map((item, index) => {
                    return (
                        <tr>
                            <th className="w-25">{item?.title}</th>
                            {item?.data?.length > 0
                                ? item?.data?.map((items, indexs) => {
                                    // console.log('dataBienDongVitriss',dataBienDongVitriss)
                                    return (
                                        <td className="text-center">{items}</td>
                                    )
                                })
                                : null}
                        
                        </tr>
                    )
                })
                : null}
            
            </tbody>
        </Table>
        <PreviewAltCard className="h-100">
            <AudienceOverview
                dataChart={settingChart?.data}
                titleChart={settingChart?.title}
                descriptionChart={settingChart?.description}
                titleOut={[settingChart?.title_out1, settingChart?.title_out2]}
            
            />
        </PreviewAltCard>
    </div>
};

