import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const PermissionUtils = (module, ar) => {
    const {FunctionUser } = useSelector((state) => state.app);
    useEffect(() => {
        FunctionUser && setDataFunctionUser(FunctionUser);
    }, [FunctionUser]);
    const [DataUserFunctionUser, setDataFunctionUser] = useState([]);
    const datam = DataUserFunctionUser?.find((item) => item?.Quanlyfunction?.tenfunction === module);
    if(ar === 'all'){
        return datam;
    }else{
        return ar === 'them' ? datam?.them : ar === 'sua' ? datam?.sua : ar === 'xoa' ?  datam?.xoa : ar === 'xem' ? datam?.xem : '';
    }
   
    // return datam ;
//v1
    // const datapermissionmodule = DataUser?.permissionmodule?.split(',');
    // const datapermission = DataUser?.permission?.split(',');
    // // const handleFind = (inputData) => {
    // //     const varpe = datapermission?.find((items, i) => i === inputData);
    // //     return varpe;
    // // };
    // // const dataInsert = datapermissionmodule?.map((item, index) => {
    // //     let data = {
    // //         module: item,
    // //         value: handleFind(index),
    // //     };
    // //     return data;
    // // });
    // const dataInsert = datapermissionmodule?.map((name, index) => {
    //   return { module: name, value: datapermission[index] };
    // });
    // // console.log('dataInsert', dataInsert)
    // // const datam = dataInsert?.find((item) => item.module === module);
    // // const datam1 = datam?.value.split('');
    // // const valuemodule = datam1?.find((itemv, iv) => iv === ar);
    // // return valuemodule;
    // return dataInsert?.find((item) => item.module === module)?.value.split('')?.find((itemv, iv) => iv === ar);
    
};
export default PermissionUtils;
