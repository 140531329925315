import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '~/store/actions'
import { ModalVanban, ModalEditVanban } from './Modal'
import Swal from 'sweetalert2'

const Vanban = () => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false);
    const [showEditVanban, setShowEditVanban] = useState(false);
   
    //lấy giá trị từ thằng redux trả về
    const { vanban } = useSelector(state => state.vanban)
    // console.log('list cong ty', vanban)
    useEffect(() => {
        vanban && setShow(false) || setShowEditVanban(false)
    }, [vanban])
    // set state để mở đóng Modal
    const handleAddNewVanban = async () => {
        setShow(true)
    }
    const [dataIdVanban, setDataIdVanban] = useState([]);
    const handleEditVanban = async (data) => {
        setShowEditVanban(true)
        await setDataIdVanban(data)
    }
    const handleDeleteVanban = (data) => {
        Swal.fire({
            title: 'Bạn có chắc không?',
            text: "Bạn sẽ không thể lấy lại khi xóa!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Không, bỏ qua!',
            confirmButtonText: 'Vâng, xóa nó đi!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(actions.DeleteVanban(data));
            }
        })
    }
    return (
        <div className="container-fluid px-4">
            <h1 className="mt-4">Văn bản</h1>
            {/* <ol className="breadcrumb mb-3">
                <li className="breadcrumb-item active">Danh sách chức vụ</li>
            </ol> */}
            {/* <div className="mx-3">
                <button className='btn btn-primary px-3 ' onClick={handleAddNewVanban}><i className="fas fa-plus"></i> Thêm mới chức vụ</button>
            </div> */}
            {/* <ModalVanban
                show={show}
                setShow={() => setShow(false)}
            /> */}
            {/* <ModalEditVanban
                show={showEditVanban}
                setShow={() => setShowEditVanban(false)}
                dataIdVanban={dataIdVanban}
            /> */}

            <div className="row my-3">
                <table className="table table-striped table-hover">
                    <tbody>
                        <tr>
                            <th>Công ty</th>
                            <th>Phòng ban</th>
                            <th>Chức vụ</th>
                            <th>Mô tả vị trí</th>
                            <th>Hành động</th>
                        </tr>
                        {vanban && vanban.length > 0 && vanban.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item?.Congty?.loaihinhcongty}</td>
                                    <td>{item?.Phongban?.phongban}</td>
                                    <td>{item.vanban}</td>
                                    <td>{item.motavitri}</td>
                                    <td>
                                        <button className="btn btn-success px-2 mx-2" onClick={() => handleEditVanban(item)}><i className="fas fa-pencil-alt"></i></button>
                                        <button className="btn btn-danger px-2" onClick={() => handleDeleteVanban(item)}><i className="fas fa-trash"></i></button>
                                    </td>
                                </tr>
                            )
                        })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Vanban;