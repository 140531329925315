import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import {Card, Table} from "reactstrap";
import {format, parseISO} from "date-fns";

const ModalInfo = ({show, setShow, dataIdPhuongtien, fieldList}) => {
    console.log('ModalInfo', dataIdPhuongtien)
    // const {loaiphuongtien} = useSelector((state) => state.quanly);
    // const [listLoaiphuongtien, setlistLoaiphuongtien] = useState(loaiphuongtien);
    const dispatch = useDispatch();
    const [dataInfo, setDataInfo] = useState(fieldList);
    //Lịch trình
    const [dataSchedule, setDataSchedule] = useState([]);
    //Đăng kiểm
    
    const [modalTab, setModalTab] = useState('1');
    // console.log('listLoaiphuongtien', listLoaiphuongtien)
    // useEffect(() => {
    //     if (loaiphuongtien && loaiphuongtien.length > 0) {
    //         let LoaiphuongtienId = loaiphuongtien.map((item) => ({label: item.tenloaiphuongtien, value: item.id}));
    //         setlistLoaiphuongtien(LoaiphuongtienId);
    //     }
    // }, [loaiphuongtien]);
 
    // const fetchData = async (data) => {
    //     try {
    //         let dataPayload = {NhanvienId: '', PhuongtienId: data}
    //         const dataApi = await apiGetKehoachcuaxeByNhanVien(dataPayload);
    //         let res = dataApi.data.data.rows;
    //         setDataSchedule(res);
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //     }
    // };
    
    
    useEffect(() => {
        // fetchData(dataIdPhuongtien.id).catch(console.error);
        show &&
        dataIdPhuongtien &&
        setDataInfo(dataIdPhuongtien);
    }, [dataIdPhuongtien, show]);
    console.log('abc:::::dataSchedule::::::::', dataSchedule)
    console.log('abc:::::DataInfo::::::::', dataInfo)
    // useEffect(() => {
    //     let TrangthaiId = dataSelectPhuongtien.map((item) => ({label: item.label, value: item.value}));
    //     setlistTrangthai(TrangthaiId);
    // }, []);
    
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">Thông tin chi tiết về phương tiện</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <Card className="card-bordered">
                        <div className="card-inner-group">
                            <ul className="nk-nav nav nav-tabs">
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${modalTab === '1' && 'active'}`}
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            setModalTab('1');
                                        }}
                                        href="#thongtinxe"
                                    >
                                        Danh sách xe
                                    </a>
                                </li>
                               
                            </ul>
                            <div className="card-inner">
                                <div className="tab-content">
                                    <div className={`tab-pane ${modalTab === '1' ? 'active' : ''}`} id="thongtinxe">
                                        <div className="between-center flex-wrap flex-md-nowrap g-3">
                                            <Table className="table table-bordered table-striped table-hover">
                                                <thead>
                                                <tr>
                                                    <th className="text-center">stt</th>
                                                    <th className="text-center">Nơi đăng ký</th>
                                                    <th className="text-center">Hãng xe</th>
                                                    <th className="text-center">Biển số xe</th>
                                                    <th className="text-center">Khung số</th>
                                                    <th className="text-center">Trạng thái</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {dataInfo?.Phuongtiens?.length > 0
                                                    ? dataInfo?.Phuongtiens?.map((item, index) => {
                                                        return (
                                                            <tr>
                                                                <td className="text-center">{index+1}</td>
                                                                <td className="text-center">{item?.noidangky}</td>
                                                                <td className="text-center">{item?.nhanhieu}</td>
                                                                <td className="text-center">{item?.biensoxe}</td>
                                                                <td className="text-center">{item?.sokhung}</td>
                                                                <td className="text-center">
                                                                         <span
                                                                             className={`${item?.TrangthaiId === 1 ? 'bg-success' : 'bg-danger'} ms-0 badge text-white`}>
                                                                            {item?.TrangthaiId === 1 ? 'Đang Hoạt động' : 'Kết thúc'}
                                                                        </span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    : null}
                                                
                                                </tbody>
                                            </Table>
                                        
                                        </div>
                                    </div>
                                    
                                </div>
                                {/*<h6 className={"mb-3 text-danger text-decoration-underline text-uppercase"}>Thông tin xe</h6>*/}
                            
                            </div>
                        
                        </div>
                    </Card>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalInfo;
