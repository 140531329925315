import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { InputForm, Button } from '~/components/Form'
import * as actions from '~/store/actions'

import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { validateLoaiHopDong } from "~/request/validateLoaiHopDong";

const ModalEditLoaihopdong = ({ show, setShow, dataIdLoaihopdong, fieldList }) => {
    // console.log(phongban)
    const dispatch = useDispatch()
    const [invalidFields, setInvalidFields] = useState([])
    const [payload, setPayloadLoaihopdong] = useState(fieldList)
    useEffect(() => {
        show && dataIdLoaihopdong && setPayloadLoaihopdong({
            tenloaihopdong: dataIdLoaihopdong?.tenloaihopdong,
            motaloaihopdong: dataIdLoaihopdong?.motaloaihopdong,
        })
    }, [dataIdLoaihopdong, show])
    const handleSubmit = async () => {
        let invalids = validate(payload)

        if (invalids === 0) {
            let datapayload = {
                id: dataIdLoaihopdong?.id,
                tenloaihopdong: payload?.tenloaihopdong,
                motaloaihopdong: payload?.motaloaihopdong,
            }
            //   console.log('check payload', datapayload)
            dispatch(actions.EditLoaihopdong(datapayload))
        }
    }

    const validate = (payload) => {
        let invalids = 0
        let fields = Object.entries(payload)
        fields.forEach(item => {
            if (validateLoaiHopDong(item[0], item[1]) !== undefined) {
                setInvalidFields(prev => [...prev, validateLoaiHopDong(item[0], item[1])])
                invalids++
            }
        })
        return invalids
    }
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Sửa loại hợp đồng
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className="row">
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Loại hợp đồng'}
                            value={payload.tenloaihopdong}
                            setValue={setPayloadLoaihopdong}
                            keyPayload={'tenloaihopdong'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-6 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Mô tả loại hợp đồng'}
                            value={payload.motaloaihopdong}
                            setValue={setPayloadLoaihopdong}
                            keyPayload={'motaloaihopdong'}
                            type='text'
                        />
                    </div>
                </div>

                <div className='col-12 mt-5'>
                    <Button
                        text='Sửa loại hợp đồng'
                        bClass='btn btn-primary px-5'
                        textColor
                        onClick={handleSubmit}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ModalEditLoaihopdong;

