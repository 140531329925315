
import actionTypes from './actionTypes'
import {
    apiGetTaiKhoanNganHang,
    apiNewTaiKhoanNganHang,
    apiEditTaiKhoanNganHang,
    apiDeleteTaiKhoanNganHang,
} from '~/services/taikhoannganhang'
import { toast } from "react-toastify";
export const getListTaiKhoanNganHang = () => async (dispatch) => {
    
    try {
        const response = await apiGetTaiKhoanNganHang()
        
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_NGAN_HANG,
                taikhoannganhang: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_NGAN_HANG,
                msg: response.data.msg
            })
        }
        
    } catch (error) {
        console.log('error TaiKhoanNganHangdsd')
        dispatch({
            type: actionTypes.GET_LIST_NGAN_HANG,
            taikhoannganhang: null
        })
    }
}

export const NewTaiKhoanNganHang = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewTaiKhoanNganHang', payload)
            const response = await apiNewTaiKhoanNganHang(payload)
            console.log('chek api apiNewTaiKhoanNganHang', response)
            if (response?.status === 200) {
                dispatch(getListTaiKhoanNganHang());
                toast.success("Thêm mới tài khoản ngân hàng thành công!")
                dispatch({
                    type: actionTypes.POST_NEW_NGAN_HANG,
                    msg: 'OK'
                });
                
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_NGAN_HANG,
                    msg: 'ERR'
                })
                toast.error("Thêm mới tài khoản ngân hàng thất bại!")
            }
            
        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_NGAN_HANG,
                taikhoannganhang: null
            })
        }
    }
}
export const EditTaiKhoanNganHang = (payload) => {
    console.log('chek api apiEditTaiKhoanNganHang', payload)
    return async (dispatch) => {
        try {
            const response = await apiEditTaiKhoanNganHang(payload)
            
            if (response?.data.success === 200) {
                dispatch(getListTaiKhoanNganHang());
                toast.success("Sửa thông tin tài khoản ngân hàng thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_NGAN_HANG,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_NGAN_HANG,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin tài khoản ngân hàng thất bại!")
            }
            
        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_NGAN_HANG,
                taikhoannganhang: null
            })
        }
    }
}
export const DeleteTaiKhoanNganHang = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteTaiKhoanNganHang(payload)
            // console.log('chek api apiDeleteTaiKhoanNganHang', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListTaiKhoanNganHang());
                toast.success("Xóa thông tin tài khoản ngân hàng thành công!")
                dispatch({
                    type: actionTypes.DELETE_NGAN_HANG,
                    msg: 'OK'
                });
                
                
            } else {
                dispatch({
                    type: actionTypes.DELETE_NGAN_HANG,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin tài khoản ngân hàng thất bại!")
            }
            
        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_NGAN_HANG,
                taikhoannganhang: null
            })
        }
    }
}