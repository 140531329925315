import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { InputForm } from '~/components/Form';
import * as actions from '~/store/actions';

import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import { matusinhResult } from '~/utils/Utils';
import Select from 'react-select';
import { apiNewPhieusuatan, apiGetSuatan } from '~/services';
import { toast } from 'react-toastify';
import {
    Button,
    Col,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    Icon,
    PaginationComponent,
    Row,
    RSelect,
    TooltipComponent,
    ExportXlsx,
    ImportXlsx,
    PaginationCount,
} from '~/components/Component';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { format } from 'date-fns';
import { dataSelectStatus } from '~/utils/Datacustom';
const CloseButton = () => {
    return (
        <span className="btn-trigger toast-close-button" role="button">
            <Icon name="cross"></Icon>
        </span>
    );
};
const ModalSuatan = ({ show, setShow, dataPhieusuatan, dataLoaisuatan }) => {
    const dispatch = useDispatch();
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayload] = useState([]);
    const Loaisuatan = dataLoaisuatan?.map((item) => ({ label: item.tieude, value: item.id }));
    const { phongban } = useSelector((state) => state.phongban);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiGetSuatan();
                // console.log('responsecccccccccccccc', response?.data?.sonvanbuachinh)
                // console.log('response', response)
                const countSapb = (dataname) => {
                    const datam = response?.data?.sonvanbuachinh?.filter((item) => item?.Phongban?.phongban === dataname);
                    return datam.length;
                };
                const dataSa = phongban.map((item) => ({
                    PhongbanId: item.phongban,
                    Soluong: countSapb(item.phongban),
                    LoaisuatanId: { label: 'Chọn loại suất ăn' },
                    is_default: 0,
                }));
                // console.log('dataSa', dataSa)
                setFormValues(dataSa);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData().catch(console.error);
        let dataMa = dataPhieusuatan?.map((item) => ({ matusinh: item.maphieu }));
        show &&
            setPayload({
                maphieu: matusinhResult(dataMa && dataMa.length > 0 ? dataMa : [{}], 'MSA'),
                ngaytao: format(new Date(), 'yyyy-MM-dd'),
                // noidung: '',
                // TrangthaiId: '',
            });
    }, [show, phongban]);

    const [formValues, setFormValues] = useState([
        // {
        //     PhongbanId: 'Phòng maketing',
        //     Soluong: 30,
        //     LoaisuatanId: { label: 'Suất ăn tăng ca', value: 14 },
        //     is_default: 0,
        // },
        // { PhongbanId: 'Phòng abc', Soluong: 40, LoaisuatanId: { label: 'Suất ăn chính', value: 15 }, is_default: 0 },
    ]);
    const [formEdit, setFormEdit] = useState({ id: 0, trangthai: false });
    // console.log('formValues', formValues);
    const handleChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
    };
    const handleSelectChange = (index, selected) => {
        const updatedFormValues = [...formValues];
        updatedFormValues[index] = {
            ...updatedFormValues[index],
            LoaisuatanId: selected,
        };
        setFormValues(updatedFormValues);
    };
    const addFormFields = () => {
        // if(formEdit.trangthai !== false){
        //   console.log('Bạn chưu cập nhật')
        // }else{
        let mang = [...formValues, { PhongbanId: '', Soluong: '', LoaisuatanId: '', is_default: 1 }];
        setFormValues(mang);
        setFormEdit((prev) => ({ ...prev, ['id']: mang?.length }));
        setFormEdit((prev) => ({ ...prev, ['trangthai']: false }));
        // }
    };

    const removeFormFields = (item, i) => {
        if (item.is_default !== 0) {
            let newFormValues = [...formValues];
            newFormValues.splice(i, 1);
            setFormValues(newFormValues);
        } else {
            toast.warning(`Trường mặc định bạn không được xóa`, {
                // position: placement,
                autoClose: true,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: false,
                closeButton: <CloseButton />,
            });
        }
    };
    const handleEdit = (i) => {
        // if(formEdit.trangthai !== false){
        //   console.log('Bạn chưu cập nhật')
        // }else{
        setFormEdit((prev) => ({ ...prev, ['id']: Number(i + 1) }));
        setFormEdit((prev) => ({ ...prev, ['trangthai']: true }));
        // }
    };
    const kiemTraMang = (arr) => {
        for (let i = 0; i < arr.length; i++) {
            const obj = arr[i];
            if (obj.PhongbanId === '' || obj.Soluong === '' || obj.LoaisuatanId === '') {
                return false;
            }
        }
        return true;
    };

    const handleSubmit = async () => {
        const invalids = validate(payload);
        // if (invalids === 0) {
        const ketQua = kiemTraMang(formValues);
        if (ketQua === true) {
            const datapayload = {
                data: [
                    {
                        maphieu: payload.maphieu,
                        ngaytao: payload.ngaytao,
                        noidung: formValues,
                        TrangthaiId: payload?.TrangthaiId?.value || 0,
                    },
                ],
            };
            const response = await apiNewPhieusuatan(datapayload);
            if (response?.data?.success === 200) {
                toast.success('Thêm mới thành công!');
                setShow(false);
            } else if (response?.data?.err === 401) {
                toast.error(response?.data?.mes);
            } else {
                toast.error('Thêm mới thất bại!');
            }
        } else {
            toast.error('Có vẻ như một số trường đang bị trống vui lòng hoàn thiện nội dung!');
        }
        // } else {
        //   toast.error('Có lỗi trong dữ liệu nhập. Vui lòng kiểm tra lại!');
        // }
    };

    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (item[1] === '') {
                setInvalidFields((prev) => [
                    ...prev,
                    {
                        name: item[0],
                        message: 'Bạn không được bỏ trống trường này.',
                    },
                ]);
                invalids++;
            }
        });

        return invalids;
    };

    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">Thêm mới phiếu suất ăn</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-4 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Mã phiếu'}
                            placeholder={'Mã phiếu'}
                            value={payload.maphieu}
                            setValue={setPayload}
                            keyPayload={'maphieu'}
                            type="text"
                            disabled={true}
                            validate
                        />
                    </div>
                    <div className="form-group col-md-4 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ngày tạo'}
                            value={payload.ngaytao}
                            setValue={setPayload}
                            keyPayload={'ngaytao'}
                            type="date"
                            disabled={true}
                            validate
                        />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>Trạng thái</span>
                        </label>
                        <Select
                            value={payload.TrangthaiId}
                            onChange={(selected) => setPayload((prev) => ({ ...prev, ['TrangthaiId']: selected }))}
                            options={dataSelectStatus}
                            placeholder={'Chọn trạng thái'}
                            name="TrangthaiId"
                        />
                    </div>
                </div>

                <div className="row px-2">
                    <div className="col-12 mt-3 mb-3">
                        <Button
                            size="md"
                            color="info"
                            outline
                            className="btn-icon btn-white btn-dim"
                            style={{ paddingRight: '10px' }}
                            onClick={() => addFormFields()}
                        >
                            <Icon name="plus"></Icon> Thêm suất ăn phát sinh
                        </Button>
                    </div>

                    <DataTable className="card-stretch">
                        <DataTableBody>
                            <DataTableHead>
                                <DataTableRow>
                                    <span className="sub-text">Stt</span>
                                </DataTableRow>
                                <DataTableRow size="mb">
                                    <span className="sub-text">Suất ăn cho</span>
                                </DataTableRow>
                                <DataTableRow size="mb">
                                    <span className="sub-text">Số lượng</span>
                                </DataTableRow>
                                <DataTableRow size="mb">
                                    <span className="sub-text">Loại suất ăn</span>
                                </DataTableRow>
                                <DataTableRow size="mb"></DataTableRow>
                            </DataTableHead>
                            {formValues?.length > 0
                                ? formValues?.map((item, it) => {
                                      return (
                                          <DataTableItem key={it}>
                                              <DataTableRow>
                                                  <span className="sub-text">{it + 1}</span>
                                              </DataTableRow>
                                              <DataTableRow size="mb">
                                                  {item.is_default !== 0 ? (
                                                      Number(it + 1) === Number(formEdit.id) ? (
                                                          <input
                                                              type="text"
                                                              name="PhongbanId"
                                                              className="form-control"
                                                              value={item.PhongbanId || ''}
                                                              onChange={(e) => handleChange(it, e)}
                                                          />
                                                      ) : (
                                                          <span className="sub-text">{item?.PhongbanId}</span>
                                                      )
                                                  ) : (
                                                      <span className="sub-text">{item?.PhongbanId}</span>
                                                  )}
                                              </DataTableRow>
                                              <DataTableRow size="mb">
                                                  {Number(it + 1) === Number(formEdit.id) ? (
                                                      <input
                                                          type="text"
                                                          name="Soluong"
                                                          className="form-control"
                                                          value={item.Soluong || ''}
                                                          onChange={(e) => handleChange(it, e)}
                                                      />
                                                  ) : (
                                                      <span className="sub-text">{item?.Soluong}</span>
                                                  )}
                                              </DataTableRow>
                                              <DataTableRow size="mb">
                                                  {Number(it + 1) === Number(formEdit.id) ? (
                                                      <Select
                                                          value={item.LoaisuatanId}
                                                          onChange={(selected) => handleSelectChange(it, selected)}
                                                          options={Loaisuatan}
                                                          placeholder={'Chọn loại suất ăn'}
                                                          name="LoaisuatanId"
                                                      />
                                                  ) : (
                                                      <span className="sub-text">{item?.LoaisuatanId?.label}</span>
                                                  )}
                                              </DataTableRow>
                                              <DataTableRow className="nk-tb-col-tools">
                                                  <ul className="nk-tb-actions gx-1">
                                                      <button
                                                          onClick={() => handleEdit(it)}
                                                          className={'btn btn-primary ms-0 badge text-white p-1'}
                                                      >
                                                          <em className="icon ni ni-edit"></em>
                                                      </button>
                                                      {/* {item?.is_default !== 0 && ( */}
                                                      <>
                                                          <button
                                                              onClick={() => removeFormFields(item, it)}
                                                              className={'btn btn-danger ms-0 badge text-white p-1'}
                                                          >
                                                              <em className="icon ni ni-trash-alt"></em>
                                                          </button>
                                                      </>
                                                      {/* )} */}
                                                  </ul>
                                              </DataTableRow>
                                              {/* <DataTableRow className="nk-tb-col-tools">
                                                  <ul className="nk-tb-actions gx-1">
                                                      <li>
                                                          <UncontrolledDropdown>
                                                              <DropdownToggle
                                                                  tag="a"
                                                                  className="dropdown-toggle btn btn-icon btn-trigger"
                                                              >
                                                                  <Icon name="more-h"></Icon>
                                                              </DropdownToggle>
                                                              <DropdownMenu end>
                                                                  <ul className="link-list-opt no-bdr">
                                                                      <li onClick={() => handleEdit(it)}>
                                                                          <DropdownItem
                                                                              tag="a"
                                                                              href="#edit"
                                                                              onClick={(ev) => {
                                                                                  ev.preventDefault();
                                                                              }}
                                                                          >
                                                                              <Icon name="edit"></Icon>
                                                                              <span>Sửa</span>
                                                                          </DropdownItem>
                                                                      </li>
                                                                      <React.Fragment>
                                                                          {item?.is_default !== 0 && (
                                                                              <>
                                                                                  <li className="divider"></li>

                                                                                  <li
                                                                                      onClick={() =>
                                                                                          removeFormFields(it)
                                                                                      }
                                                                                  >
                                                                                      <DropdownItem
                                                                                          tag="a"
                                                                                          href="#suspend"
                                                                                          onClick={(ev) => {
                                                                                              ev.preventDefault();
                                                                                          }}
                                                                                      >
                                                                                          <Icon name="na"></Icon>
                                                                                          <span>Xóa</span>
                                                                                      </DropdownItem>
                                                                                  </li>
                                                                              </>
                                                                          )}
                                                                      </React.Fragment>
                                                                  </ul>
                                                              </DropdownMenu>
                                                          </UncontrolledDropdown>
                                                      </li>
                                                  </ul>
                                              </DataTableRow> */}
                                          </DataTableItem>
                                      );
                                  })
                                : null}
                        </DataTableBody>
                    </DataTable>
                </div>

                <div className="col-12 mt-5">
                    <Button color="primary" onClick={handleSubmit}>
                        Lưu lại
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalSuatan;
