import React, { useEffect, useState } from 'react';
import Head from '~/layout/head/Head';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import {
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    Modal,
    ModalBody,
    DropdownItem,
    Form,
    Spinner,
    Input,
} from 'reactstrap';
import {
    Block,
    BlockBetween,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Icon,
    Row,
    Col,
    UserAvatar,
    PaginationComponent,
    Button,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableRow,
    DataTableItem,
    TooltipComponent,
    RSelect,
} from '~/components/Component';
import { InputForm } from '~/components/Form';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import config from '~/config';
import Select from 'react-select';
import {
    apiGetFunctionUserV2,
    apiPostFunctionUserV2,
    apiPutFunctionUserV2,
    apiDeleteFunctionUserV2,
    apiGetFunctionV2,
    apiGetUserAll,
    apiGetModuleUserV2,
    apiPostGroupFunctionUserV2,
    apiGetGroupFunctionUserV2,
    apiPutGroupFunctionUserV2,
    apiDeleteGroupFunctionUserV2,
    apiPostFunction
} from '~/services';
const FunctionUser = () => {
    const { user } = useSelector((state) => state.app);
    const [userInfo, setUserInfo] = useState([]);
    const [modal, setModal] = useState(false);
    const [data, setData] = useState([]);
    const [functionv2, setlistFunction] = useState([]);
    // console.log('functionv2', functionv2);
    const navigate = useNavigate();
    useEffect(() => {
        setUserInfo(user);
        if (userInfo.length !== 0 && +userInfo?.phanquyen !== 0) {
            navigate(config.login);
        }
        if (user) {
            const fetchData = async () => {
                try {
                    const moduleusers = await apiGetModuleUserV2({ UserId: user.id });
                    const dataSelectModuleUser = await Promise.all(
                        moduleusers?.data?.data?.rows?.map(async (item) => {
                            const functionv2 = await apiGetFunctionV2({ QuanlymoduleId: item?.QuanlymoduleId });
                            return functionv2.data?.data?.rows;
                        }),
                    );
                    const uniqueArray = Array.from(new Set(dataSelectModuleUser.flat()));
                    setlistFunction(uniqueArray);

                    const response = await apiGetGroupFunctionUserV2();
                    setData(response.data.data.rows);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };
            fetchData().catch(console.error);
        }
    }, [user]);
    const [sm, updateSm] = useState(false);
    const [tablesm, updateTableSm] = useState(false);
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [sort, setSortState] = useState('');
    const onFilterChange = (e) => {
        setSearchText(e.target.value);
    };
    // function to toggle the search option
    const toggle = () => setonSearch(!onSearch);
    // function to change the check property of an item
    const selectorCheck = (e) => {
        let newData;
        newData = data.map((item) => {
            item.checked = e.currentTarget.checked;
            return item;
        });
        setData([...newData]);
    };
    // function to change the selected property of an item
    const onSelectChange = (e, id) => {
        let newData = data;
        let index = newData.findIndex((item) => item.id === id);
        newData[index].checked = e.currentTarget.checked;
        setData([...newData]);
    };

    // Changing state value when searching name
    useEffect(() => {
        if (onSearchText !== '') {
            const filteredObject = data.filter((item) => {
                return (
                    item.ho.toLowerCase().includes(onSearchText.toLowerCase()) ||
                    item.ten.toLowerCase().includes(onSearchText.toLowerCase())
                );
            });
            setData([...filteredObject]);
        } else {
            setData([...data]);
        }
    }, [onSearchText, setData]);
    // console.log('data', data)
    // Get current list, pagination
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
    // Change Page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const [invalidFields, setInvalidFields] = useState([]);
    const [titleForm, setTitleForm] = useState(false);
    const [payload, setPayload] = useState({
        tennhomquyen: '',
        mota: '',
    });
    const [payload2, setPayload2] = useState({});
    //sử lý chọn tất cả
    const [payloadAll, setPayloadAll] = useState(false);

    useEffect(() => {
      functionv2.forEach(async (item) => {
        const payloadItem = {
          [`them_${item.id}`]: payloadAll,
          [`sua_${item.id}`]: payloadAll,
          [`xoa_${item.id}`]: payloadAll,
          [`xem_${item.id}`]: payloadAll,
        };
        setPayload2((prevPayload) => ({
          ...prevPayload,
          ...payloadItem,
        }));
      });
    }, [payloadAll]);

    const [dataUpdateFunUser, setDataUpdateFunUser] = useState([]);
    // console.log('payload', payload);
    // console.log('payload2', payload2);
    // console.log('dataUpdateFunUser', dataUpdateFunUser);
    const handleEditModuleUser = async (item) => {
        setPayload2({});
        setModal(true);
        setTitleForm(true);
        try {
            const response = await apiGetFunctionUserV2({ GroupId: item?.id });
            setDataUpdateFunUser(response.data.data.rows);
            // console.log('aaaa',response.data.data.rows)
            response?.data?.data?.rows.map(async (item, i) => {
                setPayload2((prevPayload) => ({
                    ...prevPayload,
                    [`them_${item.QuanlyfunctionId}`]: item?.them === 1 ? true : false,
                    [`sua_${item.QuanlyfunctionId}`]: item?.sua === 1 ? true : false,
                    [`xoa_${item.QuanlyfunctionId}`]: item?.xoa === 1 ? true : false,
                    [`xem_${item.QuanlyfunctionId}`]: item?.xem === 1 ? true : false,
                }));
            });
            // setData(response.data.data.rows);
            setPayload({
                id: item?.id,
                tennhomquyen: item?.tennhomquyen,
                mota: item?.mota,
            });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleDeleteModuleUser = async (item) => {
        Swal.fire({
            title: 'Bạn có chắc không?',
            text: 'Bạn sẽ không thể lấy lại khi xóa!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Không, bỏ qua!',
            confirmButtonText: 'Vâng, xóa nó đi!',
        }).then((result) => {
            if (result.isConfirmed) {
                const fetchData = async () => {
                    try {
                        const response = await apiDeleteGroupFunctionUserV2(item);
                        if (response.data.success === 200) {
                            toast.success('Xóa thành công');
                            const responseDeleteFunction = await apiDeleteFunctionUserV2({ id: item?.id });
                            const response = await apiGetGroupFunctionUserV2();
                            setData(response.data.data.rows);
                        } else {
                            toast.error('Xóa thất bại!');
                        }
                    } catch (error) {
                        console.error('Error fetching data:', error);
                    }
                };
                fetchData().catch(console.error);
            }
        });
    };
    // console.log('functionv2.length', functionv2)
    // console.log('dataUpdateFunUser', dataUpdateFunUser)

    const handleSubmit = async () => {
        let invalids = validate(payload);
        if (invalids === 0) {
            const datapayload = {
                tennhomquyen: payload?.tennhomquyen,
                mota: payload?.mota,
            };
            try {
                if (titleForm === false) {
                    // console.log('datapayload1', datapayload)
                    const responsePostGroup = await apiPostGroupFunctionUserV2(datapayload);
                    if (responsePostGroup.data.success === 200) {
                        toast.success('Thêm thành công');
                        let datafunctionv1 = {};
                        let data = [];
                        let idFunctionUser = responsePostGroup?.data?.data?.id;
                        functionv2.map(async (item, i) => {
                            data.push({
                                QuanlyfunctionId: item.id,
                                GroupId: idFunctionUser,
                                them: payload2[`them_${item.id}`] ? (payload2[`them_${item.id}`] === true ? 1 : 0) : 0,
                                sua: payload2[`sua_${item.id}`] ? (payload2[`sua_${item.id}`] === true ? 1 : 0) : 0,
                                xoa: payload2[`xoa_${item.id}`] ? (payload2[`xoa_${item.id}`] === true ? 1 : 0) : 0,
                                xem: payload2[`xem_${item.id}`] ? (payload2[`xem_${item.id}`] === true ? 1 : 0) : 0,
                            });
                        });
                        datafunctionv1.data = data;
                        const postFunctionUse = await apiPostFunctionUserV2(datafunctionv1);
                        // console.log('datafunctionv2', datafunctionv2)
                        setModal(false);
                        const responseGetGroupV1 = await apiGetGroupFunctionUserV2();
                        setData(responseGetGroupV1.data.data.rows);
                    } else {
                        toast.error('Thêm thất bại!');
                    }
                } else {
                    datapayload.id = payload?.id;
                    // console.log('datapayload', datapayload)
                    const responsePutGroup = await apiPutGroupFunctionUserV2(datapayload);
                    if (responsePutGroup.data.success === 200) {
                        let datafunctionv2 = {};
                        let data = [];
                        if (dataUpdateFunUser.length > 0) {
                            //đoạn này nếu người dùng có thêm module mới thì xóa các quyền cũ đi để thêm đầy đủ các quyền
                            if (functionv2.length > dataUpdateFunUser.length) {
                                const responseDeleteFunction4 = await apiDeleteFunctionUserV2({ id: payload?.id });
                                if (responseDeleteFunction4?.data?.success === 200) {
                                    let datafunctionv4 = {};
                                    let data = [];
                                    functionv2.map(async (item, i) => {
                                        data.push({
                                            QuanlyfunctionId: item.id,
                                            GroupId: payload?.id,
                                            them: payload2[`them_${item.id}`]
                                                ? payload2[`them_${item.id}`] === true
                                                    ? 1
                                                    : 0
                                                : 0,
                                            sua: payload2[`sua_${item.id}`]
                                                ? payload2[`sua_${item.id}`] === true
                                                    ? 1
                                                    : 0
                                                : 0,
                                            xoa: payload2[`xoa_${item.id}`]
                                                ? payload2[`xoa_${item.id}`] === true
                                                    ? 1
                                                    : 0
                                                : 0,
                                            xem: payload2[`xem_${item.id}`]
                                                ? payload2[`xem_${item.id}`] === true
                                                    ? 1
                                                    : 0
                                                : 0,
                                        });
                                    });
                                    datafunctionv4.data = data;
                                    const postFunctionUse4 = await apiPostFunctionUserV2(datafunctionv4);
                                }
                            } else {
                                // nếu người dùng k có module mới thì sẽ sửa như bình thường
                                dataUpdateFunUser.map(async (item, i) => {
                                    data.push({
                                        id: item.id,
                                        them: payload2[`them_${item.QuanlyfunctionId}`]
                                            ? payload2[`them_${item.QuanlyfunctionId}`] === true
                                                ? 1
                                                : 0
                                            : 0,
                                        sua: payload2[`sua_${item.QuanlyfunctionId}`]
                                            ? payload2[`sua_${item.QuanlyfunctionId}`] === true
                                                ? 1
                                                : 0
                                            : 0,
                                        xoa: payload2[`xoa_${item.QuanlyfunctionId}`]
                                            ? payload2[`xoa_${item.QuanlyfunctionId}`] === true
                                                ? 1
                                                : 0
                                            : 0,
                                        xem: payload2[`xem_${item.QuanlyfunctionId}`]
                                            ? payload2[`xem_${item.QuanlyfunctionId}`] === true
                                                ? 1
                                                : 0
                                            : 0,
                                    });
                                });
                                datafunctionv2.data = data;
                                // console.log('datafunctionv2', datafunctionv2)
                                const responsepost = await apiPutFunctionUserV2(datafunctionv2);
                            }
                        } else {
                            let datafunctionv3 = {};
                            let data = [];
                            functionv2.map(async (item, i) => {
                                data.push({
                                    QuanlyfunctionId: item.id,
                                    GroupId: payload?.id,
                                    them: payload2[`them_${item.id}`]
                                        ? payload2[`them_${item.id}`] === true
                                            ? 1
                                            : 0
                                        : 0,
                                    sua: payload2[`sua_${item.id}`] ? (payload2[`sua_${item.id}`] === true ? 1 : 0) : 0,
                                    xoa: payload2[`xoa_${item.id}`] ? (payload2[`xoa_${item.id}`] === true ? 1 : 0) : 0,
                                    xem: payload2[`xem_${item.id}`] ? (payload2[`xem_${item.id}`] === true ? 1 : 0) : 0,
                                });
                            });
                            datafunctionv3.data = data;
                            // console.log('datafunctionv3', datafunctionv3)
                            const postFunctionUse3 = await apiPostFunctionUserV2(datafunctionv3);
                        }
                        toast.success('Sửa thành công');
                        setModal(false);
                        const responseGetGroup = await apiGetGroupFunctionUserV2();
                        setData(responseGetGroup.data.data.rows);
                    } else {
                        toast.error('Sửa thất bại!');
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    };
    const validate = (payloadLogin) => {
        let invalids = 0;
        let fields = Object.entries(payloadLogin);
        fields.forEach((item) => {
            if (item[1] === '') {
                setInvalidFields((prev) => [
                    ...prev,
                    {
                        name: item[0],
                        message: 'Bạn không được bỏ trống trường này.',
                    },
                ]);
                invalids++;
            }
        });
        return invalids;
    };
    const Themfunction = async () => {
        try {
            const moduleusers = await apiPostFunction();
            console.log('moduleusers', moduleusers)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    return (
        <React.Fragment>
            <Head title="User List - Profile"></Head>
            <BlockHead size="lg">
                <BlockBetween>
                    <BlockHeadContent>
                        <BlockTitle tag="h4">Quản lý vai trò</BlockTitle>
                    </BlockHeadContent>
                    <BlockHeadContent>
                        <div className="toggle-wrap nk-block-tools-toggle">
                            <div className="toggle-expand-content" style={{ display: sm ? 'block' : 'none' }}>
                                <ul className="nk-block-tools g-3">
                                    <li className="nk-block-tools-opt">
                                        <Button
                                            color="primary"
                                            className="btn-icon px-1"
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                Themfunction();
                                            }}
                                        >
                                            <Icon name="plus"></Icon> Thêm mới function
                                        </Button>
                                    </li>
                                    <li className="nk-block-tools-opt">
                                        <Button
                                            color="primary"
                                            className="btn-icon px-1"
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                setModal(true);
                                                setTitleForm(false);
                                                setPayload({
                                                    mota: '',
                                                    tennhomquyen: '',
                                                });
                                                setPayload2({});
                                            }}
                                        >
                                            <Icon name="plus"></Icon> Thêm mới vai trò
                                        </Button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </BlockHeadContent>
                </BlockBetween>
            </BlockHead>

            <Block>
                <DataTable className="card-stretch">
                    <div className="card-inner position-relative card-tools-toggle">
                        <div className="card-title-group">
                            <div className="card-title">
                                <h5 className="title">Danh sách vai trò</h5>
                            </div>
                            <div className="card-tools me-n1">
                                <ul className="btn-toolbar gx-1">
                                    <li>
                                        <a
                                            href="#search"
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                toggle();
                                            }}
                                            className="btn btn-icon search-toggle toggle-search"
                                        >
                                            <Icon name="search"></Icon>
                                        </a>
                                    </li>
                                    <li className="btn-toolbar-sep"></li>
                                    <li>
                                        <div className="toggle-wrap">
                                            <Button
                                                className={`btn-icon btn-trigger toggle ${tablesm ? 'active' : ''}`}
                                                onClick={() => updateTableSm(true)}
                                            >
                                                <Icon name="menu-right"></Icon>
                                            </Button>

                                            <div className={`toggle-content ${tablesm ? 'content-active' : ''}`}>
                                                <ul className="btn-toolbar gx-1">
                                                    <li className="toggle-close">
                                                        <Button
                                                            className="btn-icon btn-trigger toggle"
                                                            onClick={() => updateTableSm(false)}
                                                        >
                                                            <Icon name="arrow-left"></Icon>
                                                        </Button>
                                                    </li>
                                                    <li>
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle
                                                                tag="a"
                                                                className="btn btn-trigger btn-icon dropdown-toggle"
                                                            >
                                                                <div className="dot dot-primary"></div>
                                                                <Icon name="filter-alt"></Icon>
                                                            </DropdownToggle>
                                                            <DropdownMenu
                                                                end
                                                                className="filter-wg dropdown-menu-xl"
                                                                style={{ overflow: 'visible' }}
                                                            >
                                                                <div className="dropdown-head">
                                                                    <span className="sub-title dropdown-title">
                                                                        Bộ lọc
                                                                    </span>

                                                                    <div className="dropdown">
                                                                        <a
                                                                            href="#more"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();
                                                                            }}
                                                                            className="btn btn-sm btn-icon"
                                                                        >
                                                                            <Icon name="more-h"></Icon>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div className="dropdown-body dropdown-body-rg">
                                                                    <Row className="gx-6 gy-3">
                                                                        <Col size="6">
                                                                            <div className="custom-control custom-control-sm custom-checkbox">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="custom-control-input"
                                                                                    id="hasBalance"
                                                                                />
                                                                                <label
                                                                                    className="custom-control-label"
                                                                                    htmlFor="hasBalance"
                                                                                >
                                                                                    {' '}
                                                                                    Have Balance
                                                                                </label>
                                                                            </div>
                                                                        </Col>
                                                                        <Col size="6">
                                                                            <div className="custom-control custom-control-sm custom-checkbox">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="custom-control-input"
                                                                                    id="hasKYC"
                                                                                />
                                                                                <label
                                                                                    className="custom-control-label"
                                                                                    htmlFor="hasKYC"
                                                                                >
                                                                                    {' '}
                                                                                    KYC Verified
                                                                                </label>
                                                                            </div>
                                                                        </Col>
                                                                        <Col size="6">
                                                                            <div className="form-group">
                                                                                <label className="overline-title overline-title-alt">
                                                                                    Role
                                                                                </label>
                                                                                <RSelect placeholder="Any Role" />
                                                                            </div>
                                                                        </Col>
                                                                        <Col size="6">
                                                                            <div className="form-group">
                                                                                <label className="overline-title overline-title-alt">
                                                                                    Status
                                                                                </label>
                                                                                <RSelect placeholder="Any Status" />
                                                                            </div>
                                                                        </Col>
                                                                        <Col size="12">
                                                                            <div className="form-group">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-secondary"
                                                                                >
                                                                                    Filter
                                                                                </button>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                                <div className="dropdown-foot between">
                                                                    <a
                                                                        href="#reset"
                                                                        onClick={(ev) => {
                                                                            ev.preventDefault();
                                                                        }}
                                                                        className="clickable"
                                                                    >
                                                                        Cập nhật bộ lọc 
                                                                    </a>
                                                                    <a
                                                                        href="#save"
                                                                        onClick={(ev) => {
                                                                            ev.preventDefault();
                                                                        }}
                                                                    >
                                                                        Lưu bộ lọc 
                                                                    </a>
                                                                </div>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </li>
                                                    <li>
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle
                                                                color="tranparent"
                                                                className="btn btn-trigger btn-icon dropdown-toggle"
                                                            >
                                                                <Icon name="setting"></Icon>
                                                            </DropdownToggle>
                                                            <DropdownMenu end className="dropdown-menu-xs">
                                                                <ul className="link-check">
                                                                    <li>
                                                                        <span>Show</span>
                                                                    </li>
                                                                    <li className={itemPerPage === 10 ? 'active' : ''}>
                                                                        <DropdownItem
                                                                            tag="a"
                                                                            href="#dropdownitem"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();
                                                                                setItemPerPage(10);
                                                                            }}
                                                                        >
                                                                            10
                                                                        </DropdownItem>
                                                                    </li>
                                                                    <li className={itemPerPage === 15 ? 'active' : ''}>
                                                                        <DropdownItem
                                                                            tag="a"
                                                                            href="#dropdownitem"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();
                                                                                setItemPerPage(15);
                                                                            }}
                                                                        >
                                                                            15
                                                                        </DropdownItem>
                                                                    </li>
                                                                </ul>
                                                                <ul className="link-check">
                                                                    <li>
                                                                        <span>Order</span>
                                                                    </li>

                                                                    <li className={sort === 'dsc' ? 'active' : ''}>
                                                                        <DropdownItem
                                                                            tag="a"
                                                                            href="#dropdownitem"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();

                                                                                setSortState('dsc');
                                                                                //   sortFunc("dsc");
                                                                            }}
                                                                        >
                                                                            DESC
                                                                        </DropdownItem>
                                                                    </li>
                                                                    <li className={sort === 'asc' ? 'active' : ''}>
                                                                        <DropdownItem
                                                                            tag="a"
                                                                            href="#dropdownitem"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();

                                                                                setSortState('asc');

                                                                                //   sortFunc("asc");
                                                                            }}
                                                                        >
                                                                            ASC
                                                                        </DropdownItem>
                                                                    </li>
                                                                </ul>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className={`card-search search-wrap ${!onSearch && 'active'}`}>
                            <div className="card-body">
                                <div className="search-content">
                                    <Button
                                        className="search-back btn-icon toggle-search active"
                                        onClick={() => {
                                            setSearchText('');

                                            toggle();
                                        }}
                                    >
                                        <Icon name="arrow-left"></Icon>
                                    </Button>
                                    <input
                                        type="text"
                                        className="border-transparent form-focus-none form-control"
                                        placeholder="Tìm kiến nhân viên"
                                        value={onSearchText}
                                        onChange={(e) => onFilterChange(e)}
                                    />
                                    <Button className="search-submit btn-icon">
                                        <Icon name="search"></Icon>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DataTableBody>
                        <DataTableHead>
                            <DataTableRow className="nk-tb-col-check">
                                <div className="custom-control custom-control-sm custom-checkbox notext">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        onChange={(e) => selectorCheck(e)}
                                        id="uid"
                                    />
                                    <label className="custom-control-label" htmlFor="uid"></label>
                                </div>
                            </DataTableRow>
                            <DataTableRow>
                                <span className="sub-text">vai trò</span>
                            </DataTableRow>
                            <DataTableRow size="mb">
                                <span className="sub-text">Mô tả</span>
                            </DataTableRow>
                            <DataTableRow className="nk-tb-col-tools text-end">
                                <UncontrolledDropdown>
                                    <DropdownToggle
                                        color="tranparent"
                                        className="btn btn-xs btn-outline-light btn-icon dropdown-toggle"
                                    >
                                        <Icon name="plus"></Icon>
                                    </DropdownToggle>
                                </UncontrolledDropdown>
                            </DataTableRow>
                        </DataTableHead>
                        {/*Head*/}
                        {currentItems.length > 0
                            ? currentItems.map((item) => {
                                  return (
                                      <DataTableItem key={item.id}>
                                          <DataTableRow className="nk-tb-col-check">
                                              <div className="custom-control custom-control-sm custom-checkbox notext">
                                                  <input
                                                      type="checkbox"
                                                      className="custom-control-input"
                                                      defaultChecked={item.checked}
                                                      id={item.id + 'uid1'}
                                                      key={Math.random()}
                                                      onChange={(e) => onSelectChange(e, item.id)}
                                                  />
                                                  <label
                                                      className="custom-control-label"
                                                      htmlFor={item.id + 'uid1'}
                                                  ></label>
                                              </div>
                                          </DataTableRow>
                                          <DataTableRow>
                                              <div className="user-card">
                                                  <div className="user-info">
                                                      <span className="tb-lead">{item?.tennhomquyen}</span>
                                                  </div>
                                              </div>
                                              {/* </Link> */}
                                          </DataTableRow>
                                          <DataTableRow size="mb">
                                              <span className="tb-amount">{item?.mota}</span>
                                          </DataTableRow>

                                          <DataTableRow className="nk-tb-col-tools">
                                              <ul className="nk-tb-actions gx-1">
                                                  <li
                                                      className="nk-tb-action-hidden"
                                                      onClick={() => handleEditModuleUser(item)}
                                                  >
                                                      <TooltipComponent
                                                          tag="a"
                                                          containerClassName="btn btn-trigger btn-icon"
                                                          id={'edit' + item.id}
                                                          icon="edit-alt-fill"
                                                          direction="top"
                                                          text="Sửa"
                                                      />
                                                  </li>
                                                  <React.Fragment>
                                                      <li
                                                          className="nk-tb-action-hidden"
                                                          onClick={() => handleDeleteModuleUser(item)}
                                                      >
                                                          <TooltipComponent
                                                              tag="a"
                                                              containerClassName="btn btn-trigger btn-icon"
                                                              id={'suspend' + item.id}
                                                              icon="user-cross-fill"
                                                              direction="top"
                                                              text="Xóa"
                                                          />
                                                      </li>
                                                  </React.Fragment>
                                                  <li>
                                                      <UncontrolledDropdown>
                                                          <DropdownToggle
                                                              tag="a"
                                                              className="dropdown-toggle btn btn-icon btn-trigger"
                                                          >
                                                              <Icon name="more-h"></Icon>
                                                          </DropdownToggle>
                                                          <DropdownMenu end>
                                                              <ul className="link-list-opt no-bdr">
                                                                  <li onClick={() => handleEditModuleUser(item)}>
                                                                      <DropdownItem tag="a" href="#edit">
                                                                          <Icon name="edit"></Icon>
                                                                          <span>Sửa</span>
                                                                      </DropdownItem>
                                                                  </li>
                                                                  <React.Fragment>
                                                                      <li className="divider"></li>
                                                                      <li onClick={() => handleDeleteModuleUser(item)}>
                                                                          <DropdownItem
                                                                              tag="a"
                                                                              href="#suspend"
                                                                              onClick={(ev) => {
                                                                                  ev.preventDefault();
                                                                              }}
                                                                          >
                                                                              <Icon name="na"></Icon>
                                                                              <span>Xóa</span>
                                                                          </DropdownItem>
                                                                      </li>
                                                                  </React.Fragment>
                                                              </ul>
                                                          </DropdownMenu>
                                                      </UncontrolledDropdown>
                                                  </li>
                                              </ul>
                                          </DataTableRow>
                                      </DataTableItem>
                                  );
                              })
                            : null}
                    </DataTableBody>
                    <div className="card-inner">
                        {currentItems.length > 0 ? (
                            <PaginationComponent
                                itemPerPage={itemPerPage}
                                totalItems={data.length}
                                paginate={paginate}
                                currentPage={currentPage}
                            />
                        ) : (
                            <div className="text-center">
                                <div>
                                    {' '}
                                    <Spinner color="primary" />
                                </div>
                                <span className="text-silent">Chưa có dữ liệu!</span>
                            </div>
                        )}
                    </div>
                </DataTable>
            </Block>

            <Modal isOpen={modal} className="modal-dialog-centered" size="lg" toggle={() => setModal(false)}>
                <ModalBody>
                    <div className="p-2">
                        <h5 className="title mb-3">{titleForm === false ? 'Thêm vai trò' : 'Sửa vai trò'}</h5>
                        <div className="row">
                            <div className="form-group col-md-12">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Tên vai trò'}
                                    value={payload.tennhomquyen}
                                    setValue={setPayload}
                                    keyPayload={'tennhomquyen'}
                                    type="text"
                                    validate
                                />
                            </div>

                            <div className="form-group col-md-12 mb-2">
                                <label htmlFor="inputZip" className="mb-2 fw-bold">
                                    <span>
                                        Mô tả vai trò <b className="text-danger">(*)</b>
                                    </span>
                                </label>
                                <Input
                                    value={payload.mota}
                                    onChange={(e) => setPayload((prev) => ({ ...prev, ['mota']: e.target.value }))}
                                    rows={'2'}
                                    name="mota"
                                    type="textarea"
                                />
                                {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'mota') && (
                                    <small className="text-danger">
                                        {invalidFields.find((i) => i.name === 'mota')?.message}
                                    </small>
                                )}
                            </div>
                            <div className="preview-block mt-2 text-end">
                                <div className="custom-control custom-switch">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="custom2"
                                        onChange={(e) => setPayloadAll(e.target.checked)}
                                    />
                                    <label className="custom-control-label" htmlFor="custom2">
                                        Chọn tất cả
                                    </label>
                                </div>
                            </div>
                        </div>

                        {functionv2.length > 0
                            ? functionv2.map((item, index) => {
                                  return (
                                      <React.Fragment key={index}>
                                          <div className="row mt-5 mb-5">
                                              <label htmlFor="inputZip" className="mb-2 fw-bold">
                                                  <span>{item.mota}</span>
                                              </label>
                                              <div className="form-group col-md-3">
                                                  <div className="custom-control custom-checkbox">
                                                      <input
                                                          type="checkbox"
                                                          className="custom-control-input"
                                                          id={`them-${item.id}`}
                                                          name={`them-${item.id}`}
                                                          onChange={(e) =>
                                                              setPayload2((prev) => ({
                                                                  ...prev,
                                                                  [`them_${item.id}`]: e.target.checked,
                                                              }))
                                                          }
                                                          checked={payload2[`them_${item.id}`]}
                                                      />
                                                      <label
                                                          className="custom-control-label"
                                                          htmlFor={`them-${item.id}`}
                                                      >
                                                          Thêm{' '}
                                                      </label>
                                                  </div>
                                              </div>
                                              <div className="form-group col-md-3">
                                                  <div className="custom-control custom-checkbox">
                                                      <input
                                                          type="checkbox"
                                                          className="custom-control-input"
                                                          id={`sua-${item.id}`}
                                                          name={`sua-${item.id}`}
                                                          onChange={(e) =>
                                                              setPayload2((prev) => ({
                                                                  ...prev,
                                                                  [`sua_${item.id}`]: e.target.checked,
                                                              }))
                                                          }
                                                          checked={payload2[`sua_${item.id}`]}
                                                      />
                                                      <label
                                                          className="custom-control-label"
                                                          htmlFor={`sua-${item.id}`}
                                                      >
                                                          Sửa{' '}
                                                      </label>
                                                  </div>
                                              </div>
                                              <div className="form-group col-md-3">
                                                  <div className="custom-control custom-checkbox">
                                                      <input
                                                          type="checkbox"
                                                          className="custom-control-input"
                                                          id={`xoa-${item.id}`}
                                                          name={`xoa-${item.id}`}
                                                          onChange={(e) =>
                                                              setPayload2((prev) => ({
                                                                  ...prev,
                                                                  [`xoa_${item.id}`]: e.target.checked,
                                                              }))
                                                          }
                                                          checked={payload2[`xoa_${item.id}`]}
                                                      />
                                                      <label
                                                          className="custom-control-label"
                                                          htmlFor={`xoa-${item.id}`}
                                                      >
                                                          Xóa{' '}
                                                      </label>
                                                  </div>
                                              </div>
                                              <div className="form-group col-md-3">
                                                  <div className="custom-control custom-checkbox">
                                                      <input
                                                          type="checkbox"
                                                          className="custom-control-input"
                                                          id={`xem-${item.id}`}
                                                          name={`xem-${item.id}`}
                                                          onChange={(e) =>
                                                              setPayload2((prev) => ({
                                                                  ...prev,
                                                                  [`xem_${item.id}`]: e.target.checked,
                                                              }))
                                                          }
                                                          checked={payload2[`xem_${item.id}`]}
                                                      />
                                                      <label
                                                          className="custom-control-label"
                                                          htmlFor={`xem-${item.id}`}
                                                      >
                                                          Xem{' '}
                                                      </label>
                                                  </div>
                                              </div>
                                          </div>
                                      </React.Fragment>
                                  );
                              })
                            : null}

                        <Col size="12 mt-5">
                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                    <Button color="primary" size="lg" onClick={handleSubmit}>
                                        {titleForm === false ? 'Thêm vai trò' : 'Sửa vai trò'}
                                    </Button>
                                </li>
                                <li>
                                    <a
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            setModal(false);
                                        }}
                                        className="link link-light"
                                    >
                                        Hủy bỏ
                                    </a>
                                </li>
                            </ul>
                        </Col>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};
export default FunctionUser;
