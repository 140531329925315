import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {InputForm, Button} from '~/components/Form'
import * as actions from '~/store/actions'
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import {
    dataSelectCapPhat,
} from '~/utils/Datacustom'

const ModalAddTaisan = ({show, setShow, idNhanvien}) => {
    const fieldList = {
        id: '',
        TaisanId: '',
        TrangthaiId: '',
        NhanvienId: '',
        soluong: '',
    }
    
    const {taisan} = useSelector(state => state.taisan)
    const [listTaisan, setlistTaisan] = useState([taisan])
    const [listStatus, setlistStatus] = useState([])
    const dispatch = useDispatch()
    const [invalidFields, setInvalidFields] = useState([])
    const [payload, setPayloadNhanvien] = useState(fieldList)
    useEffect(() => {
        dispatch(actions.getListTaisan());
        show && setPayloadNhanvien(fieldList)
    }, [show])
    
    useEffect(() => {
        if (taisan) {
            let dataSelectTaisan = taisan.map((item) => ({
                label: item.tentaisan + ' (Còn lại :' + item.soluong + ' SP)',
                value: item.id
            }));
            let capphat = dataSelectCapPhat.map((item) => ({label: item.label, value: item.value}));
            setlistTaisan(dataSelectTaisan)
            setlistStatus(capphat)
        }
    }, [taisan])
    const handleSubmit = async () => {
        let invalids = validate(payload)
        if (invalids === 0) {
            let taisanNV = taisan.find(item => {
                return item && item.id === ([payload.TaisanId.value])[0]
            })
            if (taisanNV.id) {
                let datapayload = {
                    "data": [
                        {
                            taisan: [payload.TaisanId.value],
                            id: idNhanvien,
                            NhanvienId: idNhanvien,
                            trangthaits: [payload.TrangthaiId.value],
                            soluong: [Number(payload.soluong)]
                        }
                    ]
                }
                let datapayloadEditTS = {
                    id: ([payload.TaisanId.value])[0],
                    soluongconlai: Number(taisanNV.soluongconlai) - Number((payload.soluong)),
                }
                dispatch(actions.EditNhanvien(datapayload))
                dispatch(actions.EditTaisan(datapayloadEditTS))
                setShow(false)
            }
        }
    }
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayloadNhanvien(prev => ({...prev, [statename]: selected}))
        
    }
    const validate = (payload) => {
        let invalids = 0
        let fields = Object.entries(payload)
        fields.forEach(item => {
            if (item[1] === '') {
                setInvalidFields(prev => [...prev, {
                    name: item[0],
                    message: 'Bạn không được bỏ trống trường này.'
                }])
                invalids++
            }
        })
        
        return invalids
    }
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Cấp tài sản cho nhân viên
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-6">
                        <div className="form-group">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">Tài sản</label>
                            <Select
                                value={payload.TaisanId}
                                onChange={handleOnSelect}
                                options={listTaisan}
                                placeholder={'Chọn tài sản'}
                                name='TaisanId'
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some(i => i.name === 'TaisanId') &&
                                <small
                                    className='text-danger'>{invalidFields.find(i => i.name === 'TaisanId')?.message}</small>}
                        </div>
                    </div>
                    <div className="form-group col-md-3">
                        <div className="form-group">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">Trạng thái cấp phát</label>
                            <Select
                                value={payload.TrangthaiId}
                                onChange={handleOnSelect}
                                options={listStatus}
                                placeholder={'Trạng thái cấp phát'}
                                name='TrangthaiId'
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some(i => i.name === 'TrangthaiId') &&
                                <small
                                    className='text-danger'>{invalidFields.find(i => i.name === 'TrangthaiId')?.message}</small>}
                        </div>
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Số lượng cấp'}
                            value={payload.soluong}
                            setValue={setPayloadNhanvien}
                            keyPayload={'soluong'}
                            type='number'
                        />
                    </div>
                </div>
                
                
                <div className='col-12 mt-5'>
                    <Button
                        text='Cập nhật'
                        bClass='btn btn-primary px-5'
                        textColor
                        onClick={handleSubmit}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ModalAddTaisan;

