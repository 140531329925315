import actionTypes from './actionTypes'
import { apiGetTamungluong, apiNewTamungluong, apiEditTamungluong, apiDeleteTamungluong } from '~/services/tamungluong'
import { toast } from "react-toastify";
export const getListTamungluong = () => async (dispatch) => {
    try {
        const response = await apiGetTamungluong()
        // console.log('chek api lisst Tamungluong', response?.data.success)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_TAM_UNG_LUONG,
                tamungluong: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_TAM_UNG_LUONG,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_TAM_UNG_LUONG,
            tamungluong: null
        })
    }
}

export const NewTamungluong = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewTamungluong', payload)
            const response = await apiNewTamungluong(payload)
            // console.log('chek api apiNewTamungluong', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_TAM_UNG_LUONG,
                    msg: 'OK'
                });
                dispatch(getListTamungluong());
                toast.success("Thêm mới tạm ứng lương thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_TAM_UNG_LUONG,
                    msg: 'ERR'
                })
                toast.error("Thêm mới tạm ứng lương thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_TAM_UNG_LUONG,
                tamungluong: null
            })
        }
    }
}
export const EditTamungluong = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditTamungluong(payload)
            // console.log('chek api apiEditTamungluong', response)
            if (response?.data.success === 200) {
                // dispatch(getListTamungluong());
                toast.success("Sửa thông tin tạm ứng lương thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_TAM_UNG_LUONG,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_TAM_UNG_LUONG,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin tạm ứng lương thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_TAM_UNG_LUONG,
                tamungluong: null
            })
        }
    }
}
export const DeleteTamungluong = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteTamungluong(payload)
            if (response?.data.success === 200) {
                dispatch(getListTamungluong());
                toast.success("Xóa thông tin tạm ứng lương thành công!")
                dispatch({
                    type: actionTypes.DELETE_TAM_UNG_LUONG,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_TAM_UNG_LUONG,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin tạm ứng lương thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_TAM_UNG_LUONG,
                tamungluong: null
            })
        }
    }
}