import actionTypes from './actionTypes'
import {
    apiGetNhanvien,
    apiNewNhanvien,
    apiEditNhanvien,
    apiDeleteNhanvien,
    apiGetSetuser,
    apiGetNhomnv,
    apiNewNhomnv,
    apiEditNhomnv,
    apiDeleteNhomnv,
    apiGetNhomByNhanVien, apiGetNhanvienById,
    apiGetQuyetdinh
} from '~/services/nhanvien'
import { toast } from "react-toastify";
import config from '~/config';

export const NewNhanvien = (payload ,navigate) => {
// const navigate = useNavigate();
    return async (dispatch) => {
   
        try {
            // console.log('response apiNewNhanvienpayload', payload)
            const response = await apiNewNhanvien(payload)
            console.log('response apiNewNhanvien', response)
            if (response?.data.success === 200) {
                toast.success("Thêm mới nhân viên thành công!")
                dispatch({
                    type: actionTypes.POST_NEW_NHAN_VIEN,
                    msg: 'OK',
                });
                dispatch(getListNhanvien({ page: '1', limit: '10', order: 'desc', by: 'id' }));
                navigate(`${config.he_thong + config.nhan_vien}`);
                // dispatch(getSetuser());
            }else {
                dispatch({
                    type: actionTypes.POST_NEW_NHAN_VIEN,
                    msg: 'ERR'
                })
                toast.error("Thêm mới nhân viên thất bại!")
            }

        } catch (error) {
            // console.log('chek api err catch', payload)
            dispatch({
                type: actionTypes.POST_NEW_NHAN_VIEN,
                nhanvien: null
            })
        }
    }
}
export const getListNhanvien = (payload) => async (dispatch) => {
    try {
        const response = await apiGetNhanvien(payload)
        // console.log('response',response)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_NHAN_VIEN,
                nhanvien: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_NHAN_VIEN,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_NHAN_VIEN,
            nhanvien: null
        })
    }
}
export const getListNhanvienOne = (payload) => async (dispatch) => {
    try {
        
        const response = await apiGetNhanvienById(payload)
        // console.log('response api', response)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_NHAN_VIEN_ONE,
                nhanvienone: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_NHAN_VIEN_ONE,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_NHAN_VIEN_ONE,
            nhanvienone: null
        })
    }
}
//set user vào máy chấm công
export const getSetuser = () => async (dispatch) => {
    try {
        const response = await apiGetSetuser()
        if (response?.data.success === 200) {
            dispatch({
                response: 'ok'
            })
        }
    } catch (error) {
        dispatch({
            response: null
        })
    }
}

export const EditNhanvien = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api try',payload)
            const response = await apiEditNhanvien(payload)
            // console.log('chek api apiEditNhanvien', response)
            if (response?.data.err === 0) {
                dispatch(getListNhanvien({ page: '1', limit: '10', order: 'desc', by: 'id' }));
                toast.success("Sửa thông tin nhân viên thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_NHAN_VIEN,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_NHAN_VIEN,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin nhân viên thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_NHAN_VIEN,
                nhanvien: null
            })
        }
    }
}
export const DeleteNhanvien = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteNhanvien(payload)
            // console.log('chek api apiDeleteNhanvien', response?.data.success)
            if (response?.data.err === 0) {
                dispatch(getListNhanvien({ page: '1', limit: '10', order: 'desc', by: 'id' }));
                toast.success("Xóa thông tin nhân viên thành công!")
                dispatch({
                    type: actionTypes.DELETE_NHAN_VIEN,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_NHAN_VIEN,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin nhân viên thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_NHAN_VIEN,
                nhanvien: null
            })
        }
    }
}

export const getListNhomByNhanVien = (payload) => async (dispatch) => {
    try {
        const response = await apiGetNhomByNhanVien(payload)
        // console.log('response', response)
        if (response?.data.success === 200) {
            // console.log('response?.data.data', response?.data.data)
            dispatch({
                type: actionTypes.GET_NHOM_BY_NHAN_VIEN,
                nhomBynv: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_NHOM_BY_NHAN_VIEN,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_NHOM_BY_NHAN_VIEN,
            nhomBynv: null
        })
    }
}
export const getListNhomnv = (payload) => async (dispatch) => {
    try {
        const response = await apiGetNhomnv(payload)
        // console.log('response', response)
        if (response?.data.success === 200) {
            // console.log('response?.data.data', response?.data.data)
            dispatch({
                type: actionTypes.GET_LIST_NHOM_NHAN_VIEN,
                nhomnv: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_NHOM_NHAN_VIEN,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_NHOM_NHAN_VIEN,
            nhomvn: null
        })
    }
}

export const NewNhomnv = (payload) => {
    return async (dispatch) => {
   
        try {
            const response = await apiNewNhomnv(payload)
            // console.log('response', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_NHOM_NHAN_VIEN,
                    msg: 'OK'
                });
                dispatch(getListNhomnv());
                // dispatch(getSetuser());
                toast.success("Thêm mới thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_NHOM_NHAN_VIEN,
                    msg: 'ERR'
                })
                toast.error("Thêm mới thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_NHOM_NHAN_VIEN,
                nhomvn: null
            })
        }
    }
}
export const EditNhomnv = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api try',payload)
            const response = await apiEditNhomnv(payload)
            // console.log('chek api apiEditNhanvien', response)
            if (response?.data.success === 200) {
                dispatch(getListNhomnv());
                toast.success("Sửa thông tin thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_NHOM_NHAN_VIEN,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_NHOM_NHAN_VIEN,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin thất bại!")
            }

        } catch (error) {
            // console.log('chek api catch',payload)
            dispatch({
                type: actionTypes.PUT_EDIT_NHOM_NHAN_VIEN,
                nhomvn: null
            })
        }
    }
}
export const DeleteNhomnv = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteNhomnv(payload)
            // console.log('chek api apiDeleteNhanvien', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListNhomnv());
                toast.success("Xóa thông tin thành công!")
                dispatch({
                    type: actionTypes.DELETE_NHOM_NHAN_VIEN,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_NHOM_NHAN_VIEN,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_NHOM_NHAN_VIEN,
                nhomvn: null
            })
        }
    }
}

export const getListQuyetdinh = (payload) => async (dispatch) => {
    try {
        const response = await apiGetQuyetdinh(payload)
        // console.log('apiGetQuyetdinh',response)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_QUYET_DINH,
                quyetdinh: response?.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_QUYET_DINH,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_QUYET_DINH,
            quyetdinh: null
        })
    }
}