import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { InputForm, Button } from '~/components/Form';
import * as actions from '~/store/actions';
import { validateMayChamCong } from '~/request/validateMayChamCong';
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import {dataSelectStatus} from "~/utils/Datacustom";
import {NewMayChamCong} from "~/store/actions";

const ModalMaychamcong = ({ show, setShow, fieldList }) => {
    // const { maychamcong } = useSelector((state) => state.maychamcong);
    const dispatch = useDispatch();
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayload] = useState(fieldList);
    const [listStatus, setlistStatus] = useState([]);

    useEffect(() => {
        show && setPayload(fieldList);
        setlistStatus(dataSelectStatus);
    }, [show]);
    const handleSubmit = async () => {
        let invalids = validate(payload);
        if (invalids === 0) {
            let datapayload = {
                data: [
                    {
                        status: payload.status.value,
                        ipmaychamcong: payload.ipmaychamcong,
                        tenmaychamcong: payload.tenmaychamcong,
                        timeout: payload.timeout,
                        inport: payload.inport,
                        port: payload.port,
                        loaimay: payload.loaimay,
                    },
                ],
            };
            // console.log('check payload', datapayload)
            dispatch(actions.NewMayChamCong(datapayload));
        }
    };
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayload((prev) => ({ ...prev, [statename]: selected }));
    };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateMayChamCong(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateMayChamCong(item[0], item[1])]);
                invalids++;
            }
        });
        return invalids;
    };
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">Thêm mới máy chấm công</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Tên máy chấm công'}
                            value={payload.tenmaychamcong}
                            setValue={setPayload}
                            keyPayload={'tenmaychamcong'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-6 ">
                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                                Trạng thái <b className="text-danger">(*)</b>
                            </span>
                        </label>
                        <Select
                            value={payload.status}
                            onChange={handleOnSelect}
                            options={listStatus}
                            placeholder={'Chọn trạng thái'}
                            name="status"
                            onFocus={() => setInvalidFields([])}
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'status') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'status')?.message}
                            </small>
                        )}
                    </div>
            
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'IP'}
                            value={payload.ipmaychamcong}
                            setValue={setPayload}
                            keyPayload={'ipmaychamcong'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Port'}
                            value={payload.port}
                            setValue={setPayload}
                            keyPayload={'port'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Timeout'}
                            value={payload.timeout}
                            setValue={setPayload}
                            keyPayload={'timeout'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Inport'}
                            value={payload.inport}
                            setValue={setPayload}
                            keyPayload={'inport'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Loại máy'}
                            value={payload.loaimay}
                            setValue={setPayload}
                            keyPayload={'loaimay'}
                            type="text"
                            validate
                        />
                    </div>
                </div>

                <div className="col-12 mt-5">
                    <Button text="Thêm mới" bClass="btn btn-primary px-5" textColor onClick={handleSubmit} />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalMaychamcong;
