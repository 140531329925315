import actionTypes from './actionTypes'
import { apiGetKehoachcuaxe, apiNewKehoachcuaxe, apiEditKehoachcuaxe, apiDeleteKehoachcuaxe } from '~/services/kehoachcuaxe'
import { toast } from "react-toastify";
export const getListKehoachcuaxe = () => async (dispatch) => {
    try {
        const response = await apiGetKehoachcuaxe()
        console.log('chek api lisst Kehoachcuaxe', response)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_KE_HOACH_CUA_XE,
                kehoachcuaxe: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_KE_HOACH_CUA_XE,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_KE_HOACH_CUA_XE,
            kehoachcuaxe: null
        })
    }
}

export const NewKehoachcuaxe = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewKehoachcuaxe', payload)
            const response = await apiNewKehoachcuaxe(payload)
            // console.log('chek api apiNewKehoachcuaxe', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_KE_HOACH_CUA_XE,
                    msg: 'OK'
                });
                dispatch(getListKehoachcuaxe());
                toast.success("Thêm mới thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_KE_HOACH_CUA_XE,
                    msg: 'ERR'
                })
                toast.error("Thêm mới thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_KE_HOACH_CUA_XE,
                kehoachcuaxe: null
            })
        }
    }
}
export const EditKehoachcuaxe = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditKehoachcuaxe(payload)
            // console.log('chek api apiEditKehoachcuaxe', response)
            if (response?.data.success === 200) {
                dispatch(getListKehoachcuaxe());
                toast.success("Sửa thông tin thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_KE_HOACH_CUA_XE,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_KE_HOACH_CUA_XE,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_KE_HOACH_CUA_XE,
                kehoachcuaxe: null
            })
        }
    }
}
export const DeleteKehoachcuaxe = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteKehoachcuaxe(payload)
            // console.log('chek api apiDeleteKehoachcuaxe', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListKehoachcuaxe());
                toast.success("Xóa thông tin thành công!")
                dispatch({
                    type: actionTypes.DELETE_KE_HOACH_CUA_XE,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_KE_HOACH_CUA_XE,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_KE_HOACH_CUA_XE,
                kehoachcuaxe: null
            })
        }
    }
}