import React, {useState, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as actions from '~/store/actions';
import {ModalNghiphepcongtac, ModalEditNghiphepcongtac} from './Modal';
import {MaterialReactTable} from 'material-react-table';
import Swal from 'sweetalert2';
import {Card} from 'reactstrap';
import Head from '~/layout/head/Head';
import Content from '~/layout/content/Content';
import {Block} from '~/components/block/Block';
import {
    Button,
    DataTable,
    Icon,
    PermissionUtils,
} from '~/components/Component';
import {format, parseISO} from 'date-fns';
import {Box, IconButton} from '@mui/material';
import {Edit as EditIcon, Delete as DeleteIcon} from '@mui/icons-material';
import {ExportToCsv} from "export-to-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {MRT_Localization_VI} from 'material-react-table/locales/vi';
import {useSearchParams, useNavigate, useLocation} from 'react-router-dom';
import {
    apiGetNghiphepcongtac,
} from '~/services';

const Nghiphepcongtac = () => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [showEditNghiphepcongtac, setShowEditNghiphepcongtac] = useState(false);
    const fieldList = {
        madon: '',
        LydonghiphepId: '',
        NhanvienId: '',
        tylehuongluong: 0,
        lydoNghi: '',
        ngaybatdau: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        ngayketthuc: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        giobatdau: '',
        gioketthuc: '',
        TrangthaiId: '',
        type: '',
        ghichu: '',
        songaynghitoida: 0,
        ngaynghitoida: 0,
        songaydanghi: 0,
        songayconlai: 0,
        vesomgiuaca: '',
        vesomcuoica: '',
        denmuongiuaca: '',
        denmuoncuoica: '',
        arrtuan: '',
        arrPhep: [],
        loaihuong: 0,
        giatri: 0,
        PheduyetId: '',

    };
   
    const [data, setData] = useState([]);
    const [dataTmp, setDataTmp] = useState([]);
    // console.log('================================dataTH',dataTH.length)
    const [modalTab, setModalTab] = useState('1');
    const [modalTabTitle, setModalTabTitle] = useState('Bảng tổng hợp nghỉ phép');
    const [modalTabType, setModalTabType] = useState('BANG_TONG_NGHI_PHEP');
    //lấy giá trị từ thằng redux trả về
    const {nghiphepcongtac} = useSelector((state) => state.nghiphepcongtac);
    // console.log('================================dataTH',nghiphepcongtac)
    const {bangtonghopphep} = useSelector((state) => state.bangtonghopphep);
    const [dataTH, setDataTH] = useState(bangtonghopphep);
    useEffect(() => {
        dispatch(actions.getListNghiphepcongtac({order: 'desc', by: 'id'}));
        dispatch(actions.getListLydonghiphep());
        dispatch(actions.getListCauhinhnghiphep());
        dispatch(actions.getListBangtonghopphep());
    }, [dispatch]);
    // Trạng thái Nghỉ phép công tác: {0:Tiếp nhận,1:Đang xử lý,2: Không duyệt,3: Đã duyệt}
    const currentYear = new Date().getFullYear();
    useEffect(() => {
        if (bangtonghopphep && nghiphepcongtac) {
            let listDataTH = bangtonghopphep?.filter((item) => item?.nam === currentYear);
            // let listData = [];
            let listData = nghiphepcongtac?.filter((item) => item?.type === modalTabType);
            modalTabType === "BANG_TONG_NGHI_PHEP" ? setData([...listDataTH]) : setData([...listData]);
            // setDataTH([...listDataTH]);
            setDataTmp([...nghiphepcongtac]);
            // setData([...listData]);
        }
       
    }, [nghiphepcongtac, modalTabType,bangtonghopphep]);
    
    function getDaysInMonth(year, month) {
        return new Date(year, month, 0).getDate();
    }
    
    // console.log('-------------------->>>>>>>>>>>>>>>>>>>>>>>> data',data)
    const onClickTabBangTongHopPhep = async () => {
        setModalTabType('BANG_TONG_NGHI_PHEP');
        setModalTabTitle('Bảng tổng hợp phép');
        // let listDataTH = bangtonghopphep?.filter((item) => item.nam === 2023);
        // setData([...listDataTH]);
    };
    const onClickTabNghiPhep = async () => {
        setModalTabType('NGHI_PHEP');
        setModalTabTitle('Đơn xin nghỉ');
        // let listData = nghiphepcongtac?.filter((item) => item.type === 'NGHI_PHEP');
        // setData([...listData]);
    };
    const onClickTabDiMuonVeSom = async () => {
        setModalTabType('DI_MUON_VE_SOM');
        setModalTabTitle('Đơn xin đi muộn về sớm');
    };
    const onClickTabCongTac = async () => {
        setModalTabType('CONG_TAC');
        setModalTabTitle('Đề nghị công tác');

    };
    const onClickTabCapNhatNgayCong = async () => {
        setModalTabType('CAP_NHAT_CONG');
        setModalTabTitle('Đề nghị cập nhật công');

    };
    const onClickTabPhieuracong = async () => {
        setModalTabType('PHIEU_RA_CONG');
        setModalTabTitle('Phiếu ra cổng');
    };
    const [showPhanquyen, setShowPhanquyen] = useState({});
    let dataPermissionUtils = PermissionUtils('duyetphep', 'all');
    useEffect(() => {
        if (dataPermissionUtils) {
            setShowPhanquyen(dataPermissionUtils);
        }
    }, [dataPermissionUtils]);
    
    // const [currentPage, setCurrentPage] = useState(1);
    // const [itemPerPage, setItemPerPage] = useState(10);
    
    // // Get current list, pagination
    // const indexOfLastItem = currentPage * itemPerPage;
    // const indexOfFirstItem = indexOfLastItem - itemPerPage;
    // const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
    // const paginate = (pageNumber) => setCurrentPage(pageNumber);
    useEffect(() => {
        (nghiphepcongtac && setShow(false)) || setShowEditNghiphepcongtac(false);
    }, [nghiphepcongtac]);

    const handleAddNewNghiphepcongtac = async () => {
        setShow(true);
    };
    const [dataIdNghiphepcongtac, setDataIdNghiphepcongtac] = useState([]);
    const handleEditNghiphepcongtac = async (data) => {
        setShowEditNghiphepcongtac(true);
        await setDataIdNghiphepcongtac(data);
    };
    const handleDeleteNghiphepcongtac = (data) => {
        Swal.fire({
            title: 'Bạn có chắc không?',
            text: 'Bạn sẽ không thể lấy lại khi xóa!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Không, bỏ qua!',
            confirmButtonText: 'Vâng, xóa nó đi!',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(actions.DeleteNghiphepcongtac(data));
            }
        });
    };


    // Phần url thông báo
    const navigate = useNavigate();
    const location = useLocation();
const [searchParams] = useSearchParams();
useEffect(() => {
    if(searchParams.get('thong-bao')){
        // console.log('fffffffffffffff')
        const fetchData = async () => {
            try {
                const getId = await apiGetNghiphepcongtac({id: searchParams.get('thong-bao')});
                if(getId?.data?.data?.rows?.length > 0){
                    handleEditNghiphepcongtac(getId?.data?.data?.rows[0]);
                    navigate(location.pathname)
                    if(getId?.data?.data?.rows[0]?.type === "NGHI_PHEP"){
                        onClickTabNghiPhep();
                        setModalTab('2');
                    }
                    else if(getId?.data?.data?.rows[0]?.type === "DI_MUON_VE_SOM"){
                        onClickTabDiMuonVeSom();
                        setModalTab('3');
                    }
                    else if(getId?.data?.data?.rows[0]?.type === "CONG_TAC"){
                        onClickTabCongTac();
                        setModalTab('4');
                    }
                    else if(getId?.data?.data?.rows[0]?.type === "CAP_NHAT_CONG"){
                        onClickTabCapNhatNgayCong();
                        setModalTab('5');
                    }else {
                        onClickTabBangTongHopPhep();
                        setModalTab('1');
                    }
                }
            } catch (error) {
            }
        };
        fetchData().catch(console.error);
    }
}, [searchParams.get('thong-bao'), searchParams]);
    //End Phần url thông báo
    const formatVND = (data) => {
        return Number(data ?? 0).toLocaleString('it-IT', {
            style: 'currency',
            currency: 'vnd',
        });
    };
    const Example = () => {
        let dataAb = data;
        const typeFormat = 'dd/MM/yyyy hh:mm'
        const FormatDate = (cell,type) => ( // Format Date : type = 'dd/MM/yyyy hh:mm', date = cell
            <Box>
                {format(parseISO(cell), type)}
            </Box>
        );

        let dataArrNghiPhep = [
            {
                Header: '#', // Tiêu đề cột số thứ tự
                header: 'STT', // Tiêu đề cột số thứ tự
                id: 'rowNumber', // ID của cột
                size: 50,
                Cell: ({row}) => <div>{row?.index + 1}</div>, // Giá trị của cột là số thứ tự
                enableSorting: false,
                enablePinning: true,
            },
            {
                accessorKey: 'nam',
                header: 'Năm',
                enablePinning: true,
            },
            {
                accessorKey: 'Nhanvien.hoten',
                header: 'Họ tên',
                enablePinning: true,
            },
            {
                accessorKey: 'Nhanvien.uuid',
                header: 'Mã nhân viên',
                enablePinning: true,
            },
            {
                accessorKey: 'sophepton',
                header: 'Số phép tồn',
            },
            {
                accessorKey: 'sophepthamnien',
                header: 'Số phép thâm niên',
            },
            {
                accessorKey: 'sophephientai',
                header: 'Số phép hiện tại',
            },
            {
                accessorKey: 'thang1',
                header: 'Tháng 1',
            },
            {
                accessorKey: 'thang2',
                header: 'Tháng 2',
            }, {
                accessorKey: 'thang3',
                header: 'Tháng 3',
            },
            {
                accessorKey: 'thang4',
                header: 'Tháng 4',
            }, {
                accessorKey: 'thang5',
                header: 'Tháng 5',
            },
            {
                accessorKey: 'thang6',
                header: 'Tháng 6',
            },
            {
                accessorKey: 'thang7',
                header: 'Tháng 7',
            },
            {
                accessorKey: 'thang8',
                header: 'Tháng 8',
            },
            {
                accessorKey: 'thang9',
                header: 'Tháng 9',
            }, {
                accessorKey: 'thang10',
                header: 'Tháng 10',
            },
            {
                accessorKey: 'thang11',
                header: 'Tháng 11',
            }, {
                accessorKey: 'thang12',
                header: 'Tháng 12',
            }

        ]
        if (modalTabType === 'NGHI_PHEP'){
            dataArrNghiPhep = [
                {
                    Header: '#', // Tiêu đề cột số thứ tự
                    header: 'STT', // Tiêu đề cột số thứ tự
                    id: 'rowNumber', // ID của cột
                    size: 50,
                    Cell: ({row}) => <div>{row?.index + 1}</div>, // Giá trị của cột là số thứ tự
                    enableSorting: false,
                    enablePinning: true,
                },
                {
                    accessorKey: 'Nhanvien.hoten',
                    header: 'Họ tên',
                    enablePinning: true,
                },
              
                {
                    accessorKey: 'Nhanvien.uuid',
                    header: 'Mã nhân viên',
                    enablePinning: true,
                },
                {
                    accessorKey: 'madon',
                    header: 'Mã đơn',
                },
                {
                    accessorKey: 'Lydonghiphep.tieude',
                    header: 'Lý do nghỉ phép',
                },
                {
                    accessorKey: 'songaynghitoida',
                    header: 'Số ngày nghỉ',
                },
                {
                    accessorKey: 'tylehuongluong',
                    header: 'Tỷ lệ hưởng lương',
                },
                {
                    accessorKey: 'createdAt',
                    header: 'Ngày tạo',
                    Cell: ({cell}) => FormatDate(cell?.getValue(),typeFormat),
                },
                {
                    accessorKey: 'ngaybatdau',
                    header: 'Ngày bắt đầu',
                    Cell: ({ cell }) => (
                        <Box
                            component="span"
                            sx={(theme) => ({
                                borderRadius: '0.25rem',
                                color: '#111',
                                p: '0.25rem',
                            })}
                        >
                            {typeof cell.getValue() !== 'string' ? '' : format(new Date(cell.getValue()), 'dd/MM/yyyy')}
                        </Box>
                    ),
                },
                {
                    accessorKey: 'ngayketthuc',
                    header: 'Ngày kết thúc',
                    Cell: ({ cell }) => (
                        <Box
                            component="span"
                            sx={(theme) => ({
                                borderRadius: '0.25rem',
                                color: '#111',
                                p: '0.25rem',
                            })}
                        >
                            {typeof cell.getValue() !== 'string' ? '' : format(new Date(cell.getValue()), 'dd/MM/yyyy')}
                
                        </Box>
                    ),
                },
                {
                    accessorKey: 'TrangthaiId',
                    header: 'Trạng thái',
                    Cell: ({ cell }) => (
                        <Box
                            component="span"
                            sx={(theme) => ({
                                backgroundColor: cell.getValue() === 3 ? '#1ee0ac' : '#e65100',
                                fontSize: '10px',
                                borderRadius: '0.25rem',
                                color: '#fff',
                                p: '0.25rem',
                            })}
                        >
                            {cell.getValue() === 3 ? 'Đã duyệt' : 'Chưa duyệt'}
                        </Box>
                    ),
                },
            ];
        } else if (modalTabType === 'DI_MUON_VE_SOM'){
            dataArrNghiPhep = [
                {
                    Header: '#', // Tiêu đề cột số thứ tự
                    header: 'STT', // Tiêu đề cột số thứ tự
                    id: 'rowNumber', // ID của cột
                    size: 50,
                    Cell: ({row}) => <div>{row?.index + 1}</div>, // Giá trị của cột là số thứ tự
                    enableSorting: false,
                    enablePinning: true,
                },
                {
                    accessorKey: 'Nhanvien.hoten',
                    header: 'Họ tên',
                    enablePinning: true,
                },
              
                {
                    accessorKey: 'Nhanvien.uuid',
                    header: 'Mã nhân viên',
                    enablePinning: true,
                },
                {
                    accessorKey: 'madon',
                    header: 'Mã đơn',
                },
                // {
                //     accessorKey: 'vesomgiuaca',
                //     header: 'Về sớm giữa ca',
                // },
                // {
                //     accessorKey: 'vesomcuoica',
                //     header: 'Về sớm cuối ca',
                // },
                // {
                //     accessorKey: 'denmuongiuaca',
                //     header: 'Đến muộn giữa ca',
                // },
                // {
                //     accessorKey: 'denmuoncuoica',
                //     header: 'Đến muộn cuối ca',
                // },
                {
                    accessorKey: 'createdAt',
                    header: 'Ngày tạo',
                    Cell: ({cell}) => FormatDate(cell?.getValue(),typeFormat),
                },
                // {
                //     accessorFn: (row) => `${row.ngaybatdau}`,
                //     id: 'ngaybatdau',
                //     header: 'Ngày xin',
                // },
                {
                    accessorKey: 'ngaybatdau',
                    header: 'Ngày xin',
                    Cell: ({ cell }) => (
                        <Box
                            component="span"
                            sx={(theme) => ({
                                borderRadius: '0.25rem',
                                color: '#111',
                                p: '0.25rem',
                            })}
                        >
                            {typeof cell.getValue() !== 'string' ? '' : format(new Date(cell.getValue()), 'dd/MM/yyyy')}
                        </Box>
                    ),
                },
                // {
                //     accessorFn: (row) => `${row.gioketthuc} ${row.ngayketthuc}`,
                //     id: 'ngayketthuc',
                //     header: 'Ngày kết thúc',
                // },
                {
                    accessorKey: 'TrangthaiId',
                    header: 'Trạng thái',
                    Cell: ({ cell }) => (
                        <Box
                            component="span"
                            sx={(theme) => ({
                                backgroundColor: cell.getValue() === 3 ? '#1ee0ac' : '#e65100',
                                fontSize: '10px',
                                borderRadius: '0.25rem',
                                color: '#fff',
                                p: '0.25rem',
                            })}
                        >
                            {cell.getValue() === 3 ? 'Đã duyệt' : 'Chưa duyệt'}
                        </Box>
                    ),
                },
            ];
        } else if (modalTabType === 'CONG_TAC' || modalTabType === 'CAP_NHAT_CONG'){
            dataArrNghiPhep = [
                {
                    Header: '#', // Tiêu đề cột số thứ tự
                    header: 'STT', // Tiêu đề cột số thứ tự
                    id: 'rowNumber', // ID của cột
                    size: 50,
                    Cell: ({row}) => <div>{row?.index + 1}</div>, // Giá trị của cột là số thứ tự
                    enableSorting: false,
                    enablePinning: true,
                },
                {
                    accessorKey: 'Nhanvien.hoten',
                    header: 'Họ tên',
                    enablePinning: true,
                },
                {
                    accessorKey: 'Nhanvien.uuid',
                    header: 'Mã nhân viên',
                    enablePinning: true,
                },
                {
                    accessorKey: 'madon',
                    header: 'Mã đơn',
                },
                {
                    accessorKey: 'lydoNghi',
                    header: 'Lý do Công tác',
                },
                {
                    accessorKey: 'giatri',
                    header: 'Giá trị / Ngày(VND)',
                    Cell: ({row}) => <div>{formatVND(row?.original?.giatri)}</div>,
                },
                {
                    accessorKey: 'songaynghitoida',
                    header: 'Số ngày nghỉ',
                },
                {
                    accessorKey: 'tylehuongluong',
                    header: 'Tỷ lệ hưởng lương',
                },
                {
                    accessorKey: 'createdAt',
                    header: 'Ngày tạo',
                    Cell: ({cell}) => FormatDate(cell?.getValue(),typeFormat),
                },
                {
                    accessorKey: 'ngaybatdau',
                    header: 'Ngày bắt đầu',
                    Cell: ({ cell }) => (
                        <Box
                            component="span"
                            sx={(theme) => ({
                                borderRadius: '0.25rem',
                                color: '#111',
                                p: '0.25rem',
                            })}
                        >
                            {typeof cell.getValue() !== 'string' ? '' : format(new Date(cell.getValue()), 'dd/MM/yyyy')}
                        </Box>
                    ),
                },
                {
                    accessorKey: 'ngayketthuc',
                    header: 'Ngày kết thúc',
                    Cell: ({ cell }) => (
                        <Box
                            component="span"
                            sx={(theme) => ({
                                borderRadius: '0.25rem',
                                color: '#111',
                                p: '0.25rem',
                            })}
                        >
                            {typeof cell.getValue() !== 'string' ? '' : format(new Date(cell.getValue()), 'dd/MM/yyyy')}
                
                        </Box>
                    ),
                },
                {
                    accessorKey: 'TrangthaiId',
                    header: 'Trạng thái',
                    Cell: ({ cell }) => (
                        <Box
                            component="span"
                            sx={(theme) => ({
                                backgroundColor: cell.getValue() === 3 ? '#1ee0ac' : '#e65100',
                                fontSize: '10px',
                                borderRadius: '0.25rem',
                                color: '#fff',
                                p: '0.25rem',
                            })}
                        >
                            {cell.getValue() === 3 ? 'Đã duyệt' : 'Chưa duyệt'}
                        </Box>
                    ),
                },
            ];
        }
         else if (modalTabType === 'PHIEU_RA_CONG'){
            dataArrNghiPhep = [
                {
                    Header: '#', // Tiêu đề cột số thứ tự
                    header: 'STT', // Tiêu đề cột số thứ tự
                    id: 'rowNumber', // ID của cột
                    size: 50,
                    Cell: ({row}) => <div>{row?.index + 1}</div>, // Giá trị của cột là số thứ tự
                    enableSorting: false,
                    enablePinning: true,
                },
                {
                    accessorKey: 'Nhanvien.hoten',
                    header: 'Họ tên',
                    enablePinning: true,
                },
                {
                    accessorKey: 'Nhanvien.uuid',
                    header: 'Mã nhân viên',
                    enablePinning: true,
                },
                {
                    accessorKey: 'madon',
                    header: 'Mã đơn',
                },
                {
                    accessorKey: 'ngaybatdau',
                    header: 'Ngày bắt đầu',
                    Cell: ({ cell }) => (
                        <Box
                            component="span"
                            sx={(theme) => ({
                                borderRadius: '0.25rem',
                                color: '#111',
                                p: '0.25rem',
                            })}
                        >
                            {typeof cell.getValue() !== 'string' ? '' : format(new Date(cell.getValue()), 'dd/MM/yyyy')}
                        </Box>
                    ),
                },
                {
                    accessorKey: 'ngayketthuc',
                    header: 'Ngày kết thúc',
                    Cell: ({ cell }) => (
                        <Box
                            component="span"
                            sx={(theme) => ({
                                borderRadius: '0.25rem',
                                color: '#111',
                                p: '0.25rem',
                            })}
                        >
                            {typeof cell.getValue() !== 'string' ? '' : format(new Date(cell.getValue()), 'dd/MM/yyyy')}
                
                        </Box>
                    ),
                },
                {
                    accessorKey: 'TrangthaiId',
                    header: 'Trạng thái',
                    Cell: ({ cell }) => (
                        <Box
                            component="span"
                            sx={(theme) => ({
                                backgroundColor: cell.getValue() === 3 ? '#1ee0ac' : '#e65100',
                                fontSize: '10px',
                                borderRadius: '0.25rem',
                                color: '#fff',
                                p: '0.25rem',
                            })}
                        >
                            {cell.getValue() === 3 ? 'Đã duyệt' : 'Chưa duyệt'}
                        </Box>
                    ),
                },
            ];
        }
        const columns = useMemo(() => dataArrNghiPhep, []);
        const csvOptions = { fileName: 'csv-data',
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            useBom: true,
            useKeysAsHeaders: false,
            headers: columns.map((c) => c.header),
        };
        
        const csvExporter = new ExportToCsv(csvOptions);
  
        const handleExportData = () => {
            //TODO: export data by modalTabType
            // console.log('modalTabType', modalTabType)
            if (dataAb.length === 0) {
                Swal.fire({
                    icon: 'error',
                    title: 'Cảnh báo...',
                    text: 'Không có dữ liệu để xuất!',
                });
                return;
            }
            csvExporter.generateCsv(dataAb);
        };
        
        return (
            <MaterialReactTable
                state={{
                    expanded: true,
                    isLoading: dataAb.length === 0,
                }}
                columns={columns}
                data={dataAb}
                defaultDisplayColumn={{enableResizing: true}}
                enableColumnResizing={false}
                enableFullScreenToggle={false}
                maxHeight="100"
                initialState={{
                    columnPinning: {left: ['rowNumber', 'Nhanvien.hoten', 'Nhanvien.uuid','madon']},
                    columnVisibility: {tylehuongluong: true, createdAt: true},
                }}
                
                enableRowActions={modalTabType !== "BANG_TONG_NGHI_PHEP"}
                positionActionsColumn="last"
                
                renderTopToolbarCustomActions={({table}) => (
                    <Box sx={{display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap'}}>
                        {modalTabType !== "BANG_TONG_NGHI_PHEP" && +showPhanquyen?.them === 1 && (
                            <Button
                                color="success"
                                className="btn-icon px-4 "
                                onClick={handleAddNewNghiphepcongtac}
                            >
                                <Icon name="plus"></Icon> Thêm mới
                            </Button>
                        )}
                        <Button
                            color="primary"
                            onClick={handleExportData}
                            startIcon={<FileDownloadIcon/>}
                            variant="contained"
                        >
                            Xuất dữ liệu
                        </Button>
                    </Box>
                )}
                localization={MRT_Localization_VI}
                renderRowActions={({row, table}) => (
                    <Box sx={{display: 'block', flexWrap: 'nowrap', gap: '8px', width: '150px'}}>
                        {+showPhanquyen?.sua === 1 && (
                            <IconButton
                                color="secondary"
                                width="20px"
                                onClick={() => {
                                    handleEditNghiphepcongtac(row.original);
                                }}
                            >
                                <EditIcon/>
                            </IconButton>
                        )}
                        {+showPhanquyen?.xoa === 1 && (
                            <IconButton
                                color="error"
                                onClick={() => {
                                    handleDeleteNghiphepcongtac({id: row.original.id});
                                }}
                            >
                                <DeleteIcon/>
                            </IconButton>
                        )}
                    </Box>
                )}
            />
        );
    };
    
    return (
        <React.Fragment>
            <Head title="Quản lý nghỉ phép - công tác và thêm bớt ngày công"></Head>
            <Content>
                <Card className="mt-3">
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner card-inner-lg px-4 mt-3">
                            <h4>Quản lý nghỉ phép, công tác & cập nhật công </h4>
                            <div>
                                <ul className="nk-nav nav nav-tabs">
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link ${modalTab === '1' && 'active'}`}
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                setModalTab('1');
                                                onClickTabBangTongHopPhep();
                                            }}
                                            href="#bangtonghopphep"
                                        >
                                            Bảng tổng hợp nghỉ phép
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link ${modalTab === '2' && 'active'}`}
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                setModalTab('2');
                                                onClickTabNghiPhep();
                                            }}
                                            href="#nghiphep"
                                        >
                                            Đơn xin nghỉ
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link ${modalTab === '3' && 'active'}`}
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                setModalTab('3');
                                                onClickTabDiMuonVeSom();
                                            }}
                                            href="#dimuonvesom"
                                        >
                                            Đơn xin đi muộn về sớm
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link ${modalTab === '4' && 'active'}`}
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                setModalTab('4');
                                                onClickTabCongTac();
                                            }}
                                            href="#nghiphep"
                                        >
                                            Đề nghị công tác
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link ${modalTab === '5' && 'active'}`}
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                setModalTab('5');
                                                onClickTabCapNhatNgayCong();
                                            }}
                                            href="#nghiphep"
                                        >
                                            Đề nghị cập nhật công
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link ${modalTab === '6' && 'active'}`}
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                setModalTab('6');
                                                onClickTabPhieuracong();
                                            }}
                                            href="#nghiphep"
                                        >
                                            Phiếu ra cổng
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </DataTable>
                </Card>
                
                <Block>
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner position-relative card-tools-toggle pt-0">
                            <h5>{modalTabTitle}</h5>
                        </div>
                        {/*{data?.length > 0 ? (*/}
                            <Example />
                        {/*) : (*/}
                        {/*    <div className="text-center">*/}
                        {/*        <h4>Không có dữ liệu</h4>*/}
                        {/*    </div>*/}
                        {/*)}*/}
                        {/*{data && <Example />}*/}
                       
                        
                    </DataTable>
                </Block>
                <ModalNghiphepcongtac
                    show={show}
                    type={modalTabType}
                    title={modalTabTitle}
                    setShow={() => setShow(false)}
                    fieldList={fieldList}
                />
                <ModalEditNghiphepcongtac
                    show={showEditNghiphepcongtac}
                    dataCongtac={dataTmp}
                    type={modalTabType}
                    title={modalTabTitle}
                    setShow={() => setShowEditNghiphepcongtac(false)}
                    dataIdNghiphepcongtac={dataIdNghiphepcongtac}
                    fieldList={fieldList}
                />
            </Content>
        </React.Fragment>
    );
};

export default Nghiphepcongtac;
