import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import './ModalNhanVien.scss';
import { Input, Modal, ModalBody, Spinner } from 'reactstrap';
import { apiDeleteLoaiDexuat, apiEditLoaiDexuat, apiGetLoaiDexuat, apiGetNhanvien, apiNewLoaiDexuat } from '~/services';
// import Icon from '../icon/Icon';
import Select from 'react-select';
import { Button, Icon } from '~/components/Component';
import { InputForm } from '~/components/Form';
import Textarea from '~/components/Form/Textarea';
import { toast } from 'react-toastify';

const ModalSelectDexuat = ({ showSelect, setShowSelect,listType , setlistType }) => {
    const [invalidFields, setInvalidFields] = useState('');
    const [data, setData] = useState([]);
    const [dataCkb, setDataCkb] = useState([]);
    const [dataUpdate, setDataUpdate] = useState(true);
    const [payload, setPayload] = useState({ Name: '', Description: '' });
    useEffect(() => {
        if(listType?.length > 0 && listType[0].value !== undefined){
            let result = [];
            listType?.map((i) => {
                let index = data.findIndex((item) => item.id === i.value);
                let dx = data[index];
                dx.checked = true;
                result.push(dx);
            });
            setDataCkb(result);
        }
    }, [listType]);
    useEffect(() => {
        fetchData();
    }, []);
    async function fetchData() {
        try {
            const loaiDexuat = await apiGetLoaiDexuat();
            const response = loaiDexuat.data.data.rows;
            let loaiDexuatActive = response.filter((it) => {
                return it.TrangthaiId === 1;
            });
            setData([...loaiDexuatActive]);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const onSelectChangeSV = (e, id) => {
        let index = data.findIndex((item) => item.id === id);
        let nhanvien = data[index];
        setDataCkb([nhanvien]);
    };

    const handleEditLoaiDexuat = async (data) => {
        setDataUpdate(false);
        setPayload((prev) => ({ ...prev, Name: data.Name, Description: data.Description, id: data.id }));
    };
    const handleDeleteType = async (loaiDexuatId) => {
        let dataPayload = {
            id: loaiDexuatId,
        };
        let deleteData = await apiDeleteLoaiDexuat(dataPayload);
        if (deleteData?.data.success === 200) {
            toast.success(deleteData?.data.message);
            await fetchData();
        } else {
            toast.error(deleteData?.data.message);
        }
    };
    const handleSubmitAddType = async () => {
        let invalids = validate(payload);
        if (invalids === 0) {
        if (payload.id) {
            let datapayloadEdit = {
                data: [
                    {
                        id: payload.id,
                        Name: payload.Name,
                        Description: payload.Description,
                    },
                ],
            };
            const loaiDexuat = await apiEditLoaiDexuat(datapayloadEdit);
            if (loaiDexuat?.data.success === 200) {
                toast.success('Cập nhật thành công!!');
                fetchData();
                setPayload({ Name: '', Description: '' });
                setDataUpdate(true);
            }
        } else {
            let datapayload = {
                data: [
                    {
                        Name: payload.Name,
                        Description: payload.Description,
                    },
                ],
            };
            const loaiDexuat = await apiNewLoaiDexuat(datapayload);
            if (loaiDexuat?.data.success === 200) {
                toast.success('Cập nhật thành công!!');
                fetchData();
                setPayload({ Name: '', Description: '' });
            }
        }
    }
    };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (item[1] === '') {
                setInvalidFields((prev) => [
                    ...prev,
                    {
                        name: item[0],
                        message: 'Bạn không được bỏ trống trường này.',
                    },
                ]);
                invalids++;
            }
        });

        return invalids;
    };
    const handleSubmit = () => {
        if (dataCkb.length > 0) {
            setlistType(
                dataCkb?.map((item) => ({
                    label: item.Name,
                    value: item.id,
                })),
            );
            setDataCkb([]);
            setShowSelect(false);
        }
    };

    return (
        <React.Fragment>
            <Modal isOpen={showSelect} toggle={setShowSelect} size="lg">
                <ModalBody>
                    <button
                        type="button"
                        className="btn-close text-danger fw-bolder position-absolute"
                        style={{ right: '2px', top: '2px', border: '2px solid' }}
                        onClick={setShowSelect}
                    ></button>
                    <div className="container">
                        <div className="position-relative card-tools-toggle px-0 py-2">
                            <div className="card-title-group">
                                <div className="card-title">
                                    <h5 className="title">Kiểu đề xuất</h5>
                                </div>
                            </div>
                            <div className="card-title-group mt-3 row">
                                <div className="form-group col-md-12 mb-1">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Tiêu đề'}
                                        value={payload.Name}
                                        setValue={setPayload}
                                        keyPayload={'Name'}
                                        type="text"
                                        validate
                                    />
                                </div>
                                <div className="form-group col-md-12 mb-1">
                                    <Textarea
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Mô tả'}
                                        value={payload.Description}
                                        setValue={setPayload}
                                        keyPayload={'Description'}
                                        type="text"
                                        validate
                                    />
                                </div>
                                <div className="form-group col-md-12 mb-2 text-end">
                                    <button className="btn btn-danger px-5 mt-2" onClick={handleSubmitAddType}>
                                        {dataUpdate === true ? 'Thêm mới' : 'Cập nhật'}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <table className="table table-fixed">
                            <thead className="d-block">
                                <tr className="d-flex">
                                    <th className="col-1 border-bottom-0">#</th>
                                    <th className="col-1 border-bottom-0">
                                        <div className="custom-control custom-control-sm custom-checkbox notext">
                                            <div>
                                                {/*<input*/}
                                                {/*    type="checkbox"*/}
                                                {/*    className="custom-control-input"*/}
                                                {/*    onChange={(e) => selectorCheckSL(e)}*/}
                                                {/*    id="uidd"*/}
                                                {/*/>*/}
                                                {/*<label className="custom-control-label" htmlFor="uidd"></label>*/}
                                            </div>
                                        </div>
                                    </th>
                                    <th className="col-4 border-bottom-0">
                                        <span className="sub-text fw-bold text-info text-uppercase">Loại Đề Xuất</span>
                                    </th>
                                    <th className="col-4 border-bottom-0">
                                        <span className="sub-text fw-bold text-info text-uppercase">Mô Tả</span>
                                    </th>
                                    <th className="col-2 border-bottom-0">
                                        <span className="sub-text fw-bold text-info text-uppercase">Thao tác</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.length > 0 ? (
                                    data?.map((item, index) => {
                                        return (
                                            <tr className="d-flex" key={index}>
                                                <td className="col-1">{index + 1}</td>
                                                <td className="col-1">
                                                    <div className="custom-control custom-control-sm custom-checkbox notext">
                                                        <input
                                                            type="radio"
                                                            className="custom-control-input"
                                                            defaultChecked={item.checked ? item.checked : false}
                                                            name="name"
                                                            id={item.id + 'uiddd'}
                                                            // key={Math.random()}
                                                            onChange={(e) => onSelectChangeSV(e, item.id)}
                                                        />

                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor={item.id + 'uiddd'}
                                                        ></label>
                                                    </div>
                                                </td>
                                                <td className="col-4">
                                                    <span className="tb-amount"> {item?.Name} </span>
                                                </td>
                                                <td className="col-4">
                                                    <span className="tb-amount"> {item?.Description} </span>
                                                </td>
                                                <td className="col-2">
                                                    <button
                                                        className={'ms-0 badge text-white bg-success'}
                                                        onClick={() => handleEditLoaiDexuat(item)}
                                                    >
                                                        Sửa
                                                    </button>
                                                    <button
                                                        className={'ms-0 badge text-white bg-danger'}
                                                        onClick={() => handleDeleteType(item.id)}
                                                    >
                                                        Xóa
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <div className="text-center">
                                        <div>
                                            <Spinner color="primary" />
                                        </div>
                                        <span className="text-silent">Chưa có dữ liệu!</span>
                                    </div>
                                )}
                            </tbody>
                        </table>
                        <div className="card-title-group mt-2">
                            {dataCkb.length > 0 && (
                                <button className="btn btn-primary px-5" onClick={handleSubmit}>
                                    Chọn kiểu đề xuất
                                </button>
                            )}
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default ModalSelectDexuat;
