export const validateTaiKhoanNganHang = (fieldName, value) => {
    let regNumber = /^[0-9\b]+$/;
    switch (fieldName) {
        case 'tennganhang':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Tên ngân hàng không được để trống'
                }
            }
            break;
        case 'sotaikhoan':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Số tài khoản không được để trống'
                }
            }
            break;
        case 'nguoihuongthu':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Người hưởng thụ không được để trống'
                }
            }
            break;
        
        
        default:
            break;
    }
}