import actionTypes from './actionTypes'
import { apiGetBocauhoiphongvan, apiNewBocauhoiphongvan, apiEditBocauhoiphongvan, apiDeleteBocauhoiphongvan } from '~/services/bocauhoiphongvan'
import { toast } from "react-toastify";
export const getListBoCauHoiPhongVan = () => async (dispatch) => {
    try {
        const response = await apiGetBocauhoiphongvan()
        // console.log('chek api lisst Bocauhoiphongvan', response?.data.success)
        // return 'chek api lisst Bocauhoiphongvan';
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_BO_CAU_HOI_PHONG_VAN,
                bocauhoiphongvan: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_BO_CAU_HOI_PHONG_VAN,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_BO_CAU_HOI_PHONG_VAN,
            bocauhoiphongvan: null
        })
    }
}

export const NewBocauhoiphongvan = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewBocauhoiphongvan', payload)
            const response = await apiNewBocauhoiphongvan(payload)
            // console.log('chek api apiNewBocauhoiphongvan', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_BO_CAU_HOI_PHONG_VAN,
                    msg: 'OK'
                });
                dispatch(getListBoCauHoiPhongVan());
                toast.success("Thêm mới bộ câu hỏi phỏng vấn thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_BO_CAU_HOI_PHONG_VAN,
                    msg: 'ERR'
                })
                toast.error("Thêm mới bộ câu hỏi phỏng vấn thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_BO_CAU_HOI_PHONG_VAN,
                bocauhoiphongvan: null
            })
        }
    }
}
export const EditBocauhoiphongvan = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditBocauhoiphongvan(payload)
            // console.log('chek api apiEditBocauhoiphongvan', response)
            if (response?.data.success === 200) {
                dispatch(getListBoCauHoiPhongVan());
                toast.success("Sửa thông tin bộ câu hỏi phỏng vấn thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_BO_CAU_HOI_PHONG_VAN,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_BO_CAU_HOI_PHONG_VAN,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin bộ câu hỏi phỏng vấn thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_BO_CAU_HOI_PHONG_VAN,
                bocauhoiphongvan: null
            })
        }
    }
}
export const DeleteBocauhoiphongvan = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteBocauhoiphongvan(payload)
            // console.log('chek api apiDeleteBocauhoiphongvan', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListBoCauHoiPhongVan());
                toast.success("Xóa thông tin bộ câu hỏi phỏng vấn thành công!")
                dispatch({
                    type: actionTypes.DELETE_BO_CAU_HOI_PHONG_VAN,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_BO_CAU_HOI_PHONG_VAN,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin bộ câu hỏi phỏng vấn thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_BO_CAU_HOI_PHONG_VAN,
                bocauhoiphongvan: null
            })
        }
    }
}