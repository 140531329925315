import actionTypes from './actionTypes'
import { apiGetQuydinhthuongphat, apiNewQuydinhthuongphat, apiEditQuydinhthuongphat, apiDeleteQuydinhthuongphat } from '~/services/quydinhthuongphat'
import { toast } from "react-toastify";
export const getListQuydinhthuongphat = () => async (dispatch) => {
    try {
        const response = await apiGetQuydinhthuongphat()
        // console.log('chek api lisst Quydinhthuongphat', response?.data.success)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_QUY_DINH_THUONG_PHAT,
                quydinhthuongphat: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_QUY_DINH_THUONG_PHAT,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_QUY_DINH_THUONG_PHAT,
            quydinhthuongphat: null
        })
    }
}

export const NewQuydinhthuongphat = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewQuydinhthuongphat', payload)
            const response = await apiNewQuydinhthuongphat(payload)
            // console.log('chek api apiNewQuydinhthuongphat', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_QUY_DINH_THUONG_PHAT,
                    msg: 'OK'
                });
                dispatch(getListQuydinhthuongphat());
                toast.success("Thêm mới quy định thưởng phạt thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_QUY_DINH_THUONG_PHAT,
                    msg: 'ERR'
                })
                toast.error("Thêm mới quy định thưởng phạt thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_QUY_DINH_THUONG_PHAT,
                quydinhthuongphat: null
            })
        }
    }
}
export const EditQuydinhthuongphat = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditQuydinhthuongphat(payload)
            // console.log('chek api apiEditQuydinhthuongphat', response)
            if (response?.data.success === 200) {
                dispatch(getListQuydinhthuongphat());
                toast.success("Sửa thông tin quy định thưởng phạt thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_QUY_DINH_THUONG_PHAT,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_QUY_DINH_THUONG_PHAT,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin quy định thưởng phạt thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_QUY_DINH_THUONG_PHAT,
                quydinhthuongphat: null
            })
        }
    }
}
export const DeleteQuydinhthuongphat = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteQuydinhthuongphat(payload)
            // console.log('chek api apiDeleteQuydinhthuongphat', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListQuydinhthuongphat());
                toast.success("Xóa thông tin quy định thưởng phạt thành công!")
                dispatch({
                    type: actionTypes.DELETE_QUY_DINH_THUONG_PHAT,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_QUY_DINH_THUONG_PHAT,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin quy định thưởng phạt thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_QUY_DINH_THUONG_PHAT,
                quydinhthuongphat: null
            })
        }
    }
}