import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '~/store/actions';
import Content from '~/layout/content/Content';
import Head from '~/layout/head/Head';
import KanbanBoard from './KanbanBoard';
import { columnData } from './KanbanData';
import { Modal, Card } from 'reactstrap';
import { DataTable, BlockBetween, BlockHeadContent, BlockTitle, Button, Icon, Block } from '~/components/Component';
import { KanbanBoardForm, KanbanTaskForm } from './KanbanForms';
import { Link } from 'react-router-dom';
import config from '~/config';
import { MenuGiaoViecBang } from '../MenuTab/Menucontent';

const Kanban = () => {
    const dispatch = useDispatch();
    const { giaoviec, giaoviecbangone, giaoviecbangonecolumn } = useSelector((state) => state.giaoviec);
    // console.log('giaoviecbangvvv', giaoviecbangone)
    const [columns, setColumns] = useState(columnData);
    // console.log('columns', columns);
    useEffect(() => {
        // const fetchData = async () => {
        //     const response = await apiGetGiaoviecbang();
        //     const object = {};
        //     const arays = [];
        //     response?.data?.data?.rows.forEach((item, index) => {
        //         item.Giaoviec = item.Giaoviec === null ? [] : item.Giaoviec;
        //         item.id = item.id.toString();
        //         object[index] = item;
        //         arays.push(index.toString());
        //     });
        //     const response1 = await apiGetGiaoviec();
        //     const object1 = {};
        //     response1?.data?.data?.rows.forEach((item, index) => {
        //         item.id = item.id.toString();
        //         object1[index] = item;
        //     });
        //     setColumns((prev) => ({ ...prev, ['columns']: object }));
        //     setColumns((prev) => ({ ...prev, ['columnOrder']: arays }));
        //     setColumns((prev) => ({ ...prev, ['taskv']: object1 }));
        // };
        // fetchData().catch(console.error);
        dispatch(actions.getListGiaoviec());
        dispatch(actions.getListGiaoviecbangone());
    }, []);
    useEffect(() => {
        if (giaoviec && giaoviecbangone && giaoviecbangonecolumn) {
            // const object = {};
            // console.log('giaoviecbangone', giaoviecbangone);
            // console.log('giaoviecbangonecolumn', giaoviecbangonecolumn);
            // console.log('Object.keys(giaoviecbangone)', Object.keys(giaoviecbangone));
            // const values = Object.values(giaoviecbangone);
            // values.sort((a, b) => b - a);
            // console.log('values', values);
            // const arays = [];
            // values?.map((item, index) => {
            //     arays.push(item.id);
            // });

            //  arays.sort lấy theo thứ tự từ bé đến lớn
            // arays.sort((a, b) => parseInt(a) - parseInt(b));
            //  arays.sort lấy theo thứ tự từ lớn đến bé
            // arays.sort((a, b) => parseInt(b) - parseInt(a));
            // console.log(
            //     'arays',
            //     arays?.sort((a, b) => parseInt(b) - parseInt(a)),
            // );
            const object1 = {};
            giaoviec.forEach((item) => {
                item.id = `${item.id}`;
                object1[`${item.id}`] = item;
            });
            // console.log('object1',object1 )
            setColumns((prev) => ({ ...prev, ['columns']: giaoviecbangone }));
            setColumns((prev) => ({ ...prev, ['columnOrder']: giaoviecbangonecolumn }));
            setColumns((prev) => ({ ...prev, ['task']: object1 }));
        }
    }, [giaoviec, giaoviecbangone, giaoviecbangonecolumn]);
    const [boardModal, setBoardModal] = useState(false);
    const [smBtn, setSmBtn] = useState(false);
    const [taskModal, setTaskModal] = useState(false);

    const toggleBoardModal = () => {
        setBoardModal(!boardModal);
    };

    const toggleTaskModal = () => {
        setTaskModal(!taskModal);
    };

    return (
        <React.Fragment>
            <Head title="Bảng giao việc"></Head>
            <Content>
                {/* <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page>Bảng giao việc</BlockTitle>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <a
                                    href="#toggle"
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                        setSmBtn(!smBtn);
                                    }}
                                    className="btn btn-icon btn-trigger toggle-expand me-n1"
                                >
                                    <Icon name="menu-alt-r"></Icon>
                                </a>
                                <div className={`toggle-expand-content ${smBtn ? 'expanded' : ''}`}>
                                    <ul className="nk-block-tools g-3">
                                        <li>
                                            <Button
                                                color="light"
                                                outline
                                                className="btn-white"
                                                onClick={() => toggleTaskModal()}
                                            >
                                                <Icon name="plus" />
                                                <span>Thêm nhiệm vụ</span>
                                            </Button>
                                        </li>
                                        <li>
                                            <Button color="primary" onClick={() => toggleBoardModal()}>
                                                <Icon name="plus" />
                                                <span>Thêm bảng</span>
                                            </Button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead> */}
                {/* <Card className="mb-5">
                    <div className="card-aside-wrap">
                        <div className="card-inner card-inner-lg px-4 py-2">
                            <h4>Giao việc vvvvvvvvvvv</h4>
                            <MenuGiaoViecBang/>
                        </div>
                    </div>
                </Card> */}

                <Block>
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner position-relative card-tools-toggle pt-0">
                            <div className="card-title-group py-4 row ">
                                <div className="card-title col-7">
                                    <h4 className="title">Danh sách</h4>
                                </div>
                                <div className="card-tools col-5">
                                    <div className="float-end toggle-wrap nk-block-tools-toggle">
                                        <a
                                            href="#toggle"
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                setSmBtn(!smBtn);
                                            }}
                                            className="btn btn-icon btn-trigger toggle-expand me-n1"
                                        >
                                            <Icon name="menu-alt-r"></Icon>
                                        </a>
                                        <div className={`toggle-expand-content   ${smBtn ? 'expanded' : ''}`}>
                                            <ul className="nk-block-tools g-3">
                                                <li>
                                                    <Button
                                                        color="light"
                                                        outline
                                                        className="btn-white"
                                                        onClick={() => toggleTaskModal()}
                                                    >
                                                        <Icon name="plus" />
                                                        <span>Thêm nhiệm vụ</span>
                                                    </Button>
                                                </li>
                                                <li>
                                                    <Button color="primary" onClick={() => toggleBoardModal()}>
                                                        <Icon name="plus" />
                                                        <span>Thêm bảng</span>
                                                    </Button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <MenuGiaoViecBang/>
                                </div>
                            </div>
                        </div>
                    </DataTable>
                </Block>
                <Block>
                    <div className="nk-kanban">
                        <KanbanBoard columns={columns} setColumns={setColumns} />
                    </div>
                </Block>

                <Modal size="lg" isOpen={boardModal} toggle={toggleBoardModal}>
                    <KanbanBoardForm toggle={toggleBoardModal} data={columns} setData={setColumns} />
                </Modal>

                <Modal
                    className="modal-dialog-centered modal-90w"
                    size="lg"
                    isOpen={taskModal}
                    toggle={toggleTaskModal}
                >
                    <KanbanTaskForm toggle={toggleTaskModal} data={columns} setData={setColumns} />
                </Modal>
            </Content>
        </React.Fragment>
    );
};

export default Kanban;
