export const validateTangCaNhanVien = (fieldName, value) => {
    switch (fieldName) {
        case 'ngaybatdau':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Ngày bắt đầu không được để trống'
                }
            }
            break;
        case 'ngayketthuc':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Ngày kết thúc không được để trống.'
                }
            }
            break;
        
        case 'TrangthaiId':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Trạng thái không được bỏ trống.'
                }
            }
            break;
            case 'CauhinhtangcaId':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Hình thức tăng ca không bỏ trống.'
                }
            }
            break;
        default:
            break;
    }
}