import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputForm, Button } from '~/components/Form';
import * as actions from '~/store/actions';
import { validateTaiSan } from '~/request/validateTaiSan';
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { nhomTaisan, trangthaiTaisan } from '~/utils/Datacustom';
import { apiGetTaisan } from '~/services';
import { matusinhResult } from '~/utils/Utils';
import { Input } from 'reactstrap';
import { NumericFormat } from 'react-number-format';
const ModalTaisan = ({ show, setShow, fieldList }) => {
    const dispatch = useDispatch();
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayloadTaisan] = useState(fieldList);
    // console.log('payload', payload);
    useEffect(() => {
        show && setPayloadTaisan(fieldList);
        // lấy tất cả các Mã tài sản về để check sinh ra mã mới k trùng
        const fetchData = async () => {
            const taisanAll = await apiGetTaisan();
            let dataTaisan = taisanAll?.data?.data?.rows?.map((item) => ({ matusinh: item.mataisan }));
            setPayloadTaisan((prev) => ({
                ...prev,
                ['mataisan']: matusinhResult(dataTaisan && dataTaisan.length > 0 ? dataTaisan : [{}], 'MTS'),
            }));
        };
        fetchData().catch(console.error);
    }, [show]);

    const [listTrangthai, setlistTrangthai] = useState([]);
    const [listNhomTaisan, setNhomTaisan] = useState([]);
    useEffect(() => {
        let strangthaiTaisan = trangthaiTaisan.map((item) => ({ label: item.tentrangthai, value: item.id }));
        let snhomTaisan = nhomTaisan.map((item) => ({ label: item.tenNhomtaisan, value: item.id }));
        setlistTrangthai(strangthaiTaisan);
        setNhomTaisan(snhomTaisan);
    }, []);

    const handleSubmit = async () => {
        let invalids = validate(payload);
        if (invalids === 0) {
            let datapayload = {
                data: [
                    {
                        mataisan: payload.mataisan,
                        tentaisan: payload.tentaisan,
                        soluong: payload.soluong,
                        soluongconlai: payload.soluong,
                        NhomtaisanId: payload.NhomtaisanId.value,
                        donvitaisan: payload.donvitaisan,
                        doi: payload.doi,
                        vitritaisan: payload.vitritaisan,
                        ngaymua: payload.ngaymua,
                        khauhao: payload.khauhao,
                        thoihanbaohanh: payload.thoihanbaohanh,
                        giatri: payload.giatri,
                        nhacungcap: payload.nhacungcap,
                        sodienthoai: payload.sodienthoai,
                        diachi: payload.diachi,
                        ghichu: payload.ghichu,
                        TrangthaiId: payload.TrangthaiId.value,
                    },
                ],
            };
            // console.log('check payload', datapayload)
            dispatch(actions.NewTaisan(datapayload));
        }
    };
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayloadTaisan((prev) => ({ ...prev, [statename]: selected }));
    };

    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateTaiSan(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateTaiSan(item[0], item[1])]);
                invalids++;
            }
        });
        return invalids;
    };
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">Thêm tài sản</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-4">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Tên tài sản'}
                            value={payload.tentaisan}
                            setValue={setPayloadTaisan}
                            keyPayload={'tentaisan'}
                            placeholder={'Tên tài sản'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-4 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Mã tài sản'}
                            placeholder={'Mã tài sản'}
                            value={payload.mataisan}
                            setValue={setPayloadTaisan}
                            keyPayload={'mataisan'}
                            type="text"
                            disabled={true}
                            validate
                        />
                    </div>
                    <div className="form-group col-md-4">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Số lượng'}
                            placeholder={'Số lượng'}
                            value={payload.soluong}
                            setValue={setPayloadTaisan}
                            keyPayload={'soluong'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-4 ">
                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                                Nhóm tài sản <b className="text-danger">(*)</b>
                            </span>
                        </label>
                        <Select
                            value={payload.NhomtaisanId}
                            onChange={handleOnSelect}
                            options={listNhomTaisan}
                            placeholder={'Chọn nhóm'}
                            name="NhomtaisanId"
                            onFocus={() => setInvalidFields([])}
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'NhomtaisanId') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'NhomtaisanId')?.message}
                            </small>
                        )}
                    </div>
                    <div className="form-group col-md-4 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Đơn vị tài sản'}
                            placeholder={'Đơn vị tài sản'}
                            value={payload.donvitaisan}
                            setValue={setPayloadTaisan}
                            keyPayload={'donvitaisan'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-4">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Model'}
                            placeholder={'Model'}
                            value={payload.doi}
                            setValue={setPayloadTaisan}
                            keyPayload={'doi'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-4 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Vị trí tài sản'}
                            placeholder={'Vị trí tài sản'}
                            value={payload.vitritaisan}
                            setValue={setPayloadTaisan}
                            keyPayload={'vitritaisan'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-4">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ngày mua'}
                            placeholder={'Ngày mua'}
                            value={payload.ngaymua}
                            setValue={setPayloadTaisan}
                            keyPayload={'ngaymua'}
                            type="date"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-4 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Thời gian bảo hành (tháng)'}
                            placeholder={'Thời gian bảo hành'}
                            value={payload.thoihanbaohanh}
                            setValue={setPayloadTaisan}
                            keyPayload={'thoihanbaohanh'}
                            type="number"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-4">
                        <div className="form-group">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                <span>
                                    Giá trị <b className="text-danger">(*)</b>
                                </span>
                            </label>
                            <div className="form-control-wrap">
                                <div className="form-text-hint">
                                    <span className="overline-title">VND</span>
                                </div>
                                <NumericFormat
                                    className="form-control px-2"
                                    value={payload.giatriformat}
                                    // displayType={'text'}
                                    thousandSeparator={true}
                                    // suffix={'đ'}
                                    name="giatri"
                                    onValueChange={(values) => {
                                        const { formattedValue, value } = values;
                                        setPayloadTaisan((prev) => ({ ...prev, ['giatri']: value }));
                                        setPayloadTaisan((prev) => ({ ...prev, ['giatriformat']: formattedValue }));
                                    }}
                                />
                                {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'giatri') && (
                                    <small className="text-danger">
                                        {invalidFields.find((i) => i.name === 'giatri')?.message}
                                    </small>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-4 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Khấu hao (tháng)'}
                            placeholder={'Khấu hao'}
                            value={payload.khauhao}
                            setValue={setPayloadTaisan}
                            keyPayload={'khauhao'}
                            type="number"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-4 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Nhà cung cấp'}
                            placeholder={'Nhà cung cấp'}
                            value={payload.nhacungcap}
                            setValue={setPayloadTaisan}
                            keyPayload={'nhacungcap'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-4">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Số điện thoại NCC'}
                            placeholder={'Số điện thoại NCC'}
                            value={payload.sodienthoai}
                            setValue={setPayloadTaisan}
                            keyPayload={'sodienthoai'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-4 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Địa chỉ NCC'}
                            placeholder={'Địa chỉ NCC'}
                            value={payload.diachi}
                            setValue={setPayloadTaisan}
                            keyPayload={'diachi'}
                            type="text"
                            validate
                        />
                    </div>

                    <div className="form-group col-md-4">
                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                                Trạng thái tài sản <b className="text-danger">(*)</b>
                            </span>
                        </label>
                        <Select
                            value={payload.TrangthaiId}
                            onChange={handleOnSelect}
                            options={listTrangthai}
                            placeholder={'Trạng thái'}
                            name="TrangthaiId"
                            onFocus={() => setInvalidFields([])}
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'TrangthaiId') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'TrangthaiId')?.message}
                            </small>
                        )}
                    </div>

                    <div className="form-group col-md-12 mb-2">
                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                                Ghi chú <b className="text-danger">(*)</b>
                            </span>
                        </label>
                        <Input
                            value={payload.ghichu}
                            onChange={(e) => setPayloadTaisan((prev) => ({ ...prev, ['ghichu']: e.target.value }))}
                            rows={'2'}
                            name="ghichu"
                            type="textarea"
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'ghichu') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'ghichu')?.message}
                            </small>
                        )}
                    </div>
                </div>

                <div className="col-12 mt-5">
                    <Button text="Cập nhật" bClass="btn btn-primary px-5" textColor onClick={handleSubmit} />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalTaisan;
