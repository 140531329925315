import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '~/store/actions';
// import { ModalNhanvien } from '../Admin/Modal';
import Swal from 'sweetalert2';
import Content from '~/layout/content/Content';
import Head from '~/layout/head/Head';
import { DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledDropdown } from 'reactstrap';
import {
    Block,
    BlockBetween,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Button,
    Col,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    ExportXlsx,
    Icon,
    ImportXlsx,
    PaginationCount,
    PermissionUtils,
    Row,
    RSelect,
    TooltipComponent,
} from '~/components/Component';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ListTabs, ListTabsInOne, ListTabsInTwo } from '~/components/block/Block';
import config from '~/config';
import {dataKieuPhepNhanVien, dataSelectGioitinh, dataSelectHocVan, dataSelectKieuPhep} from "~/utils/Datacustom";
import {format, parseISO} from "date-fns";
import { apiGetNhanvien } from '~/services';
import {MenuNhanVien} from './MenuTab/Menucontent';

const Nhanvien = () => {
    const dispatch = useDispatch();
    const [msg, setMsg] = useState(null);
    // const [show, setShow] = useState(false);
    const { chinhanh } = useSelector((state) => state.chinhanh);
    const { phongban } = useSelector((state) => state.phongban);
    const { chucvu } = useSelector((state) => state.chucvu);
    const { kieuluong } = useSelector((state) => state.kieuluong);
    const { maychamcong } = useSelector((state) => state.maychamcong);
    const { congthuctinhluong } = useSelector((state) => state.congthuctinhluong);
    // const { cauhinhcalamviec } = useSelector((state) => state.cauhinhcalamviec);
    const fieldList = {
        hoten: '',
        dienthoai: '',
        email: '',
        ChinhanhId: '',
        PhongbanId: '',
        ChucvuId: '',
    };
    const headerExport = ['Họ tên(*)','Ngày sinh','Giới tính',
        'Email(*)','Điện thoại(*)','Quốc tịch','Nơi sinh','Tỉnh thành','Quận/Huyện','Phường/Xã','MST cá nhân','Dân tộc','Địa chỉ hiện tại',
        'Số CMND/CCCD(*)','Ngày cấp','Nơi cấp',
        // 'Máy chấm công',
        'Người LH khẩn cấp','Số điện thoại LH khẩn cấp','Quan hệ với nhân viên','Địa chỉ khẩn cấp',
        'Chi nhánh(*)','Phòng ban(*)','Chức vụ(*)','Kiểu lương(*)','Mức lương(*)','Ngày vào làm(*)','Công thức tính lương(*)','Kiểu phép(*)','Số ngày phép','Tồn phép cũ',
        //'Học vấn','TK Ngân hàng','Tên Ngân hàng','Người thụ hưởng ','Mã chấm công NV',
        'Trạng thái'];

    const { nhanvien, count } = useSelector((state) => state.nhanvien);
    // console.log('count', count)
    // console.log('nhanvien', nhanvien)
    const [dataExport, setDataExport] = useState([]);
    useEffect(() => {
        nhanvien && setData(nhanvien);
        if (phongban.length && chinhanh.length && chucvu.length && kieuluong.length && maychamcong.length) {
            setDataExport({
                phongban: phongban,
                chinhanh: chinhanh,
                chucvu: chucvu,
                kieuluong: kieuluong,
                congthuctinhluong: congthuctinhluong,
                maychamcong: maychamcong,
                gioitinh: dataSelectGioitinh,
                hocvan: dataSelectHocVan,
                kieuphep: dataKieuPhepNhanVien,
            });
        }
        phongban &&
            phongban.length > 0 &&
            setDataPhongban(phongban.map((item) => ({ label: item.phongban, value: item.id })));
        chucvu && chucvu.length > 0 && setDataChucvu(chucvu.map((item) => ({ label: item.chucvu, value: item.id })));
    }, [nhanvien, phongban, chinhanh, chucvu,kieuluong,maychamcong,congthuctinhluong]);
    // console.log('dataExport', dataExport)
    const [data, setData] = useState(nhanvien);
    const [dataPhongban, setDataPhongban] = useState(phongban);
    const [dataChucvu, setDataChucvu] = useState(chucvu);
    const [showPhanquyen, setShowPhanquyen] = useState({});
    let dataPermissionUtils = PermissionUtils('nhanvien', 'all');
    useEffect(() => {
        if (dataPermissionUtils) {
            setShowPhanquyen(dataPermissionUtils);
        }
    }, [dataPermissionUtils]);

    const [sm, updateSm] = useState(false);
    const [smCloud, updateSmCloud] = useState(false);
    const [tablesm, updateTableSm] = useState(false);
    const [Page, setPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [sort, setSortState] = useState('desc');
    // console.log('Page', Page)
    // Xóa params
    const navigate = useNavigate();
    const location = useLocation();
    function handleReParamsClick() {
        navigate(location.pathname);
    }
    // end Xóa params
    const [searchParams] = useSearchParams();
    useEffect(() => {
        const params = [];
        for (let entry of searchParams.entries()) {
            params.push(entry);
        }
        if (params.length !== 0) {
            let searchParamsObject = {
                limit: itemPerPage,
                order: sort,
                by: 'id',
            };
            params?.forEach((i) => {
                if (Object.keys(searchParamsObject).some((item) => item === i[0])) {
                    searchParamsObject[i[0]] = [...searchParamsObject[i[0]], i[1]];
                } else {
                    searchParamsObject[i[0]] = i[1];
                }
            });
            // console.log('searchParamsObject', searchParamsObject);
            setPage(searchParamsObject.page);
            dispatch(actions.getListNhanvien(searchParamsObject));
            dispatch(actions.getListCongthuctinhluong());
        } else {
            let searchParamsObject = { page: '1', limit: itemPerPage, order: sort, by: 'id' };
            dispatch(actions.getListCongthuctinhluong());
            // console.log('searchParamsObject', searchParamsObject);
            setPage(1);
            dispatch(actions.getListNhanvien(searchParamsObject));
        }
    }, [searchParams, itemPerPage, sort]);

    const [payload, setPayload] = useState({ chucvu: '', phongban: '' });
    // console.log('payload', payload);
    const onFilter = () => {
        const queryParams = [];
        if (payload.chucvu !== '') {
            queryParams.push(`chucvu=${payload.chucvu.value}`);
        }
        if (payload.phongban !== '') {
            queryParams.push(`phongban=${payload.phongban.value}`);
        }
        if (queryParams.length > 0) {
            const queryString = queryParams.join('&');
            navigate(`${location.pathname}?page=1&${queryString}`);
        }
    };

    const onFilterChange = (e) => {
        setSearchText(e.target.value);
    };

    const toggle = () => setonSearch(!onSearch);
    const [onSearch, setonSearch] = useState(true);
    const [allNhanVien, setDataAllNV] = useState([]);
    // console.log('allNhanVien', allNhanVien)
    const [onSearchText, setSearchText] = useState('');
    useEffect(() => {
        if(onSearch === false){
            const fetchData = async () => {
                try {
                    const response = await apiGetNhanvien();
                    // console.log('response', response)
                    setDataAllNV(response?.data?.data?.rows);
                } catch (error) {
                    console.error(error);
                }
            };
            fetchData().catch(console.error);
        }else{
            setSearchText('');
        }
        if (onSearchText !== '') {

            const filteredObject = allNhanVien.length > 0 && allNhanVien.filter((item) => {
                return (
                    item.hoten.toLowerCase().includes(onSearchText.toLowerCase()) ||
                    item.uuid.toLowerCase().includes(onSearchText.toLowerCase())
                );
            });
            setData([...filteredObject]);
        } else {
            setData([...nhanvien]);
        }

    }, [onSearch, onSearchText, setData]);

    // console.log('data', data)
    const namefile = 'Thông tin nhân viên';
    const header = [
        'Stt',
        'Họ tên',
        'Mã nhân viên',
        'Email',
        'Số điện thoại',
        'Ngày sinh',
        'Giới tính',
        'Chức vụ',
        'Chi nhánh',
        'Phòng ban',
        'Lương offer',
        'Kiểu lương',
        'Mức lương',
        'Ngày vào làm việc',
        'Địa chỉ thường trú',
        'Số CMND',
        'Ngày cấp',
        'Nơi cấp',
        'Tỉnh thành',
        'Quận/Huyện',
        'Phường/Xã',
    ];
    const [columns, setColumns] = useState(() => {
        const d = header.map((item, index) => index <= 9);
        return d;
    });
    //
    // useEffect(() => {
    //     const trueCount = columns.filter((value) => value === true).length;
    //     if (trueCount >= 1) {
    //         setWindowSize(true);
    //     } else {
    //         setWindowSize(false);
    //     }
    //   }, [columns]);

    const [dataFileMau, setDataFileMau] = useState([]);
    useEffect(() => {
        if (data.length > 0) {
            const datafilemau = data.map((item, index) => {
                const phucapnhanviensArray = Array.isArray(item?.Phucapnhanviens) ? item.Phucapnhanviens : [];
                const tongGiaTri = phucapnhanviensArray.reduce((tong, item) => tong + (item?.giatri || 0), 0);
                const luongOf = (item?.mucluong || 0) + tongGiaTri;
                return {
                    ...(columns[0] && { Stt: index + 1 }),
                    ...(columns[1] && { 'Họ tên': item?.hoten }),
                    ...(columns[2] && { 'Mã nhân viên': item?.uuid }),
                    ...(columns[3] && { Email: item?.email ? item?.email : 'NAV' }),
                    ...(columns[4] && {
                        'Số điện thoại': item?.Thongtinnhanvien?.dienthoai ? item?.Thongtinnhanvien?.dienthoai : 'NAV',
                    }),
                    ...(columns[5] && {
                        'Ngày sinh': item?.Thongtinnhanvien?.ngaysinh ? item?.Thongtinnhanvien?.ngaysinh : 'NAV',
                    }),
                    ...(columns[6] && {
                        'Giới tính': item?.GioitinhId === 0 ? 'Nam' : item?.GioitinhId === 1 ? 'Nữ' : 'Khác',
                    }),
                    ...(columns[7] && { 'Chức vụ': item?.Chucvu?.chucvu ? item?.Chucvu?.chucvu : 'NAV' }),
                    ...(columns[8] && { 'Chi nhánh': item?.Chinhanh?.diachi ? item?.Chinhanh?.diachi : 'NAV' }),
                    ...(columns[9] && { 'Phòng ban': item?.Phongban?.phongban ? item?.Phongban?.phongban : 'NAV' }),
                    ...(columns[10] && { 'Lương offer': luongOf }),
                    ...(columns[11] && { 'Kiểu lương': item?.Kieuluong?.tieude ? item?.Kieuluong?.tieude : 'NAV' }),
                    ...(columns[12] && { 'Mức lương': item?.mucluong ? item?.mucluong : 'NAV' }),
                    ...(columns[13] && { 'Ngày vào làm việc': item?.ngayvaolamviec ? item?.ngayvaolamviec : 'NAV' }),
                    ...(columns[14] && {
                        'Địa chỉ thường trú': item?.Thongtinnhanvien?.diachihientai
                            ? item?.Thongtinnhanvien?.diachihientai
                            : 'NAV',
                    }),
                    ...(columns[15] && {
                        'Số CMND': item?.Thongtinnhanvien?.socmnd ? item?.Thongtinnhanvien?.socmnd : 'NAV',
                    }),
                    ...(columns[16] && {
                        'Ngày cấp': item?.Thongtinnhanvien?.ngaycap ? item?.Thongtinnhanvien?.ngaycap : 'NAV',
                    }),
                    ...(columns[17] && {
                        'Nơi cấp': item?.Thongtinnhanvien?.noicap ? item?.Thongtinnhanvien?.noicap : 'NAV',
                    }),
                    ...(columns[18] && {
                        'Tỉnh thành': item?.Thongtinnhanvien?.Province?.name? item?.Thongtinnhanvien?.Province?.name : 'NAV',
                    }),
                    ...(columns[19] && {
                        'Quận/Huyện': item?.Thongtinnhanvien?.District?.name? item?.Thongtinnhanvien?.District?.name : 'NAV',
                    }),
                    ...(columns[20] && {
                        'Phường/Xã': item?.Thongtinnhanvien?.Xa_phuong?.name ? item?.Thongtinnhanvien?.Xa_phuong?.name : 'NAV',
                    }),
                };
            });
            setDataFileMau(datafilemau);
        }
    }, [data, columns]);
    // useEffect(() => {
    //     nhanvien && setShow(false);
    // }, [nhanvien]);
    // set state để mở đóng Modal
    // const handleAddNewNhanvien = async () => {
    //     setShow(true);
    // };

    const handleDeleteNhanvien = (data) => {
        Swal.fire({
            title: 'Bạn có chắc không?',
            text: 'Bạn sẽ không thể lấy lại khi xóa!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Không, bỏ qua!',
            confirmButtonText: 'Vâng, xóa nó đi!',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(actions.DeleteNhanvien(data));
            }
        });
    };
    //
    // const [windowSize] = useState(true);
   
    // console.log('window.innerWidth', window.innerWidth)
    // console.log('window.windowSize', windowSize)
    const itemsPerPageOptions = [10, 20, 30, 40, 50, 100, 300, 500];
    return (
        <React.Fragment>
            <Head title="User List - Regular"></Head>
            <Content>
                <BlockHead size="sm mt-3">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                Quản lý hồ sơ nhân viên
                            </BlockTitle>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <Button
                                    className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? 'active' : ''}`}
                                    onClick={() => updateSm(!sm)}
                                >
                                    <Icon name="plus"></Icon>
                                </Button>
                                <div className="toggle-expand-content" style={{ display: sm ? 'block' : 'none' }}>
                                    <ul className="nk-block-tools g-3">
                                        {+showPhanquyen?.them === 1 && (
                                            <>
                                                <li className="nk-block-tools-opt">
                                                    <Link to={`${config.he_thong}${config.them_moi_nhan_vien}`}>
                                                        <Button
                                                            color="primary"
                                                            className="btn-icon px-1"
                                                        >
                                                            <Icon name="plus"></Icon> Thêm mới nhân viên
                                                        </Button>
                                                    </Link>
                                                </li>
                                                <li className="nk-block-tools-opt">
                                                    <Link
                                                        className="nav-link"
                                                        to={`${config.he_thong}${config.nhom_nhan_vien}`}
                                                    >
                                                        <Button color="success" className="btn-icon px-1">
                                                            <Icon name="plus"></Icon> Nhóm nhân viên
                                                        </Button>
                                                    </Link>
                                                </li>
                                            </>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </BlockHeadContent>
                    </BlockBetween>

                    <MenuNhanVien/>

                </BlockHead>
                <Block>
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner position-relative card-tools-toggle">
                            <div className="card-title-group">
                                <div className="card-title">
                                    <h5 className="title">Danh sách nhân viên</h5>
                                </div>

                                <div className="card-tools me-n1">
                                    {/*<ul className="btn-toolbar gx-1">*/}
                                    <ListTabs>
                                        <li>
                                            <a
                                                href="#search"
                                                className="btn btn-icon search-toggle toggle-search"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    toggle();
                                                }}
                                            >
                                                <Icon name="search"></Icon>
                                            </a>
                                        </li>
                                        <li className="btn-toolbar-sep"></li>
                                        <li>
                                            <div className="toggle-wrap">
                                                <Button
                                                    className={`btn-icon btn-trigger toggle ${tablesm ? 'active' : ''}`}
                                                    onClick={() => updateTableSm(true)}
                                                >
                                                    <Icon name="menu-right"></Icon>
                                                </Button>
                                                <div className={`toggle-content ${tablesm ? 'content-active' : ''}`}>
                                                    {/*<ul className="btn-toolbar gx-1">*/}
                                                    <ListTabsInOne>
                                                        <li className="toggle-close">
                                                            <Button
                                                                className="btn-icon btn-trigger toggle"
                                                                onClick={() => updateTableSm(false)}
                                                            >
                                                                <Icon name="arrow-left"></Icon>
                                                            </Button>
                                                        </li>
                                                        <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle
                                                                    tag="a"
                                                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                                                >
                                                                    <div className="dot dot-primary"></div>
                                                                    <Icon name="filter-alt"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu
                                                                    end
                                                                    className="filter-wg dropdown-menu-xl"
                                                                    style={{ overflow: 'visible' }}
                                                                >
                                                                    <div className="dropdown-head">
                                                                        <span className="sub-title dropdown-title">
                                                                            Bộ lọc
                                                                        </span>
                                                                        <div className="dropdown">
                                                                            <a
                                                                                href="#more"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                }}
                                                                                className="btn btn-sm btn-icon"
                                                                            >
                                                                                <Icon name="more-h"></Icon>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    <div className="dropdown-body dropdown-body-rg">
                                                                        <Row className="gx-6 gy-3">
                                                                            <Col size="12">
                                                                                <div className="form-group">
                                                                                    <label className="overline-title overline-title-alt">
                                                                                        Chức vụ
                                                                                    </label>
                                                                                    <RSelect
                                                                                        value={payload.chucvu}
                                                                                        options={dataChucvu}
                                                                                        onChange={(selected) =>
                                                                                            setPayload((prev) => ({
                                                                                                ...prev,
                                                                                                ['chucvu']: selected,
                                                                                            }))
                                                                                        }
                                                                                        placeholder="Chọn chức vụ"
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col size="12">
                                                                                <div className="form-group">
                                                                                    <label className="overline-title overline-title-alt">
                                                                                        Phòng ban
                                                                                    </label>
                                                                                    <RSelect
                                                                                        value={payload.phongban}
                                                                                        options={dataPhongban}
                                                                                        onChange={(selected) =>
                                                                                            setPayload((prev) => ({
                                                                                                ...prev,
                                                                                                ['phongban']: selected,
                                                                                            }))
                                                                                        }
                                                                                        placeholder="Chọn phòng ban"
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col size="12 text-end">
                                                                                <div className="form-group">
                                                                                    <Button
                                                                                        color="primary"
                                                                                        className="btn btn-secondary"
                                                                                        onClick={() => onFilter()}
                                                                                    >
                                                                                        Tìm kiếm
                                                                                    </Button>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                    <div className="dropdown-foot between">
                                                                        <a
                                                                            href="#reset"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();
                                                                                handleReParamsClick();
                                                                                setPayload({ chucvu: '', phongban: '' });
                                                                            }}
                                                                            className="clickable"
                                                                        >
                                                                            Đặt lại bộ lọc
                                                                        </a>
                                                                    </div>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                        <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle
                                                                    color="tranparent"
                                                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                                                >
                                                                    <Icon name="setting"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu end className="dropdown-menu-xs">
                                                                    <ListTabsInTwo>
                                                                        <li>
                                                                            <span>Show</span>
                                                                        </li>
                                                                        {itemsPerPageOptions.map((item, index) => {
                                                                            return (
                                                                                <li
                                                                                    className={
                                                                                        itemPerPage === item
                                                                                            ? 'active'
                                                                                            : ''
                                                                                    }
                                                                                    key={index}
                                                                                >
                                                                                    <DropdownItem
                                                                                        tag="a"
                                                                                        href="#dropdownitem"
                                                                                        onClick={(ev) => {
                                                                                            ev.preventDefault();
                                                                                            setItemPerPage(item);
                                                                                            // handleReParamsClick();
                                                                                        }}
                                                                                    >
                                                                                        {item}
                                                                                    </DropdownItem>
                                                                                </li>
                                                                            );
                                                                        })}
                                                                    </ListTabsInTwo>
                                                                    <ListTabsInTwo>
                                                                        <li>
                                                                            <span>Order</span>
                                                                        </li>
                                                                        <li className={sort === 'asc' ? 'active' : ''}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setSortState('asc');
                                                                                    handleReParamsClick();
                                                                                }}
                                                                            >
                                                                                ASC
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li className={sort === 'desc' ? 'active' : ''}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setSortState('desc');
                                                                                    handleReParamsClick();
                                                                                }}
                                                                            >
                                                                                DESC
                                                                            </DropdownItem>
                                                                        </li>
                                                                    </ListTabsInTwo>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                    </ListTabsInOne>
                                                    {/*</ul>*/}
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            {/* <div className=" position-relative card-tools-toggle"> */}
                                                <div className="toggle-wrap nk-block-tools-toggle">
                                                    <Button
                                                        className={`btn-icon btn-trigger toggle-expand me-n1 ${
                                                            smCloud ? 'active' : ''
                                                        }`}
                                                        onClick={() => updateSmCloud(!smCloud)}
                                                    >
                                                        <Icon name="upload-cloud"></Icon>
                                                    </Button>
                                                    <div
                                                        className="toggle-expand-content-cloud"
                                                        style={{ display: smCloud ? 'block' : 'none' }}
                                                    >
                                                        <ul className="nk-block-tools g-3">
                                                            <li className="nk-block-tools-opt">
                                                                <ExportXlsx
                                                                    dataFileMau={dataFileMau}
                                                                    title={header}
                                                                    namefile={namefile}
                                                                    btnname={'Xuất dữ liệu'}
                                                                />
                                                            </li>
                                                            <li className="nk-block-tools-opt">
                                                                <ImportXlsx
                                                                    msg={msg}
                                                                    setMsg={setMsg}
                                                                    type={'nhanvien'}
                                                                    fieldList={fieldList}
                                                                    dataExport={dataExport}
                                                                    dataFileMau={dataFileMau}
                                                                    title={headerExport}
                                                                    namefile={namefile}
                                                                    btnname={'Nhập dữ liệu'}
                                                                />
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            {/* </div> */}
                                        </li>
                                    </ListTabs>
                                </div>
                            </div>
                            <div className={`card-search search-wrap ${!onSearch && 'active'}`}>
                                <div className="card-body">
                                    <div className="search-content">
                                        <Button
                                            className="search-back btn-icon toggle-search active"
                                            onClick={() => {
                                                setSearchText('');
                                                toggle();
                                            }}
                                        >
                                            <Icon name="arrow-left"></Icon>
                                        </Button>
                                        <input
                                            type="text"
                                            className="border-transparent form-focus-none form-control"
                                            placeholder="Tìm kiến nhân viên"
                                            value={onSearchText}
                                            onChange={(e) => onFilterChange(e)}
                                        />
                                        <Button className="search-submit btn-icon">
                                            <Icon name="search"></Icon>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div  style={{ overflowY: 'hidden' }}>
                        {/* <div className={` ${windowSize ? "overflow-scroll" : ""}`} > */}
                            <DataTableBody>
                                <DataTableHead className="bg-secondary">
                                    {columns[0] && (
                                        <DataTableRow >
                                            <span className="sub-text">Stt</span>
                                        </DataTableRow>
                                    )}
                                    
                                    {columns[1] && (
                                        <DataTableRow>
                                            <span className="sub-text w-150px">Họ tên</span>
                                        </DataTableRow>
                                    )}
                                    {columns[2] && (
                                        <DataTableRow >
                                            <span className="sub-text w-100px">Mã Nhân Viên</span>
                                        </DataTableRow>
                                    )}
                                    {columns[3] && (
                                        <DataTableRow >
                                            <span className="sub-text w-100px">Email</span>
                                        </DataTableRow>
                                    )}
                                    {columns[4] && (
                                        <DataTableRow >
                                            <span className="sub-text w-100px">Số điện thoại</span>
                                        </DataTableRow>
                                    )}
                                    {columns[5] && (
                                        <DataTableRow >
                                            <span className="sub-text w-100px">Ngày sinh</span>
                                        </DataTableRow>
                                    )}
                                    {columns[6] && (
                                        <DataTableRow >
                                            <span className="sub-text w-80px">Giới tính</span>
                                        </DataTableRow>
                                    )}
                                    {columns[7] && (
                                        <DataTableRow >
                                            <span className="sub-text w-100px">Chức vụ</span>
                                        </DataTableRow>
                                    )}
                                    {columns[8] && (
                                        <DataTableRow >
                                            <span className="sub-text w-100px">Chi nhánh</span>
                                        </DataTableRow>
                                    )}
                                    {columns[9] && (
                                        <DataTableRow>
                                            <span className="sub-text w-100px">Phòng ban</span>
                                        </DataTableRow>
                                    )}
                                    {columns[10] && (
                                        <DataTableRow>
                                            <span className="sub-text w-100px">Lương Offer</span>
                                        </DataTableRow>
                                    )}
                                    {columns[11] && (
                                        <DataTableRow>
                                            <span className="sub-text w-100px">Kiểu lương</span>
                                        </DataTableRow>
                                    )}
                                    {columns[12] && (
                                        <DataTableRow>
                                            <span className="sub-text w-100px">Mức lương</span>
                                        </DataTableRow>
                                    )}
                                    {columns[13] && (
                                        <DataTableRow>
                                            <span className="sub-text w-100px">Ngày vào làm việc</span>
                                        </DataTableRow>
                                    )}
                                    {columns[14] && (
                                        <DataTableRow>
                                            <span className="sub-text w-100px">Địa chỉ thường trú</span>
                                        </DataTableRow>
                                    )}
                                    {columns[15] && (
                                        <DataTableRow>
                                            <span className="sub-text w-100px">Số CMND</span>
                                        </DataTableRow>
                                    )}
                                    {columns[16] && (
                                        <DataTableRow>
                                            <span className="sub-text w-100px">Ngày cấp</span>
                                        </DataTableRow>
                                    )}
                                    {columns[17] && (
                                        <DataTableRow>
                                            <span className="sub-text w-100px">Nơi cấp</span>
                                        </DataTableRow>
                                    )}
                                    {columns[18] && (
                                        <DataTableRow>
                                            <span className="sub-text w-100px">Tỉnh thành</span>
                                        </DataTableRow>
                                    )}
                                    {columns[19] && (
                                        <DataTableRow>
                                            <span className="sub-text w-100px">Quận/Huyện</span>
                                        </DataTableRow>
                                    )}
                                    {columns[20] && (
                                        <DataTableRow>
                                            <span className="sub-text w-100px">Phường/Xã</span>
                                        </DataTableRow>
                                    )}

                                    <DataTableRow className="nk-tb-col-tools text-end">
                                        <UncontrolledDropdown>
                                            <DropdownToggle
                                                color="tranparent"
                                                className="btn btn-xs btn-outline-light btn-icon dropdown-toggle"
                                            >
                                                <Icon name="plus"></Icon>
                                            </DropdownToggle>
                                            <DropdownMenu end className="dropdown-menu-md">
                                                <ul className="link-tidy sm no-bdr">
                                                    {header.length > 0 ? (
                                                        header.map((item, index) => (
                                                            <li key={index}>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="custom-control-input"
                                                                        id={`column-${index}`}
                                                                        checked={columns[index]}
                                                                        onChange={(e) =>
                                                                            setColumns((prev) => {
                                                                                const newColumns = [...prev];
                                                                                newColumns[index] = e.target.checked;
                                                                                return newColumns;
                                                                            })
                                                                        }
                                                                    />
                                                                    <label
                                                                        className="custom-control-label"
                                                                        htmlFor={`column-${index}`}
                                                                    >
                                                                        {item}
                                                                    </label>
                                                                </div>
                                                            </li>
                                                        ))
                                                    ) : (
                                                        <li>No data</li>
                                                    )}
                                                </ul>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </DataTableRow>
                                </DataTableHead>
                                {/*Head*/}
                                {data.length > 0
                                    ? data.map((item, index) => {
                                        const phucapnhanviensArray = Array.isArray(item?.Phucapnhanviens)
                                            ? item.Phucapnhanviens
                                            : [];
                                        const tongGiaTri = phucapnhanviensArray.reduce(
                                            (tong, item) => tong + (item?.giatri || 0),
                                            0,
                                        );
                                        const luongOf = (item?.mucluong || 0) + tongGiaTri;
                                        const displayedIndex =  itemPerPage * (Page - 1) + index + 1;
                                        return (
                                            <DataTableItem key={index}>
                                                {columns[0] && (
                                                    <DataTableRow  className="nk-tb-col-check">
                                                        <span className="tb-amount">{displayedIndex}</span>
                                                    </DataTableRow>
                                                )}
                                                {columns[1] && (
                                                    <DataTableRow >
                                                        <span className="tb-amount text-primary">{item?.hoten}</span>
                                                    </DataTableRow>
                                                )}
                                                {columns[2] && (
                                                    <DataTableRow>
                                                        <span className="tb-amount">{item?.uuid}</span>
                                                    </DataTableRow>
                                                )}
                                                {columns[3] && (
                                                    <DataTableRow>
                                                        <span>{item?.email ? item?.email : 'NAV'}</span>
                                                    </DataTableRow>
                                                )}
                                                {columns[4] && (
                                                    <DataTableRow>
                                                        <span>
                                                            {item?.Thongtinnhanvien?.dienthoai
                                                                ? item?.Thongtinnhanvien?.dienthoai
                                                                : 'NAV'}
                                                        </span>
                                                    </DataTableRow>
                                                )}
                                                {columns[5] && (
                                                    <DataTableRow>
                                                        <span>
                                                            {item?.Thongtinnhanvien?.ngaysinh
                                                                ? format(parseISO(item?.Thongtinnhanvien?.ngaysinh), 'dd-MM-yyyy')
                                                                : 'NAV'}
                                                        </span>
                                                    </DataTableRow>
                                                )}
                                                {columns[6] && (
                                                    <DataTableRow>
                                                        <span>
                                                            {item?.GioitinhId === 0
                                                                ? 'Nam'
                                                                : item?.GioitinhId === 1
                                                                ? 'Nữ'
                                                                : 'Khác'}
                                                        </span>
                                                    </DataTableRow>
                                                )}
                                                {columns[7] && (
                                                    <DataTableRow>
                                                        <span>{item?.Chucvu?.chucvu ? item?.Chucvu?.chucvu : 'NAV'}</span>
                                                    </DataTableRow>
                                                )}
                                                {columns[8] && (
                                                    <DataTableRow>
                                                        <span>
                                                            {item?.Chinhanh?.diachi ? item?.Chinhanh?.diachi : 'NAV'}
                                                        </span>
                                                    </DataTableRow>
                                                )}
                                                {columns[9] && (
                                                    <DataTableRow>
                                                        <span>
                                                            {item?.Phongban?.phongban ? item?.Phongban?.phongban : 'NAV'}
                                                        </span>
                                                    </DataTableRow>
                                                )}
                                                {columns[10] && (
                                                    <DataTableRow>
                                                        <span>{luongOf}</span>
                                                    </DataTableRow>
                                                )}
                                                {columns[11] && (
                                                    <DataTableRow>
                                                        <span>
                                                            {item?.Kieuluong?.tieude ? item?.Kieuluong?.tieude : 'NAV'}
                                                        </span>
                                                    </DataTableRow>
                                                )}
                                                {columns[12] && (
                                                    <DataTableRow>
                                                        <span>{item?.mucluong ? item?.mucluong : 'NAV'}</span>
                                                    </DataTableRow>
                                                )}
                                                {columns[13] && (
                                                    <DataTableRow>
                                                        <span>{item?.ngayvaolamviec ? item?.ngayvaolamviec : 'NAV'}</span>
                                                    </DataTableRow>
                                                )}
                                                {columns[14] && (
                                                    <DataTableRow>
                                                        <span>
                                                            {item?.Thongtinnhanvien?.diachihientai
                                                                ? item?.Thongtinnhanvien?.diachihientai
                                                                : 'NAV'}
                                                        </span>
                                                    </DataTableRow>
                                                )}
                                                {columns[15] && (
                                                    <DataTableRow>
                                                        <span>
                                                            {item?.Thongtinnhanvien?.socmnd
                                                                ? item?.Thongtinnhanvien?.socmnd
                                                                : 'NAV'}
                                                        </span>
                                                    </DataTableRow>
                                                )}
                                                {columns[16] && (
                                                    <DataTableRow>
                                                        <span>
                                                            {item?.Thongtinnhanvien?.ngaycap
                                                                ? format(parseISO(item?.Thongtinnhanvien?.ngaycap), 'dd-MM-yyyy')
                                                                : 'NAV'}
                                                        </span>
                                                    </DataTableRow>
                                                )}
                                                {columns[17] && (
                                                    <DataTableRow>
                                                        <span>
                                                            {item?.Thongtinnhanvien?.noicap
                                                                ? item?.Thongtinnhanvien?.noicap
                                                                : 'NAV'}
                                                        </span>
                                                    </DataTableRow>
                                                )}
                                                {columns[18] && (
                                                    <DataTableRow>
                                                        <span>
                                                            {item?.Thongtinnhanvien?.Province?.name? item?.Thongtinnhanvien?.Province?.name : 'NAV'}
                                                        </span>
                                                    </DataTableRow>
                                                )}
                                                {columns[19] && (
                                                    <DataTableRow>
                                                        <span>
                                                            {item?.Thongtinnhanvien?.District?.name? item?.Thongtinnhanvien?.District?.name : 'NAV'}
                                                        </span>
                                                    </DataTableRow>
                                                )}
                                                {columns[20] && (
                                                    <DataTableRow>
                                                        <span>
                                                            {item?.Thongtinnhanvien?.Xa_phuong?.name ? item?.Thongtinnhanvien?.Xa_phuong?.name : 'NAV'}
                                                        </span>
                                                    </DataTableRow>
                                                )}
    
                                                <DataTableRow className="nk-tb-col-tools">
                                                    <ul className="nk-tb-actions gx-1">
                                                        {+showPhanquyen?.sua === 1 && (
                                                            <li className="nk-tb-action-hidden">
                                                                <Link
                                                                    to={`${config.he_thong}${config.chi_tiet_nhan_vien}?NhanvienId=${item.uuid}`}
                                                                >
                                                                    <TooltipComponent
                                                                        tag="span"
                                                                        containerClassName="btn btn-trigger btn-icon"
                                                                        id={'edit' + item.id}
                                                                        icon="edit-alt-fill"
                                                                        direction="top"
                                                                        text="Sửa"
                                                                    />
                                                                </Link>
                                                            </li>
                                                        )}
                                                        {+showPhanquyen?.xoa === 1 && (
                                                            <React.Fragment>
                                                                <li
                                                                    className="nk-tb-action-hidden"
                                                                    onClick={() => handleDeleteNhanvien(item)}
                                                                >
                                                                    <TooltipComponent
                                                                        tag="a"
                                                                        containerClassName="btn btn-trigger btn-icon"
                                                                        id={'suspend' + item.id}
                                                                        icon="user-cross-fill"
                                                                        direction="top"
                                                                        text="Xóa"
                                                                    />
                                                                </li>
                                                            </React.Fragment>
                                                        )}
                                                        <li>
                                                            <UncontrolledDropdown>
                                                                {(+showPhanquyen?.sua === 1 ||
                                                                    +showPhanquyen?.xoa === 1) && (
                                                                    <DropdownToggle
                                                                        tag="a"
                                                                        className="dropdown-toggle btn btn-icon btn-trigger"
                                                                    >
                                                                        <Icon name="more-h"></Icon>
                                                                    </DropdownToggle>
                                                                )}
                                                                <DropdownMenu end>
                                                                    <ul className="link-list-opt no-bdr">
                                                                        {+showPhanquyen?.sua === 1 && (
                                                                            <li>
                                                                                <Link
                                                                                    className="pe-auto-cursor"
                                                                                    rel="noopener noreferrer"
                                                                                    to={`${config.he_thong}${config.chi_tiet_nhan_vien}?NhanvienId=${item.uuid}`}
                                                                                >
                                                                                    <Icon name="edit"></Icon>
                                                                                    <span>Sửa</span>
                                                                                </Link>
                                                                            </li>
                                                                        )}
                                                                        {+showPhanquyen?.xoa === 1 && (
                                                                            <React.Fragment>
                                                                                <li className="divider"></li>
                                                                                <li
                                                                                    onClick={() =>
                                                                                        handleDeleteNhanvien(item)
                                                                                    }
                                                                                >
                                                                                    <DropdownItem
                                                                                        tag="a"
                                                                                        href="#suspend"
                                                                                        onClick={(ev) => {
                                                                                            ev.preventDefault();
                                                                                        }}
                                                                                    >
                                                                                        <Icon name="na"></Icon>
                                                                                        <span>Xóa</span>
                                                                                    </DropdownItem>
                                                                                </li>
                                                                            </React.Fragment>
                                                                        )}
                                                                    </ul>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                    </ul>
                                                </DataTableRow>
                                            </DataTableItem>
                                        );
                                    })
                                    : null}
                            </DataTableBody>
                        </div>
                        <div className="card-inner">
                            {data.length > 0 ? (
                               onSearch && <>
                                    {/* {data.length > itemPerPage && ( */}
                                        <PaginationCount posts={nhanvien} count={count} limit={itemPerPage} />
                                    {/* )} */}
                                </>
                            ) : (
                                <div className="text-center">
                                    <div>
                                        <Spinner color="primary" />
                                    </div>
                                    <span className="text-silent">Chưa có dữ liệu!</span>
                                </div>
                            )}
                        </div>
                    </DataTable>
                </Block>

                {/* <ModalNhanvien fieldList={fieldList} show={show} setShow={() => setShow(false)} /> */}
            </Content>
        </React.Fragment>
    );
};

export default Nhanvien;
