import React from 'react';
import Icon from '../icon/Icon';
import Button from '../button/Button';
import * as excelJs from "exceljs";

const ExportDulieuToExcel = ({ dataFileMau, header, namefile, btnname, title,dataExport }) => {
    const exportExcel = async () => {
        const headerExport = title
        
        const workbook = new excelJs.Workbook();
        const ws = workbook.addWorksheet('Nhân viên mẫu');
        
        ws.addRow(headerExport);
        ws.columns.map((col, index) => (col.width = 18));
        
        const cellFormatting = {
            font: {
                name: 'Inter',
                size: 8,
            },
            alignment: {
                horizontal: 'center',
            },
        };
        
        ws.eachRow((row) => {
            row.eachCell((cell) => {
                Object.assign(cell, cellFormatting);
            });
        });
        
        const excelBlob = await workbook.xlsx.writeBuffer();
        const excelUrl = URL.createObjectURL(
            new Blob([excelBlob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        );
        
        const link = document.createElement('a');
        link.href = excelUrl;
        link.download = `(${namefile})du_lieu_mau.xlsx`;
        document.body.appendChild(link);
        link.click();
        
        URL.revokeObjectURL(excelUrl);
        document.body.removeChild(link);
    };

    return (
        <React.Fragment>
            <Button color="primary" onClick={() => exportExcel()}>
                <Icon name="download-cloud"></Icon> <span>{btnname}</span>
            </Button>
        </React.Fragment>
    );
};
export default ExportDulieuToExcel;
