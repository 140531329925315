import actionTypes from "../actions/actionTypes";
const initState = {
    payroll: [],
    msg: '',
    count: 0,
}

const payrollReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.GET_LIST_PAYROLL:
            return {
                ...state,
                payroll: action?.payroll?.rows || [],
                msg: 'Ok' || '',
                count: action?.payroll?.count || 0
            }
           
        default:
            return state;
    }

}

export default payrollReducer