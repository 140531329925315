import React, {useState, useEffect} from 'react';
import './ModalNhanVien.scss';
import {Input, Modal, ModalBody, Spinner} from 'reactstrap';
import {apiGetNhanvien} from '~/services';
import Icon from '../icon/Icon';

const ModalSelectNhanvienCond = ({showSelect, setShowSelect, setlistNhanvien, typeSelect, fieldNhanvien}) => {
    
    // console.log("fieldNhanvie111111111111111111111n", fieldNhanvien)
    
    // console.log("Modal", typeSelect)
    // if(typeSelect){
    //
    // }
    // typeSelect = {
    //  ismultiple: "Lấy nhiều"
    //  single: "lấy một"
    // }
    
    const [dataCond, setDataCond] = useState([]);
    const [data, setData] = useState([]);
    const [dataCkb, setDataCkb] = useState([]);
    // console.log('dataCkb', dataCkb);
    const [page, setPage] = useState(1);
    const [scrollH, setscrollH] = useState(138);
    const [countChk, setcountChk] = useState(0);
    useEffect(() => {
        if (showSelect === true) {
            setDataCond([{
                PhongbanId: fieldNhanvien?.PhongbanId?.value ?? 0,
                ChinhanhId: fieldNhanvien?.ChinhanhId?.value ?? 0
            }])
        }
    }, [fieldNhanvien, showSelect]);
    useEffect(() => {
        if (dataCond) {
            fetchData(page, scrollH, dataCond[0]);
        }
    }, [page, dataCond]);
    
    async function fetchData(page, scrollH, dataCond) {
        try {
        let pbId = dataCond?.PhongbanId
        let cnId = dataCond?.ChinhanhId
        let searchParamsObject = {page: page, limit: '', order: 'asc', by: 'id'};
        const nhanvien = await apiGetNhanvien(searchParamsObject);
        
        const response = nhanvien.data.data.rows;
        // console.log('pbId', pbId, cnId)
        
        let nhanVienActive = response.filter((it) => {
            return it.TrangthaiId === '1'
        })
        if (pbId !== 0 && cnId !== 0) {
            nhanVienActive = nhanVienActive.filter((i)=>{
                return i.ChinhanhId === cnId && i.PhongbanId === pbId
            })
        } else if (pbId === 0 && cnId !== 0){
            nhanVienActive = nhanVienActive.filter((i)=>{
                return i.ChinhanhId === cnId
            })
        }else if (pbId !== 0 && cnId === 0){
            nhanVienActive = nhanVienActive.filter((i)=>{
                return i.PhongbanId === pbId
            })
        }
        // console.log('nhanVienActive', nhanVienActive)
        
        setcountChk(nhanvien.data.data.count);
        if (nhanVienActive.length > 0) {
            setPage(page);
            if (page >= 2) {
                setscrollH(scrollH + 421);
            }
            setData( [ ...nhanVienActive]);
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    }
    // console.log('data', data.length)
    const handleScroll = (event) => {
        let scrollHeight = event.currentTarget.scrollTop;
        if (scrollHeight === scrollH && data.length < countChk) {
            let modtmp = page + 1;
            setPage(modtmp);
        }
    };
    
    const onSelectChangeSL = (e, id) => {
        const {checked} = e.currentTarget;
        let index = data.findIndex((item) => item.id === id);
        let nhanvien = data[index];
        nhanvien.checked = checked;
        if (checked) {
            setDataCkb([...dataCkb.filter((people) => people.id !== id), nhanvien]);
        } else {
            setDataCkb(dataCkb.filter((people) => people.id !== id));
        }
    };
    const onSelectChangeSV = (e, id) => {
        let index = data.findIndex((item) => item.id === id);
        let nhanvien = data[index];
        setDataCkb([nhanvien]);
    };
    const selectorCheckSL = (e) => {
        const {checked} = e.currentTarget;
        
        const newData = data.map((item) => (item.checked = checked));
        
        setDataCkb(checked ? [...data] : []);
    };
    
    const handleSubmit = () => {
        setlistNhanvien(dataCkb?.map((item) => ({
            label: item.hoten,
            value: item.id,
            PhongbanId: item.PhongbanId,
            email: item?.email
        })));
        setShowSelect(false);
    };
    
    return (
        <React.Fragment>
            <Modal isOpen={showSelect} toggle={setShowSelect} size="lg">
                <ModalBody>
                    <button type="button" className="btn-close text-danger position-absolute" style={{right:'2px',top:'2px',border: '2px solid'}}
                            onClick={setShowSelect}></button>
                    {/*<Icon name="cross-sm float-end fs-2 text-danger ms-2" onClick={setShowSelect}></Icon>*/}
                    <div className="container">
                        <div className="position-relative card-tools-toggle px-0 py-2">
                            <div className="card-title-group">
                                <div className="card-title">
                                    <h5 className="title">Danh sách nhân viên</h5>
                                </div>
                            </div>
                            <div className="card-title-group mt-2">
                                <Input id="NhanvienId" name="NhanvienId" placeholder="Tìm kiếm nhân viên" type="text"/>
                            </div>
                            <div className="card-title-group mt-2">
                                <span
                                    className="text-white ms-0 px-5 py-1 badge justify-content-center px-0 btn btn-primary"
                                    onClick={handleSubmit}
                                >
                                    <em className="icon ni ni-plus pe-2"></em>Chọn nhân viên
                                </span>
                            </div>
                        </div>
                        <table className="table table-fixed">
                            <thead className="d-block">
                            <tr className="d-flex">
                                <th className="col-1 border-bottom-0">#</th>
                                <th className="col-1 border-bottom-0">
                                    <div className="custom-control custom-control-sm custom-checkbox notext">
                                        {typeSelect === true ? (
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    onChange={(e) => selectorCheckSL(e)}
                                                    id="uidd"
                                                />
                                                <label className="custom-control-label" htmlFor="uidd"></label>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </th>
                                <th className="col-4 border-bottom-0">
                                    <span className="sub-text fw-bold text-info text-uppercase">TÊN NHÂN VIÊN</span>
                                </th>
                                <th className="col-3 border-bottom-0">
                                    <span className="sub-text fw-bold text-info text-uppercase">MÃ NHÂN VIÊN</span>
                                </th>
                                <th className="col-3 border-bottom-0">
                                    <span className="sub-text fw-bold text-info text-uppercase">SỐ ĐIỆN THOẠI</span>
                                </th>
                            </tr>
                            </thead>
                            <tbody
                                onScroll={handleScroll}
                                style={{height: '300px', overflow: 'scroll', display: 'block'}}
                            >
                            {data.length > 0 ? (
                                data.map((item, index) => {
                                    return (
                                        <tr className="d-flex" key={index}>
                                            <td className="col-1">{index + 1}</td>
                                            <td className="col-1">
                                                <div
                                                    className="custom-control custom-control-sm custom-checkbox notext">
                                                    {typeSelect ? (
                                                        <input
                                                            type='checkbox'
                                                            className="custom-control-input"
                                                            defaultChecked={item.checked}
                                                            name="name"
                                                            id={item.id + 'uiddd'}
                                                            key={Math.random()}
                                                            onChange={(e) => onSelectChangeSL(e, item.id)}
                                                        />
                                                    ) : (
                                                        <input
                                                            type='radio'
                                                            className="custom-control-input"
                                                            // defaultChecked
                                                            name="name"
                                                            id={item.id + 'uiddd'}
                                                            // key={Math.random()}
                                                            onChange={(e) => onSelectChangeSV(e, item.id)}
                                                        />
                                                    )}
                                                    
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor={item.id + 'uiddd'}
                                                    ></label>
                                                </div>
                                            </td>
                                            <td className="col-4">
                                                <span className="tb-amount"> {item?.hoten} </span>
                                            </td>
                                            <td className="col-3">
                                                <span className="tb-amount"> {item?.uuid} </span>
                                            </td>
                                            <td className="col-3">
                                                    <span className="tb-amount">
                                                        {' '}
                                                        {item?.Thongtinnhanvien?.dienthoai}{' '}
                                                    </span>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <div className="text-center">
                                    <div>
                                        <Spinner color="primary"/>
                                    </div>
                                    <span className="text-silent">Chưa có dữ liệu!</span>
                                </div>
                            )}
                            </tbody>
                        </table>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default ModalSelectNhanvienCond;
