import React, {useState, useEffect} from 'react'
import {InputForm, Button} from '~/components/Form'
import '../Modal.scss';
import Select from 'react-select';
import {apiNewThanhphanluong} from "~/services";
import {toast} from "react-toastify";
import Content from "~/layout/content/Content";
import {Modal, ModalBody} from 'reactstrap';
import Textarea from "~/components/Form/Textarea";
import {dataSelectActive, dataTypeSalary} from "~/utils/Datacustom";
import Icon from "~/components/icon/Icon";

const ModalKhac = ({show, setShow, fieldList}) => {
    const [invalidFields, setInvalidFields] = useState([])
    const [payload, setPayload] = useState(fieldList)
    const [showSelect, setShowSelect] = useState(false);
    const [typeSalary, settypeSalary] = useState(false);
    useEffect(() => {
        show && setPayload(fieldList)
        settypeSalary(dataTypeSalary.filter((item) => item.value === 5 || item.value === 6))
    }, [show])
    // console.log('check typeSalary', typeSalary)
    const handleSubmit = async () => {
        let invalids = 0
        
        if (invalids === 0) {
            let datapayload = {
                data: [
                    {
                        name: payload.name,
                        code: payload.code,
                        calcDefault: payload.calcDefault,
                        description: payload.description,
                        type: payload.type.value ?? 5,
                        status: payload.status.value
                    }
                ]
            }
            const data = await apiNewThanhphanluong(datapayload)
            if (data.data.success === 200) {
                toast.success(data.data.message);
                setShow(false)
            } else {
                toast.error("Cập nhật thất bại!");
            }
        }
    }
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayload(prev => ({...prev, [statename]: selected}))
    }
    return (
        <React.Fragment>
            <Content>
                <Modal isOpen={show} toggle={setShow} className="modal-dialog-centered modal-90w">
                    <ModalBody>
                        <Icon name="cross-sm float-end fs-2" onClick={setShow}></Icon>
                        <h5 className="title my-2"> Thêm mới</h5>
                        <div className="row">
                            <div className="form-group col-md-3">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Tên thành phần'}
                                    value={payload.name}
                                    setValue={setPayload}
                                    keyPayload={'name'}
                                    type='text'
                                    validate
                                />
                            </div>
                            <div className="form-group col-md-3">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Mã'}
                                    value={payload.code}
                                    setValue={setPayload}
                                    keyPayload={'code'}
                                    type='text'
                                    validate
                                />
                            </div>
                            <div className="form-group col-md-3">
                                <label htmlFor="inputZip" className="mb-2">Loại</label>
                                <Select
                                    value={payload.type}
                                    onChange={handleOnSelect}
                                    options={typeSalary}
                                    placeholder={'Loại'}
                                    name='type'
                                    onFocus={() => setInvalidFields([])}
                                />
                                {invalidFields.length > 0 && invalidFields.some(i => i.name === 'type') && <small
                                    className='text-danger'>{invalidFields.find(i => i.name === 'type')?.message}</small>}
                            </div>
                            
                            <div className="form-group col-md-12">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Công thức mặc định'}
                                    value={payload.calcDefault}
                                    setValue={setPayload}
                                    keyPayload={'calcDefault'}
                                    type='text'
                                    validate
                                />
                            </div>
                        
                            <div className="form-group col-md-12 mb-2">
                                <Textarea
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Mô tả'}
                                    value={payload.description}
                                    setValue={setPayload}
                                    keyPayload={'description'}
                                    type="text"
                                    validate
                                />
                            </div>
                            <div className='col-12 mt-5'>
                                <Button
                                    text='Thêm mới'
                                    bClass='btn btn-primary px-5'
                                    textColor
                                    onClick={handleSubmit}
                                />
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </Content>
        </React.Fragment>
    );
}

export default ModalKhac;

