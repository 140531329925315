import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '~/store/actions';
import { ModalLoaihopdong, ModalEditLoaihopdong } from './Modal';
import Swal from 'sweetalert2';
import Head from '~/layout/head/Head';
import Content from '~/layout/content/Content';
import { Block } from '~/components/block/Block';
import {
    Button,
    Col,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    Icon,
    PaginationComponent,
    Row,
    RSelect,
    TooltipComponent,
    PermissionUtils,
} from '~/components/Component';
import {
    Card,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalBody,
    UncontrolledDropdown,
    Spinner,
} from 'reactstrap';
import { MenuHopdong } from './MenuTab/Menucontent';

const Loaihopdong = () => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [showEditLoaihopdong, setShowEditLoaihopdong] = useState(false);

    //lấy giá trị từ thằng redux trả về
    const { loaihopdong } = useSelector((state) => state.hopdonglaodong);
    const [data, setData] = useState(loaihopdong);
    const fieldList = {
        tenloaihopdong: '',
        motaloaihopdong: '',
    };

    const [showPhanquyen, setShowPhanquyen] = useState({});
    let dataPermissionUtils = PermissionUtils('hopdonglaodong', 'all');
    useEffect(() => {
        if (dataPermissionUtils) {
            setShowPhanquyen(dataPermissionUtils);
        }
    }, [dataPermissionUtils]);

    useEffect(() => {
        dispatch(actions.getListLoaihopdong());
    }, []);
    useEffect(() => {
        if (loaihopdong && loaihopdong.length > 0) {
            setData([...loaihopdong]);
        }
    }, [loaihopdong]);

    const [onSearchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
  
    useEffect(() => {
        if (onSearchText !== '') {
            const filteredObject = data.filter((item) => {
                return (
                    item.ho.toLowerCase().includes(onSearchText.toLowerCase()) ||
                    item.ten.toLowerCase().includes(onSearchText.toLowerCase())
                );
            });
            setData([...filteredObject]);
        } else {
            setData([...data]);
        }
    }, [onSearchText, setData]);
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    useEffect(() => {
        (loaihopdong && setShow(false)) || setShowEditLoaihopdong(false);
    }, [loaihopdong]);
    // set state để mở đóng Modal
    const handleAddNewLoaihopdong = async () => {
        setShow(true);
    };
    const [dataIdLoaihopdong, setDataIdLoaihopdong] = useState([]);
    const handleEditLoaihopdong = async (data) => {
        setShowEditLoaihopdong(true);
        await setDataIdLoaihopdong(data);
    };
    const handleDeleteLoaihopdong = (data) => {
        Swal.fire({
            title: 'Bạn có chắc không?',
            text: 'Bạn sẽ không thể lấy lại khi xóa!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Không, bỏ qua!',
            confirmButtonText: 'Vâng, xóa nó đi!',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(actions.DeleteLoaihopdong(data));
            }
        });
    };
    return (
        <React.Fragment>
            <Head title="User List - Regular"></Head>
            <Content>
                <Card className="">
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner card-inner-lg px-4 mt-3">
                            <h4>Loại hợp đồng</h4>
                            <MenuHopdong />
                        </div>
                    </DataTable>
                </Card>
                <Block>
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner position-relative card-tools-toggle">
                            <div className="card-title-group">
                                <div className="card-title">
                                    <h5 className="title">Danh sách loại hợp đồng</h5>
                                    {/* {+showPhanquyen?.them === 1 && (
                                        <Button color="primary" onClick={handleAddNewLoaihopdong}>
                                            <Icon name="plus"></Icon> Thêm mới loại hợp đồng
                                        </Button>
                                    )} */}
                                </div>
                            </div>
                        </div>
                        <DataTableBody>
                            <DataTableHead>
                                <DataTableRow>
                                    <span className="sub-text">Stt</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span className="sub-text">Tên loại hợp đồng</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span className="sub-text">Mô tả loại hợp đồng</span>
                                </DataTableRow>
                                {/* <DataTableRow></DataTableRow> */}
                            </DataTableHead>
                            {currentItems.length > 0
                                ? currentItems.map((item, index) => {
                                      return (
                                          <DataTableItem key={index}>
                                              <DataTableRow size=" nk-tb-col-check mb">
                                                  <span className="tb-amount">{index + 1}</span>
                                              </DataTableRow>
                                              <DataTableRow>
                                                  <span className="tb-amount">{item?.tenloaihopdong}</span>
                                              </DataTableRow>
                                              <DataTableRow>
                                                  <span className="tb-amount">{item?.motaloaihopdong}</span>
                                              </DataTableRow>

                                              {/* <DataTableRow className="nk-tb-col-tools">
                                                  <ul className="nk-tb-actions gx-1">
                                                      {+showPhanquyen?.sua === 1 && (
                                                          <li
                                                              className="nk-tb-action-hidden"
                                                              onClick={() => handleEditLoaihopdong(item)}
                                                          >
                                                              <TooltipComponent
                                                                  tag="a"
                                                                  containerClassName="btn btn-trigger btn-icon"
                                                                  id={'edit' + item.id}
                                                                  icon="edit-alt-fill"
                                                                  direction="top"
                                                                  text="Sửa"
                                                              />
                                                          </li>
                                                      )}
                                                      {+showPhanquyen?.xoa === 1 && (
                                                          <React.Fragment>
                                                              <li
                                                                  className="nk-tb-action-hidden"
                                                                  onClick={() => handleDeleteLoaihopdong(item)}
                                                              >
                                                                  <TooltipComponent
                                                                      tag="a"
                                                                      containerClassName="btn btn-trigger btn-icon"
                                                                      id={'suspend' + item.id}
                                                                      icon="user-cross-fill"
                                                                      direction="top"
                                                                      text="Xóa"
                                                                  />
                                                              </li>
                                                          </React.Fragment>
                                                      )}
                                                      <li>
                                                          <UncontrolledDropdown>
                                                              {(+showPhanquyen?.sua === 1 ||
                                                                  +showPhanquyen?.xoa === 1) && (
                                                                  <DropdownToggle
                                                                      tag="a"
                                                                      className="dropdown-toggle btn btn-icon btn-trigger"
                                                                  >
                                                                      <Icon name="more-h"></Icon>
                                                                  </DropdownToggle>
                                                              )}
                                                              <DropdownMenu end>
                                                                  <ul className="link-list-opt no-bdr">
                                                                      {+showPhanquyen?.sua === 1 && (
                                                                          <li
                                                                              onClick={() =>
                                                                                  handleEditLoaihopdong(item)
                                                                              }
                                                                          >
                                                                              <DropdownItem
                                                                                  tag="a"
                                                                                  href="#edit"
                                                                                  onClick={(ev) => {
                                                                                      ev.preventDefault();
                                                                                  }}
                                                                              >
                                                                                  <Icon name="edit"></Icon>
                                                                                  <span>Sửa</span>
                                                                              </DropdownItem>
                                                                          </li>
                                                                      )}
                                                                      {+showPhanquyen?.xoa === 1 && (
                                                                          <React.Fragment>
                                                                              <li className="divider"></li>
                                                                              <li
                                                                                  onClick={() =>
                                                                                      handleDeleteLoaihopdong(item)
                                                                                  }
                                                                              >
                                                                                  <DropdownItem
                                                                                      tag="a"
                                                                                      href="#suspend"
                                                                                      onClick={(ev) => {
                                                                                          ev.preventDefault();
                                                                                      }}
                                                                                  >
                                                                                      <Icon name="na"></Icon>
                                                                                      <span>Xóa</span>
                                                                                  </DropdownItem>
                                                                              </li>
                                                                          </React.Fragment>
                                                                      )}
                                                                  </ul>
                                                              </DropdownMenu>
                                                          </UncontrolledDropdown>
                                                      </li>
                                                  </ul>
                                              </DataTableRow> */}
                                          </DataTableItem>
                                      );
                                  })
                                : null}
                        </DataTableBody>
                        {/* <div className="card-inner">
                            {currentItems.length > 0 ? (
                                <PaginationComponent
                                    itemPerPage={itemPerPage}
                                    totalItems={currentItems.length}
                                    paginate={paginate}
                                    currentPage={currentPage}
                                />
                            ) : (
                                <div className="text-center">
                                    <div>
                                        <Spinner color="primary" />
                                    </div>
                                    <span className="text-silent">Chưa có dữ liệu!</span>
                                </div>
                            )}
                        </div> */}
                    </DataTable>
                </Block>

                <ModalLoaihopdong show={show} fieldList={fieldList} setShow={() => setShow(false)} />
                <ModalEditLoaihopdong
                    show={showEditLoaihopdong}
                    fieldList={fieldList}
                    setShow={() => setShowEditLoaihopdong(false)}
                    dataIdLoaihopdong={dataIdLoaihopdong}
                />
            </Content>
        </React.Fragment>
    );
};

export default Loaihopdong;
