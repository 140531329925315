export const validatePhieuTangCa = (fieldName, value) => {
    let regNumber = /^[0-9\b]+$/;
    switch (fieldName) {
        // case 'tenphieu':
        //     if (value.length === 0) {
        //         return {
        //             name: fieldName,
        //             message: 'Nội dung không được để trống'
        //         }
        //     }
        //     break;
        // case 'ngaynopdon':
        //     if (value.length === 0) {
        //         return {
        //             name: fieldName,
        //             message: 'Nội dung không được để trống'
        //         }
        //     }
        //     break;
        case 'ngaybatdau':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        // case 'ngayketthuc':
        //     if (value.length === 0) {
        //         return {
        //             name: fieldName,
        //             message: 'Nội dung không được để trống'
        //         }
        //     }
        //     break;
        // case 'giobatdau':
        //     if (value.length === 0) {
        //         return {
        //             name: fieldName,
        //             message: 'Nội dung không được để trống'
        //         }
        //     }
        //     break;
        // case 'gioketthuc':
        //     if (value.length === 0) {
        //         return {
        //             name: fieldName,
        //             message: 'Nội dung không được để trống'
        //         }
        //     }
        //     break;
        // case 'batdaugiovaodehieuca':
        //     if (value.length === 0) {
        //         return {
        //             name: fieldName,
        //             message: 'Nội dung không được để trống'
        //         }
        //     }
        //     break;
        // case 'ketthucgiovaodehieuca':
        //     if (value.length === 0) {
        //         return {
        //             name: fieldName,
        //             message: 'Nội dung không được để trống'
        //         }
        //     }
        //     break;
        // case 'batdaugioradehieuca':
        //     if (value.length === 0) {
        //         return {
        //             name: fieldName,
        //             message: 'Nội dung không được để trống'
        //         }
        //     }
        //     break;
        // case 'ketthucgioradehieuca':
        //     if (value.length === 0) {
        //         return {
        //             name: fieldName,
        //             message: 'Nội dung không được để trống'
        //         }
        //     }
        //     break;
        // case 'tonggio':
        //     if (value.length === 0) {
        //         return {
        //             name: fieldName,
        //             message: 'Nội dung không được để trống'
        //         }
        //     }
        //     break;
        // case 'nguoiduyet':
        //     if (value.length === 0) {
        //         return {
        //             name: fieldName,
        //             message: 'Nội dung không được để trống'
        //         }
        //     }
        //     break;
        // case 'TrangthaiId':
        //     if (value.length === 0) {
        //         return {
        //             name: fieldName,
        //             message: 'Nội dung không được để trống'
        //         }
        //     }
        //     break;
        // case 'NhanvienId':
        //     if (value.length === 0) {
        //         return {
        //             name: fieldName,
        //             message: 'Nội dung không được để trống'
        //         }
        //     }
        //     break;
        // case 'CauhinhtangcaId':
        //     if (value.length === 0) {
        //         return {
        //             name: fieldName,
        //             message: 'Nội dung không được để trống'
        //         }
        //     }
        //     break;
        default:
            break;
    }
}