import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {Button, InputForm} from '~/components/Form'
import * as actions from '~/store/actions'
import {validateBangChamCong} from "~/request/validateBangChamCong";
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import {toast} from "react-toastify";
import {apiEditBangChamCongTangCa, apiListChamCongTangCa, apiThongTinChamCongById} from "~/services";
import {format, parseISO} from "date-fns";

const ModalEditCongTangCa = ({show, setShow, fieldList, dataChamCong}) => {
    // console.log('dataChamCong', dataChamCong)
    const dispatch = useDispatch()
    const [invalidFields, setInvalidFields] = useState([])
    const [payload, setPayload] = useState(fieldList)
    const [recordChamCong, setRecordChamCong] = useState()
    const [title, setTitle] = useState('Dữ liệu chấm công của nhân viên này chưa được tạo')
    
    async function fetchData() {
        let dataParam = {
            nhanvienId: Number(dataChamCong?.NhanvienId),
            day: dataChamCong?.day,
        };
        try {
            const Chamcong = await apiThongTinChamCongById(dataParam);
            if (Chamcong?.data?.success === 200) {
                const response = Chamcong?.data?.data;
                setRecordChamCong(response);
                setTitle(Chamcong?.data?.message)
            }
            
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    
    useEffect(() => {
        show &&
        dataChamCong &&
        setPayload({
            is_edit: dataChamCong?.is_edit,
            BangchotcongId: dataChamCong?.BangchotcongId,
            day: dataChamCong?.day,
            vao1: dataChamCong?.vao1 === '--:--' ? '' : dataChamCong?.vao1,
            ra1: dataChamCong?.ra1 === '--:--' ? '' : dataChamCong?.ra1,
            vao2: dataChamCong?.vao2 === '--:--' ? '' : dataChamCong?.vao2,
            ra2: dataChamCong?.ra2 === '--:--' ? '' : dataChamCong?.ra2,
            tonggio: dataChamCong?.tonggio || 0,
            ghichu: dataChamCong?.ghichu,
            nghiPhep: dataChamCong?.nghiPhep,
            cong: dataChamCong?.cong,
            NhanvienId: dataChamCong?.NhanvienId,
        });
        fetchData().then(r => r)
    }, [dataChamCong, show]);
    // console.log('dataChamCong', dataChamCong)
    
    const handleSubmit = async () => {
        if (!dataChamCong.id) {
            toast.error('Dữ liệu chấm công không tồn tại')
            setShow(true)
            return false;
        }
        const dataEdit = 1
        const dataCreate = 2
        let invalids = validate(payload)
        const now = new Date(dataChamCong.day); // lấy thời gian hiện tại của ngày chấm công
        let year = now.getFullYear();
        let month = now.getMonth() + 1;
        let date = now.getDate();
        if (invalids === 0) {
           
            let vao1Pay = payload.vao1 === dataChamCong.vao1 ? dataChamCong.vao1 : payload.vao1+':00';
            let ra1Pay = payload.ra1 === dataChamCong.ra1 ? dataChamCong.ra1 : payload.ra1+':00';
    
            let datapayloadTmp =
                {
                    id: dataChamCong.id,
                    NhanvienId: dataChamCong.NhanvienId,
                    PhieutangcaId: Number(dataChamCong.PhieutangcaId),
                    day: dataChamCong.day,
                    year: year,
                    month: month,
                    date: date,
                    vao1: vao1Pay,
                    ra1: ra1Pay,
                    cham: `${vao1Pay},${ra1Pay}`,
                    tonggio: payload.tonggio,
                    cong: payload.cong,
                    nguoitao: 36,
                    ghichu: payload.ghichu
                }
            
            
            datapayloadTmp = Object.assign({TrangthaiId: dataEdit}, datapayloadTmp);
            
            let datapayload = {
                "data": [
                    datapayloadTmp
                ]
            }
            // console.log('datapayload', datapayload)
            const result = await apiEditBangChamCongTangCa(datapayload);
            if (result.data.success === 200) {
                toast.success('Cập nhật thành công')
                setShow(false)
            }
            console.log('result', result)
            // setShow(true)
        }
    }
    const validate = (payload) => {
        let invalids = 0
        let fields = Object.entries(payload)
        fields.forEach(item => {
            if (validateBangChamCong(item[0], item[1]) !== undefined) {
                setInvalidFields(prev => [...prev, validateBangChamCong(item[0], item[1])])
                invalids++
            }
        })
        return invalids
    }
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Cập nhật công
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Vào 1'}
                            value={payload.vao1}
                            disabled={payload.is_edit !== 1}
                            setValue={setPayload}
                            keyPayload={'vao1'}
                            type='time'
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ra 1'}
                            disabled={payload.is_edit !== 1}
                            value={payload.ra1}
                            setValue={setPayload}
                            keyPayload={'ra1'}
                            type='time'
                        />
                    </div>
                    
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Tổng giờ'}
                            disabled={payload.is_edit !== 1}
                            value={payload.tonggio}
                            placeholder={payload.tonggio ?? 0}
                            setValue={setPayload}
                            keyPayload={'tonggio'}
                            type='integer'
                        />
                    </div>
                    <div className="form-group col-md-3 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Tổng công'}
                            disabled={payload.is_edit !== 1}
                            value={payload.cong}
                            placeholder={payload.cong ?? 0}
                            setValue={setPayload}
                            keyPayload={'cong'}
                            type='integer'
                        />
                    </div>
                    
                    <div className="form-group col-md-12 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ghi chú về cập nhật công'}
                            value={payload.ghichu}
                            disabled={payload.is_edit !== 1}
                            setValue={setPayload}
                            keyPayload={'ghichu'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-12 ">
                        <p className="fw-bold text-primary">{title} <i className={"text-danger"}>(Dữ liệu từ máy chấm công)</i></p>
                        <p className="">
                            {recordChamCong?.length > 0 ? recordChamCong?.map((item, index) => {
                                return (
                                    <span key={index}>
                                        <small className={"text-primary"}>{item}</small> {index + 1 < recordChamCong?.length ? '|' : ''}
                                    </span>
                                
                                );
                            }) : null}
                        </p>
                    </div>
                </div>
                
                <div className='col-12 mt-5'>
                    <Button
                        text='Cập nhật'
                        disabled={payload.is_edit !== 1}
                        bClass='btn btn-primary px-5'
                        textColor
                        onClick={handleSubmit}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ModalEditCongTangCa;

