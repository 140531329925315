var today = new Date();
var dd = String(today.getDate()).padStart(2, "0");
var mm = String(today.getMonth() + 1).padStart(2, "0");
var yyyy = today.getFullYear();

var tomorrow = new Date(today);
tomorrow.setDate(today.getDate() + 1);
/*
var t_dd = String(tomorrow.getDate()).padStart(2, "0");
var t_mm = String(tomorrow.getMonth() + 1).padStart(2, "0");
var t_yyyy = tomorrow.getFullYear();
*/

var yesterday = new Date(today);
yesterday.setDate(today.getDate() - 1);
var y_dd = String(yesterday.getDate()).padStart(2, "0");
var y_mm = String(yesterday.getMonth() + 1).padStart(2, "0");
var y_yyyy = yesterday.getFullYear();

var YM = yyyy + "-" + mm;
var YESTERDAY = y_yyyy + "-" + y_mm + "-" + y_dd;
var TODAY = yyyy + "-" + mm + "-" + dd;

var month = [
  "Tháng 1",
  "Tháng 2",
  "Tháng 3",
  "Tháng 4",
  "Tháng 5",
  "Tháng 6",
  "Tháng 7",
  "Tháng 8",
  "Tháng 9",
  "Tháng 10",
  "Tháng 11",
  "Tháng 12",
];

export const returnDate = (date) => {
  if (date !== undefined) {
    const dateSection = date.split("-");
    let newDate = 'Ngày ' + dateSection[2] + " " + month[Number(dateSection[1]) - 1] + " " + 'Năm ' + dateSection[0];
    return newDate;
  }
};

export const events = [
  // {
  //   id: "default-event-id-" + Math.floor(Math.random() * 9999999),
  //   title: "Người đọc sẽ bị phân tâm",
  //   start: YM + "-03",
  //   end: YM + "-03",
  //   className: "fc-event-danger",
  //   type: { value: "fc-event-danger", label: "Tiệc công ty" },
  //   description: "Sử dụng đoạn Lorem Ipsum, bạn cần chắc chắn rằng không có gì ngượng ngùng ẩn trong đó.",
  // },
  // {
  //   id: "default-event-id-" + Math.floor(Math.random() * 9999999),
  //   title: "Đoạn văn của văn học Latin cổ điển.",
  //   start: YM + "-14",
  //   end: YM + "-14",
  //   className: "fc-event-success",
  //   type: { value: "fc-event-success", label: "Hội thảo" },
  //   description: "Sử dụng đoạn Lorem Ipsum, bạn cần chắc chắn rằng không có gì ngượng ngùng ẩn trong đó.",
  // },
  // {
  //   id: "default-event-id-" + Math.floor(Math.random() * 9999999),
  //   title: "Bước nhảy vào điện tử",
  //   start: YM + "-05",
  //   end: YM + "-06",
  //   className: "fc-event-primary",
  //   type: { value: "fc-event-primary", label: "Công ty" },
  //   description: "Sử dụng đoạn Lorem Ipsum, bạn cần chắc chắn rằng không có gì ngượng ngùng ẩn trong đó.",
  // },
  // {
  //   id: "default-event-id-" + Math.floor(Math.random() * 9999999),
  //   title: "Đoạn Lorem Ipsum - Ra mắt sản phẩm",
  //   start: YM + "-02",
  //   end: YM + "-04",
  //   className: "fc-event-primary",
  //   type: { value: "fc-event-primary", label: "Công ty" },
  //   description: "Sử dụng đoạn Lorem Ipsum, bạn cần chắc chắn rằng không có gì ngượng ngùng ẩn trong đó.",
  // },
  // {
  //   id: "default-event-id-" + Math.floor(Math.random() * 9999999),
  //   title: "Đoạn văn của văn học Latin cổ điển.",
  //   start: YM + "-12",
  //   end: YM + "-10",
  //   className: "fc-event-pink",
  //   type: { value: "fc-event-pink", label: "Riêng tư" },
  //   description: "Sử dụng đoạn Lorem Ipsum, bạn cần chắc chắn rằng không có gì ngượng ngùng ẩn trong đó.",
  // },
  // {
  //   id: "default-event-id-" + Math.floor(Math.random() * 9999999),
  //   title: "Đoạn văn của văn học Latin cổ điển.",
  //   start: YM + "-07",
  //   end: YM + "-07",
  //   className: "fc-event-danger",
  //   type: { value: "fc-event-danger", label: "Tiệc công ty" },
  //   description: "Sử dụng đoạn Lorem Ipsum, bạn cần chắc chắn rằng không có gì ngượng ngùng ẩn trong đó.",
  // },
  // {
  //   id: "default-event-id-" + Math.floor(Math.random() * 9999999),
  //   title: "Đoạn văn của văn học Latin cổ điển.",
  //   start: YM + "-16",
  //   end: YM + "-16",
  //   className: "fc-event-info",
  //   type: { value: "fc-event-info", label: "Hội nghị" },
  //   description: "Sử dụng đoạn Lorem Ipsum, bạn cần chắc chắn rằng không có gì ngượng ngùng ẩn trong đó.",
  // },
  // {
  //   id: "default-event-id-" + Math.floor(Math.random() * 9999999),
  //   title: "Đoạn văn của văn học Latin cổ điển",
  //   start: TODAY + "-01",
  //   end: TODAY + "-01",
  //   className: "fc-event-primary",
  //   type: { value: "fc-event-primary", label: "Công ty" },
  //   description: "Sử dụng đoạn Lorem Ipsum, bạn cần chắc chắn rằng không có gì ngượng ngùng ẩn trong đó.",
  // },
  // {
  //   id: "default-event-id-" + Math.floor(Math.random() * 9999999),
  //   title: "Đoạn văn của văn học Latin cổ điển.",
  //   start: TODAY,
  //   end: TODAY,
  //   className: "fc-event-info",
  //   type: { value: "fc-event-info", label: "Hội nghị" },
  //   description: "Sử dụng đoạn Lorem Ipsum, bạn cần chắc chắn rằng không có gì ngượng ngùng ẩn trong đó.",
  // },
  // {
  //   id: "default-event-id-" + Math.floor(Math.random() * 9999999),
  //   title: "Đoạn văn của văn học Latin cổ điển.",
  //   start: TODAY,
  //   end: TODAY,
  //   className: "fc-event-warning",
  //   type: { value: "fc-event-warning", label: "Cuộc họp" },
  //   description: "Sử dụng đoạn Lorem Ipsum, bạn cần chắc chắn rằng không có gì ngượng ngùng ẩn trong đó.",
  // },
  // {
  //   id: "default-event-id-" + Math.floor(Math.random() * 9999999),
  //   title: "Thời gian chơi",
  //   start: TODAY,
  //   end: TODAY,
  //   className: "fc-event-info",
  //   type: { value: "fc-event-info", label: "Hội nghị" },
  //   description: "Sử dụng đoạn Lorem Ipsum, bạn cần chắc chắn rằng không có gì ngượng ngùng ẩn trong đó.",
  // },
];

export const eventOptions = [
  { value: "fc-event-primary", label: "Công ty" },
  { value: "fc-event-success", label: "Hội thảo" },
  { value: "fc-event-info", label: "Hội nghị" },
  { value: "fc-event-warning", label: "Cuộc họp" },
  { value: "fc-event-danger", label: "Tiệc công việc" },
  { value: "fc-event-pink", label: "Riêng tư" },
  { value: "fc-event-primary-dim", label: "Đấu giá" },
  { value: "fc-event-success-dim", label: "Sự kiện kết nối mạng" },
  { value: "fc-event-info-dim", label: "Ra mắt sản phểm" },
  { value: "fc-event-warning-dim", label: "Quỹ tăng" },
  { value: "fc-event-danger-dim", label: "Tài chợ" },
  { value: "fc-event-pink-dim", label: "Sự kiện thể thao" },
];
