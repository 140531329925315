import {DataTable} from "~/components/table/DataTable";
import {Table} from "reactstrap";
import {createColumn} from "~/pages/Admin/Modal/Tinhluong/Component";

import React, {useEffect} from "react";

    export const ColumnAllIProcessMaterial = ({titlePhucCap, titleBangLuong, dataGr, footerTotal,onColumnsChange,typeLuong,totalRow}) => { // Toan cong ty
        const colTotal = totalRow+1;
        const typeExp = typeLuong || 1;
        const colSpanCo = titlePhucCap?.filter((pca) => pca.apdungdongbaohiem === "CO").length;
        const phucCapCoBH = titlePhucCap?.filter((pca) => pca.apdungdongbaohiem === "CO");
        const colSpanKo = titlePhucCap?.filter((pca) => pca.apdungdongbaohiem === "KO").length;
        const phucCapKoBH = titlePhucCap?.filter((pca) => pca.apdungdongbaohiem === "KO");
        const createPhuCapColumns = (prefix, phuCap, colspan, rowspan, check) =>
            phuCap.filter((pca) => pca.apdungdongbaohiem === check).map((item) =>
                createColumn(prefix, item.id, `${item.header}`, (prefix === 'g2_' || prefix === 'listPhucCapKBH.' || prefix === 'listPhucCapCoBH.') ? 'EXITS' : '', colspan, rowspan)
            );
        const columnst = Array.from({length: colTotal}, (_, i) => i + 1).map((number) => {
                let numberCol = 0;
                if (number > 4) {
                    numberCol += number - 4;
                }
                return (
                    createColumn(
                        'g_numberCol' + numberCol,
                        'rowNumber' + numberCol,
                        '(' + Number(numberCol) + ')',
                        'EXITS',
                    )
                )
            }
        )
        
        const columns = [
            {
                header: [
                    createColumn('g_', 'rowNumber', 'STT', "", 1, 3),
                    createColumn('g_', 'hoten', 'Tên nhân viên', "", 1, 3),
                    createColumn('g_', 'uuid', 'Mã nhân viên', "", 1, 3),
                    createColumn('g_', 'chucvu', 'Chức danh', "", 1, 3),
                    createColumn('g_', 'mucluong', 'Lương ngày công', "", 1, 3),
                    createColumn('g_', 'listPhucCapCoBH', 'Phụ cấp Có BH', "", colSpanCo, 1),
                    createColumn('g_', 'listPhucCapKBH', 'Phụ cấp Ko BH', "", colSpanKo, 1),
                    createColumn('g_', 'thamnien', 'Phụ cấp thâm niên', "", 1, 3),
                    createColumn('g_', 'congNgay', 'Công Ngày', "", 1, 2),
                    createColumn('g_', 'congDem', 'Công Đêm', "", 1, 2),
                    createColumn('g_', 'tongTienPhuCap', 'Tổng lương', "", 1, 3),
                    createColumn('g_', 'giodaychuyen', 'Giờ dây chuyền', "", 1, 3),
                    createColumn('g_', 'luongdaychuyen', 'Lương dây chuyền', "", 1, 3),
                    createColumn('g_', 'luongNgayPhep', 'Lương nghỉ phép', "", 1, 3),
                    createColumn('g_', 'valueNgayLe', 'Lương nghỉ lễ', "", 1, 3),
                    createColumn('g_', 'lamthem', 'Làm Thêm(*)', "", 4, 1),
                    createColumn('g_', 'tongsotien', 'Tổng số tiền', "", 1, 3),
                    createColumn('g_', 'mucLuongDongbh', 'Quỹ lương đóng BH', "", 1, 3),
                    createColumn('g_', 'khautrusanluong', 'Khấu trừ (1)', "", 2, 1),
                    createColumn('g_', 'khautru', 'Khấu trừ (2)', "", 5, 1),
                    createColumn('g_', 'thuetonnamtruoc', 'Thuế 2023', "", 1, 3),
                    createColumn('g_', 'luongThucTeChuaThue', 'Thực lĩnh', "", 1, 3),
                    createColumn('g_', 'truvipham', 'Các khoản khác', "", 1, 3),
                    createColumn('g_', 'ungho', 'Ủng hộ quỹ MACĐ', "", 1, 3),
                    createColumn('g_', 'luongthuclinh', 'Thực lĩnh', "", 1, 3),
                    createColumn('g_', 'kynhan', 'Ký nhận', "", 1, 3)
                ]
            },
            {
                header: [
                    createColumn('g1_', 'rowNumber', 'STT', 'EXITS', 1, 1),
                    createColumn('g1_', 'hoten', '', 'EXITS', 1, 1),
                    createColumn('g1_', 'uuid', '', 'EXITS', 1, 1),
                    createColumn('g1_', 'chucvu', '', 'EXITS', 1, 1),
                    createColumn('g1_', 'mucluong', '', 'EXITS', 1, 1),
                    ...createPhuCapColumns('g1_', phucCapCoBH, 1, 2, "CO"),
                    ...createPhuCapColumns('g1_', phucCapKoBH, 1, 2, "KO"),
                    createColumn('g1_', 'thamnien', '', 'EXITS', 1, 1),
                    createColumn('g1_', 'congNgay', 'Công Ngày', 'EXITS', 1, 1),
                    createColumn('g1_', 'congDem', 'Công Đêm', 'EXITS', 1, 1),
                    createColumn('g1_', 'tongTienPhuCap', '', 'EXITS', 1, 1),
                    createColumn('g1_', 'giodaychuyen', '', 'EXITS', 1, 1),
                    createColumn('g1_', 'luongdaychuyen', '', 'EXITS', 1, 1),
                    createColumn('g1_', 'luongNgayPhep', '', 'EXITS', 1, 1),
                    createColumn('g1_', 'valueNgayLe', '', 'EXITS', 1, 1),
                    createColumn('g1_', 'lamthem_sogio', 'Số giờ', '', 3, 1),
                    createColumn('g1_', 'lamthem_sotien', 'Số tiền', '', 1, 2),
                    createColumn('g1_', 'tongsotien', '', 'EXITS', 1, 1),
                    createColumn('g1_', 'mucLuongDongbh', '', 'EXITS', 1, 1),
                    createColumn('g1_', 'khauTruSanLuong', 'Thiếu SL', '', 1, 2),
                    createColumn('g1_', 'khauTruGio', 'Thiếu Giờ', '', 1, 2),
                    createColumn('g1_', 'baohiemXaHoi', 'BHXH', '', 1, 2),
                    createColumn('g1_', 'baohiemThatNghiep', 'BHTN', '', 1, 2),
                    createColumn('g1_', 'baohiemYTe', 'BHYT', '', 1, 2),
                    createColumn('g1_', 'phiCongDoan', 'Đoàn Phí', '', 1, 2),
                    createColumn('g1_', 'thueTNCNphaiKT', 'Thuế TNCN', '', 1, 2),
                    createColumn('g1_', 'thuetonnamtruoc', '', 'EXITS', 1, 1),
                    createColumn('g1_', 'luongThucTeChuaThue', '', 'EXITS', 1, 1),
                    createColumn('g1_', 'truvipham', '', 'EXITS', 1, 1),
                    createColumn('g1_', 'ungho', '', 'EXITS', 1, 1),
                    createColumn('g1_', 'luongthuclinh', '', 'EXITS', 1, 1),
                    createColumn('g1_', 'kynhan', '', 'EXITS', 1, 1)
                ]
            },
            {
                header: [
                    createColumn('g2_', 'rowNumber', 'STT', 'EXITS', 1, 1),
                    createColumn('g2_', 'hoten', 'Họ và tên', 'EXITS', 1, 1),
                    createColumn('g2_', 'uuid', 'Mã nhân viên', 'EXITS', 1, 1),
                    createColumn('g2_', 'chucvu', 'Chức danh', 'EXITS', 1, 1),
                    createColumn('g2_', 'mucluong', 'Mức lương', 'EXITS', 1, 1),
                    ...createPhuCapColumns('g2_', phucCapCoBH, 1, 1, "CO"),
                    ...createPhuCapColumns('g2_', phucCapKoBH, 1, 1, "KO"),
                    createColumn('g2_', 'thamnien', 'Mức lương', 'EXITS', 1, 1),
                    createColumn('g2_', 'congNgay', 'HS 1', '', 1, 1),
                    createColumn('g2_', 'congDem', 'HS 1.3', '', 1, 1),
                    createColumn('g2_', 'tongTienPhuCap', 'Tổng lương', 'EXITS', 1, 1),
                    createColumn('g2_', 'giodaychuyen', 'Giờ dây chuyền', 'EXITS', 1, 1),
                    createColumn('g2_', 'luongdaychuyen', 'Lương dây chuyền', 'EXITS', 1, 1),
                    createColumn('g2_', 'luongNgayPhep', 'Lương nghỉ phép', 'EXITS', 1, 1),
                    createColumn('g2_', 'valueNgayLe', 'Lương nghỉ lễ', 'EXITS', 1, 1),
                    createColumn('g2_', 'lamthem_gioPhatSinh', 'Giờ PS', '', 1, 1),
                    createColumn('g2_', 'lamthem_thoiGianTangCaNgayThuong', 'Giờ Th 37.000', '', 1, 1),
                    createColumn('g2_', 'lamthem_thoiGianTangCaChuNhat', 'Giờ CN 45.000', '', 1, 1),
                    createColumn('g2_', 'lamthem_sotien', 'Lương làm thêm', 'EXITS', 1, 1),
                    createColumn('g2_', 'tongsotien', 'Tông số tiền', 'EXITS', 1, 1),
                    createColumn('g2_', 'mucLuongDongbh', 'Mức lương đóng BH', 'EXITS', 1, 1),
                    createColumn('g2_', 'khauTruSanLuong', 'Khấu trừ SL', 'EXITS', 1, 1),
                    createColumn('g2_', 'khauTruGio', 'Khấu trừ giờ', 'EXITS', 1, 1),
                    createColumn('g2_', 'baohiemXaHoi', 'BHXH', 'EXITS', 1, 1),
                    createColumn('g2_', 'baohiemThatNghiep', 'BHTN', 'EXITS', 1, 1),
                    createColumn('g2_', 'baohiemYTe', 'BHYT', 'EXITS', 1, 1),
                    createColumn('g2_', 'phiCongDoan', 'Phí công đoàn', 'EXITS', 1, 1),
                    createColumn('g2_', 'thueTNCNphaiKT', 'Thuế TNCN', 'EXITS', 1, 1),
                    createColumn('g2_', 'thuetonnamtruoc', 'Thuế tồn', 'EXITS', 1, 1),
                    createColumn('g2_', 'luongThucTeChuaThue', 'Lương thực lĩnh', 'EXITS', 1, 1),
                    createColumn('g2_', 'truvipham', 'Trừ vi phạm', 'EXITS', 1, 1),
                    createColumn('g2_', 'ungho', 'Trừ ủng hộ', 'EXITS', 1, 1),
                    createColumn('g2_', 'luongthuclinh', 'Lương thực lĩnh', 'EXITS', 1, 1),
                    createColumn('g2_', 'kynhan', 'Ký nhận', 'EXITS', 1, 1)
                ]
            },
            {
                header: [
                    createColumn('', 'rowNumber', 'STT', 'EXITS', 1, 1),
                    createColumn('Nhanvien.', 'hoten', '', 'EXITS', 1, 1),
                    createColumn('Nhanvien.', 'uuid', '', 'EXITS', 1, 1),
                    createColumn('Nhanvien.', 'chucvu', '', 'EXITS', 1, 1),
                    createColumn('Nhanvien.', 'mucluong', '', 'EXITS', 1, 1),
                    ...createPhuCapColumns('listPhucCapKBH.', phucCapCoBH, 1, 1, "CO"),
                    ...createPhuCapColumns('listPhucCapKBH.', phucCapKoBH, 1, 1, "KO"),
                    createColumn('', 'thamnien', 'Phụ cấp thâm niên', 'EXITS', 1, 1),
                    createColumn('Bangchamcongtonghopitem.', 'congNgay', 'HS 1', 'EXITS', 1, 1,),
                    createColumn('Bangchamcongtonghopitem.', 'congDem', 'HS 1.3', 'EXITS', 1, 1,),
                    createColumn('Bangchamcongtonghopitem.', 'tongTienPhuCap', 'Tổng lương', 'EXITS', 1, 1),
                    createColumn('Bangchamcongtonghopitem.', 'giodaychuyen', 'Giờ dây chuyền', 'EXITS', 1, 1),
                    createColumn('Bangchamcongtonghopitem.', 'luongdaychuyen', 'Lương dây chuyền', 'EXITS', 1, 1),
                    createColumn('', 'luongNgayPhep', 'Lương nghỉ phép', 'EXITS', 1, 1,),
                    createColumn('', 'valueNgayLe', 'Lương nghỉ lễ', 'EXITS', 1, 1),
                    createColumn('', 'gioPhatSinh', 'Giờ PS', 'EXITS', 1, 1),
                    createColumn('', 'lamthem_thoiGianTangCaNgayThuong', 'Giờ Th 37.000', 'EXITS', 1, 1),
                    createColumn('', 'lamthem_thoiGianTangCaChuNhat', 'Giờ CN 45.000', 'EXITS', 1, 1),
                    createColumn('', 'luongTangCa', 'Lương làm thêm', 'EXITS', 1, 1),
                    createColumn('', 'tongsotien', 'Tông số tiền', 'EXITS', 1, 1),
                    createColumn('', 'mucLuongDongbh', 'Mức lương đóng BH', 'EXITS', 1, 1),
                    createColumn('', 'khauTruSanLuong', 'Khấu trừ SL', 'EXITS', 1, 1),
                    createColumn('', 'khauTruGio', 'Khấu trừ giờ', 'EXITS', 1, 1),
                    createColumn('', 'baohiemXaHoi', 'BHXH', 'EXITS', 1, 1),
                    createColumn('', 'baohiemThatNghiep', 'BHTN', 'EXITS', 1, 1),
                    createColumn('', 'baohiemYTe', 'BHYT', 'EXITS', 1, 1),
                    createColumn('', 'phiCongDoan', 'Phí công đoàn', 'EXITS', 1, 1),
                    createColumn('', 'thueTNCNphaiKT', 'Thuế TNCN', 'EXITS', 1, 1),
                    createColumn('', 'thuetonnamtruoc', 'Thuế tồn', 'EXITS', 1, 1),
                    createColumn('', 'luongThucTeChuaThue', 'Lương thực lĩnh', 'EXITS', 1, 1),
                    createColumn('', 'truViPham', 'Trừ vi phạm', 'EXITS', 1, 1),
                    createColumn('', 'truUngHo', 'Trừ ủng hộ', 'EXITS', 1, 1),
                    createColumn('', 'luongThucLinh', 'Lương thực lĩnh', 'EXITS', 1, 1),
                    createColumn('', 'kynhan', 'Ký nhận', 'EXITS', 1, 1)
                ]
            }, {
                header: columnst
            }
        ];
        // console.log('columnsTest', columnsTest)
        useEffect(() => {
            onColumnsChange(columns);
        }, [typeExp, titlePhucCap]);
        
        const formatCurrency = (value) => (
            (Number(value) || 0).toLocaleString('de-DE',)
        );
        
        return <DataTable className="card-stretch" style={{zIndex: 0}}>
            <div className="card-inner position-relative card-tools-toggle">
                <div className="row">
                    <div className="col-12 mb-2">
                        <h4 className="card-title text-center">{titleBangLuong}</h4>
                    </div>
                    <div className="col-12 d-flex overflow-scroll">
                        <Table striped bordered hover vertical>
                            <thead>
                            {columns?.map((column, i) => {
                                const headerArr = column.header;
                                // console.log('headerArr', headerArr)
                                return (
                                    <tr key={i}>
                                        {headerArr.map((item, index) => {
                                            if (!item.parent) {
                                                return (
                                                    <th key={index} className={'align-middle text-center'}
                                                        colSpan={item.colspan}
                                                        rowSpan={item.rowspan}
                                                    >
                                                        {item.Header}
                                                    </th>
                                                )
                                            }
                                        })
                                        }
                                    </tr>
                                )
                            })}
                            <tr>
                                {
                                    Array.from({length: colTotal}, (_, i) => i + 1).map((number) => {
                                            let numberCol = 0;
                                            if (number > 4) {
                                                numberCol += number - 4;
                                            }
                                            return (
                                                <th key={numberCol} className={'align-middle text-center'}>
                                                    ({numberCol === 0 ? '-' : numberCol})
                                                </th>
                                            )
                                        }
                                    )
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {dataGr?.length > 0
                                ? dataGr?.map((items, indexs) => {
                                    let tongTienPhuCaps = 0;
                                    return (
                                        <>
                                            <tr>
                                                <td className={"bg-warning fw-bold text-center text-dark"}
                                                    colSpan={colTotal}>{items?.Phongban}</td>
                                            </tr>
                                            {items?.data?.map((item, index) => {
                                                let tongTienPhuCap = 0;
                                                item.luongDaKhauTru = Number(item?.luongThucTeChuaThue || 0) - Number(item?.baohiemXaHoi || 0) - Number(item?.baohiemThatNghiep || 0) - Number(item?.baohiemYTe || 0) - Number(item?.phiCongDoan || 0) - Number(item?.thueTNCNphaiKT || 0) - Number(item?.thueTonNamTruoc || 0);
                                                Object.entries(item?.listPhucCapCoBH).forEach(([key, value]) => {
                                                    if (key === "PC_HO_TRO_LAM_DEM") {
                                                        value = 0;
                                                    }
                                                    tongTienPhuCap += Number(value);
                                                    tongTienPhuCaps += Number(value);
                                                });
                                                Object.entries(item?.listPhucCapKBH).forEach(([key, value]) => {
                                                    if (key === "PC_HO_TRO_LAM_DEM") {
                                                        value = 0;
                                                    }
                                                    tongTienPhuCap += Number(value);
                                                    tongTienPhuCaps += Number(value);
                                                });
                                                return (
                                                    <tr key={index}>
                                                        <td className="text-center">{index + 1}</td>
                                                        <td><strong>{item?.Nhanvien?.hoten}</strong>
                                                            <br/> <small
                                                                className="text-danger">{item?.is_order === '0' ? '' : item?.is_order}</small><br/>
                                                            <small>{item?.is_order === '0' ? '' : item?.dateActive}</small>
                                                        </td>
                                                        <td>{item?.Nhanvien?.uuid}</td>
                                                        <td>{item?.Nhanvien?.Chucvu?.chucvu}</td>
                                                        <td className="text-center">{formatCurrency(item?.mucLuong)}</td>
                                                        {Object.values(item?.listPhucCapCoBH).map((value, indexs) => (
                                                            <td className="text-center"
                                                                key={indexs}>{formatCurrency(value)}</td>
                                                        ))}
                                                        
                                                        {Object.values(item?.listPhucCapKBH).map((value, indexs) => (
                                                            <td className="text-center"
                                                                key={indexs}>{formatCurrency(value)}</td>
                                                        ))}
                                                        <td className="text-center">{formatCurrency(item?.phuCapThamNien)}</td>
                                                        <td className="text-center">{formatCurrency(item?.luongNgay)} ({item?.congNgay})</td>
                                                        <td className="text-center">{formatCurrency(item?.luongDem)} ({item?.congDem})</td>
                                                        <td className="text-center">{formatCurrency(Number(item?.tongLuong))}</td>
                                                        <td className="text-center">{formatCurrency(item?.Bangchamcongtonghopitem?.gioDayChuyen)}</td>
                                                        <td className="text-center">{formatCurrency(item?.phuCapDayChuyen)}</td>
                                                        <td className="text-center">{formatCurrency(item?.luongNgayPhep)}</td>
                                                        <td className="text-center">{formatCurrency(item?.valueNgayLe)}</td>
                                                        <td className="text-center">{formatCurrency(item?.Bangchamcongtonghopitem?.gioPhatSinh)}</td>
                                                        <td className="text-center">{formatCurrency(item?.Bangchamcongtonghopitem?.thoiGianTangCaNgayThuong)}</td>
                                                        <td className="text-center">{formatCurrency(item?.Bangchamcongtonghopitem?.thoiGianTangCaChuNhat)}</td>
                                                        <td className="text-center">{formatCurrency(item?.luongTangCa)}</td>
                                                        <td className="text-center">{formatCurrency(item?.luongThucTeChuaThue)}</td>
                                                        <td className="text-center">{formatCurrency(item?.mucLuongDongbh)}</td>
                                                        <td className="text-center">{formatCurrency(item?.khauTruGio)}</td>
                                                        <td className="text-center">{formatCurrency(item?.khauTruSanLuong)}</td>
                                                        <td className="text-center">{formatCurrency(item?.baohiemXaHoi)}</td>
                                                        <td className="text-center">{formatCurrency(item?.baohiemThatNghiep)}</td>
                                                        <td className="text-center">{formatCurrency(item?.baohiemYTe)}</td>
                                                        <td className="text-center">{formatCurrency(item?.phiCongDoan)}</td>
                                                        <td className="text-center">{formatCurrency(item?.thueTNCNphaiKT)}</td>
                                                        <td className="text-center">{formatCurrency(item?.thueTonNamTruoc)}</td>
                                                        <td className="text-center">{formatCurrency(item?.luongDaKhauTru)}</td>
                                                        <td className="text-center">{formatCurrency(item?.truViPham)}</td>
                                                        <td className="text-center">{formatCurrency(item?.truUngHo)}</td>
                                                        <td className="text-center">{formatCurrency(item?.luongthuclinh)}</td>
                                                        {/*<td></td>*/}
                                                    </tr>
                                                );
                                            })}
                                            <tr className={"bg-success"}>
                                            <th className={"text-center text-bg-danger"}
                                                    colSpan={4}>Cộng
                                                </th>
                                                <td className={"text-center align-middle"}>{formatCurrency(items?.total?.mucluong)}</td>
                                                {titlePhucCap?.map((item, index) => {
                                                    return (
                                                        <td className={"text-center align-middle"}
                                                            key={index}>{formatCurrency(items?.total[item.id])}</td>
                                                    )
                                                })}
                                                <td className="text-center">{formatCurrency(items?.total?.thamnien)}</td>
                                                <td className="text-center">{formatCurrency(items?.total.congNgay)}</td>
                                                <td className="text-center">{formatCurrency(items?.total.congDem)}</td>
                                                <td className="text-center">{formatCurrency(Number(items?.total?.tongLuong))}</td>
                                                <td className="text-center">{formatCurrency(items?.gioDayChuyen)}</td>
                                                <td className="text-center">{formatCurrency(items?.phuCapDayChuyen)}</td>
                                                <td className="text-center">{formatCurrency(items?.total.luongNgayPhep)}</td>
                                                <td className="text-center">{formatCurrency(items?.total.valueNgayLe)}</td>
                                                <td className="text-center">{formatCurrency(items?.total.gioPhatSinh)}</td>
                                                <td className="text-center">{formatCurrency(items?.total.thoiGianTangCaNgayThuong)}</td>
                                                <td className="text-center">{formatCurrency(items?.total.thoiGianTangCaChuNhat)}</td>
                                                <td className="text-center">{formatCurrency(items?.total.luongTangCa)}</td>
                                                <td className="text-center">{formatCurrency(items?.total.luongThucTeChuaThue)}</td>
                                                <td className="text-center">{formatCurrency(items?.total.mucLuongDongbh)}</td>
                                                <td className="text-center">{formatCurrency(items?.total.khauTruGio)}</td>
                                                <td className="text-center">{formatCurrency(items?.total.khauTruSanLuong)}</td>
                                                <td className="text-center">{formatCurrency(items?.total.baohiemXaHoi)}</td>
                                                <td className="text-center">{formatCurrency(items?.total.baohiemThatNghiep)}</td>
                                                <td className="text-center">{formatCurrency(items?.total.baohiemYTe)}</td>
                                                <td className="text-center">{formatCurrency(items?.total.phiCongDoan)}</td>
                                                <td className="text-center">{formatCurrency(items?.total.thueTNCNphaiKT)}</td>
                                                <td className="text-center">{formatCurrency(items?.total.thuetonnamtruoc)}</td>
                                                <td className="text-center">{formatCurrency(items?.total.luongDaKhauTru)}</td>
                                                <td className="text-center">{formatCurrency(items?.total.truViPham)}</td>
                                                <td className="text-center">{formatCurrency(items?.total.truUngHo)}</td>
                                                <td className="text-center">{formatCurrency(items?.total.luongthuclinh)}</td>
                                                <td className="text-center"></td>
                                            </tr>
                                        </>
                                    );
                                }) : null}
                            </tbody>
                            <tfoot>
                            <tr className={"bg-warning"}>
                                <th className={"text-center align-middle"} colSpan={4}>Tổng Cộng</th>
                                <td className={"text-center align-middle"}>{formatCurrency(footerTotal?.mucluong)}</td>
                                {titlePhucCap?.map((item, index) => {
                                    return (
                                        <td className={"text-center align-middle"}
                                            key={index}>{formatCurrency(footerTotal[item.id] || 0)}</td>
                                    )
                                })}
                                <td className="text-center">{formatCurrency(footerTotal?.thamnien)}</td>
                                <td className="text-center">{formatCurrency(footerTotal?.congNgay)}</td>
                                <td className="text-center">{formatCurrency(footerTotal?.congDem)}</td>
                                <td className="text-center">{formatCurrency(Number(footerTotal?.tongLuong || 0))}</td>
                                <td className="text-center">{formatCurrency(footerTotal?.gioDayChuyen)}</td>
                                <td className="text-center">{formatCurrency(footerTotal?.phuCapDayChuyen)}</td>
                                <td className="text-center">{formatCurrency(footerTotal?.luongNgayPhep)}</td>
                                <td className="text-center">{formatCurrency(footerTotal?.valueNgayLe)}</td>
                                <td className="text-center">{formatCurrency(footerTotal?.gioPhatSinh)}</td>
                                <td className="text-center">{formatCurrency(footerTotal?.thoiGianTangCaNgayThuong)}</td>
                                <td className="text-center">{formatCurrency(footerTotal?.thoiGianTangCaChuNhat)}</td>
                                <td className="text-center">{formatCurrency(footerTotal?.luongTangCa)}</td>
                                <td className="text-center">{formatCurrency(footerTotal?.luongThucTeChuaThue)}</td>
                                <td className="text-center">{formatCurrency(footerTotal?.mucLuongDongbh)}</td>
                                <td className="text-center">{formatCurrency(footerTotal?.khauTruGio)}</td>
                                <td className="text-center">{formatCurrency(footerTotal?.khauTruSanLuong)}</td>
                                <td className="text-center">{formatCurrency(footerTotal?.baohiemXaHoi)}</td>
                                <td className="text-center">{formatCurrency(footerTotal?.baohiemThatNghiep)}</td>
                                <td className="text-center">{formatCurrency(footerTotal?.baohiemYTe)}</td>
                                <td className="text-center">{formatCurrency(footerTotal?.phiCongDoan)}</td>
                                <td className="text-center">{formatCurrency(footerTotal?.thueTNCNphaiKT)}</td>
                                <td className="text-center">{formatCurrency(footerTotal?.thueTonNamTruoc)}</td>
                                <td className="text-center">{formatCurrency(footerTotal?.luongThucTeChuaThue)}</td>
                                <td className="text-center">{formatCurrency(footerTotal?.valueKhenThuong)}</td>
                                <td className="text-center">{formatCurrency(footerTotal?.truViPham)}</td>
                                <td className="text-center">{formatCurrency(footerTotal?.luongthuclinh)}</td>
                                <td className="text-center"></td>
                            </tr>
                            </tfoot>
                        </Table>
                    </div>
                </div>
            
            </div>
        </DataTable>
};
