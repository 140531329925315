import authReducer from "./authReducer";
import userReducer from "./userReducer";
import nhanvienReducer from "./nhanvienReducer";
import congtyReducer from "./congtyReducer";
import loaiquydinhphucloiReducer from "./loaiquydinhphucloiReducer";
import kieuluongReducer from "./kieuluongReducer";
import phongbanReducer from "./phongbanReducer";
import maychamcongReducer from "./maychamcongReducer";
import congthuctinhluongReducer from "./congthuctinhluongReducer";
import chucvuReducer from "./chucvuReducer";
import ungvienReducer from "./ungvienReducer";
import danhgiaReducer from "./danhgiaReducer";
import tangcaReducer from "./tangcaReducer";
import trangthaiReducer from "./trangthaiReducer";
import nganhangReducer from "./nganhangReducer";
import nguoithannhanvienReducer from "./nguoithannhanvienReducer";
import bangchotcongReducer from "./bangchotcongReducer";
import bangchamcongtonghopReducer from "./bangchamcongtonghopReducer";
// import calamviecReducer from "./calamviecReducer";
import quydinhthuongphatReducer from "./quydinhthuongphatReducer";
import nghiphepcongtacReducer from "./nghiphepcongtacReducer";
import vanbanReducer from "./vanbanReducer";
import suatanReducer from "./suatanReducer";
import bocauhoiphongvanReducer from "./bocauhoiphongvanReducer";
import vitrituyendungReducer from "./vitrituyendungReducer";
import cauhinhnghileReducer from "./cauhinhnghileReducer";
import giaoviecReducer from "./giaoviecReducer";
import hopdonglaodongReducer from "./hopdonglaodongReducer";
import cauhinhcalamviecReducer from "./cauhinhcalamviecReducer";
import binhluanReducer from "./binhluanReducer";
import dexuatReducer from "./dexuatReducer";
import taisanReducer from "./taisanReducer";
import phucapReducer from "./phucapReducer";
import cauhinhtangcaReducer from "./cauhinhtangcaReducer";
import bangchamcongReducer from "./bangchamcongReducer";
import baohiemnhanvienReducer from "./baohiemnhanvienReducer";
import chinhanhReducer from "./chinhanhReducer";
import tamungluongReducer from "./tamungluongReducer";
import quanlyReducer from "./quanlyReducer";
// import postReducer from "./postReducer";
import appReducer from "./appReducer";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import { persistReducer } from "redux-persist";
import bangtonghopphepReducer from "~/store/reducers/bangtonghopphepReducer";
import payrollReducer from "~/store/reducers/payrollReducer";



const commonConfig = {
    storage,
    stateReconciler: autoMergeLevel2
}

const authConfig = {
    ...commonConfig,
    key: 'auth',
    whitelist: ['isLoggedIn', 'token']
}

const rootReducer = combineReducers({
    auth: persistReducer(authConfig, authReducer),
    app: appReducer,
    user: userReducer,
    nhanvien: nhanvienReducer,
    congty: congtyReducer,
    loaiquydinhphucloi: loaiquydinhphucloiReducer,
    kieuluong: kieuluongReducer,
    phongban: phongbanReducer,
    maychamcong: maychamcongReducer,
    congthuctinhluong: congthuctinhluongReducer,
    chucvu: chucvuReducer,
    bangchotcong: bangchotcongReducer,
    bangchamcongtonghop: bangchamcongtonghopReducer,
    payroll: payrollReducer,
    ungvien: ungvienReducer,
    danhgia: danhgiaReducer,
    tangca: tangcaReducer,
    quydinhthuongphat: quydinhthuongphatReducer,
    nghiphepcongtac: nghiphepcongtacReducer,
    vanban: vanbanReducer,
    suatan: suatanReducer,
    bocauhoiphongvan: bocauhoiphongvanReducer,
    vitrituyendung: vitrituyendungReducer,
    cauhinhnghile: cauhinhnghileReducer,
    giaoviec: giaoviecReducer,
    hopdonglaodong: hopdonglaodongReducer,
    cauhinhcalamviec: cauhinhcalamviecReducer,
    binhluan: binhluanReducer,
    dexuat: dexuatReducer,
    taisan: taisanReducer,
    phucap: phucapReducer,
    cauhinhtangca: cauhinhtangcaReducer,
    cauhinhtrangthai: trangthaiReducer,
    bangchamcong: bangchamcongReducer,
    baohiemnhanvien: baohiemnhanvienReducer,
    chinhanh: chinhanhReducer,
    tamungluong: tamungluongReducer,
    taikhoannganhang: nganhangReducer,
    nguoithannhanvien: nguoithannhanvienReducer,
    quanly: quanlyReducer,
    bangtonghopphep: bangtonghopphepReducer,
    // calamviec: calamviecReducer,
    // post: postReducer,
    
})

export default rootReducer