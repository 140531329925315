import actionTypes from '../actions/actionTypes';
const initState = {
    cauhinhthuethunhapcnlt: [],
    countcauhinhthuethunhapcnlt: 0,
    newcauhinhthuethunhapcnlt: [],

    danhgianhanvien: [],
    countdanhgianhanvien: 0,
    newdanhgianhanvien: [],

    hoatdongsukien: [],
    counthoatdongsukien: 0,
    newhoatdongsukien: [],

    kiemdinhatvsbhld: [],
    countkiemdinhatvsbhld: 0,
    newkiemdinhatvsbhld: [],

    loaikiemdinhatvsbhld: [],
    countloaikiemdinhatvsbhld: 0,
    newloaikiemdinhatvsbhld: [],

    moiquanhelaodong: [],
    countmoiquanhelaodong: 0,
    newmoiquanhelaodong: [],

    moiquanhenhanvien: [],
    countmoiquanhenhanvien: 0,
    newmoiquanhenhanvien: [],

    nhanvienkiemdinhatvsbhld: [],
    countnhanvienkiemdinhatvsbhld: 0,
    newnhanvienkiemdinhatvsbhld: [],

    phuongtien: [],
    countphuongtien: 0,
    newphuongtien: [],

    quanlyquythuong: [],
    countquanlyquythuong: 0,
    newquanlyquythuong: [],

    quanlythamnien: [],
    countquanlythamnien: 0,
    newquanlythamnien: [],

    thongbaonoibo: [],
    countthongbaonoibo: 0,
    newthongbaonoibo: [],

    vanthu: [],
    countvanthu: 0,
    newvanthu: [],

    xeploai: [],
    countxeploai: 0,
    newxeploai: [],

    xeploainhanvien: [],
    countxeploainhanvien: 0,
    newxeploainhanvien: [],

    cauhinhnghiphep: [],
    countcauhinhnghiphep: 0,
    newcauhinhnghiphep: [],

    lydonghiphep: [],
    countlydonghiphep: 0,
    newlydonghiphep: [],

    thongbao: [],
    countthongbao: 0,
    newthongbao: [],

    phanca: [],
    countphanca: 0,
    newphanca: [],

    cauhinhpheduyet: [],
    countcauhinhpheduyet: 0,
    newcauhinhpheduyet: [],
};

const quanlyReducer = (state = initState, action) => {
    switch (action.type) {
        //cấu hình thuế
        case actionTypes.GET_LIST_CAU_HINH_THUE_THU_NHAP_CNLT:
            return {
                ...state,
                cauhinhthuethunhapcnlt: action?.cauhinhthuethunhapcnlt?.rows || [],
                countcauhinhthuethunhapcnlt: action?.cauhinhthuethunhapcnlt?.count || 0,
            };
        case actionTypes.POST_NEW_CAU_HINH_THUE_THU_NHAP_CNLT:
        case actionTypes.PUT_EDIT_CAU_HINH_THUE_THU_NHAP_CNLT:
        case actionTypes.DELETE_CAU_HINH_THUE_THU_NHAP_CNLT:
            return {
                ...state,
                newcauhinhthuethunhapcnlt: action?.newcauhinhthuethunhapcnlt || [],
            };
        //Đánh giá nhân viên
        case actionTypes.GET_LIST_DANH_GIA_NHAN_VIEN:
            return {
                ...state,
                danhgianhanvien: action?.danhgianhanvien?.rows || [],
                countdanhgianhanvien: action?.danhgianhanvien?.count || 0,
            };
        case actionTypes.POST_NEW_DANH_GIA_NHAN_VIEN:
        case actionTypes.PUT_EDIT_DANH_GIA_NHAN_VIEN:
        case actionTypes.DELETE_DANH_GIA_NHAN_VIEN:
            return {
                ...state,
                newdanhgianhanvien: action?.newdanhgianhanvien || [],
            };
        //Hoạt động sự kiện
        case actionTypes.GET_LIST_HOAT_DONG_SU_KIEN:
            return {
                ...state,
                hoatdongsukien: action?.hoatdongsukien?.rows || [],
                counthoatdongsukien: action?.hoatdongsukien?.count || 0,
            };
        case actionTypes.POST_NEW_HOAT_DONG_SU_KIEN:
        case actionTypes.PUT_EDIT_HOAT_DONG_SU_KIEN:
        case actionTypes.DELETE_HOAT_DONG_SU_KIEN:
            return {
                ...state,
                newhoatdongsukien: action?.newhoatdongsukien || [],
            };
        //Kiểm định an toàn
        case actionTypes.GET_LIST_KIEM_DINH_ATVSBHLD:
            return {
                ...state,
                kiemdinhatvsbhld: action?.kiemdinhatvsbhld?.rows || [],
                countkiemdinhatvsbhld: action?.kiemdinhatvsbhld?.count || 0,
            };
        case actionTypes.POST_NEW_KIEM_DINH_ATVSBHLD:
        case actionTypes.PUT_EDIT_KIEM_DINH_ATVSBHLD:
        case actionTypes.DELETE_KIEM_DINH_ATVSBHLD:
            return {
                ...state,
                newkiemdinhatvsbhld: action?.newkiemdinhatvsbhld || [],
            };
        //Kiểm định an toàn
        case actionTypes.GET_LIST_LOAI_KIEM_DINH_ATVSBHLD:
            return {
                ...state,
                loaikiemdinhatvsbhld: action?.loaikiemdinhatvsbhld?.rows || [],
                countloaikiemdinhatvsbhld: action?.loaikiemdinhatvsbhld?.count || 0,
            };
        case actionTypes.POST_NEW_LOAI_KIEM_DINH_ATVSBHLD:
        case actionTypes.PUT_EDIT_LOAI_KIEM_DINH_ATVSBHLD:
        case actionTypes.DELETE_LOAI_KIEM_DINH_ATVSBHLD:
            return {
                ...state,
                newloaikiemdinhatvsbhld: action?.newloaikiemdinhatvsbhld || [],
            };
        //Kiểm định an toàn
        case actionTypes.GET_LIST_MOI_QUAN_HE_LAO_DONG:
            return {
                ...state,
                moiquanhelaodong: action?.moiquanhelaodong?.rows || [],
                countmoiquanhelaodong: action?.moiquanhelaodong?.count || 0,
            };
        case actionTypes.POST_NEW_MOI_QUAN_HE_LAO_DONG:
        case actionTypes.PUT_EDIT_MOI_QUAN_HE_LAO_DONG:
        case actionTypes.DELETE_MOI_QUAN_HE_LAO_DONG:
            return {
                ...state,
                newmoiquanhelaodong: action?.newmoiquanhelaodong || [],
            };
        //Kiểm định an toàn
        case actionTypes.GET_LIST_MOI_QUAN_HE_NHAN_VIEN:
            return {
                ...state,
                moiquanhenhanvien: action?.moiquanhenhanvien?.rows || [],
                countmoiquanhenhanvien: action?.moiquanhenhanvien?.count || 0,
            };
        case actionTypes.POST_NEW_MOI_QUAN_HE_NHAN_VIEN:
        case actionTypes.PUT_EDIT_MOI_QUAN_HE_NHAN_VIEN:
        case actionTypes.DELETE_MOI_QUAN_HE_NHAN_VIEN:
            return {
                ...state,
                newmoiquanhenhanvien: action?.newmoiquanhenhanvien || [],
            };
        //Kiểm định an toàn
        case actionTypes.GET_LIST_NHAN_VIEN_KIEM_DINH_ATVSBHLD:
            return {
                ...state,
                nhanvienkiemdinhatvsbhld: action?.nhanvienkiemdinhatvsbhld?.rows || [],
                countnhanvienkiemdinhatvsbhld: action?.nhanvienkiemdinhatvsbhld?.count || 0,
            };
        case actionTypes.POST_NEW_NHAN_VIEN_KIEM_DINH_ATVSBHLD:
        case actionTypes.PUT_EDIT_NHAN_VIEN_KIEM_DINH_ATVSBHLD:
        case actionTypes.DELETE_NHAN_VIEN_KIEM_DINH_ATVSBHLD:
            return {
                ...state,
                newnhanvienkiemdinhatvsbhld: action?.newnhanvienkiemdinhatvsbhld || [],
            };
        //Kế hoắch của xe
        case actionTypes.GET_LIST_KE_HOACH_CUA_XE:
            return {
                ...state,
                kehoachcuaxe: action?.kehoachcuaxe?.rows || [],
                countkehoachcuaxe: action?.kehoachcuaxe?.count || 0,
            };
        case actionTypes.POST_NEW_KE_HOACH_CUA_XE:
        case actionTypes.PUT_EDIT_KE_HOACH_CUA_XE:
        case actionTypes.DELETE_KE_HOACH_CUA_XE:
            return {
                ...state,
                newkehoachcuaxe: action?.newkehoachcuaxe || [],
            };
        //Đăng kiểm phương tiện
        case actionTypes.GET_LIST_DANG_KIEM_PHUONG_TIEN:
            return {
                ...state,
                dangkiemphuongtien: action?.dangkiemphuongtien?.rows || [],
                countdangkiemphuongtien: action?.dangkiemphuongtien?.count || 0,
            };
        case actionTypes.POST_NEW_DANG_KIEM_PHUONG_TIEN:
        case actionTypes.PUT_EDIT_DANG_KIEM_PHUONG_TIEN:
        case actionTypes.DELETE_DANG_KIEM_PHUONG_TIEN:
            return {
                ...state,
                newdangkiemphuongtien: action?.newdangkiemphuongtien || [],
            };
        //Bảo dưỡng phương tiện
        case actionTypes.GET_LIST_BAO_DUONG_PHUONG_TIEN:
            return {
                ...state,
                baoduongphuongtien: action?.baoduongphuongtien?.rows || [],
                countbaoduongphuongtien: action?.baoduongphuongtien?.count || 0,
            };
        case actionTypes.POST_NEW_BAO_DUONG_PHUONG_TIEN:
        case actionTypes.PUT_EDIT_BAO_DUONG_PHUONG_TIEN:
        case actionTypes.DELETE_BAO_DUONG_PHUONG_TIEN:
            return {
                ...state,
                newbaoduongphuongtien: action?.newbaoduongphuongtien || [],
            };
        //Loại phí phụ
        case actionTypes.GET_LIST_PHI_PHU:
            return {
                ...state,
                phiphu: action?.phiphu?.rows || [],
                countphiphu: action?.phiphu?.count || 0,
            };
        case actionTypes.POST_NEW_PHI_PHU:
        case actionTypes.PUT_EDIT_PHI_PHU:
        case actionTypes.DELETE_PHI_PHU:
            return {
                ...state,
                newphiphu: action?.newphiphu || [],
            };
        //Loại Phương tiện
        case actionTypes.GET_LIST_LOAI_PHUONG_TIEN:
            return {
                ...state,
                loaiphuongtien: action?.loaiphuongtien?.rows || [],
                countloaiphuongtien: action?.loaiphuongtien?.count || 0,
            };
        case actionTypes.POST_NEW_LOAI_PHUONG_TIEN:
        case actionTypes.PUT_EDIT_LOAI_PHUONG_TIEN:
        case actionTypes.DELETE_LOAI_PHUONG_TIEN:
            return {
                ...state,
                newloaiphuongtien: action?.newloaiphuongtien || [],
            };
        //Phương tiện
        case actionTypes.GET_LIST_PHUONG_TIEN:
            return {
                ...state,
                phuongtien: action?.phuongtien?.rows || [],
                countphuongtien: action?.phuongtien?.count || 0,
            };
        case actionTypes.POST_NEW_PHUONG_TIEN:
        case actionTypes.PUT_EDIT_PHUONG_TIEN:
        case actionTypes.DELETE_PHUONG_TIEN:
            return {
                ...state,
                newphuongtien: action?.newphuongtien || [],
            };
        //Kiểm định an toàn
        case actionTypes.GET_LIST_QUAN_LY_QUY_THUONG:
            return {
                ...state,
                quanlyquythuong: action?.quanlyquythuong?.rows || [],
                countquanlyquythuong: action?.quanlyquythuong?.count || 0,
            };
        case actionTypes.POST_NEW_QUAN_LY_QUY_THUONG:
        case actionTypes.PUT_EDIT_QUAN_LY_QUY_THUONG:
        case actionTypes.DELETE_QUAN_LY_QUY_THUONG:
            return {
                ...state,
                newquanlyquythuong: action?.newquanlyquythuong || [],
            };
        //Kiểm định an toàn
        case actionTypes.GET_LIST_QUAN_LY_THAM_NIEM:
            return {
                ...state,
                quanlythamnien: action?.quanlythamnien?.rows || [],
                countquanlythamnien: action?.quanlythamnien?.count || 0,
            };
        case actionTypes.POST_NEW_QUAN_LY_THAM_NIEM:
        case actionTypes.PUT_EDIT_QUAN_LY_THAM_NIEM:
        case actionTypes.DELETE_QUAN_LY_THAM_NIEM:
            return {
                ...state,
                newquanlythamnien: action?.newquanlythamnien || [],
            };
        //Kiểm định an toàn
        case actionTypes.GET_LIST_THONG_BAO_NOI_BO:
            return {
                ...state,
                thongbaonoibo: action?.thongbaonoibo?.rows || [],
                countthongbaonoibo: action?.thongbaonoibo?.count || 0,
            };
        case actionTypes.POST_NEW_THONG_BAO_NOI_BO:
        case actionTypes.PUT_EDIT_THONG_BAO_NOI_BO:
        case actionTypes.DELETE_THONG_BAO_NOI_BO:
            return {
                ...state,
                newthongbaonoibo: action?.newthongbaonoibo || [],
            };
        //Kiểm định an toàn
        case actionTypes.GET_LIST_VAN_THU:
            return {
                ...state,
                vanthu: action?.vanthu?.rows || [],
                countvanthu: action?.vanthu?.count || 0,
            };
        case actionTypes.POST_NEW_VAN_THU:
        case actionTypes.PUT_EDIT_VAN_THU:
        case actionTypes.DELETE_VAN_THU:
            return {
                ...state,
                newvanthu: action?.newvanthu || [],
            };
        //Kiểm định an toàn
        case actionTypes.GET_LIST_XEP_LOAI:
            return {
                ...state,
                xeploai: action?.xeploai?.rows || [],
                countxeploai: action?.xeploai?.count || 0,
            };
        case actionTypes.POST_NEW_XEP_LOAI:
        case actionTypes.PUT_EDIT_XEP_LOAI:
        case actionTypes.DELETE_XEP_LOAI:
            return {
                ...state,
                newxeploai: action?.newxeploai || [],
            };
        //Kiểm định an toàn
        case actionTypes.GET_LIST_XEP_LOAI_NHAN_VIEN:
            return {
                ...state,
                xeploainhanvien: action?.xeploainhanvien?.rows || [],
                countxeploainhanvien: action?.xeploainhanvien?.count || 0,
            };
        case actionTypes.POST_NEW_XEP_LOAI_NHAN_VIEN:
        case actionTypes.PUT_EDIT_XEP_LOAI_NHAN_VIEN:
        case actionTypes.DELETE_XEP_LOAI_NHAN_VIEN:
            return {
                ...state,
                newxeploainhanvien: action?.newxeploainhanvien || [],
            };
        // Cấu hình nghỉ phép
        case actionTypes.GET_LIST_CAU_HINH_NGHI_PHEP:
            return {
                ...state,
                cauhinhnghiphep: action?.cauhinhnghiphep?.rows || [],
                countcauhinhnghiphep: action?.cauhinhnghiphep?.count || 0,
            };
        case actionTypes.POST_NEW_CAU_HINH_NGHI_PHEP:
        case actionTypes.PUT_EDIT_CAU_HINH_NGHI_PHEP:
        case actionTypes.DELETE_CAU_HINH_NGHI_PHEP:
            return {
                ...state,
                newcauhinhnghiphep: action?.newcauhinhnghiphep || [],
            };
        // Lý do nghỉ phép
        case actionTypes.GET_LIST_LY_DO_NGHI_PHEP:
            return {
                ...state,
                lydonghiphep: action?.lydonghiphep?.rows || [],
                countlydonghiphep: action?.lydonghiphep?.count || 0,
            };
        case actionTypes.POST_NEW_LY_DO_NGHI_PHEP:
        case actionTypes.PUT_EDIT_LY_DO_NGHI_PHEP:
        case actionTypes.DELETE_LY_DO_NGHI_PHEP:
            return {
                ...state,
                newlydonghiphep: action?.newlydonghiphep || [],
            };
        // Thông báo
        case actionTypes.GET_LIST_THONG_BAO:
            return {
                ...state,
                thongbao: action?.thongbao?.rows || [],
                countthongbao: action?.thongbao?.count || 0,
            };
        case actionTypes.POST_NEW_THONG_BAO:
        case actionTypes.PUT_EDIT_THONG_BAO:
        case actionTypes.DELETE_THONG_BAO:
            return {
                ...state,
                newthongbao: action?.newthongbao || [],
            };
        // Phân ca
        case actionTypes.GET_LIST_PHAN_CA:
            return {
                ...state,
                phanca: action?.phanca?.rows || [],
                countphanca: action?.phanca?.count || 0,
            };
        case actionTypes.POST_NEW_PHAN_CA:
        case actionTypes.PUT_EDIT_PHAN_CA:
        case actionTypes.DELETE_PHAN_CA:
            return {
                ...state,
                newphanca: action?.newphanca || [],
            };
        // Cấu hình phê duyệt
        case actionTypes.GET_LIST_CAU_HINH_PHE_DUYET:
            return {
                ...state,
                cauhinhpheduyet: action?.cauhinhpheduyet?.rows || [],
                countcauhinhpheduyet: action?.cauhinhpheduyet?.count || 0,
            };
        case actionTypes.POST_NEW_CAU_HINH_PHE_DUYET:
        case actionTypes.PUT_EDIT_CAU_HINH_PHE_DUYET:
        case actionTypes.DELETE_CAU_HINH_PHE_DUYET:
            return {
                ...state,
                newcauhinhpheduyet: action?.newcauhinhpheduyet || [],
            };
        default:
            return state;
    }
};

export default quanlyReducer;
