import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {InputForm, Button} from '~/components/Form'
import * as actions from '~/store/actions'
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import {
    dataSelectLock,
    dataSelectStatus,
} from '~/utils/Datacustom'
import {validateTangCaNhanVien} from "~/request/validateTangCaNhanVien";
import {validateDeXuat} from "~/request/validateDeXuat";
import {format} from "date-fns";

const ModalEditTangca = ({show, setShow, dataTangca, idNhanvien, uuid}) => {
    let fieldList = {
        NhanvienId: idNhanvien,
        ngaybatdau: '',
        ngayketthuc: '',
        giobatdau: '',
        gioketthuc: '',
        nguoitao: '',
        TrangthaiId: '',
        CauhinhtangcaId: ''
    }
    const [listTrangthai, setlistTrangthai] = useState([]);
    
    const [invalidFields, setInvalidFields] = useState([])
    const {cauhinhtangca} = useSelector(state => state.cauhinhtangca)
    const dispatch = useDispatch()
    const [listCauhinhtangca, setlistCauhinhtangca] = useState([])
    const [payload, setPayload] = useState(fieldList);
    // console.log('payload', payload)
    useEffect(() => {
        if (show && dataTangca !== null) {
            let dataStatus = dataSelectStatus.find((item) => {
                return item && item.value === dataTangca?.TrangthaiId;
            });
            setPayload({
                id: dataTangca?.id,
                NhanvienId: idNhanvien,
                CauhinhtangcaId: {label: dataTangca?.Cauhinhtangca?.title, value: dataTangca?.Cauhinhtangca?.id},
                ngaybatdau: format(new Date(`${dataTangca.ngaybatdau} ${dataTangca.giobatdau}`), 'yyyy-MM-dd HH:mm'),
                ngayketthuc: format(new Date(`${dataTangca.ngayketthuc} ${dataTangca.gioketthuc}`), 'yyyy-MM-dd HH:mm'),
                nguoitao: '',
                TrangthaiId: dataStatus
            });
        }
    }, [show, dataTangca]);
    
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayload(prev => ({...prev, [statename]: selected}))
        
    }
    
    useEffect(() => {
        let dataSelectCauhinhTangca = cauhinhtangca.map((item) => ({label: item.title, value: item.id}));
        setlistCauhinhtangca(dataSelectCauhinhTangca)
        let TrangthaiId = dataSelectStatus.map((item) => ({label: item.label, value: item.value}));
        setlistTrangthai(TrangthaiId);
    }, [cauhinhtangca, dataSelectStatus])
    
    const handleSubmit = async () => {
        let invalids = validate(payload)
        if (invalids === 0) {
            let dateBatDau = format(new Date(payload.ngaybatdau), 'yyyy-MM-dd');
            let timeBatDau = format(new Date(payload.ngaybatdau), 'HH:mm');
            let dateKetThuc = format(new Date(payload.ngayketthuc), 'yyyy-MM-dd');
            let timeKetThuc = format(new Date(payload.ngayketthuc), 'HH:mm');
            let data = {
                id: payload?.id,
                NhanvienId: idNhanvien,
                CauhinhtangcaId: payload?.CauhinhtangcaId?.value,
                ngaybatdau: dateBatDau,
                ngayketthuc: dateKetThuc,
                giobatdau: timeBatDau,
                gioketthuc: timeKetThuc,
                TrangthaiId: payload.TrangthaiId.value,
            }
            dispatch(actions.EditTangCa(data, uuid))
            setShow(false);
        }
    }
    const validate = (payload) => {
        let invalids = 0
        let fields = Object.entries(payload)
        fields.forEach(item => {
            if (validateTangCaNhanVien(item[0], item[1]) !== undefined) {
                setInvalidFields(prev => [...prev, validateTangCaNhanVien(item[0], item[1])])
                invalids++
            }
        })
        return invalids
    }
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    {dataTangca === null ? 'Thêm mới tăng ca' : 'Cập nhật tăng ca'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-6">
                        <label className="mb-2 fw-bold">Hình thức tăng ca</label>
                        <Select
                            value={payload.CauhinhtangcaId}
                            onChange={handleOnSelect}
                            options={listCauhinhtangca}
                            name='CauhinhtangcaId'
                            onFocus={() => setInvalidFields([])}
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'CauhinhtangcaId') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'CauhinhtangcaId')?.message}
                            </small>
                        )}
                    
                    </div>
                    <div className="form-group col-md-6">
                        <label className="mb-2"> Trạng thái</label>
                        <Select
                            value={payload.TrangthaiId}
                            onChange={handleOnSelect}
                            options={listTrangthai}
                            name='TrangthaiId'
                            onFocus={() => setInvalidFields([])}
                        />
                        {invalidFields.length > 0 && invalidFields.some(i => i.name === 'TrangthaiId') && <small
                            className='text-danger'>{invalidFields.find(i => i.name === 'TrangthaiId')?.message}</small>}
                    
                    </div>
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ngày bắt đầu'}
                            value={payload.ngaybatdau}
                            setValue={setPayload}
                            keyPayload={'ngaybatdau'}
                            type="datetime-local"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ngày kết thúc'}
                            value={payload.ngayketthuc}
                            setValue={setPayload}
                            keyPayload={'ngayketthuc'}
                            type="datetime-local"
                            validate
                        />
                    </div>
                </div>
                <div className='col-12 mt-5'>
                    <Button
                        text={'Cập nhật'}
                        bClass='btn btn-primary px-5'
                        textColor
                        onClick={handleSubmit}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ModalEditTangca;

