import axiosConfig from '../axiosConfig'

export const apiGetVanban = () => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v1/vanban`,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiNewVanban = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v1/vanban',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiEditVanban = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v1/vanban',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiDeleteVanban = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v1/vanban',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})

export const apiVanbanHopdong = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v1/vanban/hopdong',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})