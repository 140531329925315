import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { InputForm } from '~/components/Form';
import * as actions from '~/store/actions';
import { dataSelectKieuPhep } from '~/utils/Datacustom';

import '../Modal.scss';
import Select from 'react-select';
import {
    Icon,
    SelectFinNhanvienfix,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    Button,
} from '~/components/Component';
import { Modal, ModalBody, Input, Alert } from 'reactstrap';
import { validateNghiphepcongtac } from '~/request/validateNghiphepcongtac';
import { dataStatusNghiphep } from '~/utils/Datacustom';
import { FileAllCodeContext } from '../../user-manage/FileAllCodeContext';
import { ModalSelectType } from '~/pages/Admin/Modal';
import { apiDuyetphep, apiEditNghiphepcongtac } from '~/services';
import { format, parseISO, isBefore, isEqual, addMonths } from 'date-fns';
import DatePicker from 'react-datepicker';
import { apiGetPhancatonghop } from '~/services';
import { toast } from 'react-toastify';

const ModalEditNghiphepcongtac = ({ show, setShow, dataIdNghiphepcongtac, fieldList, title, type, dataCongtac }) => {
    // console.log('dataIdNghi::::::::',dataIdNghiphepcongtac)
    const { handleThongbaoDataSocket } = useContext(FileAllCodeContext);
    // const { user } = useSelector((state) => state.app);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.getListCauhinhpheduyet());
    }, []);
    // const {editnghiphepcongtac} = useSelector((state) => state.nghiphepcongtac);
    const { lydonghiphep, cauhinhpheduyet } = useSelector((state) => state.quanly);
    const [listCongtac, setlistCongtac] = useState([]);
    const [quytrinh, setQuytrinh] = useState(true);
    const [dataduyet, setDataDuyet] = useState({ nvid: '', capip: '', arrduyet: '' });
    // const [listTrangthai, setlistTrangthai] = useState([]);
    // const [showSelectType, setShowSelectType] = useState(false);
    // const [listType, setListType] = useState([]);
    // const [listTypeOld, setListTypeOld] = useState([]);
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayload] = useState(fieldList);
    const [listNhanvien, setlistNhanvien] = useState([]);
    const [listPhancaNhanVien, setlistlistPhancaNhanvien] = useState([]);
    const [dataUser, setdataUser] = useState([]);
    const [listLydonghiphep, setlistLydonghiphep] = useState([]);
    const [listLydonghipheptmp, setlistLydonghipheptmp] = useState([]);
    // const [dataCauhinhnghiphep, setdataCauhinhnghiphep] = useState([]);
    const [viewduyet, setviewduyet] = useState(false);
    useEffect(() => {
        setPayload((prev) => ({
            ...prev,
            ['NhanvienId']: listNhanvien,
        }));
        if (type === 'DI_MUON_VE_SOM') {
            if (listNhanvien.length > 0) {
                setlistCongtac(listNhanvien[0].Quanlynghiphepcongtacnvs);
                if (payload.ngaybatdau !== '') {
                    // console.log('object', payload.ngaybatdau , listNhanvien)
                    const thangxin = payload.ngaybatdau.split('-');
                    // console.log('objectaaaaaaaaathangxin', thangxin)
                    const thang = `${thangxin[0]}-${thangxin[1]}`;
                    // console.log('objectaaaaaaaaa', thang, listNhanvien[0].value)
                    const fetchData = async () => {
                        try {
                            const response = await apiGetPhancatonghop({
                                thangXn: thang,
                                NhanvienIdXn: listNhanvien[0].value,
                            });
                            const part = response?.data?.data.map((item) => ({
                                hoten: item?.Nhanvien?.hoten,
                                uuid: item?.Nhanvien?.uuid,
                                data_phanca: item?.data_phanca ? JSON.parse(item?.data_phanca) : '',
                            }));
                            if (part[0].data_phanca !== null) {
                                const dataPc = part[0]?.data_phanca.find((item) => item.ngay === payload.ngaybatdau);
                                if (dataPc.calamviec) {
                                    setlistlistPhancaNhanvien(dataPc.calamviec);
                                } else {
                                    setlistlistPhancaNhanvien([]);
                                    toast.error(
                                        `Nhân viên ${part[0].hoten} không có ca làm việc ngày ${payload.ngaybatdau}`,
                                    );
                                }
                                // console.log('dataPc', dataPc)
                            } else {
                                setlistlistPhancaNhanvien([]);
                                toast.error(
                                    `Nhân viên ${part[0].hoten} không có ca làm việc ngày ${payload.ngaybatdau}`,
                                );
                            }
                        } catch (error) {
                            console.error(error);
                        }
                    };
                    fetchData().catch(console.error);
                }
            }
        }
        setlistLydonghipheptmp(listLydonghiphep);
    }, [listNhanvien, listLydonghiphep, payload.ngaybatdau]);

    useEffect(() => {
        if (lydonghiphep) {
            let dataSelectLydonghiphep = lydonghiphep.map((item) => ({
                songaynghitoida: item?.songaynghitoida,
                tylehuongluong: item?.tylehuongluong,
                label: item.tieude,
                value: item.id,
            }));
            setlistLydonghiphep(dataSelectLydonghiphep);
        }
    }, [lydonghiphep]);

    useEffect(() => {
        if (dataIdNghiphepcongtac && dataCongtac) {
            // let check = dataIdNghiphepcongtac?.pheduyet
            //     ? JSON.parse(dataIdNghiphepcongtac?.pheduyet).some(
            //           (item) => Number(item.userid) === Number(dataUser?.id) && item.trangthai === true,
            //       )
            //     : '';
            // let trangthai = '';
            // if (check === true) {
            //     trangthai = dataStatusNghiphep?.find((item) => {
            //         return item && item.value === 3;
            //     });
            // } else {
            //     trangthai = dataStatusNghiphep?.find((item) => {
            //         return item && item.value === dataIdNghiphepcongtac?.TrangthaiId;
            //     });
            // }

            // let listNghiPhep = dataCongtac?.filter(
            //     (item) =>
            //         item.NhanvienId === dataIdNghiphepcongtac?.NhanvienId &&
            //         item.LydonghiphepId === dataIdNghiphepcongtac?.LydonghiphepId &&
            //         Number(item?.TrangthaiId) === 3,
            // );
            // const totalDaNghi = listNghiPhep?.reduce((a, v) => a + v?.songaynghitoida ?? 0, 0);
            // console.log('totalDaNghi',totalDaNghi)
            let TrangthaiId = dataStatusNghiphep.find((items) => {
                return items && items.value === dataIdNghiphepcongtac?.TrangthaiId;
            });
            let ar = {};
            let quytrinharr =
                cauhinhpheduyet.length > 0 &&
                cauhinhpheduyet.find((items) => {
                    if (items && items.id === dataIdNghiphepcongtac?.PheduyetId) {
                        ar = { value: items.id, label: items.title };
                    }
                });
            if (show && dataIdNghiphepcongtac) {
                if (type !== 'DI_MUON_VE_SOM') {
                    setPayload({
                        LydonghiphepId: {
                            songaynghitoida: dataIdNghiphepcongtac?.Lydonghiphep?.songaynghitoida,
                            tylehuongluong: dataIdNghiphepcongtac?.Lydonghiphep?.tylehuongluong,
                            label: dataIdNghiphepcongtac?.Lydonghiphep?.tieude,
                            value: dataIdNghiphepcongtac?.LydonghiphepId,
                        },
                        songaynghitoida: dataIdNghiphepcongtac?.songaynghitoida,
                        madon: dataIdNghiphepcongtac?.madon,
                        // ngaynghitoida: dataIdNghiphepcongtac?.Lydonghiphep?.songaynghitoida,
                        tylehuongluong:
                            dataIdNghiphepcongtac?.Lydonghiphep?.tylehuongluong === 0
                                ? '0'
                                : dataIdNghiphepcongtac?.Lydonghiphep?.tylehuongluong,
                        // songayconlai:
                        //     Number(dataIdNghiphepcongtac?.Lydonghiphep?.songaynghitoida) - totalDaNghi > 0
                        //         ? Number(dataIdNghiphepcongtac?.Lydonghiphep?.songaynghitoida) - totalDaNghi
                        //         : '0',
                        // songaydanghi: totalDaNghi,
                        ngaybatdau: format(new Date(`${dataIdNghiphepcongtac.ngaybatdau}`), 'yyyy-MM-dd'),
                        ngayketthuc: format(new Date(`${dataIdNghiphepcongtac.ngayketthuc}`), 'yyyy-MM-dd'),
                        giobatdau: dataIdNghiphepcongtac?.giobatdau,
                        gioketthuc: dataIdNghiphepcongtac?.gioketthuc,
                        lydoNghi: dataIdNghiphepcongtac?.lydoNghi,
                        ghichu: dataIdNghiphepcongtac?.ghichu,
                        nguoitao: dataIdNghiphepcongtac?.nguoitao,
                        TrangthaiId: { label: TrangthaiId?.label, value: TrangthaiId?.value },
                        // pheduyet: false,
                        loaihuong: dataIdNghiphepcongtac?.loaihuong,
                        PheduyetId: ar,
                    });
                } else {
                    setPayload({
                        madon: dataIdNghiphepcongtac?.madon,
                        ngaybatdau: format(new Date(`${dataIdNghiphepcongtac.ngaybatdau}`), 'yyyy-MM-dd'),
                        ghichu: dataIdNghiphepcongtac?.ghichu,
                        nguoitao: dataIdNghiphepcongtac?.nguoitao,
                        TrangthaiId: { label: TrangthaiId?.label, value: TrangthaiId?.value },
                        // pheduyet: false,
                        giatri: dataIdNghiphepcongtac?.giatri,
                        PheduyetId: ar,
                    });
                    const khoanggio = JSON.parse(dataIdNghiphepcongtac?.arrkhoanggio || '[]').map((item) => ({
                        giobatdauxin: new Date(`2023-10-10 ${item.giobatdauxin}`),
                        gioketthucxin: new Date(`2023-10-10 ${item.gioketthucxin}`),
                    }));

                    setFormValues(khoanggio);
                }
                // trạng thái theo từng người đăng nhập
                setQuytrinh(dataIdNghiphepcongtac.TrangthaiId === 2 ? false : true);
                if (dataIdNghiphepcongtac?.ob !== null) {
                    setDataDuyet(dataIdNghiphepcongtac?.ob);
                }
            }
            setColumnTuan(dataIdNghiphepcongtac?.arrtuan ? JSON.parse(dataIdNghiphepcongtac?.arrtuan) : '');
            setlistNhanvien([
                {
                    label: dataIdNghiphepcongtac?.Nhanvien?.hoten,
                    value: dataIdNghiphepcongtac?.NhanvienId,
                },
            ]);
        }
    }, [dataIdNghiphepcongtac, show]);
    // console.log(payload)
    const [showSelect, setShowSelect] = useState(false);

    // const handleOnSelect = async (selected, name) => {
    //     let statename = name.name;
    //     const NGHI_PHEP_ID = 1;
    //     let totalDaNghi = 0;
    //     let tylehuongluong = selected.tylehuongluong === 0 ? '0' : selected.tylehuongluong;
    //     let ngaynghitoida = selected.songaynghitoida;
    //     let songayconlai = selected.songaynghitoida;
    //     let songaydanghi = 0;
    //     // let abc = listCongtac.map()
    //     if (selected.value === NGHI_PHEP_ID) {
    //         let abc = listCongtac?.filter((item) => item?.LydonghiphepId === selected.value && item?.TrangthaiId === 3);
    //         totalDaNghi = abc?.reduce((a, v) => a + v?.songaynghitoida ?? 0, 0);
    //         tylehuongluong = selected.tylehuongluong === 0 ? '0' : selected.tylehuongluong;
    //         ngaynghitoida = selected.songaynghitoida;
    //         songayconlai =
    //             Number(selected.songaynghitoida) - totalDaNghi > 0
    //                 ? Number(selected.songaynghitoida) - totalDaNghi
    //                 : '0';
    //         songaydanghi = totalDaNghi;
    //     }
    //     setPayload((prev) => ({
    //         ...prev,
    //         [statename]: selected,
    //         ['tylehuongluong']: tylehuongluong,
    //         ['ngaynghitoida']: ngaynghitoida,
    //         ['songayconlai']: songayconlai,
    //         ['songaydanghi']: songaydanghi,
    //     }));
    // };

    const handleSubmit = async () => {
        let NGHI_PHEP = 'NGHI_PHEP';
        let invalids = validate(payload);

        if (type !== 'DI_MUON_VE_SOM') {
            let checkNP = 0;
            if (type === 'NGHI_PHEP') {
                if (
                    type === 'NGHI_PHEP' &&
                    payload?.songaynghitoida <= payload?.ngaynghitoida &&
                    Number(payload?.songaynghitoida) !== 0
                ) {
                    checkNP = 0;
                } else if (type === 'NGHI_PHEP' && Number(payload?.songaynghitoida) === 0) {
                    checkNP = 1;
                    toast.error(
                        'Ngày xin nghỉ của bạn không có, Bạn chưa được phân ca trong khoảng ngày bắt đầu và ngày kết thúc!',
                    );
                } else {
                    checkNP = 1;
                    toast.error('Số ngày xin nghỉ của bạn nhiều hơn số ngày nghỉ phép tối đa!');
                }
            }
            if (invalids === 0 && checkNP === 0) {
                // let soNgayNghiToiDa = type === NGHI_PHEP ? payload.LydonghiphepId.songaynghitoida : 0;
                let dateBatDau = format(new Date(payload.ngaybatdau), 'yyyy-MM-dd');
                let timeBatDau = format(new Date(payload.ngaybatdau), 'HH:mm');
                let dateKetThuc = format(new Date(payload.ngayketthuc), 'yyyy-MM-dd');
                let timeKetThuc = format(new Date(payload.ngayketthuc), 'HH:mm');
                let tyLeHuongLuong = type === NGHI_PHEP ? payload.LydonghiphepId.tylehuongluong : 0;
                let lyDoNghiPhep = type === NGHI_PHEP ? payload.LydonghiphepId.value : null;
                let datapayload = {
                    id: dataIdNghiphepcongtac.id,
                    NhanvienId: payload.NhanvienId[0].value,
                    LydonghiphepId: lyDoNghiPhep,
                    tylehuongluong: tyLeHuongLuong,
                    songaynghitoida: payload.songaynghitoida,
                    type: payload.type,
                    lydoNghi: payload.lydoNghi,
                    ngaybatdau: dateBatDau,
                    ngayketthuc: dateKetThuc,
                    giobatdau: payload.giobatdau,
                    gioketthuc: payload.gioketthuc,
                    ghichu: payload.ghichu,
                    nguoitao: payload.nguoitao,
                    vesomgiuaca: payload.vesomgiuaca,
                    vesomcuoica: payload.vesomcuoica,
                    denmuongiuaca: payload.denmuongiuaca,
                    denmuoncuoica: payload.denmuoncuoica,
                    arrtuan: JSON.stringify(columnTuan),
                    arrPhep: JSON.stringify(payload?.arrPhep),
                    // arrPhepJs: JSON.stringify(payload?.arrPhep),
                    loaihuong: payload.loaihuong,
                    giatri: payload?.giatri,
                    TrangthaiId: payload.TrangthaiId.value,
                    dataduyet: dataduyet,
                };
                // dispatch(actions.EditNghiphepcongtac(datapayload));
                const response = await apiEditNghiphepcongtac(datapayload);
                if (response?.data.success === 200) {
                    if (response?.data?.aruser !== 0) {
                        let arr = [response?.data?.aruser];
                        handleThongbaoDataSocket(arr.join('|'));
                    }
                    setShow(false);
                    toast.success(response?.data);
                    dispatch(actions.getListBangtonghopphep());
                    dispatch(actions.getListNghiphepcongtac({order: 'desc', by: 'id'}));
                } else {
                    toast.error(response?.data);
                }
            }
        } else {
            let dateBatDau = format(new Date(payload.ngaybatdau), 'yyyy-MM-dd');
            if (formValues.length > 0) {
                if (canhbao.length === 0) {
                    const arrkhoanggio = formValues.map((item) => ({
                        giobatdauxin: item.giobatdauxin ? format(new Date(item.giobatdauxin), 'HH:mm:ss') : '',
                        gioketthucxin: item.gioketthucxin ? format(new Date(item.gioketthucxin), 'HH:mm:ss') : '',
                    }));
                    // console.log('arrkhoanggio', arrkhoanggio);
                    let datapayload = {
                        id: dataIdNghiphepcongtac.id,
                        NhanvienId: payload.NhanvienId[0].value,
                        type: type,
                        ngaybatdau: dateBatDau,
                        ghichu: payload.ghichu,
                        arrkhoanggio: JSON.stringify(arrkhoanggio),
                        TrangthaiId: payload.TrangthaiId.value,
                        dataduyet: dataduyet,
                    };
                    // console.log('datapayload', datapayload);
                    // dispatch(actions.EditNghiphepcongtac(datapayload));
                    const response = await apiEditNghiphepcongtac(datapayload);
                    if (response?.data.success === 200) {
                        if (response?.data?.aruser !== 0) {
                            let arr = [response?.data?.aruser];
                            handleThongbaoDataSocket(arr.join('|'));
                        }
                        setShow(false);
                        toast.success(response?.data?.mes);
                        dispatch(actions.getListNghiphepcongtac({order: 'desc', by: 'id'}));
                    } else {
                        toast.error(response?.data?.mes);
                    }
                } else {
                    toast.error('Không thành công vui lòng khắc phục hết lỗi cảnh báo!');
                }
            } else {
                toast.error('Bạn chưa thêm khoảng thời gian đi muộn về sớm');
            }
        }
    };
    const LayPc = async (thang, NhanvienId) => {
        // console.log('thang', thang, NhanvienId)
        const response = await apiGetPhancatonghop({
            thangXn: thang,
            NhanvienIdXn: NhanvienId,
        });
        return response;
    };
    useEffect(() => {
        const fetchData = async () => {
            if (type !== 'DI_MUON_VE_SOM') {
                const { ngaybatdau, ngayketthuc, LydonghiphepId } = payload;
                let tylehuongluong = LydonghiphepId.tylehuongluong === 0 ? '0' : LydonghiphepId.tylehuongluong;

                let nghipheptoida = 0;
                if (ngaybatdau !== '' && ngayketthuc !== '') {
                    const ngaybatdau = payload.ngaybatdau;
                    const ngayketthuc = payload.ngayketthuc;
                    const startDate = new Date(ngaybatdau);
                    const endDate = new Date(ngayketthuc);
                    const arr = [];
                    let currentMonth = startDate;
                    while (currentMonth < endDate || format(currentMonth, 'yyyy-MM') === format(endDate, 'yyyy-MM')) {
                        arr.push({
                            year: currentMonth.getFullYear(),
                            month: (currentMonth.getMonth() + 1).toString().padStart(2, '0'),
                        });
                        currentMonth = addMonths(currentMonth, 1);
                    }
                    const ngaybatdaus = new Date(ngaybatdau);
                    const ngayketthucs = new Date(ngayketthuc);
                    const tatcaNgay = [];
                    let ngayHienTai = ngaybatdaus;

                    while (isBefore(ngayHienTai, ngayketthucs) || isEqual(ngayHienTai, ngayketthucs)) {
                        tatcaNgay.push(format(ngayHienTai, 'yyyy-MM-dd'));
                        ngayHienTai = new Date(ngayHienTai.setDate(ngayHienTai.getDate() + 1));
                    }
                    let mangngaya = [];
                    for (const aa of arr) {
                        const layphanca = await LayPc(`${aa.year}-${aa.month}`, payload?.NhanvienId[0]?.value);
                        const part = layphanca?.data?.data.map((item) => ({
                            hoten: item?.Nhanvien?.hoten,
                            uuid: item?.Nhanvien?.uuid,
                            data_phanca: item?.data_phanca ? JSON.parse(item?.data_phanca) : '',
                        }));
                        // console.log('layphancaaa', payload?.NhanvienId[0]?.value)
                        let tonga = 0;
                        for (const tatcaNgays of tatcaNgay) {
                            let ngaytc = tatcaNgays.split('-');
                            //   console.log('part', part)
                            if (part.length > 0) {
                                const firstItem = part[0];
                                if (firstItem && firstItem.data_phanca !== null) {
                                    const dataPc = firstItem.data_phanca.find((item) => item.ngay === tatcaNgays);
                                    if (dataPc?.calamviec !== null) {
                                        if (Number(aa.month) === Number(ngaytc[1])) {
                                            tonga += 1;
                                        }
                                    }
                                }
                            }
                        }
                        mangngaya.push({ year: aa.year, month: aa.month, songay: tonga });
                    }
                    //   console.log('mangngaya', mangngaya)
                    if (mangngaya.length > 0) {
                        mangngaya.forEach((item) => {
                            nghipheptoida += item.songay;
                        });
                    }
                    const bd = ngaybatdau.split('-');
                    const kt = ngayketthuc.split('-');
                    if (bd.length === 3 && kt.length === 3) {
                        const isValidYear = (year) => year >= 2000 && year <= 2100;

                        if (isValidYear(bd[0]) && isValidYear(kt[0])) {
                            const ngayBatDau = new Date(ngaybatdau);
                            const ngayKetThuc = new Date(ngayketthuc);
                            if (ngayBatDau <= ngayKetThuc) {
                                // const timeDifference = ngayKetThuc.getTime() - ngayBatDau.getTime();
                                //     const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
                                setPayload((prev) => ({ ...prev, songaynghitoida: nghipheptoida }));
                            } else {
                                console.log('Ngày bắt đầu phải nhỏ hơn ngày kết thúc!');
                                setPayload((prev) => ({ ...prev, songaynghitoida: '0' }));
                            }
                        } else {
                            console.log('Năm nhập chỉ trong khoảng 2000 đến 2100!');
                        }
                    }
                    let songayconlai = 0;
                    let ngaynghitoida = 0;
                    let mangPhep = [];
                    if (payload?.LydonghiphepId?.value === 1) {
                        if (payload?.NhanvienId?.length > 0) {
                            let datapay = {
                                idnv: payload?.NhanvienId[0]?.value,
                            };
                            const Duyetphep = await apiDuyetphep(datapay);
                            ngaynghitoida = Duyetphep?.data?.data;
                            songayconlai = Duyetphep?.data?.data - nghipheptoida;
                            mangPhep = mangngaya;
                        }
                    } else {
                        songayconlai = LydonghiphepId.songaynghitoida - nghipheptoida;
                        ngaynghitoida = LydonghiphepId.songaynghitoida;
                        mangPhep = [];
                    }
                    setPayload((prev) => ({
                        ...prev,
                        ['tylehuongluong']: tylehuongluong,
                        ['ngaynghitoida']: ngaynghitoida,
                        ['songayconlai']: songayconlai,
                        ['arrPhep']: mangPhep,
                        // ['songaydanghi']: songaydanghi,
                    }));
                }
            }
        };
        fetchData().catch(console.error);
    }, [payload.ngaybatdau, payload.ngayketthuc, payload.LydonghiphepId, payload?.NhanvienId]);
    // const handleOnSelect = async (selected, name) => {
    //     console.log('selected',selected);
    //     let statename = name.name;
    //     setPayload((prev) => ({ ...prev, [statename]: selected,
    //         ['tylehuongluong']: selected.tylehuongluong === 0 ? '0' : selected.tylehuongluong,
    //         ['songaynghitoida']: selected.songaynghitoida}));
    // };
    const tuan = ['Thứ 2', 'Thứ 3', 'Thứ 4', 'Thứ 5', 'Thứ 6', 'Thứ 7', 'Chủ nhật'];
    const [columnTuan, setColumnTuan] = useState([]);
    // console.log('dataIdNghiphepcongtac?.arrtuan', dataIdNghiphepcongtac?.arrtuan)
    // console.log('columnTuan', columnTuan)
    const [formValues, setFormValues] = useState([]);
    // console.log('formValues', formValues);
    const handleChange = (i, e, name) => {
        let newFormValues = [...formValues];
        newFormValues[i][name] = e;
        setFormValues(newFormValues);
    };

    const addFormFields = () => {
        let mang = [
            ...formValues,
            { giobatdauxin: new Date(`2023-10-10 08:00:00`), gioketthucxin: new Date(`2023-10-10 08:30:00`) },
        ];
        setFormValues(mang);
    };

    const removeFormFields = (item, i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues);
    };

    // phần cảnh báo người dùng
    const kiemTraTrungLapGio = (danhSachGio) => {
        const gioDaKiemTra = [];

        for (const gio of danhSachGio) {
            const gioBatDau = new Date(gio.giobatdauxin).getTime(); // Convert to timestamp
            const gioKetThuc = new Date(gio.gioketthucxin).getTime(); // Convert to timestamp
            // Tính giờ bắt đầu và kết thúc quá 5 tiếng là báo lỗi
            const diff = Math.abs(gioKetThuc - gioBatDau);
            const gioTrongMillis = Math.floor(diff / 3600000); // 1 giờ = 3600000 milliseconds
            //   console.log('gio', gioTrongMillis);
            if (
                gioKetThuc <= gioBatDau ||
                gioDaKiemTra.includes(gioBatDau) ||
                gioDaKiemTra.includes(gioKetThuc) ||
                gioTrongMillis >= 5
            ) {
                // Nếu giờ bắt đầu hoặc giờ kết thúc đã tồn tại trong mảng, có trùng lặp
                return true;
            } else {
                gioDaKiemTra.push(gioBatDau, gioKetThuc);
            }
        }

        // Không có trùng lặp
        return false;
    };
    const [canhbao, setCanhbao] = useState([]);
    // console.log('canhbao', canhbao);
    useEffect(() => {
        if (formValues.length > 0 && listPhancaNhanVien.length > 0) {
            const arrkhoanggio = formValues.map((item) => ({
                giobatdauxin: item.giobatdauxin ? format(new Date(item.giobatdauxin), 'HH:mm:ss') : '',
                gioketthucxin: item.gioketthucxin ? format(new Date(item.gioketthucxin), 'HH:mm:ss') : '',
            }));
            // console.log('arrkhoanggio', arrkhoanggio)
            const coTrungLap = kiemTraTrungLapGio(formValues);
            if (!coTrungLap) {
                setCanhbao([{ mev: '' }]);
                for (const ArrPhanca of listPhancaNhanVien) {
                    let d = [];
                    for (const ArrTime of arrkhoanggio) {
                        if (ArrTime.giobatdauxin !== '' || ArrTime.gioketthucxin !== '') {
                            const gioVaoLamViec = ArrPhanca.Khaibaocalamviec.giovaolamviec;
                            const gioKetThuclamViec = ArrPhanca.Khaibaocalamviec.gioketthuclamviec;

                            if (ArrTime.giobatdauxin !== gioVaoLamViec && ArrTime.gioketthucxin !== gioKetThuclamViec) {
                                const mes = {
                                    mes: `Giờ bắt đầu ${
                                        ArrTime.giobatdauxin ? ArrTime.giobatdauxin : 'HH:mm'
                                    } và giờ kết thúc ${
                                        ArrTime.gioketthucxin ? ArrTime.gioketthucxin : 'HH:mm'
                                    } không phù hợp với các ca làm việc nào.`,
                                };
                                d.push(mes);
                                setCanhbao(d);
                            } else {
                                setCanhbao([]);
                            }
                        }
                    }
                }
            } else {
                setCanhbao([
                    {
                        mev: 'Giờ bắt đầu hoặc giờ kết thúc trùng nhau hoặc Giờ kết thúc phải > Giờ bắt đầu và khoảng thời gian xin không vượt quá 5 giờ',
                    },
                ]);
                // toast.error();
            }
        }
    }, [formValues, listPhancaNhanVien]);
    const inPutLyDo = (type) => {
        const componentsMap = {
            NGHI_PHEP: (
                <div className="form-group row">
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ngày bắt đầu'}
                            value={payload.ngaybatdau}
                            setValue={setPayload}
                            keyPayload={'ngaybatdau'}
                            type="date"
                            validate
                            disabled={true} // khi thay đổi cái này sẽ tính lại ngày xin nghỉ dẫn đến trừ sai trong bảng phép khi thay đổi trạng thái ngoài đã duyệt
                        />
                    </div>
                    <div className="form-group col-md-6 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ngày kết thúc'}
                            value={payload.ngayketthuc}
                            setValue={setPayload}
                            keyPayload={'ngayketthuc'}
                            type="date"
                            validate
                            disabled={true}
                        />
                    </div>
                    <div className="col-6">
                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                            Lý do {title} <b className="text-danger">(*)</b>
                        </label>
                        <Select
                            value={payload.LydonghiphepId}
                            // onChange={handleOnSelect}
                            onChange={(selected) => setPayload((prev) => ({ ...prev, ['LydonghiphepId']: selected }))}
                            options={listLydonghipheptmp}
                            placeholder={'Chọn lý do'}
                            name="LydonghiphepId"
                            onFocus={() => setInvalidFields([])}
                            isDisabled
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'LydonghiphepId') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'LydonghiphepId')?.message}
                            </small>
                        )}
                    </div>
                    <div className="col-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Tỷ lệ hưởng lương (%)'}
                            value={payload.tylehuongluong || '0'}
                            setValue={setPayload}
                            keyPayload={'tylehuongluong'}
                            type="number"
                            disabled={true}
                            validate
                        />
                    </div>
                    <div className="col-4">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Số ngày xin nghỉ'}
                            value={payload.songaynghitoida || '0'}
                            // setValue={setPayload}
                            keyPayload={'songaynghitoida'}
                            type="number"
                            disabled={true}
                            validate
                        />
                    </div>

                    <div className="col-4">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ngày phép tối đa'}
                            value={payload.ngaynghitoida || '0'}
                            setValue={setPayload}
                            keyPayload={'ngaynghitoida'}
                            type="number"
                            disabled={true}
                            validate
                        />
                    </div>
                    {/* <div className="col-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ngày phép đã nghỉ'}
                            value={payload.songaydanghi}
                            setValue={setPayload}
                            keyPayload={'songaydanghi'}
                            type="text"
                            disabled={true}
                            validate
                        />
                    </div> */}
                    <div className="col-4">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ngày phép còn lại'}
                            value={payload.songayconlai || '0'}
                            setValue={setPayload}
                            keyPayload={'songayconlai'}
                            type="number"
                            disabled={true}
                            validate
                        />
                    </div>
                </div>
            ),
            CONG_TAC: (
                <div className="form-group row">
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ngày bắt đầu'}
                            value={payload.ngaybatdau}
                            setValue={setPayload}
                            keyPayload={'ngaybatdau'}
                            type="date"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-6 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ngày kết thúc'}
                            value={payload.ngayketthuc}
                            setValue={setPayload}
                            keyPayload={'ngayketthuc'}
                            type="date"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-12 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Lý do công tác'}
                            value={payload.lydoNghi}
                            setValue={setPayload}
                            keyPayload={'lydoNghi'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-12 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Giá trị / Ngày'}
                            value={payload.giatri}
                            setValue={setPayload}
                            keyPayload={'giatri'}
                            type="text"
                            validate
                        />
                    </div>
                </div>
            ),
            CAP_NHAT_CONG: (
                <div className="form-group row">
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ngày bắt đầu'}
                            value={payload.ngaybatdau}
                            setValue={setPayload}
                            keyPayload={'ngaybatdau'}
                            type="date"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-6 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ngày kết thúc'}
                            value={payload.ngayketthuc}
                            setValue={setPayload}
                            keyPayload={'ngayketthuc'}
                            type="date"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-12 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Lý do'}
                            value={payload.lydoNghi}
                            setValue={setPayload}
                            keyPayload={'lydoNghi'}
                            type="text"
                            validate
                        />
                    </div>
                </div>
            ),
            DI_MUON_VE_SOM: (
                <>
                    <div className="form-group row">
                        <div className="col-12 mb-3">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Ngày xin '}
                                value={payload.ngaybatdau}
                                setValue={setPayload}
                                keyPayload={'ngaybatdau'}
                                type="date"
                                validate
                            />
                        </div>
                        {listPhancaNhanVien.length > 0 && (
                            <div className="col-12 mb-3">
                                <label htmlFor="inputZip" className="mb-2 fw-bold">
                                    {`Danh sách ca làm việc ${listNhanvien[0].label} ngày ${
                                        payload.ngaybatdau ? format(new Date(payload.ngaybatdau), 'dd-MM-yyyy') : ''
                                    } `}
                                </label>
                                <div style={{ overflowY: 'hidden' }}>
                                    <DataTableBody>
                                        <DataTableHead className="col-12 ">
                                            <DataTableRow className="w-50px">
                                                <span className="sub-text ">Stt</span>
                                            </DataTableRow>
                                            <DataTableRow>
                                                <span className="sub-text w-150px">Mã ca làm việc</span>
                                            </DataTableRow>
                                            <DataTableRow>
                                                <span className="sub-text w-80px">Giờ vào</span>
                                            </DataTableRow>
                                            <DataTableRow>
                                                <span className="sub-text w-80px">Giờ ra</span>
                                            </DataTableRow>
                                        </DataTableHead>
                                        {listPhancaNhanVien.map((item, index) => {
                                            return (
                                                <DataTableItem key={index}>
                                                    <DataTableRow size=" nk-tb-col-check mb">
                                                        <span className="tb-amount">{index + 1}</span>
                                                    </DataTableRow>
                                                    <DataTableRow>
                                                        <span className="tb-amount">
                                                            {item?.Khaibaocalamviec?.macalamviec}
                                                        </span>
                                                    </DataTableRow>
                                                    <DataTableRow>
                                                        <span className="tb-amount">
                                                            {item?.Khaibaocalamviec?.giovaolamviec}
                                                        </span>
                                                    </DataTableRow>
                                                    <DataTableRow>
                                                        <span className="tb-amount">
                                                            {item?.Khaibaocalamviec?.gioketthuclamviec}
                                                        </span>
                                                    </DataTableRow>
                                                </DataTableItem>
                                            );
                                        })}
                                    </DataTableBody>
                                </div>
                            </div>
                        )}
                        <div className="col-12 mb-3">
                            <div className="col-12 mt-3 mb-3">
                                <Button
                                    size="md"
                                    color="info"
                                    outline
                                    className="btn-icon btn-white btn-dim"
                                    style={{ paddingRight: '10px' }}
                                    onClick={() => addFormFields()}
                                >
                                    <Icon name="plus"></Icon> Thêm khoảng thời gian đi muộn về sớm
                                </Button>
                            </div>
                            {formValues?.length > 0 && (
                                <DataTableBody>
                                    <DataTableHead>
                                        <DataTableRow>
                                            <span className="sub-text">Stt</span>
                                        </DataTableRow>
                                        <DataTableRow size="mb">
                                            <span className="sub-text">Giờ bắt đầu</span>
                                        </DataTableRow>
                                        <DataTableRow size="mb">
                                            <span className="sub-text">Giờ kết thúc</span>
                                        </DataTableRow>
                                        <DataTableRow size="mb"></DataTableRow>
                                    </DataTableHead>
                                    {formValues?.map((item, it) => {
                                        return (
                                            <DataTableItem key={it}>
                                                <DataTableRow>
                                                    <span className="sub-text">{it + 1}</span>
                                                </DataTableRow>
                                                <DataTableRow size="mb">
                                                    <div className="form-control-wrap">
                                                        <DatePicker
                                                            selected={item.giobatdauxin || ''}
                                                            onChange={(date) => handleChange(it, date, 'giobatdauxin')}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            timeIntervals={30}
                                                            timeCaption="Time"
                                                            dateFormat="HH:mm"
                                                            className="form-control date-picker"
                                                        />
                                                    </div>
                                                </DataTableRow>
                                                <DataTableRow size="mb">
                                                    <div className="form-control-wrap">
                                                        <DatePicker
                                                            selected={item.gioketthucxin || ''}
                                                            onChange={(date) => handleChange(it, date, 'gioketthucxin')}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            timeIntervals={30}
                                                            timeCaption="Time"
                                                            dateFormat="HH:mm"
                                                            className="form-control date-picker"
                                                        />
                                                    </div>
                                                </DataTableRow>
                                                <DataTableRow className="nk-tb-col-tools">
                                                    <ul className="nk-tb-actions gx-1">
                                                        <>
                                                            <button
                                                                onClick={() => removeFormFields(item, it)}
                                                                className={'btn btn-danger ms-0 badge text-white p-1'}
                                                            >
                                                                <em className="icon ni ni-trash-alt"></em>
                                                            </button>
                                                        </>
                                                    </ul>
                                                </DataTableRow>
                                            </DataTableItem>
                                        );
                                    })}
                                </DataTableBody>
                            )}
                        </div>
                        <div className="col-12 mb-3">
                            {formValues.length > 0 && listPhancaNhanVien.length > 0 ? (
                                canhbao.length > 0 ? (
                                    <Alert className="alert-icon" color="danger">
                                        <Icon name="cross-circle" />
                                        <div>
                                            <strong>Cảnh báo</strong>
                                        </div>
                                        {canhbao?.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    {item?.mes}
                                                    {item?.mev}
                                                </div>
                                            );
                                        })}
                                    </Alert>
                                ) : null
                            ) : null}
                        </div>
                    </div>
                </>
            ),
            PHIEU_RA_CONG: (
                <div className="form-group row">
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ngày bắt đầu'}
                            value={payload.ngaybatdau}
                            setValue={setPayload}
                            keyPayload={'ngaybatdau'}
                            type="date"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-6 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ngày kết thúc'}
                            value={payload.ngayketthuc}
                            setValue={setPayload}
                            keyPayload={'ngayketthuc'}
                            type="date"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-6 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Giờ bắt đầu'}
                            value={payload.giobatdau}
                            setValue={setPayload}
                            keyPayload={'giobatdau'}
                            type="time"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-6 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Giờ kết thúc'}
                            value={payload.gioketthuc}
                            setValue={setPayload}
                            keyPayload={'gioketthuc'}
                            type="time"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-12 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Địa điểm tới'}
                            value={payload.lydoNghi}
                            setValue={setPayload}
                            keyPayload={'lydoNghi'}
                            type="text"
                        />
                    </div>
                    <div className="mt-1">
                        <div className="form-group col-md-12">
                            <div className="pb-0">
                                <div className="g-4 align-center flex-wrap">
                                    <div className="g">
                                        <div className="custom-control custom-radio">
                                            <input
                                                type="radio"
                                                className="custom-control-input"
                                                name="loaihuong"
                                                id="loaihuong"
                                                onChange={(e) =>
                                                    setPayload((prev) => ({
                                                        ...prev,
                                                        ['loaihuong']: 0,
                                                    }))
                                                }
                                                checked={payload?.loaihuong === 0}
                                            />
                                            <label className="custom-control-label" htmlFor="loaihuong">
                                                Không hưởng công
                                            </label>
                                        </div>
                                    </div>
                                    <div className="g">
                                        <div className="custom-control custom-radio">
                                            <input
                                                type="radio"
                                                className="custom-control-input"
                                                name="loaihuong"
                                                id="loaihuong1"
                                                onChange={(e) =>
                                                    setPayload((prev) => ({
                                                        ...prev,
                                                        ['loaihuong']: 1,
                                                    }))
                                                }
                                                checked={payload?.loaihuong === 1}
                                            />
                                            <label className="custom-control-label" htmlFor="loaihuong1">
                                                Có hưởng công
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ),
        };

        return componentsMap[type] || null;
    };
    // console.log('listType', listType)
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateNghiphepcongtac(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateNghiphepcongtac(item[0], item[1])]);
                invalids++;
            }
        });

        return invalids;
    };

    return (
        <React.Fragment>
            <Modal isOpen={show} className="modal-dialog-centered modal-90w" size="lg" toggle={setShow}>
                <ModalBody>
                    <Icon name="cross-sm float-end fs-2" onClick={setShow}></Icon>
                    <h5 className="title mb-3">{title}</h5>
                    <div className="row">
                        <div
                            className="form-group col-md-4"
                            // onClick={() => setShowSelect(true)}
                        >
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                Chọn Nhân Viên <b className="text-danger">(*)</b>
                            </label>
                            <Select
                                value={payload.NhanvienId || ''}
                                name="NhanvienId"
                                onFocus={() => setInvalidFields([])}
                                isDisabled
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'NhanvienId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'NhanvienId')?.message}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-4">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Mã đơn'}
                                value={payload.madon}
                                // setValue={setPayload}
                                keyPayload={'madon'}
                                type="text"
                                validate
                                disabled={true}
                            />
                        </div>
                        <div className="form-group col-md-4">
                            <div className="form-group">
                                <label htmlFor="inputZip" className="mb-2 fw-bold">
                                    Áp dụng quy trình phê duyệt <b className="text-danger">(*)</b>
                                </label>
                                <Select
                                    value={payload?.PheduyetId}
                                    // onChange={handleOnSelect}
                                    // onChange={(selected) =>
                                    //     setPayload((prev) => ({ ...prev, ['PheduyetId']: selected }))
                                    // }
                                    // options={quytrinh}
                                    placeholder={'Chọn quy trình'}
                                    name="PheduyetId"
                                    onFocus={() => setInvalidFields([])}
                                    isDisabled
                                />
                                {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'PheduyetId') && (
                                    <small className="text-danger">
                                        {invalidFields.find((i) => i.name === 'PheduyetId')?.message}
                                    </small>
                                )}
                            </div>
                        </div>
                        {/* <div className="form-group col-md-6">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Ngày bắt đầu'}
                                value={payload.ngaybatdau}
                                setValue={setPayload}
                                keyPayload={'ngaybatdau'}
                                type="datetime-local"
                                validate
                            />
                        </div>
                        <div className="form-group col-md-6 ">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Ngày kết thúc'}
                                value={payload.ngayketthuc}
                                setValue={setPayload}
                                keyPayload={'ngayketthuc'}
                                type="datetime-local"
                                validate
                            />
                        </div> */}
                    </div>
                    <div className="col-md-12">{inPutLyDo(type, title)}</div>
                    <div className="row">
                        <div className="form-group col-md-12 mt-2">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                <span>Ghi chú</span>
                            </label>
                            <Input
                                value={payload.ghichu}
                                onChange={(e) => setPayload((prev) => ({ ...prev, ['ghichu']: e.target.value }))}
                                rows={'2'}
                                name="ghichu"
                                type="textarea"
                                disabled
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'ghichu') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'ghichu')?.message}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                Trạng thái
                            </label>
                            <Select
                                value={payload.TrangthaiId}
                                name="TrangthaiId"
                                onChange={(selected) => setPayload((prev) => ({ ...prev, ['TrangthaiId']: selected }))}
                                options={dataStatusNghiphep}
                                onFocus={() => setInvalidFields([])}
                                isDisabled={quytrinh}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'TrangthaiId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'TrangthaiId')?.message}
                                </small>
                            )}
                        </div>
                        {/* <div className="form-group col-md-6">
                            {viewduyet === true && payload.TrangthaiId.value !== 3 ? (
                                <>
                                    <label htmlFor="inputZip" className="mb-3 fw-bold">
                                        Phê duyệt
                                    </label>
                                    <div className="form-group col-md-6 mb-3">
                                        <div className="custom-control custom-switch z-0 position-absolute">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="pheduyet"
                                                name="pheduyet"
                                                onChange={(e) => handlePheduyet(e.target.checked)}
                                                //  onChange={handlePheduyet}
                                                checked={payload.pheduyet}
                                            />
                                            <label className="custom-control-label" htmlFor="pheduyet">
                                                Tôi đồng ý{' '}
                                            </label>
                                        </div>
                                    </div>
                                </>
                                // dataCauhinhnghiphep?.length > 0 ? (
                                //     dataCauhinhnghiphep.map((item, index) => {
                                //     return <div key={index}>người duyệt: {item}</div>;
                                //     })
                                // ) : null
                            ) : ''}
                        </div> */}
                    </div>

                    <div className="col-12 mt-5">
                        {type !== 'DI_MUON_VE_SOM' ? (
                            <Button color="primary" onClick={handleSubmit} disabled={quytrinh}>
                                Cập nhật
                            </Button>
                        ) : formValues.length > 0 && listPhancaNhanVien.length > 0 ? (
                            <Button color="primary" onClick={handleSubmit} disabled={quytrinh}>
                                Cập nhật
                            </Button>
                        ) : (
                            <Button
                                color="primary"
                                onClick={() =>
                                    toast.error(
                                        'Bạn chưa chọn nhân viên và ngày xin, hoặc bạn chưa được phân ca và chưa có khoảng thời gian xin!',
                                    )
                                }
                            >
                                Cập nhật
                            </Button>
                        )}
                    </div>
                </ModalBody>
            </Modal>
            {/*<ModalSelectType*/}
            {/*    showSelect={showSelectType}*/}
            {/*    // typeSelect={true}*/}
            {/*    listType={listType}*/}
            {/*    setListType={setListType}*/}
            {/*    setShowSelect={() => setShowSelectType(false)}*/}
            {/*/>*/}
            <SelectFinNhanvienfix
                showSelect={showSelect}
                typeSelect={false}
                listNhanvien={listNhanvien}
                setlistNhanvien={setlistNhanvien}
                setShowSelect={() => setShowSelect(false)}
            />
        </React.Fragment>
    );
};

export default ModalEditNghiphepcongtac;
