import actionTypes from "../actions/actionTypes";
const initState = {
    congty: [],
    msg: '',
    count: 0,
    newcongty: []
}

const congtyReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.GET_LIST_CONG_TY:
            return {
                ...state,
                congty: action?.congty?.rows || [],
                msg: 'Ok' || '',
                count: action?.congty?.count || 0
            }
        case actionTypes.POST_NEW_CONG_TY:
        case actionTypes.PUT_EDIT_CONG_TY:
        case actionTypes.DELETE_CONG_TY:
            return {
                ...state,
                msg: action.msg || '',
                newcongty: action?.newcongty || []
            }
        default:
            return state;
    }

}

export default congtyReducer