import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import '../Modal.scss';
import Select from 'react-select';
import {validateChucVu} from '~/request/validateChucVu';
import {Modal, ModalBody} from 'reactstrap';
import {Icon} from '~/components/Component';
import {dataSelectStatus} from '~/utils/Datacustom';
import {InputForm} from '~/components/Form';
import {apiEditMoiquanhenhanvien, apiGetMoiquanhelaodong} from "~/services";
import {toast} from "react-toastify";
import {format, parseISO} from "date-fns";

const ModalEditMoiquanhenhanvien = ({show, setShow, dataEdit, fieldList}) => {
    const [listTrangthai, setlistTrangthai] = useState([]);
    const [listQuanhe, setlistQuanhe] = useState([]);
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayload] = useState(fieldList);
    console.log('dataEdit', dataEdit)
    
    async function fetchData() {
        try {
            const datapayloads = {
                typeGet: 2, // 1: lấy theo điều kiện phân trang, 2: lấy tất cả và không cần truyền d liệu phân trang
            };
            const response = await apiGetMoiquanhelaodong(datapayloads);
            if (response?.data?.success === 200) {
                setlistQuanhe(response?.data?.data?.rows.map((item) => ({label: item.tenvande, value: item.id})));
                
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    
    useEffect(() => {
        let TrangthaiId = dataSelectStatus.find((items) => {
            return items && items.value === dataEdit?.TrangthaiId;
        });
        let Moiquanhelaodong = listQuanhe.find((items) => {
            return items && items.value === dataEdit?.MoiquanhelaodongId;
        });
        show &&
        dataEdit &&
        setPayload({
            id: dataEdit?.id,
            TrangthaiId: {label: TrangthaiId?.label, value: TrangthaiId?.value},
            MoiquanhelaodongId: Moiquanhelaodong,
            NhanvienId: {
                label: dataEdit?.Nhanvien?.hoten,
                value: dataEdit?.Nhanvien?.id,
            },
            giatri: dataEdit.giatri,
            ngaybatdau: format(parseISO(dataEdit?.ngaybatdau), 'yyyy-MM-dd'),
        });
    }, [dataEdit, show]);
    useEffect(() => {
        fetchData().then(r => 'err');
        let TrangthaiId = dataSelectStatus.map((item) => ({label: item.label, value: item.value}));
        setlistTrangthai(TrangthaiId);
    }, [show]);
    
    const handleSubmit = async () => {
        let invalids = validate(payload);
        
        if (invalids === 0) {
            let datapayload = {
                data: [
                    {
                        id: payload?.id,
                        TrangthaiId: payload.TrangthaiId.value,
                        giatri: payload.giatri,
                        ngaybatdau: payload.ngaybatdau,
                    },
                ],
            };
            console.log('check payload', datapayload);
            const response = await apiEditMoiquanhenhanvien(datapayload)
            // console.log('chek api apiEditMoiquanhenhanvien', response)
            if (response?.data.success === 200) {
                setShow(false);
                toast.success("Sửa thông tin thành công!")
                
            } else {
                
                toast.error("Sửa thông tin thất bại!")
            }
            
        }
    };
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayload((prev) => ({...prev, [statename]: selected}));
    };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateChucVu(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateChucVu(item[0], item[1])]);
                invalids++;
            }
        });
        return invalids;
    };
    return (
        <React.Fragment>
            <Modal isOpen={show} className="modal-dialog-centered modal-90w" size="lg" toggle={setShow}>
                <ModalBody>
                    <Icon name="cross-sm float-end fs-2" onClick={setShow}></Icon>
                    <h5 className="title mb-3">Sửa vấn để phát sinh theo từng nhân viên</h5>
                    <div className="row">
                        <div className="form-group col-md-6">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                Nhân Viên
                            </label>
                            <Select
                                value={payload.NhanvienId}
                                name="NhanvienId"
                                onFocus={() => setInvalidFields([])}
                                isDisabled={true}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'NhanvienId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'NhanvienId')?.message}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                <span>
                                    Trạng thái <b className="text-danger">(*)</b>
                                </span>
                            </label>
                            <Select
                                value={payload.TrangthaiId}
                                onChange={handleOnSelect}
                                options={listTrangthai}
                                placeholder={'Chọn trạng thái'}
                                name="TrangthaiId"
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'TrangthaiId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'TrangthaiId')?.message}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-6">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Giá trị (VNĐ)'}
                                value={payload.giatri}
                                setValue={setPayload}
                                keyPayload={'giatri'}
                                type="text"
                                validate
                            />
                        </div>
                        <div className="form-group col-md-6">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Ngày bắt đầu thực hiện'}
                                value={payload.ngaybatdau}
                                setValue={setPayload}
                                keyPayload={'ngaybatdau'}
                                className={"my-0"}
                                type='DATE'
                                validate
                            />
                        </div>
                    </div>
                    <div className="btn-group my-2">
                        <button className="btn btn-primary px-5" onClick={handleSubmit}>
                            Cập nhật
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default ModalEditMoiquanhenhanvien;
