import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { InputForm } from '~/components/Form';
import * as actions from '~/store/actions';
import { dataSelectKieuPhep } from '~/utils/Datacustom';
import '../Modal.scss';
import Select from 'react-select';
import {
    Icon,
    SelectFinNhanvienfix,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    Button,
} from '~/components/Component';
import { Modal, ModalBody, Input, Alert } from 'reactstrap';
import { validateNghiphepcongtac } from '~/request/validateNghiphepcongtac';
import { FileAllCodeContext } from '../../user-manage/FileAllCodeContext';
import ModalSelectDexuat from '../Dexuat/ModalSelectDexuat';
import { ModalSelectType } from '~/pages/Admin/Modal';
import {format, parseISO, isBefore, isEqual, addMonths} from 'date-fns';
import DatePicker from 'react-datepicker';
import { apiGetPhancatonghop, apiGetNghiphepcongtac } from '~/services';
import { toast } from 'react-toastify';
import {matusinhResult} from '~/utils/Utils';
import { apiDuyetphep } from '~/services';

const ModalNghiphepcongtac = ({ show, setShow, fieldList, title, type }) => {
    const { lydonghiphep } = useSelector((state) => state.quanly);
    // console.log(title)
    const dispatch = useDispatch();
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayload] = useState(fieldList);
    // const [startTime, setStartTime] = useState(null);
    const [quytrinh, setQuytrinh] = useState([]);
    useEffect(() => {
        dispatch(actions.getListCauhinhpheduyet()); // type_duyet = 0 là tăng ca
    }, []);
    const { cauhinhpheduyet } = useSelector((state) => state.quanly);

    // console.log('payload type', type);
    const { handleThongbaoDataSocket } = useContext(FileAllCodeContext);
    useEffect(() => {
        show && setPayload(fieldList);
        setFormValues([]);
        setlistlistPhancaNhanvien([]);
        let md = '';
        let type_duyet = 0;
        if(type === "NGHI_PHEP"){
            md = "NP";
            type_duyet = 2; 
        } else if(type === "DI_MUON_VE_SOM"){
            md = "DMVS";
            type_duyet = 3;
        } else if(type === "CONG_TAC"){
            md = "CT";
            type_duyet = 4;
        } else if(type === "CAP_NHAT_CONG"){
            md = "CNC";
            type_duyet = 5;
        } else if(type === "PHIEU_RA_CONG"){
            md = "PRC";
            type_duyet = 6;
        }
        if (cauhinhpheduyet && cauhinhpheduyet.length > 0) {
            const arrQuytrinh = cauhinhpheduyet.filter((item) => (Number(item.type_duyet) === type_duyet)).map((item) => ({...item, value: item.id, label: item.title}));
            // console.log('arrQuytrinh', arrQuytrinh)
            setQuytrinh(arrQuytrinh);
        }
        const fetchData = async () => {
            const dataAll = await apiGetNghiphepcongtac();
            let data = dataAll?.data?.data?.map((item) => ({matusinh: item.madon}));
            setPayload((prev) => ({
                ...prev,
                ['madon']: matusinhResult(data && data.length > 0 ? data : [{}], md),
            }));
        };
        fetchData().catch(console.error);
        
    }, [fieldList, show, cauhinhpheduyet]);

    const [listNhanvien, setlistNhanvien] = useState([]);
    const [listCongtac, setlistCongtac] = useState([]);
    // console.log('listNhanvien', listNhanvien)
    const [showSelect, setShowSelect] = useState(false);
    // const [showSelectType, setShowSelectType] = useState(false);
    const [listLydonghiphep, setlistLydonghiphep] = useState([]);
    const [listLydonghipheptmp, setlistLydonghipheptmp] = useState([]);
    // const [listpheduyet, setlistlistpheduyet] = useState([]);
    const [listPhancaNhanVien, setlistlistPhancaNhanvien] = useState([]);
    // console.log('listPhancaNhanVien', listPhancaNhanVien);
    // const [listType, setListType] = useState([]);

    // setPayload((prev) => ({ ...prev, [statename]: selected,
    //     ['tylehuongluong']: tylehuongluong,
    //     ['ngaynghitoida']: ngaynghitoida,
    //     ['songayconlai']: songayconlai ,
    //     ['songaydanghi']: songaydanghi
    // }));

    useEffect(() => {
        setPayload((prev) => ({
            ...prev,
            ['NhanvienId']: listNhanvien,
        }));
        if(type === 'DI_MUON_VE_SOM'){
        if (listNhanvien.length > 0) {
            setlistCongtac(listNhanvien[0].Quanlynghiphepcongtacnvs);
            if (payload.ngaybatdau !== '') {
                // console.log('object', payload.ngaybatdau , listNhanvien)
                const thangxin = payload.ngaybatdau.split('-');
                // console.log('objectaaaaaaaaathangxin', thangxin)
                const thang = `${thangxin[0]}-${thangxin[1]}`;
                // console.log('objectaaaaaaaaa', thang, listNhanvien[0].value)
                const fetchData = async () => {
                    try {
                        const response = await apiGetPhancatonghop({
                            thangXn: thang,
                            NhanvienIdXn: listNhanvien[0].value,
                        });
                        const part = response?.data?.data.map((item) => ({
                            hoten: item?.Nhanvien?.hoten,
                            uuid: item?.Nhanvien?.uuid,
                            data_phanca: item?.data_phanca ? JSON.parse(item?.data_phanca) : '',
                        }));
                        if (part.length > 0) {
                            const firstItem = part[0];
                
                            if (firstItem && firstItem.data_phanca !== null) {
                                const dataPc = firstItem.data_phanca.find((item) => item.ngay === payload.ngaybatdau);
                                if (dataPc && dataPc.calamviec) {
                                    setlistlistPhancaNhanvien(dataPc.calamviec);
                                } else {
                                    setlistlistPhancaNhanvien([]);
                                    toast.error(`Nhân viên ${firstItem.hoten} không có ca làm việc ngày ${payload.ngaybatdau}`);
                                }
                            } else {
                                setlistlistPhancaNhanvien([]);
                                toast.error(`Nhân viên ${firstItem.hoten} không có ca làm việc ngày ${payload.ngaybatdau}`);
                            }
                        } else {
                            setlistlistPhancaNhanvien([]);
                            // toast.error(`Không có dữ liệu phân ca cho nhân viên`);
                        }
                    } catch (error) {
                        setlistlistPhancaNhanvien([]);
                        console.error(error);
                    }
                };
                fetchData().catch(console.error);
            }
        }
    }
        setlistLydonghipheptmp(listLydonghiphep);
    }, [listNhanvien, listLydonghiphep, payload.ngaybatdau]);

    useEffect(() => {
        if (lydonghiphep ) {
            let dataSelectLydonghiphep = lydonghiphep.map((item) => ({
                songaynghitoida: item?.songaynghitoida,
                tylehuongluong: item?.tylehuongluong,
                label: item.tieude,
                value: item.id,
            }));
            setlistLydonghiphep(dataSelectLydonghiphep);
            // const nguoiduyet = cauhinhnghiphep[0]?.nguoiduyet?.split('|').map((item) => ({
            //     userid: item,
            //     trangthai: false,
            // }));
            // setlistlistpheduyet(nguoiduyet);
        }
    }, [lydonghiphep]);
    const handleSubmit = async () => {
        // console.log('payload', payload);
        let aruser = 0;
        if(payload.PheduyetId !== '' && quytrinh.length > 0){
             
            const qt = quytrinh.find((item) => (Number(item.type_duyet) === Number(payload.PheduyetId.value)));
            const arQt = qt?.arrpheduyet ? JSON.parse(qt?.arrpheduyet) : [];
            if (arQt?.length > 0 && arQt[0]?.Nhanvien?.length > 0) {
                const idnguoiduyet = arQt[0]?.Nhanvien[0]?.value;
                aruser = idnguoiduyet;
              }
        }
        if (type !== 'DI_MUON_VE_SOM') {
            const NGHI_PHEP = 'NGHI_PHEP';
            let invalids = validate(payload);
            // let ngayNghiToiDa = Number(payload.ngaynghitoida || 0);
            // let soNgayNghiToiDa = Number(payload.songaynghitoida || 0);
            // let soNgayConLai = Number(payload.songayconlai || 0);
            // let soNgayDaNghi = Number(payload.songaydanghi || 0);
            // if (invalids === 0 && soNgayNghiToiDa <= soNgayConLai) {
                let checkNP = 0;
                if (type === 'NGHI_PHEP') {
                    if(payload?.songaynghitoida <= payload?.ngaynghitoida && Number(payload?.songaynghitoida) !== 0){
                        checkNP = 0;
                    }else if(Number(payload?.songaynghitoida) === 0){
                        checkNP = 1;
                        toast.error('Ngày xin nghỉ của bạn không có, Bạn chưa được phân ca trong khoảng ngày bắt đầu và ngày kết thúc!');
                    }else{
                        checkNP = 1;
                        toast.error('Số ngày xin nghỉ của bạn nhiều hơn số ngày nghỉ phép tối đa!');
                    }
                }
            if (invalids === 0 && checkNP === 0) {
                let dateBatDau = format(new Date(payload.ngaybatdau), 'yyyy-MM-dd');
                let timeBatDau = format(new Date(payload.ngaybatdau), 'HH:mm');
                let dateKetThuc = format(new Date(payload.ngayketthuc), 'yyyy-MM-dd');
                let timeKetThuc = format(new Date(payload.ngayketthuc), 'HH:mm');
                let tyLeHuongLuong = type === NGHI_PHEP ? payload.LydonghiphepId.tylehuongluong : 0;
                let lyDoNghiPhep = type === NGHI_PHEP ? payload.LydonghiphepId.value : null;
                let datapayload = {
                    madon: payload.madon,
                    LydonghiphepId: lyDoNghiPhep,
                    tylehuongluong: tyLeHuongLuong,
                    songaynghitoida: payload.songaynghitoida,
                    lydoNghi: payload.lydoNghi || null,
                    NhanvienId: payload.NhanvienId,
                    type: type,
                    ngaybatdau: dateBatDau,
                    ngayketthuc: dateKetThuc,
                    giobatdau: payload.giobatdau,
                    gioketthuc: payload.gioketthuc,
                    ghichu: payload.ghichu,
                    // TrangthaiId: 0,
                    // pheduyet: listpheduyet,
                    loaihuong: payload.loaihuong,
                    giatri: payload.giatri,
                    PheduyetId: payload.PheduyetId.value,
                };
                // console.log('datapayload', datapayload);
                dispatch(actions.NewNghiphepcongtac(datapayload));
                if(aruser !== 0){
                    let arr = [aruser];
                    handleThongbaoDataSocket(arr.join('|'));
                }
            } else {
                console.log('error');
            }
        } else {
            let dateBatDau = format(new Date(payload.ngaybatdau), 'yyyy-MM-dd');
            if (formValues.length > 0) {
                if (canhbao.length === 0) {
                    const arrkhoanggio = formValues.map((item) => ({
                        giobatdauxin: item.giobatdauxin ? format(new Date(item.giobatdauxin), 'HH:mm:ss') : '',
                        gioketthucxin: item.gioketthucxin ? format(new Date(item.gioketthucxin), 'HH:mm:ss') : '',
                    }));
                    // console.log('arrkhoanggio', arrkhoanggio);
                    let datapayload = {
                        madon: payload.madon,
                        NhanvienId: payload.NhanvienId,
                        type: type,
                        ngaybatdau: dateBatDau,
                        ghichu: payload.ghichu,
                        // pheduyet: listpheduyet,
                        arrkhoanggio: arrkhoanggio,
                        PheduyetId: payload.PheduyetId.value,
                    };
                    // console.log('datapayload', datapayload);
                    dispatch(actions.NewNghiphepcongtac(datapayload));
                    if(aruser !== 0){
                        let arr = [aruser];
                        handleThongbaoDataSocket(arr.join('|'));
                    }
                } else {
                    toast.error('Không thành công vui lòng khắc phục hết lỗi cảnh báo!');
                }
            } else {
                toast.error('Bạn chưa thêm khoảng thời gian đi muộn về sớm');
            }
        }
    };

    // const handleSetNgayPhep = (data)=>{
    //     data.fil
    // }
    // const tuan = ['Thứ 2', 'Thứ 3', 'Thứ 4', 'Thứ 5', 'Thứ 6', 'Thứ 7', 'Chủ nhật'];
    // const [columnTuan, setColumnTuan] = useState(() => Array(tuan.length).fill(1));
    // console.log('payload', payload)

    // const handleOnSelect = async (selected, name) => {
    //     let statename = name.name;
    //     const NGHI_PHEP_ID = 1; // Tính cho loại nghỉ phép
    //     let totalDaNghi = 0;
    //     let tylehuongluong = selected.tylehuongluong === 0 ? '0' : selected.tylehuongluong;
    //     let ngaynghitoida = selected.songaynghitoida;
    //     let songayconlai = selected.songaynghitoida;
    //     let songaydanghi = 0;

    //     if (selected.value === NGHI_PHEP_ID) {
    //         let abc = listCongtac?.filter((item) => item?.LydonghiphepId === selected.value && item?.TrangthaiId === 3);
    //         totalDaNghi = abc?.reduce((a, v) => a + v?.songaynghitoida ?? 0, 0);
    //         tylehuongluong = selected.tylehuongluong === 0 ? '0' : selected.tylehuongluong;
    //         ngaynghitoida = 10;
    //         songayconlai = ngaynghitoida - payload.songaynghitoida
    //     }
    //     setPayload((prev) => ({
    //         ...prev,
    //         [statename]: selected,
    //         ['tylehuongluong']: tylehuongluong,
    //         ['ngaynghitoida']: ngaynghitoida,
    //         ['songayconlai']: songayconlai,
    //         ['songaydanghi']: songaydanghi,
    //     }));
    // };
    const LayPc = async (thang, NhanvienId) =>{
        // console.log('thang', thang, NhanvienId)
        const response = await apiGetPhancatonghop({
            thangXn: thang,
            NhanvienIdXn: NhanvienId,
        });
        return response;
    }
    useEffect(() => {
        const fetchData = async () => {
        if(type !== 'DI_MUON_VE_SOM'){
        const { ngaybatdau, ngayketthuc, LydonghiphepId } = payload;
        let tylehuongluong = LydonghiphepId.tylehuongluong === 0 ? '0' : LydonghiphepId.tylehuongluong;
       
        let nghipheptoida = 0;
        if (ngaybatdau !== '' && ngayketthuc !== '') {
            const ngaybatdau = payload.ngaybatdau;
            const ngayketthuc = payload.ngayketthuc;
            const startDate = new Date(ngaybatdau);
            const endDate = new Date(ngayketthuc);
            const arr = [];
            let currentMonth = startDate;
            while (currentMonth < endDate || format(currentMonth, 'yyyy-MM') === format(endDate, 'yyyy-MM')) {
                arr.push({
                    year: currentMonth.getFullYear(),
                    month: (currentMonth.getMonth() + 1).toString().padStart(2, '0'),
                });
                currentMonth = addMonths(currentMonth, 1);
            }
            const ngaybatdaus = new Date(ngaybatdau);
            const ngayketthucs = new Date(ngayketthuc);
            const tatcaNgay = [];
            let ngayHienTai = ngaybatdaus;
            
            while (isBefore(ngayHienTai, ngayketthucs) || isEqual(ngayHienTai, ngayketthucs)) {
                tatcaNgay.push(format(ngayHienTai, "yyyy-MM-dd"));
                ngayHienTai = new Date(ngayHienTai.setDate(ngayHienTai.getDate() + 1));
            }
            let mangngaya = [];
            for (const aa of arr) {
                const layphanca = await LayPc(`${aa.year}-${aa.month}`, payload?.NhanvienId[0]?.value);
                const part = layphanca?.data?.data.map((item) => ({
                    hoten: item?.Nhanvien?.hoten,
                    uuid: item?.Nhanvien?.uuid,
                    data_phanca: item?.data_phanca ? JSON.parse(item?.data_phanca) : '',
                }));
                // console.log('layphancaaa', payload?.NhanvienId[0]?.value)
                let tonga = 0;
                for (const tatcaNgays of tatcaNgay) {
                  let ngaytc = tatcaNgays.split("-");
                //   console.log('part', part)
                  if (part.length > 0) {
                        const firstItem = part[0];
                        if (firstItem && firstItem.data_phanca !== null) {
                            const dataPc = firstItem.data_phanca.find((item) => item.ngay === tatcaNgays);
                            if(dataPc?.calamviec !== null){
                                if(Number(aa.month) === Number(ngaytc[1])){
                                    tonga += 1;
                                }
                            }
                            
                        }
                    }
                }
                mangngaya.push({year: aa.year, month: aa.month, songay: tonga})
              }
            //   console.log('mangngaya', mangngaya)
              if(mangngaya.length > 0){
                mangngaya.forEach((item) => {
                    nghipheptoida += item.songay;
                });
              }
            const bd = ngaybatdau.split('-');
            const kt = ngayketthuc.split('-');
            if (bd.length === 3 && kt.length === 3) {
                const isValidYear = (year) => year >= 2000 && year <= 2100;

                if (isValidYear(bd[0]) && isValidYear(kt[0])) {
                    const ngayBatDau = new Date(ngaybatdau);
                    const ngayKetThuc = new Date(ngayketthuc);
                    if (ngayBatDau <= ngayKetThuc) {
                        // const timeDifference = ngayKetThuc.getTime() - ngayBatDau.getTime();
                        //     const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
                        setPayload((prev) => ({ ...prev, songaynghitoida: nghipheptoida }));
                    } else {
                        console.log('Ngày bắt đầu phải nhỏ hơn ngày kết thúc!');
                        setPayload((prev) => ({ ...prev, songaynghitoida: '0' }));
                    }
                } else {
                    console.log('Năm nhập chỉ trong khoảng 2000 đến 2100!');
                }
            }
        let songayconlai = 0;
        let ngaynghitoida = 0;
        if (payload?.LydonghiphepId?.value === 1) {
            if (payload?.NhanvienId?.length > 0) {
                        let datapay = {
                            idnv: payload?.NhanvienId[0]?.value,
                        };
                        const Duyetphep = await apiDuyetphep(datapay);
                        ngaynghitoida = Duyetphep?.data?.data;
                        songayconlai = Duyetphep?.data?.data - nghipheptoida;
            }
        }else{
            songayconlai = LydonghiphepId.songaynghitoida - nghipheptoida;
            ngaynghitoida = LydonghiphepId.songaynghitoida;
        }
        setPayload((prev) => ({
            ...prev,
            ['tylehuongluong']: tylehuongluong,
            ['ngaynghitoida']: ngaynghitoida,
            ['songayconlai']: songayconlai,
            // ['songaydanghi']: songaydanghi,
        }));
    }

        }
    };
    fetchData().catch(console.error);
    }, [payload.ngaybatdau, payload.ngayketthuc, payload.LydonghiphepId, payload?.NhanvienId]);

    const [formValues, setFormValues] = useState([]);
    // console.log('formValues', formValues);
    const handleChange = (i, e, name) => {
        let newFormValues = [...formValues];
        newFormValues[i][name] = e;
        setFormValues(newFormValues);
    };

    const addFormFields = () => {
        let mang = [
            ...formValues,
            { giobatdauxin: new Date(`2023-10-10 08:00:00`), gioketthucxin: new Date(`2023-10-10 08:30:00`) },
        ];
        setFormValues(mang);
    };

    const removeFormFields = (item, i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues);
    };

    // phần cảnh báo người dùng
    const kiemTraTrungLapGio = (danhSachGio) => {
        const gioDaKiemTra = [];

        for (const gio of danhSachGio) {
            const gioBatDau = new Date(gio.giobatdauxin).getTime(); // Convert to timestamp
            const gioKetThuc = new Date(gio.gioketthucxin).getTime(); // Convert to timestamp
            // Tính giờ bắt đầu và kết thúc quá 5 tiếng là báo lỗi
            const diff = Math.abs(gioKetThuc - gioBatDau);
            const gioTrongMillis = Math.floor(diff / 3600000); // 1 giờ = 3600000 milliseconds
            //   console.log('gio', gioTrongMillis);
            if (
                gioKetThuc <= gioBatDau ||
                gioDaKiemTra.includes(gioBatDau) ||
                gioDaKiemTra.includes(gioKetThuc) ||
                gioTrongMillis >= 5
            ) {
                // Nếu giờ bắt đầu hoặc giờ kết thúc đã tồn tại trong mảng, có trùng lặp
                return true;
            } else {
                gioDaKiemTra.push(gioBatDau, gioKetThuc);
            }
        }

        // Không có trùng lặp
        return false;
    };
    const [canhbao, setCanhbao] = useState([]);
    // console.log('canhbao', canhbao);
    useEffect(() => {
        if (formValues.length > 0 && listPhancaNhanVien.length > 0) {
            const arrkhoanggio = formValues.map((item) => ({
                giobatdauxin: item.giobatdauxin ? format(new Date(item.giobatdauxin), 'HH:mm:ss') : '',
                gioketthucxin: item.gioketthucxin ? format(new Date(item.gioketthucxin), 'HH:mm:ss') : '',
            }));
            // console.log('arrkhoanggio', arrkhoanggio)
            const coTrungLap = kiemTraTrungLapGio(formValues);
            if (!coTrungLap) {
                setCanhbao([{ mev: '' }]);
                for (const ArrPhanca of listPhancaNhanVien) {
                    let d = [];
                    for (const ArrTime of arrkhoanggio) {
                        if (ArrTime.giobatdauxin !== '' || ArrTime.gioketthucxin !== '') {
                            const gioVaoLamViec = ArrPhanca.Khaibaocalamviec.giovaolamviec;
                            const gioKetThuclamViec = ArrPhanca.Khaibaocalamviec.gioketthuclamviec;

                            if (ArrTime.giobatdauxin !== gioVaoLamViec && ArrTime.gioketthucxin !== gioKetThuclamViec) {
                                const mes = {
                                    mes: `Giờ bắt đầu ${
                                        ArrTime.giobatdauxin ? ArrTime.giobatdauxin : 'HH:mm'
                                    } và giờ kết thúc ${
                                        ArrTime.gioketthucxin ? ArrTime.gioketthucxin : 'HH:mm'
                                    } không phù hợp với các ca làm việc nào.`,
                                };
                                d.push(mes);
                                setCanhbao(d);
                            } else {
                                setCanhbao([]);
                            }
                        }
                    }
                }
            } else {
                setCanhbao([
                    {
                        mev: 'Giờ bắt đầu hoặc giờ kết thúc trùng nhau hoặc Giờ kết thúc phải > Giờ bắt đầu và khoảng thời gian xin không vượt quá 5 giờ',
                    },
                ]);
                // toast.error();
            }
        }
    }, [formValues, listPhancaNhanVien]);
    const inPutLyDo = (type) => {
        const componentsMap = {
            NGHI_PHEP: (
                <div className="form-group row">
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ngày bắt đầu'}
                            value={payload.ngaybatdau}
                            setValue={setPayload}
                            keyPayload={'ngaybatdau'}
                            type="date"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-6 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ngày kết thúc'}
                            value={payload.ngayketthuc}
                            setValue={setPayload}
                            keyPayload={'ngayketthuc'}
                            type="date"
                            validate
                        />
                    </div>
                    <div className="col-6">
                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                            Lý do {title} <b className="text-danger">(*)</b>
                        </label>
                        <Select
                            value={payload.LydonghiphepId}
                            // onChange={handleOnSelect}
                            onChange={(selected) =>
                                setPayload((prev) => ({ ...prev, ['LydonghiphepId']: selected }))
                            }
                            options={listLydonghipheptmp}
                            placeholder={'Chọn lý do'}
                            name="LydonghiphepId"
                            onFocus={() => setInvalidFields([])}
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'LydonghiphepId') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'LydonghiphepId')?.message}
                            </small>
                        )}
                    </div>
                    <div className="col-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Tỷ lệ hưởng lương (%)'}
                            value={payload.tylehuongluong || '0'}
                            setValue={setPayload}
                            keyPayload={'tylehuongluong'}
                            type="number"
                            disabled={true}
                            validate
                        />
                    </div>
                    <div className="col-4">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Số ngày xin nghỉ'}
                            value={payload.songaynghitoida || '0'}
                            // setValue={setPayload}
                            keyPayload={'songaynghitoida'}
                            type="number"
                            disabled={true}
                            validate
                        />
                    </div>
                   
                    <div className="col-4">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Số ngày nghỉ tối đa'}
                            value={payload.ngaynghitoida || '0'}
                            setValue={setPayload}
                            keyPayload={'ngaynghitoida'}
                            type="number"
                            disabled={true}
                            validate
                        />
                    </div>
                    {/* <div className="col-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ngày phép đã nghỉ'}
                            value={payload.songaydanghi}
                            setValue={setPayload}
                            keyPayload={'songaydanghi'}
                            type="text"
                            disabled={true}
                            validate
                        />
                    </div> */}
                    <div className="col-4">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Số ngày còn lại'}
                            value={payload.songayconlai || '0'}
                            setValue={setPayload}
                            keyPayload={'songayconlai'}
                            type="number"
                            disabled={true}
                            validate
                        />
                    </div>
                </div>
            ),
            CONG_TAC: (
                <div className="form-group row">
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ngày bắt đầu'}
                            value={payload.ngaybatdau}
                            setValue={setPayload}
                            keyPayload={'ngaybatdau'}
                            type="date"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-6 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ngày kết thúc'}
                            value={payload.ngayketthuc}
                            setValue={setPayload}
                            keyPayload={'ngayketthuc'}
                            type="date"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-12 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Lý do công tác'}
                            value={payload.lydoNghi}
                            setValue={setPayload}
                            keyPayload={'lydoNghi'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-12 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Giá trị / Ngày'}
                            value={payload.giatri}
                            setValue={setPayload}
                            keyPayload={'giatri'}
                            type="text"
                            validate
                        />
                    </div>
                </div>
            ),
            CAP_NHAT_CONG: (
                <div className="form-group row">
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ngày bắt đầu'}
                            value={payload.ngaybatdau}
                            setValue={setPayload}
                            keyPayload={'ngaybatdau'}
                            type="date"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-6 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ngày kết thúc'}
                            value={payload.ngayketthuc}
                            setValue={setPayload}
                            keyPayload={'ngayketthuc'}
                            type="date"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-12 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Lý do'}
                            value={payload.lydoNghi}
                            setValue={setPayload}
                            keyPayload={'lydoNghi'}
                            type="text"
                            validate
                        />
                    </div>
                </div>
            ),
            DI_MUON_VE_SOM: (
                <>
                    <div className="form-group row">
                        <div className="col-12 mb-3">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Ngày xin'}
                                value={payload.ngaybatdau}
                                setValue={setPayload}
                                keyPayload={'ngaybatdau'}
                                type="date"
                                validate
                            />
                        </div>
                        {listPhancaNhanVien.length > 0 && (
                            <div className="col-12 mb-3">
                                <label htmlFor="inputZip" className="mb-2 fw-bold">
                                    {`Danh sách ca làm việc ${listNhanvien[0].label} ngày ${
                                        payload.ngaybatdau ? format(new Date(payload.ngaybatdau), 'dd-MM-yyyy') : ''
                                    } `}
                                </label>
                                <div style={{ overflowY: 'hidden' }}>
                                    <DataTableBody>
                                        <DataTableHead className="col-12 ">
                                            <DataTableRow className="w-50px">
                                                <span className="sub-text ">Stt</span>
                                            </DataTableRow>
                                            <DataTableRow>
                                                <span className="sub-text w-150px">Mã ca làm việc</span>
                                            </DataTableRow>
                                            <DataTableRow>
                                                <span className="sub-text w-80px">Giờ vào</span>
                                            </DataTableRow>
                                            <DataTableRow>
                                                <span className="sub-text w-80px">Giờ ra</span>
                                            </DataTableRow>
                                        </DataTableHead>
                                        {listPhancaNhanVien.map((item, index) => {
                                            return (
                                                <DataTableItem key={index}>
                                                    <DataTableRow size=" nk-tb-col-check mb">
                                                        <span className="tb-amount">{index + 1}</span>
                                                    </DataTableRow>
                                                    <DataTableRow>
                                                        <span className="tb-amount">
                                                            {item?.Khaibaocalamviec?.macalamviec}
                                                        </span>
                                                    </DataTableRow>
                                                    <DataTableRow>
                                                        <span className="tb-amount">
                                                            {item?.Khaibaocalamviec?.giovaolamviec}
                                                        </span>
                                                    </DataTableRow>
                                                    <DataTableRow>
                                                        <span className="tb-amount">
                                                            {item?.Khaibaocalamviec?.gioketthuclamviec}
                                                        </span>
                                                    </DataTableRow>
                                                </DataTableItem>
                                            );
                                        })}
                                    </DataTableBody>
                                </div>
                            </div>
                        )}
                        <div className="col-12 mb-3">
                            <div className="col-12 mt-3 mb-3">
                                <Button
                                    size="md"
                                    color="info"
                                    outline
                                    className="btn-icon btn-white btn-dim"
                                    style={{ paddingRight: '10px' }}
                                    onClick={() => addFormFields()}
                                >
                                    <Icon name="plus"></Icon> Thêm khoảng thời gian đi muộn về sớm
                                </Button>
                            </div>
                            {formValues?.length > 0 && (
                                <DataTableBody>
                                    <DataTableHead>
                                        <DataTableRow>
                                            <span className="sub-text">Stt</span>
                                        </DataTableRow>
                                        <DataTableRow size="mb">
                                            <span className="sub-text">Giờ bắt đầu</span>
                                        </DataTableRow>
                                        <DataTableRow size="mb">
                                            <span className="sub-text">Giờ kết thúc</span>
                                        </DataTableRow>
                                        <DataTableRow size="mb"></DataTableRow>
                                    </DataTableHead>
                                    {formValues?.map((item, it) => {
                                        return (
                                            <DataTableItem key={it}>
                                                <DataTableRow>
                                                    <span className="sub-text">{it + 1}</span>
                                                </DataTableRow>
                                                <DataTableRow size="mb">
                                                    <div className="form-control-wrap">
                                                        <DatePicker
                                                            selected={item.giobatdauxin || ''}
                                                            onChange={(date) => handleChange(it, date, 'giobatdauxin')}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            timeIntervals={30}
                                                            timeCaption="Time"
                                                            dateFormat="HH:mm"
                                                            className="form-control date-picker"
                                                        />
                                                    </div>
                                                </DataTableRow>
                                                <DataTableRow size="mb">
                                                    <div className="form-control-wrap">
                                                        <DatePicker
                                                            selected={item.gioketthucxin || ''}
                                                            onChange={(date) => handleChange(it, date, 'gioketthucxin')}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            timeIntervals={30}
                                                            timeCaption="Time"
                                                            dateFormat="HH:mm"
                                                            className="form-control date-picker"
                                                        />
                                                    </div>
                                                </DataTableRow>
                                                <DataTableRow className="nk-tb-col-tools">
                                                    <ul className="nk-tb-actions gx-1">
                                                        <>
                                                            <button 
                                                                onClick={() => removeFormFields(item, it)}
                                                                className={'btn btn-danger ms-0 badge text-white p-1'}
                                                            >
                                                                <em className="icon ni ni-trash-alt"></em>
                                                            </button>
                                                        </>
                                                    </ul>
                                                </DataTableRow>
                                            </DataTableItem>
                                        );
                                    })}
                                </DataTableBody>
                            )}
                        </div>
                        <div className="col-12 mb-3">
                            {formValues.length > 0 && listPhancaNhanVien.length > 0 ? (
                                canhbao.length > 0 ? (
                                    <Alert className="alert-icon" color="danger">
                                        <Icon name="cross-circle" />
                                        <div>
                                            <strong>Cảnh báo</strong>
                                        </div>
                                        {canhbao?.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    {item?.mes}
                                                    {item?.mev}
                                                </div>
                                            );
                                        })}
                                    </Alert>
                                ) : null
                            ) : null}
                        </div>
                    </div>
                    {/* <div className="row mt-1">
                        <div className="card-tools me-n1">
                            <ul className="btn-toolbar gx-5">
                                {tuan.length > 0 &&
                                    tuan.map((item, index) => (
                                        <li key={index}>
                                            <div className="custom-control custom-checkbox mt-2" style={{ zIndex: 0 }}>
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id={`columnTuan-${index}`}
                                                    checked={columnTuan[index] === 0 ? false : true}
                                                    onChange={(e) =>
                                                        setColumnTuan((prev) => {
                                                            const newColumns = [...prev];
                                                            newColumns[index] = e.target.checked === false ? 0 : 1;
                                                            return newColumns;
                                                        })
                                                    }
                                                />
                                                <label className="custom-control-label" htmlFor={`columnTuan-${index}`}>
                                                    {item}
                                                </label>
                                            </div>
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    </div> */}
                </>
            ),
            PHIEU_RA_CONG: (
                <div className="form-group row">
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ngày bắt đầu'}
                            value={payload.ngaybatdau}
                            setValue={setPayload}
                            keyPayload={'ngaybatdau'}
                            type="date"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-6 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ngày kết thúc'}
                            value={payload.ngayketthuc}
                            setValue={setPayload}
                            keyPayload={'ngayketthuc'}
                            type="date"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-6 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Giờ bắt đầu'}
                            value={payload.giobatdau}
                            setValue={setPayload}
                            keyPayload={'giobatdau'}
                            type="time"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-6 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Giờ kết thúc'}
                            value={payload.gioketthuc}
                            setValue={setPayload}
                            keyPayload={'gioketthuc'}
                            type="time"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-12 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Địa điểm tới'}
                            value={payload.lydoNghi}
                            setValue={setPayload}
                            keyPayload={'lydoNghi'}
                            type="text"
                        />
                    </div>
                    <div className="mt-1">
                        <div className="form-group col-md-12">
                            <div className="pb-0">
                                <div className="g-4 align-center flex-wrap">
                                    <div className="g">
                                        <div className="custom-control custom-radio">
                                            <input
                                                type="radio"
                                                className="custom-control-input"
                                                name="loaihuong"
                                                id="loaihuong"
                                                onChange={(e) =>
                                                    setPayload((prev) => ({
                                                        ...prev,
                                                        ['loaihuong']: 0,
                                                    }))
                                                }
                                                checked={payload?.loaihuong === 0}
                                            />
                                            <label className="custom-control-label" htmlFor="loaihuong">
                                                Không hưởng công
                                            </label>
                                        </div>
                                    </div>
                                    <div className="g">
                                        <div className="custom-control custom-radio">
                                            <input
                                                type="radio"
                                                className="custom-control-input"
                                                name="loaihuong"
                                                id="loaihuong1"
                                                onChange={(e) =>
                                                    setPayload((prev) => ({
                                                        ...prev,
                                                        ['loaihuong']: 1,
                                                    }))
                                                }
                                                checked={payload?.loaihuong === 1}
                                            />
                                            <label className="custom-control-label" htmlFor="loaihuong1">
                                                Có hưởng công
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ),
        };

        return componentsMap[type] || null;
    };

    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateNghiphepcongtac(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateNghiphepcongtac(item[0], item[1])]);
                invalids++;
            }
        });

        return invalids;
    };
    return (
        <React.Fragment>
            <Modal isOpen={show} className="modal-dialog-centered modal-90w" size="lg" toggle={setShow}>
                <ModalBody>
                    <Icon name="cross-sm float-end fs-2" onClick={setShow}></Icon>
                    <h5 className="title mb-3">Thêm mới {title}</h5>
                    <div className="row">
                        <div className="form-group col-md-4" onClick={() => setShowSelect(true)}>
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                Chọn Nhân Viên <b className="text-danger">(*)</b>
                            </label>
                            <Select value={payload.NhanvienId} name="NhanvienId" onFocus={() => setInvalidFields([])} />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'NhanvienId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'NhanvienId')?.message}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-4">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Mã đơn'}
                                value={payload.madon}
                                // setValue={setPayload}
                                keyPayload={'giobatdau'}
                                type="text"
                                validate
                                disabled={true}
                            />
                        </div>
                        <div className="form-group col-md-4">
                                <div className="form-group">
                                    <label htmlFor="inputZip" className="mb-2 fw-bold">
                                        Áp dụng quy trình phê duyệt <b className="text-danger">(*)</b>
                                    </label>
                                    <Select
                                        value={payload?.PheduyetId}
                                        // onChange={handleOnSelect}
                                        onChange={(selected) =>
                                            setPayload((prev) => ({ ...prev, ['PheduyetId']: selected }))
                                        }
                                        options={quytrinh}
                                        placeholder={'Chọn quy trình'}
                                        name="PheduyetId"
                                        onFocus={() => setInvalidFields([])}
                                    />
                                    {invalidFields.length > 0 &&
                                        invalidFields.some((i) => i.name === 'PheduyetId') && (
                                            <small className="text-danger">
                                                {invalidFields.find((i) => i.name === 'PheduyetId')?.message}
                                            </small>
                                        )}
                                </div>
                            </div>
                        {/*<div className="form-group col-md-6">*/}
                        {/*    <InputForm*/}
                        {/*        setInvalidFields={setInvalidFields}*/}
                        {/*        invalidFields={invalidFields}*/}
                        {/*        label={'Giờ bắt đầu'}*/}
                        {/*        value={payload.giobatdau}*/}
                        {/*        setValue={setPayload}*/}
                        {/*        keyPayload={'giobatdau'}*/}
                        {/*        type="time"*/}
                        {/*        validate*/}
                        {/*    />*/}
                        {/*</div>*/}
                        {/*<div className="form-group col-md-6 ">*/}
                        {/*    <InputForm*/}
                        {/*        setInvalidFields={setInvalidFields}*/}
                        {/*        invalidFields={invalidFields}*/}
                        {/*        label={'Giờ kết thúc'}*/}
                        {/*        value={payload.gioketthuc}*/}
                        {/*        setValue={setPayload}*/}
                        {/*        keyPayload={'gioketthuc'}*/}
                        {/*        type="time"*/}
                        {/*        validate*/}
                        {/*    />*/}
                        {/*</div>*/}

                        <div className="col-md-12">{inPutLyDo(type, title)}</div>
                        <div className="form-group col-md-12 mt-2">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                <span>Ghi chú</span>
                            </label>
                            <Input
                                value={payload.ghichu}
                                onChange={(e) => setPayload((prev) => ({ ...prev, ['ghichu']: e.target.value }))}
                                rows={'2'}
                                name="ghichu"
                                type="textarea"
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'ghichu') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'ghichu')?.message}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className="col-12 mt-5">
                        {type !== 'DI_MUON_VE_SOM' ? (
                            <Button color="primary" onClick={handleSubmit}>
                                Thêm mới
                            </Button>
                        ) : formValues.length > 0 && listPhancaNhanVien.length > 0 ? (
                            <Button color="primary" onClick={handleSubmit}>
                                Thêm mới
                            </Button>
                        ) : (
                            <Button color="primary" onClick={() => toast.error("Bạn chưa chọn nhân viên và ngày xin, hoặc bạn chưa được phân ca và chưa có khoảng thời gian xin!")}>
                                Thêm mới
                            </Button>
                        )}
                    </div>
                </ModalBody>
            </Modal>
            {/*<ModalSelectType*/}
            {/*    showSelect={showSelectType}*/}
            {/*    // typeSelect={true}*/}
            {/*    listType={listType}*/}
            {/*    setListType={setListType}*/}
            {/*    setShowSelect={() => setShowSelectType(false)}*/}
            {/*/>*/}
            <SelectFinNhanvienfix
                showSelect={showSelect}
                typeSelect={false}
                // listNhanvien={listNhanvien}
                setlistNhanvien={setlistNhanvien}
                setShowSelect={() => setShowSelect(false)}
            />
        </React.Fragment>
    );
};

export default ModalNghiphepcongtac;
