import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Button } from 'reactstrap';
import Head from '~/layout/head/Head';
import Content from '~/layout/content/Content';
import { Block } from '~/components/block/Block';
import { DataTable, PermissionUtils, Icon } from '~/components/Component';
import { Link } from 'react-router-dom';
import config from '~/config';
import { InputForm } from '~/components/Form';
import { apiEditEmailserver, apiGetEmailserver, apiNewEmailserver } from '~/services/emailserver';
import { toast } from 'react-toastify';
import { MenuDoanhnghiep } from './MenuTab/Menucontent';
import { NumericFormat } from 'react-number-format';

const Emailserver = () => {
    const dispatch = useDispatch();

    const [showPhanquyen, setShowPhanquyen] = useState({});
    let dataPermissionUtils = PermissionUtils('emailserver', 'all');
    useEffect(() => {
        if (dataPermissionUtils) {
            setShowPhanquyen(dataPermissionUtils);
        }
    }, [dataPermissionUtils]);

    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayload] = useState([
        {
            // module: '',
            maychu: '',
            cong: '',
            username: '',
            password: '',
            diachimailgui: '',
            diachimailhienthi: '',
            tenhienthi: '',
            baomat: '0',
            TrangthaiId: '',
        },
    ]);
    // console.log('payload', payload)
    useEffect(() => {
        const fetchData = async () => {
            try {
                const EmailServer = await apiGetEmailserver();
                const response = EmailServer.data.data.rows[0];
                // console.log('response', response)
                const datas = {
                    id: response?.id,
                    // module: response?.module,
                    maychu: response?.maychu,
                    cong: response?.cong,
                    username: response?.username,
                    password: response?.password,
                    diachimailgui: response?.diachimailgui,
                    diachimailhienthi: response?.diachimailhienthi,
                    tenhienthi: response?.tenhienthi,
                    baomat: response?.baomat,
                    // TrangthaiId: response?.TrangthaiId,
                };
                setPayload(datas);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData().catch(console.error);
    }, []);
    const [passState, setPassState] = useState(false);
    const handleSubmit = async () => {
        const datapayload = {
            data: [
                {
                    module: 1,
                    maychu: payload?.maychu,
                    cong: payload?.cong,
                    username: payload?.username,
                    password: payload?.password,
                    diachimailgui: payload?.diachimailgui,
                    diachimailhienthi: payload?.diachimailhienthi,
                    tenhienthi: payload?.tenhienthi,
                    baomat: Number(payload?.baomat),
                    TrangthaiId: 1,
                },
            ],
        };
        if (payload.id) {
            datapayload.data[0].id = payload.id;
            // console.log('datapayload', datapayload)
            const response = await apiEditEmailserver(datapayload);
            response?.data.length === 0
                ? toast.error('Sửa thông tin không thành công!')
                : toast.success('Sửa thông tin thành công!');
        } else {
            // console.log('datapayload', datapayload)
            const response = await apiNewEmailserver(datapayload);
            response?.data.length === 0
                ? toast.error('Thêm thông tin không thành công!')
                : toast.success('Thêm thông tin thành công!');
        }
    };
    return (
        <React.Fragment>
            {/*<Head title="Thiết lập Email Server"></Head>*/}
            <Content>
                <Card className="mt-0">
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner card-inner-lg px-4">
                            <h4>Cấu hình doanh nghiệp</h4>
                            <MenuDoanhnghiep />
                        </div>
                    </DataTable>
                </Card>
                <Block>
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner position-relative card-tools-toggle pt-0">
                            <div className="row">
                                <div className="form-group col-md-12">
                                    {+showPhanquyen?.them === 1 && (
                                        <Button
                                            color="primary"
                                            className="btn-icon px-4 mt-1"
                                            onClick={(e) => handleSubmit(e)}
                                        >
                                            Cập nhật
                                        </Button>
                                    )}
                                </div>

                                {/* <div className="form-group col-md-6">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Module'}
                                        value={payload?.module}
                                        setValue={setPayload}
                                        keyPayload={'module'}
                                        type="number"
                                        validate
                                    />
                                </div> */}
                                <div className="form-group col-md-6">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Máy chủ'}
                                        value={payload.maychu}
                                        setValue={setPayload}
                                        keyPayload={'maychu'}
                                        type="text"
                                        validate
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Cổng'}
                                        value={payload.cong}
                                        setValue={setPayload}
                                        keyPayload={'cong'}
                                        type="text"
                                        validate
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Họ tên'}
                                        value={payload.username}
                                        setValue={setPayload}
                                        keyPayload={'username'}
                                        type="text"
                                        validate
                                    />
                                </div>
                                <div className="form-group col-md-6 mt-1">
                                    <div className="form-label-group">
                                        <label className="form-label" htmlFor="password">
                                            <span>
                                                Mật khẩu <b className="text-danger">(*)</b>
                                            </span>
                                        </label>
                                    </div>
                                    <div className="form-control-wrap">
                                        <a
                                            href="#password"
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                setPassState(!passState);
                                            }}
                                            className={`form-icon md form-icon-right passcode-switch ${
                                                passState ? 'is-hidden' : 'is-shown'
                                            }`}
                                        >
                                            <Icon name="eye" className="passcode-icon icon-show"></Icon>

                                            <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                                        </a>
                                        <input
                                            type={passState ? 'text' : 'password'}
                                            id="password"
                                            name="password"
                                            placeholder="Nhập mật khẩu"
                                            className={`form-control-md form-control ${
                                                passState ? 'is-hidden' : 'is-shown'
                                            }`}
                                            value={payload.password || ''}
                                            onChange={(e) =>
                                                setPayload((prev) => ({ ...prev, ['password']: e.target.value }))
                                            }
                                            onFocus={() => setInvalidFields([])}
                                        />
                                        {invalidFields.length > 0 &&
                                            invalidFields.some((i) => i.name === 'password') && (
                                                <small className="text-danger">
                                                    {invalidFields.find((i) => i.name === 'password')?.message}
                                                </small>
                                            )}
                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Địa chỉ email gửi'}
                                        value={payload.diachimailgui}
                                        setValue={setPayload}
                                        keyPayload={'diachimailgui'}
                                        type="text"
                                        validate
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Địa chỉ email hiển thị'}
                                        value={payload.diachimailhienthi}
                                        setValue={setPayload}
                                        keyPayload={'diachimailhienthi'}
                                        type="text"
                                        validate
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Tên hiển thị'}
                                        value={payload.tenhienthi}
                                        setValue={setPayload}
                                        keyPayload={'tenhienthi'}
                                        type="text"
                                        validate
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <div className="preview-block">
                                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                                            <span>
                                            Phương thức bảo mật  <b className="text-danger">(*)</b>
                                            </span>
                                        </label>
                                        <div className="g-4 align-center flex-wrap ">
                                            <div className="g">
                                                <div className="custom-control custom-radio">
                                                    <input
                                                        value={0}
                                                        type="radio"
                                                        className="custom-control-input"
                                                        name="baomat"
                                                        id="customRadio7"
                                                        onChange={(e) =>
                                                            setPayload((prev) => ({
                                                                ...prev,
                                                                ['baomat']: e.target.value,
                                                            }))
                                                        }
                                                        checked={Number(payload.baomat) === 0}
                                                    />
                                                    <label className="custom-control-label" htmlFor="customRadio7">
                                                        Không
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="g">
                                                <div className="custom-control custom-radio">
                                                    <input
                                                        value={1}
                                                        type="radio"
                                                        className="custom-control-input"
                                                        name="baomat"
                                                        id="customRadio6"
                                                        onChange={(e) =>
                                                            setPayload((prev) => ({
                                                                ...prev,
                                                                ['baomat']: e.target.value,
                                                            }))
                                                        }
                                                        checked={Number(payload.baomat) === 1}
                                                    />
                                                    <label className="custom-control-label" htmlFor="customRadio6">
                                                        SSL/TLS
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="g">
                                                <div className="custom-control custom-radio">
                                                    <input
                                                        value={2}
                                                        type="radio"
                                                        className="custom-control-input"
                                                        name="baomat"
                                                        id="customRadio5"
                                                        onChange={(e) =>
                                                            setPayload((prev) => ({
                                                                ...prev,
                                                                ['baomat']: e.target.value,
                                                            }))
                                                        }
                                                        checked={Number(payload.baomat) === 2}
                                                    />
                                                    <label className="custom-control-label" htmlFor="customRadio5">
                                                        STARTTLS
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DataTable>
                </Block>
            </Content>
        </React.Fragment>
    );
};

export default Emailserver;
