import axiosConfig from '../axiosConfig'

export const apiGetNguoiPhuThuoc = () => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlynhanvien/nguoithan`,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiNewNguoiPhuThuoc = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlynhanvien/nguoithan',
            data: payload
        })
        // console.log('response1212',response)
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiEditNguoiPhuThuoc = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlynhanvien/nguoithan',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiDeleteNguoiPhuThuoc = (payload) => new Promise(async (resolve, reject) => {
    console.log('payload', payload)
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlynhanvien/nguoithan',
            data: {
                id:payload.id
            }
        })
        
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
