import React, { useEffect, useState } from 'react';
import Content from '~/layout/content/Content';
import Head from '~/layout/head/Head';
import AudienceOverview from '~/components/partials/analytics/audience-overview/AudienceOverview';
import ActiveUser from '~/components/partials/analytics/active-user/ActiveUser';
import WebsitePerformance from '~/components/partials/analytics/website-perfomance/WebsitePerfomance';
import TrafficChannel from '~/components/partials/analytics/traffic-channel/Traffic';
import TrafficDougnut from '~/components/partials/analytics/traffic-dougnut/TrafficDoughnut';
import TrafficHopdong from '~/components/partials/analytics/traffic-dougnut/TrafficHopdong';
import UserMap from '~/components/partials/analytics/user-map/UserMap';
import BrowserUser from '~/components/partials/analytics/browser-users/BrowserUser';
import PageViewer from '~/components/partials/analytics/page-view/PageView';
import SessionDevice from '~/components/partials/analytics/session-devices/SessionDevice';
import TransactionTable from '~/components/partials/default/transaction/Transaction';
import { DropdownToggle, DropdownMenu, Card, UncontrolledDropdown, DropdownItem, Alert } from 'reactstrap';
import {
    Block,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Icon,
    Button,
    Row,
    Col,
    PreviewAltCard,
} from '~/components/Component';
import ColumnChart from '~/components/partials/analytics/chart-column/ColumnChart';
import ColumnMulChart from '~/components/partials/analytics/chart-column/ColumnMulChart';
import { chartColumn, chartMulColumn } from '~/components/partials/charts/analytics/AnalyticsData';
import { dataSelectHocVan } from '~/utils/Datacustom';
import {
    apiDashboardHopDong,
    apiDashboardLuongTongHop,
    apiDataChiPhiNhanSu,
    apiDataThuNhapBQ,
    apiDataTongSoNhanVien,
    apiGetBienToanCucHeThong,
    apiDataNhanVienDiLam,
    apiDataNhanVienDiMuon,
    apiDataThucTeNghi,
    apiDataNhanVienMoi,
    apiDataNhanVienNghiViec,
    apiDataThuViecThanhCong,
} from '~/services';
import { apiGetCauhinhdoanhnghiep } from '~/services/cauhinhdoanhnghiep';
import { formatCurrencyVND, formatCurrencyVNDDS } from '~/utils/Common/Helper/FormatNumber';

import { InputForm } from '~/components/Form';
import { subDays, format } from 'date-fns';

function Dashboard() {
    const [invalidFields, setInvalidFields] = useState([]);
    const [sm, updateSm] = useState(false);
    let fiw = {totalCurrent:0, totalPrev:0, percent:0, is_check:true};
    const [dataAll, setData] = useState({
        nhanviendilam: 0,
        thuctedanghi: 0,
        dimuonvesom: 0,
        tongsonhanvien: '',
        nhanvienmoi: fiw,
        thuviecthanhcong: fiw,
        nghiviec: fiw,
    });
    const [traffic, setTraffic] = useState('Hôm nay');
    const [traffic1, setTraffic1] = useState('Hôm nay');
    const [traffic2, setTraffic2] = useState('Hôm nay');
    const [traffic3, setTraffic3] = useState('Hôm nay');

    // const [payloadTimeCustom, setPayload] = useState('homnay');

    const [Nhanvien, setNhanvien] = useState('Tuần này');
    const [Thuviec, setThuviec] = useState('Tuần này');
    const [Nghiviec, setNghiviec] = useState('Tuần này');
    // console.log('baoCaoNhanvien', baoCaoNhanvien)
    const [baoCaoTNBQ, setBaoCaoTNBQ] = useState([]);
    const [baoCaoCPNS, setBaoCaoCPNS] = useState([]);
    const [baoCaoHD, setHD] = useState([]);
    const [baoCaoLuong, setLuong] = useState([]);
    // const [timeRange, settimeRange] = useState([]);
    // const handleOnSelect = async (selected, name) => {
    //     let statename = name.name;
    //     setPayload((prev) => ({ ...prev, ['type']: selected }));
    // };
    
    useEffect(() => {
        setTimeout(() => {
            const fetchData = async () => {
                let nhanviendilam = '';
                if (traffic === 'Hôm nay') {
                    nhanviendilam = format(new Date(), 'yyyy-MM-dd');
                } else if (traffic === 'Hôm qua') {
                    const yesterday = subDays(new Date(), 1);
                    nhanviendilam = format(yesterday, 'yyyy-MM-dd');
                }
                const dataDiLam = await apiDataNhanVienDiLam({ day: nhanviendilam });
                   console.log('dataDiLam', dataDiLam)
                if (dataDiLam?.data?.success === 200) {
                    setData((prev) => ({ ...prev, ['nhanviendilam']: dataDiLam?.data?.nhanviendilam, ['dimuonvesom']: dataDiLam?.data?.tongcongtac, ['thuctedanghi']: dataDiLam?.data?.tongnghiphep}));
                }

                // let nhanviendimuon = '';
                // if (traffic1 === 'Hôm nay') {
                //     nhanviendimuon = 'homnay';
                // } else if (traffic1 === 'Hôm qua') {
                //     nhanviendimuon = 'homqua';
                // }
                // const dataDiMuon = await apiDataNhanVienDiMuon({ day: nhanviendimuon });
                // //    console.log('dataDiMuon', dataDiMuon)
                // if (dataDiMuon?.data.success === 200) {
                //     // setData((prev) => ({ ...prev, ['dimuonvesom']: dataDiMuon?.data?.data?.length }));
                // }

                let tongsonhanvien = '';
                if (traffic3 === 'Hôm nay') {
                    tongsonhanvien = 'homnay';
                } else if (traffic3 === 'Tuần này') {
                    tongsonhanvien = 'tuannay';
                } else if (traffic3 === 'Tháng này') {
                    tongsonhanvien = 'thangnay';
                }
                const dataTongSoNhanVien = await apiDataTongSoNhanVien({ day: tongsonhanvien });
                //    console.log('dataTongSoNhanVien', dataTongSoNhanVien)
                if (dataTongSoNhanVien?.data.success === 200) {
                    setData((prev) => ({ ...prev, ['tongsonhanvien']: dataTongSoNhanVien?.data }));
                }

                // let thuctenghi = '';
                // if (traffic2 === 'Hôm nay') {
                //     thuctenghi = 'homnay';
                // } else if (traffic2 === 'Tuần này') {
                //     thuctenghi = 'tuannay';
                // } else if (traffic2 === 'Tháng này') {
                //     thuctenghi = 'thangnay';
                // }
                // const dataThucTeNghi = await apiDataThucTeNghi({ day: thuctenghi });
                // //    console.log('dataThucTeNghi', dataThucTeNghi)
                // if (dataThucTeNghi?.data.success === 200) {
                //     // setData((prev) => ({ ...prev, ['thuctedanghi']: dataThucTeNghi?.data?.data }));
                // }

                let nhanvienmoi = '';
                if (Nhanvien === 'Tuần này') {
                    nhanvienmoi = 'tuannay';
                } else if (Nhanvien === 'Tháng này') {
                    nhanvienmoi = 'thangnay';
                }
                const dataNhanvienmoi = await apiDataNhanVienMoi({ day: nhanvienmoi });
                   // console.log('dataNhanvienmoi', dataNhanvienmoi)
                if (dataNhanvienmoi?.data.success === 200) {
                    setData((prev) => ({ ...prev, ['nhanvienmoi']: dataNhanvienmoi?.data?.data }));
                }

                let thuviecthanhcong = '';
                if (Thuviec === 'Tuần này') {
                    thuviecthanhcong = 'tuannay';
                } else if (Thuviec === 'Tháng này') {
                    thuviecthanhcong = 'thangnay';
                }
                const dataThuviecthanhcong = await apiDataThuViecThanhCong({ day: thuviecthanhcong });
                //    console.log('dataThuviecthanhcong', dataThuviecthanhcong)
                if (dataThuviecthanhcong?.data.success === 200) {
                    setData((prev) => ({ ...prev, ['thuviecthanhcong']: dataThuviecthanhcong?.data?.data }));
                }

                let nghiviec = '';
                if (Nghiviec === 'Tuần này') {
                    nghiviec = 'tuannay';
                } else if (Nghiviec === 'Tháng này') {
                    nghiviec = 'thangnay';
                }
                const dataNghiviec = await apiDataNhanVienNghiViec({ day: nghiviec });
                //    console.log('dataNghiviec', dataNghiviec)
                if (dataNghiviec?.data.success === 200) {
                    setData((prev) => ({ ...prev, ['nghiviec']: dataNghiviec?.data?.data }));
                }

            };
            fetchData().catch(console.error);
        }, 1000);
    }, [traffic, traffic1, traffic2, traffic3, Nhanvien, Thuviec, Nghiviec]);
    // console.log('datavvvvvvvvvv', dataAll);
    useEffect(() => {
        setTimeout(() => {
            const fetchData = async () => {
                const date = new Date();
                try {
                    let dataPayload = {
                        data: [
                            {
                                date: date,
                                dataSelectHocVan: dataSelectHocVan,
                            },
                        ],
                    };
                    let dataPayloadTNBQ = {
                        data: [
                            {
                                date: date,
                            },
                        ],
                    };
                    // Lấy dữ liệu về báo cáo chi phí nhân sự
                    const dataResHD = await apiDashboardHopDong();
                    setHD(dataResHD.data.data.rows);
                    
                    // Lấy dữ liệu về báo cáo Thu nhập bình quân
                    const dataResTNBQ = apiDataThuNhapBQ(dataPayloadTNBQ);
                    const [dataTNBQ] = await Promise.all([dataResTNBQ]);
                    // Lấy dữ liệu về báo cáo chi phí nhân sự
                    const dataResCPNS = apiDataChiPhiNhanSu(dataPayloadTNBQ);
                    const [dataCPNS] = await Promise.all([dataResCPNS]);
                    // Lấy dữ liệu về báo cáo chi phí nhân sự
                    const dataResLTH = apiDashboardLuongTongHop();
                    const [dataLTH] = await Promise.all([dataResLTH]);
                    setLuong(dataLTH.data);
                    // setBaoCaoNhanvien(dataRess.data.data[0]);
                    setBaoCaoTNBQ(dataTNBQ.data.data.luongDataTmpMulChart);
                    setBaoCaoCPNS(dataCPNS.data.data.luongDataTmpMulChart);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };
            fetchData().catch(console.error);
            // fetchDataThoiGianChamCongRange().catch(console.error)
        }, 1000);
    }, []);

    return (
        <React.Fragment>
            <Head title="DASHBOARD-ADSMO SOFTWARE" />
            <Content>
                <BlockHead size="sm">
                    <div className="nk-block-between">
                        <BlockHeadContent>
                            <BlockTitle page tag="h3">
                                Tổng quan
                            </BlockTitle>
                            <BlockDes className="text-soft">
                                <p>Phần mềm quản lý nhân sự.</p>
                            </BlockDes>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <Button
                                    className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? 'active' : ''}`}
                                    onClick={() => updateSm(!sm)}
                                >
                                    <Icon name="more-v"></Icon>
                                </Button>
                            </div>
                        </BlockHeadContent>
                    </div>
                </BlockHead>
                <Block>
                    <Row className="gy-gs">
                        <Col xxl="4">
                            <Card className="card-bordered card-full">
                                <div className="card-inner-group">
                                    <div className="card-inner">
                                        <div className="card-title-group">
                                            <h6 className="title ">Tổng số nhân viên</h6>
                                            <div className="card-tools">
                                                
                                                
                                                <div className="user-action fw-bold">
                                                    {dataAll?.tongsonhanvien?.tongsonhanvien || 0}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                       
                                    <div className="card-inner card-inner-md">
                                        <div className="user-card">
                                            <Icon name="user-list-fill fs-20px text-primary"></Icon>
                                            <div className="user-info mx-3">
                                                <span className="fs-16px">Thử việc</span>
                                            </div>
                                            <div className="user-action text-primary">
                                                {dataAll?.tongsonhanvien?.thuviec || 0}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-inner card-inner-md">
                                        <div className="user-card">
                                            <Icon name="user-check-fill fs-20px text-success"></Icon>
                                            <div className="user-info mx-3">
                                                <span className="fs-16px">Chính thức</span>
                                            </div>
                                            <div className="user-action text-success">
                                                {dataAll?.tongsonhanvien?.nhanvienchinhthuc || 0}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-inner card-inner-md">
                                        <div className="user-card">
                                            <Icon name="users-fill fs-20px text-info"></Icon>
                                            <div className="user-info mx-3">
                                                <span className="fs-16px">Nghỉ thai sản</span>
                                            </div>
                                            <div className="user-action text-info">{dataAll?.tongsonhanvien?.nghithaisan || 0}</div>
                                        </div>
                                    </div>
                                    <div className="card-inner card-inner-md">
                                        <div className="user-card">
                                            <Icon name="account-setting-fill fs-20px text-warning"></Icon>
                                            <div className="user-info mx-3">
                                                <span className="fs-16px">Khác</span>
                                            </div>
                                            <div className="user-action text-warning">{dataAll?.tongsonhanvien?.khac || 0}</div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col xxl="4">
                            <div className="example-alert mb-1">
                                <Alert className="alert-pro" color="primary">
                                    <div className="alert-text">
                                        <div className="card-title-group">
                                            <div className="card-title card-title-lg">
                                                <h6 className="title">Nhân viên mới</h6>
                                            </div>
                                            <UncontrolledDropdown>
                                                <DropdownToggle className="dropdown-toggle dropdown-indicator btn btn-sm btn-outline-light btn-white">
                                                    {Nhanvien}
                                                </DropdownToggle>
                                                <DropdownMenu end className="dropdown-menu-xs">
                                                    <ul className="link-list-opt no-bdr">
                                                        <li className={Nhanvien === 'Tuần này' ? 'active' : ''}>
                                                            <DropdownItem
                                                                href="#dropdownitem"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setNhanvien('Tuần này');
                                                                }}
                                                            >
                                                                <span>Tuần này</span>
                                                            </DropdownItem>
                                                        </li>
                                                        <li className={Nhanvien === 'Tháng này' ? 'active' : ''}>
                                                            <DropdownItem
                                                                href="#dropdownitem"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setNhanvien('Tháng này');
                                                                }}
                                                            >
                                                                <span>Tháng này</span>
                                                            </DropdownItem>
                                                        </li>
                                                    </ul>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                        <div className="py-2">
                                            <div className="nk-iv-wg2-amount">
                                                {' '}
                                                {dataAll?.nhanvienmoi?.totalCurrent}{' '}
                                                <span className={dataAll?.nhanvienmoi?.is_check === true ? 'change up': 'change down'}>
                                                    <span className="sign"></span>{dataAll?.nhanvienmoi?.percent}%
                                                </span>
                                            </div>
                                        </div>
                                        <p>{Nhanvien === 'Tuần này' ? 'Tuần trước' : 'Tháng trước'}: {' '}
                                        {dataAll?.nhanvienmoi?.totalPrev}
                                        </p>
                                    </div>
                                </Alert>
                            </div>
                            <div className="example-alert mb-1">
                                <Alert className="alert-pro" color="success">
                                    <div className="alert-text">
                                        <div className="card-title-group">
                                            <div className="card-title card-title-lg">
                                                <h6 className="title">Thử việc thành công</h6>
                                            </div>
                                            <UncontrolledDropdown>
                                                <DropdownToggle className="dropdown-toggle dropdown-indicator btn btn-sm btn-outline-light btn-white">
                                                    {Thuviec}
                                                </DropdownToggle>
                                                <DropdownMenu end className="dropdown-menu-xs">
                                                    <ul className="link-list-opt no-bdr">
                                                        <li className={Thuviec === 'Tuần này' ? 'active' : ''}>
                                                            <DropdownItem
                                                                href="#dropdownitem"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setThuviec('Tuần này');
                                                                }}
                                                            >
                                                                <span>Tuần này</span>
                                                            </DropdownItem>
                                                        </li>
                                                        <li className={Thuviec === 'Tháng này' ? 'active' : ''}>
                                                            <DropdownItem
                                                                href="#dropdownitem"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setThuviec('Tháng này');
                                                                }}
                                                            >
                                                                <span>Tháng này</span>
                                                            </DropdownItem>
                                                        </li>
                                                    </ul>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                        <div className="py-2">
                                            <div className="nk-iv-wg2-amount">
                                                {' '}
                                                {dataAll?.thuviecthanhcong?.totalCurrent}{' '}
                                                <span className={dataAll?.thuviecthanhcong?.is_check === true ? 'change up': 'change down'}>
                                                    <span className="sign"></span>{dataAll?.thuviecthanhcong?.percent}%
                                                </span>
                                            </div>
                                        </div>
                                        <p>{Thuviec === 'Tuần này' ? 'Tuần trước' : 'Tháng trước'}: {' '}
                                        {dataAll?.thuviecthanhcong?.totalPrev}
                                        </p>
                                    </div>
                                </Alert>
                            </div>
                            <div className="example-alert ">
                                <Alert className="alert-pro" color="warning">
                                    <div className="alert-text">
                                        <div className="card-title-group">
                                            <div className="card-title card-title-lg">
                                                <h6 className="title">Nghỉ việc</h6>
                                            </div>
                                            <UncontrolledDropdown>
                                                <DropdownToggle className="dropdown-toggle dropdown-indicator btn btn-sm btn-outline-light btn-white">
                                                    {Nghiviec}
                                                </DropdownToggle>
                                                <DropdownMenu end className="dropdown-menu-xs">
                                                    <ul className="link-list-opt no-bdr">
                                                        <li className={Nghiviec === 'Tuần này' ? 'active' : ''}>
                                                            <DropdownItem
                                                                href="#dropdownitem"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setNghiviec('Tuần này');
                                                                }}
                                                            >
                                                                <span>Tuần này</span>
                                                            </DropdownItem>
                                                        </li>
                                                        <li className={Nghiviec === 'Tháng này' ? 'active' : ''}>
                                                            <DropdownItem
                                                                href="#dropdownitem"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setNghiviec('Tháng này');
                                                                }}
                                                            >
                                                                <span>Tháng này</span>
                                                            </DropdownItem>
                                                        </li>
                                                    </ul>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                        <div className="py-2">
                                            <div className="nk-iv-wg2-amount">
                                                {' '}
                                                {dataAll?.nghiviec?.totalCurrent}{' '}
                                                <span className={dataAll?.nghiviec?.is_check === true ? 'change up': 'change down'}>
                                                    <span className="sign"></span>{dataAll?.nghiviec?.percent}%
                                                </span>
                                            </div>
                                        </div>
                                        <p>{Nghiviec === 'Tuần này' ? 'Tuần trước' : 'Tháng trước'}: {' '}
                                        {dataAll?.nghiviec?.totalPrev}
                                        </p>
                                    </div>
                                </Alert>
                            </div>
                        </Col>

                        <Col xxl="4" md="12">
                            <PreviewAltCard className="h-100">
                                <TrafficHopdong baoCaoHD={baoCaoHD} />
                            </PreviewAltCard>
                        </Col>
                    </Row>
                </Block>
                <Block>
                    <Row className="g-gs">
                        <Col xxl="4">
                            <PreviewAltCard className="card-bordered is-dark nk-wg-card">
                                <div className="nk-iv-wg2">
                                    <div className="nk-iv-wg2-title card-title-group">
                                        <div className="card-title card-title-sm">
                                            <h6 className="title">
                                                Nhân viên đi làm <Icon name="info"></Icon>
                                            </h6>
                                        </div>
                                        <UncontrolledDropdown>
                                            <DropdownToggle className="dropdown-toggle dropdown-indicator btn btn-sm btn-outline-light btn-white">
                                                {traffic}
                                            </DropdownToggle>
                                            <DropdownMenu
                                                end
                                                className="dropdown-menu-xs"
                                                // style={{ width: '300px' }}
                                            >
                                                <ul className="link-list-opt no-bdr">
                                                    <li className={traffic === 'Hôm nay' ? 'active' : ''}>
                                                        <DropdownItem
                                                            href="#dropdownitem"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setTraffic('Hôm nay');
                                                            }}
                                                        >
                                                            <span>Hôm nay</span>
                                                        </DropdownItem>
                                                    </li>
                                                    <li className={traffic === 'Hôm qua' ? 'active' : ''}>
                                                        <DropdownItem
                                                            href="#dropdownitem"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setTraffic('Hôm qua');
                                                            }}
                                                        >
                                                            <span>Hôm qua</span>
                                                        </DropdownItem>
                                                    </li>
                                                    {/* <li className={traffic === 'Tháng này' ? 'active' : ''}>
                                                        <DropdownItem
                                                            href="#dropdownitem"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setTraffic('Tháng này');
                                                            }}
                                                        >
                                                            <span>Tháng này</span>
                                                        </DropdownItem>
                                                    </li> */}
                                                </ul>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                    <div className="nk-iv-wg2-text">
                                        <div className="nk-iv-wg2-amount">
                                            {' '}
                                            {dataAll?.nhanviendilam}{' '}
                                            {/* <span className="change up">
                                                <span className="sign"></span>0%
                                            </span> */}
                                        </div>
                                    </div>
                                </div>
                            </PreviewAltCard>
                            <PreviewAltCard className="card-bordered is-s2 nk-wg-card">
                                <div className="nk-iv-wg2">
                                    <div className="nk-iv-wg2-title card-title-group">
                                        <div className="card-title card-title-sm">
                                            <h6 className="title">
                                                Nhân viên xin nghỉ <Icon name="info"></Icon>
                                            </h6>
                                        </div>
                                        <UncontrolledDropdown>
                                            <DropdownToggle className="dropdown-toggle dropdown-indicator btn btn-sm btn-outline-light btn-white">
                                                {traffic}
                                            </DropdownToggle>
                                            <DropdownMenu end className="dropdown-menu-xs">
                                                <ul className="link-list-opt no-bdr">
                                                    <li className={traffic === 'Hôm nay' ? 'active' : ''}>
                                                        <DropdownItem
                                                            href="#dropdownitem"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setTraffic('Hôm nay');
                                                            }}
                                                        >
                                                            <span>Hôm nay</span>
                                                        </DropdownItem>
                                                    </li>
                                                    <li className={traffic === 'Hôm qua' ? 'active' : ''}>
                                                        <DropdownItem
                                                            href="#dropdownitem"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setTraffic('Hôm qua');
                                                            }}
                                                        >
                                                            <span>Hôm qua</span>
                                                        </DropdownItem>
                                                    </li>
                                                </ul>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                    <div className="nk-iv-wg2-text">
                                        <div className="nk-iv-wg2-amount">
                                            {' '}
                                            {dataAll?.thuctedanghi}
                                            {/* <span className="change up">
                                                <span className="sign"></span>0%
                                            </span> */}
                                        </div>
                                    </div>
                                </div>
                            </PreviewAltCard>
                            <PreviewAltCard className="card-bordered is-s3 nk-wg-card">
                                <div className="nk-iv-wg2">
                                    <div className="nk-iv-wg2-title card-title-group">
                                        <div className="card-title card-title-sm">
                                            <h6 className="title">
                                                Nhân viên công tác <Icon name="info"></Icon>
                                            </h6>
                                        </div>
                                        <UncontrolledDropdown>
                                            <DropdownToggle className="dropdown-toggle dropdown-indicator btn btn-sm btn-outline-light btn-white">
                                                {traffic}
                                            </DropdownToggle>
                                            <DropdownMenu end className="dropdown-menu-xs">
                                                <ul className="link-list-opt no-bdr">
                                                    <li className={traffic === 'Hôm nay' ? 'active' : ''}>
                                                        <DropdownItem
                                                            href="#dropdownitem"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setTraffic('Hôm nay');
                                                            }}
                                                        >
                                                            <span>Hôm nay</span>
                                                        </DropdownItem>
                                                    </li>
                                                    <li className={traffic === 'Hôm qua' ? 'active' : ''}>
                                                        <DropdownItem
                                                            href="#dropdownitem"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setTraffic('Hôm qua');
                                                            }}
                                                        >
                                                            <span>Hôm qua</span>
                                                        </DropdownItem>
                                                    </li>
                                                   
                                                </ul>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                    <div className="nk-iv-wg2-text">
                                        <div className="nk-iv-wg2-amount">
                                            {' '}
                                            {dataAll?.dimuonvesom}{' '}
                                            {/* <span className="change down">
                                                <span className="sign"></span>0%
                                            </span> */}
                                        </div>
                                    </div>
                                </div>
                            </PreviewAltCard>
                        </Col>
                        <Col xxl="8" md="12">
                            <PreviewAltCard className="h-100">
                                <TrafficDougnut />
                            </PreviewAltCard>
                        </Col>
                    </Row>
                </Block>
                <Block size="lg">
                    <Card className="card-bordered">
                        <div className="card-inner-group border-bottom">
                            <div className="card-inner">
                                <div className="card-title-group">
                                    <h6 className="title">Tổng hợp lương</h6>
                                    <div className="card-tools">
                                        <div className="card-inner">
                                            <ul className="nk-iv-wg3-nav">
                                                <li>
                                                    <a href="#nav" onClick={(ev) => ev.preventDefault()}>
                                                        <Icon name="report-profit"></Icon> <span>Tính lương</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#nav" onClick={(ev) => ev.preventDefault()}>
                                                        <Icon name="growth"></Icon> <span>Thống kê</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#nav" onClick={(ev) => ev.preventDefault()}>
                                                        <Icon name="help"></Icon> <span>Trợ giúp</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card-inner-group">
                            <div className="card-inner py-5">
                                <Row className="gy-gs">
                                    <Col lg="4">
                                        <div className="nk-iv-wg3">
                                            <div className="nk-iv-wg3-title">Tổng lương</div>
                                            <div className="nk-iv-wg3-group  flex-lg-nowrap gx-4">
                                                <div className="nk-iv-wg3-sub">
                                                    <div className="nk-iv-wg3-amount">
                                                        <div className="number">
                                                            {formatCurrencyVNDDS(baoCaoLuong.tongLuong)}{' '}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg="4">
                                        <div className="nk-iv-wg3">
                                            <div className="nk-iv-wg3-title">Thuế TNCN</div>
                                            <div className="nk-iv-wg3-group flex-md-nowrap g-4">
                                                <div className="nk-iv-wg3-sub-group gx-4">
                                                    <div className="nk-iv-wg3-sub">
                                                        <div className="nk-iv-wg3-amount">
                                                            <div className="number">
                                                                {formatCurrencyVNDDS(baoCaoLuong.tongMienThue)}{' '}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg="4">
                                        <div className="nk-iv-wg3">
                                            <div className="nk-iv-wg3-title">Bảo hiểm khấu trừ</div>
                                            <div className="nk-iv-wg3-group flex-md-nowrap g-4">
                                                <div className="nk-iv-wg3-sub-group gx-4">
                                                    <div className="nk-iv-wg3-sub">
                                                        <div className="nk-iv-wg3-amount">
                                                            <div className="number">
                                                                {formatCurrencyVNDDS(baoCaoLuong.tongbaohiemYte)}{' '}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Card>
                </Block>
                <Block>
                    <Row className="g-gs">
                        <Col xxl="6">
                            <PreviewAltCard className="h-100">
                                <div className="card-title-group">
                                    <h6 className="title ">Biểu đồ thu nhập bình quân</h6>
                                </div>
                                <ColumnChart columnChart={baoCaoTNBQ} />
                            </PreviewAltCard>
                        </Col>
                        <Col xxl="6">
                            <Card className="card-bordered card-full">
                                <div className="card-inner ">
                                    <div className="card-title-group ">
                                        <div className="card-title card-title-sm">
                                            <h6 className="title">Biểu đồ chi phí nhân sự</h6>
                                        </div>
                                    </div>
                                    <ColumnMulChart columnMulChart={baoCaoCPNS} />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Block>
            </Content>
        </React.Fragment>
    );
}

export default Dashboard;
