import React, { memo } from 'react'


const Button = ({ text, textColor, bClass, onClick, px,disabled }) => {
    return (
        <button
            type='button'
            className={`py-2 ${px ? px : 'px-2'} ${textColor ? textColor : '' } ${bClass} `}
            onClick={onClick}
            disabled={disabled}
        >
            <span> {text}</span>
            {/* <span>{IcAfter && <IcAfter />}</span> */}

        </button>
    )
}

export default memo(Button)