import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {InputForm} from '~/components/Form';
import * as actions from '~/store/actions';
import {validateUngVien} from '~/request/validateUngVien';
import '../Modal.scss';
import {Modal, ModalBody} from 'reactstrap';
import Select from 'react-select';
import {Icon, SelectNhanvien} from "~/components/Component";
import {dataSelectDanhGia} from "~/utils/Datacustom";
import {validateDanhGiaNhanVien} from "~/request/validateDanhGiaNhanVien";
import {apiNewDanhgianhanvien} from "~/services";
import actionTypes from "~/store/actions/actionTypes";
import {toast} from "react-toastify";
import {getListDanhgianhanvien} from "~/store/actions";

const ModalDanhgianhanviens = ({show, setShow, fieldList}) => {
    // console.log(phongban)
    const dispatch = useDispatch();
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayloadDanhGia] = useState(fieldList);
    // console.log('payload', payload)
    const [showSelect, setShowSelect] = useState(false);
    useEffect(() => {
        show && setPayloadDanhGia(fieldList);
        // setPayloadDanhGia((prev) => ({ ...prev, [statename]: selected }));
    }, [show]);
    
    const [listNhanvien, setlistNhanvien] = useState([])
    const [listTrangthai, setlistTrangthai] = useState([]);
    // console.log('listNhanvien', listNhanvien)
    useEffect(() => {
        let TrangthaiId = dataSelectDanhGia.map((item) => ({label: item.label, value: item.value}));
        setlistTrangthai(TrangthaiId);
        listNhanvien.length > 0 && setPayloadDanhGia((prev) => ({
            ...prev,
            ['NhanvienId']: {label: listNhanvien[0]?.label, value: listNhanvien[0]?.value}
        }))
    }, [listNhanvien, dataSelectDanhGia]);
    const handleSubmit = async () => {
        let invalids = validate(payload);
        if (invalids === 0) {
            let datapayload = {
                data: [
                    {
                        NhanvienId: payload?.NhanvienId?.value,
                        nguoidanhgia: payload?.nguoidanhgia,
                        danhgia: payload?.danhgia,
                        nhanxet: payload?.nhanxet,
                        ghichu: payload?.ghichu,
                        TrangthaiId: payload?.TrangthaiId.value
                    },
                ],
            };
            // console.log('datapayload', datapayload)
            const response = await apiNewDanhgianhanvien(datapayload)
            // console.log('chek api apiNewDanhgianhanvien', response)
            if (response?.data.success === 200) {
                setShow(false)
                toast.success("Thêm mới đánh giá nhân viên thành công!")
            } else {
                toast.error("Thêm mới đánh giá nhân viên thất bại!")
            }
            
        }
    };
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayloadDanhGia((prev) => ({...prev, [statename]: selected}));
    };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateDanhGiaNhanVien(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateDanhGiaNhanVien(item[0], item[1])]);
                invalids++;
            }
        });
        return invalids;
    };
    
    return (
        <React.Fragment>
            <Modal isOpen={show} className="modal-dialog-centered modal-90w" size="lg" toggle={setShow}>
                <ModalBody>
                    <Icon name="cross-sm float-end fs-2" onClick={setShow}></Icon>
                    <h5 className="title mb-5">
                        Tạo đánh giá nhân viên
                    </h5>
                    <div className="row">
                        <div className="form-group col-md-4"
                             onClick={() => setShowSelect(true)}
                        >
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                Chọn Nhân Viên <b className="text-danger">(*)</b>
                            </label>
                            <Select
                                value={payload?.NhanvienId}
                                name='NhanvienId'
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'NhanvienId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'NhanvienId')?.message}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                                Trạng thái <b className="text-danger">(*)</b>
                            </span>
                            </label>
                            <Select
                                value={payload.TrangthaiId}
                                onChange={handleOnSelect}
                                options={listTrangthai}
                                placeholder={'Chọn trạng thái'}
                                name="TrangthaiId"
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'TrangthaiId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'TrangthaiId')?.message}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-4">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Người đánh giá'}
                                value={payload.nguoidanhgia}
                                setValue={setPayloadDanhGia}
                                keyPayload={'nguoidanhgia'}
                                placeholder={'Người đánh giá'}
                                type="text"
                            />
                        </div>
                        
                        <div className="form-group col-md-12">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Nội dung đánh giá'}
                                value={payload.danhgia}
                                setValue={setPayloadDanhGia}
                                keyPayload={'danhgia'}
                                placeholder={'Nội dung đánh giá'}
                                type="text"
                            />
                        </div>
                        <div className="form-group col-md-12">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Nội dung nhận xét'}
                                value={payload.nhanxet}
                                setValue={setPayloadDanhGia}
                                keyPayload={'nhanxet'}
                                placeholder={'Nội dung nhận xét'}
                                type="text"
                            />
                        </div>
                        <div className="form-group col-md-12">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Nội dung ghi chú'}
                                value={payload.ghichu}
                                setValue={setPayloadDanhGia}
                                keyPayload={'ghichu'}
                                placeholder={'Nội dung ghi chú'}
                                type="text"
                            />
                        </div>
                    </div>
                    <div className="btn-group my-2">
                        <button className="btn btn-primary px-5" onClick={handleSubmit}>
                            Thêm mới
                        </button>
                    </div>
                
                </ModalBody>
            </Modal>
            <SelectNhanvien
                showSelect={showSelect}
                // typeSelect = { ismultiple: "Lấy nhiều" , single: "lấy một" }
                setlistNhanvien={setlistNhanvien}
                setShowSelect={() => setShowSelect(false)}
            />
        </React.Fragment>
    );
};

export default ModalDanhgianhanviens;
