import actionTypes from './actionTypes'
import {
    apiGetPayroll
} from '~/services/bangchamcongtonghop'
export const getListPayroll = () => async (dispatch) => {
    try {
        const response = await apiGetPayroll()
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_PAYROLL,
                payroll: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_PAYROLL,
                msg: response.data.msg
            })
        }
        
    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_PAYROLL,
            payroll: null
        })
    }
}