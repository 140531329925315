import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputForm } from '~/components/Form';
import * as actions from '~/store/actions';
import { Link } from 'react-router-dom';
import '../Modal.scss';
import { Input, Modal, ModalBody, Spinner } from 'reactstrap';
import Select from 'react-select';
import { format, parseISO } from 'date-fns';

import { DataTableBody, DataTableHead, DataTableItem, DataTableRow, Icon, Button } from '~/components/Component';
import { apiVanbanHopdong } from '~/services';
import { toast } from 'react-toastify';
import { NumericFormat } from 'react-number-format';

const ModalDownloadHopdonglaodong = ({ show, setShow, dataHopdonglaodong }) => {
    // console.log('dataHopdonglaodong',dataHopdonglaodong)
    const { mauhopdong } = useSelector((state) => state.hopdonglaodong);
    // console.log('mauhopdong',mauhopdong)
    const dispatch = useDispatch();
    const [payload, setPayload] = useState({
        MauhopdongId: '',
        data: '',
    });
    const [listMauhopdong, setlistMauhopdong] = useState([]);
    const [link, setlistLink] = useState({
        linkPdf: '',
        linkW: '',
        check: 1,
    });

    useEffect(() => {
        if (show && dataHopdonglaodong) {
            setlistLink((prev) => ({ ...prev, ['linkPdf']: '' }));
            setlistLink((prev) => ({ ...prev, ['linkW']: '' }));
            setlistLink((prev) => ({ ...prev, ['check']: 1 }));
            const fetchData = async () => {
                try {
                    let pau = {
                        data: {
                            tencongty: dataHopdonglaodong?.Congty?.loaihinhcongty,
                            sohopdong: dataHopdonglaodong?.mahopdong,
                            khuvuc: dataHopdonglaodong?.Chinhanh?.diachi,
                            tenhopdong: dataHopdonglaodong?.Loaihopdong?.tenloaihopdong,
                            ngaytaohopdong: dataHopdonglaodong?.ngaytaohopdong ? format(parseISO(dataHopdonglaodong?.ngaytaohopdong), 'dd-MM-yyyy') : '',

                            nguoidaidienct: dataHopdonglaodong?.Congty?.daidienphaply,
                            chucvuct: dataHopdonglaodong?.Congty?.chucdanh,
                            dienthoaict: dataHopdonglaodong?.Congty?.dienthoai,
                            masothuect: dataHopdonglaodong?.Congty?.masothue,
                            diachict: dataHopdonglaodong?.Congty?.diachi,
                            quoctichgiamdoc: dataHopdonglaodong?.Congty?.quoctichgiamdoc,

                            nhanvien: dataHopdonglaodong?.Nhanvien?.hoten,
                            quoctich: dataHopdonglaodong?.Nhanvien?.Thongtinnhanvien?.quoctich,
                            ngaysinh: dataHopdonglaodong?.Nhanvien?.Thongtinnhanvien?.ngaysinh ? format(parseISO(dataHopdonglaodong?.Nhanvien?.Thongtinnhanvien?.ngaysinh), 'dd-MM-yyyy') : '',
                            dienthoai: dataHopdonglaodong?.Nhanvien?.Thongtinnhanvien?.dienthoai,
                            diachi: dataHopdonglaodong?.Nhanvien?.Thongtinnhanvien?.diachihientai,
                            socmnd: dataHopdonglaodong?.Nhanvien?.Thongtinnhanvien?.socmnd,
                            ngaycap: dataHopdonglaodong?.Nhanvien?.Thongtinnhanvien?.ngaycap ? format(parseISO(dataHopdonglaodong?.Nhanvien?.Thongtinnhanvien?.ngaycap), 'dd-MM-yyyy') : '',
                            noicap: dataHopdonglaodong?.Nhanvien?.Thongtinnhanvien?.noicap,
                            loaihopdong: dataHopdonglaodong?.Loaihopdong?.tenloaihopdong,
                            ngaybatdau: dataHopdonglaodong?.ngaybatdaulamviec ? format(parseISO(dataHopdonglaodong?.ngaybatdaulamviec), 'dd-MM-yyyy') : '',
                            ngayketthuc: dataHopdonglaodong?.ngayhethan ? format(parseISO(dataHopdonglaodong?.ngayhethan), 'dd-MM-yyyy') : '',
                            chucvu: dataHopdonglaodong?.Chucvu?.chucvu,
                            phongban: dataHopdonglaodong?.Phongban?.phongban,
                            masothue: dataHopdonglaodong?.Nhanvien?.Thongtinnhanvien?.masothue,
                            mucluong: dataHopdonglaodong?.Nhanvien?.mucluong ? new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(dataHopdonglaodong?.Nhanvien?.mucluong).replace('₫', '').trim() : '',

                        },
                        MauhopdongId: dataHopdonglaodong?.MauhopdongId,
                    };
                    // console.log('pauaaaaaaaaaaaaaaaaaaa', pau)
                    if(dataHopdonglaodong?.MauhopdongId !== 0){
                        const response = await apiVanbanHopdong(pau);
                        // console.log('responseddddđ', response)
                        if (response?.data?.success === 200) {
                            const link = `${process.env.REACT_APP_SERVER_URL}${response?.data?.link_vanban}`;
                            const linkPdf = `${process.env.REACT_APP_SERVER_URL}${response?.data?.link_pdf}`;
                            setlistLink((prev) => ({ ...prev, ['linkPdf']: linkPdf }));
                            setlistLink((prev) => ({ ...prev, ['linkW']: link }));
                            setlistLink((prev) => ({ ...prev, ['check']: 0 }));
                        }
                    }else{
                        setlistLink((prev) => ({ ...prev, ['check']: 2 }));
                    }
                    

                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };
            fetchData().catch(console.error);
            // if (mauhopdong) {
            //   const filteredMauHopDong = mauhopdong.filter((hopdong) => Number(hopdong.LoaihopdongId) === Number(dataHopdonglaodong?.LoaihopdongId));
            // //   console.log('filteredMauHopDong',filteredMauHopDong)
            //   const dataSelectMauhopdong = filteredMauHopDong.map((item) => ({ label: item.mota, value: item.id }));
            //   setlistMauhopdong(dataSelectMauhopdong);
            // }
        }
    }, [dataHopdonglaodong, show]);

    const [listContent, setlistContent] = useState([]);
    useEffect(() => {
        setlistContent(Object.entries(payload.data));
    }, [payload.data]);

    const handleDowload = async () => {
        const downloadLink = document.createElement('a');
        downloadLink.href = link.linkW;
        downloadLink.click();
    };
    // const handleSubmit = async () => {
    //         //   console.log('check payload', payload)
    //         try {
    //             const response = await apiVanbanHopdong(payload);
    //             console.log('response', response)
    //             // if (response?.data?.success === 200) {
    //             //     const downloadLink = document.createElement('a');
    //             //     downloadLink.href = `${process.env.REACT_APP_SERVER_URL}${response?.data?.link_vanban}`;
    //             //     downloadLink.click();
    //             // }
    //             // console.log(response?.data)
    //         } catch (error) {
    //             console.error('Error fetching data:', error);
    //             toast.error("Bạn chưa chọn mẫu hợp đồng!")
    //         }
    // };
    // const handleOnSelect = async (selected, name) => {
    //     let statename = name.name;
    //     setPayload((prev) => ({ ...prev, [statename]: selected }));
    // };

    return (
        <React.Fragment>
            <Modal isOpen={show} toggle={setShow} className="modal">
                <ModalBody className="modal-body-lg text-center">
                    <div className="nk-modal">
                        {link.check === 0 ? (
                            <Icon className="nk-modal-icon icon-circle icon-circle-xxl ni ni-check bg-success"></Icon>
                        ) : (
                            <>
                                {link.check === 1 ? (
                                    <Spinner color="dark" />
                                ) : (
                                    <Icon className="nk-modal-icon icon-circle icon-circle-xxl ni ni-cross bg-danger"></Icon>
                                )}
                            </>
                        )}

                        <h4 className="nk-modal-title">
                            {link.check === 0 ? (
                                'Tải dữ liệu thành công'
                            ) : (
                                <>{link.check === 1 ? 'Vui lòng đợi!' : 'Tải dữ liệu thất bại'}</>
                            )}
                        </h4>
                        <div className="nk-modal-text">
                            <div className="caption-text">
                                {link.check === 0 ? (
                                    'Xem chi tiết bằng file Pdf hoặc tải xuống'
                                ) : (
                                    <>
                                        {link.check === 1
                                            ? 'Hệ thống đang xử lý dữ liệu!'
                                            : 'Có vẻ như file hợp đồng mẫu bị lỗi hãy kiểm tra lại'}
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="nk-modal-action">
                            {link.check !== 0 ? (
                                <Button color="primary" size="lg" className="btn-mw mx-1" disabled>
                                    Xem Pdf
                                </Button>
                            ) : (
                                <Link to={link.linkPdf} target="_blank">
                                    <Button
                                        color="primary"
                                        size="lg"
                                        className="btn-mw mx-1"
                                    >
                                        Xem Pdf
                                    </Button>
                                </Link>
                            )}
                            {link.check !== 0 ? (
                                <Button color="success" size="lg" className="btn-mw mx-1" disabled>
                                    Tải xuống
                                </Button>
                            ) : (
                                <Button
                                    color="success"
                                    size="lg"
                                    className="btn-mw mx-1"
                                    onClick={() => handleDowload()}
                                >
                                    Tải xuống
                                </Button>
                            )}
                           
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default ModalDownloadHopdonglaodong;
