import React from 'react';
import ReactDOM from 'react-dom/client';
import App from '~/App';
import reportWebVitals from './reportWebVitals';
import "./assets/scss/dashlite.scss";
// import GlobalStyles from '~/components/GlobalStyles';
// import 'react-toastify/dist/ReactToastify.css';
// import 'bootstrap/dist/js/bootstrap.bundle.js';
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux';
import reduxStore from './redux';
const { store, persistor } = reduxStore()
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Provider store={store} >
      <PersistGate loading={null} persistor={persistor} >
        {/* <GlobalStyles> */}
          <App />
        {/* </GlobalStyles> */}
      </PersistGate>
    </Provider>
    // </React.StrictMode>
);

reportWebVitals();
