export const validateDeXuat = (fieldName, value) => {
    let regNumber = /^[0-9\b]+$/;
    switch (fieldName) {
        case 'NhanvienId':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nhân viên không được để trống'
                }
            }
            break;
        case 'title':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Đề xuất không được để trống'
                }
            }
            break;
        case 'mota':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Mô tả không được để trống.'
                }
            }
            break;
        case 'nguoicheck':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Người check không được để trống.'
                }
            }
            break;
            
        default:
            break;
    }
}