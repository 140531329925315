import actionTypes from './actionTypes'
import { apiGetQuanlythamnien, apiNewQuanlythamnien, apiEditQuanlythamnien, apiDeleteQuanlythamnien } from '~/services/quanlythamnien'
import { toast } from "react-toastify";
export const getListQuanlythamnien = () => async (dispatch) => {
    try {
        const response = await apiGetQuanlythamnien()
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_QUAN_LY_THAM_NIEN,
                quanlythamnien: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_QUAN_LY_THAM_NIEN,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_QUAN_LY_THAM_NIEN,
            quanlythamnien: null
        })
    }
}

export const NewQuanlythamnien = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewQuanlythamnien', payload)
            const response = await apiNewQuanlythamnien(payload)
            // console.log('chek api apiNewQuanlythamnien', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_QUAN_LY_THAM_NIEN,
                    msg: 'OK'
                });
                dispatch(getListQuanlythamnien());
                toast.success("Thêm mới quản lý thâm niênthành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_QUAN_LY_THAM_NIEN,
                    msg: 'ERR'
                })
                toast.error("Thêm mới quản lý thâm niênthất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_QUAN_LY_THAM_NIEN,
                quanlythamnien: null
            })
        }
    }
}
export const EditQuanlythamnien = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditQuanlythamnien(payload)
            // console.log('chek api apiEditQuanlythamnien', response)
            if (response?.data.success === 200) {
                dispatch(getListQuanlythamnien());
                toast.success("Sửa thông tin quản lý thâm niênthành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_QUAN_LY_THAM_NIEN,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_QUAN_LY_THAM_NIEN,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin quản lý thâm niênthất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_QUAN_LY_THAM_NIEN,
                quanlythamnien: null
            })
        }
    }
}
export const DeleteQuanlythamnien = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteQuanlythamnien(payload)
            // console.log('chek api apiDeleteQuanlythamnien', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListQuanlythamnien());
                toast.success("Xóa thông tin quản lý thâm niênthành công!")
                dispatch({
                    type: actionTypes.DELETE_QUAN_LY_THAM_NIEN,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_QUAN_LY_THAM_NIEN,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin quản lý thâm niênthất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_QUAN_LY_THAM_NIEN,
                quanlythamnien: null
            })
        }
    }
}