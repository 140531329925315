import actionTypes from './actionTypes'
import { apiGetDanhgia, apiNewDanhgia, apiEditDanhgia, apiDeleteDanhgia } from '~/services/danhgia'
import { toast } from "react-toastify";
export const getListDanhgia = () => async (dispatch) => {
    try {
        const response = await apiGetDanhgia()
        // console.log('chek api lisst Danhgia', response)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_DANH_GIA,
                danhgia: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_DANH_GIA,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_DANH_GIA,
            danhgia: null
        })
    }
}

export const NewDanhgia = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiNewDanhgia(payload)
            // console.log('chek api apiNewDanhgia', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_DANH_GIA,
                    msg: 'OK'
                });
                dispatch(getListDanhgia());
                toast.success("Thêm mới đánh giá thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_DANH_GIA,
                    msg: 'ERR'
                })
                toast.error("Thêm mới đánh giá thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_DANH_GIA,
                danhgia: null
            })
        }
    }
}
export const EditDanhgia = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditDanhgia(payload)
            // console.log('chek api apiEditDanhgia', response)
            if (response?.data.success === 200) {
                dispatch(getListDanhgia());
                toast.success("Sửa thông tin đánh giá thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_DANH_GIA,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_DANH_GIA,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin đánh giá thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_DANH_GIA,
                danhgia: null
            })
        }
    }
}
export const DeleteDanhgia = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteDanhgia(payload)
            // console.log('chek api apiDeleteDanhgia', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListDanhgia());
                toast.success("Xóa thông tin đánh giá thành công!")
                dispatch({
                    type: actionTypes.DELETE_DANH_GIA,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_DANH_GIA,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin đánh giá thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_DANH_GIA,
                danhgia: null
            })
        }
    }
}