import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import * as actions from '~/store/actions';
import {ModalEditTamungluong, ModalTamungluong} from './Modal';
import Swal from 'sweetalert2';
import Content from '~/layout/content/Content';
import {Block} from '~/components/block/Block';
import {Button, DataTable, ExportXlsxExcel, PermissionUtils} from '~/components/Component';
import {format, parseISO} from 'date-fns';
import {Box, IconButton, Stack, Tooltip} from "@mui/material";
import {MaterialReactTable, MRT_ExpandAllButton, useMaterialReactTable} from "material-react-table";
import {MRT_Localization_VI} from "material-react-table/locales/vi";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {Card} from "reactstrap";
import {apiGetTamungluong} from "~/services";
import {dataPagination, dataStatus} from "~/utils/Datacustom";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const Tamungluong = () => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    //lấy giá trị từ thằng redux trả về
    const [data, setData] = useState([]);
    const [columnVisibility, setColumnVisibility] = useState({});
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const fieldList = {
        NhanvienId: '',
        ngaydexuat: '',
        giodexuat: '',
        mucdexuat: '',
        mucdexuatformat: '',
        ghichu: '',
    };
    
    const [showPhanquyen, setShowPhanquyen] = useState({});
    let dataPermissionUtils = PermissionUtils('phongban', 'all');
    useEffect(() => {
        if (dataPermissionUtils) {
            setShowPhanquyen(dataPermissionUtils);
        }
    }, [dataPermissionUtils]);
    const fetchData = async () => {
        try {
            const datapayloads = {
                typeGet: 1, // 1: lấy theo điều kiện phân trang, 2: lấy tất cả và không cần truyền d liệu phân trang
                start: pagination.pageIndex || 0,
                size: pagination.pageSize || 10,
                globalFilter: 'id',
                sorting: 'desc',
            };
            const response = await apiGetTamungluong(datapayloads)
            if (response?.data?.success === 200) {
                setData(response?.data?.data?.rows);
                setTotalRowCount(response?.data?.data?.count);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    
    
    useEffect(() => {
        fetchData().then(r => r)
    }, [pagination.pageIndex, pagination.pageSize, show, showEdit]);
    
    const handleAddNew = async () => {
        setShow(true);
    };
    const [dataIdTamungluong, setDataIdTamungluong] = useState([]);
    const handleEdit = async (data) => {
        setShowEdit(true);
        await setDataIdTamungluong(data);
    };
    
    const handleDelete = (data) => {
        // console.log('data',data)
        const {id, status} = data // TODO: status check xem có thể xóa hay không
        
        
        Swal.fire({
            title: 'Bạn có chắc không?',
            text: 'Bạn sẽ không thể lấy lại khi xóa!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Không, bỏ qua!',
            confirmButtonText: 'Vâng, xóa nó đi!',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(actions.DeleteTamungluong(id));
            }
        });
    };
    console.log('columnVisibility', columnVisibility)
    // TODO: handle material table
    const Example = () => {
        
        let dataAb = data;
        // Tạo dữ liêu export
        
        const formatCurrency = (value) => (
            (Number(value) || 0).toLocaleString('vi-VN', {style: 'currency', currency: 'VND'})
        );
        const typeFormat = 'dd/MM/yyyy hh:mm'
        const FormatDate = (cell, type) => ( // Format Date : type = 'dd/MM/yyyy hh:mm', date = cell
            <Box>
                {format(parseISO(cell), type)}
            </Box>
        );
        const Status = (cell) => ( // Function show trạng thái
            <Box
                component="span"
                sx={(theme) => ({
                    backgroundColor: cell === 1 ? '#1ee0ac' : '#e65100',
                    fontSize: '10px',
                    borderRadius: '0.25rem',
                    color: '#fff',
                    p: '0.25rem',
                })}
            >
                {dataStatus.find(select => select.value === cell)?.label || dataStatus[0].label}
            </Box>
        );
        
        const columns = useMemo(
            () => [
                {
                    Header: '#', // Tiêu đề cột số thứ tự
                    header: 'STT', // Tiêu đề cột số thứ tự
                    id: 'rowNumber', // ID của cột
                    accessorKey: 'rowNumber', // ID của cột
                    size: 50,
                    Cell: ({row}) => <div>{row?.index + 1}</div>, // Giá trị của cột là số thứ tự
                    enableSorting: false,
                    enablePinning: true,
                },
                {
                    accessorKey: 'Nhanvien.hoten',
                    id: 'hoten',
                    enablePinning: true,
                    header: 'Họ tên',
                },
                {
                    accessorKey: 'Nhanvien.uuid',
                    id: 'uuid',
                    enablePinning: true,
                    header: 'Mã nhân viên',
                },
                {
                    accessorKey: 'mucdexuat',
                    id: 'mucdexuat',
                    Cell: ({cell}) => formatCurrency(cell.getValue() || 0),
                    enablePinning: true,
                    header: 'Mức đề xuất',
                },
                {
                    accessorKey: 'ngaydexuat',
                    id: 'ngaydexuat',
                    header: 'Thời gian đề xuất',
                    enablePinning: true,
                    accessorFn: (row) => <p>{FormatDate(`${row?.ngaydexuat} ${row?.giodexuat}`, typeFormat)}</p>,
                },
                
                {
                    accessorKey: 'TrangthaiId',
                    id: 'TrangthaiId',
                    header: 'Trạng thái',
                    enablePinning: true,
                    Cell: ({cell}) => Status(Number(cell?.getValue()) || 0),
                    
                },
            
            ],
            [],
        );
        const handleExportData = () => {
            const visibility = Object.keys(columnVisibility).filter(key => !columnVisibility[key]); // Lấy ra các cột không hiển thị
            let headerExport = columns.filter(item => !visibility.includes(item.id)); // Lọc ra các cột không hiển thị
            const getValue = headerExport.map((c) => c.accessorKey)
            const keyExport = headerExport.map((c) => c.id)
            let dataExport = dataAb?.map((item, indexx) => {
                let row = {};
                keyExport.forEach((key, i) => {
                    let path = getValue[i].split('.');
                    row[key] = path.reduce((o, p) => (o || {})[p], item);
                });
                row['rowNumber'] = indexx + 1
                return row;
            });
            const xlsxOptions = {
                data: dataExport,
                namefile: 'Quan-ly-tham-nien',
                headers: headerExport.map((c) => c.header),
            };
            
            const exportFunction = ExportXlsxExcel(xlsxOptions);
            exportFunction().then(r => 'err');
        };
        
        
        const rowActions = ({row, table}) => ( // Thêm nút sửa xóa vào hàng
            <Box sx={{display: 'flex', gap: '1rem'}}>
                {+showPhanquyen?.sua === 1 && (
                    <Tooltip title="Edit">
                        <IconButton onClick={() => handleEdit(row.original)}>
                            <EditIcon/>
                        </IconButton>
                    </Tooltip>
                )}
                {+showPhanquyen?.sua === 1 && (
                    <Tooltip title="Delete">
                        <IconButton color="error" onClick={() => handleDelete({
                            id: row.original.id,
                            status: row.original.TrangthaiId
                        })}>
                            <DeleteIcon/>
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
        );
        const topToolbarCustomActions = ({table}) => ( // Thêm nút thêm mới và xuất dữ liệu
            <Box sx={{display: 'flex', gap: '1rem'}}>
                {+showPhanquyen?.them === 1 && (
                    <>
                        <Button variant="contained" color="primary" onClick={handleAddNew} sx={{mr: 2}}>
                            + Thêm mới
                        </Button>
                        
                        <Button color="primary" onClick={handleExportData} variant="contained">
                            <FileDownloadIcon/>
                            Xuất dữ liệu
                        </Button>
                    </>
                )}
            </Box>
        );
        const state = { // Trạng thái của bảng
            expanded: true,
            columnFilters,
            globalFilter,
            isLoading: dataAb.length === 0,
            pagination,
            sorting,
            columnVisibility
        };
        const displayColumnDefOptions = { // Các cột hiển thị
            'mrt-row-actions': {
                sx: {width: '200px', paddingRight: '10px'},
                header: 'Thao tác',
                enableOrdering: true,
                enablePinning: true,
                enableColumnActions: true,
            },
            
        };
        const initialState = { // Các trạng thái ban đầu của bảng đc gim
            columnPinning: {left: ['rowNumber', 'Nhanvien.hoten', 'Nhanvien.uuid'], right: ['mrt-row-actions']},
            density: 'compact',
            expanded: true, //expand all groups by default
            
        };
        const table = useMaterialReactTable({
                columns,
                data,
                enableColumnResizing: false,
                enableDensityToggle: false,
                initialState: initialState,
                displayColumnDefOptions: displayColumnDefOptions,
                state: state,
                defaultDisplayColumn: {enableResizing: true},
                rowCount: totalRowCount,
                manualFiltering: true,
                manualPagination: true,
                manualSorting: true,
                onPaginationChange: setPagination,
                onColumnFiltersChange: setColumnFilters,
                onGlobalFilterChange: setGlobalFilter,
                onSortingChange: setSorting,
                localization: MRT_Localization_VI,
                onColumnVisibilityChange: setColumnVisibility,
                enableRowActions: true,
                enableColumnPinning: true,
                enableGrouping: true,
                renderTopToolbarCustomActions: topToolbarCustomActions,
                renderRowActions: rowActions,
                muiPaginationProps: {
                    rowsPerPageOptions: dataPagination,
                },
            }
        );
        
        
        return (
            <MaterialReactTable
                table={table}
            />
        );
    };
    
    return (
        <React.Fragment>
            {/*<Head title="User List - Regular"></Head>*/}
            <Content>
                <Card className="mt-0">
                    <div className="card-aside-wrap row">
                        <div className="card-inner card-inner-lg px-4 pt-1 pb-0">
                            <h4>Quản lý tạm ứng lương</h4>
                        
                        </div>
                    </div>
                </Card>
                <Block>
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner position-relative card-tools-toggle">
                            <Example/>
                        </div>
                    </DataTable>
                </Block>
                <ModalTamungluong show={show} fieldList={fieldList} setShow={() => setShow(false)}/>
                <ModalEditTamungluong
                    show={showEdit}
                    fieldList={fieldList}
                    setShow={() => setShowEdit(false)}
                    dataIdTamungluong={dataIdTamungluong}
                />
            </Content>
        </React.Fragment>
    );
};

export default Tamungluong;
