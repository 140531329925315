import actionTypes from './actionTypes'
import { apiGetPhuongtien, apiNewPhuongtien, apiEditPhuongtien, apiDeletePhuongtien } from '~/services/phuongtien'
import { toast } from "react-toastify";
export const getListPhuongtien = () => async (dispatch) => {
    try {
        const response = await apiGetPhuongtien()
        console.log('chek api lisst Phuongtien', response)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_PHUONG_TIEN,
                phuongtien: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_PHUONG_TIEN,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_PHUONG_TIEN,
            phuongtien: null
        })
    }
}

export const NewPhuongtien = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewPhuongtien', payload)
            const response = await apiNewPhuongtien(payload)
            // console.log('chek api apiNewPhuongtien', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_PHUONG_TIEN,
                    msg: 'OK'
                });
                dispatch(getListPhuongtien());
                toast.success("Thêm mới phương tiện thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_PHUONG_TIEN,
                    msg: 'ERR'
                })
                toast.error("Thêm mới phương tiện thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_PHUONG_TIEN,
                phuongtien: null
            })
        }
    }
}
export const EditPhuongtien = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditPhuongtien(payload)
            // console.log('chek api apiEditPhuongtien', response)
            if (response?.data.success === 200) {
                dispatch(getListPhuongtien());
                toast.success("Sửa thông tin phương tiện thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_PHUONG_TIEN,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_PHUONG_TIEN,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin phương tiện thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_PHUONG_TIEN,
                phuongtien: null
            })
        }
    }
}
export const DeletePhuongtien = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeletePhuongtien(payload)
            // console.log('chek api apiDeletePhuongtien', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListPhuongtien());
                toast.success("Xóa thông tin phương tiện thành công!")
                dispatch({
                    type: actionTypes.DELETE_PHUONG_TIEN,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_PHUONG_TIEN,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin phương tiện thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_PHUONG_TIEN,
                phuongtien: null
            })
        }
    }
}