import React, { useState, useEffect } from 'react';
import { InputForm, Button } from '~/components/Form';
import { validateCauHinhTangCa } from '~/request/validateCauHinhTangCa';
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import { Input } from 'reactstrap';
import {dataSelectStatus} from "~/utils/Datacustom";
import Select from "react-select";
import { formatVietnameseToString } from '~/utils/Common/formatVietnameseToString'
import {apiNewCauhinhtangca} from "~/services";
import {toast} from "react-toastify";

const ModalCauhinhtangca = ({ show, setShow, fieldList }) => {
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayload] = useState(fieldList);
    const [listTrangthai, setlistTrangthai] = useState([]);
    
    // console.log('payload', payload)
    useEffect(() => {
        show && setPayload(fieldList);
        let TrangthaiId = dataSelectStatus.map((item) => ({label: item.label, value: item.value}));
        setlistTrangthai(TrangthaiId);
    }, [show]);

    const handleSubmit = async () => {
        let invalids = validate(payload);

        if (invalids === 0) {
            let maLoai = 'TC_'+formatVietnameseToString(payload?.title);
            let datapayload = {
                data: [
                    {
                        title: payload.title,
                        matangca: maLoai.toUpperCase(),
                        mota: payload.mota,
                        giatri: payload.giatri,
                        giatriTien: payload.giatriTien,
                        TrangthaiId: payload?.TrangthaiId.value
                    },
                ],
            };
            // console.log('check payload', datapayload)
            const response = await apiNewCauhinhtangca(datapayload)
            if(response.data.success === 200){
                toast.success("Thêm mới cấu hình tăng ca thành công!")
                setShow(false);
            } else {
                toast.error("Thêm mới cấu hình tăng ca thất bại!")
            }
        }
    };
    const handleOnSlug = async (e) => {
        setPayload((prev) => ({ ...prev, ['title']: e }));
        // setPayload(prev => ({ ...prev, ['slug']: e }))
    };
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayload((prev) => ({...prev, [statename]: selected}));
    };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateCauHinhTangCa(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateCauHinhTangCa(item[0], item[1])]);
                invalids++;
            }
        });
        return invalids;
    };
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">Thêm mới cấu hình tăng ca</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-12">
                        <label htmlFor="inputZip" className="mb-2">
                            <span>
                                Tiêu đề <b className="text-danger">(*)</b>
                            </span>
                        </label>
                        <Input
                            value={payload.title}
                            onChange={(e) => handleOnSlug(e.target.value)}
                            name="title"
                            type="text"
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'title') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'title')?.message}
                            </small>
                        )}
                    </div>
             
                    <div className="form-group col-md-12 mb-2">
                        <label htmlFor="inputZip" className="mb-2">
                            <span>
                                Mô tả<b className="text-danger">(*)</b>
                            </span>
                        </label>
                        <Input
                            value={payload.mota}
                            onChange={(e) => setPayload((prev) => ({ ...prev, ['mota']: e.target.value }))}
                            rows={'2'}
                            name="mota"
                            type="textarea"
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'mota') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'mota')?.message}
                            </small>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-md-4">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Giá trị (Tính theo %)'}
                            value={payload.giatri}
                            setValue={setPayload}
                            keyPayload={'giatri'}
                            type="number"
                        />
                    </div>
                    <div className="form-group col-md-4">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Giá trị (Tính theo mức lương quy đinh)'}
                            value={payload.giatriTien}
                            setValue={setPayload}
                            keyPayload={'giatriTien'}
                            type="number"
                        />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                               Trạng thái
                            </span>
                        </label>
                        <Select
                            value={payload.TrangthaiId}
                            onChange={handleOnSelect}
                            options={listTrangthai}
                            placeholder={'Trạng thái'}
                            name="TrangthaiId"
                            onFocus={() => setInvalidFields([])}
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'TrangthaiId') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'TrangthaiId')?.message}
                            </small>
                        )}
                    </div>
                </div>
                
                <div className="col-12 mt-5">
                    <Button
                        text="Thêm cấu hình tăng ca"
                        bClass="btn btn-primary px-5"
                        textColor
                        onClick={handleSubmit}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalCauhinhtangca;
