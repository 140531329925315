import React, {useContext, useState, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {InputForm, Button} from '~/components/Form'
import * as actions from '~/store/actions'
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import {validatePhongBan} from "~/request/validatePhongBan";
import { FileAllCodeContext } from '../../user-manage/FileAllCodeContext';
import Select from "react-select";

const ModalEditPhongban = ({show, setShow, dataIdPhongban, fieldList,listPhongBan}) => {
    // console.log(dataIdPhongban)
    const dispatch = useDispatch()
    const [invalidFields, setInvalidFields] = useState([])
    const [payload, setPayload] = useState(fieldList)
    const [dataPhongBan, setDataPhongBan] = useState([])
    const { handleChangeDataSocket } = useContext(FileAllCodeContext);

    useEffect(() => {
        if (listPhongBan) {
            let dataSelectPhongban = listPhongBan.filter(item => item.id !== dataIdPhongban?.id).map((item) => ({ label: item.phongban, value: item.id }));
            setDataPhongBan(dataSelectPhongban);
        }
        const parentId = listPhongBan?.find(item => item.id === dataIdPhongban?.parentId);
        show && dataIdPhongban && setPayload({
            phongban: dataIdPhongban?.phongban,
            motavitri: dataIdPhongban?.motavitri,
            parentId: dataIdPhongban?.parentId !== 0 ? { label: parentId?.phongban, value: parentId?.PhongbanId } : { label: 'Không thuộc phòng ban nào', value: 0 },
        })
       
    }, [dataIdPhongban, show,listPhongBan])
  
    const handleSubmit = async () => {
        let invalids = validate(payload)
        if (invalids === 0) {
            let datapayload = {
                id: dataIdPhongban?.id,
                phongban: payload?.phongban,
                motavitri: payload?.motavitri,
                parentId: payload?.parentId?.value
            }
            //   console.log('datapayload', datapayload)
            dispatch(actions.EditPhongban(datapayload))
            handleChangeDataSocket('phongban');
        }
    }
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayload((prev) => ({...prev, [statename]: selected}));
    };
    const validate = (payload) => {
        let invalids = 0
        let fields = Object.entries(payload)
        fields.forEach(item => {
            if (validatePhongBan(item[0], item[1]) !== undefined) {
                setInvalidFields(prev => [...prev, validatePhongBan(item[0], item[1])])
                invalids++
            }
        })
        return invalids
    }
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Sửa Phòng Ban
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-6 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Phòng ban'}
                            value={payload.phongban}
                            setValue={setPayload}
                            keyPayload={'phongban'}
                            type='text'
                            validate
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="inputZip" className="mb-2">Thuộc phòng ban</label>
                        <Select
                            value={payload.parentId}
                            onChange={handleOnSelect}
                            options={dataPhongBan}
                            placeholder={'Thuộc phòng ban'}
                            name='parentId'
                            onFocus={() => setInvalidFields([])}
                        />
                        {invalidFields.length > 0 && invalidFields.some(i => i.name === 'parentId') && <small
                            className='text-danger'>{invalidFields.find(i => i.name === 'parentId')?.message}</small>}
                    </div>
                    <div className="form-group col-md-12">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Mô tả vị trí'}
                            value={payload.motavitri}
                            setValue={setPayload}
                            keyPayload={'motavitri'}
                            type='text'
                            validate
                        />
                    </div>
                </div>
                <div className='col-12 mt-5'>
                    <Button
                        text='Cập nhật'
                        bClass='btn btn-primary px-5'
                        textColor
                        onClick={handleSubmit}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ModalEditPhongban;

