import actionTypes from './actionTypes'
import { apiGetKieuluong, apiNewKieuluong, apiEditKieuluong, apiDeleteKieuluong } from '~/services/kieuluong'
import { toast } from "react-toastify";
export const getListKieuluong = () => async (dispatch) => {
    try {
        const response = await apiGetKieuluong()
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_KIEU_LUONG,
                kieuluong: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_KIEU_LUONG,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_KIEU_LUONG,
            kieuluong: null
        })
    }
}

export const NewKieuluong = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiNewKieuluong(payload)
            // console.log('chek api apiNewKieuluong', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_KIEU_LUONG,
                    msg: 'OK'
                });
                dispatch(getListKieuluong());
                toast.success("Thêm mới kiểu lương thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_KIEU_LUONG,
                    msg: 'ERR'
                })
                toast.error("Thêm mới kiểu lương thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_KIEU_LUONG,
                kieuluong: null
            })
        }
    }
}
export const EditKieuluong = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditKieuluong(payload)
            // console.log('chek api apiEditKieuluong', response)
            if (response?.data.success === 200) {
                dispatch(getListKieuluong());
                toast.success("Sửa thông tin kiểu lương thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_KIEU_LUONG,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_KIEU_LUONG,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin kiểu lương thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_KIEU_LUONG,
                kieuluong: null
            })
        }
    }
}
export const DeleteKieuluong = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteKieuluong(payload)
            // console.log('chek api apiDeleteKieuluong', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListKieuluong());
                toast.success("Xóa thông tin kiểu lương thành công!")
                dispatch({
                    type: actionTypes.DELETE_KIEU_LUONG,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_KIEU_LUONG,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin kiểu lương thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_KIEU_LUONG,
                kieuluong: null
            })
        }
    }
}