export const validateKehoachxe = (fieldName, value) => {
    let regNumber = /^[0-9\b]+$/;
    switch (fieldName) {
        case 'LoaiphuongtienId':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'TrangthaiId':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'PhongbanId':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'PhuongtienId':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'NhanvienId':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'malenh':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'ngaybatdau':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'giobatdau':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'ngayketthuc':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'gioketthuc':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'xuatphattai':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'diemden':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'tenkhachhang':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'diachi':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'daidien':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'dienthoai':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'chiphi':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'ghichu':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        
        default:
            break;
    }
}