import * as excelJs from 'exceljs';

const ExportSalaryXlsxDai = ({ namefile, headers }) => {
    return async () => {
        const workbook = new excelJs.Workbook();
        const worksheet = workbook.addWorksheet('Bảng chấm công');

        const fills = {
            yellowDarkVertical: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFF00' }, // Yellow background
                font30Bold: {
                    size: 30, // Set font size to 20
                    name: 'Times New Roman',
                    bold: true,
                    alignment: {
                        horizontal: 'center',
                        vertical: 'middle',
                        wrapText: true,
                    },
                },
            },
            blueDarkVertical: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '1ee0ac' }, // Blue background
                font30Bold: {
                    size: 30, // Set font size to 20
                    name: 'Times New Roman',
                    bold: true,
                    alignment: {
                        horizontal: 'center',
                        vertical: 'middle',
                        wrapText: true,
                    },
                },
            },
            redDarkVertical: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'ff2d55' }, // red background
            },
        };
        const font20Bold = {
            size: 20, // Set font size to 20
            name: 'Times New Roman',
            bold: true,
            alignment: {
                horizontal: 'center',
                vertical: 'middle',
                wrapText: true,
            },
        };
        const font10Bold = {
            size: 10, // Set font size to 14
            name: 'Times New Roman',
            bold: true,
            alignment: {
                horizontal: 'center',
                vertical: 'middle',
                wrapText: true,
            },
        };
        headers.forEach((headerRow, rowIndex) => {
            let columnIndex = 1;
            headerRow.forEach((header, Index) => {
                const cell = worksheet.getCell(rowIndex + 1, columnIndex);
                if(header?.color === 'bg-warning'){
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFF00' }
                    };
                }
                if (Index === 0) {
                    // Thực hiện hành động A nếu Index là 0
                    worksheet.getRow(rowIndex + 1).height = 40;
                    worksheet.getRow(rowIndex + 1).alignment = { wrapText: true };
                } else {
                    // Thực hiện hành động B nếu Index không phải là 0
                    worksheet.getRow(rowIndex + 1).height = 20;
                    worksheet.getRow(rowIndex + 1).alignment = { horizontal: 'center' };
                }
                cell.value = header.value;

                if (header.rowspan > 1 || header.colspan > 1) {
                    const range = {
                        top: rowIndex + 1,
                        left: columnIndex,
                        bottom: rowIndex + header.rowspan,
                        right: columnIndex + header.colspan - 1,
                    };
                    worksheet.mergeCells(range.top, range.left, range.bottom, range.right);
                }
                cell.font = font10Bold;
                columnIndex += header.colspan;
            });
        });
        const cellFormatting = {
            font: {
                size: 8,
                name: 'Times New Roman',
            },
            border: {
                top: { style: 'thin', color: { argb: '111111' } },
                left: { style: 'thin', color: { argb: '111111' } },
                bottom: { style: 'thin', color: { argb: '111111' } },
                right: { style: 'thin', color: { argb: '111111' } },
            },
            alignment: {
                horizontal: 'center',
                vertical: 'middle',
                wrapText: true,
            },
        };

        worksheet.eachRow((row) => {
            row.eachCell((cell) => {
                Object.assign(cell, cellFormatting);
            });
        });
        worksheet.pageSetup.paperSize = 9;
        worksheet.pageSetup.orientation = 'landscape';
        worksheet.pageSetup.margins = {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            header: 0.25,
            footer: 0,
        };
        worksheet.pageSetup.fitToPage = true;
        worksheet.pageSetup.fitToWidth = 1;
        // ws.pageSetup.fitToHeight = 1;
        worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
            if (rowNumber === 1) {
                row.eachCell(function (cell, colNumber) {
                    cell.fill = fills.blueDarkVertical;
                    cell.font = font20Bold;
                    cell.alignment = font20Bold.alignment;
                });
            }
            if (rowNumber > 1 && rowNumber <= 3) {
                row.eachCell(function (cell, colNumber) {
                    cell.font = font10Bold;
                    cell.alignment = font10Bold.alignment;
                });
            }
        });
        for (let rowIndex = 1; rowIndex <= worksheet.rowCount; rowIndex++) {
            for (let columnIndex = 1; columnIndex <= worksheet.columnCount; columnIndex++) {
                const cell = worksheet.getCell(rowIndex, columnIndex);
                
                if (columnIndex === 2 || columnIndex === 3) {
                    const column = worksheet.getColumn(columnIndex);
                    column.width = 25;
                }
            }
        }
        // Lưu file Excel
        const excelBlob = await workbook.xlsx.writeBuffer();
        const excelUrl = URL.createObjectURL(
            new Blob([excelBlob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
        );

        const link = document.createElement('a');
        link.href = excelUrl;
        link.download = `${namefile}.xlsx`;
        document.body.appendChild(link);
        link.click();

        URL.revokeObjectURL(excelUrl);
        document.body.removeChild(link);
    };
};
export default ExportSalaryXlsxDai;
