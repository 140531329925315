import { Fragment } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import { publicRoutes, privateRoutes } from '~/routes';
import DefaultLayout from '~/layouts/DefaultLayout';
import DefaultLayoutOnly from '~/layouts/DefaultLayoutOnly';
import AdminLayout from '~/layout/Index';
import UserProfileLayout from './pages/Admin/user-manage/UserProfileLayout';
import { ToastContainer } from 'react-toastify';
import Error404Modern from './pages/error/404-modern';
import FileAllCodeContextProvider from './pages/Admin/user-manage/FileAllCodeContext';
import PrinPhieusuatan from './pages/Admin/Print/PrintPhieusuatan';
import PrinBangtonghopsuatan from './pages/Admin/Print/PrinBangtonghopsuatan';

function App() {
    return (
        <Fragment>
            <Router>
                <div className="App">
                    <Routes>
                        <Route path="*" element={<Error404Modern />} />
                        {publicRoutes.map((route, index) => {
                            const Page = route.component;
                            let Layout = DefaultLayout;

                            if (route.layout) {
                                Layout = route.layout;
                            } else if (route.layout === null) {
                                Layout = DefaultLayoutOnly;
                            }

                            return (
                                <Route
                                    exact
                                    key={index}
                                    path={route.path}
                                    element={
                                        <Layout>
                                            <Page />
                                        </Layout>
                                    }
                                />
                            );
                        })}
                        <Route path="/he-thong" element={<AdminLayout />}>
                            {privateRoutes.map((route, index) => {
                                const AdminPage = route.component;
                                return (
                                    <Route
                                        exact
                                        key={index}
                                        path={route.path}
                                        element={
                                            route.user === 'user' ? (
                                                <FileAllCodeContextProvider>
                                                    <UserProfileLayout>
                                                        <AdminPage />
                                                    </UserProfileLayout>
                                                </FileAllCodeContextProvider>
                                            ) : (
                                                <FileAllCodeContextProvider>
                                                    <AdminPage />
                                                </FileAllCodeContextProvider>
                                            )
                                        }
                                    />
                                );
                            })}
                        </Route>
                        <Route path="print-phieu-suat-an/:id" element={<PrinPhieusuatan />} />
                        <Route path="print-bang-tong-hop-suat-an" element={<PrinBangtonghopsuatan />} />
                    </Routes>
                    <ToastContainer
                        position="bottom-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            </Router>
        </Fragment>
    );
}

export default App;
