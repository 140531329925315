import actionTypes from './actionTypes'
import { apiGetBinhluan, apiNewBinhluan, apiEditBinhluan, apiDeleteBinhluan } from '~/services/binhluan'
import { toast } from "react-toastify";
export const getListBinhluan = () => async (dispatch) => {
    try {
        const response = await apiGetBinhluan()
        // console.log('chek api lisst Binhluan', response?.data.success)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_BINH_LUAN,
                binhluan: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_BINH_LUAN,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_BINH_LUAN,
            binhluan: null
        })
    }
}

export const NewBinhluan = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewBinhluan', payload)
            const response = await apiNewBinhluan(payload)
            // console.log('chek api apiNewBinhluan', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_BINH_LUAN,
                    msg: 'OK'
                });
                dispatch(getListBinhluan());
                toast.success("Thêm mới bình luận thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_BINH_LUAN,
                    msg: 'ERR'
                })
                toast.error("Thêm mới bình luận thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_BINH_LUAN,
                binhluan: null
            })
        }
    }
}
export const EditBinhluan = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditBinhluan(payload)
            // console.log('chek api apiEditBinhluan', response)
            if (response?.data.success === 200) {
                dispatch(getListBinhluan());
                toast.success("Sửa thông tin bình luận thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_BINH_LUAN,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_BINH_LUAN,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin bình luận thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_BINH_LUAN,
                binhluan: null
            })
        }
    }
}
export const DeleteBinhluan = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteBinhluan(payload)
            // console.log('chek api apiDeleteBinhluan', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListBinhluan());
                toast.success("Xóa thông tin bình luận thành công!")
                dispatch({
                    type: actionTypes.DELETE_BINH_LUAN,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_BINH_LUAN,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin bình luận thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_BINH_LUAN,
                binhluan: null
            })
        }
    }
}