import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '~/store/actions';
import Swal from 'sweetalert2';
import exportFromJSON from 'export-from-json';
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalBody,
    UncontrolledDropdown,
    Spinner,
    Card,
} from 'reactstrap';
import Head from '~/layout/head/Head';
import Content from '~/layout/content/Content';
import { Block } from '~/components/block/Block';
import {
    Button,
    Col,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    Icon,
    PaginationComponent,
    Row,
    RSelect,
    TooltipComponent,
    PermissionUtils,
    ExportXlsx,
} from '~/components/Component';
import { MenuPhanca } from './MenuTab/Menucontent';
import { apiGetPhancatonghop } from '~/services';

const Phancatonghop = () => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [showEditPhancatonghop, setShowEditPhancatonghop] = useState(false);

    //lấy giá trị từ thằng redux trả về
    // const { phancatonghop } = useSelector((state) => state.quanly);
    // console.log('object', phancatonghop)
    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    // useEffect(() => {
    //     dispatch(actions.getListPhancatonghop());
    // }, []);
    // useEffect(() => {
    //     if (phancatonghop && phancatonghop.length > 0) {
    //         setData([...phancatonghop]);
    //     }
    // }, [phancatonghop]);

    const [showPhanquyen, setShowPhanquyen] = useState({});
    let dataPermissionUtils = PermissionUtils('phanca', 'all');
    useEffect(() => {
        if (dataPermissionUtils) {
            setShowPhanquyen(dataPermissionUtils);
        }
    }, [dataPermissionUtils]);

    const namefile = 'Thông tin phân ca';
    const header = ['Stt', 'Họ tên', 'Mã nhân viên'];
    const [columns, setColumns] = useState(() => {
        const d = header.map((item, index) => index <= 6);
        return d;
    });
    const [dataFileMau, setDataFileMau] = useState([]);
    useEffect(() => {
        if (data.length > 0) {
            const datafilemau = data.map((item, index) => {
                return {
                    ...(columns[0] && { Stt: index + 1 }),
                    // ...(columns[1] && { 'Phòng ban': item?.phongban }),
                    // ...(columns[2] && { 'Mô tả': item?.mota }),
                    // ...(columns[3] && { 'Điện thoại': item?.dienthoai }),
                };
            });
            setDataFileMau(datafilemau);
        }
    }, [data, columns]);

    const [sm, updateSm] = useState(false);
    const [tablesm, updateTableSm] = useState(false);
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [sort, setSortState] = useState('');
    // onChange function for searching name
    const onFilterChange = (e) => {
        setSearchText(e.target.value);
    };
    const toggle = () => setonSearch(!onSearch);
    // const selectorCheck = (e) => {
    //     let newData;
    //     newData = data.map((item) => {
    //         item.checked = e.currentTarget.checked;
    //         return item;
    //     });
    //     setData([...newData]);
    // };
    // const onSelectChange = (e, id) => {
    //     let newData = data;
    //     let index = newData.findIndex((item) => item.id === id);
    //     newData[index].checked = e.currentTarget.checked;
    //     setData([...newData]);
    // };
    useEffect(() => {
        
        if (onSearchText !== '' && data?.length > 0) {
            const filteredObject = data?.filter((item) => {
                return (
                    item.hoten.toLowerCase().includes(onSearchText.toLowerCase()) ||
                    item.uuid.toLowerCase().includes(onSearchText.toLowerCase())

                );
            });

            console.log('filteredObject', filteredObject);
            setData([...filteredObject]);
        } else {
            setData([...data2]);
        }
    }, [onSearchText, setData]);
    // Get current list, pagination
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const defaultValue = `${year}-${month}`;
    const [inputValue, setInputValue] = useState(defaultValue);
    const [daysInMonth, setDaysInMonth] = useState([]);
    const vietnameseDaysOfWeek = ['Chủ Nhật', 'Thứ Hai', 'Thứ Ba', 'Thứ Tư', 'Thứ Năm', 'Thứ Sáu', 'Thứ Bảy'];
    useEffect(() => {
        const [year, month] = inputValue.split('-');
        const firstDayOfMonth = new Date(year, month - 1, 1);
        const lastDay = new Date(year, month, 0);

        const daysOfMonth = [];
        for (let day = firstDayOfMonth; day <= lastDay; day.setDate(day.getDate() + 1)) {
            let days = new Date(day);
            const dayString = days.getDate().toString().padStart(2, '0');
            const monthString = (days.getMonth() + 1).toString().padStart(2, '0');
            const yearString = days.getFullYear();
            const formattedDate = `${dayString}-${monthString}-${yearString}`;

            daysOfMonth.push({
                ngay: formattedDate,
                thu: vietnameseDaysOfWeek[days.getDay()],
            });
        }
        setDaysInMonth(daysOfMonth);
    }, [inputValue]);

    // console.log('data', data);
    // console.log('daysInMonth', daysInMonth);
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await apiGetPhancatonghop({ date: inputValue });
            const part = response?.data?.data.map((item) => ({
              hoten: item?.Nhanvien?.hoten,
              uuid: item?.Nhanvien?.uuid,
              data_phanca: item?.data_phanca ? JSON.parse(item?.data_phanca) : '',
            }));
            // console.log('partvvvvvvvvvv', part);
            setData(part);
            setData2(part);
            // setData(part); // Uncomment this line if you want to update the state
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
      
        fetchData().catch(console.error);
      }, [inputValue]);
    const itemsPerPageOptions = [10, 20, 30, 40, 50, 100, 300, 500];
    const [isClic, setIsClic] = useState(2);
    const [hover, setHoveredColumn] = useState(null);
    const [pinning, setPinning] = useState([true]);
    const handleHover = (columnIndex) => {
        setHoveredColumn(columnIndex);
    };
    
    useEffect(() => {
        const d = header.map((item, index) => index <= isClic);
        setPinning(d);
    }, [isClic]);
    return (
        <React.Fragment>
            <Head title="Doanh nghiệp, Chi nhánh, Phòng ban, Chức Vụ"></Head>
            <Content>
                <Card className="mt-0">
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner card-inner-lg px-4 mt-3">
                            <h4>Quản lý phân ca làm việc</h4>
                            <MenuPhanca />
                        </div>
                    </DataTable>
                </Card>
                <Block>
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner position-relative card-tools-toggle pt-0">
                            <div className="card-title-group">
                                <div className="card-title">
                                    <h5 className="title">Bảng phân ca tổng hợp</h5>
                                    <input
                                        type="month"
                                        id="start"
                                        className="form-control"
                                        min="2018-03"
                                        value={inputValue}
                                        onChange={(e) => setInputValue(e.target.value)}
                                    />
                                </div>
                                <div className="card-tools">
                                    <ul className="btn-toolbar gx-1">
                                        <li>
                                            <a
                                                href="#search"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    toggle();
                                                }}
                                                className="btn btn-icon search-toggle toggle-search"
                                            >
                                                <Icon name="search"></Icon>
                                            </a>
                                        </li>
                                        <li className="btn-toolbar-sep"></li>
                                        <li>
                                            <div className="toggle-wrap">
                                                <Button
                                                    className={`btn-icon btn-trigger toggle ${tablesm ? 'active' : ''}`}
                                                    onClick={() => updateTableSm(true)}
                                                >
                                                    <Icon name="menu-right"></Icon>
                                                </Button>
                                                <div className={`toggle-content ${tablesm ? 'content-active' : ''}`}>
                                                    <ul className="btn-toolbar gx-1">
                                                        <li className="toggle-close">
                                                            <Button
                                                                className="btn-icon btn-trigger toggle"
                                                                onClick={() => updateTableSm(false)}
                                                            >
                                                                <Icon name="arrow-left"></Icon>
                                                            </Button>
                                                        </li>
                                                      
                                                        <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle
                                                                    color="tranparent"
                                                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                                                >
                                                                    <Icon name="setting"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu end className="dropdown-menu-xs">
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Show</span>
                                                                        </li>
                                                                       {itemsPerPageOptions.map((item, index) => {
                                                                            return (
                                                                                <li
                                                                                    className={
                                                                                        itemPerPage === item
                                                                                            ? 'active'
                                                                                            : ''
                                                                                    }
                                                                                    key={index}
                                                                                >
                                                                                    <DropdownItem
                                                                                        tag="a"
                                                                                        href="#dropdownitem"
                                                                                        onClick={(ev) => {
                                                                                            ev.preventDefault();
                                                                                            setItemPerPage(item);
                                                                                            // handleReParamsClick();
                                                                                        }}
                                                                                    >
                                                                                        {item}
                                                                                    </DropdownItem>
                                                                                </li>
                                                                            );
                                                                        })}
                                                                    </ul>
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Order</span>
                                                                        </li>
                                                                        <li className={sort === 'dsc' ? 'active' : ''}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setSortState('dsc');
                                                                                    //   sortFunc("dsc");
                                                                                }}
                                                                            >
                                                                                DESC
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li className={sort === 'asc' ? 'active' : ''}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setSortState('asc');
                                                                                    //   sortFunc("asc");
                                                                                }}
                                                                            >
                                                                                ASC
                                                                            </DropdownItem>
                                                                        </li>
                                                                    </ul>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                                <div className="toggle-wrap nk-block-tools-toggle">
                                                    <Button
                                                        className={`btn-icon btn-trigger toggle-expand me-n1 ${
                                                            sm ? 'active' : ''
                                                        }`}
                                                        onClick={() => updateSm(!sm)}
                                                    >
                                                        <Icon name="upload-cloud"></Icon>
                                                    </Button>
                                                    <div
                                                        className="toggle-expand-content-cloud"
                                                        style={{ display: sm ? 'block' : 'none' }}
                                                    >
                                                        <ul className="nk-block-tools g-3">
                                                            <li className="nk-block-tools-opt">
                                                                <ExportXlsx
                                                                    dataFileMau={dataFileMau}
                                                                    title={header}
                                                                    namefile={namefile}
                                                                    btnname={'Xuất dữ liệu'}
                                                                />
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className={`card-search search-wrap ${!onSearch && 'active'}`}>
                                <div className="card-body">
                                    <div className="search-content">
                                        <Button
                                            className="search-back btn-icon toggle-search active"
                                            onClick={() => {
                                                setSearchText('');
                                                toggle();
                                            }}
                                        >
                                            <Icon name="arrow-left"></Icon>
                                        </Button>
                                        <input
                                            type="text"
                                            className="border-transparent form-focus-none form-control"
                                            placeholder="Tìm kiếm"
                                            value={onSearchText}
                                            onChange={(e) => onFilterChange(e)}
                                        />
                                        <Button className="search-submit btn-icon">
                                            <Icon name="search"></Icon>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="overflow-scroll"> */}
                        <div style={{ overflowY: 'hidden' }}>
                        <DataTableBody >
                            <DataTableHead className="col-12 ">
                                <DataTableRow className=" fixed-column">
                                    <span className="sub-text w-50px">Stt</span>
                                </DataTableRow>
                                <DataTableRow className={`${pinning[1] ? 'fixed-column1' : ''}`}>
                                <span className="sub-text w-150px"
                                              onMouseEnter={() => handleHover(1)}
                                              onMouseLeave={() => handleHover(null)}
                                        >
                                            Họ tên{' '}
                                            <Icon
                                                name={`award w-15px ${
                                                    isClic === 1
                                                        ? 'icon-clic'
                                                        : hover === 1
                                                            ? 'icon-visible'
                                                            : 'icon-hidden'
                                                }`}
                                                onClick={() => setIsClic(1)}
                                            ></Icon>
                                        </span>
                                </DataTableRow>
                                <DataTableRow className={`${pinning[2] ? 'fixed-column2' : ''}`}>
                                <span className="sub-text w-100px"
                                              onMouseEnter={() => handleHover(2)}
                                              onMouseLeave={() => handleHover(null)}
                                        >
                                            Mã nhân viên{' '}
                                            <Icon
                                                name={`award w-15px ${
                                                    isClic === 2
                                                        ? 'icon-clic'
                                                        : hover === 2
                                                            ? 'icon-visible'
                                                            : 'icon-hidden'
                                                }`}
                                                onClick={() => setIsClic(2)}
                                            ></Icon>
                                        </span>
                                </DataTableRow>
                                
                                {daysInMonth.map((it) => (
                                    <DataTableRow size=" text-center" key={it.ngay}>
                                        <span className="sub-text w-150px">{it.thu}</span>
                                        <span className="sub-text w-150px">{it.ngay}</span>
                                    </DataTableRow>
                                ))}
                                {/* <DataTableRow className="nk-tb-col-tools text-end">
                                    <UncontrolledDropdown>
                                        <DropdownToggle
                                            color="tranparent"
                                            className="btn btn-xs btn-outline-light btn-icon dropdown-toggle"
                                        >
                                            <Icon name="plus"></Icon>
                                        </DropdownToggle>
                                        <DropdownMenu end className="dropdown-menu-xs">
                                            <ul className="link-tidy sm no-bdr">
                                                {header.length > 0 ? (
                                                    header.map((item, index) => (
                                                        <li key={index}>
                                                            <div className="custom-control custom-control-sm custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id={`column-${index}`}
                                                                    checked={columns[index]}
                                                                    onChange={(e) =>
                                                                        setColumns((prev) => {
                                                                            const newColumns = [...prev];
                                                                            newColumns[index] = e.target.checked;
                                                                            return newColumns;
                                                                        })
                                                                    }
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor={`column-${index}`}
                                                                >
                                                                    {item}
                                                                </label>
                                                            </div>
                                                        </li>
                                                    ))
                                                ) : (
                                                    <li>No data</li>
                                                )}
                                            </ul>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </DataTableRow> */}
                            </DataTableHead>
                            {currentItems.length > 0
                                ? currentItems.map((item, index) => {
                                    const displayedIndex =  itemPerPage * (currentPage - 1) + index + 1;
                                      return (
                                          <DataTableItem key={item.id}>
                                              <DataTableRow className="w-50px fixed-column"
                                                              size=" nk-tb-col-check mb">
                                                    <span className="tb-amount">{displayedIndex}</span>
                                                </DataTableRow>
                                                <DataTableRow className={`${pinning[1] ? 'fixed-column1' : ''}`}>
                                                    <span className="tb-amount">{item?.hoten}</span>
                                                </DataTableRow>
                                                <DataTableRow className={`${pinning[2] ? 'fixed-column2' : ''}`}>
                                                    <span className="tb-amount">{item?.uuid}</span>
                                                </DataTableRow>
                                              {
                                                item.data_phanca.length > 0 &&
                                                item.data_phanca.map((iv) => (
                                                <DataTableRow className="w-100px" key={`aa-${iv?.ngay}`}>
                                                    {iv?.calamviec !== null && (
                                                        iv?.calamviec.map((c) => (
                                                            <span className="tb-amount text-center" key={c?.Khaibaocalamviec?.createdAt}>
                                                                {c?.Khaibaocalamviec?.macalamviec}
                                                            </span>
                                                        ))
                                                    )}
                                                </DataTableRow>
                                              ))}
                                              
                                          </DataTableItem>
                                      );
                                  })
                                : null}
                        </DataTableBody>
                        </div>
                        <div className="card-inner">
                            {currentItems.length > 0 ? (
                                <PaginationComponent
                                    itemPerPage={itemPerPage}
                                    totalItems={data.length}
                                    paginate={paginate}
                                    currentPage={currentPage}
                                />
                            ) : (
                                <div className="text-center">
                                    <div>
                                        <Spinner color="primary" />
                                    </div>
                                    <span className="text-silent">Chưa có dữ liệu!</span>
                                </div>
                            )}
                        </div>
                    </DataTable>
                </Block>
            </Content>
        </React.Fragment>
    );
};

export default Phancatonghop;
