import axiosConfig from '../axiosConfig'

export const apiGetBocauhoiphongvan = () => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlyhethong/bocauhoiphongvan/vitrituyendung`,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiNewBocauhoiphongvan = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlyhethong/bocauhoiphongvan/vitrituyendung',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiEditBocauhoiphongvan = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlyhethong/bocauhoiphongvan/vitrituyendung',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiDeleteBocauhoiphongvan = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlyhethong/bocauhoiphongvan/vitrituyendung',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
