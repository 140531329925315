import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Card, Button} from 'reactstrap';
import Content from '~/layout/content/Content';
import {Block} from '~/components/block/Block';
import {DataTable, PermissionUtils, Icon} from '~/components/Component';
import {InputForm} from '~/components/Form';
import {
    apiEditCauhinhdoanhnghiep,
    apiGetCauhinhdoanhnghiep,
    apiNewCauhinhdoanhnghiep,
} from '~/services/cauhinhdoanhnghiep';
import _ from 'lodash';
import {toast} from 'react-toastify';
import {MenuDoanhnghiep} from './MenuTab/Menucontent';
import {NumericFormat} from 'react-number-format';

const CauHinhDoanhNghiep = () => {
    const [showPhanquyen, setShowPhanquyen] = useState({});
    let dataPermissionUtils = PermissionUtils('cauhinhdoanhnghiep', 'all');
    useEffect(() => {
        if (dataPermissionUtils) {
            setShowPhanquyen(dataPermissionUtils);
        }
    }, [dataPermissionUtils]);
    
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayload] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const cauHinhDoanhNghiep = await apiGetCauhinhdoanhnghiep();
                const response = cauHinhDoanhNghiep.data.data.rows[0];
                const datas = {
                    id: response?.id,
                    cauhinhmanv: response?.cauhinhmanv,
                    ngaycongtieuchuan: response?.ngaycongtieuchuan,
                    tientohopdong: response?.tientohopdong,
                    thunhapcanhanthueformat: response?.thunhapcanhanthue,
                    giamtrutientheosonguoiformat: response?.giamtrutientheosonguoi,
                    mucluongtoidatinhbaohiemTNformat: response?.mucluongtoidatinhbaohiemTN,
                    thoigianduyetnghiphep: response?.thoigianduyetnghiphep,
                    mucluongtoidatinhbaohiemformat: response?.mucluongtoidatinhbaohiem,
                    nghitoidakotinhbaohiem: response?.nghitoidakotinhbaohiem,
                    heSoCongNgay: response?.heSoCongNgay,
                    heSoCongDem: response?.heSoCongDem,
                };
                setPayload(datas);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData().catch(console.error);
    }, []);
 
    const handleSubmit = _.throttle(async () => {
        const datapayload = {
            data: [
                {
                    cauhinhmanv: payload?.cauhinhmanv,
                    ngaycongtieuchuan: payload?.ngaycongtieuchuan,
                    tientohopdong: payload?.tientohopdong,
                    thunhapcanhanthue: payload?.thunhapcanhanthue,
                    giamtrutientheosonguoi: payload?.giamtrutientheosonguoi,
                    mucluongtoidatinhbaohiemTN: payload?.mucluongtoidatinhbaohiemTN,
                    thoigianduyetnghiphep: payload?.thoigianduyetnghiphep,
                    nghitoidakotinhbaohiem: payload?.nghitoidakotinhbaohiem,
                    mucluongtoidatinhbaohiem: payload?.mucluongtoidatinhbaohiem,
                    heSoCongNgay: payload?.heSoCongNgay,
                    heSoCongDem: payload?.heSoCongDem,
                },
            ],
        };
        if (payload.id) {
            datapayload.data[0].id = payload.id;
            const response = await apiEditCauhinhdoanhnghiep(datapayload);
            response?.data.length === 0
                ? toast.error('Sửa thông tin không thành công!')
                : toast.success('Sửa thông tin thành công!');
        } else {
            // console.log('datapayload', datapayload)
            const response = await apiNewCauhinhdoanhnghiep(datapayload);
            response?.data.length === 0
                ? toast.error('Thêm thông tin không thành công!')
                : toast.success('Thêm thông tin thành công!');
        }
        
        
    }, 10000);
    return (
        <React.Fragment>
            {/*<Head title="Doanh nghiệp, Chi nhánh, Phòng ban, Chức Vụ"></Head>*/}
            <Content>
                <Card className="mt-0">
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner card-inner-lg px-4">
                            <h4>Cấu hình doanh nghiệp</h4>
                            <MenuDoanhnghiep/>
                        </div>
                    </DataTable>
                </Card>
                <Block>
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner position-relative card-tools-toggle pt-0">
                            <div className="row">
                                <div className="form-group col-md-12">
                                    {+showPhanquyen?.them === 1 && (
                                        <Button
                                            color="primary"
                                            className="btn-icon px-4 mt-1"
                                            onClick={(e) => handleSubmit(e)}
                                        >
                                            Cập nhật
                                        </Button>
                                    )}
                                </div>
                                
                                <div className="form-group col-md-4">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Tiền tố mã nhân viên'}
                                        value={payload?.cauhinhmanv || ''}
                                        setValue={setPayload}
                                        keyPayload={'cauhinhmanv'}
                                        type="text"
                                        validate
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Tiền tố hợp đồng'}
                                        value={payload.tientohopdong || ''}
                                        setValue={setPayload}
                                        keyPayload={'tientohopdong'}
                                        type="text"
                                        validate
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Thời gian duyệt đơn nghỉ phép /ngày'}
                                        value={payload.thoigianduyetnghiphep || ''}
                                        setValue={setPayload}
                                        keyPayload={'thoigianduyetnghiphep'}
                                        type="text"
                                        validate
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                                            <span>
                                                Mức đóng BHXH, BHYT tối đa<b className="text-danger">(*)</b>
                                            </span>
                                        </label>
                                        <div className="form-control-wrap">
                                            <div className="form-text-hint">
                                                <span className="overline-title">VND</span>
                                            </div>
                                            <NumericFormat
                                                className="form-control px-2"
                                                value={payload.mucluongtoidatinhbaohiemformat || ''}
                                                thousandSeparator={true}
                                                name="mucluongtoidatinhbaohiem"
                                                onValueChange={(values) => {
                                                    const {formattedValue, value} = values;
                                                    setPayload((prev) => ({
                                                        ...prev,
                                                        ['mucluongtoidatinhbaohiem']: value,
                                                    }));
                                                    setPayload((prev) => ({
                                                        ...prev,
                                                        ['mucluongtoidatinhbaohiemformat']: formattedValue,
                                                    }));
                                                }}
                                            />
                                            {invalidFields.length > 0 &&
                                                invalidFields.some((i) => i.name === 'mucluongtoidatinhbaohiem') && (
                                                    <small className="text-danger">
                                                        {
                                                            invalidFields.find((i) => i.name === 'mucluongtoidatinhbaohiem')
                                                                ?.message
                                                        }
                                                    </small>
                                                )}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                                            <span>
                                                Mức đóng BHTN tối đa<b className="text-danger">(*)</b>
                                            </span>
                                        </label>
                                        <div className="form-control-wrap">
                                            <div className="form-text-hint">
                                                <span className="overline-title">VND</span>
                                            </div>
                                            <NumericFormat
                                                className="form-control px-2"
                                                value={payload.mucluongtoidatinhbaohiemTNformat || ''}
                                                thousandSeparator={true}
                                                name="mucluongtoidatinhbaohiemTN"
                                                onValueChange={(values) => {
                                                    const {formattedValue, value} = values;
                                                    setPayload((prev) => ({
                                                        ...prev,
                                                        ['mucluongtoidatinhbaohiemTN']: value,
                                                    }));
                                                    setPayload((prev) => ({
                                                        ...prev,
                                                        ['mucluongtoidatinhbaohiemTNformat']: formattedValue,
                                                    }));
                                                }}
                                            />
                                            {invalidFields.length > 0 &&
                                                invalidFields.some((i) => i.name === 'mucluongtoidatinhbaohiemTN') && (
                                                    <small className="text-danger">
                                                        {
                                                            invalidFields.find((i) => i.name === 'mucluongtoidatinhbaohiemTN')
                                                                ?.message
                                                        }
                                                    </small>
                                                )}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-md-4">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Số ngày người lao động không làm việc và không hưởng tiền lương (tối thiểu) không đóng BHXH tháng đó'}
                                        value={payload.nghitoidakotinhbaohiem || ''}
                                        setValue={setPayload}
                                        placeholder={'Người lao động không làm việc và không hưởng tiền lương từ xx ngày làm việc trở lên trong tháng thì không đóng BHXH tháng đó.'}
                                        keyPayload={'nghitoidakotinhbaohiem'}
                                        type="number"
                                        validate
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Ngày công tiêu chuẩn'}
                                        value={payload.ngaycongtieuchuan || ''}
                                        setValue={setPayload}
                                        keyPayload={'ngaycongtieuchuan'}
                                        type="number"
                                        validate
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                                            <span>
                                                Mức giảm trừ bản thân thuế TNCN<b className="text-danger">(*)</b>
                                            </span>
                                        </label>
                                        <div className="form-control-wrap">
                                            <div className="form-text-hint">
                                                <span className="overline-title">VND</span>
                                            </div>
                                            <NumericFormat
                                                className="form-control px-2"
                                                value={payload.thunhapcanhanthueformat || ''}
                                                thousandSeparator={true}
                                                name="thunhapcanhanthue"
                                                onValueChange={(values) => {
                                                    const {formattedValue, value} = values;
                                                    setPayload((prev) => ({
                                                        ...prev,
                                                        ['thunhapcanhanthue']: value,
                                                    }));
                                                    setPayload((prev) => ({
                                                        ...prev,
                                                        ['thunhapcanhanthueformat']: formattedValue,
                                                    }));
                                                }}
                                            />
                                            {invalidFields.length > 0 &&
                                                invalidFields.some((i) => i.name === 'thunhapcanhanthue') && (
                                                    <small className="text-danger">
                                                        {
                                                            invalidFields.find((i) => i.name === 'thunhapcanhanthue')
                                                                ?.message
                                                        }
                                                    </small>
                                                )}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                                            <span>
                                                Mức giảm trừ người phụ thuộc thuế TNCN<b className="text-danger">(*)</b>
                                            </span>
                                        </label>
                                        <div className="form-control-wrap">
                                            <div className="form-text-hint">
                                                <span className="overline-title">VND</span>
                                            </div>
                                            <NumericFormat
                                                className="form-control px-2"
                                                value={payload.giamtrutientheosonguoiformat || ''}
                                                thousandSeparator={true}
                                                name="giamtrutientheosonguoi"
                                                onValueChange={(values) => {
                                                    const {formattedValue, value} = values;
                                                    setPayload((prev) => ({
                                                        ...prev,
                                                        ['giamtrutientheosonguoi']: value,
                                                    }));
                                                    setPayload((prev) => ({
                                                        ...prev,
                                                        ['giamtrutientheosonguoiformat']: formattedValue,
                                                    }));
                                                }}
                                            />
                                            {invalidFields.length > 0 &&
                                                invalidFields.some((i) => i.name === 'giamtrutientheosonguoi') && (
                                                    <small className="text-danger">
                                                        {
                                                            invalidFields.find(
                                                                (i) => i.name === 'giamtrutientheosonguoi',
                                                            )?.message
                                                        }
                                                    </small>
                                                )}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-md-4">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Hệ số lương công ngày'}
                                        value={payload?.heSoCongNgay}
                                        setValue={setPayload}
                                        keyPayload={'heSoCongNgay'}
                                        type="text"
                                        validate
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Hệ số lương công đêm'}
                                        value={payload?.heSoCongDem}
                                        setValue={setPayload}
                                        keyPayload={'heSoCongDem'}
                                        type="text"
                                        validate
                                    />
                                </div>
                            </div>
                        
                        </div>
                    </DataTable>
                </Block>
            </Content>
        </React.Fragment>
    );
};

export default CauHinhDoanhNghiep;
