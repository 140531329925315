import React, {useState} from 'react';
import AvatarEditor from 'react-avatar-editor';
import {Icon, Button} from '~/components/Component';
import {apiUploadImages, apiEditNhanvien, apiGetNhanvien, apiAvatar} from '~/services';
import {Spinner} from 'reactstrap';
import { toast } from 'react-toastify';

const AvatarCrop = ({setmodalAvatar, dataNhanvien, setDataNhanvien, setDataUrlAvatar}) => {
    // console.log('dataNhanvien', dataNhanvien)
    const [image, setImage] = useState(null);
    const [scale, setScale] = useState(1);
    const [position, setPosition] = useState({x: 0.5, y: 0.5});
    const [croppedImageSrc, setCroppedImageSrc] = useState(null);
    const [croppedFileImageSrc, setcroppedFileImageSrc] = useState(null);
    // console.log('croppedFileImageSrc', croppedFileImageSrc)
    
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };
    
    const handleScaleChange = (e) => {
        const scale = parseFloat(e.target.value);
        setScale(scale);
    };
    
    const handlePositionChange = (position) => {
        setPosition(position);
    };
    
    const handleCrop = () => {
        if (editor) {
            const canvas = editor.getImageScaledToCanvas();
            canvas.toBlob((blob) => {
                const file = new File([blob], 'cropped-image.jpg', {type: 'image/jpeg'});
                setCroppedImageSrc(URL.createObjectURL(file));
                setcroppedFileImageSrc(file);
            }, 'image/jpeg');
        }
    };
    
    let editor;
    
    const [isLoading, setIsLoading] = useState(false)
    const setDefaultFiles = async () => {
        setIsLoading(true)
        // setDataUrlAvatar(croppedFileImageSrc);
        // setmodalAvatar(false)
        try{
        let formData = new FormData()
        formData.append('avatar', croppedFileImageSrc)
        let response = await apiAvatar(formData)
        // console.log('response', response)
        if (response.data.success === 200) {
            console.log('Thành công', dataNhanvien)
                if(dataNhanvien !== null){
                let datapayload = {
                    data: [
                        {
                            id: dataNhanvien.id,
                            removeAvatar: dataNhanvien?.Thongtinnhanvien?.avatar,
                            avatar: response.data.data,
                        }
                    ]
                };
                let EditResponse = await apiEditNhanvien(datapayload)
                if (EditResponse.status === 200) {
                    setImage(null)
                    setCroppedImageSrc(null)
                    setcroppedFileImageSrc(null)
                    setIsLoading(false)
                    setmodalAvatar(false)
                    try {
                        let responsedata = await apiGetNhanvien({id: dataNhanvien.id})
                        console.log('responsedata', responsedata)
                        setDataNhanvien(responsedata?.data.data.rows[0])
                    } catch (error) {
                        console.error('Error fetching data:', error);
                    }
                }
            }else{
                setDataUrlAvatar({avatarUrl: response.data.data , avatarFile: croppedFileImageSrc});
                setCroppedImageSrc(null)
                setcroppedFileImageSrc(null)
                setIsLoading(false)
                setmodalAvatar(false)
            }
        }else{
            toast.error("Không thành công!");
        }
        }catch{
            console.log('err');
        }
        // let formData = new FormData()
        // formData.append('file', croppedFileImageSrc)
        // formData.append('upload_preset', process.env.REACT_APP_UPLOAD_ASSETS_NAME)
        // let response = await apiUploadImages(formData)
        // if (response.status === 200) {
        //     if(dataNhanvien !== null){
        //         let datapayload = {
        //             "data": [
        //                 {
        //                     id: dataNhanvien,
        //                     avatar: response.data?.secure_url,
        //                 }
        //             ]
        //         };
        //         let EditResponse = await apiEditNhanvien(datapayload)
        //         if (EditResponse.status === 200) {
        //             setImage(null)
        //             setCroppedImageSrc(null)
        //             setcroppedFileImageSrc(null)
        //             setIsLoading(false)
        //             setmodalAvatar(false)
        //             try {
        //                 let responsedata = await apiGetNhanvien({id: dataNhanvien})
        //                 // console.log('responsedata', responsedata)
        //                 setDataNhanvien(responsedata?.data.data.rows[0])
        //             } catch (error) {
        //                 console.error('Error fetching data:', error);
        //             }
        //         }
        //     }else{
        //         setDataUrlAvatar({avatar: response.data?.secure_url});
        //     }
        // }
    }
    
    return (
        <React.Fragment>
            <div className="text-center ">
                {croppedImageSrc === null && (
                    <div className="text-center py-3">
                        <label className="preview-inputImge" htmlFor="inputImge">
                            <Icon name="camera-fill fs-1 text-primary"></Icon>
                            <div>Tải ảnh lên</div>
                        </label>
                    </div>
                )}
                <input type="file" className="form-control" id="inputImge" hidden onChange={handleFileChange}/>
                {image && (
                    <React.Fragment>
                        <div>
                            <AvatarEditor
                                ref={(ref) => (editor = ref)}
                                image={image}
                                width={250}
                                height={250}
                                border={50}
                                color={[255, 255, 255, 0.6]} // RGBA
                                scale={scale}
                                position={position}
                                onPositionChange={handlePositionChange}
                            />
                        </div>
                        <div>
                            <input
                                type="range"
                                min="1"
                                max="2"
                                step="0.01"
                                value={scale}
                                onChange={handleScaleChange}
                            />
                        </div>
                        <div>
                            <button className='btn btn-info py-1' onClick={handleCrop}>Cắt ảnh</button>
                        </div>
                        <div className='mt-3'>
                            {croppedImageSrc && <img src={croppedImageSrc} className="rounded-4" alt="Cropped image"/>}
                        </div>
                        <div className='mt-3'>
                            {croppedFileImageSrc !== null ?
                                isLoading === true ? (
                                    <Button disabled color="primary">
                                        <Spinner size="sm"/>
                                        <span> Đặt làm ảnh đại diện... </span>
                                    </Button>
                                ) : (
                                    <Button color="primary" onClick={() => setDefaultFiles()}>
                                        Đặt làm ảnh đại diện
                                    </Button>
                                )
                                : ''
                            }
                        </div>
                    </React.Fragment>
                )}
            </div>
        </React.Fragment>
    );
};

export default AvatarCrop;
