import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {InputForm, Button} from '~/components/Form'
import * as actions from '~/store/actions'

import '../Modal.scss';
// import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import {validateBangChamCong} from "~/request/validateBangChamCong";
import { dataSelectLock} from "~/utils/Datacustom";
import {Icon} from "~/components/Component";
import {Modal,ModalBody} from "reactstrap";

const ModalEditBangchamcongtonghop = ({show, setShow, dataIdBangchamcongtonghop,fieldList}) => {
    const dispatch = useDispatch()
    const [listTrangthai, setlistTrangthai] = useState([]);
    const {bangchotcong} = useSelector(state => state.bangchotcong)
    
    const [listBangchotcong, setlistBangchotcong] = useState([]);
    
    const [invalidFields, setInvalidFields] = useState([])
    const [payload, setPayload] = useState(fieldList)
    const [alertErr, setAlertErr] = useState('')
    useEffect(() => {
    
        let TrangthaiId = dataSelectLock.find((items) => {
            return items && items.value === dataIdBangchamcongtonghop?.TrangthaiId;
        });
        let BangchotcongId = bangchotcong.find((items) => {
            return items && items.id === dataIdBangchamcongtonghop?.BangchotcongId;
        });
        show && dataIdBangchamcongtonghop && setPayload({
            TrangthaiId: { label: TrangthaiId?.label, value: TrangthaiId?.value },
            BangchotcongId: { label: BangchotcongId?.tieude, value: BangchotcongId?.id },
            tieude: dataIdBangchamcongtonghop.tieude,
            mota: dataIdBangchamcongtonghop.mota,
            nguoitao: 1,
        })
        dispatch(actions.getListBangchotcong());
    }, [dataIdBangchamcongtonghop, show])
    
    useEffect(() => {
        let TrangthaiId = dataSelectLock.map((item) => ({label: item.label, value: item.value}));
        setlistTrangthai(TrangthaiId);
        let BangchotcongId = bangchotcong?.map((item) => ({label: item.tieude, value: item.id}));
    
        setlistBangchotcong(BangchotcongId);
    }, [dataSelectLock,bangchotcong])
    const checkDate = (startDate, finishDate) => {
        return finishDate > startDate;
    };
    const handleSubmit = async () => {
        let invalids = validate(payload)
        if (invalids === 0) {
                let datapayload = {
                    id: dataIdBangchamcongtonghop.id,
                    tieude: payload.tieude,
                    mota: payload.mota,
                    nguoitao: 1,
                    TrangthaiId: payload.TrangthaiId.value,
                    BangchotcongId: payload.BangchotcongId.value,
                }
                dispatch(actions.EditBangchamcongtonghop(datapayload))
          
        }
        setShow(true)
    }
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayload(prev => ({...prev, [statename]: selected}))
    }
    const validate = (payload) => {
        let invalids = 0
        let fields = Object.entries(payload)
        fields.forEach(item => {
            if (validateBangChamCong(item[0], item[1]) !== undefined) {
                setInvalidFields(prev => [...prev, validateBangChamCong(item[0], item[1])])
                invalids++
            }
        })
        return invalids
    }
    return (
        <React.Fragment>
            <Modal isOpen={show} className="modal-dialog-centered modal-90w" size="lg" toggle={setShow}>
                <ModalBody>
                    <Icon name="cross-sm float-end fs-2" onClick={setShow}></Icon>
                    <h5 className="title mb-3">Sửa bảng chấm công</h5>
                    <div className="row">
                        <div className="form-group col-md-6">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Tên bảng công'}
                                value={payload.tieude}
                                setValue={setPayload}
                                keyPayload={'tieude'}
                                type='text'
                            />
                        </div>
                        <div className="form-group col-md-6 ">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Mô tả'}
                                value={payload.mota}
                                setValue={setPayload}
                                keyPayload={'mota'}
                                type='text'
                            />
                        </div>
    
                        <div className="form-group col-md-6">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                                Chọn bảng chấm công <b className="text-danger">(*)</b>
                            </span>
                            </label>
                            <Select
                                value={payload.BangchotcongId}
                                onChange={handleOnSelect}
                                options={listBangchotcong}
                                placeholder={'Bảng chấm công'}
                                name="BangchotcongId"
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'BangchotcongId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'BangchotcongId')?.message}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-6 ">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                                Trạng thái <b className="text-danger">(*)</b>
                            </span>
                            </label>
                            <Select
                                value={payload.TrangthaiId}
                                onChange={handleOnSelect}
                                options={listTrangthai}
                                placeholder={'Trạng thái'}
                                name="TrangthaiId"
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'TrangthaiId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'TrangthaiId')?.message}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-12" id="alertDateErr">
                            {alertErr}
                        </div>
                        <div className='col-12 mt-5'>
                            <Button
                                text='Cập nhật'
                                bClass='btn btn-primary px-5'
                                textColor
                                onClick={handleSubmit}
                            />
                        </div>
                    </div>
    
                    
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}

export default ModalEditBangchamcongtonghop;

