import axios from '../axiosConfig'
import axiosConfig from "~/axiosConfig";

export const apiListChamCongData = () => new Promise(async (resolve, reject) => {
    try {
        const response = await axios({
            method: 'get',
            url: '/api/v2/quanlyhethong/getmaychamcong/quanlycong',
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
export const apiListChamCong = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axios({
            method: 'post',
            url: '/api/v2/quanlyhethong/tinhcong/quanlycong',
            data: payload
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
export const importDataChamCong = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axios({
            method: 'post',
            url: '/api/v2/quanlyhethong/importexcell/napdschamcongtuclient',
            data: payload
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
export const apiListChamCongTangCa = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axios({
            method: 'post',
            url: '/api/v2/quanlyhethong/tinhcongtangca/quanlycong',
            data: payload
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
export const apiEditBangChamCongTangCa = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlyhethong/bangchamcongtangca/quanlycong',
            data: payload
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
// export const updateTangCaNhanvien = (payload) => new Promise(async (resolve, reject) => {
//     try {
//         const response = await axios({
//             method: 'put',
//             url: '/api/v2/quanlyhethong/update/tangca',
//             data: payload
//         })
//         resolve(response)
        
//     } catch (error) {
//         reject(error)
//     }
// })

export const apiListBangChamCong = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axios({
            method: 'get',
            url: '/api/v2/quanlyhethong/bangchamcong/quanlycong?BangchotcongId='+payload.BangchotcongId+'&ngaybatdau='+payload.ngaybatdau+'&ngayketthuc='+payload.ngayketthuc,
        })
        resolve(response)
        
        
    } catch (error) {
        reject(error)
    }
})
export const apiListBangChamCongTangCa = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axios({
            method: 'get',
            url: '/api/v2/quanlyhethong/bangchamcongtangca/quanlycong?PhieutangcaId='+payload.PhieutangcaId+'&page='+payload.start+'&limit='+payload.size+'&order='+payload.sorting+'&by='+payload.globalFilter,
        
    })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
// Danh chấm cng theo nhân viên
export const apiListBangChamCongByNhanVien = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axios({
            method: 'get',
            url: '/api/v2/quanlyhethong/bangchamcongbynhanvien/quanlycong?BangchotcongId='+payload.BangchotcongId+'&NhanvienId='+payload.NhanvienId,
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
// Danh sahc chỉnh sửa ban chấm công
export const apiListChinhSuaBangChamCong = (query) => new Promise(async (resolve, reject) => {
    try {
        const response = await axios({
            method: 'get',
            url: '/api/v2/quanlyhethong/chinhsuabangchamcong/quanlycong',
            params: query,
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
//! lấy lương theo từng nhân viên
export const apiListGetLuong = (payload) => new Promise(async (resolve, reject) => {
    // console.log('payloadfgfg', payload)
    try {
        const response = await axios({
            method: 'post',
            url: '/api/v2/quanlyhethong/luong/phucap',
            data: payload,
        })
       
        resolve(response)
        
    } catch (error) {
        resolve(error)
        reject(error)
    }
})

//! Lưu để làm báo cáo thu nập bình quân
export const apiAddBCLuong = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axios({
            method: 'post',
            url: '/api/v2/quanlyhethong/bc-listluong/thongkebaocao',
            data: payload
        })
        // console.log('response', response)
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})

//! lấy lương làm báo cáo thu nhập binh quân
export const apiListBCLuong = () => new Promise(async (resolve, reject) => {
    try {
        const response = await axios({
            method: 'get',
            url: '/api/v2/quanlyhethong/bc-listluong/thongkebaocao',
        })
        // console.log('response', response)
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
//! lấy lương làm báo cáo thu nhập binh quân
export const apiListBCLuongByNhanVien = (fieldPhieuLuong) => new Promise(async (resolve, reject) => {
    try {
        const response = await axios({
            method: 'get',
            url: '/api/v2/quanlyhethong/bc-listluong-theonhanvien/thongkebaocao?NhanvienId='+fieldPhieuLuong.NhanvienId+'&BangchotcongId='+fieldPhieuLuong.BangchotcongId,
        })
        resolve(response)
    } catch (error) {
        reject(error)
    }
})

//! lấy lương làm ba cáo thu nhập binh quân
export const apiDeleteBCLuong = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axios({
            method: 'delete',
            url: '/api/v2/quanlyhethong/bc-listluong/thongkebaocao',
            data: payload
        })
        // console.log('response', response)
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})

//! lấy lương làm ba cáo thu nhập binh quân
export const apiUpdateBCLuong = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axios({
            method: 'put',
            url: '/api/v2/quanlyhethong/bc-listluong/thongkebaocao',
            data: payload
        })
        // console.log('response', response)
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
//! lấy Báo cáo Tổng quát
export const apiDataTongQuat = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axios({
            method: 'post',
            url: '/api/v2/quanlyhethong/bc-listluong/tongquat/thongkebaocao',
            data: payload
        })
        // console.log('response', response)
        resolve(response)
    } catch (error) {
        reject(error)
    }
})
//! lấy thông nhân viên đi làm
export const apiDataNhanVienDiLam = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axios({
            method: 'get',
            url: '/api/v2/quanlyhethong/nhanviendilam/dashboardadmin',
            params: payload
        })
        // console.log('response', response)
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
//! lấy thông nhân viên đi muộn về sớm
export const apiDataNhanVienDiMuon = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axios({
            method: 'get',
            url: '/api/v2/quanlyhethong/thongkedimuonvesom/dashboardadmin',
            params: payload
        })
        // console.log('response', response)
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
//! lấy thông nhân viên thực tế nghỉ
export const apiDataThucTeNghi = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axios({
            method: 'get',
            url: '/api/v2/quanlyhethong/thuctenghi/dashboardadmin',
            params: payload
        })
        // console.log('response', response)
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
//! lấy thông tin làm báo cáo tổng quát
export const apiDataTongSoNhanVien = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axios({
            method: 'get',
            url: '/api/v2/quanlyhethong/tongsonhanvien/dashboardadmin',
            params: payload
        })
        // console.log('response', response)
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
//! lấy thông tin Nhân viên mới
export const apiDataNhanVienMoi = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axios({
            method: 'get',
            url: '/api/v2/quanlyhethong/nhanvienmoi/dashboardadmin',
            params: payload
        })
        // console.log('response', response)
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
//! lấy thông tin nhân viên nghỉ việc
export const apiDataNhanVienNghiViec = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axios({
            method: 'get',
            url: '/api/v2/quanlyhethong/nhanviennghiviec/dashboardadmin',
            params: payload
        })
        // console.log('response', response)
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
//! lấy thông tin thử việc thành công
export const apiDataThuViecThanhCong = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axios({
            method: 'get',
            url: '/api/v2/quanlyhethong/thuviecthanhcong/dashboardadmin',
            params: payload
        })
        // console.log('response', response)
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
//! lấy thông tin làm báo cáo tuyển dụng
export const apiDataTuyenDung = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axios({
            method: 'post',
            url: '/api/v2/quanlyhethong/bc-listluong/tuyendung/thongkebaocao',
            data: payload
        })
        // console.log('response', response)
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
//! lấy thông tin làm báo cáo chuyên cần
export const apiDataChuyenCan = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axios({
            method: 'post',
            url: '/api/v2/quanlyhethong/bc-listluong/chuyencan/thongkebaocao',
            data: payload
        })
        // console.log('response', response)
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
//! lấy thông tin làm báo cáo thâm niên
export const apiDataThamNien = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axios({
            method: 'post',
            url: '/api/v2/quanlyhethong/bc-listluong/thamnien/thongkebaocao',
            data: payload
        })
        // console.log('response', response)
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
//! lấy thông tin làm báo cáo biến động nhân sự
export const apiDataBienDongNhanSu = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axios({
            method: 'post',
            url: '/api/v2/quanlyhethong/bc-listluong/biendongnhansu/thongkebaocao',
            data: payload
        })
        // console.log('response', response)
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
//! lấy thông tin làm báo cáo biến động nhân sự
export const apiDataBienDongNhanSuByChucVu = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axios({
            method: 'post',
            url: '/api/v2/quanlyhethong/bc-listluong/biendongnhansutheochucvu/thongkebaocao',
            data: payload
        })
        // console.log('response', response)
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
export const apiDataDaoTaoNhanSu = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axios({
            method: 'post',
            url: '/api/v2/quanlyhethong/bc-listluong/daotaonhansu/thongkebaocao',
            data: payload
        })
        // console.log('response', response)
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
//! lấy thông tin thu nhập bình quân
export const apiDataThuNhapBQ = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axios({
            method: 'post',
            url: '/api/v2/quanlyhethong/bc-listluong/thunhapbinhquan/thongkebaocao',
            data: payload
        })
        // console.log('response', response)
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
//! lấy thông tin thu nhập bình quân
export const apiDataChiPhiNhanSu = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axios({
            method: 'post',
            url: '/api/v2/quanlyhethong/bc-listluong/chiphinhansu/thongkebaocao',
            data: payload
        })
        // console.log('response', response)
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
//! lấy thông tin đi làm đúng giờ trễ giờ trong dashboard
export const apiDashboardThoiGianChamCongRange = (payload) => new Promise(async (resolve, reject) => {
    // console.log(':::::payload:::::::', payload)
    
    try {
        const response = await axios({
            method: 'get',
            url: '/api/v2/quanlyhethong/thongkedimuonvesom/dashboardadmin?day='+payload,
        })
        // console.log('response', response)
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
//! lấy thông tin hơp đồng trong dashboard

export const apiDashboardHopDong = () => new Promise(async (resolve, reject) => {
    try {
        const response = await axios({
            method: 'get',
            url: `/api/v2/quanlyhethong/thongkehopdong/dashboardadmin`,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
//! lấy thông tin hơp đồng trong dashboard

export const apiDashboardLuongTongHop = () => new Promise(async (resolve, reject) => {
    try {
        const response = await axios({
            method: 'get',
            url: `/api/v2/quanlyhethong/thongkelistluong/dashboardadmin`,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})

//! lấy lương làm báo cáo thu nhập binh quân
export const apiPayRollNhanVien = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axios({
            method: 'get',
            url: '/api/v2/quanlyhethong/payroll/thongkebaocao?payrollId='+payload.payrollId+'&page='+payload.start+'&limit='+payload.size+'&order='+payload.sorting+'&by='+payload.globalFilter+'&phongBan='+payload.phongBan+'&TrangthaiNVId='+payload.TrangthaiNVId,
        })
        resolve(response)
    } catch (error) {
        reject(error)
    }
})

//! lấy lương làm báo cáo thu nhập binh quân
export const apiPayRollNhanVienCC = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axios({
            method: 'get',
            url: '/api/v2/quanlyhethong/payrollcc/thongkebaocao?payrollId='+payload.payrollId+'&page='+payload.start+'&limit='+payload.size+'&order='+payload.sorting+'&by='+payload.globalFilter,
        })
        resolve(response)
    } catch (error) {
        reject(error)
    }
})

export const apiPostBangcongHistory = (payload) => new Promise(async (resolve, reject) => {
    // console.log('payloadfgfg', payload)
    try {
        const response = await axios({
            method: 'post',
            url: '/api/v2/quanlyhethong/bangchamcong-history/quanlycong',
            data: payload,
        })
       
        resolve(response)
        
    } catch (error) {
        resolve(error)
        reject(error)
    }
})
export const apiGetBangcongHistory = (payload) => new Promise(async (resolve, reject) => {
    // console.log('payloadfgfg', payload)
    try {
        const response = await axios({
            method: 'get',
            url: '/api/v2/quanlyhethong/bangchamcong-history/quanlycong',
            params: payload,
        })
       
        resolve(response)
        
    } catch (error) {
        resolve(error)
        reject(error)
    }
})