import React, { useState, useEffect } from 'react';
import { InputForm, Button } from '~/components/Form';
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import {validateLoaiKiemDinh} from "~/request/validateLoaiKiemDinh";
import Textarea from "~/components/Form/Textarea";
import {apiNewLoaikiemdinhatvsbhld} from "~/services";
import {toast} from "react-toastify";

const ModalLoaikiemdinhatvsbhld = ({ show, setShow, fieldList }) => {
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayload] = useState(fieldList);
    
    useEffect(() => {
        show && setPayload(fieldList);
    }, [show]);
const handleSubmit = async () => {
        let invalids = validate(payload);
        if (invalids === 0) {
            let datapayload = {
                data: [
                    {
                        tenloaikiemdinh: payload.tenloaikiemdinh,
                        maloaikiemdinh: 'LKD-'+(new Date()).getTime(),
                        ghichu: payload.ghichu,
                    },
                ],
            };
            // console.log('check payload', datapayload)
            const response = await apiNewLoaikiemdinhatvsbhld(datapayload)
            // console.log('chek api apiNewLoaikiemdinhatvsbhld', response)
            if (response?.data.success === 200) {
                setShow(false)
                toast.success("Thêm mới thành công!")
            } else {
                toast.error("Thêm mới thất bại!")
            }
        }
    };
console.log('payload', payload)
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateLoaiKiemDinh(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateLoaiKiemDinh(item[0], item[1])]);
                invalids++;
            }
        });
        return invalids;
    };
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">Thêm Mới Loại Kiểm Định</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-12">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Tên loại kiểm định'}
                            value={payload.tenloaikiemdinh}
                            setValue={setPayload}
                            keyPayload={'tenloaikiemdinh'}
                            type="text"
                            validate
                        />
                    </div>
                
                    <div className="form-group col-md-12 mb-2">
                        <Textarea
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ghi chú về loại kiểm định'}
                            value={payload.ghichu}
                            setValue={setPayload}
                            keyPayload={'ghichu'}
                            type="text"
                            validate
                        />
                    </div>
                </div>

                <div className="col-12 mt-5">
                    <Button text="Thêm mới" bClass="btn btn-primary px-5" textColor onClick={handleSubmit} />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalLoaikiemdinhatvsbhld;
