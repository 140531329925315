import actionTypes from '../actions/actionTypes';
const initState = {
    tangca: [],
    msg: '',
    count: 0,
    phieutangca: [],
    countphieutangca: 0,
};

const tangcaReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.GET_LIST_TANG_CA:
            return {
                ...state,
                tangca: action?.tangca?.rows || [],
                msg: 'Ok' || '',
                count: action?.tangca?.count || 0,
            };
        case actionTypes.GET_LIST_PHIEU_TANG_CA:
            return {
                ...state,
                phieutangca: action?.phieutangca?.rows || [],
                msg: 'Ok' || '',
                countphieutangca: action?.phieutangca?.count || 0,
            };
       
        default:
            return state;
    }
};

export default tangcaReducer;
