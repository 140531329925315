import axiosConfig from '../axiosConfig'

export const apiGetBangchamcong = () => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlyhethong/bangchamcong/quanlycong`,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiNewBangchamcong = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlyhethong/bangchamcong/quanlycong',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiEditBangchamcong = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlyhethong/bangchamcong/quanlycong',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiDeleteBangchamcong = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlyhethong/bangchamcong/quanlycong?BangchotcongId='+payload,
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
// lấy thông tin chấm công Theo {{nhanvienId day}}
export const apiThongTinChamCongById = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: '/api/v2/quanlyhethong/cham-cong-theo-ngay-va-nhan-vien/quanlycong?nhanvienId='+payload.nhanvienId+'&day='+payload.day,
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
