import React, { memo } from 'react';

const Textarea = ({
    label,
    AbClass,
    value,
    setValue,
    keyPayload,
    invalidFields,
    setInvalidFields,
    disabled,
    placeholder,
    validate
}) => {
    return (
        <div className={`${AbClass} form-group`}>
            <label htmlFor={keyPayload} className="my-1 fw-bold">
                <span>
                    {label} {validate ? <b className="text-danger">(*)</b> : ''}
                </span>
            </label>
            <textarea
                id={keyPayload}
                className="form-control"
                value={value || ''}
                placeholder={placeholder}
                onChange={(e) => setValue((prev) => ({ ...prev, [keyPayload]: e.target.value }))}
                onFocus={() => setInvalidFields([])}
                disabled={disabled}
            />
            {invalidFields.length > 0 && invalidFields.some((i) => i.name === keyPayload) && (
                <small className="text-danger">{invalidFields.find((i) => i.name === keyPayload)?.message}</small>
            )}
        </div>
    );
};

export default memo(Textarea);
