import React, {useEffect, useMemo, useState} from 'react';
import {
    apiAddBangChamCongTongHopItem,
    apiDeleteBaohiemnhanvien,
    apiDeleteCauhinhthamnien,
    apiDeleteSanluongnhanvien,
    apiGetSanluongnhanvien,
} from '~/services';
import Content from '~/layout/content/Content';
import {Block} from '~/components/block/Block';
import {Button, DataTable, Icon,} from '~/components/Component';
import {Card} from 'reactstrap';

import {toast} from "react-toastify";
import {MaterialReactTable, useMaterialReactTable} from "material-react-table";
import {MRT_Localization_VI} from 'material-react-table/locales/vi';
import {Link, useSearchParams} from "react-router-dom";
import {Box, IconButton, Tooltip} from "@mui/material";
import {ExportToCsv} from "export-to-csv";
import config from "~/config";
import {dataPagination} from "~/utils/Datacustom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {ModalEditSanLuongNV, ModalSanLuongNV} from "~/pages/Admin/Modal";
import Swal from "sweetalert2";


const Sanluongnhanvien = () => {
    const [data, setData] = useState([]);
    const [bangSanLuong, setBangSanLuong] = useState([]);
    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [dataEdit, setDataEdit] = useState([]);
    const [dataEditNV, setDataEditNV] = useState([]);
 
    const [bangSanLuongId, setBangSanLuongId] = useState(0);
    const [totalRowCount, setTotalRowCount] = useState(0);
    
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const fieldList = {
        BangsanluongId: '',
        quantityTarget: '',
        quantityAchieve: '',
        quantityDifference: '',
        deductProduct: '',
        sumProduct: '',
        status: '',
        note: '',
        NhanvienId: '',
    }
    const [searchParams] = useSearchParams();
    useEffect(() => {
        setBangSanLuongId(searchParams.get('bangsanluong'));
    }, [bangSanLuongId]);
    
    useEffect(() => {
        if (bangSanLuongId !== 0) {
            fetchData(Number(bangSanLuongId)).then((r) => 'Error');
        }
    }, [bangSanLuongId, pagination.pageIndex, pagination.pageSize, showEdit,show]);
    
    
    async function fetchData(dataInput) {
        try {
            const datapayloads = {
                typeGet: 2, // 1: lấy theo điều kiện phân trang, 2: lấy tất cả và không cần truyền d liệu phân trang
                start: pagination.pageIndex || 0,
                size: pagination.pageSize || 10,
                globalFilter: 'id',
                sorting: 'desc',
                bangSanLuongId: dataInput,
            };
            
            const response = await apiGetSanluongnhanvien(datapayloads);
            // console.log('response', response)
            const sanLuongNhanVien = response?.data?.data?.rows
            setDataEditNV(
                sanLuongNhanVien?.map((item) => ({
                    value: item?.Nhanvien?.id,
                    label: item?.Nhanvien?.hoten,
                })),
            );
            setTotalRowCount(response?.data?.data?.count);
            setData(sanLuongNhanVien);
            setBangSanLuong(response?.data?.bangsanluong?.rows);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        
    }
    
    const handleEdit = async (data) => {
        setShowEdit(true);
        await setDataEdit(data);
    };
    const handleAddNew = async (data) => {
        setShow(true);
        // await setDataEdit(data);
    };
    
    const handleDelete = async (data) => {
        const {id} = data // TODO: status check xem có thể xóa hay không
        
        Swal.fire({
            title: 'Bạn có chắc không?',
            text: 'Bạn sẽ không thể lấy lại khi xóa!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Không, bỏ qua!',
            confirmButtonText: 'Vâng, xóa nó đi!',
        }).then (async (result) => {
            if (result.isConfirmed) {
                const response = await apiDeleteSanluongnhanvien(data)
                console.log('chek api apiDeleteBaohiemnhanvien', response?.data.success)
                if (response?.data.success === 200) {
                    toast.success('Xóa thành công');
                    fetchData(Number(bangSanLuongId)).then(r => r)
                    
                } else {
                    
                    toast.error('Xóa thất bại');
                }
                
            }
        });
    };
    
    
   
    const Example = () => {
        const dataAb = data;
        const dataExport = dataAb?.map((item, index) => ({
            ...item,
            rowNumber: index + 1,
            hoten: item?.Nhanvien?.hoten,
            PhongbanId: item?.Nhanvien?.Phongban?.phongban,
            ChucvuId: item?.Nhanvien?.Chucvu?.chucvu,
        }));
        const StatusActive = (cell) => ( // Function show trạng thái
            <Box
                component="span"
                sx={(theme) => ({
                    backgroundColor: cell === 1 ? '#1ee0ac' : '#e65100',
                    fontSize: '10px',
                    borderRadius: '0.25rem',
                    color: '#fff',
                    p: '0.25rem',
                })}
            >
                {cell === 1 ? 'Đã duyệt' : 'Chưa duyệt'}
            </Box>
        );
        
        const columns = useMemo(
            () => [
                {
                    Header: 'STT', // Tiêu đề cột số thứ tự
                    header: 'STT', // Tiêu đề cột số thứ tự
                    id: 'rowNumber', // ID của cột
                    Cell: ({row}) => <div>{row.index + 1}</div>, // Giá trị của cột là số thứ tự
                    enableSorting: false,
                    size: 50,
                    sx: {textAlign: 'center', width: '50px'},
                },
                {
                    accessorKey: 'Nhanvien.uuid',
                    header: 'Mã nhân viên',
                    enablePinning: true,
                },
                {
                    accessorKey: 'Nhanvien.hoten',
                    header: 'Họ tên',
                    enablePinning: true,
                },
                {
                    accessorKey: 'Nhanvien.Phongban.phongban',
                    header: 'Họ tên',
                    enablePinning: true,
                },
                {
                    accessorKey: 'quantityTarget',
                    header: 'Sản lượng đề xuất',
                    enablePinning: true,
                },
                {
                    accessorKey: 'quantityAchieve',
                    header: 'Sản lượng đạt được',
                    enablePinning: true,
                },
                {
                    accessorKey: 'quantityDifference',
                    header: 'Chênh lệch',
                    enablePinning: true,
                },
                {
                    accessorKey: 'deductProduct',
                    header: 'Cộng vào/ 1 sản phẩm',
                    enablePinning: true,
                },
                {
                    accessorKey: 'sumProduct',
                    header: 'Trừ đi/ 1 sản phẩm',
                    enablePinning: true,
                },
                {
                    accessorKey: 'status',
                    enablePinning: true,
                    size: 50,
                    header: ' Trạng thái',
                    Cell: ({cell}) => StatusActive(Number(cell?.getValue()) || 0),
                },
            
            ],
            [dataAb],
        );
        const csvOptions = {
            fileName: 'csv-data',
            fieldSeparator: ',',
            filename: Sanluongnhanvien?.tieude,
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            useBom: true,
            useKeysAsHeaders: false,
            headers: columns.map((c) => c.header),
        };
        
        const csvExporter = new ExportToCsv(csvOptions);
        const handleExportData = () => {
            if (dataExport.length === 0) {
                toast.error("Không có dữ liệu để xuất!")
                return;
            }
            csvExporter.generateCsv(dataExport);
        };
        const rowActions = ({row, table}) => ( // Thêm nút sửa xóa vào hàng
            <Box sx={{display: 'flex', gap: '1rem'}}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => handleEdit(row.original)}>
                        <EditIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton color="error" onClick={() => handleDelete({
                        id: row.original.id,
                    })}>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            </Box>
        );
        const topToolbarCustomActions = ({table}) => ( // Thêm nút thêm mới và xuất dữ liệu
            <Box
                sx={{display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap'}}
            >
                
                <Button
                    color="danger"
                    variant="contained"
                >
                    <Link
                        className={`nav-link`}
                        to={`${config.he_thong}${config.cau_hinh_san_luong}`}
                    >
                        <span className="fs-10px me-2"><Icon className="ni ni-back-arrow-fill"></Icon></span>Quay
                        lại
                    </Link>
                </Button>
                
                <Button variant="contained" color="primary" onClick={handleAddNew} sx={{mr: 2}}>
                    + Thêm mới
                </Button>
                <Button
                    color="primary"
                    onClick={handleExportData}
                    variant="contained"
                >
                    <Icon name="download-cloud me-2"></Icon> Xuất dữ liệu
                </Button>
            
            
            </Box>
        );
        
        const state = { // Trạng thái của bảng
            expanded: true,
            columnFilters,
            globalFilter,
            isLoading: dataAb?.length === 0,
            pagination,
            sorting,
        };
        const displayColumnDefOptions = { // Các cột hiển thị
            'mrt-row-actions': {
                sx: {width: '200px', paddingRight: '10px'},
                header: 'Thao tác',
                enableOrdering: true,
                enablePinning: true,
                enableColumnActions: true,
            }
        };
        const initialState = { // Các trạng thái ban đầu của bảng đc gim
            columnPinning: {left: ['rowNumber', 'name'], right: ['mrt-row-actions']},
            density: 'compact'
        };
        
        const table = useMaterialReactTable({
                columns,
                data,
                enableColumnResizing: false,
                enableDensityToggle: false,
                initialState: initialState,
                displayColumnDefOptions: displayColumnDefOptions,
                state: state,
                defaultDisplayColumn: {enableResizing: true},
                rowCount: totalRowCount,
                manualFiltering: true,
                manualPagination: true,
                manualSorting: true,
                onPaginationChange: setPagination,
                onColumnFiltersChange: setColumnFilters,
                onGlobalFilterChange: setGlobalFilter,
                onSortingChange: setSorting,
                localization: MRT_Localization_VI,
                enableRowActions: true,
                enableColumnPinning: true,
                renderTopToolbarCustomActions: topToolbarCustomActions,
                renderRowActions: rowActions,
                muiPaginationProps: {
                    rowsPerPageOptions: dataPagination,
                },
            }
        );
        
        
        return (
            <MaterialReactTable
                table={table}
            />
        );
    };
    
    
    return (
        <React.Fragment>
            {/*<Head title="User List - Regular"></Head>*/}
            <Content>
                <Card className="mt-0">
                    <div className="card-aside-wrap row">
                        <div className="card-inner card-inner-lg px-4 pt-1 pb-0">
                            <h4 className="mt-2 ms-2"> {bangSanLuong[0]?.name}</h4>
                        
                        </div>
                    
                    </div>
                </Card>
                <Block>
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner position-relative card-tools-toggle">
                            <Example/>
                        </div>
                    </DataTable>
                </Block>
                <ModalSanLuongNV
                    show={show}
                    fieldList={fieldList}
                    setShow={() => setShow(false)}
                    dataEdit={dataEditNV}
                    idCauHinh={bangSanLuong[0]?.id}
                />
                <ModalEditSanLuongNV
                    show={showEdit}
                    fieldList={fieldList}
                    setShow={() => setShowEdit(false)}
                    dataEdit={dataEdit}
                />
            </Content>
        </React.Fragment>
    );
};

export default Sanluongnhanvien;
