import axiosConfig from '../axiosConfig'

export const apiGetUngvien = () => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlyhethong/ungvien`,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiNewUngvien = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlyhethong/ungvien',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiEditUngvien = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlyhethong/ungvien',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiDeleteUngvien = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlyhethong/ungvien',
            data: {
                id:payload.id
            }
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
