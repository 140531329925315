import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import './ModalNhanVien.scss';
import {Modal, ModalBody, Spinner} from 'reactstrap';
import {apiNhanvienByCondition} from '~/services';
import Select from 'react-select';
import {Button, Icon} from '~/components/Component';

const ModalSelectFinNhanvienfix = ({
                                    showSelect,
                                    setShowSelect,
                                    listNhanvien,
                                    setlistNhanvien,
                                    typeSelect,
                                    typeSetting,
                                    cauHinhId
                                }) => {
    
    const [data, setData] = useState([]);
    const [dataAll, setDataAll] = useState([]);
    // const [dataCkb, setDataCkb] = useState([]);
    const [page, setPage] = useState(0);
    const [scrollH, setscrollH] = useState(136);
    const [countChk, setcountChk] = useState(1);
    const [payload, setPayload] = useState({ChinhanhId: '', PhongbanId: ''});
    const [finAll, setFinAll] = useState(true);

    // console.log('typeSetting',typeSetting)
    // console.log('scrollH',scrollH)
    // console.log('page',page)
    // console.log('showSelect',showSelect)
    console.log('data',data)
    // console.log('dataCkb',dataCkb)
    
    useEffect(() => {
        if (showSelect === true) {
            setPayload({ChinhanhId: { label: 'Chọn chi nhánh', value: '' }, PhongbanId: { label: 'Chọn phòng ban', value: '' }});
            // setDataCkb([]);
        }
        showSelect && data.length === 0 && fetchData(page, scrollH).then(r => 'err');
        showSelect === false && setData([]); setDataAll([]); setPage(0); setFinAll(true); setscrollH(136);
    }, [showSelect]);
    useEffect(() => {
        page > 0 && finAll === true && fetchData(page, scrollH).then(r => 'err');
    }, [page]);
    
    
    async function fetchData(page, scrollH) {
        try {
            const datapayloads = {
                page: page,
                limit: 10,
                order: 'desc',
                by: 'id',
                typeGet: 1, 
                chucvu: '',
                phongban: '',
            };
            const nhanvien = await apiNhanvienByCondition(datapayloads);

            let nhanVienActive = [];
            if(listNhanvien && listNhanvien.length > 0){


                let nhanViendata = nhanvien?.data?.data?.rows;
                let result = [];
                if (nhanViendata) {
                  nhanViendata.forEach((i) => {
                    let index = listNhanvien.findIndex((item) => item.value === i.id);
                    let nhanvien = { ...i };
                    nhanvien.checked = index === -1 ? false : true; 
                    result.push(nhanvien);
                  });
                }
                nhanVienActive = result;

            }else if(listNhanvien === undefined){
                nhanVienActive = nhanvien?.data?.data?.rows
            }
            setcountChk(nhanvien.data.data.count);
            if (nhanVienActive.length > 0) {
                if (page >= 2) {
                    setscrollH(scrollH + 421);
                }
                setData((prevPage) => [...prevPage, ...nhanVienActive]);
                setDataAll((prevPage) => [...prevPage, ...nhanVienActive]);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    useEffect(() => { 
        // cái này áp dụng cho phần sửa nếu như mà trong tổng dataAll không
        // có nhân viên đã chọn thì gọi lên server lấy data cho đến bao giờ đủ hết thì thôi
        if(listNhanvien && listNhanvien.length > 0 && dataAll.length > 0){
            let test = 0;
            listNhanvien.map((i) => {
                let isFound = dataAll.find((item) => item.id === i.value) !== undefined;
               if(isFound === false){
                test =+ 1;
               }
            });
            if(test != 0){
                setPage(page + 1);
            }
        }
    }, [dataAll]);

    const {phongban} = useSelector((state) => state.phongban);
    const {chinhanh} = useSelector((state) => state.chinhanh);
    const [listPhongban, setlistPhongban] = useState([]);
    const [listChiNhanh, setlistChiNhanh] = useState([]);
    console.log('payload', payload)

    useEffect(() => {
        if (phongban && chinhanh) {
            // let dataSelectPhongban = phongban.map((item) => ({label: item.phongban, value: item.id}));
            let dataSelectPhongban = [
                { label: 'Chọn phòng ban', value: '' },
                ...phongban.map((item) => ({label: item.phongban, value: item.id}))
            ];
            let dataSelectChinhanh = [
                { label: 'Chọn chi nhánh', value: '' },
                ...chinhanh.map((item) => ({ label: item.diachi, value: item.id }))
            ];
            setlistChiNhanh(dataSelectChinhanh);
            setlistPhongban(dataSelectPhongban);
        }
    }, [phongban, chinhanh]);
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState('');
const finAllData = async () => {
if(finAll === true){
    const datapayloads = {
        order: 'desc',
        by: 'id',
        typeGet: 0, 
    };
    const nhanvienAll = await apiNhanvienByCondition(datapayloads);
    // console.log('aaaaaaaaaaaaaa', nhanvienAll)
    let nvdata = nhanvienAll?.data?.data?.rows;
    let redataall = [];
    nvdata.length > 0 && nvdata.forEach((i) => {
        let index = dataAll.length > 0 && dataAll.find((item) => item.id === i.id && item.checked === true);
        let nhanvien = { ...i };
        nhanvien.checked = index != undefined ? true : false; 
        redataall.push(nhanvien);
      });
    //   console.log('redataall', redataall)
      setData(redataall);
      setDataAll(redataall);
    setFinAll(false);
}
   
}
    useEffect(() => {
        if (payload.ChinhanhId.value !== '' && payload.PhongbanId.value !== '') {
            const filteredObject = dataAll.filter((item) => {
                return (item.ChinhanhId === payload.ChinhanhId.value && item.PhongbanId === payload.PhongbanId.value);
            });
            setData([...filteredObject]);
        }else if (payload.ChinhanhId.value !== '' && payload.PhongbanId.value === '') {
            const filteredObject = dataAll.filter((item) => {
                return (item.ChinhanhId === payload.ChinhanhId.value);
            });
            setData([...filteredObject]);
        }else if (payload.PhongbanId.value !== '' && payload.ChinhanhId.value === '') {
            const filteredObject = dataAll.filter((item) => {
                return (item.PhongbanId === payload.PhongbanId.value);
            });
            setData([...filteredObject]);
        }
        if (onSearchText !== '') {
            const filteredObject = dataAll.filter((item) => {
                return (
                    item.hoten.toLowerCase().includes(onSearchText.toLowerCase()) ||
                    item.uuid.toLowerCase().includes(onSearchText.toLowerCase())
                );
            });
            setData([...filteredObject]);
        } 

        if (payload?.ChinhanhId?.value === '' && payload.PhongbanId.value === '' && onSearchText === '') {
            setData([...dataAll]);
        }
    }, [onSearchText, setData, dataAll, payload ]);
    
    const onFilterChange = (e) => {
        setSearchText(e.target.value);
        setPayload({ChinhanhId: { label: 'Chọn chi nhánh', value: '' }, PhongbanId: { label: 'Chọn phòng ban', value: '' }});
    };
    const toggle = () => setonSearch(!onSearch);
    
    const handleScroll = (event) => {
        let scrollHeight = event.currentTarget.scrollTop;
        // console.log('countChk', countChk)
        // console.log('scrollHeight', scrollHeight)
        // console.log('scrollH', scrollH)
        // console.log('dataAll', dataAll.length)
        if (scrollHeight > scrollH && dataAll.length < countChk) {
            let modtmp = page + 1;
            setPage(modtmp);
        }
    };
    // console.log('page', page)
    const onSelectChangeSL = (e, id) => {
        const {checked} = e.currentTarget;
        let index = data.findIndex((item) => item.id === id);
        let nhanvien = data[index];
        nhanvien.checked = checked;
        let nhanvienall = dataAll[index];
        nhanvienall.checked = checked;
        
    };
    const onSelectChangeSV = (e, id) => {
        data.forEach(item => {
            item.checked = item.id === id;
          });
        dataAll.forEach(item => {
            item.checked = item.id === id;
          });
    };
    const selectorCheckSL = (e) => {
        const {checked} = e.currentTarget;
        let allcheck = dataAll.map((item) => ({ ...item, checked }));
        setData(allcheck);
        setDataAll(allcheck);
    };
    const handleSubmit = () => {
       let retun =  dataAll.filter((item) => item.checked === true).map((item) => (
            { label: item.hoten,
            value: item.id,
            // PhongbanId: item.PhongbanId || 0,
            // email: item?.email || '',
            Quanlynghiphepcongtacnvs: item?.Quanlynghiphepcongtacnvs || [], 
            PhongbanId: item?.PhongbanId,
            Phongban: item?.Phongban,
            ChucvuId: item?.ChucvuId,
            Chucvu: item?.Chucvu,
            ChinhanhId: item?.ChinhanhId,
            Chinhanh: item?.Chinhanh,
            email: item?.email,
            ngayvaolamviec: item?.ngayvaolamviec,
            }
        ));
        setlistNhanvien(retun);
        setData([]);
        
        // setDataCkb([]);
        setShowSelect(false);
    };
    
    return (
        <React.Fragment>
            <Modal isOpen={showSelect} toggle={setShowSelect} size="lg">
                <ModalBody>
                    <a
                        href="#dropdownitem"
                        onClick={(ev) => {
                            ev.preventDefault();
                            setShowSelect(false);
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="container">
                        <div className="position-relative card-tools-toggle px-0 py-2">
                            <div className="card-title-group">
                                <div className="card-title">
                                    <h5 className="title">Danh sách nhân viên</h5>
                                </div>
                            </div>
                            <div className="fin" onClick={finAllData}>
                            <div className="card-title-group mt-3">
                                <div className="search-content">
                                    <input
                                        type="text"
                                        className=" form-control"
                                        placeholder="Tìm kiếm nhân viên"
                                        value={onSearchText}
                                        onChange={(e) => onFilterChange(e)}
                                    />
                                    <Button className="search-submit btn-icon px-2">
                                        <Icon name="search"></Icon>
                                    </Button>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div
                                    className="form-group col-md-6"
                                    onClick={() => {
                                        setSearchText('');
                                        toggle();
                                    }}
                                >
                                    <label htmlFor="inputZip" className="mb-2 fw-bold">
                                        <span>Chi nhánh</span>
                                    </label>
                                    <Select
                                        value={payload.ChinhanhId}
                                        onChange={(selected) => {
                                            setPayload((prev) => ({
                                                ...prev,
                                                ChinhanhId: selected,
                                            }));
                                        }}
                                        options={listChiNhanh}
                                        placeholder={'Chọn chi nhánh'}
                                        name="ChinhanhId"
                                        // onFocus={() => setInvalidFields([])}
                                    />
                                </div>
                                <div
                                    className="form-group col-md-6"
                                    onClick={() => {
                                        setSearchText('');
                                        toggle();
                                    }}
                                >
                                    <label htmlFor="inputZip" className="mb-2 fw-bold">
                                        <span>Phòng ban</span>
                                    </label>
                                    <Select
                                        value={payload.PhongbanId}
                                        onChange={(selected) => {
                                            setPayload((prev) => ({
                                                ...prev,
                                                PhongbanId: selected,
                                            }));
                                        }}
                                        options={listPhongban}
                                        placeholder={'Chọn phòng ban'}
                                        name="PhongbanId"
                                        // onFocus={() => setInvalidFields([])}
                                    />
                                </div>
                            </div>
                            </div>
                            <div className="card-title-group mt-2">
                                <span
                                    className="text-white ms-0 px-5 py-1 badge justify-content-center px-0 btn btn-primary"
                                    onClick={handleSubmit}
                                >
                                    <em className="icon ni ni-plus pe-2"></em>Chọn nhân viên
                                </span>
                            </div>
                        </div>
                        <table className="table table-fixed">
                            <thead className="d-block">
                            <tr className="d-flex">
                                <th className="col-1 border-bottom-0">#</th>
                                <th className="col-1 border-bottom-0">
                                    <div className="custom-control custom-control-sm custom-checkbox notext">
                                        {typeSelect === true ? (
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    onChange={(e) => selectorCheckSL(e)}
                                                    id="uidd"
                                                />
                                                <label className="custom-control-label" htmlFor="uidd"></label>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </th>
                                <th className="col-4 border-bottom-0">
                                    <span className="sub-text fw-bold text-info text-uppercase">TÊN NHÂN VIÊN</span>
                                </th>
                                <th className="col-3 border-bottom-0">
                                    <span className="sub-text fw-bold text-info text-uppercase">MÃ NHÂN VIÊN</span>
                                </th>
                                <th className="col-3 border-bottom-0">
                                    <span className="sub-text fw-bold text-info text-uppercase">SỐ ĐIỆN THOẠI</span>
                                </th>
                            </tr>
                            </thead>
                            <tbody
                                onScroll={handleScroll}
                                style={{height: '300px', overflow: 'scroll', display: 'block'}}
                            >
                            {data?.length > 0 ? (
                                data?.map((item, index) => {
                                    return (
                                        <tr className="d-flex" key={index}>
                                            <td className="col-1">{index + 1}</td>
                                            <td className="col-1">
                                                <div
                                                    className="custom-control custom-control-sm custom-checkbox notext">
                                                    {typeSelect ? (
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            defaultChecked={item.checked}
                                                            name="name"
                                                            id={item.id + 'uiddd'}
                                                            key={Math.random()}
                                                            onChange={(e) => onSelectChangeSL(e, item.id)}
                                                        />
                                                    ) : (
                                                        <input
                                                            type="radio"
                                                            className="custom-control-input"
                                                            defaultChecked={item.checked ? item.checked : false}
                                                            name="name"
                                                            id={item.id + 'uiddd'}
                                                            // key={Math.random()}
                                                            onChange={(e) => onSelectChangeSV(e, item.id)}
                                                        />
                                                    )}
                                                    
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor={item.id + 'uiddd'}
                                                    ></label>
                                                </div>
                                            </td>
                                            <td className="col-4">
                                                <span className="tb-amount"> {item?.hoten} </span>
                                            </td>
                                            <td className="col-3">
                                                <span className="tb-amount"> {item?.uuid} </span>
                                            </td>
                                            <td className="col-3">
                                                    <span className="tb-amount">
                                                        {' '}
                                                        {item?.Thongtinnhanvien?.dienthoai}{' '}
                                                    </span>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <div className="text-center">
                                    <div>
                                        <Spinner color="primary"/>
                                    </div>
                                    <span className="text-silent">Chưa có dữ liệu!</span>
                                </div>
                            )}
                            </tbody>
                        </table>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default ModalSelectFinNhanvienfix;
