import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {InputForm, Button} from '~/components/Form'
import * as actions from '~/store/actions'
import {dataSelectPhuongthuc, dataSelectStatus} from '~/utils/Datacustom'
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';

const ModalEditQuydinhthuongphat = ({show, setShow, dataIdQuydinhthuongphat, feildList}) => {
    const {kieuluong} = useSelector(state => state.kieuluong)
    const [listPhuongthuc, setlistPhuongthuc] = useState([])
    // const { phongban } = useSelector(state => state.phongban)
    // console.log(phongban)
    const dispatch = useDispatch()
    const [invalidFields, setInvalidFields] = useState([])
    const [payload, setPayloadQuydinhthuongphat] = useState(feildList)
    useEffect(() => {
        let KieuluongId = kieuluong?.find(item => {
            return item && item.id === dataIdQuydinhthuongphat?.Kieuluong?.id
        })
        let phuongthucId = dataSelectPhuongthuc?.find(item => {
            return item && item.value === dataIdQuydinhthuongphat?.phuongthuc
        })
        show && dataIdQuydinhthuongphat && setPayloadQuydinhthuongphat({
            KieuluongId: {label: KieuluongId?.tieude, value: KieuluongId?.id},
            tieude: dataIdQuydinhthuongphat?.tieude,
            mota: dataIdQuydinhthuongphat?.mota,
            giatri: dataIdQuydinhthuongphat?.giatri,
            phuongthuc: {label: phuongthucId?.label, value: phuongthucId?.value},

        })
    }, [show, dataIdQuydinhthuongphat])
    
    const [listKieuluong, setlistKieuluong] = useState([])
    useEffect(() => {
        if (kieuluong) {
            let dataSelectKieuluong = kieuluong.map((item) => ({ label: item.tieude, value: item.id }));
            setlistKieuluong(dataSelectKieuluong)
            setlistPhuongthuc(dataSelectPhuongthuc)
        }
    }, [kieuluong])
    const handleSubmit = async () => {
        let invalids = validate(payload)
        
        if (invalids === 0) {
            let datapayload = {
                id: dataIdQuydinhthuongphat?.id,
                KieuluongId: payload.KieuluongId.value,
                tieude: payload.tieude,
                mota: payload.mota,
                giatri: parseFloat(payload.giatri),
                phuongthuc: payload.phuongthuc.value,
                level: 1,
                sublevel: '',
                parent: 2,
            }
            // console.log('check payload', datapayload)
            dispatch(actions.EditQuydinhthuongphat(datapayload))
        }
    }
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayloadQuydinhthuongphat(prev => ({...prev, [statename]: selected}))
    }
    const validate = (payload) => {
        let invalids = 0
        let fields = Object.entries(payload)
        fields.forEach(item => {
            if (item[1] === '') {
                setInvalidFields(prev => [...prev, {
                    name: item[0],
                    message: 'Bạn không được bỏ trống trường này.'
                }])
                invalids++
            }
        })
        
        return invalids
    }
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Thêm mới quy định thưởng phạt
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Giá trị: (3000000 PT +, 20 PT %, 1000000 PT =, 3000000 PT /)'}
                            value={payload.giatri}
                            setValue={setPayloadQuydinhthuongphat}
                            keyPayload={'giatri'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-6 ">
                        <label htmlFor="inputZip" className="mb-2">Phương thức</label>
                        <Select
                            value={payload.phuongthuc}
                            onChange={handleOnSelect}
                            options={listPhuongthuc}
                            placeholder={'Chọn phương thức'}
                            name='phuongthuc'
                            onFocus={() => setInvalidFields([])}
                        />
                        {invalidFields.length > 0 && invalidFields.some(i => i.name === 'phuongthuc') && <small
                            className='text-danger'>{invalidFields.find(i => i.name === 'phuongthuc')?.message}</small>}
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Tiêu đề'}
                            value={payload.tieude}
                            setValue={setPayloadQuydinhthuongphat}
                            keyPayload={'tieude'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-6 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Mô tả'}
                            value={payload.mota}
                            setValue={setPayloadQuydinhthuongphat}
                            keyPayload={'mota'}
                            type='text'
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-md-6">
                        <label htmlFor="inputZip" className="mb-2">Kiểu lương</label>
                        <Select
                            value={payload.KieuluongId}
                            onChange={handleOnSelect}
                            options={listKieuluong}
                            placeholder={'Chọn kiểu lương'}
                            name='KieuluongId'
                            onFocus={() => setInvalidFields([])}
                        />
                        {invalidFields.length > 0 && invalidFields.some(i => i.name === 'KieuluongId') && <small
                            className='text-danger'>{invalidFields.find(i => i.name === 'KieuluongId')?.message}</small>}
                    
                    </div>
                    {/* <div className="form-group col-md-6 ">
            <InputForm
              setInvalidFields={setInvalidFields}
              invalidFields={invalidFields}
              label={'Level'}
              value={payload.level}
              setValue={setPayloadQuydinhthuongphat}
              keyPayload={'level'}
              type='text'
            />
          </div> */}
                </div>
                
                <div className='col-12 mt-5'>
                    <Button
                        text='Thêm chức vụ'
                        bClass='btn btn-primary px-5'
                        textColor
                        onClick={handleSubmit}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ModalEditQuydinhthuongphat;

