import React, { useState, useEffect } from 'react';
import { ModalCauhinhcalamviec, ModalEditCauhinhcalamviec } from './Modal';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { Card, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, UncontrolledDropdown } from 'reactstrap';
import Head from '~/layout/head/Head';
import Content from '~/layout/content/Content';
import { Block } from '~/components/block/Block';
import {
    Button,
    Col,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    Icon,
    PaginationComponent,
    Row,
    RSelect,
    TooltipComponent,
    PermissionUtils,
    ExportXlsx,
} from '~/components/Component';
import * as actions from '~/store/actions';
import { MenuPhanca } from './MenuTab/Menucontent';

const Cauhinhcalamviec = () => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [showEditCauHinhCaLamViec, setShowEditCauHinhCaLamViec] = useState(false);
    const { cauhinhcalamviec } = useSelector((state) => state.cauhinhcalamviec);
    const [data, setData] = useState([]);

    const fieldList = {
        tieude: '',
        motacalamviec: '',
        macalamviec: '',
        giovaolamviec: '',
        gioketthuclamviec: '',
        solanchuyenngaytunhien: '',
        giobatdauantrua: '',
        gioketthucantrua: '',
        tonggio: '',
        demcong: '',
        isShift: '',
        batdaugiovaodehieuca: '',
        ketthucgiovaodehieuca: '',
        batdaugioradehieuca: '',
        ketthucgioradehieuca: '',
        khongcogiorathitinhtong: '',
        khongcogiovaothitinhtong: '',
        // vitritrenbaobieu: '',
        phatdimuon: 0,
        phatvesom: 0,
        dataphatdimuon: '',
        dataphatvesom: '',
        loaica: 0,
    };

    useEffect(() => {
        if (cauhinhcalamviec) {
            setData([...cauhinhcalamviec]);
        }
    }, [cauhinhcalamviec]);
    const [showPhanquyen, setShowPhanquyen] = useState({});
    let dataPermissionUtils = PermissionUtils('cauhinhcalamviec', 'all');
    useEffect(() => {
        if (dataPermissionUtils) {
            setShowPhanquyen(dataPermissionUtils);
        }
    }, [dataPermissionUtils]);
    const namefile = 'Thông tin cấu hình ca làm việc';
    const header = [
        'Stt',
        'Ca làm việc',
        'Mã ca',
        'Thời gian làm việc',
        'Thời gian Checkin',
        'Thời gian Checkout',
        'Giờ ăn trưa',
        'Không Checkin vào',
        'Không Checkin ra',
        'Vị trí báo biểu',
        'Tổng giờ(Quy đổi ra phút)',
        'Đếm công',
    ];
    const [columns, setColumns] = useState(() => {
        const d = header.map((item, index) => index <= 6 || index >= 11);
        return d;
    });
    const [dataFileMau, setDataFileMau] = useState([]);
    useEffect(() => {
        if (data.length > 0) {
            const datafilemau = data.map((item, index) => {
                return {
                    ...(columns[0] && { Stt: index + 1 }),
                    ...(columns[1] && { 'Ca làm việc': item?.tieude }),
                    ...(columns[2] && { 'Mã ca': item?.macalamviec }),
                    ...(columns[3] && { 'Thời gian làm việc': item?.giovaolamviec + '-' + item?.gioketthuclamviec }),
                    ...(columns[4] && {
                        'Thời gian Checkin': item?.batdaugiovaodehieuca + '-' + item?.ketthucgiovaodehieuca,
                    }),
                    ...(columns[5] && {
                        'Thời gian Checkout': item?.batdaugioradehieuca + '-' + item?.ketthucgioradehieuca,
                    }),
                    ...(columns[6] && { 'Giờ ăn trưa': item?.giobatdauantrua + '-' + item?.gioketthucantrua }),
                    ...(columns[7] && { 'Không Checkin vào': item?.khongcogiorathitinhtong }),
                    ...(columns[8] && { 'Không Checkin ra': item?.khongcogiovaothitinhtong }),
                    ...(columns[9] && { 'Vị trí báo biểu': item?.vitritrenbaobieu }),
                    ...(columns[10] && { 'Tổng giờ': item?.tonggio }),
                    ...(columns[11] && { 'Đếm công': item?.demcong }),
                };
            });
            setDataFileMau(datafilemau);
        }
    }, [data, columns]);

    const [sm, updateSm] = useState(false);
    const [tablesm, updateTableSm] = useState(false);
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [sort, setSortState] = useState('');
    const onFilterChange = (e) => {
        setSearchText(e.target.value);
    };
    const toggle = () => setonSearch(!onSearch);
    const selectorCheck = (e) => {
        let newData;
        newData = data.map((item) => {
            item.checked = e.currentTarget.checked;
            return item;
        });
        setData([...newData]);
    };
    const onSelectChange = (e, id) => {
        let newData = data;
        let index = newData.findIndex((item) => item.id === id);
        newData[index].checked = e.currentTarget.checked;
        setData([...newData]);
    };
    useEffect(() => {
        if (onSearchText !== '') {
            const filteredObject = cauhinhcalamviec.filter((item) => {
                return (
                    item.tieude.toLowerCase().includes(onSearchText.toLowerCase())
                );
            });
            setData([...filteredObject]);
        } else {
            setData([...cauhinhcalamviec]);
        }
    }, [onSearchText, setData]);
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const [dataIdCauHinhCaLamViec, setDataIdCauHinhCaLamViec] = useState([]);
    const handleNewCauHinhCaLamViec = async () => {
        setShow(true);
    };
    const handleEditCauHinhCaLamViec = async (data) => {
        setShowEditCauHinhCaLamViec(true);
        await setDataIdCauHinhCaLamViec(data);
    };
    const handleDeleteCauHinhCaLamViec = async (data) => {
        Swal.fire({
            title: 'Bạn có chắc không?',
            text: 'Bạn sẽ không thể lấy lại khi xóa!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Không, bỏ qua!',
            confirmButtonText: 'Vâng, xóa nó đi!',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(actions.DeleteCauhinhcalamviec(data));
            }
        });
    };
    useEffect(() => {
        (cauhinhcalamviec && setShow(false)) || setShowEditCauHinhCaLamViec(false);
    }, [cauhinhcalamviec]);
    // set state để mở đóng Modal
    const itemsPerPageOptions = [10, 20, 30, 40, 50, 100, 300, 500];
    return (
        <React.Fragment>
            {/*<Head title="Cấu hình ca làm việc, Cấu hình tăng ca"></Head>*/}
            <Content>
                <Card className="mt-0">
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner card-inner-lg px-4 mt-3">
                            <h4>Quản lý phân ca làm việc</h4>
                            <MenuPhanca />
                        </div>
                    </DataTable>
                </Card>
                <Block>
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner position-relative card-tools-toggle pt-0">
                            <div className="card-title-group">
                                <div className="card-title">
                                    <h5 className="title">Ca làm việc</h5>
                                    {+showPhanquyen?.them === 1 && (
                                        <Button
                                            color="primary"
                                            className="btn-icon px-4 mt-2"
                                            onClick={handleNewCauHinhCaLamViec}
                                        >
                                            <Icon name="plus"></Icon> Thêm mới
                                        </Button>
                                    )}
                                </div>
                                <div className="card-tools me-n1">
                                    <ul className="btn-toolbar gx-1">
                                        <li>
                                            <a
                                                href="#search"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    toggle();
                                                }}
                                                className="btn btn-icon search-toggle toggle-search"
                                            >
                                                <Icon name="search"></Icon>
                                            </a>
                                        </li>
                                        <li className="btn-toolbar-sep"></li>
                                        <li>
                                            <div className="toggle-wrap">
                                                <Button
                                                    className={`btn-icon btn-trigger toggle ${tablesm ? 'active' : ''}`}
                                                    onClick={() => updateTableSm(true)}
                                                >
                                                    <Icon name="menu-right"></Icon>
                                                </Button>
                                                <div className={`toggle-content ${tablesm ? 'content-active' : ''}`}>
                                                    <ul className="btn-toolbar gx-1">
                                                        <li className="toggle-close">
                                                            <Button
                                                                className="btn-icon btn-trigger toggle"
                                                                onClick={() => updateTableSm(false)}
                                                            >
                                                                <Icon name="arrow-left"></Icon>
                                                            </Button>
                                                        </li>
                                                        {/* <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle
                                                                    tag="a"
                                                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                                                >
                                                                    <div className="dot dot-primary"></div>
                                                                    <Icon name="filter-alt"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu
                                                                    end
                                                                    className="filter-wg dropdown-menu-xl"
                                                                    style={{ overflow: 'visible' }}
                                                                >
                                                                    <div className="dropdown-head">
                                                                        <span className="sub-title dropdown-title">
                                                                            Bộ lọc
                                                                        </span>
                                                                        <div className="dropdown">
                                                                            <a
                                                                                href="#more"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                }}
                                                                                className="btn btn-sm btn-icon"
                                                                            >
                                                                                <Icon name="more-h"></Icon>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    <div className="dropdown-body dropdown-body-rg">
                                                                        <Row className="gx-6 gy-3">
                                                                            <Col size="6">
                                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="custom-control-input"
                                                                                        id="hasBalance"
                                                                                    />
                                                                                    <label
                                                                                        className="custom-control-label"
                                                                                        htmlFor="hasBalance"
                                                                                    >
                                                                                        {' '}
                                                                                        Have Balance
                                                                                    </label>
                                                                                </div>
                                                                            </Col>
                                                                            <Col size="6">
                                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="custom-control-input"
                                                                                        id="hasKYC"
                                                                                    />
                                                                                    <label
                                                                                        className="custom-control-label"
                                                                                        htmlFor="hasKYC"
                                                                                    >
                                                                                        {' '}
                                                                                        KYC Verified
                                                                                    </label>
                                                                                </div>
                                                                            </Col>
                                                                            <Col size="6">
                                                                                <div className="form-group">
                                                                                    <label className="overline-title overline-title-alt">
                                                                                        Role
                                                                                    </label>
                                                                                    <RSelect placeholder="Any Role" />
                                                                                </div>
                                                                            </Col>
                                                                            <Col size="6">
                                                                                <div className="form-group">
                                                                                    <label className="overline-title overline-title-alt">
                                                                                        Status
                                                                                    </label>
                                                                                    <RSelect placeholder="Any Status" />
                                                                                </div>
                                                                            </Col>
                                                                            <Col size="12">
                                                                                <div className="form-group">
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-secondary"
                                                                                    >
                                                                                        Filter
                                                                                    </button>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                    <div className="dropdown-foot between">
                                                                        <a
                                                                            href="#reset"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();
                                                                            }}
                                                                            className="clickable"
                                                                        >
                                                                            Cập nhật bộ lọc 
                                                                        </a>
                                                                        <a
                                                                            href="#save"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();
                                                                            }}
                                                                        >
                                                                            Lưu bộ lọc 
                                                                        </a>
                                                                    </div>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li> */}
                                                        <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle
                                                                    color="tranparent"
                                                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                                                >
                                                                    <Icon name="setting"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu end className="dropdown-menu-xs">
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Show</span>
                                                                        </li>
                                                                        {itemsPerPageOptions.map((item, index) => {
                                                                            return (
                                                                                <li
                                                                                    className={
                                                                                        itemPerPage === item
                                                                                            ? 'active'
                                                                                            : ''
                                                                                    }
                                                                                    key={index}
                                                                                >
                                                                                    <DropdownItem
                                                                                        tag="a"
                                                                                        href="#dropdownitem"
                                                                                        onClick={(ev) => {
                                                                                            ev.preventDefault();
                                                                                            setItemPerPage(item);
                                                                                            // handleReParamsClick();
                                                                                        }}
                                                                                    >
                                                                                        {item}
                                                                                    </DropdownItem>
                                                                                </li>
                                                                            );
                                                                        })}
                                                                    </ul>
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Order</span>
                                                                        </li>
                                                                        <li className={sort === 'dsc' ? 'active' : ''}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setSortState('dsc');
                                                                                    //   sortFunc("dsc");
                                                                                }}
                                                                            >
                                                                                DESC
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li className={sort === 'asc' ? 'active' : ''}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setSortState('asc');
                                                                                    //   sortFunc("asc");
                                                                                }}
                                                                            >
                                                                                ASC
                                                                            </DropdownItem>
                                                                        </li>
                                                                    </ul>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                        <li>
                                                            <div className=" position-relative card-tools-toggle">
                                                                <div className="toggle-wrap nk-block-tools-toggle">
                                                                    <Button
                                                                        className={`btn-icon btn-trigger toggle-expand me-n1 ${
                                                                            sm ? 'active' : ''
                                                                        }`}
                                                                        onClick={() => updateSm(!sm)}
                                                                    >
                                                                        <Icon name="menu-alt-r"></Icon>
                                                                    </Button>
                                                                    <div
                                                                        className="toggle-expand-content"
                                                                        style={{ display: sm ? 'block' : 'none' }}
                                                                    >
                                                                        <ul className="nk-block-tools g-3">
                                                                            <li>
                                                                                <ExportXlsx
                                                                                    dataFileMau={dataFileMau}
                                                                                    title={header}
                                                                                    namefile={namefile}
                                                                                    btnname={'Xuất dữ liệu'}
                                                                                />
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className={`card-search search-wrap ${!onSearch && 'active'}`}>
                                <div className="card-body">
                                    <div className="search-content">
                                        <Button
                                            className="search-back btn-icon toggle-search active"
                                            onClick={() => {
                                                setSearchText('');
                                                toggle();
                                            }}
                                        >
                                            <Icon name="arrow-left"></Icon>
                                        </Button>
                                        <input
                                            type="text"
                                            className="border-transparent form-focus-none form-control"
                                            placeholder="Tìm kiếm"
                                            value={onSearchText}
                                            onChange={(e) => onFilterChange(e)}
                                        />
                                        <Button className="search-submit btn-icon">
                                            <Icon name="search"></Icon>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ overflowY: 'hidden' }}>
                        <DataTableBody>
                            <DataTableHead>
                                {columns[0] && (
                                    <DataTableRow>
                                        <span className="sub-text">Stt</span>
                                    </DataTableRow>
                                )}
                                <DataTableRow className="nk-tb-col-check">
                                    <div className="custom-control custom-control-sm custom-checkbox notext">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            onChange={(e) => selectorCheck(e)}
                                            id="uid"
                                        />
                                        <label className="custom-control-label" htmlFor="uid"></label>
                                    </div>
                                </DataTableRow>
                                {columns[1] && (
                                    <DataTableRow>
                                        <span className='sub-text w-150px'>Ca Làm Việc</span>
                                    </DataTableRow>
                                )}
                                {columns[2] && (
                                    <DataTableRow>
                                        <span className='sub-text w-80px'>Mã ca</span>
                                    </DataTableRow>
                                )}
                                {columns[3] && (
                                    <DataTableRow>
                                        <span className='sub-text w-150px'>Thời gian làm việc</span>
                                    </DataTableRow>
                                )}
                                {columns[4] && (
                                    <DataTableRow>
                                        <span className='sub-text w-150px'>Thời gian Checkin</span>
                                    </DataTableRow>
                                )}
                                {columns[5] && (
                                    <DataTableRow>
                                        <span className='sub-text w-150px'>Thời gian Checkout</span>
                                    </DataTableRow>
                                )}
                                {columns[6] && (
                                    <DataTableRow>
                                        <span className='sub-text w-150px'>Giờ ăn trưa</span>
                                    </DataTableRow>
                                )}
                                {columns[7] && (
                                    <DataTableRow>
                                        <span className='sub-text w-150px'>Không checkin Vào</span>
                                    </DataTableRow>
                                )}
                                {columns[8] && (
                                    <DataTableRow>
                                        <span className='sub-text w-150px'>Không checkin Ra</span>
                                    </DataTableRow>
                                )}
                                {columns[9] && (
                                    <DataTableRow>
                                        <span className='sub-text w-150px'>Vị trí trên báo biểu</span>
                                    </DataTableRow>
                                )}
                                {columns[10] && (
                                    <DataTableRow>
                                        <span className='sub-text w-100px'>Tổng giờ</span>
                                    </DataTableRow>
                                )}
                                {columns[11] && (
                                    <DataTableRow>
                                        <span className='sub-text w-100px'>Đếm công</span>
                                    </DataTableRow>
                                )}
                                <DataTableRow className="nk-tb-col-tools text-end">
                                    <UncontrolledDropdown>
                                        <DropdownToggle
                                            color="tranparent"
                                            className="btn btn-xs btn-outline-light btn-icon dropdown-toggle"
                                        >
                                            <Icon name="plus"></Icon>
                                        </DropdownToggle>
                                        <DropdownMenu end className="dropdown-menu-xs">
                                            <ul className="link-tidy sm no-bdr">
                                                {header.length > 0 ? (
                                                    header.map((item, index) => (
                                                        <li key={index}>
                                                            <div className="custom-control custom-control-sm custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id={`column-${index}`}
                                                                    checked={columns[index]}
                                                                    onChange={(e) =>
                                                                        setColumns((prev) => {
                                                                            const newColumns = [...prev];
                                                                            newColumns[index] = e.target.checked;
                                                                            return newColumns;
                                                                        })
                                                                    }
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor={`column-${index}`}
                                                                >
                                                                    {item}
                                                                </label>
                                                            </div>
                                                        </li>
                                                    ))
                                                ) : (
                                                    <li>No data</li>
                                                )}
                                            </ul>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </DataTableRow>
                            </DataTableHead>
                            {currentItems.length > 0
                                ? currentItems.map((item, index) => {
                                    const displayedIndex =  itemPerPage * (currentPage - 1) + index + 1;
                                      return (
                                          <DataTableItem key={item.id}>
                                              {columns[0] && (
                                                  <DataTableRow size=" nk-tb-col-check mb">
                                                      <span className="tb-amount">{displayedIndex}</span>
                                                  </DataTableRow>
                                              )}
                                              <DataTableRow className="nk-tb-col-check">
                                                  <div className="custom-control custom-control-sm custom-checkbox notext">
                                                      <input
                                                          type="checkbox"
                                                          className="custom-control-input"
                                                          defaultChecked={item.checked}
                                                          id={item.id + 'uid1'}
                                                          key={Math.random()}
                                                          onChange={(e) => onSelectChange(e, item.id)}
                                                      />
                                                      <label
                                                          className="custom-control-label"
                                                          htmlFor={item.id + 'uid1'}
                                                      ></label>
                                                  </div>
                                              </DataTableRow>
                                              {columns[1] && (
                                                  <DataTableRow>
                                                      <span className="tb-amount">{item?.tieude}</span>
                                                  </DataTableRow>
                                              )}
                                              {columns[2] && (
                                                  <DataTableRow>
                                                      <span className="tb-amount">{item?.macalamviec}</span>
                                                  </DataTableRow>
                                              )}
                                              {columns[3] && (
                                                  <DataTableRow>
                                                      <span className="tb-amount">
                                                          {item?.giovaolamviec} - {item?.gioketthuclamviec}
                                                      </span>
                                                  </DataTableRow>
                                              )}
                                              {columns[4] && (
                                                  <DataTableRow>
                                                      <span className="tb-amount">
                                                          {item?.batdaugiovaodehieuca} - {item?.ketthucgiovaodehieuca}
                                                      </span>
                                                  </DataTableRow>
                                              )}
                                              {columns[5] && (
                                                  <DataTableRow>
                                                      <span className="tb-amount">
                                                          {item?.batdaugioradehieuca} - {item?.ketthucgioradehieuca}
                                                      </span>
                                                  </DataTableRow>
                                              )}
                                              {columns[6] && (
                                                  <DataTableRow>
                                                      <span className="tb-amount">
                                                          {item?.giobatdauantrua} - {item?.gioketthucantrua}
                                                      </span>
                                                  </DataTableRow>
                                              )}
                                              {columns[7] && (
                                                  <DataTableRow>
                                                      <span className="tb-amount">{item?.khongcogiorathitinhtong}</span>
                                                  </DataTableRow>
                                              )}
                                              {columns[8] && (
                                                  <DataTableRow>
                                                      <span className="tb-amount">
                                                          {item?.khongcogiovaothitinhtong}
                                                      </span>
                                                  </DataTableRow>
                                              )}
                                              {columns[9] && (
                                                  <DataTableRow>
                                                      <span className="tb-amount">{item?.vitritrenbaobieu}</span>
                                                  </DataTableRow>
                                              )}
                                              {columns[10] && (
                                                  <DataTableRow>
                                                      <span className="tb-amount">{item?.tonggio}</span>
                                                  </DataTableRow>
                                              )}
                                              {columns[11] && (
                                                  <DataTableRow>
                                                      <span className="tb-amount">{item?.demcong}</span>
                                                  </DataTableRow>
                                              )}

                                              <DataTableRow className="nk-tb-col-tools">
                                                  <ul className="nk-tb-actions gx-1">
                                                      {+showPhanquyen?.sua === 1 && (
                                                          <li
                                                              className="nk-tb-action-hidden"
                                                              onClick={() => handleEditCauHinhCaLamViec(item)}
                                                          >
                                                              <TooltipComponent
                                                                  tag="a"
                                                                  containerClassName="btn btn-trigger btn-icon"
                                                                  id={'edit' + item.id}
                                                                  icon="edit-alt-fill"
                                                                  direction="top"
                                                                  text="Sửa"
                                                              />
                                                          </li>
                                                      )}
                                                      {+showPhanquyen?.xoa === 1 && (
                                                          <React.Fragment>
                                                              <li
                                                                  className="nk-tb-action-hidden"
                                                                  onClick={() => handleDeleteCauHinhCaLamViec(item)}
                                                              >
                                                                  <TooltipComponent
                                                                      tag="a"
                                                                      containerClassName="btn btn-trigger btn-icon"
                                                                      id={'suspend' + item.id}
                                                                      icon="user-cross-fill"
                                                                      direction="top"
                                                                      text="Xóa"
                                                                  />
                                                              </li>
                                                          </React.Fragment>
                                                      )}
                                                      <li>
                                                          <UncontrolledDropdown>
                                                              {(+showPhanquyen?.sua === 1 ||
                                                                  +showPhanquyen?.xoa === 1) && (
                                                                  <DropdownToggle
                                                                      tag="a"
                                                                      className="dropdown-toggle btn btn-icon btn-trigger"
                                                                  >
                                                                      <Icon name="more-h"></Icon>
                                                                  </DropdownToggle>
                                                              )}
                                                              <DropdownMenu end>
                                                                  <ul className="link-list-opt no-bdr">
                                                                      {+showPhanquyen?.sua === 1 && (
                                                                          <li
                                                                              onClick={() =>
                                                                                  handleEditCauHinhCaLamViec(item)
                                                                              }
                                                                          >
                                                                              <DropdownItem
                                                                                  tag="a"
                                                                                  href="#edit"
                                                                                  onClick={(ev) => {
                                                                                      ev.preventDefault();
                                                                                  }}
                                                                              >
                                                                                  <Icon name="edit"></Icon>
                                                                                  <span>Sửa</span>
                                                                              </DropdownItem>
                                                                          </li>
                                                                      )}
                                                                      {+showPhanquyen?.xoa === 1 && (
                                                                          <React.Fragment>
                                                                              <li className="divider"></li>
                                                                              <li
                                                                                  onClick={() =>
                                                                                      handleDeleteCauHinhCaLamViec(item)
                                                                                  }
                                                                              >
                                                                                  <DropdownItem
                                                                                      tag="a"
                                                                                      href="#suspend"
                                                                                      onClick={(ev) => {
                                                                                          ev.preventDefault();
                                                                                      }}
                                                                                  >
                                                                                      <Icon name="na"></Icon>
                                                                                      <span>Xóa</span>
                                                                                  </DropdownItem>
                                                                              </li>
                                                                          </React.Fragment>
                                                                      )}
                                                                  </ul>
                                                              </DropdownMenu>
                                                          </UncontrolledDropdown>
                                                      </li>
                                                  </ul>
                                              </DataTableRow>
                                          </DataTableItem>
                                      );
                                  })
                                : null}
                        </DataTableBody>
                        </div>
                        <div className="card-inner">
                            {currentItems.length > 0 ? (
                                <PaginationComponent
                                    itemPerPage={itemPerPage}
                                    totalItems={cauhinhcalamviec.length}
                                    paginate={paginate}
                                    currentPage={currentPage}
                                />
                            ) : (
                                <div className="text-center">
                                    <span className="text-silent">No data found</span>
                                </div>
                            )}
                        </div>
                    </DataTable>
                </Block>

                <ModalCauhinhcalamviec show={show} fieldList={fieldList} setShow={() => setShow(false)} />
                <ModalEditCauhinhcalamviec
                    show={showEditCauHinhCaLamViec}
                    fieldList={fieldList}
                    setShow={() => setShowEditCauHinhCaLamViec(false)}
                    dataIdCauHinhCaLamViec={dataIdCauHinhCaLamViec}
                />
            </Content>
        </React.Fragment>
    );
};

export default Cauhinhcalamviec;
