import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '~/store/actions';
import Content from "~/layout/content/Content";
import Head from "~/layout/head/Head";
import CalenderApp from "~/components/partials/calender/Calender";
import DatePicker from "react-datepicker";
import { Modal, ModalBody,  ModalHeader, Button } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Col,
  Icon,
  PreviewAltCard,
  Row,
  RSelect,
} from "~/components/Component";
import { eventOptions, events } from "~/components/partials/calender/CalenderData";
import { useForm } from "react-hook-form";
import { setDateForPicker } from "~/utils/Utils";
import {dataSelectLoaiSuKien} from '~/utils/Datacustom';
import Swal from 'sweetalert2';

const Calender = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getListHoatdongsukien());
    // dispatch(actions.getListNhanvien());
}, []);
const { user } = useSelector((state) => state.app);
// const [userdata, setUser] = useState(null);
//   useEffect(() => {
//     setUser(user);
// }, [user]);
// console.log(userdata)

const { hoatdongsukien } = useSelector((state) => state.quanly);
const [data, setData] = useState(hoatdongsukien);
// console.log('data', data)
const [listLoaisukien, setlistLoaisukien] = useState([]);
// console.log('listLoaisukien', listLoaisukien)

useEffect(() => {
  setlistLoaisukien(dataSelectLoaiSuKien);
  if (hoatdongsukien && hoatdongsukien.length > 0) {
      setData([...hoatdongsukien]);
  }
}, [hoatdongsukien,dataSelectLoaiSuKien]);



useEffect(() => {
  if (data.length > 0) {
    const checkUser = (Nhanviensukiens) => {
      if(Number(user?.phanquyen) !== 0){
        const varpe = Nhanviensukiens?.find((items) => Number(items?.Nhanvien?.User?.id) === Number(user?.id));
        return varpe ? true : false;
      } else {
        return true;
      }
    };
    const checkLoaisukien = (value) => {
      const varpe1 = listLoaisukien?.find((items) => items?.value === value);
      return varpe1;
    };
    const datafilemau = data.flatMap((item) => {
      if (item.Nhanviensukiens !== null && checkUser(item.Nhanviensukiens) === true) {
        return {
          id: "default-event-id-" + Math.floor(Math.random() * 9999999),
          idItem: item.id,
          title: item.tensukien,
          start: item.ngaybatdau,
          end: item.ngayketthuc,
          description: item.mota,
          className: checkLoaisukien(item.loaisukien)?.className,
          type: checkLoaisukien(item.loaisukien),
        };
      }
      return null;
    });
    // console.log('datafilemau', datafilemau);
    const filteredArr = datafilemau.filter((el) => el != null);
    if(filteredArr){
      updateEvent(events);
      updateEvent((prevPage) => [...prevPage, ...filteredArr]);
    }
  }
}, [data, listLoaisukien, user]);


const [modal, setModal] = useState(false);
const [mockEvents, updateEvent] = useState(events);


console.log('mockEvents', mockEvents)

  const [dates, setDates] = useState({
    startDate: new Date(),
    startTime: new Date(),
    endTime: new Date(),
    endDate: new Date(),
  });
  const [theme, settheme] = useState({
    value: "CONG_TY",
    label: "Công ty",
  });
  const toggle = () => {
    setModal(!modal);
  };
  const {register,handleSubmit,formState: { errors },} = useForm();

  const handleFormSubmit = (formData) => {
    let datapayload = {
      data: [
          {
              tensukien: formData.title,
              mota: formData.description,
              loaisukien: theme?.value,
              ngaybatdau: setDateForPicker(dates.startDate),
              ngayketthuc: setDateForPicker(dates.endDate),
              NhanvienId: [{NhanvienId: user?.id}],
          },
      ],
  };
  dispatch(actions.NewHoatdongsukien(datapayload));

  // console.log('datapayload', datapayload)
    // updateEvent([...mockEvents, newEvent]);
    settheme({
      value: "CONG_TY",
      label: "Công ty",
    });
    toggle();
  };

  const editEvent = (formData, formData1) => {
    if(formData !== null){
      let newEvents = mockEvents;
      const index = newEvents.findIndex((item) => item.id === formData.id);
      events[index] = formData;
      updateEvent([...events]);
    }
    if(formData1 !== null){
      let datapayload = {
        data: [
          formData1,
        ],
    };
    // console.log('check payload', datapayload);
    dispatch(actions.EditHoatdongsukien(datapayload));
    }
   
  };

  const deleteEvent = (id, idItem) => {
    if(idItem === undefined){
    let filteredEvents = mockEvents.filter((item) => item.id !== id);
      updateEvent(filteredEvents);
    }else{
      Swal.fire({
        title: 'Bạn có chắc không?',
        text: 'Bạn sẽ không thể lấy lại khi xóa!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Không, bỏ qua!',
        confirmButtonText: 'Vâng, xóa nó đi!',
    }).then((result) => {
        if (result.isConfirmed) {
            dispatch(actions.DeleteHoatdongsukien({id: idItem}));
        }
    });
    }
  };

  return (
    <React.Fragment>
      <Head title="Calender" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Lịch</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <Button color="primary" onClick={toggle}>
                <Icon name="plus" />
                <span>Thêm sự kiện</span>
              </Button>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewAltCard>
            <CalenderApp events={mockEvents} onDelete={deleteEvent} onEdit={editEvent} />
          </PreviewAltCard>
        </Block>
      </Content>
      <Modal isOpen={modal} toggle={toggle} className="modal-md">
        <ModalHeader toggle={toggle}>Thêm sự kiện</ModalHeader>
        <ModalBody>
          <form className="form-validate is-alter" onSubmit={handleSubmit(handleFormSubmit)}>
            <Row className="gx-4 gy-3">
              <Col size="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="event-title">
                    Tiêu đề
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      id="event-title"
                      name="title"
                      className="form-control"
                      {...register('title', { required: true })}
                    />
                    {errors.title && <p className="invalid">Nội dung không được để trống</p>}
                  </div>
                </div>
              </Col>
              <Col sm="6">
                <div className="form-group">
                  <label className="form-label">Ngày bắt đầu &amp; Thời gian</label>
                  <Row className="gx-2">
                    <div className="w-55">
                      <div className="form-control-wrap">
                        <DatePicker
                          selected={dates.startDate}
                          onChange={(date) => setDates({ ...dates, startDate: date })}
                          className="form-control date-picker"
                        />
                      </div>
                    </div>
                    <div className="w-45">
                      <div className="form-control-wrap has-timepicker">
                        <DatePicker
                          selected={dates.startTime}
                          onChange={(date) => setDates({ ...dates, startTime: date })}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={30}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          className="form-control date-picker"
                        />
                      </div>
                    </div>
                  </Row>
                </div>
              </Col>
              <Col sm="6">
                <div className="form-group">
                  <label className="form-label">Ngày kết thúc &amp; Thời gian</label>
                  <Row className="gx-2">
                    <div className="w-55">
                      <div className="form-control-wrap">
                        <DatePicker
                          selected={dates.endDate}
                          onChange={(date) => setDates({ ...dates, endDate: date })}
                          className="form-control date-picker"
                        />
                      </div>
                    </div>
                    <div className="w-45">
                      <div className="form-control-wrap has-timepicker">
                        <DatePicker
                          selected={dates.endTime}
                          onChange={(date) => setDates({ ...dates, endTime: date })}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={30}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          className="form-control date-picker"
                        />
                      </div>
                    </div>
                  </Row>
                </div>
              </Col>
              <Col size="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="event-description">
                    Nội dung
                  </label>
                  <div className="form-control-wrap">
                    <textarea
                      className="form-control"
                      id="event-description"
                      name="description"
                      {...register('description', { required: true })}
                    ></textarea>

                    {errors.description && <p className="invalid">Nội dung không được để trống</p>}
                  </div>
                </div>
              </Col>
              <Col size="12">
                <div className="form-group">
                  <label className="form-label">Danh mục sự kiện</label>
                  <div className="form-control-wrap">
                    <RSelect
                      options={listLoaisukien}
                      defaultValue={{
                        value: "CONG_TY",
                        label: "Công ty",
                      }}
                      onChange={(e) => settheme(e)}
                      //ref={register({ required: true })}
                    />
                  </div>
                </div>
              </Col>
              <Col size="12">
                <ul className="d-flex justify-content-between gx-4 mt-1">
                  <li>
                    <Button type="submit" color="primary">
                      Thêm mới
                    </Button>
                  </li>
                  <li>
                    <Button color="danger" className="btn-dim" onClick={toggle}>
                      Hủy
                    </Button>
                  </li>
                </ul>
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
export default Calender;
