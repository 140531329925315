import axiosConfig from '../axiosConfig'

export const apiGetPhucap = () => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlyhethong/phucap`,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiGetPhucapByNhanVien = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: '/api/v2/quanlyhethong/nhanvien/phucap?idnv='+payload.NhanvienId,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiNewPhucap = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlyhethong/phucap',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiEditPhucap = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlyhethong/phucap',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiDeletePhucap = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlyhethong/phucap',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
// Thêm phụ cấp cho nhn viên
export const apiPostPhucapByNhanVien = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlyhethong/nhanvien/phucap',
            data: payload
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
// Sửa phụ cấp cho nhn viên
export const apiPutPhucapByNhanVien = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlyhethong/nhanvien/phucap',
            data: payload
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
export const apiDeletePhucapByNhanVien = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlyhethong/nhanvien/phucap',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})