import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Card, DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledDropdown,} from 'reactstrap';
import Content from '~/layout/content/Content';
import {Block} from '~/components/block/Block';
import {
    Button,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    ExportXlsx,
    Icon,
    ImportXlsx,
    PaginationComponent,
} from '~/components/Component';
import {MenuBangCong} from './MenuTab/Menucontent';
import {apiDulieuchamcong} from '~/services';
import {format, parseISO} from "date-fns";

const Dulieuchamcong = () => {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [dataAll, setDataAll] = useState([]);
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // +1 vì tháng trong JavaScript bắt đầu từ 0
    const defaultValue = `${year}-${month}`;
    
    const [inputValue, setInputValue] = useState(defaultValue);
    const [msg, setMsg] = useState(null);
    
    console.log('inputValue', inputValue)
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiDulieuchamcong({month: inputValue});
                // console.log('responsevvvvvvvvvvv', response);
                setData(response?.data?.data);
                setDataAll(response?.data?.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData().catch(console.error);
    }, [inputValue, msg]);
    const namefile = 'Dữ liệu chấm công';
    const header = ['Stt', 'Mã nhân viên', 'Họ và tên', 'Ngày chấm công', 'Giờ chấm công', 'Nguồn dữ liệu'];
    const headerExport = ['Mã nhân viên', 'Giờ chấm công', 'Ngày chấm công'];
    const [columns, setColumns] = useState(() => {
        return header.map((item, index) => index <= 6);
    });
    const [dataFileMau, setDataFileMau] = useState([]);
    useEffect(() => {
        if (data.length > 0) {
            const datafilemau = data.map((item, index) => {
                return {
                    ...(columns[0] && {Stt: index + 1}),
                    ...(columns[1] && {'Mã nhân viên': item?.uuid}),
                    ...(columns[2] && {'Họ và tên': item?.hoten}),
                    ...(columns[3] && {'Ngày chấm công': item?.day ? format(parseISO(item?.day), 'yyyy-MM-dd') : 'NNA'}),
                    ...(columns[4] && {'Giờ chấm công': item?.time}),
                    ...(columns[5] && {'Nguồn': item?.nguon === 0 ? "Máy chấm công" : "Nhập thủ công"}),
                };
            });
            setDataFileMau(datafilemau);
            console.log('datafilemau', datafilemau);
        }
    }, [data, columns]);
    console.log('data', data);
    const [sm, updateSm] = useState(false);
    const [tablesm, updateTableSm] = useState(false);
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState('');
    
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [sort, setSortState] = useState('');
    // onChange function for searching name
    const onFilterChange = (e) => {
        setSearchText(e.target.value);
    };
    const toggle = () => setonSearch(!onSearch);
    
    useEffect(() => {
        if (onSearchText !== '') {
            const filteredObject = dataAll.filter((item) => {
                return (
                    item.hoten.toLowerCase().includes(onSearchText.toLowerCase()) ||
                    item.uuid.toLowerCase().includes(onSearchText.toLowerCase())
                );
            });
            setData([...filteredObject]);
        } else {
            setData([...dataAll]);
        }
    }, [onSearchText, setData]);
    // Get current list, pagination
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const itemsPerPageOptions = [10, 20, 30, 40, 50, 100, 300, 500];
    return (
        <React.Fragment>
            <Content>
                <Card className="mt-0">
                    <DataTable className="card-stretch border-0 ">
                        <div className="card-inner card-inner-lg px-4 pt-1 pb-0">
                            <h4 className="mt-2">Quản lý chấm công</h4>
                            <MenuBangCong/>
                        </div>
                    </DataTable>
                </Card>
                <Block>
                    <DataTable className="card-stretch border-0 ">
                        <div className="card-inner position-relative card-tools-toggle pt-0 mt-4">
                            <div className="card-title-group ">
                                <div className="card-title">
                                    <h5 className="title">Dữ liệu chấm công</h5>
                                    <input
                                        type="month"
                                        id="start"
                                        className="form-control"
                                        min="2018-03"
                                        value={inputValue}
                                        onChange={(e) => setInputValue(e.target.value)}
                                    />
                                </div>
                                <div className="card-tools">
                                    <ul className="btn-toolbar gx-1">
                                        <li>
                                            <a
                                                href="#search"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    toggle();
                                                }}
                                                className="btn btn-icon search-toggle toggle-search"
                                            >
                                                <Icon name="search"></Icon>
                                            </a>
                                        </li>
                                        <li className="btn-toolbar-sep"></li>
                                        <li>
                                            <div className="toggle-wrap">
                                                <Button
                                                    className={`btn-icon btn-trigger toggle ${tablesm ? 'active' : ''}`}
                                                    onClick={() => updateTableSm(true)}
                                                >
                                                    <Icon name="menu-right"></Icon>
                                                </Button>
                                                <div className={`toggle-content ${tablesm ? 'content-active' : ''}`}>
                                                    <ul className="btn-toolbar gx-1">
                                                        <li className="toggle-close">
                                                            <Button
                                                                className="btn-icon btn-trigger toggle"
                                                                onClick={() => updateTableSm(false)}
                                                            >
                                                                <Icon name="arrow-left"></Icon>
                                                            </Button>
                                                        </li>
                                                        
                                                        <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle
                                                                    color="tranparent"
                                                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                                                >
                                                                    <Icon name="setting"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu end className="dropdown-menu-xs">
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Show</span>
                                                                        </li>
                                                                        {itemsPerPageOptions.map((item, index) => {
                                                                            return (
                                                                                <li
                                                                                    className={
                                                                                        itemPerPage === item
                                                                                            ? 'active'
                                                                                            : ''
                                                                                    }
                                                                                    key={index}
                                                                                >
                                                                                    <DropdownItem
                                                                                        tag="a"
                                                                                        href="#dropdownitem"
                                                                                        onClick={(ev) => {
                                                                                            ev.preventDefault();
                                                                                            setItemPerPage(item);
                                                                                            // handleReParamsClick();
                                                                                        }}
                                                                                    >
                                                                                        {item}
                                                                                    </DropdownItem>
                                                                                </li>
                                                                            );
                                                                        })}
                                                                    </ul>
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Order</span>
                                                                        </li>
                                                                        <li className={sort === 'dsc' ? 'active' : ''}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setSortState('dsc');
                                                                                    //   sortFunc("dsc");
                                                                                }}
                                                                            >
                                                                                DESC
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li className={sort === 'asc' ? 'active' : ''}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setSortState('asc');
                                                                                    //   sortFunc("asc");
                                                                                }}
                                                                            >
                                                                                ASC
                                                                            </DropdownItem>
                                                                        </li>
                                                                    </ul>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="toggle-wrap nk-block-tools-toggle">
                                                <Button
                                                    className={`btn-icon btn-trigger toggle-expand me-n1 ${
                                                        sm ? 'active' : ''
                                                    }`}
                                                    onClick={() => updateSm(!sm)}
                                                >
                                                    <Icon name="upload-cloud"></Icon>
                                                </Button>
                                                <div
                                                    className="toggle-expand-content-cloud"
                                                    style={{display: sm ? 'block' : 'none'}}
                                                >
                                                    <ul className="nk-block-tools g-3">
                                                        <li className="nk-block-tools-opt">
                                                            <ExportXlsx
                                                                dataFileMau={dataFileMau}
                                                                title={header}
                                                                namefile={namefile}
                                                                btnname={'Xuất dữ liệu'}
                                                            />
                                                        </li>
                                                        <li className="nk-block-tools-opt">
                                                            <ImportXlsx
                                                                msg={msg}
                                                                setMsg={setMsg}
                                                                type={'dulieuchamcong'}
                                                                title={headerExport}
                                                                namefile={namefile}
                                                                btnname={'Nhập dữ liệu'}
                                                            />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className={`card-search search-wrap ${!onSearch && 'active'}`}>
                                <div className="card-body">
                                    <div className="search-content">
                                        <Button
                                            className="search-back btn-icon toggle-search active"
                                            onClick={() => {
                                                setSearchText('');
                                                toggle();
                                            }}
                                        >
                                            <Icon name="arrow-left"></Icon>
                                        </Button>
                                        <input
                                            type="text"
                                            className="border-transparent form-focus-none form-control"
                                            placeholder="Tìm kiếm"
                                            value={onSearchText}
                                            onChange={(e) => onFilterChange(e)}
                                        />
                                        <Button className="search-submit btn-icon">
                                            <Icon name="search"></Icon>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{overflowY: 'hidden'}}>
                            <DataTableBody>
                                <DataTableHead className="col-12">
                                    {columns[0] && (
                                        <DataTableRow>
                                            <span className="sub-text">Stt</span>
                                        </DataTableRow>
                                    )}
                                    {columns[1] && (
                                        <DataTableRow>
                                            <span className="sub-text w-100px">Mã nhân viên</span>
                                        </DataTableRow>
                                    )}
                                    {columns[2] && (
                                        <DataTableRow>
                                            <span className="sub-text w-150px">Họ và tên</span>
                                        </DataTableRow>
                                    )}
                                    {columns[3] && (
                                        <DataTableRow>
                                            <span className="sub-text w-100px">Ngày chấm công</span>
                                        </DataTableRow>
                                    )}
                                    {columns[4] && (
                                        <DataTableRow>
                                            <span className="sub-text w-100px">Giờ chấm công</span>
                                        </DataTableRow>
                                    )}
                                    {columns[5] && (
                                        <DataTableRow>
                                            <span className="sub-text w-100px">Nguồn dữ liệu</span>
                                        </DataTableRow>
                                    )}
                                    <DataTableRow className="nk-tb-col-tools text-end">
                                        <UncontrolledDropdown>
                                            <DropdownToggle
                                                color="tranparent"
                                                className="btn btn-xs btn-outline-light btn-icon dropdown-toggle"
                                            >
                                                <Icon name="plus"></Icon>
                                            </DropdownToggle>
                                            <DropdownMenu end className="dropdown-menu-xs">
                                                <ul className="link-tidy sm no-bdr">
                                                    {header.length > 0 ? (
                                                        header.map((item, index) => (
                                                            <li key={index}>
                                                                <div
                                                                    className="custom-control custom-control-sm custom-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="custom-control-input"
                                                                        id={`column-${index}`}
                                                                        checked={columns[index]}
                                                                        onChange={(e) =>
                                                                            setColumns((prev) => {
                                                                                const newColumns = [...prev];
                                                                                newColumns[index] = e.target.checked;
                                                                                return newColumns;
                                                                            })
                                                                        }
                                                                    />
                                                                    <label
                                                                        className="custom-control-label"
                                                                        htmlFor={`column-${index}`}
                                                                    >
                                                                        {item}
                                                                    </label>
                                                                </div>
                                                            </li>
                                                        ))
                                                    ) : (
                                                        <li>No data</li>
                                                    )}
                                                </ul>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </DataTableRow>
                                </DataTableHead>
                                {currentItems.length > 0
                                    ? currentItems.map((item, index) => {
                                        return (
                                            <DataTableItem key={item.id}>
                                                {columns[0] && (
                                                    <DataTableRow size=" nk-tb-col-check mb">
                                                        <span className="tb-amount">{index + 1}</span>
                                                    </DataTableRow>
                                                )}
                                                {columns[1] && (
                                                    <DataTableRow>
                                                        <span className="tb-amount">{item.uuid}</span>
                                                    </DataTableRow>
                                                )}
                                                {columns[2] && (
                                                    <DataTableRow>
                                                        <span className="tb-amount">{item?.hoten}</span>
                                                    </DataTableRow>
                                                )}
                                                {columns[3] && (
                                                    <DataTableRow>
                                                        <span
                                                            className="tb-amount">{item?.day ? format(parseISO(item?.day), 'dd/MM/yyyy') : 'NNA'}</span>
                                                    </DataTableRow>
                                                )}
                                                {columns[4] && (
                                                    <DataTableRow>
                                                        <span className="tb-amount">{item?.time}</span>
                                                    </DataTableRow>
                                                )}
                                                {columns[4] && (
                                                    <DataTableRow>
                                                        <span
                                                            className="tb-amount">{item?.nguon === 0 ? "Máy chấm công" : "Nhập thủ công"}</span>
                                                    </DataTableRow>
                                                )}
                                                <DataTableRow>
                                                </DataTableRow>
                                            </DataTableItem>
                                        );
                                    })
                                    : null}
                            </DataTableBody>
                        </div>
                        <div className="card-inner">
                            {currentItems.length > 0 ? (
                                <PaginationComponent
                                    itemPerPage={itemPerPage}
                                    totalItems={data.length}
                                    paginate={paginate}
                                    currentPage={currentPage}
                                />
                            ) : (
                                <div className="text-center">
                                    <div>
                                        <Spinner color="primary"/>
                                    </div>
                                    <span className="text-silent">Chưa có dữ liệu!</span>
                                </div>
                            )}
                        </div>
                    </DataTable>
                </Block>
            </Content>
        </React.Fragment>
    );
};

export default Dulieuchamcong;
