import actionTypes from './actionTypes'
import { apiGetThongbao, apiNewThongbao, apiEditThongbao, apiDeleteThongbao } from '~/services/thongbao'
import { toast } from "react-toastify";
export const getListThongbao = (payload) => async (dispatch) => {
    try {
        const response = await apiGetThongbao(payload)
        // console.log('chek api lisst Thongbao', response?.data.success)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_THONG_BAO,
                thongbao: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_THONG_BAO,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_THONG_BAO,
            thongbao: null
        })
    }
}

export const NewThongbao = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewThongbao', payload)
            const response = await apiNewThongbao(payload)
            // console.log('chek api apiNewThongbao', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_THONG_BAO,
                    msg: 'OK'
                });
                // dispatch(getListThongbao());
                // toast.success("Thêm mới chức vụ thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_THONG_BAO,
                    msg: 'ERR'
                })
                // toast.error("Thêm mới chức vụ thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_THONG_BAO,
                thongbao: null
            })
        }
    }
}
export const EditThongbao = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditThongbao(payload)
            // console.log('chek api apiEditThongbao', response)
            if (response?.data.success === 200) {
                dispatch(getListThongbao({UserId: payload.UserId, page: '1', limit: '20', order: 'desc', by: 'id'}));
                // toast.success("Sửa thông tin chức vụ thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_THONG_BAO,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_THONG_BAO,
                    msg: 'ERR'
                })
                // toast.error("Sửa thông tin chức vụ thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_THONG_BAO,
                thongbao: null
            })
        }
    }
}
export const DeleteThongbao = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteThongbao(payload)
            // console.log('chek api apiDeleteThongbao', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListThongbao());
                // toast.success("Xóa thông tin chức vụ thành công!")
                dispatch({
                    type: actionTypes.DELETE_THONG_BAO,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_THONG_BAO,
                    msg: 'ERR'
                })
                // toast.error("Xóa thông tin chức vụ thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_THONG_BAO,
                thongbao: null
            })
        }
    }
}