import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '~/store/actions';
import { ModalCauhinhthuethunhapcnlt, ModalEditCauhinhthuethunhapcnlt } from './Modal';
import Swal from 'sweetalert2';
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalBody,
    UncontrolledDropdown,
    Spinner,
    Card,
} from 'reactstrap';
import Head from '~/layout/head/Head';
import Content from '~/layout/content/Content';
import { Block } from '~/components/block/Block';
import {
    Button,
    Col,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    Icon,
    PaginationComponent,
    Row,
    RSelect,
    TooltipComponent,
    PermissionUtils,
    ExportXlsx,
} from '~/components/Component';
import { NumericFormat } from 'react-number-format';

const Cauhinhthuethunhapcnlt = () => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);

    const [showEditCauhinhthuethunhapcnlt, setShowEditCauhinhthuethunhapcnlt] = useState(false);
    useEffect(() => {
        dispatch(actions.getListCauhinhthuethunhapcnlt());
    }, []);
    const { cauhinhthuethunhapcnlt } = useSelector((state) => state.quanly);
    // console.log(cauhinhthuethunhapcnlt)
    const [data, setData] = useState(cauhinhthuethunhapcnlt);
    const fieldList = {
        ten: '',
        mota: '',
        tugiatri: '',
        tugiatriformat: '',
        dengiatri: '',
        dengiatriformat: '',
        giatri: '',
        // TrangthaiId: '',
    };
    useEffect(() => {
        if (cauhinhthuethunhapcnlt && cauhinhthuethunhapcnlt.length > 0) {
            setData([...cauhinhthuethunhapcnlt]);
        }
    }, [cauhinhthuethunhapcnlt]);

    const [showPhanquyen, setShowPhanquyen] = useState({});
    let dataPermissionUtils = PermissionUtils('cauhinhthuethunhapcnlt', 'all');
    useEffect(() => {
        if (dataPermissionUtils) {
            setShowPhanquyen(dataPermissionUtils);
        }
    }, [dataPermissionUtils]);

    const namefile = 'Thông tin cấu hình thuế thu nhập cá nhân lũy tiến';
    const header = ['Stt', 'Tiêu đề', 'Giá trị(%)', 'Giá trị đầu(%)', 'Giá trị cuối(%)'];
    const [columns, setColumns] = useState(() => {
        const d = header.map((item, index) => index <= 8);
        return d;
    });
    const [dataFileMau, setDataFileMau] = useState([]);
    useEffect(() => {
        if (data.length > 0) {
            const datafilemau = data.map((item, index) => {
                return {
                    ...(columns[0] && { Stt: index + 1 }),
                    ...(columns[1] && { 'Tiêu đề': item?.ten }),
                    ...(columns[2] && { 'Giá trị(%)': item?.giatri }),
                    ...(columns[3] && { 'Giá trị đầu(%)': item.tugiatri }),
                    ...(columns[4] && { 'Giá trị cuối(%)': item.dengiatri }),
                };
            });
            setDataFileMau(datafilemau);
        }
    }, [data, columns]);

    const [sm, updateSm] = useState(false);
    const [tablesm, updateTableSm] = useState(false);
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [sort, setSortState] = useState('');
    // onChange function for searching name
    const onFilterChange = (e) => {
        setSearchText(e.target.value);
    };
    const toggle = () => setonSearch(!onSearch);
    const selectorCheck = (e) => {
        let newData;
        newData = data.map((item) => {
            item.checked = e.currentTarget.checked;
            return item;
        });
        setData([...newData]);
    };
    const onSelectChange = (e, id) => {
        let newData = data;
        let index = newData.findIndex((item) => item.id === id);
        newData[index].checked = e.currentTarget.checked;
        setData([...newData]);
    };
    useEffect(() => {
        if (onSearchText !== '') {
            const filteredObject = data.filter((item) => {
                return (
                    item.mota.toLowerCase().includes(onSearchText.toLowerCase()) ||
                    item.ten.toLowerCase().includes(onSearchText.toLowerCase())
                );
            });
            setData([...filteredObject]);
        } else {
            setData([...data]);
        }
    }, [onSearchText, setData]);
    // Get current list, pagination
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    useEffect(() => {
        (cauhinhthuethunhapcnlt && setShow(false)) || setShowEditCauhinhthuethunhapcnlt(false);
    }, [cauhinhthuethunhapcnlt]);
    // set state để mở đóng Modal
    const handleAddNewCauhinhthuethunhapcnlt = async () => {
        setShow(true);
    };
    const [dataIdCauhinhthuethunhapcnlt, setDataIdCauhinhthuethunhapcnlt] = useState([]);
    const handleEditCauhinhthuethunhapcnlt = async (data) => {
        setShowEditCauhinhthuethunhapcnlt(true);
        await setDataIdCauhinhthuethunhapcnlt(data);
    };
    const handleDeleteCauhinhthuethunhapcnlt = (data) => {
        Swal.fire({
            title: 'Bạn có chắc không?',
            text: 'Bạn sẽ không thể lấy lại khi xóa!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Không, bỏ qua!',
            confirmButtonText: 'Vâng, xóa nó đi!',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(actions.DeleteCauhinhthuethunhapcnlt(data));
            }
        });
    };
    return (
        <React.Fragment>
            {/*<Head title="Doanh nghiệp, Chi nhánh, Phòng ban, Chức Vụ"></Head>*/}
            <Content>
               
                <Block>
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner position-relative card-tools-toggle pt-0">
                            <div className="card-title-group mt-5 row">
                                <div className="card-title col-7">
                                    <h4>Cấu hình thuế thu nhập cá nhân lũy tiến</h4>
                                    <Button
                                        color="primary"
                                        className="btn-icon px-4 mt-2"
                                        onClick={handleAddNewCauhinhthuethunhapcnlt}
                                    >
                                        <Icon name="plus"></Icon> Thêm mới
                                    </Button>
                                   
                                </div>
                                {/* <div className="card-tools col-5">
                                    <ul className="btn-toolbar gx-1 float-end">
                                        <li>
                                            <a
                                                href="#search"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    toggle();
                                                }}
                                                className="btn btn-icon search-toggle toggle-search"
                                            >
                                                <Icon name="search"></Icon>
                                            </a>
                                        </li>
                                        <li className="btn-toolbar-sep"></li>
                                        <li>
                                            <div className=" position-relative card-tools-toggle">
                                                <div className="toggle-wrap nk-block-tools-toggle">
                                                    <Button
                                                        className={`btn-icon btn-trigger toggle-expand me-n1 ${
                                                            sm ? 'active' : ''
                                                        }`}
                                                        onClick={() => updateSm(!sm)}
                                                    >
                                                        <Icon name="upload-cloud"></Icon>
                                                    </Button>
                                                    <div
                                                        className="toggle-expand-content-cloud"
                                                        style={{ display: sm ? 'block' : 'none' }}
                                                    >
                                                        <ul className="nk-block-tools g-3">
                                                            <li className="nk-block-tools-opt">
                                                                <ExportXlsx
                                                                    dataFileMau={dataFileMau}
                                                                    title={header}
                                                                    namefile={namefile}
                                                                    btnname={'Xuất dữ liệu'}
                                                                />
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div> */}
                            </div>
                            <div className={`card-search search-wrap ${!onSearch && 'active'}`}>
                                <div className="card-body">
                                    <div className="search-content">
                                        <Button
                                            className="search-back btn-icon toggle-search active"
                                            onClick={() => {
                                                setSearchText('');
                                                toggle();
                                            }}
                                        >
                                            <Icon name="arrow-left"></Icon>
                                        </Button>
                                        <input
                                            type="text"
                                            className="border-transparent form-focus-none form-control"
                                            placeholder="Tìm kiếm"
                                            value={onSearchText}
                                            onChange={(e) => onFilterChange(e)}
                                        />
                                        <Button className="search-submit btn-icon">
                                            <Icon name="search"></Icon>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ overflowY: 'hidden' }}>
                        <DataTableBody>
                            <DataTableHead>
                                {columns[0] && (
                                    <DataTableRow>
                                        <span className="sub-text">Stt</span>
                                    </DataTableRow>
                                )}
                                {columns[1] && (
                                    <DataTableRow>
                                        <span className="sub-text w-150px">Tiêu đề</span>
                                    </DataTableRow>
                                )}
                                {columns[2] && (
                                    <DataTableRow>
                                        <span className="sub-text w-80px">Giá trị (%)</span>
                                    </DataTableRow>
                                )}
                                {columns[3] && (
                                    <DataTableRow>
                                        <span className="sub-text w-80px">Giá trị đầu (%)</span>
                                    </DataTableRow>
                                )}
                                {columns[4] && (
                                    <DataTableRow>
                                        <span className="sub-text w-100px">Giá trị cuối (%)</span>
                                    </DataTableRow>
                                )}
                                <DataTableRow className="nk-tb-col-tools text-end">
                                    <UncontrolledDropdown>
                                        <DropdownToggle
                                            color="tranparent"
                                            className="btn btn-xs btn-outline-light btn-icon dropdown-toggle"
                                        >
                                            <Icon name="plus"></Icon>
                                        </DropdownToggle>
                                        <DropdownMenu end className="dropdown-menu-xs">
                                            <ul className="link-tidy sm no-bdr">
                                                {header.length > 0 ? (
                                                    header.map((item, index) => (
                                                        <li key={index}>
                                                            <div className="custom-control custom-control-sm custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id={`column-${index}`}
                                                                    checked={columns[index]}
                                                                    onChange={(e) =>
                                                                        setColumns((prev) => {
                                                                            const newColumns = [...prev];
                                                                            newColumns[index] = e.target.checked;
                                                                            return newColumns;
                                                                        })
                                                                    }
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor={`column-${index}`}
                                                                >
                                                                    {item}
                                                                </label>
                                                            </div>
                                                        </li>
                                                    ))
                                                ) : (
                                                    <li>No data</li>
                                                )}
                                            </ul>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </DataTableRow>
                            </DataTableHead>
                            {currentItems.length > 0
                                ? currentItems.map((item, index) => {
                                      return (
                                          <DataTableItem key={item.id}>
                                              {columns[0] && (
                                                  <DataTableRow size=" nk-tb-col-check mb">
                                                      <span className="tb-amount">{index + 1}</span>
                                                  </DataTableRow>
                                              )}
                                              {columns[1] && (
                                                  <DataTableRow>
                                                      <span className="tb-amount">{item?.ten}</span>
                                                  </DataTableRow>
                                              )}
                                              {columns[2] && (
                                                  <DataTableRow>
                                                      <span className="tb-amount">{item?.giatri}%</span>
                                                  </DataTableRow>
                                              )}
                                              {columns[3] && (
                                                  <DataTableRow>
                                                      <span className="tb-amount">
                                                          <NumericFormat
                                                              value={item?.tugiatri}
                                                              displayType={'text'}
                                                              thousandSeparator={true}
                                                              suffix={'đ'}
                                                          />
                                                      </span>
                                                  </DataTableRow>
                                              )}
                                              {columns[4] && (
                                                  <DataTableRow>
                                                      <span className="tb-amount">
                                                          {' '}
                                                          <NumericFormat
                                                              value={item?.dengiatri}
                                                              displayType={'text'}
                                                              thousandSeparator={true}
                                                              suffix={'đ'}
                                                          />
                                                      </span>
                                                  </DataTableRow>
                                              )}
                                              <DataTableRow className="nk-tb-col-tools">
                                                  <ul className="nk-tb-actions gx-1">
                                                      {+showPhanquyen?.sua === 1 && (
                                                          <li
                                                              className="nk-tb-action-hidden"
                                                              onClick={() => handleEditCauhinhthuethunhapcnlt(item)}
                                                          >
                                                              <TooltipComponent
                                                                  tag="a"
                                                                  containerClassName="btn btn-trigger btn-icon"
                                                                  id={'edit' + item.id}
                                                                  icon="edit-alt-fill"
                                                                  direction="top"
                                                                  text="Sửa"
                                                              />
                                                          </li>
                                                      )}
                                                      {+showPhanquyen?.xoa === 1 && (
                                                          <React.Fragment>
                                                              <li
                                                                  className="nk-tb-action-hidden"
                                                                  onClick={() =>
                                                                      handleDeleteCauhinhthuethunhapcnlt(item)
                                                                  }
                                                              >
                                                                  <TooltipComponent
                                                                      tag="a"
                                                                      containerClassName="btn btn-trigger btn-icon"
                                                                      id={'suspend' + item.id}
                                                                      icon="user-cross-fill"
                                                                      direction="top"
                                                                      text="Xóa"
                                                                  />
                                                              </li>
                                                          </React.Fragment>
                                                      )}
                                                      <li>
                                                          <UncontrolledDropdown>
                                                              {(+showPhanquyen?.sua === 1 ||
                                                                  +showPhanquyen?.xoa === 1) && (
                                                                  <DropdownToggle
                                                                      tag="a"
                                                                      className="dropdown-toggle btn btn-icon btn-trigger"
                                                                  >
                                                                      <Icon name="more-h"></Icon>
                                                                  </DropdownToggle>
                                                              )}
                                                              <DropdownMenu end>
                                                                  <ul className="link-list-opt no-bdr">
                                                                      {+showPhanquyen?.sua === 1 && (
                                                                          <li
                                                                              onClick={() =>
                                                                                  handleEditCauhinhthuethunhapcnlt(item)
                                                                              }
                                                                          >
                                                                              <DropdownItem
                                                                                  tag="a"
                                                                                  href="#edit"
                                                                                  onClick={(ev) => {
                                                                                      ev.preventDefault();
                                                                                  }}
                                                                              >
                                                                                  <Icon name="edit"></Icon>
                                                                                  <span>Sửa</span>
                                                                              </DropdownItem>
                                                                          </li>
                                                                      )}
                                                                      {+showPhanquyen?.xoa === 1 && (
                                                                          <React.Fragment>
                                                                              <li className="divider"></li>
                                                                              <li
                                                                                  onClick={() =>
                                                                                      handleDeleteCauhinhthuethunhapcnlt(
                                                                                          item,
                                                                                      )
                                                                                  }
                                                                              >
                                                                                  <DropdownItem
                                                                                      tag="a"
                                                                                      href="#suspend"
                                                                                      onClick={(ev) => {
                                                                                          ev.preventDefault();
                                                                                      }}
                                                                                  >
                                                                                      <Icon name="na"></Icon>
                                                                                      <span>Xóa</span>
                                                                                  </DropdownItem>
                                                                              </li>
                                                                          </React.Fragment>
                                                                      )}
                                                                  </ul>
                                                              </DropdownMenu>
                                                          </UncontrolledDropdown>
                                                      </li>
                                                  </ul>
                                              </DataTableRow>
                                          </DataTableItem>
                                      );
                                  })
                                : null}
                        </DataTableBody>
                        </div>
                        <div className="card-inner">
                            {currentItems.length > 0 ? (
                                <PaginationComponent
                                    itemPerPage={itemPerPage}
                                    totalItems={cauhinhthuethunhapcnlt.length}
                                    paginate={paginate}
                                    currentPage={currentPage}
                                />
                            ) : (
                                <div className="text-center">
                                    <div>
                                        <Spinner color="primary" />
                                    </div>
                                    <span className="text-silent">Chưa có dữ liệu!</span>
                                </div>
                            )}
                        </div>
                    </DataTable>
                </Block>

                <ModalCauhinhthuethunhapcnlt show={show} fieldList={fieldList} setShow={() => setShow(false)} />
                <ModalEditCauhinhthuethunhapcnlt
                    show={showEditCauhinhthuethunhapcnlt}
                    fieldList={fieldList}
                    setShow={() => setShowEditCauhinhthuethunhapcnlt(false)}
                    dataIdCauhinhthuethunhapcnlt={dataIdCauhinhthuethunhapcnlt}
                />
            </Content>
        </React.Fragment>
    );
};

export default Cauhinhthuethunhapcnlt;
