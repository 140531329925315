export { default as Dashboard } from './Dashboard'
export { default as Nhanvien } from './Nhanvien'
export { default as Nhomnv } from './Nhomnv'
export { default as ChiTietNhanVien } from './ChiTietNhanVien'
// export { default as NewNhanVien } from './NewNhanVienvvvvvvvvvvv'
export { default as Maychamcong } from './Maychamcong'
export { default as Tinhluong } from './Tinhluong'
export { default as Chamcong } from './Chamcong'
export { default as Chinhsuachamcong } from './Chinhsuachamcong'
export { default as Congty } from './Congty'
export { default as Kieuluong } from './Kieuluong'
export { default as Phongban } from './Phongban'
export { default as Chucvu } from './Chucvu'
export { default as Baocao } from './Baocao'
export { default as TestApi } from './TestApi'
export { default as Loaiquydinhphucloi } from './Loaiquydinhphucloi'
export { default as Ungvien } from './Ungvien'
export { default as Danhgia } from './Danhgia'
export { default as Quydinhthuongphat } from './Quydinhthuongphat'
export { default as Nghiphepcongtac } from './Nghiphepcongtac'
export { default as Hopdonglaodong } from './Hopdonglaodong'
export { default as Loaihopdong } from './Loaihopdong'
export { default as Mauhopdong } from './Mauhopdong'
export { default as Cauhinhnghile } from './Cauhinhnghile'
export { default as Cauhinhcalamviec } from './Cauhinhcalamviec'
export { default as Giaoviec } from './Giaoviec'
export { default as Bocauhoiphongvan } from './Bocauhoiphongvan'
export { default as Vitrituyendung } from './Vitrituyendung'
export { default as Vanban } from './Vanban'
export { default as Suatan } from './Suatan'
export { default as Phieudaychuyen } from './Phieudaychuyen'
export { default as Suatantangca } from './Suatantangca'
export { default as Binhluan } from './Binhluan'
export { default as Dexuat } from './Dexuat'
export { default as Taisan } from './Taisan'
export { default as Phucap } from './Phucap'
export { default as Cauhinhtangca } from './Cauhinhtangca'
export { default as Cauhinhtinhluongtangca } from './Cauhinhtinhluongtangca'
export { default as Cauhinhtrangthai } from './Cauhinhtrangthai'
export { default as Chinhanh } from './Chinhanh'
export { default as Bangchamcong } from './Bangchamcong'
export { default as Baohiemnhanvien } from './Baohiemnhanvien'
export { default as Tamungluong } from './Tamungluong'
export { default as QuanLyNghi } from './QuanLyNghi'
export { default as CauHinhDoanhNghiep } from './CauHinhDoanhNghiep'
export { default as CauHinhBHXH_BHYT_BHTN } from './CauHinhBHXH_BHYT_BHTN'
export { default as Cauhinhthuethunhapcnlt } from './Cauhinhthuethunhapcnlt'
export { default as Danhgianhanvien } from './Danhgianhanvien'
export { default as Hoatdongsukien } from './Hoatdongsukien'
export { default as Kiemdinhatvsbhld } from './Kiemdinhatvsbhld'
export { default as Loaikiemdinhatvsbhld } from './Loaikiemdinhatvsbhld'
export { default as Moiquanhelaodong } from './Moiquanhelaodong'
export { default as Moiquanhenhanvien } from './Moiquanhenhanvien'
export { default as Nhanvienkiemdinhatvsbhld } from './Nhanvienkiemdinhatvsbhld'
export { default as Phuongtien } from './Phuongtien'
export { default as Loaiphuongtien } from './Loaiphuongtien'
export { default as Phiphu } from './Phiphu'
export { default as Kehoachcuaxe } from './Kehoachcuaxe'
export { default as Dangkiemphuongtien } from './Dangkiemphuongtien'
export { default as Baoduongphuongtien } from './Baoduongphuongtien'
export { default as Quanlyquythuong } from './Quanlyquythuong'
export { default as Quanlythamnien } from './Quanlythamnien'
export { default as Thongbaonoibo } from './Thongbaonoibo'
export { default as Vanthu } from './Vanthu'
export { default as Xeploai } from './Xeploai'
export { default as Xeploainhanvien } from './Xeploainhanvien'
export { default as Cauhinhnghiphep } from './Cauhinhnghiphep'
export { default as Cauhinhpheduyet } from './Cauhinhpheduyet'
export { default as Lydonghiphep } from './Lydonghiphep'
export { default as Phieutangca } from './Phieutangca'
export { default as Emailserver } from './Emailserver'
export { default as Thongbao } from './Thongbao'
export { default as Phanca } from './Phanca'
export { default as Phancatonghop } from './Phancatonghop'
export { default as Themmoinhanvien } from './CreateNhanVien'
export { default as Dulieuchamcong } from './Dulieuchamcong'
export { default as Nhanvien_quyetdinh } from './Nhanvien_quyetdinh'


