import axiosConfig from '../axiosConfig'
//! Đánh giá ứng viên
export const apiGetDanhgia = () => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlyhethong/danhgia/ungvien`,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiNewDanhgia = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlyhethong/danhgia/ungvien',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiEditDanhgia = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlyhethong/danhgia/ungvien',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiDeleteDanhgia = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlyhethong/danhgia/ungvien',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
//! Đánh giá Nhân viên
export const apiGetDanhgiaNhanVien = () => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlynhanvien/danhgianhanvien`,
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
export const apiNewDanhgiaNhanVien = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlynhanvien/danhgianhanvien',
            data: payload
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
export const apiEditDanhgiaNhanVien = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlynhanvien/danhgianhanvien',
            data: payload
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
export const apiDeleteDanhgiaNhanVien = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlynhanvien/danhgianhanvien',
            data: payload
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})