import {Card, Table} from "reactstrap";
import React from "react";
import ColumnChart from "~/components/partials/analytics/chart-column/ColumnChart";

export const ComponentThuNhapBinhQuan = (data) => { //Table 1 : Bảng tông quan
    const dataShow = data?.dataThuNhapBinhQuan?.luongData
    const datatotal_month = data?.dataThuNhapBinhQuan?.total_month
    const luongDataMth = data?.dataThuNhapBinhQuan?.luongDataMth
    const dataChart = data?.dataThuNhapBinhQuan?.luongDataTmpMulChart
    const formatVND = (data) => {
        return Number(data ?? 0).toLocaleString('it-IT', {
            style: 'currency',
            currency: 'vnd',
        });
    };
    
    return <>
        <div className="col-12">
            <Table className="table table-bordered table-striped table-hover">
                <thead key="theadComponentThuNhapBinhQuan">
                <tr>
                    <th className="w-25">Phòng ban</th>
                    {datatotal_month?.length > 0
                        ? datatotal_month?.map((item) => {
                            return (
                                <th className="text-center ">{item}</th>
                            )
                        })
                        : null}
                    <th className="">Bình quân</th>
                </tr>
                </thead>
                <tbody key="tbodyComponentThuNhapBinhQuan">
                {dataShow?.length > 0
                    ? dataShow?.map((item) => {
                        return (
                            <tr>
                                <td
                                    className="fw-bold position-relative">
                                    <div className="position-absolute"
                                         style={{top: "calc(50% - 10px)"}}>{item.namePhongban}
                                    </div>
                                </td>
                                {item?.data?.length > 0
                                    ? item?.data?.map((items) => {
                                        return (
                                            <React.Fragment>
                                                <td className="text-end">{formatVND(items.total)}</td>
                                            </React.Fragment>
                                        )
                                    })
                                    : null}
                                <td className="fw-bold text-end">{formatVND(item?.binhquanPhongban)}</td>
                            </tr>
                        )
                    })
                    : null}
                
                <tr>
                    <td
                        className="fw-bold position-relative">
                        <div className="position-absolute"
                             style={{top: "calc(50% - 15px)"}}>Bình quân
                        </div>
                    </td>
                    {luongDataMth?.length > 0
                        ? luongDataMth?.map((items, index) => {
                            // listItemsCham('item',item)
                            return (
                                <td className="fw-bold text-end">{formatVND(items)}</td>
                            )
                        })
                        : null}
                    <td className="fw-bold text-end">{formatVND((luongDataMth?.reduce((a, v) => a + Number(v), 0) / Number(datatotal_month?.length)).toFixed(0))}</td>
                </tr>
                
                </tbody>
            </Table>
        </div>
        <div className="col-12">
            <div className="py-3">
                <h6 className="overline-title text-primary">
                    Biểu đồ thu nhập bình quân
                </h6>
            </div>
            <Card className="pt-4">
                <ColumnChart
                    columnChart={dataChart}
                />
            </Card>
        </div>
    </>
};
