import axiosConfig from '../axiosConfig'
import axios from "~/axiosConfig";

export const apiGetBangchamcongtonghop = () => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlyhethong/bangchamcongtonghop/quanlycong`,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiGetPayroll = () => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlyhethong/payrolls/quanlycong`,
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
//! Thêm mới dữ liệu Chấm công tổng hơp theo nhân viên
export const apiListBangChamCongTongHopItem = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axios({
            method: 'get',
            url: '/api/v2/quanlyhethong/bangchamcongtonghopitem/quanlycong',
            params: payload
        })
        // console.log('response', response)
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
//! lấy lương làm ba cáo thu nhập binh quân
export const apiDeletetBangChamCongTongHopItem = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axios({
            method: 'delete',
            url: '/api/v2/quanlyhethong/bangchamcongtonghopitem/quanlycong?BangchamcongtonghopId='+payload,
            data: payload
        })
        // console.log('response', response)
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
//! Thêm mới dữ liệu Chấm công tổng hơp theo nhân viên
export const apiAddBangChamCongTongHopItem = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axios({
            method: 'post',
            url: '/api/v2/quanlyhethong/bangchamcongtonghopitem/quanlycong',
            data: payload
        })
        // console.log('response', response)
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
export const apiNewBangchamcongtonghop = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlyhethong/bangchamcongtonghop/quanlycong',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiEditBangchamcongtonghop = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlyhethong/bangchamcongtonghop/quanlycong',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiDeleteBangchamcongtonghop = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlyhethong/bangchamcongtonghop/quanlycong',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
