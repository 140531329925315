import React, { useState, useEffect } from 'react';
import Logo from '~/images/logo.png';
import LogoDark from '~/images/logo-dark-color.png';
import PageContainer from '~/layout/page-container/PageContainer';
import Head from '~/layout/head/Head';
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle } from '~/components/Component';
import { Link, useSearchParams } from 'react-router-dom';
import { verifyEmail } from '~/services';
import { Button, Spinner } from 'reactstrap';

const Success = () => {
    const [searchParams] = useSearchParams();
    const [data, setData] = useState(0);
    const [loatding, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        const params = [];
        for (let entry of searchParams.entries()) {
            params.push(entry);
        }

        if (params.length !== 0) {
            let searchParamsObject = {};
            params?.forEach((i) => {
                if (Object.keys(searchParamsObject).some((item) => item === i[0])) {
                    searchParamsObject[i[0]] = [...searchParamsObject[i[0]], i[1]];
                } else {
                    searchParamsObject[i[0]] = i[1];
                }
            });
            // console.log('searchParamsObject', searchParamsObject);
            const fetchData = async () => {
                const response = await verifyEmail(searchParamsObject);
                if (response) {
                    setData(response.data);
                    setLoading(false);
                }
                // console.log('response', response);
            };
            fetchData().catch(console.error);
        }
    }, [searchParams]);
    return (
        <React.Fragment>
            {/*<Head title="Success" />*/}
            <PageContainer>
                <Block className="nk-block-middle nk-auth-body">
                    <div className="brand-logo pb-5">
                        <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
                            <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
                            <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
                        </Link>
                    </div>
                    <BlockHead>
                        <BlockContent>
                            <BlockTitle tag="h4">{data.mes}</BlockTitle>
                            {loatding && <Spinner color="primary" />}
                            {data.err === 0 ? (
                                <>
                                    <BlockDes className="text-success">
                                        <Link to={`${process.env.PUBLIC_URL}/login`} className="logo-link">
                                            <Button>Đăng nhập</Button>
                                        </Link>
                                    </BlockDes>
                                </>
                            ) : (
                                <></>
                            )}
                        </BlockContent>
                    </BlockHead>
                </Block>
            </PageContainer>
        </React.Fragment>
    );
};
export default Success;
