import actionTypes from './actionTypes'
import { apiGetDanhgianhanvien, apiNewDanhgianhanvien, apiEditDanhgianhanvien, apiDeleteDanhgianhanvien } from '~/services/danhgianhanvien'
import { toast } from "react-toastify";
export const getListDanhgianhanvien = () => async (dispatch) => {
    try {
        const response = await apiGetDanhgianhanvien()
        // console.log('chek api lisst Danhgianhanvien', response?.data.success)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_DANH_GIA_NHAN_VIEN,
                danhgianhanvien: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_DANH_GIA_NHAN_VIEN,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_DANH_GIA_NHAN_VIEN,
            danhgianhanvien: null
        })
    }
}

export const NewDanhgianhanvien = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewDanhgianhanvien', payload)
            const response = await apiNewDanhgianhanvien(payload)
            // console.log('chek api apiNewDanhgianhanvien', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_DANH_GIA_NHAN_VIEN,
                    msg: 'OK'
                });
                dispatch(getListDanhgianhanvien());
                toast.success("Thêm mới đánh giá nhân viên thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_DANH_GIA_NHAN_VIEN,
                    msg: 'ERR'
                })
                toast.error("Thêm mới đánh giá nhân viên thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_DANH_GIA_NHAN_VIEN,
                danhgianhanvien: null
            })
        }
    }
}
export const EditDanhgianhanvien = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditDanhgianhanvien(payload)
            // console.log('chek api apiEditDanhgianhanvien', response)
            if (response?.data.success === 200) {
                dispatch(getListDanhgianhanvien());
                toast.success("Sửa thông tin đánh giá nhân viên thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_DANH_GIA_NHAN_VIEN,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_DANH_GIA_NHAN_VIEN,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin đánh giá nhân viên thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_DANH_GIA_NHAN_VIEN,
                danhgianhanvien: null
            })
        }
    }
}
export const DeleteDanhgianhanvien = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteDanhgianhanvien(payload)
            // console.log('chek api apiDeleteDanhgianhanvien', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListDanhgianhanvien());
                toast.success("Xóa thông tin đánh giá nhân viên thành công!")
                dispatch({
                    type: actionTypes.DELETE_DANH_GIA_NHAN_VIEN,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_DANH_GIA_NHAN_VIEN,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin đánh giá nhân viên thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_DANH_GIA_NHAN_VIEN,
                danhgianhanvien: null
            })
        }
    }
}