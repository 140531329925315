import React, { useEffect, useMemo, useState } from 'react';
import { apiListChinhSuaBangChamCong } from '~/services';
import Head from '~/layout/head/Head';
import Content from '~/layout/content/Content';
import { Block } from '~/components/block/Block';
import {Button, DataTable, Icon} from '~/components/Component';

import { Card } from 'reactstrap';
import { MaterialReactTable } from 'material-react-table';
import { Box } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import { MenuBangCong } from '~/pages/Admin/MenuTab/Menucontent';
import {MRT_Localization_VI} from 'material-react-table/locales/vi';
import {format, parseISO} from "date-fns";

const Chinhsuachamcong = () => {
    // const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    //table state
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    // const [selectedRowsCount, setSelectedRowsCount] = useState({});
    // useEffect(() => {
    //     //do something when the row selection changes...
    //     console.info({ selectedRowsCount });
    // }, [selectedRowsCount]);
    useEffect(() => {
        const fetchData = async () => {
            if (!data.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }
            try {
                let searchParamsObject = {
                    page: `${Number(pagination.pageIndex)}`,
                    limit: `${pagination.pageSize}`,
                    order: 'asc',
                    by: 'id',
                };
                console.log('searchParamsObject', searchParamsObject);
                const Chinhsuachamcong = await apiListChinhSuaBangChamCong(searchParamsObject);
                const response = Chinhsuachamcong?.data?.data[0]?.rows;
                setData(response);
                setRowCount(Chinhsuachamcong?.data?.data[0]?.count);
            } catch (error) {
                setIsError(true);
                // console.error('Error fetching data:', error);
                return;
            }
            setIsError(false);
            setIsLoading(false);
            setIsRefetching(false);
        };
        fetchData();
    }, [pagination.pageIndex, pagination.pageSize]);
    const Example = () => {
        const dataAb = data;
        const columns = useMemo(
            () => [
                {
                    Header: 'STT', // Tiêu đề cột số thứ tự
                    id: 'rowNumber', // ID của cột
                    Cell: ({ row }) => <div>{row.index + 1}</div>, // Giá trị của cột là số thứ tự
                    size: 100, // Độ rộng của cột số thứ tự
                    enableSorting: false,
                    enableColumnActions: false,
                },
                {
                    accessorKey: 'Nhanvien.hoten',
                    header: 'Họ tên',
                    // enablePinning: true,
                },
                {
                    accessorKey: 'Nhanvien.uuid',
                    header: 'Mã nhân viên',
                    // enablePinning: true,
                },
                {
                    accessorKey: 'day',
                    header: 'Ngày chấm công',
                    // enablePinning: true,
                },
                {
                    accessorKey: 'vao1',
                    header: 'Vào 1',
                },
                {
                    accessorKey: 'ra1',
                    header: 'Ra 1',
                },
               
                {
                    accessorKey: 'tonggio',
                    header: 'Tổng giờ',
                },
                {
                    accessorKey: 'cong',
                    header: 'Công được tính',
                },
                {
                    accessorKey: 'ghichu',
                    header: 'Ghi chú',
                },
            
                {
                    accessorFn: (row) => `${format(parseISO(row?.updatedAt), 'HH:mm dd-MM-yyyy')}`,
                    id: 'updatedAt',
                    header: 'Lần cập nhật cuối',
                },
                
            ],
            [],
        );
        const csvOptions = { fileName: 'csv-data',
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            useBom: true,
            useKeysAsHeaders: false,
            headers: columns.map((c) => c.header),
        };

        const csvExporter = new ExportToCsv(csvOptions);
        // const dataAb = data;
        const handleExportData = () => {
            csvExporter.generateCsv(dataAb);
        };

        const CustomActions = React.memo(({ table }) => {
            return (
                <Box sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}>
                    <Button
                        color="primary"
                        onClick={handleExportData}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        <Icon name="download-cloud me-2"></Icon> Xuất dữ liệu
                    </Button>
                    
                </Box>
            );
        });
        return (
            <MaterialReactTable
                columns={columns}
                data={dataAb}
                state={{isLoading: !dataAb}}
                localization={MRT_Localization_VI}
                initialState={{
                    columnPinning: { left: ['rowNumber','Nhanvien.hoten', 'Nhanvien.uuid', 'day'] },
                    showColumnFilters: false,
                }}
                enableFullScreenToggle={false}
                renderTopToolbarCustomActions={(props) => <CustomActions {...props} />}
            />
        );
    };

    return (
        <React.Fragment>
            {/*<Head title="User List - Regular"></Head>*/}
            <Content>
                <Card className="mt-0">
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner card-inner-lg px-4 pt-1 pb-0">
                            <h4 className="mt-2">Quản lý chấm công</h4>
                            <MenuBangCong />
                            <h5 className="mt-4"> Chỉnh sửa công</h5>
                        </div>
                    </DataTable>
                </Card>
                <Block>
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner position-relative card-tools-toggle">
                            <Example />
                        </div>
                    </DataTable>
                </Block>
            </Content>
        </React.Fragment>
    );
};

export default Chinhsuachamcong;
