import axiosConfig from '../axiosConfig'

export const apiGetCongty = () => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlyhethong/congty/cauhinhdoanhnghiep`,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiNewCongty = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlyhethong/congty/cauhinhdoanhnghiep',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiEditCongty = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlyhethong/congty/cauhinhdoanhnghiep',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiDeleteCongty = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlyhethong/congty/cauhinhdoanhnghiep',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiSendMail = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlyhethong/sendmailcong/quanlycong',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiSendMailByLuong = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlyhethong/sendmailluong/quanlycong',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
