import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '~/store/actions';
import { ModalNhomnv, ModalEditNhomnv } from './Modal';
import Swal from 'sweetalert2';
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalBody,
    UncontrolledDropdown,
    Spinner,
    Card,
} from 'reactstrap';
import Head from '~/layout/head/Head';
import Content from '~/layout/content/Content';
import { Block } from '~/components/block/Block';
import {
    Button,
    Col,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    Icon,
    PaginationComponent,
    Row,
    RSelect,
    TooltipComponent,
} from '~/components/Component';
import { Link } from 'react-router-dom';
import config from '~/config';
import { MenuDoanhnghiep } from '~/pages/Admin/MenuTab/Menucontent';

const Nhomnv = () => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [showEditNhomnv, setShowEditNhomnv] = useState(false);
    useEffect(() => {
        dispatch(actions.getListNhomnv());
    }, []);
    //lấy giá trị từ thằng redux trả về
    const { nhomnv } = useSelector((state) => state.nhanvien);
    const [data, setData] = useState(nhomnv);
    const fieldList = {
        tennhom: '',
        motanhom: '',
        trangthai: '',
    };
    useEffect(() => {
        if (nhomnv && nhomnv.length > 0) {
            setData([...nhomnv]);
        }
    }, [nhomnv]);
    console.log('nhosmnv', nhomnv);
    const [sm, updateSm] = useState(false);
    const [tablesm, updateTableSm] = useState(false);
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [sort, setSortState] = useState('');
    const onFilterChange = (e) => {
        setSearchText(e.target.value);
    };
    const toggle = () => setonSearch(!onSearch);
    const selectorCheck = (e) => {
        let newData;
        newData = data.map((item) => {
            item.checked = e.currentTarget.checked;
            return item;
        });
        setData([...newData]);
    };
    const onSelectChange = (e, id) => {
        let newData = data;
        let index = newData.findIndex((item) => item.id === id);
        newData[index].checked = e.currentTarget.checked;
        setData([...newData]);
    };
    useEffect(() => {
        if (onSearchText !== '') {
            const filteredObject = data.filter((item) => {
                return item.tennhom.toLowerCase().includes(onSearchText.toLowerCase());
            });
            setData([...filteredObject]);
        } else {
            setData([...data]);
        }
    }, [onSearchText, setData]);
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    useEffect(() => {
        (nhomnv && setShow(false)) || setShowEditNhomnv(false);
    }, [nhomnv]);
    // set state để mở đóng Modal
    const handleAddNewNhomnv = async () => {
        setShow(true);
    };
    const [dataIdNhomnv, setDataIdNhomnv] = useState([]);
    const handleEditNhomnv = async (data) => {
        setShowEditNhomnv(true);
        await setDataIdNhomnv(data);
    };
    const handleDeleteNhomnv = (data) => {
        Swal.fire({
            title: 'Bạn có chắc không?',
            text: 'Bạn sẽ không thể lấy lại khi xóa!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Không, bỏ qua!',
            confirmButtonText: 'Vâng, xóa nó đi!',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(actions.DeleteNhomnv(data));
            }
        });
    };
    return (
        <React.Fragment>
            {/*<Head title="Doanh nghiệp, Chi nhánh, Phòng ban, Chức Vụ"></Head>*/}
            <Content>
                <Card className="mt-0">
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner card-inner-lg px-4">
                            <h4>Cấu hình doanh nghiệp</h4>
                            <MenuDoanhnghiep />
                        </div>
                    </DataTable>
                </Card>

                <Block>
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner position-relative card-tools-toggle pt-0">
                            <div className="card-title-group">
                                <div className="card-title">
                                    <h5 className="title">Nhóm nhân viên</h5>
                                    <Button color="primary" className="btn-icon px-4 mt-1" onClick={handleAddNewNhomnv}>
                                        <Icon name="plus"></Icon> Cập nhật
                                    </Button>
                                </div>
                                {/* <div className="card-tools">
                                    <ul className="btn-toolbar gx-1">
                                        <li>
                                            <a
                                                href="#search"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    toggle();
                                                }}
                                                className="btn btn-icon search-toggle toggle-search"
                                            >
                                                <Icon name="search"></Icon>
                                            </a>
                                        </li>
                                        <li className="btn-toolbar-sep"></li>
                                        <li>
                                            <div className="toggle-wrap">
                                                <Button
                                                    className={`btn-icon btn-trigger toggle ${tablesm ? 'active' : ''}`}
                                                    onClick={() => updateTableSm(true)}
                                                >
                                                    <Icon name="menu-right"></Icon>
                                                </Button>
                                                <div className={`toggle-content ${tablesm ? 'content-active' : ''}`}>
                                                    <ul className="btn-toolbar gx-1">
                                                        <li className="toggle-close">
                                                            <Button
                                                                className="btn-icon btn-trigger toggle"
                                                                onClick={() => updateTableSm(false)}
                                                            >
                                                                <Icon name="arrow-left"></Icon>
                                                            </Button>
                                                        </li>
                                                        <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle
                                                                    color="tranparent"
                                                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                                                >
                                                                    <Icon name="setting"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu end className="dropdown-menu-xs">
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Show</span>
                                                                        </li>
                                                                        <li
                                                                            className={
                                                                                itemPerPage === 10 ? 'active' : ''
                                                                            }
                                                                        >
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setItemPerPage(10);
                                                                                }}
                                                                            >
                                                                                10
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li
                                                                            className={
                                                                                itemPerPage === 15 ? 'active' : ''
                                                                            }
                                                                        >
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setItemPerPage(15);
                                                                                }}
                                                                            >
                                                                                15
                                                                            </DropdownItem>
                                                                        </li>
                                                                    </ul>
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Order</span>
                                                                        </li>
                                                                        <li className={sort === 'dsc' ? 'active' : ''}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setSortState('dsc');
                                                                                    //   sortFunc("dsc");
                                                                                }}
                                                                            >
                                                                                DESC
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li className={sort === 'asc' ? 'active' : ''}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setSortState('asc');
                                                                                    //   sortFunc("asc");
                                                                                }}
                                                                            >
                                                                                ASC
                                                                            </DropdownItem>
                                                                        </li>
                                                                    </ul>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                       
                                    </ul>
                                </div> */}
                            </div>
                            <div className={`card-search search-wrap ${!onSearch && 'active'}`}>
                                <div className="card-body">
                                    <div className="search-content">
                                        <Button
                                            className="search-back btn-icon toggle-search active"
                                            onClick={() => {
                                                setSearchText('');
                                                toggle();
                                            }}
                                        >
                                            <Icon name="arrow-left"></Icon>
                                        </Button>
                                        <input
                                            type="text"
                                            className="border-transparent form-focus-none form-control"
                                            placeholder="Tìm kiếm"
                                            value={onSearchText}
                                            onChange={(e) => onFilterChange(e)}
                                        />
                                        <Button className="search-submit btn-icon">
                                            <Icon name="search"></Icon>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ overflowY: 'hidden' }}>
                        <DataTableBody>
                            <DataTableHead>
                                <DataTableRow className="nk-tb-col-check">
                                    <div className="custom-control custom-control-sm custom-checkbox notext">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            onChange={(e) => selectorCheck(e)}
                                            id="uid"
                                        />
                                        <label className="custom-control-label" htmlFor="uid"></label>
                                    </div>
                                </DataTableRow>
                                <DataTableRow>
                                    <span className="sub-text w-150px">Tên nhóm</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span className="sub-text w-150px">Mô tả hoạt động</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span className="sub-text w-150px">Số lượng nhân viên</span>
                                </DataTableRow>
                                {/*<DataTableRow>*/}
                                {/*    <span className="sub-text">Danh sách nhân viên</span>*/}
                                {/*</DataTableRow>*/}
                                <DataTableRow>
                                    <span className="sub-text w-100px">Trạng thái</span>
                                </DataTableRow>
                                <DataTableRow className="nk-tb-col-tools text-end">
                                    <UncontrolledDropdown>
                                        <DropdownToggle
                                            color="tranparent"
                                            className="btn btn-xs btn-outline-light btn-icon dropdown-toggle"
                                        >
                                            <Icon name="plus"></Icon>
                                        </DropdownToggle>
                                        <DropdownMenu end className="dropdown-menu-xs">
                                            <ul className="link-tidy sm no-bdr">
                                                <li>
                                                    <div className="custom-control custom-control-sm custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="st"
                                                        />
                                                        <label className="custom-control-label" htmlFor="st">
                                                            Status
                                                        </label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </DataTableRow>
                            </DataTableHead>
                            {currentItems.length > 0
                                ? currentItems.map((item, index) => {
                                    const displayedIndex =  itemPerPage * (currentPage - 1) + index + 1;
                                      return (
                                          <DataTableItem key={item.id}>
                                              <DataTableRow className="nk-tb-col-check">
                                                  <div className="custom-control custom-control-sm custom-checkbox notext">
                                                      <input
                                                          type="checkbox"
                                                          className="custom-control-input"
                                                          defaultChecked={item.checked}
                                                          id={item.id + 'uid1'}
                                                          key={Math.random()}
                                                          onChange={(e) => onSelectChange(e, item.id)}
                                                      />
                                                      <label
                                                          className="custom-control-label"
                                                          htmlFor={item.id + 'uid1'}
                                                      ></label>
                                                  </div>
                                              </DataTableRow>

                                              <DataTableRow>
                                                  <span className="tb-amount">{item?.tennhom}</span>
                                              </DataTableRow>
                                              <DataTableRow>
                                                  <span className="tb-amount">{item?.motanhom}</span>
                                              </DataTableRow>
                                              <DataTableRow>
                                                  <span className="tb-amount">{item?.Nhanviens?.length}</span>
                                              </DataTableRow>
                                              {/*<DataTableRow>*/}
                                              {/*    <button className="bg-primary ms-0 badge text-white">Danh sách nhân viên</button>*/}
                                              {/*</DataTableRow>*/}
                                              <DataTableRow>
                                                  <span
                                                      className={`${
                                                          item?.trangthai === true ? 'bg-success' : 'bg-danger'
                                                      } ms-0 badge text-white`}
                                                  >
                                                      {item?.trangthai === true ? 'Hoạt Động' : 'Dừng Hoạt Động'}
                                                  </span>
                                              </DataTableRow>
                                              <DataTableRow className="nk-tb-col-tools">
                                                  <ul className="nk-tb-actions gx-1">
                                                      <li
                                                          className="nk-tb-action-hidden"
                                                          onClick={() => handleEditNhomnv(item)}
                                                      >
                                                          <TooltipComponent
                                                              tag="a"
                                                              containerClassName="btn btn-trigger btn-icon"
                                                              id={'edit' + item.id}
                                                              icon="edit-alt-fill"
                                                              direction="top"
                                                              text="Sửa"
                                                          />
                                                      </li>
                                                      <React.Fragment>
                                                          <li
                                                              className="nk-tb-action-hidden"
                                                              onClick={() => handleDeleteNhomnv(item)}
                                                          >
                                                              <TooltipComponent
                                                                  tag="a"
                                                                  containerClassName="btn btn-trigger btn-icon"
                                                                  id={'suspend' + item.id}
                                                                  icon="user-cross-fill"
                                                                  direction="top"
                                                                  text="Xóa"
                                                              />
                                                          </li>
                                                      </React.Fragment>
                                                      <li>
                                                          <UncontrolledDropdown>
                                                              <DropdownToggle
                                                                  tag="a"
                                                                  className="dropdown-toggle btn btn-icon btn-trigger"
                                                              >
                                                                  <Icon name="more-h"></Icon>
                                                              </DropdownToggle>
                                                              <DropdownMenu end>
                                                                  <ul className="link-list-opt no-bdr">
                                                                      <li onClick={() => handleEditNhomnv(item)}>
                                                                          <DropdownItem
                                                                              tag="a"
                                                                              href="#edit"
                                                                              onClick={(ev) => {
                                                                                  ev.preventDefault();
                                                                              }}
                                                                          >
                                                                              <Icon name="edit"></Icon>
                                                                              <span>Sửa</span>
                                                                          </DropdownItem>
                                                                      </li>
                                                                      {/* {item.status !== "Suspend" && ( */}
                                                                      <React.Fragment>
                                                                          <li className="divider"></li>
                                                                          <li onClick={() => handleDeleteNhomnv(item)}>
                                                                              <DropdownItem
                                                                                  tag="a"
                                                                                  href="#suspend"
                                                                                  onClick={(ev) => {
                                                                                      ev.preventDefault();
                                                                                  }}
                                                                              >
                                                                                  <Icon name="na"></Icon>
                                                                                  <span>Xóa</span>
                                                                              </DropdownItem>
                                                                          </li>
                                                                      </React.Fragment>
                                                                      {/* )} */}
                                                                  </ul>
                                                              </DropdownMenu>
                                                          </UncontrolledDropdown>
                                                      </li>
                                                  </ul>
                                              </DataTableRow>
                                          </DataTableItem>
                                      );
                                  })
                                : null}
                        </DataTableBody>
                        </div>
                        <div className="card-inner">
                            {currentItems.length > 0 ? (
                                <PaginationComponent
                                    itemPerPage={itemPerPage}
                                    totalItems={nhomnv.length}
                                    paginate={paginate}
                                    currentPage={currentPage}
                                />
                            ) : (
                                <div className="text-center">
                                    <div>
                                        <Spinner color="primary" />
                                    </div>
                                    <span className="text-silent">Chưa có dữ liệu!</span>
                                </div>
                            )}
                        </div>
                    </DataTable>
                </Block>

                <ModalNhomnv show={show} fieldList={fieldList} setShow={() => setShow(false)} />
                <ModalEditNhomnv
                    show={showEditNhomnv}
                    fieldList={fieldList}
                    setShow={() => setShowEditNhomnv(false)}
                    dataIdNhomnv={dataIdNhomnv}
                />
            </Content>
        </React.Fragment>
    );
};

export default Nhomnv;
