export const validatePhanCa = (fieldName, value) => {
    let regNumber = /^[0-9\b]+$/;
    switch (fieldName) {
        case 'tieude':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống.'
                }
            }
            break;
        
        case 'ngaybatdau':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống.'
                }
            }
            break;
        case 'ngayketthuc':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống.'
                }
            }
            break;
        // case 'laptheo':
        //     if (value.length === 0) {
        //         return {
        //             name: fieldName,
        //             message: 'Nội dung không được để trống.'
        //         }
        //     }
        //     break;
        case 'NhanvienId':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống.'
                }
            }
            break;
        case 'KhaibaocalamviecId':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống.'
                }
            }
            break;
        
        default:
            break;
    }
}