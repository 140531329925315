import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '~/store/actions';
import { ModalTaisan, ModalEditTaisan } from './Modal';
import Swal from 'sweetalert2';
import { NumericFormat } from 'react-number-format';
import { format, parseISO } from 'date-fns';
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    Spinner,
    Card,
    Modal,
    ModalBody,
} from 'reactstrap';
import Head from '~/layout/head/Head';
import Content from '~/layout/content/Content';
import { Block } from '~/components/block/Block';
import {
    Button,
    Col,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    Icon,
    Row,
    RSelect,
    TooltipComponent,
    ExportXlsx,
    PaginationCount,
    PermissionUtils,
} from '~/components/Component';
import { nhomTaisan, trangthaiTaisan } from '~/utils/Datacustom';
import { Link, useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { InputForm } from '~/components/Form';

const Taisan = () => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [showEditTaisan, setShowEditTaisan] = useState(false);
    useEffect(() => {
        dispatch(actions.getListTaisan());
    }, []);

    const [showPhanquyen, setShowPhanquyen] = useState({});
    let dataPermissionUtils = PermissionUtils('taisan', 'all');
    useEffect(() => {
        if (dataPermissionUtils) {
            setShowPhanquyen(dataPermissionUtils);
        }
    }, [dataPermissionUtils]);

    //lấy giá trị từ thằng redux trả về
    const { taisan, count } = useSelector((state) => state.taisan);
    const [data, setData] = useState(taisan);
    const [dataTmp, setDataTmp] = useState([]);
    const [listTrangthaiSelect, setlistTrangthaiSelect] = useState([]);
    const [listNhomtaisanSelect, setlistNhomtaisanSelect] = useState([]);
    const [payload, setPayload] = useState();
    const [invalidFields, setInvalidFields] = useState([]);

    const namefile = 'Thông tin tài sản';
    const header = [
        'Stt',
        'Tên tài sản',
        'Mã tài sản',
        'Nhóm tài sản',
        'Ngày mua',
        'Số lượng',
        'Còn lại/ Tổng số',
        'Nguyên giá',
        'Trạng thái',
    ];
    const [columns, setColumns] = useState(() => {
        const d = header.map((item, index) => index <= 6 || index >= 8);
        return d;
    });
    const [dataFileMau, setDataFileMau] = useState([]);
    useEffect(() => {
        if (data.length > 0) {
            const datafilemau = data.map((item, index) => {
                let nhomts = nhomTaisan?.find((items) => {
                    return items && items.id === item?.NhomtaisanId;
                });
                return {
                    ...(columns[0] && { Stt: index + 1 }),
                    ...(columns[1] && { 'Tên tài sản': item?.tentaisan }),
                    ...(columns[2] && { 'Mã tài sản': item?.mataisan }),
                    ...(columns[3] && { 'Nhóm tài sản': nhomts?.tenNhomtaisan }),
                    ...(columns[4] && { 'Ngày mua': item?.ngaymua
                    ? format(parseISO(item?.ngaymua), 'dd-MM-yyyy')
                    : 'NAV' }),
                    ...(columns[5] && { 'Số lượng': item?.soluong }),
                    ...(columns[6] && { 'Còn lại/ Tổng số': item?.soluongconlai + '/' + item?.soluong }),
                    ...(columns[7] && { 'Nguyên giá': item?.giatri }),
                    ...(columns[8] && { 'Trạng thái': item?.TrangthaiId === 1 ? 'Đang hoạt động' : 'Chưa cấp phát' }),
                };
            });
            setDataFileMau(datafilemau);
        }
    }, [data, columns]);

    const fieldList = {
        mataisan: '',
        tentaisan: '',
        soluong: '',
        NhomtaisanId: '',
        donvitaisan: '',
        doi: '',
        vitritaisan: '',
        ngaymua: '',
        thoihanbaohanh: '',
        giatri: '',
        khauhao: '',
        giatriformat: '',
        nhacungcap: '',
        sodienthoai: '',
        diachi: '',
        ghichu: '',
        TrangthaiId: '',
    };
    useEffect(() => {
        if (taisan && taisan.length > 0) {
            setData([...taisan]);
            setDataTmp([...taisan]);
            let TrangthaiId = trangthaiTaisan.map((item) => ({ label: item.tentrangthai, value: item.id }));
            let NhomtaisanId = nhomTaisan.map((item) => ({ label: item.tenNhomtaisan, value: item.id }));
            setlistTrangthaiSelect(TrangthaiId);
            setlistNhomtaisanSelect(NhomtaisanId);
        }
    }, [taisan]);
    const [sm, updateSm] = useState(false);
    const [tablesm, updateTableSm] = useState(false);
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [sort, setSortState] = useState('asc');
    // Xóa params
    const navigate = useNavigate();
    const location = useLocation();
    function handleReParamsClick() {
        navigate(location.pathname);
        //  setPayload(prev => ({ ...prev, ['loaihopdong']: '' }))
    }
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const params = [];
        for (let entry of searchParams.entries()) {
            params.push(entry);
        }
        if (params.length !== 0) {
            let searchParamsObject = {
                limit: itemPerPage,
                order: sort,
                by: 'id',
            };
            params?.forEach((i) => {
                if (Object.keys(searchParamsObject).some((item) => item === i[0])) {
                    searchParamsObject[i[0]] = [...searchParamsObject[i[0]], i[1]];
                } else {
                    searchParamsObject[i[0]] = i[1];
                }
            });
            // console.log('searchParamsObject', searchParamsObject)
            dispatch(actions.getListTaisan(searchParamsObject));
            setCurrentPage(searchParamsObject?.page);
        } else {
            let searchParamsObject = { page: '1', limit: itemPerPage, order: sort, by: 'id' };
            //  console.log('searchParamsObject', searchParamsObject)
            dispatch(actions.getListTaisan(searchParamsObject));
            setCurrentPage(1);
        }
    }, [searchParams, itemPerPage, sort]);

    // onChange function for searching name
    const onFilterChange = (e) => {
        setSearchText(e.target.value);
    };
    // function to toggle the search option
    const toggle = () => setonSearch(!onSearch);
    // function to change the check property of an item
    const selectorCheck = (e) => {
        let newData;
        newData = data.map((item) => {
            item.checked = e.currentTarget.checked;
            return item;
        });
        setData([...newData]);
    };
    // function to change the selected property of an item
    const onSelectChange = (e, id) => {
        let newData = data;
        let index = newData.findIndex((item) => item.id === id);
        newData[index].checked = e.currentTarget.checked;
        setData([...newData]);
    };
    // console.log('data',data)
    // Changing state value when searching name
    useEffect(() => {
        // console.log('TrangthaiId',payload)
        let filteredObject = [];

        if (onSearchText !== '') {
            filteredObject = data.filter((item) => {
                return (
                    item.tentaisan.toLowerCase().includes(onSearchText.toLowerCase()) ||
                    item.nhacungcap.toLowerCase().includes(onSearchText.toLowerCase()) ||
                    item.mataisan.toLowerCase().includes(onSearchText.toLowerCase())
                );
            });
            setData([...filteredObject]);
        } else {
            switch (true) {
                case payload?.TrangthaiId?.value !== undefined:
                    let cvId = payload?.TrangthaiId?.value;
                    filteredObject = dataTmp.filter((item) => item.TrangthaiId === cvId);
                    break;
                case payload?.NhomtaisanId?.value !== undefined:
                    let pbId = payload?.NhomtaisanId?.value;
                    filteredObject = dataTmp.filter((item) => item.NhomtaisanId === pbId);
                    break;
                default:
                    filteredObject = dataTmp;
                    break;
            }
            setData([...filteredObject]);
        }
    }, [onSearchText, setData, payload]);

    // console.log('list cong ty', taisan)
    useEffect(() => {
        (taisan && setShow(false)) || setShowEditTaisan(false);
    }, [taisan]);
    // set state để mở đóng Modal
    const handleAddNewTaisan = async () => {
        setShow(true);
    };
    const [dataIdTaisan, setDataIdTaisan] = useState([]);
    const [handleEditDetail, setHandleEditDetail] = useState(false);
    // console.log('handleEditDetail', handleEditDetail)
    const handleEditTaisan = async (data) => {
        setShowEditTaisan(true);
        await setDataIdTaisan(data);
    };
    const handleSubmit = async () => {
        let dataFilter = data.filter((item) => {
            return item.ngaymua >= payload?.ngaybatdau && item.ngaymua <= payload?.ngayketthuc;
        });
        // console.log('dataFilter', dataFilter)
        setData([...dataFilter]);
    };

    const handleDeleteTaisan = (data) => {
        Swal.fire({
            title: 'Bạn có chắc không?',
            text: 'Bạn sẽ không thể lấy lại khi xóa!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Không, bỏ qua!',
            confirmButtonText: 'Vâng, xóa nó đi!',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(actions.DeleteTaisan(data));
            }
        });
    };
    const itemsPerPageOptions = [10, 20, 30, 40, 50, 100, 300, 500];
    // <Button
    //     color="primary"
    //     className="btn-icon px-1"
    //     onClick={handleAddNewTaisan}
    // >
    //     <Icon name="plus"></Icon> Thêm mới tài sản
    // </Button>
    return (
        <React.Fragment>
            <Head title="Quản lý Kho VPP, Tài Sản"></Head>
            <Content>
                <Block>
                    <DataTable className="card-stretch">
                        <div className="card-inner position-relative card-tools-toggle">
                            <div className="card-title-group row">
                                <div className="card-title col-7">
                                <h4>Quản lý Kho VPP, Tài Sản</h4>
                                    {+showPhanquyen?.them === 1 && (
                                        <Button color="primary" className="btn-icon px-4 mt-2" onClick={handleAddNewTaisan}>
                                            <Icon name="plus"></Icon> Thêm mới
                                        </Button>
                                    )}
                                </div>
                                <div className="card-tools me-n1 col-5">
                                    <ul className="btn-toolbar gx-1 float-end">
                                        <li>
                                            <a
                                                href="#search"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    toggle();
                                                }}
                                                className="btn btn-icon search-toggle toggle-search"
                                            >
                                                <Icon name="search"></Icon>
                                            </a>
                                        </li>
                                        <li className="btn-toolbar-sep"></li>
                                        <li>
                                            <div className="toggle-wrap">
                                                <Button
                                                    className={`btn-icon btn-trigger toggle ${tablesm ? 'active' : ''}`}
                                                    onClick={() => updateTableSm(true)}
                                                >
                                                    <Icon name="menu-right"></Icon>
                                                </Button>
                                                <div className={`toggle-content ${tablesm ? 'content-active' : ''}`}>
                                                    <ul className="btn-toolbar gx-1">
                                                        <li className="toggle-close">
                                                            <Button
                                                                className="btn-icon btn-trigger toggle"
                                                                onClick={() => updateTableSm(false)}
                                                            >
                                                                <Icon name="arrow-left"></Icon>
                                                            </Button>
                                                        </li>
                                                        <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle
                                                                    tag="a"
                                                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                                                >
                                                                    <div className="dot dot-primary"></div>
                                                                    <Icon name="filter-alt"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu
                                                                    end
                                                                    className="filter-wg dropdown-menu-xl"
                                                                    style={{ overflow: 'visible' }}
                                                                >
                                                                    <div className="dropdown-head">
                                                                        <span className="sub-title dropdown-title">
                                                                            Bộ lọc
                                                                        </span>
                                                                        <div className="dropdown">
                                                                            <a
                                                                                href="#more"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                }}
                                                                                className="btn btn-sm btn-icon"
                                                                            >
                                                                                <Icon name="more-h"></Icon>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    <div className="dropdown-body dropdown-body-rg">
                                                                        <Row className="gx-6 gy-3">
                                                                        <div className="form-group ">
                                                                            <InputForm
                                                                                setInvalidFields={setInvalidFields}
                                                                                invalidFields={invalidFields}
                                                                                label={'Ngày mua'}
                                                                                value={payload?.ngaybatdau}
                                                                                setValue={setPayload}
                                                                                keyPayload={'ngaybatdau'}
                                                                                type="date"
                                                                            />
                                                                        </div>
                                                                        <div className="form-group ">
                                                                            <InputForm
                                                                                setInvalidFields={setInvalidFields}
                                                                                invalidFields={invalidFields}
                                                                                label={'Ngày kết thúc'}
                                                                                value={payload?.ngayketthuc}
                                                                                setValue={setPayload}
                                                                                keyPayload={'ngayketthuc'}
                                                                                type="date"
                                                                            />
                                                                        </div>
                                                                        <div className="form-group  my-auto text-center">
                                                                            <Button color="primary" className="btn-icon px-1" onClick={handleSubmit}>
                                                                                Tra cứu theo ngày
                                                                            </Button>
                                                                        </div>
                                                                            <Col size="12">
                                                                                <div className="form-group">
                                                                                    <label className="overline-title overline-title-alt">
                                                                                        Nhóm tài sản
                                                                                    </label>
                                                                                    <RSelect
                                                                                        options={listNhomtaisanSelect}
                                                                                        onChange={(selected) => {
                                                                                            setPayload([]);
                                                                                            setPayload((prev) => ({
                                                                                                ...prev,
                                                                                                ['NhomtaisanId']:
                                                                                                    selected,
                                                                                            }));
                                                                                        }}
                                                                                        placeholder="Nhóm tài sản"
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col size="12">
                                                                                <div className="form-group">
                                                                                    <label className="overline-title overline-title-alt">
                                                                                        Trạng thái
                                                                                    </label>
                                                                                    <RSelect
                                                                                        options={listTrangthaiSelect}
                                                                                        onChange={(selected) => {
                                                                                            setPayload([]);
                                                                                            setPayload((prev) => ({
                                                                                                ...prev,
                                                                                                ['TrangthaiId']:
                                                                                                    selected,
                                                                                            }));
                                                                                        }}
                                                                                        placeholder="Trạng thái"
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                        <li>
                                                            <UncontrolledDropdown>
                                                                {(+showPhanquyen?.xem === 1 ||
                                                                    +showPhanquyen?.sua === 1 ||
                                                                    +showPhanquyen?.xoa === 1) && (
                                                                    <DropdownToggle
                                                                        tag="a"
                                                                        className="dropdown-toggle btn btn-icon btn-trigger"
                                                                    >
                                                                        <Icon name="setting"></Icon>
                                                                    </DropdownToggle>
                                                                )}
                                                                <DropdownMenu end className="dropdown-menu-xs">
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Show</span>
                                                                        </li>
                                                                        {itemsPerPageOptions.map((item, index) => {
                                                                            return (
                                                                                <li
                                                                                    className={
                                                                                        itemPerPage === item
                                                                                            ? 'active'
                                                                                            : ''
                                                                                    }
                                                                                    key={index}
                                                                                >
                                                                                    <DropdownItem
                                                                                        tag="a"
                                                                                        href="#dropdownitem"
                                                                                        onClick={(ev) => {
                                                                                            ev.preventDefault();
                                                                                            setItemPerPage(item);
                                                                                            // handleReParamsClick();
                                                                                        }}
                                                                                    >
                                                                                        {item}
                                                                                    </DropdownItem>
                                                                                </li>
                                                                            );
                                                                        })}
                                                                    </ul>
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Order</span>
                                                                        </li>
                                                                        <li className={sort === 'asc' ? 'active' : ''}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setSortState('asc');
                                                                                    handleReParamsClick();
                                                                                }}
                                                                            >
                                                                                ASC
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li className={sort === 'desc' ? 'active' : ''}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setSortState('desc');
                                                                                    handleReParamsClick();
                                                                                }}
                                                                            >
                                                                                DESC
                                                                            </DropdownItem>
                                                                        </li>
                                                                    </ul>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="toggle-wrap nk-block-tools-toggle">
                                                <Button
                                                    className={`btn-icon btn-trigger toggle-expand me-n1 ${
                                                        sm ? 'active' : ''
                                                    }`}
                                                    onClick={() => updateSm(!sm)}
                                                >
                                                    <Icon name="upload-cloud"></Icon>
                                                </Button>
                                                <div
                                                    className="toggle-expand-content-cloud"
                                                    style={{ display: sm ? 'block' : 'none' }}
                                                >
                                                    <ul className="nk-block-tools g-3">
                                                        <li className="nk-block-tools-opt">
                                                            <ExportXlsx
                                                                dataFileMau={dataFileMau}
                                                                title={header}
                                                                namefile={namefile}
                                                                btnname={'Xuất dữ liệu'}
                                                            />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className={`card-search search-wrap ${!onSearch && 'active'}`}>
                                <div className="card-body">
                                    <div className="search-content">
                                        <Button
                                            className="search-back btn-icon toggle-search active"
                                            onClick={() => {
                                                setSearchText('');
                                                toggle();
                                            }}
                                        >
                                            <Icon name="arrow-left"></Icon>
                                        </Button>
                                        <input
                                            type="text"
                                            className="border-transparent form-focus-none form-control"
                                            placeholder="Tìm kiếm theo tên, mã, đời tài sản"
                                            value={onSearchText}
                                            onChange={(e) => onFilterChange(e)}
                                        />
                                        <Button className="search-submit btn-icon">
                                            <Icon name="search"></Icon>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ overflowY: 'hidden' }}>
                        <DataTableBody>
                            <DataTableHead>
                                {columns[0] && (
                                    <DataTableRow>
                                        <span className="sub-text">Stt</span>
                                    </DataTableRow>
                                )}
                                <DataTableRow className="nk-tb-col-check">
                                    <div className="custom-control custom-control-sm custom-checkbox notext">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            onChange={(e) => selectorCheck(e)}
                                            id="uid"
                                        />
                                        <label className="custom-control-label" htmlFor="uid"></label>
                                    </div>
                                </DataTableRow>
                                {columns[1] && (
                                    <DataTableRow>
                                        <span className="sub-text w-150px">Tên tài sản</span>
                                    </DataTableRow>
                                )}
                                {columns[2] && (
                                    <DataTableRow>
                                        <span className="sub-text w-100px">Mã tài sản</span>
                                    </DataTableRow>
                                )}
                                {columns[3] && (
                                    <DataTableRow>
                                        <span className="sub-text w-150px">Nhóm tài sản</span>
                                    </DataTableRow>
                                )}
                                {columns[4] && (
                                    <DataTableRow>
                                        <span className="sub-text w-150px">Ngày mua</span>
                                    </DataTableRow>
                                )}
                                {columns[5] && (
                                    <DataTableRow>
                                        <span className="sub-text w-100px">Số lượng</span>
                                    </DataTableRow>
                                )}
                                {columns[6] && (
                                    <DataTableRow>
                                        <span className="sub-text w-150px">Còn lại / Tổng số</span>
                                    </DataTableRow>
                                )}
                                {columns[7] && (
                                    <DataTableRow>
                                        <span className="sub-text w-100px">Nguyên giá</span>
                                    </DataTableRow>
                                )}
                                {columns[8] && (
                                    <DataTableRow>
                                        <span className="sub-text w-100px">Trạng thái</span>
                                    </DataTableRow>
                                )}
                                <DataTableRow className="nk-tb-col-tools text-end">
                                    <UncontrolledDropdown>
                                        <DropdownToggle
                                            color="tranparent"
                                            className="btn btn-xs btn-outline-light btn-icon dropdown-toggle"
                                        >
                                            <Icon name="plus"></Icon>
                                        </DropdownToggle>
                                        <DropdownMenu end className="dropdown-menu-xs">
                                            <ul className="link-tidy sm no-bdr">
                                                {header.length > 0 ? (
                                                    header.map((item, index) => (
                                                        <li key={index}>
                                                            <div className="custom-control custom-control-sm custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id={`column-${index}`}
                                                                    checked={columns[index]}
                                                                    onChange={(e) =>
                                                                        setColumns((prev) => {
                                                                            const newColumns = [...prev];
                                                                            newColumns[index] = e.target.checked;
                                                                            return newColumns;
                                                                        })
                                                                    }
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor={`column-${index}`}
                                                                >
                                                                    {item}
                                                                </label>
                                                            </div>
                                                        </li>
                                                    ))
                                                ) : (
                                                    <li>No data</li>
                                                )}
                                            </ul>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </DataTableRow>
                            </DataTableHead>
                            {data?.length > 0
                                ? data.map((item, index) => {
                                      let nhomts = nhomTaisan?.find((items) => {
                                          return items && items.id === item?.NhomtaisanId;
                                      });
                                      const displayedIndex =  itemPerPage * (currentPage - 1) + index + 1;
                                      return (
                                          <DataTableItem key={item.id}>
                                              {columns[0] && (
                                                  <DataTableRow>
                                                      <span className="tb-amount">{displayedIndex}</span>
                                                  </DataTableRow>
                                              )}
                                              <DataTableRow className="nk-tb-col-check">
                                                  <div className="custom-control custom-control-sm custom-checkbox notext">
                                                      <input
                                                          type="checkbox"
                                                          className="custom-control-input"
                                                          defaultChecked={item.checked}
                                                          id={item.id + 'uid1'}
                                                          key={Math.random()}
                                                          onChange={(e) => onSelectChange(e, item.id)}
                                                      />
                                                      <label
                                                          className="custom-control-label"
                                                          htmlFor={item.id + 'uid1'}
                                                      ></label>
                                                  </div>
                                              </DataTableRow>
                                              {columns[1] && (
                                                  <DataTableRow>
                                                      <span className="tb-amount">{item?.tentaisan}</span>
                                                  </DataTableRow>
                                              )}
                                              {columns[2] && (
                                                  <DataTableRow>
                                                      <span className="tb-amount">{item?.mataisan}</span>
                                                  </DataTableRow>
                                              )}
                                              {columns[3] && (
                                                  <DataTableRow>
                                                      <span className="tb-amount">{nhomts?.tenNhomtaisan}</span>
                                                  </DataTableRow>
                                              )}
                                              {columns[4] && (
                                                  <DataTableRow>
                                                      <span className="tb-amount">
                                                          {item?.ngaymua
                                                              ? format(parseISO(item?.ngaymua), 'dd-MM-yyyy')
                                                              : 'NAV'}
                                                      </span>
                                                  </DataTableRow>
                                              )}
                                              {columns[5] && (
                                                  <DataTableRow>
                                                      <span className="tb-amount">{item?.soluong}</span>
                                                  </DataTableRow>
                                              )}
                                              {columns[6] && (
                                                  <DataTableRow>
                                                      <span className="tb-amount">
                                                          {item?.soluongconlai}/{item?.soluong}
                                                      </span>
                                                  </DataTableRow>
                                              )}
                                              {columns[7] && (
                                                  <DataTableRow>
                                                      <span className="tb-amount">
                                                          <NumericFormat
                                                              value={item?.giatri}
                                                              displayType={'text'}
                                                              thousandSeparator={true}
                                                              suffix={'đ'}
                                                          />
                                                      </span>
                                                  </DataTableRow>
                                              )}
                                              {columns[8] && (
                                                  <DataTableRow>
                                                      <span
                                                          className={`${
                                                              item?.TrangthaiId === 1 ? 'bg-success' : 'bg-danger'
                                                          } ms-0 badge text-white`}
                                                      >
                                                          {item?.TrangthaiId === 1 ? 'Đang hoạt động' : 'Chưa cấp phát'}
                                                      </span>
                                                  </DataTableRow>
                                              )}
                                              <DataTableRow className="nk-tb-col-tools">
                                                  <ul className="nk-tb-actions gx-1">
                                                      {+showPhanquyen?.xem === 1 && (
                                                          <li
                                                              className="nk-tb-action-hidden"
                                                              onClick={() => {
                                                                  setHandleEditDetail(true);
                                                                  handleEditTaisan(item);
                                                              }}
                                                          >
                                                              <TooltipComponent
                                                                  tag="a"
                                                                  containerClassName="btn btn-trigger btn-icon"
                                                                  id={'detail' + item.id}
                                                                  icon="eye-fill"
                                                                  direction="top"
                                                                  text="Xem chi tiết"
                                                              />
                                                          </li>
                                                      )}
                                                      {+showPhanquyen?.sua === 1 && (
                                                          <li
                                                              className="nk-tb-action-hidden"
                                                              onClick={() => {
                                                                  setHandleEditDetail(false);
                                                                  handleEditTaisan(item);
                                                              }}
                                                          >
                                                              <TooltipComponent
                                                                  tag="a"
                                                                  containerClassName="btn btn-trigger btn-icon"
                                                                  id={'edit' + index}
                                                                  icon="edit-alt-fill"
                                                                  direction="top"
                                                                  text="Sửa"
                                                              />
                                                          </li>
                                                      )}
                                                      {+showPhanquyen?.xoa === 1 && (
                                                          <React.Fragment>
                                                              <li
                                                                  className="nk-tb-action-hidden"
                                                                  onClick={() => handleDeleteTaisan(item)}
                                                              >
                                                                  <TooltipComponent
                                                                      tag="a"
                                                                      containerClassName="btn btn-trigger btn-icon"
                                                                      id={'suspend' + item.id}
                                                                      icon="user-cross-fill"
                                                                      direction="top"
                                                                      text="Xóa"
                                                                  />
                                                              </li>
                                                          </React.Fragment>
                                                      )}
                                                      <li>
                                                          <UncontrolledDropdown>
                                                              <DropdownToggle
                                                                  tag="a"
                                                                  className="dropdown-toggle btn btn-icon btn-trigger"
                                                              >
                                                                  <Icon name="more-h"></Icon>
                                                              </DropdownToggle>
                                                              <DropdownMenu end>
                                                                  <ul className="link-list-opt no-bdr">
                                                                      {+showPhanquyen?.xem === 1 && (
                                                                          <li
                                                                              onClick={() => {
                                                                                  setHandleEditDetail(true);
                                                                                  handleEditTaisan(item);
                                                                              }}
                                                                          >
                                                                              <DropdownItem tag="a" href="#Detail">
                                                                                  <Icon name="eye-fill"></Icon>
                                                                                  <span>Xem chi tiết</span>
                                                                              </DropdownItem>
                                                                          </li>
                                                                      )}
                                                                      {+showPhanquyen?.sua === 1 && (
                                                                          <React.Fragment>
                                                                              <li className="divider"></li>
                                                                              <li
                                                                                  onClick={() => {
                                                                                      setHandleEditDetail(false);
                                                                                      handleEditTaisan(item);
                                                                                  }}
                                                                              >
                                                                                  <DropdownItem tag="a" href="#edit">
                                                                                      <Icon name="edit"></Icon>
                                                                                      <span>Sửa</span>
                                                                                  </DropdownItem>
                                                                              </li>
                                                                          </React.Fragment>
                                                                      )}
                                                                      {+showPhanquyen?.xoa === 1 && (
                                                                          <React.Fragment>
                                                                              <li className="divider"></li>
                                                                              <li
                                                                                  onClick={() =>
                                                                                      handleDeleteTaisan(item)
                                                                                  }
                                                                              >
                                                                                  <DropdownItem tag="a" href="#suspend">
                                                                                      <Icon name="na"></Icon>
                                                                                      <span>Xóa</span>
                                                                                  </DropdownItem>
                                                                              </li>
                                                                          </React.Fragment>
                                                                      )}
                                                                  </ul>
                                                              </DropdownMenu>
                                                          </UncontrolledDropdown>
                                                      </li>
                                                  </ul>
                                              </DataTableRow>
                                          </DataTableItem>
                                      );
                                  })
                                : null}
                        </DataTableBody>
</div>
                        <div className="card-inner">
                            {data.length > 0 ? (
                                <PaginationCount posts={taisan} count={count} limit={itemPerPage} />
                            ) : (
                                <div className="text-center">
                                    <div>
                                        <Spinner color="primary" />
                                    </div>
                                    <span className="text-silent">Chưa có dữ liệu!</span>
                                </div>
                            )}
                        </div>
                    </DataTable>
                </Block>

                <ModalTaisan show={show} fieldList={fieldList} setShow={() => setShow(false)} />
                <ModalEditTaisan
                    show={showEditTaisan}
                    fieldList={fieldList}
                    setShow={() => setShowEditTaisan(false)}
                    dataIdTaisan={dataIdTaisan}
                    handleEditDetail={handleEditDetail}
                    setHandleEditDetail={setHandleEditDetail}
                />
            </Content>
        </React.Fragment>
    );
};

export default Taisan;
