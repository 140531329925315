
const config = {
    test_api: 'test-api',
    system: 'dashboard',
    lich: 'lich',
    nhan_vien: 'nhan-vien',
    nhom_nhan_vien: 'nhom-nhan-vien',
    cong_ty: 'cong-ty',
    quyet_dinh: 'quyet-dinh',
    kieu_luong: 'kieu-luong',
    phong_ban: 'phong-ban',
    chuc_vu: 'chuc-vu',
    bao_cao: 'bao-cao',
    ca_lam_viec: 'ca-lam-viec',
    may_cham_cong: 'may-cham-cong',
    danh_sach_bang_luong: 'danh-sach-bang-luong',
    tinh_luong: 'tinh-luong',
    cham_cong: 'cham-cong',
    cham_cong_tang_ca: 'cham-cong-tang-ca',
    cham_cong_tong_hop: 'cham-cong-tong-hop',
    san_luong_nhan_vien: 'san-luong-nhan-vien',
    thanh_phan_cong_thuc: 'thanh-phan-cong-thuc',
    chinh_sua_cham_cong: 'chinh-sua-cham-cong',
    du_lieu_cham_cong: 'du-lieu-cham-cong',
    bang_chot_cong: 'bang-chot-cong',
    phan_ca: 'phan-ca',
    phan_ca_tong_hop: 'phan-ca-tong-hop',
    bang_cham_cong_tong_hop: 'bang-cham-cong-tong-hop',
    cau_hinh_ca_lam_viec: 'cau-hinh-ca-lam-viec',
    phieu_day_chuyen: 'phieu-day-chuyen',
    phieu_tang_ca: 'phieu-tang-ca',
    loai_quy_dinh_phuc_loi: 'loai-quy-dinh-phuc-loi',
    ung_vien: 'ung-vien',
    danh_gia: 'danh-gia',
    // quy_dinh_thuong_phat: 'quy-dinh-thuong-phat',
    nghi_phep_cong_tac: 'nghi-phep-cong-tac',
    van_ban: 'van-ban',
    suat_an: 'suat-an',
    suat_an_tang_ca: 'suat-an-tang-ca',
    bo_cau_hoi_phong_van: 'bo-cau-hoi-phong-van',
    vi_tri_tuyen_dung: 'vi-tri-tuyen-dung',
    cau_hinh_nghi_le: 'cau-hinh-nghi-le',
    giao_viec: 'giao-viec',
    bang_giao_viec: 'bang-giao-viec',
    hop_dong_lao_dong: 'hop-dong-lao-dong',
    loai_hop_dong: 'loai-hop-dong',
    mau_hop_dong: 'mau-hop-dong',
    binh_luan: 'binh-luan',
    de_xuat: 'de-xuat',
    tai_san: 'tai-san',
    phu_cap: 'phu-cap',
    cau_hinh_tang_ca: 'cau-hinh-tang-ca',
    cau_hinh_tinh_luong_tang_ca: 'cau-hinh-tinh-luong-tang-ca',
    cau_hinh_trang_thai: 'cau-hinh-trang-thai',
    bang_cham_cong: 'bang-cham-cong',
    chi_nhanh: 'chi-nhanh',
    tam_ung_luong: 'tam-ung-luong',
    quan_ly_nghi: 'quan-ly-nghi',
    bao_hiem_nhan_vien: 'bao-hiem-nhan-vien',

    cau_hinh_bhxh_bhyt_bhtn: 'cau-hinh-bhxh-bhyt-bhtn',
    cau_hinh_thue_thu_nhap_cnlt: 'cau-hinh-thue-thu-nhap-cnlt',
    danh_gia_nhan_vien: 'danh-gia-nhan-vien',
    hoat_dong_su_kien: 'hoat-dong-su-kien',
    kiem_dinh_atvsbhld: 'kiem-dinh-atvsbhld',
    loai_kiem_dinh_atvsbhld: 'loai-kiem-dinh-atvsbhld',
    moi_quan_he_lao_dong: 'moi-quan-he-lao-dong',
    moi_quan_he_nhan_vien: 'moi-quan-he-nhan-vien',
    nhan_vien_kiem_dinh_atvsbhld: 'nhan-vien-kiem-dinh-atvsbhld',
    phuong_tien: 'phuong-tien',
    loai_phuong_tien: 'loai-phuong-tien',
    phi_phu: 'phi-phu',
    ke_hoach_cua_xe: 'ke-hoach-cua-xe',
    dang_kiem_phuong_tien: 'dang-kiem-phuong-tien',
    bao_duong_phuong_tien: 'bao-duong-phuong-tien',
    quan_ly_quy_thuong: 'quan-ly-quy-thuong',
    quan_ly_tham_nien: 'quan-ly-tham-nien',
    thong_bao_noi_bo: 'thong-bao-noi-bo',
    van_thu: 'van-thu',
    xep_loai: 'xep-loai',
    xep_loai_nhan_vien: 'xep-loai-nhan-vien',
    verify_email: 'verify-email',
    auth_reset: 'auth-reset',
    auth_reset_password: 'auth-reset-password',

    user_profile_regular: 'user-profile-regular',
    user_profile_notification: 'user-profile-notification',
    user_profile_setting: 'user-profile-setting',
    user_list_all: 'user-list-all',
    module_user: 'module-user',
    function_user: 'function-user',
    
    chi_tiet_nhan_vien: 'chi-tiet-nhan-vien/*',
    them_moi_nhan_vien: 'them-moi-nhan-vien',
    thong_bao: 'thong-bao',

    he_thong: '/he-thong/',
    login: '/login',
    register: '/register',
    
    cau_hinh_doanh_nghiep: 'cau-hinh-doanh-nghiep',
    email_server: 'email-server',
    cau_hinh_cong_thuc_tinh_luong: 'cau-hinh-cong-thuc-tinh-luong',
    cau_hinh_thanh_phan_luong: 'cau-hinh-thanh-phan-luong',
    cau_hinh_nghi_phep: 'cau-hinh-nghi-phep',
    cau_hinh_phe_duyet: 'cau-hinh-phe-duyet',
    ly_do_nghi_phep: 'ly-do-nghi-phep',
    cau_hinh_chuyen_can: 'cau-hinh-chuyen-can',
    cau_hinh_tham_nien: 'cau-hinh-tham-nien',
    cau_hinh_day_chuyen: 'cau-hinh-day-chuyen',
    cau_hinh_san_luong: 'cau-hinh-san-luong',
    
    //home page
    home: '/',
    following: '/following',
    blog: '/blog/:slug',
    
};

export default config;

