import React, { useContext, useEffect, useState } from 'react';
import Head from '~/layout/head/Head';
import { toast } from 'react-toastify';
import {
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    Modal,
    ModalBody,
    DropdownItem,
    Spinner,
} from 'reactstrap';

import {
    Block,
    BlockBetween,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Icon,
    Row,
    Col,
    UserAvatar,
    PaginationComponent,
    Button,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableRow,
    DataTableItem,
    TooltipComponent,
    RSelect,
    SelectNhanvien,
} from '~/components/Component';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import config from '~/config';
import { apiGetUserAll, apiPostUser, apiEditUser, apiDeleteUser, apiGetGroupFunctionUserV2 } from '~/services';
import { FileAllCodeContext } from './FileAllCodeContext';

const UserListAllPage = () => {
    const { handlePermissionChange } = useContext(FileAllCodeContext);

    const { user } = useSelector((state) => state.app);
    const [userInfo, setUserInfo] = useState([]);
    const [modal, setModal] = useState(false);
    const [data, setData] = useState([]);
    const [nhomQuyen, setNhomQuyen] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        setUserInfo(user);
        if (userInfo.length !== 0 && +userInfo?.phanquyen !== 0) {
            navigate(config.login);
        }
        const fetchData = async () => {
            try {
                const response = await apiGetUserAll();
                setData(response?.data?.data?.rows);
                const response1 = await apiGetGroupFunctionUserV2();
                setNhomQuyen(response1?.data?.data?.rows.map((item) => ({ label: item.tennhomquyen, value: item.id })));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData().catch(console.error);
    }, []);
    const [sm, updateSm] = useState(false);
    const [tablesm, updateTableSm] = useState(false);
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [sort, setSortState] = useState('');
    const onFilterChange = (e) => {
        setSearchText(e.target.value);
    };
    // function to toggle the search option
    const toggle = () => setonSearch(!onSearch);
    // function to change the check property of an item
    const selectorCheck = (e) => {
        let newData;
        newData = data.map((item) => {
            item.checked = e.currentTarget.checked;
            return item;
        });
        setData([...newData]);
    };
    // function to change the selected property of an item
    const onSelectChange = (e, id) => {
        let newData = data;
        let index = newData.findIndex((item) => item.id === id);
        newData[index].checked = e.currentTarget.checked;
        setData([...newData]);
    };

    // Changing state value when searching name
    useEffect(() => {
        if (onSearchText !== '') {
            const filteredObject = data.filter((item) => {
                return (
                    item.ho.toLowerCase().includes(onSearchText.toLowerCase()) ||
                    item.ten.toLowerCase().includes(onSearchText.toLowerCase())
                );
            });
            setData([...filteredObject]);
        } else {
            setData([...data]);
        }
    }, [onSearchText, setData]);
    // console.log('data', data)
    // Get current list, pagination
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
    // Change Page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const [invalidFields, setInvalidFields] = useState([]);
    const [showSelect, setShowSelect] = useState(false);
    const [listNhanvien, setlistNhanvien] = useState([]);
    // console.log('listNhanvien', listNhanvien);
    const [passState, setPassState] = useState(false);
    const [titleForm, setTitleForm] = useState(false);
    // console.log('titleForm', titleForm);

    const phanquyenSelect = [
        {
            label: 'Không',
            value: 2,
        },
        {
            label: 'Có',
            value: 0,
        },
    ];
    const [payload, setPayload] = useState({
        NhanvienName: '',
        NhanvienId: '',
        username: '',
        password: '',
        email: '',
        permission_GroupId: '',
        phanquyen: '',
    });
    // console.log('payload', payload)
    useEffect(() => {
        if (listNhanvien.length > 0) {
            setPayload((prevPayload) => ({
                ...prevPayload,
                NhanvienName: listNhanvien[0].label,
                NhanvienId: listNhanvien[0].value,
                username: listNhanvien[0].label,
                email: listNhanvien[0].email,
            }));
        }
    }, [listNhanvien]);
    // const [DataModull, setDataModull] = useState([]);
    // const [dataUserState, setdataUser] = useState(dataUser);
    // const [formValues, setFormValues] = useState([]);
    // const [userEdit, setUserEdit] = useState({});

    // const mangTinh2 = (mang) => {
    //     const tinh = [0, 0, 0, 0];
    //     for (const i in mang) {
    //         tinh[mang[i]] = 1;
    //     }
    //     return tinh;
    // };

    const editUser = async (data) => {
        // console.log('data----------------', data)
        setModal(true);
        setPayload([]);
        // console.log('object', data)
        let datalistGroupId = nhomQuyen?.find((item) => {
            return item && item.value === Number(data?.permission_GroupId);
        });
        let datalistPhanquyen = phanquyenSelect?.find((item) => {
            return item && item.value === Number(data?.phanquyen);
        });
        setPayload({
            NhanvienName: data?.username,
            NhanvienId: data?.NhanvienId,
            username: data?.username,
            password: data?.password,
            email: data?.email,
            permission_GroupId: datalistGroupId,
            phanquyen: datalistPhanquyen,
            id: data?.id,
        });
    };
    const handleSubmit = async () => {
        if (titleForm === false) {
            let invalids = validate(payload);
            if (invalids === 0) {
                const datapayload = {
                    NhanvienId: payload?.NhanvienId,
                    username: payload?.username,
                    password: payload?.password,
                    email: payload?.email,
                    permission_GroupId: payload?.permission_GroupId?.value,
                    phanquyen: payload?.phanquyen?.value,
                };
                try {
                    const responsePostGroup = await apiPostUser(datapayload);
                    if (responsePostGroup?.data?.success === 0) {
                        toast.success(responsePostGroup?.data?.mes);
                        setModal(false);
                        const responseGetGroupV1 = await apiGetUserAll();
                        setData(responseGetGroupV1?.data?.data?.rows);
                    } else {
                        toast.error(responsePostGroup?.data?.mes);
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
        } else {
            const datapayloadEdit = {
                NhanvienId: payload?.NhanvienId,
                username: payload?.username,
                // password: payload?.password,
                email: payload?.email,
                permission_GroupId: payload?.permission_GroupId?.value,
                phanquyen: payload?.phanquyen?.value,
            };
            if (payload?.password !== undefined && payload?.password !== '') {
                datapayloadEdit.password = payload?.password;
            }
            // console.log('payload?.password', payload?.password)
            // console.log('datapayloadEdit', datapayloadEdit)
            try {
                const responsePostEdit = await apiEditUser(datapayloadEdit);
                if (responsePostEdit?.data?.success === 200) {
                    toast.success('Cập nhật thành công');
                    setModal(false);
                    handlePermissionChange(payload?.id);
                    const responseGetGroupV3 = await apiGetUserAll();
                    setData(responseGetGroupV3?.data?.data?.rows);
                } else {
                    toast.error('Cập nhật không thành công');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    };

    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (item[1] === '') {
                setInvalidFields((prev) => [
                    ...prev,
                    {
                        name: item[0],
                        message: 'Bạn không được bỏ trống trường này.',
                    },
                ]);
                invalids++;
            }
        });
        return invalids;
    };
    const handleDeleteNhanvien = async (item) => {
        try {
            const response = await apiDeleteUser(item.id);
            if (response.status === 200) {
                const response2 = await apiGetUserAll();
                toast.success(response.data.mes);
                setData(response2.data.data.rows);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    return (
        <React.Fragment>
            <Head title="User List - Profile"></Head>
            <BlockHead size="lg">
                <BlockBetween>
                    <BlockHeadContent>
                        <BlockTitle tag="h4">Danh sách người dùng</BlockTitle>
                    </BlockHeadContent>
                    <BlockHeadContent>
                        <div className="toggle-wrap nk-block-tools-toggle">
                            <div className="toggle-expand-content" style={{ display: sm ? 'block' : 'none' }}>
                                <ul className="nk-block-tools g-3">
                                    <li className="nk-block-tools-opt">
                                        <Button
                                            color="primary"
                                            className="btn-icon px-1"
                                            onClick={() => {
                                                setModal(true);
                                                setPayload({
                                                    NhanvienId: '',
                                                    username: '',
                                                    password: '',
                                                    email: '',
                                                    permission_GroupId: '',
                                                    phanquyen: { label: 'Không', value: 2 },
                                                });
                                                setTitleForm(false);
                                            }}
                                        >
                                            <Icon name="plus"></Icon> Thêm mới người dùng
                                        </Button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </BlockHeadContent>
                </BlockBetween>
            </BlockHead>

            <Block>
                <DataTable className="card-stretch">
                    <div className="card-inner position-relative card-tools-toggle">
                        <div className="card-title-group">
                            <div className="card-tools me-n1">
                                <ul className="btn-toolbar gx-1">
                                    <li>
                                        <a
                                            href="#search"
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                toggle();
                                            }}
                                            className="btn btn-icon search-toggle toggle-search"
                                        >
                                            <Icon name="search"></Icon>
                                        </a>
                                    </li>
                                    <li className="btn-toolbar-sep"></li>
                                    <li>
                                        <div className="toggle-wrap">
                                            <Button
                                                className={`btn-icon btn-trigger toggle ${tablesm ? 'active' : ''}`}
                                                onClick={() => updateTableSm(true)}
                                            >
                                                <Icon name="menu-right"></Icon>
                                            </Button>

                                            <div className={`toggle-content ${tablesm ? 'content-active' : ''}`}>
                                                <ul className="btn-toolbar gx-1">
                                                    <li className="toggle-close">
                                                        <Button
                                                            className="btn-icon btn-trigger toggle"
                                                            onClick={() => updateTableSm(false)}
                                                        >
                                                            <Icon name="arrow-left"></Icon>
                                                        </Button>
                                                    </li>
                                                    <li>
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle
                                                                tag="a"
                                                                className="btn btn-trigger btn-icon dropdown-toggle"
                                                            >
                                                                <div className="dot dot-primary"></div>
                                                                <Icon name="filter-alt"></Icon>
                                                            </DropdownToggle>
                                                            <DropdownMenu
                                                                end
                                                                className="filter-wg dropdown-menu-xl"
                                                                style={{ overflow: 'visible' }}
                                                            >
                                                                <div className="dropdown-head">
                                                                    <span className="sub-title dropdown-title">
                                                                        Bộ lọc
                                                                    </span>

                                                                    <div className="dropdown">
                                                                        <a
                                                                            href="#more"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();
                                                                            }}
                                                                            className="btn btn-sm btn-icon"
                                                                        >
                                                                            <Icon name="more-h"></Icon>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div className="dropdown-body dropdown-body-rg">
                                                                    <Row className="gx-6 gy-3">
                                                                        <Col size="6">
                                                                            <div className="custom-control custom-control-sm custom-checkbox">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="custom-control-input"
                                                                                    id="hasBalance"
                                                                                />
                                                                                <label
                                                                                    className="custom-control-label"
                                                                                    htmlFor="hasBalance"
                                                                                >
                                                                                    {' '}
                                                                                    Have Balance
                                                                                </label>
                                                                            </div>
                                                                        </Col>
                                                                        <Col size="6">
                                                                            <div className="custom-control custom-control-sm custom-checkbox">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="custom-control-input"
                                                                                    id="hasKYC"
                                                                                />
                                                                                <label
                                                                                    className="custom-control-label"
                                                                                    htmlFor="hasKYC"
                                                                                >
                                                                                    {' '}
                                                                                    KYC Verified
                                                                                </label>
                                                                            </div>
                                                                        </Col>
                                                                        <Col size="6">
                                                                            <div className="form-group">
                                                                                <label className="overline-title overline-title-alt">
                                                                                    Role
                                                                                </label>
                                                                                <RSelect placeholder="Any Role" />
                                                                            </div>
                                                                        </Col>
                                                                        <Col size="6">
                                                                            <div className="form-group">
                                                                                <label className="overline-title overline-title-alt">
                                                                                    Status
                                                                                </label>
                                                                                <RSelect placeholder="Any Status" />
                                                                            </div>
                                                                        </Col>
                                                                        <Col size="12">
                                                                            <div className="form-group">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-secondary"
                                                                                >
                                                                                    Filter
                                                                                </button>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                                <div className="dropdown-foot between">
                                                                    <a
                                                                        href="#reset"
                                                                        onClick={(ev) => {
                                                                            ev.preventDefault();
                                                                        }}
                                                                        className="clickable"
                                                                    >
                                                                        Cập nhật bộ lọc 
                                                                    </a>
                                                                    <a
                                                                        href="#save"
                                                                        onClick={(ev) => {
                                                                            ev.preventDefault();
                                                                        }}
                                                                    >
                                                                        Lưu bộ lọc 
                                                                    </a>
                                                                </div>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </li>
                                                    <li>
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle
                                                                color="tranparent"
                                                                className="btn btn-trigger btn-icon dropdown-toggle"
                                                            >
                                                                <Icon name="setting"></Icon>
                                                            </DropdownToggle>
                                                            <DropdownMenu end className="dropdown-menu-xs">
                                                                <ul className="link-check">
                                                                    <li>
                                                                        <span>Show</span>
                                                                    </li>
                                                                    <li className={itemPerPage === 10 ? 'active' : ''}>
                                                                        <DropdownItem
                                                                            tag="a"
                                                                            href="#dropdownitem"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();
                                                                                setItemPerPage(10);
                                                                            }}
                                                                        >
                                                                            10
                                                                        </DropdownItem>
                                                                    </li>
                                                                    <li className={itemPerPage === 15 ? 'active' : ''}>
                                                                        <DropdownItem
                                                                            tag="a"
                                                                            href="#dropdownitem"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();
                                                                                setItemPerPage(15);
                                                                            }}
                                                                        >
                                                                            15
                                                                        </DropdownItem>
                                                                    </li>
                                                                </ul>
                                                                <ul className="link-check">
                                                                    <li>
                                                                        <span>Order</span>
                                                                    </li>

                                                                    <li className={sort === 'dsc' ? 'active' : ''}>
                                                                        <DropdownItem
                                                                            tag="a"
                                                                            href="#dropdownitem"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();

                                                                                setSortState('dsc');
                                                                                //   sortFunc("dsc");
                                                                            }}
                                                                        >
                                                                            DESC
                                                                        </DropdownItem>
                                                                    </li>
                                                                    <li className={sort === 'asc' ? 'active' : ''}>
                                                                        <DropdownItem
                                                                            tag="a"
                                                                            href="#dropdownitem"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();

                                                                                setSortState('asc');

                                                                                //   sortFunc("asc");
                                                                            }}
                                                                        >
                                                                            ASC
                                                                        </DropdownItem>
                                                                    </li>
                                                                </ul>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className={`card-search search-wrap ${!onSearch && 'active'}`}>
                            <div className="card-body">
                                <div className="search-content">
                                    <Button
                                        className="search-back btn-icon toggle-search active"
                                        onClick={() => {
                                            setSearchText('');

                                            toggle();
                                        }}
                                    >
                                        <Icon name="arrow-left"></Icon>
                                    </Button>
                                    <input
                                        type="text"
                                        className="border-transparent form-focus-none form-control"
                                        placeholder="Tìm kiến nhân viên"
                                        value={onSearchText}
                                        onChange={(e) => onFilterChange(e)}
                                    />
                                    <Button className="search-submit btn-icon">
                                        <Icon name="search"></Icon>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DataTableBody>
                        <DataTableHead>
                            <DataTableRow className="nk-tb-col-check">
                                <div className="custom-control custom-control-sm custom-checkbox notext">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        onChange={(e) => selectorCheck(e)}
                                        id="uid"
                                    />
                                    <label className="custom-control-label" htmlFor="uid"></label>
                                </div>
                            </DataTableRow>
                            <DataTableRow>
                                <span className="sub-text">Người dùng</span>
                            </DataTableRow>
                            <DataTableRow size="mb">
                                <span className="sub-text">Email</span>
                            </DataTableRow>
                            <DataTableRow className="nk-tb-col-tools text-end">
                                <UncontrolledDropdown>
                                    <DropdownToggle
                                        color="tranparent"
                                        className="btn btn-xs btn-outline-light btn-icon dropdown-toggle"
                                    >
                                        <Icon name="plus"></Icon>
                                    </DropdownToggle>
                                </UncontrolledDropdown>
                            </DataTableRow>
                        </DataTableHead>
                        {/*Head*/}
                        {currentItems.length > 0
                            ? currentItems.map((item) => {
                                  return (
                                      <DataTableItem key={item.id}>
                                          <DataTableRow className="nk-tb-col-check">
                                              <div className="custom-control custom-control-sm custom-checkbox notext">
                                                  <input
                                                      type="checkbox"
                                                      className="custom-control-input"
                                                      defaultChecked={item.checked}
                                                      id={item.id + 'uid1'}
                                                      key={Math.random()}
                                                      onChange={(e) => onSelectChange(e, item.id)}
                                                  />
                                                  <label
                                                      className="custom-control-label"
                                                      htmlFor={item.id + 'uid1'}
                                                  ></label>
                                              </div>
                                          </DataTableRow>
                                          <DataTableRow>
                                              <div className="user-card">
                                                  <div className="user-info">
                                                      <span className="tb-lead">
                                                          {item?.username}{' '}
                                                          <span
                                                              className={`dot dot-${
                                                                  item.status === 'Active'
                                                                      ? 'success'
                                                                      : item.status === 'Pending'
                                                                      ? 'warning'
                                                                      : 'danger'
                                                              } d-md-none ms-1`}
                                                          ></span>
                                                      </span>
                                                      {/* <span>{item.email}</span> */}
                                                  </div>
                                              </div>
                                              {/* </Link> */}
                                          </DataTableRow>
                                          <DataTableRow size="mb">
                                              <span className="tb-amount">{item?.email}</span>
                                          </DataTableRow>

                                          <DataTableRow className="nk-tb-col-tools">
                                              <ul className="nk-tb-actions gx-1">
                                                  <li
                                                      className="nk-tb-action-hidden"
                                                      onClick={() => {
                                                          editUser(item);
                                                          setTitleForm(true);
                                                      }}
                                                  >
                                                      <TooltipComponent
                                                          tag="a"
                                                          containerClassName="btn btn-trigger btn-icon"
                                                          id={'edit' + item.id}
                                                          icon="edit-alt-fill"
                                                          direction="top"
                                                          text="Sửa"
                                                      />
                                                  </li>
                                                  <React.Fragment>
                                                      <li
                                                          className="nk-tb-action-hidden"
                                                          onClick={() => handleDeleteNhanvien(item)}
                                                      >
                                                          <TooltipComponent
                                                              tag="a"
                                                              containerClassName="btn btn-trigger btn-icon"
                                                              id={'suspend' + item.id}
                                                              icon="user-cross-fill"
                                                              direction="top"
                                                              text="Xóa"
                                                          />
                                                      </li>
                                                  </React.Fragment>
                                                  <li>
                                                      <UncontrolledDropdown>
                                                          <DropdownToggle
                                                              tag="a"
                                                              className="dropdown-toggle btn btn-icon btn-trigger"
                                                          >
                                                              <Icon name="more-h"></Icon>
                                                          </DropdownToggle>
                                                          <DropdownMenu end>
                                                              <ul className="link-list-opt no-bdr">
                                                                  <li
                                                                      onClick={() => {
                                                                          editUser(item);
                                                                          setTitleForm(true);
                                                                      }}
                                                                  >
                                                                      <DropdownItem
                                                                          tag="a"
                                                                          href="#edit"
                                                                          onClick={(ev) => {
                                                                              ev.preventDefault();
                                                                          }}
                                                                      >
                                                                          <Icon name="edit"></Icon>
                                                                          <span>Sửa</span>
                                                                      </DropdownItem>
                                                                  </li>
                                                                  <React.Fragment>
                                                                      <li className="divider"></li>
                                                                      <li onClick={() => handleDeleteNhanvien(item)}>
                                                                          <DropdownItem
                                                                              tag="a"
                                                                              href="#suspend"
                                                                              onClick={(ev) => {
                                                                                  ev.preventDefault();
                                                                              }}
                                                                          >
                                                                              <Icon name="na"></Icon>
                                                                              <span>Xóa</span>
                                                                          </DropdownItem>
                                                                      </li>
                                                                  </React.Fragment>
                                                              </ul>
                                                          </DropdownMenu>
                                                      </UncontrolledDropdown>
                                                  </li>
                                              </ul>
                                          </DataTableRow>
                                      </DataTableItem>
                                  );
                              })
                            : null}
                    </DataTableBody>
                    <div className="card-inner">
                        {currentItems.length > 0 ? (
                            <PaginationComponent
                                itemPerPage={itemPerPage}
                                totalItems={data.length}
                                paginate={paginate}
                                currentPage={currentPage}
                            />
                        ) : (
                            <div className="text-center">
                                <div>
                                    {' '}
                                    <Spinner color="primary" />
                                </div>
                                <span className="text-silent">Chưa có dữ liệu!</span>
                            </div>
                        )}
                    </div>
                </DataTable>
            </Block>

            <Modal isOpen={modal} className="modal-dialog-centered" size="lg" toggle={() => setModal(false)}>
                <ModalBody>
                    <div className="p-2">
                        <h5 className="title">{titleForm === false ? 'Thêm người dùng' : 'Sửa người dùng'}</h5>

                        <div className="tab-content">
                            <Row className="gy-4">
                                <div
                                    className="form-group col-md-6"
                                    onClick={() => titleForm === false && setShowSelect(true)}
                                >
                                    <label className="mb-2 fw-bold">
                                        <span>
                                            {titleForm === false ? 'Chọn nhân viên' : 'Sửa tài khoản nhân viên'}{' '}
                                            <b className="text-danger">(*)</b>
                                        </span>
                                    </label>
                                    <input
                                        type="text"
                                        id="display-name"
                                        className="form-control"
                                        name="NhanvienName"
                                        value={payload.NhanvienName}
                                        // onChange={(e) =>
                                        //     setPayload((prev) => ({ ...prev, ['NhanvienId']: e.target.value }))
                                        // }
                                        placeholder="Chọn nhân viên"
                                        disabled={titleForm === false ? false : true}
                                    />
                                    {/* <input
                                        value={payload?.NhanvienId}
                                        name="NhanvienId"
                                        onFocus={() => setInvalidFields([])}
                                    /> */}
                                    {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'NhanvienId') && (
                                        <small className="text-danger">
                                            {invalidFields.find((i) => i.name === 'NhanvienId')?.message}
                                        </small>
                                    )}
                                </div>
                                <div className="form-group col-md-6">
                                    <label className="mb-2 fw-bold">
                                        <span>
                                            Chọn vai trò <b className="text-danger">(*)</b>
                                        </span>
                                    </label>
                                    <Select
                                        value={payload?.permission_GroupId}
                                        onChange={(selected) =>
                                            setPayload((prev) => ({ ...prev, ['permission_GroupId']: selected }))
                                        }
                                        options={nhomQuyen}
                                        name="permission_GroupId"
                                        onFocus={() => setInvalidFields([])}
                                    />
                                    {invalidFields.length > 0 &&
                                        invalidFields.some((i) => i.name === 'permission_GroupId') && (
                                            <small className="text-danger">
                                                {invalidFields.find((i) => i.name === 'permission_GroupId')?.message}
                                            </small>
                                        )}
                                </div>
                                <Col md="6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="display-name">
                                            Email
                                        </label>
                                        <input
                                            type="email"
                                            id="display-name"
                                            className="form-control"
                                            name="email"
                                            value={payload.email}
                                            // onChange={(e) =>
                                            //     setPayload((prev) => ({ ...prev, ['email']: e.target.value }))
                                            // }
                                            placeholder="Thêm email"
                                            disabled={true}
                                        />
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="full-name">
                                            Tên tài khoản
                                        </label>
                                        <input
                                            type="text"
                                            id="full-name"
                                            className="form-control"
                                            name="username"
                                            onChange={(e) =>
                                                setPayload((prev) => ({ ...prev, ['username']: e.target.value }))
                                            }
                                            value={payload.username}
                                            placeholder="Thêm họ và tên"
                                            // disabled={true}
                                        />
                                    </div>
                                </Col>

                                <div className="form-group col-md-6">
                                    <label className="mb-2 fw-bold">
                                        <span>
                                            Tạo vai trò và cấp tài khoản <b className="text-danger">(*)</b>
                                        </span>
                                    </label>
                                    <Select
                                        value={payload?.phanquyen}
                                        onChange={(selected) =>
                                            setPayload((prev) => ({ ...prev, ['phanquyen']: selected }))
                                        }
                                        options={phanquyenSelect}
                                        name="phanquyen"
                                        onFocus={() => setInvalidFields([])}
                                    />
                                    {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'phanquyen') && (
                                        <small className="text-danger">
                                            {invalidFields.find((i) => i.name === 'phanquyen')?.message}
                                        </small>
                                    )}
                                </div>
                                <Col md="6">
                                    <div className="form-group">
                                        <div className="form-label-group">
                                            <label className="form-label" htmlFor="password">
                                                Mật khẩu
                                            </label>
                                        </div>
                                        <div className="form-control-wrap">
                                            <a
                                                href="#password"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    setPassState(!passState);
                                                }}
                                                className={`form-icon lg form-icon-right passcode-switch ${
                                                    passState ? 'is-hidden' : 'is-shown'
                                                }`}
                                            >
                                                <Icon name="eye" className="passcode-icon icon-show"></Icon>

                                                <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                                            </a>
                                            <input
                                                type={passState ? 'text' : 'password'}
                                                id="password"
                                                name="password"
                                                placeholder="Nhập mật khẩu"
                                                className={`form-control-lg form-control ${
                                                    passState ? 'is-hidden' : 'is-shown'
                                                }`}
                                                value={payload.password}
                                                onChange={(e) =>
                                                    setPayload((prev) => ({ ...prev, ['password']: e.target.value }))
                                                }
                                                onFocus={() => setInvalidFields([])}
                                            />
                                            {invalidFields.length > 0 &&
                                                invalidFields.some((i) => i.name === 'password') && (
                                                    <small className="text-danger">
                                                        {invalidFields.find((i) => i.name === 'password')?.message}
                                                    </small>
                                                )}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Col size="12 mt-5">
                                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                    <li>
                                        <Button color="primary" size="lg" onClick={handleSubmit}>
                                            {titleForm === false ? 'Thêm mới' : 'Cập nhật'}
                                        </Button>
                                    </li>
                                    <li>
                                        <a
                                            href="#dropdownitem"
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                setModal(false);
                                            }}
                                            className="link link-light"
                                        >
                                            Hủy bỏ
                                        </a>
                                    </li>
                                </ul>
                            </Col>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <SelectNhanvien
                showSelect={showSelect}
                // typeSelect = {
                //  ismultiple: "Lấy nhiều"
                //  single: "lấy một"
                // }
                // typeSelect
                setlistNhanvien={setlistNhanvien}
                setShowSelect={() => setShowSelect(false)}
            />
        </React.Fragment>
    );
};
export default UserListAllPage;
