import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { InputForm, Button } from '~/components/Form';
import * as actions from '~/store/actions';

import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';

const ModalBocauhoiphongvan = ({ show, setShow, fieldList }) => {
    const dispatch = useDispatch();
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayloadBocauhoiphongvan] = useState(fieldList);
    useEffect(() => {
        show && setPayloadBocauhoiphongvan(fieldList);
        setFormValues([{ cauhoi: '' }]);
    }, [show]);

    const handleSubmit = async () => {
        let invalids = validate(payload);
        let cauhoidata = [];
        for (const i in formValues) {
            cauhoidata.push(formValues[i].cauhoi);
        }
        // console.log(cauhoidata);
        if (invalids === 0) {
            let datapayload = {
                data: [
                    {
                        tencauhoi: payload.tencauhoi,
                        mota: payload.mota,
                        cauhoi: cauhoidata,
                    },
                ],
            };
            // console.log('check payload', datapayload)
            dispatch(actions.NewBocauhoiphongvan(datapayload));
        }
    };

    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (item[1] === '') {
                setInvalidFields((prev) => [
                    ...prev,
                    {
                        name: item[0],
                        message: 'Bạn không được bỏ trống trường này.',
                    },
                ]);
                invalids++;
            }
        });

        return invalids;
    };

    const [formValues, setFormValues] = useState([{ cauhoi: '' }]);

    let handleChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
    };

    let addFormFields = () => {
        setFormValues([...formValues, { cauhoi: '' }]);
    };

    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues);
    };

    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">Bộ câu hỏi phỏng vấn</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Tên câu hỏi'}
                            value={payload.tencauhoi}
                            setValue={setPayloadBocauhoiphongvan}
                            keyPayload={'tencauhoi'}
                            type="text"
                        />
                    </div>
                    <div className="form-group col-md-6 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Mô tả'}
                            value={payload.mota}
                            setValue={setPayloadBocauhoiphongvan}
                            keyPayload={'mota'}
                            type="text"
                        />
                    </div>
                </div>

                <button className="button add btn btn-warning me-3 mt-3" type="button" onClick={() => addFormFields()}>
                    Thêm câu hỏi
                </button>

                {formValues.map((element, index) => (
                    <div className="row" key={index}>
                        <div className="col-sm-11">
                            <label>Câu hỏi {index + 1}</label>
                            <input
                                type="text"
                                name="cauhoi"
                                className="form-control"
                                value={element.cauhoi || ''}
                                onChange={(e) => handleChange(index, e)}
                            />
                        </div>

                        {index ? (
                            <div className="col-sm-1">
                                <button
                                    type="button"
                                    className="button remove btn btn-danger mt-4"
                                    onClick={() => removeFormFields(index)}
                                >
                                    Xóa
                                </button>
                            </div>
                        ) : null}
                    </div>
                ))}
                <div className="col-12 mt-5">
                    <Button text="Thêm câu hỏi" bClass="btn btn-primary px-5" textColor onClick={handleSubmit} />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalBocauhoiphongvan;
