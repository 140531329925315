import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {InputForm, Button} from '~/components/Form';
import * as actions from '~/store/actions';
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import {validatePhuongTien} from '~/request/validatePhuongTien';
import {dataSelectPhuongtien} from "~/utils/Datacustom";
import {InputSwitch} from "~/components/Component";
import {Badge, Card, Table} from "reactstrap";
import {apiGetKehoachcuaxeByNhanVien} from "~/services";
import {apiGetCauhinhdoanhnghiep} from "~/services/cauhinhdoanhnghiep";
import {format, parseISO} from "date-fns";

const ModalInfo = ({show, setShow, dataIdPhuongtien, fieldList}) => {
    console.log('ModalInfo', dataIdPhuongtien)
    // const {loaiphuongtien} = useSelector((state) => state.quanly);
    // const [listLoaiphuongtien, setlistLoaiphuongtien] = useState(loaiphuongtien);
    const dispatch = useDispatch();
    const [dataInfo, setDataInfo] = useState(fieldList);
    //Lịch trình
    const [dataSchedule, setDataSchedule] = useState([]);
    //Đăng kiểm
    const [dataRegistry, setDataRegistry] = useState([]);
    //Bảo dưỡng
    const [dataMaintenance, setDataMaintenance] = useState([]);
    
    const [modalTab, setModalTab] = useState('1');
    // console.log('listLoaiphuongtien', listLoaiphuongtien)
    // useEffect(() => {
    //     if (loaiphuongtien && loaiphuongtien.length > 0) {
    //         let LoaiphuongtienId = loaiphuongtien.map((item) => ({label: item.tenloaiphuongtien, value: item.id}));
    //         setlistLoaiphuongtien(LoaiphuongtienId);
    //     }
    // }, [loaiphuongtien]);
    const onClickTabLichSuXe = async () => {
        console.log('dataInfo::::::', dataInfo)
        // try {
        //     let dataPayload = {NhanvienId: '', PhuongtienId: dataInfo.id}
        //     const dataApi = await apiGetKehoachcuaxeByNhanVien(dataPayload);
        //     console.log('res::::::::::dataPayload', dataPayload);
        //     let res = dataApi.data.data.rows;
        //     // console.log('res::::::::::res',res);
        //     setDataSchedule(res);
        // } catch (error) {
        //     console.error('Error fetching data:', error);
        // }
        
    };
    const fetchData = async (data) => {
        try {
            let dataPayload = {NhanvienId: '', PhuongtienId: data}
            const dataApi = await apiGetKehoachcuaxeByNhanVien(dataPayload);
            let res = dataApi.data.data.rows;
            setDataSchedule(res);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    
    
    useEffect(() => {
        fetchData(dataIdPhuongtien.id).catch(console.error);
        show &&
        dataIdPhuongtien &&
        setDataInfo(dataIdPhuongtien);
    }, [dataIdPhuongtien, show]);
    console.log('abc:::::dataSchedule::::::::', dataSchedule)
    console.log('abc:::::DataInfo::::::::', dataInfo)
    // useEffect(() => {
    //     let TrangthaiId = dataSelectPhuongtien.map((item) => ({label: item.label, value: item.value}));
    //     setlistTrangthai(TrangthaiId);
    // }, []);
    
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">Thông tin chi tiết về phương tiện</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <Card className="card-bordered">
                        <div className="card-inner-group">
                            <ul className="nk-nav nav nav-tabs">
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${modalTab === '1' && 'active'}`}
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            setModalTab('1');
                                        }}
                                        href="#thongtinxe"
                                    >
                                        Thông tin xe
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${modalTab === '2' && 'active'}`}
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            setModalTab('2');
                                            onClickTabLichSuXe();
                                        }}
                                        href="#lichsuxechay"
                                    >
                                        Lịch sử xe chạy
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${modalTab === '3' && 'active'}`}
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            setModalTab('3');
                                        }}
                                        href="#lichsudangkiem"
                                    >
                                        Lịch sử đăng kiểm
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${modalTab === '4' && 'active'}`}
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            setModalTab('4');
                                        }}
                                        href="#lichsubaoduong"
                                    >
                                        Lịch sử bảo dưỡng
                                    </a>
                                </li>
                            </ul>
                            <div className="card-inner">
                                <div className="tab-content">
                                    <div className={`tab-pane ${modalTab === '1' ? 'active' : ''}`} id="thongtinxe">
                                        <div className="between-center flex-wrap flex-md-nowrap g-3">
                                            <Table className="table table-bordered table-striped table-hover">
                                                <thead>
                                                <tr>
                                                    <th className="text-center">Loại xe</th>
                                                    <th className="text-center">Biển số</th>
                                                    <th className="text-center">Tình trạng</th>
                                                    <th className="text-center">Năm sản xuất</th>
                                                    <th className="text-center">Nhãn hiệu</th>
                                                    <th className="text-center">Trạng thái</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td className="text-center">{dataInfo?.Loaiphuongtien?.tenloaiphuongtien}</td>
                                                    <td className="text-center">{dataInfo?.biensoxe}</td>
                                                    <td className="text-center">{dataInfo?.tinhtrangxe}</td>
                                                    <td className="text-center">{dataInfo?.namsanxuat}</td>
                                                    <td className="text-center">{dataInfo?.nhanhieu}</td>
                                                    <td className="text-center"> <span
                                                        className={`${dataInfo?.TrangthaiId === 0 ? 'bg-success' : 'bg-danger'} ms-0 badge text-white px-4`}>
                                                    {dataInfo?.TrangthaiId === 0 ? 'Còn xe' : 'Hết xe'}
                                                </span></td>
                                                </tr>
                                                
                                                </tbody>
                                            </Table>
                                        
                                        </div>
                                    </div>
                                    <div className={`tab-pane ${modalTab === '2' ? 'active' : ''}`} id="lichsuxechay">
                                        <div className="between-center flex-wrap flex-md-nowrap g-3">
                                            <Table className="table table-bordered table-striped table-hover">
                                                <thead>
                                                <tr>
                                                    <th className="text-center">Lái xe</th>
                                                    <th className="text-center">Mã NV</th>
                                                    <th className="text-center">Mã lệnh</th>
                                                    <th className="text-center">Xuất phát</th>
                                                    <th className="text-center">Điểm đến</th>
                                                    <th className="text-center">Thời gian</th>
                                                    <th className="text-center">Khách hàng</th>
                                                    <th className="text-center">Trạng thái</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {dataInfo?.Kehoachchuyens?.length > 0
                                                    ? dataInfo?.Kehoachchuyens?.map((item, index) => {
                                                        return (
                                                            <tr>
                                                                <td className="text-center">{item?.Nhanvien?.hoten}</td>
                                                                <td className="text-center">{item?.Nhanvien?.uuid}</td>
                                                                <td className="text-center">{item?.malenh}</td>
                                                                <td className="text-center">{item?.diachi}</td>
                                                                <td className="text-center">{item?.diemden}</td>
                                                                <td className="text-center">{item?.ngayketthuc}</td>
                                                                <td className="text-center">{item?.tenkhachhang}</td>
                                                                <td className="text-center">
                                                                         <span
                                                                             className={`${item?.TrangthaiId === 1 ? 'bg-success' : 'bg-danger'} ms-0 badge text-white`}>
                                                    {item?.TrangthaiId === 1 ? 'Đang Hoạt động' : 'Kết thúc'}
                                                </span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    : null}
                                                
                                                
                                                </tbody>
                                            </Table>
                                        
                                        </div>
                                    </div>
                                    <div className={`tab-pane ${modalTab === '3' ? 'active' : ''}`} id="lichsudangkiem">
                                        <div className="between-center flex-wrap flex-md-nowrap g-3">
                                            <Table className="table table-bordered table-striped table-hover">
                                                <thead>
                                                <tr>
                                                    <th className="text-center">Chu kỳ đăng kiểm</th>
                                                    <th className="text-center">Ngày đăng kiểm</th>
                                                    <th className="text-center">Hạn đăng kiểm</th>
                                                    <th className="text-center">Đăng kiểm tiếp theo</th>
                                                    <th className="text-center">Chi Phí</th>
                                                    <th className="text-center">Trạng thái</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {dataInfo?.Dangkiemphuongtiens?.length > 0
                                                    ? dataInfo?.Dangkiemphuongtiens?.map((item, index) => {
                                                        return (
                                                            <tr>
                                                                <td className="text-center">{index+1}</td>
                                                                <td className="text-center">{item?.chukydangkiem}</td>
                                                                <td className="text-center">{format(parseISO(item?.ngaydangkiem), 'dd/MM/yyyy')}</td>
                                                                <td className="text-center">{format(parseISO(item?.thoihantruoc), 'dd/MM/yyyy')}</td>
                                                                <td className="text-center">{format(parseISO(item?.thoihantieptheo), 'dd/MM/yyyy')}</td>
                                                                <td className="text-center">{item?.chiphi}</td>
                                                                <td className="text-center">
                                                                         <span
                                                                             className={`${item?.TrangthaiId === 1 ? 'bg-success' : 'bg-danger'} ms-0 badge text-white`}>
                                                                            {item?.TrangthaiId === 1 ? 'Đang Hoạt động' : 'Kết thúc'}
                                                                        </span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    : null}
                                                
                                                </tbody>
                                            </Table>
                                        
                                        </div>
                                    </div>
                                    <div className={`tab-pane ${modalTab === '4' ? 'active' : ''}`} id="lichsubaoduong">
                                        <div className="between-center flex-wrap flex-md-nowrap g-3">
                                            <Table className="table table-bordered table-striped table-hover">
                                                <thead>
                                                <tr>
                                                    <th className="text-center">stt</th>
                                                    <th className="text-center">Ngày bảo dưỡng</th>
                                                    <th className="text-center">Số KM</th>
                                                    <th className="text-center">Chi phí</th>
                                                    <th className="text-center">Trạng thái</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {dataInfo?.Baoduongphuongtiens
                                                    ?.length > 0
                                                    ? dataInfo?.Baoduongphuongtiens
                                                        ?.map((item, index) => {
                                                            return (
                                                                <tr>
                                                                    <td className="text-center">{index+1}</td>
                                                                    <td className="text-center">{format(parseISO(item?.ngaybaoduong), 'dd/MM/yyyy')}</td>
                                                                    <td className="text-center">{item?.kmtruoc}</td>
                                                                    <td className="text-center">{item?.chiphi}</td>
                                                                    <td className="text-center">
                                                                         <span
                                                                             className={`${item?.TrangthaiId === 1 ? 'bg-success' : 'bg-danger'} ms-0 badge text-white`}>
                                                                            {item?.TrangthaiId === 1 ? 'Đang Hoạt động' : 'Kết thúc'}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    : null}
                                                
                                                </tbody>
                                            </Table>
                                        
                                        </div>
                                    </div>
                                </div>
                                {/*<h6 className={"mb-3 text-danger text-decoration-underline text-uppercase"}>Thông tin xe</h6>*/}
                            
                            </div>
                        
                        </div>
                    </Card>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalInfo;
