import actionTypes from './actionTypes'
import {apiGetTaisan, apiNewTaisan, apiEditTaisan, apiDeleteTaisan, apiListTaiSanByNhanvien} from '~/services/taisan'
import { toast } from "react-toastify";
export const getListTaisan = (query) => async (dispatch) => {
    try {
        const response = await apiGetTaisan(query)
        // console.log('chek api lisst Taisan', response?.data.success)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_TAI_SAN,
                taisan: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_TAI_SAN,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_TAI_SAN,
            taisan: null
        })
    }
}
export const getListTaisanByNhanVien = (payload) => async (dispatch) => {
    try {
        const response = await apiListTaiSanByNhanvien(payload)
        // console.log('chek api lisst Taisan', response?.data.success)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_TAI_SAN_BY_NV,
                taisan: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_TAI_SAN_BY_NV,
                msg: response.data.msg
            })
        }
        
    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_TAI_SAN,
            taisan: null
        })
    }
}

export const NewTaisan = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewTaisan', payload)
            const response = await apiNewTaisan(payload)
            // console.log('chek api apiNewTaisan', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_TAI_SAN,
                    msg: 'OK'
                });
                dispatch(getListTaisan());
                toast.success("Thêm mới tài sản thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_TAI_SAN,
                    msg: 'ERR'
                })
                toast.error("Thêm mới tài sản thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_TAI_SAN,
                taisan: null
            })
        }
    }
}
export const EditTaisan = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditTaisan(payload)
            // console.log('chek api apiEditTaisan', response)
            if (response?.data.success === 200) {
                dispatch(getListTaisan());
                toast.success("Sửa thông tin tài sản thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_TAI_SAN,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_TAI_SAN,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin tài sản thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_TAI_SAN,
                taisan: null
            })
        }
    }
}
export const DeleteTaisan = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteTaisan(payload)
            // console.log('chek api apiDeleteTaisan', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListTaisan());
                toast.success("Xóa thông tin tài sản thành công!")
                dispatch({
                    type: actionTypes.DELETE_TAI_SAN,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_TAI_SAN,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin tài sản thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_TAI_SAN,
                taisan: null
            })
        }
    }
}