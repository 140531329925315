import axiosConfig from '../axiosConfig'

export const apiGetQuanlyquythuong = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: '/api/v2/quanlynhanvien/quanlyquythuong?page='+payload.start+'&limit='+payload.size+'&order='+payload.sorting+'&by='+payload.globalFilter+'$typeGet='+payload.typeGet,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiNewQuanlyquythuong = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlynhanvien/quanlyquythuong',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiEditQuanlyquythuong = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlynhanvien/quanlyquythuong',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiDeleteQuanlyquythuong = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlynhanvien/quanlyquythuong',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
