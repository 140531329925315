import React, {useEffect, useMemo, useState} from 'react';
import {Card,} from 'reactstrap';
import Content from '~/layout/content/Content';
import {Block} from '~/components/block/Block';
import {Button, DataTable, ExportXlsxExcel, Icon,} from '~/components/Component';
import {apiGetQuanlythamnien} from '~/services';
import {format, parseISO} from 'date-fns';
import {Box} from "@mui/material";
import {MaterialReactTable, useMaterialReactTable} from "material-react-table";
import {MRT_Localization_VI} from "material-react-table/locales/vi";
import {dataPagination, dataSelectGioitinh} from "~/utils/Datacustom";

const Quanlythamnien = () => {
    const [show, setShow] = useState(false);
    const [data, setData] = useState([]);
    const [columnVisibility, setColumnVisibility] = useState({});
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [totalRowCount, setTotalRowCount] = useState(0);
    
    useEffect(() => {
        fetchData().then(r => 'err');
    }, [pagination.pageIndex, pagination.pageSize, show]);
    
    async function fetchData() {
        try {
            const datapayloads = {
                typeGet: 1, // 1: lấy theo điều kiện phân trang, 2: lấy tất cả và không cần truyền d liệu phân trang
                start: pagination.pageIndex || 0,
                size: pagination.pageSize || 10,
                globalFilter: 'id',
                sorting: 'desc',
            };
            const response = await apiGetQuanlythamnien(datapayloads);
            console.log('response',response)
            // const response = resData.data.data.rows;
            if (response?.data?.success === 200) {
                setData(response?.data?.data?.rows);
                setTotalRowCount(response?.data?.data?.count);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    console.log('data',data)
    const Example = () => {
        
        let dataAb = data;
        const StatusGioiTinh = (cell) => ( // Function show trạng thái
            <Box
                component="span"
                sx={(theme) => ({
                    backgroundColor: cell === 1 ? '#1ee0ac' : '#e65100',
                    fontSize: '10px',
                    borderRadius: '0.25rem',
                    color: '#fff',
                    p: '0.25rem',
                })}
            >
                {dataSelectGioitinh.find(select => select.value === cell)?.label || dataSelectGioitinh[0].label}
            </Box>
        );
        const typeFormat = 'dd/MM/yyyy hh:mm'
        const FormatDate = (cell, type) => ( // Format Date : type = 'dd/MM/yyyy hh:mm', date = cell
            <Box>
                {format(parseISO(cell), type)}
            </Box>
        );
        const convertNumber = (cell) => ( // Format Date : type = 'dd/MM/yyyy hh:mm', date = cell
            <Box>
                {(Number(cell) / 12 < 0 ? 0 : Number(cell / 12)).toFixed(2)}
            </Box>
        );
        const formatCurrency = (value) => (
            (Number(value) || 0).toLocaleString('vi-VN', {style: 'currency', currency: 'VND'})
        );
        const columns = useMemo(
            () => [
                {
                    Header: '#', // Tiêu đề cột số thứ tự
                    header: 'STT', // Tiêu đề cột số thứ tự
                    accessorKey: 'rowNumber', // ID của cột
                    id: 'rowNumber', // ID của cột
                    size: 50,
                    Cell: ({row}) => <div>{row?.index + 1}</div>, // Giá trị của cột là số thứ tự
                    enableSorting: false,
                    enablePinning: true,
                },
                {
                    accessorKey: 'hoten',
                    id: 'hoten',
                    enablePinning: true,
                    size: 150,
                    header: 'Họ và tên',
                },
                {
                    accessorKey: 'uuid',
                    id: 'uuid',
                    enablePinning: true,
                    size: 150,
                    header: 'Mã nhân viên',
                },
                {
                    accessorKey: 'Phongban.phongban',
                    id: 'phongban',
                    enablePinning: true,
                    header: 'Phòng ban',
                },
                {
                    accessorKey: 'Chucvu.chucvu',
                    id: 'chucvu',
                    enablePinning: true,
                    header: 'Chức vụ',
                },
                {
                    accessorKey: 'ngayvaolamviec',
                    id: 'ngayvaolamviec',
                    header: 'Ngày vào làm việc (Ngày)',
                    enablePinning: true,
                    Cell: ({cell}) => cell?.getValue() !== null ? FormatDate(cell?.getValue(), typeFormat) : 'NAN',
                },
                {
                    accessorKey: 'thamNienData.phucapThamNien',
                    id: 'phucapThamNien',
                    Cell: ({cell}) => formatCurrency(cell?.getValue()),
                    enablePinning: true,
                    header: 'Phụ cấp Thâm niên(VND)',
                },
                {
                    accessorKey: 'thamNienData.thoigianPhuCap',
                    id: 'thoigianPhuCap',
                    Cell: ({cell}) => convertNumber(cell?.getValue()),
                    enablePinning: true,
                    header: 'Thời gian thâm niên(Năm)',
                },
                {
                    accessorKey: 'thamNienData.totalThangThuViec',
                    id: 'totalThangThuViec',
                    enablePinning: true,
                    header: 'Thời gian thử việc',
                },
                
            ],
            [],
        );
        const handleExportData = () => {
            const visibility = Object.keys(columnVisibility).filter(key => !columnVisibility[key]); // Lấy ra các cột không hiển thị
            let headerExport = columns.filter(item => !visibility.includes(item.id)); // Lọc ra các cột không hiển thị
            const getValue = headerExport.map((c) => c.accessorKey)
            const keyExport = headerExport.map((c) => c.id)
            let dataExport = dataAb?.map((item, indexx) => {
                let row = {};
                keyExport.forEach((key, i) => {
                    let path = getValue[i].split('.');
                    row[key] = path.reduce((o, p) => (o || {})[p], item);
                });
                row['rowNumber'] = indexx + 1
                return row;
            });
            const xlsxOptions = {
                data: dataExport,
                namefile: 'Quan-ly-tham-nien',
                headers: headerExport.map((c) => c.header),
            };
            
            const exportFunction = ExportXlsxExcel(xlsxOptions);
            exportFunction().then(r => 'err');
        };
        
        const topToolbarCustomActions = ({table}) => ( // Thêm nút thêm mới và xuất dữ liệu
            <Box sx={{display: 'flex', gap: '1rem'}}>
                <Button color="primary" onClick={handleExportData} variant="contained">
                    <Icon name="download-cloud me-2"></Icon> Xuất dữ liệu
                </Button>
            </Box>
        );
        const state = { // Trạng thái của bảng
            expanded: true,
            isLoading: dataAb.length === 0,
            pagination,
            columnVisibility
        };
        const initialState = { // Các trạng thái ban đầu của bảng đc gim
            columnPinning: {left: ['rowNumber', 'hoten', 'uuid']},
            density: 'compact'
        };
        const table = useMaterialReactTable({
                columns,
                data,
                // enableColumnResizing: false,
                
                initialState: initialState,
                state: state,
                defaultDisplayColumn: {enableResizing: true},
                rowCount: totalRowCount,
                manualPagination: true,
                onPaginationChange: setPagination,
                localization: MRT_Localization_VI,
                onColumnVisibilityChange: setColumnVisibility,
                enableGrouping: true,
                enableColumnPinning: true,
                renderTopToolbarCustomActions: topToolbarCustomActions,
                muiPaginationProps: {
                    rowsPerPageOptions: dataPagination,
                },
            }
        );
        
        
        return (
            <MaterialReactTable
                table={table}
            />
        );
    };
    return (
        <React.Fragment>
            {/*<Head title="User List - Regular"></Head>*/}
            <Content>
                <Card className="mt-0">
                    <div className="card-aside-wrap row">
                        <div className="card-inner card-inner-lg px-4">
                            <h4 data-date={format(new Date(), 'dd-MM-yyyy')}>Quản lý thâm niên</h4>
                        </div>
                    </div>
                </Card>
                <Block>
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner position-relative card-tools-toggle">
                            <Example/>
                        </div>
                    </DataTable>
                </Block>
            </Content>
        </React.Fragment>
    
    );
};

export default Quanlythamnien;
