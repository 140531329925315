import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputForm, Button } from '~/components/Form';
import * as actions from '~/store/actions';
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import { validatePhuCap } from '~/request/validatePhuCap';
import { Input } from 'reactstrap';
import { dataLoaiPhuCap, dataSelectLoaiSuKien } from '~/utils/Datacustom';
import { formatVietnameseToString } from '~/utils/Common/formatVietnameseToString';
import Select from 'react-select';
import Textarea from '~/components/Form/Textarea';
import { dataSelectStatus } from '~/utils/Datacustom';

const ModalEditPhucap = ({ show, setShow, dataIdPhucap, fieldList }) => {
    // console.log('dataIdPhucap',dataIdPhucap)
    const dispatch = useDispatch();
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayloadPhucap] = useState(fieldList);
    const [listLoaiPhuCap, setListLoaiPhuCap] = useState(fieldList);
    // console.log('payload', payload)
    useEffect(() => {
        setListLoaiPhuCap(dataLoaiPhuCap);
    }, [dataLoaiPhuCap]);
    useEffect(() => {
        let loaiPhuCap = dataLoaiPhuCap.find((items) => {
            return items && items.value === dataIdPhucap?.loaiphucap;
        });
        let trangthai = dataSelectStatus?.find((item) => {
            return item && item.value === Number(dataIdPhucap?.TrangthaiId);
        });
        show &&
            dataIdPhucap &&
            setPayloadPhucap({
                title: dataIdPhucap?.title,
                mota: dataIdPhucap?.mota,
                maphucap: dataIdPhucap?.maphucap,
                loaiPhuCap: { label: loaiPhuCap?.label, value: loaiPhuCap?.value },
                noidung: loaiPhuCap?.description,
                apdungdongbaohiem: dataIdPhucap?.apdungdongbaohiem,
                apdungdongthue: dataIdPhucap?.apdungdongthue,
                TrangthaiId: { label: trangthai?.label, value: trangthai?.value },
            });
    }, [dataIdPhucap, show]);

    const handleSubmit = async () => {
        let invalids = validate(payload);

        if (invalids === 0) {
            let title = payload?.title;
            let loaiPhuCap = payload?.loaiPhuCap?.value;
            let maPhuCap = '';
            if (loaiPhuCap === '' +
                '') {
                maPhuCap = loaiPhuCap;
            } else {
                maPhuCap = 'PC_' + formatVietnameseToString(title);
            }
            let datapayload = {
                id: dataIdPhucap?.id,
                title: title,
                loaiphucap: loaiPhuCap,
                mota: payload.mota,
                maphucap: maPhuCap.toUpperCase(),
                apdungdongbaohiem: payload.apdungdongbaohiem === true ? '1' : '0',
                apdungdongthue: payload.apdungdongthue === true ? '1' : '0',
                TrangthaiId: payload?.TrangthaiId?.value,
            };
            //   console.log('check payload', datapayload)
            dispatch(actions.EditPhucap(datapayload));
        }
    };
    const handleOnSelect = async (selected, name) => {
        // console.log('deadsasa',selected)
        let statename = name.name;
        setPayloadPhucap((prev) => ({ ...prev, [statename]: selected }));
        setPayloadPhucap((prev) => ({ ...prev, ['noidung']: selected.description }));
    };
    // console.log('payload', payload);
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validatePhuCap(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validatePhuCap(item[0], item[1])]);
                invalids++;
            }
        });
        return invalids;
    };
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">Sửa phụ cấp</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-12">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Tiêu đề'}
                            value={payload.title}
                            setValue={setPayloadPhucap}
                            keyPayload={'title'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-12">
                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                            Loại phụ cấp <b className="text-danger">(*)</b>
                        </label>
                        <Select
                            value={payload.loaiPhuCap}
                            onChange={handleOnSelect}
                            options={listLoaiPhuCap}
                            placeholder={'Loại phụ cấp'}
                            name="loaiPhuCap"
                            onFocus={() => setInvalidFields([])}
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'loaiPhuCap') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'loaiPhuCap')?.message}
                            </small>
                        )}
                    </div>
                    <div className="form-group col-md-12 mb-2">
                        <Textarea
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Mô tả loại phụ cấp'}
                            value={payload.noidung}
                            disabled={true}
                            setValue={setPayloadPhucap}
                            keyPayload={'noidung'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-12 mb-2">
                        <label htmlFor="inputZip" className="mb-2">
                            <span>
                                Mô tả <b className="text-danger">(*)</b>
                            </span>
                        </label>
                        <Input
                            value={payload.mota}
                            onChange={(e) => setPayloadPhucap((prev) => ({ ...prev, ['mota']: e.target.value }))}
                            rows={'2'}
                            name="mota"
                            type="textarea"
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'mota') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'mota')?.message}
                            </small>
                        )}
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="form-group col-md-4">
                        <div className="custom-control custom-checkbox">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="apdungdongbaohiem"
                                name="apdungdongbaohiem"
                                onChange={(e) =>
                                    setPayloadPhucap((prev) => ({ ...prev, ['apdungdongbaohiem']: e.target.checked }))
                                }
                                checked={payload.apdungdongbaohiem}
                            />
                            <label className="custom-control-label" htmlFor="apdungdongbaohiem">
                                Áp dụng đóng bảo hiểm{' '}
                            </label>
                        </div>
                    </div>
                    <div className="form-group col-md-4">
                        <div className="custom-control custom-checkbox">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="apdungdongthue"
                                name="apdungdongthue"
                                onChange={(e) =>
                                    setPayloadPhucap((prev) => ({ ...prev, ['apdungdongthue']: e.target.checked }))
                                }
                                checked={payload.apdungdongthue}
                            />
                            <label className="custom-control-label" htmlFor="apdungdongthue">
                                Áp dụng đóng thuế thu nhập cá nhân{' '}
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="form-group col-md-4">
                        <label className="mb-2 fw-bold">
                            <span>
                                Trạng thái <b className="text-danger">(*)</b>
                            </span>
                        </label>
                        <Select
                            value={payload.TrangthaiId}
                            onChange={(selected) =>
                                setPayloadPhucap((prev) => ({ ...prev, ['TrangthaiId']: selected }))
                            }
                            options={dataSelectStatus}
                            name="TrangthaiId"
                            onFocus={() => setInvalidFields([])}
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'TrangthaiId') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'TrangthaiId')?.message}
                            </small>
                        )}
                    </div>
                </div>
                <div className="col-12 mt-5">
                    <Button text="Cập nhật" bClass="btn btn-primary px-5" textColor onClick={handleSubmit} />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalEditPhucap;
