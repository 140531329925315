import actionTypes from "../actions/actionTypes";
const initState = {
    cauhinhcalamviec: [],
    msg: '',
    // count: 0,
    newcauhinhcalamviec: []
}

const cauhinhcalamviecReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.GET_LIST_CAU_HINH_CA_LAM_VIEC:
            return {
                ...state,
                cauhinhcalamviec: action?.cauhinhcalamviec || [],
                msg: 'Ok' || '',
                count: action?.cauhinhcalamviec || 0
            }
        case actionTypes.POST_NEW_CAU_HINH_CA_LAM_VIEC:
        case actionTypes.PUT_EDIT_CAU_HINH_CA_LAM_VIEC:
        case actionTypes.DELETE_CAU_HINH_CA_LAM_VIEC:
            return {
                ...state,
                msg: action.msg || '',
                newcauhinhcalamviec: action?.newcauhinhcalamviec || []
            }
        default:
            return state;
    }

}

export default cauhinhcalamviecReducer