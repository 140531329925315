import actionTypes from "../actions/actionTypes";
const initState = {
    dexuat: [],
    msg: '',
    count: 0,
    newdexuat: []
}

const dexuatReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.GET_LIST_DE_XUAT:
            return {
                ...state,
                dexuat: action?.dexuat?.rows || [],
                msg: 'Ok' || '',
                count: action?.dexuat?.count || 0
            }
        case actionTypes.POST_NEW_DE_XUAT:
        case actionTypes.PUT_EDIT_DE_XUAT:
        case actionTypes.DELETE_DE_XUAT:
            return {
                ...state,
                msg: action.msg || '',
                newdexuat: action?.newdexuat || []
            }
        default:
            return state;
    }

}

export default dexuatReducer