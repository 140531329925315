import React, { useState, useEffect } from 'react';
import { InputForm, Button } from '~/components/Form';
import { validateVanThu } from '~/request/validateVanThu';
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import {dataSelectLoaiVanThu, dataSelectStatus} from "~/utils/Datacustom";
import {apiNewVanthu} from "~/services";
import {toast} from "react-toastify";
import {format, parseISO} from "date-fns";

const ModalVanthu = ({ show, setShow, fieldList }) => {
    const [listTrangthai, setlistTrangthai] = useState([]);
    const [loaiVanThu, setloaiVanThu] = useState([]);
    
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayloadVanthu] = useState(fieldList);
    useEffect(() => {
        show && setPayloadVanthu(fieldList);
    }, [show]);
    useEffect(() => {
        let TrangthaiId = dataSelectStatus.map((item) => ({label: item.label, value: item.value}));
        setlistTrangthai(TrangthaiId);
        let LoaivanthuId = dataSelectLoaiVanThu.map((item) => ({label: item.label, value: item.value}));
        setloaiVanThu(LoaivanthuId);
    }, []);
    const handleSubmit = async () => {
       
        let invalids = validate(payload);
        if (invalids === 0) {
            // format(parseISO(cell?.getValue()), 'dd-MM-yyyy')
            const ngayGui = format(parseISO(payload.ngaygui), 'yyyy-MM-dd HH:mm:ss')
            const ngayNhan = format(parseISO(payload.ngaynhan), 'yyyy-MM-dd HH:mm:ss')
            let datapayload = {
                data: [
                    {
                        LoaivanthuId: payload.LoaivanthuId.value,
                        TrangthaiId: payload.TrangthaiId.value,
                        douutien: payload.douutien ?? '0',
                        ghichu: payload.ghichu,
                        mavanthu: payload.mavanthu,
                        vanthu: payload.vanthu,
                        ngaygui: ngayGui,
                        ngaynhan: ngayNhan,
                        nguoinhan: payload.nguoinhan,
                        nguoigui: payload.nguoigui,
                        chiphi: payload.chiphi,
                    },
                ],
            };
            const response = await apiNewVanthu(datapayload)
            if (response?.data.success === 200) {
                setShow(false);
                toast.success("Thêm mới văn thư thành công!")
            } else {
                toast.error("Thêm mới văn thư thất bại!")
            }
            
        }
    };
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayloadVanthu((prev) => ({ ...prev, [statename]: selected }));
    };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateVanThu(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateVanThu(item[0], item[1])]);
                invalids++;
            }
        });
        return invalids;
    };
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">Thêm mới văn thư</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-4">
                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                                Loại văn thư <b className="text-danger">(*)</b>
                            </span>
                        </label>
                        <Select
                            value={payload.LoaivanthuId}
                            onChange={handleOnSelect}
                            options={loaiVanThu}
                            placeholder={'Chọn loại'}
                            name="LoaivanthuId"
                            onFocus={() => setInvalidFields([])}
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'LoaivanthuId') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'LoaivanthuId')?.message}
                            </small>
                        )}
                    </div>
                    <div className="form-group col-md-4">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Mã văn thư'}
                            value={payload.mavanthu}
                            setValue={setPayloadVanthu}
                            keyPayload={'mavanthu'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                                Trạng thái <b className="text-danger">(*)</b>
                            </span>
                        </label>
                        <Select
                            value={payload.TrangthaiId}
                            onChange={handleOnSelect}
                            options={listTrangthai}
                            placeholder={'Trạng thái'}
                            name="TrangthaiId"
                            onFocus={() => setInvalidFields([])}
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'TrangthaiId') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'TrangthaiId')?.message}
                            </small>
                        )}
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Người gửi'}
                            value={payload.nguoigui}
                            setValue={setPayloadVanthu}
                            keyPayload={'nguoigui'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ngày gửi'}
                            value={payload.ngaygui}
                            setValue={setPayloadVanthu}
                            keyPayload={'ngaygui'}
                            type="datetime-local"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Người nhận'}
                            value={payload.nguoinhan}
                            setValue={setPayloadVanthu}
                            keyPayload={'nguoinhan'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ngày nhận'}
                            value={payload.ngaynhan}
                            setValue={setPayloadVanthu}
                            keyPayload={'ngaynhan'}
                            type="datetime-local"
                            validate
                        />
                    </div>
              
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Chi phí(nếu có)'}
                            value={payload.chiphi}
                            setValue={setPayloadVanthu}
                            keyPayload={'chiphi'}
                            type="number"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-9">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ghi chú'}
                            value={payload.ghichu}
                            setValue={setPayloadVanthu}
                            keyPayload={'ghichu'}
                            type="text"
                            validate
                        />
                    </div>
                </div>

                <div className="col-12 mt-5">
                    <Button text="Thêm mới" bClass="btn btn-primary px-5" textColor onClick={handleSubmit} />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalVanthu;
