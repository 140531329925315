import actionTypes from "../actions/actionTypes";
const initState = {
    nhanvien: [],
    nhanvienone: [],
    msg: '',
    count: 0,
    newnhanvien: [],
    nhomnv: [],
    countNhomnv: 0,
    newNhomnv: [],

    quyetdinh: [],
    countQuyetdinh: 0,
}

const nhanvienReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.GET_LIST_NHAN_VIEN_ONE:
            return {
                ...state,
                nhanvienone: action?.nhanvienone?.rows || [],
                msg: 'Ok' || '',
                count: action?.nhanvienone?.count || 0,
            }
        case actionTypes.GET_LIST_NHAN_VIEN:
            return {
                ...state,
                nhanvien: action?.nhanvien?.rows || [],
                msg: 'Ok' || '',
                count: action?.nhanvien?.count || 0,
            }
        case actionTypes.POST_NEW_NHAN_VIEN:
        case actionTypes.PUT_EDIT_NHAN_VIEN:
        case actionTypes.DELETE_NHAN_VIEN:
            return {
                ...state,
                msg: action.msg || '',
                newnhanvien: action?.newnhanvien || [],
            }
            //nhóm ূপাদ nhân viên
        case actionTypes.GET_NHOM_BY_NHAN_VIEN:
            return {
                ...state,
                nhomBynv: action?.nhomBynv?.rows || [],
                countnhomBynv: action?.nhomBynv?.count || 0
            }
            //nhóm nhân viên
        case actionTypes.GET_LIST_NHOM_NHAN_VIEN:
            return {
                ...state,
                nhomnv: action?.nhomnv?.rows || [],
                countNhomnv: action?.nhomnv?.count || 0
            }
        case actionTypes.POST_NEW_NHOM_NHAN_VIEN:
        case actionTypes.PUT_EDIT_NHOM_NHAN_VIEN:
        case actionTypes.DELETE_NHOM_NHAN_VIEN:
            return {
                ...state,
                msg: action.msg || '',
                newNhomnv: action?.newNhomnv || [],
            }

        case actionTypes.GET_LIST_QUYET_DINH:
            return {
                ...state,
                quyetdinh: action?.quyetdinh?.data || [],
                countQuyetdinh: action?.quyetdinh?.count || 0
            }
        default:
            return state;
    }

}

export default nhanvienReducer