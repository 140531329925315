import React, { useEffect, useState } from 'react';
import Head from '~/layout/head/Head';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import {
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    Modal,
    ModalBody,
    DropdownItem,
    Form,
    Spinner,
} from 'reactstrap';
import {
    Block,
    BlockBetween,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Icon,
    Row,
    Col,
    UserAvatar,
    PaginationComponent,
    Button,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableRow,
    DataTableItem,
    TooltipComponent,
    RSelect,
} from '~/components/Component';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import config from '~/config';
import Select from 'react-select';
import {
    apiGetModuleUserV2,
    apiPostModuleUserV2,
    apiPutModuleUserV2,
    apiDeleteModuleUserV2,
    apiGetModuleV2,
    apiGetUserAll,
} from '~/services';
const ModuleUser = () => {
    const { user } = useSelector((state) => state.app);
    const [userInfo, setUserInfo] = useState([]);
    const [modal, setModal] = useState(false);
    const [data, setData] = useState([]);
    const [module, setlistModule] = useState([]);
    const [userall, setlistUserAll] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        setUserInfo(user);
        if (userInfo.length !== 0 && +userInfo?.phanquyen !== 0) {
            navigate(config.login);
        }
        const fetchData = async () => {
            try {
                const response = await apiGetModuleUserV2();
                setData(response.data.data.rows);
                const Modulev2 = await apiGetModuleV2();
                let dataSelectModule = Modulev2?.data?.data?.rows.map((item) => ({ label: item.mota, value: item.id }));
                setlistModule(dataSelectModule);
                const UserAll = await apiGetUserAll();
                let dataSelectUserAll = UserAll?.data?.data?.rows.map((item) => ({
                    label: item.username,
                    value: item.id,
                }));
                setlistUserAll(dataSelectUserAll);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData().catch(console.error);
    }, []);
    const [sm, updateSm] = useState(false);
    const [tablesm, updateTableSm] = useState(false);
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [sort, setSortState] = useState('');
    const onFilterChange = (e) => {
        setSearchText(e.target.value);
    };
    // function to toggle the search option
    const toggle = () => setonSearch(!onSearch);
    // function to change the check property of an item
    const selectorCheck = (e) => {
        let newData;
        newData = data.map((item) => {
            item.checked = e.currentTarget.checked;
            return item;
        });
        setData([...newData]);
    };
    // function to change the selected property of an item
    const onSelectChange = (e, id) => {
        let newData = data;
        let index = newData.findIndex((item) => item.id === id);
        newData[index].checked = e.currentTarget.checked;
        setData([...newData]);
    };

    // Changing state value when searching name
    useEffect(() => {
        if (onSearchText !== '') {
            const filteredObject = data.filter((item) => {
                return (
                    item.ho.toLowerCase().includes(onSearchText.toLowerCase()) ||
                    item.ten.toLowerCase().includes(onSearchText.toLowerCase())
                );
            });
            setData([...filteredObject]);
        } else {
            setData([...data]);
        }
    }, [onSearchText, setData]);
    // console.log('data', data)
    // Get current list, pagination
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
    // Change Page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const [invalidFields, setInvalidFields] = useState([]);
    const [titleForm, setTitleForm] = useState(false);
    const [payload, setPayload] = useState({
        UserId: '',
        QuanlymoduleId: '',
    });
    // console.log(payload)
    const handleEditModuleUser = async (item) => {
        setModal(true);
        setTitleForm(true);
        setPayload({
            id: item?.id,
            UserId: { label: item?.User?.username, value: item?.UserId },
            QuanlymoduleId: { label: item?.Quanlymodule?.mota, value: item?.QuanlymoduleId },
        });
    };
    const handleDeleteModuleUser = async (item) => {
        Swal.fire({
            title: 'Bạn có chắc không?',
            text: 'Bạn sẽ không thể lấy lại khi xóa!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Không, bỏ qua!',
            confirmButtonText: 'Vâng, xóa nó đi!',
        }).then((result) => {
            if (result.isConfirmed) {
                const fetchData = async () => {
                    try {
                        const response = await apiDeleteModuleUserV2(item);
                        if (response.data.success === 200) {
                            toast.success('Xóa thành công');
                            const response = await apiGetModuleUserV2();
                            setData(response.data.data.rows);
                        } else {
                            toast.error('Xóa thất bại!');
                        }
                    } catch (error) {
                        console.error('Error fetching data:', error);
                    }
                };
                fetchData().catch(console.error);
            }
        });
    };

    const handleSubmit = async () => {
        let invalids = validate(payload);
        if (invalids === 0) {
            const datapayload = {
                data: [
                    {
                        UserId: payload?.UserId?.value,
                        QuanlymoduleId: payload?.QuanlymoduleId?.value,
                    },
                ],
            };
            try {
                if (titleForm === false) {
                    // console.log('datapayload1', datapayload)
                    const response = await apiPostModuleUserV2(datapayload);
                    // console.log('response', response);
                    if (response.data.success === 200) {
                        toast.success('Thêm thành công');
                        setModal(false);
                        const response = await apiGetModuleUserV2();
                        setData(response.data.data.rows);
                    } else {
                        toast.error('Thêm thất bại!');
                    }
                } else {
                    datapayload.data[0].id = payload?.id;
                    // console.log('datapayload2', datapayload)
                    const response = await apiPutModuleUserV2(datapayload);
                    if (response.data.success === 200) {
                        toast.success('Sửa thành công');
                        setModal(false);
                        const response = await apiGetModuleUserV2();
                        setData(response.data.data.rows);
                    } else {
                        toast.error('Sửa thất bại!');
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    };
    const validate = (payloadLogin) => {
        let invalids = 0;
        let fields = Object.entries(payloadLogin);
        fields.forEach((item) => {
            if (item[1] === '') {
                setInvalidFields((prev) => [
                    ...prev,
                    {
                        name: item[0],
                        message: 'Bạn không được bỏ trống trường này.',
                    },
                ]);
                invalids++;
            }
        });
        return invalids;
    };
    return (
        <React.Fragment>
            <Head title="User List - Profile"></Head>
            <BlockHead size="lg">
                <BlockBetween>
                    <BlockHeadContent>
                        <BlockTitle tag="h4">Danh sách Module User</BlockTitle>
                    </BlockHeadContent>
                    <BlockHeadContent>
                        <div className="toggle-wrap nk-block-tools-toggle">
                            <div className="toggle-expand-content" style={{ display: sm ? 'block' : 'none' }}>
                                <ul className="nk-block-tools g-3">
                                    <li className="nk-block-tools-opt">
                                        <Button
                                            color="primary"
                                            className="btn-icon px-1"
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                setModal(true);
                                                setTitleForm(false);
                                                setPayload({
                                                    UserId: '',
                                                    QuanlymoduleId: '',
                                                });
                                            }}
                                        >
                                            <Icon name="plus"></Icon> Thêm mới module user
                                        </Button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </BlockHeadContent>
                </BlockBetween>
            </BlockHead>

            <Block>
                <DataTable className="card-stretch">
                    <div className="card-inner position-relative card-tools-toggle">
                        <div className="card-title-group">
                            <div className="card-title">
                                <h5 className="title">Danh sách module</h5>
                            </div>
                            <div className="card-tools me-n1">
                                <ul className="btn-toolbar gx-1">
                                    <li>
                                        <a
                                            href="#search"
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                toggle();
                                            }}
                                            className="btn btn-icon search-toggle toggle-search"
                                        >
                                            <Icon name="search"></Icon>
                                        </a>
                                    </li>
                                    <li className="btn-toolbar-sep"></li>
                                    <li>
                                        <div className="toggle-wrap">
                                            <Button
                                                className={`btn-icon btn-trigger toggle ${tablesm ? 'active' : ''}`}
                                                onClick={() => updateTableSm(true)}
                                            >
                                                <Icon name="menu-right"></Icon>
                                            </Button>

                                            <div className={`toggle-content ${tablesm ? 'content-active' : ''}`}>
                                                <ul className="btn-toolbar gx-1">
                                                    <li className="toggle-close">
                                                        <Button
                                                            className="btn-icon btn-trigger toggle"
                                                            onClick={() => updateTableSm(false)}
                                                        >
                                                            <Icon name="arrow-left"></Icon>
                                                        </Button>
                                                    </li>
                                                    <li>
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle
                                                                tag="a"
                                                                className="btn btn-trigger btn-icon dropdown-toggle"
                                                            >
                                                                <div className="dot dot-primary"></div>
                                                                <Icon name="filter-alt"></Icon>
                                                            </DropdownToggle>
                                                            <DropdownMenu
                                                                end
                                                                className="filter-wg dropdown-menu-xl"
                                                                style={{ overflow: 'visible' }}
                                                            >
                                                                <div className="dropdown-head">
                                                                    <span className="sub-title dropdown-title">
                                                                        Bộ lọc
                                                                    </span>

                                                                    <div className="dropdown">
                                                                        <a
                                                                            href="#more"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();
                                                                            }}
                                                                            className="btn btn-sm btn-icon"
                                                                        >
                                                                            <Icon name="more-h"></Icon>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div className="dropdown-body dropdown-body-rg">
                                                                    <Row className="gx-6 gy-3">
                                                                        <Col size="6">
                                                                            <div className="custom-control custom-control-sm custom-checkbox">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="custom-control-input"
                                                                                    id="hasBalance"
                                                                                />
                                                                                <label
                                                                                    className="custom-control-label"
                                                                                    htmlFor="hasBalance"
                                                                                >
                                                                                    {' '}
                                                                                    Have Balance
                                                                                </label>
                                                                            </div>
                                                                        </Col>
                                                                        <Col size="6">
                                                                            <div className="custom-control custom-control-sm custom-checkbox">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="custom-control-input"
                                                                                    id="hasKYC"
                                                                                />
                                                                                <label
                                                                                    className="custom-control-label"
                                                                                    htmlFor="hasKYC"
                                                                                >
                                                                                    {' '}
                                                                                    KYC Verified
                                                                                </label>
                                                                            </div>
                                                                        </Col>
                                                                        <Col size="6">
                                                                            <div className="form-group">
                                                                                <label className="overline-title overline-title-alt">
                                                                                    Role
                                                                                </label>
                                                                                <RSelect placeholder="Any Role" />
                                                                            </div>
                                                                        </Col>
                                                                        <Col size="6">
                                                                            <div className="form-group">
                                                                                <label className="overline-title overline-title-alt">
                                                                                    Status
                                                                                </label>
                                                                                <RSelect placeholder="Any Status" />
                                                                            </div>
                                                                        </Col>
                                                                        <Col size="12">
                                                                            <div className="form-group">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-secondary"
                                                                                >
                                                                                    Filter
                                                                                </button>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                                <div className="dropdown-foot between">
                                                                    <a
                                                                        href="#reset"
                                                                        onClick={(ev) => {
                                                                            ev.preventDefault();
                                                                        }}
                                                                        className="clickable"
                                                                    >
                                                                        Cập nhật bộ lọc 
                                                                    </a>
                                                                    <a
                                                                        href="#save"
                                                                        onClick={(ev) => {
                                                                            ev.preventDefault();
                                                                        }}
                                                                    >
                                                                        Lưu bộ lọc 
                                                                    </a>
                                                                </div>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </li>
                                                    <li>
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle
                                                                color="tranparent"
                                                                className="btn btn-trigger btn-icon dropdown-toggle"
                                                            >
                                                                <Icon name="setting"></Icon>
                                                            </DropdownToggle>
                                                            <DropdownMenu end className="dropdown-menu-xs">
                                                                <ul className="link-check">
                                                                    <li>
                                                                        <span>Show</span>
                                                                    </li>
                                                                    <li className={itemPerPage === 10 ? 'active' : ''}>
                                                                        <DropdownItem
                                                                            tag="a"
                                                                            href="#dropdownitem"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();
                                                                                setItemPerPage(10);
                                                                            }}
                                                                        >
                                                                            10
                                                                        </DropdownItem>
                                                                    </li>
                                                                    <li className={itemPerPage === 15 ? 'active' : ''}>
                                                                        <DropdownItem
                                                                            tag="a"
                                                                            href="#dropdownitem"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();
                                                                                setItemPerPage(15);
                                                                            }}
                                                                        >
                                                                            15
                                                                        </DropdownItem>
                                                                    </li>
                                                                </ul>
                                                                <ul className="link-check">
                                                                    <li>
                                                                        <span>Order</span>
                                                                    </li>

                                                                    <li className={sort === 'dsc' ? 'active' : ''}>
                                                                        <DropdownItem
                                                                            tag="a"
                                                                            href="#dropdownitem"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();

                                                                                setSortState('dsc');
                                                                                //   sortFunc("dsc");
                                                                            }}
                                                                        >
                                                                            DESC
                                                                        </DropdownItem>
                                                                    </li>
                                                                    <li className={sort === 'asc' ? 'active' : ''}>
                                                                        <DropdownItem
                                                                            tag="a"
                                                                            href="#dropdownitem"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();

                                                                                setSortState('asc');

                                                                                //   sortFunc("asc");
                                                                            }}
                                                                        >
                                                                            ASC
                                                                        </DropdownItem>
                                                                    </li>
                                                                </ul>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className={`card-search search-wrap ${!onSearch && 'active'}`}>
                            <div className="card-body">
                                <div className="search-content">
                                    <Button
                                        className="search-back btn-icon toggle-search active"
                                        onClick={() => {
                                            setSearchText('');

                                            toggle();
                                        }}
                                    >
                                        <Icon name="arrow-left"></Icon>
                                    </Button>
                                    <input
                                        type="text"
                                        className="border-transparent form-focus-none form-control"
                                        placeholder="Tìm kiến nhân viên"
                                        value={onSearchText}
                                        onChange={(e) => onFilterChange(e)}
                                    />
                                    <Button className="search-submit btn-icon">
                                        <Icon name="search"></Icon>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DataTableBody>
                        <DataTableHead>
                            <DataTableRow className="nk-tb-col-check">
                                <div className="custom-control custom-control-sm custom-checkbox notext">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        onChange={(e) => selectorCheck(e)}
                                        id="uid"
                                    />
                                    <label className="custom-control-label" htmlFor="uid"></label>
                                </div>
                            </DataTableRow>
                            <DataTableRow>
                                <span className="sub-text">Người dùng</span>
                            </DataTableRow>
                            <DataTableRow size="mb">
                                <span className="sub-text">Module</span>
                            </DataTableRow>
                            <DataTableRow className="nk-tb-col-tools text-end">
                                <UncontrolledDropdown>
                                    <DropdownToggle
                                        color="tranparent"
                                        className="btn btn-xs btn-outline-light btn-icon dropdown-toggle"
                                    >
                                        <Icon name="plus"></Icon>
                                    </DropdownToggle>
                                </UncontrolledDropdown>
                            </DataTableRow>
                        </DataTableHead>
                        {/*Head*/}
                        {currentItems.length > 0
                            ? currentItems.map((item) => {
                                  return (
                                      <DataTableItem key={item.id}>
                                          <DataTableRow className="nk-tb-col-check">
                                              <div className="custom-control custom-control-sm custom-checkbox notext">
                                                  <input
                                                      type="checkbox"
                                                      className="custom-control-input"
                                                      defaultChecked={item.checked}
                                                      id={item.id + 'uid1'}
                                                      key={Math.random()}
                                                      onChange={(e) => onSelectChange(e, item.id)}
                                                  />
                                                  <label
                                                      className="custom-control-label"
                                                      htmlFor={item.id + 'uid1'}
                                                  ></label>
                                              </div>
                                          </DataTableRow>
                                          <DataTableRow>
                                              <div className="user-card">
                                                  <div className="user-info">
                                                      <span className="tb-lead">{item?.User?.username}</span>
                                                  </div>
                                              </div>
                                              {/* </Link> */}
                                          </DataTableRow>
                                          <DataTableRow size="mb">
                                              <span className="tb-amount">{item?.Quanlymodule?.mota}</span>
                                          </DataTableRow>

                                          <DataTableRow className="nk-tb-col-tools">
                                              <ul className="nk-tb-actions gx-1">
                                                  <li
                                                      className="nk-tb-action-hidden"
                                                      onClick={() => handleEditModuleUser(item)}
                                                  >
                                                      <TooltipComponent
                                                          tag="a"
                                                          containerClassName="btn btn-trigger btn-icon"
                                                          id={'edit' + item.id}
                                                          icon="edit-alt-fill"
                                                          direction="top"
                                                          text="Sửa"
                                                      />
                                                  </li>
                                                  <React.Fragment>
                                                      <li
                                                          className="nk-tb-action-hidden"
                                                          onClick={() => handleDeleteModuleUser(item)}
                                                      >
                                                          <TooltipComponent
                                                              tag="a"
                                                              containerClassName="btn btn-trigger btn-icon"
                                                              id={'suspend' + item.id}
                                                              icon="user-cross-fill"
                                                              direction="top"
                                                              text="Xóa"
                                                          />
                                                      </li>
                                                  </React.Fragment>
                                                  <li>
                                                      <UncontrolledDropdown>
                                                          <DropdownToggle
                                                              tag="a"
                                                              className="dropdown-toggle btn btn-icon btn-trigger"
                                                          >
                                                              <Icon name="more-h"></Icon>
                                                          </DropdownToggle>
                                                          <DropdownMenu end>
                                                              <ul className="link-list-opt no-bdr">
                                                                  <li onClick={() => handleEditModuleUser(item)}>
                                                                      <DropdownItem tag="a" href="#edit">
                                                                          <Icon name="edit"></Icon>
                                                                          <span>Sửa</span>
                                                                      </DropdownItem>
                                                                  </li>
                                                                  <React.Fragment>
                                                                      <li className="divider"></li>
                                                                      <li onClick={() => handleDeleteModuleUser(item)}>
                                                                          <DropdownItem
                                                                              tag="a"
                                                                              href="#suspend"
                                                                              onClick={(ev) => {
                                                                                  ev.preventDefault();
                                                                              }}
                                                                          >
                                                                              <Icon name="na"></Icon>
                                                                              <span>Xóa</span>
                                                                          </DropdownItem>
                                                                      </li>
                                                                  </React.Fragment>
                                                              </ul>
                                                          </DropdownMenu>
                                                      </UncontrolledDropdown>
                                                  </li>
                                              </ul>
                                          </DataTableRow>
                                      </DataTableItem>
                                  );
                              })
                            : null}
                    </DataTableBody>
                    <div className="card-inner">
                        {currentItems.length > 0 ? (
                            <PaginationComponent
                                itemPerPage={itemPerPage}
                                totalItems={data.length}
                                paginate={paginate}
                                currentPage={currentPage}
                            />
                        ) : (
                            <div className="text-center">
                                <div>
                                    {' '}
                                    <Spinner color="primary" />
                                </div>
                                <span className="text-silent">Chưa có dữ liệu!</span>
                            </div>
                        )}
                    </div>
                </DataTable>
            </Block>

            <Modal isOpen={modal} className="modal-dialog-centered" size="lg" toggle={() => setModal(false)}>
                <ModalBody>
                    <div className="p-2">
                        <h5 className="title mb-3">{titleForm === false ? 'Thêm Module User' : 'Sửa Module User'}</h5>

                        <Row className="gy-4">
                            <Col md="6">
                                <div className="form-group">
                                    <label htmlFor="inputZip" className="mb-2 fw-bold">
                                        <span>
                                            Người dùng <b className="text-danger">(*)</b>
                                        </span>
                                    </label>
                                    <Select
                                        value={payload.UserId}
                                        onChange={(selected) =>
                                            setPayload((prev) => ({ ...prev, ['UserId']: selected }))
                                        }
                                        options={userall}
                                        placeholder={'Chọn người dùng'}
                                        name="UserId"
                                        onFocus={() => setInvalidFields([])}
                                    />
                                    {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'UserId') && (
                                        <small className="text-danger">
                                            {invalidFields.find((i) => i.name === 'UserId')?.message}
                                        </small>
                                    )}
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="form-group">
                                    <label htmlFor="inputZip" className="mb-2 fw-bold">
                                        <span>
                                            Module <b className="text-danger">(*)</b>
                                        </span>
                                    </label>
                                    <Select
                                        value={payload.QuanlymoduleId}
                                        onChange={(selected) =>
                                            setPayload((prev) => ({ ...prev, ['QuanlymoduleId']: selected }))
                                        }
                                        options={module}
                                        placeholder={'Chọn module'}
                                        name="QuanlymoduleId"
                                        onFocus={() => setInvalidFields([])}
                                    />
                                    {invalidFields.length > 0 &&
                                        invalidFields.some((i) => i.name === 'QuanlymoduleId') && (
                                            <small className="text-danger">
                                                {invalidFields.find((i) => i.name === 'QuanlymoduleId')?.message}
                                            </small>
                                        )}
                                </div>
                            </Col>
                        </Row>
                        <Col size="12 mt-5">
                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                    <Button color="primary" size="lg" onClick={handleSubmit}>
                                        {titleForm === false ? 'Thêm Module User' : 'Sửa Module User'}
                                    </Button>
                                </li>
                                <li>
                                    <a
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            setModal(false);
                                        }}
                                        className="link link-light"
                                    >
                                        Hủy bỏ
                                    </a>
                                </li>
                            </ul>
                        </Col>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};
export default ModuleUser;
