import actionTypes from './actionTypes'
import { apiGetBangchotcong, apiNewBangchotcong, apiEditBangchotcong, apiDeleteBangchotcong } from '~/services/bangchotcong'
import { toast } from "react-toastify";
export const getListBangchotcong = () => async (dispatch) => {
    try {
        const response = await apiGetBangchotcong()
        // console.log('apiGetBangchotcong',response)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_BANG_CHOT_CONG,
                bangchotcong: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_BANG_CHOT_CONG,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_BANG_CHOT_CONG,
            bangchotcong: null
        })
    }
}

export const NewBangchotcong = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewBangchotcong', payload)
            const response = await apiNewBangchotcong(payload)
            console.log('chek api apiNewBangchotcong', response)
            if (response?.status === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_BANG_CHOT_CONG,
                    msg: 'OK'
                });
                dispatch(getListBangchotcong());
                toast.success("Thêm mới bảng chấm công thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_BANG_CHOT_CONG,
                    msg: 'ERR'
                })
                toast.error("Thêm mới bảng chấm công thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_BANG_CHOT_CONG,
                bangchotcong: null
            })
        }
    }
}
export const EditBangchotcong = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditBangchotcong(payload)
            if (response?.data.success === 200) {
                dispatch(getListBangchotcong());
                toast.success(response?.data.message);
                dispatch({
                    type: actionTypes.PUT_EDIT_BANG_CHOT_CONG,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_BANG_CHOT_CONG,
                    msg: 'ERR'
                })
                toast.error(response?.data.message)
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_BANG_CHOT_CONG,
                bangchotcong: null
            })
        }
    }
}
export const DeleteBangchotcong = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteBangchotcong(payload)
            if (response?.data.success === 200) {
                dispatch(getListBangchotcong());
                toast.success(response?.data.message)
                dispatch({
                    type: actionTypes.DELETE_BANG_CHOT_CONG,
                    msg: 'OK'
                });
               
            }else if(response?.data.success === 401){
                toast.error(response?.data.message)
            } else {
                dispatch({
                    type: actionTypes.DELETE_BANG_CHOT_CONG,
                    msg: 'ERR'
                })
                toast.error(response?.data.message)
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_BANG_CHOT_CONG,
                bangchotcong: null
            })
        }
    }
}