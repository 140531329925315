export const validateDanhGiaNhanVien = (fieldName, value) => {
    let regNumber = /^[0-9\b]+$/;
    switch (fieldName) {
        case 'NhanvienId':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống.'
                }
            }
            break;
        case 'nguoidanhgia':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống.'
                }
            }
            break;
        case 'danhgia':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống.'
                }
            }
            break;
        case 'nhanxet':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống.'
                }
            }
            break;
        case 'ghichu':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống.'
                }
            }
            break;
        default:
            break;
    }
}