import axiosConfig from '../axiosConfig'

export const apiGetHopdonglaodong = (query) => new Promise(async (resolve, reject) => {
    try {
        // console.log('query', query)
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlyhethong/hopdonglaodong`,
            params: query,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiNewHopdonglaodong = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlyhethong/hopdonglaodong',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiEditHopdonglaodong = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlyhethong/hopdonglaodong',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiDeleteHopdonglaodong = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlyhethong/hopdonglaodong',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiGetLoaihopdong = () => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlyhethong/loaihopdonglaodong/hopdonglaodong`,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiNewLoaihopdong = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlyhethong/loaihopdonglaodong/hopdonglaodong',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiEditLoaihopdong = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlyhethong/loaihopdonglaodong/hopdonglaodong',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiDeleteLoaihopdong = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlyhethong/loaihopdonglaodong/hopdonglaodong',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiGetMauhopdong = () => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlynhanvien/templatehopdong`,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiNewMauhopdong = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlynhanvien/templatehopdong',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiEditMauhopdong = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlynhanvien/templatehopdong',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiDeleteMauhopdong = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlynhanvien/templatehopdong',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
