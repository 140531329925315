import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Header from '~/layouts/components/Header';

import Footer from '~/layouts/components/Footer';
import Settings from '~/layouts/components/Settings';
// import Sidebar from '~/layouts/components/Sidebar';
import styles from './DefaultLayout.module.scss';

const cx = classNames.bind(styles);

function DefaultLayout({ children }) {
    return (
        <div>
           <Header />
            <div id="main-wrapper">
                <div className={cx('site-wrapper-reveal')}>{children}</div>
                <Footer />
            </div>
            <Settings />
        </div>
    );
}

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default DefaultLayout;
