import actionTypes from "../actions/actionTypes";
const initState = {
    maychamcong: [],
    msg: '',
    count: 0,
    newmaychamcong: []
}

const maychamcongReducer = (state = initState, action) => {
    // console.log(action)
    switch (action.type) {
        case actionTypes.GET_LIST_MAY_CHAM_CONG:
            return {
                ...state,
                maychamcong: action?.maychamcong?.rows || [],
                msg: 'Ok' || '',
                count: action?.maychamcong?.count || 0
            }
        case actionTypes.POST_NEW_MAY_CHAM_CONG:
        case actionTypes.PUT_EDIT_MAY_CHAM_CONG:
        case actionTypes.DELETE_MAY_CHAM_CONG:
            return {
                ...state,
                msg: action.msg || '',
                newmaychamcong: action?.newmaychamcong || []
            }
        default:
            return state;
    }

}

export default maychamcongReducer