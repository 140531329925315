
/**
 * Dữ liệu trong báo cáo tổng quát
 *      data = [
 *                 {data: 'data1', color: '#04f620', label: 'label1'},
 *                 {data: 'data2', color: '#04f632', label: 'label2'},
 *                 {data: 'data3', color: '#043320', label: 'label3'},
 *                 {data: 'data4', color: '#0ff620', label: 'label4'}
 *             ]
 *          dataCount =
 *                 {
 *                         tmpDataCurrent: 12,
 *                         tmpDataPrev: 13,
 *                         tmpDataCurrentActive: 14,
 *                         tmpDataPreActive: 15,
 *                 }
 * @return {object} Thông tin để render ra biểu đồ =>
 *     const chart1 = {
 *                 countUngvien: dataCount,
 *                 labels: month,
 *                 dataUnit: "People",
 *                 datasets: dataSheet
 *             }
 * @param data
 * @param month
 * @param dataCount
 */
export const SetDatadrawLineChart = (data, month, dataCount) => {
    // console.log('data', data);
    const dataSheet = data?.map((item, index) => {
        const isOddIndex = (index + 2) % 2 !== 0;
        
        return {
            label: item.label,
            borderWidth: 2,
            borderColor: item.color,
            backgroundColor: isOddIndex ? "transparent" : "rgba(157, 114, 255, 0.15)",
            pointBorderColor: "transparent",
            pointBackgroundColor: "transparent",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: item.color,
            pointBorderWidth: 2,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 2,
            pointRadius: 4,
            pointHitRadius: 4,
            data: item.data,
        };
    });
    
    return {
        countUngvien: dataCount,
        labels: month,
        dataUnit: "People",
        datasets: dataSheet,
    };
};