import actionTypes from './actionTypes'
import { apiGetDangkiemphuongtien, apiNewDangkiemphuongtien, apiEditDangkiemphuongtien, apiDeleteDangkiemphuongtien } from '~/services/dangkiemphuongtien'
import { toast } from "react-toastify";
export const getListDangkiemphuongtien = () => async (dispatch) => {
    try {
        const response = await apiGetDangkiemphuongtien()
        console.log('chek api lisst Dangkiemphuongtien', response)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_DANG_KIEM_PHUONG_TIEN,
                dangkiemphuongtien: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_DANG_KIEM_PHUONG_TIEN,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_DANG_KIEM_PHUONG_TIEN,
            dangkiemphuongtien: null
        })
    }
}

export const NewDangkiemphuongtien = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewDangkiemphuongtien', payload)
            const response = await apiNewDangkiemphuongtien(payload)
            // console.log('chek api apiNewDangkiemphuongtien', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_DANG_KIEM_PHUONG_TIEN,
                    msg: 'OK'
                });
                dispatch(getListDangkiemphuongtien());
                toast.success("Thêm mới thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_DANG_KIEM_PHUONG_TIEN,
                    msg: 'ERR'
                })
                toast.error("Thêm mới thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_DANG_KIEM_PHUONG_TIEN,
                dangkiemphuongtien: null
            })
        }
    }
}
export const EditDangkiemphuongtien = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditDangkiemphuongtien(payload)
            // console.log('chek api apiEditDangkiemphuongtien', response)
            if (response?.data.success === 200) {
                dispatch(getListDangkiemphuongtien());
                toast.success("Sửa thông tin thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_DANG_KIEM_PHUONG_TIEN,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_DANG_KIEM_PHUONG_TIEN,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_DANG_KIEM_PHUONG_TIEN,
                dangkiemphuongtien: null
            })
        }
    }
}
export const DeleteDangkiemphuongtien = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteDangkiemphuongtien(payload)
            // console.log('chek api apiDeleteDangkiemphuongtien', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListDangkiemphuongtien());
                toast.success("Xóa thông tin thành công!")
                dispatch({
                    type: actionTypes.DELETE_DANG_KIEM_PHUONG_TIEN,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_DANG_KIEM_PHUONG_TIEN,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_DANG_KIEM_PHUONG_TIEN,
                dangkiemphuongtien: null
            })
        }
    }
}