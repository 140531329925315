export const validateKieuLuong = (fieldName, value) => {
    switch (fieldName) {
        case 'tieude':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Tiêu đề không được để trống.'
                }
            }
            break;
        case 'mota':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Mô tả không được bỏ trống trường này.'
                }
            }
            break;
        default:
            break;
    }
}