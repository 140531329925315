import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {InputForm, Button} from '~/components/Form'
import * as actions from '~/store/actions'

import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import {validateBangChamCong} from "~/request/validateBangChamCong";

const ModalEditBangchamcong = ({show, setShow, dataIdBangchamcong,fieldList}) => {
    const {phongban} = useSelector(state => state.phongban)
    const dispatch = useDispatch()
    const [invalidFields, setInvalidFields] = useState([])
    const [payload, setPayloadBangchamcong] = useState(fieldList)
    useEffect(() => {
        
        let PhongbanId = phongban.find(item => {
            return item && item.id === dataIdBangchamcong?.Phongban?.id
        })
        show && dataIdBangchamcong && setPayloadBangchamcong({
            PhongbanId: {label: PhongbanId?.phongban, value: PhongbanId?.id},
            chucvu: dataIdBangchamcong?.chucvu,
            motavitri: dataIdBangchamcong?.motavitri,
        })
    }, [dataIdBangchamcong, show])
    
 
    const [listPhongban, setlistPhongban] = useState([])
    useEffect(() => {
        if ( phongban) {
            let dataSelectPhongban = phongban.map((item) => ({ label: item.phongban, value: item.id }));
            setlistPhongban(dataSelectPhongban)
        }
    }, [phongban])
    const handleSubmit = async () => {
        let invalids = validate(payload)
        
        if (invalids === 0) {
            let datapayload = {
                id: dataIdBangchamcong.id,
                PhongbanId: payload.PhongbanId.value,
                chucvu: payload.chucvu,
                motavitri: payload.motavitri,
            }
            //   console.log('check payload', datapayload)
            dispatch(actions.EditBangchamcong(datapayload))
        }
    }
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayloadBangchamcong(prev => ({...prev, [statename]: selected}))
    }
    const validate = (payload) => {
        let invalids = 0
        let fields = Object.entries(payload)
        fields.forEach(item => {
            if (validateBangChamCong(item[0], item[1]) !== undefined) {
                setInvalidFields(prev => [...prev, validateBangChamCong(item[0], item[1])])
                invalids++
            }
        })
        return invalids
    }
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Sửa chức vụ
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    
                    <div className="form-group col-md-6 ">
                        <label htmlFor="inputZip" className="mb-2">Phòng ban</label>
                        <Select
                            value={payload.PhongbanId}
                            onChange={handleOnSelect}
                            options={listPhongban}
                            placeholder={'Chọn phòng ban'}
                            name='PhongbanId'
                            onFocus={() => setInvalidFields([])}
                        />
                        {invalidFields.length > 0 && invalidFields.some(i => i.name === 'PhongbanId') && <small
                            className='text-danger'>{invalidFields.find(i => i.name === 'PhongbanId')?.message}</small>}
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Chức vụ'}
                            value={payload.chucvu}
                            setValue={setPayloadBangchamcong}
                            keyPayload={'chucvu'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-6 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Mô tả vị trí'}
                            value={payload.motavitri}
                            setValue={setPayloadBangchamcong}
                            keyPayload={'motavitri'}
                            type='text'
                        />
                    </div>
                </div>
                
                <div className='col-12 mt-5'>
                    <Button
                        text='Cập nhật'
                        bClass='btn btn-primary px-5'
                        textColor
                        onClick={handleSubmit}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ModalEditBangchamcong;

