import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {InputForm} from '~/components/Form';
import {Icon, SelectNhanvien} from '~/components/Component';
import {validateBaoHiemNhanVien} from '~/request/validateBaoHiemNhanVien';
import '../Modal.scss';
import {Modal, ModalBody} from 'reactstrap';
// import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import {dataSelectBHXH} from '~/utils/Datacustom';
import {Input} from 'reactstrap';
import {apiNewBaohiemnhanvien} from "~/services";
import {toast} from "react-toastify";

const ModalBaohiemnhanvien = ({show, setShow, fieldList}) => {
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayloadBaohiemnhanvien] = useState(fieldList);
    // console.log('payload', payload)
    const [showSelect, setShowSelect] = useState(false);
    const [listNhanvien, setlistNhanvien] = useState([]);
    // console.log('listNhanvien', listNhanvien)
    useEffect(() => {
        listNhanvien && setPayloadBaohiemnhanvien((prev) => ({...prev, ['NhanvienId']: listNhanvien[0]}));
    }, [listNhanvien]);
    useEffect(() => {
        show && setPayloadBaohiemnhanvien(fieldList);
    }, [show]);
    
    const [listTrangthai, setlistTrangthai] = useState([]);
    useEffect(() => {
        let tinhtrangBHXH = dataSelectBHXH.map((item) => ({label: item.label, value: item.value}));
        setlistTrangthai(tinhtrangBHXH);
    }, []);
    const handleSubmit = async () => {
        console.log('check payload', payload);
        let invalids = validate(payload);
        if (invalids === 0) {
            let datapayload = {
                NhanvienId: payload?.NhanvienId.value,
                sothebhyt: payload?.sothebhyt,
                nguoinhansobh: payload?.nguoinhansobh,
                trangthainopsobh: payload?.trangthainopsobh?.value,
                ngaythamgiabh: payload?.ngaythamgiabh,
                phicongdoan: payload?.phicongdoan,
                phicongdoanctydong: payload?.phicongdoanctydong,
                ghichu: payload?.ghichu,
            };
            console.log('check payload', datapayload);
           
            const response = await apiNewBaohiemnhanvien(datapayload);
            console.log('check response', response);
            if (response?.data.success === 200) {
                toast.success(response?.data.data)
                setShow(false);
            } else {
                toast.error(response?.data.data)
            }
        }
    };
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayloadBaohiemnhanvien((prev) => ({...prev, [statename]: selected}));
    };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateBaoHiemNhanVien(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateBaoHiemNhanVien(item[0], item[1])]);
                invalids++;
            }
        });
        return invalids;
    };
    return (
        <React.Fragment>
            <Modal isOpen={show} className="modal-dialog-centered modal-90w" size="lg" toggle={setShow}>
                <ModalBody>
                    <Icon name="cross-sm float-end fs-2" onClick={setShow}></Icon>
                    <h5 className="title mb-5"></h5>
                    <div className="row">
                        <div className="form-group col-md-3" onClick={() => setShowSelect(true)}>
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                Chọn Nhân Viên <b className="text-danger">(*)</b>
                            </label>
                            <Select value={payload?.NhanvienId} name="NhanvienId" onFocus={() => setInvalidFields([])}/>
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'NhanvienId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'NhanvienId')?.message}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-3">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Sổ BHXH'}
                                value={payload.sothebhyt}
                                setValue={setPayloadBaohiemnhanvien}
                                keyPayload={'sothebhyt'}
                                placeholder={'Sổ BHXH'}
                                type="number"
                                validate
                            />
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                <span>
                                    Áp dụng
                                </span>
                            </label>
                            <Select
                                value={payload.trangthainopsobh}
                                onChange={handleOnSelect}
                                options={listTrangthai}
                                name="trangthainopsobh"
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'trangthainopsobh') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'trangthainopsobh')?.message}
                                </small>
                            )}
                        </div>
                        
                        <div className="form-group col-md-3">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Ngày Tham Gia BH'}
                                value={payload.ngaythamgiabh}
                                setValue={setPayloadBaohiemnhanvien}
                                keyPayload={'ngaythamgiabh'}
                                placeholder={'Ngày Tham Gia BH'}
                                type="date"
                                validate
                            />
                        </div>
                        <div className="form-group col-md-3">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Người Nhận Sổ BH'}
                                value={payload.nguoinhansobh}
                                setValue={setPayloadBaohiemnhanvien}
                                keyPayload={'nguoinhansobh'}
                                placeholder={'Người Nhận Sổ BH'}
                                type="text"
                                validate
                            />
                        </div>
                        <div className="form-group col-md-3">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Phí Công Đoàn NV(%)'}
                                value={payload.phicongdoan}
                                setValue={setPayloadBaohiemnhanvien}
                                keyPayload={'phicongdoan'}
                                placeholder={'Phí Công Đoàn NV'}
                                type="number"
                                validate
                            />
                        </div>
                        <div className="form-group col-md-3">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Phí Công Đoàn Công Ty(%)'}
                                value={payload.phicongdoanctydong}
                                setValue={setPayloadBaohiemnhanvien}
                                keyPayload={'phicongdoanctydong'}
                                placeholder={'Phí Công Đoàn Công Ty'}
                                type="number"
                                validate
                            />
                        </div>
                        <div className="form-group col-md-12 mb-2">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                <span>
                                    Ghi chú
                                </span>
                            </label>
                            <Input
                                value={payload.ghichu}
                                onChange={(e) =>
                                    setPayloadBaohiemnhanvien((prev) => ({...prev, ['ghichu']: e.target.value}))
                                }
                                rows={'2'}
                                name="ghichu"
                                type="textarea"
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'ghichu') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'ghichu')?.message}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className="btn-group my-2">
                        <button className="btn btn-primary px-5" onClick={handleSubmit}>
                            Thêm mới
                        </button>
                    </div>
                </ModalBody>
            </Modal>
            <SelectNhanvien
                showSelect={showSelect}
                // typeSelect = { ismultiple: "Lấy nhiều" , single: "lấy một" }
                setlistNhanvien={setlistNhanvien}
                setShowSelect={() => setShowSelect(false)}
            />
        </React.Fragment>
    );
};

export default ModalBaohiemnhanvien;
