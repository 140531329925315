import React, { useState } from 'react';
import { DropdownToggle, DropdownMenu, UncontrolledDropdown, DropdownItem, Spinner } from 'reactstrap';
import { TCHopdong } from '../../charts/analytics/AnalyticsCharts';
import { format } from 'date-fns';
const TrafficHopdong = ({ baoCaoHD }) => {
    // console.log('baoCaoHD', baoCaoHD);
    const tinhphantram = (data) => {
        const total = baoCaoHD?.reduce(
            (accumulator, currentValue) => accumulator + currentValue?.Hopdonglaodongs?.length,
            0,
        );
        return (data / total) * 100;
    };

    const colorMap = {
        1: '#185581',
        2: '#00aeef',
        3: '#1ee0ac',
        4: '#f3b210',
        5: '#e85347',
        6: '#ff63a5',
    };

    const color = (data) => colorMap[data] || '';

    let data = baoCaoHD?.map((item, index) => ({
        tenloaihopdong: item?.tenloaihopdong,
        value: item?.Hopdonglaodongs?.length,
        color: color(index + 1),
        phantram: tinhphantram(item?.Hopdonglaodongs?.length),
    }));

    let mang1 = [];
    let mang2 = [];
    let mang3 = [];
    let data1 = baoCaoHD?.forEach((item, index) => {
        mang1.push(item?.tenloaihopdong);
        mang2.push(item?.Hopdonglaodongs?.length);
        mang3.push(color(index + 1));
    });

    let data2 = {
        labels: mang1,
        dataUnit: 'People',
        legend: false,
        datasets: [
            {
                borderColor: '#fff',
                backgroundColor: mang3,
                data: mang2,
            },
        ],
    };
    // console.log('data2', data2);
    // const [traffic, setTraffic] = useState('7');
    const currentDate = new Date();
    // console.log('currentDate', format(currentDate, 'dd-MM-yyyy'));
    return (
        <React.Fragment>
            {' '}
            <div className="card-title-group">
                <div className="card-title card-title-sm">
                    <h6 className="title">Thống kê hợp đồng theo loại</h6>
                </div>
                <p>Tất cả đơn vị - Đến ngày {format(currentDate, 'dd/MM/yyyy')}</p>
            </div>
            {baoCaoHD?.length > 0 ? (
                <div className="traffic-channel">
                    <div className="traffic-channel-doughnut-ck">
                        <TCHopdong state={data2} className="analytics-doughnut"></TCHopdong>
                    </div>
                    <div className="traffic-channel-group g-2">
                        {data?.length > 0
                            ? data?.map((item, index) => {
                                  return (
                                      <div className="traffic-channel-data" key={index}>
                                          <div className="title">
                                              <span className="dot dot-lg sq" style={{ background: item.color }}></span>
                                              <span>{item.tenloaihopdong}</span>
                                          </div>
                                          <div className="amount">
                                              {item.value} <small>({item.phantram.toFixed(2)}%)</small>
                                          </div>
                                      </div>
                                  );
                              })
                            : null}
                    </div>
                </div>
            ) : (
                <div className="text-center mt-5">
                    <div >
                        <Spinner color="primary" />
                    </div>
                    <span className="text-silent">Chưa có dữ liệu!</span>
                </div>
            )}
        </React.Fragment>
    );
};
export default TrafficHopdong;
