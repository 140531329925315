import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '~/store/actions';
import { ModalLoaiquydinhphucloi, ModalEditLoaiquydinhphucloi } from './Modal';
import Swal from 'sweetalert2';
import Head from '~/layout/head/Head';
import Content from '~/layout/content/Content';
import { Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle } from '~/components/block/Block';
import {
    Button,
    Col,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    Icon,
    PaginationComponent,
    Row,
    RSelect,
    TooltipComponent,
    PermissionUtils,
} from '~/components/Component';
import { DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, UncontrolledDropdown, Spinner, } from 'reactstrap';
import exportFromJSON from 'export-from-json';
const Loaiquydinhphucloi = () => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [showEditLoaiquydinhphucloi, setShowEditLoaiquydinhphucloi] = useState(false);
    useEffect(() => {
        dispatch(actions.getListLoaiquydinhphucloi());
    }, []);
    const fieldList = {
        mota: '',
        noidung: '',
        giatri: '',
        sothutu: '',
        status: '',
    };
    const { loaiquydinhphucloi } = useSelector((state) => state.loaiquydinhphucloi);
    useEffect(() => {
        if (loaiquydinhphucloi) {
            setData([...loaiquydinhphucloi]);
        }
    }, [loaiquydinhphucloi]);

    const [showPhanquyen, setShowPhanquyen] = useState({});
    let dataPermissionUtils = PermissionUtils('quydinhphucloi', 'all');
    useEffect(() => {
        if (dataPermissionUtils) {
            setShowPhanquyen(dataPermissionUtils);
        }
    }, [dataPermissionUtils]);

    const [data, setData] = useState([]);
    const [sm, updateSm] = useState(false);
    const [tablesm, updateTableSm] = useState(false);
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [sort, setSortState] = useState('');
    const onFilterChange = (e) => {
        setSearchText(e.target.value);
    };
    const toggle = () => setonSearch(!onSearch);
    const selectorCheck = (e) => {
        let newData;
        newData = data.map((item) => {
            item.checked = e.currentTarget.checked;
            return item;
        });
        setData([...newData]);
    };
    const onSelectChange = (e, id) => {
        let newData = data;
        let index = newData.findIndex((item) => item.id === id);
        newData[index].checked = e.currentTarget.checked;
        setData([...newData]);
    };
    useEffect(() => {
        if (onSearchText !== '') {
            const filteredObject = loaiquydinhphucloi.filter((item) => {
                return (
                    item.ho.toLowerCase().includes(onSearchText.toLowerCase()) ||
                    item.ten.toLowerCase().includes(onSearchText.toLowerCase())
                );
            });
            setData([...filteredObject]);
        } else {
            setData([...loaiquydinhphucloi]);
        }
    }, [onSearchText, setData]);
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
    // Change Page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const Export = ({ data }) => {
        const [modal, setModal] = useState(false);

        useEffect(() => {
            if (modal === true) {
                setTimeout(() => setModal(false), 2000);
            }
        }, [modal]);

        const fileName = 'Danh-sach-cong-ty';

        const exportCSV = () => {
            const exportType = exportFromJSON.types.csv;
            exportFromJSON({ data, fileName, exportType });
        };

        const exportExcel = () => {
            const exportType = exportFromJSON.types.xls;
            exportFromJSON({ data, fileName, exportType });
        };

        return (
            <React.Fragment>
                <div className="dt-export-buttons d-flex align-center">
                    <div className="dt-export-title d-none d-md-inline-block">Xuất dữ liệu</div>
                    <div className="dt-buttons btn-group flex-wrap">
                        <button
                            className="btn btn-secondary buttons-csv buttons-html5"
                            type="button"
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            title="File .CSV"
                            onClick={() => exportCSV()}
                        >
                            <span>CSV</span>
                        </button>{' '}
                        <button
                            className="btn btn-secondary buttons-excel buttons-html5"
                            type="button"
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            title="File xls"
                            onClick={() => exportExcel()}
                        >
                            <span>Excel</span>
                        </button>{' '}
                    </div>
                </div>
                <Modal isOpen={modal} className="modal-dialog-centered text-center" size="sm">
                    <ModalBody className="text-center m-2">
                        <h5>Copied to clipboard</h5>
                    </ModalBody>
                    <div className="p-3 bg-light">
                        <div className="text-center">Copied {data.length} rows to clipboard</div>
                    </div>
                </Modal>
            </React.Fragment>
        );
    };
    // console.log('list cong ty', congty)

    useEffect(() => {
        loaiquydinhphucloi && setShow(false);
    }, [loaiquydinhphucloi]);
    // set state để mở đóng Modal
    const handleAddNewQuydinhphucloi = async () => {
        setShow(true);
    };
    const [dataIdQuydinhphucloi, setDataIdQuydinhphucloi] = useState([]);
    const handleEditLoaiquydinhphucloi = async (data) => {
        setShowEditLoaiquydinhphucloi(true);
        await setDataIdQuydinhphucloi(data);
    };
    const handleDeleteQuydinhphucloi = (data) => {
        Swal.fire({
            title: 'Bạn có chắc không?',
            text: 'Bạn sẽ không thể lấy lại khi xóa!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Không, bỏ qua!',
            confirmButtonText: 'Vâng, xóa nó đi!',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(actions.DeleteQuydinhthuongphat(data));
            }
        });
    };

    return (
        <React.Fragment>
            <Head title="Quy định phúc lợi"></Head>
            <Content>
               
                <Block>
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner position-relative card-tools-toggle">
                            <div className="card-title-group mt-2">
                                <div className="card-title">
                                    <h4 className="title">Quy định phúc lợi</h4>
                                    {+showPhanquyen?.them === 1 && (
                                            <li className="nk-block-tools-opt">
                                                <Button
                                                    color="primary"
                                                    className="btn-icon px-1"
                                                    onClick={handleAddNewQuydinhphucloi}
                                                >
                                                    <Icon name="plus"></Icon> Thêm mới
                                                </Button>
                                            </li>
                                        )}
                                </div>
                                <div className="card-tools me-n1">
                                    <ul className="btn-toolbar gx-1">
                                        <li>
                                            <a
                                                href="#search"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    toggle();
                                                }}
                                                className="btn btn-icon search-toggle toggle-search"
                                            >
                                                <Icon name="search"></Icon>
                                            </a>
                                        </li>
                                        <li className="btn-toolbar-sep"></li>
                                        <li>
                                            <div className="toggle-wrap">
                                                <Button
                                                    className={`btn-icon btn-trigger toggle ${tablesm ? 'active' : ''}`}
                                                    onClick={() => updateTableSm(true)}
                                                >
                                                    <Icon name="menu-right"></Icon>
                                                </Button>
                                                <div className={`toggle-content ${tablesm ? 'content-active' : ''}`}>
                                                    <ul className="btn-toolbar gx-1">
                                                        <li className="toggle-close">
                                                            <Button
                                                                className="btn-icon btn-trigger toggle"
                                                                onClick={() => updateTableSm(false)}
                                                            >
                                                                <Icon name="arrow-left"></Icon>
                                                            </Button>
                                                        </li>
                                                        
                                                        <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle
                                                                    color="tranparent"
                                                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                                                >
                                                                    <Icon name="setting"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu end className="dropdown-menu-xs">
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Show</span>
                                                                        </li>
                                                                        <li
                                                                            className={
                                                                                itemPerPage === 10 ? 'active' : ''
                                                                            }
                                                                        >
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setItemPerPage(10);
                                                                                }}
                                                                            >
                                                                                10
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li
                                                                            className={
                                                                                itemPerPage === 15 ? 'active' : ''
                                                                            }
                                                                        >
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setItemPerPage(15);
                                                                                }}
                                                                            >
                                                                                15
                                                                            </DropdownItem>
                                                                        </li>
                                                                    </ul>
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Order</span>
                                                                        </li>
                                                                        <li className={sort === 'dsc' ? 'active' : ''}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setSortState('dsc');
                                                                                    //   sortFunc("dsc");
                                                                                }}
                                                                            >
                                                                                DESC
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li className={sort === 'asc' ? 'active' : ''}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setSortState('asc');
                                                                                    //   sortFunc("asc");
                                                                                }}
                                                                            >
                                                                                ASC
                                                                            </DropdownItem>
                                                                        </li>
                                                                    </ul>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className={`card-search search-wrap ${!onSearch && 'active'}`}>
                                <div className="card-body">
                                    <div className="search-content">
                                        <Button
                                            className="search-back btn-icon toggle-search active"
                                            onClick={() => {
                                                setSearchText('');
                                                toggle();
                                            }}
                                        >
                                            <Icon name="arrow-left"></Icon>
                                        </Button>
                                        <input
                                            type="text"
                                            className="border-transparent form-focus-none form-control"
                                            placeholder="Tìm kiếm"
                                            value={onSearchText}
                                            onChange={(e) => onFilterChange(e)}
                                        />
                                        <Button className="search-submit btn-icon">
                                            <Icon name="search"></Icon>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ overflowY: 'hidden' }}>
                        <DataTableBody>
                            <DataTableHead>
                                <DataTableRow>
                                    <span className="sub-text">stt</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span className="sub-text w-150px">Mô tả</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span className="sub-text w-150px">Nội dung</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span className="sub-text w-150px">Giá trị</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span className="sub-text w-150px">Số thứ tự</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span className="sub-text w-100px">Trạng thái</span>
                                </DataTableRow>
                            </DataTableHead>
                            {currentItems.length > 0
                                ? currentItems.map((item, index) => {
                                      return (
                                          <DataTableItem key={item.id}>
                                              <DataTableRow>
                                                  <span className="tb-amount">{index + 1}</span>
                                              </DataTableRow>
                                              <DataTableRow>
                                                  <span className="tb-amount">{item?.mota}</span>
                                              </DataTableRow>
                                              <DataTableRow>
                                                  <span className="tb-amount">{item?.noidung}</span>
                                              </DataTableRow>
                                              <DataTableRow>
                                                  <span className="tb-amount">{item?.giatri}</span>
                                              </DataTableRow>
                                              <DataTableRow>
                                                  <span className="tb-amount">{item?.sothutu}</span>
                                              </DataTableRow>
                                              <DataTableRow>
                                                  <span className="tb-amount">{item?.status}</span>
                                              </DataTableRow>

                                              <DataTableRow className="nk-tb-col-tools">
                                                  <ul className="nk-tb-actions gx-1">
                                                      {+showPhanquyen?.sua === 1 && (
                                                          <li
                                                              className="nk-tb-action-hidden"
                                                              onClick={() => handleEditLoaiquydinhphucloi(item)}
                                                          >
                                                              <TooltipComponent
                                                                  tag="a"
                                                                  containerClassName="btn btn-trigger btn-icon"
                                                                  id={'edit' + item.id}
                                                                  icon="edit-alt-fill"
                                                                  direction="top"
                                                                  text="Sửa"
                                                              />
                                                          </li>
                                                      )}
                                                      {+showPhanquyen?.xoa === 1 && (
                                                          <React.Fragment>
                                                              <li
                                                                  className="nk-tb-action-hidden"
                                                                  onClick={() => handleDeleteQuydinhphucloi(item)}
                                                              >
                                                                  <TooltipComponent
                                                                      tag="a"
                                                                      containerClassName="btn btn-trigger btn-icon"
                                                                      id={'suspend' + item.id}
                                                                      icon="user-cross-fill"
                                                                      direction="top"
                                                                      text="Xóa"
                                                                  />
                                                              </li>
                                                          </React.Fragment>
                                                      )}
                                                      <li>
                                                          <UncontrolledDropdown>
                                                              <DropdownToggle
                                                                  tag="a"
                                                                  className="dropdown-toggle btn btn-icon btn-trigger"
                                                              >
                                                                  <Icon name="more-h"></Icon>
                                                              </DropdownToggle>
                                                              <DropdownMenu end>
                                                                  <ul className="link-list-opt no-bdr">
                                                                      {+showPhanquyen?.sua === 1 && (
                                                                          <li
                                                                              onClick={() =>
                                                                                  handleEditLoaiquydinhphucloi(item)
                                                                              }
                                                                          >
                                                                              <DropdownItem
                                                                                  tag="a"
                                                                                  href="#edit"
                                                                                  onClick={(ev) => {
                                                                                      ev.preventDefault();
                                                                                  }}
                                                                              >
                                                                                  <Icon name="edit"></Icon>
                                                                                  <span>Sửa</span>
                                                                              </DropdownItem>
                                                                          </li>
                                                                      )}
                                                                      {+showPhanquyen?.xoa === 1 && (
                                                                          <React.Fragment>
                                                                              <li className="divider"></li>
                                                                              <li
                                                                                  onClick={() =>
                                                                                      handleDeleteQuydinhphucloi(item)
                                                                                  }
                                                                              >
                                                                                  <DropdownItem
                                                                                      tag="a"
                                                                                      href="#suspend"
                                                                                      onClick={(ev) => {
                                                                                          ev.preventDefault();
                                                                                      }}
                                                                                  >
                                                                                      <Icon name="na"></Icon>
                                                                                      <span>Xóa</span>
                                                                                  </DropdownItem>
                                                                              </li>
                                                                          </React.Fragment>
                                                                      )}
                                                                  </ul>
                                                              </DropdownMenu>
                                                          </UncontrolledDropdown>
                                                      </li>
                                                  </ul>
                                              </DataTableRow>
                                          </DataTableItem>
                                      );
                                  })
                                : null}
                        </DataTableBody>
                        </div>
                        <div className="card-inner">
                            {currentItems.length > 0 ? (
                                <PaginationComponent
                                    itemPerPage={itemPerPage}
                                    totalItems={loaiquydinhphucloi.length}
                                    paginate={paginate}
                                    currentPage={currentPage}
                                />
                            ) : (
                                <div className="text-center">
                                <div>
                                    <Spinner color="primary" />
                                </div>
                                <span className="text-silent">Chưa có dữ liệu!</span>
                            </div>
                            )}
                        </div>
                    </DataTable>
                </Block>

                <ModalLoaiquydinhphucloi show={show} setShow={() => setShow(false)} fieldList={fieldList} />
                <ModalEditLoaiquydinhphucloi
                    show={showEditLoaiquydinhphucloi}
                    setShow={() => setShowEditLoaiquydinhphucloi(false)}
                    dataIdQuydinhphucloi={dataIdQuydinhphucloi}
                    fieldList={fieldList}
                />
            </Content>
        </React.Fragment>
    );
};

export default Loaiquydinhphucloi;
