import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {InputForm, Button, DatePickerTime} from '~/components/Form'
import * as actions from '~/store/actions'

import '../Modal.scss';
import {Modal, ModalBody} from 'reactstrap';
import Select from 'react-select';
import {format} from "date-fns";
import {validateUngVien} from "~/request/validateUngVien";
import {Icon, SelectNhanvien} from "~/components/Component";
import {dataSelectDanhGia, trangthaiTaisan} from "~/utils/Datacustom";
import {apiEditDanhgianhanvien, apiEditDanhgiaNhanVien, apiGetDanhgiaNhanVien, apiNewDanhgiaNhanVien} from "~/services";
import {toast} from "react-toastify";
import {EditDanhgianhanvien, getListDanhgianhanvien} from "~/store/actions";
import actionTypes from "~/store/actions/actionTypes";

const ModalEditDanhgianhanviens = ({show, setShow, dataIdDanhGia, fieldList}) => {
    
    const [listNhanvien, setlistNhanvien] = useState([])
    
    const dispatch = useDispatch()
    const [invalidFields, setInvalidFields] = useState([])
    const [payload, setPayloadDanhGia] = useState(fieldList)
    useEffect(() => {
        setlistNhanvien({label: dataIdDanhGia?.Nhanvien?.hoten, value: dataIdDanhGia?.Nhanvien?.id})
        let TrangthaiId = dataSelectDanhGia.find((items) => {
            return items && items.value === dataIdDanhGia?.TrangthaiId;
        });
        show && dataIdDanhGia && setPayloadDanhGia({
            NhanvienId: {label: dataIdDanhGia?.Nhanvien?.hoten, value: dataIdDanhGia?.Nhanvien?.id},
            nguoidanhgia: dataIdDanhGia?.nguoidanhgia,
            danhgia: dataIdDanhGia?.danhgia,
            nhanxet: dataIdDanhGia?.nhanxet,
            ghichu: dataIdDanhGia?.ghichu,
            TrangthaiId: {label: TrangthaiId?.label, value: TrangthaiId?.value},
        })
    }, [dataIdDanhGia, show])
    const [showSelect, setShowSelect] = useState(false);
    
    const [listTrangthai, setlistTrangthai] = useState([]);
    useEffect(() => {
        let TrangthaiId = dataSelectDanhGia.map((item) => ({label: item.label, value: item.value}));
        setlistTrangthai(TrangthaiId);
    }, []);
    
    const handleSubmit = async () => {
        let invalids = validate(payload)
        if (invalids === 0) {
            let datapayload = {
                "data": [
                    {
                        id: dataIdDanhGia.id,
                        NhanvienId: payload?.NhanvienId?.value,
                        nguoidanhgia: payload?.nguoidanhgia,
                        danhgia: payload?.danhgia,
                        nhanxet: payload?.nhanxet,
                        ghichu: payload?.ghichu,
                        TrangthaiId: payload?.TrangthaiId.value
                    }
                ]
            }
            // return false
            const response = await apiEditDanhgianhanvien(datapayload)
            // console.log('chek api apiEditDanhgianhanvien', response)
            if (response?.data.success === 200) {
                setShow(false)
                toast.success("Sửa thông tin đánh giá nhân viên thành công!")
            } else {
                toast.error("Sửa thông tin đánh giá nhân viên thất bại!")
            }
            
            
        }
    }
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayloadDanhGia(prev => ({...prev, [statename]: selected}))
    }
    const validate = (payload) => {
        let invalids = 0
        let fields = Object.entries(payload)
        // console.log(fields)
        fields.forEach(item => {
            if (validateUngVien(item[0], item[1]) !== undefined) {
                setInvalidFields(prev => [...prev, validateUngVien(item[0], item[1])])
                invalids++
            }
        })
        
        return invalids
    }
    // const validate = (payload) => {}
    
    return (
        <React.Fragment>
            <Modal isOpen={show} className="modal-dialog-centered modal-90w" size="lg" toggle={setShow}>
                <ModalBody>
                    <a href="#cancel" onClick={setShow} className="close">
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <h5 className="title mb-3">
                        Tạo đánh giá nhân viên
                    </h5>
                    <div className="row">
                        <div className="form-group col-md-4"
                             onClick={() => setShowSelect(true)}
                        >
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                Chọn Nhân Viên
                            </label>
                            <Select
                                value={listNhanvien}
                                name='NhanvienId'
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'NhanvienId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'NhanvienId')?.message}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                                Tình trạng sổ
                            </span>
                            </label>
                            <Select
                                value={payload.TrangthaiId}
                                onChange={handleOnSelect}
                                options={listTrangthai}
                                placeholder={'Tình trạng sổ'}
                                name="TrangthaiId"
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'trangthainopsobh') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'trangthainopsobh')?.message}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-4">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Người đánh giá'}
                                value={payload.nguoidanhgia}
                                setValue={setPayloadDanhGia}
                                keyPayload={'nguoidanhgia'}
                                placeholder={'Người đánh giá'}
                                type="text"
                            />
                        </div>
                        
                        <div className="form-group col-md-12">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Nội dung đánh giá'}
                                value={payload.danhgia}
                                setValue={setPayloadDanhGia}
                                keyPayload={'danhgia'}
                                placeholder={'Nội dung đánh giá'}
                                type="text"
                            />
                        </div>
                        <div className="form-group col-md-12">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Nội dung nhận xét'}
                                value={payload.nhanxet}
                                setValue={setPayloadDanhGia}
                                keyPayload={'nhanxet'}
                                placeholder={'Nội dung nhận xét'}
                                type="text"
                            />
                        </div>
                        <div className="form-group col-md-12">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Nội dung ghi chú'}
                                value={payload.ghichu}
                                setValue={setPayloadDanhGia}
                                keyPayload={'ghichu'}
                                placeholder={'Nội dung ghi chú'}
                                type="text"
                            />
                        </div>
                    
                    
                    </div>
                    <div className="btn-group my-2">
                        <button className="btn btn-primary px-5" onClick={handleSubmit}>
                            Thêm mới
                        </button>
                    </div>
                
                </ModalBody>
            </Modal>
            <SelectNhanvien
                showSelect={showSelect}
                // typeSelect = { ismultiple: "Lấy nhiều" , single: "lấy một" }
                setlistNhanvien={setlistNhanvien}
                setShowSelect={() => setShowSelect(false)}
            />
        </React.Fragment>
    );
}

export default ModalEditDanhgianhanviens;

