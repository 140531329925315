import React, { useState, useEffect } from 'react';
import { InputForm, Button } from '~/components/Form';
import { validateChucVu } from '~/request/validateChucVu';
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import {dataSelectActive} from "~/utils/Datacustom";
import {apiNewQuanlyquythuong} from "~/services";
import {toast} from "react-toastify";
import {validateQuyThuong} from "~/request/validateQuyThuong";

const ModalQuanlyquythuong = ({ show, setShow, fieldList }) => {
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayloadQuanlyquythuong] = useState(fieldList);
    useEffect(() => {
        show && setPayloadQuanlyquythuong(fieldList);
    }, [show]);
    
    const [listTrangthai, setlistTrangthai] = useState([]);
    
    useEffect(() => {
        let Trangthai = dataSelectActive.map((item) => ({label: item.label, value: item.value}));
        setlistTrangthai(Trangthai);
    }, []);
    const handleSubmit = async () => {
        let invalids = validate(payload);
        if (invalids === 0) {
            let datapayload = {
                data: [
                    {
                        title: payload.title,
                        giatri: payload.giatri,
                        type: payload.type,
                        TrangthaiId: payload.TrangthaiId.value,
                    },
                ],
            };
            const response = await apiNewQuanlyquythuong(datapayload)
            if (response?.data.success === 200) {
                toast.success("Thêm mới quản lý quỹ thưởng thành công!")
                setShow(false);
            } else {
                toast.error("Thêm mới quản lý quỹ thưởng thất bại!")
            }
        }
    };
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayloadQuanlyquythuong((prev) => ({ ...prev, [statename]: selected }));
    };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateQuyThuong(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateQuyThuong(item[0], item[1])]);
                invalids++;
            }
        });
        return invalids;
    };
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">Thêm mới</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Tên quỹ thưởng'}
                            value={payload.title}
                            setValue={setPayloadQuanlyquythuong}
                            keyPayload={'title'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Kiểu thưởng'}
                            value={payload.type}
                            setValue={setPayloadQuanlyquythuong}
                            keyPayload={'type'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Giá trị'}
                            value={payload.giatri}
                            setValue={setPayloadQuanlyquythuong}
                            keyPayload={'giatri'}
                            type="number"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-6 ">
                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                                Trạng thái <b className="text-danger">(*)</b>
                            </span>
                        </label>
                        <Select
                            value={payload.TrangthaiId}
                            onChange={handleOnSelect}
                            options={listTrangthai}
                            placeholder={'Trạng thái'}
                            name="TrangthaiId"
                            onFocus={() => setInvalidFields([])}
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'TrangthaiId') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'TrangthaiId')?.message}
                            </small>
                        )}
                    </div>
                </div>
                
                <div className="col-12 mt-5">
                    <Button text="Thêm mới" bClass="btn btn-primary px-5" textColor onClick={handleSubmit} />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalQuanlyquythuong;
