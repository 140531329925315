import React from "react";

export const ComponentTongQuat = (data) => { //Table 1 : Bảng tông quan
    const dataTongQuat = data?.dataTongQuat
    const formatVND = (data) => {
        return Number(data ?? 0).toLocaleString('it-IT', {
            style: 'currency',
            currency: 'vnd',
        });
    };
    
    return <div className="col-md-12 mt-3 row" id="table-to-xls-tq">
        
        <table id="table-to-xls-tongquat"
               className="table table-bordered table-striped table-hover">
            <thead key='theadTongQuat'>
            <tr key={'trTongQuat'}>
                <th className="text-center">STT</th>
                <th className="text-center">Tiêu chí</th>
                <th className="text-center">Số liệu</th>
                <th className="text-center">Ghi chú</th>
            </tr>
            </thead>
            <tbody key='tbodyTongQuat'>
            {dataTongQuat?.length > 0
                ? dataTongQuat?.map((item, index) => {
                    if (item?.data?.length === undefined) {
                        return (
                            <tr key={index}>
                                <td className="text-center fw-bold">{item?.stt}</td>
                                <td className="fw-bold">{item?.title}</td>
                                <td className="text-end fw-bold">{item?.stt === 15 ? formatVND(Number(item?.data || 0)) : item?.data || 0}{item?.percent === true ? '%' : ''}</td>
                                <td></td>
                            </tr>
                        );
                    } else {
                        return (
                            <React.Fragment>
                                <tr key={index}>
                                    <td rowSpan={item?.data.length + 1}
                                        className="text-center fw-bold">{item?.stt}</td>
                                    <td className="fw-bold text-uppercase">{item?.title}</td>
                                    <td className="text-end fw-bold"></td>
                                    <td></td>
                                </tr>
                                
                                {
                                    item?.data.length > 0
                                        ? item?.data.map((items, indexs) => {
                                            return (
                                                <tr key={indexs}>
                                                    <td>{items.title}</td>
                                                    <td className="text-end">{items?.data || 0}{item?.percent === true ? '%' : ''}</td>
                                                    <td></td>
                                                </tr>
                                            );
                                        })
                                        : null
                                }
                            
                            </React.Fragment>
                        
                        )
                    }
                    
                    
                })
                : null}
            
            
            </tbody>
        </table>
    
    </div>
};
