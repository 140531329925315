import React, {useEffect, useState} from 'react';
import {apiAddBCLuong, apiDeleteBCLuong, apiPayRollNhanVien, apiSendMailByLuong, apiUpdateBCLuong} from '~/services';
import {useSelector} from 'react-redux';
import Content from '~/layout/content/Content';
import {Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle} from '~/components/block/Block';
import './chitietnhanvien.scss';
import {toast} from "react-toastify";
import {addDays, format} from "date-fns";
import {useSearchParams} from "react-router-dom";
import {Button, Icon} from "~/components/Component";
import ExportSalaryXlsx from "~/components/xlsx/ExportSalaryXlsx";
import Select from "react-select";
import {statusCustommerActive, typeBangLuong, typeFormCut} from "~/utils/Datacustom";
import {ColumnAllCompany} from "~/pages/Admin/Modal/Tinhluong/ColumnAllCompany";
import {ColumnAllFactory} from "~/pages/Admin/Modal/Tinhluong/ColumnAllFactory";
import {ColumnAllIntern} from "~/pages/Admin/Modal/Tinhluong/ColumnAllIntern";
import {ColumnAllArofarm} from "~/pages/Admin/Modal/Tinhluong/ColumnAllArofarm";
import {ColumnAllIProcessMaterial} from "~/pages/Admin/Modal/Tinhluong/ColumnAllIProcessMaterial";
import {ColumnAllJanitor} from "~/pages/Admin/Modal/Tinhluong/ColumnAllJanitor";
import ExportSalaryXlsxtmp from "~/components/xlsx/ExportSalaryXlsxtmp";

function Tinhluong() {
    const [data, setData] = useState([]);
    const [dataPayroll, setDataPayroll] = useState([]);
    const [dataGr, setDataGr] = useState([]);
    const [footerTotal, setFooterTotal] = useState([]);
    const {phongban} = useSelector((state) => state.phongban);
    const {phucap} = useSelector((state) => state.phucap);
    const [dataPhuCap, setDataPhuCap] = useState([]);
    const [dataPhongBan, setDataPhongBan] = useState([])
    const [dataColumns, setDataColumns] = useState([])
    // console.log('dataColumns', dataColumns)
    const [titleBangLuong, setTitleBangLuong] = useState('BẢNG THANH TOÁN LƯƠNG TOÀN CÔNG TY');
    const fieldList = {
        PhongbanId: [],
        TrangthaiNVId: [],
        miniForm: {value: 1, label: 'Không'},
        typeLuong: {value: 1, label: 'BẢNG THANH TOÁN LƯƠNG TOÀN CÔNG TY'},
    };
    const [payload, setPayload] = useState(fieldList)
    const [titlePhucCap, setTitlePhucCap] = useState([]);
    const [searchParams] = useSearchParams();
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    useEffect(() => {
        if (phongban.length > 0) {
            let dataSelectPhongban = phongban.map((item) => ({label: item.phongban, value: item.id}));
            setDataPhongBan(dataSelectPhongban);
        }
    }, [phongban])
    const [totalRowCount, setTotalRowCount] = useState(10);
    useEffect(() => {
        if (phucap && phucap?.length > 0) {
            const numDescending = [...(phucap)].sort((a, b) => b.apdungdongbaohiem - a.apdungdongbaohiem);
            setDataPhuCap(numDescending);
        }
    }, [phucap]);
    const handleColumnsChange = (columns) => {
        setDataColumns(columns);
    };
    // console.log('dataColumns',dataColumns)
    const fetchDataLuong = async () => {
        try {
            if (searchParams.get('bangluong')) {
               
                let idPayRoll = searchParams.get('bangluong');
                if (Number(pagination.pageSize) >= Number(totalRowCount)) {
                    pagination.pageSize = totalRowCount;
                }
                let phongBanId = 0
                let TrangthaiNVId = payload?.TrangthaiNVId?.value || 0
                
                if (payload?.PhongbanId?.length > 0) {
                    phongBanId = payload?.PhongbanId?.map((item) => {
                        return item.value
                    });
                }
                const datapayloads = {
                    payrollId: idPayRoll,
                    start: pagination.pageIndex || 0,
                    size: pagination.pageSize || 10,
                    globalFilter: 'id',
                    sorting: 'desc',
                    phongBan: phongBanId,
                    TrangthaiNVId: TrangthaiNVId,
                };
                setData([]); // Đặt dữ liệu thành mảng rỗng để hiển thị trạng thái "loading"
                // console.log('datapayloads', datapayloads)
                const tinhLuongABC = await apiPayRollNhanVien(datapayloads);
                // console.log('dataSalary', tinhLuongABC)
                if (tinhLuongABC?.data?.success === 200) {
                    const {dataPayroll, checkLock, dataSalary, totalRow, groupedData, footerTotal} = tinhLuongABC?.data;
                    
                    setDataPayroll(dataPayroll);
                    setTotalRowCount(totalRow);
                    setLock({lock: checkLock});
                    const result = dataSalary?.map((item) => item);
                    setTitleBangLuong(payload?.typeLuong?.label);
                    setDataGr(groupedData);
                    setFooterTotal(footerTotal);
                    setData(result?.sort((a, b) => a?.data?.Nhanvien?.id - b?.data?.Nhanvien?.id));
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    
    // const columns = dataColumns
    // console.log('columns', columns)
    const handleFillterData = async () => {
        setTitleBangLuong(payload?.typeLuong?.label);
        await fetchDataLuong().then(r => 'err');
    }
    
    const handleExportData = () => {
        const headerExport1 = dataColumns?.map((item) => item.header);
  
        const header1Convert = headerExport1[0]?.map((header) => {
            return {colspan: header.colspan || 1, rowspan: header.rowspan || 1, value: header.Header};
        })
        const header2Convert = headerExport1[1]?.map((header) => {
            return {colspan: header.colspan || 1, rowspan: header.rowspan || 1, value: header.Header};
        });
        const header3Convert = headerExport1[2]?.map((header) => {
            return {colspan: header.colspan || 1, rowspan: header.rowspan || 1, value: header.Header};
        });
        const header4Convert = headerExport1[4]?.map((header) => {
            return {colspan: header.colspan || 1, rowspan: header.rowspan || 1, value: header.Header || ''};
        });
        // const header3Convert = header3
        let header0 = [{
            value: payload?.typeLuong?.label || 'BẢNG THANH TOÁN LƯƠNG CBCNV NHÀ MÁY',
            colspan: payload?.typeLuong?.totalRow || 40,
            rowspan: 1
        }]
        let headerT = [{value: dataPayroll?.title, colspan: payload?.typeLuong?.totalRow || 40, rowspan: 1}]
        const headerExport = [
            header0, headerT, header1Convert, header2Convert, header3Convert, header4Convert
        ];
      
      
        const getValue = headerExport1[3].map((c) => c.accessorKey) // Get accessor key of column
        const keyExport = headerExport1[3].map((c) => c.id) // Get id of column
        // console.log('keyExport', keyExport)
        // console.log('dataGr', dataGr)
        let dataExport2 = dataGr?.map((items, indexs) => { // Get data of table to export
            let arr = items.data;
            console.log('arr', arr)
            let dataTitle = items.Phongban;
            let dataTitleParent = items.parentName;
            let titleParentName = items.parentName;
            // let dataParentId = items.ParentId;
            let dataTotal = [items.total];
            let totalRows = arr.length;
            let dataEp = arr?.map((item, indexx) => {
                let row = {};
                keyExport.forEach((key, i) => {
                    let path = getValue[i].split('.');
                    row[key] = path.reduce((o, p) => (o || {})[p], item);
                });
                console.log('row', row)
                row['rowNumber'] = indexx + 1
                row['kynhan'] = '...............'
                return row;
            });
            return {
                data: dataEp,
                titlePhongban: dataTitle,
                total: dataTotal,
                totalRows: totalRows,
                titleParent: dataTitleParent,
                titleParentName: titleParentName,
                
            };
        });
        const xlsxOptions = {
            // data: dataExport,
            dataGr: dataExport2,
            footerData: [footerTotal],
            namefile: 'bang-luong',
            headers: headerExport,
            miniForm: payload?.miniForm?.value,
            typeLuong: payload?.typeLuong?.value,
        };
        console.log('xlsxOptions', xlsxOptions)
        let exportFunction = ExportSalaryXlsx(xlsxOptions);
        if (payload?.miniForm?.value === 0 && payload?.typeLuong?.value === 4) {
            exportFunction = ExportSalaryXlsxtmp(xlsxOptions);
        }
        
        exportFunction().then(r => 'err');
    };
    
    useEffect(() => {
        if (dataPhuCap.length > 0) {
            const dataColumnsDongBH = dataPhuCap
                ?.filter((pca) => pca.apdungdongbaohiem === true)
                .map((pca, pa) => {
                    return {
                        id: pca.maphucap,
                        header: pca.title,
                        apdungdongbaohiem: "CO",
                    };
                });
            const dataColumnsKoBH = dataPhuCap
                ?.filter((pca) => pca.apdungdongbaohiem !== true)
                .map((pca, pa) => {
                    return {
                        id: pca.maphucap,
                        header: pca.title,
                        apdungdongbaohiem: "KO",
                    };
                });
            setTitlePhucCap(dataColumnsDongBH.concat(dataColumnsKoBH));
            fetchDataLuong().then(r => 'err');
        }
        
    }, [totalRowCount, dataPhuCap]);
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayload((prev) => ({...prev, [statename]: selected}));
        
    };
    // console.log(dataPayroll)
    // console.log('payload', payload)
    const handleLock = async () => {
        let datapayload = {idPayroll: 6};
        
        console.log('handleLock', datapayload)
        const res = await apiUpdateBCLuong(datapayload)
        if (res.data.success === 200) {
            toast.success(res.data.message);
            setLock({lock: res.data.lockCheck});
        } else {
            toast.error('Thao tác thất bại');
        }
    };
    const handleUpdateSalary = async () => {
        try {
            if (Number(dataPayroll?.BangchamcongtonghopId) === 0) {
                toast.error('Bảng chấm công không tồn tại!');
                return false
            }
            if (checkLock.lock === 1) {
                toast.error('Vui lòng mở khóa bảng lương để cập nhật !');
                return false
            }
            const luongNhanVien = await apiAddBCLuong({BangchamcongtonghopId: Number(dataPayroll?.BangchamcongtonghopId)});
            if (luongNhanVien.data.success === 200) {
                toast.success(luongNhanVien.data.message);
                await fetchDataLuong();
            } else {
                toast.error(luongNhanVien.data.message);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        
    };
    const handleGetSendMailLuong = async () => {
        if (checkLock.lock === 0) {
            toast.error('Bảng lương chưa khóa, vui lòng kiểm tra lại!');
            return false
        }
        let idPayRoll = dataPayroll?.id;
        if (idPayRoll !== 0) {
            const datapayloads = {
                payrollId: idPayRoll,
                ngayGuiMail: format(new Date(), 'dd-MM-yyyy'),
                ngayGuiMailFedback: format(addDays(new Date(), 3), 'dd-MM-yyyy'),
            };
            
            const result = await apiSendMailByLuong(datapayloads);
            if (result?.data?.success === 200) {
                toast.success(result?.data?.message);
            } else {
                toast.error(result?.data?.message);
            }
        } else {
            toast.error('Vui lòng kiểm tra lại bảng chấm công');
        }
    };
    const handleRemove = async (dataPayroll) => {
        if (!dataPayroll && checkLock.lock === 1) {
            toast.error('Bạn chưa chọn Bảng tổng hợp công!');
            return false
        }
        const datapayload = {idPayroll: dataPayroll}
        
        const res = await apiDeleteBCLuong(datapayload)
        if (res.data.success === 200) {
            toast.success(res.data.message);
            // console.log('sẽ về danh sách luong')
            setData([])
        } else if (res.data.success === 500) {
            toast.error(res.data.message);
        } else {
            toast.error(res.data.message);
        }
    };
    const [checkLock, setLock] = useState({
        lock: 1,
    });
    
    return (
        <React.Fragment>
            {/*<Head title="User List - Regular"></Head>*/}
            <Content clasName="position-relative overflow-hidden">
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h4" page className={'mt-4 mb-2'}>
                                {dataPayroll?.title}
                            </BlockTitle>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <Block className="overflow-hidden">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex">
                                <Button
                                    color="primary"
                                    onClick={handleUpdateSalary}
                                    variant="contained"
                                    className="me-2"
                                >
                                    <Icon name="upload-cloud me-2"></Icon>Cập nhật
                                </Button>
                                <Button
                                    color="primary"
                                    onClick={handleLock}
                                    variant="contained"
                                    className="me-2"
                                >
                                    <Icon
                                        name="lock me-2"></Icon> {checkLock.lock === 1 ? 'Khóa' : 'Mở khóa'}
                                </Button>
                                <Button
                                    color="primary"
                                    onClick={handleGetSendMailLuong}
                                    variant="contained"
                                    className="me-2"
                                >
                                    <Icon name="mail me-2"></Icon> Gửi mail
                                </Button>
                                <Button
                                    color="primary"
                                    onClick={() => handleRemove(dataPayroll?.id)}
                                    variant="contained"
                                    className="me-2"
                                >
                                    <Icon name="trash me-2"></Icon> Xóa
                                </Button>
                                <Button color="primary" onClick={handleExportData} variant="contained">
                                    <Icon name="download-cloud me-2"></Icon> Xuất dữ liệu
                                </Button>
                            </div>
                        </div>
                        <div className="col-md-12 mt-2 mb-2" style={{zIndex: 999}}>
                            <div className="d-flex justify-content-end row">
                                <div className="col-lg-3 col-md-12 col-sm-12 mt-1">
                                    <Select
                                        className={"w-max-content me-2"}
                                        value={payload.typeLuong}
                                        onChange={handleOnSelect}
                                        options={typeBangLuong}
                                        isMulti={false}
                                        placeholder={'Loại bảng lương'}
                                        name='typeLuong'
                                    />
                                </div>
                                <div className="col-lg-2 col-md-12 col-sm-12 mt-1">
                                    <Select
                                        className={"me-2"}
                                        value={payload.TrangthaiNVId}
                                        onChange={handleOnSelect}
                                        options={statusCustommerActive}
                                        isMulti={false}
                                        placeholder={'Trạng thái nhân viên'}
                                        name='TrangthaiNVId'
                                    />
                                </div>
                                <div className="col-lg-5 col-md-12 col-sm-12 mt-1">
                                    <Select
                                        className={"w-max-content me-2"}
                                        value={payload.PhongbanId}
                                        onChange={handleOnSelect}
                                        options={dataPhongBan}
                                        isMulti={true}
                                        placeholder={'Theo phòng ban'}
                                        name='PhongbanId'
                                    />
                                </div>
                                <div className="col-lg-1 col-md-4 col-sm-4 mt-1">
                                    <Select
                                        className={"w-max-content"}
                                        value={payload.miniForm}
                                        onChange={handleOnSelect}
                                        options={typeFormCut}
                                        isMulti={false}
                                        placeholder={'Không'}
                                        name='miniForm'
                                    />
                                </div>
                                <div className="col-lg-1 col-md-12 col-sm-12 mt-1">
                                        <Button color="danger" onClick={handleFillterData}
                                                variant="contained">
                                            <Icon name="filter-fill"></Icon>
                                        </Button>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                    {payload?.typeLuong?.value === 3 &&
                        <ColumnAllIntern
                            titlePhucCap={titlePhucCap}
                            titleBangLuong={titleBangLuong}
                            dataGr={dataGr}
                            onColumnsChange={handleColumnsChange}
                            footerTotal={footerTotal}
                            typeLuong={payload?.typeLuong?.value ?? 0}
                            totalRow={payload?.typeLuong?.totalRow ?? 42}
                        />}
                    {payload?.typeLuong?.value === 4 &&
                        <ColumnAllArofarm
                            titlePhucCap={titlePhucCap}
                            titleBangLuong={titleBangLuong}
                            dataGr={dataGr}
                            onColumnsChange={handleColumnsChange}
                            footerTotal={footerTotal}
                            miniForm={payload?.miniForm?.value}
                            typeLuong={payload?.typeLuong?.value ?? 0}
                            totalRow={payload?.typeLuong?.totalRow ?? 40}
                        />
                    }
                    {payload?.typeLuong?.value === 5 &&
                        <ColumnAllIProcessMaterial
                            titlePhucCap={titlePhucCap}
                            titleBangLuong={titleBangLuong}
                            dataGr={dataGr}
                            onColumnsChange={handleColumnsChange}
                            footerTotal={footerTotal}
                            miniForm={payload?.miniForm?.value}
                            typeLuong={payload?.typeLuong?.value ?? 0}
                            totalRow={payload?.typeLuong?.totalRow ?? 42}
                        />
                    }
                    {payload?.typeLuong?.value === 6 &&
                        <ColumnAllJanitor
                            titlePhucCap={titlePhucCap}
                            titleBangLuong={titleBangLuong}
                            dataGr={dataGr}
                            onColumnsChange={handleColumnsChange}
                            footerTotal={footerTotal}
                            typeLuong={payload?.typeLuong?.value ?? 0}
                            totalRow={payload?.typeLuong?.totalRow ?? 42}
                        />
                    }
                    {payload?.typeLuong?.value === 1 &&
                        <ColumnAllCompany
                            titlePhucCap={titlePhucCap}
                            titleBangLuong={titleBangLuong}
                            dataGr={dataGr}
                            onColumnsChange={handleColumnsChange}
                            footerTotal={footerTotal}
                            typeLuong={payload?.typeLuong?.value ?? 0}
                            totalRow={payload?.typeLuong?.totalRow ?? 42}
                        />
                    }
                    {payload?.typeLuong?.value === 2 &&
                        <ColumnAllFactory
                            titlePhucCap={titlePhucCap}
                            titleBangLuong={titleBangLuong}
                            dataGr={dataGr}
                            onColumnsChange={handleColumnsChange}
                            footerTotal={footerTotal}
                            typeLuong={payload?.typeLuong?.value ?? 0}
                            totalRow={payload?.typeLuong?.totalRow ?? 42}
                        />
                    }
                
                </Block>
            
            </Content>
        </React.Fragment>
    )
        ;
}

export default Tinhluong;
