import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {InputForm, Button} from '~/components/Form'
import * as actions from '~/store/actions'
import {validateBangChamCong} from "~/request/validateBangChamCong";
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { dataSelectLock} from "~/utils/Datacustom";

const ModalBangchamcongtonghop = ({show, setShow, fieldList}) => {
    const dispatch = useDispatch()
    const [listTrangthai, setlistTrangthai] = useState([]);
    const [listBangchotcong, setlistBangchotcong] = useState([]);
    const [invalidFields, setInvalidFields] = useState([])
    const {bangchotcong} = useSelector(state => state.bangchotcong)
    const [payload, setPayload] = useState(fieldList)
    useEffect(() => {
        show && setPayload(fieldList)
        dispatch(actions.getListBangchotcong());
    }, [show])

    useEffect(() => {
        let TrangthaiId = dataSelectLock.map((item) => ({label: item.label, value: item.value}));
        setlistTrangthai(TrangthaiId);
        let BangchotcongId = bangchotcong?.map((item) => ({label: item.tieude, value: item.id}));
        setlistBangchotcong(BangchotcongId);
    }, [dataSelectLock,bangchotcong])
  
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayload(prev => ({...prev, [statename]: selected}))
    }
    const validate = (payload) => {
        let invalids = 0
        let fields = Object.entries(payload)
        fields.forEach(item => {
            if (validateBangChamCong(item[0], item[1]) !== undefined) {
                setInvalidFields(prev => [...prev, validateBangChamCong(item[0], item[1])])
                invalids++
            }
        })
        return invalids
    }
    const handleSubmit = async () => {
        let invalids = validate(payload)
        if (invalids === 0) {
            let datapayload = {
                tieude: payload.tieude,
                mota: payload.mota,
                BangchotcongId: payload.BangchotcongId.value,
                nguoitao: 1,
                TrangthaiId: payload.TrangthaiId.value,
            }
            dispatch(actions.NewBangchamcongtonghop(datapayload))
            
        }
    }
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Thêm mới bảng chấm công tổng hợp
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Tên bảng công tổng hợp'}
                            value={payload.tieude}
                            setValue={setPayload}
                            keyPayload={'tieude'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-6 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Mô tả'}
                            value={payload.mota}
                            setValue={setPayload}
                            keyPayload={'mota'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                                Chọn bảng chấm công <b className="text-danger">(*)</b>
                            </span>
                        </label>
                        <Select
                            value={payload.BangchotcongId}
                            onChange={handleOnSelect}
                            options={listBangchotcong}
                            placeholder={'Bảng chấm công'}
                            name="BangchotcongId"
                            onFocus={() => setInvalidFields([])}
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'BangchotcongId') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'BangchotcongId')?.message}
                            </small>
                        )}
                    </div>
                    <div className="form-group col-md-6 ">
                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                                Trạng thái <b className="text-danger">(*)</b>
                            </span>
                        </label>
                        <Select
                            value={payload.TrangthaiId}
                            onChange={handleOnSelect}
                            options={listTrangthai}
                            placeholder={'Trạng thái'}
                            name="TrangthaiId"
                            onFocus={() => setInvalidFields([])}
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'TrangthaiId') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'TrangthaiId')?.message}
                            </small>
                        )}
                    </div>
                </div>
                
                <div className='col-12 mt-5'>
                    <Button
                        text='Lưu'
                        bClass='btn btn-primary px-5'
                        textColor
                        onClick={handleSubmit}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ModalBangchamcongtonghop;

