import React from 'react';
import { Link } from 'react-router-dom';
import config from '~/config';
import { useState } from 'react';
import Menu from '~/layouts/components/Menu';

function Header({e}) {
    const [ fix, setFix ] = useState(false)
    function setFixed(){
        if(window.scrollY >= 320){
            setFix(true)
        } else {
            setFix(false)
        }
    }
    window.addEventListener('scroll',setFixed)
    return (
        <div className="header-area header-area--absolute">
            <div className={fix ? 'header-bottom-wrap header-sticky is-sticky' : 'header-bottom-wrap header-sticky'}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="header position-relative">
                                <div className="header__logo">
                                    <a href="index.html">
                                        <img src="./images/logo/light-logo.webp" width="160" height="48"
                                            className="img-fluid light-logo" alt="" />
                                        <img src="./images/logo/logo-dark.webp" width="160" height="48"
                                            className="img-fluid dark-logo" alt="" />
                                    </a>
                                </div>
                                <Menu />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;
