import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { InputForm, Button } from '~/components/Form';
import * as actions from '~/store/actions';
import '../Modal.scss';
import { Modal, ModalBody, Input } from 'reactstrap';
import Select from 'react-select';
import { validateChucVu } from '~/request/validateChucVu';
import { dataSelectStatus } from '~/utils/Datacustom';
import { Icon } from '~/components/Component';
import {apiEditXeploainhanvien, apiGetQuanlyquythuong, apiGetXeploai} from "~/services";
import {toast} from "react-toastify";
import {validateXepLoaiNhanVien} from "~/request/validateXepLoaiNhanVien";

const ModalEditXeploainhanvien = ({ show, setShow, dataIdXeploainhanvien, fieldList }) => {
    const [listXeploai, setlistXeploai] = useState([]);
    const [listQuythuong, setlistQuythuong] = useState([]);
    const [listTrangthai, setlistTrangthai] = useState([]);
    const dispatch = useDispatch();
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayloadXeploainhanvien] = useState(fieldList);
    
    
    
    async function fetchDataXepLoai() { // Lấy dữ liệu sếp loai đang sử dụng
        try {
            const datapayloads = {
                typeGet: 2, // 1: lấy theo điều kiện phân trang, 2: lấy tất cả và không cần truyền dữ liệu phân trang
            };
            const response = await apiGetXeploai(datapayloads);
            if (response?.data?.success === 200) {
                setlistXeploai(response?.data?.data?.rows.map((item) => ({ label: item.ten, value: item.id })));
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    async function fetchDataQuyThuong() { // Lấy dữ liệu quỹ thưởng đang sử dụng
        try {
            const datapayloads = {
                typeGet: 2, // 1: lấy theo điều kiện phân trang, 2: lấy tất cả và không cần truyền d liệu phân trang
            };
            const response = await apiGetQuanlyquythuong(datapayloads);
            if (response?.data?.success === 200) {
                setlistQuythuong(response?.data?.data?.rows.map((item) => ({ label: item.title, value: item.id })));
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {
        fetchDataXepLoai().then(r => 'err');
        fetchDataQuyThuong().then(r => 'err');
        dispatch(actions.getListNhanvien());
    }, [dataIdXeploainhanvien]);
    useEffect(() => {
        let TrangthaiId = dataSelectStatus.find((items) => {
            return items && items.value === dataIdXeploainhanvien?.TrangthaiId;
        });
        let quythuong = listQuythuong?.find((items) => {
            return items && items.value === dataIdXeploainhanvien?.quythuong;
        });
        let XeploaiId = listXeploai?.find((items) => {
            return items && items.value === dataIdXeploainhanvien?.XeploaiId;
        });
        show &&
            dataIdXeploainhanvien &&
            setPayloadXeploainhanvien({
                TrangthaiId: { label: TrangthaiId?.label, value: TrangthaiId?.value },
                quythuong: quythuong,
                XeploaiId: XeploaiId,
                ten: dataIdXeploainhanvien.ten,
                mota: dataIdXeploainhanvien.mota,
                giatri: dataIdXeploainhanvien.giatri,
                NhanvienId: {
                    label: dataIdXeploainhanvien?.Nhanvien?.hoten,
                    value: dataIdXeploainhanvien?.Nhanvien?.id,
                },
            });
    }, [dataIdXeploainhanvien, show]);
    useEffect(() => {
        let TrangthaiId = dataSelectStatus.map((item) => ({ label: item.label, value: item.value }));
        setlistTrangthai(TrangthaiId);
    }, [show]);
    const handleSubmit = async () => {
        let invalids = validate(payload);
        if (invalids === 0) {
            let datapayload = {
                data: [
                    {
                        id: dataIdXeploainhanvien.id,
                        TrangthaiId: payload.TrangthaiId.value,
                        XeploaiId: payload.XeploaiId.value,
                        quythuong: payload.quythuong.value,
                        NhanvienId: payload.NhanvienId.value,
                        ten: payload.ten,
                        mota: payload.mota,
                        giatri: payload.giatri,
                    },
                ],
            };
            const response = await apiEditXeploainhanvien(datapayload)
            // console.log('chek api apiEditXeploainhanvien', response)
            if (response?.data.success === 200) {
                toast.success("Sửa thông tin xếp loại nhân viên thành công!")
              setShow(false);
            } else {
                toast.error("Sửa thông tin xếp loại nhân viên thất bại!")
            }
        }
    };
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayloadXeploainhanvien((prev) => ({ ...prev, [statename]: selected }));
    };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateXepLoaiNhanVien(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateXepLoaiNhanVien(item[0], item[1])]);
                invalids++;
            }
        });
        return invalids;
    };
    return (
        <React.Fragment>
            <Modal isOpen={show} className="modal-dialog-centered modal-90w" size="lg" toggle={setShow}>
                <ModalBody>
                    <Icon name="cross-sm float-end fs-2" onClick={setShow}></Icon>
                    <h5 className="title mb-5">Sửa xếp loại nhân viên</h5>
                    <div className="row">
                        <div className="form-group col-md-6">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                Chọn Nhân Viên
                            </label>
                            <Select
                                value={payload.NhanvienId}
                                name="NhanvienId"
                                isMulti
                                onFocus={() => setInvalidFields([])}
                                isDisabled={true}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'NhanvienId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'NhanvienId')?.message}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-6 ">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                <span>
                                    Xếp loại <b className="text-danger">(*)</b>
                                </span>
                            </label>
                            <Select
                                value={payload.XeploaiId}
                                onChange={handleOnSelect}
                                options={listXeploai}
                                placeholder={'Chọn loại'}
                                name="XeploaiId"
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'XeploaiId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'XeploaiId')?.message}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-12">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Tiêu đề'}
                                value={payload.ten}
                                setValue={setPayloadXeploainhanvien}
                                keyPayload={'ten'}
                                type="text"
                                validate
                            />
                        </div>
                        <div className="form-group col-md-12 mb-2">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                <span>
                                    Mô tả
                                </span>
                            </label>
                            <Input
                                value={payload.mota}
                                onChange={(e) =>
                                    setPayloadXeploainhanvien((prev) => ({ ...prev, ['mota']: e.target.value }))
                                }
                                rows={'2'}
                                name="mota"
                                type="textarea"
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'mota') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'mota')?.message}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-4">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Gi trị'}
                                value={payload.giatri}
                                setValue={setPayloadXeploainhanvien}
                                keyPayload={'giatri'}
                                type="text"
                                validate
                            />
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                <span>
                                    Chọn quỹ thưởng
                                </span>
                            </label>
                            <Select
                                value={payload.quythuong}
                                onChange={handleOnSelect}
                                options={listQuythuong}
                                placeholder={'Chọn quỹ thưởng'}
                                name="quythuong"
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'quythuong') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'quythuong')?.message}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                <span>
                                    Trạng thái
                                </span>
                            </label>
                            <Select
                                value={payload.TrangthaiId}
                                onChange={handleOnSelect}
                                options={listTrangthai}
                                placeholder={'Chọn trạng thái'}
                                name="TrangthaiId"
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'TrangthaiId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'TrangthaiId')?.message}
                                </small>
                            )}
                        </div>
                    </div>

                    <div className="col-12 mt-5">
                        <Button text="Cập nhật" bClass="btn btn-primary px-5" textColor onClick={handleSubmit} />
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default ModalEditXeploainhanvien;
