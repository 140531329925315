import React, {useEffect, useState} from "react";
import {AudienceLineChart} from "../../charts/analytics/AnalyticsCharts";
import {Icon} from "../../../Component";

const AudienceOverview = ({dataChart, titleChart, descriptionChart,titleOut}) => {
    
    const [title, settitle] = useState('');
    const [description, setdescription] = useState('');
    const [titleout, settitleout] = useState([]);
    const [dataCh, setdataCh] = useState([]);
    
    useEffect(() => {
        if (titleChart !== undefined) {
            settitle(titleChart)
        }
        if (descriptionChart !== undefined) {
            setdescription(descriptionChart)
        }
        if (titleOut !== undefined) {
            settitleout(titleOut)
        }
        if (dataChart !== undefined) {
            setdataCh([dataChart])
        }
    }, [titleChart, descriptionChart, dataChart])
    const showIcon = (valCurent, valPre) => {
        let percent = 0;
        if(valPre !== 0){
            percent = (valCurent * 100 / valPre).toFixed(2);
        }
        if (valCurent !== undefined && valPre !== undefined){
            if (valCurent > valPre) {
                return (
                    <div className="change up">
                        <Icon name="arrow-long-up"></Icon>
                        {percent}% /tháng trước
                    </div>
                )
            } else {
                return (
                    <div className="change down">
                        <Icon name="arrow-long-down"></Icon>
                        {percent}% /tháng trước
                    </div>
                )
            }
        } else {
            return (
                ''
            )
        }
        
    };
    const [auOverview, setAuOverview] = useState("month-1");
    return (
        <React.Fragment>
            <div className="card-title-group pb-3 g-2">
                <div className="card-title card-title-sm">
                    <h6 className="title">{title}</h6>
                    <p>{description}</p>
                </div>
            </div>
            <div className="analytic-ov">
                <div className="analytic-data-group analytic-ov-group g-3">
                    <div className="analytic-data w-50">
                        <div className="title">{titleout[0]}</div>
                        <div className="amount">{dataCh[0]?.countUngvien?.tmpDataCurrent}</div>
                        { showIcon(dataCh[0]?.countUngvien?.tmpDataCurrent, dataCh[0]?.countUngvien?.tmpDataPrev) }
                    
                    </div>
                    <div className="analytic-data w-50">
                        <div className="title">{titleout[1]}</div>
                        <div className="amount">{dataCh[0]?.countUngvien?.tmpDataCurrentActive}</div>
                        { showIcon(dataCh[0]?.countUngvien?.tmpDataCurrentActive, dataCh[0]?.countUngvien?.tmpDataPreActive) }
                        
                    </div>
                </div>
                <div className="analytic-ov-ck">
                    <AudienceLineChart
                        state={auOverview}
                        dataCh={dataCh}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};
export default AudienceOverview;
