import axiosConfig from '../axiosConfig';

export const apiGetUserAll = () =>
    new Promise(async (resolve, reject) => {
        try {
            const response = await axiosConfig({
                method: 'get',
                url: `/api/v1/user/all`,
            });
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
export const apiPostUser = (payload) =>
    new Promise(async (resolve, reject) => {
        try {
            const response = await axiosConfig({
                method: 'post',
                url: '/api/v1/user',
                data: payload,
            });
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
export const apiEditUser = (payload) =>
    new Promise(async (resolve, reject) => {
        try {
            const response = await axiosConfig({
                method: 'put',
                url: '/api/v1/user',
                data: payload,
            });
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });

export const apiGetUserOne = (payload) =>
    new Promise(async (resolve, reject) => {
        try {
            const response = await axiosConfig({
                method: 'get',
                url: `/api/v1/user/one`,
                params: {
                    id: payload,
                },
            });
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });

export const apiDeleteUser = (payload) =>
    new Promise(async (resolve, reject) => {
        try {
            const response = await axiosConfig({
                method: 'delete',
                url: `/api/v1/user`,
                params: {
                    id: payload,
                },
            });
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });

export const apiGetModule = () =>
    new Promise(async (resolve, reject) => {
        try {
            const response = await axiosConfig({
                method: 'get',
                url: `/api/v1/cauhinhhethong`,
            });
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });

export const apiNewUserPermission = (payload) =>
    new Promise(async (resolve, reject) => {
        try {
            const response = await axiosConfig({
                method: 'post',
                url: '/api/v1/auth/register',
                data: payload,
            });
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
//lấy dữ liệu thô máy chấm công để có thông tin suất ăn
export const apiMaychamcong = () =>
    new Promise(async (resolve, reject) => {
        try {
            const response = await axiosConfig({
                method: 'get',
                url: '/api/v2/quanlyhethong/getmaychamcong/quanlycong',
            });
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
//lấy dữ liệu chấm công
export const apiDulieuchamcong = (query) =>
    new Promise(async (resolve, reject) => {
        try {
            const response = await axiosConfig({
                method: 'get',
                url: '/api/v2/quanlyhethong/dulieuchamcong/quanlycong',
                params: query,
            });
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });

    export const apiPostFunction = () =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await axiosConfig({
                    method: 'post',
                    url: `/api/v2/quanlyhethong/function`,
                });
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    export const apiGetModuleV2 = () =>
    new Promise(async (resolve, reject) => {
        try {
            const response = await axiosConfig({
                method: 'get',
                url: `/api/v2/quanlyhethong/module`,
            });
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
    export const apiGetFunctionV2 = (query) =>
    new Promise(async (resolve, reject) => {
        try {
            const response = await axiosConfig({
                method: 'get',
                url: `/api/v2/quanlyhethong/function`,
                params: query,
            });
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
// ModuleUser
    export const apiGetModuleUserV2 = (query) =>
    new Promise(async (resolve, reject) => {
        try {
            const response = await axiosConfig({
                method: 'get',
                url: `/api/v2/quanlyhethong/module-user`,
                params: query,
            });
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });

    export const apiPostModuleUserV2 = (payload) =>
    new Promise(async (resolve, reject) => {
        try {
            const response = await axiosConfig({
                method: 'post',
                url: `/api/v2/quanlyhethong/module-user`,
                data: payload,
            });
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
    export const apiPutModuleUserV2 = (payload) =>
    new Promise(async (resolve, reject) => {
        try {
            const response = await axiosConfig({
                method: 'put',
                url: `/api/v2/quanlyhethong/module-user`,
                data: payload,
            });
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
    export const apiDeleteModuleUserV2 = (payload) =>
    new Promise(async (resolve, reject) => {
        try {
            const response = await axiosConfig({
                method: 'delete',
                url: `/api/v2/quanlyhethong/module-user`,
                data: payload,
            });
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
// FunctionUser
export const apiGetFunctionUserV2 = (query) =>
new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlyhethong/function-user`,
            params: query,
        });
        resolve(response);
    } catch (error) {
        reject(error);
    }
});
export const apiPostFunctionUserV2 = (payload) =>
new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: `/api/v2/quanlyhethong/function-user`,
            data: payload,
        });
        resolve(response);
    } catch (error) {
        reject(error);
    }
});
export const apiPutFunctionUserV2 = (payload) =>
new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: `/api/v2/quanlyhethong/function-user`,
            data: payload,
        });
        resolve(response);
    } catch (error) {
        reject(error);
    }
});
export const apiDeleteFunctionUserV2 = (payload) =>
new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: `/api/v2/quanlyhethong/function-user`,
            data: payload,
        });
        resolve(response);
    } catch (error) {
        reject(error);
    }
});
// Group FunctionUser
export const apiGetGroupFunctionUserV2 = () =>
new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlyhethong/group-function-user`,
        });
        resolve(response);
    } catch (error) {
        reject(error);
    }
});
export const apiPostGroupFunctionUserV2 = (payload) =>
new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: `/api/v2/quanlyhethong/group-function-user`,
            data: payload,
        });
        resolve(response);
    } catch (error) {
        reject(error);
    }
});
export const apiPutGroupFunctionUserV2 = (payload) =>
new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: `/api/v2/quanlyhethong/group-function-user`,
            data: payload,
        });
        resolve(response);
    } catch (error) {
        reject(error);
    }
});
export const apiDeleteGroupFunctionUserV2 = (payload) =>
new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: `/api/v2/quanlyhethong/group-function-user`,
            data: payload,
        });
        resolve(response);
    } catch (error) {
        reject(error);
    }
});

export const apiDonvihanhchinh = () => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlynhanvien/don-vi-hanh-chinh/nhanvien`,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})