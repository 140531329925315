export const validateCauHinhThamNien = (fieldName, value) => {
    switch (fieldName) {
        case 'name':
            if (value.length === 0) {
                
                return {
                    name: fieldName,
                    message: 'Dữ liệu không được để trống'
                }
            }
            break;
        case 'description':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Dữ liệu không được để trống'
                }
            }
            break;
        case 'totalMaxMonth':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Dữ liệu không được để trống'
                }
            }
            break;
        case 'value':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Dữ liệu không được để trống'
                }
            }
            break;
        case 'minday':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Dữ liệu không được để trống'
                }
            }
            break;
        
        default:
            break;
    }
}