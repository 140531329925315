export const validateCauHinhChuyenCan = (fieldName, value) => {
    switch (fieldName) {
        case 'name':
            if (value.length === 0) {
                
                return {
                    name: fieldName,
                    message: 'Dữ liệu không được để trống'
                }
            }
            break;
        case 'description':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Dữ liệu không được để trống'
                }
            }
            break;
        case 'totalDayCalcu':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Dữ liệu không được để trống'
                }
            }
            const totalDayCalcu = parseInt(value, 10);
            if (isNaN(totalDayCalcu) || totalDayCalcu > 31 || totalDayCalcu < 0) {
                return {
                    name: fieldName,
                    message: 'Giá trị không hợp lệ, phải nhỏ hơn hoặc bằng 31 và lớn hơn 0'
                };
            }
            break;
        // case 'calculatorCondition':
        //     if (value.length === 0) {
        //         return {
        //             name: fieldName,
        //             message: 'Dữ liệu không được để trống'
        //         }
        //     }
        //     break;
        // case 'totalWorkDay':
        //     if (value.length === 0) {
        //         return {
        //             name: fieldName,
        //             message: 'Dữ liệu không được để trống'
        //         }
        //     }
        //     const totalWorkDay = parseInt(value, 10);
        //     if (isNaN(totalWorkDay) || totalWorkDay > 31 || totalWorkDay < 0) {
        //         return {
        //             name: fieldName,
        //             message: 'Giá trị không hợp lệ, phải nhỏ hơn hoặc bằng 31 và lớn hơn 0'
        //         };
        //     }
        //     break;
        
        default:
            break;
    }
}