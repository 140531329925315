import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '~/store/actions'
import {
    ModalCauhinhnghile,
    ModalDetailCauhinhnghile,
    ModalEditCauhinhnghile,
} from './Modal'
import Swal from 'sweetalert2'
import exportFromJSON from "export-from-json";
import {Card, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, UncontrolledDropdown} from "reactstrap";
import Head from "~/layout/head/Head";
import Content from "~/layout/content/Content";
import {Block} from "~/components/block/Block";
import {
    Button,
    Col,
    DataTable,
    DataTableBody,
    DataTableHead, DataTableItem,
    DataTableRow,
    Icon, PaginationComponent,
    Row,
    RSelect, TooltipComponent,
    PermissionUtils,
    ExportXlsx
} from "~/components/Component";
import {MenuNghiphepNghile} from './MenuTab/Menucontent';

const Cauhinhnghile = () => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false);
    const [showEditCauhinhnghile, setShowEditCauhinhnghile] = useState(false);
    const [showDetailCauhinhnghile, setShowDetailCauhinhnghile] = useState(false);
    const [data, setData] = useState([]);
    
    const fieldList = {
        tieude: '',
        mota: '',
        ngaybatdau: '',
        ngayketthuc: '',
        // giobatdau: '',
        // gioketthuc: '',
        apdungtinhluong: '',
        TrangthaiId: '',
    }
    //lấy giá trị từ thằng redux trả về
    const { cauhinhnghile } = useSelector(state => state.cauhinhnghile)
    useEffect(() => {
        dispatch(actions.getListCauhinhnghile());
    }, [])
    useEffect(() => {
        if (cauhinhnghile) {
            setData([...cauhinhnghile]);
        }
    }, [cauhinhnghile]);

    const [showPhanquyen, setShowPhanquyen] = useState({});
    let dataPermissionUtils = PermissionUtils('cauhinhnghile', 'all');
    useEffect(() => {
        if (dataPermissionUtils) {
            setShowPhanquyen(dataPermissionUtils);
        }
    }, [dataPermissionUtils]);

    const namefile = 'Thông tin cấu hình nghỉ lễ';
    const header = ['Stt', 'Tiêu đề', 'Ngày bắt đầu', 'Ngày kết thúc', 'Trạng thái'];
    const [columns, setColumns] = useState(() => {
        const d = header.map((item, index) => index <= 8);
        return d;
    });
    const [dataFileMau, setDataFileMau] = useState([]);
    useEffect(() => {
        if (data.length > 0) {
            const datafilemau = data.map((item, index) => {
                return {
                    ...(columns[0] && { Stt: index + 1 }),
                    ...(columns[1] && { 'Tiêu đề': item?.title }),
                    ...(columns[2] && { 'Ngày bắt đầu': item?.ngaybatdau }),
                    ...(columns[3] && { 'Ngày kết thúc': item?.ngayketthuc }),
                    ...(columns[4] && { 'Trạng thái': item?.TrangthaiId === '1' ? 'Hoạt Động' : 'Dừng Hoạt Động' }),
                };
            });
            setDataFileMau(datafilemau);
        }
    }, [data, columns]);
    // console.log('cauhinhnghile',cauhinhnghile)
    const [sm, updateSm] = useState(false);
    const [tablesm, updateTableSm] = useState(false);
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [sort, setSortState] = useState("");
    // onChange function for searching name
    const onFilterChange = (e) => {
        setSearchText(e.target.value);
    };
    const toggle = () => setonSearch(!onSearch);
    const selectorCheck = (e) => {
        let newData;
        newData = data.map((item) => {
            item.checked = e.currentTarget.checked;
            return item;
        });
        setData([...newData]);
    };
    const onSelectChange = (e, id) => {
        let newData = data;
        let index = newData.findIndex((item) => item.id === id);
        newData[index].checked = e.currentTarget.checked;
        setData([...newData]);
    };
    useEffect(() => {
        if (onSearchText !== "") {
            const filteredObject = cauhinhnghile.filter((item) => {
                return (
                    item.tieude.toLowerCase().includes(onSearchText.toLowerCase())
                );
            });
            setData([...filteredObject]);
        } else {
            setData([...cauhinhnghile]);
        }
    }, [onSearchText, setData]);
    // Get current list, pagination
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    

    useEffect(() => {
        cauhinhnghile && setShow(false) || setShowEditCauhinhnghile(false)
    }, [cauhinhnghile])
    const handleAddNewCauhinhnghile = async () => {
        setShow(true)
    }
    const [dataIdCauhinhnghile, setDataIdCauhinhnghile] = useState([]);
    const handleEditCauhinhnghile = async (data) => {
        setShowEditCauhinhnghile(true)
        await setDataIdCauhinhnghile(data)
    }
    const handleDetailtCauhinhnghile = async (data) => {
        setShowDetailCauhinhnghile(true)
        await setDataIdCauhinhnghile(data)
    }
    const handleDeleteCauhinhnghile = (data) => {
        Swal.fire({
            title: 'Bạn có chắc không?',
            text: "Bạn sẽ không thể lấy lại khi xóa!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Không, bỏ qua!',
            confirmButtonText: 'Vâng, xóa nó đi!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(actions.DeleteCauhinhnghile(data));
            }
        })
    }
    const itemsPerPageOptions = [10, 20, 30, 40, 50, 100, 300, 500];
    return (
        <React.Fragment>
            <Head title="Cấu hình nghỉ phép công tác và nghỉ lễ"></Head>
            <Content>
                <Card className="mt-0">
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner card-inner-lg px-4">
                            <h4>Cấu hình nghỉ phép công tác và nghỉ lễ</h4>
                            <MenuNghiphepNghile />
                        </div>
                    </DataTable>
                </Card>
                <Block>
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner position-relative card-tools-toggle pt-0">
                            <div className="card-title-group">
                                <div className="card-title">
                                    <h5 className="title">Cấu hình nghỉ lễ</h5>
                                    <Button
                                            color="primary"
                                            className="btn-icon px-4 mt-2"
                                            onClick={handleAddNewCauhinhnghile}
                                        >
                                            <Icon name="plus"></Icon> Thêm mới
                                        </Button>
                                    {/* <span
                                        className="text-white ms-0 px-5 py-1 badge justify-content-center px-0 btn btn-primary"
                                        onClick={handleAddNewCauhinhnghile}><em
                                        className="icon ni ni-plus pe-2"></em>Thêm mới</span> */}
                                </div>
                                <div className="card-tools me-n1">
                                    <ul className="btn-toolbar gx-1">
                                        <li>
                                            <a
                                                href="#search"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    toggle();
                                                }}
                                                className="btn btn-icon search-toggle toggle-search"
                                            >
                                                <Icon name="search"></Icon>
                                            </a>
                                        </li>
                                        <li className="btn-toolbar-sep"></li>
                                        <li>
                                            <div className="toggle-wrap">
                                                <Button
                                                    className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                                                    onClick={() => updateTableSm(true)}
                                                >
                                                    <Icon name="menu-right"></Icon>
                                                </Button>
                                                <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                                                    <ul className="btn-toolbar gx-1">
                                                        <li className="toggle-close">
                                                            <Button className="btn-icon btn-trigger toggle"
                                                                    onClick={() => updateTableSm(false)}>
                                                                <Icon name="arrow-left"></Icon>
                                                            </Button>
                                                        </li>
                                                        
                                                        <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle color="tranparent"
                                                                                className="btn btn-trigger btn-icon dropdown-toggle">
                                                                    <Icon name="setting"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu end className="dropdown-menu-xs">
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Show</span>
                                                                        </li>
                                                                        {itemsPerPageOptions.map((item, index) => {
                                                                            return (
                                                                                <li
                                                                                    className={
                                                                                        itemPerPage === item
                                                                                            ? 'active'
                                                                                            : ''
                                                                                    }
                                                                                    key={index}
                                                                                >
                                                                                    <DropdownItem
                                                                                        tag="a"
                                                                                        href="#dropdownitem"
                                                                                        onClick={(ev) => {
                                                                                            ev.preventDefault();
                                                                                            setItemPerPage(item);
                                                                                            // handleReParamsClick();
                                                                                        }}
                                                                                    >
                                                                                        {item}
                                                                                    </DropdownItem>
                                                                                </li>
                                                                            );
                                                                        })}
                                                                    </ul>
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Order</span>
                                                                        </li>
                                                                        <li className={sort === "dsc" ? "active" : ""}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setSortState("dsc");
                                                                                    //   sortFunc("dsc");
                                                                                }}
                                                                            >
                                                                                DESC
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li className={sort === "asc" ? "active" : ""}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setSortState("asc");
                                                                                    //   sortFunc("asc");
                                                                                }}
                                                                            >
                                                                                ASC
                                                                            </DropdownItem>
                                                                        </li>
                                                                    </ul>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                        <li>
                                                                <div className="toggle-wrap nk-block-tools-toggle">
                                                                    <Button
                                                                        className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                                                                        onClick={() => updateSm(!sm)}
                                                                    >
                                                                        <Icon name="upload-cloud"></Icon>
                                                                    </Button>
                                                                    <div className="toggle-expand-content-cloud"
                                                                         style={{display: sm ? "block" : "none"}}>
                                                                        <ul className="nk-block-tools g-3">
                                                                            <li className="nk-block-tools-opt">
                                                                            <ExportXlsx
                                                                                    dataFileMau={dataFileMau}
                                                                                    title={header}
                                                                                    namefile={namefile}
                                                                                    btnname={'Xuất dữ liệu'}
                                                                                />
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                                <div className="card-body">
                                    <div className="search-content">
                                        <Button
                                            className="search-back btn-icon toggle-search active"
                                            onClick={() => {
                                                setSearchText("");
                                                toggle();
                                            }}
                                        >
                                            <Icon name="arrow-left"></Icon>
                                        </Button>
                                        <input
                                            type="text"
                                            className="border-transparent form-focus-none form-control"
                                            placeholder="Tìm kiếm"
                                            value={onSearchText}
                                            onChange={(e) => onFilterChange(e)}
                                        />
                                        <Button className="search-submit btn-icon">
                                            <Icon name="search"></Icon>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ overflowY: 'hidden' }}>
                        <DataTableBody>
                            <DataTableHead>
                                {columns[0] && (
                                    <DataTableRow>
                                        <span className="sub-text">Stt</span>
                                    </DataTableRow>
                                )}
                                <DataTableRow className="nk-tb-col-check">
                                    <div className="custom-control custom-control-sm custom-checkbox notext">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            onChange={(e) => selectorCheck(e)}
                                            id="uid"
                                        />
                                        <label className="custom-control-label" htmlFor="uid"></label>
                                    </div>
                                </DataTableRow>
                                {columns[1] && (
                                <DataTableRow>
                                    <span className="sub-text w-150px">Tiêu đề</span>
                                </DataTableRow>
                                )}
                                <DataTableRow>
                                    <span className="sub-text w-100px">Xem chi tiết</span>
                                </DataTableRow>
                                {columns[2] && (
                                <DataTableRow>
                                    <span className="sub-text w-100px">Ngày bắt đầu</span>
                                </DataTableRow>
                                )}
                                {columns[3] && (
                                <DataTableRow>
                                    <span className="sub-text w-100px">Ngày kết thúc</span>
                                </DataTableRow>
                                )}
                                {columns[4] && (
                                <DataTableRow>
                                    <span className="sub-text w-100px">Trạng thái</span>
                                </DataTableRow>
                                )}
                                 <DataTableRow className="nk-tb-col-tools text-end">
                                    <UncontrolledDropdown>
                                        <DropdownToggle
                                            color="tranparent"
                                            className="btn btn-xs btn-outline-light btn-icon dropdown-toggle"
                                        >
                                            <Icon name="plus"></Icon>
                                        </DropdownToggle>
                                        <DropdownMenu end className="dropdown-menu-xs">
                                            <ul className="link-tidy sm no-bdr">
                                                {header.length > 0 ? (
                                                    header.map((item, index) => (
                                                        <li key={index}>
                                                            <div className="custom-control custom-control-sm custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id={`column-${index}`}
                                                                    checked={columns[index]}
                                                                    onChange={(e) =>
                                                                        setColumns((prev) => {
                                                                            const newColumns = [...prev];
                                                                            newColumns[index] = e.target.checked;
                                                                            return newColumns;
                                                                        })
                                                                    }
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor={`column-${index}`}
                                                                >
                                                                    {item}
                                                                </label>
                                                            </div>
                                                        </li>
                                                    ))
                                                ) : (
                                                    <li>No data</li>
                                                )}
                                            </ul>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </DataTableRow>
                        
                            </DataTableHead>
                            {currentItems.length > 0
                                ? currentItems.map((item,index) => {
                                    const displayedIndex =  itemPerPage * (currentPage - 1) + index + 1;
                                    return (
                                        <DataTableItem key={item.id}>
                                            {columns[0] && (
                                                  <DataTableRow size=" nk-tb-col-check mb">
                                                      <span className="tb-amount">{displayedIndex}</span>
                                                  </DataTableRow>
                                              )}
                                            <DataTableRow className="nk-tb-col-check">
                                                <div
                                                    className="custom-control custom-control-sm custom-checkbox notext">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        defaultChecked={item.checked}
                                                        id={item.id + "uid1"}
                                                        key={Math.random()}
                                                        onChange={(e) => onSelectChange(e, item.id)}
                                                    />
                                                    <label className="custom-control-label"
                                                           htmlFor={item.id + "uid1"}></label>
                                                </div>
                                            </DataTableRow>
                                            {columns[1] && (
                                            <DataTableRow>
                                                <span className="tb-amount">
                                                    {item?.tieude}
                                                </span>
                                            </DataTableRow>
                                            )}
                                             
                                            <DataTableRow>
                                                  <button onClick={() => handleDetailtCauhinhnghile(item)}
                                                      className={"bg-primary p-1 px-4 ms-0 badge text-white"}>
                                                   <Icon name="search"></Icon>
                                                </button>
                                            </DataTableRow>
                                            
                                             {columns[2] && (
                                            <DataTableRow>
                                                <span className="tb-amount">
                                                    {item?.ngaybatdau}
                                                </span>
                                            </DataTableRow>
                                            )}
                                            {columns[3] && (
                                            <DataTableRow>
                                                <span className="tb-amount">
                                                    {item?.ngayketthuc}
                                                </span>
                                            </DataTableRow>
                                            )}
                                            {columns[4] && (
                                            <DataTableRow>
                                                  <span
                                                      className={`${item?.TrangthaiId === 1 ? 'bg-success' : 'bg-danger'} ms-0 badge text-white`}>
                                                    {item?.TrangthaiId === 1 ? 'Hoạt Động' : 'Dừng Hoạt Động'}
                                                </span>
                                            </DataTableRow>
                                            )}
                                            <DataTableRow className="nk-tb-col-tools">
                                                <ul className="nk-tb-actions gx-1">
                                                {+showPhanquyen?.sua === 1 && (
                                                    <li className="nk-tb-action-hidden"
                                                        onClick={() => handleEditCauhinhnghile(item)}>
                                                        <TooltipComponent
                                                            tag="a"
                                                            containerClassName="btn btn-trigger btn-icon"
                                                            id={"edit" + item.id}
                                                            icon="edit-alt-fill"
                                                            direction="top"
                                                            text="Sửa"
                                                        />
                                                    </li>
                                                )}
                                                   {+showPhanquyen?.xoa === 1 && (
                                                        <React.Fragment>
                                                            <li className="nk-tb-action-hidden"
                                                                onClick={() => handleDeleteCauhinhnghile(item)}>
                                                                <TooltipComponent
                                                                    tag="a"
                                                                    containerClassName="btn btn-trigger btn-icon"
                                                                    id={"suspend" + item.id}
                                                                    icon="user-cross-fill"
                                                                    direction="top"
                                                                    text="Xóa"
                                                                />
                                                            </li>
                                                        </React.Fragment>
                                                   )}
                                                    <li>
                                                        <UncontrolledDropdown>
                                                        {(+showPhanquyen?.sua === 1 ||
                                                                  +showPhanquyen?.xoa === 1) && (
                                                                  <DropdownToggle
                                                                      tag="a"
                                                                      className="dropdown-toggle btn btn-icon btn-trigger"
                                                                  >
                                                                      <Icon name="more-h"></Icon>
                                                                  </DropdownToggle>
                                                              )}
                                                            <DropdownMenu end>
                                                                <ul className="link-list-opt no-bdr">
                                                                {+showPhanquyen?.sua === 1 && (
                                                                    <li onClick={() => handleEditCauhinhnghile(item)}>
                                                                        <DropdownItem
                                                                            tag="a"
                                                                            href="#edit"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();
                                                                            }}
                                                                        >
                                                                            <Icon name="edit"></Icon>
                                                                            <span>Sửa</span>
                                                                        </DropdownItem>
                                                                    </li>
                                                                   )}
                                                                   {+showPhanquyen?.xoa === 1 && (
                                                                    <React.Fragment>
                                                                        <li className="divider"></li>
                                                                        <li onClick={() => handleDeleteCauhinhnghile(item)}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#suspend"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                }}
                                                                            >
                                                                                <Icon name="na"></Icon>
                                                                                <span>Xóa</span>
                                                                            </DropdownItem>
                                                                        </li>
                                                                    </React.Fragment>
                                                                    )}
                                                                </ul>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </li>
                                                </ul>
                                            </DataTableRow>
                                        </DataTableItem>
                                    );
                                })
                                : null}
                        </DataTableBody>
                        </div>
                        <div className="card-inner">
                            {currentItems.length > 0 ? (
                                <PaginationComponent
                                    itemPerPage={itemPerPage}
                                    totalItems={cauhinhnghile.length}
                                    paginate={paginate}
                                    currentPage={currentPage}
                                />
                            ) : (
                                <div className="text-center">
                                    <span className="text-silent">No data found</span>
                                </div>
                            )}
                        </div>
                    </DataTable>
                </Block>
    
                <ModalCauhinhnghile
                    fieldList={fieldList}
                    show={show}
                    setShow={() => setShow(false)}
                />
                <ModalEditCauhinhnghile
                    fieldList={fieldList}
                    show={showEditCauhinhnghile}
                    setShow={() => setShowEditCauhinhnghile(false)}
                    dataIdCauhinhnghile={dataIdCauhinhnghile}
                />
                <ModalDetailCauhinhnghile
                    fieldList={fieldList}
                    show={showDetailCauhinhnghile}
                    setShow={() => setShowDetailCauhinhnghile(false)}
                    dataIdCauhinhnghile={dataIdCauhinhnghile}
                />
        
            </Content>
        </React.Fragment>
    );
}

export default Cauhinhnghile;