import axiosConfig from '../axiosConfig'

export const apiRegister = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v1/auth/register',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiLogin = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v1/auth/login',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiGetUser = () =>
    new Promise(async (resolve, reject) => {
        try {
            const response = await axiosConfig({
                method: 'get',
                url: `/api/v1/user`,
            });
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
export const verifyEmail = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v1/auth/verify-email',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const AuthResetServices = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v1/auth/auth-reset',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const AuthResetPasswordServices = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v1/auth/auth-reset-password',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
// export const ApiRefreshToken = (refresh) => new Promise(async (resolve, reject) => {
//     try {
//         console.log('sdfsdf', refresh);
//         const response = await axiosConfig({
//             method: 'post',
//             url: '/api/v1/auth/refresh-token',
//             refreshToken: refresh
//         })
//         console.log('response', response);
//         resolve(response)

//     } catch (error) {
//         reject(error)
//     }
// })
const handleRefreshToken = (refresh) => {
    return axiosConfig.post('/api/v1/auth/refresh-token',{ refreshToken: refresh });
   }
   export {handleRefreshToken}


export const apiBlog = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: '/api/v1/auth/blog',
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})