import axiosConfig from '../axiosConfig'

export const apiGetQuanlythamnien = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: '/api/v2/quanlynhanvien/quanlythamnien?page='+payload.start+'&limit='+payload.size+'&order='+payload.sorting+'&by='+payload.globalFilter+'&typeGet='+payload.typeGet,
        })
        resolve(response)
    } catch (error) {
        reject(error)
    }
})
export const apiNewQuanlythamnien = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlynhanvien/quanlythamnien',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiEditQuanlythamnien = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlynhanvien/quanlythamnien',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiDeleteQuanlythamnien = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlynhanvien/quanlythamnien',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
