export const validateNguoiPhuThuoc = (fieldName, value) => {
    let regNumber = /^[0-9\b]+$/;
    switch (fieldName) {
        case 'hoten':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Họ tên không để trống'
                }
            }
            break;
        case 'quanhevsnhanvien':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Quan hệ với nhân viên không để trống'
                }
            }
            break;
        case 'thoidiemtinhgiamtrutu':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Thời điểm bắt đầu tính giảm trừ không để trống'
                }
            }
            break;
        // case 'thoidiemtinhgiamtruden':
        //     if (value.length === 0) {
        //         return {
        //             name: fieldName,
        //             message: 'Thời điểm kết thúc tính giảm trừ không để trống'
        //         }
        //     }
        //     break;
        // case 'diachi':
        //     if (value.length === 0) {
        //         return {
        //             name: fieldName,
        //             message: 'Địa không để trống'
        //         }
        //     }
        //     break;
            
        default:
            break;
    }
}