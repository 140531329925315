import actionTypes from './actionTypes'
import { apiGetChucvu, apiNewChucvu, apiEditChucvu, apiDeleteChucvu } from '~/services/chucvu'
import { toast } from "react-toastify";
export const getListChucvu = () => async (dispatch) => {
    try {
        const response = await apiGetChucvu()
        // console.log('chek api lisst Chucvu', response?.data.success)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_CHUC_VU,
                chucvu: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_CHUC_VU,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_CHUC_VU,
            chucvu: null
        })
    }
}

export const NewChucvu = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewChucvu', payload)
            const response = await apiNewChucvu(payload)
            // console.log('chek api apiNewChucvu', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_CHUC_VU,
                    msg: 'OK'
                });
                dispatch(getListChucvu());
                toast.success("Thêm mới chức vụ thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_CHUC_VU,
                    msg: 'ERR'
                })
                toast.error("Thêm mới chức vụ thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_CHUC_VU,
                chucvu: null
            })
        }
    }
}
export const EditChucvu = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditChucvu(payload)
            // console.log('chek api apiEditChucvu', response)
            if (response?.data.success === 200) {
                dispatch(getListChucvu());
                toast.success("Sửa thông tin chức vụ thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_CHUC_VU,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_CHUC_VU,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin chức vụ thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_CHUC_VU,
                chucvu: null
            })
        }
    }
}
export const DeleteChucvu = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteChucvu(payload)
            // console.log('chek api apiDeleteChucvu', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListChucvu());
                toast.success("Xóa thông tin chức vụ thành công!")
                dispatch({
                    type: actionTypes.DELETE_CHUC_VU,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_CHUC_VU,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin chức vụ thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_CHUC_VU,
                chucvu: null
            })
        }
    }
}