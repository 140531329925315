import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {InputForm, Button} from '~/components/Form'
import * as actions from '~/store/actions'
import {validateBinhLuan} from "~/request/validateBinhLuan";
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';

const ModalBinhluan = ({show, setShow, fieldList}) => {
    const {phongban} = useSelector(state => state.phongban)
    const dispatch = useDispatch()
    const [invalidFields, setInvalidFields] = useState([])
    const [payload, setPayloadBinhluan] = useState(fieldList)
    useEffect(() => {
        show && setPayloadBinhluan(fieldList)
    }, [show])
    
    const [listPhongban, setlistPhongban] = useState([])
    useEffect(() => {
        if (phongban) {
            let dataSelectPhongban = phongban.map((item) => ({ label: item.phongban, value: item.id }));
            setlistPhongban(dataSelectPhongban)
        }
    }, [phongban])
    const handleSubmit = async () => {
        let invalids = validate(payload)
        
        if (invalids === 0) {
            let datapayload = {
                PhongbanId: payload.PhongbanId.value,
                binhluan: payload.binhluan,
                motavitri: payload.motavitri,
            }
            // console.log('check payload', datapayload)
            dispatch(actions.NewBinhluan(datapayload))
        }
    }
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayloadBinhluan(prev => ({...prev, [statename]: selected}))
    }
    const validate = (payload) => {
        let invalids = 0
        let fields = Object.entries(payload)
        fields.forEach(item => {
            if (validateBinhLuan(item[0], item[1]) !== undefined) {
                setInvalidFields(prev => [...prev, validateBinhLuan(item[0], item[1])])
                invalids++
            }
        })
        return invalids
    }
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Thêm mới bình luận
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-6 ">
                        <label htmlFor="inputZip" className="mb-2">Phòng ban</label>
                        <Select
                            value={payload.PhongbanId}
                            onChange={handleOnSelect}
                            options={listPhongban}
                            placeholder={'Chọn phòng ban'}
                            name='PhongbanId'
                            onFocus={() => setInvalidFields([])}
                        />
                        {invalidFields.length > 0 && invalidFields.some(i => i.name === 'PhongbanId') && <small
                            className='text-danger'>{invalidFields.find(i => i.name === 'PhongbanId')?.message}</small>}
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Chức vụ'}
                            value={payload.binhluan}
                            setValue={setPayloadBinhluan}
                            keyPayload={'binhluan'}
                            type='text'
                            validate
                        />
                    </div>
                    <div className="form-group col-md-6 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Mô tả vị trí'}
                            value={payload.motavitri}
                            setValue={setPayloadBinhluan}
                            keyPayload={'motavitri'}
                            type='text'
                            validate
                        />
                    </div>
                </div>
                
                <div className='col-12 mt-5'>
                    <Button
                        text='Thêm mới'
                        bClass='btn btn-primary px-5'
                        textColor
                        onClick={handleSubmit}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ModalBinhluan;

