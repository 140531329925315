import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {InputForm, Button} from '~/components/Form'
import { Input, Modal, ModalBody } from 'reactstrap';
import * as actions from '~/store/actions'
import '../Modal.scss';
import Select from 'react-select';
import {
    dataSelectDouutien,
    dataSelectStatus,
} from "~/utils/Datacustom";
import { Icon, SelectNhanvien } from '~/components/Component';

const ModalEditGiaoviec = ({show, setShow, dataIdGiaoviec, fieldList, handleEditDetail}) => {
    const [file, setFile] = useState(null);

    const {giaoviecbang} = useSelector(state => state.giaoviec)
    const [listNhanvien, setlistNhanvien] = useState([])
    const [listStatus, setlistStatus] = useState([])
    const [listDouutien, setlistDouutien] = useState([]);
    const [listGiaoviecbang, setlistGiaoviecbang] = useState([]);
    const [showSelect, setShowSelect] = useState(false);
    const dispatch = useDispatch()
    const [invalidFields, setInvalidFields] = useState([])
    const [payload, setPayloadGiaoviec] = useState(fieldList)
    // console.log(payload)
    useEffect(() => {
        let trangthai = listStatus?.find(item => {
            return item && item.value === Number(dataIdGiaoviec?.trangthai)
        })
        let douutien = listDouutien?.find(item => {
            return item && item.value === Number(dataIdGiaoviec?.douutien)
        })
        show && dataIdGiaoviec && setPayloadGiaoviec({
            id: dataIdGiaoviec?.id,
            congviec: dataIdGiaoviec?.congviec,
            mota: dataIdGiaoviec?.mota,
            ngaybatdau: dataIdGiaoviec?.ngaybatdau,
            ngayketthuc: dataIdGiaoviec?.ngayketthuc,
            giobatdau: dataIdGiaoviec?.giobatdau,
            gioketthuc: dataIdGiaoviec?.gioketthuc,
            NhanvienId: {label: dataIdGiaoviec?.Nhanvien?.hoten, value: dataIdGiaoviec?.NhanvienId},
            GiaoviecbangId: {label: dataIdGiaoviec?.Giaoviecbang?.text, value: dataIdGiaoviec?.GiaoviecbangId},
            douutien: {label: douutien?.label, value: douutien?.value},
            tailieudinhkem: dataIdGiaoviec?.tailieudinhkem,
            tags: dataIdGiaoviec?.tags,
            trangthai: {label: trangthai?.label, value: trangthai?.value},
            
        })
    }, [dataIdGiaoviec, show])
    useEffect(() => {
        if (dataSelectDouutien && giaoviecbang && dataSelectStatus ) {
            let dataSelectGiaoviecbang = giaoviecbang.map((item) => ({ label: item.text, value: item.id }));
            setlistStatus(dataSelectStatus);
            setlistDouutien(dataSelectDouutien);
            setlistGiaoviecbang(dataSelectGiaoviecbang);
        }
        listNhanvien.length > 0 &&
            setPayloadGiaoviec((prev) => ({
                ...prev,
                ['NhanvienId']: { label: listNhanvien[0]?.label, value: listNhanvien[0]?.value },
            }));
    }, [dataSelectStatus, dataSelectDouutien, giaoviecbang]);
    const handleOnSelect = async (selected, name) => {
        console.log('e.target.files')
        let statename = name.name;
        setPayloadGiaoviec(prev => ({...prev, [statename]: selected}))
        
    }
    const handleSubmit = async () => {
        
        const formData = new FormData();
        formData.append('id', payload?.id);
        formData.append('congviec', payload.congviec || '');
        formData.append('mota', payload.mota || '');
        formData.append('ngaybatdau', payload.ngaybatdau || '');
        formData.append('ngayketthuc', payload.ngayketthuc || '');
        formData.append('giobatdau', payload.giobatdau || '');
        formData.append('gioketthuc', payload.gioketthuc || '');
        formData.append('NhanvienId', payload.NhanvienId.value || '');
        formData.append('GiaoviecbangId', payload.GiaoviecbangId.value || '');
        formData.append('douutien', payload.douutien.value || '');
        formData.append('tailieudinhkem', payload.tailieudinhkem || '');
        formData.append('tags', payload.tags || '');
        formData.append('trangthai', payload.trangthai.value || '');
        dispatch(actions.EditGiaoviec(formData))
    }
    const handleFileChange = async (e) => {
        const selectedFile = e.target.files[0];
        console.log('selectedFile',selectedFile)
        setPayloadGiaoviec((prev) => ({...prev, ['tailieudinhkem']: selectedFile}));
        
    };
    const validate = (payload) => {
        let invalids = 0
        let fields = Object.entries(payload)
        fields.forEach(item => {
            if (item[1] === '') {
                setInvalidFields(prev => [...prev, {
                    name: item[0],
                    message: 'Bạn không được bỏ trống trường này.'
                }])
                invalids++
            }
        })
        
        return invalids
    }
    
    return (
        <React.Fragment>
        <Modal isOpen={show} className="modal-dialog-centered modal-90w" size="lg" toggle={setShow}>
           <ModalBody>
           <Icon name="cross-sm float-end fs-2" onClick={setShow}></Icon>
               <h5 className="title mb-3">
               {handleEditDetail === false ? 'Sửa giao việc' : 'Chi tiết giao việc'}
               </h5>
               <div className="row">
                   <div className="form-group col-md-12">
                       <InputForm
                           setInvalidFields={setInvalidFields}
                           invalidFields={invalidFields}
                           label={'Công việc'}
                           value={payload.congviec}
                           setValue={setPayloadGiaoviec}
                           keyPayload={'congviec'}
                           type="text"
                           validate
                       />
                   </div>

                   <div className="form-group col-md-12 mb-2">
                       <label htmlFor="inputZip" className="mb-2 fw-bold">
                           <span>
                               Mô tả chi tiết công việc <b className="text-danger">(*)</b>
                           </span>
                       </label>
                       <Input
                           value={payload.mota}
                           onChange={(e) => setPayloadGiaoviec((prev) => ({ ...prev, ['mota']: e.target.value }))}
                           rows={'2'}
                           name="mota"
                           type="textarea"
                       />
                       {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'mota') && (
                           <small className="text-danger">
                               {invalidFields.find((i) => i.name === 'mota')?.message}
                           </small>
                       )}
                   </div>
               </div>
               <div className="row">
                   <div className="form-group col-md-3">
                       <InputForm
                           setInvalidFields={setInvalidFields}
                           invalidFields={invalidFields}
                           label={'Ngày bắt đầu'}
                           value={payload.ngaybatdau}
                           setValue={setPayloadGiaoviec}
                           keyPayload={'ngaybatdau'}
                           type="date"
                           validate
                       />
                   </div>
                   <div className="form-group col-md-3">
                       <InputForm
                           setInvalidFields={setInvalidFields}
                           invalidFields={invalidFields}
                           label={'Ngày kết thúc'}
                           value={payload.ngayketthuc}
                           setValue={setPayloadGiaoviec}
                           keyPayload={'ngayketthuc'}
                           type="date"
                           validate
                       />
                   </div>
                   <div className="form-group col-md-3">
                       <InputForm
                           setInvalidFields={setInvalidFields}
                           invalidFields={invalidFields}
                           label={'Giờ bắt đầu'}
                           value={payload.giobatdau}
                           setValue={setPayloadGiaoviec}
                           keyPayload={'giobatdau'}
                           type="time"
                           validate
                       />
                   </div>
                   <div className="form-group col-md-3">
                       <InputForm
                           setInvalidFields={setInvalidFields}
                           invalidFields={invalidFields}
                           label={'Giờ kết thúc'}
                           value={payload.gioketthuc}
                           setValue={setPayloadGiaoviec}
                           keyPayload={'gioketthuc'}
                           type="time"
                           validate
                       />
                   </div>
               </div>
               <div className="row">
                   {/* <div className="form-group col-md-4">
                       <label htmlFor="inputZip" className="mb-2 fw-bold">
                           <span>
                               Nhóm nhân viên <b className="text-danger">(*)</b>
                           </span>
                       </label>
                       <Select
                           value={payload.NhomnvId}
                           onChange={handleOnSelect}
                           options={listNhomNV}
                           placeholder={'Nhóm nhân viên'}
                           name="NhomnvId"
                           onFocus={() => setInvalidFields([])}
                       />
                       {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'NhomnvId') && (
                           <small className="text-danger">
                               {invalidFields.find((i) => i.name === 'NhomnvId')?.message}
                           </small>
                       )}
                   </div> */}
                   <div className="form-group col-md-6"
                        onClick={() => setShowSelect(true)}
                   >
                       <label htmlFor="inputZip" className="mb-2 fw-bold">
                           Chọn Nhân Viên <b className="text-danger">(*)</b>
                       </label>
                       <Select
                           value={payload?.NhanvienId}
                           name='NhanvienId'
                           onFocus={() => setInvalidFields([])}
                       />
                       {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'NhanvienId') && (
                           <small className="text-danger">
                               {invalidFields.find((i) => i.name === 'NhanvienId')?.message}
                           </small>
                       )}
                   </div>
                   
                   <div className="form-group col-md-6">
                       <label htmlFor="inputZip" className="mb-2 fw-bold">
                           <span>
                               Độ ưu tiên <b className="text-danger">(*)</b>
                           </span>
                       </label>
                       <Select
                           value={payload.douutien}
                           onChange={handleOnSelect}
                           options={listDouutien}
                           placeholder={'Chọn độ ưu tiên'}
                           name="douutien"
                           onFocus={() => setInvalidFields([])}
                       />
                       {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'douutien') && (
                           <small className="text-danger">
                               {invalidFields.find((i) => i.name === 'douutien')?.message}
                           </small>
                       )}
                   </div>
                   <div className="form-group col-md-6">
                       <label htmlFor="tailieudinhkem" className="mb-2 fw-bold w-100">
                                <span>
                                    Tài liệu đính kèm <b className="text-danger">(*)</b>
                                </span>
                           <input type="file" id="tailieudinhkem" accept=".pdf,.docx" className="form-control"
                                  onChange={handleFileChange}></input>
                       </label>
    
                   </div>
               
                   <div className="form-group col-md-6">
                       <InputForm
                           setInvalidFields={setInvalidFields}
                           invalidFields={invalidFields}
                           label={'Tags'}
                           value={payload.tags}
                           setValue={setPayloadGiaoviec}
                           keyPayload={'tags'}
                           type="text"
                           validate
                       />
                   </div>
                   {/* <div className="form-group col-md-4">
                       <label htmlFor="inputZip" className="mb-2 fw-bold">
                           <span>
                               Thuộc Công Việc <b className="text-danger">(*)</b>
                           </span>
                       </label>
                       <Select
                           value={payload.parent}
                           // onChange={handleSelect}
                           onChange={(selected) =>
                               setPayloadGiaoviec((prev) => ({ ...prev, ['parent']: selected }))
                           }
                           options={listGiaoViec}
                           placeholder={'Thuộc Công Việc'}
                           name="parent"
                           onFocus={() => setInvalidFields([])}
                       />
                       
                   </div> */}
                   {/* <div className="form-group col-md-4">
                       <label htmlFor="inputZip" className="mb-2 fw-bold">
                           <span>
                               Sub Công Việc <b className="text-danger">(*)</b>
                           </span>
                       </label>
                       <Select
                           value={payload.subcongviec}
                           isMulti
                           onChange={handleOnSelect}
                           options={listGiaoViec}
                           placeholder={'Sub Công Việc'}
                           name="subcongviec"
                           onFocus={() => setInvalidFields([])}
                       />
                       {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'subcongviec') && (
                           <small className="text-danger">
                               {invalidFields.find((i) => i.name === 'subcongviec')?.message}
                           </small>
                       )}
                   </div> */}
               </div>
               <div className="row mt-3">
                   <div className="form-group col-md-6">
                       <label htmlFor="inputZip" className="mb-2 fw-bold">
                           <span>
                               Bảng <b className="text-danger">(*)</b>
                           </span>
                       </label>
                       <Select
                           value={payload.GiaoviecbangId}
                           onChange={handleOnSelect}
                           options={listGiaoviecbang}
                           placeholder={'Chọn bảng'}
                           name="GiaoviecbangId"
                           onFocus={() => setInvalidFields([])}
                       />
                       {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'GiaoviecbangId') && (
                           <small className="text-danger">
                               {invalidFields.find((i) => i.name === 'GiaoviecbangId')?.message}
                           </small>
                       )}
                   </div>
                   <div className="form-group col-md-6">
                       <label htmlFor="inputZip" className="mb-2 fw-bold">
                           <span>
                               Trạng thái <b className="text-danger">(*)</b>
                           </span>
                       </label>
                       <Select
                           value={payload.trangthai}
                           onChange={handleOnSelect}
                           options={listStatus}
                           placeholder={'Trạng thái'}
                           name="trangthai"
                           onFocus={() => setInvalidFields([])}
                       />
                       {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'trangthai') && (
                           <small className="text-danger">
                               {invalidFields.find((i) => i.name === 'trangthai')?.message}
                           </small>
                       )}
                   </div>
                   {/* <div className="form-group col-md-4">
                       <label htmlFor="inputZip" className="mb-2 fw-bold">
                           <span>
                               Phê duyệt <b className="text-danger">(*)</b>
                           </span>
                       </label>
                       <Select
                           value={payload.pheduyet}
                           onChange={handleOnSelect}
                           options={listDouutien}
                           placeholder={'Trạng thái Phê duyệt'}
                           name="pheduyet"
                           onFocus={() => setInvalidFields([])}
                       />
                       {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'trangthai') && (
                           <small className="text-danger">
                               {invalidFields.find((i) => i.name === 'trangthai')?.message}
                           </small>
                       )}
                   </div> */}
               </div>
               {handleEditDetail === false ? (
                <div className="col-12 mt-5">
                   <Button text="Cập nhật" bClass="btn btn-primary px-5" textColor onClick={handleSubmit} />
               </div>
               ) : ''}
               
               </ModalBody>
       </Modal>
       <SelectNhanvien
           showSelect={showSelect}
           // typeSelect = { ismultiple: "Lấy nhiều" , single: "lấy một" }
           setlistNhanvien={setlistNhanvien}
           setShowSelect={() => setShowSelect(false)}
       />
   </React.Fragment>
    );
}

export default ModalEditGiaoviec;

