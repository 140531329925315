import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { InputForm } from '~/components/Form';
import * as actions from '~/store/actions';
import { validateBangChamCong } from '~/request/validateBangChamCong';
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { Icon, Button } from '~/components/Component';
import { Input, Tooltip, } from 'reactstrap';
import { apiPostBangcongHistory } from '~/services';
import { format, parseISO } from 'date-fns';

const ModalEditCong = ({ show, setShow, dataChamCong, fetchData }) => {
    const dispatch = useDispatch();
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayload] = useState([]);
    const [payloadTc, setPayloadTc] = useState([]);
    const [ChamCong, setdataChamCong] = useState([]);
    console.log('dataChamCong', dataChamCong);
    // console.log('ChamCong', ChamCong);

    useEffect(() => {
        show && dataChamCong && setdataChamCong(dataChamCong);
        setPayload([]);
        setPayloadTc([]);
        setEdit(0);
        setEditTc(0);
    }, [dataChamCong, show]);
    // console.log('dataChamCong', dataChamCong)

    const handleSubmit = async (gio) => {
        try {
            if (Number(payload?.gio) !== Number(gio)) {
                let obj = {
                    NhanvienId: ChamCong?.NhanvienId,
                    BangchotcongId: ChamCong?.dataBangchotcong?.id,
                    ngaybatdau: ChamCong?.dataBangchotcong?.ngaybatdau
                        ? format(parseISO(ChamCong?.dataBangchotcong?.ngaybatdau), 'yyyy-MM-dd')
                        : '',
                    ngayketthuc: ChamCong?.dataBangchotcong?.ngayketthuc
                        ? format(parseISO(ChamCong?.dataBangchotcong?.ngayketthuc), 'yyyy-MM-dd')
                        : '',
                    idcalamviec: payload?.idcalamviec,
                    date_or_history: ChamCong?.data?.ngay,
                    tonggio: Number(payload?.gio),
                    ghichu: payload?.ghichu,
                };
                // console.log('bbbbbbbbbbbbbbbbbbbbbbb', obj);
                const response = await apiPostBangcongHistory(obj);
                if (response.data.success === 200) {
                    let objs = {
                        id: ChamCong?.dataBangchotcong?.id,
                        ngaybatdau: ChamCong?.dataBangchotcong?.ngaybatdau
                            ? format(parseISO(ChamCong?.dataBangchotcong?.ngaybatdau), 'yyyy-MM-dd')
                            : '',
                        ngayketthuc: ChamCong?.dataBangchotcong?.ngayketthuc
                            ? format(parseISO(ChamCong?.dataBangchotcong?.ngayketthuc), 'yyyy-MM-dd')
                            : '',
                    };
                    fetchData(objs);
                    toast.success('Cập nhật thành công!');
                    setShow(false);
                }
                // console.log('response', response);
            } else {
                toast.error('Tổng giờ không thay đổi!');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleSubmitTc = async (gio) => {
        try {
            if (Number(payloadTc?.gio) !== Number(gio)) {
                let obj = {
                    NhanvienId: ChamCong?.NhanvienId,
                    BangchotcongId: ChamCong?.dataBangchotcong?.id,
                    ngaybatdau: ChamCong?.dataBangchotcong?.ngaybatdau
                        ? format(parseISO(ChamCong?.dataBangchotcong?.ngaybatdau), 'yyyy-MM-dd')
                        : '',
                    ngayketthuc: ChamCong?.dataBangchotcong?.ngayketthuc
                        ? format(parseISO(ChamCong?.dataBangchotcong?.ngayketthuc), 'yyyy-MM-dd')
                        : '',
                    date_or_history: ChamCong?.data?.ngay,
                    tonggio: Number(payloadTc?.gio),
                    ghichu: payloadTc?.ghichu,
                    idtangca: payloadTc?.idtangca,
                };
                // console.log('bbbbbbbbbbbbbbbbbbbbbbb', obj);
                const response = await apiPostBangcongHistory(obj);
                if (response.data.success === 200) {
                    let objs = {
                        id: ChamCong?.dataBangchotcong?.id,
                        ngaybatdau: ChamCong?.dataBangchotcong?.ngaybatdau
                            ? format(parseISO(ChamCong?.dataBangchotcong?.ngaybatdau), 'yyyy-MM-dd')
                            : '',
                        ngayketthuc: ChamCong?.dataBangchotcong?.ngayketthuc
                            ? format(parseISO(ChamCong?.dataBangchotcong?.ngayketthuc), 'yyyy-MM-dd')
                            : '',
                    };
                    fetchData(objs);
                    toast.success('Cập nhật thành công!');
                    setShow(false);
                }
                // console.log('response', response);
            } else {
                toast.error('Tổng giờ tăng ca không thay đổi!');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const [edit, setEdit] = useState(0);
    const handleItemEdit = async (data, index) => {
        // console.log('data item ', data, index);
        setPayload((prev) => ({
            ...prev,
            ['gio']: data.gio,
            ['cong']: data.cong,
            ['demcongKb']: data.demcongKb,
            ['tonggioKb']: data.tonggioKb,
            ['idcalamviec']: data.id,
            ['ghichu']: data.meschinhsua,
        }));
        setEdit(index + 1);
    };
    const [editTc, setEditTc] = useState(0);
    const handleItemEditTc = async (data, index) => {
        // console.log('data item ', data, index);
        setPayloadTc((prev) => ({
            ...prev,
            ['gio']: data.gio,
            ['idtangca']: data.id,
            ['ghichu']: data.meschinhsuaTc,
        }));
        setEditTc(index + 1);
    };
    // const [dataCapnhat, setDataCapnhat] = useState([]);
    // useEffect(() => {
    //     // console.log(payload)
    //    const cong = Number(((payload?.gio / Number(payload?.tonggioKb)) * Number(payload?.demcongKb)).toFixed(2));

    //     // if(dataCapnhat){
    //         // setDataCapnhat(...[{id:edit, congCn: cong, gioCn: Number(payload.gio)}])
    //     // }
    //     // setPayload((prev) => ({ ...prev, ['cong']: cong }))
    //    console.log('cong', cong)

    // }, [payload]);
    // console.log('dataCapnhat', dataCapnhat)
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const tooltip = () => setTooltipOpen(!tooltipOpen);
    return (
        <Modal
            show={show}
            onHide={setShow}
            // dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">Chi tiết {ChamCong?.data?.ngay}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="rowssvv">
                    <div className="form-group col-md-12">
                        {ChamCong?.data?.dataCalamviec?.length > 0 &&
                            ChamCong?.data?.dataCalamviec.map((c, index) => (
                                <div className="text-center tb-amount" key={index}>
                                    {/* {c?.mesCalamviec ? (
                                        <div
                                            className="text-danger"
                                            dangerouslySetInnerHTML={{ __html: c?.mesCalamviec }}
                                        />
                                    ) : (
                                        ''
                                    )} */}
                                    <div className="fs-14px">{c?.macalamviec}</div>
                                    <div
                                        className={
                                            c?.dimuon !== 0 || c?.vesom !== 0 ? 'fs-14px text-danger' : 'fs-14px'
                                        }
                                    >
                                        {c?.vao ? c?.vao : '--:--'} - {c?.ra ? c?.ra : '--:--'}
                                    </div>
                                    <span
                                        className={
                                            c?.dimuon !== 0 || c?.vesom !== 0 ? 'fs-14px text-danger' : 'fs-14px'
                                        }
                                    >
                                        Đi muộn: {c?.dimuon} (phút) - Về sớm {c?.vesom} (phút)
                                    </span>
                                    <div>
                                        <div>
                                            {/* Tổng giờ: {c?.gio} - Tổng công: {c?.cong} */}
                                            Tổng giờ: {c?.allgiongayhl} - Tổng công: {c?.congngayhl}
                                            {edit === 0 && ChamCong?.dataBangchotcong?.TrangthaiId !== 1 && (
                                                <Icon
                                                    onClick={() => handleItemEdit(c, index)}
                                                    name="edit fs-18px text-primary"
                                                ></Icon>
                                            )}
                                        </div>
                                        {index + 1 === edit && (
                                            <div className="tall">
                                                <div className="d-flex justify-content-between mb-2">
                                                    <div className="w-100">
                                                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                                                            <span>Cập nhật giờ</span>
                                                        </label>
                                                        <Input
                                                            value={payload?.gio}
                                                            onChange={(e) =>
                                                                setPayload((prev) => ({
                                                                    ...prev,
                                                                    ['gio']: e.target.value,
                                                                }))
                                                            }
                                                            name="gio"
                                                            type="number"
                                                        />
                                                    </div>
                                                    <div className="w-100">
                                                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                                                            <span>Nội dung sửa</span>
                                                        </label>
                                                        <Input
                                                            value={payload?.ghichu}
                                                            onChange={(e) =>
                                                                setPayload((prev) => ({
                                                                    ...prev,
                                                                    ['ghichu']: e.target.value,
                                                                }))
                                                            }
                                                            name="ghichu"
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                                <Button
                                                    color="primary"
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        handleSubmit(c?.gio);
                                                        setEdit(0);
                                                        setPayload([]);
                                                    }}
                                                >
                                                    Cập nhật
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                    {c?.xindimuonvesom?.length > 0 ? (
                                        <div className="text-warning">
                                            <span>Có đơn xin đi muộn về sớm</span>
                                            {/* {c?.xindimuonvesom.map((i) => (
                                                <span key={i?.someUniqueKey}>
                                                    {i?.dimuon}
                                                    {''}
                                                    {i?.vesom}
                                                </span>
                                            ))} */}
                                        </div>
                                    ):(
                                        (c?.dimuon !== 0 || c?.vesom !== 0) && (
                                            <>
                                                <div id={`donxin${index}`}><Icon className="text-warning fs-20px" name="alert-fill" /> <div className="text-warning" >Không có đơn đi muộn về sớm</div></div>
                                                <Tooltip
                                                    isOpen={tooltipOpen}
                                                    target={`donxin${index}`}
                                                    toggle={tooltip}
                                                >
                                                    Không có đơn xin đi muộn về sớm. Hệ thống mặc định vẫn ghi nhận công!
                                                </Tooltip>
                                             </>
                                          )
                                    )}
                                    {c?.mesCalamviec !== '' && <div className="text-danger">{c?.mesCalamviec}</div>}
                                    {c?.chinhsua !== 0 && <div className="text-danger">(Đã chỉnh sửa)</div>}
                                </div>
                            ))}
                        {/* {ChamCong?.data?.TCngayle?.length > 0 &&
                            ChamCong?.data?.TCngayle.map((le) => (
                                <div className="text-center tb-amount" key={le}>
                                    <div className="text-gray">Tăng ca ngày lễ</div>
                                    <div className="text-gray">{le?.maphieu}</div>
                                    <span className={le?.mestc ? 'fs-12px text-danger' : 'fs-12px'}>
                                        {le.gio} (giờ)
                                    </span>
                                    {le?.mestc && <div className="text-danger">{le?.mestc}</div>}
                                </div>
                            ))} */}
                        {ChamCong?.data?.TCngaythuong?.length > 0 &&
                            ChamCong?.data?.TCngaythuong.map((th, index) => (
                                <div className="text-center tb-amount" key={th}>
                                    <div className="text-gray">{th?.loaiTc} ({th?.maphieu})</div>
                                    <div className="text-gray"> {th?.tcVao ? th?.tcVao : '--:--'} - {th?.tcRa ? th?.tcRa : '--:--'}</div>
                                    <span className={th?.mestc ? 'fs-12px text-danger' : 'fs-12px text-gray'}>
                                        {th.gio} (giờ)
                                        {editTc === 0 && ChamCong?.dataBangchotcong?.TrangthaiId !== 1 && (
                                                <Icon
                                                    onClick={() => handleItemEditTc(th, index)}
                                                    name="edit fs-18px text-primary"
                                                ></Icon>
                                        )}
                                    </span>
                                    {th?.mestc && <div className="text-danger">{th?.mestc}</div>}
                                    {index + 1 === editTc && (
                                            <div className="tall">
                                                <div className="d-flex justify-content-between mb-2">
                                                    <div className="w-100">
                                                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                                                            <span>Cập nhật giờ tăng ca</span>
                                                        </label>
                                                        <Input
                                                            value={payloadTc?.gio}
                                                            onChange={(e) =>
                                                                setPayloadTc((prev) => ({
                                                                    ...prev,
                                                                    ['gio']: e.target.value,
                                                                }))
                                                            }
                                                            name="gio"
                                                            type="number"
                                                        />
                                                    </div>
                                                    <div className="w-100">
                                                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                                                            <span>Nội dung sửa</span>
                                                        </label>
                                                        <Input
                                                            value={payloadTc?.ghichu}
                                                            onChange={(e) =>
                                                                setPayloadTc((prev) => ({
                                                                    ...prev,
                                                                    ['ghichu']: e.target.value,
                                                                }))
                                                            }
                                                            name="ghichu"
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                                <Button
                                                    color="primary"
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        handleSubmitTc(th?.gio);
                                                        setEditTc(0);
                                                        setPayloadTc([]);
                                                    }}
                                                >
                                                    Cập nhật
                                                </Button>
                                            </div>
                                        )}
                                        {th?.meschinhsuaTc !== '' && <div className="text-danger">(Đã sửa {th?.maphieu})</div>}
                                </div>
                            ))}
                        {/* {ChamCong?.data?.TCchunhat?.length > 0 &&
                            ChamCong?.data?.TCchunhat.map((ch) => (
                                <div className="text-center tb-amount" key={ch}>
                                    <div className="text-gray">Tăng ca chủ nhật</div>
                                    <div className="text-gray">{ch?.maphieu}</div>
                                    <span className={ch?.mestc ? 'fs-12px text-danger' : 'fs-12px'}>
                                        {ch.gio} (giờ)
                                    </span>
                                    {ch?.mestc && <div className="text-danger">{ch?.mestc}</div>}
                                </div>
                            ))} */}
                        {/* {dataChamCong?.mes !== '' && <div className="text-center text-gray tb-amount">{dataChamCong?.mes}</div>} */}
                        <hr />
                        {ChamCong?.data?.mes !== '' && (
                            <div className="text-center text-gray tb-amount">{ChamCong?.mes}</div>
                        )}
                        {ChamCong?.data?.meslock?.length > 0 &&
                            ChamCong?.data?.meslock.map((lo, index) => (
                                <div className="text-center text-danger tb-amount" key={`bb-${index}`}>
                                    {lo.log}
                                </div>
                            ))}
                    </div>
                </div>
                {/* <div className='col-12 mt-5'>
                    <Button
                        text='Cập nhật'
                        disabled={payload.is_edit !== 0}
                        bClass='btn btn-primary px-5'
                        textColor
                        onClick={handleSubmit}
                    />
                </div> */}
            </Modal.Body>
        </Modal>
    );
};

export default ModalEditCong;

// import React, {useEffect, useState} from 'react'
// import {useDispatch} from 'react-redux'
// import {Button, InputForm} from '~/components/Form'
// import * as actions from '~/store/actions'
// import {validateBangChamCong} from "~/request/validateBangChamCong";
// import '../Modal.scss';
// import Modal from 'react-bootstrap/Modal';
// import {toast} from "react-toastify";
// import {apiListBangChamCong, apiThongTinChamCongById} from "~/services";

// const ModalEditCong = ({show, setShow, fieldList, dataChamCong}) => {
//     const dispatch = useDispatch()
//     const [invalidFields, setInvalidFields] = useState([])
//     const [payload, setPayload] = useState(fieldList)
//     const [recordChamCong, setRecordChamCong] = useState()
//     const [title, setTitle] = useState('Dữ liệu chấm công của nhân viên này chưa được tạo')

//     async function fetchData() {
//         let dataParam = {
//             nhanvienId: Number(dataChamCong?.NhanvienId),
//             day: dataChamCong?.day,
//         };
//         try {
//             const Chamcong = await apiThongTinChamCongById(dataParam);
//             if (Chamcong?.data?.success === 200) {
//                 const response = Chamcong?.data?.data;
//                 setRecordChamCong(response);
//                 setTitle(Chamcong?.data?.message)
//             }

//         } catch (error) {
//             console.error('Error fetching data:', error);
//         }
//     }
//     // GET {{url_api}}/v2/quanlyhethong/tangca?page=0&limit=10&order=asc&by=id

//     useEffect(() => {
//         show &&
//         dataChamCong &&
//         setPayload({
//             is_edit: dataChamCong?.is_edit,
//             BangchotcongId: dataChamCong?.BangchotcongId,
//             day: dataChamCong?.day,
//             vao1: dataChamCong?.vao1 === '--:--' ? '' : dataChamCong?.vao1,
//             ra1: dataChamCong?.ra1 === '--:--' ? '' : dataChamCong?.ra1,
//             vao2: dataChamCong?.vao2 === '--:--' ? '' : dataChamCong?.vao2,
//             ra2: dataChamCong?.ra2 === '--:--' ? '' : dataChamCong?.ra2,
//             tonggio: dataChamCong?.tonggio,
//             ghichu: dataChamCong?.ghichu,
//             nghiPhep: dataChamCong?.nghiPhep,
//             cong: dataChamCong?.cong,
//             NhanvienId: dataChamCong?.NhanvienId,
//         });
//         fetchData().then(r => r)
//     }, [dataChamCong, show]);

//     const handleSubmit = async () => {
//         // console.log('payload',payload)
//         if (!dataChamCong.idBangCong) {
//             toast.error('Dữ liệu chấm công không tồn tại')
//             setShow(true)
//             return false;
//         }
//         const dataEdit = 1
//         const dataCreate = 2
//         let invalids = validate(payload)
//         const now = new Date(dataChamCong.day); // lấy thời gian hiện tại
//         let year = now.getFullYear();
//         let month = now.getMonth() + 1;
//         let date = now.getDate();
//         if (invalids === 0) {

//             let chamString = dataChamCong.cham ?? '';
//             let vao1Update = dataChamCong.vao1 === '--:--' ? 0 : dataChamCong.vao1
//             let ra1Update = dataChamCong.ra1 === '--:--' ? 0 : dataChamCong.ra1;
//             let vao1Pay = payload.vao1 === '' ? 0 : payload.vao1;
//             let ra1Pay = payload.ra1 === '' ? 0 : payload.ra1;
//             if (vao1Pay !== vao1Update && vao1Pay !== 0) { // nếu giờ vào 1 khác giờ vào 1 cũ
//                 vao1Pay = payload.vao1 + ':00';
//                 if (vao1Update !== 0) {
//                     chamString = chamString.replace(vao1Update, vao1Pay);
//                 } else {
//                     chamString = chamString === '' ? +vao1Pay : chamString + ',' + vao1Pay;
//                 }
//             }
//             if (ra1Pay !== ra1Update && ra1Pay !== 0) {
//                 ra1Pay = payload.ra1 + ':00';
//                 if (ra1Update !== 0) {
//                     chamString = chamString.replace(ra1Update, ra1Pay);
//                 } else {
//                     chamString = chamString === '' ? +ra1Pay : chamString + ',' + ra1Pay;
//                 }
//             }

//             let datapayloadTmp =
//                 {
//                     NhanvienId: dataChamCong.NhanvienId,
//                     BangchotcongId: Number(dataChamCong.BangchotcongId),
//                     day: dataChamCong.day,
//                     year: year,
//                     month: month,
//                     date: date,
//                     vao1: vao1Pay,
//                     ra1: ra1Pay,
//                     vao2: payload.vao2 === '' ? 0 : payload.vao2,
//                     ra2: payload.ra2 === '' ? 0 : payload.ra2,
//                     cham: `${chamString}`,

//                     tonggio: payload.tonggio,
//                     cong: payload.cong,
//                     nguoitao: 36,
//                     ghichu: payload.ghichu
//                 }

//             datapayloadTmp = Object.assign({id: dataChamCong.idBangCong, TrangthaiId: dataEdit}, datapayloadTmp);

//             let datapayload = {
//                 "data": [
//                     datapayloadTmp
//                 ]
//             }
//             // console.log('datapayload', datapayload)
//             dispatch(actions.EditBangchamcong(datapayload))
//             setShow(true)
//         }
//     }
//     const validate = (payload) => {
//         let invalids = 0
//         let fields = Object.entries(payload)
//         fields.forEach(item => {
//             if (validateBangChamCong(item[0], item[1]) !== undefined) {
//                 setInvalidFields(prev => [...prev, validateBangChamCong(item[0], item[1])])
//                 invalids++
//             }
//         })
//         return invalids
//     }
//     return (
//         <Modal
//             show={show}
//             onHide={setShow}
//             dialogClassName="modal-90w"
//             aria-labelledby="example-custom-modal-styling-title"
//         >
//             <Modal.Header closeButton>
//                 <Modal.Title id="example-custom-modal-styling-title">
//                     Cập nhật công
//                 </Modal.Title>
//             </Modal.Header>
//             <Modal.Body>
//                 <div className="row">
//                     <div className="form-group col-md-3">
//                         <InputForm
//                             setInvalidFields={setInvalidFields}
//                             invalidFields={invalidFields}
//                             label={'Vào 1'}
//                             value={payload.vao1}
//                             disabled={payload.is_edit !== 0}
//                             setValue={setPayload}
//                             keyPayload={'vao1'}
//                             type='time'
//                         />
//                     </div>
//                     <div className="form-group col-md-3">
//                         <InputForm
//                             setInvalidFields={setInvalidFields}
//                             invalidFields={invalidFields}
//                             label={'Ra 1'}
//                             disabled={payload.is_edit !== 0}
//                             value={payload.ra1}
//                             setValue={setPayload}
//                             keyPayload={'ra1'}
//                             type='time'
//                         />
//                     </div>

//                     <div className="form-group col-md-3">
//                         <InputForm
//                             setInvalidFields={setInvalidFields}
//                             invalidFields={invalidFields}
//                             label={'Tổng giờ'}
//                             disabled={payload.is_edit !== 0}
//                             value={payload.tonggio}
//                             setValue={setPayload}
//                             keyPayload={'tonggio'}
//                             type='integer'
//                         />
//                     </div>
//                     <div className="form-group col-md-3 ">
//                         <InputForm
//                             setInvalidFields={setInvalidFields}
//                             invalidFields={invalidFields}
//                             label={'Tổng công'}
//                             disabled={payload.is_edit !== 0}
//                             value={payload.cong}
//                             setValue={setPayload}
//                             keyPayload={'cong'}
//                             type='integer'
//                         />
//                     </div>

//                     <div className="form-group col-md-12 ">
//                         <InputForm
//                             setInvalidFields={setInvalidFields}
//                             invalidFields={invalidFields}
//                             label={'Ghi chú về cập nhật công'}
//                             value={payload.ghichu}
//                             disabled={payload.is_edit !== 0}
//                             setValue={setPayload}
//                             keyPayload={'ghichu'}
//                             type='text'
//                         />
//                     </div>
//                     <div className="form-group col-md-12 ">
//                         <p className="fw-bold text-primary">{title}<i className={"text-danger"}>(Dữ liệu gốc)</i></p>
//                         <p className="">
//                             {recordChamCong?.length > 0 ? recordChamCong?.map((item, index) => {
//                                 return (
//                                     <span key={index}> {item} {index + 1 < recordChamCong?.length ? '|' : '' } </span>
//                                 );
//                             }) : null}
//                         </p>
//                     </div>

//                 </div>

//                 <div className='col-12 mt-5'>
//                     <Button
//                         text='Cập nhật'
//                         disabled={payload.is_edit !== 0}
//                         bClass='btn btn-primary px-5'
//                         textColor
//                         onClick={handleSubmit}
//                     />
//                 </div>
//             </Modal.Body>
//         </Modal>
//     );
// }

// export default ModalEditCong;
