import React from 'react';
import Search from '~/layouts/components/Menu/Search';

function Menu({ e }) {
    return (
        <div className="header-right">
            <div className="header__navigation menu-style-four d-none d-xl-block">
                <nav className="navigation-menu">
                    <ul>
                        <li className="has-children has-children--multilevel-submenu">
                            <a href="#"><span>Home</span></a>
                            <ul className="submenu">
                                <li><a href="index-infotechno.html"><span>Infotechno</span></a></li>
                                <li><a href="index-processing.html"><span>Processing</span></a></li>
                            </ul>
                        </li>
                        <li className="has-children has-children--multilevel-submenu">
                            <a href="#"><span>Company</span></a>
                            <ul className="submenu">
                                <li className="has-children">
                                    <a href="about-us-01.html"><span>About us</span></a>
                                    <ul className="submenu">
                                        <li><a href="about-us-01.html"><span>About us 01</span></a></li>
                                        <li><a href="about-us-02.html"><span>About us 02</span></a></li>
                                        <li className="has-children">
                                            <a href="#"><span>Submenu Level Two</span></a>
                                            <ul className="submenu">
                                                <li><a href="#"><span>Submenu Level Three</span></a></li>
                                                <li><a href="#"><span>Submenu Level Three</span></a></li>
                                                <li><a href="#"><span>Submenu Level Three</span></a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li><a href="contact-us.html"><span>Contact us</span></a></li>
                                <li><a href="leadership.html"><span>Leadership</span></a></li>
                            </ul>
                        </li>
                        <li className="has-children has-children--multilevel-submenu">
                            <a href="#"><span>IT solutions</span></a>
                            <ul className="submenu">
                                <li><a href="it-services.html"><span>IT Services</span></a></li>
                                <li><a href="managed-it-service.html"><span>Managed IT Services</span></a></li>
                                <li><a href="industries.html"><span>Industries</span></a></li>
                                <li><a href="business-solution.html"><span>Business solution</span></a></li>
                                <li><a href="it-services-details.html"><span>IT Services Details</span></a></li>
                            </ul>
                        </li>
                        <li className="has-children">
                            <a href="#"><span>Elements</span></a>
                            <ul className="megamenu megamenu--mega">
                                <li>
                                    <h2 className="page-list-title">ELEMENT GROUP 01</h2>
                                    <ul>
                                        <li><a href="element-accordion.html"><span>Accordions &amp; Toggles</span></a></li>
                                        <li><a href="element-box-icon.html"><span>Box Icon</span></a></li>
                                        <li><a href="element-box-image.html"><span>Box Image</span></a></li>
                                        <li><a href="element-box-large-image.html"><span>Box Large Image</span></a></li>
                                    </ul>
                                </li>
                                <li>
                                    <h2 className="page-list-title">ELEMENT GROUP 02</h2>
                                    <ul>
                                        <li><a href="element-counters.html"><span>Counters</span></a></li>
                                        <li><a href="element-dividers.html"><span>Dividers</span></a></li>
                                        <li><a href="element-flexible-image-slider.html"><span>Flexible image slider</span></a></li>
                                        <li><a href="element-google-map.html"><span>Google Map</span></a></li>
                                    </ul>
                                </li>
                                <li>
                                    <h2 className="page-list-title">ELEMENT GROUP 03</h2>
                                    <ul>
                                        <li><a href="element-popup-video.html"><span>Popup Video</span></a></li>
                                        <li><a href="element-pricing-box.html"><span>Pricing Box</span></a></li>
                                        <li><a href="element-progress-bar.html"><span>Progress Bar</span></a></li>
                                    </ul>
                                </li>
                                <li>
                                    <h2 className="page-list-title">ELEMENT GROUP 04</h2>
                                    <ul>
                                        <li><a href="element-testimonials.html"><span>Testimonials</span></a></li>
                                        <li><a href="element-timeline.html"><span>Timeline</span></a></li>
                                        <li><a href="element-carousel-sliders.html"><span>Carousel Sliders</span></a></li>
                                        <li><a href="element-typed-text.html"><span>Typed Text</span></a></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="has-children has-children--multilevel-submenu">
                            <a href="#"><span>Case Studies</span></a>
                            <ul className="submenu">
                                <li><a href="case-studies.html"><span>Case Studies 01</span></a></li>
                                <li><a href="case-studies-02.html"><span>Case Studies 02</span></a></li>
                                <li><a href="single-smart-vision.html"><span>Single Layout</span></a></li>
                            </ul>
                        </li>
                        <li className="has-children has-children--multilevel-submenu">
                            <a href="blog-list-large-image.html"><span>Blog</span></a>
                            <ul className="submenu left">
                                <li><a href="blog-list-large-image.html"><span>List Large Image</span></a></li>
                                <li><a href="blog-list-left-large-image.html"><span>Left Large Image</span></a></li>
                                <li><a href="blog-grid-classic.html"><span>Grid Classic</span></a></li>
                                <li><a href="blog-grid-masonry.html"><span>Grid Masonry</span></a></li>
                                <li className="has-children">
                                    <a href="blog-post-layout-one.html"><span>Single Layouts</span></a>
                                    <ul className="submenu left">
                                        <li><a href="blog-post-layout-one.html"><span>Left Sidebar</span></a></li>
                                        <li><a href="blog-post-right-sidebar.html"><span>Right Sidebar</span></a></li>
                                        <li><a href="blog-post-no-sidebar.html"><span>No Sidebar</span></a></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>

            <Search />

            <div className="mobile-navigation-icon white-md-icon d-block d-xl-none"
                id="mobile-menu-trigger">
                <i></i>
            </div>
        </div>
    );
}

export default Menu;
