import axiosConfig from '../axiosConfig'
import { toast } from "react-toastify";

export const apiGetNghiphepcongtac = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlynhanvien/nghiphep-congtac/duyetphep`,
            params: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiNewNghiphepcongtac = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlynhanvien/nghiphep-congtac/duyetphep',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
        toast.error(error?.response?.data?.mes)
    }
})
export const apiEditNghiphepcongtac = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlynhanvien/nghiphep-congtac/duyetphep',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
        toast.error(error?.response?.data?.mes)
    }
})
export const apiDuyetphep = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlynhanvien/duyetphep',
            data: payload,
        })
        resolve(response)

    } catch (error) {
        reject(error)
        // toast.error(error?.response?.data?.mes)
    }
})
export const apiDeleteNghiphepcongtac = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlynhanvien/nghiphep-congtac/duyetphep',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
        toast.error(error?.response?.data?.mes)
    }
})

export const apiGetLoaiNghiPhepCongTac = () => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlyhethong/loainghiphepcongtac`,
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
export const apiNewLoaiNghiPhepCongTac = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: `/api/v2/quanlyhethong/loainghiphepcongtac`,
            data: payload
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
export const apiEditLoaiNghiPhepCongTac = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: `/api/v2/quanlyhethong/loainghiphepcongtac`,
            data: payload
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
export const apiDeleteLoaiNghiPhepCongTac = (payload) => new Promise(async (resolve, reject) => {
    // console.log('payload',payload)
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlyhethong/loainghiphepcongtac',
            data: payload
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
        toast.error(error.response.data.mes)
    }
})