import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { Column } from '@ant-design/plots';

import { each, groupBy } from '@antv/util';
import { chartMulColumn } from '~/components/partials/charts/analytics/AnalyticsData';

const ColumnMulChart = (dataChart) => {
    // console.log('columnChart',dataChart.columnMulChart)
    // console.log('dataChart',dataChart)
    // return false
    const [data, setData] = useState([]);
    useEffect(() => {
        if (dataChart?.columnMulChart?.length > 0) {
            setData(dataChart.columnMulChart);
        }
    }, [dataChart.columnMulChart]);
    // const annotations = [];
    // each(groupBy(data, 'year'), (values, k) => {
    //     const value = values.reduce((a, b) => a + b.value, 0);
    //     annotations.push({
    //         type: 'text',
    //         position: [k, value],
    //         content: `${value}`,
    //         style: {
    //             textAlign: 'center',
    //             fontSize: 14,
    //             fill: 'rgba(0,0,0,0.85)',
    //         },
    //         offsetY: -10,
    //     });
    // });
    const config = {
        data,
        isGroup: true,
        xField: 'month',
        yField: 'value',
        seriesField: 'name',
        // legend: {
        //     position: 'top-left',
        //     top: '100',
        //     paddingBottom: 1000,
        //     paddingTop: 1000,
        // },
    };

    return (
        <React.Fragment>
            <Column {...config} />
        </React.Fragment>
    );
};

export default ColumnMulChart;
