import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputForm } from '~/components/Form';
import * as actions from '~/store/actions';
import '../Modal.scss';
import Select from 'react-select';
import { validatePheDuyet } from '~/request/validatePheDuyet';
import { SelectLoaiquytrinh } from '~/utils/Datacustom';
import Content from '~/layout/content/Content';
import { Input, Modal, ModalBody, Table } from 'reactstrap';
import { Button, Icon } from '~/components/Component';
import { formatVietnameseToString } from '~/utils/Common/formatVietnameseToString';

const ModalEditCauhinhpheduyet = ({ show, setShow, dataIdCauhinhpheduyet, fieldList }) => {
    //   console.log('dataIdCauhinhpheduyet',dataIdCauhinhpheduyet)
    const dispatch = useDispatch();
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayload] = useState(fieldList);
    const [form, setForm] = useState([]);
    useEffect(() => {
        let type = SelectLoaiquytrinh.find((items) => {
            return items && items.value === Number(dataIdCauhinhpheduyet?.type_duyet);
        });
        show &&
            dataIdCauhinhpheduyet &&
            setPayload({
                title: dataIdCauhinhpheduyet?.title || '',
                mota: dataIdCauhinhpheduyet?.mota,
                type_duyet: type,
            });
        setForm(dataIdCauhinhpheduyet?.arrpheduyet ? JSON.parse(dataIdCauhinhpheduyet?.arrpheduyet) : []);
    }, [dataIdCauhinhpheduyet, show]);

    useEffect(() => {
        dispatch(actions.getUser());
    }, []);
    const { userall } = useSelector((state) => state.app);
    const [listUserAll, setlistUserAll] = useState([]);
    useEffect(() => {
        if (userall) {
            const dataSelectUserall = userall.map((item) => ({ label: item.username, value: item.id }));
            setlistUserAll(dataSelectUserall);
        }
    }, [userall]);
    const handleSubmit = async () => {
        let invalids = validate(payload);
        const invalidsQT = validateQT(form);
        if (invalids === 0 && invalidsQT === 0) {
            let datapayload = {
                data: [
                    {
                        id: dataIdCauhinhpheduyet.id,
                        title: payload.title,
                        arrpheduyet: form.length > 0 ? JSON.stringify(form) : null,
                        mota: payload.mota,
                        type_duyet: payload.type_duyet.value,
                    },
                ],
            };
            //   console.log('check payload', datapayload)
            dispatch(actions.EditCauhinhpheduyet(datapayload));
        }
    };
    const validateQT = (arr) => {
        let newArray = {};
        arr.forEach((item, index) => {
            newArray[`Nhanvien${index}`] = item.Nhanvien.length > 0 ? item.Nhanvien : '';
        });
        let invalids = 0;
        let fields = Object.entries(newArray);
        fields.forEach((item) => {
            if (item[1] === '') {
                setInvalid((prev) => [
                    ...prev,
                    {
                        name: item[0],
                        message: 'Bạn không được bỏ trống trường này.',
                    },
                ]);
                invalids++;
            }
        });

        return invalids;
    };
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayload((prev) => ({ ...prev, [statename]: selected }));
    };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validatePheDuyet(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validatePheDuyet(item[0], item[1])]);
                invalids++;
            }
        });
        return invalids;
    };

    const [invalid, setInvalid] = useState([]);
    const handleChange = (i, e) => {
        let newFormValues = [...form];
        newFormValues[i][e.target.name] = e.target.value;
        setForm(newFormValues);
    };

    const addFormFields = () => {
        let mang = [
            ...form,
            {
                Nhanvien: [],
                songay: '',
            },
        ];
        setForm(mang);
    };

    const removeFormFields = (i) => {
        let newFormValues = [...form];
        newFormValues.splice(i, 1);
        setForm(newFormValues);
    };
    // const [idTC, setIdTC] = useState(null);
    // console.log(listNhanvien)
    // useEffect(() => {
    //     const updatedFormValues = [...form];
    //     updatedFormValues[idTC] = {
    //         ...updatedFormValues[idTC],
    //         Nhanvien: listNhanvien.length > 0 ? listNhanvien : '',
    //     };
    //     setForm(updatedFormValues);
    // }, [listNhanvien]);

    const handleSelectChange = (index, selected) => {
        const updatedFormValues = [...form];
        updatedFormValues[index] = {
            ...updatedFormValues[index],
            Nhanvien: selected,
        };
        setForm(updatedFormValues);
    };

    return (
        <React.Fragment>
            <Content>
                <Modal isOpen={show} toggle={setShow} className="modal-dialog-centered modal-90w">
                    <ModalBody>
                        <Icon name="cross-sm float-end fs-2" onClick={setShow}></Icon>
                        <h5 className="title mb-5">Thêm mới</h5>
                        <div className="row">
                            <div className="form-group col-md-4">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Nội dung'}
                                    value={payload.title}
                                    setValue={setPayload}
                                    keyPayload={'title'}
                                    type="text"
                                    validate
                                />
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="inputZip" className="mb-2 fw-bold">
                                    <span>Mã</span>
                                </label>
                                <input
                                    type="text"
                                    name="code"
                                    className="form-control"
                                    value={dataIdCauhinhpheduyet.code || ''}
                                    disabled
                                />
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="inputZip" className="mb-2 fw-bold">
                                    <span>
                                        Áp dụng <b className="text-danger">(*)</b>
                                    </span>
                                </label>
                                <Select
                                    value={payload.type_duyet}
                                    onChange={handleOnSelect}
                                    options={SelectLoaiquytrinh}
                                    placeholder={'Áp dụng cho'}
                                    name="type_duyet"
                                    onFocus={() => setInvalidFields([])}
                                />
                                {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'type_duyet') && (
                                    <small className="text-danger">
                                        {invalidFields.find((i) => i.name === 'type_duyet')?.message}
                                    </small>
                                )}
                            </div>
                            <div className="form-group col-md-12">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Mô tả'}
                                    value={payload.mota}
                                    setValue={setPayload}
                                    keyPayload={'mota'}
                                    type="text"
                                    validate
                                />
                            </div>
                        </div>
                        <div className="mt-3">
                            <div className="form-group col-md-12">
                                <div className="pb-0">
                                    <h6 className="text-primary">Quy trình duyệt phiếu</h6>
                                    <Button
                                        size="md"
                                        color="light"
                                        outline
                                        className="btn-white btn-dim btn-icon px-4 mt-2"
                                        onClick={() => addFormFields()}
                                    >
                                        Thêm quy trình <Icon name="plus"></Icon>
                                    </Button>
                                </div>
                            </div>
                            {form?.length > 0 && (
                                <div style={{ overflowY: 'hidden' }}>
                                    <Table striped bordered hover vertical>
                                        <thead>
                                            <tr>
                                                <th className="align-middle text-center">
                                                    <span className="sub-text">Cấp duyệt</span>
                                                </th>
                                                <th className="align-middle text-center">
                                                    <span className="sub-text w-150px">Người duyệt</span>
                                                </th>
                                                <th className="align-middle text-center">
                                                    <span className="sub-text w-120px">Hạn phê duyệt</span>
                                                </th>

                                                <th className="nk-tb-col-tools text-end"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {form?.length > 0
                                                ? form.map((item, it) => (
                                                      <React.Fragment key={it}>
                                                          <tr>
                                                              <td>
                                                                  <span className="sub-text text-center">{it + 1}</span>
                                                              </td>

                                                              <td
                                                              //   onClick={(ev) => {
                                                              //       ev.preventDefault();
                                                              //       setShowSelect(true);
                                                              //       setIdTC(it);
                                                              //   }}
                                                              >
                                                                  <span className="sub-text">
                                                                      <Select
                                                                          value={item.Nhanvien}
                                                                          onChange={(selected) =>
                                                                              handleSelectChange(it, selected)
                                                                          }
                                                                          isMulti
                                                                          options={listUserAll}
                                                                          placeholder={'Chọn người duyệt'}
                                                                          name="Nhanvien"
                                                                          menuPosition="fixed"
                                                                          onFocus={() => setInvalid([])}
                                                                      />
                                                                      {invalid.length > 0 &&
                                                                          invalid.some(
                                                                              (i) => i.name === `Nhanvien${it}`,
                                                                          ) && (
                                                                              <small className="text-danger">
                                                                                  {
                                                                                      invalid.find(
                                                                                          (i) =>
                                                                                              i.name ===
                                                                                              `Nhanvien${it}`,
                                                                                      )?.message
                                                                                  }
                                                                              </small>
                                                                          )}
                                                                  </span>
                                                              </td>
                                                              <td>
                                                                  <span className="sub-text">
                                                                      <input
                                                                          type="number"
                                                                          name="songay"
                                                                          className="form-control"
                                                                          value={item.songay || ''}
                                                                          onChange={(e) => handleChange(it, e)}
                                                                          onFocus={() => setInvalid([])}
                                                                      />
                                                                      {invalid.length > 0 &&
                                                                          invalid.some(
                                                                              (i) => i.name === `songay_${it}`,
                                                                          ) && (
                                                                              <small className="text-danger">
                                                                                  {
                                                                                      invalid.find(
                                                                                          (i) =>
                                                                                              i.name === `songay_${it}`,
                                                                                      )?.message
                                                                                  }
                                                                              </small>
                                                                          )}
                                                                  </span>
                                                              </td>
                                                              <td>
                                                                  <span className="sub-text nk-tb-col-tools text-end">
                                                                      {' '}
                                                                      <ul className="nk-tb-actions gx-4">
                                                                          <>
                                                                              <button
                                                                                  onClick={() => removeFormFields(it)}
                                                                                  className={
                                                                                      'btn btn-danger  badge text-white p-1'
                                                                                  }
                                                                              >
                                                                                  <em className="icon ni ni-trash-alt"></em>
                                                                              </button>
                                                                          </>
                                                                      </ul>
                                                                  </span>
                                                              </td>
                                                          </tr>
                                                      </React.Fragment>
                                                  ))
                                                : null}
                                        </tbody>
                                    </Table>
                                </div>
                            )}
                        </div>

                        <div className="col-12 mt-5">
                            <Button color="primary" className="btn-icon px-4 mt-2" onClick={handleSubmit}>
                                Thêm mới
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
            </Content>

            {/* <SelectFinNhanvien
            showSelect={showSelect}
            typeSelect={true}
            listNhanvien={[]}
            setlistNhanvien={setlistNhanvien}
            setShowSelect={() => setShowSelect(false)}
        /> */}
        </React.Fragment>
    );
};

export default ModalEditCauhinhpheduyet;
