import React, { useState, useEffect } from "react";
import Content from "~/layout/content/Content";
import { Link, Navigate, useLocation } from "react-router-dom";
import { Icon, UserAvatar } from "~/components/Component";
import { findUpper } from "~/utils/Utils";
import { Card, DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import config from '~/config';
import { useSelector } from 'react-redux'
const UserProfileLayout = ({ children }) => {
  const { user } = useSelector(state => state.app)
  // console.log('user', user)
  const [setusser, SetUser] = useState('0');
  useEffect(() => {
    SetUser(user?.phanquyen);
  }, [user]);

  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [profileName, setProfileName] = useState("A");
  // function to change the design view under 990 px
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document.getElementsByClassName("nk-header")[0].addEventListener("click", function () {
      updateSm(false);
    });
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);
  const location = useLocation();
  if (setusser !== '0' && setusser !== undefined) {
    const active = location?.pathname?.split('/');
    if(active[2] === config.module_user || active[2] === config.function_user || active[2] === config.user_list_all){
      return <Navigate to={`*`} replace={true}/>
      // console.log('aaaaaaaaaaaaaaa')
    }
  }
  // return <Navigate to={`*`} replace={true}/>

  return (
    <React.Fragment>
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${sm ? "content-active" : ""
                }`}
            >
              <div className="card-inner-group">
                <div className="card-inner">
                  <div className="user-card">
                    <UserAvatar text={findUpper(profileName)} theme="primary" />
                    <div className="user-info">
                      <span className="lead-text">{user.username}</span>
                      <span className="sub-text">{user.email}</span>
                    </div>
                    <div className="user-action">
                      <UncontrolledDropdown>
                        <DropdownToggle tag="a" className="btn btn-icon btn-trigger me-n2">
                          <Icon name="more-v"></Icon>
                        </DropdownToggle>
                        <DropdownMenu end>
                          <ul className="link-list-opt no-bdr">
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <Icon name="camera-fill"></Icon>
                                <span>Thay đổi hình ảnh</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <Icon name="edit-fill"></Icon>
                                <span>Cập nhật hồ sơ</span>
                              </DropdownItem>
                            </li>
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
               
                <div className="card-inner p-0">
                  <ul className="link-list-menu">
                    <li onClick={() => updateSm(false)}>
                      <Link
                        to={`${ config.he_thong + config.user_profile_regular}`}
                        className={
                          window.location.pathname === `${ config.he_thong + config.user_profile_regular}` ? "active" : ""
                        }
                      >
                        <Icon name="user-fill-c"></Icon>
                        <span>Thông tin cá nhân</span>
                      </Link>
                    </li>
                    {/* <li onClick={() => updateSm(false)}>
                      <Link
                        to={`${ config.he_thong + config.user_profile_notification}`}
                        className={
                          window.location.pathname === `${ config.he_thong + config.user_profile_notification}`
                            ? "active"
                            : ""
                        }
                      >
                        <Icon name="bell-fill"></Icon>
                        <span>Thông báo</span>
                      </Link>
                    </li> */}

                    {/* <li >
                      <Link
                        // to={`${process.env.PUBLIC_URL}/he-thong/user-profile-setting`}
                        to={`${ config.he_thong + config.user_profile_setting}`}
                        className={
                          window.location.pathname === `${ config.he_thong + config.user_profile_setting}` ? "active" : ""
                        }
                      >
                        <Icon name="lock-alt-fill"></Icon>
                        <span>Cài đặt và bảo mật</span>
                      </Link>
                    </li> */}
                    
                    {+user.phanquyen === 0 &&  <Link
                        to={`${ config.he_thong + config.module_user}`}
                        className={
                          window.location.pathname === `${ config.he_thong + config.module_user}` ? "active" : ""
                        }
                      >
                        <Icon name="lock-alt-fill"></Icon>
                        <span>Module User</span>
                      </Link>
                      }
                    {+user.phanquyen === 0 &&  <Link
                        to={`${ config.he_thong + config.function_user}`}
                        className={
                          window.location.pathname === `${ config.he_thong + config.function_user}` ? "active" : ""
                        }
                      >
                        <Icon name="lock-alt-fill"></Icon>
                        <span>Quản lý vai trò</span>
                      </Link>
                      }
                    {+user.phanquyen === 0 &&  <Link
                        to={`${ config.he_thong + config.user_list_all}`}
                        className={
                          window.location.pathname === `${ config.he_thong + config.user_list_all}` ? "active" : ""
                        }
                      >
                        <Icon name="lock-alt-fill"></Icon>
                        <span>Quản lý người dùng</span>
                      </Link>
                      }
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-inner card-inner-lg">
              {sm && mobileView && <div className="toggle-overlay" onClick={() => updateSm(!sm)}></div>}
              {children}
            </div>
          </div>
        </Card>
      </Content>
    </React.Fragment>
  );
};

export default UserProfileLayout;
