import axiosConfig from '../axiosConfig'

export const apiGetLoaiphuongtien = () => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlyphuongtien/loaiphuongtien`,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiNewLoaiphuongtien = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlyphuongtien/loaiphuongtien',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiEditLoaiphuongtien = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlyphuongtien/loaiphuongtien',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiDeleteLoaiphuongtien = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlyphuongtien/loaiphuongtien',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
