import actionTypes from './actionTypes'
import { apiGetHopdonglaodong, apiNewHopdonglaodong, apiEditHopdonglaodong, apiDeleteHopdonglaodong, 
    apiGetLoaihopdong, apiNewLoaihopdong, apiEditLoaihopdong, apiDeleteLoaihopdong,
    apiGetMauhopdong, apiNewMauhopdong, apiEditMauhopdong, apiDeleteMauhopdong
 } from '~/services/hopdonglaodong'
import { toast } from "react-toastify";
export const getListHopdonglaodong = (query) => async (dispatch) => {
    try {
        const response = await apiGetHopdonglaodong(query)
        // console.log('chek api lisst Hopdonglaodong', response)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_HOP_DONG_LAO_DONG,
                hopdonglaodong: response?.data?.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_HOP_DONG_LAO_DONG,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_HOP_DONG_LAO_DONG,
            hopdonglaodong: null
        })
    }
}

export const NewHopdonglaodong = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewHopdonglaodong', payload)
            const response = await apiNewHopdonglaodong(payload)
            // console.log('chek api apiNewHopdonglaodong', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_HOP_DONG_LAO_DONG,
                    msg: 'OK'
                });
                dispatch(getListHopdonglaodong({ page: '1', limit: '10', order: 'desc', by: 'id' }));
                toast.success("Thêm mới hợp đồng lao động thành công!")
            }else if(response?.data.success === 401){
                toast.error("Hợp đồng đã tồn tại!")
            }else {
                dispatch({
                    type: actionTypes.POST_NEW_HOP_DONG_LAO_DONG,
                    msg: 'ERR'
                })
                toast.error("Thêm mới hợp đồng lao động thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_HOP_DONG_LAO_DONG,
                hopdonglaodong: null
            })
        }
    }
}
export const EditHopdonglaodong = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditHopdonglaodong(payload)
            // console.log('chek api apiEditHopdonglaodong', response)
            if (response?.data.success === 200) {
                dispatch(getListHopdonglaodong({ page: '1', limit: '10', order: 'desc', by: 'id' }));
                toast.success("Sửa thông tin hợp đồng lao động thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_HOP_DONG_LAO_DONG,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_HOP_DONG_LAO_DONG,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin hợp đồng lao động thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_HOP_DONG_LAO_DONG,
                hopdonglaodong: null
            })
        }
    }
}
export const DeleteHopdonglaodong = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteHopdonglaodong(payload)
            // console.log('chek api apiDeleteHopdonglaodong', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListHopdonglaodong({ page: '1', limit: '10', order: 'desc', by: 'id' }));
                toast.success("Xóa thông tin hợp đồng lao động thành công!")
                dispatch({
                    type: actionTypes.DELETE_HOP_DONG_LAO_DONG,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_HOP_DONG_LAO_DONG,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin hợp đồng lao động thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_HOP_DONG_LAO_DONG,
                hopdonglaodong: null
            })
        }
    }
}
export const getListLoaihopdong = () => async (dispatch) => {
    try {
        const response = await apiGetLoaihopdong()
        // console.log('chek api lisst Loaihopdong', response?.data.success)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_LOAI_HOP_DONG,
                loaihopdong: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_LOAI_HOP_DONG,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_LOAI_HOP_DONG,
            loaihopdong: null
        })
    }
}

export const NewLoaihopdong = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewLoaihopdong', payload)
            const response = await apiNewLoaihopdong(payload)
            // console.log('chek api apiNewLoaihopdong', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_LOAI_HOP_DONG,
                    msg: 'OK'
                });
                dispatch(getListLoaihopdong());
                toast.success("Thêm mới loại hợp đồng thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_LOAI_HOP_DONG,
                    msg: 'ERR'
                })
                toast.error("Thêm mới loại hợp đồng thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_LOAI_HOP_DONG,
                loaihopdong: null
            })
        }
    }
}
export const EditLoaihopdong = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditLoaihopdong(payload)
            // console.log('chek api apiEditLoaihopdong', response)
            if (response?.data.success === 200) {
                dispatch(getListLoaihopdong());
                toast.success("Sửa thông tin loại hợp đồng thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_LOAI_HOP_DONG,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_LOAI_HOP_DONG,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin loại hợp đồng thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_LOAI_HOP_DONG,
                loaihopdong: null
            })
        }
    }
}
export const DeleteLoaihopdong = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteLoaihopdong(payload)
            // console.log('chek api apiDeleteLoaihopdong', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListLoaihopdong());
                toast.success("Xóa thông tin loại hợp đồng thành công!")
                dispatch({
                    type: actionTypes.DELETE_LOAI_HOP_DONG,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_LOAI_HOP_DONG,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin loại hợp đồng thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_LOAI_HOP_DONG,
                loaihopdong: null
            })
        }
    }

}
export const getListMauhopdong = () => async (dispatch) => {
    try {
        const response = await apiGetMauhopdong()
        // console.log('chek api lisst Mauhopdong', response?.data.success)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_MAU_HOP_DONG,
                mauhopdong: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_MAU_HOP_DONG,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_MAU_HOP_DONG,
            mahopdong: null
        })
    }
}

export const NewMauhopdong = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewMauhopdong', payload)
            const response = await apiNewMauhopdong(payload)
            // console.log('chek api apiNewMauhopdong', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_MAU_HOP_DONG,
                    msg: 'OK'
                });
                dispatch(getListMauhopdong());
                toast.success("Thêm mới thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_MAU_HOP_DONG,
                    msg: 'ERR'
                })
                toast.error("Thêm mới thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_MAU_HOP_DONG,
                mahopdong: null
            })
        }
    }
}
export const EditMauhopdong = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditMauhopdong(payload)
            // console.log('chek api apiEditMauhopdong', response)
            if (response?.data.success === 200) {
                dispatch(getListMauhopdong());
                toast.success("Sửa thông tin thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_MAU_HOP_DONG,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_MAU_HOP_DONG,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_MAU_HOP_DONG,
                mahopdong: null
            })
        }
    }
}
export const DeleteMauhopdong = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteMauhopdong(payload)
            console.log('chek api apiDeleteMauhopdong', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListMauhopdong());
                toast.success("Xóa thông tin thành công!")
                dispatch({
                    type: actionTypes.DELETE_MAU_HOP_DONG,
                    msg: 'OK'
                });
               
            } else if (response?.data.success === 502) {
                toast.error("Mẫu hợp đồng đang được sử dụng!")
            } else {
                dispatch({
                    type: actionTypes.DELETE_MAU_HOP_DONG,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_MAU_HOP_DONG,
                mahopdong: null
            })
        }
    }

}