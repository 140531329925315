
import * as excelJs from "exceljs";

const ExportXlsxExcel = ({data, title, namefile, headers}) => {
    return async () => {
        const headerExport = headers
        const workbook = new excelJs.Workbook();
        const ws = workbook.addWorksheet('Hợp đồng mẫu');
        ws.addRow(headerExport);
        data.forEach((item, index) => {
            const keys = Object.keys(item);
            keys.forEach((key, keyIndex) => {
                ws.getCell(index + 2, keyIndex + 1).value = item[key];
            });
        });
        
        ws.columns.map((col, index) => (col.width = 20));
        
        const cellFormatting = {
            font: {
                name: 'Inter',
                size: 8,
            },
            alignment: {
                horizontal: 'center',
            },
        };
        
        ws.eachRow((row) => {
            row.eachCell((cell) => {
                Object.assign(cell, cellFormatting);
            });
        });
        
        const excelBlob = await workbook.xlsx.writeBuffer();
        const excelUrl = URL.createObjectURL(
            new Blob([excelBlob], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
        );
        
        const link = document.createElement('a');
        link.href = excelUrl;
        link.download = `${namefile}.xlsx`;
        document.body.appendChild(link);
        link.click();
        
        URL.revokeObjectURL(excelUrl);
        document.body.removeChild(link);
    }
};
export default ExportXlsxExcel;