import actionTypes from './actionTypes'
import { apiGetMoiquanhenhanvien, apiNewMoiquanhenhanvien, apiEditMoiquanhenhanvien, apiDeleteMoiquanhenhanvien } from '~/services/moiquanhenhanvien'
import { toast } from "react-toastify";
export const getListMoiquanhenhanvien = () => async (dispatch) => {
    try {
        const response = await apiGetMoiquanhenhanvien()
        // console.log('chek api lisst Moiquanhenhanvien', response?.data.success)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_MOI_QUAN_HE_NHAN_VIEN,
                moiquanhenhanvien: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_MOI_QUAN_HE_NHAN_VIEN,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_MOI_QUAN_HE_NHAN_VIEN,
            moiquanhenhanvien: null
        })
    }
}

export const NewMoiquanhenhanvien = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewMoiquanhenhanvien', payload)
            const response = await apiNewMoiquanhenhanvien(payload)
            // console.log('chek api apiNewMoiquanhenhanvien', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_MOI_QUAN_HE_NHAN_VIEN,
                    msg: 'OK'
                });
                dispatch(getListMoiquanhenhanvien());
                toast.success("Thêm mới thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_MOI_QUAN_HE_NHAN_VIEN,
                    msg: 'ERR'
                })
                toast.error("Thêm mới thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_MOI_QUAN_HE_NHAN_VIEN,
                moiquanhenhanvien: null
            })
        }
    }
}
export const EditMoiquanhenhanvien = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditMoiquanhenhanvien(payload)
            // console.log('chek api apiEditMoiquanhenhanvien', response)
            if (response?.data.success === 200) {
                dispatch(getListMoiquanhenhanvien());
                toast.success("Sửa thông tin thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_MOI_QUAN_HE_NHAN_VIEN,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_MOI_QUAN_HE_NHAN_VIEN,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_MOI_QUAN_HE_NHAN_VIEN,
                moiquanhenhanvien: null
            })
        }
    }
}
export const DeleteMoiquanhenhanvien = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteMoiquanhenhanvien(payload)
            // console.log('chek api apiDeleteMoiquanhenhanvien', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListMoiquanhenhanvien());
                toast.success("Xóa thông tin thành công!")
                dispatch({
                    type: actionTypes.DELETE_MOI_QUAN_HE_NHAN_VIEN,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_MOI_QUAN_HE_NHAN_VIEN,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_MOI_QUAN_HE_NHAN_VIEN,
                moiquanhenhanvien: null
            })
        }
    }
}