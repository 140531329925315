import React, { useState, useEffect } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Badge, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, UncontrolledDropdown } from 'reactstrap';
import { Icon, UserAvatar } from '../../../components/Component';
import { findUpper } from '../../../utils/Utils';
import { KanbanTaskForm, KanbanBoardForm } from './KanbanForms';
import { useDispatch } from 'react-redux';
import * as actions from '~/store/actions';
import Swal from 'sweetalert2';

export const KanbanCard = ({ data, setData, card, index, column }) => {
    const [open, setOpen] = useState(false);
    const [taskModal, setTaskModal] = useState(false);
    const dispatch = useDispatch();
    const toggleOpen = () => {
        setOpen(!open);
    };

    const toggleTaskModal = () => {
        setTaskModal(!taskModal);
    };

    const deleteTask = (id) => {
        // let defaultData = data;
        // delete defaultData.task[id];

        // defaultData.columns[column.id].tasks = defaultData.columns[column.id].tasks.filter((item) => item !== id);

        // setData({ ...defaultData });
        Swal.fire({
            title: 'Bạn có chắc không?',
            text: 'Bạn sẽ không thể lấy lại khi xóa!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Không, bỏ qua!',
            confirmButtonText: 'Vâng, xóa nó đi!',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(actions.DeleteGiaoviec(id));
            }
        });
    };
    // console.log('card', card)
    const { id, meta, congviec, Nhanvien, mota, ngaybatdau, ngayketthuc, douutien } = card;
    return (
        <React.Fragment>
            <Draggable draggableId={id} key={id} index={index}>
                {(provided) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="mt-2"
                    >
                        <div className="kanban-item">
                            <div className="kanban-item-title">
                                <h6 className="title">{congviec}</h6>
                                <Dropdown isOpen={open} toggle={toggleOpen}>
                                    <DropdownToggle
                                        tag="a"
                                        href="#toggle"
                                        className="dropdown-toggle"
                                        onClick={(ev) => ev.preventDefault()}
                                    >
                                        <div className="user-avatar-group">
                                            {Object.keys(Nhanvien).map((user, index) => (
                                                <UserAvatar
                                                    key={index}
                                                    className="xs"
                                                    theme={user?.hoten}
                                                    text={user?.hoten}
                                                ></UserAvatar>
                                            ))}
                                        </div>
                                    </DropdownToggle>
                                    <DropdownMenu end>
                                        <ul className="link-list-opt no-bdr p-3 g-2">
                                            {meta?.users?.map((user, index) => (
                                                <li key={index}>
                                                    <div className="user-card" onClick={toggleOpen}>
                                                        <UserAvatar
                                                            className="sm"
                                                            theme={user?.theme}
                                                            text={findUpper(user?.value)}
                                                        ></UserAvatar>
                                                        <div className="user-name">
                                                            <span className="tb-lead">{user?.value}</span>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                            <div className="kanban-item-text">
                                <p>{mota}</p>
                            </div>
                            <ul className="kanban-item-tags">
                                {douutien === '0' ? (
                                    <Badge color="danger">Cao</Badge>
                                ) : douutien === '1' ? (
                                    <Badge color="info">Trung bình</Badge>
                                ) : (
                                    <Badge color="warning">Thấp</Badge>
                                )}
                            </ul>
                            <div className="kanban-item-meta">
                                <ul className="kanban-item-meta-list">
                                    <li>
                                        <Icon name="calendar"></Icon>
                                        <span>{ngaybatdau}</span>
                                    </li>
                                    <li className="text-danger">
                                        <Icon name="calendar"></Icon>
                                        <span>{ngayketthuc}</span>
                                    </li>
                                    {/* <li>
                    <Icon name="notes"></Icon>
                    <span>{meta?.category}</span>
                  </li> */}
                                </ul>
                                <ul className="kanban-item-meta-list">
                                    <UncontrolledDropdown>
                                        <DropdownToggle
                                            tag="a"
                                            href="toggle"
                                            onClick={(ev) => ev.preventDefault()}
                                            className="dropdown-toggle btn btn-xs btn-icon btn-trigger me-n1"
                                        >
                                            <Icon name="more-v"></Icon>
                                        </DropdownToggle>
                                        <DropdownMenu end>
                                            <ul className="link-list-opt no-bdr">
                                                <li>
                                                    <DropdownItem
                                                        tag="a"
                                                        href="#item"
                                                        onClick={(ev) => {
                                                            ev.preventDefault();
                                                            toggleTaskModal();
                                                        }}
                                                    >
                                                        <Icon name="edit"></Icon>
                                                        <span>Sửa nhiệm vụ</span>
                                                    </DropdownItem>
                                                </li>
                                                <li>
                                                    <DropdownItem
                                                        tag="a"
                                                        href="#item"
                                                        onClick={(ev) => {
                                                            ev.preventDefault();
                                                            deleteTask(card?.id);
                                                        }}
                                                    >
                                                        <Icon name="trash"></Icon>
                                                        <span>Xóa nhiệm vụ</span>
                                                    </DropdownItem>
                                                </li>
                                            </ul>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </ul>
                            </div>
                        </div>
                    </div>
                )}
            </Draggable>
            <Modal size="lg" isOpen={taskModal} toggle={toggleTaskModal}>
                <KanbanTaskForm
                    toggle={toggleTaskModal}
                    data={data}
                    setData={setData}
                    editTask={card}
                    taskToBoard={column}
                />
            </Modal>
        </React.Fragment>
    );
};

export const KanbanCardList = ({ data, setData, tasks, column }) => {
    return tasks.length > 0 ? (
        tasks.map((task, index) => {
            const cardv = Object.values(data.task)?.find((items) => items.id === task);
            // const card = data.task[task];
            // console.log('card', card)
            return (
                <KanbanCard card={cardv} data={data} setData={setData} key={cardv.id} index={index} column={column} />
            );
            // tasks.map((task, index) => {
            //   const card = data.task[task];
            //   return <KanbanCard card={card} data={data} setData={setData} key={card.id} index={index} column={column} />;
        })
    ) : (
        <div className="kanban-drag"></div>
    );
};

export const KanbanColumn = ({ data, setData, column, index }) => {
    const [open, setOpen] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const dispatch = useDispatch();
    // console.log('dataccc', data)
    // useEffect(() => {
    //     console.log('datavvv', column.tasks)
    //     console.log('datavvv id', column.id)
    //     const datacolumn = column.id?.split('-');
    //     console.log('datacolumn', datacolumn[1])

    //     column.tasks.map(async (item, i) => {
    //       let datapayload = {
    //         id: Number(item),
    //         GiaoviecbangId: Number(datacolumn[1]),
    //     }
    //     console.log(`datapayload-${i}`, datapayload)
    //     // dispatch(actions.EditGiaoviec(datapayload))
    //     });

    // }, [column.tasks]);

    const toggleModal = () => {
        setOpen(!open);
    };

    const toggleEditModal = () => {
        setEditModal(!editModal);
    };

    const deleteBoard = (item) => {
        // console.log('item', item);
        const datacolumn = item.id?.split('-');
        // console.log('datacolumn', datacolumn)
        // let defaultData = data;
        // delete defaultData.columns[item.id];
        // defaultData.columnOrder = defaultData.columnOrder.filter((el) => el !== item.id);
        // setData({ ...defaultData });
        Swal.fire({
            title: 'Bạn có chắc không?',
            text: 'Bạn sẽ không thể lấy lại khi xóa!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Không, bỏ qua!',
            confirmButtonText: 'Vâng, xóa nó đi!',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(actions.DeleteGiaoviecbang({ id: Number(datacolumn[1]) }));
                item?.tasks.map(async (item, i) => {
                    let datapayload = {
                        id: Number(item),
                    };
                    // console.log(`datapayload-${i}`, datapayload);
                    dispatch(actions.DeleteGiaoviec(datapayload));
                });
            }
        });
    };

    const emptyList = (item) => {
        // let defaultData = data;
        // defaultData.columns[item.id].tasks = [];
        // setData({ ...defaultData });
        console.log('item', item);
        if(item.tasks.length > 0){
          Swal.fire({
            title: 'Bạn có chắc không làm rỗng bảng không?',
            text: 'Bạn sẽ không thể lấy lại khi làm rỗng bảng!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Không, bỏ qua!',
            confirmButtonText: 'Vâng, xóa nó đi!',
        }).then((result) => {
            if (result.isConfirmed) {
                item?.tasks.map(async (item, i) => {
                    let datapayload = {
                        id: Number(item),
                    };
                    // console.log(`datapayload-${i}`, datapayload);
                    dispatch(actions.DeleteGiaoviec(datapayload));
                });
            }
        });
        }else{
          Swal.fire('Hiện tại bảng đã trống!')

        }
    };

    return (
        <React.Fragment>
            <Draggable draggableId={column.id} key={column.id} index={index}>
                {(provided) => (
                    <div className="kanban-board" ref={provided.innerRef} {...provided.draggableProps}>
                        <div className={`kanban-board-header kanban-${column.theme}`} {...provided.dragHandleProps}>
                            <div className="kanban-title-board">
                                <div className="kanban-title-content">
                                    <h6 className="title">{column.text}</h6>
                                    <Badge className="text-dark" pill color="outline-light">
                                        {column.tasks.length}
                                    </Badge>
                                </div>
                                <div className="kanban-title-content">
                                    <UncontrolledDropdown>
                                        <DropdownToggle
                                            tag="a"
                                            href="toggle"
                                            onClick={(ev) => ev.preventDefault()}
                                            className="dropdown-toggle btn btn-sm btn-icon btn-trigger me-n1"
                                        >
                                            <Icon name="more-h"></Icon>
                                        </DropdownToggle>
                                        <DropdownMenu end>
                                            <ul className="link-list-opt no-bdr">
                                                <li>
                                                    <DropdownItem
                                                        tag="a"
                                                        href="#item"
                                                        onClick={(ev) => {
                                                            ev.preventDefault();
                                                            toggleEditModal();
                                                        }}
                                                    >
                                                        <Icon name="edit"></Icon>
                                                        <span>Sửa bảng</span>
                                                    </DropdownItem>
                                                </li>
                                                <li>
                                                    <DropdownItem
                                                        tag="a"
                                                        href="#item"
                                                        onClick={(ev) => {
                                                            ev.preventDefault();
                                                            deleteBoard(column);
                                                        }}
                                                    >
                                                        <Icon name="trash"></Icon>
                                                        <span>Xóa bảng</span>
                                                    </DropdownItem>
                                                </li>
                                                <li>
                                                    <DropdownItem
                                                        tag="a"
                                                        href="#item"
                                                        onClick={(ev) => {
                                                            ev.preventDefault();
                                                            emptyList(column);
                                                        }}
                                                    >
                                                        <Icon name="trash-empty"></Icon>
                                                        <span>Bảng trống</span>
                                                    </DropdownItem>
                                                </li>
                                                <li>
                                                    <DropdownItem
                                                        tag="a"
                                                        href="#item"
                                                        onClick={(ev) => {
                                                            ev.preventDefault();
                                                            toggleModal();
                                                        }}
                                                    >
                                                        <Icon name="plus-sm"></Icon>
                                                        <span>Thêm nhiệm vụ</span>
                                                    </DropdownItem>
                                                </li>
                                            </ul>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </div>
                            </div>
                        </div>
                        <Droppable droppableId={column.id} type="task">
                            {(provided) => (
                                <div className="kanban-drag" {...provided.droppableProps} ref={provided.innerRef}>
                                    <KanbanCardList
                                        data={data}
                                        setData={setData}
                                        tasks={column.tasks}
                                        column={column}
                                    />
                                    <button className="kanban-add-task mt-2 btn btn-block" onClick={toggleModal}>
                                        <Icon name="plus-sm"></Icon>
                                        <span>{column.tasks.length > 0 ? 'Thêm một ' : 'Thêm '} nhiệm vụ</span>
                                    </button>
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>
                )}
            </Draggable>
            <Modal className="modal-dialog-centered modal-90w" size="lg" isOpen={open} toggle={toggleModal}>
                <KanbanTaskForm toggle={toggleModal} data={data} setData={setData} taskToBoard={column} />
            </Modal>

            <Modal className="modal-dialog-centered modal-90w" size="lg" isOpen={editModal} toggle={toggleEditModal}>
                <KanbanBoardForm toggle={toggleEditModal} data={data} setData={setData} editBoard={column} />
            </Modal>
        </React.Fragment>
    );
};
