import actionTypes from './actionTypes'
import { apiGetVanthu, apiNewVanthu, apiEditVanthu, apiDeleteVanthu } from '~/services/vanthu'
import { toast } from "react-toastify";
export const getListVanthu = () => async (dispatch) => {
    try {
        const response = await apiGetVanthu()
        // console.log('chek api lisst Vanthu', response?.data.success)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_VAN_THU,
                vanthu: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_VAN_THU,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_VAN_THU,
            vanthu: null
        })
    }
}

export const NewVanthu = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewVanthu', payload)
            const response = await apiNewVanthu(payload)
            // console.log('chek api apiNewVanthu', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_VAN_THU,
                    msg: 'OK'
                });
                dispatch(getListVanthu());
                toast.success("Thêm mới văn thư thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_VAN_THU,
                    msg: 'ERR'
                })
                toast.error("Thêm mới văn thư thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_VAN_THU,
                vanthu: null
            })
        }
    }
}
export const EditVanthu = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditVanthu(payload)
            // console.log('chek api apiEditVanthu', response)
            if (response?.data.success === 200) {
                dispatch(getListVanthu());
                toast.success("Sửa thông tin văn thư thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_VAN_THU,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_VAN_THU,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin văn thư thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_VAN_THU,
                vanthu: null
            })
        }
    }
}
export const DeleteVanthu = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteVanthu(payload)
            // console.log('chek api apiDeleteVanthu', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListVanthu());
                toast.success("Xóa thông tin văn thư thành công!")
                dispatch({
                    type: actionTypes.DELETE_VAN_THU,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_VAN_THU,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin văn thư thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_VAN_THU,
                vanthu: null
            })
        }
    }
}