import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { InputForm } from '~/components/Form';
import * as actions from '~/store/actions';
import { Input } from 'reactstrap';
import '../Modal.scss';
import {
    Button,
    Col,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    Icon,
    PaginationComponent,
    Row,
    RSelect,
    TooltipComponent,
    ExportXlsx,
    ImportXlsx,
    PaginationCount,
} from '~/components/Component';
import { Modal, ModalBody } from 'reactstrap';
import Select from 'react-select';
import { validateTaiSan } from '~/request/validateTaiSan';
import { nhomTaisan, trangthaiTaisan } from '~/utils/Datacustom';
import { NumericFormat } from 'react-number-format';
import ModalCtb from './ModalCtb';
import { apiGetPhucapByNhanVien, apiGetTaisanNhanvien, apiListGetLuong } from '~/services';
import { format, parseISO } from 'date-fns';
import { matusinhResult } from '~/utils/Utils';

const ModalEditTaisan = ({ show, setShow, dataIdTaisan, fieldList, handleEditDetail, setHandleEditDetail }) => {
    // console.log(phongban)
    const [showCtb, setShowCtb] = useState(false);
    const [showHandleCtb, setHandleCtb] = useState(null);
    // console.log('showHandleCtb', showHandleCtb)
    const dispatch = useDispatch();
    const [invalidFields, setInvalidFields] = useState([]);
    const [dataTaisanHtr, setdataTaisanHtr] = useState([]);
    // console.log('dataTaisanHtr', dataTaisanHtr)
    const [payload, setPayloadTaisan] = useState(fieldList);
    const [payloadKhauhao, setPayloadKhauhao] = useState({
        nguyengia: '',
        khauhao: '',
        conlai: '',
    });
    useEffect(() => {
        const fetchData = async () => {
            // Use the built-in Date methods to get the start and end of the month
            try {
                let dataTsnv = await apiGetTaisanNhanvien();
                let dataTaisanCapPhat = dataTsnv?.data?.data?.rows;
                setdataTaisanHtr(dataTaisanCapPhat?.filter((abcd) => abcd.TaisanId === dataIdTaisan.id));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (dataIdTaisan) {
            fetchData();
        }
        let NhomtaisanId = nhomTaisan.find((items) => {
            return items && items.id === dataIdTaisan?.NhomtaisanId;
        });
        let TrangthaiId = trangthaiTaisan.find((items) => {
            return items && items.id === dataIdTaisan?.TrangthaiId;
        });
        show &&
            dataIdTaisan &&
            setPayloadTaisan({
                id: dataIdTaisan?.id,
                mataisan: dataIdTaisan?.mataisan,
                tentaisan: dataIdTaisan?.tentaisan,
                soluong: dataIdTaisan?.soluong,
                NhomtaisanId: { label: NhomtaisanId?.tenNhomtaisan, value: NhomtaisanId?.id },
                donvitaisan: dataIdTaisan?.donvitaisan,
                doi: dataIdTaisan?.doi,
                vitritaisan: dataIdTaisan?.vitritaisan,
                ngaymua: dataIdTaisan?.ngaymua,
                khauhao: dataIdTaisan?.khauhao,
                thoihanbaohanh: dataIdTaisan?.thoihanbaohanh,
                giatri: dataIdTaisan?.giatri,
                giatriformat: dataIdTaisan?.giatri,
                nhacungcap: dataIdTaisan?.nhacungcap,
                sodienthoai: dataIdTaisan?.sodienthoai,
                diachi: dataIdTaisan?.diachi,
                ghichu: dataIdTaisan?.ghichu,
                TrangthaiId: { label: TrangthaiId?.tentrangthai, value: TrangthaiId?.id },
            });
        const nguyengia = dataIdTaisan?.giatri * dataIdTaisan?.soluong;
        const khauhao = Math.round(nguyengia / dataIdTaisan?.khauhao);
        const conlai = Math.round(nguyengia - khauhao);
        setPayloadKhauhao((prev) => ({ ...prev, ['nguyengia']: nguyengia }));
        setPayloadKhauhao((prev) => ({ ...prev, ['khauhao']: khauhao }));
        setPayloadKhauhao((prev) => ({ ...prev, ['conlai']: conlai }));
    }, [dataIdTaisan, show]);
    // console.log('dataTaisanHtr', dataTaisanHtr);
    
    const [listTrangthai, setlistTrangthai] = useState([]);
    const [listNhomTaisan, setNhomTaisan] = useState([]);
    useEffect(() => {
        let strangthaiTaisan = trangthaiTaisan.map((item) => ({ label: item.tentrangthai, value: item.id }));
        let snhomTaisan = nhomTaisan.map((item) => ({ label: item.tenNhomtaisan, value: item.id }));
        setlistTrangthai(strangthaiTaisan);
        setNhomTaisan(snhomTaisan);
    }, []);

    const handleSubmit = async () => {
        let invalids = validate(payload);

        if (invalids === 0) {
            let datapayload = {
                id: payload?.id,
                mataisan: payload?.mataisan,
                tentaisan: payload?.tentaisan,
                soluong: payload?.soluong,
                NhomtaisanId: payload?.NhomtaisanId.value,
                donvitaisan: payload?.donvitaisan,
                doi: payload?.doi,
                vitritaisan: payload?.vitritaisan,
                ngaymua: payload?.ngaymua,
                khauhao: payload.khauhao,
                thoihanbaohanh: payload?.thoihanbaohanh,
                giatri: payload?.giatri,
                nhacungcap: payload?.nhacungcap,
                sodienthoai: payload?.sodienthoai,
                diachi: payload?.diachi,
                ghichu: payload?.ghichu,
                TrangthaiId: payload?.TrangthaiId.value,
            };
            //   console.log('check payload', datapayload)
            dispatch(actions.EditTaisan(datapayload));
        }
    };
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayloadTaisan((prev) => ({ ...prev, [statename]: selected }));
    };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateTaiSan(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateTaiSan(item[0], item[1])]);
                invalids++;
            }
        });
        return invalids;
    };
    return (
        <Modal isOpen={show} toggle={setShow} className="modal-dialog-centered modal-90w" size="lg">
            <ModalBody>
                <Icon name="cross-sm float-end fs-2" onClick={setShow}></Icon>
                <h4 className="title">{handleEditDetail === false ? 'Sửa tài sản' : 'Chi tiết tài sản'}</h4>
                <div className="d-flex bd-highlight mb-2">
                    <div className="p-2 mt-1 flex-grow-1 bd-highlight">
                        <h5>Thông tin chung</h5>
                    </div>
                    {handleEditDetail === false ? (
                        <React.Fragment>
                            <div className="p-1 bd-highlight">
                                <Button outline color="success" onClick={() => setHandleEditDetail(true)}>
                                    Xem chi tiết
                                </Button>
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div className="p-1 bd-highlight">
                                <Button
                                    outline
                                    color="primary"
                                    onClick={() => {
                                        setShowCtb(true);
                                        setHandleCtb('Capphat');
                                    }}
                                >
                                    Cấp phát
                                </Button>
                            </div>
                            <div className="p-1 bd-highlight">
                                <Button
                                    outline
                                    color="secondary"
                                    onClick={() => {
                                        setShowCtb(true);
                                        setHandleCtb('Thuhoi');
                                    }}
                                >
                                    Thu hồi
                                </Button>
                            </div>
                            <div className="p-1 bd-highlight">
                                <Button
                                    outline
                                    color="danger"
                                    onClick={() => {
                                        setShowCtb(true);
                                        setHandleCtb('Baomat');
                                    }}
                                >
                                    Báo mất/Báo hỏng
                                </Button>
                            </div>
                            <div className="p-1 bd-highlight">
                                <Button outline color="success" onClick={() => setHandleEditDetail(false)}>
                                    Sửa
                                </Button>
                            </div>
                            <ModalCtb
                                showCtb={showCtb}
                                setShowCtb={() => setShowCtb(false)}
                                showHandleCtb={showHandleCtb}
                                dataIdTaisan={dataIdTaisan}
                            />
                        </React.Fragment>
                    )}
                </div>
                <div className="row">
                    <div className="form-group col-md-4">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Tên tài sản'}
                            value={payload.tentaisan}
                            setValue={setPayloadTaisan}
                            keyPayload={'tentaisan'}
                            placeholder={'Tên tài sản'}
                            type="text"
                            disabled={handleEditDetail === false ? false : true}
                            validate
                        />
                    </div>
                    <div className="form-group col-md-4 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Mã tài sản'}
                            placeholder={'Mã tài sản'}
                            value={payload.mataisan}
                            setValue={setPayloadTaisan}
                            keyPayload={'mataisan'}
                            type="text"
                            disabled={true}
                            validate
                        />
                    </div>
                    <div className="form-group col-md-4">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Số lượng'}
                            placeholder={'Số lượng'}
                            value={payload.soluong}
                            setValue={setPayloadTaisan}
                            keyPayload={'soluong'}
                            type="text"
                            disabled={true}
                            validate
                        />
                    </div>
                    <div className="form-group col-md-4 ">
                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                                Nhóm tài sản <b className="text-danger">(*)</b>
                            </span>
                        </label>
                        <Select
                            value={payload.NhomtaisanId}
                            onChange={handleOnSelect}
                            options={listNhomTaisan}
                            placeholder={'Chọn nhóm'}
                            name="NhomtaisanId"
                            onFocus={() => setInvalidFields([])}
                            isDisabled={handleEditDetail === false ? false : true}
                            validate
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'NhomtaisanId') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'NhomtaisanId')?.message}
                            </small>
                        )}
                    </div>
                    <div className="form-group col-md-4 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Đơn vị tài sản'}
                            placeholder={'Đơn vị tài sản'}
                            value={payload.donvitaisan}
                            setValue={setPayloadTaisan}
                            keyPayload={'donvitaisan'}
                            type="text"
                            disabled={handleEditDetail === false ? false : true}
                            validate
                        />
                    </div>
                    <div className="form-group col-md-4">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Model'}
                            placeholder={'Model'}
                            value={payload.doi}
                            setValue={setPayloadTaisan}
                            keyPayload={'doi'}
                            type="text"
                            disabled={handleEditDetail === false ? false : true}
                            validate
                        />
                    </div>
                    <div className="form-group col-md-4 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Vị trí tài sản'}
                            placeholder={'Vị trí tài sản'}
                            value={payload.vitritaisan}
                            setValue={setPayloadTaisan}
                            keyPayload={'vitritaisan'}
                            type="text"
                            disabled={handleEditDetail === false ? false : true}
                            validate
                        />
                    </div>
                    <div className="form-group col-md-4">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ngày mua'}
                            placeholder={'Ngày mua'}
                            value={payload.ngaymua}
                            setValue={setPayloadTaisan}
                            keyPayload={'ngaymua'}
                            type="date"
                            disabled={handleEditDetail === false ? false : true}
                            validate
                        />
                    </div>
                    <div className="form-group col-md-4 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Thời gian bảo hành (tháng)'}
                            placeholder={'Thời gian bảo hành'}
                            value={payload.thoihanbaohanh}
                            setValue={setPayloadTaisan}
                            keyPayload={'thoihanbaohanh'}
                            type="text"
                            disabled={handleEditDetail === false ? false : true}
                            validate
                        />
                    </div>
                    <div className="form-group col-md-4">
                        <div className="form-group">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                <span>
                                    Giá trị <b className="text-danger">(*)</b>
                                </span>
                            </label>
                            <div className="form-control-wrap">
                                <div className="form-text-hint">
                                    <span className="overline-title">VND</span>
                                </div>
                                <NumericFormat
                                    className="form-control px-2"
                                    disabled={handleEditDetail === false ? false : true}
                                    value={payload.giatriformat}
                                    // displayType={'text'}
                                    thousandSeparator={true}
                                    // suffix={'đ'}
                                    name="giatri"
                                    onValueChange={(values) => {
                                        const { formattedValue, value } = values;
                                        setPayloadTaisan((prev) => ({ ...prev, ['giatri']: value }));
                                        setPayloadTaisan((prev) => ({ ...prev, ['giatriformat']: formattedValue }));
                                    }}
                                />
                                {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'giatri') && (
                                    <small className="text-danger">
                                        {invalidFields.find((i) => i.name === 'giatri')?.message}
                                    </small>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-4 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Khấu hao (tháng)'}
                            placeholder={'Khấu hao'}
                            value={payload.khauhao}
                            setValue={setPayloadTaisan}
                            keyPayload={'khauhao'}
                            type="number"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-4 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Nhà cung cấp'}
                            placeholder={'Nhà cung cấp'}
                            value={payload.nhacungcap}
                            setValue={setPayloadTaisan}
                            keyPayload={'nhacungcap'}
                            type="text"
                            disabled={handleEditDetail === false ? false : true}
                            validate
                        />
                    </div>
                    <div className="form-group col-md-4">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Số điện thoai NCC'}
                            placeholder={'Số điện thoai NCC'}
                            value={payload.sodienthoai}
                            setValue={setPayloadTaisan}
                            keyPayload={'sodienthoai'}
                            type="text"
                            disabled={handleEditDetail === false ? false : true}
                            validate
                        />
                    </div>
                    <div className="form-group col-md-4 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Địa chỉ NCC'}
                            placeholder={'Địa chỉ NCC'}
                            value={payload.diachi}
                            setValue={setPayloadTaisan}
                            keyPayload={'diachi'}
                            type="text"
                            disabled={handleEditDetail === false ? false : true}
                            validate
                        />
                    </div>

                    <div className="form-group col-md-4">
                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                                Trạng thái tài sản <b className="text-danger">(*)</b>
                            </span>
                        </label>
                        <Select
                            value={payload.TrangthaiId}
                            onChange={handleOnSelect}
                            options={listTrangthai}
                            placeholder={'Trạng thái'}
                            name="TrangthaiId"
                            onFocus={() => setInvalidFields([])}
                            isDisabled={handleEditDetail === false ? false : true}
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'TrangthaiId') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'TrangthaiId')?.message}
                            </small>
                        )}
                    </div>
                    <div className="form-group col-md-12 mb-2">
                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                                Ghi chú <b className="text-danger">(*)</b>
                            </span>
                        </label>
                        <Input
                            value={payload.ghichu}
                            onChange={(e) => setPayloadTaisan((prev) => ({ ...prev, ['ghichu']: e.target.value }))}
                            rows={'2'}
                            name="ghichu"
                            type="textarea"
                            disabled={handleEditDetail === false ? false : true}
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'ghichu') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'ghichu')?.message}
                            </small>
                        )}
                    </div>
                </div>

                <div className="col-12 mt-2">
                    {handleEditDetail === false ? (
                        <Button color="primary" onClick={handleSubmit}>
                            Cập nhật
                        </Button>
                    ) : (
                        <React.Fragment>
                            <h5 className="p-2">Giá trị tài sản</h5>
                            <div className="row">
                                <div className="form-group col-md-3">
                                    <div className="form-group">
                                        <label htmlFor="inputZip" className="mb-2">
                                            <span>Nguyên giá</span>
                                        </label>
                                        <div className="form-control-wrap">
                                            <div className="form-text-hint">
                                                <span className="overline-title">VND</span>
                                            </div>
                                            <NumericFormat
                                                className="form-control px-2"
                                                disabled={handleEditDetail === false ? false : true}
                                                value={payloadKhauhao.nguyengia}
                                                thousandSeparator={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-md-3">
                                    <div className="form-group">
                                        <label htmlFor="inputZip" className="mb-2">
                                            <span>Đơn giá</span>
                                        </label>
                                        <div className="form-control-wrap">
                                            <div className="form-text-hint">
                                                <span className="overline-title">VND</span>
                                            </div>
                                            <NumericFormat
                                                className="form-control px-2"
                                                disabled={handleEditDetail === false ? false : true}
                                                value={payload.giatriformat}
                                                thousandSeparator={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-md-3">
                                    <div className="form-group">
                                        <label htmlFor="inputZip" className="mb-2">
                                            <span>Giá trị khấu hao</span>
                                        </label>
                                        <div className="form-control-wrap">
                                            <div className="form-text-hint">
                                                <span className="overline-title">VND</span>
                                            </div>
                                            <NumericFormat
                                                className="form-control px-2"
                                                disabled={handleEditDetail === false ? false : true}
                                                value={payloadKhauhao.khauhao}
                                                thousandSeparator={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-md-3">
                                    <div className="form-group">
                                        <label htmlFor="inputZip" className="mb-2">
                                            <span>Giá trị còn lại</span>
                                        </label>
                                        <div className="form-control-wrap">
                                            <div className="form-text-hint">
                                                <span className="overline-title">VND</span>
                                            </div>
                                            <NumericFormat
                                                className="form-control px-2"
                                                disabled={handleEditDetail === false ? false : true}
                                                value={payloadKhauhao.conlai}
                                                thousandSeparator={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h5 className="p-2">Lịch sử tài sản</h5>
                            <DataTable className="card-stretch">
                                <DataTableBody>
                                    <DataTableHead>
                                        <DataTableRow>
                                            <span className="sub-text">Nhân viên</span>
                                        </DataTableRow>
                                        <DataTableRow size="mb">
                                            <span className="sub-text">Tên tài sản</span>
                                        </DataTableRow>
                                        <DataTableRow size="mb">
                                            <span className="sub-text">Số lượng</span>
                                        </DataTableRow>
                                        <DataTableRow size="mb">
                                            <span className="sub-text">Mã hành động</span>
                                        </DataTableRow>
                                        <DataTableRow size="mb">
                                            <span className="sub-text">Trạng thái</span>
                                        </DataTableRow>
                                    </DataTableHead>
                                    {dataTaisanHtr.length > 0
                                        ? dataTaisanHtr.map((item, it) => {
                                              // ['Cấp Phát', 'Thu hồi', 'Báo Mất', 'Đã thu hồi', 'Đã báo mất']
                                              let TrangThaiName = [
                                                  'Cấp Phát',
                                                  'Thu hồi',
                                                  'Báo Mất',
                                                  'Đã thu hồi',
                                                  'Đã báo mất',
                                              ];
                                              let TrangThaiColor = [
                                                  'bg-success',
                                                  'bg-primary',
                                                  'bg-warning',
                                                  'bg-danger',
                                                  'bg-danger',
                                              ];
                                              return (
                                                  <DataTableItem key={it}>
                                                      <DataTableRow>
                                                          <span className="sub-text">{item?.Nhanvien?.hoten}</span>
                                                      </DataTableRow>
                                                      <DataTableRow size="mb">
                                                          <span className="sub-text">{dataIdTaisan?.tentaisan}</span>
                                                      </DataTableRow>
                                                      <DataTableRow size="mb">
                                                          <span className="sub-text">{item?.soluong}</span>
                                                      </DataTableRow>
                                                      <DataTableRow size="mb">
                                                          <span className="sub-text">{item?.mataisannhanvien}</span>
                                                      </DataTableRow>
                                                      <DataTableRow size="mb">
                                                          <span
                                                              className={`${
                                                                  TrangThaiColor[item?.TrangthaiId]
                                                              } ms-0 badge text-white`}
                                                          >
                                                              {TrangThaiName[item?.TrangthaiId]}
                                                          </span>
                                                      </DataTableRow>
                                                  </DataTableItem>
                                              );
                                          })
                                        : null}
                                </DataTableBody>
                            </DataTable>
                        </React.Fragment>
                    )}
                </div>
            </ModalBody>
        </Modal>
    );
};

export default ModalEditTaisan;
