import React, {useEffect, useMemo, useState} from 'react';
import {
    apiDeleteBCLuong,
} from '~/services';
import {useDispatch, useSelector} from 'react-redux';
import Content from '~/layout/content/Content';
import {Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle} from '~/components/block/Block';
import {DataTable} from '~/components/table/DataTable';
import * as actions from '~/store/actions';
import './chitietnhanvien.scss';
import {Box, IconButton, Tooltip} from "@mui/material";
import {MaterialReactTable, useMaterialReactTable} from "material-react-table";
import {dataPagination, dataSelectLock} from "~/utils/Datacustom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {PermissionUtils} from "~/components/Component";
import {toast} from "react-toastify";
import Swal from "sweetalert2";
import {MRT_Localization_VI} from "material-react-table/locales/vi";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {Link} from "react-router-dom";
import config from "~/config";
function Danhsachbangluong() {
    const dispatch = useDispatch();
    const {payroll} = useSelector(state => state.payroll)
    const [data, setData] = useState([]);
    
    
    const [payrolls, setPayrolls] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [totalRowCount, setTotalRowCount] = useState(10);
    useEffect(() => {
        dispatch(actions.getListPayroll()).then(r => 'err');
    }, [pagination.pageIndex, pagination.pageSize]);
    useEffect(() => {
        setData(payroll)
    }, [payroll]);
    console.log('dáta', data)
    const [showPhanquyen, setShowPhanquyen] = useState({});
    let dataPermissionUtils = PermissionUtils('phongban', 'all');
    useEffect(() => {
        if (dataPermissionUtils) {
            setShowPhanquyen(dataPermissionUtils);
        }
    }, [dataPermissionUtils]);
    /**
     * Returns x Các loại trong quan
     *
     * @return {number} payroll: Lương tri trả nhân viên
     */
    const handleEdit = async (data) => {
        console.log('data', data)
    }
    const handleView = async (data) => {
        console.log('data', data)
    }
    const handleDelete = async (data) => {
        const {id, status} = data // TODO: status check xem có thể xóa hay không
        if (status === 1) {
            Swal.fire({
                title: 'Không thể xóa',
                text: 'Bảng lương đã được thanh toán không thể xóa',
                icon: 'error',
                confirmButtonText: 'Đóng',
            });
        } else {
            Swal.fire({
                title: 'Bạn có chắc không?',
                text: 'Bạn sẽ không thể lấy lại khi xóa!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Không, bỏ qua!',
                confirmButtonText: 'Vâng, xóa nó đi!',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const datapayload = {idPayroll: id}
                    const res = await apiDeleteBCLuong(datapayload)
                    if (res.data.success === 200) {
                        toast.success(res.data.message);
                        dispatch(actions.getListPayroll()).then(r => 'err');
                    } else if (res.data.success === 500) {
                        toast.error(res.data.message);
                    } else {
                        toast.error(res.data.message);
                    }
                    
                }
            });
        }
       
    };
    const Status = (cell) => ( // Function show trạng thái
        <Box
            component="span"
            sx={(theme) => ({
                backgroundColor: cell === 1 ? '#1ee0ac' : '#e65100',
                fontSize: '10px',
                borderRadius: '0.25rem',
                color: '#fff',
                p: '0.25rem',
            })}
        >
            {dataSelectLock.find(select => select.value === cell)?.label || dataSelectLock[0].label}
        </Box>
    );
    const Example = () => {
        let dataAb = data;
        const columns = useMemo(
            () => [
                {
                    Header: '#', // Tiêu đề cột số thứ tự
                    header: 'STT', // Tiêu đề cột số thứ tự
                    id: 'rowNumber', // ID của cột
                    size: 50,
                    Cell: ({row}) => <div>{row?.index + 1}</div>, // Giá trị của cột là số thứ tự
                    enableSorting: false,
                    enablePinning: true,
                },
                {
                    accessorKey: 'title',
                    enablePinning: true,
                    header: 'Tiêu đề',
                    enableColumnActions: false,
                    Cell: ({ cell, row }) => (
                        <Link to={`${config.he_thong}${config.tinh_luong}?bangluong=${row.original.id}`}>
                            {cell.getValue()}
                        </Link>
                    ),
                },
                {
                    accessorKey: 'TrangthaiId',
                    header: 'Trạng thái',
                    enablePinning: true,
                    Cell: ({cell}) => Status(Number(cell?.getValue()) || 0),
                    
                },
            ],
            [],
        );
        const rowActions = ({row, table}) => ( // Thêm nút sửa xóa vào hàng
            <Box sx={{display: 'flex', gap: '1rem'}}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => handleEdit(row.original)}>
                        <EditIcon/>
                    </IconButton>
                </Tooltip>
              
                <Tooltip title="Delete">
                    <IconButton color="error" onClick={() => handleDelete({
                        id: row.original.id,
                        status: row.original.TrangthaiId
                    })}>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            </Box>
        );
        const table = useMaterialReactTable({
            columns,
            enableColumnPinning: true,
            data: dataAb,
            enableRowActions: true,
            localization: MRT_Localization_VI,
            renderRowActions: rowActions,
            muiPaginationProps: {
                rowsPerPageOptions: dataPagination,
            },
        })
        
        return (
            <MaterialReactTable
                table={table}
            />
        );
    }
    
    return (
        <React.Fragment>
            {/*<Head title="User List - Regular"></Head>*/}
            <Content clasName="position-relative overflow-hidden">
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                Danh sách bảng lương
                            </BlockTitle>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <Block className="overflow-hidden">
                    <DataTable className="card-stretch">
                        <div className="card-inner position-relative card-tools-toggle">
                            <div className="row">
                                
                                <div className="col-12">
                                    <Example/>
                                </div>
                            </div>
                        
                        </div>
                    </DataTable>
                </Block>
            
            </Content>
        </React.Fragment>
    );
}

export default Danhsachbangluong;
