export const validateNghiphepcongtac = (fieldName, value) => {
    switch (fieldName) {
        case 'PheduyetId':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'NhanvienId':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'kieuphep':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'ngaybatdau':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'ngayketthuc':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        // case 'giobatdau':
        //     if (value.length === 0) {
        //         return {
        //             name: fieldName,
        //             message: 'Nội dung không được để trống'
        //         }
        //     }
        //     break;
        // case 'gioketthuc':
        //     if (value.length === 0) {
        //         return {
        //             name: fieldName,
        //             message: 'Nội dung không được để trống'
        //         }
        //     }
        //     break;
        // default:
        //     break;
    }
}