import actionTypes from "../actions/actionTypes";
const initState = {
    giaoviec: [],
    msg: '',
    count: 0,
    newgiaoviec: [],
    giaoviecbang: [],
    msgbang: '',
    countbang: 0,
    newgiaoviecbang: [],
    giaoviecbangone: [],
    giaoviecbangonecolumn: []
}

const giaoviecReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.GET_LIST_GIAO_VIEC:

            return {
                ...state,
                giaoviec: action?.giaoviec?.rows || [],
                msg: 'Ok' || '',
                count: action?.giaoviec?.count || 0
            }
        case actionTypes.POST_NEW_GIAO_VIEC:
        case actionTypes.PUT_EDIT_GIAO_VIEC:
        case actionTypes.DELETE_GIAO_VIEC:
            return {
                ...state,
                msg: action.msg || '',
                newgiaoviec: action?.newgiaoviec || []
            }
            //---------------------------------
        case actionTypes.GET_LIST_GIAO_VIEC_BANG:

            return {
                ...state,
                giaoviecbang: action?.giaoviecbang?.rows || [],
                msgbang: 'Ok' || '',
                countbang: action?.giaoviecbang?.count || 0
            }
        case actionTypes.POST_NEW_GIAO_VIEC_BANG:
        case actionTypes.PUT_EDIT_GIAO_VIEC_BANG:
        case actionTypes.DELETE_GIAO_VIEC_BANG:
            return {
                ...state,
                msgbang: action.msgbang || '',
                newgiaoviecbang: action?.newgiaoviecbang || []
            }
            //---------------------------------
        case actionTypes.GET_LIST_GIAO_VIEC_BANG_ONE:

        return {
            ...state,
            giaoviecbangone: action?.giaoviecbangone || [],
            giaoviecbangonecolumn: action?.giaoviecbangonecolumn || [],
            msgbang: 'Ok' || '',
        }
        default:
            return state;
    }

}

export default giaoviecReducer