import axiosConfig from '../axiosConfig'

export const apiGetCauhinhtangca = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: '/api/v2/quanlyhethong/cauhinhtangca?page='+payload.start+'&limit='+payload.size+'&order='+payload.sorting+'&by='+payload.globalFilter,
            
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiNewCauhinhtangca = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlyhethong/cauhinhtangca',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiEditCauhinhtangca = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlyhethong/cauhinhtangca',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiDeleteCauhinhtangca = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlyhethong/cauhinhtangca?id=' + payload,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
