import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { InputForm } from '~/components/Form';
import * as actions from '~/store/actions';

import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { Input } from 'reactstrap';
import { validateCauhinhcalamviec } from '~/request/validateCauhinhcalamviec';
import { dataShift, SelectLoaiphatdimuon, SelectLoaiphatvesom } from '~/utils/Datacustom';
import { Button, Icon } from '~/components/Component';
const ModalEditCauhinhcalamviec = ({ show, setShow, dataIdCauHinhCaLamViec, fieldList }) => {
    // console.log('dataIdCauHinhCaLamViec', dataIdCauHinhCaLamViec);
    const dispatch = useDispatch();
    // const [timeStart, settimeStart] = useState()
    // const [timeFinish, settimeFinish] = useState({timeFinish:''})
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayload] = React.useState(fieldList);
    const [shift, setShift] = useState([]);
    useEffect(() => {
        show && setPayload(fieldList);
        setShift(dataShift);
    }, [show, dataShift]);

    const [invalidDm, setInvalidFieldsDm] = useState([]);
    const [formDM, setFormDM] = useState([
        {
            tu: 1,
            den: 30,
            Loaiphatdimuon: 0,
            giobitru: 0,
            congbitru: 0,
        },
        {
            tu: 31,
            den: 0,
            Loaiphatdimuon: 0,
            giobitru: 0,
            congbitru: 0,
        },
    ]);
    const handleChangeDM = (i, e) => {
        let newFormValues = [...formDM];
        if (e.target.name === 'den') {
            newFormValues[i + 1]['tu'] = Number(e.target.value) + 1;
        }
        newFormValues[i][e.target.name] = e.target.value;
        setFormDM(newFormValues);
    };

    const addFormFieldsDM = () => {
        let mang = [
            ...formDM,
            {
                tu: 0,
                den: 0,
                Loaiphatdimuon: 0,
                giobitru: 0,
                congbitru: 0,
            },
        ];
        setFormDM(mang);
    };
    const removeFormFieldsDM = (i) => {
        let newFormValues = [...formDM];
        newFormValues.splice(i, 1);
        setFormDM(newFormValues);
    };
    const [invalidVs, setInvalidFieldsVs] = useState([]);
    const [formVS, setFormVS] = useState([
        {
            tu: 1,
            den: 30,
            Loaiphatvesom: 0,
            giobitru: 0,
            congbitru: 0,
        },
        {
            tu: 31,
            den: 0,
            Loaiphatvesom: 0,
            giobitru: 0,
            congbitru: 0,
        },
    ]);
    const handleChangeVS = (i, e) => {
        let newFormValues = [...formVS];
        if (e.target.name === 'den') {
            newFormValues[i + 1]['tu'] = Number(e.target.value) + 1;
        }
        newFormValues[i][e.target.name] = e.target.value;
        setFormVS(newFormValues);
    };

    const addFormFieldsVS = () => {
        let mang = [
            ...formVS,
            {
                tu: 0,
                den: 0,
                Loaiphatvesom: 0,
                giobitru: 0,
                congbitru: 0,
            },
        ];
        setFormVS(mang);
    };
    const removeFormFieldsVS = (i) => {
        let newFormValues = [...formVS];
        newFormValues.splice(i, 1);
        setFormVS(newFormValues);
    };

    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayload((prev) => ({ ...prev, [statename]: selected }));
    };

    useEffect(() => {
        let is_Shift = shift.find((items) => {
            return items && items.value === dataIdCauHinhCaLamViec?.isShift;
        });

        show &&
            dataIdCauHinhCaLamViec &&
            setPayload({
                id: dataIdCauHinhCaLamViec?.id,
                tieude: dataIdCauHinhCaLamViec?.tieude,
                macalamviec: dataIdCauHinhCaLamViec?.macalamviec,
                motacalamviec: dataIdCauHinhCaLamViec?.motacalamviec,
                giovaolamviec: dataIdCauHinhCaLamViec?.giovaolamviec,
                gioketthuclamviec: dataIdCauHinhCaLamViec?.gioketthuclamviec,
                solanchuyenngaytunhien: dataIdCauHinhCaLamViec?.solanchuyenngaytunhien,
                giobatdauantrua: dataIdCauHinhCaLamViec?.giobatdauantrua,
                gioketthucantrua: dataIdCauHinhCaLamViec?.gioketthucantrua,
                tonggio: dataIdCauHinhCaLamViec?.tonggio,
                isShift: { label: is_Shift?.label, value: is_Shift?.value },
                demcong: dataIdCauHinhCaLamViec?.demcong,
                batdaugiovaodehieuca: dataIdCauHinhCaLamViec?.batdaugiovaodehieuca,
                ketthucgiovaodehieuca: dataIdCauHinhCaLamViec?.ketthucgiovaodehieuca,
                batdaugioradehieuca: dataIdCauHinhCaLamViec?.batdaugioradehieuca,
                ketthucgioradehieuca: dataIdCauHinhCaLamViec?.ketthucgioradehieuca,
                phatdimuon: dataIdCauHinhCaLamViec?.phatdimuon,
                phatvesom: dataIdCauHinhCaLamViec?.phatvesom,
                loaica: dataIdCauHinhCaLamViec?.loaica,
                // khongcogiorathitinhtong: dataIdCauHinhCaLamViec?.khongcogiorathitinhtong,
                // khongcogiovaothitinhtong: dataIdCauHinhCaLamViec?.khongcogiovaothitinhtong,
                // vitritrenbaobieu: dataIdCauHinhCaLamViec?.vitritrenbaobieu,
            });
        setFormDM(
            dataIdCauHinhCaLamViec?.dataphatdimuon
                ? JSON.parse(dataIdCauHinhCaLamViec?.dataphatdimuon)
                : [
                      {
                          tu: 1,
                          den: 30,
                          Loaiphatdimuon: 0,
                          giobitru: 0,
                          congbitru: 0,
                      },
                      {
                          tu: 31,
                          den: 0,
                          Loaiphatdimuon: 0,
                          giobitru: 0,
                          congbitru: 0,
                      },
                  ],
        );
        setFormVS(
            dataIdCauHinhCaLamViec?.dataphatdimuon
                ? JSON.parse(dataIdCauHinhCaLamViec?.dataphatvesom)
                : [
                      {
                          tu: 1,
                          den: 30,
                          Loaiphatvesom: 0,
                          giobitru: 0,
                          congbitru: 0,
                      },
                      {
                          tu: 31,
                          den: 0,
                          Loaiphatvesom: 0,
                          giobitru: 0,
                          congbitru: 0,
                      },
                  ],
        );
    }, [dataIdCauHinhCaLamViec, show]);

    const handleRenderTime = async (payload) => {
        let finishTime = payload.gioketthuclamviec;
        let startTime = payload.giovaolamviec;
        let startAfternon = payload.giobatdauantrua || '00:00';
        let finishAfternon = payload.gioketthucantrua || '00:00';
        let tonggio = 0;
        if (finishTime && startTime && startAfternon && finishAfternon) {
            const [hh1, mm1] = finishTime.split(':').map(Number);
            const [hh2, mm2] = startTime.split(':').map(Number);
            const [hh3, mm3] = finishAfternon.split(':').map(Number);
            const [hh4, mm4] = startAfternon.split(':').map(Number);

            // Chuyển đổi thời gian thành tổng số giây
            const totalSeconds1 = hh1 * 3600 + mm1 * 60;
            const totalSeconds2 = hh2 * 3600 + mm2 * 60;
            const totalSeconds3 = hh3 * 3600 + mm3 * 60;
            const totalSeconds4 = hh4 * 3600 + mm4 * 60;

            // Trừ thời gian thứ hai từ thời gian đầu tiên
            const differenceSeconds = totalSeconds1 - totalSeconds2;
            const differenceSeconds1 = totalSeconds3 - totalSeconds4;

            // Chuyển đổi lại thành định dạng hh:mm:ss
            const hhResult =
                Math.floor((differenceSeconds - differenceSeconds1) / 60) +
                Math.floor(((differenceSeconds - differenceSeconds1) % 3600) / 60);

            // Định dạng kết quả

            tonggio = (hhResult / 60).toFixed(2);
        }
        setPayload((prev) => ({ ...prev, ['tonggio']: tonggio }));
        return tonggio;
    };
    useEffect(() => {
        handleRenderTime(payload);
    }, [payload.gioketthuclamviec, payload.giovaolamviec, payload.giobatdauantrua, payload.gioketthucantrua]);

    // console.log('handleRenderTime',)
    const handleSubmit = async () => {
        let invalids = validate(payload);
        const vaDimuon = (payload.phatdimuon === 1 && invalidDm.length === 0) || payload.phatdimuon === 0;
        const vaVesom = (payload.phatvesom === 1 && invalidVs.length === 0) || payload.phatvesom === 0;
        if (invalids === 0 && vaDimuon && vaVesom) {
            let datapayload = {
                id: dataIdCauHinhCaLamViec?.id,
                tieude: payload.tieude,
                motacalamviec: payload.motacalamviec,
                giovaolamviec: payload.giovaolamviec,
                gioketthuclamviec: payload.gioketthuclamviec,
                solanchuyenngaytunhien: payload.solanchuyenngaytunhien,
                giobatdauantrua: payload.giobatdauantrua,
                gioketthucantrua: payload.gioketthucantrua,
                isShift: payload.isShift.value,
                tonggio: payload.tonggio,
                demcong: payload.demcong,
                batdaugiovaodehieuca: payload.batdaugiovaodehieuca,
                ketthucgiovaodehieuca: payload.ketthucgiovaodehieuca,
                batdaugioradehieuca: payload.batdaugioradehieuca,
                ketthucgioradehieuca: payload.ketthucgioradehieuca,
                phatdimuon: payload.phatdimuon,
                phatvesom: payload.phatvesom,
                dataphatdimuon: payload.phatdimuon === 1 ? JSON.stringify(formDM) : null,
                dataphatvesom: payload.phatdimuon === 1 ? JSON.stringify(formVS) : null,
                loaica: payload.loaica,
                // khongcogiorathitinhtong: payload.khongcogiorathitinhtong,
                // khongcogiovaothitinhtong: payload.khongcogiovaothitinhtong,
                // vitritrenbaobieu: payload.vitritrenbaobieu,
            };
            dispatch(actions.EditCauhinhcalamviec(datapayload));
        }
    };

    // if (payload)
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateCauhinhcalamviec(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateCauhinhcalamviec(item[0], item[1])]);
                invalids++;
            }
        });

        return invalids;
    };

    useEffect(() => {
        if (formDM) {
            let manglog = [];
            const kiemTraKhoangCach = (indexToRemove, tu, den) => {
                const updatedFormDM = formDM.filter((_, index) => index !== indexToRemove);
                for (const khoangCach of updatedFormDM) {
                    if (
                        (Number(tu) >= Number(khoangCach.tu) && Number(tu) <= Number(khoangCach.den)) ||
                        (Number(den) >= Number(khoangCach.tu) && Number(den) <= Number(khoangCach.den))
                    ) {
                        return true;
                    }
                }
                return false;
            };
            formDM?.map((item, it) => {
                if (Number(item.tu) > Number(item.den)) {
                    if (formDM.length !== it + 1) {
                        manglog.push(
                            { name: `den${it}`, message: 'Số phút đến phải > hoặc = từ' },
                            { name: `tu${it}`, message: 'Số phút đến phải > hoặc = từ' },
                        );
                    }
                }
                const kiemtra = kiemTraKhoangCach(it, item.tu, item.den);
                if (kiemtra === true) {
                    manglog.push(
                        { name: `den${it}`, message: 'Khoảng thời gian đã bị trùng' },
                        { name: `tu${it}`, message: 'Khoảng thời gian đã bị trùng' },
                    );
                }
            });
            setInvalidFieldsDm(manglog);
        }
    }, [formDM]);
    console.log('formDM', formDM)
    useEffect(() => {
        if (formVS) {
            let manglog = [];
            const kiemTraKhoangCach = (indexToRemove, tu, den) => {
                const updatedFormVS = formVS.filter((_, index) => index !== indexToRemove);
                for (const khoangCach of updatedFormVS) {
                    if (
                        (Number(tu) >= Number(khoangCach.tu) && Number(tu) <= Number(khoangCach.den)) ||
                        (Number(den) >= Number(khoangCach.tu) && Number(den) <= Number(khoangCach.den))
                    ) {
                        return true;
                    }
                }
                return false;
            };
            formVS?.map((item, it) => {
                if (Number(item.tu) > Number(item.den)) {
                    if (formVS.length !== it + 1) {
                        manglog.push(
                            { name: `den${it}`, message: 'Số phút đến phải > hoặc = từ' },
                            { name: `tu${it}`, message: 'Số phút đến phải > hoặc = từ' },
                        );
                    }
                }
                const kiemtra = kiemTraKhoangCach(it, item.tu, item.den);
                if (kiemtra === true) {
                    manglog.push(
                        { name: `den${it}`, message: 'Khoảng thời gian đã bị trùng' },
                        { name: `tu${it}`, message: 'Khoảng thời gian đã bị trùng' },
                    );
                }
            });
            setInvalidFieldsVs(manglog);
        }
    }, [formVS]);

    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">Sửa cấu hình ca làm việc</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ca Làm Việc'}
                            value={payload.tieude}
                            setValue={setPayload}
                            keyPayload={'tieude'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Mã ca'}
                            value={payload.macalamviec}
                            setValue={setPayload}
                            keyPayload={'macalamviec'}
                            type="text"
                            validate
                            disabled={true}
                        />
                    </div>
                    <div className="mt-1">
                        <div className="form-group col-md-12">
                            <div className="pb-0">
                                <div className="g-4 align-center flex-wrap">
                                    <div className="g">
                                        <div className="custom-control custom-radio">
                                            <input
                                                type="radio"
                                                className="custom-control-input"
                                                name="loaica"
                                                id="loaica"
                                                onChange={(e) =>
                                                    setPayload((prev) => ({
                                                        ...prev,
                                                        ['loaica']: 0,
                                                    }))
                                                }
                                                checked={payload?.loaica === 0}
                                            />
                                            <label className="custom-control-label" htmlFor="loaica">
                                                Ca ngày
                                            </label>
                                        </div>
                                    </div>
                                    <div className="g">
                                        <div className="custom-control custom-radio">
                                            <input
                                                type="radio"
                                                className="custom-control-input"
                                                name="loaica"
                                                id="loaica1"
                                                onChange={(e) =>
                                                    setPayload((prev) => ({
                                                        ...prev,
                                                        ['loaica']: 1,
                                                    }))
                                                }
                                                checked={payload?.loaica === 1}
                                            />
                                            <label className="custom-control-label" htmlFor="loaica1">
                                                Ca đêm
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-12 mt-2">
                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                            Mô tả ca làm vệc
                        </label>
                        <Input
                            value={payload.motacalamviec}
                            onChange={(e) => setPayload((prev) => ({ ...prev, ['motacalamviec']: e.target.value }))}
                            rows={'2'}
                            name="motacalamviec"
                            type="textarea"
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'motacalamviec') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'motacalamviec')?.message}
                            </small>
                        )}
                    </div>

                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Giờ vào làm việc'}
                            value={payload.giovaolamviec}
                            setValue={setPayload}
                            keyPayload={'giovaolamviec'}
                            type="time"
                            onChange={handleRenderTime}
                            validate
                        />
                    </div>

                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Giờ kết thúc làm việc'}
                            value={payload.gioketthuclamviec}
                            setValue={setPayload}
                            keyPayload={'gioketthuclamviec'}
                            type="time"
                            onChange={handleRenderTime}
                            validate
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Bắt đầu giờ vào để hiểu ca'}
                            value={payload.batdaugiovaodehieuca}
                            setValue={setPayload}
                            keyPayload={'batdaugiovaodehieuca'}
                            type="time"
                            onChange={handleRenderTime}
                            validate
                        />
                    </div>

                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Kết thúc giờ vào để hiểu ca'}
                            value={payload.ketthucgiovaodehieuca}
                            setValue={setPayload}
                            keyPayload={'ketthucgiovaodehieuca'}
                            type="time"
                            onChange={handleRenderTime}
                            validate
                        />
                    </div>

                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Bắt đầu giờ ra để hiểu ca'}
                            value={payload.batdaugioradehieuca}
                            setValue={setPayload}
                            keyPayload={'batdaugioradehieuca'}
                            type="time"
                            onChange={handleRenderTime}
                            validate
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Kết thúc giờ ra để hiểu ca'}
                            value={payload.ketthucgioradehieuca}
                            setValue={setPayload}
                            keyPayload={'ketthucgioradehieuca'}
                            type="time"
                            onChange={handleRenderTime}
                            validate
                        />
                    </div>

                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Giờ bắt nghỉ giữa ca'}
                            value={payload.giobatdauantrua}
                            setValue={setPayload}
                            keyPayload={'giobatdauantrua'}
                            type="time"
                            onChange={handleRenderTime}
                        />
                    </div>

                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Giờ kết thúc nghỉ giữa ca'}
                            value={payload.gioketthucantrua}
                            setValue={setPayload}
                            keyPayload={'gioketthucantrua'}
                            type="time"
                            onChange={handleRenderTime}
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Tổng giờ'}
                            value={payload.tonggio}
                            setValue={setPayload}
                            keyPayload={'tonggio'}
                            type="number"
                            validate
                        />
                    </div>

                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Đếm công'}
                            value={payload.demcong}
                            setValue={setPayload}
                            keyPayload={'demcong'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Số lần chuyển ngày tự nhiên'}
                            value={payload.solanchuyenngaytunhien}
                            setValue={setPayload}
                            keyPayload={'solanchuyenngaytunhien'}
                            type="number"
                        />
                    </div>
                    {/* <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Công khi không có giờ ra'}
                            value={payload.khongcogiorathitinhtong}
                            setValue={setPayload}
                            keyPayload={'khongcogiorathitinhtong'}
                            type='number'
                        />
                    </div> */}
                    {/* <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Công khi không có giờ vào'}
                            value={payload.khongcogiovaothitinhtong}
                            setValue={setPayload}
                            keyPayload={'khongcogiovaothitinhtong'}
                            type='number'
                        />
                    </div> */}
                    <div className="form-group col-md-3">
                        <div className="form-group">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                Áp dụng tăng ca
                            </label>
                            <Select
                                value={payload?.isShift}
                                onChange={handleOnSelect}
                                options={shift}
                                placeholder={'Tùy chọn'}
                                name="isShift"
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'isShift') && (
                                <small>{invalidFields.find((i) => i.name === 'isShift')?.message}</small>
                            )}
                        </div>
                    </div>
                </div>
                <div className="mt-3">
                    <div className="form-group col-md-12">
                        <div className="pb-0">
                            <h6 className="text-primary">Quy định trừ giờ đi muộn</h6>
                            <div className="g-4 align-center flex-wrap">
                                <div className="g">
                                    <div className="custom-control custom-radio">
                                        <input
                                            type="radio"
                                            className="custom-control-input"
                                            name="phatdimuon"
                                            id="phatdimuon"
                                            onChange={(e) =>
                                                setPayload((prev) => ({
                                                    ...prev,
                                                    ['phatdimuon']: 0,
                                                }))
                                            }
                                            checked={payload?.phatdimuon === 0}
                                        />
                                        <label className="custom-control-label" htmlFor="phatdimuon">
                                            Không
                                        </label>
                                    </div>
                                </div>
                                <div className="g">
                                    <div className="custom-control custom-radio">
                                        <input
                                            type="radio"
                                            className="custom-control-input"
                                            name="phatdimuon"
                                            id="phatdimuon1"
                                            onChange={(e) =>
                                                setPayload((prev) => ({
                                                    ...prev,
                                                    ['phatdimuon']: 1,
                                                }))
                                            }
                                            checked={payload?.phatdimuon === 1}
                                        />
                                        <label className="custom-control-label" htmlFor="phatdimuon1">
                                            Có
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {payload?.phatdimuon === 1 && formDM?.length > 0 && (
                        <>
                            {formDM?.map((item, it) => {
                                return (
                                    <div className="row mt-1" key={it}>
                                        <div className="col-3">
                                            <div className="g-4 align-center flex-wrap">
                                                <div className="g">Từ </div>
                                                <div className="g">
                                                    <input
                                                        type="number"
                                                        name="tu"
                                                        className="form-control"
                                                        value={item.tu || 0}
                                                        onChange={(e) => handleChangeDM(it, e)}
                                                        // onFocus={() => setInvalidFieldsDm([])}
                                                    />
                                                    {invalidDm.length > 0 &&
                                                        invalidDm.some((i) => i.name === `tu${it}`) && (
                                                            <small className="text-danger">
                                                                {invalidDm.find((i) => i.name === `tu${it}`)?.message}
                                                            </small>
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                        {formDM.length !== it + 1 && (
                                            <div className="col-3">
                                                <div className="g-4 align-center flex-wrap">
                                                    <div className="g">Đến</div>
                                                    <div className="g">
                                                        <input
                                                            type="number"
                                                            name="den"
                                                            className="form-control"
                                                            value={item.den || 0}
                                                            onChange={(e) => handleChangeDM(it, e)}
                                                            // onFocus={() => setInvalidFieldsDm([])}
                                                        />
                                                        {invalidDm.length > 0 &&
                                                            invalidDm.some((i) => i.name === `den${it}`) && (
                                                                <small className="text-danger">
                                                                    {
                                                                        invalidDm.find((i) => i.name === `den${it}`)
                                                                            ?.message
                                                                    }
                                                                </small>
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        <div className="col-5">
                                            <div className="g-4 align-center flex-wrap">
                                                <div className="g">
                                                    {' '}
                                                    {formDM.length !== it + 1 ? 'Phút thì' : 'Phút trở lên thì'}
                                                </div>
                                                <div className="g">
                                                    <Select
                                                        value={item.Loaiphatdimuon}
                                                        onChange={(selected) => {
                                                            setFormDM((prev) => {
                                                                const updatedFormValues = [...prev];
                                                                updatedFormValues[it] = {
                                                                    ...updatedFormValues[it],
                                                                    Loaiphatdimuon: selected,
                                                                };
                                                                return updatedFormValues;
                                                            });
                                                        }}
                                                        options={SelectLoaiphatdimuon}
                                                        placeholder={'Không trừ công, Không tính đi muộn'}
                                                        name="phutthi"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            {formDM.length !== it + 1 && Number(it) !== 0 && (
                                                <button
                                                    onClick={() => removeFormFieldsDM(it)}
                                                    className={'btn btn-danger  badge text-white p-1'}
                                                >
                                                    <em className="icon ni ni-trash-alt"></em>
                                                </button>
                                            )}
                                        </div>
                                        {item?.Loaiphatdimuon?.value === 2 && (
                                            <div className="col-12 m-1 p-1 rounded border bg-light">
                                                <div className="row">
                                                    <div className="col-4">
                                                        <div className="g-4 align-center flex-wrap">
                                                            <div className="g">Giờ công bị trừ</div>
                                                            <div className="g">
                                                                <input
                                                                    type="number"
                                                                    name="giobitru"
                                                                    className="form-control"
                                                                    value={item.giobitru}
                                                                    onChange={(e) => handleChangeDM(it, e)}
                                                                    placeholder="0"
                                                                    // onFocus={() => setInvalidFieldsDm([])}
                                                                />
                                                                {invalidDm.length > 0 &&
                                                                    invalidDm.some(
                                                                        (i) => i.name === `giobitru_${it}`,
                                                                    ) && (
                                                                        <small className="text-danger">
                                                                            {
                                                                                invalidDm.find(
                                                                                    (i) => i.name === `giobitru_${it}`,
                                                                                )?.message
                                                                            }
                                                                        </small>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-4">
                                                        <div className="g-4 align-center flex-wrap">
                                                            <div className="g">Ngày công bị trừ</div>
                                                            <div className="g">
                                                                <input
                                                                    type="number"
                                                                    name="congbitru"
                                                                    className="form-control"
                                                                    value={item.congbitru || 0}
                                                                    onChange={(e) => handleChangeDM(it, e)}
                                                                    // onFocus={() => setInvalidFieldsDm([])}
                                                                />
                                                                {invalidDm.length > 0 &&
                                                                    invalidDm.some(
                                                                        (i) => i.name === `congbitru_${it}`,
                                                                    ) && (
                                                                        <small className="text-danger">
                                                                            {
                                                                                invalidDm.find(
                                                                                    (i) => i.name === `congbitru_${it}`,
                                                                                )?.message
                                                                            }
                                                                        </small>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </>
                    )}
                    {payload.phatdimuon === 1 && (
                        <>
                            <Button
                                size="md"
                                color="light"
                                outline
                                className="btn-white btn-dim btn-icon px-4 mt-2"
                                onClick={() => addFormFieldsDM()}
                            >
                                Thêm <Icon name="plus"></Icon>
                            </Button>
                        </>
                    )}
                </div>
                <div className="mt-3">
                    <div className="form-group col-md-12">
                        <div className="pb-0">
                            <h6 className="text-primary">Quy định trừ giờ về sớm</h6>
                            <div className="g-4 align-center flex-wrap">
                                <div className="g">
                                    <div className="custom-control custom-radio">
                                        <input
                                            type="radio"
                                            className="custom-control-input"
                                            name="phatvesom"
                                            id="phatvesom"
                                            onChange={(e) =>
                                                setPayload((prev) => ({
                                                    ...prev,
                                                    ['phatvesom']: 0,
                                                }))
                                            }
                                            checked={payload?.phatvesom === 0}
                                        />
                                        <label className="custom-control-label" htmlFor="phatvesom">
                                            Không
                                        </label>
                                    </div>
                                </div>
                                <div className="g">
                                    <div className="custom-control custom-radio">
                                        <input
                                            type="radio"
                                            className="custom-control-input"
                                            name="phatvesom"
                                            id="phatvesom1"
                                            onChange={(e) =>
                                                setPayload((prev) => ({
                                                    ...prev,
                                                    ['phatvesom']: 1,
                                                }))
                                            }
                                            checked={payload?.phatvesom === 1}
                                        />
                                        <label className="custom-control-label" htmlFor="phatvesom1">
                                            Có
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {payload?.phatvesom === 1 && formVS?.length > 0 && (
                        <>
                            {formVS?.map((item, it) => {
                                return (
                                    <div className="row mt-1" key={it}>
                                        <div className="col-3">
                                            <div className="g-4 align-center flex-wrap">
                                                <div className="g">Từ </div>
                                                <div className="g">
                                                    <input
                                                        type="number"
                                                        name="tu"
                                                        className="form-control"
                                                        value={item.tu || 0}
                                                        onChange={(e) => handleChangeVS(it, e)}
                                                        // onFocus={() => setInvalidFieldsVs([])}
                                                    />
                                                    {invalidVs.length > 0 &&
                                                        invalidVs.some((i) => i.name === `tu${it}`) && (
                                                            <small className="text-danger">
                                                                {invalidVs.find((i) => i.name === `tu${it}`)?.message}
                                                            </small>
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                        {formVS.length !== it + 1 && (
                                            <div className="col-3">
                                                <div className="g-4 align-center flex-wrap">
                                                    <div className="g">Đến</div>
                                                    <div className="g">
                                                        <input
                                                            type="number"
                                                            name="den"
                                                            className="form-control"
                                                            value={item.den || 0}
                                                            onChange={(e) => handleChangeVS(it, e)}
                                                            // onFocus={() => setInvalidFieldsVs([])}
                                                        />
                                                        {invalidVs.length > 0 &&
                                                            invalidVs.some((i) => i.name === `den${it}`) && (
                                                                <small className="text-danger">
                                                                    {
                                                                        invalidVs.find((i) => i.name === `den${it}`)
                                                                            ?.message
                                                                    }
                                                                </small>
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        <div className="col-5">
                                            <div className="g-4 align-center flex-wrap">
                                                <div className="g">
                                                    {' '}
                                                    {formVS.length !== it + 1 ? 'Phút thì' : 'Phút trở lên thì'}
                                                </div>
                                                <div className="g">
                                                    <Select
                                                        value={item.Loaiphatvesom}
                                                        onChange={(selected) => {
                                                            setFormVS((prev) => {
                                                                const updatedFormValues = [...prev];
                                                                updatedFormValues[it] = {
                                                                    ...updatedFormValues[it],
                                                                    Loaiphatvesom: selected,
                                                                };
                                                                return updatedFormValues;
                                                            });
                                                        }}
                                                        options={SelectLoaiphatvesom}
                                                        placeholder={'Không trừ công, Không tính về sớm'}
                                                        name="phutthi"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            {formVS.length !== it + 1 && Number(it) !== 0 && (
                                                <button
                                                    onClick={() => removeFormFieldsVS(it)}
                                                    className={'btn btn-danger  badge text-white p-1'}
                                                >
                                                    <em className="icon ni ni-trash-alt"></em>
                                                </button>
                                            )}
                                        </div>
                                        {item?.Loaiphatvesom?.value === 2 && (
                                            <div className="col-12 m-1 p-1 rounded border bg-light">
                                                <div className="row">
                                                    <div className="col-4">
                                                        <div className="g-4 align-center flex-wrap">
                                                            <div className="g">Giờ công bị trừ</div>
                                                            <div className="g">
                                                                <input
                                                                    type="number"
                                                                    name="giobitru"
                                                                    className="form-control"
                                                                    value={item.giobitru}
                                                                    onChange={(e) => handleChangeVS(it, e)}
                                                                    placeholder="0"
                                                                    // onFocus={() => setInvalidFieldsVs([])}
                                                                />
                                                                {invalidVs.length > 0 &&
                                                                    invalidVs.some(
                                                                        (i) => i.name === `giobitru_${it}`,
                                                                    ) && (
                                                                        <small className="text-danger">
                                                                            {
                                                                                invalidVs.find(
                                                                                    (i) => i.name === `giobitru_${it}`,
                                                                                )?.message
                                                                            }
                                                                        </small>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-4">
                                                        <div className="g-4 align-center flex-wrap">
                                                            <div className="g">Ngày công bị trừ</div>
                                                            <div className="g">
                                                                <input
                                                                    type="number"
                                                                    name="congbitru"
                                                                    className="form-control"
                                                                    value={item.congbitru || 0}
                                                                    onChange={(e) => handleChangeVS(it, e)}
                                                                    // onFocus={() => setInvalidFieldsVs([])}
                                                                />
                                                                {invalidVs.length > 0 &&
                                                                    invalidVs.some(
                                                                        (i) => i.name === `congbitru_${it}`,
                                                                    ) && (
                                                                        <small className="text-danger">
                                                                            {
                                                                                invalidVs.find(
                                                                                    (i) => i.name === `congbitru_${it}`,
                                                                                )?.message
                                                                            }
                                                                        </small>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </>
                    )}
                    {payload.phatvesom === 1 && (
                        <>
                            <Button
                                size="md"
                                color="light"
                                outline
                                className="btn-white btn-dim btn-icon px-4 mt-2"
                                onClick={() => addFormFieldsVS()}
                            >
                                Thêm <Icon name="plus"></Icon>
                            </Button>
                        </>
                    )}
                </div>
                <div className="col-12 mt-5">
                    <Button color="primary" onClick={handleSubmit}>
                        Cập nhật
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalEditCauhinhcalamviec;
