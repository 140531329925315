import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, InputForm} from '~/components/Form';
import * as actions from '~/store/actions';
import {dataSelectStatus} from '~/utils/Datacustom';
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import {validateCongThucLuong} from "~/request/validateCongThucLuong";
import {validateCauHinhNghiLe} from "~/request/validateCauHinhNghiLe";
import Textarea from "~/components/Form/Textarea";

const ModalCauhinhnghile = ({show, setShow, fieldList}) => {
    // const { congty } = useSelector(state => state.congty)
    const {phongban} = useSelector((state) => state.phongban);
    const {nhanvien} = useSelector((state) => state.nhanvien);
   
    const dispatch = useDispatch();
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayload] = useState(fieldList);
    useEffect(() => {
        dispatch(actions.getListNhanvien());
    }, []);
    useEffect(() => {
        show && setPayload(fieldList);
    }, [show]);
    
    const [listPhongban, setlistPhongban] = useState([]);
    const [listNhanvien, setListnhanvien] = useState([]);
    useEffect(() => {
        if (nhanvien) {
            setListnhanvien(nhanvien.filter(i => Number(i.TrangthaiId) === 1));
        }
        if (phongban) {
            let dataSelectPhongban = phongban.map((item) => ({label: item.phongban, value: item.id}));
            setlistPhongban(dataSelectPhongban);
        }
    }, [phongban, nhanvien]);
    const handleSubmit = async () => {
        let invalids = validate(payload);
        if (invalids === 0) {
            let listNhanvienbyphongban = [];
            let listPhongban = [];
            const dataPhongban = payload.PhongbanId;
            if (dataPhongban) {
                dataPhongban.forEach((phongban) => {
                    listNhanvien.filter((i) => i.PhongbanId === phongban.value).map(item => listNhanvienbyphongban.push(item.id));
                });
                listPhongban = dataPhongban.map(item => item.value)
            }
            
            let datapayload = {
                data: [
                    {
                        tieude: payload.tieude,
                        ngaybatdau: payload.ngaybatdau,
                        ngayketthuc: payload.ngayketthuc,
                        // giobatdau: payload.giobatdau,
                        // gioketthuc: payload.gioketthuc,
                        NhanvienId: listNhanvienbyphongban,
                        PhongbanId: listPhongban,
                        mota: payload.mota,
                        apdungtinhluong: payload.apdungtinhluong,
                        // giatri: payload.giatri,
                        TrangthaiId: payload.TrangthaiId.value,
                    },
                ],
            }
            // console.log('datapayload', datapayload)
            dispatch(actions.NewCauhinhnghile(datapayload))
            
        }
    };
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayload((prev) => ({...prev, [statename]: selected}));
    };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateCauHinhNghiLe(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateCauHinhNghiLe(item[0], item[1])]);
                invalids++;
            }
        });
        
        return invalids;
    };
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">Thêm mới cấu hình nghỉ lễ</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-12">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Tiêu đề'}
                            value={payload.tieude}
                            setValue={setPayload}
                            keyPayload={'tieude'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-12">
                        <Textarea
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Nội dung mô tả'}
                            value={payload.mota}
                            setValue={setPayload}
                            keyPayload={'mota'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-8">
                        <label htmlFor="inputZip" className="mb-2">
                            Phòng ban <b className="text-danger">(*)</b>
                        </label>
                        <Select
                            value={payload.PhongbanId}
                            onChange={handleOnSelect}
                            isMulti
                            options={listPhongban}
                            placeholder={'Chọn phòng ban'}
                            name="PhongbanId"
                            onFocus={() => setInvalidFields([])}
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'PhongbanId') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'PhongbanId')?.message}
                            </small>
                        )}
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="inputZip" className="mb-2">
                            Trạng thái <b className="text-danger">(*)</b>
                        </label>
                        <Select
                            value={payload.TrangthaiId}
                            onChange={handleOnSelect}
                            options={dataSelectStatus}
                            placeholder={'Chọn trạng thái'}
                            name="TrangthaiId"
                            onFocus={() => setInvalidFields([])}
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'TrangthaiId') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'TrangthaiId')?.message}
                            </small>
                        )}
                    </div>
                    <div className="form-group col-md-3 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ngày bắt đầu'}
                            value={payload.ngaybatdau}
                            setValue={setPayload}
                            keyPayload={'ngaybatdau'}
                            type="date"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ngày kết thúc'}
                            value={payload.ngayketthuc}
                            setValue={setPayload}
                            keyPayload={'ngayketthuc'}
                            type="date"
                            validate
                        />
                    </div>
                    <div className="form-group col-lg-12">
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            defaultChecked
                                            id="customCheck45"
                                            onChange={(e) =>
                                                setPayload((prev) => ({
                                                    ...prev,
                                                    ['apdungtinhluong']: e.target.checked === true ? 1 : 0,
                                                }))
                                            }
                                            checked={payload?.apdungtinhluong || false}
                                        />
                                        <label className="custom-control-label" htmlFor="customCheck45">
                                            Áp dụng tính lương lễ
                                        </label>
                                    </div>
                                </div>
                    {/* <div className="form-group col-md-3 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Giờ bắt đầu'}
                            value={payload.giobatdau}
                            setValue={setPayload}
                            keyPayload={'giobatdau'}
                            type="time"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Giờ kết thúc'}
                            value={payload.gioketthuc}
                            setValue={setPayload}
                            keyPayload={'gioketthuc'}
                            type="time"
                            validate
                        />
                    </div> */}
                   
                    
                </div>
                
                <div className="col-12 mt-5">
                    <Button text="Thêm mới" bClass="btn btn-primary px-5" textColor onClick={handleSubmit}/>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalCauhinhnghile;
