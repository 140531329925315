import actionTypes from './actionTypes'
import { apiGetPhanca, apiNewPhanca, apiEditPhanca, apiDeletePhanca } from '~/services/phanca'
import { toast } from "react-toastify";
export const getListPhanca = () => async (dispatch) => {
    try {
        const response = await apiGetPhanca()
        // console.log('chek api lisst Phanca', response?.data.success)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_PHAN_CA,
                phanca: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_PHAN_CA,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_PHAN_CA,
            phanca: null
        })
    }
}

export const NewPhanca = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiNewPhanca(payload)
            // console.log('chek api apiNewPhanca', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_PHAN_CA,
                    msg: 'OK'
                });
                dispatch(getListPhanca());
                toast.success("Thêm mới thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_PHAN_CA,
                    msg: 'ERR'
                })
                toast.error("Thêm mới thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_PHAN_CA,
                phanca: null
            })
        }
    }
}
export const EditPhanca = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditPhanca(payload)
            // console.log('chek api apiEditPhanca', response)
            if (response?.data.success === 200) {
                dispatch(getListPhanca());
                toast.success("Sửa thông tin thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_PHAN_CA,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_PHAN_CA,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_PHAN_CA,
                phanca: null
            })
        }
    }
}
export const DeletePhanca = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeletePhanca(payload)
            // console.log('chek api apiDeletePhanca', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListPhanca());
                toast.success("Xóa thông tin thành công!")
                dispatch({
                    type: actionTypes.DELETE_PHAN_CA,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_PHAN_CA,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_PHAN_CA,
                phanca: null
            })
        }
    }
}