import React, {useEffect, useState, useLayoutEffect} from "react";
// import Pages from "../route/Index";
import Head from "./head/Head";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import * as actions from '~/store/actions'
import {Navigate, Outlet} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import config from '~/config';

function Layout() {
    const dispatch = useDispatch();
    const { isLoggedIn } = useSelector(state => state.auth);
    useEffect(() => {
      dispatch(actions.getUser());
      setTimeout(() => {
        isLoggedIn && dispatch(actions.getUserAll());
        isLoggedIn && dispatch(actions.getListCongTy());
        isLoggedIn && dispatch(actions.getListChinhanh());
        isLoggedIn && dispatch(actions.getListChucvu());
        isLoggedIn && dispatch(actions.getListPhongban());
        isLoggedIn && dispatch(actions.getListKieuluong());
        isLoggedIn && dispatch(actions.getListTaisan());
        isLoggedIn && dispatch(actions.getListCauhinhcalamviec());
        isLoggedIn && dispatch(actions.getListTaiKhoanNganHang());
        isLoggedIn && dispatch(actions.getListMayChamCong());
        isLoggedIn && dispatch(actions.getListNguoiPhuThuoc());
        isLoggedIn && dispatch(actions.getListPhucap());
        isLoggedIn && dispatch(actions.getListTamungluong());
        isLoggedIn && dispatch(actions.getListCauhinhtangca());
      }, 1000);
    }, [isLoggedIn, dispatch]);
    //Sidebar
    const [visibility, setVisibility] = useState(false);
    const [mobileView, setMobileView] = useState(false);
    const [themeState] = useState({
        main: "default",
        header: "theme",
        skin: "light",
    });
    
    useEffect(() => {
        viewChange();
    }, []);
    
    // Stops scrolling on overlay
    useLayoutEffect(() => {
        if (visibility) {
            document.body.style.overflow = "hidden";
            document.body.style.height = "100%";
        }
        if (!visibility) {
            document.body.style.overflow = "auto";
            document.body.style.height = "auto";
        }
    }, [visibility]);
    
    //Adds classes to body
    useEffect(() => {
        document.body.className = `nk-body bg-lighter npc-invest has-touch nk-nio-theme ${
            themeState.skin === "dark" ? "dark-mode" : ""
        }`;
    }, [themeState, visibility]);
    
    // function to toggle sidebar
    const toggleSidebar = (e) => {
        e.preventDefault();
        if (visibility === false) {
            setVisibility(true);
        } else {
            setVisibility(false);
        }
    };
    
    // function to change the design view under 1200 px
    const viewChange = () => {
        if (window.innerWidth < 992) {
            setMobileView(true);
        } else {
            setMobileView(false);
            setVisibility(false);
        }
    };
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    
    if (!isLoggedIn) return <Navigate to={`${config.home}`} replace={true}/>
    return (
        <React.Fragment>
            <Head title="Loading"/>
            <div className="nk-app-root">
                <div className="nk-wrap">
                    <Header
                        visibility={visibility}
                        toggleSidebar={toggleSidebar}
                        mobileView={mobileView}
                        theme={themeState.header}
                        fixed={true}
                    />
                    {/* <Pages /> */}
                    {/* <div className={cx('site-wrapper-reveal')}>{children}</div> */}
                    <Outlet/>
                    <Footer/>
                </div>
            </div>
        </React.Fragment>
    );
}


export default Layout;
