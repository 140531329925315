import React, { useState, useEffect } from 'react';
import Content from '~/layout/content/Content';
import Head from '~/layout/head/Head';
import LogoDark from '~/images/logo-dark2x.png';
import { Button, Icon, Block } from '~/components/Component';
import { invoiceData } from './Invoice';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { apiGetPhieusuatan, apiGetCongty, apiGetLoaisuatan } from '~/services';
import { format, parseISO } from 'date-fns';
import { NumericFormat } from 'react-number-format';
import {
    Spinner,
} from 'reactstrap';
const PrinBangtonghopsuatan = () => {
    const [data, setData] = useState([]);
    const [congty, setCongty] = useState(null);
    const [dataLoaisuatan, setDataLoaisuatan] = useState([]);
    const [dataPhieusuatan, setDataPhieusuatan] = useState([]);
    useEffect(() => {
        setTimeout(() => window.print(), 500);
    }, []);
    const [searchParams] = useSearchParams();
    useEffect(() => {
        const params = [];
        for (let entry of searchParams.entries()) {
            params.push(entry);
        }
        setInputValue(params[0][1]);
        const fetchData = async () => {
            const congty = await apiGetCongty();
            setCongty(congty?.data?.data?.rows[0]);
            const response = await apiGetLoaisuatan();
            setDataLoaisuatan(response?.data?.data?.rows);
            const response1 = await apiGetPhieusuatan();
            setDataPhieusuatan(response1?.data?.data?.rows);
        };
        fetchData().catch(console.error);
    }, []);
    const [inputValue, setInputValue] = useState(null);
    // console.log('inputValue', inputValue);
    const [daysInMonth, setDaysInMonth] = useState([]);
    // console.log('daysInMonth', daysInMonth);
    const vietnameseDaysOfWeek = ['Chủ Nhật', 'Thứ Hai', 'Thứ Ba', 'Thứ Tư', 'Thứ Năm', 'Thứ Sáu', 'Thứ Bảy'];
    const dataitemdate = (date) => {
        const foundItem = dataPhieusuatan?.find((item) => {
            const formattedDate = item.ngaytao ? format(parseISO(item.ngaytao), 'dd-MM-yyyy') : null;
            return formattedDate === date;
        });
        let a = null;
        if (foundItem !== undefined) {
            const coumtSL = (dataSL) => {
                let tongSoLuong = 0;
                for (let i = 0; i < dataSL.length; i++) {
                    const phanTu = dataSL[i];
                    tongSoLuong += parseInt(phanTu.Soluong, 10);
                }
                return tongSoLuong;
            };
            const coumtCp = (dataCp) => {
                let tongchiphi = 0;
                dataCp.forEach((item) => {
                    const laoiSa = dataLoaisuatan.find((itemSa) => {
                        return itemSa && Number(itemSa.id) === Number(item?.LoaisuatanId?.value);
                    });
                    if (laoiSa) {
                        tongchiphi += laoiSa.giatri * item?.Soluong || 0;
                    }
                });
                return tongchiphi;
            };
            // console.log('foundItem', foundItem);
            a = {
                id: foundItem?.id,
                ngaytao: foundItem?.ngaytao,
                nguoitao: foundItem?.User?.username,
                TrangthaiId: foundItem?.TrangthaiId,
                maphieu: foundItem?.maphieu,
                noidung: foundItem?.noidung,
                tongsoluong: coumtSL(foundItem?.noidung ? JSON.parse(foundItem?.noidung) : []),
                tongchiphi: coumtCp(foundItem?.noidung ? JSON.parse(foundItem?.noidung) : []),
            };
        }
        return a;
    };
    useEffect(() => {
        if (inputValue) {
            const [year, month] = inputValue.split('-');
            const formattedValue = `${month}-${year}`;

            const firstDayOfMonth = new Date(year, month - 1, 1);
            const lastDay = new Date(year, month, 0);

            const daysOfMonth = [];
            for (let day = firstDayOfMonth; day <= lastDay; day.setDate(day.getDate() + 1)) {
                // if (day.getDay() !== 0) {
                let days = new Date(day);
                const dayString = days.getDate().toString().padStart(2, '0');
                const monthString = (days.getMonth() + 1).toString().padStart(2, '0');
                const yearString = days.getFullYear();
                const formattedDate = `${dayString}-${monthString}-${yearString}`;

                daysOfMonth.push({
                    ngay: formattedDate,
                    thu: vietnameseDaysOfWeek[days.getDay()],
                    itemphieu: dataitemdate(formattedDate),
                });
                // }
            }
            const sumSl = daysOfMonth.reduce((total, item) => total + (item?.itemphieu?.tongsoluong || 0), 0);
            const sumCp = daysOfMonth.reduce((total, item) => total + (item?.itemphieu?.tongchiphi || 0), 0);
            const datato = daysOfMonth.filter((item) => item?.itemphieu !== null);
            // console.log('datato', datato)
            setCuontTongsoluong(sumSl);
            setCuontChiphi(sumCp);
            setDaysInMonth(datato);
            setMonth(formattedValue);
        }
    }, [inputValue, dataPhieusuatan]);
    const [month, setMonth] = useState(null);
    const [countTongsoluong, setCuontTongsoluong] = useState(0);
    const [CountTongchiphi, setCuontChiphi] = useState(0);
    return (
        <body className="bg-white">
            <Head title="Bảng tổng hợp suất ăn"></Head>
            <Content>
                <Block>
                    <div className="invoice invoice-print">
                        <div className="invoice-action">
                            <Button
                                size="lg"
                                color="primary"
                                outline
                                className="btn-icon btn-white btn-dim"
                                onClick={() => window.print()}
                            >
                                <Icon name="printer-fill"></Icon>
                            </Button>
                        </div>
                        <div className="invoice-wrap">
                            <div className="invoice-brand">
                                {congty && congty?.avatar !== null ? (
                                    <img src={congty?.avatar} alt="Avatar Image" className="rounded-4" />
                                ) : (
                                    <img src={LogoDark} alt="" />
                                )}
                            </div>

                            <div className="invoice-head">
                                <div className="invoice-contact">
                                    {/* <span className="overline-title">Công ty</span> */}
                                    <div className="invoice-contact-info">
                                        <h5 className="title">Công ty {congty?.loaihinhcongty}</h5>
                                        <ul className="list-plain">
                                            <li>
                                                <Icon name="map-pin-fill"></Icon>
                                                <span>{congty?.diachi}</span>
                                            </li>
                                            <li>
                                                <Icon name="call-fill"></Icon>
                                                <span>{congty?.dienthoai}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="invoice-desc">
                                    <h5 className="title">Bảng tổng hợp suất ăn</h5>
                                    <ul className="list-plain">
                                        <li className="invoice-date">
                                            <span>Tháng</span>:<span>{month}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="invoice-bills">
                                <div className="table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th className="w-5px">Stt</th>
                                                <th className="w-30">Ngày tạo phiếu</th>
                                                <th>Mã phiếu</th>
                                                <th>Người tạo</th>
                                                <th>Số lượng suất ăn</th>
                                                <th>Chi phí</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {daysInMonth.length > 0 ? (
                                                daysInMonth.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                {item?.thu}
                                                                {' ngày '}
                                                                {item?.ngay}
                                                            </td>
                                                            <td>
                                                                {item?.itemphieu?.maphieu
                                                                    ? item?.itemphieu?.maphieu
                                                                    : '_ _ _ _'}
                                                            </td>
                                                            <td>
                                                                {item?.itemphieu?.nguoitao
                                                                    ? item?.itemphieu?.nguoitao
                                                                    : '_ _ _ _ '}
                                                            </td>
                                                            <td>
                                                                {item?.itemphieu?.tongsoluong
                                                                    ? item?.itemphieu?.tongsoluong
                                                                    : 0}
                                                            </td>
                                                            <td>
                                                                {item?.itemphieu?.tongchiphi ? (
                                                                    <NumericFormat
                                                                        value={item?.itemphieu?.tongchiphi}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true}
                                                                        suffix={'đ'}
                                                                    />
                                                                ) : (
                                                                    0
                                                                )}
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td colSpan="6" className="text-center">
                                                    <Spinner color="primary" />
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                        {daysInMonth.length > 0 && (
                                            <tfoot>
                                                <tr>
                                                    <td colSpan="2"></td>
                                                    <td colSpan="2">Tổng số suất ăn</td>
                                                    <td>{countTongsoluong}</td>
                                                    <td>
                                                        <NumericFormat
                                                            value={CountTongchiphi}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            suffix={'đ'}
                                                        />
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        )}
                                    </table>
                                    <div className="nk-notes ff-italic fs-12px text-soft"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Block>
            </Content>
        </body>
    );
};

export default PrinBangtonghopsuatan;
