import actionTypes from './actionTypes'
import {
    apiGetPhucap,
    apiNewPhucap,
    apiEditPhucap,
    apiDeletePhucap,
    apiPostPhucapByNhanVien,
    apiPutPhucapByNhanVien, apiGetPhucapByNhanVien, apiDeletePhucapByNhanVien
} from '~/services/phucap'
import { toast } from "react-toastify";
import { getListNhanvienOne } from './nhanvien';
export const getListPhucap = () => async (dispatch) => {
    try {
        const response = await apiGetPhucap()
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_PHU_CAP,
                phucap: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_PHU_CAP,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_PHU_CAP,
            phucap: null
        })
    }
}


export const NewPhucap = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewPhucap', payload)
            const response = await apiNewPhucap(payload)
            // console.log('chek api apiNewPhucap', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_PHU_CAP,
                    msg: 'OK'
                });
                dispatch(getListPhucap());
                toast.success("Thêm mới phụ cấp thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_PHU_CAP,
                    msg: 'ERR'
                })
                toast.error("Thêm mới phụ cấp thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_PHU_CAP,
                phucap: null
            })
            toast.error("Phụ cấp đã tồn tại!")
        }
    }
}
export const EditPhucap = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditPhucap(payload)
            // console.log('chek api apiEditPhucap', response)
            if (response?.data.success === 200) {
                dispatch(getListPhucap());
                toast.success("Sửa thông tin phụ cấp thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_PHU_CAP,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_PHU_CAP,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin phụ cấp thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_PHU_CAP,
                phucap: null
            })
        }
    }
}
export const DeletePhucap = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeletePhucap(payload)
            // console.log('chek api apiDeletePhucap', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListPhucap());
                toast.success("Xóa thông tin phụ cấp thành công!")
                dispatch({
                    type: actionTypes.DELETE_PHU_CAP,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_PHU_CAP,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin phụ cấp thất bại,do đang có người dùng đang cấu hình!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_PHU_CAP,
                phucap: null
            })
        }
    }
}
export const getListPhucapNhanvien = (payload) => async (dispatch) => {
    try {
        const response = await apiGetPhucapByNhanVien(payload)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_PHU_CAP,
                phucap: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_PHU_CAP,
                msg: response.data.msg
            })
        }
        
    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_PHU_CAP,
            phucap: null
        })
    }
}
export const NewPhucapByNhanVien = (payload, uuid) => {
    return async (dispatch) => {
        try {
            const response = await apiPostPhucapByNhanVien(payload)
            if (response?.data[0].success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_PHU_CAP_NV,
                    msg: 'OK'
                });
                dispatch(getListNhanvienOne({uuid: uuid}));
                toast.success("Thêm mới thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_PHU_CAP_NV,
                    msg: 'ERR'
                })
                toast.error("Thêm mới thất bại!")
            }
            
        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_PHU_CAP,
                phucap: null
            })
        }
    }
}
export const EditPhucapByNhanVien = (payload, uuid) => {
    return async (dispatch) => {
        try {
            const response = await apiPutPhucapByNhanVien(payload)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.PUT_EDIT_PHU_CAP_NV,
                    msg: 'OK'
                });
                toast.success("Cập nhật thành công!")
                dispatch(getListNhanvienOne({uuid: uuid}));
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_PHU_CAP_NV,
                    msg: 'ERR'
                })
                toast.error("Cập nhật thất bại!")
            }
            
        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_PHU_CAP_NV,
                phucap: null
            })
        }
    }
}
export const DeletePhucapnhanvien = (payload, uuid) => {
    return async (dispatch) => {
        try {
            const response = await apiDeletePhucapByNhanVien(payload)
            if (response?.data.success === 200) {
                toast.success("Xóa phụ cấp thành công!")
                dispatch(getListNhanvienOne({uuid: uuid}));
            } else {
                toast.error("Cập nhật thất bại!")
            }
            
        } catch (error) {
            dispatch({
                phucap: null
            })
        }
    }
}