import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import bootstrapPlugin from "@fullcalendar/bootstrap5";
import viLocale from "@fullcalendar/core/locales/vi";
import DatePicker from "react-datepicker";
import { Popover, PopoverHeader, PopoverBody, ModalHeader, Modal, ModalBody,  Button } from "reactstrap";
import { useForm } from "react-hook-form";
import { Col, Row, RSelect } from "../../Component";
import { setDateForPicker } from "~/utils/Utils";
import { eventOptions, returnDate } from "./CalenderData";

const EventView = (event) => {
  // console.log('event', event)
  const [mouseEnter, setMouseEnter] = useState(false);
  const { title, extendedProps, publicId } = event.event.event._def;
  return (
    <React.Fragment>
      <div id={publicId} onMouseEnter={() => setMouseEnter(true)} onMouseLeave={() => setMouseEnter(false)}>
        {title}
      </div>{" "}
      <Popover placement="bottom" isOpen={mouseEnter} target={publicId}>
        <PopoverHeader>{title}</PopoverHeader>
      </Popover>
    </React.Fragment>
  );
};

const Calender_phanca = ({ events }) => {
  const [mockEvents, updateEvents] = useState(events);
  // console.log('mockEvents', mockEvents)
  useEffect(() => {
    updateEvents(events);
  }, [events]);

  

  return (
    <React.Fragment>
      <FullCalendar
        timeZone={"local"}
        locale={viLocale}
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin, bootstrapPlugin]}
        events={mockEvents}
        // eventClick={(info) => handleEventClick(info)}
        initialView="dayGridMonth"
        headerToolbar={{
          left: "title prev,next",
          center: null,
          // right: "today dayGridMonth,timeGridWeek,timeGridDay,listWeek",
          right: null,
        }}
        themeSystem="bootstrap5"
        height={800}
        contentHeight={780}
        eventContent={(e) => <EventView event={e} />}
        aspectRatio={3}
        editable={true}
        droppable={true}
      />

    </React.Fragment>
  );
};

export default Calender_phanca;
