export const validateQuyetDinh = (fieldName, value) => {
    let regNumber = /^[0-9\b]+$/;
    switch (fieldName) {
        case 'matusinh':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        
        case 'NhanvienId':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'ngayhieuluc':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống.'
                }
            }
            break;
        case 'PheduyetId':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống.'
                }
            }
            break;
        
        default:
            break;
    }
}