import React, {useState, useEffect, useMemo} from 'react';
import {ModalCauhinhtangca, ModalEditCauhinhtangca} from './Modal';
import Swal from 'sweetalert2';
import {
    Card,
} from 'reactstrap';
import Content from '~/layout/content/Content';
import {Block} from '~/components/block/Block';
import {
    Button,
    DataTable,
    Icon,
    PermissionUtils,
} from '~/components/Component';
import {MenuTangca} from './MenuTab/Menucontent';
import {Box, IconButton} from "@mui/material";
import {ExportToCsv} from "export-to-csv";
import {MaterialReactTable, useMaterialReactTable} from "material-react-table";
import {MRT_Localization_VI} from "material-react-table/locales/vi";
import {Delete as DeleteIcon, Edit as EditIcon} from "@mui/icons-material";
import {apiDeleteCauhinhtangca, apiGetCauhinhtangca} from "~/services";
import {toast} from "react-toastify";
import {dataPagination} from "~/utils/Datacustom";

const Cauhinhtangca = () => {
    const [show, setShow] = useState(false);
    const [showEditCauhinhtangca, setShowEditCauhinhtangca] = useState(false);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [data, setData] = useState([]);
    const fetchData = async () => {
        try {
            const datapayloads = {
                start: pagination.pageIndex || 0,
                size: pagination.pageSize || 10,
                globalFilter: 'id',
                sorting: 'desc',
            };
            const response = await apiGetCauhinhtangca(datapayloads)
            if (response?.data?.success === 200) {
                setData(response?.data?.data?.rows);
                setTotalRowCount(response?.data?.data?.count);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    //lấy giá trị từ thằng redux trả về
    useEffect(() => {
        fetchData().then(r => r)
    }, [show, showEditCauhinhtangca, pagination.pageSize, pagination.pageIndex]);
    
    const fieldList = {
        title: '',
        matangca: '',
        mota: '',
        giatri: '',
        giatriTien: '',
        giatriTienformat: '',
        TrangthaiId: '',
    };
    
    const [showPhanquyen, setShowPhanquyen] = useState({});
    let dataPermissionUtils = PermissionUtils('cauhinhtangca', 'all');
    useEffect(() => {
        if (dataPermissionUtils) {
            setShowPhanquyen(dataPermissionUtils);
        }
    }, [dataPermissionUtils]);
    
    const handleAddNewCauhinhtangca = async () => {
        setShow(true);
    };
    const [dataIdCauhinhtangca, setDataIdCauhinhtangca] = useState([]);
    const handleEditCauhinhtangca = async (data) => {
        setShowEditCauhinhtangca(true);
        await setDataIdCauhinhtangca(data);
    };
    const handleDeleteCauhinhtangca = (data) => {
        const {id, status} = data // TODO: status check xem có thể xóa hay không
        // console.log("req.body.id", id);
        Swal.fire({
            title: 'Bạn có chắc không?',
            text: 'Bạn sẽ không thể lấy lại khi xóa!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Không, bỏ qua!',
            confirmButtonText: 'Vâng, xóa nó đi!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const response = await apiDeleteCauhinhtangca(id)
                // console.log('response', response)
                if (response.data.success === 200) {
                    await Swal.fire('Đã xóa!', 'Bạn đã xóa thành công.', 'success');
                    fetchData().then(r => r)
                } else {
                    await Swal.fire('Xóa không thành công!', 'Có lỗi xảy ra.', 'error');
                }
            }
        });
    };
    const Example = () => {
        
        let dataAb = data;
        // Tạo dữ liêu export
        const dataExport = dataAb
        const columns = useMemo(
            () => [
                {
                    Header: '#', // Tiêu đề cột số thứ tự
                    header: 'STT', // Tiêu đề cột số thứ tự
                    id: 'rowNumber', // ID của cột
                    size: 50,
                    Cell: ({row}) => <div>{row?.index + 1}</div>, // Giá trị của cột là số thứ tự
                    enableSorting: false,
                    enablePinning: true,
                },
                {
                    accessorKey: 'title',
                    enablePinning: true,
                    header: 'Tiêu đề',
                },
                {
                    accessorKey: 'matangca',
                    enablePinning: true,
                    header: 'Mã tăng ca',
                },
                {
                    accessorKey: 'mota',
                    enablePinning: true,
                    header: 'Mô tả',
                },
                {
                    accessorKey: 'giatri',
                    enablePinning: true,
                    header: 'Giá trị(%)',
                },
                {
                    accessorKey: 'giatriTien',
                    enablePinning: true,
                    header: 'Giá trị (VND)',
                },
                
                {
                    accessorKey: 'TrangthaiId',
                    header: 'Trạng thái',
                    enablePinning: true,
                    Cell: ({cell}) => (
                        <Box
                            component="span"
                            sx={(theme) => ({
                                backgroundColor: cell?.getValue() === '1' ? '#1ee0ac' : '#e65100',
                                fontSize: '10px',
                                borderRadius: '0.25rem',
                                color: '#fff',
                                p: '0.25rem',
                            })}
                        >
                            {cell?.getValue() === '1' ? 'Hoạt Động' : 'Dừng Hoạt Động'}
                        </Box>
                    ),
                },
            
            
            ],
            [],
        );
        const csvOptions = {
            fileName: 'csv-data',
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            useBom: true,
            useKeysAsHeaders: false,
            headers: columns.map((c) => c.header),
        };
        
        
        const csvExporter = new ExportToCsv(csvOptions);
        
        const handleExportData = () => {
            if (dataExport.length === 0) {
                toast.error("Không có dữ liệu để xuất!")
                return;
            }
            csvExporter.generateCsv(dataExport);
        };
        
        const table = useMaterialReactTable({
                columns,
                data,
                enableColumnResizing: false,
                enableDensityToggle: false,
                initialState: {
                    columnPinning: {left: ['rowNumber', 'title'], right: ['mrt-row-actions']},
                    density: 'compact'
                },
                
                state: {
                    expanded: true,
                    columnFilters,
                    globalFilter,
                    isLoading: dataAb.length === 0,
                    pagination,
                    sorting,
                },
                displayColumnDefOptions: {
                    'mrt-row-actions': {
                        header: '##',
                        enableOrdering: true,
                        enablePinning: true,
                        enableColumnActions: true,
                    },
                },
                defaultDisplayColumn: {enableResizing: true},
                rowCount: totalRowCount,
                manualFiltering: true,
                manualPagination: true,
                manualSorting: true,
                onPaginationChange: setPagination,
                onColumnFiltersChange: setColumnFilters,
                onGlobalFilterChange: setGlobalFilter,
                onSortingChange: setSorting,
                localization: MRT_Localization_VI,
                positionActionsColumn: 'first',
                enableRowActions: true,
                enableColumnPinning: true,
                renderTopToolbarCustomActions: ({table}) => (
                    <Box sx={{display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap'}}>
                        {/* <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddNewCauhinhtangca}
                            sx={{mr: 2}}
                        >
                            + Thêm mới
                        </Button> */}
                        <Button
                            color="primary"
                            onClick={handleExportData}
                            variant="contained"
                        >
                            <Icon name="download-cloud me-2"></Icon> Xuất dữ liệu
                        </Button>
                    </Box>
                ),
                muiPaginationProps: {
                    rowsPerPageOptions: dataPagination,
                },
                renderRowActions: ({row, table}) => (
                    <div className={'w-100'}>
                        <IconButton
                            color="secondary"
                            onClick={() => {
                                handleEditCauhinhtangca(row.original).then(r => 'err');
                            }}
                        >
                            <EditIcon/>
                        </IconButton>
                        <IconButton
                            color="error"
                            onClick={() => {
                                handleDeleteCauhinhtangca({id: row.original.id, status: row.original.TrangthaiId});
                            }}
                        >
                            <DeleteIcon/>
                        </IconButton>
                    </div>
                
                ),
            }
        );
        
        
        return (
            <MaterialReactTable
                table={table}
            />
        );
    };
    return (
        <React.Fragment>
            {/*<Head title="Cấu hình ca làm việc, Cấu hình tăng ca"></Head>*/}
            <Content>
                <Card className="mt-3">
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner card-inner-lg px-4">
                            <h4>Cấu hình tăng ca</h4>
                            <MenuTangca/>
                        </div>
                    </DataTable>
                </Card>
                
                <Block>
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner position-relative card-tools-toggle">
                            <Example/>
                        </div>
                    </DataTable>
                
                </Block>
                
                <ModalCauhinhtangca show={show} fieldList={fieldList} setShow={() => setShow(false)}/>
                <ModalEditCauhinhtangca
                    show={showEditCauhinhtangca}
                    fieldList={fieldList}
                    setShow={() => setShowEditCauhinhtangca(false)}
                    dataIdCauhinhtangca={dataIdCauhinhtangca}
                />
            </Content>
        </React.Fragment>
    );
};

export default Cauhinhtangca;
