import actionTypes from "../actions/actionTypes";

const initState = {
    phucap: [],
    msg: '',
    count: 0,
    newphucap: []
}

const phucapReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.GET_LIST_PHU_CAP:
            return {
                ...state,
                phucap: action?.phucap?.rows || [],
                msg: 'Ok' || '',
                count: action?.phucap?.count || 0
            }
        case actionTypes.POST_NEW_PHU_CAP_NV:
        case actionTypes.PUT_EDIT_PHU_CAP_NV:
        case actionTypes.POST_NEW_PHU_CAP:
        case actionTypes.PUT_EDIT_PHU_CAP:
        case actionTypes.DELETE_PHU_CAP:
            return {
                ...state,
                msg: action.msg || '',
                newphucap: action?.newphucap || []
            }
        default:
            return state;
    }
    
}

export default phucapReducer