export const validateCauhinhcalamviec = (fieldName, value) => {
    let regNumber = /^[0-9\b]+$/;
    switch (fieldName) {
        case 'tieude':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
       
        case 'giovaolamviec':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'gioketthuclamviec':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'batdaugiovaodehieuca':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'ketthucgiovaodehieuca':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'batdaugioradehieuca':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'ketthucgioradehieuca':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'tonggio':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'demcong':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        default:
            break;
    }
}