export const validatePhongBan = (fieldName, value) => {
    switch (fieldName) {
        case 'phongban':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Phòng ban không được để trống'
                }
            }
            break;
        case 'motavitri':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Vị trí không được bỏ trống'
                }
            }
            break;
        default:
            break;
    }
}