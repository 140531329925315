import React, { useEffect, useMemo, useState } from 'react';
import {
    apiAddBangChamCongTongHopItem,
    apiAddBCLuong,
    apiEditBangchamcongtonghop,
    apiListBangChamCongTongHopItem,
    apiListGetLuong,
} from '~/services';
import { useDispatch, useSelector } from 'react-redux';
import Content from '~/layout/content/Content';
import { Block } from '~/components/block/Block';
import {
    Button,
    Col,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    Icon,
    PaginationComponent,
    Row,
    RSelect,
    TooltipComponent,
    PermissionUtils,
    ExportXlsx,
} from '~/components/Component';
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalBody,
    UncontrolledDropdown,
    Spinner,
    Card,
    Table
} from 'reactstrap';
import * as actions from '~/store/actions';

import { toast } from 'react-toastify';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_VI } from 'material-react-table/locales/vi';
import { Link, useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { ExportToCsv } from 'export-to-csv';
import config from '~/config';
import { format, parseISO, isSunday } from 'date-fns';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ExportSalaryXlsxDai from '~/components/xlsx/ExportSalaryXlsxDai';

const Chamcongtonghop = () => {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [dataAll, setDataAll] = useState([]);
    
    const [ckdaychuyen, setCkdaychuyen] = useState(false);
    // console.log('ckdaychuyen', ckdaychuyen)

    const { bangchamcongtonghop } = useSelector((state) => state.bangchamcongtonghop);
    const { bangchotcong } = useSelector((state) => state.bangchotcong);
    const [itemChotCong, setItemChotCong] = useState([]);
    const [Chamcongtonghop, setItemBangChamCongTongHop] = useState([]);
    const [bangChamCongTongHopId, setBangChamCongTongHopId] = useState(0);
    const [searchParams] = useSearchParams();
    useEffect(() => {
        dispatch(actions.getListBangchotcong()).then((r) => 'err');
        dispatch(actions.getListBangchamcongtonghop()).then((r) => 'err');
        setBangChamCongTongHopId(searchParams.get('bangcongtonghop'));
    }, [bangChamCongTongHopId]);

    const [arrngay, setDaysInMonth] = useState([]);
    const vietnameseDaysOfWeek = ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'];
    useEffect(() => {
        if (bangChamCongTongHopId !== 0 && bangchamcongtonghop.length > 0 && bangchotcong.length > 0) {
            const dataBangchamcongtonghop = bangchamcongtonghop?.find(
                (item) => item?.id === Number(bangChamCongTongHopId),
            );
            const dataBangchotcong = bangchotcong?.find(
                (item) => item?.id === Number(dataBangchamcongtonghop?.BangchotcongId),
            );
            setItemBangChamCongTongHop(dataBangchamcongtonghop);
            setItemChotCong(dataBangchotcong);
            fetchData(Number(bangChamCongTongHopId)).then((r) => 'Error');

            const firstDayOfMonth = new Date(dataBangchamcongtonghop?.Bangchotcong?.ngaybatdau);
            const lastDay = new Date(dataBangchamcongtonghop?.Bangchotcong?.ngayketthuc);
            const daysOfMonth = [];
            for (let day = firstDayOfMonth; day <= lastDay; day.setDate(day.getDate() + 1)) {
                let days = new Date(day);
                daysOfMonth.push({
                    thu: vietnameseDaysOfWeek[days.getDay()],
                });
            }
            setDaysInMonth(daysOfMonth);
        }
    }, [bangChamCongTongHopId, bangchotcong, bangchamcongtonghop]);

    async function fetchData(dataInput) {
        try {
            const re = await apiListBangChamCongTongHopItem({ BangchamcongtonghopId: dataInput });
            const bangLuongTongHop = re?.data?.data?.rows;
            setData(bangLuongTongHop);
            setDataAll(bangLuongTongHop);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const handleSubmit = async () => {
        try {
            const update = await apiAddBangChamCongTongHopItem({
                BangchotcongId: Chamcongtonghop.BangchotcongId,
                BangchamcongtonghopId: Chamcongtonghop.id,
            });

            if (update.data.success === 200) {
                toast.success('Cập nhật thành công cho: ' + update.data.data?.length + ' nhân viên');
                fetchData(Chamcongtonghop.id);
            } else {
                toast.error(update.data.message);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleSubmitBangLuong = async () => {
        try {
            const bangChamCongTH = Chamcongtonghop;
            if (bangChamCongTH && bangChamCongTH.TrangthaiId === 0) {
                toast.error('Bảng Chấm công tổng hợp chưa khóa không thể tính lương.');
                return;
            }
            const bangChamCongTongHopId = bangChamCongTH.id;
            let datapayloadPayroll = {};
            datapayloadPayroll.BangchamcongtonghopId = Number(bangChamCongTongHopId);
            const luongNhanVien = await apiAddBCLuong(datapayloadPayroll);
            // console.log('luongNhanVien', luongNhanVien);
            if (luongNhanVien.data.success === 200) {
                toast.success(luongNhanVien.data.message);
            } else {
                toast.error(luongNhanVien.data.message);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleLock = async (itemChotCong) => {
        if (itemChotCong && itemChotCong?.TrangthaiId !== undefined) {
            let datapayload = {
                id: itemChotCong?.id,
                TrangthaiId: itemChotCong?.TrangthaiId === 1 ? 0 : 1,
            };
            const response = await apiEditBangchamcongtonghop(datapayload);
            if (response.data.success === 200) {
                toast.success(itemChotCong?.TrangthaiId === 1 ? 'Mở khóa thành công!!' : 'Khóa thành công!!');
                dispatch(actions.getListBangchamcongtonghop());
            }
        }
    };
    const [isClic, setIsClic] = useState(2);

    const columns = [
        {
            header: [
                {
                    id: 'g_rowNumber',
                    accessorKey: 'g_rowNumber',
                    Header: 'STT',
                    sortable: true,
                    rowspan: 2,
                    class: 'fixed-column',
                    class1: 'w-20px',
                },
                {
                    id: 'g_hoten',
                    accessorKey: 'g_hoten',
                    Header: 'Tên nhân viên',
                    sortable: true,
                    rowspan: 2,
                    class: `${isClic === 1 || 2 ? 'fixed-column1' : ''}`,
                    class1: 'w-150px',
                    fixed: 1,
                },
                {
                    id: 'g_mnv',
                    accessorKey: 'g_mnv',
                    Header: 'Mã nhân viên',
                    sortable: true,
                    rowspan: 2,
                    class: `${isClic === 2 ? 'fixed-column2' : ''}`,
                    class1: 'w-120px',
                    fixed: 2,
                },
                ...arrngay.map((item, index) => ({
                    id: `g_${index + 1}`,
                    accessorKey: `g_${index + 1}`,
                    Header: index + 1,
                    sortable: true,
                    class: item.thu === 'CN' ? 'bg-warning' : '',
                })),
      
                
            ],
        },
        {
            header: [
                ...arrngay.map((item) => ({
                    id: `g_${item.thu}`,
                    accessorKey: `g_${item.thu}`,
                    Header: item.thu,
                    sortable: true,
                    class: item.thu === 'CN' ? 'bg-warning' : '',
                })),
                
            ],
        },
    ];

    if(!ckdaychuyen){
        columns[0].header.push(
            {
                id: 'g_cong',
                accessorKey: 'g_cong',
                Header: 'Công',
                sortable: true,
                colspan: 2,
            },
            {
                id: 'g_ps',
                accessorKey: 'g_ps',
                Header: 'PS',
                sortable: true,
            },
            {
                id: 'g_nl',
                accessorKey: 'g_nl',
                Header: 'NL',
                sortable: true,
            },
            {
                id: 'g_tangca',
                accessorKey: 'g_tangca',
                Header: 'Tăng ca',
                sortable: true,
                colspan: 3,
            },
            {
                id: 'g_vang',
                accessorKey: 'g_vang',
                Header: 'Vắng',
                sortable: true,
                colspan: 3,
            },
        );
        columns[1].header.push(
            {
                id: 'g_ngay',
                accessorKey: 'g_ngay',
                Header: 'Ngày',
                sortable: true,
            },
            {
                id: 'g_dem',
                accessorKey: 'g_dem',
                Header: 'Đêm',
                sortable: true,
            },
            {
                id: 'g_gioccongtru',
                accessorKey: 'g_gioccongtru',
                Header: '+- giờ',
                sortable: true,
            },
            {
                id: 'g_nl',
                accessorKey: 'g_nl',
                Header: '',
                sortable: true,
            },
            {
                id: 'g_nt',
                accessorKey: 'g_nt',
                Header: 'NT',
                sortable: true,
            },
            {
                id: 'g_cn',
                accessorKey: 'g_cn',
                Header: 'CN',
                sortable: true,
            },
            {
                id: 'g_tcnl',
                accessorKey: 'g_tcnl',
                Header: 'TCNL',
                sortable: true,
            },
            {
                id: 'g_np',
                accessorKey: 'g_np',
                Header: 'NP',
                sortable: true,
            },
            {
                id: 'g_nkl',
                accessorKey: 'g_nkl',
                Header: 'NKL',
                sortable: true,
            },
            {
                id: 'g_ntd',
                accessorKey: 'g_ntd',
                Header: 'NTD',
                sortable: true,
            },
        );
    }else{
        columns[0].header.push(
            {
                id: 'g_daychuyen',
                accessorKey: 'g_daychuyen',
                Header: 'Tổng TG Dây Chuyền',
                sortable: true,
                colspan: 1,
                class1: 'w-150px',
            },
        );
        columns[1].header.push(
            {
                id: 'g_daychuyen1',
                accessorKey: 'g_daychuyen1',
                Header: '',
                sortable: true,
                colspan: 1,
            },
        );
    }
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [sort, setSortState] = useState('');
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    let tong_congNgay = data.reduce((total, item) => total + item?.congNgay, 0);
    let tong_congDem = data.reduce((total, item) => total + item?.congDem, 0);
    let tong_gioPhatSinh = data.reduce((total, item) => total + item?.gioPhatSinh, 0);
    let tong_lamThemNgayThuong = data.reduce((total, item) => total + item?.lamThemNgayThuong, 0);
    let tong_lamThemChuNhat = data.reduce((total, item) => total + item?.lamThemChuNhat, 0);
    let tong_lamThemNgayLe = data.reduce((total, item) => total + item?.lamThemNgayLe, 0);
    let tong_nghiPhepChuan = data.reduce((total, item) => total + item?.nghiPhepChuan, 0);
    let tong_nghiKhongLuong = data.reduce((total, item) => total + item?.nghikhongluong, 0);
    let tong_nghiTuDo = data.reduce((total, item) => total + item?.nghiTuDo, 0);
    let tong_nghiLe = data.reduce((total, item) => {
        return total + (item?.nghiLeHuongLuong || 0) + (item?.nghiLeKhongLuong || 0);
    }, 0);
    let tong_gioDayChuyen = data.reduce((total, item) => total + item?.gioDayChuyen, 0);
    const handleExportData = () => {

        const header0 = [
            {
                value: Chamcongtonghop?.tieude || 'BẢNG CHẤM CÔNG TỔNG HỢP',
                colspan: columns[1].header.length + 3 || 40,
                rowspan: 1,
            },
        ];
        const header1 = columns[0].header.map((header) => {
            return {
                colspan: header.colspan || 1,
                rowspan: header.rowspan || 1,
                value: header.Header,
                color: header.class,
            };
        });
        let arrayheader2 = [
            { colspan: 1, rowspan: 1, value: 'STT' },
            { colspan: 1, rowspan: 1, value: 'Tên nhân viên' },
            { colspan: 1, rowspan: 1, value: 'Mã nhân viên' },
        ];
        const header2 = columns[1].header.map((header) => {
            arrayheader2.push({
                colspan: header.colspan || 1,
                rowspan: header.rowspan || 1,
                value: header.Header,
                color: header.class,
            });
        });
        const headerExport = [header0, header1, arrayheader2];
        data.length > 0 &&
            data.map((item, it) => {
                let data1 = [];
                data1.push({ colspan: 1, rowspan: 2, value: `${it + 1}` });
                data1.push({ colspan: 1, rowspan: 2, value: item?.Nhanvien?.hoten });
                data1.push({ colspan: 1, rowspan: 2, value: item?.Nhanvien?.uuid });
                item?.datangay !== null &&
                    JSON.parse(item?.datangay).map((items) => {
                        const a = isSunday(new Date(items?.ngay)) ? 'bg-warning' : '';
                        const b =
                            items?.kyhieu !== null
                                ? items?.kyhieu
                                : items?.congngay === 0 && items?.congdem === 0
                                ? 0
                                : items?.congngay !== 0 && items?.congdem === 0
                                ? items?.congngay
                                : items?.congngay === 0 && items?.congdem !== 0
                                ? `${items?.congdem} Đ`
                                : items?.congngay !== 0 && items?.congdem !== 0
                                ? `${items?.congngay}, ${items?.congdem} Đ`
                                : '';
                        data1.push({ colspan: 1, rowspan: 1, value: b, color: a });
                    });
                if(!ckdaychuyen){
                    data1.push({ colspan: 1, rowspan: 2, value: item?.congNgay });
                    data1.push({ colspan: 1, rowspan: 2, value: item?.congDem });
                    data1.push({ colspan: 1, rowspan: 2, value: item?.gioPhatSinh });
                    data1.push({ colspan: 1, rowspan: 2, value: Number(item?.nghiLeHuongLuong) +  Number(item?.nghiLeKhongLuong)});
                    data1.push({ colspan: 1, rowspan: 2, value: item?.lamThemNgayThuong });
                    data1.push({ colspan: 1, rowspan: 2, value: item?.lamThemChuNhat });
                    data1.push({ colspan: 1, rowspan: 2, value: item?.lamThemNgayLe });
                    data1.push({ colspan: 1, rowspan: 2, value: item?.nghiPhepChuan });
                    data1.push({ colspan: 1, rowspan: 2, value: item?.nghikhongluong });
                    data1.push({ colspan: 1, rowspan: 2, value: item?.nghiTuDo });
                }else{
                    data1.push({ colspan: 1, rowspan: 2, value: item?.gioDayChuyen });
                }
                
                let data2 = [];
                data2.push({ colspan: 1, rowspan: 1, value: `${it + 1}` });
                data2.push({ colspan: 1, rowspan: 1, value: item?.Nhanvien?.hoten });
                data2.push({ colspan: 1, rowspan: 1, value: item?.Nhanvien?.uuid });
                item?.datangay !== null &&
                    JSON.parse(item?.datangay).map((items) => {
                        const a = isSunday(new Date(items?.ngay)) ? 'bg-warning' : '';
                        if(!ckdaychuyen){
                            data2.push({ colspan: 1, rowspan: 1, value: items?.giotru, color: a });
                        }else{
                            data2.push({ colspan: 1, rowspan: 1, value: items?.giodaychuyen, color: a });
                        }
                    });
                if(!ckdaychuyen){
                    data2.push({ colspan: 1, rowspan: 1, value: item?.congNgay });
                    data2.push({ colspan: 1, rowspan: 1, value: item?.congDem });
                    data2.push({ colspan: 1, rowspan: 1, value: item?.gioPhatSinh });
                    data2.push({ colspan: 1, rowspan: 1, value: Number(item?.nghiLeHuongLuong) +  Number(item?.nghiLeKhongLuong) });
                    data2.push({ colspan: 1, rowspan: 1, value: item?.lamThemNgayThuong });
                    data2.push({ colspan: 1, rowspan: 1, value: item?.lamThemChuNhat });
                    data2.push({ colspan: 1, rowspan: 1, value: item?.lamThemNgayLe });
                    data2.push({ colspan: 1, rowspan: 1, value: item?.nghiPhepChuan });
                    data2.push({ colspan: 1, rowspan: 1, value: item?.nghikhongluong });
                    data2.push({ colspan: 1, rowspan: 1, value: item?.nghiTuDo });
                }else{
                    data2.push({ colspan: 1, rowspan: 1, value: item?.gioDayChuyen });
                }
                headerExport.push(data1, data2);
            });

        let data3 = [];
        data3.push({ colspan: 3, rowspan: 1, value: 'Tổng' });
        arrngay.length > 0 &&
            arrngay.map((item) => {
                data3.push({ colspan: 1, rowspan: 1, value: '' });
            });
        if(!ckdaychuyen){
            data3.push({ colspan: 1, rowspan: 1, value: tong_congNgay });
            data3.push({ colspan: 1, rowspan: 1, value: tong_congDem });
            data3.push({ colspan: 1, rowspan: 1, value: tong_gioPhatSinh });
            data3.push({ colspan: 1, rowspan: 1, value: tong_nghiLe });
            data3.push({ colspan: 1, rowspan: 1, value: tong_lamThemNgayThuong });
            data3.push({ colspan: 1, rowspan: 1, value: tong_lamThemChuNhat });
            data3.push({ colspan: 1, rowspan: 1, value: tong_lamThemNgayLe });
            data3.push({ colspan: 1, rowspan: 1, value: tong_nghiPhepChuan });
            data3.push({ colspan: 1, rowspan: 1, value: tong_nghiKhongLuong });
            data3.push({ colspan: 1, rowspan: 1, value: tong_nghiTuDo });
        }else{ 
            data3.push({ colspan: 1, rowspan: 1, value: tong_gioDayChuyen });
        }
        headerExport.push(data3);
        // console.log('dataex', dataex)
        // console.log('headerExport', headerExport);
        const xlsxOptions = {
            namefile: 'bang-cong',
            headers: headerExport,
            // dataex: dataex,
        };
        const exportFunction = ExportSalaryXlsxDai(xlsxOptions);
        exportFunction().then((r) => 'err');
    };

    const [sm, updateSm] = useState(false);
    const [tablesm, updateTableSm] = useState(false);
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState('');
    const onFilterChange = (e) => {
        setSearchText(e.target.value);
    };
   
    const toggle = () => setonSearch(!onSearch);
    useEffect(() => {
        
        if (onSearchText !== '' && data?.length > 0) {
            const filteredObject = data?.filter((item) => {
                return (
                    item?.Nhanvien?.hoten.toLowerCase().includes(onSearchText.toLowerCase()) ||
                    item?.Nhanvien?.uuid.toLowerCase().includes(onSearchText.toLowerCase())

                );
            });

            setData([...filteredObject]);
        } else {
            setData([...dataAll]);
        }
    }, [onSearchText, setData]);

    const itemsPerPageOptions = [10, 20, 30, 40, 50, 100, 300, 500];

    return (
        <React.Fragment>
            {/*<Head title="User List - Regular"></Head>*/}
            <Content>
                <Card className="mt-0">
                    <div className="card-aside-wrap row">
                        <div className="card-inner card-inner-lg px-4 pt-1 pb-0">
                            <h4 className="mt-2 ms-2"> {Chamcongtonghop?.tieude}</h4>

                        </div>
                    </div>
                </Card>
                <Block>
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner position-relative card-tools-toggle">
                            <div className="card-title-group">
                                <div className="card-title my-2">
                                    <Button color="danger" variant="contained" className="mx-1">
                                        <Link
                                            className={`nav-link`}
                                            to={`${config.he_thong}${config.bang_cham_cong_tong_hop}`}
                                        >
                                            <span className="fs-10px me-2">
                                                <Icon className="ni ni-back-arrow-fill"></Icon>
                                            </span>
                                            Quay lại
                                        </Link>
                                    </Button>

                                    {Chamcongtonghop?.TrangthaiId === 1 ? (
                                        <Button color="gray" variant="contained" className="mx-1">
                                            <Icon name="reload-alt me-2"></Icon> Cập nhật
                                        </Button>
                                    ) : (
                                        <Button
                                            color="primary"
                                            onClick={handleSubmit}
                                            variant="contained"
                                            className="mx-1"
                                        >
                                            <Icon name="update me-2"></Icon> Cập nhật
                                        </Button>
                                    )}

                                    {
                                        <Button
                                            color="primary"
                                            onClick={handleSubmitBangLuong}
                                            variant="contained"
                                            className="mx-1"
                                        >
                                            <Icon name="property-add me-2"></Icon> Tạo bảng lương
                                        </Button>
                                    }
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            name="giodaychuyen"
                                            id="giodaychuyen"
                                            defaultChecked={ckdaychuyen}
                                            onChange={(e) => setCkdaychuyen(e.target.checked)}
                                        />
                                        <label className="custom-control-label" htmlFor="giodaychuyen">
                                            Xem giờ dây chuyền
                                        </label>
                                    </div>
                                    <Button
                                        color="warning"
                                        onClick={() => handleLock(Chamcongtonghop)}
                                        variant="contained"
                                        className="mx-1"
                                    >
                                        <Icon
                                            name={Chamcongtonghop?.TrangthaiId === 1 ? 'lock' : 'unlock'}
                                            className="fs-26px"
                                        ></Icon>
                                    </Button>
                                    {/* <Button color="primary" onClick={handleExportData} variant="contained">
                                        <Icon name="download-cloud me-2"></Icon> Xuất dữ liệu
                                    </Button> */}
                                </div>
                                <div className="card-tools">
                                    <ul className="btn-toolbar gx-1">
                                        <li>
                                            <a
                                                href="#search"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    toggle();
                                                }}
                                                className="btn btn-icon search-toggle toggle-search"
                                            >
                                                <Icon name="search"></Icon>
                                            </a>
                                        </li>
                                        <li className="btn-toolbar-sep"></li>
                                        <li>
                                            <div className="toggle-wrap">
                                                <Button
                                                    className={`btn-icon btn-trigger toggle ${tablesm ? 'active' : ''}`}
                                                    onClick={() => updateTableSm(true)}
                                                >
                                                    <Icon name="menu-right"></Icon>
                                                </Button>
                                                <div className={`toggle-content ${tablesm ? 'content-active' : ''}`}>
                                                    <ul className="btn-toolbar gx-1">
                                                        <li className="toggle-close">
                                                            <Button
                                                                className="btn-icon btn-trigger toggle"
                                                                onClick={() => updateTableSm(false)}
                                                            >
                                                                <Icon name="arrow-left"></Icon>
                                                            </Button>
                                                        </li>
                                                      
                                                        <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle
                                                                    color="tranparent"
                                                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                                                >
                                                                    <Icon name="setting"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu end className="dropdown-menu-xs">
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Show</span>
                                                                        </li>
                                                                       {itemsPerPageOptions.map((item, index) => {
                                                                            return (
                                                                                <li
                                                                                    className={
                                                                                        itemPerPage === item
                                                                                            ? 'active'
                                                                                            : ''
                                                                                    }
                                                                                    key={index}
                                                                                >
                                                                                    <DropdownItem
                                                                                        tag="a"
                                                                                        href="#dropdownitem"
                                                                                        onClick={(ev) => {
                                                                                            ev.preventDefault();
                                                                                            setItemPerPage(item);
                                                                                            // handleReParamsClick();
                                                                                        }}
                                                                                    >
                                                                                        {item}
                                                                                    </DropdownItem>
                                                                                </li>
                                                                            );
                                                                        })}
                                                                    </ul>
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Order</span>
                                                                        </li>
                                                                        <li className={sort === 'dsc' ? 'active' : ''}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setSortState('dsc');
                                                                                    //   sortFunc("dsc");
                                                                                }}
                                                                            >
                                                                                DESC
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li className={sort === 'asc' ? 'active' : ''}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setSortState('asc');
                                                                                    //   sortFunc("asc");
                                                                                }}
                                                                            >
                                                                                ASC
                                                                            </DropdownItem>
                                                                        </li>
                                                                    </ul>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                                <div className="toggle-wrap nk-block-tools-toggle">
                                                    <Button color="primary" onClick={handleExportData} variant="contained">
                                                        <Icon name="download-cloud me-2"></Icon> Xuất dữ liệu
                                                    </Button>
                                                </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className={`mb-3 card-search search-wrap ${!onSearch && 'active'}`}>
                                <div className="card-body">
                                    <div className="search-content">
                                        <Button
                                            className="search-back btn-icon toggle-search active"
                                            onClick={() => {
                                                setSearchText('');
                                                toggle();
                                            }}
                                        >
                                            <Icon name="arrow-left"></Icon>
                                        </Button>
                                        <input
                                            type="text"
                                            className="border-transparent form-focus-none form-control"
                                            placeholder="Tìm kiếm"
                                            value={onSearchText}
                                            onChange={(e) => onFilterChange(e)}
                                        />
                                        <Button className="search-submit btn-icon">
                                            <Icon name="search"></Icon>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <Table striped bordered hover vertical>
                                    <thead>
                                        {columns?.map((column, i) => {
                                            const headerarr = column.header;
                                            return (
                                                <tr key={i}>
                                                    {headerarr.map((item, index) => {
                                                        // console.log('item', item)
                                                        return (
                                                            <th
                                                                key={index}
                                                                className={`align-middle text-center ${
                                                                    item?.class || ''
                                                                }`}
                                                                colSpan={item?.colspan || 1}
                                                                rowSpan={item?.rowspan || 1}
                                                            >
                                                                <span className={`sub-text ${item?.class1 || ''}`}>
                                                                    {item?.Header}{' '}
                                                                    {item?.fixed === index && (
                                                                        <Icon
                                                                            name={`award w-15px ${
                                                                                isClic === index
                                                                                    ? 'icon-clic'
                                                                                    : 'icon-visible'
                                                                            }`}
                                                                            onClick={() => setIsClic(index)}
                                                                        ></Icon>
                                                                    )}
                                                                </span>
                                                            </th>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })}
                                    </thead>
                                    <tbody>
                                        {currentItems?.length > 0
                                            ? currentItems.map((item, it) => {
                                                  const displayedIndex = itemPerPage * (currentPage - 1) + it + 1;
                                                  return (
                                                      <React.Fragment key={it}>
                                                          <tr>
                                                              <td
                                                                  className="align-middle text-center fixed-column"
                                                                  rowSpan="2"
                                                              >
                                                                  {displayedIndex}
                                                              </td>
                                                              <td
                                                                  className={`align-middle text-center fixed-column1`}
                                                                  rowSpan="2"
                                                              >
                                                                  {item?.Nhanvien?.hoten}
                                                              </td>
                                                              <td
                                                                  className={`align-middle text-center ${
                                                                      isClic === 2 ? 'fixed-column2' : ''
                                                                  }`}
                                                                  rowSpan="2"
                                                              >
                                                                  {item?.Nhanvien?.uuid}
                                                              </td>
                                                              {item?.datangay !== null &&
                                                                  JSON.parse(item?.datangay).map((items, its) => {
                                                                      const a = isSunday(new Date(items?.ngay))
                                                                          ? 'bg-warning'
                                                                          : '';
                                                                      return (
                                                                          <td className={`text-center ${a}`} key={its}>
                                                                              {items?.kyhieu !== null
                                                                                  ? items?.kyhieu
                                                                                  : items?.congngay === 0 &&
                                                                                    items?.congdem === 0
                                                                                  ? 0
                                                                                  : items?.congngay !== 0 &&
                                                                                    items?.congdem === 0
                                                                                  ? items?.congngay
                                                                                  : items?.congngay === 0 &&
                                                                                    items?.congdem !== 0
                                                                                  ? `${items?.congdem} Đ`
                                                                                  : items?.congngay !== 0 &&
                                                                                    items?.congdem !== 0
                                                                                  ? `${items?.congngay}, ${items?.congdem} Đ`
                                                                                  : ''}
                                                                          </td>
                                                                      );
                                                                  })}
                                                                  {!ckdaychuyen ? (
                                                                    <>
                                                                    <td className="align-middle text-center" rowSpan="2">
                                                                        {item?.congNgay}
                                                                    </td>
                                                                    <td className="align-middle text-center" rowSpan="2">
                                                                        {item?.congDem}
                                                                    </td>
                                                                    <td className="align-middle text-center" rowSpan="2">
                                                                        {item?.gioPhatSinh}
                                                                    </td>
                                                                    <td className="align-middle text-center" rowSpan="2">
                                                                        {Number(item?.nghiLeHuongLuong) + Number(item?.nghiLeKhongLuong)}
                                                                    </td>
                                                                    <td className="align-middle text-center" rowSpan="2">
                                                                        {item?.lamThemNgayThuong}
                                                                    </td>
                                                                    <td className="align-middle text-center" rowSpan="2">
                                                                        {item?.lamThemChuNhat}
                                                                    </td>
                                                                    <td className="align-middle text-center" rowSpan="2">
                                                                        {item?.lamThemNgayLe}
                                                                    </td>
                                                                    <td className="align-middle text-center" rowSpan="2">
                                                                        {item?.nghiPhepChuan}
                                                                    </td>
                                                                    <td className="align-middle text-center" rowSpan="2">
                                                                        {item?.nghikhongluong}
                                                                    </td>
                                                                    <td className="align-middle text-center" rowSpan="2">
                                                                        {item?.nghiTuDo}
                                                                    </td>
                                                                    </>
                                                                ) : (
                                                                    <td className="align-middle text-center" rowSpan="2">
                                                                        {item?.gioDayChuyen}
                                                                    </td>
                                                                )}
                                                              
                                                          </tr>
                                                          <tr>
                                                              {item?.datangay !== null &&
                                                                  JSON.parse(item?.datangay).map((items, its) => {
                                                                      const a = isSunday(new Date(items?.ngay))
                                                                          ? 'bg-warning'
                                                                          : '';
                                                                      return (
                                                                          <td className={`text-center ${a}`} key={its}>
                                                                              {!ckdaychuyen ? items?.giotru : items?.giodaychuyen}
                                                                          </td>
                                                                      );
                                                                  })}
                                                          </tr>
                                                      </React.Fragment>
                                                  );
                                              })
                                            : null}
                                        <tr>
                                            <td className="align-middle text-center" colSpan="3">
                                                Tổng
                                            </td>
                                            {arrngay.length > 0 &&
                                                arrngay.map((item) => {
                                                    return (
                                                        <td className="align-middle text-center" colSpan="1">
                                                        </td>
                                                    );
                                                })}
                                                {!ckdaychuyen ? (
                                                    <>
                                                    <td className="align-middle text-center" colSpan="1">
                                                        {tong_congNgay}
                                                    </td>
                                                    <td className="align-middle text-center" colSpan="1">
                                                        {tong_congDem}
                                                    </td>
                                                    <td className="align-middle text-center" colSpan="1">
                                                        {tong_gioPhatSinh}
                                                    </td>
                                                    <td className="align-middle text-center" colSpan="1">
                                                        {tong_nghiLe}
                                                    </td>
                                                    <td className="align-middle text-center" colSpan="1">
                                                        {tong_lamThemNgayThuong}
                                                    </td>
                                                    <td className="align-middle text-center" colSpan="1">
                                                        {tong_lamThemChuNhat}
                                                    </td>
                                                    <td className="align-middle text-center" colSpan="1">
                                                        {tong_lamThemNgayLe}
                                                    </td>
                                                    <td className="align-middle text-center" colSpan="1">
                                                        {tong_nghiPhepChuan}
                                                    </td>
                                                    <td className="align-middle text-center" colSpan="1">
                                                        {tong_nghiKhongLuong}
                                                    </td>
                                                    <td className="align-middle text-center" colSpan="1">
                                                        {tong_nghiTuDo}
                                                    </td>
                                                    </>
                                                ) : (
                                                    <td className="align-middle text-center" rowSpan="1">
                                                        {tong_gioDayChuyen}
                                                    </td>
                                                )}
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                            <div className="card-inner">
                                {currentItems.length > 0 ? (
                                    <PaginationComponent
                                        itemPerPage={itemPerPage}
                                        totalItems={data.length}
                                        paginate={paginate}
                                        currentPage={currentPage}
                                    />
                                ) : (
                                    <div className="text-center">
                                        <div>
                                            <Spinner color="primary" />
                                        </div>
                                        <span className="text-silent">Chưa có dữ liệu!</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </DataTable>
                </Block>
            </Content>
        </React.Fragment>
    );
};

export default Chamcongtonghop;
