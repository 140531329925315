import actionTypes from "../actions/actionTypes";
const initState = {
    loaiquydinhphucloi: [],
    msg: '',
    count: 0,
    newloaiquydinhphucloi: []
}

const loaiquydinhphucloiReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.GET_LIST_LOAI_QUY_DINH_PHUC_LOI:

            return {
                ...state,
                loaiquydinhphucloi: action?.loaiquydinhphucloi || [],
                msg: 'Ok' || '',
                count: action?.loaiquydinhphucloi?.length || 0
            }
        case actionTypes.POST_NEW_CONG_TY:
        case actionTypes.POST_NEW_LOAI_QUY_DINH_PHUC_LOI:
            return {
                ...state,
                msg: action.msg || '',
                newloaiquydinhphucloi: action?.newloaiquydinhphucloi || []
            }
        default:
            return state;
    }

}

export default loaiquydinhphucloiReducer