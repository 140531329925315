import actionTypes from './actionTypes'
import { apiGetKiemdinhatvsbhld, apiNewKiemdinhatvsbhld, apiEditKiemdinhatvsbhld, apiDeleteKiemdinhatvsbhld } from '~/services/kiemdinhatvsbhld'
import { toast } from "react-toastify";
export const getListKiemdinhatvsbhld = () => async (dispatch) => {
    try {
        const response = await apiGetKiemdinhatvsbhld()
        // console.log('chek api lisst Kiemdinhatvsbhld', response?.data.success)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_KIEM_DINH_ATVSBHLD,
                kiemdinhatvsbhld: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_KIEM_DINH_ATVSBHLD,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_KIEM_DINH_ATVSBHLD,
            kiemdinhatvsbhld: null
        })
    }
}

export const NewKiemdinhatvsbhld = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewKiemdinhatvsbhld', payload)
            const response = await apiNewKiemdinhatvsbhld(payload)
            // console.log('chek api apiNewKiemdinhatvsbhld', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_KIEM_DINH_ATVSBHLD,
                    msg: 'OK'
                });
                dispatch(getListKiemdinhatvsbhld());
                toast.success("Thêm mới kiểm định an toàn vệ sinh thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_KIEM_DINH_ATVSBHLD,
                    msg: 'ERR'
                })
                toast.error("Thêm mới kiểm định an toàn vệ sinh thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_KIEM_DINH_ATVSBHLD,
                kiemdinhatvsbhld: null
            })
        }
    }
}
export const EditKiemdinhatvsbhld = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditKiemdinhatvsbhld(payload)
            // console.log('chek api apiEditKiemdinhatvsbhld', response)
            if (response?.data.success === 200) {
                dispatch(getListKiemdinhatvsbhld());
                toast.success("Sửa thông tin kiểm định an toàn vệ sinh thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_KIEM_DINH_ATVSBHLD,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_KIEM_DINH_ATVSBHLD,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin kiểm định an toàn vệ sinh thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_KIEM_DINH_ATVSBHLD,
                kiemdinhatvsbhld: null
            })
        }
    }
}
export const DeleteKiemdinhatvsbhld = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteKiemdinhatvsbhld(payload)
            // console.log('chek api apiDeleteKiemdinhatvsbhld', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListKiemdinhatvsbhld());
                toast.success("Xóa thông tin kiểm định an toàn vệ sinh thành công!")
                dispatch({
                    type: actionTypes.DELETE_KIEM_DINH_ATVSBHLD,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_KIEM_DINH_ATVSBHLD,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin kiểm định an toàn vệ sinh thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_KIEM_DINH_ATVSBHLD,
                kiemdinhatvsbhld: null
            })
        }
    }
}