// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DefaultLayout_wrapper__tkNS0 {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.DefaultLayout_container__NgAo7 {\n  width: var(--default-layout-width);\n  max-width: 100%;\n  padding: 0 var(--default-layout-horizontal-spacer);\n  margin-top: var(--default-layout-header-height);\n  display: flex;\n}\n\n.DefaultLayout_content__e9Pwb {\n  flex: 1 1;\n}", "",{"version":3,"sources":["webpack://./src/layouts/DefaultLayout/DefaultLayout.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,kCAAA;EACA,eAAA;EACA,kDAAA;EACA,+CAAA;EACA,aAAA;AACJ;;AAEA;EACI,SAAA;AACJ","sourcesContent":[".wrapper {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.container {\n    width: var(--default-layout-width);\n    max-width: 100%;\n    padding: 0 var(--default-layout-horizontal-spacer);\n    margin-top: var(--default-layout-header-height);\n    display: flex;\n}\n\n.content {\n    flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "DefaultLayout_wrapper__tkNS0",
	"container": "DefaultLayout_container__NgAo7",
	"content": "DefaultLayout_content__e9Pwb"
};
export default ___CSS_LOADER_EXPORT___;
