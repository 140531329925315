export const validateChiNhanh = (fieldName, value) => {
    let regNumber = /^[0-9\b]+$/;
    switch (fieldName) {
        case 'chucvu':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Chức Vụ không được để trống'
                }
            }
            break;
       
        case 'PhongbanId':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Hãy chọn Phòng ban'
                }
            }
            break;
        case 'motavitri':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Mô tả vị trí không được để trống.'
                }
            }
            break;
        
        default:
            break;
    }
}