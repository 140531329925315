export const SelectLoaiquytrinh = [
    {
        label: 'Tăng ca',
        value: 0
    },
    {
        label: 'Dây chuyền',
        value: 1
    },
    {
        label: 'Đơn xin nghỉ',
        value: 2
    },
    {
        label: 'Đi muộn về sớm',
        value: 3
    },
    {
        label: 'Công tác',
        value: 4
    },
    {
        label: 'Cập nhật công',
        value: 5
    },
    {
        label: 'Phiếu ra cổng',
        value: 6
    },
    {
        label: 'Quyết định',
        value: 7
    },
]
export const dataSelectGioitinh = [
    {
        label: 'Nam',
        value: 0
    },
    {
        label: 'Nữ',
        value: 1
    },
    // {
    //     label: 'Giới tính khác',
    //     value: 2
    // },
]
export const SelectLoaiphatdimuon = [
    {
        label: 'Không trừ công, không tính đi muộn',
        value: 0
    },
    {
        label: 'Không trừ công, vẫn tính đi muộn',
        value: 1
    },
    {
        label: 'Trừ công theo số cố định',
        value: 2
    },
    {
        label: 'Trừ công theo số phút thực tế đi muộn',
        value: 3
    },
]
export const SelectLoaiphatvesom = [
    {
        label: 'Không trừ công, không tính về sớm',
        value: 0
    },
    {
        label: 'Không trừ công, vẫn tính về sớm',
        value: 1
    },
    {
        label: 'Trừ công theo số cố định',
        value: 2
    },
    {
        label: 'Trừ công theo số phút thực tế về sớm',
        value: 3
    },
]
/**
 * Returns x thêm trạng thái cho nghỉ phép.
 *
 * @param {string} label: tên option
 * @param {string} value: giá trị option
 * @return {string} color: màu ắc trạng thái
 */
export const dataStatusNghiphep = [
    {
        label: 'Tiếp nhận',
        value: 0,
        color: 'bg-success',
    },
    {
        label: 'Đang xử lý',
        value: 1,
        color: 'bg-success',
    },
    {
        label: 'Cần duyệt',
        value: 2,
        color: 'bg-success',
    },
    {
        label: 'Hoàn thành',
        value: 3,
        color: 'bg-success',
    },
    {
        label: 'Không duyệt',
        value: 4,
        color: 'bg-warning',
    }
]
/**
 * Returns x thêm trạng thái cho nhân viên.
 *
 * @param {string} label: tên option
 * @param {string} value: giá trị option
 * @return {string} color: màu ắc trạng thái
 */
export const dataSelectStatus = [
    {
        label: 'Chưa duyệt',
        value: 0,
        color: 'w-75 justify-content-center px-0 btn btn-warning',
    },
    {
        label: 'Đã Duyệt',
        value: 1,
        color: 'w-75 justify-content-center px-0 btn btn-success',
    }
]
/**
 * Returns x Các loại vấn đề chính liến quan đến các khoản phát sinh nhân viên.
 *
 * @param {string} label: tên option
 * @param {string} value: giá trị option
 */
export const typeProblemImportant = [
    {
        label: 'Hành chính',
        value: 1,
    },
    {
        label: 'Ủng hộ',
        value: 2,
    },
    {
        label: 'Loại khác',
        value: 3,
    }
]
/**
 * Returns x : Tăng ca hoặc không tăng ca.
 *
 * @param {string} label: tên option
 * @param {string} value: giá trị option
 */
export const dataShift = [
    {
        label: 'Không tăng ca',
        value: 0,
    },
    {
        label: 'Tăng ca',
        value: 1,
    }
]

/**
 * Returns x : Các thời điểm làm thêm giờ.
 *
 * @param {string} label: tên option
 * @param {string} value: giá trị option
 */
export const dataThoidiemlamthem = [
    {
        label: 'Mặc định ngày nghỉ',
        value: 0,
    },
    {
        label: 'Trước ca',
        value: 1,
    },
    {
        label: 'Sau ca',
        value: 2,
    },
    {
        label: 'Nghỉ giữa ca',
        value: 3,
    },
    {
        label: 'Ngày nghỉ',
        value: 4,
    },
];

export const dataSelectStatus1 = [
    {
        label: 'Chưa duyệt',
        value: 0,
    },
    {
        label: 'Đã Duyệt',
        value: 1,
    },
    {
        label: 'Đã khóa',
        value: 2,
    }
]
/**
 * Returns x giá trị pagination
 *
 * @param {array} pagination Array
 */
export const dataPagination = [10, 20, 30, 40, 50, 100, 200, 300, 400, 500, 1000]

/**
 * Returns list x
 *
 * @param {array} dataTypeOvertime Array
 */
export const dataTypeOvertime = [
    {label: 'Tính theo phần trăm', value: 0},
    {label: 'Tính theo số tiền quy định', value: 1},
]
/**
 * Returns x thêm trạng thái cho hợp đồng.
 *
 * @param {string} label: tên option
 * @param {string} value: giá trị option
 */
export const dataStatus = [
    {
        label: 'Chưa duyệt',
        value: 0,
    },
    {
        label: 'Đã Duyệt',
        value: 1,
    },
    {
        label: 'Hết hạn',
        value: 2,
    }
]
/**
 * Returns x thêm trạng thái giành riêng cho nhân viên .
 *
 * @param {string} label: tên option
 * @param {string} value: giá trị option
 * @return {string} color: màu ắc trạng thái
 */
export const statusCustommerActive = [
 
    {
        label: 'Đang làm việc chính thức',
        value: 1,
        color: 'w-75 justify-content-center px-0 btn btn-success',
    },
    {
        label: 'Đã nghỉ',
        value: 2,
        color: 'w-75 justify-content-center px-0 btn btn-success',
    },
    {
        label: 'Nghỉ thai sản',
        value: 3,
        color: 'w-75 justify-content-center px-0 btn btn-success',
    },
    {
        label: 'Thử việc',
        value: 4,
        color: 'w-75 justify-content-center px-0 btn btn-warning',
    }
]
/**
 * Returns x Các loại học vấn.
 *
 * @param {string} label: tên option
 * @param {string} value: giá trị option
 */
export const typeBangLuong = [
    {
        label: 'BẢNG THANH TOÁN LƯƠNG TOÀN CÔNG TY',
        value: 1,
        totalRow: 40,
    },
    {
        label: 'BẢNG THANH TOÁN LƯƠNG CBCNV NHÀ MÁY',
        value: 2,
        totalRow: 40,
    },
    {
        label: 'BẢNG THANH TOÁN LƯƠNG CÔNG NHÂN THỬ VIỆC ',
        value: 3,
        totalRow: 23,
    },
    {
        label: 'BẢNG THANH TOÁN LƯƠNG. BP: AROFARM',
        value: 4,
        totalRow: 31,
    },
    {
        label: 'BẢNG THANH TOÁN LƯƠNG CÔNG NHÂN SƠ CHẾ',
        value: 5,
        totalRow: 40,
    },
    {
        label: 'BẢNG THANH TOÁN LƯƠNG CÔNG NHÂN VỆ SINH',
        value: 6,
        totalRow: 40,
    }
]
/**
 * Returns x Các loại học vấn.
 *
 * @param {string} label: tên option
 * @param {string} value: giá trị option
 */
export const typeFormCut = [
    {
        label: 'Cắt',
        value: 0,
    },
    {
        label: 'Không',
        value: 1,
    },
    
]
/**
 * Returns x Các loại học vấn.
 *
 * @param {string} label: tên option
 * @param {string} value: giá trị option
 */
export const dataSelectHocVan = [
    {
        label: 'Tốt nghiệp THPT',
        value: 0,
    },
    {
        label: 'Trung Cấp',
        value: 1,
    },
    {
        label: 'Cao đẳng',
        value: 2,
    },
    {
        label: 'Đại học',
        value: 3,
    },
    {
        label: 'Loại khác',
        value: 4,
    }
]
/**
 * Returns x Các loại hợp đồng
 *
 * @param {string} label: Mã loại hơp đồng
 * @param {string} value: giá trị mã loại hơp đồng
 */
export const dataSelectMaLoaiHopDong = [
    {
        label: 'HD_THU_VIEC',
        value: 'HD_THU_VIEC',
    },
    {
        label: 'HD_LAO_DONG_CHINH_THUC',
        value: 'HD_LAO_DONG_CHINH_THUC',
    },
    {
        label: 'HD_LAO_DONG_THOI_VU',
        value: 'HD_LAO_DONG_THOI_VU',
    }
]
/**
 * Returns x Các loại trong quan
 *
 * @param {string} label: Mã loại quan hệ
 * @param {string} value: giá trị mã loại quan hệ
 */
export const dataSelectLoaiSuKien = [
    {
        order: 1,
        label: 'Du lịch',
        value: 'DU_LICH',
        className: 'fc-event-primary',
    },
    {
        order: 2,
        label: 'Team building',
        value: 'TEAM_BUILDING',
        className: 'fc-event-info',
    },
    {
        order: 3,
        label: 'Công ty',
        value: 'CONG_TY',
        className: 'fc-event-success',
    },
    {
        order: 4,
        label: 'Hội thảo',
        value: 'HOI_THAO',
        className: 'fc-event-warning',
    },
    {
        order: 5,
        label: 'Hội nghị',
        value: 'HOI_NGHI',
        className: 'fc-event-danger',
    },
    {
        order: 6,
        label: 'Cuộc họp',
        value: 'CUOC_HOP',
        className: 'fc-event-pink',
    },
    {
        order: 7,
        label: 'Công việc',
        value: 'CONG_VIEC',
        className: 'fc-event-primary-dim',
    },
    {
        order: 8,
        label: 'Đào tạo',
        value: 'DAO_TAO_NHAN_SU',
        className: 'fc-event-success-dim',
    },
    {
        order: 9,
        label: 'Tai nạn lao động',
        value: 'TAI_NAN',
        className: 'fc-event-success-dim',
    },
    {
        order: 10,
        label: 'Sự kiện khác',
        value: 'SU_KIEN_KHAC',
        className: 'fc-event-success-dim',
    }
]
export const dataSelectPhuongtien = [
    {
        label: 'Còn xe',
        value: 0,
    },
    {
        label: 'Hết xe',
        value: 1,
    }
]
export const dataSelectQuythuong = [
    {
        label: 'Thưởng từ quỹ',
        value: true,
    },
    {
        label: 'Thưởng nóng',
        value: false,
    },

]
export const dataSelectLoaiVanThu = [
    {
        label: 'Gửi đi',
        value: 0,
    },
    {
        label: 'Nhận vào',
        value: 1,
    },

]
export const dataSelectActive = [
    {
        label: 'Hoạt động',
        value: 1,
    },
    {
        label: 'Không Hoạt động',
        value: 0,
    },

]
export const dataSelectMauhopdong = [
    
    {
        label: 'Đang hoạt động',
        value: 0,
    },
    {
        label: 'Lưu trữ',
        value: 1,
    },
]
export const dataTruongMauhopdong = [
    { title: '{tenhopdong}', des: 'Tiêu đề hợp đồng' },
    { title: '{ngaytaohopdong}', des: 'Ngày tạo hợp đồng' },
    { title: '{nguoidaidienct}', des: 'Người đại diện' },
    { title: '{chucvuct}', des: 'Chức vụ trong công ty' },
    { title: '{dienthoaict}', des: 'Điện thoại công ty' },
    { title: '{masothuect}', des: 'Mã số thuế công ty' },
    { title: '{diachict}', des: 'Địa chỉ công ty' },
    { title: '{quoctichgiamdoc}', des: 'Quốc tịch giám đốc' },
    { title: '{nhanvien}', des: 'Nhân viên' },
    { title: '{quoctich}', des: 'Quốc tịch' },
    { title: '{ngaysinh}', des: 'Ngày sinh' },
    { title: '{dienthoai}', des: 'Điện thoại' },
    { title: '{diachi}', des: 'Địa chỉ' },
    { title: '{socmnd}', des: 'Thẻ căn cước' },
    { title: '{ngaycap}', des: 'Ngày cấp' },
    { title: '{noicap}', des: 'Nơi cấp' },
    { title: '{loaihopdong}', des: 'Loại hợp đồng' },
    { title: '{ngaybatdau}', des: 'Ngày bắt đầu' },
    { title: '{ngayketthuc}', des: 'Ngày kết thúc' },
    { title: '{chucvu}', des: 'Chức vụ' },
    { title: '{phongban}', des: 'Phòng ban' },
    { title: '{masothue}', des: 'Mã số thuế' },
    { title: '{mucluong}', des: 'Mức lương' },
]
export const dataConfirm = [
    {
        label: 'Áp dụng',
        value: 1,
    },
    {
        label: 'Không Áp dụng',
        value: 0,
    },

]
//  Các loại cộng trừ trong tính lương
export const dataTypeSalary = [
    {
        label: 'Thu nhập',
        value: 2,
        color: 'FF0707FF',
    },
    {
        label: 'Khấu trừ',
        value: 3,
        color: 'FFDD00FF',
    },
    {
        label: 'Thành phần cố định',
        value: 1,
        color: '003BFCFF',
    },
    {
        label: 'Tổng cộng',
        value: 4,
        color: 'FF9100FF',
    },
    {
        label: 'Thu nhập khác',
        value: 5,
        color: 'FF9100FF',
    },
    {
        label: 'Khấu trừ khác',
        value: 6,
        color: 'FF9100FF',
    },

]
export const dataSelectConfirm = [
    {
        label: 'Áp dụng',
        value: 1,
    },
    {
        label: 'Không áp dụng',
        value: 0,
    },

]

export const dataSelectLock = [
    {
        label: 'Đã khóa (Không chỉnh sửa)',
        value: 1,
    },
    {
        label: 'Chưa khóa (Có thể chỉnh sửa)',
        value: 0,
    },

]
export const dataSelectDanhGia = [
    {
        label: 'Đã đánh giá',
        value: 1,
    },
    {
        label: 'Đang đánh giá',
        value: 0,
    },

]
export const dataSelectBHXH = [
    {
        label: 'Không',
        value: 0,
    },
    {
        label: 'Có',
        value: 1,
    },

]
export const dataSelectCapPhat = [
    {
        label: 'Đã cấp',
        value: 1,
    },
    {
        label: 'Chưa cấp',
        value: 0,
    },

]

export const dataSelectPhuongthuc = [
    {
        label: 'Cộng',
        value: '+'
    },
    {
        label: 'Chia',
        value: '/'
    },
    {
        label: 'Phần trăm (%)',
        value: '%'
    },
    {
        label: 'Bằng',
        value: '='
    },
]

export const dataSelectKieuPhep = [
    {
        value: 0,
        label: 'Nghỉ phép tiêu chuẩn',
        type: 'PHEP_CHUAN',
    },
    {
        value: 1,
        label: 'Nghỉ không lương',
        type: 'PHEP_KHONG_LUONG',
    },
    {
        value: 2,
        label: 'Công tác',
        type: 'PHEP_CONG_TAC',
    },
    {
        value: 3,
        label: 'Thêm ngày công',
        type: 'THEM_NGAY_CONG',
    },
    {
        value: 4,
        label: 'Giảm ngày công',
        type: 'GIAM_NGAY_CONG',
    },
]
export const dataStatusGiaoviec = [
    {
        value: 0,
        label: 'Hỏa tốc',
    },
    {
        value: 1,
        label: 'Cao',
    },
    {
        value: 2,
        label: 'Trung bình',
    },
]
export const dataSelectNhomNV = [
    {
        value: 0,
        label: 'Nhóm Nhân Viên',
    },
    {
        value: 1,
        label: 'Nhóm Quản Lý',
    },
    {
        value: 2,
        label: 'Nhóm Khác',
    },
]
export const dataSelectDouutien = [
    {
        label: 'Cao',
        value: 0
    },
    {
        label: 'Trung bình',
        value: 1
    },
    {
        label: 'Thấp',
        value: 2
    }
]
export const dataSelectLevel = [
    {
        label: 'Level 0',
        value: 0
    },
    {
        label: 'Level 1',
        value: 1
    },
    {
        label: 'Level 2',
        value: 2
    }
]

export const dataUser = [
    {
        value: 0,
        label: 'Thêm',
    },
    {
        value: 1,
        label: 'Sửa',
    },
    {
        value: 2,
        label: 'Xóa',
    },
    {
        value: 3,
        label: 'Xem',
    }
]
export const nhomTaisan = [
    {
        id: 1,
        tenNhomtaisan: 'Nhóm văn phòng',
    },
    {
        id: 2,
        tenNhomtaisan: 'Nhóm kỹ thuật',
    },
    {
        id: 3,
        tenNhomtaisan: 'Nhóm khác',
    }
]
export const trangthaiTaisan = [
    {
        id: 0,
        tentrangthai: 'Chưa cấp phát',
    }, {
        id: 1,
        tentrangthai: 'Đang hoạt động',
    }
]
export const trangthaiCapPhat = [
    {
        id: 0,
        tentrangthai: 'Đã cấp phát',
    },
    {
        id: 1,
        tentrangthai: 'Thu hồi',
    },
    {
        id: 2,
        tentrangthai: 'Báo mất / Báo hỏng',
    }
]
export const moduleName = [
    {
        id: 1,
        value: 'quydinhphucloi',
        name: 'Quy định phúc lợi',
        type:[{name:'Trạng thái qd',value:'status'},{name:'Phân cấp qd',value:'level'},{name:'Trạng thái hoạt động qd',value:'active'}]
    }, {
        id: 2,
        value: 'vitrituyendung',
        name: 'Vị trí tuyển dụng',
        type:[{name:'Trạng thái vt',value:'status'},{name:'Phân cấp vt',value:'level'},{name:'Trạng thái hoạt động vt',value:'active'}]
    }, {
        id: 3,
        value: 'cauhinhnghile',
        name: 'Cấu hình nghỉ lễ',
        type:[{name:'Trạng thái ch',value:'status'},{name:'Phân cấp ch',value:'level'},{name:'Trạng thái hoạt động ch',value:'active'}]
    }, {
        id: 4,
        value: 'tangca',
        name: 'Tăng ca',
        type:[{name:'Trạng thái tc',value:'status'},{name:'Phân cấp tc',value:'level'},{name:'Trạng thái hoạt động tc',value:'active'}]
    }, {
        id: 5,
        value: 'congty',
        name: 'Công ty',
        type:[{name:'Trạng thái ct',value:'status'},{name:'Phân cấp ct',value:'level'},{name:'Trạng thái hoạt động ct',value:'active'}]
    }, {
        id: 6,
        value: 'ungvien',
        name: 'Ứng viên',
        type:[{name:'Trạng thái',value:'status'},{name:'Phân cấp',value:'level'},{name:'Trạng thái hoạt động',value:'active'}]
    }, {
        id: 7,
        value: 'tinhluongnv',
        name: 'Tính lương nhân viên',
        type:[{name:'Trạng thái',value:'status'},{name:'Phân cấp',value:'level'},{name:'Trạng thái hoạt động',value:'active'}]
    }, {
        id: 8,
        value: 'giaoviec',
        name: 'Giao việc',
        type:[{name:'Trạng thái',value:'status'},{name:'Phân cấp',value:'level'},{name:'Trạng thái hoạt động',value:'active'}]
    }, {
        id: 9,
        value: 'phucap',
        name: 'Phụ cấp',
        type:[{name:'Trạng thái',value:'status'},{name:'Phân cấp',value:'level'},{name:'Trạng thái hoạt động',value:'active'}]
    }, {
        id: 10,
        value: 'taisan',
        name: 'Tài sản',
        type:[{name:'Trạng thái',value:'status'},{name:'Phân cấp',value:'level'},{name:'Trạng thái hoạt động',value:'active'}]
    }, {
        id: 11,
        value: 'cauhinhtangca',
        name: 'Cấu hình tăng ca',
        type:[{name:'Trạng thái',value:'status'},{name:'Phân cấp',value:'level'},{name:'Trạng thái hoạt động',value:'active'}]
    }, {
        id: 12,
        value: 'dexuat',
        name: 'Đề xuất',
        type:[{name:'Trạng thái',value:'status'},{name:'Phân cấp',value:'level'},{name:'Trạng thái hoạt động',value:'active'}]
    }, {
        id: 13,
        value: 'nhanvien',
        name: 'Nhân viên',
        type: [{name:'Trạng thái1',value:'status'},{name:'Phân cấp1',value:'level'},{name:'Trạng thái hoạt động',value:'active'}]
    },
]
export const dataThuethunhapcanhan = [
    {
        value: 0,
        label: 'Không có thuế thu nhập cá nhân',
    },
    {
        value: 1,
        label: 'Tính theo biểu thuế lũy tiến từng phần',
    },
    {
        value: 2,
        label: 'Tính theo thuế suất toàn phần 10%',
    },
    
]
// export const filterStatus = [
//     { value: "Active", label: "Active" },
//     { value: "Pending", label: "Pending" },
//     { value: "Suspend", label: "Suspend" },
//   ];
  
//   export const filterRole = [
//     { value: "investor", label: "Investor" },
//     { value: "seller", label: "Seller" },
//     { value: "buyer", label: "Buyer" },
//   ];
  export const valueStatus = [
    { value: 0, label: "Giá trị = 0" },
    { value: 1, label: "Giá trị = 1" },
  ];

export const dataLoaiPhuCap = [
    {
        value: 'PC_KHAC',
        label: 'Phụ cấp nhân viên',
        description: 'Phụ cấp nhân viên: Đây là loại phụ cấp thông tường được cộng thêm vào lương',
    },
    {
        value: 'PC_CHUYEN_CAN',
        label: 'Phụ cấp chuyên cần (Áp dụng cho người đủ ĐK)',
        description: 'Phụ cấp chuyên cần: Đây là loại phụ cấp được áp dụng cho tất nhân viên đủ điều kiện tính chuyên cần',
    },
    

]

export const dataKieuPhepNhanVien = [
    {
        label: 'Nghỉ phép năm thường / năm',
        value: 0,
    },
    {
        label: 'Nghỉ phép năm điều kiện làm việc độc hại /năm',
        value: 1,
    },
    {
        label: 'Nghỉ phép năm lao động khuyết tật',
        value: 2,
    },
]
