import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '~/store/actions';
import config from '~/config';
import {
    Block,
    BlockContent,
    BlockDes,
    BlockHead,
    BlockTitle,
    Icon,
    Button,
    PreviewCard,
} from '~/components/Component';
import { Link } from 'react-router-dom';
import Logo from '~/images/logo.png';
import LogoDark from '~/images/logo-dark-color.png';
import PageContainer from '~/layout/page-container/PageContainer';
import Head from '~/layout/head/Head';
import { Form, Spinner, Alert } from 'reactstrap';
const Register = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const { isLoggedIn } = useSelector(state => state.auth)
    // console.log('isLoggedIn', isLoggedIn)
    const [invalidFields, setInvalidFields] = useState([]);
    // console.log('invalidFields', invalidFields);
    const [payload, setPayloadLogin] = useState({
        loaihinhcongty: '',
        masothue: '',
        diachi: '',
        dienthoai: '',
        username: '',
        email: '',
        password: '',
    });
    useEffect(() => {
        // isLoggedIn && navigate(`${config.he_thong + config.system}`)
        // setLoading(false);
        if (invalidFields.length === 0) {
            setLoading(false);
        }
    }, [invalidFields]);
    const [loading, setLoading] = useState(false);
    const [passState, setPassState] = useState(false);
    const handleSubmit = async () => {
        setLoading(true);
        let invalids = validate(payload);
        if (invalids === 0) {
            let datapayload = {
                loaihinhcongty: payload.loaihinhcongty,
                masothue: payload.masothue,
                diachi: payload.diachi,
                dienthoai: payload.dienthoai,
                username: payload.username,
                daidienphaply: payload.username,
                email: payload.email,
                password: payload.password,
                phanquyen: '1',
                permission_GroupId: '0',
            };
            //   console.log('check payload', datapayload)
            dispatch(actions.register(datapayload));
        }
    };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        
        fields.forEach((item) => {
            if (item[1] === '') {
                setInvalidFields((prev) => [
                    ...prev,
                    {
                        name: item[0],
                        message: 'Bạn không được bỏ trống trường này.',
                    },
                ]);
                invalids++;
            }
        });
        fields.forEach((item) => {
            switch (item[0]) {
                case 'password':
                    if (item[1].length < 6) {
                        setInvalidFields((prev) => [
                            ...prev,
                            {
                                name: item[0],
                                message: 'Mật khẩu phải có tối thiểu 6 kí tự.',
                            },
                        ]);
                        invalids++;
                    }
                    break;
                case 'masothue':
                    if (item[1].length < 10) {
                        setInvalidFields((prev) => [
                            ...prev,
                            {
                                name: item[0],
                                message: 'Mã số thuế phải có tối thiểu 10 kí tự.',
                            },
                        ]);
                        invalids++;
                    }
                    break;
                case 'dienthoai':
                    if (item[1].length < 10) {
                        setInvalidFields((prev) => [
                            ...prev,
                            {
                                name: item[0],
                                message: 'Điện thoại phải có tối thiểu 9 kí tự.',
                            },
                        ]);
                        invalids++;
                    }
                    break;
                case 'email':
                    const validateEmail = (email) => {
                        return email.match(
                          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        );
                      };
                    if (validateEmail(item[1]) === null) {
                        setInvalidFields((prev) => [
                            ...prev,
                            {
                                name: item[0],
                                message: 'Email không hợp lệ.',
                            },
                        ]);
                        invalids++;
                    }
                    break;
                default:
                    break;
            }
        });
        return invalids;
    };
    // Đăng nhập bằng nút enter
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };
    return (
        <React.Fragment>
            {/*<Head title="Register" />*/}
            <PageContainer>
                <Block className="nk-block-middle nk-auth-body wide-sm">
                    <div className="brand-logo pb-4 text-center">
                        <Link to={process.env.PUBLIC_URL + '/'} className="logo-link">
                            <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
                            <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
                        </Link>
                    </div>

                    <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
                        <BlockHead>
                            <BlockContent>
                                <BlockTitle tag="h4">Đăng ký</BlockTitle>
                                <BlockDes>
                                    <p>Tạo tài khoản mới dùng thử miễn phí 15 ngày.</p>
                                </BlockDes>
                            </BlockContent>
                        </BlockHead>

                        <Form className="is-alter">
                            <div className="form-group">
                                <div className="form-label-group">
                                    <label className="form-label" htmlFor="default-01">
                                        <span>
                                            Công ty <b className="text-danger">(*)</b>
                                        </span>
                                    </label>
                                </div>
                                <div className="form-control-wrap">
                                    <input
                                        type="text"
                                        id="default-01"
                                        name="loaihinhcongty"
                                        placeholder="Nhập tên công ty"
                                        className="form-control-lg form-control"
                                        value={payload.loaihinhcongty}
                                        onChange={(e) =>
                                            setPayloadLogin((prev) => ({ ...prev, ['loaihinhcongty']: e.target.value }))
                                        }
                                        onFocus={() => setInvalidFields([])}
                                    />
                                    {invalidFields.length > 0 &&
                                        invalidFields.some((i) => i.name === 'loaihinhcongty') && (
                                            <small className="text-danger">
                                                {invalidFields.find((i) => i.name === 'loaihinhcongty')?.message}
                                            </small>
                                        )}
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-label-group">
                                    <label className="form-label" htmlFor="default-01">
                                        <span>
                                            Địa chỉ <b className="text-danger">(*)</b>
                                        </span>
                                    </label>
                                </div>
                                <div className="form-control-wrap">
                                    <input
                                        type="text"
                                        id="default-01"
                                        name="diachi"
                                        placeholder="Nhập địa chỉ"
                                        className="form-control-lg form-control"
                                        value={payload.diachi}
                                        onChange={(e) =>
                                            setPayloadLogin((prev) => ({ ...prev, ['diachi']: e.target.value }))
                                        }
                                        onFocus={() => setInvalidFields([])}
                                    />
                                    {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'diachi') && (
                                        <small className="text-danger">
                                            {invalidFields.find((i) => i.name === 'diachi')?.message}
                                        </small>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <div className="form-label-group">
                                        <label className="form-label" htmlFor="default-01">
                                            <span>
                                                Mã số thuế <b className="text-danger">(*)</b>
                                            </span>
                                        </label>
                                    </div>
                                    <div className="form-control-wrap">
                                        <input
                                            type="text"
                                            id="default-01"
                                            name="masothue"
                                            placeholder="Nhập mã số thuế"
                                            className="form-control-lg form-control"
                                            value={payload.masothue}
                                            onChange={(e) =>
                                                setPayloadLogin((prev) => ({ ...prev, ['masothue']: e.target.value }))
                                            }
                                            onFocus={() => setInvalidFields([])}
                                        />
                                        {invalidFields.length > 0 &&
                                            invalidFields.some((i) => i.name === 'masothue') && (
                                                <small className="text-danger">
                                                    {invalidFields.find((i) => i.name === 'masothue')?.message}
                                                </small>
                                            )}
                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <div className="form-label-group">
                                        <label className="form-label" htmlFor="default-01">
                                            <span>
                                                Điện thoại <b className="text-danger">(*)</b>
                                            </span>
                                        </label>
                                    </div>
                                    <div className="form-control-wrap">
                                        <input
                                            type="text"
                                            id="default-01"
                                            name="dienthoai"
                                            placeholder="Nhập điện thoại"
                                            className="form-control-lg form-control"
                                            value={payload.dienthoai}
                                            onChange={(e) =>
                                                setPayloadLogin((prev) => ({ ...prev, ['dienthoai']: e.target.value }))
                                            }
                                            onFocus={() => setInvalidFields([])}
                                        />
                                        {invalidFields.length > 0 &&
                                            invalidFields.some((i) => i.name === 'dienthoai') && (
                                                <small className="text-danger">
                                                    {invalidFields.find((i) => i.name === 'dienthoai')?.message}
                                                </small>
                                            )}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <div className="form-label-group">
                                        <label className="form-label" htmlFor="default-01">
                                            <span>
                                                Họ và tên <b className="text-danger">(*)</b>
                                            </span>
                                        </label>
                                    </div>
                                    <div className="form-control-wrap">
                                        <input
                                            type="text"
                                            id="default-01"
                                            name="username"
                                            placeholder="Nhập họ tên của bạn"
                                            className="form-control-lg form-control"
                                            value={payload.username}
                                            onChange={(e) =>
                                                setPayloadLogin((prev) => ({ ...prev, ['username']: e.target.value }))
                                            }
                                            onFocus={() => setInvalidFields([])}
                                        />
                                        {invalidFields.length > 0 &&
                                            invalidFields.some((i) => i.name === 'username') && (
                                                <small className="text-danger">
                                                    {invalidFields.find((i) => i.name === 'username')?.message}
                                                </small>
                                            )}
                                    </div>
                                </div>

                                <div className="form-group col-md-6">
                                    <div className="form-label-group">
                                        <label className="form-label" htmlFor="default-01">
                                            <span>
                                                Email <b className="text-danger">(*)</b>
                                            </span>
                                        </label>
                                    </div>
                                    <div className="form-control-wrap">
                                        <input
                                            type="text"
                                            id="default-01"
                                            name="name"
                                            placeholder="Nhập địa chỉ email"
                                            className="form-control-lg form-control"
                                            value={payload.email}
                                            onChange={(e) =>
                                                setPayloadLogin((prev) => ({ ...prev, ['email']: e.target.value }))
                                            }
                                            onFocus={() => setInvalidFields([])}
                                        />
                                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'email') && (
                                            <small className="text-danger">
                                                {invalidFields.find((i) => i.name === 'email')?.message}
                                            </small>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-label-group">
                                    <label className="form-label" htmlFor="password">
                                        <span>
                                            Mật khẩu <b className="text-danger">(*)</b>
                                        </span>
                                    </label>
                                </div>
                                <div className="form-control-wrap">
                                    <a
                                        href="#password"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            setPassState(!passState);
                                        }}
                                        className={`form-icon lg form-icon-right passcode-switch ${
                                            passState ? 'is-hidden' : 'is-shown'
                                        }`}
                                    >
                                        <Icon name="eye" className="passcode-icon icon-show"></Icon>

                                        <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                                    </a>
                                    <input
                                        type={passState ? 'text' : 'password'}
                                        id="password"
                                        name="password"
                                        placeholder="Nhập mật khẩu"
                                        className={`form-control-lg form-control ${
                                            passState ? 'is-hidden' : 'is-shown'
                                        }`}
                                        value={payload.password}
                                        onChange={(e) =>
                                            setPayloadLogin((prev) => ({ ...prev, ['password']: e.target.value }))
                                        }
                                        onFocus={() => setInvalidFields([])}
                                        onKeyDown={(event) => handleKeyDown(event)}
                                    />
                                    {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'password') && (
                                        <small className="text-danger">
                                            {invalidFields.find((i) => i.name === 'password')?.message}
                                        </small>
                                    )}
                                </div>
                            </div>
                            <div className="form-group">
                                {loading ? (
                                    <Button size="lg" className="btn-block" type="button" color="primary">
                                        <Spinner size="sm" color="light" /> Đăng ký
                                    </Button>
                                ) : (
                                    <Button
                                        size="lg"
                                        className="btn-block"
                                        type="button"
                                        color="primary"
                                        onClick={handleSubmit}
                                    >
                                        Đăng ký
                                    </Button>
                                )}
                            </div>
                        </Form>
                        <div className="form-note-s2 text-center pt-4">
                            {' '}
                            Bạn đã có tài khoản? <Link to={`${process.env.PUBLIC_URL}/login`}>Vui lòng đăng nhập</Link>
                        </div>
                    </PreviewCard>
                </Block>
            </PageContainer>
        </React.Fragment>
    );
};

export default Register;
