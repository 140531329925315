import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Content from '~/layout/content/Content';
import {
    Card,
    Table,
    Modal,
    ModalBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    Tooltip,
} from 'reactstrap';
import {
    DataTableBody,
    DataTableHead,
    DataTableRow,
    DataTableItem,
    DataTable,
    Button,
    Icon,
} from '~/components/Component';
import {useSearchParams} from 'react-router-dom';
import {format, parseISO} from 'date-fns';
import {
    apiGetKehoachcuaxeByNhanVien,
    apiGetNguoiPhuThuoc,
    apiGetNhanvienById,
    apiGetPhucapByNhanVien,
    apiListBangChamCong, apiListBangChamCongByNhanVien, apiListBCLuongByNhanVien,
    apiListGetLuong,
    apiListTaiSanByNhanvien,
    apiDeleteMccByNhanVien, apiPayRollNhanVien, apiGetCauhinhchuyencan, apiGetCauhinhthamnien, apiGetCauhinhdaychuyen
} from '~/services';
import {InputForm} from '~/components/Form';
import Select from 'react-select';
import * as actions from '~/store/actions';
import {validateNhanVien} from '~/request/validateNhanVien';
import {
    dataSelectGioitinh,
    statusCustommerActive,
    dataKieuPhepNhanVien,
    dataSelectLock,
    dataTypeOvertime, dataSelectHocVan
} from '~/utils/Datacustom';
import {ModalAddTaisan, ModalAddTangca, ModalAddBank, ModalEditBank, ModalAddPhuCap, ModalMcc} from './Modal';
import Swal from 'sweetalert2';
import ModalAddNPT from '~/pages/Admin/Modal/Nhanvien/ModalAddNPT';
import ModalEditNPT from '~/pages/Admin/Modal/Nhanvien/ModalEditNPT';
import LogoLight2x from '../../images/avatar/avatar.jpg';
import './chitietnhanvien.scss';
import AvatarCrop from './Modal/Nhanvien/AvatarCrop';
import {NumericFormat} from 'react-number-format';
import {toast} from 'react-toastify';
import ModalEditTangca from "~/pages/Admin/Modal/Nhanvien/ModalEditTangca";
import {validateNhanVienChiTiet} from "~/request/validateNhanVienChiTiet";

const ChiTietNhanVien = () => {
        const dispatch = useDispatch();
        
        const [tooltipOpen1, setTooltipOpen1] = useState(false);
        const toggle1 = () => setTooltipOpen1(!tooltipOpen1);
        const [tooltipOpen2, setTooltipOpen2] = useState(false);
        const toggle2 = () => setTooltipOpen2(!tooltipOpen2);
        
        const [show, setShow] = useState(false);
        const [showTangCa, setShowTangCa] = useState(false);
        const [showEditTangCa, setShowEditTangCa] = useState(false);
        const [showBank, setShowBank] = useState(false);
        const [showNPT, setShowNPT] = useState(false);
        const [showPhuCap, setShowPhuCap] = useState(false);
        const [showMcc, setShowMcc] = useState(false);
        const [showBankEdit, setShowBankEdit] = useState(false);
        const [showEditNPT, setShowEditNPT] = useState(false);
        const {nhomnv, nhanvienone} = useSelector((state) => state.nhanvien);
        const {bangchotcong} = useSelector(state => state.bangchotcong)
        // const {congty} = useSelector((state) => state.congty);
        const {phongban} = useSelector((state) => state.phongban);
        const {maychamcong} = useSelector((state) => state.maychamcong);
        // console.log('maychamcong', maychamcong)
        const {kieuluong} = useSelector((state) => state.kieuluong);
        const {congthuctinhluong} = useSelector((state) => state.congthuctinhluong);
        const {chucvu} = useSelector((state) => state.chucvu);
        const {cauhinhcalamviec} = useSelector((state) => state.cauhinhcalamviec);
        // const {tangca} = useSelector((state) => state.tangca);
        const {taikhoannganhang} = useSelector((state) => state.taikhoannganhang);
        const {nguoithannhanvien} = useSelector((state) => state.nguoithannhanvien);
        const {cauhinhtangca} = useSelector((state) => state.cauhinhtangca);
        const {chinhanh} = useSelector((state) => state.chinhanh);
        const {donvihanhchinh} = useSelector((state) => state.app);
        const {phucap} = useSelector((state) => state.phucap);
        
        const [payloadSelect, setpayloadSelect] = useState([]);
        const [payloadPhieuLuong, setPayloadPhieuLuong] = useState({ngaybatdau: '', ngayketthuc: '', ngayhienthi: ''});
        const [dataC, setDataC] = useState([]);
        const [dataP, setDataP] = useState([]);
        const [dataTaisan, setDataTaisan] = useState([]);
        const [dataNguoiPhuThuoc, setDataNguoiPhuThuoc] = useState([]);
        const [dataPhieuLuong, setdataPhieuLuong] = useState([]);
        const [dataPhuongTien, setdataPhuongTien] = useState([]);
        const [dataPhuongTienSearch, setdataPhuongTienSearch] = useState([]);
        const [invalidFields, setInvalidFields] = useState([]);
        // const [listCongty, setlistCongty] = useState([]);
        const [listChinhanh, setlistChinhanh] = useState([]);
        const [dataNganHang, setDataNganHang] = useState([]);
        const [listPhongban, setlistPhongban] = useState([]);
        const [listKieuluong, setlistKieuluong] = useState([]);
        const [listCongthuc, setlistCongthuc] = useState([]);
        const [listChuyenCan, setlistChuyenCan] = useState([]);
        const [list_calc_overtime, setlist_calc_overtime] = useState([]);
        const [listThamnien, setlistThamnien] = useState([]);
        const [listPhuCapDayChuyen, setlistPhuCapDayChuyen] = useState([]);
        const [listChucvu, setlistChucvu] = useState([]);
        const [listGioitinh, setlistGioitinh] = useState([]);
        const [listCauhinhcalamviec, setlistCauhinhcalamviec] = useState([]);
        const [listCauhinhtangca, setlistCauhinhtangca] = useState([]);
        const [listStatus, setlistStatus] = useState([]);
        const [listHocVan, setlistHocVan] = useState([]);
        const [listMayChamCong, setlistMayChamCong] = useState([]);
        const [listNhomnhanvien, setlistNhomnhanvien] = useState([]);
        const [dataTangCa, setdataTangCa] = useState([]);
        const [dataNghiPhepNV, setDataNghiPhepNV] = useState([]);
        const [payload, setPayloadNhanvien] = useState({
            ChinhanhId: '',
            PhongbanId: '',
            ChucvuId: '',
            KieuluongId: '',
            CauhinhchuyencanId: '',
            calc_overtime: '',
            CauhinhthamnienId: '',
            GioitinhId: '',
            TrangthaiId: '',
            MaychamcongId: '',
            NhomnvId: '',
            // KhaibaocalamviecId: '',
            ngayvaolamviec: '',
            hoten: '',
            uuid: '',
            mucluong: '',
            diachihientai: '',
            dienthoai: '',
            email: '',
            ngaysinh: '',
            noisinh: '',
            // nguyenquan: '',
            socmnd: '',
            ngaycap: '',
            noicap: '',
            dantoc: '',
            quoctich: '',
            nguoilienhe: '',
            sodienthoainglh: '',
            quanhevsnv: '',
            diachinglh: '',
            nghipheptieuchuan: 12,
            type_nghiphep: '',
            sothangthuviec: 0,
            sophepton: 0,
            masothue: '',
        });
        const [dataNhanvien, setDataNhanvien] = useState(nhanvienone);
        // console.log('dataNhanvien', dataNhanvien)
        const [searchParams, setsearchParams] = useSearchParams();
        const [listBangchotcong, setlistBangchotcong] = useState([]);
        useEffect(() => {
            let BangchotcongId = bangchotcong?.map((item) => ({label: item.tieude, value: item.id}));
            setlistBangchotcong(BangchotcongId);
        }, [bangchotcong])
        
        const fetchCauHinhChuyenCan = async () => {
            try {
                const datapayloads = {
                    typeGet: 2, // 1: lấy theo điều kiện phân trang, 2: lấy tất cả và không cần truyền d liệu phân trang
                };
                const response = await apiGetCauhinhchuyencan(datapayloads)
                if (response?.data?.success === 200) {
                    let dataSelect = response?.data?.data?.rows.map((item) => ({label: item.name, value: item.id}));
                    setlistChuyenCan(dataSelect)
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        const fetchCauHinhThamNien = async () => {
            try {
                const datapayloads = {
                    typeGet: 2, // 1: lấy theo điều kiện phân trang, 2: lấy tất cả và không cần truyền d liệu phân trang
                    
                };
                const response = await apiGetCauhinhthamnien(datapayloads)
                if (response?.data?.success === 200) {
                    let dataSelect = response?.data?.data?.rows.map((item) => ({label: item.name, value: item.id}));
                    setlistThamnien(dataSelect);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        const fetchCauHinhDayChuyen = async () => {
            try {
                const datapayloads = {
                    typeGet: 2, // 1: lấy theo điều kiện phân trang, 2: lấy tất cả và không cần truyền d liệu phân trang
                    
                };
                const response = await apiGetCauhinhdaychuyen(datapayloads)
                if (response?.data?.success === 200) {
                    let dataSelect = response?.data?.data?.rows.map((item) => ({label: item.name, value: item.id}));
                    setlistPhuCapDayChuyen(dataSelect);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        useEffect(() => {
            // Gọi danh sách các API sử dụng
            dispatch(actions.getListNhanvien({uuid: searchParams.get('NhanvienId')}));
            dispatch(actions.getListNhanvienOne({uuid: searchParams.get('NhanvienId')}));
            dispatch(actions.getListTangCa());
            dispatch(actions.getListCongthuctinhluong());
            dispatch(actions.getListPhucap());
            dispatch(actions.getListTaisan());
            dispatch(actions.getListTaiKhoanNganHang());
            dispatch(actions.getListNhomnv());
            dispatch(actions.getListNhomByNhanVien({uuid: searchParams.get('NhanvienId')}));
            dispatch(actions.getListMayChamCong());
            dispatch(actions.getListBangchotcong());
            dispatch(actions.getDonvihanhchinh());
            fetchCauHinhChuyenCan().then(r => 'err')
            fetchCauHinhThamNien().then(r => 'err')
            fetchCauHinhDayChuyen().then(r => 'err')
            setlist_calc_overtime(dataTypeOvertime);
        }, []);
        // console.log('')
        useEffect(() => {
            if (nhanvienone && nhanvienone.length > 0) {
                setDataNhanvien(nhanvienone[0]);
            }
        }, [nhanvienone]);
        const handleOnSelect = async (selected, name) => {
            let statename = name.name;
            setPayloadNhanvien((prev) => ({...prev, [statename]: selected}));
        };
        const handleOnSelectFilter = async (selected, name) => {
            let statename = name.name;
            setpayloadSelect((prev) => ({...prev, [statename]: selected}));
        };
        useEffect(() => {
            if (
                phongban &&
                kieuluong &&
                congthuctinhluong &&
                chucvu &&
                cauhinhcalamviec &&
                taikhoannganhang &&
                chinhanh &&
                nguoithannhanvien &&
                maychamcong &&
                nhomnv
            ) {
                let dataSelectChinhanh = chinhanh.map((item) => ({label: item.diachi, value: item.id}));
                let dataSelectTKNH = taikhoannganhang.map((item) => ({
                    label: item.tennganhang,
                    value: item.id,
                    NhanvienId: item.NhanvienId,
                    tennganhang: item.tennganhang,
                    nguoihuongthu: item.nguoihuongthu,
                    TrangthaiId: item.TrangthaiId,
                    sotaikhoan: item.sotaikhoan,
                }));
                let dataSelectNPT = nguoithannhanvien?.filter((item) => {
                    return item.NhanvienId === dataNhanvien?.id;
                });
                let dataSelectMayChamCong = maychamcong
                    .filter((item) => item?.status === 1)
                    .map((item) => ({label: item?.tenmaychamcong, value: item?.id}));
                let dataSelectPhongban = phongban.map((item) => ({label: item.phongban, value: item.id}));
                let dataSelectKieuluong = kieuluong.map((item) => ({label: item.tieude, value: item.id}));
                let dataSelectCongthuc = congthuctinhluong.map((item) => ({label: item.tencongthuc, value: item.id}));
                let dataSelectChucvu = chucvu.map((item) => ({label: item.chucvu, value: item.id}));
                let dataSelectCalamviec = cauhinhcalamviec.map((item) => ({
                    label: item.macalamviec,
                    value: item.id,
                }));
                let dataSelectCauhinhTangca = cauhinhtangca.map((item) => ({label: item.title, value: item.id}));
                let dataSelectNhomnv = dataNhanvien?.Nhomnhanviens?.map((item) => ({
                    label: item?.Nhomnv?.tennhom,
                    value: item?.Nhomnv?.id,
                }));
                
                setlistPhongban(dataSelectPhongban);
                setlistMayChamCong(dataSelectMayChamCong);
                setlistKieuluong(dataSelectKieuluong);
                setlistCongthuc(dataSelectCongthuc);
                setlistChucvu(dataSelectChucvu);
                setlistCauhinhcalamviec(dataSelectCalamviec);
                setlistCauhinhtangca(dataSelectCauhinhTangca);
                setlistNhomnhanvien(dataSelectNhomnv);
                setlistGioitinh(dataSelectGioitinh);
                setlistHocVan(dataSelectHocVan);
                setlistStatus(statusCustommerActive);
                setDataNganHang(dataSelectTKNH);
                setDataNguoiPhuThuoc(dataSelectNPT);
                setlistChinhanh(dataSelectChinhanh);
                fetchCauHinhChuyenCan().then(r => 'err')
                fetchCauHinhThamNien().then(r => 'err')
                fetchCauHinhDayChuyen().then(r => 'err')
            }
        }, [
            phongban,
            kieuluong,
            chucvu,
            cauhinhcalamviec,
            taikhoannganhang,
            chinhanh,
            nguoithannhanvien,
            nhomnv,
            maychamcong,
        ]);
        
        useEffect(() => {
            let ChinhanhId = listChinhanh?.find((item) => {
                return item && item.value === dataNhanvien?.ChinhanhId;
            });
            
            let PhongbanId = listPhongban?.find((item) => {
                return item && item.value === Number(dataNhanvien?.PhongbanId);
            });
            
            let ChucvuId = listChucvu?.find((item) => {
                return item && item.value === dataNhanvien?.ChucvuId;
            });
            let KieuluongId = listKieuluong?.find((item) => {
                return item && item.value === Number(dataNhanvien?.KieuluongId);
            });
            let CongthucId = listCongthuc?.find((item) => {
                return item && Number(item.value) === Number(dataNhanvien?.CongthucId);
            });
            let calc_overtime = list_calc_overtime?.find((item) => {
                return item && Number(item.value) === Number(dataNhanvien?.calc_overtime);
            });
            let CauhinhchuyencanId = listChuyenCan?.find((item) => {
                return item && Number(item.value) === Number(dataNhanvien?.CauhinhchuyencanId);
            });
            
            let CauhinhthamnienId = listThamnien?.find((item) => {
                return item && Number(item.value) === Number(dataNhanvien?.CauhinhthamnienId);
            });
            let CauhinhdaychuyenId = listThamnien?.find((item) => {
                return item && Number(item.value) === Number(dataNhanvien?.CauhinhdaychuyenId);
            });
            let GioitinhId = listGioitinh?.find((item) => {
                return item && item.value === dataNhanvien?.GioitinhId;
            });
            let TrangthaiId = listStatus?.find((item) => {
                return item && item.value === Number(dataNhanvien?.TrangthaiId);
            });
            let hocvan = listHocVan?.find((item) => {
                return item && item.value === Number(dataNhanvien?.hocvan);
            });
            // let NhomnvId = listNhomnhanvien?.find((item) => {
            //     return item && item.value === dataNhanvien?.NhomnvId;
            // });
            
            // let KhaibaocalamviecId = dataNhanvien?.Phanca_nhanviens?.map((item) => ({
            //     label: item?.Phanca?.Khaibaocalamviecs[0]?.macalamviec,
            //     value: item?.Phanca?.Khaibaocalamviecs[0]?.id,
            // }));
            // let MaychamcongId = listMayChamCong?.find((item) => {
            //     return item && item.value === dataNhanvien?.MaychamcongId;
            // });
            let Type_nghiphep = dataKieuPhepNhanVien?.find((item) => {
                return item && Number(item.value) === Number(dataNhanvien?.type_nghiphep);
            });
            
            dataNhanvien &&
            setPayloadNhanvien({
                ChinhanhId: {label: ChinhanhId?.label, value: ChinhanhId?.value},
                PhongbanId: {label: PhongbanId?.label, value: PhongbanId?.value},
                ChucvuId: {label: ChucvuId?.label, value: ChucvuId?.value},
                KieuluongId: {label: KieuluongId?.label, value: KieuluongId?.value},
                CongthucId: {label: CongthucId?.label, value: CongthucId?.value},
                CauhinhchuyencanId: {label: CauhinhchuyencanId?.label, value: CauhinhchuyencanId?.value},
                CauhinhthamnienId: {label: CauhinhthamnienId?.label, value: CauhinhthamnienId?.value},
                CauhinhdaychuyenId: {label: CauhinhdaychuyenId?.label, value: CauhinhdaychuyenId?.value},
                calc_overtime: {label: calc_overtime?.label, value: calc_overtime?.value},
                GioitinhId: {label: GioitinhId?.label, value: GioitinhId?.value},
                TrangthaiId: {label: TrangthaiId?.label, value: TrangthaiId?.value},
                hocvan: {label: hocvan?.label, value: hocvan?.value},
                // MaychamcongId: {label: MaychamcongId?.label, value: MaychamcongId?.value},
                NhomnvId: listNhomnhanvien,
                // KhaibaocalamviecId: dataNhanvien?.Phanca_nhanviens,
                ngayvaolamviec: dataNhanvien?.ngayvaolamviec
                    ? format(parseISO(dataNhanvien?.ngayvaolamviec), 'yyyy-MM-dd')
                    : '',
                hoten: dataNhanvien?.hoten,
                uuid: dataNhanvien?.uuid,
                // mucluong: dataNhanvien?.mucluong,
                mucluongformat: dataNhanvien?.mucluong,
                diachihientai: dataNhanvien?.Thongtinnhanvien?.diachihientai,
                dienthoai: dataNhanvien?.Thongtinnhanvien?.dienthoai,
                email: dataNhanvien?.email,
                ngaysinh: dataNhanvien?.Thongtinnhanvien?.ngaysinh,
                noisinh: dataNhanvien?.Thongtinnhanvien?.noisinh,
                // nguyenquan: dataNhanvien?.Thongtinnhanvien?.nguyenquan,
                socmnd: dataNhanvien?.Thongtinnhanvien?.socmnd,
                ngaycap: dataNhanvien?.Thongtinnhanvien?.ngaycap,
                noicap: dataNhanvien?.Thongtinnhanvien?.noicap,
                dantoc: dataNhanvien?.Thongtinnhanvien?.dantoc,
                quoctich: dataNhanvien?.Thongtinnhanvien?.quoctich,
                nguoilienhe: dataNhanvien?.Thongtinnhanvien?.nguoilienhe,
                sodienthoainglh: dataNhanvien?.Thongtinnhanvien?.sodienthoainglh,
                quanhevsnv: dataNhanvien?.Thongtinnhanvien?.quanhevsnv,
                diachinglh: dataNhanvien?.Thongtinnhanvien?.diachinglh,
                nghipheptieuchuan: dataNhanvien?.nghipheptieuchuan,
                type_nghiphep: {label: Type_nghiphep?.label, value: Type_nghiphep?.value},
                sothangthuviec: dataNhanvien?.sothangthuviec,
                sophepton: dataNhanvien?.sophepton,
                masothue: dataNhanvien?.masothue,
            });
            if (dataNhanvien?.Thongtinnhanvien?.ProvinceId !== 0 && dataNhanvien?.Thongtinnhanvien?.DistrictId !== 0 && dataNhanvien?.Thongtinnhanvien?.Xa_phuongId !== 0) {
                setSelectedProvince({
                    label: dataNhanvien?.Thongtinnhanvien?.Province?.name,
                    value: dataNhanvien?.Thongtinnhanvien?.Province?.id
                });
                setSelectedDistrict({
                    label: dataNhanvien?.Thongtinnhanvien?.District?.name,
                    value: dataNhanvien?.Thongtinnhanvien?.District?.id
                });
                setSelectedWard({
                    label: dataNhanvien?.Thongtinnhanvien?.Xa_phuong?.name,
                    value: dataNhanvien?.Thongtinnhanvien?.Xa_phuong?.id
                });
            }
        }, [
            dataNhanvien,
            listChinhanh,
            listKieuluong,
            listCongthuc,
            listPhongban,
            listMayChamCong,
            listChucvu,
            listStatus,
            listHocVan,
            listGioitinh,
            listCauhinhcalamviec,
            listNhomnhanvien,
            listChuyenCan,
            list_calc_overtime,
            listThamnien,
            listPhuCapDayChuyen,
            dataKieuPhepNhanVien,
        ]);
        const [modalTab, setModalTab] = useState('1');
        const [formData, setFormData] = useState([]);
        const [dataBank, setdataBank] = useState([]);
        const [dataNPT, setdataNPT] = useState([]);
        const [dataTangca, setdataTangca] = useState([]);
        const [dataPhuCapNV, setdataPhuCapNV] = useState([]);
        const [dataMcc, setdataMcc] = useState([]);
        const [modal, setModal] = useState(false);
        const onInputChange = (e) => {
            setFormData({...formData, [e.target.name]: e.target.value});
        };
        
        
        const [listTinhthanh, setlistTinhThanh] = useState([]);
        const [listQuanhuyen, setlistQuanhuyen] = useState([]);
        const [listXaphuong, setlistXaphuong] = useState([]);
        const [selectedProvince, setSelectedProvince] = useState('');
        const [selectedDistrict, setSelectedDistrict] = useState('');
        const [selectedWard, setSelectedWard] = useState('');
        const handleProvinceChange = (selected) => {
            setSelectedProvince(selected);
            setSelectedDistrict('');
            setSelectedWard('');
        };
        const handleDistrictChange = (selected) => {
            setSelectedDistrict(selected);
            setSelectedWard('');
        };
        const handleWardChange = (selected) => {
            setSelectedWard(selected);
        };
        useEffect(() => {
            const dataTT = donvihanhchinh?.data?.province
                ? JSON.parse(donvihanhchinh.data.province).map((item) => ({
                    label: item.name,
                    value: item.id,
                }))
                : [];
            setlistTinhThanh(dataTT);
            if (selectedProvince) {
                const dataQH =
                    donvihanhchinh?.data?.district &&
                    JSON.parse(donvihanhchinh.data.district)
                        .filter((it) => it?.province_id === Number(selectedProvince.value))
                        .map((item) => ({label: item.name, value: item.id}));
                setlistQuanhuyen(dataQH);
            } else {
                setlistQuanhuyen([]);
            }
            if (selectedDistrict) {
                const dataXP =
                    donvihanhchinh?.data?.wards &&
                    JSON.parse(donvihanhchinh.data.wards)
                        .filter((it) => it?.district_id === Number(selectedDistrict.value))
                        .map((item) => ({label: item.name, value: item.id}));
                setlistXaphuong(dataXP);
            } else {
                setlistXaphuong([]);
            }
        }, [donvihanhchinh, selectedProvince, selectedDistrict]);
        const [invalidDvhc, setInvalidFieldsDvhc] = useState([]);
        const validateDVHC = (arr) => {
            let invalids = 0;
            let fields = Object.entries(arr);
            fields.forEach((item) => {
                if (item[1] === '') {
                    setInvalidFieldsDvhc((prev) => [
                        ...prev,
                        {
                            name: item[0],
                            message: 'Bạn không được bỏ trống trường này.',
                        },
                    ]);
                    invalids++;
                }
            });
            
            return invalids;
        };
        const handleSubmit = async () => {
            let invalids = validate(payload);
            const valiDVHC = {TinhthanhId: selectedProvince, QuanhuyenId: selectedDistrict, PhuongxaId: selectedWard};
            const invalidsDVHC = validateDVHC(valiDVHC);
            if (invalids === 0 && invalidsDVHC === 0) {
                let datapayload = {
                    data: [
                        {
                            id: dataNhanvien.id,
                            ChinhanhId: payload?.ChinhanhId?.value,
                            PhongbanId: payload?.PhongbanId?.value,
                            ChucvuId: payload?.ChucvuId?.value,
                            // MaychamcongId: payload?.MaychamcongId?.value,
                            kieuluong: Number(payload?.KieuluongId?.value),
                            CongthucId: Number(payload?.CongthucId?.value),
                            GioitinhId: payload?.GioitinhId?.value,
                            TrangthaiId: payload?.TrangthaiId?.value,
                            hocvan: payload?.hocvan?.value,
                            NhomnvId: payload?.NhomnvId?.value,
                            // KhaibaocalamviecId: payload?.KhaibaocalamviecId?.value,
                            CauhinhtangcaId: payload?.CauhinhtangcaId?.value,
                            CauhinhchuyencanId: payload?.CauhinhchuyencanId?.value,
                            calc_overtime: payload?.calc_overtime?.value,
                            CauhinhthamnienId: payload?.CauhinhthamnienId?.value,
                            ngayvaolamviec: payload?.ngayvaolamviec,
                            hoten: payload?.hoten,
                            
                            mucluong: payload?.mucluong,
                            diachihientai: payload?.diachihientai,
                            dienthoai: payload?.dienthoai,
                            email: payload?.email,
                            ngaysinh: payload?.ngaysinh,
                            noisinh: payload?.noisinh,
                            // nguyenquan: payload?.nguyenquan,
                            socmnd: payload?.socmnd,
                            ngaycap: payload?.ngaycap,
                            noicap: payload?.noicap,
                            dantoc: payload?.dantoc,
                            quoctich: payload?.quoctich,
                            nguoilienhe: payload?.nguoilienhe,
                            sodienthoainglh: payload?.sodienthoainglh,
                            quanhevsnv: payload?.quanhevsnv,
                            diachinglh: payload?.diachinglh,
                            nghipheptieuchuan: payload?.nghipheptieuchuan,
                            type_nghiphep: payload?.type_nghiphep?.value,
                            ProvinceId: selectedProvince?.value,
                            DistrictId: selectedDistrict?.value,
                            Xa_phuongId: selectedWard?.value,
                            sothangthuviec: payload?.sothangthuviec,
                            masothue: payload?.masothue,
                            // file:{filename:"sam5003-den-4-25d209a8-eb18-4395-916b-0adedb75be63.jpg"}
                        },
                    ],
                };
                
                dispatch(actions.EditNhanvien(datapayload));
            }
        };
        const validate = (payload) => {
            let invalids = 0;
            let fields = Object.entries(payload);
            fields.forEach((item) => {
                if (validateNhanVienChiTiet(item[0], item[1]) !== undefined) {
                    setInvalidFields((prev) => [...prev, validateNhanVienChiTiet(item[0], item[1])]);
                    invalids++;
                }
            });
            return invalids;
        };
        const formatVND = (data) => {
            return Number(data ?? 0).toLocaleString('it-IT', {
                style: 'currency',
                currency: 'vnd',
            });
        };
        // const payRoll = (luong, luongcs, cong, congcf) => {
        //     let luongs = Number(luong);
        //     let luongscs = Number(luongcs);
        //     let congs = Number(cong);
        //     let congcfs = Number(congcf);
        //     let payroll = luongs + (luongscs * congs) / congcfs;
        //     return formatVND(payroll);
        // };
        
        const handleAddPhuCap = async () => {
            setShowPhuCap(true);
            await setdataPhuCapNV(null);
        };
        const handleEditPhuCap = async (data) => {
            setShowPhuCap(true);
            await setdataPhuCapNV(data);
        };
        const handleAddBank = async () => {
            setShowBank(true);
        };
        const handleEditBank = async (data) => {
            setShowBankEdit(true);
            await setdataBank(data);
        };
        const handleDeleteBank = async (data) => {
            Swal.fire({
                title: 'Bạn có chắc không?',
                text: 'Bạn sẽ không thể lấy lại khi xóa!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Không, bỏ qua!',
                confirmButtonText: 'Vâng, xóa nó đi!',
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch(actions.DeleteTaiKhoanNganHang(data));
                }
            });
        };
        const handleDeletePhuCap = async (data) => {
            // console.log('datadadadad', data)
            try {
                const result = await Swal.fire({
                    title: 'Bạn có chắc không?',
                    text: 'Bạn sẽ không thể lấy lại khi xóa!',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Không, bỏ qua!',
                    confirmButtonText: 'Vâng, xóa nó đi!',
                });
                
                if (result.isConfirmed) {
                    data.NhanvienId = dataNhanvien.id;
                    dispatch(actions.DeletePhucapnhanvien(data, searchParams.get('NhanvienId')));
                }
            } catch (error) {
                console.error('Error with SweetAlert:', error);
            }
        };
        const handleAddMcc = async () => {
            setShowMcc(true);
            await setdataMcc(null);
        };
        const handleEditMcc = async (data) => {
            setShowMcc(true);
            await setdataMcc(data);
        };
        const handleDeleteMcc = async (data) => {
            // console.log('data', data)
            Swal.fire({
                title: 'Bạn có chắc không?',
                text: 'Bạn sẽ không thể lấy lại khi xóa!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Không, bỏ qua!',
                confirmButtonText: 'Vâng, xóa nó đi!',
            }).then((result) => {
                if (result.isConfirmed) {
                    const fetchData = async () => {
                        try {
                            const create = await apiDeleteMccByNhanVien(data);
                            if (create?.data?.success === 200) {
                                toast.success("Thêm mới thành công");
                                setShow(false);
                                dispatch(actions.getListNhanvienOne({uuid: searchParams.get('NhanvienId')}));
                            } else {
                                toast.error("Thêm mới thất bại");
                            }
                        } catch (error) {
                            console.error('Error fetching data:', error);
                        }
                    };
                    fetchData().catch(console.error);
                }
            });
        };
        const handleAddNPT = async () => {
            setShowNPT(true);
        };
        const handleEditNPT = async (data) => {
            setShowEditNPT(true);
            await setdataNPT(data);
        };
        const handleDeleteNPT = async (data) => {
            // console.log('data', data);
            Swal.fire({
                title: 'Bạn có chắc không?',
                text: 'Bạn sẽ không thể lấy lại khi xóa!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Không, bỏ qua!',
                confirmButtonText: 'Vâng, xóa nó đi!',
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch(actions.DeleteNguoiPhuThuoc(data));
                }
            });
        };
        const handleAddCauhinhtangca = async () => {
            setShowTangCa(true);
            await setdataTangca(null);
        };
        const handleEditTangca = async (data) => {
            setShowEditTangCa(true);
            await setdataTangca(data);
        };
        const handleDeleteTangca = async (data) => {
            // console.log('data', data);
            Swal.fire({
                title: 'Bạn có chắc không?',
                text: 'Bạn sẽ không thể lấy lại khi xóa!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Không, bỏ qua!',
                confirmButtonText: 'Vâng, xóa nó đi!',
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch(actions.DeleteTangCa({id: data.id}, searchParams.get('NhanvienId')));
                }
            });
        };
        const handleSubmitChamCong = async () => {
            
            let fieldChamCong = {
                BangchotcongId: payloadSelect?.BangchotcongId?.value,
                NhanvienId: dataNhanvien?.id,
            };
            // console.log('fieldChamCong', fieldChamCong)
            try {
                const Chamcong = await apiListBangChamCongByNhanVien(fieldChamCong);
                // console.log('Chamcong', Chamcong)
                const response = Chamcong.data.data[0].rows;
                // console.log(response)
                if (response && response.length > 0) {
                    setDataC(response);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        
        function getMonthYear(dataDate) {
            const date = new Date(dataDate);
            const currentYear = date.getFullYear();
            let currentMonth = 12;
            if (date.getMonth() + 1 < 10) {
                currentMonth = '0' + (date.getMonth() + 1);
            } else {
                currentMonth = date.getMonth() + 1;
            }
            return currentYear + '-' + currentMonth;
        }
        
        const fetchPhieuLuong = async () => {
            try {
                let datapayload = {
                    NhanvienId: dataNhanvien.id,
                    BangchotcongId: payloadSelect?.BangchotcongId?.value
                }
                const respon = await apiListBCLuongByNhanVien(datapayload);
                // console.log('respon', respon);
                if (respon?.data?.success === 200) {
                    setDataP(respon.data.data.rows);
                }
                
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        const onClickTabPhieuLuong = async () => {
            try {
                const date = new Date();
                const month = date.getMonth();
                const year = date.getFullYear();
                
                // const ngayBatDau = `${year}-${month}-1`;
                // const ngayKetThuc = `${year}-${month}-${new Date(year, month, 0).getDate()}`;
                
                const setDateCurrentFilter = getMonthYear(date);
                
                const datapayload = {
                    NhanvienId: dataNhanvien.id,
                };
                // console.log('dataNhanvien.id', dataNhanvien.id)
                await Promise.all([
                    fetchPhieuLuong(),
                    setPayloadPhieuLuong({
                        ...payloadPhieuLuong,
                        ['ngayketthuc']: setDateCurrentFilter,
                        ['ngayhienthi']: `${year}-${month + 1}`,
                    }),
                ]);
                const dataApi = await apiGetPhucapByNhanVien(datapayload);
                // console.log('dataApi', dataApi)
                const data = dataApi.data.data.rows.filter((item) => item.Phucap.TrangthaiId === 1);
                // console.log('data', data)
                setdataPhieuLuong(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        const handleSubmitPhieuLuong = async () => {
            const dateCurrent = new Date();
            const currentMonth = dateCurrent.getMonth() + 1;
            const currentYear = dateCurrent.getFullYear();
            const newDate = new Date(payloadPhieuLuong.ngayketthuc);
            let month = newDate.getMonth() + 1;
            const year = newDate.getFullYear();
            if (month - currentMonth >= 0 && currentYear === year) {
                month = dateCurrent.getMonth();
            }
            const ngayBatDau = `${year}-${month}-1`;
            const ngayKetThuc = `${year}-${month}-${new Date(year, month, 0).getDate()}`;
            setPayloadPhieuLuong({
                ...payloadPhieuLuong,
                ['ngayhienthi']: `${year}-${month}`,
            });
            await fetchPhieuLuong();
        };
        const onClickTabChamCong = async (data) => {
            let newDate = new Date();
            let dateCurrent = newDate.getDate();
            let month = newDate.getMonth() + 1;
            let year = newDate.getFullYear();
            const daysInMonth = (month, year) => {
                return new Date(year, month, 0).getDate();
            };
            if (dateCurrent < 7) {
                month = newDate.getMonth();
            }
            let ngayBatDau = year + '-' + month + '-1';
            let ngayKetThuc = year + '-' + month + '-' + daysInMonth(month, year);
            let fieldChamCong = {
                ngaybatdau: ngayBatDau,
                ngayketthuc: ngayKetThuc,
                NhanvienId: data ?? 8,
            };
            try {
                const Chamcong = await apiListBangChamCong(fieldChamCong);
                const response = Chamcong.data.data.rows;
                if (response && response.length > 0) {
                    setDataC(response);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        const onClickTabTaiSan = async (data) => {
            try {
                const TaisanByNhanVien = await apiListTaiSanByNhanvien(data);
                const response = TaisanByNhanVien.data.data.rows;
                if (response && response.length > 0) {
                    setDataTaisan(response);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        const onClickTabCongViec = async (d) => {
            try {
                const nguoiPhuThuoc = await apiGetNguoiPhuThuoc();
                const response = nguoiPhuThuoc.data.data.rows;
                
                if (response && response.length > 0) {
                    let dataNPT = response?.filter((item) => {
                        return item && item.NhanvienId === d;
                    });
                    setDataNguoiPhuThuoc(dataNPT);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        // const onClickTabTangCa = async (data) => {
        //     let dataTangCa = tangca?.filter((item) => {
        //         return item && item.NhanvienId === data;
        //     });
        //     setdataTangCa(dataTangCa);
        // };
        const fetchNhanvienByUuid = async (data) => {
            try {
                const resNhanVien = await apiGetNhanvienById(data);
                const dataNhanvien = resNhanVien.data.data.rows;
                setDataNghiPhepNV(dataNhanvien);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        const onClickTabNghiPhep = async (data) => {
            // console.log(':::::data::::',data)
            let dataUuid = {id: data};
            fetchNhanvienByUuid(dataUuid);
        };
        // const onClickTabPhieuLuong = async () => {
        //     const date = new Date();
        //     const setDateCurrentFilter = getMonthYear(date);
        //     setPayloadPhieuLuong({
        //         ['ngayketthuc']: setDateCurrentFilter,
        //     });
        //     let datapayload = {
        //         NhanvienId: dataNhanvien.id,
        //     };
        //     try {
        //         const dataApi = await apiGetPhucapByNhanVien(datapayload);
        //         let res = dataApi.data.data.rows;
        //         let data = res.filter((item) => item.Phucap.TrangthaiId === 1)
        //         setdataPhieuLuong(data);
        //     } catch (error) {
        //         console.error('Error fetching data:', error);
        //     }
        // };
        const handleSubmitPhuongTien = async () => {
            let ngayBatDau = payloadPhieuLuong.ngaybatdau;
            let ngayKetThuc = payloadPhieuLuong.ngayketthuc;
            
            let abc = dataPhuongTienSearch.filter(
                (item) => item.ngaybatdau >= ngayBatDau && item.ngayketthuc <= ngayKetThuc,
            );
            setdataPhuongTien(abc);
        };
        const onClickTabPhuongTien = async (data) => {
            try {
                let dataPayload = {NhanvienId: data, PhuongtienId: ''};
                const dataApi = await apiGetKehoachcuaxeByNhanVien(dataPayload);
                // console.log('res::::::::::dataApi',dataApi);
                let res = dataApi.data.data.rows;
                // console.log('res::::::::::res',res);
                setdataPhuongTien(res);
                setdataPhuongTienSearch(res);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        // const [defaultFiles, setDefaultFiles] = useState([]);
        const [modalAvatar, setmodalAvatar] = useState(false);
        
        const [tooltipOpen, setTooltipOpen] = useState(false);
        const toggle = () => setTooltipOpen(!tooltipOpen);
        
        return (
            <React.Fragment>
                <Content>
                    <Card className="card-bordered">
                        {/* <div className="card-aside-wrap"> */}
                        <div className="card-inner card-inner-lg">
                            <div className="p-2">
                                <ul className="nk-nav nav nav-tabs">
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link ${modalTab === '1' && 'active'}`}
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                setModalTab('1');
                                            }}
                                            href="#canhan"
                                        >
                                            Thông tin cá nhân
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link ${modalTab === '2' && 'active'}`}
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                setModalTab('2');
                                                onClickTabCongViec(dataNhanvien?.id);
                                            }}
                                            href="#congviec"
                                        >
                                            Thông tin liên quan
                                        </a>
                                    </li>
                                    {/* <li className="nav-item">
                                        <a
                                            className={`nav-link ${modalTab === '7' && 'active'}`}
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                setModalTab('7');
                                                // onClickTabTangCa(dataNhanvien?.id);
                                            }}
                                            href="#tangca-neuco"
                                        >
                                            Tăng ca
                                        </a>
                                    </li> */}
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link ${modalTab === '3' && 'active'}`}
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                setModalTab('3');
                                                onClickTabNghiPhep(dataNhanvien?.uuid);
                                            }}
                                            href="#nghiphep"
                                        >
                                            Nghỉ phép
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link ${modalTab === '4' && 'active'}`}
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                setModalTab('4');
                                                onClickTabTaiSan(dataNhanvien?.id);
                                            }}
                                            href="#taisan"
                                        >
                                            Tài sản
                                        </a>
                                    </li>
                                    
                                    {/*<li className="nav-item">*/}
                                    {/*    <a*/}
                                    {/*        className={`nav-link ${modalTab === '5' && 'active'}`}*/}
                                    {/*        onClick={(ev) => {*/}
                                    {/*            ev.preventDefault();*/}
                                    {/*            setModalTab('5');*/}
                                    {/*        }}*/}
                                    {/*        href="#tailieu"*/}
                                    {/*    >*/}
                                    {/*        Tài liệu*/}
                                    {/*    </a>*/}
                                    {/*</li>*/}
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link ${modalTab === '6' && 'active'}`}
                                            href="#chamcong"
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                setModalTab('6');
                                                onClickTabChamCong(dataNhanvien?.id);
                                            }}
                                        >
                                            Chấm công
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link ${modalTab === '8' && 'active'}`}
                                            // onClick={() => onClickTabChamCong(dataNhanvien?.id)}
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                setModalTab('8');
                                                onClickTabPhieuLuong();
                                            }}
                                            href="#phieuluong"
                                        >
                                            Phiếu lương
                                        </a>
                                    </li>
                                    {/*<li className="nav-item">*/}
                                    {/*    <a*/}
                                    {/*        className={`nav-link ${modalTab === '9' && 'active'}`r}*/}
                                    {/*        // onClick={() => onClickTabChamCong(dataNhanvien?.id)}*/}
                                    {/*        onClick={(ev) => {*/}
                                    {/*            ev.preventDefault();*/}
                                    {/*            setModalTab('9');*/}
                                    {/*            onClickTabPhuongTien(dataNhanvien?.id);*/}
                                    {/*        }}*/}
                                    {/*        href="#phuongtien"*/}
                                    {/*    >*/}
                                    {/*        Lịch trình xe*/}
                                    {/*    </a>*/}
                                    {/*</li>*/}
                                </ul>
                                <div className="tab-content">
                                    <div className={`tab-pane ${modalTab === '1' ? 'active' : ''}`} id="canhan">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="pb-0">
                                                    <h6 className="text-primary">Thông tin cơ bản</h6>
                                                </div>
                                            </div>
                                            <div className="py-3">
                                                <div className="avatar-container border rounded-4">
                                                    {dataNhanvien?.Thongtinnhanvien?.avatar === null ? (
                                                        <img
                                                            src={LogoLight2x}
                                                            alt="Avatar Image"
                                                            className="rounded-4"
                                                        />
                                                    ) : (
                                                        <img
                                                            src={`${process.env.REACT_APP_SERVER_URL}${dataNhanvien?.Thongtinnhanvien?.avatar}`}
                                                            alt="Avatar Image"
                                                            className="rounded-4"
                                                        />
                                                    )}
                                                    <div className="camera-icon" onClick={() => setmodalAvatar(true)}>
                                                        <Icon name="camera-fill" htmlFor="inputImge"></Icon>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="col-md-3">
                                                <InputForm
                                                    setInvalidFields={setInvalidFields}
                                                    invalidFields={invalidFields}
                                                    label={'Họ và tên'}
                                                    value={payload?.hoten}
                                                    setValue={setPayloadNhanvien}
                                                    keyPayload={'hoten'}
                                                    type="text"
                                                    validate
                                                />
                                            </div>
                                            
                                            <div className="form-group col-md-3">
                                                <InputForm
                                                    setInvalidFields={setInvalidFields}
                                                    invalidFields={invalidFields}
                                                    label={'Ngày sinh'}
                                                    value={payload?.ngaysinh}
                                                    setValue={setPayloadNhanvien}
                                                    keyPayload={'ngaysinh'}
                                                    type="date"
                                                />
                                            </div>
                                            <div className="form-group col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="inputZip" className="mb-2 fw-bold">
                                                        Giới tính
                                                    </label>
                                                    <Select
                                                        value={payload?.GioitinhId}
                                                        onChange={handleOnSelect}
                                                        options={listGioitinh}
                                                        placeholder={'Chọn giới tính'}
                                                        name="GioitinhId"
                                                        onFocus={() => setInvalidFields([])}
                                                    />
                                                    {invalidFields.length > 0 &&
                                                        invalidFields.some((i) => i.name === 'GioitinhId') && (
                                                            <small>
                                                                {
                                                                    invalidFields.find((i) => i.name === 'GioitinhId')
                                                                        ?.message
                                                                }
                                                            </small>
                                                        )}
                                                </div>
                                            </div>
                                            <div className="form-group col-md-3">
                                                <InputForm
                                                    setInvalidFields={setInvalidFields}
                                                    invalidFields={invalidFields}
                                                    label={'Mã Nhân Viên'}
                                                    value={payload?.uuid}
                                                    setValue={setPayloadNhanvien}
                                                    keyPayload={'uuid'}
                                                    type="text"
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                        
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="pb-0">
                                                    <h6 className="overline-title text-primary">Liên hệ</h6>
                                                </div>
                                            </div>
                                            
                                            <div className="form-group col-md-3">
                                                <InputForm
                                                    setInvalidFields={setInvalidFields}
                                                    invalidFields={invalidFields}
                                                    label={'Email'}
                                                    value={payload?.email}
                                                    setValue={setPayloadNhanvien}
                                                    keyPayload={'email'}
                                                    type="text"
                                                    validate
                                                    // disabled={true}
                                                />
                                            </div>
                                            <div className="form-group col-md-3">
                                                <InputForm
                                                    setInvalidFields={setInvalidFields}
                                                    invalidFields={invalidFields}
                                                    label={'Số điện thoại'}
                                                    value={payload?.dienthoai}
                                                    setValue={setPayloadNhanvien}
                                                    keyPayload={'dienthoai'}
                                                    type="text"
                                                    validate
                                                />
                                            </div>
                                            <div className="form-group col-md-3">
                                                <InputForm
                                                    setInvalidFields={setInvalidFields}
                                                    invalidFields={invalidFields}
                                                    label={'Quốc tich'}
                                                    value={payload?.quoctich}
                                                    setValue={setPayloadNhanvien}
                                                    keyPayload={'quoctich'}
                                                    type="text"
                                                />
                                            </div>
                                            {/* <div className="form-group col-md-3">
                                                    <InputForm
                                                        setInvalidFields={setInvalidFields}
                                                        invalidFields={invalidFields}
                                                        label={'Nguyên quán'}
                                                        value={payload?.nguyenquan}
                                                        setValue={setPayloadNhanvien}
                                                        keyPayload={'nguyenquan'}
                                                        type="text"
                                                    />
                                                </div> */}
                                            <div className="form-group col-md-3">
                                                <InputForm
                                                    setInvalidFields={setInvalidFields}
                                                    invalidFields={invalidFields}
                                                    label={'Nơi sinh'}
                                                    value={payload?.noisinh}
                                                    setValue={setPayloadNhanvien}
                                                    keyPayload={'noisinh'}
                                                    type="text"
                                                />
                                            </div>
                                            <div className="form-group col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="inputZip" className="mb-2 fw-bold">
                                                        Tỉnh thành <b className="text-danger">(*)</b>
                                                    </label>
                                                    <Select
                                                        value={selectedProvince}
                                                        onChange={handleProvinceChange}
                                                        options={listTinhthanh}
                                                        placeholder={'Chọn Tỉnh thành'}
                                                        name="TinhthanhId"
                                                        onFocus={() => setInvalidFieldsDvhc([])}
                                                    />
                                                    {invalidDvhc.length > 0 &&
                                                        invalidDvhc.some((i) => i.name === 'TinhthanhId') && (
                                                            <small className="text-danger">
                                                                {invalidDvhc.find((i) => i.name === 'TinhthanhId')?.message}
                                                            </small>
                                                        )}
                                                </div>
                                            </div>
                                            <div className="form-group col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="inputZip" className="mb-2 fw-bold">
                                                        Quận/Huyện <b className="text-danger">(*)</b>
                                                    </label>
                                                    <Select
                                                        value={selectedDistrict}
                                                        onChange={handleDistrictChange}
                                                        options={listQuanhuyen}
                                                        placeholder={'Chọn Quận/Huyện'}
                                                        name="QuanhuyenId"
                                                        onFocus={() => setInvalidFieldsDvhc([])}
                                                    />
                                                    {invalidDvhc.length > 0 &&
                                                        invalidDvhc.some((i) => i.name === 'QuanhuyenId') && (
                                                            <small className="text-danger">
                                                                {invalidDvhc.find((i) => i.name === 'QuanhuyenId')?.message}
                                                            </small>
                                                        )}
                                                </div>
                                            </div>
                                            <div className="form-group col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="inputZip" className="mb-2 fw-bold">
                                                        Phường/Xã <b className="text-danger">(*)</b>
                                                    </label>
                                                    <Select
                                                        value={selectedWard}
                                                        onChange={handleWardChange}
                                                        options={listXaphuong}
                                                        placeholder={'Chọn Phường/Xã'}
                                                        name="PhuongxaId"
                                                        onFocus={() => setInvalidFieldsDvhc([])}
                                                    />
                                                    {invalidDvhc.length > 0 &&
                                                        invalidDvhc.some((i) => i.name === 'PhuongxaId') && (
                                                            <small className="text-danger">
                                                                {invalidDvhc.find((i) => i.name === 'PhuongxaId')?.message}
                                                            </small>
                                                        )}
                                                </div>
                                            </div>
                                            <div className="form-group col-md-3">
                                                <InputForm
                                                    setInvalidFields={setInvalidFields}
                                                    invalidFields={invalidFields}
                                                    label={'Mã số thuế cá nhân'}
                                                    value={payload?.masothue}
                                                    setValue={setPayloadNhanvien}
                                                    keyPayload={'masothue'}
                                                    type="text"
                                                />
                                            </div>
                                            <div className="form-group col-md-3">
                                                <InputForm
                                                    setInvalidFields={setInvalidFields}
                                                    invalidFields={invalidFields}
                                                    label={'Dân tộc'}
                                                    value={payload?.dantoc}
                                                    setValue={setPayloadNhanvien}
                                                    keyPayload={'dantoc'}
                                                    type="text"
                                                />
                                            </div>
                                            <div className="form-group col-md-3">
                                                <InputForm
                                                    setInvalidFields={setInvalidFields}
                                                    invalidFields={invalidFields}
                                                    label={'Địa chỉ hiện tại'}
                                                    value={payload?.diachihientai}
                                                    setValue={setPayloadNhanvien}
                                                    keyPayload={'diachihientai'}
                                                    type="text"
                                                />
                                            </div>
                                            <div className="form-group col-md-3">
                                                <label htmlFor="inputZip" className="mb-2 fw-bolder">
                                                    Học vấn <b className="text-danger">(*)</b>
                                                </label>
                                                <Select
                                                    value={payload?.hocvan}
                                                    onChange={handleOnSelect}
                                                    options={listHocVan}
                                                    placeholder={'Chọn loại học vấn'}
                                                    name="hocvan"
                                                    onFocus={() => setInvalidFields([])}
                                                />
                                                {invalidFields.length > 0 &&
                                                    invalidFields.some((i) => i.name === 'hocvan') && (
                                                        <small>
                                                            {
                                                                invalidFields.find((i) => i.name === 'hocvan')
                                                                    ?.message
                                                            }
                                                        </small>
                                                    )}
                                            </div>
                                            <div className="form-group col-md-3">
                                                <label htmlFor="inputZip" className="mb-2 fw-bolder">
                                                    Trạng thái <b className="text-danger">(*)</b>
                                                </label>
                                                <Select
                                                    value={payload?.TrangthaiId}
                                                    onChange={handleOnSelect}
                                                    options={listStatus}
                                                    placeholder={'Chọn trạng tháí'}
                                                    name="TrangthaiId"
                                                    onFocus={() => setInvalidFields([])}
                                                />
                                                {invalidFields.length > 0 &&
                                                    invalidFields.some((i) => i.name === 'TrangthaiId') && (
                                                        <small>
                                                            {
                                                                invalidFields.find((i) => i.name === 'TrangthaiId')
                                                                    ?.message
                                                            }
                                                        </small>
                                                    )}
                                            </div>
                                            {payload?.TrangthaiId?.value === 1 && (
                                                <div className="form-group col-md-3 mb-2">
                                                    <>
                                                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                                                            Số tháng thử việc <Icon name="help-fill"
                                                                                    id="sothangthuviec"></Icon>
                                                            <Tooltip isOpen={tooltipOpen1} target="sothangthuviec"
                                                                     toggle={toggle1}>
                                                                Số tháng nhân viên phải thử việc!
                                                            </Tooltip>
                                                        </label>
                                                    </>
                                                    <input
                                                        id="sothangthuviec"
                                                        name="sothangthuviec"
                                                        className="form-control"
                                                        type="number"
                                                        value={payload?.sothangthuviec || 0}
                                                        onChange={(e) =>
                                                            setPayloadNhanvien((prev) => ({
                                                                ...prev,
                                                                ['sothangthuviec']: e.target.value,
                                                            }))
                                                        }
                                                        onFocus={() => setInvalidFields([])}
                                                    />
                                                </div>
                                            )}
                                        
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-md-12">
                                                <div className="pb-0">
                                                    <h6 className="overline-title text-primary">Thông tin định danh</h6>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-4">
                                                <InputForm
                                                    setInvalidFields={setInvalidFields}
                                                    invalidFields={invalidFields}
                                                    label={'Số CMND/CCCD'}
                                                    value={payload?.socmnd}
                                                    setValue={setPayloadNhanvien}
                                                    keyPayload={'socmnd'}
                                                    type="text"
                                                    validate
                                                />
                                            </div>
                                            <div className="form-group col-md-4">
                                                <InputForm
                                                    setInvalidFields={setInvalidFields}
                                                    invalidFields={invalidFields}
                                                    label={'Ngày cấp'}
                                                    value={payload?.ngaycap}
                                                    setValue={setPayloadNhanvien}
                                                    keyPayload={'ngaycap'}
                                                    type="date"
                                                />
                                            </div>
                                            <div className="form-group col-md-4">
                                                <InputForm
                                                    setInvalidFields={setInvalidFields}
                                                    invalidFields={invalidFields}
                                                    label={'Nơi cấp'}
                                                    value={payload?.noicap}
                                                    setValue={setPayloadNhanvien}
                                                    keyPayload={'noicap'}
                                                    type="text"
                                                />
                                            </div>
                                            {/* <div className="form-group col-md-3">
                                                    <label htmlFor="inputZip" className="mb-2 fw-bolder">
                                                        Chọn máy chấm công
                                                    </label>
                                                    <Select
                                                        value={payload?.MaychamcongId}
                                                        onChange={handleOnSelect}
                                                        options={listMayChamCong}
                                                        placeholder={'Chọn máy chấm công'}
                                                        name="MaychamcongId"
                                                        onFocus={() => setInvalidFields([])}
                                                    />
                                                    {invalidFields.length > 0 &&
                                                        invalidFields.some((i) => i.name === 'MaychamcongId') && (
                                                            <small>
                                                                {
                                                                    invalidFields.find((i) => i.name === 'MaychamcongId')
                                                                        ?.message
                                                                }
                                                            </small>
                                                        )}
                                                </div> */}
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-md-12">
                                                <div className="pb-0">
                                                    <h6 className="overline-title text-primary">Liên hệ khẩn cấp</h6>
                                                </div>
                                            </div>
                                            
                                            <div className="form-group col-md-3">
                                                <InputForm
                                                    setInvalidFields={setInvalidFields}
                                                    invalidFields={invalidFields}
                                                    label={'Tên người LH khẩn cấp'}
                                                    value={payload?.nguoilienhe}
                                                    setValue={setPayloadNhanvien}
                                                    keyPayload={'nguoilienhe'}
                                                    type="text"
                                                />
                                            </div>
                                            <div className="form-group col-md-3">
                                                <InputForm
                                                    setInvalidFields={setInvalidFields}
                                                    invalidFields={invalidFields}
                                                    label={'Số điện thoại LH khẩn cấp'}
                                                    value={payload?.sodienthoainglh}
                                                    setValue={setPayloadNhanvien}
                                                    keyPayload={'sodienthoainglh'}
                                                    type="text"
                                                />
                                            </div>
                                            <div className="form-group col-md-3">
                                                <InputForm
                                                    setInvalidFields={setInvalidFields}
                                                    invalidFields={invalidFields}
                                                    label={'Quan hệ với nhân viên'}
                                                    value={payload?.quanhevsnv}
                                                    setValue={setPayloadNhanvien}
                                                    keyPayload={'quanhevsnv'}
                                                    type="text"
                                                />
                                            </div>
                                            <div className="form-group col-md-3">
                                                <InputForm
                                                    setInvalidFields={setInvalidFields}
                                                    invalidFields={invalidFields}
                                                    label={'Địa chỉ khẩn cấp'}
                                                    value={payload?.diachinglh}
                                                    setValue={setPayloadNhanvien}
                                                    keyPayload={'diachinglh'}
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 mb-3">
                                                <div className="pb-0">
                                                    <h6 className="overline-title text-primary">Tình trạng</h6>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-4">
                                                {/* <label htmlFor="inputZip" className="mb-2 fw-bold">
                                                    <span>
                                                        Công ty <b>(*)</b>
                                                    </span>
                                                </label> */}
                                                <InputForm
                                                    setInvalidFields={setInvalidFields}
                                                    invalidFields={invalidFields}
                                                    label={'Công ty'}
                                                    value={dataNhanvien?.Congty?.loaihinhcongty}
                                                    // setValue={setPayloadNhanvien}
                                                    // keyPayload={'CongtyId'}
                                                    type="text"
                                                    disabled={true}
                                                />
                                                {/* <Select
                                                    value={payload?.CongtyId}
                                                    onChange={handleOnSelect}
                                                    options={listCongty}
                                                    placeholder={'Chọn công ty'}
                                                    name="CongtyId"
                                                    onFocus={() => setInvalidFields([])}
                                                    isDisabled={true}
                                                />
                                                {invalidFields.length > 0 &&
                                                    invalidFields.some((i) => i.name === 'CongtyId') && (
                                                        <small>
                                                            {invalidFields.find((i) => i.name === 'CongtyId')?.message}
                                                        </small>
                                                    )} */}
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label htmlFor="inputZip" className="mb-2 fw-bold">
                                                    <span>
                                                        Chi nhánh <b className="text-danger">(*)</b>
                                                    </span>
                                                </label>
                                                <Select
                                                    value={payload?.ChinhanhId}
                                                    onChange={handleOnSelect}
                                                    options={listChinhanh}
                                                    placeholder={'Chọn Chi nhánh'}
                                                    name="ChinhanhId"
                                                    onFocus={() => setInvalidFields([])}
                                                />
                                                {invalidFields.length > 0 &&
                                                    invalidFields.some((i) => i.name === 'ChinhanhId') && (
                                                        <small>
                                                            {
                                                                invalidFields.find((i) => i.name === 'ChinhanhId')
                                                                    ?.message
                                                            }
                                                        </small>
                                                    )}
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label htmlFor="inputZip" className="mb-2 fw-bold">
                                                    <span>
                                                        Phòng ban <b className="text-danger">(*)</b>
                                                    </span>
                                                </label>
                                                <Select
                                                    value={payload?.PhongbanId}
                                                    onChange={handleOnSelect}
                                                    options={listPhongban}
                                                    placeholder={'Chọn Phòng Ban'}
                                                    name="PhongbanId"
                                                    onFocus={() => setInvalidFields([])}
                                                />
                                                {invalidFields.length > 0 &&
                                                    invalidFields.some((i) => i.name === 'PhongbanId') && (
                                                        <small>
                                                            {
                                                                invalidFields.find((i) => i.name === 'PhongbanId')
                                                                    ?.message
                                                            }
                                                        </small>
                                                    )}
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label htmlFor="inputZip" className="mb-2 fw-bold">
                                                    <span>
                                                        Chức vụ <b className="text-danger">(*)</b>
                                                    </span>
                                                </label>
                                                <Select
                                                    value={payload?.ChucvuId}
                                                    onChange={handleOnSelect}
                                                    options={listChucvu}
                                                    placeholder={'Chọn chức vụ'}
                                                    name="ChucvuId"
                                                    onFocus={() => setInvalidFields([])}
                                                />
                                                {invalidFields.length > 0 &&
                                                    invalidFields.some((i) => i.name === 'ChucvuId') && (
                                                        <small>
                                                            {invalidFields.find((i) => i.name === 'ChucvuId')?.message}
                                                        </small>
                                                    )}
                                            </div>
                                            
                                            <div className="form-group col-md-4">
                                                <label htmlFor="inputZip" className="mb-2 fw-bold">
                                                    Kiểu lương <b className="text-danger">(*)</b>
                                                </label>
                                                <Select
                                                    value={payload?.KieuluongId}
                                                    onChange={handleOnSelect}
                                                    options={listKieuluong}
                                                    placeholder={'Hình thức làm việc'}
                                                    name="KieuluongId"
                                                    onFocus={() => setInvalidFields([])}
                                                />
                                                {invalidFields.length > 0 &&
                                                    invalidFields.some((i) => i.name === 'KieuluongId') && (
                                                        <small>
                                                            {
                                                                invalidFields.find((i) => i.name === 'KieuluongId')
                                                                    ?.message
                                                            }
                                                        </small>
                                                    )}
                                            </div>
                                            
                                            <div className="form-group col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="inputZip" className="mb-2 fw-bold">
                                                        <span>
                                                            Mức lương <b className="text-danger">(*)</b>
                                                        </span>
                                                    </label>
                                                    <div className="form-control-wrap">
                                                        <div className="form-text-hint">
                                                            <span className="overline-title">VND</span>
                                                        </div>
                                                        <NumericFormat
                                                            className="form-control px-2"
                                                            value={payload.mucluongformat}
                                                            // displayType={'text'}
                                                            thousandSeparator={true}
                                                            // suffix={'đ'}
                                                            name="mucluong"
                                                            onValueChange={(values) => {
                                                                const {formattedValue, value} = values;
                                                                setPayloadNhanvien((prev) => ({
                                                                    ...prev,
                                                                    ['mucluong']: value,
                                                                }));
                                                                setPayloadNhanvien((prev) => ({
                                                                    ...prev,
                                                                    ['mucluongformat']: formattedValue,
                                                                }));
                                                            }}
                                                            disabled
                                                        />
                                                        {invalidFields.length > 0 &&
                                                            invalidFields.some((i) => i.name === 'mucluong') && (
                                                                <small>
                                                                    {
                                                                        invalidFields.find((i) => i.name === 'mucluong')
                                                                            ?.message
                                                                    }
                                                                </small>
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="form-group col-md-3">
                                                <InputForm
                                                    setInvalidFields={setInvalidFields}
                                                    invalidFields={invalidFields}
                                                    label={'Ngày vào làm việc'}
                                                    value={payload?.ngayvaolamviec}
                                                    setValue={setPayloadNhanvien}
                                                    keyPayload={'ngayvaolamviec'}
                                                    type="date"
                                                    validate
                                                />
                                            </div>
                                            <div className="form-group col-md-3 mb-2">
                                                <label htmlFor="inputZip" className="mb-2 fw-bold">
                                                    Công thức tính lương <b className="text-danger">(*)</b>
                                                </label>
                                                <Select
                                                    value={payload?.CongthucId}
                                                    onChange={handleOnSelect}
                                                    options={listCongthuc}
                                                    placeholder={'Chọn công thức tính lương'}
                                                    name="CongthucId"
                                                    onFocus={() => setInvalidFields([])}
                                                    isDisabled
                                                />
                                                {invalidFields.length > 0 &&
                                                    invalidFields.some((i) => i.name === 'CongthucId') && (
                                                        <small>
                                                            {
                                                                invalidFields.find((i) => i.name === 'CongthucId')
                                                                    ?.message
                                                            }
                                                        </small>
                                                    )}
                                            </div>
                                            <div className="form-group col-md-3">
                                                <label htmlFor="inputZip" className="mb-2 fw-bold">
                                                    Kiểu phép <b className="text-danger">(*)</b>
                                                </label>
                                                
                                                <Select
                                                    value={payload?.type_nghiphep}
                                                    onChange={handleOnSelect}
                                                    options={dataKieuPhepNhanVien}
                                                    placeholder={'Kiểu phép'}
                                                    name="type_nghiphep"
                                                    onFocus={() => setInvalidFields([])}
                                                />
                                                {invalidFields.length > 0 &&
                                                    invalidFields.some((i) => i.name === 'type_nghiphep') && (
                                                        <small>
                                                            {
                                                                invalidFields.find((i) => i.name === 'type_nghiphep')
                                                                    ?.message
                                                            }
                                                        </small>
                                                    )}
                                            </div>
                                            <div className="form-group col-md-3 mb-2">
                                                <>
                                                    <label htmlFor="inputZip" className="mb-2 fw-bold">
                                                        Số ngày phép <Icon name="help-fill" id="songayphep"></Icon>
                                                        <Tooltip
                                                            isOpen={tooltipOpen}
                                                            target="songayphep"
                                                            toggle={toggle}
                                                        >
                                                            Không bao gôm ngày phép được tăng theo thâm niên!
                                                        </Tooltip>
                                                    </label>
                                                </>
                                                <input
                                                    id="nghipheptieuchuan"
                                                    name="nghipheptieuchuan"
                                                    className="form-control"
                                                    value={payload.nghipheptieuchuan}
                                                    onChange={(e) =>
                                                        setPayloadNhanvien((prev) => ({
                                                            ...prev,
                                                            ['nghipheptieuchuan']: e.target.value,
                                                        }))
                                                    }
                                                    onFocus={() => setInvalidFields([])}
                                                />
                                            </div>
                                            <div className="form-group col-md-3 mb-2">
                                                <>
                                                    <label htmlFor="inputZip" className="mb-2 fw-bold">
                                                        Tồn phép cũ <Icon name="help-fill" id="sophepton"></Icon>
                                                        <Tooltip isOpen={tooltipOpen2} target="sophepton"
                                                                 toggle={toggle2}>
                                                            Tồn phép cũ trước khi tạo nhân viên trên hệ thống!
                                                        </Tooltip>
                                                    </label>
                                                </>
                                                <input
                                                    id="sophepton"
                                                    name="sophepton"
                                                    className="form-control"
                                                    type="number"
                                                    value={payload?.sophepton || '0'}
                                                    disabled="true"
                                                    onFocus={() => setInvalidFields([])}
                                                />
                                            </div>
                                            <div className="form-group col-md-3 mb-2">
                                                <label htmlFor="inputZip" className="mb-2 fw-bold">
                                                    Cấu hình tính lương tăng ca
                                                </label>
                                                <Select
                                                    value={payload?.calc_overtime}
                                                    onChange={handleOnSelect}
                                                    options={list_calc_overtime}
                                                    placeholder={'Chọn kiểu tính lương tăng ca'}
                                                    name="calc_overtime"
                                                    onFocus={() => setInvalidFields([])}
                                                />
                                                {invalidFields.length > 0 &&
                                                    invalidFields.some((i) => i.name === 'calc_overtime') && (
                                                        <small>
                                                            {
                                                                invalidFields.find((i) => i.name === 'calc_overtime')
                                                                    ?.message
                                                            }
                                                        </small>
                                                    )}
                                            </div>
                                            <div className="form-group col-md-3 mb-2">
                                                <label htmlFor="inputZip" className="mb-2 fw-bold">
                                                    Cấu hình chuyên cần
                                                </label>
                                                <Select
                                                    value={payload?.CauhinhchuyencanId}
                                                    onChange={handleOnSelect}
                                                    options={listChuyenCan}
                                                    placeholder={'Chọn cấu hình chuyên cần'}
                                                    name="CauhinhchuyencanId"
                                                    onFocus={() => setInvalidFields([])}
                                                />
                                                {invalidFields.length > 0 &&
                                                    invalidFields.some((i) => i.name === 'CauhinhchuyencanId') && (
                                                        <small>
                                                            {
                                                                invalidFields.find((i) => i.name === 'CauhinhchuyencanId')
                                                                    ?.message
                                                            }
                                                        </small>
                                                    )}
                                            </div>
                                            <div className="form-group col-md-3 mb-2">
                                                <label htmlFor="inputZip" className="mb-2 fw-bold">
                                                    Cấu hình thâm niên
                                                </label>
                                                <Select
                                                    value={payload?.CauhinhthamnienId}
                                                    onChange={handleOnSelect}
                                                    options={listThamnien}
                                                    placeholder={'Chọn cấu hình thâm niên'}
                                                    name="CauhinhthamnienId"
                                                    onFocus={() => setInvalidFields([])}
                                                />
                                                {invalidFields.length > 0 &&
                                                    invalidFields.some((i) => i.name === 'CauhinhthamnienId') && (
                                                        <small>
                                                            {
                                                                invalidFields.find((i) => i.name === 'CauhinhthamnienId')
                                                                    ?.message
                                                            }
                                                        </small>
                                                    )}
                                            </div>
                                            <div className="form-group col-md-3 mb-2">
                                                <label htmlFor="inputZip" className="mb-2 fw-bold">
                                                    Cấu hình tính phụ cấp dây chuyền
                                                </label>
                                                <Select
                                                    value={payload?.CauhinhdaychuyenId}
                                                    onChange={handleOnSelect}
                                                    options={listPhuCapDayChuyen}
                                                    placeholder={'Chọn cấu hình dây chuyền'}
                                                    name="CauhinhdaychuyenId"
                                                    onFocus={() => setInvalidFields([])}
                                                />
                                                {invalidFields.length > 0 &&
                                                    invalidFields.some((i) => i.name === 'CauhinhdaychuyenId') && (
                                                        <small>
                                                            {
                                                                invalidFields.find((i) => i.name === 'CauhinhdaychuyenId')
                                                                    ?.message
                                                            }
                                                        </small>
                                                    )}
                                            </div>
                                            
                                            {/* <div className="form-group col-md-12">
                                                    <h6 className="overline-title text-primary">
                                                        Thông tin ca làm việc nhân viên
                                                    </h6>
                                                    
                                                    <DataTable className="card-stretch">
                                                        <DataTableBody>
                                                            <DataTableHead>
                                                                <DataTableRow>
                                                                    <span className="sub-text">#</span>
                                                                </DataTableRow>
                                                                <DataTableRow>
                                                                    <span className="sub-text">Ca làm việc</span>
                                                                </DataTableRow>
                                                                <DataTableRow size="mb">
                                                                    <span className="sub-text">Thời gian bắt đầu</span>
                                                                </DataTableRow>
                                                                <DataTableRow size="mb">
                                                                    <span className="sub-text">Thời gian kết thúc</span>
                                                                </DataTableRow>
                                                                <DataTableRow size="mb">
                                                                    <span className="sub-text">Mã ca làm việc</span>
                                                                </DataTableRow>
                                                                <DataTableRow size="mb">
                                                                    <span className="sub-text">Thời gian checkIn</span>
                                                                </DataTableRow>
                                                                <DataTableRow size="mb">
                                                                    <span className="sub-text">Thời gian checkOut</span>
                                                                </DataTableRow>
                                                            </DataTableHead>
                                                            {payload?.KhaibaocalamviecId?.length > 0
                                                                ? payload?.KhaibaocalamviecId?.map((calamviec, it) => {
                                                                    return (
                                                                        <DataTableItem key={it}>
                                                                            <DataTableRow>
                                                                                <span className="sub-text">{it+1}</span>
                                                                            </DataTableRow>
                                                                            <DataTableRow>
                                                                                <span className="sub-text">{calamviec?.Phanca?.tieude}</span>
                                                                            </DataTableRow>
                                                                            <DataTableRow size="mb">
                                                                                <span className="sub-text">{calamviec?.Phanca?.ngaybatdau}</span>
                                                                            </DataTableRow>
                                                                            <DataTableRow size="mb">
                                                                                <span className="sub-text">{calamviec?.Phanca?.ngayketthuc}</span>
                                                                            </DataTableRow>
                                                                            <DataTableRow size="mb">
                                                                                <span className="sub-text">{calamviec?.Phanca?.Khaibaocalamviecs[0]?.macalamviec}</span>
                                                                            </DataTableRow>
                                                                            <DataTableRow size="mb">
                                                                                <span className="sub-text">
                                                                                    {calamviec?.Phanca?.Khaibaocalamviecs[0]?.batdaugiovaodehieuca}-
                                                                                    {calamviec?.Phanca?.Khaibaocalamviecs[0]?.ketthucgiovaodehieuca}
                                                                                </span>
                                                                            </DataTableRow>
                                                                            <DataTableRow size="mb">
                                                                                <span className="sub-text">
                                                                                    {calamviec?.Phanca?.Khaibaocalamviecs[0]?.batdaugioradehieuca}-
                                                                                    {calamviec?.Phanca?.Khaibaocalamviecs[0]?.ketthucgioradehieuca}
                                                                                </span>
                                                                            </DataTableRow>
                                                                        </DataTableItem>
                                                                    );
                                                                })
                                                                : null}
                                                        </DataTableBody>
                                                    </DataTable>
                                                    
                                                </div> */}
                                        </div>
                                        <div className="col-12 mt-1 d-flex justify-content-end">
                                            <Button color="primary" onClick={handleSubmit}>
                                                Cập nhật
                                            </Button>
                                        </div>
                                        
                                        <div className="mt-3">
                                            <div className="form-group col-md-12">
                                                <div className="pb-0">
                                                    <h6 className="overline-title text-primary">
                                                        Thông tin người phụ thuộc
                                                    </h6>
                                                    <Button
                                                        color="primary"
                                                        className="btn-icon px-4 mt-2"
                                                        onClick={handleAddNPT}
                                                    >
                                                        <Icon name="plus"></Icon> Thêm người phụ thuộc
                                                    </Button>
                                                </div>
                                            </div>
                                            
                                            {dataNguoiPhuThuoc?.length > 0 && (
                                                <DataTable className="card-stretch">
                                                    <div style={{overflowY: 'hidden'}}>
                                                        <DataTableBody>
                                                            <DataTableHead>
                                                                <DataTableRow>
                                                                    <span className="sub-text">Stt</span>
                                                                </DataTableRow>
                                                                <DataTableRow>
                                                                    <span className='sub-text w-150px'>Họ tên</span>
                                                                </DataTableRow>
                                                                <DataTableRow>
                                                                    <span className='sub-text w-150px'>Quan hệ</span>
                                                                </DataTableRow>
                                                                <DataTableRow>
                                                                    <span
                                                                        className='sub-text w-150px'>Thời gian tính GT</span>
                                                                </DataTableRow>
                                                                <DataTableRow>
                                                                    <span className='sub-text w-150px'>Ngày sinh</span>
                                                                </DataTableRow>
                                                                <DataTableRow>
                                                                    <span className='sub-text w-150px'>CMT/CCCD</span>
                                                                </DataTableRow>
                                                                <DataTableRow>
                                                                    <span className='sub-text w-150px'>Giấy tờ</span>
                                                                </DataTableRow>
                                                                <DataTableRow>
                                                                    <span className='sub-text w-150px'>Số điện thoại</span>
                                                                </DataTableRow>
                                                                <DataTableRow>
                                                                    <span className="nk-tb-col-tools text-end"></span>
                                                                </DataTableRow>
                                                            </DataTableHead>
                                                            {dataNguoiPhuThuoc.map((ngPT, it) => {
                                                                return (
                                                                    <DataTableItem key={it}>
                                                                        <DataTableRow>
                                                                            <span className="sub-text">{it + 1}</span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                            <span className="sub-text">{ngPT.hoten}</span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                                <span className="sub-text">
                                                                                    {ngPT.quanhevsnhanvien}
                                                                                </span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                                <span className="sub-text">
                                                                                    {ngPT.thoidiemtinhgiamtrutu}
                                                                                </span>
                                                                            <span className="sub-text">
                                                                                    {ngPT.thoidiemtinhgiamtruden}
                                                                                </span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                                <span className="sub-text">
                                                                                    {ngPT.ngaysinh}
                                                                                </span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                            <span className="sub-text">{ngPT.cmnd}</span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                                <span className="sub-text">
                                                                                    {ngPT.giayto}
                                                                                </span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                                <span className="sub-text">
                                                                                    {ngPT.sodienthoai}
                                                                                </span>
                                                                        </DataTableRow>
                                                                        <DataTableRow className="nk-tb-col-tools ">
                                                                            <ul className="nk-tb-actions gx-4">
                                                                                <button
                                                                                    onClick={() => handleEditNPT(ngPT)}
                                                                                    className={
                                                                                        'btn btn-primary ms-0 badge text-white p-1'
                                                                                    }
                                                                                >
                                                                                    <em className="icon ni ni-edit"></em>
                                                                                </button>
                                                                                <button
                                                                                    onClick={() => handleDeleteNPT(ngPT)}
                                                                                    className={
                                                                                        'btn btn-danger ms-0 badge text-white p-1'
                                                                                    }
                                                                                >
                                                                                    <em className="icon ni ni-trash-alt"></em>
                                                                                </button>
                                                                            </ul>
                                                                        </DataTableRow>
                                                                    </DataTableItem>
                                                                );
                                                            })
                                                            }
                                                        </DataTableBody>
                                                    </div>
                                                </DataTable>
                                            )}
                                        </div>
                                    </div>
                                    <div className={`tab-pane ${modalTab === '2' ? 'active' : ''}`} id="congviec">
                                        <div className="mt-5">
                                            <div className="form-group col-md-12">
                                                <div className="pb-0">
                                                    <h6 className="overline-title text-primary">Thông tin phụ cấp</h6>
                                                    {dataNhanvien?.Phucapnhanviens?.length < phucap?.length && (
                                                        <Button
                                                            color="primary"
                                                            className="btn-icon px-4 mt-2"
                                                            onClick={handleAddPhuCap}
                                                        >
                                                            <Icon name="plus"></Icon> Thêm phụ cấp
                                                        </Button>
                                                    )}
                                                </div>
                                            </div>
                                            {dataNhanvien?.Phucapnhanviens?.length > 0 && (
                                                <DataTable className="card-stretch">
                                                    <div style={{overflowY: 'hidden'}}>
                                                        <DataTableBody>
                                                            <DataTableHead>
                                                                <DataTableRow>
                                                                    <span className="sub-text">Stt</span>
                                                                </DataTableRow>
                                                                <DataTableRow>
                                                                    <span className='sub-text w-150px'>Tên phụ cấp</span>
                                                                </DataTableRow>
                                                                <DataTableRow>
                                                                    <span className='sub-text w-150px'>Mã phụ cấp</span>
                                                                </DataTableRow>
                                                                <DataTableRow>
                                                                    <span className='sub-text w-150px'>Mức phụ cấp</span>
                                                                </DataTableRow>
                                                                <DataTableRow>
                                                                    <span
                                                                        className='sub-text w-150px'>Phụ cấp Đóng BHXH</span>
                                                                </DataTableRow>
                                                                <DataTableRow>
                                                                    <span
                                                                        className='sub-text w-150px'>Phụ cấp Đóng Thuế</span>
                                                                </DataTableRow>
                                                                {/*<DataTableRow>*/}
                                                                {/*    <span className="sub-text">Trạng Thái</span>*/}
                                                                {/*</DataTableRow>*/}
                                                                <DataTableRow>
                                                                    <span className="nk-tb-col-tools text-end"></span>
                                                                </DataTableRow>
                                                            </DataTableHead>
                                                            {dataNhanvien?.Phucapnhanviens.map((phieuluong, it) => {
                                                                return (
                                                                    <DataTableItem key={it}>
                                                                        <DataTableRow>
                                                                            <span className="sub-text">{it + 1}</span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                            <span className="sub-text">
                                                                                {phieuluong?.Phucap?.title}
                                                                            </span>
                                                                        </DataTableRow>
                                                                        
                                                                        <DataTableRow>
                                                                            <span className="sub-text">
                                                                                {phieuluong?.Phucap?.maphucap}
                                                                            </span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                            <span className="sub-text">
                                                                                <NumericFormat
                                                                                    value={phieuluong?.giatri}
                                                                                    displayType={'text'}
                                                                                    thousandSeparator={true}
                                                                                    suffix={'đ'}
                                                                                />
                                                                            </span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                            <span
                                                                                className={`${
                                                                                    phieuluong?.Phucap
                                                                                        ?.apdungdongbaohiem === true
                                                                                        ? 'bg-success'
                                                                                        : 'bg-danger'
                                                                                } ms-0 badge text-white`}
                                                                            >
                                                                                {phieuluong?.Phucap?.apdungdongbaohiem ===
                                                                                true
                                                                                    ? 'Áp dụng'
                                                                                    : 'Không Áp Dụng'}
                                                                            </span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                            <span
                                                                                className={`${
                                                                                    phieuluong?.Phucap?.apdungdongthue ===
                                                                                    true
                                                                                        ? 'bg-success'
                                                                                        : 'bg-danger'
                                                                                } ms-0 badge text-white`}
                                                                            >
                                                                                {phieuluong?.Phucap?.apdungdongthue ===
                                                                                true
                                                                                    ? 'Áp dụng'
                                                                                    : 'Không Áp Dụng'}
                                                                            </span>
                                                                        </DataTableRow>
                                                                        {/*<DataTableRow>*/}
                                                                        {/*      <span*/}
                                                                        {/*          className={`${*/}
                                                                        {/*              phieuluong?.TrangthaiId === 0*/}
                                                                        {/*                  ? 'bg-danger'*/}
                                                                        {/*                  : 'bg-success'*/}
                                                                        {/*          } ms-0 badge text-white`}*/}
                                                                        {/*      >*/}
                                                                        {/*          {phieuluong?.TrangthaiId === 0*/}
                                                                        {/*              ? 'Không hoạt động'*/}
                                                                        {/*              : 'Vẫn Hoạt động'}*/}
                                                                        {/*      </span>*/}
                                                                        {/*</DataTableRow>*/}
                                                                        <DataTableRow className="nk-tb-col-tools ">
                                                                            <ul className="nk-tb-actions gx-4">
                                                                                <button
                                                                                    onClick={() =>
                                                                                        handleEditPhuCap(phieuluong)
                                                                                    }
                                                                                    className={
                                                                                        'btn btn-primary ms-0 badge text-white p-1'
                                                                                    }
                                                                                >
                                                                                    <em className="icon ni ni-edit"></em>
                                                                                </button>
                                                                                <button
                                                                                    onClick={() =>
                                                                                        handleDeletePhuCap(phieuluong)
                                                                                    }
                                                                                    className={
                                                                                        'btn btn-danger ms-0 badge text-white p-1'
                                                                                    }
                                                                                >
                                                                                    <em className="icon ni ni-trash-alt"></em>
                                                                                </button>
                                                                            </ul>
                                                                        </DataTableRow>
                                                                    </DataTableItem>
                                                                );
                                                            })
                                                            }
                                                        </DataTableBody>
                                                    </div>
                                                </DataTable>
                                            )}
                                        </div>
                                        {/* <div className="mt-5">
                                            <div className="form-group col-md-12">
                                                    <div className="pb-0">
                                                        <h6 className="overline-title text-primary">Lịch sử quyết định</h6>
                                                    </div>
                                                </div>
                                        </div> */}
                                        <div className="mt-5">
                                            <div className="form-group col-md-12">
                                                <div className="pb-0">
                                                    <h6 className="overline-title text-primary">Tài khoản ngân
                                                        hàng{dataNganHang?.length}</h6>
                                                    <Button
                                                        color="primary"
                                                        className="btn-icon px-4 mt-2"
                                                        onClick={handleAddBank}
                                                    >
                                                        <Icon name="plus"></Icon> Thêm tài khoản ngân hàng
                                                    </Button>
                                                </div>
                                            </div>
                                            {dataNganHang?.length > 0 && (
                                                <DataTable className="card-stretch">
                                                    <div style={{overflowY: 'hidden'}}>
                                                        <DataTableBody>
                                                            <DataTableHead>
                                                                <DataTableRow>
                                                                    <span className="sub-text">Stt</span>
                                                                </DataTableRow>
                                                                <DataTableRow>
                                                                    <span className='sub-text w-150px'>Tên ngân hàng</span>
                                                                </DataTableRow>
                                                                <DataTableRow>
                                                                    <span className='sub-text w-150px'>Số tài khoản</span>
                                                                </DataTableRow>
                                                                <DataTableRow>
                                                                    <span className='sub-text w-150px'>Ngời hưởng thụ</span>
                                                                </DataTableRow>
                                                                <DataTableRow>
                                                                    <span className='sub-text w-150px'>Trạng thái</span>
                                                                </DataTableRow>
                                                                <DataTableRow>
                                                                    <span className="nk-tb-col-tools text-end"></span>
                                                                </DataTableRow>
                                                            </DataTableHead>
                                                            {dataNganHang.map((ngHang, it) => {
                                                                if (ngHang?.NhanvienId === dataNhanvien?.id) {
                                                                    return (
                                                                        <DataTableItem key={it}>
                                                                            <DataTableRow>
                                                                                <span className="sub-text">{it + 1}</span>
                                                                            </DataTableRow>
                                                                            <DataTableRow>
                                                                                <span className="sub-text">
                                                                                    {ngHang?.tennganhang}
                                                                                </span>
                                                                            </DataTableRow>
                                                                            <DataTableRow>
                                                                                <span className="sub-text">
                                                                                    {ngHang?.sotaikhoan}
                                                                                </span>
                                                                            </DataTableRow>
                                                                            <DataTableRow>
                                                                                <span className="sub-text">
                                                                                    {ngHang?.nguoihuongthu}
                                                                                </span>
                                                                            </DataTableRow>
                                                                            <DataTableRow>
                                                                                <span
                                                                                    className={`${
                                                                                        ngHang?.TrangthaiId === 1
                                                                                            ? 'bg-success'
                                                                                            : 'bg-danger'
                                                                                    } ms-0 badge text-white`}
                                                                                >
                                                                                    {ngHang?.TrangthaiId === 1
                                                                                        ? 'Hoạt động'
                                                                                        : 'Không Hoạt động'}
                                                                                </span>
                                                                            </DataTableRow>
                                                                            <DataTableRow className="nk-tb-col-tools ">
                                                                                <ul className="nk-tb-actions gx-4">
                                                                                    <button
                                                                                        onClick={() => handleEditBank(ngHang)}
                                                                                        className={
                                                                                            'btn btn-primary ms-0 badge text-white p-1'
                                                                                        }
                                                                                    >
                                                                                        <em className="icon ni ni-edit"></em>
                                                                                    </button>
                                                                                    <button
                                                                                        onClick={() =>
                                                                                            handleDeleteBank(ngHang)
                                                                                        }
                                                                                        className={
                                                                                            'btn btn-danger ms-0 badge text-white p-1'
                                                                                        }
                                                                                    >
                                                                                        <em className="icon ni ni-trash-alt"></em>
                                                                                    </button>
                                                                                </ul>
                                                                            </DataTableRow>
                                                                        </DataTableItem>
                                                                    );
                                                                }
                                                            })}
                                                        </DataTableBody>
                                                    </div>
                                                </DataTable>
                                            )}
                                        </div>
                                        <div className="mt-5">
                                            <div className="form-group col-md-12">
                                                <div className="pb-0">
                                                    <h6 className="overline-title text-primary">Máy chấm công</h6>
                                                    <Button
                                                        color="primary"
                                                        className="btn-icon px-4 mt-2"
                                                        onClick={handleAddMcc}
                                                    >
                                                        <Icon name="plus"></Icon> Thêm máy
                                                    </Button>
                                                </div>
                                            </div>
                                            {dataNhanvien?.Thongtinchamcongs?.length > 0 && (
                                                <DataTable className="card-stretch">
                                                    <div style={{overflowY: 'hidden'}}>
                                                        <DataTableBody>
                                                            <DataTableHead>
                                                                <DataTableRow>
                                                                    <span className='sub-text'>Stt</span>
                                                                </DataTableRow>
                                                                <DataTableRow>
                                                                    <span className='sub-text w-150px'>Tên/Vị trí máy chấm công</span>
                                                                </DataTableRow>
                                                                <DataTableRow>
                                                                    <span className='sub-text w-150px'>Mã chấm công nhân viên</span>
                                                                </DataTableRow>
                                                                <DataTableRow>
                                                                    <span className='sub-text w-150px'>Tên chấm công</span>
                                                                </DataTableRow>
                                                                <DataTableRow>
                                                                    <span className='sub-text w-150px'>Mã thẻ</span>
                                                                </DataTableRow>
                                                                <DataTableRow
                                                                    className="nk-tb-col-tools text-end"></DataTableRow>
                                                            </DataTableHead>
                                                            {dataNhanvien?.Thongtinchamcongs?.map((itemMcc, itm) => {
                                                                return (
                                                                    <DataTableItem key={itm}>
                                                                        <DataTableRow>
                                                                                <span className="sub-text">
                                                                                    {itm + 1}
                                                                                </span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                                <span className="sub-text">
                                                                                    {itemMcc?.Maychamcong?.tenmaychamcong}
                                                                                </span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                                <span className="sub-text">
                                                                                    {itemMcc?.tenchamcong}
                                                                                </span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                                <span className="sub-text">
                                                                                    {itemMcc?.mathe}
                                                                                </span>
                                                                        </DataTableRow>
                                                                        
                                                                        <DataTableRow className="nk-tb-col-tools ">
                                                                            <ul className="nk-tb-actions gx-4">
                                                                                <button
                                                                                    onClick={() => handleEditMcc(itemMcc)}
                                                                                    className={
                                                                                        'btn btn-primary ms-0 badge text-white p-1'
                                                                                    }
                                                                                >
                                                                                    <em className="icon ni ni-edit"></em>
                                                                                </button>
                                                                                <button
                                                                                    onClick={() =>
                                                                                        handleDeleteMcc(itemMcc)
                                                                                    }
                                                                                    className={
                                                                                        'btn btn-danger ms-0 badge text-white p-1'
                                                                                    }
                                                                                >
                                                                                    <em className="icon ni ni-trash-alt"></em>
                                                                                </button>
                                                                            </ul>
                                                                        </DataTableRow>
                                                                    </DataTableItem>
                                                                );
                                                            })}
                                                        </DataTableBody>
                                                    </div>
                                                </DataTable>
                                            )}
                                        </div>
                                    </div>
                                    <div className={`tab-pane ${modalTab === '3' ? 'active' : ''}`} id="nghiphep">
                                        <div className="nk-data data-list">
                                            <div className="data-head">
                                                <h6 className="overline-title">Tổng hợp kế hoạch nghỉ phép năm</h6>
                                            </div>
                                            <DataTable className="card-stretch">
                                                <div style={{overflowY: 'hidden'}}>
                                                    <DataTableBody>
                                                        <DataTableHead>
                                                            <DataTableRow>
                                                                <span className="sub-text">Năm</span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="sub-text w-100px">Số phép tồn</span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="sub-text w-150px">Số phép thâm niên</span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="sub-text w-100px">Số phép còn lại</span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="sub-text w-80px">Đã nghỉ</span>
                                                            </DataTableRow>
                                                            {/* <DataTableRow>
                                                                <span className="sub-text w-80px">Còn lại</span>
                                                            </DataTableRow> */}
                                                            <DataTableRow>
                                                                <span className="sub-text">T1</span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="sub-text">T2</span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="sub-text">T3</span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="sub-text">T4</span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="sub-text">T5</span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="sub-text">T6</span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="sub-text">T7</span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="sub-text">T8</span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="sub-text">T9</span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="sub-text">T10</span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="sub-text">T11</span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span className="sub-text">T12</span>
                                                            </DataTableRow>
                                                        </DataTableHead>
                                                        {dataNhanvien?.Phepnhanviens?.length > 0 ?
                                                            dataNhanvien?.Phepnhanviens.map((item, index) => {
                                                                let danghi =
                                                                    Number(item?.thang1) +
                                                                    Number(item?.thang2) +
                                                                    Number(item?.thang3) +
                                                                    Number(item?.thang4) +
                                                                    Number(item?.thang5) +
                                                                    Number(item?.thang6) +
                                                                    Number(item?.thang7) +
                                                                    Number(item?.thang8) +
                                                                    Number(item?.thang9) +
                                                                    Number(item?.thang10) +
                                                                    Number(item?.thang11) +
                                                                    Number(item?.thang12);
                                                                // let tongphep = Number(item?.sophepton) + Number(item?.sophepthamnien) + Number(item?.sophephientai);
                                                                // let conlai = Number(tongphep) - Number(danghi);
                                                                return (
                                                                    <DataTableItem key={index}>
                                                                        <DataTableRow>
                                                                            <span className="sub-text">{item?.nam}</span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                            <span className="sub-text">
                                                                                {' '}
                                                                                {item?.sophepton}
                                                                            </span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                            <span className="sub-text">
                                                                                {' '}
                                                                                {item?.sophepthamnien}
                                                                            </span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                            <span className="sub-text">
                                                                                {' '}
                                                                                {item?.sophephientai}
                                                                            </span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                            <span className="sub-text">{danghi}</span>
                                                                        </DataTableRow>
                                                                        {/* <DataTableRow>
                                                                            <span className="sub-text">{conlai}</span>
                                                                        </DataTableRow> */}
                                                                        <DataTableRow>
                                                                            <span className="sub-text">
                                                                                {' '}
                                                                                {item?.thang1}
                                                                            </span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                            <span className="sub-text">
                                                                                {' '}
                                                                                {item?.thang2}
                                                                            </span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                            <span className="sub-text">
                                                                                {' '}
                                                                                {item?.thang3}
                                                                            </span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                            <span className="sub-text">
                                                                                {' '}
                                                                                {item?.thang4}
                                                                            </span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                            <span className="sub-text">
                                                                                {' '}
                                                                                {item?.thang5}
                                                                            </span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                            <span className="sub-text">
                                                                                {' '}
                                                                                {item?.thang6}
                                                                            </span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                            <span className="sub-text">
                                                                                {' '}
                                                                                {item?.thang7}
                                                                            </span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                            <span className="sub-text">
                                                                                {' '}
                                                                                {item?.thang8}
                                                                            </span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                            <span className="sub-text">
                                                                                {' '}
                                                                                {item?.thang9}
                                                                            </span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                            <span className="sub-text">
                                                                                {' '}
                                                                                {item?.thang10}
                                                                            </span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                            <span className="sub-text">
                                                                                {' '}
                                                                                {item?.thang11}
                                                                            </span>
                                                                        </DataTableRow>
                                                                        <DataTableRow>
                                                                            <span className="sub-text">
                                                                                {' '}
                                                                                {item?.thang12}
                                                                            </span>
                                                                        </DataTableRow>
                                                                    </DataTableItem>
                                                                );
                                                            })
                                                            : (
                                                                <DataTableItem>
                                                                    <DataTableRow>
                                                                        <span
                                                                            className="sub-text">{new Date().getFullYear()}</span>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                            <span className="sub-text">
                                                                               0
                                                                            </span>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                            <span className="sub-text">
                                                                                0
                                                                            </span>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                            <span className="sub-text">
                                                                                0
                                                                            </span>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                        <span className="sub-text">0</span>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                        <span className="sub-text">0</span>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                            <span className="sub-text">
                                                                                0
                                                                            </span>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                            <span className="sub-text">
                                                                                0
                                                                            </span>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                            <span className="sub-text">
                                                                                0
                                                                            </span>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                            <span className="sub-text">
                                                                                0
                                                                            </span>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                            <span className="sub-text">
                                                                                0
                                                                            </span>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                            <span className="sub-text">
                                                                                0
                                                                            </span>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                            <span className="sub-text">
                                                                                0
                                                                            </span>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                            <span className="sub-text">
                                                                                0
                                                                            </span>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                            <span className="sub-text">
                                                                                0
                                                                            </span>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                            <span className="sub-text">
                                                                                0
                                                                            </span>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                            <span className="sub-text">
                                                                                0
                                                                            </span>
                                                                    </DataTableRow>
                                                                
                                                                </DataTableItem>
                                                            )}
                                                    </DataTableBody>
                                                </div>
                                            </DataTable>
                                        </div>
                                    
                                    </div>
                                    <div className={`tab-pane ${modalTab === '4' ? 'active' : ''}`} id="taisan">
                                        <div className="data-head d-flex justify-content-between">
                                            <h6 className="overline-title my-auto">Thông tin tài sản nhân viên</h6>
                                            
                                            {/*<Button color="primary" onClick={handleAddTaisan}>*/}
                                            {/*    Cấp tài sản*/}
                                            {/*</Button>*/}
                                        </div>
                                        <DataTable className="card-stretch">
                                            <div style={{overflowY: 'hidden'}}>
                                                <DataTableBody>
                                                    <DataTableHead>
                                                        <DataTableRow>
                                                            <span className="sub-text">Stt</span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="sub-text w-100px">Tên tài sản</span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="sub-text w-100px">Mã tài sản</span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="sub-text w-100px">Số lượng</span>
                                                        </DataTableRow>
                                                        {/* <DataTableRow>
                                                            <span className="sub-text w-100px">Mô tả</span>
                                                        </DataTableRow> */}
                                                        <DataTableRow>
                                                            <span className="sub-text w-100px">Ngày cấp</span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="sub-text w-100px">Mã tài sản sử dụng</span>
                                                        </DataTableRow>
                                                    </DataTableHead>
                                                    {dataTaisan.length > 0
                                                        ? dataTaisan.map((tsan, it) => {
                                                            return (
                                                                <DataTableItem key={it}>
                                                                    <DataTableRow>
                                                                        <span className="sub-text">{it + 1}</span>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                        <span className="sub-text">
                                                                            {tsan?.Taisan?.tentaisan}
                                                                        </span>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                        <span className="sub-text">
                                                                            {tsan?.Taisan?.mataisan}
                                                                        </span>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                        <span className="sub-text">{tsan?.soluong}</span>
                                                                    </DataTableRow>
                                                                    {/* <DataTableRow>
                                                                        <span className="sub-text">
                                                                            {tsan?.ghichu}
                                                                        </span>
                                                                    </DataTableRow> */}
                                                                    <DataTableRow>
                                                                        <span className="sub-text">
                                                                            {format(
                                                                                parseISO(tsan?.createdAt),
                                                                                'dd-MM-yyyy hh:mm',
                                                                            )}
                                                                        </span>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                        <span className="sub-text">
                                                                            {tsan?.mataisannhanvien}
                                                                        </span>
                                                                    </DataTableRow>
                                                                </DataTableItem>
                                                            );
                                                        })
                                                        : null}
                                                </DataTableBody>
                                            </div>
                                        </DataTable>
                                    </div>
                                    {/* <div className={`tab-pane ${modalTab === '7' ? 'active' : ''}`} id="tangca-neuco">
                                        <div className="form-group col-md-12">
                                            <div className="pb-0">
                                                <h6 className="overline-title text-primary">Thông tin tăng ca</h6>
                                                <Button
                                                    color="primary"
                                                    className="btn-icon px-4 mt-2"
                                                    onClick={handleAddCauhinhtangca}
                                                >
                                                    <Icon name="plus"></Icon> Thêm mới tăng ca
                                                </Button>
                                            </div>
                                        </div>
                                        
                                        <div className="data-head d-flex justify-content-between">
                                            <h6 className="overline-title my-auto">Danh sách tăng ca</h6>
                                        </div>
                                        <DataTable className="card-stretch">
                                            <div style={{overflowY: 'hidden'}}>
                                                <DataTableBody>
                                                    <DataTableHead>
                                                        <DataTableRow>
                                                            <span className="sub-text">Stt</span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="sub-text w-100px">Tăng ca</span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="sub-text w-100px">Hệ số hưởng</span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="sub-text w-100px">Ngày bắt đầu</span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="sub-text w-100px">Ngày kết thúc</span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="sub-text w-100px">Giờ bắt đầu</span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="sub-text w-100px">Giờ kết thúc</span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="sub-text w-100px">Trạng Thái</span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="nk-tb-col-tools text-end"></span>
                                                        </DataTableRow>
                                                    </DataTableHead>
                                                    {dataNhanvien?.Quanlytangcas?.length > 0
                                                        ? dataNhanvien?.Quanlytangcas?.map((tca, it) => {
                                                            return (
                                                                <DataTableItem key={it}>
                                                                    <DataTableRow>
                                                                        <span className="sub-text">{it + 1}</span>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                        <span className="sub-text">
                                                                            {tca?.Cauhinhtangca?.title}
                                                                        </span>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                        <span className="sub-text">
                                                                            {tca?.Cauhinhtangca?.giatri}
                                                                        </span>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                        <span className="sub-text">{tca.ngaybatdau}</span>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                        <span className="sub-text">
                                                                            {tca.ngayketthuc}
                                                                        </span>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                        <span className="sub-text">{tca.giobatdau}</span>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                        <span className="sub-text">{tca.gioketthuc}</span>
                                                                    </DataTableRow>
                                                                    <DataTableRow>
                                                                        <span
                                                                            className={`${
                                                                                tca?.TrangthaiId === 1
                                                                                    ? 'bg-success'
                                                                                    : 'bg-danger'
                                                                            } ms-0 badge text-white`}
                                                                        >
                                                                            {tca?.TrangthaiId === 1
                                                                                ? 'Hoạt động'
                                                                                : 'Không hoạt động'}
                                                                        </span>
                                                                    </DataTableRow>
                                                                    <DataTableRow className="nk-tb-col-tools ">
                                                                        <ul className="nk-tb-actions gx-4">
                                                                            <button
                                                                                onClick={() => handleEditTangca(tca)}
                                                                                className={
                                                                                    'btn btn-primary ms-0 badge text-white p-1'
                                                                                }
                                                                            >
                                                                                <em className="icon ni ni-edit"></em>
                                                                            </button>
                                                                            <button
                                                                                onClick={() => handleDeleteTangca(tca)}
                                                                                className={
                                                                                    'btn btn-danger ms-0 badge text-white p-1'
                                                                                }
                                                                            >
                                                                                <em className="icon ni ni-trash-alt"></em>
                                                                            </button>
                                                                        </ul>
                                                                    </DataTableRow>
                                                                </DataTableItem>
                                                            );
                                                        })
                                                        : null}
                                                </DataTableBody>
                                            </div>
                                        </DataTable>
                                    </div> */}
                                    <div className={`tab-pane ${modalTab === '5' ? 'active' : ''}`} id="tailieu">
                                        <div className="row">
                                            <DataTable className="card-stretch">
                                                <DataTableBody>
                                                    <DataTableHead>
                                                        <DataTableRow>
                                                            <span className="sub-text">Stt</span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="sub-text">Tên tài liệu</span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="sub-text">Mã tài sản</span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="sub-text">Mô tả</span>
                                                        </DataTableRow>
                                                    </DataTableHead>
                                                    <DataTableItem>
                                                        <DataTableRow>
                                                            <span className="sub-text">1</span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="sub-text">Tivi 27in</span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="sub-text">TV_27IN</span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="sub-text">Mới mua</span>
                                                        </DataTableRow>
                                                    </DataTableItem>
                                                </DataTableBody>
                                            </DataTable>
                                        </div>
                                    </div>
                                    <div className={`tab-pane ${modalTab === '6' ? 'active' : ''}`} id="chamcong">
                                        <div className="row">
                                            <div className="card-inner position-relative card-tools-toggle p-0">
                                                <div className="row">
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                                                            <span>
                                                                Chọn bảng chấm công theo thời gian bạn muốn xem <b>(*)</b>
                                                            </span>
                                                        </label>
                                                        <Select
                                                            value={payloadSelect.BangchotcongId}
                                                            onChange={handleOnSelectFilter}
                                                            options={listBangchotcong}
                                                            placeholder={'Bảng chấm công'}
                                                            name="BangchotcongId"
                                                            onFocus={() => setInvalidFields([])}
                                                        />
                                                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'BangchotcongId') && (
                                                            <small>
                                                                {invalidFields.find((i) => i.name === 'BangchotcongId')?.message}
                                                            </small>
                                                        )}
                                                    </div>
                                                    <div
                                                        className="form-group col-md-2 h-50 my-auto justify-content-between d-flex">
                                                        <button
                                                            className="btn btn-info py-1 mt-2"
                                                            onClick={handleSubmitChamCong}
                                                        >
                                                            <em className="icon ni ni-search"></em>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <DataTable className="card-stretch">
                                                <DataTableBody>
                                                    <DataTableHead>
                                                        <DataTableRow>
                                                            <span className="sub-text">STT</span>
                                                        </DataTableRow>
                                                        <DataTableRow size="mb">
                                                            <span className="sub-text">Ngày</span>
                                                        </DataTableRow>
                                                        <DataTableRow size="mb">
                                                            <span className="sub-text">Họ tên</span>
                                                        </DataTableRow>
                                                        <DataTableRow size="mb">
                                                            <span className="sub-text">Giờ ra</span>
                                                        </DataTableRow>
                                                        <DataTableRow size="mb">
                                                            <span className="sub-text">Vào 1</span>
                                                        </DataTableRow>
                                                        <DataTableRow size="mb">
                                                            <span className="sub-text">Ra 1</span>
                                                        </DataTableRow>
                                                        <DataTableRow size="mb">
                                                            <span className="sub-text">Vào 2</span>
                                                        </DataTableRow>
                                                        <DataTableRow size="mb">
                                                            <span className="sub-text">Ra 2</span>
                                                        </DataTableRow>
                                                        <DataTableRow size="mb">
                                                            <span className="sub-text text-center">Tổng công</span>
                                                        </DataTableRow>
                                                        <DataTableRow size="mb">
                                                            <span className="sub-text text-center">Tổng giờ</span>
                                                        </DataTableRow>
                                                    </DataTableHead>
                                                    {dataC.length > 0
                                                        ? dataC.map((item, index) => {
                                                            return (
                                                                <DataTableItem key={item.id}>
                                                                    <DataTableRow size="mb">
                                                                        <span className="tb-amount">{index + 1}</span>
                                                                    </DataTableRow>
                                                                    <DataTableRow size="mb">
                                                                        <span className="tb-amount">{item?.day}</span>
                                                                    </DataTableRow>
                                                                    <DataTableRow size="mb">
                                                                          <span className="tb-amount">
                                                                              {item.Nhanvien.hoten}
                                                                          </span>
                                                                    </DataTableRow>
                                                                    <DataTableRow size="mb">
                                                                          <span className="tb-amount">
                                                                              {item?.cham}
                                                                          </span>
                                                                    </DataTableRow>
                                                                    <DataTableRow size="mb">
                                                                          <span className="tb-amount">
                                                                              {item?.vao1}
                                                                          </span>
                                                                    </DataTableRow>
                                                                    <DataTableRow size="mb">
                                                                        <span className="tb-amount">{item?.ra1}</span>
                                                                    </DataTableRow>
                                                                    <DataTableRow size="mb">
                                                                          <span className="tb-amount">
                                                                              {item?.vao2}
                                                                          </span>
                                                                    </DataTableRow>
                                                                    <DataTableRow size="mb">
                                                                        <span className="tb-amount">{item?.ra2}</span>
                                                                    </DataTableRow>
                                                                    <DataTableRow size="mb text-center">
                                                                          <span className="tb-amount">
                                                                              {item?.cong}
                                                                          </span>
                                                                    </DataTableRow>
                                                                    <DataTableRow size="mb text-center">
                                                                          <span className="tb-amount">
                                                                              {item?.tonggio}
                                                                          </span>
                                                                    </DataTableRow>
                                                                </DataTableItem>
                                                            );
                                                        })
                                                        : null}
                                                </DataTableBody>
                                            </DataTable>
                                        </div>
                                    </div>
                                    <div className={`tab-pane ${modalTab === '8' ? 'active' : ''}`} id="phieuluong">
                                        <div className="row">
                                            <div className="card-inner position-relative card-tools-toggle p-0">
                                                <div className="row">
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                                                            <span>
                                                                Chọn bảng chấm công theo thời gian bạn muốn xem <b>(*)</b>
                                                            </span>
                                                        </label>
                                                        <Select
                                                            value={payloadSelect.BangchotcongId}
                                                            onChange={handleOnSelectFilter}
                                                            options={listBangchotcong}
                                                            placeholder={'Bảng chấm công'}
                                                            name="BangchotcongId"
                                                            onFocus={() => setInvalidFields([])}
                                                        />
                                                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'BangchotcongId') && (
                                                            <small>
                                                                {invalidFields.find((i) => i.name === 'BangchotcongId')?.message}
                                                            </small>
                                                        )}
                                                    </div>
                                                    <div
                                                        className="form-group col-md-2 h-50 my-auto justify-content-between d-flex">
                                                        <button
                                                            className="btn btn-info py-1 mt-2"
                                                            onClick={handleSubmitPhieuLuong}
                                                        >
                                                            <em className="icon ni ni-search"></em>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            {dataP
                                                ? dataP.map((item, index) => {
                                                    console.log('item: ' , item)
                                                    let arrPhuCap = JSON.parse(item?.phuCapNhanVien);
                                                    let usingPhucap = Object.entries(arrPhuCap).map(([key, value]) => ({
                                                        name: key,
                                                        value: value
                                                    }));
                                                    let ngayBatDau = item?.Bangchotcong?.ngaybatdau
                                                    let ngayKetThuc = item?.Bangchotcong?.ngayketthuc
                                                    let dateActive = item?.dateActive
                                                    let is_order = item?.is_order
                                                    let sttT = 1;
                                                    return (
                                                        <Table bordered>
                                                            <thead>
                                                            <tr>
                                                                <th colSpan={4} className="text-center">
                                                                    Phiếu Lương
                                                                </th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            
                                                            <tr>
                                                                <td className="py-1 fw-bold">Thời gian</td>
                                                                <td className="py-1" colSpan={2}>
                                                                          <span>
                                                                              {ngayBatDau
                                                                                  ? format(parseISO(ngayBatDau), 'dd/MM/yyyy')
                                                                                  : ''} - {ngayKetThuc
                                                                              ? format(parseISO(ngayKetThuc), 'dd/MM/yyyy')
                                                                              : ''}
                                                                          </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="py-1 fw-bold">Tên NV</td>
                                                                <td className="py-1" colSpan={2}>
                                                                          <span>
                                                                              {dataNhanvien?.hoten}
                                                                          </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="py-1 fw-bold">Mã NV</td>
                                                                <td className="py-1" colSpan={2}>
                                                                          <span>
                                                                              {dataNhanvien?.uuid}
                                                                          </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="py-1 fw-bold">Chức vụ</td>
                                                                <td className="py-1" colSpan={2}>
                                                                          <span>
                                                                              {dataNhanvien?.Chucvu.chucvu}
                                                                          </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="py-1 fw-bold text-center text-white bg-info" colSpan={3}>Chi
                                                                    tiết phiếu lương <br/>  {is_order !== '0' ? `Phiếu luân chuyển: ${is_order} || Bắt đầu: ${format(parseISO(dateActive), 'dd/MM/yyyy')}` : ''}
                                                                </td>
                                                            
                                                            </tr>
                                                            <tr>
                                                                <td className="py-1 fw-bold text-center">
                                                                </td>
                                                                
                                                                <td className="py-1 fw-bold">TC</td>
                                                                <td className="py-1 fw-bold">TT</td>
                                                            </tr>
                                                            <tr>
                                                                
                                                                <td className="py-1 fw-bold">Mức lương</td>
                                                                <td className="py-1 fw-bold">
                                                                          <span>
                                                                              {formatVND(item?.mucLuong)}
                                                                          </span>
                                                                </td>
                                                                <td className="py-1 fw-bold">
                                                                          <span>
                                                                              {formatVND(item?.mucLuong)}
                                                                          </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="py-1 fw-bold" colSpan={3}>
                                                                    Phụ cấp nhân viên
                                                                </td>
                                                            </tr>
                                                            
                                                            {usingPhucap.length > 0 && usingPhucap.map((av, ak, it) => {
                                                                return (
                                                                    <tr>
                                                                        <td className="py-1 ps-5">
                                                                            {ak + 1}. {av.name}
                                                                        </td>
                                                                        <td className="py-1 fw-bold">
                                                                            {formatVND(av.value)}
                                                                        </td>
                                                                        <td className="py-1 fw-bold">
                                                                            {formatVND(av.value)}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                            <tr>
                                                                
                                                                <td className="py-1 fw-bold" colSpan={2}>Tổng phụ
                                                                    cấp
                                                                </td>
                                                                <td className="py-1 fw-bold">
                                                                          <span>
                                                                              {formatVND(Object.values(arrPhuCap).reduce((a, b) => a + b, 0))}
                                                                          </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="py-1 fw-bold" colSpan="3">Thâm niên
                                                                    nhân
                                                                    viên
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="py-1 ps-5">Thời gian thâm niên</td>
                                                                <td className="py-1">{item?.thoigianThamNien < 0 ? -item?.thoigianThamNien : item?.thoigianThamNien} (Tháng)</td>
                                                                <td className="py-1">{item?.thoigianThamNien < 0 ? -item?.thoigianThamNien : item?.thoigianThamNien} (Tháng)</td>
                                                            </tr>
                                                            <tr>
                                                                
                                                                <td className="py-1 ps-5">Hỗ trợ thâm niên</td>
                                                                <td className="py-1">{formatVND(item?.phuCapThamNien)}</td>
                                                                <td className="py-1">{formatVND(item?.phuCapThamNien)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="py-1 fw-bold" colSpan="3">Thông tin
                                                                    công
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="py-1 ps-5">Công ngày</td>
                                                                <td className="py-1">
                                                                          <span>
                                                                              {item?.congNgay} (Ngày)
                                                                          </span>
                                                                </td>
                                                                <td className="py-1 ps-5">
                                                                          <span>
                                                                              {item?.congNgay} (Ngày)
                                                                          </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="py-1 ps-5">Công đêm</td>
                                                                <td className="py-1">
                                                                          <span>
                                                                              {item?.congDem} (Ngày)
                                                                          </span>
                                                                </td>
                                                                <td className="py-1 ps-5">
                                                                          <span>
                                                                              {item?.congDem} (Ngày)
                                                                          </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="py-1 ps-5">Số ngày công tác(nếu có)
                                                                </td>
                                                                <td className="py-1">
                                                                          <span>
                                                                              {item?.nghiCongtac || 0} (Ngày)
                                                                          </span>
                                                                </td>
                                                                <td className="py-1 ps-5">
                                                                          <span>
                                                                              {item?.nghiCongtac || 0} (Ngày)
                                                                          </span>
                                                                </td>
                                                            </tr>
                                                            
                                                            <tr>
                                                                <td className="py-1 fw-bold" colSpan="3">Thông tin
                                                                    tăng
                                                                    ca
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="py-1 ps-5">Làm thêm ngày thường</td>
                                                                <td className="py-1">
                                                                 <span>
                                                                              {item?.Bangchamcongtonghopitem?.lamThemNgayThuong}
                                                                          </span>
                                                                </td>
                                                                <td className="py-1">
                                                                          <span>
                                                                              {formatVND(item?.lamThemNgayThuong)}
                                                                          </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="py-1 ps-5">Làm thêm chủ nhật</td>
                                                                <td className="py-1">
                                                                    {item?.Bangchamcongtonghopitem?.lamThemChuNhat}
                                                                </td>
                                                                <td className="py-1">
                                                                          <span>
                                                                               {formatVND(item?.lamThemChuNhat)}
                                                                          </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="py-1 ps-5">Làm thêm ngày lễ</td>
                                                                <td className="py-1">
                                                                    {item?.Bangchamcongtonghopitem?.lamThemNgayLe}
                                                                </td>
                                                                <td className="py-1">
                                                                          <span>
                                                                              {formatVND(item?.lamThemNgayLe)}
                                                                          </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="py-1 ps-5">Lương giờ phát sinh</td>
                                                                <td className="py-1">
                                                                
                                                                </td>
                                                                <td className="py-1">
                                                                          <span>
                                                                              {formatVND(item?.lamThemPhatSinh)}
                                                                          </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="py-1 fw-bold" colSpan={2}>
                                                                    Tổng thu nhập làm thêm
                                                                </td>
                                                                <td className="py-1 fw-bold">
                                                                          <span>
                                                                              {formatVND(item?.luongTangCa)}
                                                                          </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="py-1 fw-bold" colSpan="3">Nghỉ lễ &
                                                                    Phép
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="py-1 ps-5">Nghỉ lễ</td>
                                                                <td className="py-1">
                                                                          <span>
                                                                              {item?.totalNgayLe || 0} (Ngày)
                                                                          </span>
                                                                </td>
                                                                <td className="py-1">
                                                                      <span>
                                                                          {formatVND(item?.valueNgayLe)}
                                                                      </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="py-1 ps-5">Nghỉ phép</td>
                                                                <td className="py-1">
                                                                          <span>
                                                                              {item?.nghiPhepChuan || 0} (Ngày)
                                                                          </span>
                                                                </td>
                                                                <td className="py-1">
                                                                      <span>
                                                                          {item?.nghiPhepChuan || 0} (Ngày)
                                                                      </span>
                                                                </td>
                                                            </tr>
                                                            
                                                            <tr>
                                                                <td className="py-1 fw-bold">
                                                                    Khen thưởng
                                                                </td>
                                                                <td className="py-1">
                                                                </td>
                                                                <td className="py-1 fw-bold">
                                                                    <span>{formatVND(item?.valueKhenThuong || 0)}</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="fw-bold">
                                                                    Tổng thu nhập trước thuế, khấu trừ
                                                                </td>
                                                                <td className="py-1">
                                                                    <span></span>
                                                                </td>
                                                                <td className="py-1 fw-bold">
                                                                    <span>{formatVND(Number(item?.luongThucTe))}</span>
                                                                </td>
                                                            </tr>
                                                            {/*<tr>*/}
                                                            {/*    <td className="py-1 fw-bold">*/}
                                                            {/*        Tổng khấu trừ & giảm trừ vi phạm*/}
                                                            {/*    </td>*/}
                                                            {/*    <td className="py-1">*/}
                                                            {/*        <span></span>*/}
                                                            {/*    </td>*/}
                                                            {/*    <td className="py-1 fw-bold">*/}
                                                            {/*        <span>{formatVND(Number(item?.truvipham)+ Number(item?.baohiemXaHoi)+Number(item?.baohiemYTe)+Number(item?.baohiemThatNghiep))}</span>*/}
                                                            {/*    </td>*/}
                                                            {/*</tr>*/}
                                                            <tr>
                                                                <td className="py-1 fw-bold">
                                                                    Khoản khấu trừ
                                                                </td>
                                                                <td className="py-1">
                                                                        <span>
                                                                        </span>
                                                                </td>
                                                                <td className="py-1 fw-bold">
                                                                
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="py-1 ps-5">Bảo hiểm xã hội</td>
                                                                <td className="py-1">
                                                                    <span></span>
                                                                </td>
                                                                <td className="py-1">
                                                                          <span>
                                                                              {formatVND(item?.baohiemXaHoi)}
                                                                          </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="py-1 ps-5">Bảo hiểm y tế</td>
                                                                <td className="py-1">
                                                                    <span></span>
                                                                </td>
                                                                <td className="py-1">
                                                                          <span>
                                                                              {formatVND(item?.baohiemYTe)}
                                                                              
                                                                          </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                
                                                                <td className="py-1 ps-5">Bảo hiểm thất nghiệp</td>
                                                                <td className="py-1">
                                                                    <span></span>
                                                                </td>
                                                                <td className="py-1">
                                                                          <span>
                                                                              {formatVND(item?.baohiemThatNghiep)}
                                                                          </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="py-1 fw-bold" colSpan={2}>
                                                                    Phí công đoàn nếu có
                                                                </td>
                                                                <td className="py-1 fw-bold">
                                                                    {formatVND(item?.phiCongDoan)}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="py-1 fw-bold" colSpan={2}>
                                                                    Giảm trừ vi phạm (Nếu có)
                                                                </td>
                                                                <td className="py-1 fw-bold">
                                                                    {formatVND(item?.truViPham)}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                
                                                                <td className="py-1 fw-bold">Tạm ứng (Nếu có)</td>
                                                                <td className="py-1">
                                                                    <span></span>
                                                                </td>
                                                                <td className="py-1">
                                                                          <span>
                                                                              {formatVND(item?.tamUngLuong)}
                                                                          </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="py-1 fw-bold"> (*) Thuế TNCN (Nếu có)
                                                                </td>
                                                                <td className="py-1">
                                                                    <span></span>
                                                                </td>
                                                                <td className="py-1 fw-bold">
                                                                          <span>
                                                                              {formatVND(item?.thueTNCNphaiKT)}
                                                                          </span>
                                                                </td>
                                                            </tr>
                                                            
                                                            <tr>
                                                                
                                                                <td className="py-1 fw-bold text-decoration-underline">
                                                                    Tổng thu nhập khác(nếu có)
                                                                </td>
                                                                <td className="py-1">
                                                                    <span></span>
                                                                </td>
                                                                <td className="py-1 text-decoration-underline">
                                                                          <span className="fw-bold">
                                                                              {formatVND(item?.thuNhapKhac)}
                                                                          </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                
                                                                <td className="py-1 fw-bold text-decoration-underline">
                                                                    Tổng khấu trừ khác(nếu có)
                                                                </td>
                                                                <td className="py-1">
                                                                    <span></span>
                                                                </td>
                                                                <td className="py-1 text-decoration-underline">
                                                                          <span className="fw-bold">
                                                                              {formatVND(item?.khauTruKhac)}
                                                                          </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                
                                                                <td className="py-1 fw-bold text-decoration-underline">
                                                                    Lương sau khấu trừ (Lương thực Lĩnh)
                                                                </td>
                                                                <td className="py-1">
                                                                    <span></span>
                                                                </td>
                                                                <td className="py-1 text-decoration-underline">
                                                                          <span className="fw-bold">
                                                                              {formatVND(item?.luongthuclinh)}
                                                                          </span>
                                                                </td>
                                                            </tr>
                                                            
                                                            <tr>
                                                                <td colSpan={4}></td>
                                                            </tr>
                                                            <tr>
                                                                <td rowSpan={2}><i>Người Lập bảng:</i></td>
                                                                <td colSpan={2}><i>Người Nhận:</i><br/>
                                                                    {dataNhanvien.hoten}
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </Table>
                                                    );
                                                })
                                                : null}
                                        </div>
                                    </div>
                                    <div className={`tab-pane ${modalTab === '9' ? 'active' : ''}`} id="phuongtien">
                                        <div className="row">
                                            <div className="card-inner position-relative card-tools-toggle p-0">
                                                <div className="row">
                                                    <div className="form-group col-md-4">
                                                        <InputForm
                                                            setInvalidFields={setInvalidFields}
                                                            invalidFields={invalidFields}
                                                            label={'Ngày bắt đầu'}
                                                            value={payloadPhieuLuong.ngaybatdau}
                                                            setValue={setPayloadPhieuLuong}
                                                            keyPayload={'ngaybatdau'}
                                                            type="date"
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-4">
                                                        <InputForm
                                                            setInvalidFields={setInvalidFields}
                                                            invalidFields={invalidFields}
                                                            label={'Ngày kết thúc'}
                                                            value={payloadPhieuLuong.ngayketthuc}
                                                            setValue={setPayloadPhieuLuong}
                                                            keyPayload={'ngayketthuc'}
                                                            type="date"
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-2">
                                                        <p className="my-1 fw-bold">Tra cứu theo thời gian</p>
                                                        <button
                                                            className="btn btn-info py-1"
                                                            onClick={handleSubmitPhuongTien}
                                                        >
                                                            <em className="icon ni ni-search"></em>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <DataTable className="card-stretch">
                                                <DataTableBody>
                                                    <DataTableHead>
                                                        <DataTableRow>
                                                            <span className="sub-text">STT</span>
                                                        </DataTableRow>
                                                        <DataTableRow>
                                                            <span className="sub-text">Phương tiện</span>
                                                        </DataTableRow>
                                                        <DataTableRow size="mb">
                                                            <span className="sub-text">Điểm đầu</span>
                                                        </DataTableRow>
                                                        <DataTableRow size="mb">
                                                            <span className="sub-text">Điểm cuối</span>
                                                        </DataTableRow>
                                                        <DataTableRow size="mb">
                                                            <span className="sub-text">Ngày đi</span>
                                                        </DataTableRow>
                                                        <DataTableRow size="mb">
                                                            <span className="sub-text">Ngày về</span>
                                                        </DataTableRow>
                                                        <DataTableRow size="mb">
                                                            <span className="sub-text">Khách hàng</span>
                                                        </DataTableRow>
                                                        <DataTableRow size="mb">
                                                            <span className="sub-text">Chi phí</span>
                                                        </DataTableRow>
                                                        <DataTableRow size="mb">
                                                            <span className="sub-text">Phiếu Kế hoạch(**)</span>
                                                        </DataTableRow>
                                                        <DataTableRow size="mb">
                                                            <span className="sub-text">Trạng thái</span>
                                                        </DataTableRow>
                                                        <DataTableRow className="nk-tb-col-tools text-end">
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle
                                                                    color="tranparent"
                                                                    className="btn btn-xs btn-outline-light btn-icon dropdown-toggle"
                                                                >
                                                                    <Icon name="plus"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu end className="dropdown-menu-xs">
                                                                    <ul className="link-tidy sm no-bdr">
                                                                        <li>
                                                                            <div
                                                                                className="custom-control custom-control-sm custom-checkbox">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="custom-control-input"
                                                                                    id="st"
                                                                                />
                                                                                <label
                                                                                    className="custom-control-label"
                                                                                    htmlFor="st"
                                                                                >
                                                                                    Status
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </DataTableRow>
                                                    </DataTableHead>
                                                    {dataPhuongTien?.length > 0
                                                        ? dataPhuongTien.map((item, index) => {
                                                            return (
                                                                <DataTableItem key={index}>
                                                                    <DataTableRow size="mb">
                                                                        <span className="tb-amount">{index + 1}</span>
                                                                    </DataTableRow>
                                                                    
                                                                    <DataTableRow size="mb">
                                                                          <span className="tb-amount">
                                                                              {item?.Phuongtien?.biensoxe} (
                                                                              {
                                                                                  item?.Phuongtien?.Loaiphuongtien
                                                                                      ?.tenloaiphuongtien
                                                                              }
                                                                              )
                                                                          </span>
                                                                    </DataTableRow>
                                                                    
                                                                    <DataTableRow size="mb">
                                                                          <span className="tb-amount">
                                                                              {item?.xuatphattai}
                                                                          </span>
                                                                    </DataTableRow>
                                                                    <DataTableRow size="mb">
                                                                          <span className="tb-amount">
                                                                              {item?.diemden}
                                                                          </span>
                                                                    </DataTableRow>
                                                                    <DataTableRow size="mb">
                                                                          <span className="tb-amount">
                                                                              {item?.ngaybatdau}
                                                                              <i>{item?.giobatdau}</i>
                                                                          </span>
                                                                    </DataTableRow>
                                                                    <DataTableRow size="mb">
                                                                          <span className="tb-amount">
                                                                              {item?.ngayketthuc}
                                                                              <i>{item?.gioketthuc}</i>
                                                                          </span>
                                                                    </DataTableRow>
                                                                    <DataTableRow size="mb">
                                                                          <span className="tb-amount">
                                                                              {item?.tenkhachhang}
                                                                          </span>
                                                                    </DataTableRow>
                                                                    <DataTableRow size="mb">
                                                                          <span className="tb-amount">
                                                                              {item?.chiphi}
                                                                          </span>
                                                                    </DataTableRow>
                                                                    <DataTableRow size="mb">
                                                                          <span
                                                                              className={
                                                                                  'text-white ms-0 px-3 py-1 badge justify-content-center px-0 btn btn-primary'
                                                                              }
                                                                          >
                                                                              In
                                                                          </span>
                                                                    </DataTableRow>
                                                                    <DataTableRow size="mb">
                                                                          <span
                                                                              className={`${
                                                                                  item?.TrangthaiId === 1
                                                                                      ? 'bg-success'
                                                                                      : 'bg-danger'
                                                                              } ms-0 badge text-white`}
                                                                          >
                                                                              {item?.TrangthaiId === 1
                                                                                  ? 'Hoạt động'
                                                                                  : 'Không Hoạt động'}
                                                                          </span>
                                                                    </DataTableRow>
                                                                </DataTableItem>
                                                            );
                                                        })
                                                        : null}
                                                </DataTableBody>
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* </div> */}
                    </Card>
                </Content>
                <ModalAddTaisan idNhanvien={dataNhanvien?.id} show={show} setShow={() => setShow(false)}/>
                <ModalAddTaisan idNhanvien={dataNhanvien?.id} show={show} setShow={() => setShow(false)}/>
                <ModalAddTangca
                    idNhanvien={dataNhanvien?.id}
                    show={showTangCa}
                    // dataTangca={dataTangca}
                    uuid={searchParams.get('NhanvienId')}
                    setShow={() => setShowTangCa(false)}
                />
                <ModalEditTangca
                    idNhanvien={dataNhanvien?.id}
                    show={showEditTangCa}
                    dataTangca={dataTangca}
                    uuid={searchParams.get('NhanvienId')}
                    setShow={() => setShowEditTangCa(false)}
                />
                <ModalAddPhuCap
                    idNhanvien={dataNhanvien?.id}
                    uuid={searchParams.get('NhanvienId')}
                    dataPhuCapNV={dataPhuCapNV}
                    show={showPhuCap}
                    setShow={() => setShowPhuCap(false)}
                    totalPhucap={dataNhanvien?.Phucapnhanviens}
                />
                <ModalMcc
                    idNhanvien={dataNhanvien?.id}
                    uuid={searchParams.get('NhanvienId')}
                    dataMcc={dataMcc}
                    show={showMcc}
                    setShow={() => setShowMcc(false)}
                    totalMcc={dataNhanvien?.Thongtinchamcongs}
                />
                <ModalAddBank idNhanvien={dataNhanvien?.id} show={showBank} setShow={() => setShowBank(false)}/>
                <ModalEditBank dataBank={dataBank} show={showBankEdit} setShow={() => setShowBankEdit(false)}/>
                
                <ModalAddNPT idNhanvien={dataNhanvien?.id} show={showNPT} setShow={() => setShowNPT(false)}/>
                <ModalEditNPT dataNPT={dataNPT} show={showEditNPT} setShow={() => setShowEditNPT(false)}/>
                
                <Modal
                    isOpen={modalAvatar}
                    toggle={() => setmodalAvatar(false)}
                    className="modal-dialog-centered"
                    size="md"
                >
                    <ModalBody>
                        <a href="#cancel" onClick={() => setmodalAvatar(false)} className="close">
                            <Icon name="cross-sm"></Icon>
                        </a>
                        <div className="p-2">
                            <h5 className="title">Cập nhật ảnh đại diện</h5>
                            <div className="mt-4">
                                <AvatarCrop
                                    setmodalAvatar={setmodalAvatar}
                                    dataNhanvien={dataNhanvien}
                                    setDataNhanvien={setDataNhanvien}
                                />
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
;

export default ChiTietNhanVien;
