import actionTypes from "../actions/actionTypes";
const initState = {
    bangchamcong: [],
    msg: '',
    count: 0,
    newbangchamcong: []
}

const bangchamcongReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.GET_LIST_BANG_CHAM_CONG:
            return {
                ...state,
                bangchamcong: action?.bangchamcong?.rows || [],
                msg: 'Ok' || '',
                count: action?.bangchamcong?.count || 0
            }
        case actionTypes.POST_NEW_BANG_CHAM_CONG:
        case actionTypes.PUT_EDIT_BANG_CHAM_CONG:
        case actionTypes.DELETE_BANG_CHAM_CONG:
            return {
                ...state,
                msg: action.msg || '',
                newbangchamcong: action?.newbangchamcong || []
            }
        default:
            return state;
    }

}

export default bangchamcongReducer