import actionTypes from './actionTypes'
import { apiGetXeploainhanvien, apiNewXeploainhanvien, apiEditXeploainhanvien, apiDeleteXeploainhanvien } from '~/services/xeploainhanvien'
import { toast } from "react-toastify";
export const getListXeploainhanvien = () => async (dispatch) => {
    try {
        const response = await apiGetXeploainhanvien()
        // console.log('chek api lisst Xeploainhanvien', response?.data.success)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_XEP_LOAI_NHAN_VIEN,
                xeploainhanvien: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_XEP_LOAI_NHAN_VIEN,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_XEP_LOAI_NHAN_VIEN,
            xeploainhanvien: null
        })
    }
}

export const NewXeploainhanvien = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewXeploainhanvien', payload)
            const response = await apiNewXeploainhanvien(payload)
            // console.log('chek api apiNewXeploainhanvien', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_XEP_LOAI_NHAN_VIEN,
                    msg: 'OK'
                });
                dispatch(getListXeploainhanvien());
                toast.success("Thêm mới xếp loại nhân viên thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_XEP_LOAI_NHAN_VIEN,
                    msg: 'ERR'
                })
                toast.error("Thêm mới xếp loại nhân viên thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_XEP_LOAI_NHAN_VIEN,
                xeploainhanvien: null
            })
        }
    }
}
export const EditXeploainhanvien = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditXeploainhanvien(payload)
            // console.log('chek api apiEditXeploainhanvien', response)
            if (response?.data.success === 200) {
                dispatch(getListXeploainhanvien());
                toast.success("Sửa thông tin xếp loại nhân viên thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_XEP_LOAI_NHAN_VIEN,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_XEP_LOAI_NHAN_VIEN,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin xếp loại nhân viên thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_XEP_LOAI_NHAN_VIEN,
                xeploainhanvien: null
            })
        }
    }
}
export const DeleteXeploainhanvien = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteXeploainhanvien(payload)
            // console.log('chek api apiDeleteXeploainhanvien', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListXeploainhanvien());
                toast.success("Xóa thông tin xếp loại nhân viên thành công!")
                dispatch({
                    type: actionTypes.DELETE_XEP_LOAI_NHAN_VIEN,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_XEP_LOAI_NHAN_VIEN,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin xếp loại nhân viên thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_XEP_LOAI_NHAN_VIEN,
                xeploainhanvien: null
            })
        }
    }
}