import React, {useEffect, useMemo, useState} from 'react';
import {
    apiEditBangchotcong,
    apiGetBangchotcong,
    apiListBangChamCong,
    apiListChamCong,
    apiGetBangcongHistory, apiSendMail,
} from '~/services';
import {useDispatch, useSelector} from 'react-redux';
import Head from '~/layout/head/Head';
import Content from '~/layout/content/Content';
import {Block} from '~/components/block/Block';
import {
    Button,
    Col,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    Icon,
    PaginationComponent,
    Row,
    RSelect,
    TooltipComponent,
    PermissionUtils,
    ExportXlsx,
} from '~/components/Component';

import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalBody,
    UncontrolledDropdown,
    Spinner,
    Card,
    Alert,
    Tooltip,
} from 'reactstrap';
import {addDays, format, parseISO} from 'date-fns';
import * as actions from '~/store/actions';

import {toast} from 'react-toastify';
import {MaterialReactTable} from 'material-react-table';
import {Link, json, useSearchParams} from 'react-router-dom';
import {Box} from '@mui/material';
import {ExportToCsv} from 'export-to-csv';
import {ModalEditCong, ModalLog, ModalHistory} from '~/pages/Admin/Modal';
import config from '~/config';
import {MRT_Localization_VI} from 'material-react-table/locales/vi';
import Dropzone from 'react-dropzone';
import * as XLSX from 'xlsx';
import {getListBangchotcong} from '~/store/actions';

const Chamcong = () => {
    const dispatch = useDispatch();
    
    const [data, setData] = useState([]);
    const [dataAll, setDataAll] = useState([]);
    const [dataBangcong, setDataBangcong] = useState([]);
    const [dateInMonth, setDateInMonth] = useState('');
    const [smonth, setMonth] = useState('');
    const [syear, setYear] = useState('');
    const [searchParams] = useSearchParams();
    useEffect(() => {
        const fetchDataAB = async () => {
            try {
                if (searchParams.get('bangcong')) {
                    const finbangchotcongId = await apiGetBangchotcong({bangcongId: searchParams.get('bangcong')});
                    if (finbangchotcongId?.data?.success === 200) {
                        setDataBangcong(finbangchotcongId?.data?.data?.rows[0]);
                        fetchData(finbangchotcongId?.data?.data?.rows[0])
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        
        fetchDataAB().catch(console.error);
    }, [searchParams, setDataBangcong]);
// console.log('dataBangcong', dataBangcong)
// console.log('data', data)
    useEffect(() => {
        if (dataBangcong?.TrangthaiId === 0) {
            handleGetChamCongSubmit();
        }
    }, [dataBangcong]);
    const [daysInMonth, setDaysInMonth] = useState([]);
    const vietnameseDaysOfWeek = ['Chủ Nhật', 'Thứ Hai', 'Thứ Ba', 'Thứ Tư', 'Thứ Năm', 'Thứ Sáu', 'Thứ Bảy'];
    const [totalData, setTotalData] = useState({tongcong: 0, dimuonvesom: 0, congtac: 0, xinnghi: 0, unlock: []});
    
    async function fetchData(payload) {
        // console.log('payloadvvvvvvvvvvvvvvvvvvvvv', payload)
        const firstDayOfMonth = new Date(payload?.ngaybatdau);
        const lastDay = new Date(payload?.ngayketthuc);
        
        const daysOfMonth = [];
        for (let day = firstDayOfMonth; day <= lastDay; day.setDate(day.getDate() + 1)) {
            let days = new Date(day);
            const dayString = days.getDate().toString().padStart(2, '0');
            const monthString = (days.getMonth() + 1).toString().padStart(2, '0');
            const yearString = days.getFullYear();
            const formattedDate = `${dayString}-${monthString}-${yearString}`;
            
            daysOfMonth.push({
                ngay: formattedDate,
                thu: vietnameseDaysOfWeek[days.getDay()],
            });
        }
        setDaysInMonth(daysOfMonth);
        
        let datapayload = {
            BangchotcongId: Number(payload?.id),
        };
        try {
            const Chamcong = await apiListBangChamCong(datapayload);
            const response = Chamcong?.data?.data?.rows;
            // console.log('response', response);
            let tongcong = 0;
            let dimuonvesom = 0;
            let congtac = 0;
            let xinnghi = 0;
            // let unlock = 0;
            let ghichu = [];
            const daBangchamcong = response.map((item) => {
                tongcong += item?.cong;
                dimuonvesom += item?.dimuonvesom;
                congtac += item?.dicongtac;
                xinnghi += item?.nghikhongluong + item?.nghihuongluong;
                // xinnghi += item?.nghikhongluong + item?.nghihuongluong;
                // unlock += item?.TrangthaiId;
                ghichu = ghichu.concat(item.ghichu ? JSON.parse(item.ghichu) : []);
                return {
                    hoten: item?.Nhanvien?.hoten,
                    uuid: item?.Nhanvien?.uuid,
                    NhanvienId: item?.NhanvienId,
                    BangchotcongId: item?.BangchotcongId,
                    dateCong: item.datangay ? JSON.parse(item.datangay) : [],
                };
            });
            setTotalData({
                tongcong: tongcong,
                dimuonvesom: dimuonvesom,
                congtac: congtac,
                xinnghi: xinnghi,
                unlock: ghichu
            });
            setData(daBangchamcong);
            setDataAll(daBangchamcong);
            
            // console.log('response', response);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    
    // console.log('totalData', totalData);
    const [isLoading, setIsLoading] = useState(false)
    const handleGetChamCongSubmit = async () => {
        if (dataBangcong?.TrangthaiId === 0) {
            try {
                const datapayload = {
                    ngaybatdau: dataBangcong?.ngaybatdau
                        ? format(parseISO(dataBangcong?.ngaybatdau), 'yyyy-MM-dd')
                        : null,
                    ngayketthuc: dataBangcong?.ngayketthuc
                        ? format(parseISO(dataBangcong?.ngayketthuc), 'yyyy-MM-dd')
                        : null,
                    BangchotcongId: Number(dataBangcong?.id),
                };
                // console.log('datapayload', datapayload);
                const res = await apiListChamCong(datapayload);
                console.log('res', res);
                setIsLoading(false);
                toast.success('Lấy thông tin chấm công thành công');
                fetchData(dataBangcong);
            } catch (error) {
                // console.error("Error fetching cham cong:", error);
                toast.error('Đã có lỗi xảy ra khi lấy thông tin chấm công');
            }
        } else {
            // console.error("Bảng chốt công không tồn tại");
            toast.error('Đã có lỗi xảy ra khi lấy thông tin chấm công');
            
        }
    };
    // console.log('datapayload', format(addDays(parseISO(new Date()), 3), 'dd-MM-yyyy'));
    const handleGetSendMailCong = async () => {
        if (dataBangcong?.TrangthaiId === 0) {
            toast.error('Vui lòng khóa bảng chấm công trước khi gửi mail!');
        } else {
            try {
                const datapayload = {
                    ngaybatdau: dataBangcong?.ngaybatdau
                        ? format(parseISO(dataBangcong?.ngaybatdau), 'MM-yyyy')
                        : null,
                    ngayGuiMail: format(new Date(), 'dd-MM-yyyy'),
                    ngayGuiMailFedback: format(addDays(new Date(), 3), 'dd-MM-yyyy'),
                    BangchotcongId: Number(dataBangcong?.id),
                }
                const result = await apiSendMail(datapayload);
                if (result?.data?.success === 200) {
                    toast.success(result?.data?.message);
                } else {
                    toast.error(result?.data?.message);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
            
            // try {
            
       
    };
// console.log('data', data);
    const toggle = () => setonSearch(!onSearch);
    const [sm, updateSm] = useState(false);
    const [tablesm, updateTableSm] = useState(false);
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState('');
    const onFilterChange = (e) => {
        setSearchText(e.target.value);
    };
    useEffect(() => {
        
        if (onSearchText !== '' && dataAll?.length > 0) {
            const filteredObject = dataAll?.filter((item) => {
                return (
                    item?.hoten.toLowerCase().includes(onSearchText.toLowerCase()) ||
                    item?.uuid.toLowerCase().includes(onSearchText.toLowerCase())

                );
            });

            setData([...filteredObject]);
        } else {
            setData([...dataAll]);
        }
    }, [onSearchText, setData]);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [sort, setSortState] = useState('');
    const [dataFileMau, setDataFileMau] = useState([]);
    const namefile = 'Thông tin phân ca';
    const header = ['Stt', 'Phòng ban', 'Mô tả'];
    const [columns, setColumns] = useState(() => {
        const d = header.map((item, index) => index <= 6);
        return d;
    });
    
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const itemsPerPageOptions = [10, 20, 30, 40, 50, 100, 300, 500];
    
    const [dataId, setDataId] = useState([]);
    const [show, setShow] = useState(false);
    const [showLog, setShowLog] = useState(false);
    const handleItemEdit = async (data, NhanvienId) => {
        setShow(true);
        const alldata = {data, NhanvienId, dataBangchotcong: dataBangcong};
        setDataId(alldata);
    };
    const handleLock = async (data) => {
        if (totalData?.unlock?.length === 0) {
            try {
                let datapayload = {
                    id: data.id,
                    TrangthaiId: data.TrangthaiId === 1 ? 0 : 1,
                };
                const response = await apiEditBangchotcong(datapayload);
                if (response.data.success === 200) {
                    toast.success(data.TrangthaiId === 1 ? 'Mở khóa thành công!!' : 'Khóa thành công!!');
                    const finbangchotcongId = await apiGetBangchotcong({bangcongId: searchParams.get('bangcong')});
                    if (finbangchotcongId?.data?.success === 200) {
                        setDataBangcong(finbangchotcongId?.data?.data?.rows[0]);
                        // console.log('aaaaaaaaaaaaaa', finbangchotcongId?.data?.data?.rows[0])
                    }
                } else {
                    toast.error('Đã có lỗi xảy ra vui lòng thử lại!!');
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            // toast.error('Vui lòng khắc phục các cảnh báo màu đỏ!');
            setShowLog(true);
        }
    };
    const [dataHistory, setDataHistory] = useState([]);
// console.log('dataHistory', dataHistory)
    const [showHistory, setShowHistory] = useState(false);
    const HandHistory = async () => {
        try {
            let datapayload = {
                BangchotcongId: Number(dataBangcong?.id),
            };
            const response = await apiGetBangcongHistory(datapayload);
            // console.log('responsebbbbbbbbbb', response)
            if (response.data.success === 200) {
                setDataHistory(response?.data?.data);
                setShowHistory(true);
            }
        } catch (error) {
            console.error(error);
        }
    }
    const [isClic, setIsClic] = useState(2);
    const [hover, setHoveredColumn] = useState(null);
    const [pinning, setPinning] = useState([true]);
    const handleHover = (columnIndex) => {
        setHoveredColumn(columnIndex);
    };
    
    useEffect(() => {
        const d = header.map((item, index) => index <= isClic);
        setPinning(d);
    }, [isClic]);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const tooltip = () => setTooltipOpen(!tooltipOpen);
    const [tooltipOpen1, setTooltipOpen1] = useState(false);
    const tooltip1 = () => setTooltipOpen1(!tooltipOpen1);
    const [tooltipOpen2, setTooltipOpen2] = useState(false);
    const tooltip2 = () => setTooltipOpen2(!tooltipOpen2);
    return (
        <React.Fragment>
            <Head title="Doanh nghiệp, Chi nhánh, Phòng ban, Chức Vụ"></Head>
            <Content>
                <Card className="mt-0">
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner card-inner-lg px-4 mt-3">
                            <h4>
                                {dataBangcong?.tieude} từ ngày{' '}
                                {dataBangcong?.ngaybatdau
                                    ? format(parseISO(dataBangcong?.ngaybatdau), 'dd-MM-yyyy')
                                    : ''}{' '}
                                đến ngày{' '}
                                {dataBangcong?.ngayketthuc
                                    ? format(parseISO(dataBangcong?.ngayketthuc), 'dd-MM-yyyy')
                                    : ''}
                            </h4>
                            <i className={dataBangcong?.TrangthaiId === 1 ? 'text-danger' : 'text-info'}>
                                {dataBangcong?.TrangthaiId === 1
                                    ? '(*) Bảng chấm công đã khóa, muốn chỉnh sửa vui lòng mở khóa.'
                                    : '(*) Bảng chấm công chưa khóa, bạn có thể chỉnh sửa công.'}
                            </i>
                        </div>
                    </DataTable>
                </Card>
                <Block>
                    <DataTable className="card-stretch border-0">
                        <div className=" position-relative card-tools-toggle pt-0 px-3">
                            <div className="card-title-group">
                                <div className="card-title">
                                    <Button color="danger" variant="contained" className="mx-1">
                                        <Link className={`nav-link`} to={`${config.he_thong}${config.bang_chot_cong}`}>
                                            <span className="fs-10px me-2">
                                                <Icon className="ni ni-back-arrow-fill"></Icon>
                                            </span>
                                            Quay lại
                                        </Link>
                                    </Button>
                                    {dataBangcong?.TrangthaiId === 1 ? (
                                        <Button color="gray" variant="contained">
                                            <Icon name="reload-alt me-2"></Icon> Cập nhật
                                        </Button>
                                    ) : (
                                        isLoading === true ? (
                                            <Button disabled color="primary">
                                                <Spinner size="sm"/>
                                                <span> Cập nhật </span>
                                            </Button>
                                        ) : (
                                            <Button color="primary" onClick={(ev) => {ev.preventDefault();handleGetChamCongSubmit();setIsLoading(true);}}  variant="contained">
                                                <Icon name="update me-2"></Icon> Cập nhật
                                            </Button>
                                        )
                                       
                                    )}
                                    <Button color="info" variant="contained" onClick={handleGetSendMailCong}
                                            className="mx-1" id="guimail">
                                        <Icon name="emails-fill me-2"></Icon> Gửi Email
                                    </Button>
                                    <Tooltip
                                        isOpen={tooltipOpen}
                                        target="guimail"
                                        toggle={tooltip}
                                    >
                                        Gửi Email thông báo công cho từng nhân viên!
                                    </Tooltip>
                                    <Button
                                        color={dataBangcong?.TrangthaiId === 1 ? 'secondary' : 'warning'}
                                        onClick={() => handleLock(dataBangcong)}
                                        variant="contained"
                                        className="mx-1"
                                    >
                                        <Icon
                                            name={dataBangcong?.TrangthaiId === 1 ? 'lock' : 'unlock'}
                                            className="fs-26px"
                                        ></Icon>
                                    </Button>
                                </div>
                                <div className="card-tools">
                                    <ul className="btn-toolbar gx-1">
                                        <li>
                                            <a
                                                href="#search"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    toggle();
                                                }}
                                                className="btn btn-icon search-toggle toggle-search"
                                            >
                                                <Icon name="search"></Icon>
                                            </a>
                                        </li>
                                        <li className="btn-toolbar-sep"></li>
                                        <li>
                                            <div className="toggle-wrap">
                                                <Button
                                                    className={`btn-icon btn-trigger toggle ${tablesm ? 'active' : ''}`}
                                                    onClick={() => updateTableSm(true)}
                                                >
                                                    <Icon name="menu-right"></Icon>
                                                </Button>
                                                <div className={`toggle-content ${tablesm ? 'content-active' : ''}`}>
                                                    <ul className="btn-toolbar gx-1">
                                                        <li className="toggle-close">
                                                            <Button
                                                                className="btn-icon btn-trigger toggle"
                                                                onClick={() => updateTableSm(false)}
                                                            >
                                                                <Icon name="arrow-left"></Icon>
                                                            </Button>
                                                        </li>
                                                        
                                                        <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle
                                                                    color="tranparent"
                                                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                                                >
                                                                    <Icon name="setting"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu end className="dropdown-menu-xs">
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Show</span>
                                                                        </li>
                                                                        {itemsPerPageOptions.map((item, index) => {
                                                                            return (
                                                                                <li
                                                                                    className={
                                                                                        itemPerPage === item
                                                                                            ? 'active'
                                                                                            : ''
                                                                                    }
                                                                                    key={index}
                                                                                >
                                                                                    <DropdownItem
                                                                                        tag="a"
                                                                                        href="#dropdownitem"
                                                                                        onClick={(ev) => {
                                                                                            ev.preventDefault();
                                                                                            setItemPerPage(item);
                                                                                            // handleReParamsClick();
                                                                                        }}
                                                                                    >
                                                                                        {item}
                                                                                    </DropdownItem>
                                                                                </li>
                                                                            );
                                                                        })}
                                                                    </ul>
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Order</span>
                                                                        </li>
                                                                        <li className={sort === 'dsc' ? 'active' : ''}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setSortState('dsc');
                                                                                    //   sortFunc("dsc");
                                                                                }}
                                                                            >
                                                                                DESC
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li className={sort === 'asc' ? 'active' : ''}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setSortState('asc');
                                                                                    //   sortFunc("asc");
                                                                                }}
                                                                            >
                                                                                ASC
                                                                            </DropdownItem>
                                                                        </li>
                                                                    </ul>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="toggle-wrap nk-block-tools-toggle">
                                                <Button
                                                    className={`btn-icon btn-trigger toggle-expand me-n1 ${
                                                        sm ? 'active' : ''
                                                    }`}
                                                    onClick={() => updateSm(!sm)}
                                                >
                                                    <Icon name="upload-cloud"></Icon>
                                                </Button>
                                                <div
                                                    className="toggle-expand-content-cloud"
                                                    style={{display: sm ? 'block' : 'none'}}
                                                >
                                                    <ul className="nk-block-tools g-3">
                                                        <li className="nk-block-tools-opt">
                                                            <ExportXlsx
                                                                dataFileMau={dataFileMau}
                                                                title={header}
                                                                namefile={namefile}
                                                                btnname={'Xuất dữ liệu'}
                                                            />
                                                        </li>
                                                        <li className="nk-block-tools-opt">
                                                            <Button color="gray" variant="contained"
                                                                    onClick={() => HandHistory()}>
                                                                Lịch sử chỉnh sửa
                                                            </Button>
                                                        </li>
                                                    
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className={`card-search search-wrap ${!onSearch && 'active'}`}>
                                <div className="card-body">
                                    <div className="search-content">
                                        <Button
                                            className="search-back btn-icon toggle-search active"
                                            onClick={() => {
                                                setSearchText('');
                                                toggle();
                                            }}
                                        >
                                            <Icon name="arrow-left"></Icon>
                                        </Button>
                                        <input
                                            type="text"
                                            className="border-transparent form-focus-none form-control"
                                            placeholder="Tìm kiếm"
                                            value={onSearchText}
                                            onChange={(e) => onFilterChange(e)}
                                        />
                                        <Button className="search-submit btn-icon">
                                            <Icon name="search"></Icon>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div className="row mb-3 px-4">
                                <div className="col-lg-3">
                                    <div className="example-alert">
                                        <Alert className="alert-pro" color="success">
                                            <div className="alert-text d-flex justify-content-between">
                                                <div className="alert-title">
                                                    <h6>Tổng công</h6>
                                                </div>
                                                <div className="alert-total">
                                                    <span>{totalData.tongcong.toFixed(2)}</span>
                                                </div>
                                            </div>
                                        </Alert>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="example-alert">
                                        <Alert className="alert-pro" color="warning">
                                            <div className="alert-text d-flex justify-content-between">
                                                <div className="alert-title">
                                                    <h6>Đi muộn về sớm</h6>
                                                </div>
                                                <div className="alert-total">
                                                    <span>{totalData.dimuonvesom}</span>
                                                </div>
                                            </div>
                                        </Alert>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="example-alert">
                                        <Alert className="alert-pro" color="info">
                                            <div className="alert-text d-flex justify-content-between">
                                                <div className="alert-title">
                                                    <h6>Công tác</h6>
                                                </div>
                                                <div className="alert-total">
                                                    <span>{totalData.congtac}</span>
                                                </div>
                                            </div>
                                        </Alert>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="example-alert">
                                        <Alert className="alert-pro" color="danger">
                                            <div className="alert-text">
                                                <div className="alert-text d-flex justify-content-between">
                                                    <div className="alert-title">
                                                        <h6>Xin nghỉ</h6>
                                                    </div>
                                                    <div className="alert-total">
                                                        <span>{totalData.xinnghi}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Alert>
                                    </div>
                                </div>
                            </div>
                            <hr />
                        {/* <div className="overflow-scroll"> */}
                        <div style={{overflowY: 'hidden'}}>
                            <DataTableBody>
                                <DataTableHead className="col-12 ">
                                    <DataTableRow className=" fixed-column">
                                        <span className="sub-text w-50px">Stt</span>
                                    </DataTableRow>
                                    <DataTableRow className={`${pinning[1] ? 'fixed-column1' : ''}`}>
                                        <span className="sub-text w-150px"
                                              onMouseEnter={() => handleHover(1)}
                                              onMouseLeave={() => handleHover(null)}
                                        >
                                            Họ tên{' '}
                                            <Icon
                                                name={`award w-15px ${
                                                    isClic === 1
                                                        ? 'icon-clic'
                                                        : hover === 1
                                                            ? 'icon-visible'
                                                            : 'icon-hidden'
                                                }`}
                                                onClick={() => setIsClic(1)}
                                            ></Icon>
                                        </span>
                                    </DataTableRow>
                                    <DataTableRow className={`${pinning[2] ? 'fixed-column2' : ''}`}>
                                        <span className="sub-text w-100px"
                                              onMouseEnter={() => handleHover(2)}
                                              onMouseLeave={() => handleHover(null)}
                                        >
                                            Mã nhân viên{' '}
                                            <Icon
                                                name={`award w-15px ${
                                                    isClic === 2
                                                        ? 'icon-clic'
                                                        : hover === 2
                                                            ? 'icon-visible'
                                                            : 'icon-hidden'
                                                }`}
                                                onClick={() => setIsClic(2)}
                                            ></Icon>
                                        </span>
                                    </DataTableRow>
                                    
                                    {daysInMonth.map((it) => (
                                        <DataTableRow size=" text-center" key={it.ngay}>
                                            <span className="sub-text w-150px">{it.thu}</span>
                                            <span className="sub-text w-150px">{it.ngay}</span>
                                        </DataTableRow>
                                    ))}
                                </DataTableHead>
                                {currentItems.length > 0
                                    ? currentItems.map((item, index) => {
                                        const displayedIndex =  itemPerPage * (currentPage - 1) + index + 1;
                                        return (
                                            <DataTableItem key={index}>
                                                <DataTableRow className="w-50px fixed-column"
                                                              size=" nk-tb-col-check mb">
                                                    <span className="tb-amount">{displayedIndex}</span>
                                                </DataTableRow>
                                                <DataTableRow className={`${pinning[1] ? 'fixed-column1' : ''}`}>
                                                    <span className="tb-amount">{item?.hoten}</span>
                                                </DataTableRow>
                                                <DataTableRow className={`${pinning[2] ? 'fixed-column2' : ''}`}>
                                                    <span className="tb-amount">{item?.uuid}</span>
                                                </DataTableRow>
                                                {item.dateCong.length > 0 &&
                                                    item.dateCong.map((iv) => (
                                                        <DataTableRow className="w-100px" key={`aa-${iv?.ngay}`}>
                                                            <div onClick={() => handleItemEdit(iv, item?.NhanvienId)}>
                                                                {iv?.mes === '' && iv?.dataCalamviec?.length > 0 &&
                                                                    iv?.dataCalamviec.map((c, index) => (
                                                                        <div
                                                                            className="text-center tb-amount"
                                                                            key={c}
                                                                            // onClick={() => handleItemEdit(iv, item?.NhanvienId)}
                                                                        >
                                                                            {c?.xindimuonvesom?.length > 0 ? (
                                                                                <div className="text-warning">
                                                                                    <div className="text-warning">Có đơn
                                                                                        xin<br></br> đi
                                                                                        muộn về sớm
                                                                                    </div>
                                                                                    {/* {c?.xindimuonvesom.map((i) => (
                                                                                        <span className="text-warning" key={i?.someUniqueKey}>
                                                                                            {i?.dimuon}
                                                                                            {''}
                                                                                            {i?.vesom}
                                                                                        </span>
                                                                                    ))} */}
                                                                                </div>
                                                                            ) : (
                                                                                (c?.dimuon !== 0 || c?.vesom !== 0) && (
                                                                                    <>
                                                                                        <Icon
                                                                                            className="text-warning fs-20px"
                                                                                            name="alert-fill"
                                                                                            id={`donxin${index}`}/>
                                                                                        <div
                                                                                            className="text-warning">Không
                                                                                            có đơn xin đi muộn về sớm
                                                                                        </div>
                                                                                        <Tooltip
                                                                                            isOpen={tooltipOpen1}
                                                                                            target={`donxin${index}`}
                                                                                            toggle={tooltip1}
                                                                                        >
                                                                                            Không có đơn xin đi muộn về
                                                                                            sớm.
                                                                                            Hệ thống mặc định vẫn ghi
                                                                                            nhận
                                                                                            công! <br></br>Click vào,
                                                                                            nếu
                                                                                            muốn thay đổi.
                                                                                        </Tooltip>
                                                                                    </>
                                                                                )
                                                                            )}
                                                                            <div className="fs-12px">
                                                                                {c?.macalamviec}
                                                                            </div>
                                                                            
                                                                            <span
                                                                                className={
                                                                                    c?.dimuon !== 0 || c?.vesom !== 0
                                                                                        ? 'fs-12px text-warning'
                                                                                        : 'fs-12px'
                                                                                }
                                                                            >
                                                                                {c?.vao ? c?.vao : '--:--'} -{' '}
                                                                                {c?.ra ? c?.ra : '--:--'}
                                                                              </span>
                                                                            {/* {c?.mesCalamviec !== '' &&
                                                                                <div className="text-danger">{c?.mesCalamviec}</div>
                                                                            } */}
                                                                            {c?.chinhsua !== 0 &&
                                                                                <div className="text-danger">(Đã chỉnh
                                                                                    sửa)</div>
                                                                            }
                                                                        </div>
                                                                    ))}
                                                                {iv?.mes !== '' && (
                                                                    <div className="text-center text-gray tb-amount">
                                                                        {iv?.mes}
                                                                    </div>
                                                                )}
                                                                {iv?.meslock.length > 0 && (
                                                                    iv?.meslock.map((lo, index) => (
                                                                        <div
                                                                            className="text-center text-danger tb-amount"
                                                                            key={`bb-${index}`}>
                                                                            {lo.url === 'nghi-phep-cong-tac' ? (
                                                                                <>
                                                                                    <Icon
                                                                                        className="text-warning fs-20px"
                                                                                        name="alert-fill"
                                                                                        id={`donxinnghi${index}`}/>
                                                                                    <div
                                                                                        className="text-warning">{lo.log}</div>
                                                                                    <Tooltip
                                                                                        isOpen={tooltipOpen2}
                                                                                        target={`donxinnghi${index}`}
                                                                                        toggle={tooltip2}
                                                                                    >
                                                                                        {lo.log}. Hệ thống mặc định
                                                                                        không
                                                                                        ghi nhận công. Nếu muốn ghi nhận
                                                                                        công hãy hủy đơn!
                                                                                    </Tooltip>
                                                                                </>
                                                                            ) : (
                                                                                <span>{lo.log}</span>
                                                                            )}
                                                                        </div>
                                                                    ))
                                                                )}
                                                                {/* {iv?.TCngayle?.length > 0 &&
                                                                    iv?.TCngayle.map((le) => (
                                                                        <div
                                                                            className="text-center tb-amount"
                                                                            key={le}
                                                                        >
                                                                            <div className="text-gray">{le?.maphieu}</div>
                                                                            <span
                                                                                className={
                                                                                    le?.mestc
                                                                                        ? 'fs-12px text-danger'
                                                                                        : 'fs-12px'
                                                                                }
                                                                            >
                                                                                 {le.gio} (giờ)
                                                                              </span>
                                                                           
                                                                        </div>
                                                                    ))} */}
                                                                {iv?.TCngaythuong?.length > 0 &&
                                                                    iv?.TCngaythuong.map((th) => (
                                                                        <div
                                                                            className="text-center tb-amount"
                                                                            key={th}
                                                                        >
                                                                            {/* <div className="text-gray">Tăng ca</div> */}
                                                                            <div
                                                                                className="text-gray">{th?.maphieu}</div>
                                                                            <div
                                                                                className="text-gray fs-12px"> {th?.tcVao ? th?.tcVao : '--:--'} - {th?.tcRa ? th?.tcRa : '--:--'}</div>
                                                                            <span
                                                                                className={
                                                                                    th?.mestc
                                                                                        ? 'fs-12px text-danger'
                                                                                        : 'fs-12px text-gray'
                                                                                }
                                                                            >
                                                                                 {th.gio} (giờ)
                                                                              </span>
                                                                            {/* {le?.mestc &&
                                                                                  <div className="text-danger">{le?.mestc}</div>
                                                                                } */}
                                                                        </div>
                                                                    ))}
                                                                {/* {iv?.TCchunhat?.length > 0 &&
                                                                    iv?.TCchunhat.map((ch) => (
                                                                        <div
                                                                            className="text-center tb-amount"
                                                                            key={ch}
                                                                        >
                                                                            <div className="text-gray">{ch?.maphieu}</div>
                                                                            <span
                                                                                className={
                                                                                    ch?.mestc
                                                                                        ? 'fs-12px text-danger'
                                                                                        : 'fs-12px'
                                                                                }
                                                                            >
                                                                                 {ch.gio} (giờ)
                                                                              </span>
                                                                            
                                                                        </div>
                                                                    ))} */}
                                                            
                                                            </div>
                                                        </DataTableRow>
                                                    ))}
                                            </DataTableItem>
                                        );
                                    })
                                    : null}
                            </DataTableBody>
                        </div>
                        <div className="card-inner">
                            {currentItems.length > 0 ? (
                                <PaginationComponent
                                    itemPerPage={itemPerPage}
                                    totalItems={data.length}
                                    paginate={paginate}
                                    currentPage={currentPage}
                                />
                            ) : (
                                <div className="text-center">
                                    <div>
                                        <Spinner color="primary"/>
                                    </div>
                                    <span className="text-silent">Chưa có dữ liệu!</span>
                                </div>
                            )}
                        </div>
                    </DataTable>
                </Block>
                <ModalEditCong show={show} setShow={() => setShow(false)} dataChamCong={dataId} fetchData={fetchData}/>
                <ModalLog show={showLog} setShow={() => setShowLog(false)} dataLog={totalData.unlock}/>
                <ModalHistory show={showHistory} setShow={() => setShowHistory(false)} dataHistory={dataHistory}/>
            </Content>
        </React.Fragment>
    );
}

export default Chamcong;


// import React, {useEffect, useMemo, useState} from 'react';
// import {
//     apiEditBangchotcong,
//     apiListBangChamCong,
//     apiListChamCong,
// } from '~/services';
// import {useDispatch, useSelector} from 'react-redux';
// import Head from '~/layout/head/Head';
// import Content from '~/layout/content/Content';
// import {Block} from '~/components/block/Block';
// import {Button, DataTable, Icon,} from '~/components/Component';

// import {Card} from 'reactstrap';
// import {format, parseISO} from "date-fns";
// import * as actions from '~/store/actions';

// import {toast} from "react-toastify";
// import {MaterialReactTable} from "material-react-table";
// import {Link, useSearchParams} from "react-router-dom";
// import {Box} from "@mui/material";
// import {ExportToCsv} from "export-to-csv";
// import {ModalEditCong} from "~/pages/Admin/Modal";
// import config from "~/config";
// import {MRT_Localization_VI} from "material-react-table/locales/vi";
// import {generateDateHeader, generateDateHeaderExport} from "~/pages/Admin/Modal/Bangchamcong/RenderButton";


// const Chamcong = () => {
//         const dispatch = useDispatch();
//         const [data, setData] = useState([]);
//         const [dataNhanvien, setDataNhanvien] = useState([]);

//         // const [files, setFiles] = useState([]);
//         // const [spinner, setSpinner] = useState(false);

//         const [dataChamCong, setDataChamCong] = useState([]);
//         const [dateInMonth, setDateInMonth] = useState('');
//         const [smonth, setMonth] = useState('');
//         const [syear, setYear] = useState('');
//         const [show, setShow] = useState(false);

//         const [itemChotCong, setItemChotCong] = useState([]);
//         // console.log('itemChotCong', itemChotCong)
//         const {nhanvien} = useSelector((state) => state.nhanvien);
//         const {bangchotcong} = useSelector(state => state.bangchotcong)

//         const [bangChotCongId, setBangChotCongId] = useState('');
//         const [searchParams] = useSearchParams();
//         const fieldList = {
//             vao1: '',
//             ra1: '',
//             vao2: '',
//             ra2: '',
//             tonggio: '',
//             cong: '',
//             // luongngay:'',
//         }
//         useEffect(() => {
//             if (nhanvien) {
//                 setDataNhanvien(nhanvien)
//             }
//         }, [nhanvien]);
//         const fetchDataAndBangChotCong = () => {
//             if (dataNhanvien && bangChotCongId) {
//                 dispatch(actions.getListBangchotcong());
//             }
//         };
//         const findAndUpdateItemChotCong = () => {
//             if (bangchotcong) {
//                 const dataBangchotcong = bangchotcong?.find((item) => item?.id === Number(bangChotCongId));
//                 // console.log('dataBangchotcong', dataBangchotcong)
//                 if (dataBangchotcong) {
//                     fetchData(dataBangchotcong).then((r) => 'Error');
//                     setItemChotCong(dataBangchotcong);
//                 }

//             }
//         };
//         useEffect(() => {
//             dispatch(actions.getListNhanvien());
//             setBangChotCongId(searchParams.get('bangcong'));
//         }, []);

//         useEffect(() => {
//             fetchDataAndBangChotCong();
//         }, [nhanvien, bangChotCongId]);

//         useEffect(() => {
//             findAndUpdateItemChotCong();
//         }, [bangchotcong]);

//         async function fetchData(payload) {
//             const now = new Date(payload?.ngaybatdau); // lấy thời gian hiện tại
//             let year = now.getFullYear();
//             let month = now.getMonth() + 1;
//             const countDate = getDaysInMonth(year, month);
//             setDateInMonth(countDate)
//             setMonth(month)
//             setYear(year)

//             let datapayload = {
//                 BangchotcongId: Number(payload?.id),
//                 ngaybatdau: payload?.ngaybatdau,
//                 ngayketthuc: payload?.ngayketthuc,
//             };

//             try {
//                 if (Number(bangChotCongId) !== 0) {

//                     const Chamcong = await apiListBangChamCong(datapayload);
//                     const response = Chamcong?.data?.data;
//                     setData(response);
//                 }

//                 // console.log('response', response);
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             }
//         }


//         const handleItemEdit = async (row, day) => {
//             let idBangCong = row.idBangCong
//             if (!idBangCong) {
//                 toast.error('Vui lòng kiểm tra lại dữ liệu đã nhập!')
//                 return false;
//             }

//             let dayTmp = day.split('d')
//             let dayReal = dayTmp[1]
//             setShow(true);

//             let dateCong = row

//             let addField = {
//                 is_edit: itemChotCong.TrangthaiId,
//                 NhanvienId: row.NhanvienId,
//                 id: idBangCong,
//                 day: dateCong.dayCong,
//                 cham: dateCong.cham,
//                 vao1: dateCong.vao1,
//                 ra1: dateCong.ra1,
//                 vao2: dateCong.vao2,
//                 ra2: dateCong.ra2,
//                 idBangCong: dateCong.idBangCong,
//                 ghichu: dateCong.ghichu,
//                 cong: dateCong.cong,
//                 tonggio: dateCong.tonggio,
//                 BangchotcongId: bangChotCongId,
//                 nghiPhep: dateCong.nghiPhep,
//                 KhaibaocalamviecId: '',
//                 PhancaId: ''
//             }
//             await setDataChamCong(addField)
//         }
//         const handleLock = async (itemChotCong) => {

//             let isDanger = data.map((item) => item.isDanger === 1)
//             if (isDanger && data?.length === 0) {
//                 toast.error('Vui lòng kiểm tra lại dữ liệu đã nhập!')
//                 return false;
//             }
//             if (itemChotCong) {
//                 let datapayload = {
//                     id: itemChotCong.id,
//                     TrangthaiId: itemChotCong.TrangthaiId === 1 ? 0 : 1,
//                 }
//                 const response = await apiEditBangchotcong(datapayload)
//                 if (response.data.success === 200) {
//                     toast.success(itemChotCong.TrangthaiId === 1 ? 'Mở khóa thành công!!' : 'Khóa thành công!!')
//                     fetchDataAndBangChotCong()
//                 } else {
//                     toast.error('Đã có lỗi xảy ra vui lòng thử lại!!')
//                 }
//             }
//         };
//         const Example = () => {
//                 const dataAb = data;
//                 const dataExport = dataAb.map((item, index) => {

//                     let abc = {}
//                     abc.rowNumber = index + 1
//                     abc.name = item.name
//                     abc.uuid = item.uuid

//                     for (let i = 1; i <= dateInMonth; i++) {
//                         abc[`dateCong.d${i}`] = item.dateCong[`d${i}`][0].ghichu + ' :' + item.dateCong[`d${i}`][0].vao1 + ' - ' + item.dateCong[`d${i}`][0].ra1;

//                     }
//                     return abc
//                 })
//                 //
//                 const generateDateAccessor = (row, day) => (
//                     <>
//                         {row.dateCong[day] && row.dateCong[day].map((itemDay, kl) => {

//                             if (itemDay.KhaibaocalamviecId !== 0) {
//                                 const isDangerClass = itemDay.TrangthaiId === 3;
//                                 const className = `btn fs-12px px-0 py-0 w-100 justify-content-center text-center ${isDangerClass ? 'btn-warning' : ''}`;

//                                 return (
//                                     <>
//                                         <button
//                                             className={className}
//                                             data-id={itemDay.idBangCong}
//                                             onClick={() => handleItemEdit(itemDay, day)}>
//                                             <b className="text-dark">
//                                                 <a className="">{itemDay.ghichu}</a>
//                                                 <br/>
//                                                 <spam
//                                                     className={itemDay?.dimuon !== 0 ? "text-danger" : ""}>{itemDay.vao1}</spam>
//                                                 - <spam
//                                                 className={itemDay?.vesom !== 0 ? "text-danger" : ""}>{itemDay.ra1}</spam>
//                                             </b>
//                                         </button>
//                                         {itemDay.KhaibaocalamviecId && (
//                                             <>
//                                                 <div className="text-center fs-10px">Đi muộn: {itemDay?.dimuon || 0} phút</div>
//                                                 <div className="text-center fs-10px">Về sớm: {itemDay?.vesom || 0} phút</div>
//                                             </>
//                                         )}

//                                         {row.dateCong[day].length === kl + 1 && itemDay?.nghiPhep[0] ?
//                                             <p
//                                                 className="fs-12px px-0 py-0 w-100 justify-content-center text-center">
//                                                 <b className="text-danger">{itemDay?.nghiPhep[0]?.type}<br/>
//                                                     <small className="text-dark text-center fs-8">
//                                                         Từ:{(format(parseISO(itemDay?.nghiPhep[0]?.ngaybatdau), 'dd/MM/yyyy'))} -
//                                                         Tới{(format(parseISO(itemDay?.nghiPhep[0]?.ngayketthuc), 'dd/MM/yyyy'))}
//                                                     </small>
//                                                 </b></p> : ''}
//                                     </>
//                                 )
//                             }
//                         })}
//                     </>

//                 );

//                 // Tạo và convert dữ liệu ra ban chấm công
//                 const dateColumns = Array.from({length: dateInMonth}, (_, i) => {
//                     const day = i + 1;
//                     const accessorKey = `dateCong.d${day}`;
//                     const headerExport = generateDateHeaderExport(day, smonth, syear);
//                     const enableSorting = false;
//                     const enableColumnActions = false;
//                     const accessorFn = (row) => generateDateAccessor(row, `d${day}`);
//                     return {
//                         accessorKey,
//                         headerExport, enableSorting, enableColumnActions,
//                         accessorFn,
//                         size: 150,
//                         Header: generateDateHeader(day, smonth, syear)
//                     };
//                 });

//                 const columns = useMemo(
//                     () => [
//                         {
//                             Header: 'STT', // Tiêu đề cột số thứ tự
//                             header: 'STT', // Tiêu đề cột số thứ tự
//                             id: 'rowNumber', // ID của cột
//                             Cell: ({row}) => <div>{row.index + 1}</div>, // Giá trị của cột là số thứ tự
//                             size: 100, // Độ rộng của cột số thứ tự
//                             enableSorting: false,
//                             enableExport: false,
//                             enableColumnActions: false,
//                         },
//                         {
//                             accessorKey: 'name',
//                             header: 'Họ tên',
//                             enablePinning: true,
//                             enableColumnActions: true
//                         },
//                         {
//                             accessorKey: 'uuid',
//                             header: 'Mã nhân viên',
//                             enablePinning: true,
//                             enableColumnActions: true
//                         },
//                         ...dateColumns,
//                     ],
//                     [],
//                 )

//                 // console.log('columns',columns)
//                 const csvOptions = { fileName: 'csv-data',
//                     fieldSeparator: ',',
//                     filename: itemChotCong?.tieude,
//                     quoteStrings: '"',
//                     decimalSeparator: '.',
//                     showLabels: true,
//                     useBom: true,
//                     useKeysAsHeaders: false,
//                     headers: columns.map((c) => c.header !== undefined ? c.header : c.headerExport),

//                 };
//                 const csvExporter = new ExportToCsv(csvOptions);
//                 const handleExportData = () => {
//                     csvExporter.generateCsv(dataExport);
//                 };
//                 const handleBackPage = () => {
//                     window.location.href = `${config.he_thong}${config.bang_chot_cong}`;
//                 };
//                 const lockStatus = () => (
//                     <>
//                         <Icon name={itemChotCong.TrangthaiId === 1 ? "lock" : "unlock"} className="fs-26px"></Icon>
//                     </>
//                 );


//                 const CustomActions = React.memo(({table}) => {
//                     const createButton = (color, onClick, variant, icon, label) => (
//                         <Button color={color} onClick={onClick} variant={variant}>
//                             {icon && <Icon name={`${icon} me-2`}></Icon>}
//                             {label}
//                         </Button>
//                     );

//                     const navigateBackButton = createButton(
//                         "danger",
//                         handleBackPage,
//                         "contained",
//                         "ni ni-back-arrow-fill",
//                         "Quay lại"
//                     );
//                     const updateButton = itemChotCong.TrangthaiId === 1
//                         ? createButton("gray", null, "contained", "reload-alt", "Cập nhật")
//                         : createButton("primary", handleGetChamCongSubmit, "contained", "update", "Cập nhật");

//                     const exportButton = createButton("primary", handleExportData, "contained", "download-cloud", "Xuất dữ liệu");

//                     const lockButton = createButton("warning", () => handleLock(itemChotCong), "contained", null, lockStatus());

//                     return (
//                         <Box sx={{display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap'}}>
//                             {navigateBackButton}
//                             {updateButton}
//                             {exportButton}
//                             {lockButton}
//                         </Box>
//                     );

//                 });

//                 return (
//                     <MaterialReactTable
//                         columns={columns}
//                         data={dataAb}
//                         state={{isLoading: !dataAb}}
//                         defaultDisplayColumn={{enableResizing: true}}
//                         enableColumnResizing
//                         maxHeight='100'
//                         rowCount={dataAb.length}
//                         enableFullScreenToggle={false}
//                         initialState={{columnPinning: {left: ['rowNumber', 'name', 'uuid']}, density: 'compact'}}
//                         localization={MRT_Localization_VI}
//                         renderTopToolbarCustomActions={(props) => <CustomActions {...props} />}
//                     />
//                 );
//             }
//         ;


//         function getDaysInMonth(year, month) {
//             return new Date(year, month, 0).getDate();
//         }

//         const handleGetChamCongSubmit = async () => {

//             let dataBangchotcong = bangchotcong?.find(item => {
//                 return item && item.id === Number(bangChotCongId)
//             })

//             if (dataBangchotcong) {
//                 try {
//                     const {ngaybatdau, ngayketthuc} = dataBangchotcong || {};
//                     const datapayload = {
//                         ngaybatdau: ngaybatdau ? format(parseISO(ngaybatdau), 'yyyy-MM-dd') : null,
//                         ngayketthuc: ngayketthuc ? format(parseISO(ngayketthuc), 'yyyy-MM-dd') : null,
//                         BangchotcongId: Number(bangChotCongId),
//                     };
//                     await apiListChamCong(datapayload);
//                     toast.success("Lấy thông tin chấm công thành công");
//                     fetchData(dataBangchotcong);
//                 } catch (error) {
//                     // console.error("Error fetching cham cong:", error);
//                     toast.error("Đã có lỗi xảy ra khi lấy thông tin chấm công");
//                 }
//             } else {
//                 // console.error("Bảng chốt công không tồn tại");
//                 toast.error("Đã có lỗi xảy ra khi lấy thông tin chấm công");
//             }
//         };

//         return (
//             <React.Fragment>
//                 <Head title="Bảng công"></Head>
//                 <Content>
//                     <Card className="mt-5">
//                         <div className="card-aside-wrap row">
//                             <div className="card-inner card-inner-lg px-4 pt-1 pb-0">
//                                 <h4 className="mt-2"> {itemChotCong?.tieude}</h4>
//                                 <i className={itemChotCong?.TrangthaiId === 1 ? 'text-danger' : 'text-info'}>{itemChotCong?.TrangthaiId === 1 ? '(*) Bảng chấm công đã khóa, muốn chỉnh sửa vui lòng mở khóa.' : '(*) Bảng chấm công chưa khóa, bạn có thể chỉnh sửa công.'}</i>

//                             </div>
//                         </div>
//                     </Card>
//                     <Block>
//                         <DataTable className="card-stretch border-0">
//                             <div className="card-inner position-relative card-tools-toggle">
//                                 <Example/>
//                             </div>
//                         </DataTable>
//                     </Block>
//                     <ModalEditCong show={show} fieldList={fieldList} setShow={() => setShow(false)}
//                                    dataChamCong={dataChamCong}/>

//                 </Content>
//             </React.Fragment>
//         );
//     }
// ;

// export default Chamcong;