import React from 'react';
import Icon from '../icon/Icon';
import Button from '../button/Button';
import * as excelJs from "exceljs";

const ExportToExcel = ({ dataFileMau, header, namefile, btnname, title,dataExport, donvihanhchinh }) => {
    const exportExcel = async () => {
        const headerExport = title
        const opt_chinhanh = dataExport.chinhanh.flatMap((opt) => opt.diachi);
        const opt_phongban = dataExport.phongban.flatMap((opt) => opt.phongban);
        const opt_chucvu = dataExport.chucvu.flatMap((opt) => opt.chucvu);
        const opt_gioitinh = dataExport.gioitinh.flatMap((opt) => opt.label);
        const opt_kieuluong = dataExport.kieuluong.flatMap((opt) => opt.tieude);
        // const opt_hocvan = dataExport.hocvan.flatMap((opt) => opt.label);
        // const opt_maychamcong = dataExport.maychamcong.flatMap((opt) => opt.tenmaychamcong);
        // const opt_calamviec = dataExport.cauhinhcalamviec.flatMap((opt) => opt.macalamviec);
        const opt_congthuc = dataExport?.congthuctinhluong.flatMap((opt) => opt.tencongthuc);
        const opt_kieuphepp = dataExport?.kieuphep.flatMap((opt) => opt.label);
        
        const mangTt = donvihanhchinh?.data?.province
        ? JSON.parse(donvihanhchinh.data.province).map((item) => ({
              label: item.name,
              value: item.id,
          }))
        : [];
        const mangTt2 = donvihanhchinh?.data?.district
        ? JSON.parse(donvihanhchinh.data.district).map((item) => ({
              label: item.name,
              value: item.id,
              ma: item.province_id,
          }))
        : [];
        const mangTt3 = donvihanhchinh?.data?.wards
        ? JSON.parse(donvihanhchinh.data.wards).map((item) => ({
              label: item.name,
              value: item.id,
              mas: item.district_id,
          }))
        : [];
        // const mangTt = [
        //         {
        //             "label": " Hà Nội",
        //             "value": 1
        //         },
        //         {
        //             "label": " Hà Giang",
        //             "value": 2
        //         },
        //         {
        //             "label": " Cao Bằng",
        //             "value": 3
        //         },
                
        // ];
        // const mangTt2 = [
        //         {
        //             "label": " Hậu Giang",
        //             "value": 60,
        //             "ma": 1
        //         },
        //         {
        //             "label": " Sóc Trăng",
        //             "value": 61,
        //             "ma": 1
        //         },
        //         {
        //             "label": " Bạc Liêu",
        //             "value": 62,
        //             "ma": 1
        //         },
        //         {
        //             "label": " Cà Mau",
        //             "value": 63,
        //             "ma": 1
        //         }
        // ];
        // const mangTt3 = [
        //         {
        //             "label": " aaaa",
        //             "value": 60,
        //             "ma": 60
        //         },
        //         {
        //             "label": " bbbbbb",
        //             "value": 61,
        //             "ma": 60
        //         },
        //         {
        //             "label": " cccccc",
        //             "value": 62,
        //             "ma": 60
        //         },
        //         {
        //             "label": " dddd",
        //             "value": 63,
        //             "ma": 60
        //         }
        // ];
        const otp_mangTt = mangTt.flatMap((opt) => opt.label);
        const formulas = [
            `"${opt_gioitinh.join(',')}"`,
            `"${otp_mangTt.join(',')}"`,
            `=OFFSET('danh sách địa chính'!$D$1;MATCH(VLOOKUP(AG2;'danh sách địa chính'.$A$2:$B$64;2;0);'danh sách địa chính'.$F$2:$F$706;0);0;COUNTIF('danh sách địa chính'.$F$2:$F$706;VLOOKUP(AG2;'danh sách địa chính'.$A$2:$B$64;2;0)))`,
            `=OFFSET('danh sách địa chính'!$H$1;MATCH(VLOOKUP(AH2;'danh sách địa chính'.$D$2:$E$706;2;0);'danh sách địa chính'.$J$2:$J$10585;0);0;COUNTIF('danh sách địa chính'.$J$2:$J$10585;VLOOKUP(AH2;'danh sách địa chính'.$D$2:$E$706;2;0)))`,
            // `"${opt_maychamcong.join(',')}"`,
            `"${opt_chinhanh.join(',')}"`,
            `"${opt_phongban.join(',')}"`,
            `"${opt_chucvu.join(',')}"`,
            // `"${opt_calamviec.join(',')}"`,
            `"${opt_kieuluong.join(',')}"`,
            `"${opt_congthuc?.join(',')}"`,
            `"${opt_kieuphepp.join(',')}"`,
            // `"${opt_hocvan.join(',')}"`,
            
        ];
        //=OFFSET('danh sách địa chính'.$D$1;MATCH(VLOOKUP(AG2;$'danh sách địa chính'.$A$2:$B$64;2;0),$'danh sách địa chính'.F2:$F$706;0);0;COUNTIF($'danh sách địa chính'.F2:$F$706;VLOOKUP(AG2;$'danh sách địa chính'.A2:$B$64;2;0)))
        //=OFFSET('danh sách địa chính'.$H$1;MATCH(VLOOKUP(AH2;$'danh sách địa chính'.$D$2:$F$706;2;0);$'danh sách địa chính'.$J$2:$J$10585;0);0;COUNTIF('danh sách địa chính'.$J$2:$J$10585;VLOOKUP(AH2;$'danh sách địa chính'.$D$2:$F$706;2;0)))
        
        const workbook = new excelJs.Workbook();
        const ws = workbook.addWorksheet('Nhân viên mẫu');
        const ws2 = workbook.addWorksheet('danh sách địa chính');
        const columnWidths = [
            { width: 15 },
            { width: 10, alignment: { horizontal: 'center', vertical: 'middle', wrapText: true } },
            { width: 10 },
            { width: 20 },
            { width: 10, alignment: { horizontal: 'center', vertical: 'middle', wrapText: true } },
            { width: 10, alignment: { horizontal: 'center', vertical: 'middle', wrapText: true } },
            { width: 10 },
            { width: 20 },
            { width: 10, alignment: { horizontal: 'center', vertical: 'middle', wrapText: true } },
            { width: 10, alignment: { horizontal: 'center', vertical: 'middle', wrapText: true } }
        ];
        columnWidths.forEach((widthOptions, columnIndex) => {
            const column = ws2.getColumn(columnIndex + 1);
            column.width = widthOptions.width;
            // Cẩn chỉnh là một đối tượng chứa các tùy chọn căn chỉnh
            if (widthOptions?.alignment) {
                column.alignment = widthOptions.alignment;
            }
        });

        ws2.addRow(["Tỉnh/Thành", "Mã", "", "Quận/Huyện", "Mã", "Mã tỉnh", "", "Phường/Xã", "Mã", "Mã huyện"]);
        const maxRows = Math.max(mangTt.length, mangTt2.length, mangTt3.length);
        for (let i = 0; i < maxRows; i++) {
            const location1 = mangTt[i] || { label: "", value: "" };
            const location2 = mangTt2[i] || { label: "", value: "", ma: "" };
            const location3 = mangTt3[i] || { label: "", value: "", mas: "" };
            
            ws2.addRow([location1.label, location1.value, "", location2.label, location2.value, location2.ma,"", location3.label, location3.value, location3.mas]);
        }
        const dataValidationRanges = ['C2:C99999','H2:H99999', 'I2:I99999', 'J2:J99999', 'U2:U99999', 'V2:V99999', 'W2:W99999', 'X2:X99999', 'AA2:AA99999', 'AB2:AB99999'];
        // const dataValidationRanges = ['B2:B99999','M2:M99999', 'R2:R99999', 'S2:S99999','T2:T99999','U2:U99999','X2:X99999','Z2:Z99999','AB2:AB99999','AG2', 'AH2', 'AI2'];
        dataValidationRanges.forEach((range, index) => {
            ws.dataValidations.add(range, {
                type: 'list',
                allowBlank: false,
                showDropDown:false,
                showErrorMessage: false,
                showInputMessage: false,
                formulae: [formulas[index]],
            });
        });
    
        ws.addRow(headerExport);
        ws.columns.map((col, index) => (col.width = 18));
        
        const cellFormatting = {
            font: {
                name: 'Inter',
                size: 8,
            },
            alignment: {
                horizontal: 'center',
            },
        };
        
        ws.eachRow((row) => {
            row.eachCell((cell) => {
                Object.assign(cell, cellFormatting);
            });
        });
        
        const excelBlob = await workbook.xlsx.writeBuffer();
        const excelUrl = URL.createObjectURL(
            new Blob([excelBlob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        );
        
        const link = document.createElement('a');
        link.href = excelUrl;
        link.download = '(Nhân viên)du_lieu_mau.xlsx';
        document.body.appendChild(link);
        link.click();
        
        URL.revokeObjectURL(excelUrl);
        document.body.removeChild(link);
    };

    return (
        <React.Fragment>
            <Button color="primary" onClick={() => exportExcel()}>
                <Icon name="download-cloud"></Icon> <span>{btnname}</span>
            </Button>
        </React.Fragment>
    );
};
export default ExportToExcel;
