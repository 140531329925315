import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
// import './ModalNhanVien.scss';
import {Input, Modal, ModalBody, Spinner} from 'reactstrap';
import {
    apiDeleteLoaiDexuat, apiDeleteLoaiNghiPhepCongTac,
    apiEditLoaiDexuat, apiEditLoaiNghiPhepCongTac,
    apiGetLoaiDexuat,
    apiGetLoaiNghiPhepCongTac,
    apiGetNhanvien,
    apiNewLoaiDexuat, apiNewLoaiNghiPhepCongTac
} from '~/services';
// import Icon from '../icon/Icon';
import Select from 'react-select';
import {Button, Icon} from '~/components/Component';
import {InputForm} from "~/components/Form";
import Textarea from "~/components/Form/Textarea";
import {toast} from "react-toastify";
import {dataSelectActive, dataSelectKieuPhep, dataSelectStatus} from "~/utils/Datacustom";
import {validateNghiphepcongtac} from "~/request/validateLoainghiphepcongtac";

const ModalSelectType = ({showSelect, setShowSelect, listType, setListType}) => {
    const [invalidFields, setInvalidFields] = useState('');
    const [dataAll, setDataAll] = useState([]);
    const [data, setData] = useState([]);
    const [dataCkb, setDataCkb] = useState([]);
    const [listTrangThai, setListTrangThai] = useState([]);
    const [listKieuphep, setlistKieuphep] = useState([]);
    const [payload, setPayload] = useState({tieude: '', mota: ''});
    
    useEffect(() => {
        fetchData()
    }, []);
    // console.log('dataAll', dataAll)
    useEffect(() => {
        if (showSelect === true) {
            setDataCkb([]);
            setListTrangThai(dataSelectActive)
            setlistKieuphep(dataSelectKieuPhep);
        }
        if (listType?.length > 0 && listType[0].value !== undefined) {
            let result = [];
            listType?.map((i) => {
                let index = dataAll.findIndex((item) => item.id === i.value);
                let nhanvien = dataAll[index];
                nhanvien.checked = true;
                result.push(nhanvien);
            });
            setDataCkb(listType);
        }
    }, [showSelect, listType]);
    
    async function fetchData() {
        try {
            const loaiNghiPhepCongTac = await apiGetLoaiNghiPhepCongTac();
            const response = loaiNghiPhepCongTac.data.data.rows;
            let loaiNghiPhepCongTacActive = response.filter((it) => {
                return it.TrangthaiId === 1;
            });
            setData([...loaiNghiPhepCongTacActive])
            setDataAll([...loaiNghiPhepCongTacActive])
            
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    
    // console.log('data:::::::::::',data)
    const onSelectChangeSV = (e, id) => {
        let index = data.findIndex((item) => item.id === id);
        let nhanvien = data[index];
        setDataCkb([nhanvien]);
    };
    
    // const selectorCheckSL = (e) => {
    //     const { checked } = e.currentTarget;
    //     const newData = data.map((item) => (item.checked = checked));
    //     setDataCkb(checked ? [...newData] : []);
    // };
    // handleEditType
    const handleEditType = async (data) => {
        let TrangthaiId = dataSelectActive.find((items) => {
            return items && items.value === data?.TrangthaiId;
        });
        
        let type = dataSelectKieuPhep.find((items) => {
            return items && items.type === data?.type;
        });
        setPayload((prev) => ({
            ...prev,
            "tieude": data.tieude,
            "mota": data.mota,
            "TrangthaiId": {label: TrangthaiId?.label, value: TrangthaiId?.value},
            "type": {label: type?.label, value: type?.type},
            "id": data.id
        }));
    };
    const handleDeleteType = async (loaiNghiPhepCongTacId) => {
        let dataPayload = {
            id: loaiNghiPhepCongTacId
        }
        let deleteData = await apiDeleteLoaiNghiPhepCongTac(dataPayload)
        if (deleteData?.data.success === 200) {
            toast.success("Xóa dữ liệu thành công!!")
            fetchData()
        } else {
            toast.error("Kiểu đề xuất đang được sử dụng. Muốn xóa thì gỡ kiểu đề xuất ra.")
        }
    }
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateNghiphepcongtac(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateNghiphepcongtac(item[0], item[1])]);
                invalids++;
            }
        });
        
        return invalids;
    };
    const handleSubmitAddType = async () => {
        let invalids = validate(payload);
        if (invalids === 0) {
            if (payload.id) {
                let datapayloadEdit = {
                    data: [
                        {
                            id: payload.id,
                            tieude: payload.tieude,
                            mota: payload.mota,
                            type: payload?.type.type,
                            TrangthaiId: payload?.TrangthaiId.value,
                        },
                    ],
                };
                
                const loaiNghiPhepCongTac = await apiEditLoaiNghiPhepCongTac(datapayloadEdit);
                if (loaiNghiPhepCongTac?.data.success === 200) {
                    toast.success("Cập nhật thành công!!")
                    
                    fetchData()
                }
            } else {
                let datapayload = {
                    data: [
                        {
                            tieude: payload.tieude,
                            mota: payload.mota,
                            type: payload?.type.type,
                            TrangthaiId: payload?.TrangthaiId.value,
                        },
                    ],
                };
                
                const loaiNghiPhepCongTac = await apiNewLoaiNghiPhepCongTac(datapayload);
                if (loaiNghiPhepCongTac?.data.success === 200) {
                    toast.success("Cập nhật thành công!!")
                    fetchData()
                }
            }
            setPayload([])
        }
    };
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayload((prev) => ({...prev, [statename]: selected}));
    };
    const handleSubmit = () => {
        
        setListType(
            dataCkb?.map((item) => ({
                label: item.tieude || item.label,
                value: item.id || item.value,
                type: item.type || item.type,
            })),
        );
        setShowSelect(false);
    };
    
    return (
        <React.Fragment>
            <Modal isOpen={showSelect} toggle={setShowSelect} size="lg">
                <ModalBody>
                    <button type="button" className="btn-close text-danger fw-bolder position-absolute"
                            style={{right: '2px', top: '2px', border: '2px solid'}}
                            onClick={setShowSelect}></button>
                    <div className="container">
                        <div className="position-relative card-tools-toggle px-0">
                            <div className="">
                                <div className="card-title">
                                    <h5 className="title text-center text-uppercase text-primary">Chọn một loại phù
                                        hợp</h5>
                                </div>
                            </div>
                            <div className="card-title-group mt-3 row border">
                                <div className="form-group col-md-12 mb-1">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Tiêu đề'}
                                        value={payload.tieude}
                                        setValue={setPayload}
                                        keyPayload={'tieude'}
                                        type="text"
                                        validate
                                    />
                                </div>
                                <div className="form-group col-md-12 mb-1">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Mô tả'}
                                        value={payload.mota}
                                        setValue={setPayload}
                                        keyPayload={'mota'}
                                        type="text"
                                        validate
                                    />
                                </div>
                                <div className="form-group col-md-6 mb-3">
                                    <label htmlFor="inputZip">
                                        Thuộc loại Cấu hình <b className="text-danger">(*)</b>
                                    </label>
                                    <Select
                                        value={payload.type}
                                        onChange={handleOnSelect}
                                        options={listKieuphep}
                                        placeholder={'Chọn loại'}
                                        name="type"
                                        onFocus={() => setInvalidFields([])}
                                    />
                                    {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'TrangthaiId') && (
                                        <small className="text-danger">
                                            {invalidFields.find((i) => i.name === 'TrangthaiId')?.message}
                                        </small>
                                    )}
                                </div>
                                <div className="form-group col-md-6 mb-3">
                                    <label htmlFor="inputZip">
                                        Trạng thái <b className="text-danger">(*)</b>
                                    </label>
                                    <Select
                                        value={payload.TrangthaiId}
                                        onChange={handleOnSelect}
                                        options={listTrangThai}
                                        placeholder={'Chọn trạng thái'}
                                        name="TrangthaiId"
                                        onFocus={() => setInvalidFields([])}
                                    />
                                    {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'TrangthaiId') && (
                                        <small className="text-danger">
                                            {invalidFields.find((i) => i.name === 'TrangthaiId')?.message}
                                        </small>
                                    )}
                                </div>
                                <div className="form-group col-md-12 mb-2 text-end">
                                    <span
                                        className="text-white ms-0 px-5 py-1 badge justify-content-center px-0 btn btn-primary"
                                        onClick={handleSubmitAddType}
                                    >
                                    <em className="icon ni ni-plus pe-2"></em>Cập nhật loại nghỉ phép công tác
                                </span>
                                </div>
                            </div>
                            <div className="card-title-group mt-3 row">
                                <table className="table table-fixed">
                                    <thead className="d-block">
                                    <tr className="d-flex bg-warning text-white">
                                        <th colSpan={5} className="text-center">Danh sách loại Nghỉ Phép - Công Tác</th>
                                    </tr>
                                    <tr className="d-flex">
                                        <th className="col-1 border-bottom-0">#</th>
                                        <th className="col-1 border-bottom-0">
                                            <div className="custom-control custom-control-sm custom-checkbox notext">
                                                <div>
                                                    {/*<input*/}
                                                    {/*    type="checkbox"*/}
                                                    {/*    className="custom-control-input"*/}
                                                    {/*    onChange={(e) => selectorCheckSL(e)}*/}
                                                    {/*    id="uidd"*/}
                                                    {/*/>*/}
                                                    {/*<label className="custom-control-label" htmlFor="uidd"></label>*/}
                                                </div>
                                            </div>
                                        </th>
                                        <th className="col-4 border-bottom-0">
                                            <span className="sub-text fw-bold text-info text-uppercase">Tiêu đề</span>
                                        </th>
                                        <th className="col-4 border-bottom-0">
                                            <span
                                                className="sub-text fw-bold text-info text-uppercase">Loại Cấu hình</span>
                                        </th>
                                        <th className="col-2 border-bottom-0">
                                            <span className="sub-text fw-bold text-info text-uppercase">Thao tác</span>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data?.length > 0 ? (
                                        data?.map((item, index) => {
                                            return (
                                                <tr className="d-flex" key={index}>
                                                    <td className="col-1">{index + 1}</td>
                                                    <td className="col-1">
                                                        <div
                                                            className="custom-control custom-control-sm custom-checkbox notext">
                                                            <input
                                                                type="radio"
                                                                className="custom-control-input"
                                                                defaultChecked={item.checked ? item.checked : false}
                                                                name="name"
                                                                id={item.id + 'uiddd'}
                                                                // key={Math.random()}
                                                                onChange={(e) => onSelectChangeSV(e, item.id)}
                                                            />
                                                            
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor={item.id + 'uiddd'}
                                                            ></label>
                                                        </div>
                                                    </td>
                                                    <td className="col-4">
                                                        <span className="tb-amount"> {item?.tieude} </span>
                                                    </td>
                                                    <td className="col-4">
                                                        <span className="tb-amount"> {item?.type} </span>
                                                    </td>
                                                    <td className="col-2">
                                                        <button className={"ms-0 badge text-white bg-success"}
                                                                onClick={() => handleEditType(item)}>
                                                            Sửa
                                                        </button>
                                                        <button className={"ms-0 badge text-white bg-danger"}
                                                                onClick={() => handleDeleteType(item.id)}>
                                                            Xóa
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <div className="text-center">
                                            <div>
                                                <Spinner color="primary"/>
                                            </div>
                                            <span className="text-silent">Chưa có dữ liệu!</span>
                                        </div>
                                    )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        
                        <div className="card-title-group mt-2">
                                <span
                                    className="text-white ms-0 px-5 py-1 badge justify-content-center px-0 btn btn-primary"
                                    onClick={handleSubmit}
                                >
                                    <em className="icon ni ni-plus pe-2"></em>Xác nhận chọn
                                </span>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default ModalSelectType;
