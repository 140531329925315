export const validateMayChamCong = (fieldName, value) => {
    let regNumber = /^[0-9\b]+$/;
    switch (fieldName) {
        case 'tenmaychamcong':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Dữ liệu không được để trống'
                }
            }
            break;
        case 'ipmaychamcong':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Dữ liệu không được để trống'
                }
            }
            break;
        case 'timeout':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Dữ liệu không được để trống'
                }
            }
            break;
        case 'inport':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Dữ liệu không được để trống'
                }
            }
            break;
        case 'port':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Dữ liệu không được để trống'
                }
            }
            break;
        
        default:
            break;
    }
}