import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Modal, ModalBody, Button } from 'reactstrap';
import Head from '~/layout/head/Head';
import Content from '~/layout/content/Content';
import { Block } from '~/components/block/Block';
import { DataTable, PermissionUtils, Icon } from '~/components/Component';
import { apiEditCongty, apiGetCongty, apiNewCongty } from '~/services/congty';
import { toast } from 'react-toastify';
import { InputForm } from '~/components/Form';
import { MenuDoanhnghiep } from './MenuTab/Menucontent';
import { format, parseISO } from 'date-fns';
import LogoLight2x from '../../images/avatar/logo.jpg';
import AvatarCrop from './Modal/Congty/AvatarCongtyCrop';

const Congty = () => {
    const dispatch = useDispatch();

    const [showPhanquyen, setShowPhanquyen] = useState({});
    let dataPermissionUtils = PermissionUtils('cauhinhdoanhnghiep', 'all');
    useEffect(() => {
        if (dataPermissionUtils) {
            setShowPhanquyen(dataPermissionUtils);
        }
    }, [dataPermissionUtils]);

    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayload] = useState({
        loaihinhcongty: '',
        masothue: '',
        diachi: '',
        daidienphaply: '',
        ngaycapphep: '',
        ngayhoatdong: '',
        dienthoai: '',
        email: '',
        tengiaodich: '',
        masodkkd: '',
        noicap: '',
        chucdanh: '',
        quoctichgiamdoc: '',
        fax: '',
        website: '',
        avatar: null,
    });
    const [modalAvatar, setmodalAvatar] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const congty = await apiGetCongty();
                const response = congty.data.data.rows[0];
                // console.log('response', congty)
                if(congty?.data?.success === 200){
                const datas = {
                    id: response?.id,
                    loaihinhcongty: response?.loaihinhcongty,
                    masothue: response?.masothue,
                    diachi: response?.diachi,
                    daidienphaply: response?.daidienphaply,
                    ngaycapphep: format(parseISO(response?.ngaycapphep), 'yyyy-MM-dd'),
                    ngayhoatdong: format(parseISO(response?.ngayhoatdong), 'yyyy-MM-dd'),
                    dienthoai: response?.dienthoai,
                    email: response?.email,
                    tengiaodich: response?.tengiaodich,
                    masodkkd: response?.masodkkd,
                    noicap: response?.noicap,
                    chucdanh: response?.chucdanh,
                    quoctichgiamdoc: response?.quoctichgiamdoc,
                    fax: response?.fax,
                    website: response?.website,
                    avatar: response?.avatar,
                };
                setPayload(datas);
            }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData().catch(console.error);
    }, []);
    const handleSubmit = async () => {
        const datapayload = {
            loaihinhcongty: payload.loaihinhcongty,
            masothue: payload.masothue,
            diachi: payload.diachi,
            daidienphaply: payload.daidienphaply,
            ngaycapphep: payload.ngaycapphep,
            ngayhoatdong: payload.ngayhoatdong,
            dienthoai: payload.dienthoai,
            email: payload.email,
            tengiaodich: payload.tengiaodich,
            masodkkd: payload.masodkkd,
            noicap: payload.noicap,
            chucdanh: payload.chucdanh,
            quoctichgiamdoc: payload.quoctichgiamdoc,
            fax: payload.fax,
            website: payload.website,
            avatar: payload.avatar,
        };
        if (payload.id) {
            datapayload.id = payload.id;
            const response = await apiEditCongty(datapayload);
            response?.data.length === 0
                ? toast.error('Sửa thông tin không thành công!')
                : toast.success('Sửa thông tin thành công!');
        } else {
            // console.log('datapayload', datapayload)
            const response = await apiNewCongty(datapayload);
            response?.data.length === 0
                ? toast.error('Thêm thông tin không thành công!')
                : toast.success('Thêm thông tin thành công!');
        }
    };

    return (
        <React.Fragment>
            {/*<Head title="Doanh nghiệp, Chi nhánh, Phòng ban, Chức Vụ"></Head>*/}
            <Content>
                <Card className="mt-0">
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner card-inner-lg px-4">
                            <h4>Cấu hình doanh nghiệp</h4>
                            <MenuDoanhnghiep />
                        </div>
                    </DataTable>
                </Card>
                <Block>
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner position-relative card-tools-toggle pt-0">
                            <div className="row">
                                <div className="form-group col-md-12">
                                    {+showPhanquyen?.them === 1 && (
                                        <Button
                                            color="primary"
                                            className="btn-icon px-4 mt-1"
                                            onClick={(e) => handleSubmit(e)}
                                        >
                                            Cập nhật
                                        </Button>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mt-3">
                                    <div className="pb-0">
                                        <h6 className="overline-title text-primary">Công ty</h6>
                                    </div>
                                </div>
                                <div className="py-3">
                                    <div className="avatar-container border rounded-4">
                                        {payload?.avatar === null ? (
                                            <img src={LogoLight2x} alt="Avatar Image" className="rounded-4" />
                                        ) : (
                                            <img src={payload?.avatar} alt="Avatar Image" className="rounded-4" />
                                        )}
                                        <div className="camera-icon" onClick={() => setmodalAvatar(true)}>
                                            <Icon name="camera-fill" htmlFor="inputImge"></Icon>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Tên công ty'}
                                        value={payload.loaihinhcongty}
                                        setValue={setPayload}
                                        keyPayload={'loaihinhcongty'}
                                        type="text"
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Tên giao dịch/viết tắt'}
                                        value={payload.tengiaodich}
                                        setValue={setPayload}
                                        keyPayload={'tengiaodich'}
                                        type="text"
                                    />
                                </div>
                                <div className="form-group col-md-6 ">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Ngày thành lập'}
                                        value={payload.ngayhoatdong}
                                        setValue={setPayload}
                                        keyPayload={'ngayhoatdong'}
                                        type="date"
                                    />
                                </div>
                                <div className="form-group col-md-6 ">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Mã số thuế'}
                                        value={payload.masothue}
                                        setValue={setPayload}
                                        keyPayload={'masothue'}
                                        type="text"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12 mt-3 mb-3">
                                    <div className="pb-0">
                                        <h6 className="overline-title text-primary">Đăng ký kinh doanh</h6>
                                    </div>
                                </div>
                                <div className="form-group col-md-4">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Mã số DKKD'}
                                        value={payload.masodkkd}
                                        setValue={setPayload}
                                        keyPayload={'masodkkd'}
                                        type="text"
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Ngày cấp phép'}
                                        value={payload.ngaycapphep}
                                        setValue={setPayload}
                                        keyPayload={'ngaycapphep'}
                                        type="date"
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Nơi cấp'}
                                        value={payload.noicap}
                                        setValue={setPayload}
                                        keyPayload={'noicap'}
                                        type="text"
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Người đại diện pháp luật'}
                                        value={payload.daidienphaply}
                                        setValue={setPayload}
                                        keyPayload={'daidienphaply'}
                                        type="text"
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Chức danh'}
                                        value={payload.chucdanh}
                                        setValue={setPayload}
                                        keyPayload={'chucdanh'}
                                        type="text"
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Quốc tịch'}
                                        value={payload.quoctichgiamdoc}
                                        setValue={setPayload}
                                        keyPayload={'quoctichgiamdoc'}
                                        type="text"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mt-3 mb-3">
                                    <div className="pb-0">
                                        <h6 className="overline-title text-primary">Liên hệ</h6>
                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Địa chỉ'}
                                        value={payload.diachi}
                                        setValue={setPayload}
                                        keyPayload={'diachi'}
                                        type="text"
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Email'}
                                        value={payload.email}
                                        setValue={setPayload}
                                        keyPayload={'email'}
                                        type="email"
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Điện thoại'}
                                        value={payload.dienthoai}
                                        setValue={setPayload}
                                        keyPayload={'dienthoai'}
                                        type="text"
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Fax'}
                                        value={payload.fax}
                                        setValue={setPayload}
                                        keyPayload={'fax'}
                                        type="text"
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Website'}
                                        value={payload.website}
                                        setValue={setPayload}
                                        keyPayload={'website'}
                                        type="text"
                                    />
                                </div>
                            </div>
                        </div>
                    </DataTable>
                </Block>
            </Content>
            <Modal
                isOpen={modalAvatar}
                toggle={() => setmodalAvatar(false)}
                className="modal-dialog-centered"
                size="md"
            >
                <ModalBody>
                    <a
                        href="#cancel"
                        onClick={(ev) => {
                            ev.preventDefault();
                            setmodalAvatar(false);
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h5 className="title">Logo công ty</h5>
                        <div className="mt-4">
                            <AvatarCrop setmodalAvatar={setmodalAvatar} dataCongty={payload} />
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default Congty;
