import actionTypes from './actionTypes'
import { apiGetLoaiquydinhphucloi, apiNewLoaiquydinhphucloi } from '~/services/loaiquydinhphucloi'
import { toast } from "react-toastify";
export const getListLoaiquydinhphucloi = () => async (dispatch) => {
    try {
        const response = await apiGetLoaiquydinhphucloi()
        // console.log('chek api lisst apiGetLoaiquydinhphucloi', response)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_LOAI_QUY_DINH_PHUC_LOI,
                loaiquydinhphucloi: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_LOAI_QUY_DINH_PHUC_LOI,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_LOAI_QUY_DINH_PHUC_LOI,
            congty: null
        })
    }
}

export const NewLoaiquydinhphucloi = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiNewLoaiquydinhphucloi(payload)
            console.log('chek api apiNewLoaiquydinhphucloi', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_LOAI_QUY_DINH_PHUC_LOI,
                    msg: 'OK'
                });
                dispatch(getListLoaiquydinhphucloi());
                toast.success("Thêm mới loại quy định phúc lợi thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_LOAI_QUY_DINH_PHUC_LOI,
                    msg: 'ERR'
                })
                toast.error("Thêm mới loại quy định phúc lợi thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_CONG_TY,
                congty: null
            })
        }
    }
}
// export const EditCongty = (payload) => {
//     return async (dispatch) => {
//         try {
//             const response = await apiEditCongty(payload)
//             console.log('chek api apiEditCongty', response)
//             if (response?.data.success === 200) {
//                 dispatch(getListCongTy());
//                 toast.success("Sửa thông tin công ty thành công!")
//                 dispatch({
//                     type: actionTypes.PUT_EDIT_CONG_TY,
//                     msg: 'OK'
//                 });
//             } else {
//                 dispatch({
//                     type: actionTypes.PUT_EDIT_CONG_TY,
//                     msg: 'ERR'
//                 })
//                 toast.error("Sửa thông tin công ty thất bại!")
//             }

//         } catch (error) {
//             dispatch({
//                 type: actionTypes.PUT_EDIT_CONG_TY,
//                 congty: null
//             })
//         }
//     }
// }
// export const DeleteCongty = (payload) => {
//     return async (dispatch) => {
//         try {
//             const response = await apiDeleteCongty(payload)
//             // console.log('chek api apiDeleteCongty', response?.data.success)
//             if (response?.data.success === 200) {
//                 dispatch(getListCongTy());
//                 toast.success("Xóa thông tin công ty thành công!")
//                 console.log('dsfndfghdfghdfgh')
//                 dispatch({
//                     type: actionTypes.DELETE_CONG_TY,
//                     msg: 'OK'
//                 });
               
//             } else {
//                 dispatch({
//                     type: actionTypes.DELETE_CONG_TY,
//                     msg: 'ERR'
//                 })
//                 toast.error("Xóa thông tin công ty thất bại!")
//             }

//         } catch (error) {
//             dispatch({
//                 type: actionTypes.DELETE_CONG_TY,
//                 congty: null
//             })
//         }
//     }
// }