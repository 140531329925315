import React, { useState } from 'react'
import { apiGetNhanVien } from '~/services/nhanvien'
import { useDispatch, useSelector } from 'react-redux'

const TestApi = () => {
     const [formValues, setFormValues] = useState([{ name: "", email : ""}])
 
     let handleChange = (i, e) => {
         let newFormValues = [...formValues];
         newFormValues[i][e.target.name] = e.target.value;
         setFormValues(newFormValues);
       }
     
     let addFormFields = () => {
         setFormValues([...formValues, { name: "", email: "" }])
       }
     
     let removeFormFields = (i) => {
         let newFormValues = [...formValues];
         newFormValues.splice(i, 1);
         setFormValues(newFormValues)
     }
     
     let handleSubmit = (event) => {
         event.preventDefault();
         alert(JSON.stringify(formValues));
     }
 
     return (
       <div className='container py-5'>
         <form  onSubmit={handleSubmit}>
           {formValues.map((element, index) => (
             <div className="mb-3 row g-3" key={index}>
               <div className="col-sm-6">
               <label>Name</label>
               <input type="text" name="name" className="form-control" value={element.name || ""} onChange={e => handleChange(index, e)} />
               </div>
               <div className="col-sm-6">
               <label>Email</label>
               <input type="text" name="email" className="form-control" value={element.email || ""} onChange={e => handleChange(index, e)} />
               </div>
               {
                 index ? 
                 <div className="col-sm text-end">
                   <button type="button"  className="button remove btn btn-danger" onClick={() => removeFormFields(index)}>Remove</button> 
                   </div>
                 : null
               }
               </div>
             
           ))}
           <div className="button-section">
               <button className="button add btn btn-warning me-3" type="button" onClick={() => addFormFields()}>Add</button>
               <button className="button submit btn btn-primary" type="submit">Submit</button>
           </div>
       </form>
       </div>
     )
 }

export default TestApi;