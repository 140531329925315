import actionTypes from "../actions/actionTypes";
const initState = {
    vitrituyendung: [],
    msg: '',
    count: 0,
    newvitrituyendung: []
}

const vitrituyendungReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.GET_LIST_VI_TRI_TUYEN_DUNG:

            return {
                ...state,
                vitrituyendung: action?.vitrituyendung?.rows || [],
                msg: 'Ok' || '',
                count: action?.vitrituyendung?.count || 0
            }
        case actionTypes.POST_NEW_VI_TRI_TUYEN_DUNG:
        case actionTypes.PUT_EDIT_VI_TRI_TUYEN_DUNG:
        case actionTypes.DELETE_VI_TRI_TUYEN_DUNG:
            return {
                ...state,
                msg: action.msg || '',
                newvitrituyendung: action?.newvitrituyendung || []
            }
        default:
            return state;
    }

}

export default vitrituyendungReducer