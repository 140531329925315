import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputForm, Button } from '~/components/Form';
import * as actions from '~/store/actions';
import { Icon } from '~/components/Component';
import '../Modal.scss';
import { Modal, ModalBody } from 'reactstrap';
import Select from 'react-select';
import { validateBaoHiemNhanVien } from '~/request/validateBaoHiemNhanVien';
import { dataSelectBHXH } from '~/utils/Datacustom';
import { Input } from 'reactstrap';
import {apiEditBaohiemnhanvien, apiNewBaohiemnhanvien} from "~/services";
import {toast} from "react-toastify";

const ModalEditBaohiemnhanvien = ({ show, setShow, dataIdBaohiemnhanvien, fieldList }) => {
    // console.log('dataSelectBHXH', dataSelectBHXH)
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayloadBaohiemnhanvien] = useState(fieldList);
    const [listTrangthai, setlistTrangthai] = useState([]);
    useEffect(() => {
        let tinhtrangBHXH = dataSelectBHXH.map((item) => ({ label: item.label, value: item.value }));
        setlistTrangthai(tinhtrangBHXH);
        let TrangthaiId = dataSelectBHXH.find((items) => {
            return items && items.value === dataIdBaohiemnhanvien?.trangthainopsobh;
        });
        show &&
            dataIdBaohiemnhanvien &&
            setPayloadBaohiemnhanvien({
                id: dataIdBaohiemnhanvien?.id,
                NhanvienId: { label: dataIdBaohiemnhanvien?.Nhanvien.hoten, value: dataIdBaohiemnhanvien?.Nhanvien.id },
                sothebhyt: dataIdBaohiemnhanvien?.sothebhyt,
                ngaynopsobh: dataIdBaohiemnhanvien?.ngaynopsobh,
                nguoinhansobh: dataIdBaohiemnhanvien?.nguoinhansobh,
                sogiaykhaisinh: dataIdBaohiemnhanvien?.sogiaykhaisinh,
                trangthainopsobh: { label: TrangthaiId?.label, value: TrangthaiId?.value },
                lichkhamchuabenh: dataIdBaohiemnhanvien?.lichkhamchuabenh,
                chedothaisan: dataIdBaohiemnhanvien?.chedothaisan,
                noidkbh: dataIdBaohiemnhanvien?.noidkbh,
                ngaythamgiabh: dataIdBaohiemnhanvien?.ngaythamgiabh,
                mucluongdongbh: dataIdBaohiemnhanvien?.mucluongdongbh,
                phicongdoan: dataIdBaohiemnhanvien?.phicongdoan,
                phicongdoanctydong: dataIdBaohiemnhanvien?.phicongdoanctydong,
                noicapgiayks: dataIdBaohiemnhanvien?.noicapgiayks,
                ghichu: dataIdBaohiemnhanvien?.ghichu,
            });
    }, [dataIdBaohiemnhanvien]);

    const handleSubmit = async () => {
        let invalids = validate(payload);
        // console.log('payload payload', payload)
        if (invalids === 0) {
            let datapayload = {
                id: dataIdBaohiemnhanvien?.id,
                NhanvienId: payload?.NhanvienId.value,
                sothebhyt: payload?.sothebhyt,
                // ngaynopsobh: payload?.ngaynopsobh,
                // nguoinhansobh: payload?.nguoinhansobh,
                // sogiaykhaisinh: payload?.sogiaykhaisinh,
                trangthainopsobh: payload?.trangthainopsobh?.value,
                // lichkhamchuabenh: payload?.lichkhamchuabenh,
                // chedothaisan: payload?.chedothaisan,
                // noidkbh: payload?.noidkbh,
                ngaythamgiabh: payload?.ngaythamgiabh,
                phicongdoan: payload?.phicongdoan,
                phicongdoanctydong: payload?.phicongdoanctydong,
                // noicapgiayks: payload?.noicapgiayks,
                // bhtngcongtydong: payload?.bhtngcongtydong,
                // bhxhcongtydong: payload?.bhxhcongtydong,
                // bhytcongtydong: payload?.bhytcongtydong,
                // bhytnglddong: payload?.bhytnglddong,
                // bhxhnglddong: payload?.bhxhnglddong,
                // bhtngnglddong: payload?.bhtngnglddong,
                ghichu: payload?.ghichu,
            };
            const response = await apiEditBaohiemnhanvien(datapayload);
            // console.log('response',response)
            if (response?.data.success === 200) {
                toast.success("Sửa thông tin bảo hiểm nhân viên thành công!")
                setShow(false);
            } else {
                toast.error("Sửa thông tin bảo hiểm nhân viên thất bại!")
            }
        }
    };
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayloadBaohiemnhanvien((prev) => ({ ...prev, [statename]: selected }));
    };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateBaoHiemNhanVien(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateBaoHiemNhanVien(item[0], item[1])]);
                invalids++;
            }
        });
        return invalids;
    };
    return (
        <React.Fragment>
            <Modal isOpen={show} className="modal-dialog-centered modal-90w" size="lg" toggle={setShow}>
                <ModalBody>
                    <Icon name="cross-sm float-end fs-2" onClick={setShow}></Icon>
                    <h5 className="title mb-3">Thông tin bảo hiểm nhân viên</h5>
                    <div className="row">
                        <div className="form-group col-md-3">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                Nhân Viên <b className="text-danger">(*)</b>
                            </label>
                            <Select
                                value={payload.NhanvienId}
                                name="NhanvienId"
                                onFocus={() => setInvalidFields([])}
                                isDisabled={true}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'NhanvienId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'NhanvienId')?.message}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-3">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Sổ BHXH'}
                                value={payload.sothebhyt}
                                setValue={setPayloadBaohiemnhanvien}
                                keyPayload={'sothebhyt'}
                                placeholder={'Tên tài sản'}
                                type="number"
                                validate
                            />
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                <span>
                                    Áp dụng
                                </span>
                            </label>
                            <Select
                                value={payload.trangthainopsobh}
                                onChange={handleOnSelect}
                                options={listTrangthai}
                                name="trangthainopsobh"
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'trangthainopsobh') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'trangthainopsobh')?.message}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-3">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Mức Lương Đóng BH'}
                                value={payload.mucluongdongbh}
                                setValue={setPayloadBaohiemnhanvien}
                                keyPayload={'mucluongdongbh'}
                                disabled="true"
                                placeholder={'Mức Lương Đóng BH'}
                                type="number"
                                validate
                            />
                        </div>
                        <div className="form-group col-md-3">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Ngày Tham Gia BH'}
                                value={payload.ngaythamgiabh}
                                setValue={setPayloadBaohiemnhanvien}
                                keyPayload={'ngaythamgiabh'}
                                placeholder={'Ngày Tham Gia BH'}
                                type="date"
                                validate
                            />
                        </div>
                        <div className="form-group col-md-3">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Người Nhận Sổ BH'}
                                value={payload.nguoinhansobh}
                                setValue={setPayloadBaohiemnhanvien}
                                keyPayload={'nguoinhansobh'}
                                placeholder={'Người Nhận Sổ BH'}
                                type="text"
                                validate
                            />
                        </div>
                        
                        <div className="form-group col-md-3">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Phí Công Đoàn NV(%)'}
                                value={payload.phicongdoan}
                                setValue={setPayloadBaohiemnhanvien}
                                keyPayload={'phicongdoan'}
                                placeholder={'Phí Công Đoàn NV'}
                                type="number"
                                validate
                            />
                        </div>
                        <div className="form-group col-md-3">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Phí Công Đoàn Công Ty(%)'}
                                value={payload.phicongdoanctydong}
                                setValue={setPayloadBaohiemnhanvien}
                                keyPayload={'phicongdoanctydong'}
                                placeholder={'Phí Công Đoàn Công Ty'}
                                type="number"
                                validate
                            />
                        </div>
                        <div className="form-group col-md-12 mb-2">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                <span>
                                    Ghi chú
                                </span>
                            </label>
                            <Input
                                value={payload.ghichu}
                                onChange={(e) =>
                                    setPayloadBaohiemnhanvien((prev) => ({ ...prev, ['ghichu']: e.target.value }))
                                }
                                rows={'2'}
                                name="ghichu"
                                type="textarea"
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'ghichu') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'ghichu')?.message}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className="btn-group my-2">
                        <button className="btn btn-primary px-5" onClick={handleSubmit}>
                            Cập nhật
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default ModalEditBaohiemnhanvien;
