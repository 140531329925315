import axiosConfig from '../axiosConfig'
import { toast } from "react-toastify";
export const apiGetDexuat = () => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlyhethong/dexuat`,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiNewDexuat = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlyhethong/dexuat',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
        toast.error(error.response.data.mes)
    }
})
export const apiEditDexuat = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlyhethong/dexuat',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
        toast.error(error.response.data.mes)
    }
})
export const apiDeleteDexuat = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlyhethong/dexuat',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
        toast.error(error.response.data.mes)
    }
})
export const apiNewLoaiDexuat = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: `/api/v2/quanlyhethong/kieudexuat/dexuat`,
            data: payload
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
export const apiGetLoaiDexuat = () => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlyhethong/kieudexuat/dexuat`,
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
export const apiEditLoaiDexuat = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlyhethong/kieudexuat/dexuat',
            data: payload
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
        toast.error(error.response.data.mes)
    }
})
export const apiDeleteLoaiDexuat = (payload) => new Promise(async (resolve, reject) => {
    // console.log('payload',payload)
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlyhethong/kieudexuat/dexuat',
            data: payload
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
        toast.error(error.response.data.mes)
    }
})