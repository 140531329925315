import actionTypes from "../actions/actionTypes";
const initState = {
    chucvu: [],
    msg: '',
    count: 0,
    newchucvu: []
}

const chucvuReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.GET_LIST_CHUC_VU:
            return {
                ...state,
                chucvu: action?.chucvu?.rows || [],
                msg: 'Ok' || '',
                count: action?.chucvu?.count || 0
            }
        case actionTypes.POST_NEW_CHUC_VU:
        case actionTypes.PUT_EDIT_CHUC_VU:
        case actionTypes.DELETE_CHUC_VU:
            return {
                ...state,
                msg: action.msg || '',
                newchucvu: action?.newchucvu || []
            }
        default:
            return state;
    }

}

export default chucvuReducer