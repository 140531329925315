import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Column } from '@ant-design/plots';

import { each, groupBy } from '@antv/util';
import {chartColumn} from "~/components/partials/charts/analytics/AnalyticsData";

const ColumnChart = (dataChart) => {
    const [data, setData] = useState([]);
    useEffect(() => {
        if (dataChart?.columnChart?.length > 0) {
            setData(dataChart.columnChart)
        }
        
    }, [dataChart.columnChart]);
    // const annotations = [];
    // each(groupBy(data, 'year'), (values, k) => {
    //     const value = values.reduce((a, b) => a + b.value, 0);
    //     annotations.push({
    //         type: 'text',
    //         position: [k, value],
    //         content: `${value}`,
    //         style: {
    //             textAlign: 'center',
    //             fontSize: 14,
    //             fill: 'rgba(0,0,0,0.85)',
    //         },
    //         offsetY: -10,
    //     });
    // });
    const config = {
        data,
        isStack: true,
        xField: 'year',
        yField: 'value',
        seriesField: 'type',
        legend: {
            position: 'top-left',
            paddingBottom: 100,
            paddingTop: 100,
        },
        // columnStyle: {
        //     radius: [20, 20, 0, 0],
        // },
        // label: {
        //     position: 'middle',
        //
        //     layout: [
        //         {
        //             type: 'interval-adjust-position',
        //         },
        //         {
        //             type: 'interval-hide-overlap',
        //         },
        //         {
        //             type: 'adjust-color',
        //         },
        //     ],
        // },
        // annotations,
    };
    
    return <Column {...config} />;
};

export default ColumnChart;
