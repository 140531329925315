import actionTypes from "../actions/actionTypes";
const initState = {
    quydinhthuongphat: [],
    msg: '',
    count: 0,
    newquydinhthuongphat: []
}

const quydinhthuongphatReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.GET_LIST_QUY_DINH_THUONG_PHAT:

            return {
                ...state,
                quydinhthuongphat: action?.quydinhthuongphat?.rows || [],
                msg: 'Ok' || '',
                count: action?.quydinhthuongphat?.count || 0
            }
        case actionTypes.POST_NEW_QUY_DINH_THUONG_PHAT:
        case actionTypes.PUT_EDIT_QUY_DINH_THUONG_PHAT:
        case actionTypes.DELETE_QUY_DINH_THUONG_PHAT:
            return {
                ...state,
                msg: action.msg || '',
                newquydinhthuongphat: action?.newquydinhthuongphat || []
            }
        default:
            return state;
    }

}

export default quydinhthuongphatReducer