import actionTypes from "../actions/actionTypes";
const initState = {
    kieuluong: [],
    msg: '',
    count: 0,
    newkieuluong: []
}

const kieuluongReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.GET_LIST_KIEU_LUONG:

            return {
                ...state,
                kieuluong: action?.kieuluong?.rows || [],
                msg: 'Ok' || '',
                count: action?.kieuluong?.count || 0
            }
        case actionTypes.POST_NEW_KIEU_LUONG:
        case actionTypes.PUT_EDIT_KIEU_LUONG:
        case actionTypes.DELETE_KIEU_LUONG:
            return {
                ...state,
                msg: action.msg || '',
                newkieuluong: action?.newkieuluong || []
            }
        default:
            return state;
    }

}

export default kieuluongReducer