export const validateLoaiHopDong = (fieldName, value) => {
    let regNumber = /^[0-9\b]+$/;
    switch (fieldName) {
        case 'tenloaihopdong':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Tên không được để trống'
                }
            }
            break;
        case 'motaloaihopdong':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Mô tả không được để trống'
                }
            }
            break;
        
        
        default:
            break;
    }
}