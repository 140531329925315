import actionTypes from "../actions/actionTypes";
const initState = {
    hopdonglaodong: [],
    loaihopdong: [],
    mauhopdong: [],
    msg: '',
    count: 0,
    newhopdonglaodong: [],
    newmauhopdong: [],
    newloaihopdong: []
}

const hopdonglaodongReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.GET_LIST_HOP_DONG_LAO_DONG:

            return {
                ...state,
                hopdonglaodong: action?.hopdonglaodong?.rows || [],
                msg: 'Ok' || '',
                count: action?.hopdonglaodong?.count || 0
            }
        case actionTypes.POST_NEW_HOP_DONG_LAO_DONG:
        case actionTypes.PUT_EDIT_HOP_DONG_LAO_DONG:
        case actionTypes.DELETE_HOP_DONG_LAO_DONG:
            return {
                ...state,
                msg: action.msg || '',
                newhopdonglaodong: action?.newhopdonglaodong || []
            }
        // Loại hợp đồng
        case actionTypes.GET_LIST_LOAI_HOP_DONG:

            return {
                ...state,
                loaihopdong: action?.loaihopdong?.rows || [],
            }
        case actionTypes.POST_NEW_LOAI_HOP_DONG:
        case actionTypes.PUT_EDIT_LOAI_HOP_DONG:
        case actionTypes.DELETE_LOAI_HOP_DONG:
            return {
                ...state,
                newloaihopdong: action?.newloaihopdong || []
            }
        // mẫu hợp đồng
        case actionTypes.GET_LIST_MAU_HOP_DONG:

            return {
                ...state,
                mauhopdong: action?.mauhopdong?.rows || [],
            }
        case actionTypes.POST_NEW_MAU_HOP_DONG:
        case actionTypes.PUT_EDIT_MAU_HOP_DONG:
        case actionTypes.DELETE_MAU_HOP_DONG:
            return {
                ...state,
                newmauhopdong: action?.newmauhopdong || []
            }
        default:
            return state;
    }

}

export default hopdonglaodongReducer