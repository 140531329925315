import React, {useState, useEffect} from 'react'
import {InputForm, Button} from '~/components/Form'
import {validateCauHinhChuyenCan} from "~/request/validateCauHinhChuyenCan";
import '../Modal.scss';
import Select from 'react-select';
import {apiNewCauhinhchuyencan} from "~/services";
import {toast} from "react-toastify";
import {dataSelectActive, dataSelectConfirm} from "~/utils/Datacustom";
import Textarea from "~/components/Form/Textarea";
import Content from "~/layout/content/Content";
import {Modal, ModalBody} from 'reactstrap';
import Icon from "~/components/icon/Icon";
import {SelectFinNhanvien} from "~/components/Component";
import Swal from "sweetalert2";

const ModalChuyenCan = ({show, setShow, fieldList}) => {
    const [invalidFields, setInvalidFields] = useState([])
    const [payload, setPayload] = useState(fieldList)
    const [showSelect, setShowSelect] = useState(false);
    const [listNhanvien, setlistNhanvien] = useState([]);
    const [usedWorked, setUsedWorked] = useState(false);
    useEffect(() => {
        show && setPayload(fieldList)
    }, [show])
    useEffect(() => {
        setPayload((prev) => ({
            ...prev,
            ['NhanvienId']: listNhanvien,
        }));
    }, [listNhanvien]);
    function generateChecklist(check) {
        let checklist = '';
        
        for (let i = 1; i <= check; i++) {
            checklist += `xx${'.'.repeat(1)}`;
        }
        if (checklist.endsWith('.')) {
            checklist = checklist.slice(0, -1);
        }
        return checklist;
    }
    const handleSubmit = async () => {
        const totalWorkDay = Number(payload?.totalWorkDay)
        const totalDayCalcu = Number(payload?.totalDayCalcu)
        const calculatorCondition = payload?.calculatorCondition
        const calcuConditionCheck = calculatorCondition.split('.')
        console.log('Calcu condition',calcuConditionCheck)
        if (payload?.is_Work?.value === 0) {
            if(calcuConditionCheck[totalDayCalcu - totalWorkDay] === undefined) {
                // console.log('Calcu condition',calcuConditionCheck[totalDayCalcu - totalWorkDay])
                const checkList = (totalDayCalcu-totalWorkDay) || 0
                return await Swal.fire({
                    title: 'Kiểm tra lại điều kiện tính toán chuyên cần '+generateChecklist(checkList)+' với xx là các gi trị giảm theo ngày nghỉ',
                    text: 'Số ngày tối đa =< tối thiểu',
                    icon: 'danger',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Đóng !!!',
                    confirmButtonText: 'Tôi xem lại!',
                })
            }
            
        }
        
        
        let invalids = validate(payload)
        
        if (invalids === 0) {
            let datapayload = {
                data: [
                    {
                        name: payload.name,
                        description: payload.description,
                        totalDayCalcu: payload.totalDayCalcu,
                        calculatorCondition: payload.calculatorCondition,
                        totalWorkDay: payload.totalWorkDay,
                        status: payload.status.value,
                        is_Work: payload.is_Work.value,
                        is_Vacation: payload.is_Vacation.value,
                        is_Holiday: payload.is_Holiday.value,
                        is_Law: payload.is_Law.value,
                        is_Unpaid: payload.is_Unpaid.value,
                        NhanvienId: listNhanvien,
                    }
                ]
            }
            // console.log('check payload', datapayload)
            const data = await apiNewCauhinhchuyencan(datapayload)
            if (data.data.success === 200) {
                toast.success(data.data.message);
                setShow(false)
            } else {
                toast.error("Cập nhật thất bại!");
            }
        }
    }
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        if (statename === 'is_Work' && selected.value === 1) { //Ap dụng
            setUsedWorked(true);
        } else {
            setUsedWorked(false);
        }
       
        setPayload(prev => ({...prev, [statename]: selected}))
    }
    
    const validate = (payload) => {
        let invalids = 0
        let fields = Object.entries(payload)
        fields.forEach(item => {
            if (validateCauHinhChuyenCan(item[0], item[1]) !== undefined) {
                setInvalidFields(prev => [...prev, validateCauHinhChuyenCan(item[0], item[1])])
                invalids++
            }
        })
        return invalids
    }
    return (
        <React.Fragment>
            <Content>
                <Modal isOpen={show} toggle={setShow} className="modal-dialog-centered modal-90w">
                    <ModalBody>
                        <Icon name="cross-sm float-end fs-2" onClick={setShow}></Icon>
                        <h5 className="title mb-5"> Thêm mới cấu hình chuyên cần</h5>
                        <div className="row">
                            <div className="form-group col-md-12">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Tên cấu hình'}
                                    value={payload.name}
                                    setValue={setPayload}
                                    keyPayload={'name'}
                                    type='text'
                                    validate
                                />
                            </div>
                            <div className="form-group col-md-12 mb-2">
                                <Textarea
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Mô tả'}
                                    value={payload.description}
                                    setValue={setPayload}
                                    keyPayload={'description'}
                                    type="text"
                                    validate
                                />
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="inputZip" className="mb-2 fw-bold">Trạng thái<b
                                    className="text-danger">(*)</b></label>
                                <Select
                                    value={payload.status}
                                    onChange={handleOnSelect}
                                    options={dataSelectActive}
                                    placeholder={'Trạng thái'}
                                    name='status'
                                    onFocus={() => setInvalidFields([])}
                                />
                                {invalidFields.length > 0 && invalidFields.some(i => i.name === 'status') && <small
                                    className='text-danger'>{invalidFields.find(i => i.name === 'status')?.message}</small>}
                            </div>
                            
                            <div className="form-group col-md-4">
                                <label htmlFor="inputZip" className="mb-2 fw-bold">Đi làm đủ 100%<b
                                    className="text-danger">(*)</b></label>
                                <Select
                                    value={payload.is_Work}
                                    onChange={handleOnSelect}
                                    options={dataSelectConfirm}
                                    placeholder={'Không áp dụng'}
                                    name='is_Work'
                                    onFocus={() => setInvalidFields([])}
                                />
                                {invalidFields.length > 0 && invalidFields.some(i => i.name === 'is_Work') && <small
                                    className='text-danger'>{invalidFields.find(i => i.name === 'is_Work')?.message}</small>}
                            </div>
                            <div className="form-group col-md-4">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Số ngày công được hưởng 100% chuyên cần'}
                                    value={payload.totalDayCalcu}
                                    setValue={setPayload}
                                    keyPayload={'totalDayCalcu'}
                                    type='number'
                                    validate
                                />
                            </div>
                            <div className="form-group col-md-4">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Số ngày công không tính chuyên cần'}
                                    value={payload.totalWorkDay}
                                    setValue={setPayload}
                                    keyPayload={'totalWorkDay'}
                                    disabled={usedWorked}
                                    type='text'
                                />
                            </div>
                            <div className="form-group col-md-4">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Tỷ lệ tính chuyên cần'}
                                    placeholder={"VD: 100.50.0: Đủ: 100%, Nghỉ 1 buổi còn 50%, lớn hơn 2 buổi 0% chuyên cần"} value={payload.calculatorCondition}
                                    setValue={setPayload}
                                    keyPayload={'calculatorCondition'}
                                    disabled={usedWorked}
                                    type='text'
                                />
                            </div>
                            
                            <div className="form-group col-md-4">
                                <label htmlFor="inputZip" className="mb-2 fw-bold"> Có bao gồm nghỉ phép không ?</label>
                                <Select
                                    value={payload.is_Vacation}
                                    onChange={handleOnSelect}
                                    options={dataSelectConfirm}
                                    placeholder={'Không áp dụng'}
                                    name='is_Vacation'
                                    isDisabled={usedWorked}
                                    onFocus={() => setInvalidFields([])}
                                />
                                {invalidFields.length > 0 && invalidFields.some(i => i.name === 'is_Vacation') && <small
                                    className='text-danger'>{invalidFields.find(i => i.name === 'is_Vacation')?.message}</small>}
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="inputZip" className="mb-2 fw-bold"> Có bao gồm nghỉ lễ không ?</label>
                                <Select
                                    value={payload.is_Holiday}
                                    onChange={handleOnSelect}
                                    options={dataSelectConfirm}
                                    placeholder={'Không áp dụng'}
                                    name='is_Holiday'
                                    isDisabled={usedWorked}
                                    onFocus={() => setInvalidFields([])}
                                />
                                {invalidFields.length > 0 && invalidFields.some(i => i.name === 'is_Holiday') && <small
                                    className='text-danger'>{invalidFields.find(i => i.name === 'is_Holiday')?.message}</small>}
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="inputZip" className="mb-2 fw-bold"> Có bao gồm nghỉ theo luật không
                                    ?</label>
                                <Select
                                    value={payload.is_Law}
                                    onChange={handleOnSelect}
                                    options={dataSelectConfirm}
                                    placeholder={'Không áp dụng'}
                                    name='is_Law'
                                    isDisabled={usedWorked}
                                    onFocus={() => setInvalidFields([])}
                                />
                                {invalidFields.length > 0 && invalidFields.some(i => i.name === 'is_Law') && <small
                                    className='text-danger'>{invalidFields.find(i => i.name === 'is_Law')?.message}</small>}
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="inputZip" className="mb-2 fw-bold">Có bao gồm nghỉ không lương không
                                    ?</label>
                                <Select
                                    value={payload.is_Unpaid}
                                    onChange={handleOnSelect}
                                    options={dataSelectConfirm}
                                    placeholder={'Không áp dụng'}
                                    name='is_Unpaid'
                                    isDisabled={usedWorked}
                                    onFocus={() => setInvalidFields([])}
                                />
                                {invalidFields.length > 0 && invalidFields.some(i => i.name === 'is_Unpaid') && <small
                                    className='text-danger'>{invalidFields.find(i => i.name === 'is_Unpaid')?.message}</small>}
                            </div>
                            <div className="row">
                                <div className="form-group col-md-12" onClick={() => setShowSelect(true)}>
                                    <label htmlFor="inputZip" className="mb-2 fw-bold">
                                        Chọn Nhân Viên
                                    </label>
                                    <Select
                                        value={payload.NhanvienId}
                                        name="NhanvienId"
                                        isMulti
                                        onFocus={() => setInvalidFields([])}
                                    />
                                    {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'NhanvienId') && (
                                        <small className="text-danger">
                                            {invalidFields.find((i) => i.name === 'NhanvienId')?.message}
                                        </small>
                                    )}
                                </div>
                            </div>
                        </div>
                        
                        <div className='col-12 mt-5'>
                            <Button
                                text='Thêm mới'
                                bClass='btn btn-primary px-5'
                                textColor
                                onClick={handleSubmit}
                            />
                        </div>
                    </ModalBody>
                </Modal>
            </Content>
            <SelectFinNhanvien
                showSelect={showSelect}
                typeSelect={true}
                listNhanvien={listNhanvien}
                setlistNhanvien={setlistNhanvien}
                typeSetting={'chuyencan'} // 'chuyencan' || 'thamnien' || 'congthuc' || 'hoatdongsukien'
                setShowSelect={() => setShowSelect(false)}
            />
        
        
        </React.Fragment>
    );
}

export default ModalChuyenCan;

