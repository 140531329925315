import actionTypes from './actionTypes'
import { apiGetCauhinhpheduyet, apiNewCauhinhpheduyet, apiEditCauhinhpheduyet, apiDeleteCauhinhpheduyet } from '~/services/cauhinhpheduyet'
import { toast } from "react-toastify";
export const getListCauhinhpheduyet = (payload) => async (dispatch) => {
    try {
        const response = await apiGetCauhinhpheduyet(payload)
        // console.log('chek api lisst Cauhinhpheduyet', response?.data.success)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_CAU_HINH_PHE_DUYET,
                cauhinhpheduyet: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_CAU_HINH_PHE_DUYET,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_CAU_HINH_PHE_DUYET,
            cauhinhpheduyet: null
        })
    }
}

export const NewCauhinhpheduyet = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewCauhinhpheduyet', payload)
            const response = await apiNewCauhinhpheduyet(payload)
            // console.log('chek api apiNewCauhinhpheduyet', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_CAU_HINH_PHE_DUYET,
                    msg: 'OK'
                });
                dispatch(getListCauhinhpheduyet());
                toast.success("Thêm mới thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_CAU_HINH_PHE_DUYET,
                    msg: 'ERR'
                })
                toast.error("Thêm mới thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_CAU_HINH_PHE_DUYET,
                cauhinhpheduyet: null
            })
        }
    }
}
export const EditCauhinhpheduyet = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditCauhinhpheduyet(payload)
            // console.log('chek api apiEditCauhinhpheduyet', response)
            if (response?.data.success === 200) {
                dispatch(getListCauhinhpheduyet());
                toast.success("Sửa thông tin thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_CAU_HINH_PHE_DUYET,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_CAU_HINH_PHE_DUYET,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_CAU_HINH_PHE_DUYET,
                cauhinhpheduyet: null
            })
        }
    }
}
export const DeleteCauhinhpheduyet = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteCauhinhpheduyet(payload)
            // console.log('chek api apiDeleteCauhinhpheduyet', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListCauhinhpheduyet());
                toast.success("Xóa thông tin thành công!")
                dispatch({
                    type: actionTypes.DELETE_CAU_HINH_PHE_DUYET,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_CAU_HINH_PHE_DUYET,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_CAU_HINH_PHE_DUYET,
                cauhinhpheduyet: null
            })
        }
    }
}