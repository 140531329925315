import actionTypes from "../actions/actionTypes";
const initState = {
    tamungluong: [],
    msg: '',
    count: 0,
    newtamungluong: []
}

const tamungluongReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.GET_LIST_TAM_UNG_LUONG:
            return {
                ...state,
                tamungluong: action?.tamungluong?.rows || [],
                msg: 'Ok' || '',
                count: action?.tamungluong?.count || 0
            }
        case actionTypes.POST_NEW_TAM_UNG_LUONG:
        case actionTypes.PUT_EDIT_TAM_UNG_LUONG:
        case actionTypes.DELETE_TAM_UNG_LUONG:
            return {
                ...state,
                msg: action.msg || '',
                newtamungluong: action?.newtamungluong || []
            }
        default:
            return state;
    }

}

export default tamungluongReducer