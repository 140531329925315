import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { InputForm, Button } from '~/components/Form';
import * as actions from '~/store/actions';
import { validateCongTy } from '~/request/validateCongTy';
import '../Modal.scss';
import { format, parseISO } from 'date-fns';
import { dataSelectDanhGia } from '~/utils/Datacustom';
import { Modal, ModalBody } from 'reactstrap';
import { Icon } from '~/components/Component';
import Select from 'react-select';
import { Input } from 'reactstrap';
import {apiEditNhanvienkiemdinhatvsbhld, apiGetKiemdinhatvsbhld} from "~/services";
import {toast} from "react-toastify";
import actionTypes from "~/store/actions/actionTypes";
import {getListNhanvienkiemdinhatvsbhld} from "~/store/actions";

const ModalEditNhanvienkiemdinhatvsbhld = ({ show, setShow, dataIdNhanvienkiemdinhatvsbhld, fieldList }) => {
    // console.log('dataIdNhanvien', dataIdNhanvienkiemdinhatvsbhld)
    const dispatch = useDispatch();
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayload] = useState(fieldList);
    const [listTrangthai, setlistTrangthai] = useState([]);
    const [listKiemDinh, setLisKiemDinh] = useState([]);
    async function fetchDataKiemDinh() {
        try {
            const datapayloads = {
                typeGet: 2,
            };
            const response = await apiGetKiemdinhatvsbhld(datapayloads);
            // console.log('response', response)
            // const response = resData.data.data.rows;
            if (response?.data?.success === 200) {
                let KiemDinhId = response?.data?.data?.rows?.map((item) => ({ label: item.tenkiemdinh, value: item.id }));
                
                setLisKiemDinh(KiemDinhId);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    useEffect(() => {
        fetchDataKiemDinh().then(r => 'err');
    }, [dataIdNhanvienkiemdinhatvsbhld]);

    useEffect(() => {
        let TrangthaiId = dataSelectDanhGia.find((items) => {
            return items && items.value === dataIdNhanvienkiemdinhatvsbhld?.TrangthaiId;
        });
        let Kiemdinhatvsbhld = listKiemDinh?.find((items) => {
            return items && items.value === dataIdNhanvienkiemdinhatvsbhld?.KiemdinhatvsbhldId;
        });
        show &&
            dataIdNhanvienkiemdinhatvsbhld &&
            setPayload({
                id: dataIdNhanvienkiemdinhatvsbhld?.id,
                NhanvienId: {
                    label: dataIdNhanvienkiemdinhatvsbhld?.Nhanvien?.hoten,
                    value: dataIdNhanvienkiemdinhatvsbhld?.Nhanvien?.id,
                },
                KiemdinhatvsbhldId: Kiemdinhatvsbhld,
                chiphi: dataIdNhanvienkiemdinhatvsbhld?.chiphi,
                soluong: dataIdNhanvienkiemdinhatvsbhld?.soluong,
                dongia: dataIdNhanvienkiemdinhatvsbhld?.dongia,
                phancongthuchien: dataIdNhanvienkiemdinhatvsbhld?.NhanvienId?.label,
                ngaybatdau: dataIdNhanvienkiemdinhatvsbhld?.ngaybatdau,
                ngayketthuc: dataIdNhanvienkiemdinhatvsbhld?.ngayketthuc,
                TrangthaiId: { label: TrangthaiId?.label, value: TrangthaiId?.value },
                ghichu: dataIdNhanvienkiemdinhatvsbhld?.ghichu,
            });
    }, [dataIdNhanvienkiemdinhatvsbhld, show]);

    const handleSubmit = async () => {
        let invalids = validate(payload);
        if (invalids === 0) {
            let datapayload = {
                data: [
                    {
                        id: dataIdNhanvienkiemdinhatvsbhld?.id,
                        NhanvienId: payload?.NhanvienId?.value,
                        KiemdinhatvsbhldId: payload?.KiemdinhatvsbhldId?.value,
                        chiphi: payload?.chiphi,
                        soluong: payload?.soluong,
                        dongia: payload?.dongia,
                        phancongthuchien: payload?.NhanvienId?.label,
                        ngaybatdau: payload?.ngaybatdau,
                        ngayketthuc: payload?.ngayketthuc,
                        TrangthaiId: payload?.TrangthaiId?.value,
                        ghichu: payload?.ghichu,
                    },
                ],
            };
            // console.log('datapayload', datapayload);
            
            const response = await apiEditNhanvienkiemdinhatvsbhld(datapayload)
            // console.log('chek api apiEditNhanvienkiemdinhatvsbhld', response)
            if (response?.data.success === 200) {
                setShow(false);
                toast.success("Sửa thông tin thành công!")
            } else {
                toast.error("Sửa thông tin thất bại!")
            }
            
        }
    };
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayload((prev) => ({ ...prev, [statename]: selected }));
    };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateCongTy(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateCongTy(item[0], item[1])]);
                invalids++;
            }
        });
        return invalids;
    };
    return (
        <React.Fragment>
            <Modal isOpen={show} className="modal-dialog-centered modal-90w" size="lg" toggle={setShow}>
                <ModalBody>
                <Icon name="cross-sm float-end fs-2" onClick={setShow}></Icon>
                    <h5 className="title mb-3">Cập nhật nhân viên kiểm định</h5>
                    <div className="row">
                        <div className="form-group col-md-6" >
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                Chọn Nhân Viên <b className="text-danger">(*)</b>
                            </label>
                            <Select value={payload.NhanvienId} name="NhanvienId" onFocus={() => setInvalidFields([])} isDisabled={true}/>
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'NhanvienId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'NhanvienId')?.message}
                                </small>
                            )}
                        </div>

                        <div className="form-group col-md-6">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                Kiểm định <b className="text-danger">(*)</b>
                            </label>
                            <Select
                                value={payload.KiemdinhatvsbhldId}
                                onChange={handleOnSelect}
                                options={listKiemDinh}
                                placeholder={'Chọn kiểm định'}
                                name="KiemdinhatvsbhldId"
                                onFocus={() => setInvalidFields([])}
                            />

                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'KiemdinhatvsbhldId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'KiemdinhatvsbhldId')?.message}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                Trạng thái <b className="text-danger">(*)</b>
                            </label>
                            <Select
                                value={payload.TrangthaiId}
                                onChange={handleOnSelect}
                                options={listTrangthai}
                                placeholder={'Trạng thái'}
                                name="TrangthaiId"
                                onFocus={() => setInvalidFields([])}
                            />

                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'TrangthaiId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'TrangthaiId')?.message}
                                </small>
                            )}
                        </div>

                        <div className="form-group col-md-4">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Chi phí'}
                                value={payload.chiphi}
                                setValue={setPayload}
                                keyPayload={'chiphi'}
                                type="number"
                                validate
                            />
                        </div>
                        <div className="form-group col-md-4">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Số lượng'}
                                value={payload.soluong}
                                setValue={setPayload}
                                keyPayload={'soluong'}
                                type="number"
                                validate
                            />
                        </div>

                        <div className="form-group col-md-4">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Đơn giá'}
                                value={payload.dongia}
                                setValue={setPayload}
                                keyPayload={'dongia'}
                                type="number"
                                validate
                            />
                        </div>

                        <div className="form-group col-md-4">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Ngày bắt đầu'}
                                value={payload.ngaybatdau}
                                setValue={setPayload}
                                keyPayload={'ngaybatdau'}
                                type="date"
                                validate
                            />
                        </div>
                        <div className="form-group col-md-4">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Ngày kết thúc'}
                                value={payload.ngayketthuc}
                                setValue={setPayload}
                                keyPayload={'ngayketthuc'}
                                type="date"
                                validate
                            />
                        </div>
                        <div className="form-group col-md-12 mt-3 mb-2">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                <span>
                                    Ghi chú <b className="text-danger">(*)</b>
                                </span>
                            </label>
                            <Input
                                value={payload.ghichu}
                                onChange={(e) => setPayload((prev) => ({ ...prev, ['ghichu']: e.target.value }))}
                                rows={'2'}
                                name="ghichu"
                                type="textarea"
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'ghichu') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'ghichu')?.message}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className="col-12 mt-5">
                        <Button text="Cập nhật" bClass="btn btn-primary px-5" textColor onClick={handleSubmit} />
                    </div>
                </ModalBody>
            </Modal>
            
        </React.Fragment>
    );
};

export default ModalEditNhanvienkiemdinhatvsbhld;
