import React, {useState, useEffect, useMemo} from 'react';
import {
    Card,
} from 'reactstrap';
import Content from '~/layout/content/Content';
import {Block} from '~/components/block/Block';
import {
    Button,
    DataTable,
    Icon,
    PermissionUtils,
} from '~/components/Component';
import {MenuTangca} from './MenuTab/Menucontent';
import {Box} from "@mui/material";
import {ExportToCsv} from "export-to-csv";
import {MaterialReactTable, useMaterialReactTable} from "material-react-table";
import {MRT_Localization_VI} from "material-react-table/locales/vi";
import {toast} from "react-toastify";
import {dataPagination} from "~/utils/Datacustom";
import {apiGetNhanvienSetOvt} from "~/services";

const Cauhinhtinhluongtangca = () => {
    const [show, setShow] = useState(false);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const usStateList = [
        'Phòng Marketing',
        'Phòng kỹ thuật',
        'Xưởng Sản xuất 1',
        'Phòng kế hoạch',
    ]
    const [data, setData] = useState([]);
    const fetchData = async () => {
        try {
            
            const datapayloads = {
                typeGet: 1, // 1: lấy theo điều kiện phân trang, 2: lấy tất cả và không cần truyền d liệu phân trang
                page: pagination.pageIndex || 0,
                limit: pagination.pageSize || 10,
                by: sorting[0]?.id || 'id',
                order: sorting[0]?.desc ? 'desc' : 'asc',
            };
            
            const response = await apiGetNhanvienSetOvt(datapayloads)
            // console.log('response', response)
            if (response?.data?.success === 200) {
                setData(response?.data?.data?.rows);
                setTotalRowCount(response?.data?.data?.count);
            }
            
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    //lấy giá trị từ thằng redux trả về
    useEffect(() => {
        console.log('response')
        fetchData().then(r => r)
    }, [show, pagination.pageSize, pagination.pageIndex, sorting]);
    
    
    const [showPhanquyen, setShowPhanquyen] = useState({});
    let dataPermissionUtils = PermissionUtils('cauhinhtangca', 'all');
    useEffect(() => {
        if (dataPermissionUtils) {
            setShowPhanquyen(dataPermissionUtils);
        }
    }, [dataPermissionUtils]);
    
    const Example = () => {
        
        let dataAb = data;
        // Tạo dữ liêu export
        // const dataExport = dataAb
        const dataExport = dataAb?.map((item, index) => ({
            rowNumber: index + 1,
            hoten: item?.hoten,
            uuid: item?.uuid,
            PhongbanId: item?.Phongban?.phongban,
            ChucvuId: item?.Chucvu?.chucvu,
            calc_overtime: item?.calc_overtime === 0 ? 'Tính theo phần trăm' : 'Tính theo số tiền quy định',
        }));
        
        const columns = useMemo(
            () => [
                {
                    Header: '#', // Tiêu đề cột số thứ tự
                    header: 'STT', // Tiêu đề cột số thứ tự
                    id: 'rowNumber', // ID của cột
                    size: 50,
                    Cell: ({row}) => <div>{row?.index + 1}</div>, // Giá trị của cột là số thứ tự
                    enableSorting: false,
                    enablePinning: true,
                },
                {
                    accessorKey: 'uuid',
                    enablePinning: true,
                    header: 'Mã nhân viên',
                },
                {
                    accessorKey: 'hoten',
                    enablePinning: true,
                    header: 'Họ tên nhân viên',
                },
                {
                    accessorKey: 'Phongban.phongban',
                    enablePinning: true,
                    header: 'Phòng ban',
                },
                {
                    accessorKey: 'Chucvu.chucvu',
                    enablePinning: true,
                    header: 'Chức vụ',
                },
                {
                    accessorKey: 'calc_overtime',
                    enablePinning: true,
                    header: 'Loại tính lương tăng ca',
                    Cell: ({cell}) => (
                        <Box
                            component="span"
                            sx={(theme) => ({
                                backgroundColor: cell?.getValue() === 0 ? '#1ee0ac' : '#0073e6',
                                fontSize: '10px',
                                borderRadius: '0.25rem',
                                color: '#fff',
                                p: '0.25rem',
                            })}
                        >
                            {cell?.getValue() === 0 ? 'Tính theo phần trăm' : 'Tính theo số tiền quy định'}
                        </Box>
                    ),
                },
            
            ],
            [],
        );
        const csvOptions = {
            fileName: 'csv-data',
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            useBom: true,
            useKeysAsHeaders: false,
            headers: columns.map((c) => c.header),
        };
        
        
        const csvExporter = new ExportToCsv(csvOptions);
        
        const handleExportData = () => {
            if (dataExport.length === 0) {
                toast.error("Không có dữ liệu để xuất!")
                return;
            }
            csvExporter.generateCsv(dataExport);
        };
        const topToolbarCustomActions = ({table}) => (
            <Box sx={{display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap'}}>
                <Button
                    color="primary"
                    onClick={handleExportData}
                    variant="contained"
                >
                    <Icon name="download-cloud me-2"></Icon> Xuất dữ liệu
                </Button>
            </Box>
        )
        const table = useMaterialReactTable({
                columns,
                data,
                enableColumnResizing: false,
                enableDensityToggle: false,
                initialState: {
                    columnPinning: {left: ['rowNumber', 'uuid', 'hoten'], right: ['mrt-row-actions']},
                    density: 'compact'
                },
                
                state: {
                    expanded: true,
                    isLoading: dataAb.length === 0,
                    pagination,
                    sorting,
                },
                defaultDisplayColumn: {enableResizing: true},
                rowCount: totalRowCount,
                
                manualPagination: true,
                onPaginationChange: setPagination,
                manualSorting: true,
                onSortingChange: setSorting,
                
                
                localization: MRT_Localization_VI,
                enableColumnPinning: true,
                renderTopToolbarCustomActions: topToolbarCustomActions,
                muiPaginationProps: {
                    rowsPerPageOptions: dataPagination,
                },
                
            }
        );
        
        
        return (
            <MaterialReactTable
                table={table}
            />
        );
    };
    return (
        <React.Fragment>
            {/*<Head title="Cấu hình ca làm việc, Cấu hình tăng ca"></Head>*/}
            <Content>
                <Card className="mt-3">
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner card-inner-lg px-4">
                            <h4>Danh sách nhân viên theo loại tăng ca</h4>
                            <MenuTangca/>
                        </div>
                    </DataTable>
                </Card>
                
                <Block>
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner position-relative card-tools-toggle">
                            <Example/>
                        </div>
                    </DataTable>
                
                </Block>
            </Content>
        </React.Fragment>
    );
};

export default Cauhinhtinhluongtangca;
