import * as excelJs from "exceljs";

const ExportSalaryXlsx = ({namefile, headers, dataGr, footerData, miniForm, typeLuong}) => {
    const headerTmp = [headers[2], headers[3], headers[4]];
    return async () => {
        const workbook = new excelJs.Workbook();
        const ws = workbook.addWorksheet('Bảng lương XXX');
        const dataTmpKeys = Object.keys(dataGr[0].data[0]);
        const font10Bold = {
            size: 10, // Set font size to 14bg-success
            name: 'Times New Roman',
            bold: true,
            alignment: {
                horizontal: 'center',
                vertical: 'middle',
                wrapText: true
            },
        };
        const fills = {
            yellowDarkVertical: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'FFFF00'}, // Yellow background
                font30Bold: {
                    size: 30, // Set font size to 20
                    name: 'Times New Roman',
                    bold: true,
                    alignment: {
                        horizontal: 'center',
                        vertical: 'middle',
                        wrapText: true
                    },
                },
            },
            blueDarkVertical: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: '1ee0ac'}, // Blue background
                font30Bold: {
                    size: 30, // Set font size to 20
                    name: 'Times New Roman',
                    bold: true,
                    alignment: {
                        horizontal: 'center',
                        vertical: 'middle',
                        wrapText: true
                    },
                },
            },
            redDarkVertical: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'ff2d55'} // red background
            },
            
        };
        
        
        const cellFormatting = {
            font: {
                size: 8,
                name: 'Times New Roman',
            },
            border: {
                top: {style: 'thin', color: {argb: '111111'}},
                left: {style: 'thin', color: {argb: '111111'}},
                bottom: {style: 'thin', color: {argb: '111111'}},
                right: {style: 'thin', color: {argb: '111111'}}
                
            },
            alignment: {
                horizontal: 'center',
                vertical: 'middle',
                wrapText: true,
            },
        };
        
        ws.pageSetup.paperSize = 9;
        ws.pageSetup.orientation = 'landscape';
        ws.pageSetup.margins = {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            header: 0.25,
            footer: 0
        };
        ws.pageSetup.fitToPage = true;
        ws.pageSetup.fitToWidth = 1;
        
        function addHeaders(ws, headerTmp, font10Bold, startRow) {
            headerTmp.forEach((headerRow, rowIndex) => {
                let columnIndex = 1;
                let rowI = startRow + rowIndex; // Sử dụng startRow để chỉ định vị trí bắt đầu
                headerRow.forEach((header) => {
                    
                    const cell = ws.getCell(rowI, columnIndex);
                    ws.getRow(rowI).height = 40;
                    ws.getRow(rowI).alignment = {wrapText: true};
                    cell.value = header.value;
                    const range = {
                        top: rowI,
                        left: columnIndex,
                        bottom: rowI + header.rowspan - 1,
                        right: columnIndex + header.colspan - 1
                    };
                    if (!ws.getCell(range.top, range.left).isMerged) {
                        ws.mergeCells(range);
                    }
                    cell.font = font10Bold;
                    columnIndex += header.colspan;
                });
            });
            ws.eachRow((row) => {
                row.eachCell((cell) => {
                    Object.assign(cell, cellFormatting);
                });
            });
        }
        
        let rowsStart = 1;
        dataGr.forEach((items) => {
            const dataTmp = items.data; // Data of the data group
            const dataTitle = items.titlePhongban ?? ''; // Title of the data group
            const cell = ws.getCell(rowsStart, 1);
            cell.value = dataTitle;
            cell.fill = fills.yellowDarkVertical;
            cell.font = font10Bold;
            cell.alignment = font10Bold.alignment
            const range = {top: rowsStart, left: 1, bottom: rowsStart, right: dataTmpKeys.length};
            if (!ws.getCell(range.top, range.left).isMerged) {
                ws.mergeCells(range);
            }
            rowsStart++;
            dataTmp.forEach((item) => {
                addHeaders(ws, headerTmp, font10Bold, rowsStart);
                rowsStart = rowsStart + 3;
                let row = ws.getRow(rowsStart);
                dataTmpKeys.forEach((key, keyIndex) => {
                    let value = isNaN(Number(item[key])) ? item[key] : Number(item[key])
                    row.getCell(keyIndex + 1).value = value || 0;
                    row.getCell(keyIndex + 1).border = {
                        top: {style: 'thin', color: {argb: '111111'}},
                        left: {style: 'thin', color: {argb: '111111'}},
                        bottom: {style: 'thick', color: {argb: '111111'}},
                        right: {style: 'thin', color: {argb: '111111'}}
                    };
                    row.getCell(keyIndex + 1).numFmt = '#,##0';
                });
                rowsStart++;
            });
        });
        
        const excelBlob = await workbook.xlsx.writeBuffer();
        const excelUrl = URL.createObjectURL(
            new Blob([excelBlob], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
        );
        
        const link = document.createElement('a');
        link.href = excelUrl;
        link.download = `${namefile}.xlsx`;
        document.body.appendChild(link);
        link.click();
        
        URL.revokeObjectURL(excelUrl);
        document.body.removeChild(link);
    }
};
export default ExportSalaryXlsx;