import React, {useContext, useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Icon from '../icon/Icon';
import Button from '../button/Button';
import * as XLSX from 'xlsx';
import {Modal, ModalBody} from 'reactstrap';
import Dropzone from 'react-dropzone';
import {toast} from 'react-toastify';
import {Spinner} from 'reactstrap';
import {bytesToMegaBytes, currentTime, getDateStructured} from '~/utils/Utils';
import {FileAllCodeContext} from '~/pages/Admin/user-manage/FileAllCodeContext';
import {ExportXlsx} from "~/components/Component";
import ExportToExcel from "~/components/xlsx/ExportToExcel";
import ExportDulieuToExcel from "~/components/xlsx/ExportDulieuToExcel";
import * as actions from "~/store/actions";
import {importDataChamCong, apiDulieuchamcong} from '~/services';
import {format, parseISO} from "date-fns";

const ImportXlsx = ({type, fieldList, dataFileMau, title, namefile, btnname, dataExport, setMsg}) => {
    const [fileData, setFileData] = useState(null);
    const [files, setFiles] = useState([]);
    const [spinner, setSpinner] = useState(false);
    // const {createFileHopdong, deletemes} = useContext(FileAllCodeContext);
    useEffect(() => {
        dispatch(actions.getDonvihanhchinh());
    }, []);
    const dispatch = useDispatch();
    const {donvihanhchinh} = useSelector((state) => state.app);
    // console.log('donvihanhchinh', donvihanhchinh)
    // useEffect(() => {
    //     msg === 200 && deletemes();
    //     // setUploadModal(false)
    //     setFiles([]);
    //     setSpinner(false);
    //     setMsg(null);
    // }, [msg]);
    const handleDropChange = (acceptedFiles) => {
        setFiles(acceptedFiles);
        const file = acceptedFiles[0];
        exportImport(file);
        setSpinner(false);
    };
    const exportImport = async (file) => {
        const ab = await file.arrayBuffer();
        const workbook = XLSX.read(ab, {type: 'array', cellText: false, cellDates: true});
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const sheetData = XLSX.utils.sheet_to_json(worksheet, {header: 1, raw: false, dateNF: 'yyyy-mm-dd'});
        setFileData(sheetData);
    };
    
    const removeFromList = (name) => {
        let defaultFiles = files;
        defaultFiles = defaultFiles.filter((item) => item.name !== name);
        setFiles([...defaultFiles]);
        setFileData(null);
    };
    const [uploadModal, setUploadModal] = useState(false);
    const [logPost, setLogPost] = useState([]);

    const addFilesToSystem = async () => {
        const checkAllPropertiesFilled = (arr) => {
            for (let i = 0; i < arr.length; i++) {
                const obj = arr[i];
                for (const prop in obj) {
                    if (obj[prop] === '' || obj[prop] === null || obj[prop] === undefined) {
                        return false;
                    }
                }
            }
            return true;
        };
        
        if (type === 'dulieuchamcong') {
           
            try {
               
                const data = fileData
                    .filter(arr => arr.length > 0)
                    .slice(1)
                    .map(item => {
                        // console.log('item', item);
                        let [ThongtinchamcongId, time, day, nguon] = item;
                        const answer_array = day.split('/');
                        //   console.log('answer_array', answer_array);
                        
                        const date = parseInt(answer_array[0], 10);
                        const month = parseInt(answer_array[1], 10) - 1; // Months are 0-based
                        const year = parseInt(answer_array[2], 10);
                        // Construct the date using the Date constructor
                        const dateObject = new Date(year, month, date);
                       
                        // Format the date using your preferred format ('yyyy-MM-dd' in this case)
                        day = format(dateObject, 'yyyy-MM-dd');
                        
                        return {
                            ThongtinchamcongId,
                            time,
                            day,
                            nguon
                        };
                    });
              
                const allPropertiesFilled = checkAllPropertiesFilled(data);
                
                if (allPropertiesFilled) {
                    const datapayload = {
                        data: data.filter((item) => item !== null),
                    };
                    const resImport = await importDataChamCong(datapayload);
                    // console.log('resImport', resImport)
                    if (resImport?.data?.success === 200) {
                        toast.success('Thêm dữ liệu thành công!');
                        setUploadModal(false);
                        setMsg(uploadModal);
                    }
                    // console.log('resImport', datapayload)
                    
                } else {
                    toast.error('File dữ liệu có trường bị trống vui lòng nhập lại!');
                }
            } catch {
                toast.error('File dữ liệu có trường không đúng định dạng!');
            }
        }
        if (type === 'hopdonglaodong') {
            // const data = fileData.slice(1).map((item) => (
            //     {
            //         id: item[1],
            //         ChinhanhId: item[3],
            //         mahopdong: item[4],
            //         LoaihopdongId: item[5],
            //         ThuethunhapcanhanId: item[6],
            //         ngayhethan: item[9],
            //         motahopdong: item[13],
            //         ngaybatdaulamviec: item[12],
            //         quyettoanthaythue: item[16],
            //         diaiemlamviec: item[17]
            //     }
            // ));
            // const allPropertiesFilled = checkAllPropertiesFilled(data);
            // if (allPropertiesFilled === true) {
            //     createFileHopdong(data);
            //     setSpinner(true);
            // } else {
            //     toast.error('File dữ liệu có trường bị trống vui lòng nhập lại!');
            // }
            // console.log('fileData', fileData)
            const data = fileData.filter(arr => arr.length > 0).slice(1).map((item) => {
                const [Stt, Nhanvien, Uuid, Loaihopdong, Loaithuethunhap, Nguoiky, Mauhopdong, Ngaytaohopdong, Ngayhethan, Ngaybatdaulamviec, Mota, Diaiemlamviec, Trangthai
                ] = item;
                const dataNhanvien = dataExport?.nhanvien.find((iss) => iss.uuid === Uuid);
                const dataLoaihopdong = dataExport?.loaihopdong.find((si) => si.tenloaihopdong === Loaihopdong);
                const dataLoaithuethunhap = dataExport?.loaithuethunhap.find((is) => is.label === Loaithuethunhap);
                const dataNguoiky = dataExport?.nguoiky.find((is) => is.username === Nguoiky);
                // const dataQuyettoan = dataExport?.quyettoanthaythue.find((is) => is.label === Quyettoanthaythue);
                const dataMauhopdong = dataExport?.mauhopdong.find((is) => is.mota === Mauhopdong);
                const dataTrangthai = dataExport?.trangthai.find((is) => is.label === Trangthai);
                return {
                    NhanvienId: dataNhanvien?.id || null,
                    PhongbanId: dataNhanvien?.PhongbanId || null,
                    ChinhanhId: dataNhanvien?.ChinhanhId || null,
                    ChucvuId: dataNhanvien?.ChucvuId || null,
                    UserId: dataNguoiky?.id || null,
                    LoaihopdongId: dataLoaihopdong?.id || null,
                    MauhopdongId: dataMauhopdong?.id || null,
                    ThuethunhapcanhanId: dataLoaithuethunhap ? Number(dataLoaithuethunhap?.value) : null,
                    ngayhethan: Ngayhethan || Number(dataLoaihopdong?.id) != 3 ? null : format(new Date(), 'yyyy-MM-dd'),
                    diaiemlamviec: Diaiemlamviec || null,
                    ngaybatdaulamviec: Ngaybatdaulamviec || null,
                    ngaytaohopdong: Ngaytaohopdong || null,
                    motahopdong: Mota || null,
                    // quyettoanthaythue: dataQuyettoan ? Number(dataQuyettoan?.value) : null,
                    TrangthaiId: dataTrangthai ? Number(dataTrangthai?.value) : null,
                };
            });
            console.log('data', data)
            const allPropertiesFilled = checkAllPropertiesFilled(data);
            
            if (allPropertiesFilled) {
                const datapayload = {
                    data: data.filter((item) => item !== null),
                };
                // console.log('datapayload', datapayload)
                dispatch(actions.NewHopdonglaodong(datapayload));
            } else {
                toast.error('File dữ liệu có trường bị trống vui lòng nhập lại!');
            }
        }
        if (type === 'nhanvien') {
            const data = fileData?.slice(1)?.map((item, index) => {
                const [hoten, ngaysinh, GioitinhId,
                     email, dienthoai, quoctich, noisinh, ProvinceId, DistrictId, Xa_phuongId,  masothue, dantoc, diachihientai,
                     socmnd, ngaycap, noicap,
                     nguoilienhe, sodienthoainglh, quanhevsnv, diachinglh,
                     ChinhanhId, PhongbanId, ChucvuId, KieuluongId, mucluong, ngayvaolamviec, CongthucId, kieuphep, nghipheptieuchuan,sophepton,
                     TrangthaiId
                    //  nguyenquan, noisinh, dantoc, diachihientai, socmnd,
                    // ngaycap, noicap, MaychamcongId, nguoilienhe, sodienthoainglh, quanhevsnv, diachinglh, ChinhanhId, PhongbanId, ChucvuId,
                    // KieuluongId, mucluong, ngayvaolamviec, ngaynghilamviec, CongthucId,
                    // kieuphep, nghipheptieuchuan, masothue, hocvan,
                    // sotaikhoan, tennganhang, nguoihuongthu
                ] = item;
                const Chinhanh = dataExport?.chinhanh.find((iss) => iss.diachi === ChinhanhId);
                const Phongban = dataExport?.phongban.find((si) => si.phongban === PhongbanId);
                const Chucvu = dataExport?.chucvu.find((is) => is.chucvu === ChucvuId);
                const Kieuluong = dataExport?.kieuluong.find((is) => is.tieude === KieuluongId);
                const Gioitinh = dataExport?.gioitinh.find((is) => is.label === GioitinhId);
                // const Maychamcong = dataExport?.maychamcong.find((is) => is.tenmaychamcong === MaychamcongId);
                const Congthuc = dataExport?.congthuctinhluong.find((is) => is.tencongthuc === CongthucId);
                // const Hocvan = dataExport?.hocvan.find((is) => is.label === hocvan);
                // const Khaibaocalamviec = dataExport?.cauhinhcalamviec.find((is) => is.macalamviec === KhaibaocalamviecId);
                const Kieuphep = dataExport?.kieuphep.find((is) => is.label === kieuphep);
                const formattedDateNgaysinh = ngaysinh && ngaysinh.split('/').reverse().join('-');
                const formattedDateNgaycap = ngaycap && ngaycap.split('/').reverse().join('-');
                const formattedDateNgayvaolamviec = ngayvaolamviec && ngayvaolamviec.split('/').reverse().join('-');
                


                return {
                    ids: index +2,
                    hoten,
                    ngaysinh: formattedDateNgaysinh,
                    GioitinhId: Gioitinh?.value || 0,
                    email,
                    dienthoai,
                    quoctich,
                    noisinh,
                    // ProvinceId: Province?.id || 0,
                    // DistrictId: District?.id || 0,
                    // Xa_phuongId: Wards?.id || 0,
                    SProvinceId: ProvinceId,
                    SDistrictId: DistrictId,
                    SXa_phuongId: Xa_phuongId,
                    masothue,
                    dantoc,
                    diachihientai,
                    socmnd,
                    ngaycap: formattedDateNgaycap,
                    noicap,
                    nguoilienhe,
                    sodienthoainglh,
                    quanhevsnv,
                    diachinglh,
                    ChinhanhId: Chinhanh?.id || 0,
                    PhongbanId: Phongban?.id || 0,
                    ChucvuId: Chucvu?.id || 0,
                    KieuluongId: Kieuluong?.id || 0,
                    mucluong,
                    ngayvaolamviec: formattedDateNgayvaolamviec,
                    CongthucId: Congthuc?.id || 0,
                    kieuphep: Kieuphep?.value || 0,
                    nghipheptieuchuan: item.nghipheptieuchuan || 12,
                    sophepton,
                    TrangthaiId: item.TrangthaiId || 0,
                };
            });
            const allPropertiesFilled = data
            ?.filter((item) => {
                return (
                    item.hoten !== undefined ||
                    item.email !== undefined ||
                    item.dienthoai !== undefined ||
                    item.socmnd !== undefined ||
                    item.ChinhanhId !== 0 ||
                    item.PhongbanId !== 0 ||
                    item.ChucvuId !== 0 ||
                    item.mucluong !== undefined ||
                    item.ngayvaolamviec !== undefined
                );
            })
            ?.map((item) => {
                const Province = donvihanhchinh?.data?.province &&
                    JSON.parse(donvihanhchinh.data.province).find((it) => it.name === item.SProvinceId);
        
                const District = donvihanhchinh?.data?.district &&
                    JSON.parse(donvihanhchinh.data.district).find((it) => it.name === item.SDistrictId);
        
                const Wards = donvihanhchinh?.data?.wards &&
                    JSON.parse(donvihanhchinh.data.wards).find((it) => it.name === item.SXa_phuongId);
        
                return {
                    ...item,  // Sao chép toàn bộ thuộc tính của item
                    ProvinceId: Province?.id || 0,  // Thêm Province vào đối tượng item
                    DistrictId: District?.id || 0,  // Thêm District vào đối tượng item
                    Xa_phuongId: Wards?.id || 0      // Thêm Wards vào đối tượng item
                };
            });
        
        // console.log('aaaaaaaaaaaaaaaaaaaaa', allPropertiesFilled);
            if (allPropertiesFilled.length > 0) {
                const datacheck = allPropertiesFilled?.filter((item) => 
                    item.hoten == undefined 
                || item.email == undefined
                || item.dienthoai == undefined
                || item.ChinhanhId == 0
                || item.PhongbanId == 0
                || item.ChucvuId == 0
                // || item.KieuluongId == 0
                || item.mucluong == undefined
                || item.ngayvaolamviec == undefined
                // && item.CongthucId == 0
             );
            //  console.log('bbbbbbbbbbbbb', datacheck);
                if(datacheck.length > 0 ){
                    // toast.error('Có lỗi!');
                    setLogPost(datacheck);
                }else{
                    setLogPost([]);
                    const datapayload = {data: allPropertiesFilled};
                    // console.log('datapayload', datapayload)
                    dispatch(actions.NewNhanvien(datapayload));
                    setFiles([]);
                    setUploadModal(false);
                }

            } else {
                toast.error('Chưa có dữ liệu nào được nhập!');
                // toast.error('File dữ liệu có trường bị trống vui lòng nhập lại!');
            }
        }
    };
    return (
        <React.Fragment>
            <Button color="primary" onClick={() => setUploadModal(true)}>
                <Icon name="upload-cloud"></Icon> <span>Nhập dữ liệu</span>
            </Button>
            <Modal isOpen={uploadModal} size="md" toggle={(ev) => {
                            ev.preventDefault(); setUploadModal(false); setLogPost([]);}}>
                <a href="#cancel" onClick={(ev) => {
                            ev.preventDefault(); setUploadModal(false); setLogPost([]);}} className="close">
                    <Icon name="cross-sm"></Icon>
                </a>
                <ModalBody>
                    <div className="modal-body modal-body-md">
                        {type === 'nhanvien' && (
                            <div className="nk-upload-list mb-3 d-flex">
                                <h5 className="me-2 pt-2">Dữ liệu mẫu</h5>
                                <ExportToExcel
                                    dataFileMau={dataFileMau}
                                    namefile={namefile}
                                    btnname={'Tải về file mẫu'}
                                    dataExport={dataExport}
                                    title={title}
                                    donvihanhchinh={donvihanhchinh}
                                />
                            </div>
                        )}
                        {type === 'dulieuchamcong' && (
                            <div className="nk-upload-list mb-3 d-flex">
                                <h5 className="me-2 pt-2">Dữ liệu mẫu</h5>
                                <ExportDulieuToExcel
                                    dataFileMau={dataFileMau}
                                    namefile={namefile}
                                    btnname={'Tải về file mẫu'}
                                    dataExport={dataExport}
                                    title={title}
                                />
                            </div>
                        )}
                        <hr/>
                        <div className="nk-upload-form">
                            <h5 className="title mb-3">File dữ liệu</h5>
                            <Dropzone onDrop={(acceptedFiles) => handleDropChange(acceptedFiles)}>
                                {({getRootProps, getInputProps}) => (
                                    <section>
                                        <div
                                            {...getRootProps()}
                                            className="dropzone upload-zone small bg-lighter my-2 dz-clickable"
                                        >
                                            <input {...getInputProps()} />
                                            <div className="dz-message">
                                                <span className="dz-message-text">
                                                    <span>Kéo và thả </span> Tệp tin ở đây <span>hoặc chọn</span>
                                                </span>
                                            </div>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                        </div>
                        <hr/>
                        <div className="nk-upload-list">
                            <h6 className="title">Tệp tin đã tải lên</h6>
                            {files.length > 0 ? (
                                files.map((file, index) => (
                                    <div className="nk-upload-item" key={index}>
                                        <div className="nk-upload-icon"></div>
                                        <div className="nk-upload-info">
                                            <div className="nk-upload-title">
                                                <span className="title">{file.name}</span>
                                            </div>
                                            <div className="nk-upload-size">{bytesToMegaBytes(file.size)} MB</div>
                                        </div>
                                        <div className="nk-upload-action">
                                            <a
                                                href="#delete"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    removeFromList(file.name);
                                                    setLogPost([]);
                                                }}
                                                className="btn btn-icon btn-trigger"
                                            >
                                                <Icon name="trash"></Icon>
                                            </a>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="d-flex justify-center">
                                    <span>Chưa có tệp nào được thêm vào !</span>
                                </div>
                            )}
                        </div>
                        
                        <div className="nk-modal-action justify-end">
                            <ul className="btn-toolbar g-4 align-center">
                                {files.length > 0 ? (
                                    <li>
                                        {spinner === true ? (
                                            <Button disabled color="primary">
                                                <Spinner size="sm"/>
                                                <span> Thêm dữ liệu... </span>
                                            </Button>
                                        ) : (
                                            <Button color="primary" onClick={() => addFilesToSystem()}>
                                                Thêm dữ liệu
                                            </Button>
                                        )}
                                    </li>
                                ) : (
                                    <></>
                                )}
                            </ul>
                        </div>
                        {logPost.length > 0 && (
                            <div className='text-danger'>Có một số trường bắt buộc trong các dòng
                             sau bị bị trống hoặc không đúng cú pháp {logPost.map((im) => im.ids + ', ')}</div>
                        )}
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};
export default ImportXlsx;
