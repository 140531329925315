import axiosConfig from '../axiosConfig'

export const apiGetMayChamCong = () => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/maychamcong/quanlymaychamcong`,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiNewMayChamCong = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/maychamcong/quanlymaychamcong',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiEditMayChamCong = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/maychamcong/quanlymaychamcong',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiDeleteMayChamCong = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/maychamcong/quanlymaychamcong',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiNewMccByNhanVien = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/maychamcong/mccbynhanvien/quanlymaychamcong',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiEditMccByNhanVien = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/maychamcong/mccbynhanvien/quanlymaychamcong',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiDeleteMccByNhanVien = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/maychamcong/mccbynhanvien/quanlymaychamcong',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
