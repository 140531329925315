import actionTypes from './actionTypes'
import { apiGetXeploai, apiNewXeploai, apiEditXeploai, apiDeleteXeploai } from '~/services/xeploai'
import { toast } from "react-toastify";
export const getListXeploai = () => async (dispatch) => {
    try {
        const response = await apiGetXeploai()
        // console.log('chek api lisst Xeploai', response?.data.success)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_XEP_LOAI,
                xeploai: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_XEP_LOAI,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_XEP_LOAI,
            xeploai: null
        })
    }
}

export const NewXeploai = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewXeploai', payload)
            const response = await apiNewXeploai(payload)
            // console.log('chek api apiNewXeploai', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_XEP_LOAI,
                    msg: 'OK'
                });
                dispatch(getListXeploai());
                toast.success("Thêm mới xếp loại thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_XEP_LOAI,
                    msg: 'ERR'
                })
                toast.error("Thêm mới xếp loại thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_XEP_LOAI,
                xeploai: null
            })
        }
    }
}
export const EditXeploai = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditXeploai(payload)
            // console.log('chek api apiEditXeploai', response)
            if (response?.data.success === 200) {
                dispatch(getListXeploai());
                toast.success("Sửa thông tin xếp loại thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_XEP_LOAI,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_XEP_LOAI,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin xếp loại thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_XEP_LOAI,
                xeploai: null
            })
        }
    }
}
export const DeleteXeploai = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteXeploai(payload)
            // console.log('chek api apiDeleteXeploai', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListXeploai());
                toast.success("Xóa thông tin xếp loại thành công!")
                dispatch({
                    type: actionTypes.DELETE_XEP_LOAI,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_XEP_LOAI,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin xếp loại thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_XEP_LOAI,
                xeploai: null
            })
        }
    }
}