import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Button, InputForm} from '~/components/Form'
import * as actions from '~/store/actions'

import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import {dataSelectStatus} from "~/utils/Datacustom";
import {validateCauHinhNghiLe} from "~/request/validateCauHinhNghiLe";
import Textarea from "~/components/Form/Textarea";
import {Table} from "reactstrap";
import {PaginationComponent} from "~/components/Component";

const ModalDetailCauhinhnghile = ({show, setShow, dataIdCauhinhnghile, fieldList}) => {
    // console.log('dataIdCauhinhnghile', dataIdCauhinhnghile)
    const {phongban} = useSelector(state => state.phongban)
    const {nhanvien} = useSelector((state) => state.nhanvien);
    // console.log(phongban)
    const dispatch = useDispatch()
    const [invalidFields, setInvalidFields] = useState([])
    const [payload, setPayload] = useState(fieldList)
    const [listPhongban, setlistPhongban] = useState([])
    const [listNhanvien, setListnhanvien] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    useEffect(() => {
        dispatch(actions.getListNhanvien());
    }, []);
    useEffect(() => {
        let PhongbanId = dataIdCauhinhnghile?.Phongbans?.map((item) => ({
            value: item.id,
            label: item.phongban,
        }))
        let NhanvienId = dataIdCauhinhnghile?.Nhanviens?.map((item) => ({
            value: item.uuid,
            label: item.hoten,
        }))
        let TrangthaiId = dataSelectStatus?.find(item => {
            return item && item.value === Number(dataIdCauhinhnghile?.TrangthaiId)
        })
        show && dataIdCauhinhnghile && setPayload({
            tieude: dataIdCauhinhnghile.tieude,
            mota: dataIdCauhinhnghile.mota,
            giatri: dataIdCauhinhnghile.giatri,
            ngaybatdau: dataIdCauhinhnghile.ngaybatdau,
            ngayketthuc: dataIdCauhinhnghile.ngayketthuc,
            giobatdau: dataIdCauhinhnghile.giobatdau,
            gioketthuc: dataIdCauhinhnghile.gioketthuc,
            PhongbanId: PhongbanId,
            NhanvienId: NhanvienId,
            TrangthaiId: {label: TrangthaiId?.label, value: TrangthaiId?.value},
            
        })
    }, [dataIdCauhinhnghile, show])
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItems = payload?.NhanvienId?.slice(indexOfFirstItem, indexOfLastItem);
    
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    
    useEffect(() => {
        if (phongban) {
            let dataSelectPhongban = phongban.map((item) => ({label: item.phongban, value: item.id}));
            setlistPhongban(dataSelectPhongban)
        }
        if (nhanvien) {
            setListnhanvien(nhanvien.filter(i => Number(i.TrangthaiId) === 1));
        }
    }, [phongban, nhanvien])
    // const handleSubmit = async () => {
    //     let invalids = validate(payload)
    //     if (invalids === 0) {
    //         let listNhanvienbyphongban = [];
    //         let listPhongban = [];
    //         const dataPhongban = payload.PhongbanId;
    //         if (dataPhongban) {
    //             dataPhongban.forEach((phongban) => {
    //                 listNhanvien.filter((i) => i.PhongbanId === phongban.value).map(item => listNhanvienbyphongban.push(item.id));
    //             });
    //             listPhongban = dataPhongban.map(item => item.value)
    //         }
    //         let datapayload = {
    //             data: [
    //                 {
    //                     id: dataIdCauhinhnghile.id,
    //                     tieude: payload.tieude,
    //                     mota: payload.mota,
    //                     ngaybatdau: payload.ngaybatdau,
    //                     ngayketthuc: payload.ngayketthuc,
    //                     giobatdau: payload.giobatdau,
    //                     gioketthuc: payload.gioketthuc,
    //                     NhanvienId: listNhanvienbyphongban,
    //                     TrangthaiId: payload.TrangthaiId.value,
    //                 },
    //             ],
    //
    //         }
    //         dispatch(actions.EditCauhinhnghile(datapayload))
    //     }
    // }

    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateCauHinhNghiLe(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateCauHinhNghiLe(item[0], item[1])]);
                invalids++;
            }
        });
        
        return invalids;
    };
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Chi tiết : <span className={"text-primary"}>{payload.tieude}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12 mb-3 pb-3 border-bottom">
                        <p><b className={"text-uppercase"}>Mô tả:</b> {payload.mota}</p>
                        <p><b className={"text-uppercase"}>Thời gian bắt đầu:</b> <i
                            className={"text-danger"}>{payload.giobatdau}</i> : {payload.ngaybatdau} </p>
                        <p><b className={"text-uppercase"}>Thời gian kết thúc:</b> <i
                            className={"text-danger"}>{payload.gioketthuc}</i> : {payload.ngayketthuc}</p>
                        <p><b className={"text-uppercase"}>Trạng thái sự kiện:</b> <span
                            className={`${payload?.TrangthaiId?.value === 1 ? 'bg-success' : 'bg-danger'} ms-0 badge text-white`}>
                                                    {payload?.TrangthaiId?.value === 1 ? 'Hoạt Động' : 'Dừng Hoạt Động'}
                                                </span></p>
                    </div>
                    <div className="col-6">
                        <h5 className={"text-uppercase mb-2"}>Danh sách nhân viên nghỉ lễ</h5>
                        
                        <Table className="table table-bordered table-striped table-hover">
                            <thead>
                            <tr>
                                <th className="text-center">STT</th>
                                <th className="text-center"> Tên nhân viên</th>
                                <th className="text-center"> Mã nhân viên</th>
                            </tr>
                            </thead>
                            <tbody>
                            {currentItems?.length > 0
                                ? currentItems?.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="text-center">{index + 1}</td>
                                            <td className="text-center">{item.label}</td>
                                            <td className="text-center">{item.value}</td>
                                        </tr>
                                    )
                                })
                            :null}
                            </tbody>
                            <tfoot>
                            <tr>
                                <th className="text-center"></th>
                                <th className="text-center">
                                        {currentItems?.length > 0 ? (
                                            <PaginationComponent
                                                itemPerPage={itemPerPage}
                                                totalItems={payload?.NhanvienId?.length}
                                                paginate={paginate}
                                                currentPage={currentPage}
                                            />
                                        ) : (
                                            <div className="text-center">
                                                <span className="text-silent">No data found</span>
                                            </div>
                                        )}
                                </th>
                                <th className="text-center"></th>
                            </tr>
                            
                            </tfoot>
                            
                        </Table>
                    </div>
                    <div className="col-6">
                        <h5 className={"text-uppercase mb-2"}>Danh sách phòng ban nghỉ lễ</h5>
                        <Table className="table table-bordered table-striped table-hover">
                            <thead>
                            <tr>
                                <th className="text-center">STT</th>
                                <th className="text-center"> Tên phòng ban</th>
                            </tr>
                            </thead>
                            <tbody>
                            {payload?.PhongbanId?.length > 0
                                ? payload?.PhongbanId?.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="text-center">{index + 1}</td>
                                            <td className="text-center">{item.label}</td>
                                        </tr>
                                    )
                                })
                                :null}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ModalDetailCauhinhnghile;

