import actionTypes from './actionTypes'
import { apiGetUser, apiGetUserAll, apiGetFunctionUserV2, apiDonvihanhchinh } from '~/services/app'
import { toast } from "react-toastify";
// export const getUser = () => async (dispatch) => {
//     try {
//         const response = await apiGetUser()
//        console.log('get user', response)
//         if (response?.data?.err === 0) {
//             // dispatch(getGetFunctionUserV22({GroupId : response?.data?.userData?.permission_GroupId}));
//             dispatch({
//                 type: actionTypes.GET_USER,
//                 user: response?.data.userData,
//                 FunctionUser: response?.data.functionUser
//             })
//         } else {
//             dispatch({
//                 type: actionTypes.GET_USER,
//                 msg: response.data.msg
//             })
//         }

//     } catch (error) {
//         dispatch({
//             type: actionTypes.GET_USER,
//             user: null
//         })
//     }
// }
// export const getGetFunctionUserV22 = (data) => async (dispatch) => {
//     try {

//         const response = await apiGetFunctionUserV2(data)
//     //    console.log('get apiGetFunctionUserV2', response)
//         if (response?.data.success === 200) {
//             dispatch({
//                 type: actionTypes.GET_FUN_USER,
//                 FunctionUser: response?.data?.data
//             })
//         } else {
//             dispatch({
//                 type: actionTypes.GET_FUN_USER,
//                 msg: response.data.msg
//             })
//         }

//     } catch (error) {
//         dispatch({
//             type: actionTypes.GET_FUN_USER,
//             user: null
//         })
//     }
// }
export const getUserAll = () => async (dispatch) => {
    try {
        const response = await apiGetUserAll()
    //    console.log('get user', response.data.success)
        if (response?.data.success === 200) {
    //    console.log('get api user', response?.data.data)
            dispatch({
                type: actionTypes.GET_USER_ALL,
                userall: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_USER_ALL,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_USER_ALL,
            user: null
        })
    }
}

export const getDonvihanhchinh = () => async (dispatch) => {
    try {
        const response = await apiDonvihanhchinh()
        // console.log('response donvi', response)
        if (response?.data?.success === 200) {
            dispatch({
                type: actionTypes.GET_DON_VI_HANH_CHINH,
                donvihanhchinh: response.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_DON_VI_HANH_CHINH,
                msg: 'Err'
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_DON_VI_HANH_CHINH,
            donvihanhchinh: null
        })
    }
}