import actionTypes from "../actions/actionTypes";
const initState = {
    nghiphepcongtac: [],
    msg: '',
    count: 0,
    newnghiphepcongtac: [],
    // editnghiphepcongtac: 0,
}

const nghiphepcongtacReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.GET_LIST_NGHI_PHEP_CONG_TAC:

            return {
                ...state,
                nghiphepcongtac: action?.nghiphepcongtac?.data || [],
                msg: 'Ok' || '',
                count: action?.nghiphepcongtac?.count || 0
            }
        case actionTypes.POST_NEW_NGHI_PHEP_CONG_TAC:
        case actionTypes.PUT_EDIT_NGHI_PHEP_CONG_TAC:
        case actionTypes.DELETE_NGHI_PHEP_CONG_TAC:
            return {
                ...state,
                msg: action.msg || '',
                newnghiphepcongtac: action?.newnghiphepcongtac || [],
                // editnghiphepcongtac: action?.editnghiphepcongtac || 0,
            }
        default:
            return state;
    }

}

export default nghiphepcongtacReducer