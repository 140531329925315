import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {InputForm, Button} from '~/components/Form';
import * as actions from '~/store/actions';
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import {validateAtvsBhld} from '~/request/validateAtvsBhld';
import Textarea from "~/components/Form/Textarea";
import {Table} from "reactstrap";

const ModalDetail = ({show, setShow, dataIdKiemdinhatvsbhld}) => {
    console.log('dataIdKiemdinhatvsbhld', dataIdKiemdinhatvsbhld)
    
    
    const [payload, setPayload] = useState();
    
    useEffect(() => {
        show &&
        dataIdKiemdinhatvsbhld &&
        setPayload(dataIdKiemdinhatvsbhld);
    }, [dataIdKiemdinhatvsbhld, show]);
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">Thông tin kiểm định</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <Table className="table table-bordered table-striped table-hover">
                        <thead>
                        <tr>
                            <th className="text-center w-25">Tiêu đề</th>
                            <th className="text-center w-75">Thông tin</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Thông tin kiểm định</td>
                            <td>
                                <p><span
                                    className={"fw-bold"}>Tên kiểm định:</span> {payload?.tenkiemdinh}
                                </p>
                                <p><span className={"fw-bold"}>Mã kiểm định:</span> {payload?.makiemdinh}
                                </p>
                                <p><span
                                    className={"fw-bold"}>Chu kỳ (Ngày):</span> {payload?.chukykiemdinh} ngày
                                </p>
                                <p><span
                                    className={"fw-bold"}>Loại kiểm định:</span> {payload?.Loaikiemdinhatvsbhld?.tenloaikiemdinh}
                                </p>
                                <p><span
                                    className={"fw-bold"}>Mã loại kiểm định:</span> {payload?.Loaikiemdinhatvsbhld?.maloaikiemdinh}
                                </p>
                                <p><span
                                    className={"fw-bold"}>Nhân viên kiểm định:</span> {payload?.Nhanviens[0]?.hoten}
                                </p>
                                <p><span
                                    className={"fw-bold"}>Thời gian bắt đầu:</span> {payload?.Nhanviens[0]?.Nhanvienkiemdinh?.ngaybatdau}
                                </p>
                                <p><span
                                    className={"fw-bold"}>Thời gian kết thúc:</span> {payload?.Nhanviens[0]?.Nhanvienkiemdinh?.ngayketthuc}
                                </p>
                                <p><span
                                    className={"fw-bold"}>Chi phí:</span> {payload?.Nhanviens[0]?.Nhanvienkiemdinh?.chiphi}
                                </p>
                                <p><span className={"fw-bold"}>Trạng thái:</span>
                                    <span
                                        className={`${
                                            payload?.Nhanviens[0]?.Nhanvienkiemdinh?.TrangthaiId === 1 ? 'bg-success' : 'bg-danger'
                                        } ms-0 badge text-white`}
                                    >
                                                          {payload?.Nhanviens[0]?.Nhanvienkiemdinh?.TrangthaiId === 1 ? 'Đang kiểm định' : 'Kiểm định kết thúc'}
                                                      </span>
                                </p>
                            
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </div>
            
            </Modal.Body>
        </Modal>
    );
};

export default ModalDetail;
