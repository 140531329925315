import axiosConfig from '../axiosConfig'
import {apiDeleteCongty} from "~/services/congty";
import {toast} from "react-toastify";
import actionTypes from "~/store/actions/actionTypes";
import {getListCongTy} from "~/store/actions";

export const apiInsertTangCa = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: `/api/v2/quanlyhethong/tangca`,
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiGetListTangCa = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: '/api/v2/quanlyhethong/tangca',
            params: payload,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiUpdateTangCaById = (payload) => new Promise(async (resolve, reject) => {
    console.log('data12',payload)
    try {
        const response = await axiosConfig({
            method: 'put',
            url: `/api/v2/quanlyhethong/tangca`,
            data: payload,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})

export const apiDeleteTangCa = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlyhethong/tangca',
            data: payload
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})

export const apiGetPhieutangca = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlyhethong/phieutangca`,
            params: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiNewPhieutangca = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlyhethong/phieutangca',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiEditPhieutangca = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlyhethong/phieutangca',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiDeletePhieutangca = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlyhethong/phieutangca',
            data: {
                id:payload.id
            }
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})