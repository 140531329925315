import axiosConfig from '../axiosConfig'
import { toast } from "react-toastify";

export const apiGetBangtonghopphep = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlynhanvien/quanlyphepnam`,
            params: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiNewBangtonghopphep = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlynhanvien/quanlyphepnam',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
        toast.error(error?.response?.data?.mes)
    }
})
export const apiEditBangtonghopphep = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlynhanvien/quanlyphepnam',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
        toast.error(error?.response?.data?.mes)
    }
})
