import React, { useState, useEffect } from 'react';
import Content from '~/layout/content/Content';
import Head from '~/layout/head/Head';
import LogoDark from '~/images/logo-dark2x.png';
import { Button, Icon, Block } from '~/components/Component';
import { invoiceData } from './Invoice';
import { Link, useLocation } from 'react-router-dom';
import { apiGetPhieusuatan, apiGetCongty } from '~/services';
import { format, parseISO } from 'date-fns';

const PrintPhieusuatan = () => {
    const location = useLocation();
    const active = location?.pathname?.split('/');
    const [data, setData] = useState([]);
    const [congty, setCongty] = useState([]);

    useEffect(() => {
        setTimeout(() => window.print(), 500);
    }, []);

    useEffect(() => {
        const idsa = active[2];
        const fetchData = async () => {
            try {
                const response1 = await apiGetPhieusuatan({ id: idsa });
                setData(response1?.data?.data?.rows);
                const congty = await apiGetCongty();
                setCongty(congty?.data?.data?.rows[0]);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData().catch(console.error);
    }, []);
    let tongSoLuong = 0;
    const par = data[0]?.noidung ? JSON.parse(data[0]?.noidung) : [];
    for (let i = 0; i < par.length; i++) {
        const phanTu = par[i];
        tongSoLuong += parseInt(phanTu.Soluong, 10);
    }
    return (
        <body className="bg-white">
            <Head title="Phiếu báo suất ăn"></Head>
            <Content>
                <Block>
                    <div className="invoice invoice-print">
                        <div className="invoice-action">
                            <Button
                                size="lg"
                                color="primary"
                                outline
                                className="btn-icon btn-white btn-dim"
                                onClick={() => window.print()}
                            >
                                <Icon name="printer-fill"></Icon>
                            </Button>
                        </div>
                        <div className="invoice-wrap">
                            <div className="invoice-brand">
                                {congty?.avatar === null ? (
                                     <img src={LogoDark} alt="" />
                                ) : (
                                    <img src={congty?.avatar} alt="Avatar Image" className="rounded-4" />
                                )}
                               
                            </div>

                            <div className="invoice-head">
                                <div className="invoice-contact">
                                    {/* <span className="overline-title">Công ty</span> */}
                                    <div className="invoice-contact-info">
                                        <h4 className="title">Công ty {congty?.loaihinhcongty}</h4>
                                        <ul className="list-plain">
                                            <li>
                                                <Icon name="map-pin-fill"></Icon>
                                                <span>{congty?.diachi}</span>
                                            </li>
                                            <li>
                                                <Icon name="call-fill"></Icon>
                                                <span>{congty?.dienthoai}</span>
                                            </li>
                                            <li>
                                                <Icon name="printer"></Icon>
                                                <span>{congty?.fax}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="invoice-desc">
                                    <h4 className="title">Phiếu báo suất ăn</h4>
                                    <ul className="list-plain">
                                        <li className="invoice-id">
                                            <span>Mã phiếu</span>:<span>{data[0]?.maphieu}</span>
                                        </li>
                                        <li className="invoice-date">
                                            <span>Ngày tạo phiếu</span>:
                                            <span>
                                                {data[0]?.ngaytao
                                                    ? format(parseISO(data[0]?.ngaytao), 'dd-MM-yyyy')
                                                    : 'NNA'}
                                            </span>
                                        </li>
                                        <li className="invoice-date">
                                            <span>Người tạo</span>:<span>{data[0]?.User?.username}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="invoice-bills">
                                <div className="table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th className="w-150px">Stt</th>
                                                <th className="w-30">Suất ăn cho</th>
                                                <th>Loại suất ăn</th>
                                                <th>Số lượng</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data[0]?.noidung
                                                ? JSON.parse(data[0]?.noidung)?.length > 0
                                                    ? JSON.parse(data[0]?.noidung).map((item, it) => {
                                                          return (
                                                              <tr key={it}>
                                                                  <td>{it + 1}</td>
                                                                  <td>{item?.PhongbanId}</td>
                                                                  <td>{item?.LoaisuatanId?.label}</td>
                                                                  <td>{item?.Soluong}</td>
                                                              </tr>
                                                          );
                                                      })
                                                    : null
                                                : null}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan="2"></td>
                                                <td colSpan="1">Tổng số suất ăn</td>
                                                <td>{tongSoLuong}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                    <div className="nk-notes ff-italic fs-12px text-soft"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Block>
            </Content>
        </body>
    );
};

export default PrintPhieusuatan;
