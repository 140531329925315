import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {InputForm, Button} from '~/components/Form'
import * as actions from '~/store/actions'

import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';

const ModalEditVitrituyendung = ({show, setShow, dataIdVitrituyendung, fieldList}) => {
    //   console.log('dataIdVitrituyendung',dataIdVitrituyendung)
    
    const {phongban} = useSelector(state => state.phongban)
    // console.log(phongban)
    const dispatch = useDispatch()
    const [invalidFields, setInvalidFields] = useState([])
    
    
    const [payload, setPayloadVitrituyendung] = useState(fieldList)
    
    useEffect(() => {
       
        let PhongbanId = phongban?.find(item => {
            return item && item.id === dataIdVitrituyendung?.Phongban?.id
        })
        show && dataIdVitrituyendung && setPayloadVitrituyendung({
            BocauhoiphongvanId: 1,
            BocauhoituyendungId: 1,
            BangdanhgiatuyendungId: 1,
            NhomtuyendungId: 1,
            PhongbanId: 1,
            ChucvuId: 1,
            hinhthuclamviec: 'fulltime',
            trinhdohocvan: dataIdVitrituyendung.trinhdohocvan,
            kinhnghiem: dataIdVitrituyendung.kinhnghiem,
            yeucaudotuoi: dataIdVitrituyendung.yeucaudotuoi,
            soluongcantuyen: dataIdVitrituyendung.soluongcantuyen,
            thoihanthuviec: dataIdVitrituyendung.thoihanthuviec,
            hannophoso: dataIdVitrituyendung.hannophoso,
            noilamviec: dataIdVitrituyendung.noilamviec,
            mucluongtoithieu: dataIdVitrituyendung.mucluongtoithieu,
            mucluongtoida: dataIdVitrituyendung.mucluongtoida,
            luongthoathuan: dataIdVitrituyendung.luongthoathuan,
            titletuyendung: dataIdVitrituyendung.titletuyendung,
            motacongviec: dataIdVitrituyendung.motacongviec,
            yeucaucongviec: dataIdVitrituyendung.yeucaucongviec,
            quyenloiduochuong: dataIdVitrituyendung.quyenloiduochuong,
            hotenlh: dataIdVitrituyendung.hotenlh,
            emaillh: dataIdVitrituyendung.emaillh,
            sodtlh: dataIdVitrituyendung.sodtlh,
            diachilh: dataIdVitrituyendung.diachilh,
            trangthai: 3,
        })
    }, [dataIdVitrituyendung, show])
    
    const [listPhongban, setlistPhongban] = useState([])
    useEffect(() => {
        if ( phongban) {
            let dataSelectPhongban = phongban.map((item) => ({ label: item.phongban, value: item.id }));
            setlistPhongban(dataSelectPhongban)
        }
    }, [phongban])
    const handleSubmit = async () => {
        let invalids = validate(payload)
        
        if (invalids === 0) {
            let datapayload = {
                BocauhoiphongvanId: 1,
                BocauhoituyendungId: 1,
                BangdanhgiatuyendungId: 1,
                NhomtuyendungId: 1,
                PhongbanId: 1,
                ChucvuId: 1,
                hinhthuclamviec: 'fulltime',
                trinhdohocvan: payload.trinhdohocvan,
                kinhnghiem: payload.kinhnghiem,
                yeucaudotuoi: payload.yeucaudotuoi,
                soluongcantuyen: payload.soluongcantuyen,
                thoihanthuviec: payload.thoihanthuviec,
                hannophoso: payload.hannophoso,
                noilamviec: payload.noilamviec,
                mucluongtoithieu: payload.mucluongtoithieu,
                mucluongtoida: payload.mucluongtoida,
                luongthoathuan: payload.luongthoathuan,
                titletuyendung: payload.titletuyendung,
                motacongviec: payload.motacongviec,
                yeucaucongviec: payload.yeucaucongviec,
                quyenloiduochuong: payload.quyenloiduochuong,
                hotenlh: payload.hotenlh,
                emaillh: payload.emaillh,
                sodtlh: payload.sodtlh,
                diachilh: payload.diachilh,
                trangthai: 3,
            }
              console.log('check payload', datapayload)
            dispatch(actions.EditVitrituyendung(datapayload))
        }
    }
    const validate = (payload) => {
        let invalids = 0
        let fields = Object.entries(payload)
        fields.forEach(item => {
            if (item[1] === '') {
                setInvalidFields(prev => [...prev, {
                    name: item[0],
                    message: 'Bạn không được bỏ trống trường này.'
                }])
                invalids++
            }
        })
        
        return invalids
    }
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Thêm mới vị trí tuyển dụng
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-4 col-4">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Trình độ học vấn'}
                            value={payload.trinhdohocvan}
                            setValue={setPayloadVitrituyendung}
                            keyPayload={'trinhdohocvan'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-4 col-4 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Kinh nghiệm'}
                            value={payload.kinhnghiem}
                            setValue={setPayloadVitrituyendung}
                            keyPayload={'kinhnghiem'}
                            type='text'
                        />
                    </div>
                
                    <div className="form-group col-md-4 col-4">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Yêu cầu độ tuổi'}
                            value={payload.yeucaudotuoi}
                            setValue={setPayloadVitrituyendung}
                            keyPayload={'yeucaudotuoi'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-4 col-4 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Số lượng cần tuyển'}
                            value={payload.soluongcantuyen}
                            setValue={setPayloadVitrituyendung}
                            keyPayload={'soluongcantuyen'}
                            type='text'
                        />
                    </div>
                
                    <div className="form-group col-md-4 col-4">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Thời hạn thử việc'}
                            value={payload.thoihanthuviec}
                            setValue={setPayloadVitrituyendung}
                            keyPayload={'thoihanthuviec'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-4 col-4 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Hạn nộp hồ sơ'}
                            value={payload.hannophoso}
                            setValue={setPayloadVitrituyendung}
                            keyPayload={'hannophoso'}
                            type='date'
                        />
                    </div>
                
                    <div className="form-group col-md-4 col-4">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Nơi làm việc'}
                            value={payload.noilamviec}
                            setValue={setPayloadVitrituyendung}
                            keyPayload={'noilamviec'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-4 col-4 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Mức lương tối thiểu'}
                            value={payload.mucluongtoithieu}
                            setValue={setPayloadVitrituyendung}
                            keyPayload={'mucluongtoithieu'}
                            type='text'
                        />
                    </div>
                
                    <div className="form-group col-md-4 col-4">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Mức lương tối đa'}
                            value={payload.mucluongtoida}
                            setValue={setPayloadVitrituyendung}
                            keyPayload={'mucluongtoida'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-4 col-4 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Lương thỏa thuận'}
                            value={payload.luongthoathuan}
                            setValue={setPayloadVitrituyendung}
                            keyPayload={'luongthoathuan'}
                            type='text'
                        />
                    </div>
                
                    <div className="form-group col-md-4 col-4">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Tiêu đề tuyển dụng'}
                            value={payload.titletuyendung}
                            setValue={setPayloadVitrituyendung}
                            keyPayload={'titletuyendung'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-4 col-4 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Mô tả công việc'}
                            value={payload.motacongviec}
                            setValue={setPayloadVitrituyendung}
                            keyPayload={'motacongviec'}
                            type='text'
                        />
                    </div>
                
                    <div className="form-group col-md-4 col-4">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Yêu cầu công việc'}
                            value={payload.yeucaucongviec}
                            setValue={setPayloadVitrituyendung}
                            keyPayload={'yeucaucongviec'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-4 col-4 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Quyền lợi được hưởng'}
                            value={payload.quyenloiduochuong}
                            setValue={setPayloadVitrituyendung}
                            keyPayload={'quyenloiduochuong'}
                            type='text'
                        />
                    </div>
                
                    <div className="form-group col-md-4 col-4">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Họ tên liên hệ'}
                            value={payload.hotenlh}
                            setValue={setPayloadVitrituyendung}
                            keyPayload={'hotenlh'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-4 col-4 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Email liên hệ'}
                            value={payload.emaillh}
                            setValue={setPayloadVitrituyendung}
                            keyPayload={'emaillh'}
                            type='text'
                        />
                    </div>
                
                    <div className="form-group col-md-4 col-4">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Số điện thoại liên hệ'}
                            value={payload.sodtlh}
                            setValue={setPayloadVitrituyendung}
                            keyPayload={'sodtlh'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-4 col-4 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Địa chỉ liên hệ'}
                            value={payload.diachilh}
                            setValue={setPayloadVitrituyendung}
                            keyPayload={'diachilh'}
                            type='text'
                        />
                    </div>
                </div>
            
                <div className='col-12 mt-5'>
                    <Button
                        text='Update'
                        bClass='btn btn-primary px-5'
                        textColor
                        onClick={handleSubmit}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ModalEditVitrituyendung;

