import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as actions from '~/store/actions';
import {ModalDangkiemphuongtien, ModalEditDangkiemphuongtien, ModalExportExcell} from './Modal';
import Swal from 'sweetalert2';
import exportFromJSON from 'export-from-json';
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalBody,
    UncontrolledDropdown,
    Spinner,
    Card,
} from 'reactstrap';
import Head from '~/layout/head/Head';
import Content from '~/layout/content/Content';
import {Block} from '~/components/block/Block';
import {
    Button,
    Col,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    Icon,
    PaginationComponent,
    Row,
    RSelect,
    TooltipComponent,
} from '~/components/Component';
import {Link} from 'react-router-dom';
import config from '~/config';
import {dataSelectActive} from "~/utils/Datacustom";
import {InputForm} from "~/components/Form";
import {MenuPhuongTien} from "~/pages/Admin/MenuTab/Menucontent";
import {getListDangkiemphuongtien} from "~/store/actions/dangkiemphuongtien";
import {matusinhResult} from "~/utils/Utils";

const Dangkiemphuongtien = () => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [showEditDangkiemphuongtien, setShowEditDangkiemphuongtien] = useState(false);
    const [listTrangthaiSelect, setlistTrangthaiSelect] = useState([])
    const [dataTmp, setDataTmp] = useState([]);
    const [payload, setPayload] = useState()
    const [invalidFields, setInvalidFields] = useState([])
    
    // --start-- Export excell
    const [showExportView, setShowExportView] = useState(false);
    const [typeExport, setTypeExport] = useState('csv');
    // --end-- Export excell
    
    //lấy giá trị từ thằng redux trả về
    const {dangkiemphuongtien} = useSelector((state) => state.quanly);
    const [data, setData] = useState(dangkiemphuongtien);
    // const [codeRender, setcodeRender] = useState('');
    
    const fieldList = {
        PhuongtienId: '',
        TrangthaiId: '',
        ngaydangkiem: '',
        chukydangkiem: '',
        thoihantruoc: '',
        thoihantieptheo: '',
        chiphi: '',
        ghichu: '',
    };
    useEffect(() => {
        dispatch(actions.getListDangkiemphuongtien());
    }, []);
    console.log('dangkiemphuongtien',dangkiemphuongtien)
    useEffect(() => {
        if (dangkiemphuongtien && dangkiemphuongtien?.length > 0) {
            setData([...dangkiemphuongtien]);
            setDataTmp([...dangkiemphuongtien]);
            let TrangthaiId = dataSelectActive.map((item) => ({label: item.label, value: item.value}));
            setlistTrangthaiSelect(TrangthaiId);
        }
    }, [dangkiemphuongtien]);
    
    const [sm, updateSm] = useState(false);
    const [tablesm, updateTableSm] = useState(false);
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState('');
    const [modal, setModal] = useState({
        edit: false,
        add: false,
    });
    const [actionText, setActionText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [sort, setSortState] = useState('');
    // onChange function for searching name
    const onFilterChange = (e) => {
        setSearchText(e.target.value);
    };
    // function to toggle the search option
    const toggle = () => setonSearch(!onSearch);
    // function to change the check property of an item
    const selectorCheck = (e) => {
        let newData;
        newData = data.map((item) => {
            item.checked = e.currentTarget.checked;
            return item;
        });
        setData([...newData]);
    };
    // function to change the selected property of an item
    const onSelectChange = (e, id) => {
        let newData = data;
        let index = newData.findIndex((item) => item.id === id);
        newData[index].checked = e.currentTarget.checked;
        setData([...newData]);
    };
    // Changing state value when searching name
    useEffect(() => {
        let filteredObject = [];
        
        if (onSearchText !== '') {
            filteredObject = data.filter((item) => {
                return (
                    item.biensoxe.toLowerCase().includes(onSearchText.toLowerCase()) ||
                    item.sokhung.toLowerCase().includes(onSearchText.toLowerCase()) ||
                    item.nhanhieu.toLowerCase().includes(onSearchText.toLowerCase())
                );
            });
            setData([...filteredObject]);
        } else {
            switch (true) {
                case payload?.TrangthaiId?.value !== undefined:
                    let pbId = payload?.TrangthaiId?.value;
                    filteredObject = dataTmp.filter((item) => item.TrangthaiId === pbId);
                    break;
                default:
                    filteredObject = dataTmp;
                    break;
            }
            setData([...filteredObject]);
        }
    }, [onSearchText, setData, payload]);
    // Get current list, pagination
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);
    // Change Page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const handleSubmit = async () => {
        let dataFilter = data.filter((item) => {
            return item.ngaydangkiem >= payload?.ngaybatdau && item.thoihantruoc <= payload?.ngayketthuc;
        });
        setData([...dataFilter]);
    }
    const Export = ({data}) => {
        const [modal, setModal] = useState(false);
        
        useEffect(() => {
            if (modal === true) {
                setTimeout(() => setModal(false), 2000);
            }
        }, [modal]);
        
        const fileName = 'Danh-sach-cong-ty';
    
        const exportCSV = () => {
            setShowExportView(true);
            setTypeExport('csv');
            // const exportType = exportFromJSON.types.csv;
            // exportFromJSON({ data, fileName, exportType });
        };
    
        const exportExcel = () => {
            setShowExportView(true);
            setTypeExport('xls');
            // const exportType = exportFromJSON.types.xls;
            // exportFromJSON({ data, fileName, exportType });
        };
        
        return (
            <React.Fragment>
                <div className="dt-export-buttons d-flex align-center">
                    <div className="d-block">
                        <div className="dt-buttons btn-group">
                            <button
                                className="btn btn-success buttons-csv buttons-html5"
                                type="button"
                                onClick={() => exportCSV()}
                            >
                                <span>CSV</span>
                            </button>
                            {' '}
                            <button
                                className="btn btn-success buttons-excel buttons-html5"
                                type="button"
                                onClick={() => exportExcel()}
                            >
                                <span>Excel</span>
                            </button>
                            {' '}
                        </div>
                    </div>
                </div>
                <Modal isOpen={modal} className="modal-dialog-centered text-center" size="sm">
                    <ModalBody className="text-center m-2">
                        <h5>Copied to clipboard</h5>
                    </ModalBody>
                    <div className="p-3 bg-light">
                        <div className="text-center">Copied {data?.length} rows to clipboard</div>
                    </div>
                </Modal>
            </React.Fragment>
        );
    };
    console.log('dangkiemphuongtien', dangkiemphuongtien)
    useEffect(() => {
        (dangkiemphuongtien && setShow(false)) || setShowEditDangkiemphuongtien(false);
    }, [dangkiemphuongtien]);
    // set state để mở đóng Modal
    const handleAddNewDangkiemphuongtien = async () => {
        setShow(true);
    };
    const [dataIdDangkiemphuongtien, setDataIdDangkiemphuongtien] = useState([]);
    const handleEditDangkiemphuongtien = async (data) => {
        setShowEditDangkiemphuongtien(true);
        await setDataIdDangkiemphuongtien(data);
    };
    const handleDeleteDangkiemphuongtien = (data) => {
        Swal.fire({
            title: 'Bạn có chắc không?',
            text: 'Bạn sẽ không thể lấy lại khi xóa!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Không, bỏ qua!',
            confirmButtonText: 'Vâng, xóa nó đi!',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(actions.DeleteDangkiemphuongtien(data));
            }
        });
    };
    return (
        <React.Fragment>
            {/*<Head title="Quản lý Phương Tiện"></Head>*/}
            <Content>
                <Card className="mt-0">
                    <div className="card-aside-wrap">
                        <div className="card-inner card-inner-lg px-4">
                            <h4>Quản Lý Đăng kiểm phương tiện</h4>
                            <MenuPhuongTien />
                        </div>
                    </div>
                </Card>
                <Block>
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner position-relative card-tools-toggle pt-0">
                            <div className="card-title-group row">
                                <div className="card-title col-9 row">
                                    <div className="col-4">
                                        <h5 className="title">Danh sách</h5>
                                        <Button
                                            color="primary"
                                            className="btn-icon px-4 "
                                            onClick={handleAddNewDangkiemphuongtien}
                                        >
                                            <Icon name="plus"></Icon> Thêm mới
                                        </Button>
                                    </div>
                                    <div className="col-8 row">
                                        <div className="form-group col-md-4">
                                            <InputForm
                                                setInvalidFields={setInvalidFields}
                                                invalidFields={invalidFields}
                                                label={'Ngày đăng kiểm'}
                                                value={payload?.ngaybatdau}
                                                setValue={setPayload}
                                                keyPayload={'ngaybatdau'}
                                                className={"my-0"}
                                                type='date'
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <InputForm
                                                setInvalidFields={setInvalidFields}
                                                invalidFields={invalidFields}
                                                label={'Ngày kết thúc đăng kiểm'}
                                                value={payload?.ngayketthuc}
                                                setValue={setPayload}
                                                keyPayload={'ngayketthuc'}
                                                className={"my-0"}
                                                type='date'
                                            />
                                        </div>
                                        <div className="form-group col-md-4 my-auto text-center">
                                            <Button color="primary" className="btn-icon px-4"
                                                    onClick={handleSubmit}>
                                                Tra cứu
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-tools col-3">
                                    <ul className="btn-toolbar gx-1 justify-content-end">
                                        <li>
                                            <a
                                                href="#search"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    toggle();
                                                }}
                                                className="btn btn-icon search-toggle toggle-search"
                                            >
                                                <Icon name="search"></Icon>
                                            </a>
                                        </li>
                                        <li className="btn-toolbar-sep"></li>
                                        <li>
                                            <div className="toggle-wrap">
                                                <Button
                                                    className={`btn-icon btn-trigger toggle ${tablesm ? 'active' : ''}`}
                                                    onClick={() => updateTableSm(true)}
                                                >
                                                    <Icon name="menu-right"></Icon>
                                                </Button>
                                                <div className={`toggle-content ${tablesm ? 'content-active' : ''}`}>
                                                    <ul className="btn-toolbar gx-1">
                                                        <li className="toggle-close">
                                                            <Button
                                                                className="btn-icon btn-trigger toggle"
                                                                onClick={() => updateTableSm(false)}
                                                            >
                                                                <Icon name="arrow-left"></Icon>
                                                            </Button>
                                                        </li>
                                                        <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle
                                                                    tag="a"
                                                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                                                >
                                                                    <div className="dot dot-primary"></div>
                                                                    <Icon name="filter-alt"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu
                                                                    end
                                                                    className="filter-wg dropdown-menu-xl"
                                                                    style={{overflow: 'visible'}}
                                                                >
                                                                    <div className="dropdown-head">
                                                                        <span className="sub-title dropdown-title">
                                                                            Bộ lọc
                                                                        </span>
                                                                        <div className="dropdown">
                                                                            <a
                                                                                href="#more"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                }}
                                                                                className="btn btn-sm btn-icon"
                                                                            >
                                                                                <Icon name="more-h"></Icon>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    <div className="dropdown-body dropdown-body-rg">
                                                                        <Row className="gx-6 gy-3">
                                                                            <Col size="12">
                                                                                <div className="form-group">
                                                                                    <label
                                                                                        className="overline-title overline-title-alt">Status</label>
                                                                                    <RSelect
                                                                                        // value={payload.TrangthaiId}
                                                                                        options={listTrangthaiSelect}
                                                                                        onChange={(selected) => {
                                                                                            setPayload([]);
                                                                                            setPayload(prev => ({
                                                                                                ...prev,
                                                                                                ['TrangthaiId']: selected
                                                                                            }))
                                                                                        }}
                                                                                        placeholder="Trạng thái"/>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                    <div className="dropdown-foot between">
                                                                        <a
                                                                            href="#reset"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();
                                                                            }}
                                                                            className="clickable"
                                                                        >
                                                                            Cập nhật bộ lọc 
                                                                        </a>
                                                                        <a
                                                                            href="#save"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();
                                                                            }}
                                                                        >
                                                                            Lưu bộ lọc 
                                                                        </a>
                                                                    </div>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                        <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle
                                                                    color="tranparent"
                                                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                                                >
                                                                    <Icon name="setting"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu end className="dropdown-menu-xs">
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Show</span>
                                                                        </li>
                                                                        <li
                                                                            className={
                                                                                itemPerPage === 10 ? 'active' : ''
                                                                            }
                                                                        >
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setItemPerPage(10);
                                                                                }}
                                                                            >
                                                                                10
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li
                                                                            className={
                                                                                itemPerPage === 15 ? 'active' : ''
                                                                            }
                                                                        >
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setItemPerPage(15);
                                                                                }}
                                                                            >
                                                                                15
                                                                            </DropdownItem>
                                                                        </li>
                                                                    </ul>
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Order</span>
                                                                        </li>
                                                                        <li className={sort === 'dsc' ? 'active' : ''}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setSortState('dsc');
                                                                                    //   sortFunc("dsc");
                                                                                }}
                                                                            >
                                                                                DESC
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li className={sort === 'asc' ? 'active' : ''}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setSortState('asc');
                                                                                    //   sortFunc("asc");
                                                                                }}
                                                                            >
                                                                                ASC
                                                                            </DropdownItem>
                                                                        </li>
                                                                    </ul>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className=" position-relative card-tools-toggle">
                                                <div className="toggle-wrap nk-block-tools-toggle">
                                                    <Button
                                                        className={`btn-icon btn-trigger toggle-expand me-n1 ${
                                                            sm ? 'active' : ''
                                                        }`}
                                                        onClick={() => updateSm(!sm)}
                                                    >
                                                        <Icon name="menu-alt-r"></Icon>
                                                    </Button>
                                                    <div
                                                        className="toggle-expand-content"
                                                        style={{display: sm ? 'block' : 'none'}}
                                                    >
                                                        <ul className="nk-block-tools g-3">
                                                            <li>
                                                                <Export data={data}/>
                                                            </li>
                                                            {/*<li className="nk-block-tools-opt">*/}
                                                            {/*    <Button color="primary" className="btn-icon px-1"*/}
                                                            {/*            onClick={handleAddNewDangkiemphuongtien}>*/}
                                                            {/*        <Icon name="plus"></Icon> Thêm mới*/}
                                                            {/*    </Button>*/}
                                                            {/*</li>*/}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className={`card-search search-wrap ${!onSearch && 'active'}`}>
                                <div className="card-body">
                                    <div className="search-content">
                                        <Button
                                            className="search-back btn-icon toggle-search active"
                                            onClick={() => {
                                                setSearchText('');
                                                toggle();
                                            }}
                                        >
                                            <Icon name="arrow-left"></Icon>
                                        </Button>
                                        <input
                                            type="text"
                                            className="border-transparent form-focus-none form-control"
                                            placeholder="Tìm kiếm công ty"
                                            value={onSearchText}
                                            onChange={(e) => onFilterChange(e)}
                                        />
                                        <Button className="search-submit btn-icon">
                                            <Icon name="search"></Icon>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DataTableBody>
                            <DataTableHead>
    
                                <DataTableRow>
                                    <span className="sub-text">STT</span>
                                </DataTableRow>
                                <DataTableRow className="nk-tb-col-check">
                                    <div className="custom-control custom-control-sm custom-checkbox notext">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            onChange={(e) => selectorCheck(e)}
                                            id="uid"
                                        />
                                        <label className="custom-control-label" htmlFor="uid"></label>
                                    </div>
                                </DataTableRow>
                                <DataTableRow>
                                    <span className="sub-text">Phương tiện</span>
                                </DataTableRow>
                                <DataTableRow size="mb">
                                    <span className="sub-text">Chu kỳ đăng kiểm</span>
                                </DataTableRow>
                                <DataTableRow size="mb">
                                    <span className="sub-text">Ngày đăng kiểm</span>
                                </DataTableRow>
                                <DataTableRow size="mb">
                                    <span className="sub-text">Hạn đăng kiểm</span>
                                </DataTableRow>
                                <DataTableRow size="mb">
                                    <span className="sub-text">Đăng kiểm tiếp theo</span>
                                </DataTableRow>
                                <DataTableRow size="mb">
                                    <span className="sub-text">Chi Phí</span>
                                </DataTableRow>
                                <DataTableRow size="mb">
                                    <span className="sub-text">Trạng thái</span>
                                </DataTableRow>
                                <DataTableRow className="nk-tb-col-tools text-end">
                                    <UncontrolledDropdown>
                                        <DropdownToggle
                                            color="tranparent"
                                            className="btn btn-xs btn-outline-light btn-icon dropdown-toggle"
                                        >
                                            <Icon name="plus"></Icon>
                                        </DropdownToggle>
                                        <DropdownMenu end className="dropdown-menu-xs">
                                            <ul className="link-tidy sm no-bdr">
                                                <li>
                                                    <div className="custom-control custom-control-sm custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="st"
                                                        />
                                                        <label className="custom-control-label" htmlFor="st">
                                                            Status
                                                        </label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </DataTableRow>
                            </DataTableHead>
                            {currentItems?.length > 0
                                ? currentItems?.map((item,index) => {
                                    return (
                                        <DataTableItem key={index}>
                                            <DataTableRow size="mb">
                                                <span className="tb-amount">{index+1}</span>
                                            </DataTableRow>
                                            <DataTableRow className="nk-tb-col-check">
                                                <div
                                                    className="custom-control custom-control-sm custom-checkbox notext">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        defaultChecked={item.checked}
                                                        id={item.id + 'uid1'}
                                                        key={Math.random()}
                                                        onChange={(e) => onSelectChange(e, item.id)}
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor={item.id + 'uid1'}
                                                    ></label>
                                                </div>
                                            </DataTableRow>
                                            <DataTableRow size="mb">
                                                <span className="tb-amount">{item?.Phuongtien?.biensoxe} ({item?.Phuongtien?.Loaiphuongtien?.tenloaiphuongtien})</span>
                                            </DataTableRow>
                                            
                                            <DataTableRow size="mb">
                                                <span className="tb-amount">{item?.chukydangkiem}</span>
                                            </DataTableRow>
                                            <DataTableRow size="mb">
                                                <span className="tb-amount">{item?.ngaydangkiem}</span>
                                            </DataTableRow>
                                            <DataTableRow size="mb">
                                                <span className="tb-amount">{item?.thoihantruoc}</span>
                                            </DataTableRow>
                                            <DataTableRow size="mb">
                                                <span className="tb-amount">{item?.thoihantieptheo}</span>
                                            </DataTableRow>
                                            <DataTableRow size="mb">
                                                <span className="tb-amount">{item?.chiphi}</span>
                                            </DataTableRow>
                                            <DataTableRow size="mb">
                                                   <span
                                                       className={`${item?.TrangthaiId === 1 ? 'bg-success' : 'bg-danger'} ms-0 badge text-white`}>
                                                    {item?.TrangthaiId === 1 ? 'Đã đăng kiểm' : 'Hết đăng kiểm'}
                                                </span>
                                            </DataTableRow>
                                            
                                            <DataTableRow className="nk-tb-col-tools">
                                                <ul className="nk-tb-actions gx-1">
                                                    <li
                                                        className="nk-tb-action-hidden"
                                                        onClick={() => handleEditDangkiemphuongtien(item)}
                                                    >
                                                        <TooltipComponent
                                                            tag="a"
                                                            containerClassName="btn btn-trigger btn-icon"
                                                            id={'edit' + item.id}
                                                            icon="edit-alt-fill"
                                                            direction="top"
                                                            text="Sửa"
                                                        />
                                                    </li>
                                                    {item.status !== 'Suspend' && (
                                                        <React.Fragment>
                                                            <li
                                                                className="nk-tb-action-hidden"
                                                                onClick={() => handleDeleteDangkiemphuongtien(item)}
                                                            >
                                                                <TooltipComponent
                                                                    tag="a"
                                                                    containerClassName="btn btn-trigger btn-icon"
                                                                    id={'suspend' + item.id}
                                                                    icon="user-cross-fill"
                                                                    direction="top"
                                                                    text="Xóa"
                                                                />
                                                            </li>
                                                        </React.Fragment>
                                                    )}
                                                    <li>
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle
                                                                tag="a"
                                                                className="dropdown-toggle btn btn-icon btn-trigger"
                                                            >
                                                                <Icon name="more-h"></Icon>
                                                            </DropdownToggle>
                                                            <DropdownMenu end>
                                                                <ul className="link-list-opt no-bdr">
                                                                    <li onClick={() => handleEditDangkiemphuongtien(item)}>
                                                                        <DropdownItem
                                                                            tag="a"
                                                                            href="#edit"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();
                                                                            }}
                                                                        >
                                                                            <Icon name="edit"></Icon>
                                                                            <span>Sửa</span>
                                                                        </DropdownItem>
                                                                    </li>
                                                                    {/* {item.status !== "Suspend" && ( */}
                                                                    <React.Fragment>
                                                                        <li className="divider"></li>
                                                                        <li onClick={() => handleDeleteDangkiemphuongtien(item)}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#suspend"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                }}
                                                                            >
                                                                                <Icon name="na"></Icon>
                                                                                <span>Xóa</span>
                                                                            </DropdownItem>
                                                                        </li>
                                                                    </React.Fragment>
                                                                    {/* )} */}
                                                                </ul>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </li>
                                                </ul>
                                            </DataTableRow>
                                        </DataTableItem>
                                    );
                                })
                                : null}
                        </DataTableBody>
                        <div className="card-inner">
                            {currentItems?.length > 0 ? (
                                <PaginationComponent
                                    itemPerPage={itemPerPage}
                                    totalItems={dangkiemphuongtien?.length}
                                    paginate={paginate}
                                    currentPage={currentPage}
                                />
                            ) : (
                                <div className="text-center">
                                    <div>
                                        <Spinner color="primary"/>
                                    </div>
                                    <span className="text-silent">Chưa có dữ liệu!</span>
                                </div>
                            )}
                        </div>
                    </DataTable>
                </Block>
                
                <ModalDangkiemphuongtien show={show} fieldList={fieldList} setShow={() => setShow(false)}/>
                <ModalEditDangkiemphuongtien
                    show={showEditDangkiemphuongtien}
                    fieldList={fieldList}
                    setShow={() => setShowEditDangkiemphuongtien(false)}
                    dataIdPhuongtien={dataIdDangkiemphuongtien}
                />
                {/*// --start-- Modal Export excell*/}
                <ModalExportExcell
                    show={showExportView}
                    typeExport={typeExport}
                    setShow={() => setShowExportView(false)}
                    dataExport={data}
                />
                {/*// --end-- Modal Export excell*/}
            </Content>
        </React.Fragment>
    );
};

export default Dangkiemphuongtien;
