import actionTypes from "../actions/actionTypes";
const initState = {
    soluongnvdilam: '',
    soluongtangca: '',
    sonvanbuachinh: [],
    tangca: [],
    // msg: '',
    // count: 0,
    newsuatan: []
}

const suatanReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.GET_LIST_SUAT_AN:

            return {
                ...state,
                soluongnvdilam: action?.suatan?.soluongnvdilam || '',
                soluongtangca: action?.suatan?.soluongtangca || '',
                sonvanbuachinh: action?.suatan?.sonvanbuachinh || [],
                tangca: action?.suatan?.tangca || [],
                msg: 'Ok' || '',
                // count: action.suatan.count || 0

            }
        case actionTypes.POST_NEW_SUAT_AN:
        case actionTypes.PUT_EDIT_SUAT_AN:
        case actionTypes.DELETE_SUAT_AN:
            return {
                ...state,
                msg: action.msg || '',
                newsuatan: action?.newsuatan || []
            }
        default:
            return state;
    }

}

export default suatanReducer