import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { InputForm, Button } from '~/components/Form';
import * as actions from '~/store/actions';
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import { validateKieuLuong } from '~/request/validateKieuLuong';

const ModalKieuluong = ({ show, setShow, fieldList }) => {
    const dispatch = useDispatch();
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayloadKieuluong] = useState(fieldList);
    useEffect(() => {
        show && setPayloadKieuluong(fieldList);
    }, [show]);
    const handleSubmit = async () => {
        let invalids = validate(payload);
        if (invalids === 0) {
            let datapayload = {
                data: [
                    {
                        tieude: payload.tieude,
                        // giatri: payload.giatri,
                        mota: payload.mota,
                        status: 1,
                    },
                ],
            };

            // console.log('datapayload', datapayload)
            dispatch(actions.NewKieuluong(datapayload));
        }
    };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateKieuLuong(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateKieuLuong(item[0], item[1])]);
                invalids++;
            }
        });

        return invalids;
    };
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">Thêm mới kiểu lương</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-12">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Tiêu đề'}
                            value={payload.tieude}
                            setValue={setPayloadKieuluong}
                            keyPayload={'tieude'}
                            type="text"
                            validate
                        />
                    </div>
                    {/* <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Giá trị'}
                            value={payload.giatri}
                            setValue={setPayloadKieuluong}
                            keyPayload={'giatri'}
                            type="text"
                            validate
                        />
                    </div> */}
                </div>
                <div className="row">
                    <div className="form-group col-md-12">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Mô tả vị trí'}
                            value={payload.mota}
                            setValue={setPayloadKieuluong}
                            keyPayload={'mota'}
                            type="text"
                            validate
                        />
                    </div>
                </div>

                <div className="col-12 mt-5">
                    <Button text="Thêm kiểu lương" bClass="btn btn-primary px-5" textColor onClick={handleSubmit} />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalKieuluong;
