import actionTypes from "../actions/actionTypes";
const initState = {
    ungvien: [],
    msg: '',
    count: 0,
    newungvien: []
}

const ungvienReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.GET_LIST_UNG_VIEN:

            return {
                ...state,
                ungvien: action?.ungvien || [],
                msg: 'Ok' || '',
                count: action?.ungvien?.length || 0
            }
        case actionTypes.POST_NEW_UNG_VIEN:
        case actionTypes.PUT_EDIT_UNG_VIEN:
        case actionTypes.DELETE_UNG_VIEN:
            return {
                ...state,
                msg: action.msg || '',
                newungvien: action?.newungvien || []
            }
        default:
            return state;
    }

}

export default ungvienReducer