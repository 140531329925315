export const validateQuanHeLaoDong = (fieldName, value) => {
    switch (fieldName) {
        case 'tenvande':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Dữ liệu không được để trống.'
                }
            }
            break;
        case 'mota':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Dữ liệu không được để trống.'
                }
            }
            break;
        case 'NhanvienId':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Dữ liệu không được để trống.'
                }
            }
            break;
        case 'type_problem':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Dữ liệu không được để trống.'
                }
            }
            break;
        case 'ngaybatdau':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Dữ liệu không được để trống.'
                }
            }
            break;
        
        default:
            break;
    }
}