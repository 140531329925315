import { Link } from 'react-router-dom';
import config from '~/config';
function Footer() {
    return (
        <div>
            <div className="footer-area-wrapper bg-black">
                <div className="footer-area section-space--ptb_80">
                    <div className="container">
                        <div className="row footer-widget-wrapper">
                            <div className="col-lg-4 col-md-6 col-sm-6 footer-widget">
                                <div className="footer-widget__logo mb-30">
                                    <img src="./images/logo/primary-white-logo-160x47.webp" width="160" height="48" className="img-fluid" alt="" />
                                </div>
                                <ul className="footer-widget__list">
                                    <li className="text-white">58 Howard Street #2 San Francisco, CA 941</li>
                                    <li><a href="mailto:contact@aeroland.com" className="hover-style-link text-white">contact@aeroland.com</a></li>
                                    <li><a href="tel:123344556" className="hover-style-link text-white font-weight--bold">(+68)1221 09876</a></li>
                                    <li><a href="https://hasthemes.com/" className="hover-style-link text-white">www.mitech.xperts.com</a></li>
                                </ul>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 footer-widget">
                                <h6 className="footer-widget__title text-white mb-20">IT Services</h6>
                                <ul className="footer-widget__list">
                                    <li><a href="#" className="hover-style-link text-white">Managed IT</a></li>
                                    <li><a href="#" className="hover-style-link text-white">IT Support</a></li>
                                    <li><a href="#" className="hover-style-link text-white">IT Consultancy</a></li>
                                    <li><a href="#" className="hover-style-link text-white">Cloud Computing</a></li>
                                    <li><a href="#" className="hover-style-link text-white">Cyber Security</a></li>
                                </ul>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 footer-widget">
                                <h6 className="footer-widget__title text-white mb-20">Quick links</h6>
                                <ul className="footer-widget__list">
                                    <li><a href="#" className="hover-style-link text-white">Pick up locations</a></li>
                                    <li><a href="#" className="hover-style-link text-white">Terms of Payment</a></li>
                                    <li><a href="#" className="hover-style-link text-white">Privacy Policy</a></li>
                                    <li><a href="#" className="hover-style-link text-white">Where to Find Us</a></li>
                                </ul>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 footer-widget">
                                <h6 className="footer-widget__title mb-20">Support</h6>
                                <ul className="footer-widget__list">
                                    <li><a href="#" className="hover-style-link text-white">Forum Support</a></li>
                                    <li><a href="#" className="hover-style-link text-white">Help & FAQ</a></li>
                                    <li><a href="#" className="hover-style-link text-white">Contact Us</a></li>
                                    <li><a href="#" className="hover-style-link text-white">Pricing and plans</a></li>
                                    <li><a href="#" className="hover-style-link text-white">Cookies Policy</a></li>
                                </ul>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 footer-widget">
                                <div className="footer-widget__title section-space--mb_50"></div>
                                <ul className="footer-widget__list">
                                    <li><a href="#" className="image_btn"><img className="img-fluid" src="assets/images/icons/aeroland-button-google-play.webp" alt="" /></a></li>
                                    <li><a href="#" className="image_btn"><img className="img-fluid" src="assets/images/icons/aeroland-button-app-store.webp" alt="" /></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-copyright-area section-space--pb_30">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 text-center text-md-start">
                                <span className="copyright-text text-white">&copy; 2021 Mitech. <a href="https://hasthemes.com/">All Rights Reserved.</a></span>
                            </div>
                            <div className="col-md-6 text-center text-md-end">
                                <ul className="list ht-social-networks solid-rounded-icon">

                                    <li className="item">
                                        <a href="https://twitter.com"  aria-label="Twitter" className="social-link hint--bounce hint--top hint--primary">
                                            <i className="fab fa-twitter text-white link-icon"></i>
                                        </a>
                                    </li>
                                    <li className="item">
                                        <a href="https://facebook.com"  aria-label="Facebook" className="social-link hint--bounce hint--top hint--primary">
                                            <i className="fab fa-facebook-f text-white link-icon"></i>
                                        </a>
                                    </li>
                                    <li className="item">
                                        <a href="https://instagram.com"  aria-label="Instagram" className="social-link hint--bounce hint--top hint--primary">
                                            <i className="fab fa-instagram text-white link-icon"></i>
                                        </a>
                                    </li>
                                    <li className="item">
                                        <a href="https://linkedin.com"  aria-label="Linkedin" className="social-link hint--bounce hint--top hint--primary">
                                            <i className="fab fa-linkedin text-white link-icon"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
