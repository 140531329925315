import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, InputForm} from '~/components/Form';
import * as actions from '~/store/actions';
import '../Modal.scss';
import {dataSelectStatus} from "~/utils/Datacustom";
import Select from "react-select";
import {Modal, ModalBody} from 'reactstrap';
import Content from "~/layout/content/Content";
import {apiGetBienToanCucHeThong, apiGetThanhphanluong, apiNewCongthuctinhluong} from "~/services";
import {validateCongThucLuong} from "~/request/validateCongThucLuong";
import Icon from "~/components/icon/Icon";
import {SelectFinNhanvien} from "~/components/Component";
import Textarea from "~/components/Form/Textarea";
import Swal from "sweetalert2";
import {toast} from "react-toastify";

const ModalCongThucLuong = ({show, setShow, fieldList}) => {
    // const dispatch = useDispatch();
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayload] = useState(fieldList);
    const [showSelect, setShowSelect] = useState(false);
    const [listStatus, setlistStatus] = useState([]);
    const [dataBienToanCucHeThong, setdataBienToanCucHeThong] = useState([]);
    const [listNhanvien, setlistNhanvien] = useState([]);
    // const {phucap} = useSelector((state) => state.phucap);
    
    const fetchData = async () => {
        try {
            const datapayloads = {
                typeGet: 2, // 1: lấy theo điều kiện phân trang, 2: lấy tất cả và không cần truyền d liệu phân trang
            };
            const res = await apiGetThanhphanluong(datapayloads)
         
            const response = res.data.data.rows
            let dataSelect = response.map((item) => ({ label: item.name, value: item.id, type: item.type,code: item.code }));
            setdataBienToanCucHeThong(dataSelect)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    useEffect(() => {
        setPayload((prev) => ({
            ...prev,
            ['NhanvienId']: listNhanvien,
        }));
    }, [listNhanvien]);
    useEffect(() => {
        show && setPayload(fieldList);
        setlistStatus(dataSelectStatus);
        fetchData().then(r => 'err')
    }, [show]);


    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayload((prev) => ({...prev, [statename]: selected}));
       
    };
    const handleSubmit = async () => {
        let invalids = validate(payload);
        if (invalids === 0) {
          
            let datapayload = {
                data: [
                    {
                        tencongthuc: payload.tencongthuc,
                        mota: payload.mota,
                        TrangthaiId: payload?.TrangthaiId?.value,
                        NhanvienId: listNhanvien,
                        thanhPhanId: dataBienToanCucHeThong,
                    },
                ],
            };
            const result = await apiNewCongthuctinhluong(datapayload)
            console.log('result',result)
            if (result.data.success === 200) {
                toast.success(result.data.message);
                setShow(false)
            } else {
                toast.error("Cập nhật thất bại!");
            }
            // console.log('datapayload',datapayload)
            // dispatch(actions.NewCongthuctinhluong(datapayload));
        }
    };
    
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateCongThucLuong(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateCongThucLuong(item[0], item[1])]);
                invalids++;
            }
        });
        
        return invalids;
    };
    return (
        <React.Fragment>
            <Content>
                <Modal isOpen={show} toggle={setShow} className="modal-dialog-centered modal-90w">
                    <ModalBody>
                        <Icon name="cross-sm float-end fs-2" onClick={setShow}></Icon>
                        <h5 className="title mb-5"> Thêm mới công thức tính lương</h5>
                        
                        <div className="row mt-4">
                            <div className="form-group col-md-7">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Tên công thức'}
                                    value={payload.tencongthuc}
                                    setValue={setPayload}
                                    keyPayload={'tencongthuc'}
                                    type="text"
                                    validate
                                />
                            </div>
                            <div className="form-group col-md-5">
                                <label htmlFor="inputZip" className="mb-2 fw-bolder">
                                    Trạng thái
                                </label>
                                <Select
                                    value={payload?.TrangthaiId}
                                    onChange={handleOnSelect}
                                    options={listStatus}
                                    placeholder={'Chọn trạng tháí'}
                                    name="TrangthaiId"
                                    onFocus={() => setInvalidFields([])}
                                />
                                {invalidFields.length > 0 &&
                                    invalidFields.some((i) => i.name === 'TrangthaiId') && (
                                        <small className="text-danger">
                                            {
                                                invalidFields.find((i) => i.name === 'TrangthaiId')
                                                    ?.message
                                            }
                                        </small>
                                    )}
                            </div>
                            {/*<div className="form-group col-md-12">*/}
                            {/*    <label htmlFor="inputZip" className="mb-2 fw-bolder">*/}
                            {/*        Thêm biến loại thu nhập*/}
                            {/*    </label>*/}
                            {/*    <Select*/}
                            {/*        value={payload?.thanhPhanThuNhap}*/}
                            {/*        onChange={handleOnSelect}*/}
                            {/*        options={dataBienToanCucHeThong.filter((item) => item.type === 1)}*/}
                            {/*        placeholder={'Thêm biến vào công thức'}*/}
                            {/*        isMulti={true}*/}
                            {/*        name="thanhPhanThuNhap"*/}
                            {/*        onFocus={() => setInvalidFields([])}*/}
                            {/*    />*/}
                            {/*    {invalidFields.length > 0 &&*/}
                            {/*        invalidFields.some((i) => i.name === 'thanhPhanThuNhap') && (*/}
                            {/*            <small className="text-danger">*/}
                            {/*                {*/}
                            {/*                    invalidFields.find((i) => i.name === 'thanhPhanThuNhap')*/}
                            {/*                        ?.message*/}
                            {/*                }*/}
                            {/*            </small>*/}
                            {/*        )}*/}
                            {/*</div>*/}
                            {/*<div className="form-group col-md-12">*/}
                            {/*    <label htmlFor="inputZip" className="mb-2 fw-bolder">*/}
                            {/*        Thêm biến loại khấu trừ*/}
                            {/*    </label>*/}
                            {/*    <Select*/}
                            {/*        value={payload?.thanhPhanKhauTru}*/}
                            {/*        onChange={handleOnSelect}*/}
                            {/*        options={dataBienToanCucHeThong.filter((item) => item.type === 2)}*/}
                            {/*        placeholder={'Thêm biến vào công thức'}*/}
                            {/*        isMulti={true}*/}
                            {/*        name="thanhPhanKhauTru"*/}
                            {/*        onFocus={() => setInvalidFields([])}*/}
                            {/*    />*/}
                            {/*    {invalidFields.length > 0 &&*/}
                            {/*        invalidFields.some((i) => i.name === 'thanhPhanKhauTru') && (*/}
                            {/*            <small className="text-danger">*/}
                            {/*                {*/}
                            {/*                    invalidFields.find((i) => i.name === 'thanhPhanKhauTru')*/}
                            {/*                        ?.message*/}
                            {/*                }*/}
                            {/*            </small>*/}
                            {/*        )}*/}
                            {/*</div>*/}
                            {/*<div className="form-group col-md-12">*/}
                            {/*    <label htmlFor="inputZip" className="mb-2 fw-bolder">*/}
                            {/*        Thêm biến khác*/}
                            {/*    </label>*/}
                            {/*    <Select*/}
                            {/*        value={payload?.thanhPhanKhac}*/}
                            {/*        onChange={handleOnSelect}*/}
                            {/*        options={dataBienToanCucHeThong.filter((item) => item.type === 3)}*/}
                            {/*        placeholder={'Thêm biến vào công thức'}*/}
                            {/*        isMulti={true}*/}
                            {/*        name="thanhPhanKhac"*/}
                            {/*        onFocus={() => setInvalidFields([])}*/}
                            {/*    />*/}
                            {/*    {invalidFields.length > 0 &&*/}
                            {/*        invalidFields.some((i) => i.name === 'thanhPhanKhac') && (*/}
                            {/*            <small className="text-danger">*/}
                            {/*                {*/}
                            {/*                    invalidFields.find((i) => i.name === 'thanhPhanKhac')*/}
                            {/*                        ?.message*/}
                            {/*                }*/}
                            {/*            </small>*/}
                            {/*        )}*/}
                            {/*</div>*/}
                            <div className="form-group col-md-12" onClick={() => setShowSelect(true)}>
                                <label htmlFor="inputZip" className="mb-2 fw-bold">
                                    Nhân viên áp dụng
                                </label>
                                <Select
                                    value={payload.NhanvienId}
                                    name="NhanvienId"
                                    isMulti
                                    onFocus={() => setInvalidFields([])}
                                />
                                {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'NhanvienId') && (
                                    <small className="text-danger">
                                        {invalidFields.find((i) => i.name === 'NhanvienId')?.message}
                                    </small>
                                )}
                            </div>
                            <div className="form-group col-md-12">
                                <Textarea
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Mô tả'}
                                    value={payload.mota}
                                    setValue={setPayload}
                                    keyPayload={'mota'}
                                    type="text"
                                    validate
                                />
                            </div>
                        
                        </div>
                        
                        <div className="col-12 mt-5">
                            <Button text="Thêm Cách Tính" bClass="btn btn-primary px-5" textColor
                                    onClick={handleSubmit}/>
                        </div>
                    </ModalBody>
                </Modal>
            </Content>
            <SelectFinNhanvien
                showSelect={showSelect}
                typeSelect={true}
                listNhanvien={listNhanvien}
                setlistNhanvien={setlistNhanvien}
                typeSetting={'congthuc'} // 'chuyencan' || 'thamnien' || 'congthuc' || 'hoatdongsukien'
                setShowSelect={() => setShowSelect(false)}
            />
        </React.Fragment>
    )
    
};

export default ModalCongThucLuong;
