import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { InputForm } from '~/components/Form';
import * as actions from '~/store/actions';
import { validateMauHopDong } from '~/request/validateMauHopDong';
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import Dropzone from 'react-dropzone';
import { dataSelectMauhopdong, dataTruongMauhopdong } from '~/utils/Datacustom';
import { toast } from 'react-toastify';
import { bytesToMegaBytes } from '~/utils/Utils';
import {
    DataTableBody,
    DataTableHead,
    DataTableRow,
    DataTableItem,
    DataTable,
    Button,
    Icon,
} from '~/components/Component';

const ModalEditMauhopdong = ({ show, setShow, fieldList, dataIdMauhopdong }) => {
    const dispatch = useDispatch();
    const { loaihopdong } = useSelector((state) => state.hopdonglaodong);
    const [listLoaihopdong, setlistLoaihopdong] = useState([]);
    useEffect(() => {
        if (loaihopdong) {
            let dataSelectLoaihopdong = loaihopdong.map((item) => ({ label: item.tenloaihopdong, value: item.id }));
            setlistLoaihopdong(dataSelectLoaihopdong);
        }
    }, [loaihopdong]);
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayload] = useState(fieldList);

    const [files, setFiles] = useState([]);
    useEffect(() => {

        let LoaihopdongId = listLoaihopdong.find((items) => {
            return items && items.value === dataIdMauhopdong?.LoaihopdongId;
        });
        let TrangthaiId = dataSelectMauhopdong.find((items) => {
            return items && items.value === dataIdMauhopdong?.TrangthaiId;
        });

        show &&
        dataIdMauhopdong &&
            setPayload({
                mota: dataIdMauhopdong?.mota,
                myfilesdocs: dataIdMauhopdong?.myfilesdocs,
                linkfile: dataIdMauhopdong?.linkfile,
                LoaihopdongId: LoaihopdongId,
                TrangthaiId: TrangthaiId,
            });
    }, [dataIdMauhopdong, show]);
    const handleSubmit = async () => {
        let invalids = validate(payload);
        if (invalids === 0) {
            const formData = new FormData();
            // formData.append('TrangthaiId', payload.TrangthaiId.value);
            formData.append('id', dataIdMauhopdong?.id);
            formData.append('mota', payload.mota);
            formData.append('LoaihopdongId', payload.LoaihopdongId?.value);
            formData.append('TrangthaiId', payload.TrangthaiId?.value);
            formData.append('myfilesdocs', payload.myfilesdocs);
            formData.append('linkfile', payload.linkfile);
            // consoe.log('formData', formData)
            dispatch(actions.EditMauhopdong(formData));
        }
    };
    const handleDropChange = (acceptedFiles) => {
        const allowedFileType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        const docxFiles = acceptedFiles.filter((file) => file.type === allowedFileType);
        if (docxFiles.length === 0) {
            toast.error('Vui lòng nhập file .docx!');
            return;
        }
        const myfilesdocs = docxFiles[0];
        setPayload((prev) => ({ ...prev, myfilesdocs }));
        setFiles(acceptedFiles);

    };
    const removeFromList = (name) => {
        let defaultFiles = files;
        defaultFiles = defaultFiles.filter((item) => item.name !== name);
        setFiles([...defaultFiles]);
        const myfilesdocs = '';
        setPayload((prev) => ({ ...prev, myfilesdocs }));
    };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateMauHopDong(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateMauHopDong(item[0], item[1])]);
                invalids++;
            }
        });
        return invalids;
    };
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">Sửa mẫu hợp đồng</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-4">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Mô tả'}
                            value={payload.mota}
                            setValue={setPayload}
                            keyPayload={'mota'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                                Loại hợp đồng <b className="text-danger">(*)</b>
                            </span>
                        </label>
                        <Select
                            value={payload.LoaihopdongId}
                            onChange={(selected) => {
                                setPayload((prev) => ({
                                    ...prev,
                                    ['LoaihopdongId']: selected,
                                }));
                            }}
                            options={listLoaihopdong}
                            placeholder={'Chọn trạng thái'}
                            name="LoaihopdongId"
                            onFocus={() => setInvalidFields([])}
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'LoaihopdongId') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'LoaihopdongId')?.message}
                            </small>
                        )}
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                                Trạng thái 
                            </span>
                        </label>
                        <Select
                            value={payload.TrangthaiId}
                            onChange={(selected) => {
                                setPayload((prev) => ({
                                    ...prev,
                                    ['TrangthaiId']: selected,
                                }));
                            }}
                            options={dataSelectMauhopdong}
                            placeholder={'Chọn trạng thái'}
                            name="TrangthaiId"
                            onFocus={() => setInvalidFields([])}
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'TrangthaiId') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'TrangthaiId')?.message}
                            </small>
                        )}
                    </div>
                    {/* <div className="form-group col-md-6 ">
                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                                Trạng thái <b className="text-danger">(*)</b>
                            </span>
                        </label>
                        <Select
                            value={payload.TrangthaiId}
                            onChange={(selected) => {
                                setPayload((prev) => ({
                                    ...prev,
                                    ['TrangthaiId']: selected,
                                }));
                            }}
                            options={dataSelectActive}
                            placeholder={'Chọn trạng thái'}
                            name="TrangthaiId"
                            onFocus={() => setInvalidFields([])}
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'TrangthaiId') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'TrangthaiId')?.message}
                            </small>
                        )}
                    </div> */}
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-md-6 col-sm-12">
                        <div className="nk-upload-form mt-3">
                            <h5 className="title mb-3">File dữ liệu</h5>
                            <Dropzone onDrop={(acceptedFiles) => handleDropChange(acceptedFiles)}>
                                {({ getRootProps, getInputProps }) => (
                                    <section>
                                        <div
                                            {...getRootProps()}
                                            className="dropzone upload-zone small bg-lighter my-2 dz-clickable"
                                        >
                                            <input {...getInputProps()} name="file" />
                                            <div className="dz-message">
                                                <span className="dz-message-text">
                                                    <span>Kéo và thả </span> Tệp tin ở đây <span>hoặc chọn</span>
                                                </span>
                                            </div>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'linkfile') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'linkfile')?.message}
                                </small>
                            )}
                        </div>
                        <div className="nk-upload-list">
                            <h6 className="title">Tệp tin đã tải lên (Hỗ trợ file .doc và .docx)</h6>
                            {files.length > 0 ? (
                                files.map((file, index) => (
                                    <div className="nk-upload-item" key={index}>
                                        <div className="nk-upload-icon"></div>
                                        <div className="nk-upload-info">
                                            <div className="nk-upload-title">
                                                <span className="title">{file.name}</span>
                                            </div>
                                            <div className="nk-upload-size">{bytesToMegaBytes(file.size)} MB</div>
                                        </div>
                                        <div className="nk-upload-action">
                                            <a
                                                href="#delete"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    removeFromList(file.name);
                                                }}
                                                className="btn btn-icon btn-trigger"
                                            >
                                                <Icon name="trash"></Icon>
                                            </a>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="d-flex justify-center">
                                    <span>Chưa có tệp nào được thêm vào !</span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="form-group col-lg-6 col-md-6 col-sm-12 mt-3">
                        <h5 className="title mb-3">Danh sách trường tùy chỉnh</h5>
                        <DataTable className="card-stretch">
                            <div style={{ overflowY: 'hidden' }}>
                                <DataTableBody>
                                    {dataTruongMauhopdong.map((item, it) => {
                                        return (
                                            <DataTableItem key={it}>
                                                <DataTableRow>
                                                    <span className="sub-text">{item.title}</span>
                                                </DataTableRow>
                                                <DataTableRow>
                                                    <span className="sub-text">
                                                        <Icon name="piority-fill"></Icon>
                                                    </span>
                                                </DataTableRow>
                                                <DataTableRow>
                                                    <span className="sub-text">{item.des}</span>
                                                </DataTableRow>
                                            </DataTableItem>
                                        );
                                    })}
                                </DataTableBody>
                            </div>
                        </DataTable>
                    </div>
                </div>

                <div className="col-12 mt-5">
                    <Button color="primary" size="lg" className="btn-mw mx-1" onClick={() => handleSubmit()}>
                    Cập nhật
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalEditMauhopdong;
