export { default as ModalPhongban } from './Phongban/ModalPhongban'
export { default as ModalEditPhongban } from './Phongban/ModalEditPhongban'
export { default as ModalChucvu } from './Chucvu/ModalChucvu'
export { default as ModalEditChucvu } from './Chucvu/ModalEditChucvu'
export { default as ModalLoaiquydinhphucloi } from './Quydinhphucloi/ModalLoaiquydinhphucloi'
export { default as ModalEditLoaiquydinhphucloi } from './Quydinhphucloi/ModalEditLoaiquydinhphucloi'
export { default as ModalUngvien } from './Ungvien/ModalUngvien'
export { default as ModalEditUngvien } from './Ungvien/ModalEditUngvien'
export { default as ModalDanhgia } from './Danhgia/ModalDanhgia'
export { default as ModalEditDanhgia } from './Danhgia/ModalEditDanhgia'
export { default as ModalDanhgianhanvien } from './Danhgianhanviens/ModalDanhgianhanviens'
export { default as ModalEditDanhgianhanvien } from './Danhgianhanviens/ModalEditDanhgianhanviens'

export { default as ModalCongThucLuong } from './CongThucTinhLuong/ModalCongThucLuong'
export { default as ModalEditCongThucLuong } from './CongThucTinhLuong/ModalEditCongThucLuong'
export { default as ModalNhanVienInCongThucLuong } from './CongThucTinhLuong/ModalNhanVienInCongThucLuong'

export { default as ModalKieuLuong } from './KieuLuong/ModalKieuLuong'
export { default as ModalEditKieuLuong } from './KieuLuong/ModalEditKieuLuong'

export { default as ModalNhanvien } from './Nhanvien/ModalNhanvien'
// export { default as ModalEditNhanvien } from './Nhanvien/ModalEditNhanvien'
export { default as ModalAddTaisan } from './Nhanvien/ModalAddTaisan'
export { default as ModalAddTangca } from './Nhanvien/ModalAddTangca'
export { default as ModalAddPhuCap } from './Nhanvien/ModalAddPhuCap'
export { default as ModalMcc } from './Nhanvien/ModalMcc'
export { default as ModalAddBank } from './Nhanvien/ModalAddBank'
export { default as ModalEditBank } from './Nhanvien/ModalEditBank'
export { default as ModalQuydinhthuongphat } from './Quydinhthuongphat/ModalQuydinhthuongphat'
export { default as ModalEditQuydinhthuongphat } from './Quydinhthuongphat/ModalEditQuydinhthuongphat'
// export { default as ModalEditTangca } from './Tangca/ModalEditTangca'

export { default as ModalBocauhoiphongvan } from './Bocauhoiphongvan/ModalBocauhoiphongvan'
export { default as ModalEditBocauhoiphongvan } from './Bocauhoiphongvan/ModalEditBocauhoiphongvan'

export { default as ModalCauhinhnghile } from './Cauhinhnghile/ModalCauhinhnghile'
export { default as ModalEditCauhinhnghile } from './Cauhinhnghile/ModalEditCauhinhnghile'
export { default as ModalDetailCauhinhnghile } from './Cauhinhnghile/ModalDetailCauhinhnghile'

export { default as ModalCauhinhcalamviec } from './Cauhinhcalamviec/ModalCauhinhcalamviec'
export { default as ModalEditCauhinhcalamviec } from './Cauhinhcalamviec/ModalEditCauhinhcalamviec'

export { default as ModalGiaoviec } from './Giaoviec/ModalGiaoviec'
export { default as ModalEditGiaoviec } from './Giaoviec/ModalEditGiaoviec'

export { default as ModalNghiphepcongtac } from './Nghiphepcongtac/ModalNghiphepcongtac'
export { default as ModalEditNghiphepcongtac } from './Nghiphepcongtac/ModalEditNghiphepcongtac'
export { default as ModalSelectType } from './Nghiphepcongtac/ModalSelectType'

export { default as ModalSuatan } from './Suatan/ModalSuatan'
export { default as ModalEditSuatan } from './Suatan/ModalEditSuatan'
export { default as ModalLoaisuatan } from './Suatan/ModalLoaisuatan'
export { default as ModalEditLoaisuatan } from './Suatan/ModalEditLoaisuatan'

export { default as ModalVanban } from './Vanban/ModalVanban'
export { default as ModalEditVanban } from './Vanban/ModalEditVanban'

export { default as ModalVitrituyendung } from './Vitrituyendung/ModalVitrituyendung'
export { default as ModalEditVitrituyendung } from './Vitrituyendung/ModalEditVitrituyendung'

export { default as ModalHopdonglaodong } from './Hopdonglaodong/ModalHopdonglaodong'
export { default as ModalEditHopdonglaodong } from './Hopdonglaodong/ModalEditHopdonglaodong'
export { default as ModalDownloadHopdonglaodong } from './Hopdonglaodong/ModalDownloadHopdonglaodong'

export { default as ModalLoaihopdong } from './Loaihopdong/ModalLoaihopdong'
export { default as ModalEditLoaihopdong } from './Loaihopdong/ModalEditLoaihopdong'

export { default as ModalBinhluan } from './Binhluan/ModalBinhluan'
export { default as ModalEditBinhluan } from './Binhluan/ModalEditBinhluan'

export { default as ModalDexuat } from './Dexuat/ModalDexuat'
export { default as ModalEditDexuat } from './Dexuat/ModalEditDexuat'
export { default as ModalSelectDexuat } from './Dexuat/ModalSelectDexuat'

export { default as ModalTaisan } from './Taisan/ModalTaisan'
export { default as ModalEditTaisan } from './Taisan/ModalEditTaisan'

export { default as ModalPhucap } from './Phucap/ModalPhucap'
export { default as ModalEditPhucap } from './Phucap/ModalEditPhucap'

export { default as ModalCauhinhtangca } from './Cauhinhtangca/ModalCauhinhtangca'
export { default as ModalEditCauhinhtangca } from './Cauhinhtangca/ModalEditCauhinhtangca'

// export { default as ModalCauhinhtrangthai } from './Cauhinhtrangthai/ModalCauhinhtrangthai'
// export { default as ModalEditCauhinhtrangthai } from './Cauhinhtrangthai/ModalEditCauhinhtrangthai'

export { default as ModalBangchamcong } from './Bangchamcong/ModalBangchamcong'
export { default as ModalEditCong } from './Bangchamcong/ModalEditCong'
export { default as ModalLog } from './Bangchamcong/ModalLog'
export { default as ModalHistory } from './Bangchamcong/ModalHistory'
export { default as ModalEditCongTangCa } from './Bangchamcong/ModalEditCongTangCa'
export { default as ModalEditBangchamcong } from './Bangchamcong/ModalEditBangchamcong'

export { default as ModalBangchotcong } from './Bangchotcong/ModalBangchotcong'
export { default as ModalEditBangchotcong } from './Bangchotcong/ModalEditBangchotcong'
export { default as ModalBangchamcongtonghop } from './Bangchamcongtonghop/ModalBangchamcongtonghop'
export { default as ModalEditBangchamcongtonghop } from './Bangchamcongtonghop/ModalEditBangchamcongtonghop'

export { default as ModalChinhanh } from './Chinhanh/ModalChinhanh'
export { default as ModalEditChinhanh } from './Chinhanh/ModalEditChinhanh'

export { default as ModalTamungluong } from './Tamungluong/ModalTamungluong'
export { default as ModalEditTamungluong } from './Tamungluong/ModalEditTamungluong'

export { default as ModalBaohiemnhanvien } from './Baohiemnhanvien/ModalBaohiemnhanvien'
export { default as ModalEditBaohiemnhanvien } from './Baohiemnhanvien/ModalEditBaohiemnhanvien'

export { default as ModalCauhinhthuethunhapcnlt } from './Cauhinhthuethunhapcnlt/ModalCauhinhthuethunhapcnlt'
export { default as ModalEditCauhinhthuethunhapcnlt } from './Cauhinhthuethunhapcnlt/ModalEditCauhinhthuethunhapcnlt'

// export { default as ModalDanhgianhanvien } from './Danhgianhanvien/ModalDanhgianhanvien'
// export { default as ModalEditDanhgianhanvien } from './Danhgianhanvien/ModalEditDanhgianhanvien'

export { default as ModalHoatdongsukien } from './Hoatdongsukien/ModalHoatdongsukien'
export { default as ModalEditHoatdongsukien } from './Hoatdongsukien/ModalEditHoatdongsukien'

export { default as ModalKiemdinhatvsbhld } from './Kiemdinhatvsbhld/ModalKiemdinhatvsbhld'
export { default as ModalEditKiemdinhatvsbhld } from './Kiemdinhatvsbhld/ModalEditKiemdinhatvsbhld'
export { default as ModalDetail } from './Kiemdinhatvsbhld/ModalDetail'

export { default as ModalLoaikiemdinhatvsbhld } from './Loaikiemdinhatvsbhld/ModalLoaikiemdinhatvsbhld'
export { default as ModalEditLoaikiemdinhatvsbhld } from './Loaikiemdinhatvsbhld/ModalEditLoaikiemdinhatvsbhld'

export { default as ModalMoiquanhelaodong } from './Moiquanhelaodong/ModalMoiquanhelaodong'
export { default as ModalEditMoiquanhelaodong } from './Moiquanhelaodong/ModalEditMoiquanhelaodong'

export { default as ModalMoiquanhenhanvien } from './Moiquanhenhanvien/ModalMoiquanhenhanvien'
export { default as ModalEditMoiquanhenhanvien } from './Moiquanhenhanvien/ModalEditMoiquanhenhanvien'

export { default as ModalNhanvienkiemdinhatvsbhld } from './Nhanvienkiemdinhatvsbhld/ModalNhanvienkiemdinhatvsbhld'
export { default as ModalEditNhanvienkiemdinhatvsbhld } from './Nhanvienkiemdinhatvsbhld/ModalEditNhanvienkiemdinhatvsbhld'
//!<---- START PHƯƠNG TIỆN---->
export { default as ModalPhuongtien } from './Phuongtien/ModalPhuongtien'
export { default as ModalInfo } from './Phuongtien/ModalInfo'
export { default as ModalEditPhuongtien } from './Phuongtien/ModalEditPhuongtien'
//!<---- END PHƯƠNG TIỆN---->

//!<---- START LOẠI PHƯƠNG TIỆN---->
export { default as ModalLoaiphuongtien } from './Loaiphuongtien/ModalLoaiphuongtien'
export { default as ModalEditLoaiphuongtien } from './Loaiphuongtien/ModalEditLoaiphuongtien'
//!<---- END LOẠI PHƯƠNG TIỆN---->

// !<---- START Phụ phí---->
export { default as ModalPhiphu } from './Phiphu/ModalPhiphu'
export { default as ModalEditPhiphu } from './Phiphu/ModalEditPhiphu'
//!<---- END Phụ phí---->

// !<---- START Kế hoặch của xe---->
export { default as ModalKehoachcuaxe } from './Kehoachcuaxe/ModalKehoachcuaxe'
export { default as ModalEditKehoachcuaxe } from './Kehoachcuaxe/ModalEditKehoachcuaxe'
//!<---- END Kế hoặch của xe---->


// !<---- START Đăng kiểm phương tiện---->
export { default as ModalDangkiemphuongtien } from './Dangkiemphuongtien/ModalDangkiemphuongtien'
export { default as ModalEditDangkiemphuongtien } from './Dangkiemphuongtien/ModalEditDangkiemphuongtien'
//!<---- END Đăng kiểm phương tiện---->

// !<---- START Bảo dưỡng phương tiện--->
export { default as ModalBaoduongphuongtien } from './Baoduongphuongtien/ModalBaoduongphuongtien'
export { default as ModalEditBaoduongphuongtien } from './Baoduongphuongtien/ModalEditBaoduongphuongtien'
//!<---- END Bảo dưỡng phương tiện---->

export { default as ModalQuanlyquythuong } from './Quanlyquythuong/ModalQuanlyquythuong'
export { default as ModalEditQuanlyquythuong } from './Quanlyquythuong/ModalEditQuanlyquythuong'

export { default as ModalQuanlythamnien } from './Quanlythamnien/ModalQuanlythamnien'
export { default as ModalExportExcell } from './Quanlythamnien/ModalExportExcell'
export { default as ModalEditQuanlythamnien } from './Quanlythamnien/ModalEditQuanlythamnien'

export { default as ModalThongbaonoibo } from './Thongbaonoibo/ModalThongbaonoibo'
export { default as ModalEditThongbaonoibo } from './Thongbaonoibo/ModalEditThongbaonoibo'

export { default as ModalVanthu } from './Vanthu/ModalVanthu'
export { default as ModalEditVanthu } from './Vanthu/ModalEditVanthu'

export { default as ModalXeploai } from './Xeploai/ModalXeploai'
export { default as ModalEditXeploai } from './Xeploai/ModalEditXeploai'

export { default as ModalXeploainhanvien } from './Xeploainhanvien/ModalXeploainhanvien'
export { default as ModalEditXeploainhanvien } from './Xeploainhanvien/ModalEditXeploainhanvien'

export { default as ModalNhomnv } from './Nhomnv/ModalNhomnv'
export { default as ModalEditNhomnv } from './Nhomnv/ModalEditNhomnv'

export { default as ModalMaychamcong } from './Maychamcong/ModalMaychamcong'
export { default as ModalEditMaychamcong } from './Maychamcong/ModalEditMaychamcong'

export { default as ModalLydonghiphep } from './Lydonghiphep/ModalLydonghiphep'
export { default as ModalEditLydonghiphep } from './Lydonghiphep/ModalEditLydonghiphep'

export { default as ModalMauhopdong } from './Mauhopdong/ModalMauhopdong'
export { default as ModalEditMauhopdong } from './Mauhopdong/ModalEditMauhopdong'

export { default as ModalPhieutangca } from './Phieutangca/ModalPhieutangca'
export { default as ModalEditPhieutangca } from './Phieutangca/ModalEditPhieutangca'

export { default as ModalPhieudaychuyen } from './Phieudaychuyen/ModalPhieudaychuyen'
export { default as ModalEditPhieudaychuyen } from './Phieudaychuyen/ModalEditPhieudaychuyen'

export { default as ModalPhanca } from './Phanca/ModalPhanca'
export { default as ModalEditPhanca } from './Phanca/ModalEditPhanca'

export { default as ModalChuyenCan } from './Chuyencan/Modal'
export { default as ModalEditChuyenCan } from './Chuyencan/ModalEdit'
export { default as ModalUpdateChuyenCan } from './Chuyencan/ModalUpdateNhanVien'

export { default as ModalThamNien } from './Thamnien/Modal'
export { default as ModalEditThamNien } from './Thamnien/ModalEdit'

export { default as ModalUpdateThamNien } from './Thamnien/ModalUpdateNhanVien'
export { default as ModalKhac } from './Cauhinhdaychuyen/Modal'
export { default as ModalEditKhac } from './Cauhinhdaychuyen/ModalEdit'
export { default as ModalSanLuong } from './Cauhinhsanluong/Modal'
export { default as ModalEditSanLuong } from './Cauhinhsanluong/ModalEdit'

export { default as ModalThanhPhanLuong } from './Cauhinhthanhphanluong/ModalKhac'
export { default as ModalEditThanhPhanLuong } from './Cauhinhthanhphanluong/ModalEditKhac'

export { default as ModalSanLuongNV } from './Sanluongnhanvien/Modal'
export { default as ModalEditSanLuongNV } from './Sanluongnhanvien/ModalEdit'

export { default as ModalCauhinhpheduyet } from './Cauhinhpheduyet/ModalCauhinhpheduyet'
export { default as ModalEditCauhinhpheduyet } from './Cauhinhpheduyet/ModalEditCauhinhpheduyet'

export { default as ModalQuyetdinh } from './Quyetdinh/ModalQuyetdinh'
export { default as ModalEditQuyetdinh } from './Quyetdinh/ModalEditQuyetdinh'