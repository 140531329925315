import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '~/store/actions';
import { ModalLydonghiphep, ModalEditLydonghiphep } from './Modal';
import Swal from 'sweetalert2';
import exportFromJSON from 'export-from-json';
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalBody,
    UncontrolledDropdown,
    Spinner,
    Card,
} from 'reactstrap';
import Head from '~/layout/head/Head';
import Content from '~/layout/content/Content';
import { Block } from '~/components/block/Block';
import {
    Button,
    Col,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    Icon,
    PaginationComponent,
    Row,
    RSelect,
    TooltipComponent,
    PermissionUtils,
    ExportXlsx,
} from '~/components/Component';
import { MenuNghiphepNghile } from './MenuTab/Menucontent';

const Lydonghiphep = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.getListLydonghiphep());
    }, []);
    const [show, setShow] = useState(false);
    const [showEditLydonghiphep, setShowEditLydonghiphep] = useState(false);
    const { lydonghiphep } = useSelector((state) => state.quanly);
    const [data, setData] = useState(lydonghiphep);
    // console.log('data', data)
    const fieldList = {
        tieude: '',
        songaynghitoida: '',
        tylehuongluong: '',
        ghichu: '',
        TrangthaiId: '',
    };
    useEffect(() => {
        if (lydonghiphep && lydonghiphep.length > 0) {
            setData([...lydonghiphep]);
        }
    }, [lydonghiphep]);

    const [showPhanquyen, setShowPhanquyen] = useState({});
    let dataPermissionUtils = PermissionUtils('lydonghiphep', 'all');
    useEffect(() => {
        if (dataPermissionUtils) {
            setShowPhanquyen(dataPermissionUtils);
        }
    }, [dataPermissionUtils]);

    const namefile = 'Thông tin lý do nghỉ phép';
    const header = ['Stt', 'Tiêu đề', 'Số ngày nghỉ tối đa', 'Trạng thái'];
    const [columns, setColumns] = useState(() => {
        const d = header.map((item, index) => index <= 6);
        return d;
    });
    const [dataFileMau, setDataFileMau] = useState([]);
    useEffect(() => {
        if (data.length > 0) {
            const datafilemau = data.map((item, index) => {
                return {
                    ...(columns[0] && { Stt: index + 1 }),
                    ...(columns[1] && { 'Tiêu đề': item?.title }),
                    ...(columns[2] && { 'Số ngày nghỉ tối đa': item?.mota }),
                    ...(columns[3] && { 'Trạng thái': item?.TrangthaiId === '1' ? 'Hoạt Động' : 'Dừng Hoạt Động' }),
                };
            });
            setDataFileMau(datafilemau);
        }
    }, [data, columns]);

    const [sm, updateSm] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
   
    const selectorCheck = (e) => {
        let newData;
        newData = data.map((item) => {
            item.checked = e.currentTarget.checked;
            return item;
        });
        setData([...newData]);
    };
    const onSelectChange = (e, id) => {
        let newData = data;
        let index = newData.findIndex((item) => item.id === id);
        newData[index].checked = e.currentTarget.checked;
        setData([...newData]);
    };
 
    // Get current list, pagination
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    useEffect(() => {
        (lydonghiphep && setShow(false)) || setShowEditLydonghiphep(false);
    }, [lydonghiphep]);
    const handleAddNewLydonghiphep = async () => {
        setShow(true);
    };
    const [dataIdLydonghiphep, setDataIdLydonghiphep] = useState([]);
    const handleEditLydonghiphep = async (data) => {
        setShowEditLydonghiphep(true);
        await setDataIdLydonghiphep(data);
    };
    const handleDeleteLydonghiphep = (data) => {
        Swal.fire({
            title: 'Bạn có chắc không?',
            text: 'Bạn sẽ không thể lấy lại khi xóa!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Không, bỏ qua!',
            confirmButtonText: 'Vâng, xóa nó đi!',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(actions.DeleteLydonghiphep(data));
            }
        });
    };
    return (
        <React.Fragment>
            <Head title="Doanh nghiệp, Chi nhánh, Phòng ban, Chức Vụ"></Head>
            <Content>
                <Card className="mt-0">
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner card-inner-lg px-4">
                            <h4>Cấu hình nghỉ ngày phép, nghỉ phép, nghỉ lễ, công tác</h4>
                            <MenuNghiphepNghile />
                        </div>
                    </DataTable>
                </Card>
                <Block>
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner position-relative card-tools-toggle pt-0">
                            <div className="card-title-group">
                                <div className="card-title">
                                    <h5 className="title">Danh sách</h5>
                                    {+showPhanquyen?.them === 1 && (
                                        <Button
                                            color="primary"
                                            className="btn-icon px-4 mt-2"
                                            onClick={handleAddNewLydonghiphep}
                                        >
                                            <Icon name="plus"></Icon> Thêm mới
                                        </Button>
                                    )}
                                </div>
                                <div className="card-tools">
                                    <ul className="btn-toolbar gx-1">
                                        <li>
                                                <div className="toggle-wrap nk-block-tools-toggle">
                                                    <Button
                                                        className={`btn-icon btn-trigger toggle-expand me-n1 ${
                                                            sm ? 'active' : ''
                                                        }`}
                                                        onClick={() => updateSm(!sm)}
                                                    >
                                                        <Icon name="upload-cloud"></Icon>
                                                    </Button>
                                                    <div
                                                        className="toggle-expand-content-cloud"
                                                        style={{ display: sm ? 'block' : 'none' }}
                                                    >
                                                        <ul className="nk-block-tools g-3">
                                                            <li className="nk-block-tools-opt">
                                                                <ExportXlsx
                                                                    dataFileMau={dataFileMau}
                                                                    title={header}
                                                                    namefile={namefile}
                                                                    btnname={'Xuất dữ liệu'}
                                                                />
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div style={{ overflowY: 'hidden' }}>
                        <DataTableBody>
                            <DataTableHead>
                                {columns[0] && (
                                    <DataTableRow>
                                        <span className="sub-text">Stt</span>
                                    </DataTableRow>
                                )}
                                <DataTableRow className="nk-tb-col-check">
                                    <div className="custom-control custom-control-sm custom-checkbox notext">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            onChange={(e) => selectorCheck(e)}
                                            id="uid"
                                        />
                                        <label className="custom-control-label" htmlFor="uid"></label>
                                    </div>
                                </DataTableRow>
                                {columns[1] && (
                                    <DataTableRow>
                                        <span className="sub-text w-150px">Tiêu đề</span>
                                    </DataTableRow>
                                )}
                                {columns[2] && (
                                    <DataTableRow>
                                        <span className="sub-text w-150px">Số ngày nghỉ tối đa</span>
                                    </DataTableRow>
                                )}
                                <DataTableRow>
                                    <span className="sub-text w-150px">Tỷ lệ hưởng lương (%)</span>
                                </DataTableRow>
                                {columns[3] && (
                                    <DataTableRow>
                                        <span className="sub-text w-100px">Trạng thái</span>
                                    </DataTableRow>
                                )}
                                <DataTableRow className="nk-tb-col-tools text-end">
                                    <UncontrolledDropdown>
                                        <DropdownToggle
                                            color="tranparent"
                                            className="btn btn-xs btn-outline-light btn-icon dropdown-toggle"
                                        >
                                            <Icon name="plus"></Icon>
                                        </DropdownToggle>
                                        <DropdownMenu end className="dropdown-menu-xs">
                                            <ul className="link-tidy sm no-bdr">
                                                {header.length > 0 ? (
                                                    header.map((item, index) => (
                                                        <li key={index}>
                                                            <div className="custom-control custom-control-sm custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id={`column-${index}`}
                                                                    checked={columns[index]}
                                                                    onChange={(e) =>
                                                                        setColumns((prev) => {
                                                                            const newColumns = [...prev];
                                                                            newColumns[index] = e.target.checked;
                                                                            return newColumns;
                                                                        })
                                                                    }
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor={`column-${index}`}
                                                                >
                                                                    {item}
                                                                </label>
                                                            </div>
                                                        </li>
                                                    ))
                                                ) : (
                                                    <li>No data</li>
                                                )}
                                            </ul>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </DataTableRow>
                            </DataTableHead>
                            {currentItems.length > 0
                                ? currentItems.map((item, index) => {
                                      return (
                                          <DataTableItem key={item.id}>
                                              {columns[0] && (
                                                  <DataTableRow size=" nk-tb-col-check mb">
                                                      <span className="tb-amount">{index + 1}</span>
                                                  </DataTableRow>
                                              )}
                                              <DataTableRow className="nk-tb-col-check">
                                                  <div className="custom-control custom-control-sm custom-checkbox notext">
                                                      <input
                                                          type="checkbox"
                                                          className="custom-control-input"
                                                          defaultChecked={item.checked}
                                                          id={item.id + 'uid1'}
                                                          key={Math.random()}
                                                          onChange={(e) => onSelectChange(e, item.id)}
                                                      />
                                                      <label
                                                          className="custom-control-label"
                                                          htmlFor={item.id + 'uid1'}
                                                      ></label>
                                                  </div>
                                              </DataTableRow>
                                              {columns[1] && (
                                                  <DataTableRow>
                                                      <span className="tb-amount">{item?.tieude}</span>
                                                  </DataTableRow>
                                              )}
                                              {columns[2] && (
                                                  <DataTableRow>
                                                      <span className="tb-amount">{item?.songaynghitoida}</span>
                                                  </DataTableRow>
                                              )}
                                              <DataTableRow>
                                                  <span className="tb-amount">{item?.tylehuongluong}</span>
                                              </DataTableRow>
                                              {columns[3] && (
                                                  <DataTableRow>
                                                      <span
                                                          className={`${
                                                              item?.TrangthaiId === 1 ? 'bg-success' : 'bg-danger'
                                                          } ms-0 badge text-white`}
                                                      >
                                                          {item?.TrangthaiId !== 1 ? 'Chưa Duyệt' : 'Đã Duyệt'}
                                                      </span>
                                                  </DataTableRow>
                                              )}
                                              <DataTableRow className="nk-tb-col-tools">
                                                  <ul className="nk-tb-actions gx-1">
                                                      {+showPhanquyen?.sua === 1 && (
                                                          <li
                                                              className="nk-tb-action-hidden"
                                                              onClick={() => handleEditLydonghiphep(item)}
                                                          >
                                                              <TooltipComponent
                                                                  tag="a"
                                                                  containerClassName="btn btn-trigger btn-icon"
                                                                  id={'edit' + item.id}
                                                                  icon="edit-alt-fill"
                                                                  direction="top"
                                                                  text="Sửa"
                                                              />
                                                          </li>
                                                      )}
                                                      {+showPhanquyen?.xoa === 1 && item?.is_default !== 1 && (
                                                          <React.Fragment>
                                                              <li
                                                                  className="nk-tb-action-hidden"
                                                                  onClick={() => handleDeleteLydonghiphep(item)}
                                                              >
                                                                  <TooltipComponent
                                                                      tag="a"
                                                                      containerClassName="btn btn-trigger btn-icon"
                                                                      id={'suspend' + item.id}
                                                                      icon="user-cross-fill"
                                                                      direction="top"
                                                                      text="Xóa"
                                                                  />
                                                              </li>
                                                          </React.Fragment>
                                                      )}
                                                      <li>
                                                          <UncontrolledDropdown>
                                                              {(+showPhanquyen?.sua === 1 ||
                                                                  +showPhanquyen?.xoa === 1) && (
                                                                  <DropdownToggle
                                                                      tag="a"
                                                                      className="dropdown-toggle btn btn-icon btn-trigger"
                                                                  >
                                                                      <Icon name="more-h"></Icon>
                                                                  </DropdownToggle>
                                                              )}
                                                              <DropdownMenu end>
                                                                  <ul className="link-list-opt no-bdr">
                                                                      {+showPhanquyen?.sua === 1 && (
                                                                          <li
                                                                              onClick={() =>
                                                                                  handleEditLydonghiphep(item)
                                                                              }
                                                                          >
                                                                              <DropdownItem
                                                                                  tag="a"
                                                                                  href="#edit"
                                                                                  onClick={(ev) => {
                                                                                      ev.preventDefault();
                                                                                  }}
                                                                              >
                                                                                  <Icon name="edit"></Icon>
                                                                                  <span>Sửa</span>
                                                                              </DropdownItem>
                                                                          </li>
                                                                      )}
                                                                      {+showPhanquyen?.xoa === 1 && item?.is_default !== 1 &&(
                                                                          <React.Fragment>
                                                                              <li className="divider"></li>
                                                                              <li
                                                                                  onClick={() =>
                                                                                      handleDeleteLydonghiphep(item)
                                                                                  }
                                                                              >
                                                                                  <DropdownItem
                                                                                      tag="a"
                                                                                      href="#suspend"
                                                                                      onClick={(ev) => {
                                                                                          ev.preventDefault();
                                                                                      }}
                                                                                  >
                                                                                      <Icon name="na"></Icon>
                                                                                      <span>Xóa</span>
                                                                                  </DropdownItem>
                                                                              </li>
                                                                          </React.Fragment>
                                                                      )}
                                                                  </ul>
                                                              </DropdownMenu>
                                                          </UncontrolledDropdown>
                                                      </li>
                                                  </ul>
                                              </DataTableRow>
                                          </DataTableItem>
                                      );
                                  })
                                : null}
                        </DataTableBody>
                        </div>
                        <div className="card-inner">
                            {currentItems.length > 0 ? (
                                <PaginationComponent
                                    itemPerPage={itemPerPage}
                                    totalItems={lydonghiphep.length}
                                    paginate={paginate}
                                    currentPage={currentPage}
                                />
                            ) : (
                                <div className="text-center">
                                    <div>
                                        <Spinner color="primary" />
                                    </div>
                                    <span className="text-silent">Chưa có dữ liệu!</span>
                                </div>
                            )}
                        </div>
                    </DataTable>
                </Block>

                <ModalLydonghiphep show={show} fieldList={fieldList} setShow={() => setShow(false)} />
                <ModalEditLydonghiphep
                    show={showEditLydonghiphep}
                    fieldList={fieldList}
                    setShow={() => setShowEditLydonghiphep(false)}
                    dataIdLydonghiphep={dataIdLydonghiphep}
                />
            </Content>
        </React.Fragment>
    );
};

export default Lydonghiphep;
