import {Card, Table} from "reactstrap";
import React from "react";
import ColumnChart from "~/components/partials/analytics/chart-column/ColumnChart";

export const ComponentChiPhiNhanSu = (data) => {
    const dataShow = data?.dataChiPhiNhanSu?.dataDaotaoNhanSuP
    const datatotal_month = data?.dataChiPhiNhanSu?.total_month
    const dataDaoTaoNhanSuTotal = data?.dataChiPhiNhanSu?.dataArr
    const dataChart = data?.dataChiPhiNhanSu?.luongDataTmpMulChart
    const formatVND = (data) => {
        return Number(data ?? 0).toLocaleString('it-IT', {
            style: 'currency',
            currency: 'vnd',
        });
    };
    return <>
        <div className="col-12">
            
            <Table className="table table-bordered table-striped table-hover">
                <thead key="thead2">
                <tr>
                    <th className="w-25">Phòng ban</th>
                    {datatotal_month?.length > 0
                        ? datatotal_month?.map((item) => {
                            return (
                                <th className="text-center ">{item}</th>
                            )
                        })
                        : null}
                    <th className="text-center">Tổng cộng</th>
                </tr>
                </thead>
                <tbody key="tbody2">
                {dataShow?.length > 0
                    ? dataShow?.map((item) => {
                        return (
                            <tr>
                                <td
                                    className="fw-bold position-relative">
                                    <div className="position-absolute"
                                         style={{top: "calc(50% - 10px)"}}>{item.namePhongban}
                                    </div>
                                </td>
                                {item?.data?.length > 0
                                    ? item?.data?.map((items) => {
                                        return (
                                            <React.Fragment>
                                                <td className="text-end">{formatVND(items.data)}</td>
                                            </React.Fragment>
                                        )
                                    })
                                    : null}
                                <td className="text-end">{formatVND(item?.data.reduce((a, v) => a + Number(v.data), 0).toFixed(0))}</td>
                            </tr>
                        )
                    })
                    : null}
                
                <tr>
                    <td
                        className="fw-bold position-relative">
                        <div className="position-absolute"
                             style={{top: "calc(50% - 10px)"}}>Tổng cộng
                        </div>
                    </td>
                    {dataDaoTaoNhanSuTotal?.length > 0
                        ? dataDaoTaoNhanSuTotal?.map((items) => {
                            return (
                                <React.Fragment>
                                    <th className="text-end">{formatVND(items.dd)}</th>
                                </React.Fragment>
                            )
                        })
                        : null}
                    <th className="text-end">{formatVND(dataDaoTaoNhanSuTotal?.reduce((a, v) => a + Number(v.dd), 0).toFixed(0))}</th>
                </tr>
                
                </tbody>
            </Table>
        
        </div>
        <div className="col-12">
            <div className="py-3">
                <h6 className="overline-title text-primary">
                    Biểu đồ chi phí nhân sự
                </h6>
            </div>
            <Card className="pt-4">
                <ColumnChart
                    columnChart={dataChart}
                />
            </Card>
        </div>
    </>
};
