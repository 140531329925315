import React, {useEffect, useState} from 'react';
import {Button, InputForm} from '~/components/Form';
import '../Modal.scss';
import {dataConfirm} from "~/utils/Datacustom";
import Select from "react-select";
import {Modal, ModalBody} from 'reactstrap';
import Content from "~/layout/content/Content";
import {
    apiNewThanhphancongthuc
} from "~/services";
import {validateCongThucLuong} from "~/request/validateCongThucLuong";
import Icon from "~/components/icon/Icon";
import {toast} from "react-toastify";

const ModalAddThanhPhanCongThuc = ({show, setShow, fieldList, dataThanhPhan,dataExits,congthucId}) => {
    // console.log('dataThanhPhan',dataThanhPhan)
    const ids = dataExits?.map(i => i.ThanhphanluongId)
    const thanhPhanEx = dataThanhPhan?.filter(i => !ids.includes(i.id))
    // const dispatch = useDispatch();
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayload] = useState(fieldList);
    const [dataBienToanCucHeThong, setdataBienToanCucHeThong] = useState([]);
    // const {phucap} = useSelector((state) => state.phucap);
    
    
    useEffect(() => {
        show && setPayload(fieldList);
        setdataBienToanCucHeThong(thanhPhanEx?.map(i => {
            return {
                value: i.id,
                label: i.name,
            };
        }));
    }, [show]);
    
    
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayload((prev) => ({...prev, [statename]: selected}));
        
    };
    const handleSubmit = async () => {
        let invalids = validate(payload);
        // console.log('payload',payload)
        if (invalids === 0) {
           
            let datapayload = {
                data:
                    {
                        CongthucId: Number(congthucId),
                        codeCustom: payload.codeCustom,
                        ThanhphanluongId: payload?.ThanhphanluongId?.value,
                        TrangthaiId: payload?.TrangthaiId?.value,
                    },
            };
     
            const result = await apiNewThanhphancongthuc(datapayload)
            if (result.data.success === 200) {
                toast.success(result.data.message);
                setShow(false)
            } else {
                toast.error(result.data.message);
            }
        }
    };
    
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateCongThucLuong(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateCongThucLuong(item[0], item[1])]);
                invalids++;
            }
        });
        
        return invalids;
    };
    return (
        <React.Fragment>
            <Content>
                <Modal isOpen={show} toggle={setShow} className="modal-dialog-centered modal-90w">
                    <ModalBody>
                        <Icon name="cross-sm float-end fs-2" onClick={setShow}></Icon>
                        <h5 className="title mb-5"> Thêm thành phần lương vào công thức</h5>
                        
                        <div className="row">
                            <div className="form-group col-md-4">
                                <label htmlFor="inputZip" className="mb-2">Thành phần lương</label>
                                <Select
                                    value={payload.ThanhphanluongId}
                                    onChange={handleOnSelect}
                                    options={dataBienToanCucHeThong}
                                    placeholder={'Thành phần lương'}
                                    name='ThanhphanluongId'
                                    onFocus={() => setInvalidFields([])}
                                />
                                {invalidFields.length > 0 && invalidFields.some(i => i.name === 'ThanhphanluongId') &&
                                    <small
                                        className='text-danger'>{invalidFields.find(i => i.name === 'ThanhphanluongId')?.message}</small>}
                            </div>
                            <div className="form-group col-md-4">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Công thức thay thế'}
                                    value={payload.codeCustom}
                                    setValue={setPayload}
                                    keyPayload={'codeCustom'}
                                    type='text'
                                    validate
                                />
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="inputZip" className="mb-2">Trạng thái</label>
                                <Select
                                    value={payload.TrangthaiId}
                                    onChange={handleOnSelect}
                                    options={dataConfirm}
                                    placeholder={'Trạng thái'}
                                    name='TrangthaiId'
                                    onFocus={() => setInvalidFields([])}
                                />
                                {invalidFields.length > 0 && invalidFields.some(i => i.name === 'TrangthaiId') && <small
                                    className='text-danger'>{invalidFields.find(i => i.name === 'TrangthaiId')?.message}</small>}
                            </div>
                            <div className='col-12 mt-5'>
                                <Button
                                    text='Thêm mới'
                                    bClass='btn btn-primary px-5'
                                    textColor
                                    onClick={handleSubmit}
                                />
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </Content>
        </React.Fragment>
    )
    
};

export default ModalAddThanhPhanCongThuc;
