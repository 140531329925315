import axiosConfig from '../axiosConfig'

export const apiGetBienToanCucHeThong = () => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlyhethong/bientoancuchethong/congthuc`,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
// Thanh phan luong
export const apiGetThanhphanluong = () => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlynhanvien/thanh-phan-luong/congthuc`,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiNewThanhphanluong = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlynhanvien/thanh-phan-luong/congthuc',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiEditThanhphanluong = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlynhanvien/thanh-phan-luong/congthuc',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiDeleteThanhphanluong = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlynhanvien/thanh-phan-luong/congthuc',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})

// Cong thuc tinh luong
export const apiGetThanhphancongthuc = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlynhanvien/thanh-phan-cong-thuc/congthuc`,
            params: payload,
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})

export const apiDeleteThanhphancongthuc = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlynhanvien/thanh-phan-cong-thuc/congthuc',
            data: payload
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})



export const apiUpdateThanhphancongthuc = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: `/api/v2/quanlynhanvien/thanh-phan-cong-thuc/congthuc`,
            data: payload
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})


export const apiNewThanhphancongthuc = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: `/api/v2/quanlynhanvien/thanh-phan-cong-thuc/congthuc`,
            data: payload
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})


export const apiGetCongthuctinhluong = () => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlynhanvien/congthuc`,
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
export const apiNewCongthuctinhluong = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlynhanvien/congthuc',
            data: payload
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
export const apiEditCongthuctinhluong = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlynhanvien/congthuc',
            data: payload
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
// export const apiEditCongthuctinhluong = (payload) => new Promise(async (resolve, reject) => {
//     try {
//         const response = await axiosConfig({
//             method: 'put',
//             url: '/api/v2/quanlynhanvien/congthuc',
//             data: payload
//         })
//         resolve(response)
//
//     } catch (error) {
//         reject(error)
//     }
// })
export const apiDeleteCongthuctinhluong = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlynhanvien/congthuc',
            data: payload
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
