import axiosConfig from '../axiosConfig'

export const apiGetCauHinhCaLamViec = () => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlyhethong/cauhinhcalamviec`,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})


export const apiPostCauHinhCaLamViec = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: `/api/v2/quanlyhethong/cauhinhcalamviec`,
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiPutCauHinhCaLamViec = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: `/api/v2/quanlyhethong/cauhinhcalamviec`,
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiDeleteCauHinhCaLamViec = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: `/api/v2/quanlyhethong/cauhinhcalamviec`,
            data: payload
        })
        resolve(response)
        
    } catch (error) {
        reject(error)
    }
})
