export const validateCongTy = (fieldName, value) => {
    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let regNumber = /^[0-9\b]+$/;
    switch (fieldName) {
        case 'loaihinhcongty':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Loại hình công ty không được để trống'
                }
            }
            break;
        case 'masothue':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Mã số thuế không được để trống'
                }
            }
            break;
        case 'diachi':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Địa chỉ không được để trống'
                }
            }
            break;
        case 'daidienphaply':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Đại diện pháp lý không được để trống.'
                }
            }
            break;
        case 'ngaycapphep':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Ngày cấp phép không được để trống.'
                }
            }
            break;
        case 'ngayhoatdong':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Ngày hoạt động không được để trống.'
                }
            }
            break;
        case 'dienthoai':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Số điện thoại không được bỏ trống trường này.'
                }
            } else if (!regNumber.test(value)) {
                return {
                    name: fieldName,
                    message: 'Số điện thoại không đúng định dạng'
                }
            } else if (value.length < 10) {
                return {
                    name: fieldName,
                    message: 'Số điện thoại có độ dài từ 10-12'
                }
        
            }
            break;
        case 'email':
            if (!regEmail.test(value)) {
                return {
                    name: fieldName,
                    message: 'Email không được để trống.'
                }
            }
            break;
        
        default:
            break;
    }
}