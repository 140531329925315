import React, {useState, useEffect} from 'react'
import {InputForm, Button} from '~/components/Form'
import '../Modal.scss';
import Select from 'react-select';
import {apiEditCauhinhsanluong, apiEditSanluongnhanvien} from "~/services";
import {toast} from "react-toastify";
import Content from "~/layout/content/Content";
import {Modal, ModalBody} from 'reactstrap';
import {dataSelectActive} from "~/utils/Datacustom";
import Icon from "~/components/icon/Icon";

const ModalEditKhac = ({show, setShow, dataEdit, fieldList}) => {
    const [invalidFields, setInvalidFields] = useState([])
    const [payload, setPayload] = useState(fieldList)
  
    useEffect(() => {
        let status = dataSelectActive?.find((item) => {
            return item && item.value === dataEdit?.status;
        });
        show && dataEdit && setPayload({
            quantityTarget: dataEdit.quantityTarget,
            quantityAchieve: dataEdit.quantityAchieve,
            quantityDifference: dataEdit.quantityDifference,
            deductProduct: dataEdit.deductProduct,
            sumProduct: dataEdit.sumProduct,
            status: {label: status?.label, value: status?.value},
        })
    }, [dataEdit, show])
    const handleSubmit = async () => {
        let invalids = 0
        
        if (invalids === 0) {
            let datapayload =  {
                data: [
                    {
                        id: dataEdit.id,
                        NhanvienId: dataEdit.NhanvienId,
                        BangsanluongId: dataEdit.BangsanluongId,
                        quantityTarget: payload.quantityTarget,
                        quantityAchieve: payload.quantityAchieve,
                        quantityDifference: payload.quantityDifference,
                        deductProduct: payload.deductProduct,
                        sumProduct: payload.sumProduct,
                        status: payload.status.value,
                    }
                ]
            }
           
            const result = await apiEditSanluongnhanvien(datapayload)
            if (result.data.success === 200) {
                toast.success(result.data.message)
                setShow(false)
            } else {
                toast.error("Có lỗi xảy ra!")
            }
        }
    }
useEffect(() => {
    const valueQuantityDifference = (payload.quantityTarget - payload.quantityAchieve)
    setPayload(prev => ({...prev, quantityDifference: valueQuantityDifference}))
},[payload.quantityTarget, payload.quantityAchieve])
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayload(prev => ({...prev, [statename]: selected}))
    }
    return (
        <React.Fragment>
            <Content>
                <Modal isOpen={show} toggle={setShow} className="modal-dialog-centered modal-90w">
                    <ModalBody>
                        <Icon name="cross-sm float-end fs-2" onClick={setShow}></Icon>
                        <h5 className="title my-2"> Sửa sản lượng nhân viên</h5>
                        <div className="row">
                            <div className="form-group col-md-6">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Số lượng đề xuất'}
                                    value={payload.quantityTarget}
                                    setValue={setPayload}
                                    keyPayload={'quantityTarget'}
                                    type='text'
                                    validate
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Số lượng đạt được'}
                                    value={payload.quantityAchieve}
                                    setValue={setPayload}
                                    keyPayload={'quantityAchieve'}
                                    type='text'
                                    validate
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Số lượng chênh lệch'}
                                    value={payload.quantityDifference}
                                    setValue={setPayload}
                                    keyPayload={'quantityDifference'}
                                    type='text'
                                    validate
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Giá trị cộng thêm/ 1 sản phẩm'}
                                    value={payload.sumProduct}
                                    setValue={setPayload}
                                    keyPayload={'sumProduct'}
                                    type='text'
                                    validate
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Giá trị khấu trừ/ 1 sản phẩm'}
                                    value={payload.deductProduct}
                                    setValue={setPayload}
                                    keyPayload={'deductProduct'}
                                    type='text'
                                    validate
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="inputZip" className="mb-2">Trạng thái</label>
                                <Select
                                    value={payload.status}
                                    onChange={handleOnSelect}
                                    options={dataSelectActive}
                                    placeholder={'Trạng thái'}
                                    name='status'
                                    onFocus={() => setInvalidFields([])}
                                />
                                {invalidFields.length > 0 && invalidFields.some(i => i.name === 'status') && <small
                                    className='text-danger'>{invalidFields.find(i => i.name === 'status')?.message}</small>}
                            </div>
                        </div>
                        
                        <div className='col-12 mt-5'>
                            <Button
                                text='Cập nhật'
                                bClass='btn btn-primary px-5'
                                textColor
                                onClick={handleSubmit}
                            />
                        </div>
                    </ModalBody>
                </Modal>
            </Content>
        </React.Fragment>
    );
}

export default ModalEditKhac;

