import React from 'react';
import Icon from '../icon/Icon';
import Button from '../button/Button';
import * as XLSX from 'xlsx';
import * as excelJs from "exceljs";
// import XLSX from 'xlsx';
// import XLSXStyle from 'xlsx-style';

const ExportXlsx = ({ dataFileMau, header, namefile, btnname, title, dataExport,footerTotal }) => {
    const exportExcel = async () => {
        if (header !== undefined) {
            // const dataex = dataFileMau?.map((item) => Object.values(item));
            // const compatibleData = dataex.map((row) => {
            //     const obj = {};
            //     header.forEach((col, index) => {
            //         obj[col] = row[index];
            //     });
            //     return obj;
            // });
            // const wb = XLSX.utils.book_new();
            // const ws1 = XLSX.utils.json_to_sheet(compatibleData, { header });
            // XLSX.utils.book_append_sheet(wb, ws1, namefile);
            // XLSX.writeFile(wb, `${namefile}.xlsx`);
            const headerExport = header
            const opt_loaihopdong = dataExport?.loaihopdong.flatMap((opt) => opt?.tenloaihopdong);
            const opt_loaithuethunhap = dataExport?.loaithuethunhap.flatMap((opt) => opt?.label);
            const opt_nguoiky = dataExport.nguoiky?.flatMap((opt) => opt?.username);
            // const opt_quyettoanthaythue = dataExport?.quyettoanthaythue.flatMap((opt) => opt?.label);
            const opt_mauhopdong = dataExport?.mauhopdong.flatMap((opt) => opt?.mota);
            const opt_trangthai = dataExport?.trangthai.flatMap((opt) => opt?.label);
            const formulas = [
                `"${opt_loaihopdong.join(',')}"`,
                `"${opt_loaithuethunhap.join(',')}"`,
                `"${opt_nguoiky.join(',')}"`,
                // `"${opt_quyettoanthaythue.join(',')}"`,
                `"${opt_mauhopdong.join(',')}"`,
                `"${opt_trangthai.join(',')}"`,
            ];
            // console.log('formulas', formulas)
            const workbook = new excelJs.Workbook();
            const ws = workbook.addWorksheet('Hợp đồng mẫu');
            
            const dataValidationRanges = ['D2:D99999','E2:E99999', 'F2:F99999', 'G2:G99999','M2:M99999',];
            dataValidationRanges.forEach((range, index) => {
                ws.dataValidations.add(range, {
                    type: 'list',
                    allowBlank: false,
                    formulae: [formulas[index]],
                });
            });
            
            ws.addRow(headerExport);
            // const employeeNames = ['nhan vien 1', 'nhan vien 2', 'nhan vien 3'];
            dataFileMau.forEach((name, index) => {
                ws.getCell(index + 2, 1).value = name?.Stt;
                ws.getCell(index + 2, 2).value = name?.hoten;
                ws.getCell(index + 2, 3).value = name?.uuid;
              });
            ws.columns.map((col, index) => (col.width = 20));
            
            const cellFormatting = {
                font: {
                    name: 'Inter',
                    size: 8,
                },
                alignment: {
                    horizontal: 'center',
                },
            };
            
            ws.eachRow((row) => {
                row.eachCell((cell) => {
                    Object.assign(cell, cellFormatting);
                });
            });
            
            const excelBlob = await workbook.xlsx.writeBuffer();
            const excelUrl = URL.createObjectURL(
                new Blob([excelBlob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            );
            
            const link = document.createElement('a');
            link.href = excelUrl;
            link.download = `${namefile}.xlsx`;
            document.body.appendChild(link);
            link.click();
            
            URL.revokeObjectURL(excelUrl);
            document.body.removeChild(link);
        
        } else {
            let header;
            if (dataFileMau.length > 0) {
                const data = dataFileMau.map((item) => Object.keys(item));
                header = data[0];
            } else {
                header = title;
            }
            const wb = XLSX.utils.book_new();
            const ws1 = XLSX.utils.json_to_sheet(dataFileMau, { header });
            XLSX.utils.book_append_sheet(wb, ws1, namefile);
            XLSX.writeFile(wb, `${namefile}.xlsx`);
            // Tạo workbook mới và worksheet mới
            // const wb = XLSX.utils.book_new();
            // const ws = XLSX.utils.json_to_sheet(dataFileMau, { header });

            // // Tùy chỉnh style cho header
            // const headerStyle = {
            //     font: { bold: true, color: { rgb: 'FFFFFF' } }, // Chữ in đậm, màu trắng
            //     fill: { fgColor: { rgb: '007ACC' } }, // Màu nền header
            //     alignment: { wrapText: true }, // Dãn dòng
            // };

            // // Áp dụng style cho header
            // for (let i = 0; i < header.length; i++) {
            //     const cellRef = XLSX.utils.encode_cell({ r: 0, c: i });
            //     ws[cellRef].s = headerStyle;
            // }

            // // Thêm worksheet vào workbook
            // XLSX.utils.book_append_sheet(wb, ws, namefile);

            // // Xuất file .xlsx với style đã tùy chỉnh
            // const wopts = { bookType: 'xlsx', bookSST: false, type: 'binary' };
            // const wbout = XLSXStyle.write(wb, wopts);

            // const s2ab = (s) => {
            //     const buf = new ArrayBuffer(s.length);
            //     const view = new Uint8Array(buf);
            //     for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
            //     return buf;
            // };

            // const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
            // const url = URL.createObjectURL(blob);

            // // Tạo một thẻ a ẩn và kích hoạt sự kiện click để tải xuống file
            // const a = document.createElement('a');
            // a.style.display = 'none';
            // a.href = url;
            // a.download = `${namefile}.xlsx`;
            // document.body.appendChild(a);
            // a.click();
            // URL.revokeObjectURL(url);
            // document.body.removeChild(a);
            // }
        }
    };

    return (
        <React.Fragment>
            <Button color="primary" onClick={() => exportExcel()}>
                <Icon name="download-cloud"></Icon> <span>{btnname}</span>
            </Button>
        </React.Fragment>
    );
};
export default ExportXlsx;
