import React, { useContext, useEffect, useMemo, useState } from 'react';
import { InputForm } from '~/components/Form';
import { dataStatusNghiphep, dataThoidiemlamthem } from '~/utils/Datacustom';
import '../Modal.scss';
import { Input, Modal, ModalBody, Table } from 'reactstrap';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { validatePhieuTangCa } from '~/request/validatePhieuTangCa';
import * as actions from '~/store/actions';
import Content from '~/layout/content/Content';

import {
    Button,
    Icon,
    SelectFinNhanvienfix,
    Block,
    BlockBetween,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    DataTableBody,
    DataTableHead,
    DataTableRow,
    DataTableItem,
    DataTable,
} from '~/components/Component';
import { apiGetPhieutangca, apiGetUserAll, apiNewPhieutangca } from '~/services';
import { matusinhResult } from '~/utils/Utils';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_VI } from 'material-react-table/locales/vi';
import actionTypes from '~/store/actions/actionTypes';
import { toast } from 'react-toastify';
import { getListPhieutangca } from '~/store/actions';
import { FileAllCodeContext } from '../../user-manage/FileAllCodeContext';

const ModalPhieutangca = ({ show, setShow, fieldList }) => {
    const { handleThongbaoDataSocket } = useContext(FileAllCodeContext);
    const [invalidFields, setInvalidFields] = useState([]);
    const [showSelect, setShowSelect] = useState(false);
    const [quytrinh, setQuytrinh] = useState([]);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.getListCauhinhpheduyet({type_duyet: 0})); // type_duyet = 0 là tăng ca
    }, []);
    const { cauhinhpheduyet } = useSelector((state) => state.quanly);
    // console.log('cauhinhpheduyet', cauhinhpheduyet)

    useEffect(() => {
        if (cauhinhpheduyet && cauhinhpheduyet.length > 0) {
            const arrQuytrinh = cauhinhpheduyet.map((item) => ({value: item.id, label: item.title}));
            setQuytrinh(arrQuytrinh);
        }
    }, [cauhinhpheduyet]);

    const { cauhinhcalamviec } = useSelector((state) => state.cauhinhcalamviec);
    // const { cauhinhtangca } = useSelector((state) => state.cauhinhtangca);
    const [dataCalamviec, setDataCalamviec] = useState([]);
    const [listNhanvien, setlistNhanvien] = useState([]);
    // const [listTrangthai, setlistTrangthai] = useState([]);
    // useEffect(() => {
    //     setlistTrangthai(dataStatusNghiphep);
    // }, [dataStatusNghiphep]);
    const [payload, setPayload] = React.useState(fieldList);
    // console.log('object', dataCalamviec)
    useEffect(() => {
        show && setPayload(fieldList);
        setInvalidFields([]);
        setlistNhanvien([]);
        setFormNVTC([]);
        // lấy tất cả các Mã về để check sinh ra mã mới k trùng
        const fetchData = async () => {
            const dataAll = await apiGetPhieutangca();
            let data = dataAll?.data?.data?.map((item) => ({ matusinh: item.maphieu }));
            setPayload((prev) => ({
                ...prev,
                ['maphieu']: matusinhResult(data && data.length > 0 ? data : [{}], 'PTC'),
            }));
        };
        fetchData().catch(console.error);
    }, [show, dataThoidiemlamthem]);
    useEffect(() => {
        const caLamViec = cauhinhcalamviec
            .filter((item) => item.isShift === 1)
            .map((item) => ({
                label: item.tieude,
                value: item.id,
                maca: item.macalamviec,
                batdaugiovaodehieuca: item.batdaugioradehieuca,
                ketthucgiovaodehieuca: item.ketthucgiovaodehieuca,
                giovaolamviec: item.giovaolamviec,
                gioketthuclamviec: item.gioketthuclamviec,
                batdaugioradehieuca: item.batdaugioradehieuca,
                ketthucgioradehieuca: item.ketthucgioradehieuca,
                giobatdaunghigiuaca: item.giobatdauantrua,
                gioketthucnghigiuaca: item.gioketthucantrua,
                chuyenngay: item.solanchuyenngaytunhien,
                tonggio: item.tonggio,
            }));
        setDataCalamviec(caLamViec);
    }, [cauhinhcalamviec]);

    // useEffect(() => {
    //     const fetchData = async () => {
    //         const userAll = await apiGetUserAll();
    //         let resultuser = userAll.data.data.rows?.map((item) => ({ label: item.username, value: item.id }));
    //         setlistUser(resultuser);
    //     };
    //     fetchData().catch(console.error);
    // }, []);
    // const [listUser, setlistUser] = useState([]);

    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validatePhieuTangCa(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validatePhieuTangCa(item[0], item[1])]);
                invalids++;
            }
        });
        return invalids;
    };
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        // if (statename === 'KhaibaocalamviecId') {
        //     let data = cauhinhcalamviec.find((item) => item.id === selected.value);
        //     setPayload((prev) => ({
        //         ...prev,
        //         ['KhaibaocalamviecId']: selected,
        //         ['giobatdau']: data.giovaolamviec,
        //         ['gioketthuc']: data.gioketthuclamviec,
        //         ['batdaugiovaodehieuca']: data.batdaugiovaodehieuca,
        //         ['ketthucgiovaodehieuca']: data.ketthucgiovaodehieuca,
        //         ['batdaugioradehieuca']: data.batdaugioradehieuca,
        //         ['ketthucgioradehieuca']: data.ketthucgioradehieuca,
        //         ['giobatdaunghigiuaca']: data.giobatdauantrua,
        //         ['gioketthucnghigiuaca']: data.gioketthucantrua,
        //         // ['tonggio']: data.tonggio,
        //         // ['cong']: data.demcong,
        //     }));
        // }
        setPayload((prev) => ({ ...prev, [statename]: selected }));
    };

    // const tongphut = (startTime, finishTime) => {
    //     if (!startTime || !finishTime) {
    //         return 0;
    //     }
    //     let startParts = startTime.split(':');
    //     let finishParts = finishTime.split(':');
    //     let startMinutes = parseInt(startParts[0]) * 60 + parseInt(startParts[1]);
    //     let finishMinutes = parseInt(finishParts[0]) * 60 + parseInt(finishParts[1]);
    //     return finishMinutes - startMinutes;
    // };

    // useEffect(() => {
    //     let phutlamviec = 0;
    //     let phutnghi = 0;
    //     if (payload.giobatdau !== '' && payload.gioketthuc !== '') {
    //         phutlamviec = tongphut(payload.giobatdau, payload.gioketthuc);
    //     }
    //     if (payload.giobatdaunghigiuaca !== '' && payload.gioketthucnghigiuaca !== '') {
    //         phutnghi = tongphut(payload.giobatdaunghigiuaca, payload.gioketthucnghigiuaca);
    //     }
    //     let tonggio = (phutlamviec - phutnghi) / 60;
    //     setPayload((prev) => ({ ...prev, ['tonggio']: tonggio.toFixed(2) }));
    // }, [payload.giobatdau, payload.gioketthuc, payload.giobatdaunghigiuaca, payload.gioketthucnghigiuaca]);

    const [invalidNVTC, setInvalidFieldsNVTC] = useState([]);
    const [formNVTC, setFormNVTC] = useState([]);
    // console.log('formNVTC', formNVTC)
    const validateNVTC = (arr) => {
        let newArray = {};
        arr.forEach((item, index) => {
            newArray[`Nhanvien_${index}`] = item.NhanvienId;
            newArray[`giobatdau_${index}`] = item.giobatdau;
            newArray[`gioketthuc_${index}`] = item.gioketthuc;
        });
        let invalids = 0;
        let fields = Object.entries(newArray);
        fields.forEach((item) => {
            if (item[1] === '') {
                setInvalidFieldsNVTC((prev) => [
                    ...prev,
                    {
                        name: item[0],
                        message: 'Bạn không được bỏ trống trường này.',
                    },
                ]);
                invalids++;
            }
        });

        return invalids;
    };

    const handleSubmit = async () => {
        try {
            let invalids = validate(payload);
            const invalidsNVTC = validateNVTC(formNVTC);
            if (formNVTC.length > 0) {
                if (invalids === 0 && invalidsNVTC === 0) {
                    let datapayload = {
                        // tenphieu: payload.tenphieu,
                        maphieu: payload.maphieu,
                        // CauhinhtangcaId: payload.CauhinhtangcaId.value,
                        // ngaynopdon: payload.ngaynopdon,
                        ngaybatdau: payload.ngaybatdau,
                        // ngayketthuc: payload.ngayketthuc,
                        // giobatdau: payload.giobatdau,
                        // gioketthuc: payload.gioketthuc,
                        // batdaugiovaodehieuca: payload.batdaugiovaodehieuca,
                        // ketthucgiovaodehieuca: payload.ketthucgiovaodehieuca,
                        // batdaugioradehieuca: payload.batdaugioradehieuca,
                        // ketthucgioradehieuca: payload.ketthucgioradehieuca,
                        // giobatdaunghigiuaca: payload.giobatdaunghigiuaca,
                        // gioketthucnghigiuaca: payload.gioketthucnghigiuaca,
                        // tonggio: payload.tonggio,
                        // mota: payload.mota,
                        // daychuyen: 0,
                        PheduyetId: payload.PheduyetId.value,
                        arrTC: JSON.stringify(formNVTC),
                        // TrangthaiId: payload.TrangthaiId.value,
                    };
                    const response = await apiNewPhieutangca(datapayload);
                    // console.log('chek api apiNewPhieutangca', response)
                    if (response?.data.success === 200) {
                        // console.log('response?.dataaaaaaaaaaaaaaa', response?.data?.aruser)
                        let arr = [response?.data?.aruser];
                        handleThongbaoDataSocket(arr.join('|'));
                        setShow(false);
                        toast.success('Thêm mới thành công!');
                    } else {
                        toast.error('Thêm mới thất bại!');
                    }
                }
            } else {
                toast.error('Bạn chưa thêm nhân viên tăng ca');
            }
        } catch {}
    };

    // console.log('formNVTC', formNVTC);
    // const [formEditNVTC, setFormEditNVTC] = useState({ id: 0, trangthai: false });
    const handleChangeNVTC = (i, e, ck) => {
        let newFormValues = [...formNVTC];
        newFormValues[i][e.target.name] = !ck ? e.target.value : e.currentTarget.checked === true ? 1 : 0;
        setFormNVTC(newFormValues);
    };

    const addFormFieldsNVTC = () => {
        let mang = [
            ...formNVTC,
            {
                NhanvienId: '',
                noidung: '',
                giobatdau: '',
                gioketthuc: '',
                KhaibaocalamviecId: '',
                chuyenngay: '',
                batdaugiovaodehieuca: '',
                ketthucgiovaodehieuca: '',
                batdaugioradehieuca: '',
                ketthucgioradehieuca: '',
                giobatdaunghigiuaca: '',
                gioketthucnghigiuaca: '',
                daychuyen: 0,
                tonggio: 0,
                anchinh: 0,
                anphu: 0,
            },
        ];
        setFormNVTC(mang);
    };

    const removeFormFieldsNVTC = (i) => {
        let newFormValues = [...formNVTC];
        newFormValues.splice(i, 1);
        setFormNVTC(newFormValues);
    };
    const [idTC, setIdTC] = useState(null);
    // console.log(listNhanvien)
    useEffect(() => {
        const updatedFormValues = [...formNVTC];
        updatedFormValues[idTC] = {
            ...updatedFormValues[idTC],
            NhanvienId: listNhanvien.length > 0 ? listNhanvien[0].value : '',
            Nhanvien: listNhanvien.length > 0 ? { hoten: listNhanvien[0].label } : '',
        };
        setFormNVTC(updatedFormValues);
    }, [listNhanvien]);

    const handleSelectChangeNVTC = (index, selected) => {
        const updatedFormValues = [...formNVTC];
        updatedFormValues[index] = {
            ...updatedFormValues[index],
            KhaibaocalamviecId: selected.value,
            giobatdau: selected.giovaolamviec,
            gioketthuc: selected.gioketthuclamviec,
            batdaugiovaodehieuca: selected.batdaugioradehieuca,
            ketthucgiovaodehieuca: selected.ketthucgiovaodehieuca,
            batdaugioradehieuca: selected.batdaugioradehieuca,
            ketthucgioradehieuca: selected.ketthucgioradehieuca,
            giobatdaunghigiuaca: selected.giobatdaunghigiuaca,
            gioketthucnghigiuaca: selected.gioketthucnghigiuaca,
            chuyenngay: selected.solanchuyenngaytunhien,
            tonggio: selected.tonggio,
        };
        setFormNVTC(updatedFormValues);
    };
    // console.log('formNVTC', formNVTC)
    return (
        <React.Fragment>
            <Content>
                <Modal isOpen={show} toggle={setShow} className="modal-dialog-centered modal-90w">
                    <ModalBody>
                        <Icon name="cross-sm float-end fs-2" onClick={setShow}></Icon>
                        <h5 className="title mb-5">Thêm mới phiếu tăng ca</h5>
                        <div className="row">
                            <div className="form-group col-md-3">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Mã phiếu'}
                                    value={payload.maphieu}
                                    // setValue={setPayload}
                                    keyPayload={'maphieu'}
                                    type="text"
                                    disabled={true}
                                    validate
                                />
                            </div>

                            <div className="form-group col-md-3">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Ngày tăng ca'}
                                    value={payload.ngaybatdau}
                                    setValue={setPayload}
                                    keyPayload={'ngaybatdau'}
                                    type="DATE"
                                    validate
                                />
                            </div>
                            <div className="form-group col-md-3">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Bộ phận'}
                                    value={payload.bophan}
                                    setValue={setPayload}
                                    keyPayload={'bophan'}
                                    type="text"
                                />
                            </div>
                            <div className="form-group col-md-3">
                                <div className="form-group">
                                    <label htmlFor="inputZip" className="mb-2 fw-bold">
                                        Áp dụng quy trình phê duyệt
                                    </label>
                                    <Select
                                        value={payload?.PheduyetId}
                                        onChange={handleOnSelect}
                                        options={quytrinh}
                                        placeholder={'Chọn quy trình'}
                                        name="PheduyetId"
                                        menuPosition="fixed"
                                        onFocus={() => setInvalidFields([])}
                                    />
                                    {invalidFields.length > 0 &&
                                        invalidFields.some((i) => i.name === 'PheduyetId') && (
                                            <small className="text-danger">
                                                {invalidFields.find((i) => i.name === 'PheduyetId')?.message}
                                            </small>
                                        )}
                                </div>
                            </div>
                            {/* <div className="form-group col-md-3">
                                <div className="form-group">
                                    <label htmlFor="inputZip" className="mb-2 fw-bold">
                                        Trạng thái
                                    </label>
                                    <Select
                                        value={payload?.TrangthaiId}
                                        onChange={handleOnSelect}
                                        options={dataStatusNghiphep}
                                        placeholder={'Chọn trạng thái'}
                                        name="TrangthaiId"
                                        onFocus={() => setInvalidFields([])}

                                    />
                                    {invalidFields.length > 0 &&
                                        invalidFields.some((i) => i.name === 'TrangthaiId') && (
                                            <small className="text-danger">
                                                {invalidFields.find((i) => i.name === 'TrangthaiId')?.message}
                                            </small>
                                        )}
                                </div>
                            </div> */}
                            {/* <div className="form-group col-md-12 mt-2">
                                <label htmlFor="inputZip" className="mb-2 fw-bold">
                                    Lý do làm thêm
                                </label>
                                <Input
                                    value={payload.mota}
                                    onChange={(e) =>
                                        setPayload((prev) => ({
                                            ...prev,
                                            ['mota']: e.target.value,
                                        }))
                                    }
                                    rows={'2'}
                                    name="mota"
                                    type="textarea"
                                />
                                {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'mota') && (
                                    <small className="text-danger">
                                        {invalidFields.find((i) => i.name === 'mota')?.message}
                                    </small>
                                )}
                            </div> */}
                            <div className="mt-3">
                                <div className="form-group col-md-12">
                                    <div className="pb-0">
                                        <h6 className="text-primary">Thôn tin nhân viên tăng ca</h6>
                                        <Button
                                            size="md"
                                            color="light"
                                            outline
                                            className="btn-white btn-dim btn-icon px-4 mt-2"
                                            onClick={() => addFormFieldsNVTC()}
                                        >
                                            Thêm nhân viên <Icon name="plus"></Icon>
                                        </Button>
                                    </div>
                                </div>
                                {formNVTC?.length > 0 && (
                                    <div style={{ overflowY: 'hidden' }}>
                                        <Table striped bordered hover vertical>
                                            <thead>
                                                <tr>
                                                    <th className="align-middle text-center" colspan="1" rowspan="2">
                                                        <span className="sub-text w-20px">STT</span>
                                                    </th>
                                                    <th className="align-middle text-center" colspan="1" rowspan="2">
                                                        <span className="sub-text w-150px">Tên nhân viên </span>
                                                    </th>
                                                    {/* <th
                                                                className="align-middle text-center"
                                                                colspan="1"
                                                                rowspan="2"
                                                            >
                                                                <span className="sub-text w-120px">Mã nhân viên </span>
                                                            </th> */}
                                                    <th className="align-middle text-center" colspan="1" rowspan="2">
                                                        <span className="sub-text w-120px">Nội dung</span>
                                                    </th>
                                                    <th className="align-middle text-center" colspan="1" rowspan="2">
                                                        <span className="sub-text w-120px">Ca làm</span>
                                                    </th>
                                                    <th className="align-middle text-center" colspan="1" rowspan="2">
                                                        <span className="sub-text w-120px">Thời gian tăng ca</span>
                                                    </th>
                                                    <th className="align-middle text-center" colspan="1" rowspan="2">
                                                        <span className="sub-text w-120px">Thời gian dự kiến</span>
                                                    </th>
                                                    <th className="align-middle text-center" colspan="2" rowspan="1">
                                                        <span className="sub-text ">Báo ăn ca</span>
                                                    </th>
                                                    {/* <th className="align-middle text-center" colspan="1" rowspan="2">
                                                        <span className="sub-text w-50px">Dây chuyền</span>
                                                    </th> */}
                                                    <th className="nk-tb-col-tools text-end" colspan="1" rowspan="2">
                                                        <span className="sub-text ">Hành động</span>
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th className="align-middle text-center" colspan="1" rowspan="1">
                                                        <span className="sub-text ">Ăn chính</span>
                                                    </th>
                                                    <th className="align-middle text-center" colspan="1" rowspan="1">
                                                        <span className="sub-text ">Ăn phụ</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {formNVTC?.length > 0
                                                    ? formNVTC.map((item, it) => (
                                                          <React.Fragment key={it}>
                                                              <tr>
                                                                  <td>
                                                                      <span className="sub-text text-center">
                                                                          {it + 1}
                                                                      </span>
                                                                  </td>
                                                                  <td
                                                                      onClick={(ev) => {
                                                                          ev.preventDefault();
                                                                          setShowSelect(true);
                                                                          setIdTC(it);
                                                                      }}
                                                                  >
                                                                      <span
                                                                          className="sub-text"
                                                                          onFocus={() => setInvalidFieldsNVTC([])}
                                                                      >
                                                                          <input
                                                                              value={item?.Nhanvien?.hoten || ''}
                                                                              type="text"
                                                                              name="Nhanvien"
                                                                              className="form-control"
                                                                          />
                                                                          {invalidNVTC.length > 0 &&
                                                                              invalidNVTC.some(
                                                                                  (i) => i.name === `Nhanvien_${it}`,
                                                                              ) && (
                                                                                  <small className="text-danger">
                                                                                      {
                                                                                          invalidNVTC.find(
                                                                                              (i) =>
                                                                                                  i.name ===
                                                                                                  `Nhanvien_${it}`,
                                                                                          )?.message
                                                                                      }
                                                                                  </small>
                                                                              )}
                                                                      </span>
                                                                  </td>
                                                                  <td>
                                                                      <span className="sub-text">
                                                                          <input
                                                                              type="text"
                                                                              name="noidung"
                                                                              className="form-control"
                                                                              value={item.noidung || ''}
                                                                              onChange={(e) => handleChangeNVTC(it, e)}
                                                                              onFocus={() => setInvalidFieldsNVTC([])}
                                                                          />
                                                                          {invalidNVTC.length > 0 &&
                                                                              invalidNVTC.some(
                                                                                  (i) => i.name === `noidung_${it}`,
                                                                              ) && (
                                                                                  <small className="text-danger">
                                                                                      {
                                                                                          invalidNVTC.find(
                                                                                              (i) =>
                                                                                                  i.name ===
                                                                                                  `noidung_${it}`,
                                                                                          )?.message
                                                                                      }
                                                                                  </small>
                                                                              )}
                                                                      </span>
                                                                  </td>
                                                                  <td>
                                                                      <span className="sub-text">
                                                                          <Select
                                                                              value={item.KhaibaocalamviecId}
                                                                              onChange={(selected) =>
                                                                                  handleSelectChangeNVTC(it, selected)
                                                                              }
                                                                              options={dataCalamviec}
                                                                              placeholder={'Chọn ca'}
                                                                              name="KhaibaocalamviecId"
                                                                              menuPosition="fixed"
                                                                          />
                                                                          {invalidNVTC.length > 0 &&
                                                                              invalidNVTC.some(
                                                                                  (i) =>
                                                                                      i.name ===
                                                                                      `KhaibaocalamviecId_${it}`,
                                                                              ) && (
                                                                                  <small className="text-danger">
                                                                                      {
                                                                                          invalidNVTC.find(
                                                                                              (i) =>
                                                                                                  i.name ===
                                                                                                  `KhaibaocalamviecId_${it}`,
                                                                                          )?.message
                                                                                      }
                                                                                  </small>
                                                                              )}
                                                                      </span>
                                                                  </td>
                                                                  <td>
                                                                      <span className="sub-text d-flex">
                                                                          <div className="gio-vao">
                                                                              <input
                                                                                  type="time"
                                                                                  name="giobatdau"
                                                                                  className="form-control"
                                                                                  value={item.giobatdau || ''}
                                                                                  onChange={(e) =>
                                                                                      handleChangeNVTC(it, e)
                                                                                  }
                                                                                  onFocus={() =>
                                                                                      setInvalidFieldsNVTC([])
                                                                                  }
                                                                                  disabled={true}
                                                                              />
                                                                              {invalidNVTC.length > 0 &&
                                                                                  invalidNVTC.some(
                                                                                      (i) =>
                                                                                          i.name === `giobatdau_${it}`,
                                                                                  ) && (
                                                                                      <small className="text-danger">
                                                                                          {
                                                                                              invalidNVTC.find(
                                                                                                  (i) =>
                                                                                                      i.name ===
                                                                                                      `giobatdau_${it}`,
                                                                                              )?.message
                                                                                          }
                                                                                      </small>
                                                                                  )}
                                                                          </div>
                                                                          <div className="gio-ra">
                                                                              <input
                                                                                  type="time"
                                                                                  name="gioketthuc"
                                                                                  className="form-control"
                                                                                  value={item.gioketthuc || ''}
                                                                                  onChange={(e) =>
                                                                                      handleChangeNVTC(it, e)
                                                                                  }
                                                                                  onFocus={() =>
                                                                                      setInvalidFieldsNVTC([])
                                                                                  }
                                                                                  disabled={true}
                                                                              />
                                                                              {invalidNVTC.length > 0 &&
                                                                                  invalidNVTC.some(
                                                                                      (i) =>
                                                                                          i.name === `gioketthuc_${it}`,
                                                                                  ) && (
                                                                                      <small className="text-danger">
                                                                                          {
                                                                                              invalidNVTC.find(
                                                                                                  (i) =>
                                                                                                      i.name ===
                                                                                                      `gioketthuc_${it}`,
                                                                                              )?.message
                                                                                          }
                                                                                      </small>
                                                                                  )}
                                                                          </div>
                                                                      </span>
                                                                  </td>
                                                                  <td>
                                                                      <span className="sub-text">
                                                                          <input
                                                                              type="text"
                                                                              name="tonggio"
                                                                              value={item.tonggio || ''}
                                                                              className="form-control"
                                                                              onChange={(e) => handleChangeNVTC(it, e)}
                                                                              onFocus={() => setInvalidFieldsNVTC([])}
                                                                              disabled={true}
                                                                          />
                                                                          {invalidNVTC.length > 0 &&
                                                                              invalidNVTC.some(
                                                                                  (i) => i.name === `tonggio_${it}`,
                                                                              ) && (
                                                                                  <small className="text-danger">
                                                                                      {
                                                                                          invalidNVTC.find(
                                                                                              (i) =>
                                                                                                  i.name ===
                                                                                                  `tonggio_${it}`,
                                                                                          )?.message
                                                                                      }
                                                                                  </small>
                                                                              )}
                                                                      </span>
                                                                  </td>
                                                                  <td>
                                                                      <span className="sub-text text-center">
                                                                          <div className="custom-control custom-checkbox">
                                                                              <input
                                                                                  type="checkbox"
                                                                                  className="custom-control-input"
                                                                                  //   defaultChecked
                                                                                  id={`anchinh_${it}`}
                                                                                  name="anchinh"
                                                                                  //   value={item.anchinh || ''}
                                                                                  onChange={(e) =>
                                                                                      handleChangeNVTC(it, e, 'ck')
                                                                                  }
                                                                                  checked={
                                                                                      item.anchinh === 1 ? true : false
                                                                                  }
                                                                              />
                                                                              <label
                                                                                  className="custom-control-label"
                                                                                  htmlFor={`anchinh_${it}`}
                                                                              ></label>
                                                                          </div>
                                                                      </span>
                                                                  </td>
                                                                  <td>
                                                                      <span className="sub-text text-center">
                                                                          <div className="custom-control custom-checkbox">
                                                                              <input
                                                                                  type="checkbox"
                                                                                  className="custom-control-input"
                                                                                  //   defaultChecked
                                                                                  id={`anphu_${it}`}
                                                                                  name="anphu"
                                                                                  //   value={item.anphu || ''}
                                                                                  onChange={(e) =>
                                                                                      handleChangeNVTC(it, e, 'ck')
                                                                                  }
                                                                                  checked={
                                                                                      item.anphu === 1 ? true : false
                                                                                  }
                                                                              />
                                                                              <label
                                                                                  className="custom-control-label"
                                                                                  htmlFor={`anphu_${it}`}
                                                                              ></label>
                                                                          </div>
                                                                      </span>
                                                                  </td>
                                                                  {/* <td>
                                                                      <span className="sub-text text-center">
                                                                          <div className="custom-control custom-checkbox">
                                                                              <input
                                                                                  type="checkbox"
                                                                                  className="custom-control-input"
                                                                                //   defaultChecked
                                                                                  id={`daychuyen${it}`}
                                                                                  name="daychuyen"
                                                                                  value={item.daychuyen || ''}
                                                                                  onChange={(e) => handleChangeNVTC(it, e)}
                                                                              />
                                                                              <label
                                                                                  className="custom-control-label"
                                                                                  htmlFor={`daychuyen${it}`}
                                                                              ></label>
                                                                          </div>
                                                                      </span>
                                                                  </td> */}
                                                                  <td>
                                                                      <span className="sub-text nk-tb-col-tools text-end">
                                                                          {' '}
                                                                          <ul className="nk-tb-actions gx-4">
                                                                              {/* <button
                                                                                          onClick={() =>
                                                                                              handleEditNVTC(it)
                                                                                          }
                                                                                          className={
                                                                                              'btn btn-primary  badge text-white p-1'
                                                                                          }
                                                                                      >
                                                                                          <em className="icon ni ni-edit"></em>
                                                                                      </button> */}
                                                                              <>
                                                                                  <button
                                                                                      onClick={() =>
                                                                                          removeFormFieldsNVTC(it)
                                                                                      }
                                                                                      className={
                                                                                          'btn btn-danger  badge text-white p-1'
                                                                                      }
                                                                                  >
                                                                                      <em className="icon ni ni-trash-alt"></em>
                                                                                  </button>
                                                                              </>
                                                                          </ul>
                                                                      </span>
                                                                  </td>
                                                              </tr>
                                                          </React.Fragment>
                                                      ))
                                                    : null}
                                            </tbody>
                                        </Table>
                                    </div>
                                )}
                            </div>
                            <div className="row">
                                <div className="form-group col-md-12 mt-2">
                                    <Button color="primary" className="btn-icon px-4 mt-2" onClick={handleSubmit}>
                                        Thêm mới
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </Content>

            <SelectFinNhanvienfix
                showSelect={showSelect}
                // typeSelect={true}
                // listNhanvien={listNhanvien}
                setlistNhanvien={setlistNhanvien}
                setShowSelect={() => setShowSelect(false)}
            />
        </React.Fragment>
    );
};

export default ModalPhieutangca;
