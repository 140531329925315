export const validateBangChamCong = (fieldName, value) => {
    let regNumber = /^[0-9\b]+$/;
    switch (fieldName) {
        case 'tonggio':
            console.log('value', value)
            if (value.length === 0) {
                
                return {
                    name: fieldName,
                    message: 'Dữ liệu không được để trống'
                }
            }
            break;
        case 'cong':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Dữ liệu không được để trống'
                }
            }
            break;
        case 'vao1':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Dữ liệu không được để trống'
                }
            }
            break;
        case 'ra1':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Dữ liệu không được để trống'
                }
            }
            break;
        
        default:
            break;
    }
}