import React, {useState, useEffect} from 'react'
import {InputForm, Button} from '~/components/Form'
import '../Modal.scss';
import Select from 'react-select';
import {apiEditCauhinhthamnien} from "~/services";
import {toast} from "react-toastify";
import Content from "~/layout/content/Content";
import {Modal, ModalBody} from 'reactstrap';
import {validateCauHinhThamNien} from "~/request/validateCauHinhThamNien";
import Textarea from "~/components/Form/Textarea";
import {dataSelectActive, dataThoidiemlamthem} from "~/utils/Datacustom";
import Icon from "~/components/icon/Icon";
import {SelectFinNhanvien} from "~/components/Component";

const ModalEditChuyenCan = ({show, setShow, dataEdit, fieldList}) => {
    console.log('dataEdit', dataEdit)
    const [invalidFields, setInvalidFields] = useState([])
    const [payload, setPayload] = useState(fieldList)
    const [showSelect, setShowSelect] = useState(false);
    const [listNhanvien, setlistNhanvien] = useState([]);
    useEffect(() => {
        let status = dataSelectActive?.find((item) => {
            return item && item.value === dataEdit?.status;
        });
        show && dataEdit && setPayload({
            name: dataEdit.name,
            description: dataEdit.description,
            minday: dataEdit.minday,
            totalMaxMonth: dataEdit.totalMaxMonth,
            dateCheck: dataEdit.dateCheck,
            value: dataEdit.value,
            valueMax: dataEdit.valueMax,
            status: {label: status?.label, value: status?.value},
        })
        setlistNhanvien(
            dataEdit?.Nhanviens?.map((item) => ({
                value: item?.id,
                label: item?.hoten,
            })),
        );
    }, [dataEdit, show])
    // console.log('listNhanvien', listNhanvien)
    useEffect(() => {
        setPayload((prev) => ({
            ...prev,
            ['NhanvienId']: listNhanvien,
        }));
    }, [listNhanvien]);
    const handleSubmit = async () => {
        let invalids = validate(payload)
        
        if (invalids === 0) {
            // const dataNhanVien = listNhanvien?.map((item) => {
            //     return item.value
            // });
            let datapayload =  {
                data: [
                    {
                        id: dataEdit.id,
                        name: payload.name,
                        description: payload.description,
                        value: payload.value,
                        valueMax: payload.valueMax,
                        minday: payload.minday,
                        totalMaxMonth: payload.totalMaxMonth,
                        dateCheck: payload.dateCheck,
                        status: payload.status.value,
                        NhanvienId: listNhanvien,
                    }
                ]
            }
           
            const result = await apiEditCauhinhthamnien(datapayload)
            if (result.data.success === 200) {
                toast.success(result.data.message)
                setShow(false)
            } else {
                toast.error("Có lỗi xảy ra!")
            }
        }
    }
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayload(prev => ({...prev, [statename]: selected}))
    }
    const validate = (payload) => {
        let invalids = 0
        let fields = Object.entries(payload)
        fields.forEach(item => {
            if (validateCauHinhThamNien(item[0], item[1]) !== undefined) {
                setInvalidFields(prev => [...prev, validateCauHinhThamNien(item[0], item[1])])
                invalids++
            }
        })
        return invalids
    }
    return (
        <React.Fragment>
            <Content>
                <Modal isOpen={show} toggle={setShow} className="modal-dialog-centered modal-90w">
                    <ModalBody>
                        <Icon name="cross-sm float-end fs-2" onClick={setShow}></Icon>
                        <h5 className="title my-2"> Sửa cấu hình thâm niên</h5>
                        <div className="row">
                            <div className="form-group col-md-12">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Tên cấu hình'}
                                    value={payload.name}
                                    setValue={setPayload}
                                    keyPayload={'name'}
                                    type='text'
                                />
                            </div>
                            <div className="form-group col-md-12 mb-2">
                                <Textarea
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Mô tả'}
                                    value={payload.description}
                                    setValue={setPayload}
                                    keyPayload={'description'}
                                    type="text"
                                    validate
                                />
                            </div>
                            <div className="form-group col-md-4">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Tính thâm niên từ tháng'}
                                    placeholder={'Ngày kiểm tra thâm niên'}
                                    value={payload.totalMaxMonth}
                                    setValue={setPayload}
                                    keyPayload={'totalMaxMonth'}
                                    type='text'
                                    validate
                                />
                            </div>
                            <div className="form-group col-md-4">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Ngày cập nhật thâm niên hàng tháng'}
                                    placeholder={'VD: 15. Hệ thống cập nhật dữ liệu từ ngày 1-15 hàng tháng.'}
                                    value={payload.dateCheck}
                                    setValue={setPayload}
                                    keyPayload={'dateCheck'}
                                    type='text'
                                    validate
                                />
                            </div>
                            <div className="form-group col-md-4">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Giá trị thâm niên/ Năm'}
                                    value={payload.value}
                                    setValue={setPayload}
                                    keyPayload={'value'}
                                    type='text'
                                    validate
                                />
                            </div>
                            <div className="form-group col-md-4">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Giá trị thâm niên tối đa'}
                                    value={payload.valueMax}
                                    setValue={setPayload}
                                    keyPayload={'valueMax'}
                                    type='text'
                                    validate
                                />
                            </div>
                            <div className="form-group col-md-4 ">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Ngày công tối thiểu được PC / tháng'}
                                    value={payload.minday}
                                    setValue={setPayload}
                                    keyPayload={'minday'}
                                    type='text'
                                    validate
                                />
                            </div>
                            <div className="form-group col-md-4 ">
                                <label htmlFor="inputZip" className="mb-2">Trạng thái</label>
                                <Select
                                    value={payload.status}
                                    onChange={handleOnSelect}
                                    options={dataSelectActive}
                                    placeholder={'Trạng thái'}
                                    name='status'
                                    onFocus={() => setInvalidFields([])}
                                />
                                {invalidFields.length > 0 && invalidFields.some(i => i.name === 'status') && <small
                                    className='text-danger'>{invalidFields.find(i => i.name === 'status')?.message}</small>}
                            </div>
                            <div className="row">
                                <div className="form-group col-md-12" onClick={() => setShowSelect(true)}>
                                    <label htmlFor="inputZip" className="mb-2 fw-bold">
                                        Chọn Nhân Viên <b className="text-danger">(*)</b>
                                    </label>
                                    <Select
                                        value={payload.NhanvienId}
                                        name="NhanvienId"
                                        isMulti
                                        onFocus={() => setInvalidFields([])}
                                    />
                                    {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'NhanvienId') && (
                                        <small className="text-danger">
                                            {invalidFields.find((i) => i.name === 'NhanvienId')?.message}
                                        </small>
                                    )}
                                </div>
                            </div>
                        </div>
                        
                        <div className='col-12 mt-5'>
                            <Button
                                text='Cập nhật'
                                bClass='btn btn-primary px-5'
                                textColor
                                onClick={handleSubmit}
                            />
                        </div>
                    </ModalBody>
                </Modal>
            </Content>
            <SelectFinNhanvien
                showSelect={showSelect}
                typeSelect={true}
                listNhanvien={listNhanvien}
                cauHinhId={dataEdit?.id}
                setlistNhanvien={setlistNhanvien}
                typeSetting={'thamnien'} // 'chuyencan' || 'thamnien' || 'congthuc' || 'hoatdongsukien'
                setShowSelect={() => setShowSelect(false)}
            />
        </React.Fragment>
    );
}

export default ModalEditChuyenCan;

