import React, { useState, useEffect } from 'react';
import { validateChucVu } from '~/request/validateChucVu';
import '../Modal.scss';
import Select from 'react-select';
import { dataSelectStatus } from '~/utils/Datacustom';
import { Modal, ModalBody } from 'reactstrap';
import {Icon, SelectFinNhanvienfix, SelectNhanvien} from '~/components/Component';
import { NumericFormat } from 'react-number-format';
import {apiGetMoiquanhelaodong, apiNewMoiquanhenhanvien} from "~/services";
import {toast} from "react-toastify";
import {InputForm} from "~/components/Form";

const ModalMoiquanhenhanvien = ({show, setShow, fieldList,dataEdit,idCauHinh}) => {
    console.log('dataEdit',dataEdit,idCauHinh)
    const [showSelect, setShowSelect] = useState(false);
    const [listNhanvien, setlistNhanvien] = useState([]);
    const [listTrangthai, setlistTrangthai] = useState([]);
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayload] = useState(fieldList);
    
    useEffect(() => {
        show && setPayload(fieldList)
        setlistNhanvien(
            dataEdit?.map((item) => ({
                value: item?.value,
                label: item?.label,
            })),
        );
    }, [show])
    useEffect(() => {
        let TrangthaiId = dataSelectStatus.map((item) => ({ label: item.label, value: item.value }));
        setlistTrangthai(TrangthaiId);
        setPayload((prev) => ({
            ...prev,
            ['NhanvienId']: listNhanvien,
        }));
    }, [listNhanvien]);
    const handleSubmit = async () => {
        let invalids = validate(payload);

        if (invalids === 0) {
            const dataNhanVien = listNhanvien?.map((item) => {
                return item.value
            });
            let datapayload = {
                data: [
                    {
                        TrangthaiId: payload.TrangthaiId.value,
                        MoiquanhelaodongId: idCauHinh,
                        NhanvienId: dataNhanVien,
                        ngaybatdau: payload.ngaybatdau,
                        giatri: payload.giatri,
                    }
                ]
            }
            
            const response = await apiNewMoiquanhenhanvien(datapayload)
            if (response?.data.success === 200) {
                setShow(false);
                toast.success("Thêm mới thành công!")
            } else {
                toast.error("Thêm mới thất bại!")
            }
        }
    };
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayload((prev) => ({ ...prev, [statename]: selected }));
    };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateChucVu(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateChucVu(item[0], item[1])]);
                invalids++;
            }
        });
        return invalids;
    };
    return (
        <React.Fragment>
            <Modal isOpen={show} className="modal-dialog-centered modal-90w" size="lg" toggle={setShow}>
                <ModalBody>
                    <Icon name="cross-sm float-end fs-2" onClick={setShow}></Icon>
                    <h5 className="title mb-3">Thêm mới vấn đề phát sinh phía nhân sự</h5>
                    <div className="row">
                        <div className="form-group col-md-12" onClick={() => setShowSelect(true)}>
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                Chọn Nhân Viên <b className="text-danger">(*)</b>
                            </label>
                            <Select
                                value={payload.NhanvienId}
                                name="NhanvienId"
                                isMulti
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'NhanvienId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'NhanvienId')?.message}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                <span>
                                    Trạng thái <b className="text-danger">(*)</b>
                                </span>
                            </label>
                            <Select
                                value={payload.TrangthaiId}
                                onChange={handleOnSelect}
                                options={listTrangthai}
                                placeholder={'Chọn trạng thái'}
                                name="TrangthaiId"
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'TrangthaiId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'TrangthaiId')?.message}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-6">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Giá trị (VNĐ)'}
                                value={payload.giatri}
                                setValue={setPayload}
                                keyPayload={'giatri'}
                                type="text"
                                validate
                            />
                        </div>
                        <div className="form-group col-md-6">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Ngày bắt đầu thực hiện'}
                                value={payload.ngaybatdau}
                                setValue={setPayload}
                                keyPayload={'ngaybatdau'}
                                className={"my-0"}
                                type='DATE'
                                validate
                            />
                        </div>
                    </div>
                    <div className="btn-group my-2">
                        <button className="btn btn-primary px-5" onClick={handleSubmit}>
                            Thêm mới
                        </button>
                    </div>
                </ModalBody>
            </Modal>
            <SelectFinNhanvienfix
                showSelect={showSelect}
                typeSelect={true}
                listNhanvien={listNhanvien}
                setlistNhanvien={setlistNhanvien}
                cauHinhId={idCauHinh}
                typeSetting={'quanhenhanvien'} // 'chuyencan' || 'thamnien' || 'congthuc' || 'hoatdongsukien' || daychuyen
                setShowSelect={() => setShowSelect(false)}
            />
        </React.Fragment>
    );
};

export default ModalMoiquanhenhanvien;
