import actionTypes from './actionTypes'
import { apiGetBaohiemnhanvien, apiNewBaohiemnhanvien, apiEditBaohiemnhanvien, apiDeleteBaohiemnhanvien } from '~/services/baohiemnhanvien'
import { toast } from "react-toastify";
export const getListBaohiemnhanvien = () => async (dispatch) => {
    try {
        const response = await apiGetBaohiemnhanvien()
        // console.log('chek api lisst Baohiemnhanvien', response?.data.success)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_BAO_HIEM_NHAN_VIEN,
                baohiemnhanvien: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_BAO_HIEM_NHAN_VIEN,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_BAO_HIEM_NHAN_VIEN,
            baohiemnhanvien: null
        })
    }
}

export const NewBaohiemnhanvien = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewBaohiemnhanvien', payload)
            const response = await apiNewBaohiemnhanvien(payload)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_BAO_HIEM_NHAN_VIEN,
                    msg: 'OK'
                });
                dispatch(getListBaohiemnhanvien());
                toast.success("Thêm mới bảo hiểm nhân viên thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_BAO_HIEM_NHAN_VIEN,
                    msg: 'ERR'
                })
                toast.error("Thông tin bảo hiểm nhân viên này đã tồn tại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_BAO_HIEM_NHAN_VIEN,
                baohiemnhanvien: null
            })
        }
    }
}
export const EditBaohiemnhanvien = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditBaohiemnhanvien(payload)
            // console.log('chek api apiEditBaohiemnhanvien', response)
            if (response?.data.success === 200) {
                dispatch(getListBaohiemnhanvien());
                toast.success("Sửa thông tin bảo hiểm nhân viên thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_BAO_HIEM_NHAN_VIEN,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_BAO_HIEM_NHAN_VIEN,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin bảo hiểm nhân viên thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_BAO_HIEM_NHAN_VIEN,
                baohiemnhanvien: null
            })
        }
    }
}
export const DeleteBaohiemnhanvien = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteBaohiemnhanvien(payload)
            // console.log('chek api apiDeleteBaohiemnhanvien', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListBaohiemnhanvien());
                toast.success("Xóa thông tin bảo hiểm nhân viên thành công!")
                dispatch({
                    type: actionTypes.DELETE_BAO_HIEM_NHAN_VIEN,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_BAO_HIEM_NHAN_VIEN,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin bảo hiểm nhân viên thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_BAO_HIEM_NHAN_VIEN,
                baohiemnhanvien: null
            })
        }
    }
}