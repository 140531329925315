import React, {useState, useEffect, useMemo} from 'react';
import {ModalKhac, ModalEditKhac} from './Modal';
import Swal from 'sweetalert2';
import {
    Card,
} from 'reactstrap';
import Head from '~/layout/head/Head';
import Content from '~/layout/content/Content';
import {Block} from '~/components/block/Block';
import {
    Button,
    DataTable,
    Icon,
} from '~/components/Component';
import {ExportToCsv} from "export-to-csv";
import {toast} from "react-toastify";
import {MaterialReactTable, useMaterialReactTable} from "material-react-table";
import {MRT_Localization_VI} from "material-react-table/locales/vi";
import {Box, IconButton, Tooltip} from "@mui/material";
import {Delete as DeleteIcon, Edit as EditIcon} from "@mui/icons-material";
import {
    apiGetCauhinhdaychuyen,
} from "~/services";
import {MenuLuongCapThuong} from "~/pages/Admin/MenuTab/Menucontent";
import {dataPagination} from "~/utils/Datacustom";

const Cauhinhdaychuyen = () => {
    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    //lấy giá trị từ thằng redux trả về
    const [data, setData] = useState([]);
    const [dataEdit, setDataEdit] = useState([]);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const fieldList = {
        name: '',
        description: '',
        status: '',
        value: '',
        workhour: '',
        NhanvienId: '',
    };
    const fetchData = async () => {
        try {
            const datapayloads = {
                typeGet: 1, // 1: lấy theo điều kiện phân trang, 2: lấy tất cả và không cần truyền d liệu phân trang
                start: pagination.pageIndex || 0,
                size: pagination.pageSize || 10,
                globalFilter: 'id',
                sorting: 'desc',
            };
            const response = await apiGetCauhinhdaychuyen(datapayloads)
            // console.log('response', response)
            if (response?.data?.success === 200) {
                setData(response?.data?.data?.rows);
                setTotalRowCount(response?.data?.data?.count);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        fetchData().then(r => r)
    }, [pagination.pageIndex, pagination.pageSize, show, showEdit]);
    const handleAddNew = async () => {
        setShow(true);
    };
    const handleEdit = async (data) => {
        setShowEdit(true);
        await setDataEdit(data);
    };
  
    const Example = () => {
        let dataAb = data;
        // Tạo dữ liêu export
        const StatusActive = (cell) => ( // Function show trạng thái
            <Box
                component="span"
                sx={(theme) => ({
                    backgroundColor: cell === 1 ? '#1ee0ac' : '#e65100',
                    fontSize: '10px',
                    borderRadius: '0.25rem',
                    color: '#fff',
                    p: '0.25rem',
                })}
            >
                {cell === 1 ? 'Hoạt động' : 'Không hoạt động'}
            </Box>
        );
        
        const columns = useMemo(
            () => [
                {
                    Header: '#', // Tiêu đề cột số thứ tự
                    header: 'STT', // Tiêu đề cột số thứ tự
                    id: 'rowNumber', // ID của cột
                    size: 50,
                    Cell: ({row}) => <div>{row?.index + 1}</div>, // Giá trị của cột là số thứ tự
                    enableSorting: false,
                    enablePinning: true,
                },
                {
                    accessorKey: 'name',
                    enablePinning: true,
                    size: 100,
                    header: ' Tiêu đề',
                },
                {
                    accessorKey: 'status',
                    enablePinning: true,
                    size: 50,
                    header: ' Trạng thái',
                    Cell: ({cell}) => StatusActive(Number(cell?.getValue()) || 0),
                },
                {
                    accessorKey: 'value',
                    header: 'Giá trị',
                    enablePinning: true,
                },
                {
                    accessorKey: 'workhour',
                    header: 'Số giờ làm việc hưởng PC',
                    enablePinning: true,
                },
            
            ],
            [],
        );
        const csvOptions = {
            fileName: 'csv-data',
            fieldSeparator: ',',
            quoteStrings: '"',
            filename: 'cau-hinh-day-chuyen',
            decimalSeparator: '.',
            showLabels: true,
            useBom: true,
            useKeysAsHeaders: false,
            headers: columns.map((c) => c.header),
        };
        
        
        const csvExporter = new ExportToCsv(csvOptions);
        
        const handleExportData = () => {
            const dataExport = dataAb?.map((item, indexx) => {
                let abc = {}
                abc.rowNumber = indexx + 1
                abc.name = item.name
                abc.description = item.description
                abc.status = StatusActive(Number(item.status) || 0)
                abc.value = item.value
                abc.workhour = item.workhour
                
                return abc
            })
            if (dataExport?.length === 0) {
                toast.error("Không có dữ liệu để xuất!")
                return;
            }
            csvExporter.generateCsv(dataExport);
        };
        const rowActions = ({row, table}) => ( // Thêm nút sửa xóa vào hàng
            <Box sx={{display: 'flex', gap: '1rem'}}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => handleEdit(row.original)}>
                        <EditIcon/>
                    </IconButton>
                </Tooltip>
            </Box>
        );
        const topToolbarCustomActions = ({table}) => ( // Thêm nút thêm mới và xuất dữ liệu
            <Box sx={{display: 'flex', gap: '1rem'}}>
           
                <Button variant="contained" color="primary" onClick={handleAddNew} sx={{mr: 2}}>
                    + Thêm mới
                </Button>
                <Button color="primary" onClick={handleExportData} variant="contained">
                    <Icon name="download-cloud me-2"></Icon> Xuất dữ liệu
                </Button>
            
            </Box>
        );
        const state = { // Trạng thái của bảng
            expanded: true,
            columnFilters,
            globalFilter,
            isLoading: dataAb?.length === 0,
            pagination,
            sorting,
        };
        const displayColumnDefOptions = { // Các cột hiển thị
            'mrt-row-actions': {
                sx: {width: '200px', paddingRight: '10px'},
                header: 'Thao tác',
                enableOrdering: true,
                enablePinning: true,
                enableColumnActions: true,
            }
        };
        const initialState = { // Các trạng thái ban đầu của bảng đc gim
            columnPinning: {left: ['rowNumber', 'name'], right: ['mrt-row-actions']},
            density: 'compact'
        };
        const table = useMaterialReactTable({
                columns,
                data,
                enableColumnResizing: false,
                enableDensityToggle: false,
                initialState: initialState,
                displayColumnDefOptions: displayColumnDefOptions,
                state: state,
                defaultDisplayColumn: {enableResizing: true},
                rowCount: totalRowCount,
                manualFiltering: true,
                manualPagination: true,
                manualSorting: true,
                onPaginationChange: setPagination,
                onColumnFiltersChange: setColumnFilters,
                onGlobalFilterChange: setGlobalFilter,
                onSortingChange: setSorting,
                localization: MRT_Localization_VI,
                enableRowActions: true,
                enableColumnPinning: true,
                renderTopToolbarCustomActions: topToolbarCustomActions,
                renderRowActions: rowActions,
                muiPaginationProps: {
                    rowsPerPageOptions: dataPagination,
                },
            }
        );
        
        
        return (
            <MaterialReactTable
                table={table}
            />
        );
    };
    
    return (
        <React.Fragment>
            <Head title="Hoạt động sự kiện doanh nghiệp"></Head>
            <Content>
                <Card className="mt-0">
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner card-inner-lg px-4">
                            <h4>Cấu hình PC dây chuyền</h4>
                            <MenuLuongCapThuong/>
                        </div>
                    </DataTable>
                </Card>
                <Block>
                    <DataTable className="card-stretch border-0">
                        <div style={{overflowY: 'hidden'}}>
                            <DataTable className="card-stretch border-0">
                                <div className="card-inner position-relative card-tools-toggle">
                                    <Example/>
                                </div>
                            </DataTable>
                        </div>
                    </DataTable>
                </Block>
                
                <ModalKhac show={show} fieldList={fieldList} setShow={() => setShow(false)}/>
                <ModalEditKhac
                    show={showEdit}
                    fieldList={fieldList}
                    setShow={() => setShowEdit(false)}
                    dataEdit={dataEdit}
                />
            </Content>
        </React.Fragment>
    );
};

export default Cauhinhdaychuyen;
