import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as actions from '~/store/actions';
import {ModalEditQuyetdinh, ModalQuyetdinh} from '../Admin/Modal';
import Swal from 'sweetalert2';
import {Card, DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledDropdown,} from 'reactstrap';
import Head from '~/layout/head/Head';
import Content from '~/layout/content/Content';
import {Block} from '~/components/block/Block';
import {
    BlockBetween,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Button,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    ExportXlsx,
    Icon,
    PaginationComponent,
    PermissionUtils,
    TooltipComponent,
} from '~/components/Component';
import {MenuNhanVien} from './MenuTab/Menucontent';
import {useSearchParams} from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import { format, parseISO } from 'date-fns';
import {dataStatusNghiphep} from '~/utils/Datacustom';

const Nhanvien_quyetdinh = () => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [searchParams, setsearchParams] = useSearchParams();
    // console.log('searchParams', searchParams.get('type'))
    const [Title, setTitle] = useState(null);
    useEffect(() => {
        let type = searchParams.get('type');
        if(type === "bo-nhiem"){
            setTitle({name: "bổ nhiệm", ma: "BN"});
        } else if(type === "mien-nhiem"){
            setTitle({name: "miễn nhiệm", ma: "MN"});
        } else if(type === "thuyen-chuyen"){
            setTitle({name: "thuyên chuyển", ma: "TC"});
        } else if(type === "nghi-viec"){
            setTitle({name: "nghỉ việc", ma: "NGV"});
        } 
    }, [searchParams.get('type')]);

    useEffect(() => {
        dispatch(actions.getListCongthuctinhluong());
        dispatch(actions.getListPhucap());
        dispatch(actions.getListChucvu());
        dispatch(actions.getListQuyetdinh());
        dispatch(actions.getListCauhinhpheduyet());
    }, []);
    const { phucap } = useSelector((state) => state.phucap);
    const { quyetdinh } = useSelector((state) => state.nhanvien);
    const { chucvu } = useSelector((state) => state.chucvu);
    const { congthuctinhluong } = useSelector((state) => state.congthuctinhluong);
    const { cauhinhpheduyet } = useSelector((state) => state.quanly);
    useEffect(() => {
        if (phucap) {
            setlistPhucap(phucap.map((item) => ({ label: item.title, value: item.id })));
        }
        if (chucvu) {
            setlistChuvu(chucvu.map((item) => ({ label: item.chucvu, value: item.id })));
        }
        if (congthuctinhluong) {
            setlistCongthuc(congthuctinhluong.map((item) => ({ label: item.tencongthuc, value: item.id })));
        }
        if (cauhinhpheduyet) {
            setlistPheduyet(cauhinhpheduyet.filter((item) => (Number(item.type_duyet) === 7)).map((item) => ({...item, value: item.id, label: item.title})));
        }
        if (quyetdinh) {
            let datafin = quyetdinh.filter((item) => item?.type === searchParams.get('type'));
            setData(datafin);
        }
    }, [phucap, quyetdinh,congthuctinhluong, cauhinhpheduyet, searchParams.get('type')]);

    const [listPhucap, setlistPhucap] = useState([]);
    const [listChucvu, setlistChuvu] = useState([]);
    const [listCongthuc, setlistCongthuc] = useState([]);
    const [listPheduyet, setlistPheduyet] = useState([]);
    // console.log('listPheduyet', listPheduyet)
    //lấy giá trị từ thằng redux trả về
    // const {quyetdinh} = useSelector((state) => state.quanly);
    const [data, setData] = useState([]);
    // console.log('data', data)
    const fieldList = {
        matusinh: '',
        ChucvuId: '',
        note: '',
        NhanvienId: '',
        mucluong: '',
        mucluongformat: '',
        data_phucap: '',
        type: '',
        ngayhieuluc: '',
        CongthucId: '',
        TrangthaiId: '',
        PheduyetId: '',
    };
    
    // useEffect(() => {
    //     quyetdinh && setData([...quyetdinh]);
    // }, [quyetdinh]);
    
    const [showPhanquyen, setShowPhanquyen] = useState({});
    let dataPermissionUtils = PermissionUtils('nhanvien', 'all');
    useEffect(() => {
        if (dataPermissionUtils) {
            setShowPhanquyen(dataPermissionUtils);
        }
    }, [dataPermissionUtils]);
    
    const namefile = 'Thông tin quyết định';
    const header = ['Stt', `Mã ${Title?.name}`,'Nhân viên', 'Mô tả', 'Ngày hiệu lực', 'Trạng thái'];
    const [columns, setColumns] = useState(() => {
        return header.map((item, index) => index <= 6);
    });
    const [dataFileMau, setDataFileMau] = useState([]);
    useEffect(() => {
        if (data.length > 0) {
            const datafilemau = data.map((item, index) => {
                return {
                    ...(columns[0] && {Stt: index + 1}),
                    ...(columns[1] && {'Tiêu đề': item?.phongban}),
                    ...(columns[2] && {'Mô tả': item?.mota}),
                    ...(columns[3] && {'Điện thoại': item?.dienthoai}),
                    ...(columns[4] && {'Trực thuộc': item?.parentId}),
                };
            });
            setDataFileMau(datafilemau);
        }
    }, [data, columns]);
    
    const [sm, updateSm] = useState(false);
    const [tablesm, updateTableSm] = useState(false);
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(20);
    const [sort, setSortState] = useState('');
    // onChange function for searching name
    const onFilterChange = (e) => {
        setSearchText(e.target.value);
    };
    const toggle = () => setonSearch(!onSearch);
    const selectorCheck = (e) => {
        let newData;
        newData = data.map((item) => {
            item.checked = e.currentTarget.checked;
            return item;
        });
        setData([...newData]);
    };
    const onSelectChange = (e, id) => {
        let newData = data;
        let index = newData.findIndex((item) => item.id === id);
        newData[index].checked = e.currentTarget.checked;
        setData([...newData]);
    };
    
    useEffect(() => {
        if (onSearchText !== '') {
            const filteredObject = data.filter((item) => {
                return (
                    item.title.toLowerCase().includes(onSearchText.toLowerCase())
                );
            });
            setData([...filteredObject]);
        } else {
            setData([...data]);
        }
    }, [onSearchText, setData]);
    // console.log('data', data)
    
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    
    // useEffect(() => {
    //     (quyetdinh && setShow(false)) || setShowEdit(false);
    // }, [quyetdinh]);
    // set state để mở đóng Modal
    const handleAddNewQuyetdinh = async () => {
        setShow(true);
    };
    const [dataIdQuyetdinh, setDataIdQuyetdinh] = useState([]);
    const handleEditQuyetdinh = async (data) => {
        setShowEdit(true);
        await setDataIdQuyetdinh(data);
    };


    const handleDeleteQuyetdinh = (data) => {
        Swal.fire({
            title: 'Bạn có chắc không?',
            text: 'Bạn sẽ không thể lấy lại khi xóa!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Không, bỏ qua!',
            confirmButtonText: 'Vâng, xóa nó đi!',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(actions.DeleteQuyetdinh(data));
            }
        });
    };
    const itemsPerPageOptions = [10, 20, 30, 40, 50, 100, 300, 500];
    return (
        <React.Fragment>
            <Head title="Quản lý hồ sơ nhân viên"></Head>
            <Content>
                <BlockHead size="sm mt-3">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                Quản lý hồ sơ nhân viên
                            </BlockTitle>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <Button
                                    className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? 'active' : ''}`}
                                    onClick={() => updateSm(!sm)}
                                >
                                    <Icon name="plus"></Icon>
                                </Button>
                                <div className="toggle-expand-content" style={{ display: sm ? 'block' : 'none' }}>
                                    <ul className="nk-block-tools g-3">
                                        {+showPhanquyen?.them === 1 && (
                                            <>
                                                {/* <li className="nk-block-tools-opt">
                                                    <Link to={`${config.he_thong}${config.them_moi_nhan_vien}`}>
                                                        <Button
                                                            color="primary"
                                                            className="btn-icon px-1"
                                                        >
                                                            <Icon name="plus"></Icon> Thêm mới nhân viên
                                                        </Button>
                                                    </Link>
                                                </li>
                                                <li className="nk-block-tools-opt">
                                                    <Link
                                                        className="nav-link"
                                                        to={`${config.he_thong}${config.nhom_nhan_vien}`}
                                                    >
                                                        <Button color="success" className="btn-icon px-1">
                                                            <Icon name="plus"></Icon> Nhóm nhân viên
                                                        </Button>
                                                    </Link>
                                                </li> */}
                                            </>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </BlockHeadContent>
                    </BlockBetween>

                    <MenuNhanVien/>

                </BlockHead>
                <Block>
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner position-relative card-tools-toggle pt-0">
                            <div className="card-title-group mt-3">
                                <div className="card-title">
                                    <h5 className="title">Quyết định {Title?.name}</h5>
                                    {+showPhanquyen?.them === 1 && (
                                        <Button
                                            color="primary"
                                            className="btn-icon px-4 mt-2"
                                            onClick={handleAddNewQuyetdinh}
                                        >
                                            <Icon name="plus"></Icon> Thêm mới
                                        </Button>
                                    )}
                                </div>
                                <div className="card-tools">
                                    <ul className="btn-toolbar gx-1">
                                        <li>
                                            <a
                                                href="#search"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    toggle();
                                                }}
                                                className="btn btn-icon search-toggle toggle-search"
                                            >
                                                <Icon name="search"></Icon>
                                            </a>
                                        </li>
                                        <li className="btn-toolbar-sep"></li>
                                        <li>
                                            <div className="toggle-wrap">
                                                <Button
                                                    className={`btn-icon btn-trigger toggle ${tablesm ? 'active' : ''}`}
                                                    onClick={() => updateTableSm(true)}
                                                >
                                                    <Icon name="menu-right"></Icon>
                                                </Button>
                                                <div className={`toggle-content ${tablesm ? 'content-active' : ''}`}>
                                                    <ul className="btn-toolbar gx-1">
                                                        <li className="toggle-close">
                                                            <Button
                                                                className="btn-icon btn-trigger toggle"
                                                                onClick={() => updateTableSm(false)}
                                                            >
                                                                <Icon name="arrow-left"></Icon>
                                                            </Button>
                                                        </li>
                                                        <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle
                                                                    color="tranparent"
                                                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                                                >
                                                                    <Icon name="setting"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu end className="dropdown-menu-xs">
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Show</span>
                                                                        </li>
                                                                        {itemsPerPageOptions.map((item, index) => {
                                                                            return (
                                                                                <li
                                                                                    className={
                                                                                        itemPerPage === item
                                                                                            ? 'active'
                                                                                            : ''
                                                                                    }
                                                                                    key={index}
                                                                                >
                                                                                    <DropdownItem
                                                                                        tag="a"
                                                                                        href="#dropdownitem"
                                                                                        onClick={(ev) => {
                                                                                            ev.preventDefault();
                                                                                            setItemPerPage(item);
                                                                                            // handleReParamsClick();
                                                                                        }}
                                                                                    >
                                                                                        {item}
                                                                                    </DropdownItem>
                                                                                </li>
                                                                            );
                                                                        })}
                                                                    </ul>
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Order</span>
                                                                        </li>
                                                                        <li className={sort === 'dsc' ? 'active' : ''}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setSortState('dsc');
                                                                                    //   sortFunc("dsc");
                                                                                }}
                                                                            >
                                                                                DESC
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li className={sort === 'asc' ? 'active' : ''}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setSortState('asc');
                                                                                    //   sortFunc("asc");
                                                                                }}
                                                                            >
                                                                                ASC
                                                                            </DropdownItem>
                                                                        </li>
                                                                    </ul>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="toggle-wrap nk-block-tools-toggle">
                                                <Button
                                                    className={`btn-icon btn-trigger toggle-expand me-n1 ${
                                                        sm ? 'active' : ''
                                                    }`}
                                                    onClick={() => updateSm(!sm)}
                                                >
                                                    <Icon name="upload-cloud"></Icon>
                                                </Button>
                                                <div
                                                    className="toggle-expand-content-cloud"
                                                    style={{display: sm ? 'block' : 'none'}}
                                                >
                                                    <ul className="nk-block-tools g-3">
                                                        <li className="nk-block-tools-opt">
                                                            <ExportXlsx
                                                                dataFileMau={dataFileMau}
                                                                title={header}
                                                                namefile={namefile}
                                                                btnname={'Xuất dữ liệu'}
                                                            />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className={`card-search search-wrap ${!onSearch && 'active'}`}>
                                <div className="card-body">
                                    <div className="search-content">
                                        <Button
                                            className="search-back btn-icon toggle-search active"
                                            onClick={() => {
                                                setSearchText('');
                                                toggle();
                                            }}
                                        >
                                            <Icon name="arrow-left"></Icon>
                                        </Button>
                                        <input
                                            type="text"
                                            className="border-transparent form-focus-none form-control"
                                            placeholder="Tìm kiếm"
                                            value={onSearchText}
                                            onChange={(e) => onFilterChange(e)}
                                        />
                                        <Button className="search-submit btn-icon">
                                            <Icon name="search"></Icon>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='fix_height' style={{overflowY: 'hidden'}}>
                            <DataTableBody>
                                <DataTableHead>
                                    {columns[0] && (
                                        <DataTableRow>
                                            <span className="sub-text">Stt</span>
                                        </DataTableRow>
                                    )}
                                    {columns[1] && (
                                        <DataTableRow>
                                            <span className="sub-text w-150px">Mã {Title?.name}</span>
                                        </DataTableRow>
                                    )}
                                    {columns[2] && (
                                        <DataTableRow>
                                            <span className="sub-text w-150px">Nhân viên</span>
                                        </DataTableRow>
                                    )}
                                    {columns[3] && (
                                        <DataTableRow>
                                            <span className="sub-text w-150px">Mô tả</span>
                                        </DataTableRow>
                                    )}
                                     {columns[4] && (
                                    <DataTableRow>
                                        <span className="sub-text w-150px">Ngày hiệu lực</span>
                                    </DataTableRow>
                                    )}
                                    <DataTableRow>
                                        <span className="sub-text w-150px">Trạng thái</span>
                                    </DataTableRow>
                                    <DataTableRow className="nk-tb-col-tools text-end">
                                        <UncontrolledDropdown>
                                            <DropdownToggle
                                                color="tranparent"
                                                className="btn btn-xs btn-outline-light btn-icon dropdown-toggle"
                                            >
                                                <Icon name="plus"></Icon>
                                            </DropdownToggle>
                                            <DropdownMenu end className="dropdown-menu-xs">
                                                <ul className="link-tidy sm no-bdr">
                                                    {header.length > 0 ? (
                                                        header.map((item, index) => (
                                                            <li key={index}>
                                                                <div
                                                                    className="custom-control custom-control-sm custom-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="custom-control-input"
                                                                        id={`column-${index}`}
                                                                        checked={columns[index]}
                                                                        onChange={(e) =>
                                                                            setColumns((prev) => {
                                                                                const newColumns = [...prev];
                                                                                newColumns[index] = e.target.checked;
                                                                                return newColumns;
                                                                            })
                                                                        }
                                                                    />
                                                                    <label
                                                                        className="custom-control-label"
                                                                        htmlFor={`column-${index}`}
                                                                    >
                                                                        {item}
                                                                    </label>
                                                                </div>
                                                            </li>
                                                        ))
                                                    ) : (
                                                        <li>No data</li>
                                                    )}
                                                </ul>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </DataTableRow>
                                </DataTableHead>
                                {currentItems.length > 0
                                ? currentItems.map((item, index) => {
                                    const displayedIndex =  itemPerPage * (currentPage - 1) + index + 1;
                                    const trangthai = dataStatusNghiphep.find(select => select.value === item.TrangthaiId);
                                      return (
                                          <DataTableItem key={item.id}>
                                              {columns[0] && (
                                                  <DataTableRow size=" nk-tb-col-check mb">
                                                      <span className="tb-amount">{displayedIndex}</span>
                                                  </DataTableRow>
                                              )}
                                             
                                              {columns[1] && (
                                                  <DataTableRow>
                                                      <span className="tb-amount">{item?.matusinh}</span>
                                                  </DataTableRow>
                                              )}
                                              {columns[2] && (
                                                  <DataTableRow>
                                                      <span className="tb-amount">{item?.Nhanvien?.hoten}</span>
                                                  </DataTableRow>
                                              )}
                                              {columns[3] && (
                                                  <DataTableRow>
                                                      <span className="tb-amount">{item?.note}</span>
                                                  </DataTableRow>
                                              )}
                                              {columns[4] && (
                                                  <DataTableRow>
                                                      <span className="tb-amount">{item?.ngayhieuluc ? format(parseISO(item?.ngayhieuluc), 'dd-MM-yyyy') : null}</span>
                                                  </DataTableRow>
                                              )}
                                             <DataTableRow>
                                                <span className={`${trangthai?.color || ''} ms-0 badge text-white`}>
                                                    {trangthai?.label || 'Không xác định'}
                                                </span>
                                            </DataTableRow>
                                             
                                              <DataTableRow className="nk-tb-col-tools">
                                                  <ul className="nk-tb-actions gx-1">
                                                      {+showPhanquyen?.sua === 1 && (
                                                          <li
                                                              className="nk-tb-action-hidden"
                                                              onClick={() => handleEditQuyetdinh(item)}
                                                          >
                                                              <TooltipComponent
                                                                  tag="a"
                                                                  containerClassName="btn btn-trigger btn-icon"
                                                                  id={'edit' + item.id}
                                                                  icon="edit-alt-fill"
                                                                  direction="top"
                                                                  text="Sửa"
                                                              />
                                                          </li>
                                                      )}
                                                      {+showPhanquyen?.xoa === 1 && (
                                                          <React.Fragment>
                                                              <li
                                                                  className="nk-tb-action-hidden"
                                                                  onClick={() => handleDeleteQuyetdinh(item)}
                                                              >
                                                                  <TooltipComponent
                                                                      tag="a"
                                                                      containerClassName="btn btn-trigger btn-icon"
                                                                      id={'suspend' + item.id}
                                                                      icon="user-cross-fill"
                                                                      direction="top"
                                                                      text="Xóa"
                                                                  />
                                                              </li>
                                                          </React.Fragment>
                                                      )}
                                                      <li>
                                                          <UncontrolledDropdown>
                                                              {(+showPhanquyen?.sua === 1 ||
                                                                  +showPhanquyen?.xoa === 1) && (
                                                                  <DropdownToggle
                                                                      tag="a"
                                                                      className="dropdown-toggle btn btn-icon btn-trigger"
                                                                  >
                                                                      <Icon name="more-h"></Icon>
                                                                  </DropdownToggle>
                                                              )}
                                                              <DropdownMenu end>
                                                                  <ul className="link-list-opt no-bdr">
                                                                      {+showPhanquyen?.sua === 1 && (
                                                                          <li onClick={() => handleEditQuyetdinh(item)}>
                                                                              <DropdownItem
                                                                                  tag="a"
                                                                                  href="#edit"
                                                                                  onClick={(ev) => {
                                                                                      ev.preventDefault();
                                                                                  }}
                                                                              >
                                                                                  <Icon name="edit"></Icon>
                                                                                  <span>Sửa</span>
                                                                              </DropdownItem>
                                                                          </li>
                                                                      )}
                                                                      {+showPhanquyen?.xoa === 1 && (
                                                                          <React.Fragment>
                                                                              <li className="divider"></li>
                                                                              <li
                                                                                  onClick={() =>
                                                                                      handleDeleteQuyetdinh(item)
                                                                                  }
                                                                              >
                                                                                  <DropdownItem
                                                                                      tag="a"
                                                                                      href="#suspend"
                                                                                      onClick={(ev) => {
                                                                                          ev.preventDefault();
                                                                                      }}
                                                                                  >
                                                                                      <Icon name="na"></Icon>
                                                                                      <span>Xóa</span>
                                                                                  </DropdownItem>
                                                                              </li>
                                                                          </React.Fragment>
                                                                      )}
                                                                  </ul>
                                                              </DropdownMenu>
                                                          </UncontrolledDropdown>
                                                      </li>
                                                  </ul>
                                              </DataTableRow>
                                          </DataTableItem>
                                      );
                                  })
                                : null}
                            </DataTableBody>
                        </div>
                        <div className="card-inner">
                            {currentItems.length > 0 ? (
                                <PaginationComponent
                                    itemPerPage={itemPerPage}
                                    totalItems={currentItems.length}
                                    paginate={paginate}
                                    currentPage={currentPage}
                                />
                            ) : (
                                <div className="text-center">
                                    <div>
                                        <Spinner color="primary"/>
                                    </div>
                                    <span className="text-silent">Chưa có dữ liệu!</span>
                                </div>
                            )}
                        </div>
                    </DataTable>
                </Block>
                
                <ModalQuyetdinh
                    show={show}
                    setShow={() => setShow(false)}
                    fieldList={fieldList}
                    title={Title}
                    type={searchParams.get('type')}
                    listPhucap={listPhucap}
                    phucap={phucap}
                    setlistPhucap={setlistPhucap}
                    data={data}
                    listChucvu={listChucvu}
                    listCongthuc={listCongthuc}
                    listPheduyet={listPheduyet}
                />
                <ModalEditQuyetdinh
                    show={showEdit}
                    setShow={() => setShowEdit(false)}
                    dataIdQuyetdinh={dataIdQuyetdinh}
                    fieldList={fieldList}
                    title={Title}
                    type={searchParams.get('type')}
                    // listPhucap={listPhucap}
                    // phucap={phucap}
                    // setlistPhucap={setlistPhucap}
                    // listChucvu={listChucvu}
                    // listCongthuc={listCongthuc}
                    // listPheduyet={listPheduyet}
                    // listPhongBan={data}
                />
            </Content>
        </React.Fragment>
    );
};

export default Nhanvien_quyetdinh;
