import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {InputForm, Button} from '~/components/Form';
import * as actions from '~/store/actions';
import '../Modal.scss';
import Select from 'react-select';
import {validatePhuongTien} from '~/request/validatePhuongTien';
import {dataSelectActive} from "~/utils/Datacustom";
import {Modal, ModalBody, Input} from 'reactstrap';
import {Icon, SelectFinNhanvienfix} from "~/components/Component";
import Textarea from "~/components/Form/Textarea";

const ModalEditKehoachcuaxe = ({show, setShow, dataIdPhuongtien, fieldList}) => {
      console.log('dataIdPhuongtien',dataIdPhuongtien)
    const dispatch = useDispatch();
    const [listTrangthai, setlistTrangthai] = useState([]);
    // const {loaiphuongtien} = useSelector((state) => state.quanly);
    const {phuongtien} = useSelector((state) => state.quanly);
    const {nhanvien} = useSelector(state => state.nhanvien)
    const {phongban} = useSelector(state => state.phongban)
    // const [listLoaiphuongtien, setlistLoaiphuongtien] = useState(loaiphuongtien);
    const [listPhuongtien, setlistPhuongtien] = useState(phuongtien);
    const [listPhongban, setlistPhongban] = useState(phuongtien);
    const [listNhanvien, setlistNhanvien] = useState(nhanvien);
    const [showSelect, setShowSelect] = useState(false);
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayload] = useState(fieldList);
    // console.log('listLoaiphuongtien', listLoaiphuongtien)
    useEffect(() => {
        // if (loaiphuongtien && loaiphuongtien.length > 0) {
        //     let LoaiphuongtienId = loaiphuongtien.map((item) => ({label: item.tenloaiphuongtien, value: item.id}));
        //     setlistLoaiphuongtien(LoaiphuongtienId);
        // }
        if (phuongtien && phuongtien.length > 0) {
            let PhuongtienId = phuongtien
                .filter(item => item.TrangthaiId === 1)
                .map(item => ({label: 'Xe: ' + item?.biensoxe + ' ('+item?.Loaiphuongtien?.tenloaiphuongtien + ')', value: item?.id}));
            setlistPhuongtien(PhuongtienId);
        }
        if (phongban && phongban.length > 0) {
            let PhongbanId = phongban.map((item) => ({label: item.phongban, value: item.id}));
            setlistPhongban(PhongbanId);
        }
        if (nhanvien && nhanvien.length > 0) {
            let NhanvienId = nhanvien
                .filter(item => item.TrangthaiId === 1)
                .map(item => ({label: item.hoten, value: item.id}));
            setlistNhanvien(NhanvienId);
        }
        let TrangthaiId = dataSelectActive.map((item) => ({label: item.label, value: item.value}));
        setlistTrangthai(TrangthaiId);
    }, [phongban, nhanvien, phuongtien]);
    useEffect(() => {
        let TrangthaiId = dataSelectActive.find((items) => {
            return items && items.value === dataIdPhuongtien?.TrangthaiId;
        });
        // let LoaiphuongtienId = listLoaiphuongtien?.find((items) => {
        //     return items && items.value === dataIdPhuongtien?.LoaiphuongtienId;
        // });
        let PhuongtienId = listPhuongtien?.find((items) => {
            return items && items.value === dataIdPhuongtien?.PhuongtienId;
        });
        let PhongbanId = listPhongban?.find((items) => {
            return items && items.value === dataIdPhuongtien?.PhongbanId;
        });
        
        show &&
        dataIdPhuongtien &&
        setPayload({
            TrangthaiId: { label: TrangthaiId?.label, value: TrangthaiId?.value },
            // LoaiphuongtienId: { label: LoaiphuongtienId?.label, value: LoaiphuongtienId?.value },
            PhongbanId: { label: PhongbanId?.label, value: PhongbanId?.value },
            PhuongtienId: { label: PhuongtienId?.label, value: PhuongtienId?.value },
            // NhanvienId: { label: NhanvienId?.label, value: NhanvienId?.value },
            malenh: dataIdPhuongtien.malenh,
            ngaybatdau: dataIdPhuongtien.ngaybatdau,
            giobatdau: dataIdPhuongtien.giobatdau,
            ngayketthuc: dataIdPhuongtien.ngayketthuc,
            gioketthuc: dataIdPhuongtien.gioketthuc,
            xuatphattai: dataIdPhuongtien.xuatphattai,
            diemden: dataIdPhuongtien.diemden,
            tenkhachhang: dataIdPhuongtien.tenkhachhang,
            diachi: dataIdPhuongtien.diachi,
            daidien: dataIdPhuongtien.daidien,
            dienthoai: dataIdPhuongtien.dienthoai,
            chiphi: dataIdPhuongtien.chiphi,
            ghichu: dataIdPhuongtien.ghichu,
        });
        setlistNhanvien({
            value: dataIdPhuongtien?.Nhanvien?.id,
            label: dataIdPhuongtien?.Nhanvien?.hoten,
        })
    }, [dataIdPhuongtien, show]);
    useEffect(() => {
        setPayload((prev) => ({
            ...prev,
            ['NhanvienId']: listNhanvien,
        }));
    }, [listNhanvien]);
    useEffect(() => {
        let TrangthaiId = dataSelectActive.map((item) => ({label: item.label, value: item.value}));
        setlistTrangthai(TrangthaiId);
    }, []);
    const handleSubmit = async () => {
        let invalids = validate(payload);
        
        if (invalids === 0) {
            let datapayload = {
                data: [
                    {
                        id: dataIdPhuongtien.id,
                        TrangthaiId: payload.TrangthaiId.value,
                        // LoaiphuongtienId: payload.LoaiphuongtienId.value,
                        PhongbanId: payload.PhongbanId.value,
                        PhuongtienId: payload.PhuongtienId.value,
                        NhanvienId: payload.NhanvienId[0].value,
                        malenh: payload.malenh,
                        ngaybatdau: payload.ngaybatdau,
                        giobatdau: payload.giobatdau,
                        ngayketthuc: payload.ngayketthuc,
                        gioketthuc: payload.gioketthuc,
                        xuatphattai: payload.xuatphattai,
                        diemden: payload.diemden,
                        tenkhachhang: payload.tenkhachhang,
                        diachi: payload.diachi,
                        daidien: payload.daidien,
                        dienthoai: payload.dienthoai,
                        chiphi: payload.chiphi,
                        ghichu: payload.ghichu,
                    },
                ],
            };
            //   console.log('check payload', datapayload)
            dispatch(actions.EditKehoachcuaxe(datapayload));
        }
    };
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayload((prev) => ({...prev, [statename]: selected}));
    };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validatePhuongTien(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validatePhuongTien(item[0], item[1])]);
                invalids++;
            }
        });
        return invalids;
    };
    return (
        <React.Fragment>
            <Modal isOpen={show} className="modal-dialog-centered modal-90w" size="lg" toggle={setShow}>
                <ModalBody>
                    <Icon name="cross-sm float-end fs-2" onClick={setShow}></Icon>
                    <h5 className="title mb-3">Sửa kế hoạch cho xe chạy</h5>
                    <div className="row">
                        <div className="form-group col-md-3">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                                Phòng ban<b className="text-danger">(*)</b>
                            </span>
                            </label>
                            <Select
                                value={payload.PhongbanId}
                                onChange={handleOnSelect}
                                options={listPhongban}
                                placeholder={'Phòng ban'}
                                name="PhongbanId"
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'PhongbanId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'PhongbanId')?.message}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                                Phương tiện<b className="text-danger">(*)</b>
                            </span>
                            </label>
                            <Select
                                value={payload.PhuongtienId}
                                onChange={handleOnSelect}
                                options={listPhuongtien}
                                placeholder={'Phương tiện'}
                                name="PhuongtienId"
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'PhuongtienId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'PhuongtienId')?.message}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                                Trạng thái <b className="text-danger">(*)</b>
                            </span>
                            </label>
                            <Select
                                value={payload.TrangthaiId}
                                onChange={handleOnSelect}
                                options={listTrangthai}
                                placeholder={'Trạng thái'}
                                name="TrangthaiId"
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'TrangthaiId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'TrangthaiId')?.message}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-3" onClick={() => setShowSelect(true)}>
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                Chọn Nhân Viên <b className="text-danger">(*)</b>
                            </label>
                            <Select
                                value={payload.NhanvienId}
                                name="NhanvienId"
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'NhanvienId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'NhanvienId')?.message}
                                </small>
                            )}
                        </div>
        
                        <div className="form-group col-md-4">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Mã lệnh'}
                                value={payload.malenh}
                                setValue={setPayload}
                                keyPayload={'malenh'}
                                disabled={true}
                                validate
                            />
                        </div>
                        <div className="form-group col-md-4">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Điểm xuất phát'}
                                value={payload.xuatphattai}
                                setValue={setPayload}
                                keyPayload={'xuatphattai'}
                                type="text"
                                validate
                            />
                        </div>
                        <div className="form-group col-md-4">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Điểm đến'}
                                value={payload.diemden}
                                setValue={setPayload}
                                keyPayload={'diemden'}
                                type="text"
                                validate
                            />
                        </div>
                        <div className="form-group col-md-3">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Ngày đi'}
                                value={payload.ngaybatdau}
                                setValue={setPayload}
                                keyPayload={'ngaybatdau'}
                                type="date"
                                validate
                            />
                        </div>
                        <div className="form-group col-md-3">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Giờ đi'}
                                value={payload.giobatdau}
                                setValue={setPayload}
                                keyPayload={'giobatdau'}
                                type='time'
                            />
                        </div>
                        <div className="form-group col-md-3">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Ngày về'}
                                value={payload.ngayketthuc}
                                setValue={setPayload}
                                keyPayload={'ngayketthuc'}
                                type="date"
                                validate
                            />
                        </div>
                        <div className="form-group col-md-3">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Giờ về'}
                                value={payload.gioketthuc}
                                setValue={setPayload}
                                keyPayload={'gioketthuc'}
                                type='time'
                            />
                        </div>
        
                        <div className="form-group col-md-3">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Chi phí'}
                                value={payload.chiphi}
                                setValue={setPayload}
                                keyPayload={'chiphi'}
                                type="text"
                                validate
                            />
                        </div>
        
                        <div className="form-group col-md-3">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Khách hàng'}
                                value={payload.tenkhachhang}
                                setValue={setPayload}
                                keyPayload={'tenkhachhang'}
                                type="text"
                                validate
                            />
                        </div>
                        <div className="form-group col-md-3">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Người Đại diện'}
                                value={payload.daidien}
                                setValue={setPayload}
                                keyPayload={'daidien'}
                                type="text"
                                validate
                            />
                        </div>
                        <div className="form-group col-md-3">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Điện thoại'}
                                value={payload.dienthoai}
                                setValue={setPayload}
                                keyPayload={'dienthoai'}
                                type="text"
                                validate
                            />
                        </div>
                        <div className="form-group col-md-12">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Địa Chỉ Khách hàng'}
                                value={payload.diachi}
                                setValue={setPayload}
                                keyPayload={'diachi'}
                                type="text"
                                validate
                            />
                        </div>
                        <div className="form-group col-md-12">
                            <Textarea
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Ghi chú'}
                                value={payload.ghichu}
                                setValue={setPayload}
                                keyPayload={'ghichu'}
                                type="text"
                                validate
                            />
                        </div>
    
                    </div>
                
                    <div className="col-12 mt-5">
                        <Button text="Thêm mới" bClass="btn btn-primary px-5" textColor onClick={handleSubmit}/>
                    </div>
                </ModalBody>
            </Modal>
            <SelectFinNhanvienfix
                showSelect={showSelect}
                typeSelect={false}
                setlistNhanvien={setlistNhanvien}
                setShowSelect={() => setShowSelect(false)}
            />
        </React.Fragment>
    );
};

export default ModalEditKehoachcuaxe;
