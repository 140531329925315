import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '~/store/actions';
import { ModalUngvien, ModalEditUngvien } from '../Admin/Modal';
import Swal from 'sweetalert2';
import { Card, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, UncontrolledDropdown } from 'reactstrap';
import Head from '~/layout/head/Head';
import Content from '~/layout/content/Content';
import { Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle } from '~/components/block/Block';
import {
    Button,
    Col,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    Icon,
    PaginationComponent,
    Row,
    RSelect,
    TooltipComponent,
    PermissionUtils,
    ExportXlsx,
} from '~/components/Component';
import { MenuPhuongTien, MenuTuyenDung } from '~/pages/Admin/MenuTab/Menucontent';
import { InputForm } from '~/components/Form';

const Ungvien = () => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [showEditUngvien, setShowEditUngvien] = useState(false);
    //lấy giá trị từ thằng redux trả về
    const { ungvien } = useSelector((state) => state.ungvien);
    const { vitrituyendung } = useSelector((state) => state.vitrituyendung);
    const [data, setData] = useState([]);
    const [payload, setPayload] = useState();
    const [invalidFields, setInvalidFields] = useState([]);

    const param = {
        VitrituyendungId: '',
        PhongbanId: '',
        tenthuonggoi: '',
        diachihientai: '',
        dienthoai: '',
        email: '',
        nguoilienhe: '',
        ngaysinh: '',
        noisinh: '',
        nguyenquan: '',
        socmnd: '',
        noicap: '',
        dantoc: '',
        quoctich: '',
    };

    const handleSubmit = async () => {
        let dataFilter = data.filter((item) => {
            return item.ngaydangkiem >= payload?.ngaybatdau && item.handangkiem <= payload?.ngayketthuc;
        });
        setData([...dataFilter]);
    };

    const [showPhanquyen, setShowPhanquyen] = useState({});
    let dataPermissionUtils = PermissionUtils('ungvien', 'all');
    useEffect(() => {
        if (dataPermissionUtils) {
            setShowPhanquyen(dataPermissionUtils);
        }
    }, [dataPermissionUtils]);

    const namefile = 'Thông tin ứng viên';
    const header = [
        'Stt',
        'Vị trí ứng viên',
        'Họ tên',
        'Ngày sinh',
        'Địa chỉ',
        'Nơi sinh',
        'Nguyên quán',
        'Số điện thoại',
        'Email',
        'Người liên hệ',
        'Số CMND',
        'Nơi cấp',
        'Dân tộc',
        'Phòng ban',
        'Quốc tịch',
    ];
    const [columns, setColumns] = useState(() => {
        const d = header.map((item, index) => index <= 12);
        return d;
    });
    const [dataFileMau, setDataFileMau] = useState([]);
    useEffect(() => {
        if (data.length > 0) {
            const datafilemau = data.map((item, index) => {
                const nameVitrituyendung = handleVitrituyendung(vitrituyendung, Number(item.VitrituyendungId));
                return {
                    ...(columns[0] && { Stt: index + 1 }),
                    ...(columns[1] && { 'Vị trí ứng viên': nameVitrituyendung?.titletuyendung }),
                    ...(columns[2] && { 'Họ tên': item?.tenthuonggoi }),
                    ...(columns[3] && { 'Ngày sinh': item?.ngaysinh }),
                    ...(columns[4] && { 'Địa chỉ': item?.diachihientai }),
                    ...(columns[5] && { 'Nơi sinh': item?.noisinh }),
                    ...(columns[6] && { 'Nguyên quán': item?.nguyenquan }),
                    ...(columns[7] && { 'Số điện thoại': item?.dienthoai }),
                    ...(columns[8] && { Email: item?.email }),
                    ...(columns[9] && { 'Người liên hệ': item?.nguoilienhe }),
                    ...(columns[10] && { 'Số CMND': item?.socmnd }),
                    ...(columns[11] && { 'Nơi cấp': item?.noicap }),
                    ...(columns[12] && { 'Dân tộc': item?.dantoc }),
                    ...(columns[13] && { 'Phòng ban': item?.Phongban?.phongban }),
                    ...(columns[14] && { 'Quốc tịch': item?.quoctich }),
                };
            });
            setDataFileMau(datafilemau);
        }
    }, [data, columns]);
    const [sm, updateSm] = useState(false);
    const [tablesm, updateTableSm] = useState(false);
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [sort, setSortState] = useState('');
    useEffect(() => {
        dispatch(actions.getListUngvien());
    }, []);
    useEffect(() => {
        if (ungvien) {
            setData([...ungvien]);
        }
    }, [ungvien]);
    const onFilterChange = (e) => {
        setSearchText(e.target.value);
    };
    const toggle = () => setonSearch(!onSearch);
    const selectorCheck = (e) => {
        let newData;
        newData = data.map((item) => {
            item.checked = e.currentTarget.checked;
            return item;
        });
        setData([...newData]);
    };
    const onSelectChange = (e, id) => {
        let newData = data;
        let index = newData.findIndex((item) => item.id === id);
        newData[index].checked = e.currentTarget.checked;
        setData([...newData]);
    };
    useEffect(() => {
        if (onSearchText !== '') {
            const filteredObject = ungvien.filter((item) => {
                return (
                    item.titletuyendung.toLowerCase().includes(onSearchText.toLowerCase())
                );
            });
            setData([...filteredObject]);
        } else {
            setData([...ungvien]);
        }
    }, [onSearchText, setData]);
    // Get current list, pagination
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    useEffect(() => {
        (ungvien && setShow(false)) || setShowEditUngvien(false);
    }, [ungvien]);
    // set state để mở đóng Modal
    const handleAddNewUngvien = async () => {
        setShow(true);
    };
    const [dataIdUngvien, setDataIdUngvien] = useState([]);
    const handleEditUngvien = async (data) => {
        setShowEditUngvien(true);
        await setDataIdUngvien(data);
    };
    const handleVitrituyendung = (data, val) => {
        return data.find((obj) => {
            return obj.id === val;
        });
    };
    const handleDeleteUngvien = (data) => {
        Swal.fire({
            title: 'Bạn có chắc không?',
            text: 'Bạn sẽ không thể lấy lại khi xóa!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Không, bỏ qua!',
            confirmButtonText: 'Vâng, xóa nó đi!',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(actions.DeleteUngvien(data));
            }
        });
    };
    return (
        <React.Fragment>
            {/*<Head title="Ứng viên"></Head>*/}
            <Content>
                <Card className="mt-0">
                    <div className="card-aside-wrap">
                        <div className="card-inner card-inner-lg px-4">
                            <h4>Tuyển dụng</h4>
                            <MenuTuyenDung />
                        </div>
                    </div>
                </Card>

                <Block>
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner position-relative card-tools-toggle">
                            <div className="card-title-group">
                                <div className="card-title col-9 row">
                                    <div className="col-4">
                                        <h5 className="title">Ứng viên</h5>
                                        <Button
                                            color="primary"
                                            className="btn-icon px-4 "
                                            onClick={handleAddNewUngvien}
                                        >
                                            <Icon name="plus"></Icon> Thêm mới
                                        </Button>
                                    </div>
                                    {/*<div className="col-8 row">*/}
                                    {/*    <div className="form-group col-md-4">*/}
                                    {/*        <InputForm*/}
                                    {/*            setInvalidFields={setInvalidFields}*/}
                                    {/*            invalidFields={invalidFields}*/}
                                    {/*            label={'Ngày đăng kiểm'}*/}
                                    {/*            value={payload?.ngaybatdau}*/}
                                    {/*            setValue={setPayload}*/}
                                    {/*            keyPayload={'ngaybatdau'}*/}
                                    {/*            className={"my-0"}*/}
                                    {/*            type='date'*/}
                                    {/*        />*/}
                                    {/*    </div>*/}
                                    {/*    <div className="form-group col-md-4">*/}
                                    {/*        <InputForm*/}
                                    {/*            setInvalidFields={setInvalidFields}*/}
                                    {/*            invalidFields={invalidFields}*/}
                                    {/*            label={'Kết thúc đăng kiểm'}*/}
                                    {/*            value={payload?.ngayketthuc}*/}
                                    {/*            setValue={setPayload}*/}
                                    {/*            keyPayload={'ngayketthuc'}*/}
                                    {/*            className={"my-0"}*/}
                                    {/*            type='date'*/}
                                    {/*        />*/}
                                    {/*    </div>*/}
                                    {/*    <div className="form-group col-md-4 my-auto text-center">*/}
                                    {/*        <Button color="primary" className="btn-icon px-4"*/}
                                    {/*                onClick={handleSubmit}>*/}
                                    {/*            Tra cứu*/}
                                    {/*        </Button>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>

                                <div className="card-tools me-n1">
                                    <ul className="btn-toolbar gx-1">
                                        <li>
                                            <a
                                                href="#search"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    toggle();
                                                }}
                                                className="btn btn-icon search-toggle toggle-search"
                                            >
                                                <Icon name="search"></Icon>
                                            </a>
                                        </li>
                                        <li className="btn-toolbar-sep"></li>
                                        <li>
                                            <div className="toggle-wrap">
                                                <Button
                                                    className={`btn-icon btn-trigger toggle ${tablesm ? 'active' : ''}`}
                                                    onClick={() => updateTableSm(true)}
                                                >
                                                    <Icon name="menu-right"></Icon>
                                                </Button>
                                                <div className={`toggle-content ${tablesm ? 'content-active' : ''}`}>
                                                    <ul className="btn-toolbar gx-1">
                                                        <li className="toggle-close">
                                                            <Button
                                                                className="btn-icon btn-trigger toggle"
                                                                onClick={() => updateTableSm(false)}
                                                            >
                                                                <Icon name="arrow-left"></Icon>
                                                            </Button>
                                                        </li>
                                                        <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle
                                                                    tag="a"
                                                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                                                >
                                                                    <div className="dot dot-primary"></div>
                                                                    <Icon name="filter-alt"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu
                                                                    end
                                                                    className="filter-wg dropdown-menu-xl"
                                                                    style={{ overflow: 'visible' }}
                                                                >
                                                                    <div className="dropdown-head">
                                                                        <span className="sub-title dropdown-title">
                                                                            Bộ lọc
                                                                        </span>
                                                                        <div className="dropdown">
                                                                            <a
                                                                                href="#more"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                }}
                                                                                className="btn btn-sm btn-icon"
                                                                            >
                                                                                <Icon name="more-h"></Icon>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    <div className="dropdown-body dropdown-body-rg">
                                                                        <Row className="gx-6 gy-3">
                                                                            <Col size="6">
                                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="custom-control-input"
                                                                                        id="hasBalance"
                                                                                    />
                                                                                    <label
                                                                                        className="custom-control-label"
                                                                                        htmlFor="hasBalance"
                                                                                    >
                                                                                        {' '}
                                                                                        Have Balance
                                                                                    </label>
                                                                                </div>
                                                                            </Col>
                                                                            <Col size="6">
                                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="custom-control-input"
                                                                                        id="hasKYC"
                                                                                    />
                                                                                    <label
                                                                                        className="custom-control-label"
                                                                                        htmlFor="hasKYC"
                                                                                    >
                                                                                        {' '}
                                                                                        KYC Verified
                                                                                    </label>
                                                                                </div>
                                                                            </Col>
                                                                            <Col size="6">
                                                                                <div className="form-group">
                                                                                    <label className="overline-title overline-title-alt">
                                                                                        Role
                                                                                    </label>
                                                                                    <RSelect placeholder="Any Role" />
                                                                                </div>
                                                                            </Col>
                                                                            <Col size="6">
                                                                                <div className="form-group">
                                                                                    <label className="overline-title overline-title-alt">
                                                                                        Status
                                                                                    </label>
                                                                                    <RSelect placeholder="Any Status" />
                                                                                </div>
                                                                            </Col>
                                                                            <Col size="12">
                                                                                <div className="form-group">
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-secondary"
                                                                                    >
                                                                                        Filter
                                                                                    </button>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                    <div className="dropdown-foot between">
                                                                        <a
                                                                            href="#reset"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();
                                                                            }}
                                                                            className="clickable"
                                                                        >
                                                                            Cập nhật bộ lọc 
                                                                        </a>
                                                                        <a
                                                                            href="#save"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();
                                                                            }}
                                                                        >
                                                                            Lưu bộ lọc 
                                                                        </a>
                                                                    </div>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                        <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle
                                                                    color="tranparent"
                                                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                                                >
                                                                    <Icon name="setting"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu end className="dropdown-menu-xs">
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Show</span>
                                                                        </li>
                                                                        <li
                                                                            className={
                                                                                itemPerPage === 10 ? 'active' : ''
                                                                            }
                                                                        >
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setItemPerPage(10);
                                                                                }}
                                                                            >
                                                                                10
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li
                                                                            className={
                                                                                itemPerPage === 15 ? 'active' : ''
                                                                            }
                                                                        >
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setItemPerPage(15);
                                                                                }}
                                                                            >
                                                                                15
                                                                            </DropdownItem>
                                                                        </li>
                                                                    </ul>
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Order</span>
                                                                        </li>
                                                                        <li className={sort === 'dsc' ? 'active' : ''}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setSortState('dsc');
                                                                                    //   sortFunc("dsc");
                                                                                }}
                                                                            >
                                                                                DESC
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li className={sort === 'asc' ? 'active' : ''}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setSortState('asc');
                                                                                    //   sortFunc("asc");
                                                                                }}
                                                                            >
                                                                                ASC
                                                                            </DropdownItem>
                                                                        </li>
                                                                    </ul>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className=" position-relative card-tools-toggle">
                                                <div className="toggle-wrap nk-block-tools-toggle">
                                                    <Button
                                                        className={`btn-icon btn-trigger toggle-expand me-n1 ${
                                                            sm ? 'active' : ''
                                                        }`}
                                                        onClick={() => updateSm(!sm)}
                                                    >
                                                        <Icon name="menu-alt-r"></Icon>
                                                    </Button>
                                                    <div
                                                        className="toggle-expand-content"
                                                        style={{ display: sm ? 'block' : 'none' }}
                                                    >
                                                        <ul className="nk-block-tools g-3">
                                                            <li>
                                                                <ExportXlsx
                                                                    dataFileMau={dataFileMau}
                                                                    title={header}
                                                                    namefile={namefile}
                                                                    btnname={'Xuất dữ liệu'}
                                                                />
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className={`card-search search-wrap ${!onSearch && 'active'}`}>
                                <div className="card-body">
                                    <div className="search-content">
                                        <Button
                                            className="search-back btn-icon toggle-search active"
                                            onClick={() => {
                                                setSearchText('');
                                                toggle();
                                            }}
                                        >
                                            <Icon name="arrow-left"></Icon>
                                        </Button>
                                        <input
                                            type="text"
                                            className="border-transparent form-focus-none form-control"
                                            placeholder="Tìm kiếm"
                                            value={onSearchText}
                                            onChange={(e) => onFilterChange(e)}
                                        />
                                        <Button className="search-submit btn-icon">
                                            <Icon name="search"></Icon>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DataTableBody>
                            <DataTableHead>
                                {columns[0] && (
                                    <DataTableRow size="mb">
                                        <span className="sub-text">Stt</span>
                                    </DataTableRow>
                                )}
                                <DataTableRow className="nk-tb-col-check">
                                    <div className="custom-control custom-control-sm custom-checkbox notext">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            onChange={(e) => selectorCheck(e)}
                                            id="uid"
                                        />
                                        <label className="custom-control-label" htmlFor="uid"></label>
                                    </div>
                                </DataTableRow>
                                {columns[1] && (
                                    <DataTableRow>
                                        <span className="sub-text">Vị trí tuyển dụng</span>
                                    </DataTableRow>
                                )}
                                <DataTableRow size="mb">
                                    <span className="sub-text">Thông tin ứng viên</span>
                                </DataTableRow>
                                <DataTableRow size="mb">
                                    <span className="sub-text">Liên lạc</span>
                                </DataTableRow>
                                <DataTableRow size="mb">
                                    <span className="sub-text">CMND/Căn cước</span>
                                </DataTableRow>
                                {columns[13] && (
                                    <DataTableRow size="mb">
                                        <span className="sub-text">Phòng ban</span>
                                    </DataTableRow>
                                )}
                                {columns[14] && (
                                    <DataTableRow size="mb">
                                        <span className="sub-text">Quốc tịch</span>
                                    </DataTableRow>
                                )}
                                <DataTableRow className="nk-tb-col-tools text-end">
                                    <UncontrolledDropdown>
                                        <DropdownToggle
                                            color="tranparent"
                                            className="btn btn-xs btn-outline-light btn-icon dropdown-toggle"
                                        >
                                            <Icon name="plus"></Icon>
                                        </DropdownToggle>
                                        <DropdownMenu end className="dropdown-menu-xs">
                                            <ul className="link-tidy sm no-bdr">
                                                {header.length > 0 ? (
                                                    header.map((item, index) => (
                                                        <li key={index}>
                                                            <div className="custom-control custom-control-sm custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id={`column-${index}`}
                                                                    checked={columns[index]}
                                                                    onChange={(e) =>
                                                                        setColumns((prev) => {
                                                                            const newColumns = [...prev];
                                                                            newColumns[index] = e.target.checked;
                                                                            return newColumns;
                                                                        })
                                                                    }
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor={`column-${index}`}
                                                                >
                                                                    {item}
                                                                </label>
                                                            </div>
                                                        </li>
                                                    ))
                                                ) : (
                                                    <li>No data</li>
                                                )}
                                            </ul>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </DataTableRow>
                            </DataTableHead>
                            {currentItems.length > 0
                                ? currentItems.map((item, index) => {
                                      const nameVitrituyendung = handleVitrituyendung(
                                          vitrituyendung,
                                          Number(item.VitrituyendungId),
                                      );
                                      return (
                                          <DataTableItem key={item.id}>
                                              {columns[0] && (
                                                  <DataTableRow size=" nk-tb-col-check mb">
                                                      <span className="tb-amount">{index + 1}</span>
                                                  </DataTableRow>
                                              )}
                                              <DataTableRow className="nk-tb-col-check">
                                                  <div className="custom-control custom-control-sm custom-checkbox notext">
                                                      <input
                                                          type="checkbox"
                                                          className="custom-control-input"
                                                          defaultChecked={item.checked}
                                                          id={item.id + 'uid1'}
                                                          key={Math.random()}
                                                          onChange={(e) => onSelectChange(e, item.id)}
                                                      />
                                                      <label
                                                          className="custom-control-label"
                                                          htmlFor={item.id + 'uid1'}
                                                      ></label>
                                                  </div>
                                              </DataTableRow>
                                              {columns[1] && (
                                                  <DataTableRow size="mb">
                                                      <p className="mb-0">
                                                          <b className="text-danger">Vị trí: </b>
                                                          {nameVitrituyendung?.titletuyendung}
                                                      </p>
                                                  </DataTableRow>
                                              )}
                                              <DataTableRow size="mb">
                                                  {columns[2] && (
                                                      <p className="mb-0">
                                                          <b className="text-danger">Họ tên: </b>
                                                          {item?.tenthuonggoi}
                                                      </p>
                                                  )}
                                                  {columns[3] && (
                                                      <p className="mb-0">
                                                          <b className="text-danger">Ngày sinh: </b>
                                                          {item?.ngaysinh}
                                                      </p>
                                                  )}
                                                  {columns[4] && (
                                                      <p className="mb-0">
                                                          <b className="text-danger">Địa chỉ: </b>
                                                          {item?.diachihientai}
                                                      </p>
                                                  )}
                                                  {columns[5] && (
                                                      <p className="mb-0">
                                                          <b className="text-danger">Nơi sinh: </b>
                                                          {item?.noisinh}
                                                      </p>
                                                  )}
                                                  {columns[6] && (
                                                      <p className="mb-0">
                                                          <b className="text-danger">Nguyên quán: </b>
                                                          {item?.nguyenquan}
                                                      </p>
                                                  )}
                                              </DataTableRow>

                                              <DataTableRow size="mb">
                                                  {columns[7] && (
                                                      <p className="mb-0">
                                                          <b className="text-danger">Số điện thoại: </b>
                                                          {item?.dienthoai}
                                                      </p>
                                                  )}
                                                  {columns[8] && (
                                                      <p className="mb-0">
                                                          <b className="text-danger">Email: </b>
                                                          {item?.email}
                                                      </p>
                                                  )}
                                                  {columns[9] && (
                                                      <p className="mb-0">
                                                          <b className="text-danger">Người liên hệ: </b>
                                                          {item?.nguoilienhe}
                                                      </p>
                                                  )}
                                              </DataTableRow>
                                              <DataTableRow size="mb">
                                                  {columns[10] && (
                                                      <p className="mb-0">
                                                          <b className="text-danger">Số CMND: </b>
                                                          {item?.socmnd}
                                                      </p>
                                                  )}
                                                  {columns[11] && (
                                                      <p className="mb-0">
                                                          <b className="text-danger">Nơi cấp: </b>
                                                          {item?.noicap}
                                                      </p>
                                                  )}
                                                  {columns[12] && (
                                                      <p className="mb-0">
                                                          <b className="text-danger">Dân tộc: </b>
                                                          {item?.dantoc}
                                                      </p>
                                                  )}
                                              </DataTableRow>
                                              {columns[13] && (
                                                  <DataTableRow size="mb">
                                                      <span className="tb-amount">{item?.Phongban?.phongban}</span>
                                                  </DataTableRow>
                                              )}
                                              {columns[14] && (
                                                  <DataTableRow size="mb">
                                                      <span className="tb-amount">{item?.quoctich}</span>
                                                  </DataTableRow>
                                              )}
                                              <DataTableRow className="nk-tb-col-tools">
                                                  <ul className="nk-tb-actions gx-1">
                                                      {+showPhanquyen?.sua === 1 && (
                                                          <li
                                                              className="nk-tb-action-hidden"
                                                              onClick={() => handleEditUngvien(item)}
                                                          >
                                                              <TooltipComponent
                                                                  tag="a"
                                                                  containerClassName="btn btn-trigger btn-icon"
                                                                  id={'edit' + item.id}
                                                                  icon="edit-alt-fill"
                                                                  direction="top"
                                                                  text="Sửa"
                                                              />
                                                          </li>
                                                      )}
                                                      {+showPhanquyen?.xoa === 1 && (
                                                          <React.Fragment>
                                                              <li
                                                                  className="nk-tb-action-hidden"
                                                                  onClick={() => handleDeleteUngvien(item)}
                                                              >
                                                                  <TooltipComponent
                                                                      tag="a"
                                                                      containerClassName="btn btn-trigger btn-icon"
                                                                      id={'suspend' + item.id}
                                                                      icon="user-cross-fill"
                                                                      direction="top"
                                                                      text="Xóa"
                                                                  />
                                                              </li>
                                                          </React.Fragment>
                                                      )}
                                                      <li>
                                                          <UncontrolledDropdown>
                                                              {(+showPhanquyen?.sua === 1 ||
                                                                  +showPhanquyen?.xoa === 1) && (
                                                                  <DropdownToggle
                                                                      tag="a"
                                                                      className="dropdown-toggle btn btn-icon btn-trigger"
                                                                  >
                                                                      <Icon name="more-h"></Icon>
                                                                  </DropdownToggle>
                                                              )}
                                                              <DropdownMenu end>
                                                                  <ul className="link-list-opt no-bdr">
                                                                      {+showPhanquyen?.sua === 1 && (
                                                                          <li onClick={() => handleEditUngvien(item)}>
                                                                              <DropdownItem
                                                                                  tag="a"
                                                                                  href="#edit"
                                                                                  onClick={(ev) => {
                                                                                      ev.preventDefault();
                                                                                  }}
                                                                              >
                                                                                  <Icon name="edit"></Icon>
                                                                                  <span>Sửa</span>
                                                                              </DropdownItem>
                                                                          </li>
                                                                      )}
                                                                      {+showPhanquyen?.xoa === 1 && (
                                                                          <React.Fragment>
                                                                              <li className="divider"></li>
                                                                              <li
                                                                                  onClick={() =>
                                                                                      handleDeleteUngvien(item)
                                                                                  }
                                                                              >
                                                                                  <DropdownItem
                                                                                      tag="a"
                                                                                      href="#suspend"
                                                                                      onClick={(ev) => {
                                                                                          ev.preventDefault();
                                                                                      }}
                                                                                  >
                                                                                      <Icon name="na"></Icon>
                                                                                      <span>Xóa</span>
                                                                                  </DropdownItem>
                                                                              </li>
                                                                          </React.Fragment>
                                                                      )}
                                                                  </ul>
                                                              </DropdownMenu>
                                                          </UncontrolledDropdown>
                                                      </li>
                                                  </ul>
                                              </DataTableRow>
                                          </DataTableItem>
                                      );
                                  })
                                : null}
                        </DataTableBody>
                        <div className="card-inner">
                            {currentItems.length > 0 ? (
                                <PaginationComponent
                                    itemPerPage={itemPerPage}
                                    totalItems={ungvien.length}
                                    paginate={paginate}
                                    currentPage={currentPage}
                                />
                            ) : (
                                <div className="text-center">
                                    <span className="text-silent">No data found</span>
                                </div>
                            )}
                        </div>
                    </DataTable>
                </Block>

                <ModalUngvien show={show} setShow={() => setShow(false)} param={param} />
                <ModalEditUngvien
                    show={showEditUngvien}
                    setShow={() => setShowEditUngvien(false)}
                    dataIdUngvien={dataIdUngvien}
                    param={param}
                />
            </Content>
        </React.Fragment>
    );
};

export default Ungvien;
