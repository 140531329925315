import {Card, Table} from "reactstrap";
import React from "react";
import ColumnChart from "~/components/partials/analytics/chart-column/ColumnChart";

export const ComponentDaoTaoNhanSuTop = (data) => { //Table 1 : Bảng tông quan
    const dataShow = data?.dataDaoTaoNhanSu?.dataDaotaoNhanSuP
    const dataDaoTaoNhanSuTotal = data?.dataDaoTaoNhanSu?.dataArr
    const datatotal_month = data?.dataDaoTaoNhanSu?.total_month
    const dataChart = data?.dataDaoTaoNhanSu?.luongDataTmpMulChart
    const formatVND = (data) => {
        return Number(data ?? 0).toLocaleString('it-IT', {
            style: 'currency',
            currency: 'vnd',
        });
    };
    // console.log('datatotal_month',datatotal_month)
    return <>
        <div className="col-md-12 mt-3 row">
            <div className="col-12">
                <Table className="table table-bordered table-striped table-hover">
                    <thead key="theadComponentDaoTaoNhanSuTop">
                    <tr>
                        <th className="w-25">Phòng ban</th>
                        {datatotal_month?.length > 0
                            ? datatotal_month?.map((item) => {
                                return (
                                    <th className="text-center ">{item}</th>
                                )
                            })
                            : null}
                        <th className="text-center">Tổng cộng</th>
                    </tr>
                    </thead>
                    <tbody key="tbodyComponentDaoTaoNhanSuTop">
                    {dataShow?.length > 0
                        ? dataShow?.map((item) => {
                            return (
                                <tr>
                                    <td
                                        className="fw-bold position-relative">
                                        <div className="position-absolute"
                                             style={{top: "calc(50% - 10px)"}}>{item.namePhongban}
                                        </div>
                                    </td>
                                    {item?.data?.length > 0
                                        ? item?.data?.map((items) => {
                                            return (
                                                <td className="text-end">
                                                    {formatVND(items?.data)}
                                                </td>
                                            )
                                        })
                                        : null}
                                    <td className="text-end">{formatVND(item?.data?.reduce((a, v) => a + Number(v.data), 0).toFixed(0))}</td>
                                </tr>
                            )
                        })
                        : null}
                    
                    <tr>
                        <td
                            className="fw-bold position-relative">
                            <div className="position-absolute"
                                 style={{top: "calc(50% - 10px)"}}>Tổng cộng
                            </div>
                        </td>
                        {dataDaoTaoNhanSuTotal?.length > 0
                            ? dataDaoTaoNhanSuTotal?.map((items) => {
                                return (
                                    <th className="text-end">{formatVND(items.dd)}</th>
                                )
                            })
                            : null}
                        <th className="text-end">{formatVND(dataDaoTaoNhanSuTotal?.reduce((a, v) => a + Number(v?.dd), 0).toFixed(0))}</th>
                    </tr>
                    
                    </tbody>
                </Table>
            </div>
        </div>
        <div className="col-12">
            <div className="py-3">
                <h6 className="overline-title text-primary">
                    Biểu đồ đào tạo nhân sự
                </h6>
            </div>
            <Card className="pt-4">
                <ColumnChart
                    columnChart={dataChart}
                />
            </Card>
        </div>
    </>
};
