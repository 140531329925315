import axiosConfig from '../axiosConfig'

export const apiGetTaiKhoanNganHang = () => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlynhanvien/tknganhangnv/nhanvien`,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiNewTaiKhoanNganHang = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlynhanvien/tknganhangnv/nhanvien',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiEditTaiKhoanNganHang = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlynhanvien/tknganhangnv/nhanvien',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiDeleteTaiKhoanNganHang = (payload) => new Promise(async (resolve, reject) => {
    console.log('payload', payload)
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlynhanvien/tknganhangnv/nhanvien',
            data: {
                id:payload.value
            }
        })
        
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
