import actionTypes from "../actions/actionTypes";
const initState = {
    cauhinhnghile: [],
    msg: '',
    count: 0,
    newcauhinhnghile: []
}

const cauhinhnghileReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.GET_LIST_CAU_HINH_NGHI_LE:
            return {
                ...state,
                cauhinhnghile: action?.cauhinhnghile?.rows || [],
                msg: 'Ok' || '',
                count: action?.cauhinhnghile?.count || 0
            }
        case actionTypes.POST_NEW_CAU_HINH_NGHI_LE:
        case actionTypes.PUT_EDIT_CAU_HINH_NGHI_LE:
        case actionTypes.DELETE_CAU_HINH_NGHI_LE:
            return {
                ...state,
                msg: action.msg || '',
                newcauhinhnghile: action?.newcauhinhnghile || []
            }
        default:
            return state;
    }

}

export default cauhinhnghileReducer