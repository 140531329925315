import React from "react";
import ErrorImage from "~/images/gfx/error-404.svg";
import PageContainer from "~/layout/page-container/PageContainer";
import { Link } from "react-router-dom";
import { Block, BlockContent, Button } from "~/components/Component";

const Error404Modern = () => {
  return (
    <PageContainer>
      <Block className="nk-block-middle wide-md mx-auto">
        <BlockContent className="nk-error-ld text-center">
          <img className="nk-error-gfx" src={ErrorImage} alt="error" />
          <div className="wide-xs mx-auto">
            <h3 className="nk-error-title">Ối! Tại sao bạn ở đây?</h3>
            <p className="nk-error-text">
            Chúng tôi rất xin lỗi vì sự bất tiện. Có vẻ như bạn đang cố truy cập một trang đã bị xóa hoặc chưa từng tồn tại.
            </p>
            <Link to={`${process.env.PUBLIC_URL}/login`}>
              <Button color="primary" size="lg" className="mt-2">
                Quay lại
              </Button>
            </Link>
          </div>
        </BlockContent>
      </Block>
    </PageContainer>
  );
};
export default Error404Modern;
