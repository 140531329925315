import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { InputForm, Button } from '~/components/Form';
import * as actions from '~/store/actions';
import { validateCauHinhThue } from '~/request/validateCauHinhThue';
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import { Input } from 'reactstrap';
import { NumericFormat } from 'react-number-format';

const ModalCauhinhthuethunhapcnlt = ({ show, setShow, fieldList }) => {
    const dispatch = useDispatch();
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayloadCauhinhthuethunhapcnlt] = useState(fieldList);
    useEffect(() => {
        show && setPayloadCauhinhthuethunhapcnlt(fieldList);
    }, [show]);
    const handleSubmit = async () => {
        let invalids = validate(payload);
        if (invalids === 0) {
            let datapayload = {
                data: [
                    {
                        ten: payload.ten,
                        mota: payload.mota,
                        tugiatri: payload.tugiatri,
                        dengiatri: payload.dengiatri,
                        giatri: payload.giatri,
                        // TrangthaiId: payload.TrangthaiId,
                    },
                ],
            };
            // console.log('check payload', datapayload)
            dispatch(actions.NewCauhinhthuethunhapcnlt(datapayload));
        }
    };
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayloadCauhinhthuethunhapcnlt((prev) => ({ ...prev, [statename]: selected }));
    };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateCauHinhThue(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateCauHinhThue(item[0], item[1])]);
                invalids++;
            }
        });
        return invalids;
    };
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">Thêm cấu hình thuế</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Tiêu đề'}
                            value={payload.ten}
                            setValue={setPayloadCauhinhthuethunhapcnlt}
                            keyPayload={'ten'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Thuế suất (%)'}
                            value={payload.giatri}
                            setValue={setPayloadCauhinhthuethunhapcnlt}
                            keyPayload={'giatri'}
                            type="number"
                            validate
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-md-6">
                        <div className="form-group">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                <span>
                                    Thu nhập chịu thuế TNCN từ <b className="text-danger">(*)</b>
                                </span>
                            </label>
                            <div className="form-control-wrap">
                                <div className="form-text-hint">
                                    <span className="overline-title">VND</span>
                                </div>
                                <NumericFormat
                                    className="form-control px-2"
                                    value={payload.tugiatriformat}
                                    // displayType={'text'}
                                    thousandSeparator={true}
                                    // suffix={'đ'}
                                    name="tugiatri"
                                    onValueChange={(values) => {
                                        const { formattedValue, value } = values;
                                        setPayloadCauhinhthuethunhapcnlt((prev) => ({ ...prev, ['tugiatri']: value }));
                                        setPayloadCauhinhthuethunhapcnlt((prev) => ({
                                            ...prev,
                                            ['tugiatriformat']: formattedValue,
                                        }));
                                    }}
                                />
                                {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'tugiatri') && (
                                    <small className="text-danger">
                                        {invalidFields.find((i) => i.name === 'tugiatri')?.message}
                                    </small>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-6">
                        <div className="form-group">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                <span>
                                    Thu nhập chịu thuế TNCN đến <b className="text-danger">(*)</b>
                                </span>
                            </label>
                            <div className="form-control-wrap">
                                <div className="form-text-hint">
                                    <span className="overline-title">VND</span>
                                </div>
                                <NumericFormat
                                    className="form-control px-2"
                                    value={payload.dengiatriformat}
                                    // displayType={'text'}
                                    thousandSeparator={true}
                                    // suffix={'đ'}
                                    name="dengiatri"
                                    onValueChange={(values) => {
                                        const { formattedValue, value } = values;
                                        setPayloadCauhinhthuethunhapcnlt((prev) => ({ ...prev, ['dengiatri']: value }));
                                        setPayloadCauhinhthuethunhapcnlt((prev) => ({
                                            ...prev,
                                            ['dengiatriformat']: formattedValue,
                                        }));
                                    }}
                                />
                                {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'dengiatri') && (
                                    <small className="text-danger">
                                        {invalidFields.find((i) => i.name === 'dengiatri')?.message}
                                    </small>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Thu nhập chịu thuế TNCN từ'}
                            value={payload.tugiatri}
                            setValue={setPayloadCauhinhthuethunhapcnlt}
                            keyPayload={'tugiatri'}
                            type="text"
                            validate
                        />
                    </div> */}
                    {/* <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Thu nhập chịu thuế TNCN đến'}
                            value={payload.dengiatri}
                            setValue={setPayloadCauhinhthuethunhapcnlt}
                            keyPayload={'dengiatri'}
                            type="text"
                            validate
                        />
                    </div> */}
                </div>
                <div className="row">
                    <div className="form-group col-md-12 mt-3 mb-2">
                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                                Mô tả <b className="text-danger">(*)</b>
                            </span>
                        </label>
                        <Input
                            value={payload.mota}
                            onChange={(e) =>
                                setPayloadCauhinhthuethunhapcnlt((prev) => ({ ...prev, ['mota']: e.target.value }))
                            }
                            rows={'2'}
                            name="mota"
                            type="textarea"
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'mota') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'mota')?.message}
                            </small>
                        )}
                    </div>
                </div>

                <div className="col-12 mt-5">
                    <Button text="Thêm mới" bClass="btn btn-primary px-5" textColor onClick={handleSubmit} />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalCauhinhthuethunhapcnlt;
