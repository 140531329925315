import actionTypes from './actionTypes'
import { apiGetVitrituyendung, apiNewVitrituyendung, apiEditVitrituyendung, apiDeleteVitrituyendung } from '~/services/vitrituyendung'
import { toast } from "react-toastify";
export const getListVitrituyendung = () => async (dispatch) => {
    try {
        const response = await apiGetVitrituyendung()

        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_VI_TRI_TUYEN_DUNG,
                vitrituyendung: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_VI_TRI_TUYEN_DUNG,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_VI_TRI_TUYEN_DUNG,
            vitrituyendung: null
        })
    }
}

export const NewVitrituyendung = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewVitrituyendung', payload)
            const response = await apiNewVitrituyendung(payload)
            // console.log('chek api apiNewVitrituyendung', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_VI_TRI_TUYEN_DUNG,
                    msg: 'OK'
                });
                dispatch(getListVitrituyendung());
                toast.success("Thêm mới vị trí tuyển dụng thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_VI_TRI_TUYEN_DUNG,
                    msg: 'ERR'
                })
                toast.error("Thêm mới vị trí tuyển dụng thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_VI_TRI_TUYEN_DUNG,
                vitrituyendung: null
            })
        }
    }
}
export const EditVitrituyendung = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditVitrituyendung(payload)
            console.log('chek api apiEditVitrituyendung edit zo')
            if (response?.data.success === 200) {
                dispatch(getListVitrituyendung());
                toast.success("Sửa thông tin vị trí tuyển dụng thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_VI_TRI_TUYEN_DUNG,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_VI_TRI_TUYEN_DUNG,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin vị trí tuyển dụng thất bại!")
            }

        } catch (error) {
            console.log('chek api apiEditVitrituyendung edit out')
            dispatch({
                type: actionTypes.PUT_EDIT_VI_TRI_TUYEN_DUNG,
                vitrituyendung: null
            })
        }
    }
}
export const DeleteVitrituyendung = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteVitrituyendung(payload)
            // console.log('chek api apiDeleteVitrituyendung', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListVitrituyendung());
                toast.success("Xóa thông tin vị trí tuyển dụng thành công!")
                dispatch({
                    type: actionTypes.DELETE_VI_TRI_TUYEN_DUNG,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_VI_TRI_TUYEN_DUNG,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin vị trí tuyển dụng thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_VI_TRI_TUYEN_DUNG,
                vitrituyendung: null
            })
        }
    }
}