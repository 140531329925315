import config from '~/config';

const menu = [
    {
        text: "Tổng quan",
        link: config.system,
        module: "dashboardadmin",
    },
    {
        text: "Lịch",
        link: config.lich,
        module: "dashboardadmin",
    },
    {
        text: "Quản lý nhân sự",
        module: "nhanvien",
        subMenu: [
            {
                text: "Quản lý hồ sơ nhân viên",
                link: config.nhan_vien,
                module: "nhanvien",
            },
            {
                text: "Quản lý hợp đồng lao động",
                link: config.hop_dong_lao_dong,
                module: "hopdonglaodong",
            },
            {
                text: "Quản lý phân ca làm việc",
                link: config.phan_ca_tong_hop,
                module: "phanca",
            },
            {
                text: "Quản lý công",
                link: config.bang_chot_cong,
                module: "quanlycong",
            },{
                text: "Danh sách bảng lương",
                link: config.danh_sach_bang_luong,
                module: "tinhluongnv",
            },
            {
                text: "Quản lý phiếu tăng ca, Quản lý tăng ca",
                link: config.phieu_tang_ca,
                module: "tangca",
            },
            {
                text: "Quản lý phép, lễ, nghỉ, công tác, đi muộn về sớm",
                link: config.nghi_phep_cong_tac,
                module: "nhanvien",
            },
            {
                text: "Quản lý tạm ứng lương",
                link: config.tam_ung_luong,
                module: "tamungluong",
            },
            {
                text: "Quản lý BHXH, BHYT, BHTN",
                link: config.bao_hiem_nhan_vien,
                module: "baohiemnhanvien",
            },
            // {
            //     text: "Quản lý quỹ thưởng, xếp loại",
            //     link: config.quan_ly_quy_thuong,
            //     module: "quanlyquythuong",
            // },
            {
                text: "Quản lý thâm niên",
                link: config.quan_ly_tham_nien,
                module: "quanlythamnien",
            },
            {
                text: "Quản lý đánh giá CBCNV",
                link: config.danh_gia_nhan_vien,
                module: "danhgianhanvien",
            },
            {
                text: "Quản lý các khoản phát sinh nhân sự",
                link: config.moi_quan_he_lao_dong,
                module: "moiquanhelaodong",
            },
            // {
            //     text: "(*)Quản lý chính sách và quy định",
            //     link: config.phieu_day_chuyen,
            //     module: "tangca",
            // },
            // {
            //     text: "Bình luận",
            //     link: config.binh_luan,
            //     module: "binhluan",
            // },
        ],
    },
    {
        text: "Quản lý hành chính",
        // module: "nhanvien",
        subMenu: [
            {
                text: "Quản lý Văn Thư",
                link: config.van_thu,
                module: "vanthu",
            },
            {
                text: "Quản lý phương tiện",
                link: config.phuong_tien,
                module: "phuongtien",
            },
            {
                text: "Quản lý Kho VPP, Tài Sản",
                link: config.tai_san,
                module: "taisan",
            },
            {
                text: "Quản lý suất ăn",
                link: config.suat_an,
                module: "nhanvien",
            },
            {
                text: "Quản Lý An Toàn - Vệ sinh - BHLĐ",
                link: config.kiem_dinh_atvsbhld,
                module: "kiemdinhatvsbhld",
            },
            {
                text: "Quản lý hoạt động , Sự kiện",
                link: config.hoat_dong_su_kien,
                module: "hoatdongsukien",
            },
            // {
            //     text: "(*)Quản lý Tuân thủ quy định ",
            //     link: config.cau_hinh_ca_lam_viec,
            //     module: "cauhinhcalamviec",
            // },
            {
                text: "Quản lý thông báo thông tin nội bộ",
                link: config.thong_bao_noi_bo,
                module: "thongbaonoibo",
            },
            
           
        ],
    },
    {
        text: "Giao việc & Đề xuất",
        // module: "giaoviec",
        subMenu: [
            {
                text: "Giao việc",
                link: config.giao_viec,
                module: "giaoviec",
            },
            {
                text: "Đề xuất",
                link: config.de_xuat,
                module: "dexuat",
            },
            // {
            //     text: "(**)Báo cáo sự cố",
            //     link: config.cau_hinh_ca_lam_viec,
            //     module: "cauhinhcalamviec",
            // },
           
        ],
    },
    {
        text: "Tuyển dụng",
        link: config.vi_tri_tuyen_dung,
        module: "vitrituyendung",
        // subMenu: [
        //     {
        //         text: "Ứng viên",
        //         link: config.ung_vien,
        //         module: "ungvien",
        //     },
        //     {
        //         text: "Đánh giá ứng viên",
        //         link: config.danh_gia,
        //         module: "ungvien",
        //     },
        //     {
        //         text: "Vị trí tuyển dụng",
        //         link: config.vi_tri_tuyen_dung,
        //         module: "vitrituyendung",
        //     },
        //     {
        //         text: "Bộ câu hỏi phỏng vấn",
        //         link: config.bo_cau_hoi_phong_van,
        //         module: "vitrituyendung",
        //     },
        // ],
    },
    {
        text: "Báo cáo",
        link: config.bao_cao,
        module: "thongkebaocao",
        // subMenu: [
        //     {
        //         text: "Báo cáo",
        //         link: config.bao_cao,
        //         module: "thongkebaocao",
        //     },
        // ],
    },
    {
        text: "Thiết lập",
        subMenu: [
            {
                text: "Tài khoản",
                link: config.user_profile_regular,
                module: "cauhinhdoanhnghiep",
            },
            {
                text: "Doanh nghiệp, Chi nhánh, Phòng ban, Chức Vụ",
                link: config.cong_ty,
                module: "cauhinhdoanhnghiep",
            },
            {
                text: "Lương, phụ cấp, thưởng, Chuyên cần & Thâm niên",
                link: config.phu_cap,
                module: "phucap",
            },
            // {
            //     text: "Cấu hình ca làm việc, Cấu hình tăng ca",
            //     link: config.cau_hinh_ca_lam_viec,
            //     module: "cauhinhcalamviec",
            // },
            {
                text: "Cấu hình nghỉ ngày phép, nghỉ phép, nghỉ lễ, công tác",
                link: config.cau_hinh_nghi_phep,
                module: "cauhinhnghiphep",
            },
            // {
            //     text: "Phúc lợi, hỗ trợ...",
            //     link: config.loai_quy_dinh_phuc_loi,
            //     module: "quydinhphucloi",
            // },
            // {
            //     text: "(*)Cấu hình phụ cấp thâm niên, chuyên cần",
            //     link: config.nghi_phep_cong_tac,
            //     module: "nhanvien",
            // },
            // {
            //     text: "Cấu hình quy định thưởng phạt",
            //     link: config.quy_dinh_thuong_phat,
            //     module: "quydinhthuongphat",
            // },
            {
                text: "Cấu hình BHXH, BHYT,BHTN",
                link: config.cau_hinh_bhxh_bhyt_bhtn,
                module: "cauhinhdoanhnghiep",
            },
            {
                text: "Cấu hình Thuế TNCN",
                link: config.cau_hinh_thue_thu_nhap_cnlt,
                module: "cauhinhthuethunhapcnlt",
            },
            {
                text: "Máy chấm công",
                link: config.may_cham_cong,
                module: "maychamcong",
            },
            {
                text: "Cấu hình phê duyệt",
                link: config.cau_hinh_phe_duyet,
                module: "pheduyet",
            },
        ],
    },
];
export default menu;
