import axiosConfig from '../axiosConfig'

export const apiGetKehoachcuaxe = () => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlyphuongtien/kehoachcuaxe`,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiGetKehoachcuaxeByNhanVien = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: `/api/v2/quanlyphuongtien/kehoachcuaxebnv?NhanvienId=${payload.NhanvienId}&PhuongtienId=${payload.PhuongtienId}`,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiNewKehoachcuaxe = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlyphuongtien/kehoachcuaxe',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiEditKehoachcuaxe = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlyphuongtien/kehoachcuaxe',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiDeleteKehoachcuaxe = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlyphuongtien/kehoachcuaxe',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
