import actionTypes from "../actions/actionTypes";
const initState = {
    bangchamcongtonghop: [],
    msg: '',
    count: 0,
    newbangchamcongtonghop: []
}

const bangchamcongtonghopReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.GET_LIST_BANG_CHAM_CONG_TONG_HOP:
            return {
                ...state,
                bangchamcongtonghop: action?.bangchamcongtonghop?.rows || [],
                msg: 'Ok' || '',
                count: action?.bangchamcongtonghop?.count || 0
            }
        case actionTypes.POST_NEW_BANG_CHAM_CONG_TONG_HOP:
        case actionTypes.PUT_EDIT_BANG_CHAM_CONG_TONG_HOP:
        case actionTypes.DELETE_BANG_CHAM_CONG_TONG_HOP:
            return {
                ...state,
                msg: action.msg || '',
                newbangchamcongtonghop: action?.newbangchamcongtonghop || []
            }
        default:
            return state;
    }

}

export default bangchamcongtonghopReducer