export const validateTaiSan = (fieldName, value) => {
    let regNumber = /^[0-9\b]+$/;
    switch (fieldName) {
        case 'mataisan':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Mã tài sản không được để trống'
                }
            }
            break;
        case 'tentaisan':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Tên tài sản không được để trống'
                }
            }
            break;
        case 'soluong':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Số lượng không được để trống'
                }
            }
            break;
        case 'NhomtaisanId':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nhóm tài sản không được để trống'
                }
            }
            break;
        case 'donvitaisan':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Đơn vị tài sản không được để trống.'
                }
            }
            break;
        case 'doi':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Model không được để trống.'
                }
            }
            break;
        case 'vitritaisan':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Vị trí không được để trống.'
                }
            }
            break;
        case 'ngaymua':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Ngày mua không được để trống.'
                }
            }
            break;
        case 'thoihanbaohanh':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Thời gian bảo hành không được để trống.'
                }
            }
            break;
        case 'giatri':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Giá trị không được để trống.'
                }
            }
            break;
        case 'nhacungcap':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nhà cung cấp không được để trống.'
                }
            }
            break;
        case 'sodienthoai':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: ' Số điện thoại không được để trống.'
                }
            }
            break;
        case 'diachi':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Địa chỉ không được để trống.'
                }
            }
            break;
        case 'ghichu':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Ghi chú không được để trống.'
                }
            }
            break;
        case 'TrangthaiId':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Trạng thái không được để trống.'
                }
            }
            break;
        
        default:
            break;
    }
}