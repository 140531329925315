import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InputForm, Button } from '~/components/Form'
import * as actions from '~/store/actions'
import { validateChiNhanh } from "~/request/validateChiNhanh";
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';

const ModalChinhanh = ({ show, setShow, fieldList }) => {
    const dispatch = useDispatch()
    const [invalidFields, setInvalidFields] = useState([])
    const [payload, setPayloadChinhanh] = useState(fieldList)
    useEffect(() => {
        show && setPayloadChinhanh(fieldList)
    }, [show])
    const handleSubmit = async () => {
        let invalids = validate(payload)
        if (invalids === 0) {
            let datapayload = {
                data: [
                    {
                        dienthoai: payload.dienthoai,
                        diachi: payload.diachi,
                        email: payload.email,
                        TrangthaiId: 1,
                    },
                ],
            }
            // console.log('check payload', datapayload)
            dispatch(actions.NewChinhanh(datapayload))
        }
    }
    const validate = (payload) => {
        let invalids = 0
        let fields = Object.entries(payload)
        fields.forEach(item => {
            if (validateChiNhanh(item[0], item[1]) !== undefined) {
                setInvalidFields(prev => [...prev, validateChiNhanh(item[0], item[1])])
                invalids++
            }
        })
        return invalids
    }
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Thêm mới chi nhánh
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                <div className="form-group col-md-6 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Địa chỉ'}
                            value={payload.diachi}
                            setValue={setPayloadChinhanh}
                            keyPayload={'diachi'}
                            type='text'
                            validate
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Email'}
                            value={payload.email}
                            setValue={setPayloadChinhanh}
                            keyPayload={'email'}
                            type='email'
                            validate
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Điện thoại'}
                            value={payload.dienthoai}
                            setValue={setPayloadChinhanh}
                            keyPayload={'dienthoai'}
                            type='text'
                            validate
                        />
                    </div>
                   
                </div>

                <div className='col-12 mt-5'>
                    <Button
                        text='Thêm chi nhánh'
                        bClass='btn btn-primary px-5'
                        textColor
                        onClick={handleSubmit}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ModalChinhanh;

