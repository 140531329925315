import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {InputForm, Button} from '~/components/Form';
import * as actions from '~/store/actions';
import {validateDeXuat} from '~/request/validateDeXuat';
import '../Modal.scss';
import Select from 'react-select';
import {Input, ModalBody, Modal} from 'reactstrap';
import {apiGetUserAll} from '~/services';
import {dataSelectNhomNV, dataSelectStatus} from '~/utils/Datacustom';
import {Icon, SelectFinNhanvienfix} from "~/components/Component";
import ModalSelectDexuat from "~/pages/Admin/Modal/Dexuat/ModalSelectDexuat";


const ModalDexuat = ({show, setShow, fieldList}) => {
    // const {nhomnv} = useSelector((state) => state.nhanvien);
    // console.log('nhomnv', nhomnv)
    // const { phongban } = useSelector((state) => state.phongban);
    const dispatch = useDispatch();
    const [invalidFields, setInvalidFields] = useState('');
    
    const [payload, setPayload] = useState(fieldList);
    const [showSelectType, setShowSelectType] = useState(false);
    const [showSelect, setShowSelect] = useState(false);
    const [listNhanvien, setlistNhanvien] = useState([]);
    const [listType, setlistType] = useState([]);
    
    useEffect(() => {
        show && setPayload(fieldList);
    }, [show]);
    useEffect(() => {
        setPayload((prev) => ({
            ...prev,
            ['NhanvienId']: listNhanvien,
            ['KieudexuatId']: listType,
        }));
    }, [listNhanvien,listType]);
    
    useEffect(() => {
        const fetchData = async () => {
            const userAll = await apiGetUserAll();
            let resultuser = [];
            userAll.data.data.rows?.map((item, index) => {
                let objectuser = {};
                objectuser.label = item.username;
                objectuser.value = item.id;
                resultuser.push(objectuser);
            });
            setlistUser(resultuser);
        };
        fetchData().catch(console.error);
    }, []);
    
    // const [listPhongban, setlistPhongban] = useState([]);
    const [listUser, setlistUser] = useState([]);
    // console.log('listUser', listUser)
    
    const handleSubmit = async () => {
        let invalids = validate(payload);
        if (invalids === 0) {
            let NhanvienId = payload.NhanvienId.map((items)=>(items.value))
            let KieudexuatId = payload.KieudexuatId[0].value
            let datapayload = {
                data: [
                    {
                        NhanvienId: NhanvienId,
                        KieudexuatId: KieudexuatId,
                        title: payload.title,
                        mota: payload.mota,
                        giatri: payload.giatri,
                        ngaybatdau: payload.ngaybatdau,
                        nguoicheck: payload.nguoicheck.value,
                        TrangthaiId: 0,
                    },
                ],
            };
            // console.log('check payload', datapayload)
            dispatch(actions.NewDexuat(datapayload));
        }
    };
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayload((prev) => ({...prev, [statename]: selected}));
    };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateDeXuat(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateDeXuat(item[0], item[1])]);
                invalids++;
            }
        });
        return invalids;
    };
    return (
        <React.Fragment>
            <Modal isOpen={show} className="modal-dialog-centered modal-90w" size="lg" toggle={setShow}>
                <ModalBody>
                    <Icon name="cross-sm float-end fs-2" onClick={setShow}></Icon>
                    <h5 className="title mb-3">Thêm mới</h5>
                    <div className="row">
                    
                    </div>
                    <div className="row">
                        <div className="form-group col-md-12">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Đề xuất'}
                                value={payload.title}
                                setValue={setPayload}
                                keyPayload={'title'}
                                type="text"
                                validate
                            />
                        </div>
                        
                        <div className="form-group col-md-12 mb-2">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                                Mô tả chi tiết đề xuất <b className="text-danger">(*)</b>
                            </span>
                            </label>
                            <Input
                                value={payload.mota}
                                onChange={(e) => setPayload((prev) => ({...prev, ['mota']: e.target.value}))}
                                rows={'2'}
                                name="mota"
                                type="textarea"
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'mota') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'mota')?.message}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-12" onClick={() => setShowSelect(true)}>
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                Nhân viên <b className="text-danger">(*)</b>
                            </label>
                            <Select
                                value={payload.NhanvienId}
                                name="NhanvienId"
                                isMulti
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'NhanvienId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'NhanvienId')?.message}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-6" onClick={() => setShowSelectType(true)}>
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                                Kiểu đề xuất <b className="text-danger">(*)</b>
                            </span>
                            </label>
                            <Select
                                value={payload.KieudexuatId}
                                name="KieudexuatId"
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'KieudexuatId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'KieudexuatId')?.message}
                                </small>
                            )}
                        </div>
                        
                        <div className="form-group col-md-6">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                Người xác nhận
                            </label>
                            <Select
                                value={payload.nguoicheck}
                                onChange={handleOnSelect}
                                options={listUser}
                                placeholder={'Chọn người check'}
                                name="nguoicheck"
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'nguoicheck') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'nguoicheck')?.message}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-6">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Giá trị (Không có giá trị thì để trống)'}
                                value={payload.giatri}
                                setValue={setPayload}
                                keyPayload={'giatri'}
                                type="integer"
                                validate
                            />
                        </div>
                        <div className="form-group col-md-6">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Ngày áp dụng'}
                                value={payload.ngaybatdau}
                                setValue={setPayload}
                                keyPayload={'ngaybatdau'}
                                type="date"
                                validate
                            />
                        </div>
                        
                        
                    </div>
                    
                    <div className="btn-group my-2">
                        <button className="btn btn-primary px-5" onClick={handleSubmit}>
                            Thêm mới
                        </button>
                    </div>
                </ModalBody>
            </Modal>
            <ModalSelectDexuat
                showSelect={showSelectType}
                // typeSelect={true}
                // listType={listType}
                setlistType={setlistType}
                setShowSelect={() => setShowSelectType(false)}
            />
            <SelectFinNhanvienfix
                showSelect={showSelect}
                typeSelect={true}
                // listNhanvien={listNhanvien}
                setlistNhanvien={setlistNhanvien}
                setShowSelect={() => setShowSelect(false)}
            />
        </React.Fragment>
    );
};

export default ModalDexuat;
