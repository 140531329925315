import axiosConfig from '../axiosConfig'

export const apiGetKiemdinhatvsbhld = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'get',
            url: '/api/v2/quanlyhanhchinh/kiemdinhatvsbhld?page='+payload.start+'&limit='+payload.size+'&order='+payload.sorting+'&by='+payload.globalFilter+'&typeGet='+payload.typeGet,
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiNewKiemdinhatvsbhld = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'post',
            url: '/api/v2/quanlyhanhchinh/kiemdinhatvsbhld',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiEditKiemdinhatvsbhld = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'put',
            url: '/api/v2/quanlyhanhchinh/kiemdinhatvsbhld',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
export const apiDeleteKiemdinhatvsbhld = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            method: 'delete',
            url: '/api/v2/quanlyhanhchinh/kiemdinhatvsbhld',
            data: payload
        })
        resolve(response)

    } catch (error) {
        reject(error)
    }
})
