import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Content from '~/layout/content/Content';
import { Block } from '~/components/block/Block';
import { Button, DataTable, PermissionUtils } from '~/components/Component';

import { Card } from 'reactstrap';
import * as actions from '~/store/actions';

import { MaterialReactTable } from 'material-react-table';
import Swal from 'sweetalert2';
import { ModalEditBangchamcongtonghop, ModalBangchamcongtonghop } from '~/pages/Admin/Modal';
import { Box, IconButton } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { MenuBangCong } from '~/pages/Admin/MenuTab/Menucontent';
import { Link } from 'react-router-dom';
import config from '~/config';
import { MRT_Localization_VI } from 'material-react-table/locales/vi';
import { toast } from 'react-toastify';

const Bangchamcongtonghop = () => {
    const dispatch = useDispatch();
    const [showPhanquyen, setShowPhanquyen] = useState({});
    let dataPermissionUtils = PermissionUtils('quanlycong', 'all');
    useEffect(() => {
        if (dataPermissionUtils) {
            setShowPhanquyen(dataPermissionUtils);
        }
    }, [dataPermissionUtils]);

    const [data, setData] = useState([]);
    const [show, setShow] = useState(false);
    const [showEditBangchamcongtonghop, setShowEditBangchamcongtonghop] = useState(false);
    const [dataIdBangchamcongtonghop, setDataIdBangchamcongtonghop] = useState([]);
    const { bangchamcongtonghop } = useSelector((state) => state.bangchamcongtonghop);
    const fieldList = {
        tieude: '',
        mota: '',
        BangchotcongId: '',
        nguoitao: 1,
        TrangthaiId: '',
    };
    useEffect(() => {
        dispatch(actions.getListBangchamcongtonghop());
    }, []);
    useEffect(() => {
        if (bangchamcongtonghop) {
            setData([...bangchamcongtonghop]);
            setShow(false);
        }
    }, [bangchamcongtonghop]);
    const handleAddNewBangchamcongtonghop = async () => {
        setShow(true);
    };
    const handleEditBangchamcongtonghop = async (data) => {
        setShowEditBangchamcongtonghop(true);
        await setDataIdBangchamcongtonghop(data);
    };
    const handleDeleteBangchamcongtonghop = (id) => {
        Swal.fire({
            title: 'Bạn có chắc không?',
            text: 'Bạn sẽ không thể lấy lại khi xóa!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Không, bỏ qua!',
            confirmButtonText: 'Vâng, xóa nó đi!',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(actions.DeleteBangchamcongtonghop({ id }));
            }
        });
    };
    const Example = () => {
        const columns = useMemo(
            () => [
                {
                    Header: 'STT', // Tiêu đề cột số thứ tự
                    header: 'STT', // Tiêu đề cột số thứ tự
                    id: 'rowNumber', // ID của cột
                    Cell: ({ row }) => <div>{row.index + 1}</div>, // Giá trị của cột là số thứ tự
                    enableSorting: false,
                    size: 100,
                    sx: { textAlign: 'center' },
                },
                {
                    accessorKey: 'tieude',
                    header: 'Tên bảng lương',
                    enablePinning: true,
                    enableColumnActions: false,
                    Cell: ({ cell, row }) => (
                        <Link to={`${config.he_thong}${config.cham_cong_tong_hop}?bangcongtonghop=${row.original.id}`}>
                            {cell.getValue()}
                        </Link>
                    ),
                },
                {
                    accessorKey: 'mota',
                    header: 'Mô tả',
                    enablePinning: true,
                },
                {
                    accessorKey: 'TrangthaiId',
                    header: 'Trạng thái',
                    Cell: ({ cell }) => (
                        <Box
                            component="span"
                            sx={(theme) => ({
                                backgroundColor:
                                    cell.getValue() === 1 ? theme.palette.success.dark : theme.palette.warning.dark,
                                borderRadius: '0.25rem',
                                color: '#fff',
                                maxWidth: '9ch',
                                p: '0.25rem',
                            })}
                        >
                            {cell.getValue() === 1 ? 'Đã khóa' : 'Chưa khóa'}
                        </Box>
                    ),
                },
            ],
            [],
        );
        const dataExport = data?.map((item) => ({
            tieude: item.tieude,
            mota: item.mota,
            TrangthaiId: item.TrangthaiId === 1 ? 'Đã khóa' : 'Chưa khóa',
        }));
        const csvOptions = {
            fileName: 'csv-data',
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            useBom: true,
            useKeysAsHeaders: false,
            headers: columns.map((c) => c.header),
        };

        const csvExporter = new ExportToCsv(csvOptions);

        const handleExportData = () => {
            if (dataExport.length === 0) {
                toast.error('Không có dữ liệu để xuất!');
                return;
            }
            csvExporter.generateCsv(dataExport);
        };
        return (
            <MaterialReactTable
                columns={columns}
                data={data}
                enableRowActions
                positionActionsColumn="last"
                renderTopToolbarCustomActions={() => (
                    <Box sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}>
                        {+showPhanquyen?.them === 1 && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleAddNewBangchamcongtonghop}
                                sx={{ mr: 2 }}
                            >
                                + Thêm mới
                            </Button>
                        )}
                        <Button
                            color="primary"
                            onClick={handleExportData}
                            startIcon={<FileDownloadIcon />}
                            variant="contained"
                        >
                            Xuất dữ liệu
                        </Button>
                    </Box>
                )}
                renderRowActions={({ row, table }) => (
                    <Box sx={{ display: 'block', flexWrap: 'nowrap', gap: '8px', width: '200px' }}>
                        {+showPhanquyen?.sua === 1 && (
                            <IconButton
                                color="secondary"
                                onClick={() => {
                                    handleEditBangchamcongtonghop(row.original);
                                }}
                            >
                                <EditIcon />
                            </IconButton>
                        )}
                        {+showPhanquyen?.xoa === 1 && (
                            <IconButton
                                color="error"
                                onClick={() => {
                                    handleDeleteBangchamcongtonghop(row.original.id);
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        )}
                    </Box>
                )}
                localization={MRT_Localization_VI}
            />
        );
    };

    return (
        <React.Fragment>
            {/*<Head title="User List - Regular"></Head>*/}
            <Content>
                <Card className="mt-0">
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner card-inner-lg px-4 pt-1 pb-0">
                            <h4 className="mt-2">Quản lý chấm công</h4>
                            <MenuBangCong />
                            <h5 className="mt-4"> Bảng chấm công tổng hợp</h5>
                        </div>
                    </DataTable>
                </Card>
                <Block>
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner position-relative card-tools-toggle">
                            <Example />
                        </div>
                    </DataTable>
                </Block>
                <ModalBangchamcongtonghop show={show} fieldList={fieldList} setShow={() => setShow(false)} />
                <ModalEditBangchamcongtonghop
                    show={showEditBangchamcongtonghop}
                    fieldList={fieldList}
                    setShow={() => setShowEditBangchamcongtonghop(false)}
                    dataIdBangchamcongtonghop={dataIdBangchamcongtonghop}
                />
            </Content>
        </React.Fragment>
    );
};

export default Bangchamcongtonghop;
