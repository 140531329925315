
export const formatVietnameseToString = (keyword) => {
    return keyword
        // Chuyển hết sang chữ thường
        .toLowerCase()
        // Chuyển hết sang chữ hoa
        // .toUpperCase()
        // xóa dấu
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        // Thay ký tự đĐ
        .replace(/[đĐ]/g, 'd')
        // Xóa ký tự đặc biệt
        .replace(/([^0-9a-z-\s])/g, '')
        // Xóa khoảng trắng thay bằng ký tự
        // .replace(/(\s+)/g, '-')
        // Xóa ký tự - liên tiếp
        // .replace(/-+/g, '-')
        // xóa phần dư - ở đầu & cuối
        // .replace(/^-+|-+$/g, '')
        .split(" ")
        .join("_")
}