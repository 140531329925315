import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputForm, Button } from '~/components/Form';
import * as actions from '~/store/actions';
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import {
    apiNewMccByNhanVien,
    apiEditMccByNhanVien,
} from '~/services';
import { toast } from 'react-toastify';
// import { dataSelectCapPhat } from '~/utils/Datacustom';
// import { EditTaisan, NewTaisan } from '~/store/actions';

const ModalMcc = ({ show, setShow, idNhanvien, dataMcc, uuid ,totalMcc}) => {
    const [payload, setPayload] = useState({});

    const { maychamcong } = useSelector((state) => state.maychamcong);
    const [listMcc, setlistMcc] = useState([]);
    const dispatch = useDispatch();
    const [invalidFields, setInvalidFields] = useState([]);
 // không cho phụ cấp trùng nhau
 useEffect(() => {
    if (totalMcc && totalMcc?.length > 0 && maychamcong) {
        const c = maychamcong.filter(
            (itemB) => !totalMcc.some((itemA) => Number(itemA?.MaychamcongId) === Number(itemB.id)),
        );
        setlistMcc(c.map((item) => ({ label: item.tenmaychamcong, value: item.id })));
    } else {
        setlistMcc(maychamcong.map((item) => ({ label: item.tenmaychamcong, value: item.id })))
    }
}, [totalMcc, totalMcc?.length, maychamcong]);
    useEffect(() => {
        if (show && dataMcc !== null) {
            const fieldList = {
                id: dataMcc?.id,
                MaychamcongId: { label: dataMcc?.Maychamcong?.tenmaychamcong, value: dataMcc?.MaychamcongId},
                machamcong: dataMcc?.machamcong,
                NhanvienId: idNhanvien,
                tenchamcong: dataMcc?.tenchamcong,
                mathe: dataMcc?.mathe,
            };
            setPayload(fieldList);
        }else{
            const fieldList = {
                MaychamcongId: '',
                machamcong: '',
                tenchamcong: '',
                mathe: '',
            };
            setPayload(fieldList);
        }
    }, [show, dataMcc]);

    const handleSubmit = async () => {
        let invalids = validate(payload);
        // console.log('invalids',invalids)
        if (invalids === 0) {
            if (payload?.id) {
                let datapayload = {
                    id: payload?.id,
                    NhanvienId: idNhanvien,
                    MaychamcongId: payload?.MaychamcongId?.value,
                    machamcong: payload?.machamcong,
                    tenchamcong: payload?.tenchamcong,
                    mathe: payload?.mathe,
                };
                const edit = await apiEditMccByNhanVien(datapayload);
                // console.log('datapayload ccccccccccc edit', edit)

                if (edit?.data?.success === 200) {
                    toast.success('Cập nhật thành công');
                    setShow(false);
                    dispatch(actions.getListNhanvienOne({uuid: uuid}));
                } else {
                    toast.error('Cập nhật thất bại');
                }
            } else {
                let datapayload = {
                    // data: [
                    //     {
                            NhanvienId: idNhanvien,
                            MaychamcongId: payload?.MaychamcongId?.value,
                            machamcong: Number(payload?.machamcong),
                            tenchamcong: payload?.tenchamcong,
                            mathe: payload?.mathe,
                    //     },
                    // ],
                };
                // console.log('datapayload ccccccccccc', datapayload)
                const create = await apiNewMccByNhanVien(datapayload);
                if (create?.data?.success === 200) {
                    toast.success("Thêm mới thành công");
                    setShow(false);
                    dispatch(actions.getListNhanvienOne({uuid: uuid}));
                } else {
                    toast.error("Thêm mới thất bại");
                }
            }
            setShow(false);
        }
    };
    // const handleOnSelect = async (selected, name) => {
    //     let statename = name.name;
    //     setPayload((prev) => ({ ...prev, [statename]: selected }));
    // };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (item[1] === '') {
                setInvalidFields((prev) => [
                    ...prev,
                    {
                        name: item[0],
                        message: 'Bạn không được bỏ trống trường này.',
                    },
                ]);
                invalids++;
            }
        });

        return invalids;
    };
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">{dataMcc !== null ? 'Cập nhật máy chấm công nhân viên' : 'Thêm mới máy chấm công nhân viên'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-3">
                        <div className="form-group">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                Máy chấm công
                            </label>
                            <Select
                                value={payload.MaychamcongId}
                                onChange={(selected) => {
                                    setPayload(prev => ({
                                        ...prev,
                                        ['MaychamcongId']: selected
                                    }))
                                }}
                                options={listMcc}
                                placeholder={'Chọn máy chấm công'}
                                name="MaychamcongId"
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'MaychamcongId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'MaychamcongId')?.message}
                                </small>
                            )}
                        </div>
                    </div>
                   
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Mã chấm công'}
                            value={payload.machamcong}
                            setValue={setPayload}
                            keyPayload={'machamcong'}
                            type='number'
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Tên chấm công'}
                            value={payload.tenchamcong}
                            setValue={setPayload}
                            keyPayload={'tenchamcong'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Mã thẻ'}
                            value={payload.mathe}
                            setValue={setPayload}
                            keyPayload={'mathe'}
                            type='text'
                        />
                    </div>
                </div>

                <div className="col-12 mt-5">
                    <Button text={dataMcc === null ? 'Thêm mới' : 'Cập nhật'} bClass="btn btn-primary px-5" textColor onClick={handleSubmit} />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalMcc;
