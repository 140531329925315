import React, {useState, useEffect} from 'react'
import {InputForm, Button} from '~/components/Form'
import '../Modal.scss';
import Content from "~/layout/content/Content";
import {Modal, ModalBody} from 'reactstrap';
import Select from "react-select";
import {dataSelectStatus} from "~/utils/Datacustom";
import {apiEditCongthuctinhluong} from "~/services";
import Icon from "~/components/icon/Icon";
import {SelectFinNhanvien} from "~/components/Component";
import Textarea from "~/components/Form/Textarea";
import {validateCongThucLuong} from "~/request/validateCongThucLuong";
import {toast} from "react-toastify";

const ModalEditCongThucLuong = ({show, setShow, dataEdit, fieldList}) => {
    console.log('dataEdit',dataEdit)
    const [invalidFields, setInvalidFields] = useState([])
    const [payload, setPayload] = useState(fieldList)
    const [listStatus, setlistStatus] = useState([]);
    const [showSelect, setShowSelect] = useState(false);
    const [listNhanvien, setlistNhanvien] = useState([]);
    
    // console.log('dataBienToanCucHeThong', dataBienToanCucHeThong)
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayload((prev) => ({...prev, [statename]: selected}));
    };

    useEffect(() => {
        
        let TrangthaiId = dataSelectStatus.find((items) => {
            return items && items.value === Number(dataEdit?.TrangthaiId);
        });
        
        show && dataEdit && setPayload({
            tencongthuc: dataEdit?.tencongthuc,
            mota: dataEdit?.mota,
            TrangthaiId: {label: TrangthaiId?.label, value: TrangthaiId?.value},
        })
        setlistNhanvien(
            dataEdit?.Nhanviens?.map((item) => ({
                value: item?.id,
                label: item?.hoten,
            })),
        );
        setlistStatus(dataSelectStatus);
    }, [dataEdit, show])
    
    const handleSubmit = async () => {
        
        let invalids = validate(payload)
        if (invalids === 0) {
            let datapayload = {
                data: [
                    {
                        id: dataEdit?.id,
                        tencongthuc: payload?.tencongthuc,
                        mota: payload.mota,
                        TrangthaiId: payload?.TrangthaiId?.value,
                        NhanvienId: listNhanvien,
                    },
                ],
            }
            // console.log('datapayload',datapayload)
            const result = await apiEditCongthuctinhluong(datapayload)
           
            if (result.data.success === 200) {
                toast.success(result.data.message);
                setShow(false)
            } else {
                toast.error("Cập nhật thất bại!");
            }
        }
    }
    useEffect(() => {
        setPayload((prev) => ({
            ...prev,
            ['NhanvienId']: listNhanvien,
        }));
    }, [listNhanvien]);
    const validate = (payload) => {
        let invalids = 0
        let fields = Object.entries(payload)
        fields.forEach(item => {
            if (validateCongThucLuong(item[0], item[1]) !== undefined) {
                setInvalidFields(prev => [...prev, validateCongThucLuong(item[0], item[1])])
                invalids++
            }
        })
        
        return invalids
    }

    return (
        <React.Fragment>
            <Content>
                <Modal isOpen={show} toggle={setShow} className="modal-dialog-centered modal-90w">
                    <ModalBody>
                        <Icon name="cross-sm float-end fs-2" onClick={setShow}></Icon>
                        <h5 className="title mb-5"> Sửa công thức tính lương</h5>
                        <div className="row mt-4">
                            <div className="form-group col-md-7">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Tên công thức'}
                                    value={payload.tencongthuc}
                                    setValue={setPayload}
                                    keyPayload={'tencongthuc'}
                                    type="text"
                                    validate
                                />
                            </div>
                            <div className="form-group col-md-5">
                                <label htmlFor="inputZip" className="mb-2 fw-bolder">
                                    Trạng thái
                                </label>
                                <Select
                                    value={payload?.TrangthaiId}
                                    onChange={handleOnSelect}
                                    options={listStatus}
                                    placeholder={'Chọn trạng tháí'}
                                    name="TrangthaiId"
                                    onFocus={() => setInvalidFields([])}
                                />
                                {invalidFields.length > 0 &&
                                    invalidFields.some((i) => i.name === 'TrangthaiId') && (
                                        <small className="text-danger">
                                            {
                                                invalidFields.find((i) => i.name === 'TrangthaiId')
                                                    ?.message
                                            }
                                        </small>
                                    )}
                            </div>
                            
                            <div className="form-group col-md-12" onClick={() => setShowSelect(true)}>
                                <label htmlFor="inputZip" className="mb-2 fw-bold">
                                    Nhân viên áp dụng
                                </label>
                                <Select
                                    value={payload.NhanvienId}
                                    name="NhanvienId"
                                    isMulti
                                    onFocus={() => setInvalidFields([])}
                                />
                                {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'NhanvienId') && (
                                    <small className="text-danger">
                                        {invalidFields.find((i) => i.name === 'NhanvienId')?.message}
                                    </small>
                                )}
                            </div>
                            <div className="form-group col-md-12">
                                <Textarea
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Mô tả'}
                                    value={payload.mota}
                                    setValue={setPayload}
                                    keyPayload={'mota'}
                                    type="text"
                                    validate
                                />
                            </div>
                        
                        </div>
                        
                        <div className="col-12 mt-5">
                            <Button text="Cập nhật Cách Tính" bClass="btn btn-primary px-5" textColor
                                    onClick={handleSubmit}/>
                        </div>
                    </ModalBody>
                </Modal>
            </Content>
            <SelectFinNhanvien
                showSelect={showSelect}
                typeSelect={true}
                listNhanvien={listNhanvien}
                cauHinhId={dataEdit?.id}
                setlistNhanvien={setlistNhanvien}
                typeSetting={'congthuc'} // 'chuyencan' || 'thamnien' || 'congthuc' || 'hoatdongsukien'
                setShowSelect={() => setShowSelect(false)}
            />
        </React.Fragment>
    
    );
}

export default ModalEditCongThucLuong;

