import actionTypes from "../actions/actionTypes";
const initState = {
    bocauhoiphongvan: [],
    msg: '',
    count: 0,
    newbocauhoiphongvan: []
}

const bocauhoiphongvanReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.GET_LIST_BO_CAU_HOI_PHONG_VAN:
            return {
                ...state,
                bocauhoiphongvan: action?.bocauhoiphongvan?.rows || [],
                msg: 'Ok' || '',
                count: action?.bocauhoiphongvan?.count || 0
            }
        case actionTypes.POST_NEW_BO_CAU_HOI_PHONG_VAN:
        case actionTypes.PUT_EDIT_BO_CAU_HOI_PHONG_VAN:
        case actionTypes.DELETE_BO_CAU_HOI_PHONG_VAN:
            return {
                ...state,
                msg: action.msg || '',
                newbocauhoiphongvan: action?.newbocauhoiphongvan || []
            }
        default:
            return state;
    }

}

export default bocauhoiphongvanReducer