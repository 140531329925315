import actionTypes from './actionTypes'
import { apiGetNhanvienkiemdinhatvsbhld, apiNewNhanvienkiemdinhatvsbhld, apiEditNhanvienkiemdinhatvsbhld, apiDeleteNhanvienkiemdinhatvsbhld } from '~/services/nhanvienkiemdinhatvsbhld'
import { toast } from "react-toastify";
export const getListNhanvienkiemdinhatvsbhld = () => async (dispatch) => {
    try {
        const response = await apiGetNhanvienkiemdinhatvsbhld()
        // console.log('chek api lisst Nhanvienkiemdinhatvsbhld', response?.data.success)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_NHAN_VIEN_KIEM_DINH_ATVSBHLD,
                nhanvienkiemdinhatvsbhld: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_NHAN_VIEN_KIEM_DINH_ATVSBHLD,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_NHAN_VIEN_KIEM_DINH_ATVSBHLD,
            nhanvienkiemdinhatvsbhld: null
        })
    }
}

export const NewNhanvienkiemdinhatvsbhld = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewNhanvienkiemdinhatvsbhld', payload)
            const response = await apiNewNhanvienkiemdinhatvsbhld(payload)
            // console.log('chek api apiNewNhanvienkiemdinhatvsbhld', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_NHAN_VIEN_KIEM_DINH_ATVSBHLD,
                    msg: 'OK'
                });
                dispatch(getListNhanvienkiemdinhatvsbhld());
                toast.success("Thêm mới thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_NHAN_VIEN_KIEM_DINH_ATVSBHLD,
                    msg: 'ERR'
                })
                toast.error("Thêm mới thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_NHAN_VIEN_KIEM_DINH_ATVSBHLD,
                nhanvienkiemdinhatvsbhld: null
            })
        }
    }
}
export const EditNhanvienkiemdinhatvsbhld = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditNhanvienkiemdinhatvsbhld(payload)
            // console.log('chek api apiEditNhanvienkiemdinhatvsbhld', response)
            if (response?.data.success === 200) {
                dispatch(getListNhanvienkiemdinhatvsbhld());
                toast.success("Sửa thông tin thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_NHAN_VIEN_KIEM_DINH_ATVSBHLD,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_NHAN_VIEN_KIEM_DINH_ATVSBHLD,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_NHAN_VIEN_KIEM_DINH_ATVSBHLD,
                nhanvienkiemdinhatvsbhld: null
            })
        }
    }
}
export const DeleteNhanvienkiemdinhatvsbhld = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteNhanvienkiemdinhatvsbhld(payload)
            // console.log('chek api apiDeleteNhanvienkiemdinhatvsbhld', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListNhanvienkiemdinhatvsbhld());
                toast.success("Xóa thông tin thành công!")
                dispatch({
                    type: actionTypes.DELETE_NHAN_VIEN_KIEM_DINH_ATVSBHLD,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_NHAN_VIEN_KIEM_DINH_ATVSBHLD,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_NHAN_VIEN_KIEM_DINH_ATVSBHLD,
                nhanvienkiemdinhatvsbhld: null
            })
        }
    }
}