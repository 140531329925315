import actionTypes from './actionTypes'
import { apiRegister, apiLogin, handleRefreshToken, apiGetUser } from '~/services/auth'
import { toast } from "react-toastify";
import Swal from 'sweetalert2';
export const register = (payload) => async (dispatch) => {
    try {
        const response = await apiRegister(payload)
        if (response?.data.err === 0) {
            Swal.fire({
                icon: 'success',
                title: 'Đăng ký thành công vui lòng truy cập Email của bạn để xác minh tài khoản!',
                showConfirmButton: false,
                timer: 3500000
              })
            dispatch({
                type: actionTypes.REGISTER_SUCCESS,
                data: response.data.token
            })
        } else {
            toast.error(response.data.mes)
            dispatch({
                type: actionTypes.REGISTER_FAIL,
                data: response.data.msg
            })
        }

    } catch (error) {
        toast.error("Đăng ký tài khoản thất bại!")
        dispatch({
            type: actionTypes.REGISTER_FAIL,
            data: null
        })
    }
}
export const login = (payload) => async (dispatch) => {
   
    try {
        const response = await apiLogin(payload)
        // console.log('check res', response.data);
        if (response?.data.err === 0) {
            // const res = await handleRefreshToken(response?.data.refresh_token)
            // console.log('check res1', res?.data.access_token);
            // console.log('check res2', response?.data.access_token);
            toast.success("Đăng nhập thành công!")
            dispatch({
                type: actionTypes.LOGIN_SUCCESS,
                data: response?.data.access_token
            })
            setTimeout(() => {
                dispatch(getUser());
            }, 1000)
        } else {
            toast.error(response.data.mes)
            dispatch({
                type: actionTypes.LOGIN_FAIL,
                data: response.data.mes
            })
        }

    } catch (error) {
        toast.error("Tài khoản của bạn chưa đúng!")
        dispatch({
            type: actionTypes.LOGIN_FAIL,
            data: null
        })
    }
}


export const getUser = () => async (dispatch) => {
    try {
        const response = await apiGetUser()
       console.log('get user', response)
        if (response?.data?.err === 0) {
            dispatch({
                type: actionTypes.GET_USER,
                user: response?.data?.userData,
                FunctionUser: response?.data?.functionUser
            })
        } else {
            dispatch({
                type: actionTypes.GET_USER,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_USER,
            user: null
        })
    }
}
export const logout = () => ({
    type: actionTypes.LOGOUT
})