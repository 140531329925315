import { useState } from 'react';
function Search({ e }) {
    const [show, setShow] = useState(false);

    const showInput = () => {
        setShow(true)
        // hideInput()
    };
    const hideInput = () => {
        setShow(false)
    };
    return (
        <div className="header-search-form-two white-icon">
            <div className="search-icon" id="search-overlay-trigger">
                <a href="#" onClick={showInput}>
                    <i className="far fa-search"></i>
                </a>
            </div>
            <div className={show ? 'search-overlay active' : 'search-overlay'}>

                <div className="search-overlay__header">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-md-6 ms-auto col-4">
                                <div className="search-content text-end">
                                    <span className="mobile-navigation-close-icon" onClick={hideInput} id="search-close-trigger"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="search-overlay__inner">
                    <div className="search-overlay__body">
                        <div className="search-overlay__form">
                            <input type="text" placeholder="Tìm kiếm" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Search;
