import React, { useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '~/store/actions';
import { ModalHopdonglaodong, ModalEditHopdonglaodong, ModalDownloadHopdonglaodong } from './Modal';
import Swal from 'sweetalert2';
import Head from '~/layout/head/Head';
import Content from '~/layout/content/Content';
import { Block, ListTabs } from '~/components/block/Block';
import {
    Button,
    Col,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    Icon,
    Row,
    RSelect,
    TooltipComponent,
    ExportXlsx,
    ImportXlsx,
    PaginationCount,
    PermissionUtils,
} from '~/components/Component';
import {
    Card,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalBody,
    UncontrolledDropdown,
    Spinner,
} from 'reactstrap';
import config from '~/config';
import { format, parseISO } from 'date-fns';
import { toast } from 'react-toastify';
// import { filterRole, filterStatus } from '~/utils/Datacustom';
// import { FileAllCodeContext } from './user-manage/FileAllCodeContext';
import { Link, useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { MenuHopdong } from './MenuTab/Menucontent';
import { dataThuethunhapcanhan, dataStatus } from '~/utils/Datacustom';

const Hopdonglaodong = () => {
    // const { contextData } = useContext(FileAllCodeContext);
    // const [msg, setMsg] = useState(null);
    // useEffect(() => {
    //     contextData && contextData.success === 200 && toast.success(contextData.msg) && setMsg(contextData.success);
    // }, [contextData]);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.getListNhanvien({ order: 'desc', by: 'id' }));
        dispatch(actions.getListMauhopdong());
    }, []);

    const [show, setShow] = useState(false);
    const [showEditHopdonglaodong, setShowEditHopdonglaodong] = useState(false);
    const [showDownloadHopdonglaodong, setShowDownloadHopdonglaodong] = useState(false);
    const [modal, setModal] = useState(false);
    //lấy giá trị từ thằng redux trả về
    const { hopdonglaodong, count, loaihopdong } = useSelector((state) => state.hopdonglaodong);
    const { chinhanh } = useSelector((state) => state.chinhanh);
    const { phongban } = useSelector((state) => state.phongban);
    const { nhanvien } = useSelector((state) => state.nhanvien);
    const { mauhopdong } = useSelector((state) => state.hopdonglaodong);

    const [data, setData] = useState(hopdonglaodong);
    // console.log('hopdonglaodong', hopdonglaodong)
    const [dataLoai, setDataLoai] = useState([]);
    const [dataChinhanh, setDataChinhanh] = useState([]);
    const [dataPhongban, setDataPhongban] = useState([]);
    const [dataNhanvien, setDataNhanvien] = useState([]);
    const namefile = 'Mau-nhap-hop-dong';
    const header = [
        'Stt',
        'Họ và tên',
        'Mã nhân viên',
        'Loại hợp đồng',
        'Loại thuế thu nhập cá nhân',
        'Người ký',
        // 'Quyết toán thay thuế TNCH',
        'Mẫu hợp đồng',
        'Ngày ký',
        'Ngày hết hạn',
        'Ngày bắt đầu làm việc',
        'Mô tả',
        'Địa điểm làm việc',
        'Trạng thái',
    ];
    const [dataFileMau, setDataFileMau] = useState(null);
    // console.log('dataFileMau', dataFileMau);

    const namefile1 = 'Thông tin hợp đồng';
    const header1 = [
        'Stt',
        'Họ và tên',
        'Mã hợp đồng',
        'Loại hợp đồng',
        'Chi nhánh',
        'Phòng ban',
        'Chức vụ',
        'Mẫu hợp đồng',
        'Người ký',
        // 'Thời gian thử việc',
        'Ngày tạo hợp đồng',
        'Ngày bắt đầu làm việc',
        'Ngày hết hạn hợp đồng',
        // 'Quyết toán thay thuế TNCH',
        'Địa điểm làm việc',
        'Mô tả hợp đồng',
        'Trạng thái',
    ];
    const [columns, setColumns] = useState(() => {
        const d = header1.map((item, index) => index <= 6 || index >= 14);
        return d;
    });
    const [dataFileMau1, setDataFileMau1] = useState([]);
    useEffect(() => {
        if (data.length > 0) {
            const datafilemau1 = data.map((item, index) => {
                return {
                    ...(columns[0] && { Stt: index + 1 }),
                    ...(columns[1] && { 'Họ và tên': item?.Nhanvien?.hoten ? item?.Nhanvien?.hoten : 'Nav' }),
                    ...(columns[2] && { 'Mã hợp đồng': item?.mahopdong }),
                    ...(columns[3] && { 'Loại hợp đồng': item?.Loaihopdong?.tenloaihopdong }),
                    ...(columns[4] && { 'Chi nhánh': item?.Chinhanh?.diachi }),
                    ...(columns[5] && { 'Phòng ban': item?.Phongban?.phongban }),
                    ...(columns[6] && { 'Chức vụ': item?.Chucvu?.chucvu }),
                    ...(columns[7] && { 'Mẫu hợp đồng': item?.Mauhopdong?.mota }),
                    ...(columns[8] && { 'Người ký': item?.User?.username }),
                    // ...(columns[9] && { 'Thời gian thử việc': item?.thoigianthuviec + 'tháng' }),
                    ...(columns[9] && {
                        'Ngày tạo hợp đồng': item?.ngaytaohopdong
                            ? format(parseISO(item?.ngaytaohopdong), 'dd-MM-yyyy')
                            : 'NAV',
                    }),
                    ...(columns[10] && {
                        'Ngày bắt đầu làm việc': item?.ngaybatdaulamviec
                            ? format(parseISO(item?.ngaybatdaulamviec), 'dd-MM-yyyy')
                            : 'NAV',
                    }),
                    ...(columns[11] && {
                        'Ngày hết hạn hợp đồng': item?.ngayhethan
                            ? format(parseISO(item?.ngayhethan), 'dd-MM-yyyy')
                            : 'NAV',
                    }),
                    // ...(columns[12] && { 'Quyết toán thay thuế TNCH': item?.quyettoanthaythue === 0 ? 'Có' : 'Không' }),
                    ...(columns[12] && { 'Địa điểm làm việc': item?.diaiemlamviec }),
                    ...(columns[13] && { 'Mô tả hợp đồng': item?.ghichu ? item?.ghichu : 'NAV' }),
                    ...(columns[14] && { 'Trạng thái': item?.TrangthaiId === 1 ? 'Hoạt động' : 'Không Hoạt động' }),
                };
            });
            setDataFileMau1(datafilemau1);
        }
    }, [data, columns]);

    const [showPhanquyen, setShowPhanquyen] = useState({});
    let dataPermissionUtils = PermissionUtils('hopdonglaodong', 'all');
    useEffect(() => {
        if (dataPermissionUtils) {
            setShowPhanquyen(dataPermissionUtils);
        }
    }, [dataPermissionUtils]);

    const [dataSelectMau, setDataSelectMau] = useState({
        chinhanh: '',
        phongban: '',
        nhanvien: '',
        selectnhanvien: false,
    });
    const [itemPerPage, setItemPerPage] = useState(10);
    const [sort, setSortState] = useState('desc');
    // console.log('sort', sort)
    // Xóa params
    const navigate = useNavigate();
    const location = useLocation();
    function handleReParamsClick() {
        navigate(location.pathname);
        setPayload((prev) => ({ ...prev, ['loaihopdong']: '' }));
    }
    // end Xóa params
    //params thay đổi thì chạy
    const [searchParams] = useSearchParams();
    useEffect(() => {
        const params = [];
        for (let entry of searchParams.entries()) {
            params.push(entry);
        }
        if (params.length !== 0) {
            let searchParamsObject = {
                limit: itemPerPage,
                order: sort,
                by: 'id',
            };
            params?.forEach((i) => {
                if (Object.keys(searchParamsObject).some((item) => item === i[0])) {
                    searchParamsObject[i[0]] = [...searchParamsObject[i[0]], i[1]];
                } else {
                    searchParamsObject[i[0]] = i[1];
                }
            });
            // console.log('searchParamsObject', searchParamsObject)
            dispatch(actions.getListHopdonglaodong(searchParamsObject));
        } else {
            let searchParamsObject = { page: '1', limit: itemPerPage, order: sort, by: 'id' };
            dispatch(actions.getListHopdonglaodong(searchParamsObject));
        }
        dispatch(actions.getListLoaihopdong());
    }, [searchParams, itemPerPage, sort]);

    // const datavvv = [ {ketqua: 'MHD001'}, {ketqua: 'MHD002'},{ketqua: 'MHD003'},{ketqua: 'MHD006'},{ketqua: 'MHD005'}];
    // const mhd = 'MHD';
    // let counter = 0;
    // let resultvvv = '';
    // const generateResult = () => {
    //   do {
    //     resultvvv = `${mhd}${counter.toString().padStart(3, '0')}`;
    //     counter++;
    //   } while (datavvv.some(item => item.ketqua === resultvvv));
    //   console.log(resultvvv);
    // };
    // useEffect(() => {
    //     const params = Array.from(searchParams.entries());
    //     const searchParamsObject = params.reduce((acc, [key, value]) => {
    //       if (Object.keys(acc).includes(key)) {
    //         return { ...acc, [key]: [...acc[key], value] };
    //       } else {
    //         return { ...acc, [key]: value };
    //       }
    //     }, {
    //       page: '1',
    //       limit: `${limit}`,
    //       order: 'ASC',
    //       by: 'id',
    //     });

    //     dispatch(actions.getListHopdonglaodong(searchParamsObject));
    //     dispatch(actions.getListLoaihopdong());
    //   }, [searchParams]);
    // const [dataparams, setDataParams] = useState([]);
    // console.log('dataparams', dataparams)

    const handleFileModel = async () => {
        setModal(true);
        let dataChinhanh = chinhanh.map((item) => ({ label: item.diachi, value: item.id }));
        let dataPhongban = phongban.map((item) => ({ label: item.phongban, value: item.id }));
        let dataNhanvien = nhanvien.map((item) => ({ label: item.hoten, value: item.id }));
        setDataChinhanh(dataChinhanh);
        setDataPhongban(dataPhongban);
        setDataNhanvien(dataNhanvien);
    };
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setDataSelectMau((prev) => ({ ...prev, [statename]: selected }));
    };
    useEffect(() => {
        if (dataSelectMau) {
            let vacn = dataSelectMau.chinhanh;
            let vapb = dataSelectMau.phongban;
            let vanv = dataSelectMau.nhanvien;
            // console.log(object)
            if (vacn.length > 0) {
                const pvIds = nhanvien
                    .filter((itemA) => {
                        return vacn.some((itemB) => itemB.value === itemA.ChinhanhId);
                    })
                    .map((itemA, index) => ({
                        Stt: index + 1,
                        id: itemA.id,
                        hoten: itemA.hoten,
                        PhongbanId: itemA.PhongbanId,
                        uuid: itemA.uuid,
                    }));
                if (vapb.length > 0) {
                    const pbId = pvIds
                        .filter((itemA) => {
                            return vapb.some((itemB) => itemB.value === itemA.PhongbanId);
                        })
                        .map((itemA, index) => ({
                            Stt: index + 1,
                            id: itemA.id,
                            hoten: itemA.hoten,
                            PhongbanId: itemA.PhongbanId,
                            uuid: itemA.uuid,
                        }));
                    setDataFileMau(pbId);
                    dataSelectMau.nhanvien = '';
                } else {
                    setDataFileMau(pvIds);
                    dataSelectMau.phongban = '';
                    dataSelectMau.nhanvien = '';
                }
            }
            if (vapb.length > 0) {
                if (vacn.length <= 0) {
                    const pbIds = nhanvien
                        .filter((itemA) => {
                            return vapb.some((itemB) => itemB.value === itemA.PhongbanId);
                        })
                        .map((itemA, index) => ({
                            Stt: index + 1,
                            id: itemA.id,
                            hoten: itemA.hoten,
                            PhongbanId: itemA.PhongbanId,
                            uuid: itemA.uuid,
                        }));
                    setDataFileMau(pbIds);
                    dataSelectMau.nhanvien = '';
                    dataSelectMau.chinhanh = '';
                }
            }
            if (vanv.length > 0) {
                const nvIds = nhanvien
                    .filter((itemA) => {
                        return vanv.some((itemB) => itemB.value === itemA.id);
                    })
                    .map((itemA, index) => ({
                        Stt: index + 1,
                        id: itemA.id,
                        hoten: itemA.hoten,
                        PhongbanId: itemA.PhongbanId,
                        uuid: itemA.uuid,
                    }));
                setDataFileMau(nvIds);
                dataSelectMau.chinhanh = '';
                dataSelectMau.phongban = '';
            }
            if (vanv.length <= 0 && vacn.length <= 0 && vapb.length <= 0) {
                const datafilemau = nhanvien.map((item, index) => ({
                    Stt: index + 1,
                    Id: item.id,
                    hoten: item.hoten,
                    PhongbanId: item.PhongbanId,
                    uuid: item.uuid,
                }));
                setDataFileMau(datafilemau);
            }
        }
    }, [dataSelectMau]);
    // console.log('dataFileMau', dataFileMau)
    // console.log('dataSelectMau', dataSelectMau)
    const fieldList = {
        PhongbanId: '',
        NhanvienId: '',
        ChinhanhId: '',
        ChucvuId: '',
        UserId: '',
        LoaihopdongId: '',
        MauhopdongId: '',
        // mahopdong: '',
        motahopdong: '',
        ngayhethan: '',
        ngaytaohopdong: '',
        ThuethunhapcanhanId: '',
        // thoigianthuviec: '',
        diaiemlamviec: '',
        ngaybatdaulamviec: '',
        // quyettoanthaythue: false,
        TrangthaiId: '',
    };
    const [dataExport, setDataExport] = useState([]);
    // console.log('dataExport', dataExport);

    const { userall } = useSelector((state) => state.app);

    useEffect(() => {
        if (hopdonglaodong) {
            setData([...hopdonglaodong]);
        }
        if (chinhanh && chinhanh.length > 0) {
            setDataChinhanh([...chinhanh]);
        }
        if (phongban && phongban.length > 0) {
            setDataPhongban([...phongban]);
        }
        if (nhanvien && nhanvien.length > 0) {
            setDataNhanvien([...nhanvien]);
        }
        if (loaihopdong.length && userall.length && nhanvien && mauhopdong) {
            setDataExport({
                loaihopdong: loaihopdong,
                loaithuethunhap: dataThuethunhapcanhan,
                nguoiky: userall,
                trangthai: dataStatus,
                nhanvien: nhanvien,
                mauhopdong: mauhopdong,
            });
        }
        let dataLoai = loaihopdong.map((item) => ({ label: item.tenloaihopdong, value: item.id }));
        setDataLoai(dataLoai);
        setDataLoai([...dataLoai, { label: 'Cận hết hạn', value: 'canhethan' }, { label: 'Hết hạn', value: 'hethan' }]);
    }, [hopdonglaodong, loaihopdong, chinhanh, phongban, nhanvien, mauhopdong]);
    const [sm, updateSm] = useState(false);
    const [smCloud, updateSmCloud] = useState(false);
    const [tablesm, updateTableSm] = useState(false);
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState('');
    const onFilterChange = (e) => {
        setSearchText(e.target.value);
    };
    // function to toggle the search option
    const toggle = () => setonSearch(!onSearch);
    const selectorCheck = (e) => {
        let newData;
        newData = data.map((item) => {
            item.checked = e.currentTarget.checked;
            return item;
        });
        setData([...newData]);
    };
    const onSelectChange = (e, id) => {
        let newData = data;
        let index = newData.findIndex((item) => item.id === id);
        newData[index].checked = e.currentTarget.checked;
        setData([...newData]);
    };
    useEffect(() => {
        if (onSearchText !== '') {
            const filteredObject = data.filter((item) => {
                return (
                    item?.Nhanvien?.hoten.toLowerCase().includes(onSearchText.toLowerCase()) ||
                    item.mahopdong.toLowerCase().includes(onSearchText.toLowerCase())
                );
            });
            setData([...filteredObject]);
        } else {
            setData([...hopdonglaodong]);
        }
    }, [onSearchText, setData]);
    // Tìm kiềm theo loại hợp đồng
    const [payload, setPayload] = useState({ loaihopdong: '' });
    // console.log('payload', payload)
    const onSearchLoai = () => {
        navigate(`${location.pathname}?page=1&filter=${payload?.loaihopdong?.value}`);
        // if (id === 'All') {
        //     setData([...hopdonglaodong]);
        // } else if (id === 'Hethan') {
        //     const newDate = new Date();
        //     let loaihopdong = hopdonglaodong.filter((item) => {
        //         return item && format(parseISO(item.ngayhethan), 'yyyy-MM-dd') <= format(newDate, 'yyyy-MM-dd');
        //     });
        //     setData([...loaihopdong]);
        // } else {
        //     let loaihopdong = hopdonglaodong.filter((item) => {
        //         return item && item.LoaihopdongId === id;
        //     });
        //     setData([...loaihopdong]);
        // }
    };

    useEffect(() => {
        (hopdonglaodong && setShow(false)) || setShowEditHopdonglaodong(false);
    }, [hopdonglaodong]);
    // set state để mở đóng Modal
    const handleAddNewHopdonglaodong = async () => {
        setShow(true);
    };
    const [dataIdHopdonglaodong, setDataIdHopdonglaodong] = useState([]);
    const handleEditHopdonglaodong = async (data) => {
        setShowEditHopdonglaodong(true);
        await setDataIdHopdonglaodong(data);
    };
    const [dataHopdonglaodong, setDataHopdonglaodong] = useState([]);
    const handleDownloadHopdonglaodong = async (data) => {
        setShowDownloadHopdonglaodong(true);
        await setDataHopdonglaodong(data);
    };
    const handleDeleteHopdonglaodong = (data) => {
        Swal.fire({
            title: 'Bạn có chắc không?',
            text: 'Bạn sẽ không thể lấy lại khi xóa!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Không, bỏ qua!',
            confirmButtonText: 'Vâng, xóa nó đi!',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(actions.DeleteHopdonglaodong(data));
            }
        });
    };
    const itemsPerPageOptions = [10, 20, 30, 40, 50, 100, 300, 500];
    return (
        <React.Fragment>
            {/*<Head title="User List - Regular"></Head>*/}
            <Content>
                {/* <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                Hợp đồng lao động
                            </BlockTitle>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <Button
                                    className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? 'active' : ''}`}
                                    onClick={() => updateSm(!sm)}
                                >
                                    <Icon name="menu-alt-r"></Icon>
                                </Button>
                                <div className="toggle-expand-content" style={{ display: sm ? 'block' : 'none' }}>
                                    <ul className="nk-block-tools ">
                                        <li className="px-3">
                                            <ExportXlsx
                                                dataFileMau={dataFileMau}
                                                header={header}
                                                namefile={namefile}
                                                btnname={'Xuất dữ liệu'}
                                            />
                                        </li>

                                        <li className="preview-item">
                                            {+showPhanquyen?.them === 1 && (
                                                <UncontrolledDropdown>
                                                    <div className="btn-group">
                                                        <Button color="primary" onClick={handleAddNewHopdonglaodong}>
                                                            <Icon name="plus"></Icon> Thêm mới hợp đồng
                                                        </Button>
                                                        <DropdownToggle
                                                            className="dropdown-toggle-split"
                                                            color="secondary"
                                                        >
                                                            <Icon name="chevron-down"></Icon>
                                                        </DropdownToggle>
                                                    </div>
                                                    <DropdownMenu>
                                                        <ul className="link-list-opt">
                                                            <li onClick={handleFileModel}>
                                                                <DropdownItem tag="a" className="pe-auto-cursor">
                                                                    <Icon name="file-xls"></Icon>
                                                                    <span>Nhập File</span>
                                                                </DropdownItem>
                                                            </li>
                                                            <li>
                                                                <Link
                                                                    className="pe-auto-cursor"
                                                                    rel="noopener noreferrer"
                                                                    to={`${
                                                                        process.env.PUBLIC_URL +
                                                                        config.he_thong +
                                                                        config.loai_hop_dong
                                                                    }`}
                                                                >
                                                                    <Icon name="file-plus"></Icon>
                                                                    <span>Loại hợp đồng</span>
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            )}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead> */}
                
                <Card className="">
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner card-inner-lg px-4 mt-3">
                            <h4>Quản lý hợp đồng lao động</h4>
                            <MenuHopdong />
                        </div>
                    </DataTable>
                </Card>
                <Block>
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner position-relative card-tools-toggle">
                         
                            <div className="card-title-group ">
                                <div className="card-title me-n1">
                                    <h5 className="title">Danh sách hợp đồng</h5>
                                    {+showPhanquyen?.them === 1 && (
                                        <UncontrolledDropdown>
                                            <div className="btn-group">
                                                <Button color="primary" onClick={handleAddNewHopdonglaodong}>
                                                    <Icon name="plus"></Icon> Thêm mới hợp đồng
                                                </Button>
                                                {/* <DropdownToggle className="dropdown-toggle-split" color="secondary">
                                                    <Icon name="chevron-down"></Icon>
                                                </DropdownToggle> */}
                                            </div>
                                            {/* <DropdownMenu>
                                                <ul className="link-list-opt">
                                                    <li onClick={handleFileModel}>
                                                        <DropdownItem tag="a" className="pe-auto-cursor">
                                                            <Icon name="file-xls"></Icon>
                                                            <span>Nhập File</span>
                                                        </DropdownItem>
                                                    </li>
                                                </ul>
                                            </DropdownMenu> */}
                                        </UncontrolledDropdown>
                                    )}
                                </div>
                                <div className="card-tools me-n1 gx-1">
                                    {/* <ul className="btn-toolbar gx-1"> */}
                                    <ListTabs>
                                        <li>
                                            <a
                                                href="#search"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    toggle();
                                                }}
                                                className="btn btn-icon search-toggle toggle-search"
                                            >
                                                <Icon name="search"></Icon>
                                            </a>
                                        </li>
                                        <li className="btn-toolbar-sep"></li>
                                        <li>
                                            <div className="toggle-wrap">
                                                <Button
                                                    className={`btn-icon btn-trigger toggle ${tablesm ? 'active' : ''}`}
                                                    onClick={() => updateTableSm(true)}
                                                >
                                                    <Icon name="menu-right"></Icon>
                                                </Button>
                                                <div className={`toggle-content ${tablesm ? 'content-active' : ''}`}>
                                                    <ul className="btn-toolbar gx-1">
                                                        <li className="toggle-close">
                                                            <Button
                                                                className="btn-icon btn-trigger toggle"
                                                                onClick={() => updateTableSm(false)}
                                                            >
                                                                <Icon name="arrow-left"></Icon>
                                                            </Button>
                                                        </li>
                                                        <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle
                                                                    tag="a"
                                                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                                                >
                                                                    <div className="dot dot-primary"></div>
                                                                    <Icon name="filter-alt"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu
                                                                    end
                                                                    className="filter-wg dropdown-menu-xl"
                                                                    style={{ overflow: 'visible' }}
                                                                >
                                                                    <div className="dropdown-head">
                                                                        <span className="sub-title dropdown-title">
                                                                            Bộ lọc
                                                                        </span>
                                                                        <div className="dropdown">
                                                                            <a
                                                                                href="#more"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                }}
                                                                                className="btn btn-sm btn-icon"
                                                                            >
                                                                                <Icon name="more-h"></Icon>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    <div className="dropdown-body dropdown-body-rg">
                                                                        <Row className="gx-6 gy-3">
                                                                            <Col size="12">
                                                                                <div className="form-group">
                                                                                    <label className="overline-title overline-title-alt">
                                                                                        Loại hợp đồng
                                                                                    </label>
                                                                                    <RSelect
                                                                                        value={payload.loaihopdong}
                                                                                        options={dataLoai}
                                                                                        onChange={(selected) =>
                                                                                            setPayload((prev) => ({
                                                                                                ...prev,
                                                                                                ['loaihopdong']:
                                                                                                    selected,
                                                                                            }))
                                                                                        }
                                                                                        placeholder="Danh sách loại hợp đồng"
                                                                                    />
                                                                                </div>
                                                                            </Col>

                                                                            <Col size="12 text-end">
                                                                                <div className="form-group">
                                                                                    {payload?.loaihopdong === '' ? (
                                                                                        <Button
                                                                                            disabled
                                                                                            color="primary"
                                                                                            className="btn btn-secondary"
                                                                                        >
                                                                                            <span> Tìm kiếm... </span>
                                                                                        </Button>
                                                                                    ) : (
                                                                                        <Button
                                                                                            color="primary"
                                                                                            className="btn btn-secondary"
                                                                                            onClick={() =>
                                                                                                onSearchLoai()
                                                                                            }
                                                                                        >
                                                                                            Tìm kiếm
                                                                                        </Button>
                                                                                    )}
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                    <div className="dropdown-foot between">
                                                                        <a
                                                                            href="#reset"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();
                                                                                handleReParamsClick();
                                                                            }}
                                                                            className="clickable"
                                                                        >
                                                                            Đặt lại bộ lọc
                                                                        </a>
                                                                    </div>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                        <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle
                                                                    color="tranparent"
                                                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                                                >
                                                                    <Icon name="setting"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu end className="dropdown-menu-xs">
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Show</span>
                                                                        </li>
                                                                        {itemsPerPageOptions.map((item, index) => {
                                                                            return (
                                                                                <li
                                                                                    className={
                                                                                        itemPerPage === item
                                                                                            ? 'active'
                                                                                            : ''
                                                                                    }
                                                                                    key={index}
                                                                                >
                                                                                    <DropdownItem
                                                                                        tag="a"
                                                                                        href="#dropdownitem"
                                                                                        onClick={(ev) => {
                                                                                            ev.preventDefault();
                                                                                            setItemPerPage(item);
                                                                                            // handleReParamsClick();
                                                                                        }}
                                                                                    >
                                                                                        {item}
                                                                                    </DropdownItem>
                                                                                </li>
                                                                            );
                                                                        })}
                                                                    </ul>
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Order</span>
                                                                        </li>
                                                                        <li className={sort === 'asc' ? 'active' : ''}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setSortState('asc');
                                                                                    handleReParamsClick();
                                                                                }}
                                                                            >
                                                                                ASC
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li className={sort === 'desc' ? 'active' : ''}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setSortState('desc');
                                                                                    handleReParamsClick();
                                                                                }}
                                                                            >
                                                                                DESC
                                                                            </DropdownItem>
                                                                        </li>
                                                                    </ul>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            {/* <div className=" position-relative card-tools-toggle"> */}
                                                <div className="toggle-wrap nk-block-tools-toggle">
                                                    <Button
                                                        className={`btn-icon btn-trigger toggle-expand me-n1 ${
                                                            smCloud ? 'active' : ''
                                                        }`}
                                                        onClick={() => updateSmCloud(!smCloud)}
                                                    >
                                                        <Icon name="upload-cloud"></Icon>
                                                    </Button>
                                                    <div
                                                        className="toggle-expand-content-cloud"
                                                        style={{ display: smCloud ? 'block' : 'none' }}
                                                    >
                                                        <ul className="nk-block-tools g-3">
                                                            <li className="nk-block-tools-opt">
                                                                <ExportXlsx
                                                                    dataFileMau={dataFileMau1}
                                                                    title={header1}
                                                                    namefile={namefile1}
                                                                    btnname={'Xuất dữ liệu'}
                                                                />
                                                            </li>
                                                            <li className="nk-block-tools-opt">
                                                                <Button color="primary" onClick={handleFileModel}>
                                                                    <Icon name="upload-cloud"></Icon>{' '}
                                                                    <span>Nhập dữ liệu</span>
                                                                </Button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            {/* </div> */}
                                        </li>
                                    </ListTabs>
                                    {/* </ul> */}
                                </div>
                            </div>
                            <div className={`card-search search-wrap ${!onSearch && 'active'}`}>
                                <div className="card-body">
                                    <div className="search-content">
                                        <Button
                                            className="search-back btn-icon toggle-search active"
                                            onClick={() => {
                                                setSearchText('');
                                                toggle();
                                            }}
                                        >
                                            <Icon name="arrow-left"></Icon>
                                        </Button>
                                        <input
                                            type="text"
                                            className="border-transparent form-focus-none form-control"
                                            placeholder="Tìm kiến hợp đồng"
                                            value={onSearchText}
                                            onChange={(e) => onFilterChange(e)}
                                        />
                                        <Button className="search-submit btn-icon">
                                            <Icon name="search"></Icon>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ overflowY: 'overlay' }}>
                            <DataTableBody>
                                <DataTableHead>
                                    {columns[0] && (
                                        <DataTableRow>
                                            <span className="sub-text">Stt</span>
                                        </DataTableRow>
                                    )}
                                    <DataTableRow className="nk-tb-col-check">
                                        <div className="custom-control custom-control-sm custom-checkbox notext">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                onChange={(e) => selectorCheck(e)}
                                                id="uid"
                                            />
                                            <label className="custom-control-label" htmlFor="uid"></label>
                                        </div>
                                    </DataTableRow>
                                    {columns[1] && (
                                        <DataTableRow>
                                            <span className='sub-text w-150px'>
                                                Họ và tên
                                            </span>
                                        </DataTableRow>
                                    )}
                                    {columns[2] && (
                                        <DataTableRow>
                                            <span className='sub-text w-150px'>
                                                Mã hợp đồng
                                            </span>
                                        </DataTableRow>
                                    )}
                                    {columns[3] && (
                                        <DataTableRow>
                                            <span className='sub-text w-150px'>
                                                Loại hợp đồng
                                            </span>
                                        </DataTableRow>
                                    )}
                                    {columns[4] && (
                                        <DataTableRow>
                                            <span className='sub-text w-150px'>
                                                Chi nhánh
                                            </span>
                                        </DataTableRow>
                                    )}
                                    {columns[5] && (
                                        <DataTableRow>
                                            <span className='sub-text w-150px'>
                                                Phòng ban
                                            </span>
                                        </DataTableRow>
                                    )}
                                    {columns[6] && (
                                        <DataTableRow>
                                            <span className='sub-text w-150px'>
                                                Chức vụ
                                            </span>
                                        </DataTableRow>
                                    )}
                                    {columns[7] && (
                                        <DataTableRow>
                                            <span className='sub-text w-150px'>
                                                Loại thuế thu nhập cá nhân
                                            </span>
                                        </DataTableRow>
                                    )}
                                    {columns[8] && (
                                        <DataTableRow>
                                            <span className='sub-text w-150px'>
                                                Người ký
                                            </span>
                                        </DataTableRow>
                                    )}
                                    {/* {columns[9] && (
                                        <DataTableRow>
                                            <span className="sub-text w-150px" : "sub-text"}>Thời gian thử việc</span>
                                        </DataTableRow>
                                    )} */}
                                    {columns[9] && (
                                        <DataTableRow>
                                            <span className='sub-text w-150px'>
                                                Ngày tạo hợp đồng
                                            </span>
                                        </DataTableRow>
                                    )}
                                    {columns[10] && (
                                        <DataTableRow>
                                            <span className='sub-text w-150px'>
                                                Ngày bắt đầu làm việc
                                            </span>
                                        </DataTableRow>
                                    )}
                                    {columns[11] && (
                                        <DataTableRow>
                                            <span className='sub-text w-150px'>
                                                Ngày hết hạn hợp đồng
                                            </span>
                                        </DataTableRow>
                                    )}
                                    {/* {columns[12] && (
                                        <DataTableRow>
                                            <span className="sub-text w-150px" : "sub-text"}>Quyết toán thay thuế TNCH</span>
                                        </DataTableRow>
                                    )} */}
                                    {columns[12] && (
                                        <DataTableRow>
                                            <span className='sub-text w-150px'>
                                                Địa điểm làm việc
                                            </span>
                                        </DataTableRow>
                                    )}
                                    {columns[13] && (
                                        <DataTableRow>
                                            <span className='sub-text w-150px'>
                                                Mô tả hợp đồng
                                            </span>
                                        </DataTableRow>
                                    )}
                                    {columns[14] && (
                                        <DataTableRow>
                                            <span className='sub-text w-150px'>
                                                Trạng thái
                                            </span>
                                        </DataTableRow>
                                    )}
                                    <DataTableRow className="nk-tb-col-tools text-end">
                                        <UncontrolledDropdown>
                                            <DropdownToggle
                                                color="tranparent"
                                                className="btn btn-xs btn-outline-light btn-icon dropdown-toggle"
                                            >
                                                <Icon name="plus"></Icon>
                                            </DropdownToggle>
                                            <DropdownMenu end className="dropdown-menu-md">
                                                <ul className="link-tidy sm no-bdr">
                                                    {header1.length > 0 ? (
                                                        header1.map((item, index) => (
                                                            <li key={index}>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="custom-control-input"
                                                                        id={`column-${index}`}
                                                                        checked={columns[index]}
                                                                        onChange={(e) =>
                                                                            setColumns((prev) => {
                                                                                const newColumns = [...prev];
                                                                                newColumns[index] = e.target.checked;
                                                                                return newColumns;
                                                                            })
                                                                        }
                                                                    />
                                                                    <label
                                                                        className="custom-control-label"
                                                                        htmlFor={`column-${index}`}
                                                                    >
                                                                        {item}
                                                                    </label>
                                                                </div>
                                                            </li>
                                                        ))
                                                    ) : (
                                                        <li>No data</li>
                                                    )}
                                                </ul>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </DataTableRow>
                                </DataTableHead>
                                {data.length > 0
                                    ? data.map((item, index) => {
                                          return (
                                              <DataTableItem key={index}>
                                                  {columns[0] && (
                                                      <DataTableRow size=" nk-tb-col-check mb">
                                                          <span className="tb-amount">{index + 1}</span>
                                                      </DataTableRow>
                                                  )}
                                                  <DataTableRow className="nk-tb-col-check">
                                                      <div className="custom-control custom-control-sm custom-checkbox notext">
                                                          <input
                                                              type="checkbox"
                                                              className="custom-control-input"
                                                              defaultChecked={item.checked}
                                                              id={item.id + 'uid1'}
                                                              key={Math.random()}
                                                              onChange={(e) => onSelectChange(e, item.id)}
                                                          />
                                                          <label
                                                              className="custom-control-label"
                                                              htmlFor={item.id + 'uid1'}
                                                          ></label>
                                                      </div>
                                                  </DataTableRow>
                                                  {columns[1] && (
                                                      <DataTableRow>
                                                          <span className="tb-amount">
                                                              {item?.Nhanvien?.hoten ? item?.Nhanvien?.hoten : 'Nav'}
                                                          </span>
                                                      </DataTableRow>
                                                  )}
                                                  {columns[2] && (
                                                      <DataTableRow>
                                                          <span className="tb-amount">{item?.mahopdong}</span>
                                                      </DataTableRow>
                                                  )}
                                                  {columns[3] && (
                                                      <DataTableRow>
                                                          <span className="tb-amount">
                                                              {item?.Loaihopdong?.tenloaihopdong}
                                                          </span>
                                                      </DataTableRow>
                                                  )}
                                                  {columns[4] && (
                                                      <DataTableRow>
                                                          <span className="tb-amount">{item?.Chinhanh?.diachi}</span>
                                                      </DataTableRow>
                                                  )}
                                                  {columns[5] && (
                                                      <DataTableRow>
                                                          <span className="tb-amount">{item?.Phongban?.phongban}</span>
                                                      </DataTableRow>
                                                  )}
                                                  {columns[6] && (
                                                      <DataTableRow>
                                                          <span className="tb-amount">{item?.Chucvu?.chucvu}</span>
                                                      </DataTableRow>
                                                  )}
                                                  {columns[7] && (
                                                      <DataTableRow>
                                                          <span className="tb-amount">
                                                              {item?.Mauhopdong?.mota ? item?.Mauhopdong?.mota : 'NNA'}
                                                          </span>
                                                      </DataTableRow>
                                                  )}
                                                  {columns[8] && (
                                                      <DataTableRow>
                                                          <span className="tb-amount">{item?.User?.username}</span>
                                                      </DataTableRow>
                                                  )}
                                                  {/* {columns[9] && (
                                                    <DataTableRow>
                                                        <span className="tb-amount">{item?.thoigianthuviec} tháng</span>
                                                    </DataTableRow>
                                                )} */}
                                                  {columns[9] && (
                                                      <DataTableRow>
                                                          <span className="tb-amount">
                                                              {item?.ngaytaohopdong
                                                                  ? format(parseISO(item?.ngaytaohopdong), 'dd-MM-yyyy')
                                                                  : 'NAV'}
                                                          </span>
                                                      </DataTableRow>
                                                  )}
                                                  {columns[10] && (
                                                      <DataTableRow>
                                                          <span className="tb-amount">
                                                              {item?.ngaybatdaulamviec
                                                                  ? format(
                                                                        parseISO(item?.ngaybatdaulamviec),
                                                                        'dd-MM-yyyy',
                                                                    )
                                                                  : 'NAV'}
                                                          </span>
                                                      </DataTableRow>
                                                  )}
                                                  {columns[11] && (
                                                      <DataTableRow>
                                                          <span className="tb-amount">
                                                              {item?.ngayhethan
                                                                  ? format(parseISO(item?.ngayhethan), 'dd-MM-yyyy')
                                                                  : 'NAV'}
                                                          </span>
                                                      </DataTableRow>
                                                  )}
                                                  {/* {columns[12] && (
                                                    <DataTableRow>
                                                        <span className="tb-amount">
                                                            {item?.quyettoanthaythue === 0 ? 'Có' : 'Không'}
                                                        </span>
                                                    </DataTableRow>
                                                )} */}
                                                  {columns[12] && (
                                                      <DataTableRow>
                                                          <span className="tb-amount">{item?.diaiemlamviec}</span>
                                                      </DataTableRow>
                                                  )}
                                                  {columns[13] && (
                                                      <DataTableRow>
                                                          <span className="tb-amount">
                                                              {item?.ghichu ? item?.ghichu : 'NAV'}
                                                          </span>
                                                      </DataTableRow>
                                                  )}
                                                  {columns[14] && (
                                                      <DataTableRow>
                                                          <span
                                                              className={`${
                                                                  item?.TrangthaiId === 1 ? 'bg-success' : 'bg-danger'
                                                              } ms-0 badge text-white`}
                                                          >
                                                              {item?.TrangthaiId === 1
                                                                  ? 'Hoạt động'
                                                                  : 'Không Hoạt động'}
                                                          </span>
                                                      </DataTableRow>
                                                  )}

                                                  <DataTableRow className="nk-tb-col-tools">
                                                      <ul className="nk-tb-actions gx-1">
                                                          {+showPhanquyen?.sua === 1 && (
                                                              <li
                                                                  className="nk-tb-action-hidden"
                                                                  onClick={() => handleEditHopdonglaodong(item)}
                                                              >
                                                                  <TooltipComponent
                                                                      tag="a"
                                                                      containerClassName="btn btn-trigger btn-icon"
                                                                      id={'edit' + item.id}
                                                                      icon="edit-alt-fill"
                                                                      direction="top"
                                                                      text="Sửa"
                                                                  />
                                                              </li>
                                                          )}
                                                          {+showPhanquyen?.xoa === 1 && (
                                                              <React.Fragment>
                                                                  <li
                                                                      className="nk-tb-action-hidden"
                                                                      onClick={() => handleDeleteHopdonglaodong(item)}
                                                                  >
                                                                      <TooltipComponent
                                                                          tag="a"
                                                                          containerClassName="btn btn-trigger btn-icon"
                                                                          id={'suspend' + index}
                                                                          icon="user-cross-fill"
                                                                          direction="top"
                                                                          text="Xóa"
                                                                      />
                                                                  </li>
                                                              </React.Fragment>
                                                          )}
                                                          {+showPhanquyen?.xem === 1 && (
                                                              <React.Fragment>
                                                                  <li
                                                                      className="nk-tb-action-hidden"
                                                                      onClick={() => handleDownloadHopdonglaodong(item)}
                                                                  >
                                                                      <TooltipComponent
                                                                          tag="a"
                                                                          containerClassName="btn btn-trigger btn-icon"
                                                                          id={'dow' + index}
                                                                          icon="download"
                                                                          direction="top"
                                                                          text="Download"
                                                                      />
                                                                  </li>
                                                              </React.Fragment>
                                                          )}
                                                          <li>
                                                              <UncontrolledDropdown>
                                                                  {(+showPhanquyen?.sua === 1 ||
                                                                      +showPhanquyen?.xoa === 1) && (
                                                                      <DropdownToggle
                                                                          tag="a"
                                                                          className="dropdown-toggle btn btn-icon btn-trigger"
                                                                      >
                                                                          <Icon name="more-h"></Icon>
                                                                      </DropdownToggle>
                                                                  )}
                                                                  <DropdownMenu className="dropdown-menu" end>
                                                                      <ul className="link-list-opt no-bdr">
                                                                          {+showPhanquyen?.sua === 1 && (
                                                                              <li
                                                                                  onClick={() =>
                                                                                      handleEditHopdonglaodong(item)
                                                                                  }
                                                                              >
                                                                                  <DropdownItem
                                                                                      tag="a"
                                                                                      href="#edit"
                                                                                      onClick={(ev) => {
                                                                                          ev.preventDefault();
                                                                                      }}
                                                                                  >
                                                                                      <Icon name="edit"></Icon>
                                                                                      <span>Sửa</span>
                                                                                  </DropdownItem>
                                                                              </li>
                                                                          )}
                                                                          {+showPhanquyen?.xoa === 1 && (
                                                                              <React.Fragment>
                                                                                  <li className="divider"></li>
                                                                                  <li
                                                                                      onClick={() =>
                                                                                          handleDeleteHopdonglaodong(
                                                                                              item,
                                                                                          )
                                                                                      }
                                                                                  >
                                                                                      <DropdownItem
                                                                                          tag="a"
                                                                                          href="#suspend"
                                                                                          onClick={(ev) => {
                                                                                              ev.preventDefault();
                                                                                          }}
                                                                                      >
                                                                                          <Icon name="na"></Icon>
                                                                                          <span>Xóa</span>
                                                                                      </DropdownItem>
                                                                                  </li>
                                                                              </React.Fragment>
                                                                          )}
                                                                          {+showPhanquyen?.xem === 1 && (
                                                                              <React.Fragment>
                                                                                  <li className="divider"></li>
                                                                                  <li
                                                                                      onClick={() =>
                                                                                          handleDownloadHopdonglaodong(
                                                                                              item,
                                                                                          )
                                                                                      }
                                                                                  >
                                                                                      <DropdownItem
                                                                                          tag="a"
                                                                                          href="#suspend"
                                                                                          onClick={(ev) => {
                                                                                              ev.preventDefault();
                                                                                          }}
                                                                                      >
                                                                                          <Icon name="download"></Icon>
                                                                                          <span>Download</span>
                                                                                      </DropdownItem>
                                                                                  </li>
                                                                              </React.Fragment>
                                                                          )}
                                                                      </ul>
                                                                  </DropdownMenu>
                                                              </UncontrolledDropdown>
                                                          </li>
                                                      </ul>
                                                  </DataTableRow>
                                              </DataTableItem>
                                          );
                                      })
                                    : null}
                            </DataTableBody>
                        </div>
                        <div className="card-inner">
                            {data.length > 0 ? (
                                // data.length > 10 ? (
                                // <PaginationCount posts={hopdonglaodong} count={count} limit={itemPerPage} />
                                //   ) : null
                                <>
                                    {data.length > itemPerPage && (
                                        <PaginationCount posts={hopdonglaodong} count={count} limit={itemPerPage} />
                                    )}
                                </>
                            ) : (
                                <div className="text-center">
                                    <div>
                                        <Spinner color="primary" />
                                    </div>
                                    <span className="text-silent">Chưa có dữ liệu!</span>
                                </div>
                            )}
                        </div>
                    </DataTable>
                </Block>

                <ModalHopdonglaodong show={show} fieldList={fieldList} setShow={() => setShow(false)} />
                <ModalEditHopdonglaodong
                    show={showEditHopdonglaodong}
                    fieldList={fieldList}
                    setShow={() => setShowEditHopdonglaodong(false)}
                    dataIdHopdonglaodong={dataIdHopdonglaodong}
                />
                <ModalDownloadHopdonglaodong
                    show={showDownloadHopdonglaodong}
                    setShow={() => setShowDownloadHopdonglaodong(false)}
                    setDataHopdonglaodong={setDataHopdonglaodong}
                    dataHopdonglaodong={dataHopdonglaodong}
                />
                <Modal isOpen={modal} toggle={() => setModal(false)} className="modal-dialog-centered" size="lg">
                    <ModalBody>
                        <Icon name="cross-sm float-end fs-2" onClick={() => setModal(false)}></Icon>
                        <div className="p-2">
                            <h5 className="title">Nhập vào hợp đồng nhân viên</h5>
                            <div className="mt-4">
                                <p>
                                    Bạn có thể nhập liệu hợp đồng cho hàng loạt nhân viên từ file Excel. Hãy thao tác
                                    theo các bước sau:
                                </p>
                                <h6 className="title mt-5">
                                    Bước 1 - Chọn nhóm nhân viên ( Nếu bạn muốn nhập liệu tất cả nhân viên, hãy bỏ qua
                                    bước này. )
                                </h6>
                                <div className="form-group mt-3">
                                    <div className="custom-control custom-switch">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="selectnhanvien"
                                            name="selectnhanvien"
                                            onChange={(e) =>
                                                setDataSelectMau((prev) => ({
                                                    ...prev,
                                                    ['selectnhanvien']: e.target.checked,
                                                }))
                                            }
                                        />
                                        <label className="custom-control-label" htmlFor="selectnhanvien">
                                            Chọn theo từng nhân viên{' '}
                                        </label>
                                    </div>
                                </div>
                                {dataSelectMau.selectnhanvien === false ? (
                                    <React.Fragment>
                                        <div className="form-group">
                                            <label className="form-label">Chi nhánh </label>
                                            <RSelect
                                                value={dataSelectMau.chinhanh}
                                                onChange={handleOnSelect}
                                                name="chinhanh"
                                                options={dataChinhanh}
                                                isMulti
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">Phòng ban</label>
                                            <RSelect
                                                value={dataSelectMau.phongban}
                                                onChange={handleOnSelect}
                                                name="phongban"
                                                options={dataPhongban}
                                                isMulti
                                            />
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    <div className="form-group">
                                        <label className="form-label">Nhân viên</label>
                                        <RSelect
                                            value={dataSelectMau.nhanvien}
                                            onChange={handleOnSelect}
                                            name="nhanvien"
                                            options={dataNhanvien}
                                            isMulti
                                        />
                                    </div>
                                )}
                                <h6 className="title mt-5 mb-3">Bước 2 - Tải về file mẫu</h6>
                                <ExportXlsx
                                    dataFileMau={dataFileMau}
                                    header={header}
                                    namefile={namefile}
                                    btnname={'Tải về file mẫu'}
                                    dataExport={dataExport}
                                />
                                <h6 className="title mt-5">Bước 3 - Tải lên file dữ liệu</h6>
                                <ImportXlsx dataExport={dataExport} type={'hopdonglaodong'} />
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </Content>
        </React.Fragment>
    );
};

export default Hopdonglaodong;
