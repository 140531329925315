import React, {useState, useEffect} from 'react'
import {InputForm, Button} from '~/components/Form'
import '../Modal.scss';
import Select from 'react-select';
import Textarea from "~/components/Form/Textarea";
import {dataSelectActive, dataSelectConfirm} from "~/utils/Datacustom";
import {apiEditCauhinhchuyencan} from "~/services";
import {toast} from "react-toastify";
import {validateCauHinhChuyenCan} from "~/request/validateCauHinhChuyenCan";
import Content from "~/layout/content/Content";
import {Modal, ModalBody} from 'reactstrap';
import Icon from "~/components/icon/Icon";
import {SelectFinNhanvien} from "~/components/Component";
import Swal from "sweetalert2";

const ModalEditChuyenCan = ({show, setShow, dataEdit, fieldList}) => {
    // console.log('dataEdit', dataEdit)
    const [invalidFields, setInvalidFields] = useState([])
    const [payload, setPayload] = useState(fieldList)
    const [showSelect, setShowSelect] = useState(false);
    const [listNhanvien, setlistNhanvien] = useState([]);
    const [usedWorked, setUsedWorked] = useState(true);
    
    useEffect(() => {
        let status = dataSelectActive?.find((item) => {
            return item && item.value === dataEdit?.status;
        });
        let is_Work = dataSelectConfirm?.find((item) => {
                return item && item.value === dataEdit?.is_Work;
            });
        let is_Vacation = dataSelectConfirm?.find((item) => {
                return item && item.value === dataEdit?.is_Vacation;
            });
        let is_Holiday = dataSelectConfirm?.find((item) => {
                return item && item.value === dataEdit?.is_Holiday;
            });
        let is_Law = dataSelectConfirm?.find((item) => {
                return item && item.value === dataEdit?.is_Law;
            });
        let is_Unpaid = dataSelectConfirm?.find((item) => {
                return item && item.value === dataEdit?.is_Unpaid;
            });
        show && dataEdit && setPayload({
            name: dataEdit.name,
            description: dataEdit.description,
            totalDayCalcu: dataEdit.totalDayCalcu,
            calculatorCondition: dataEdit.calculatorCondition,
            giatri: dataEdit.giatri,
            totalWorkDay: dataEdit.totalWorkDay,
            status: {label: status?.label, value: status?.value},
            is_Work: {label: is_Work.label, value: is_Work.value},
            is_Vacation: {label: is_Vacation.label, value: is_Vacation.value},
            is_Holiday: {label: is_Holiday.label, value: is_Holiday.value},
            is_Law: {label: is_Law.label, value: is_Law.value},
            is_Unpaid: {label: is_Unpaid.label, value: is_Unpaid.value},
        })
        setlistNhanvien(
            dataEdit?.Nhanviens?.map((item) => ({
                value: item?.id,
                label: item?.hoten,
            })),
        );
        setUsedWorked(dataEdit?.is_Work)
    }, [dataEdit, show])
    useEffect(() => {
        setPayload((prev) => ({
            ...prev,
            ['NhanvienId']: listNhanvien,
        }));
    }, [listNhanvien]);
    function generateChecklist(check) {
        let checklist = '';
        
        for (let i = 1; i <= check; i++) {
            checklist += `xx${'.'.repeat(1)}`;
        }
        if (checklist.endsWith('.')) {
            checklist = checklist.slice(0, -1);
        }
        return checklist;
    }
    const handleSubmit = async () => {
        const totalWorkDay = Number(payload.totalWorkDay)
        const totalDayCalcu = Number(payload.totalDayCalcu)
        const dataCalculator = payload.calculatorCondition
        const calculatorCondition = dataCalculator?.split('|'); // Dieu kien tinh toan chuyen can
        let conditionCalculator = calculatorCondition.map(item => {
            const [index, value] = item.split('-');
            if (index.includes('(') && index.includes(')')) {
                const indices = index.replace('(', '').replace(')', '').split(',');
                return indices.reduce((acc, i) => {
                    acc[Number(i)] = Number(value);
                    return acc;
                }, {});
            } else {
                return { [Number(index)]: Number(value) };
            }
        }).reduce((acc, cur) => ({ ...acc, ...cur }), {});
       

        if (payload?.is_Work?.value === 0) {
           if(conditionCalculator[totalDayCalcu - totalWorkDay] === undefined) {
                const checkList = (totalDayCalcu-totalWorkDay) || 0
                return await Swal.fire({
                    title: 'Kiểm tra lại điều kiện tính toán chuyên cần '+generateChecklist(checkList)+' với xx là các gi trị giảm theo ngày nghỉ',
                    text: 'Số ngày tối đa =< tối thiểu',
                    icon: 'danger',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Đóng !!!',
                    confirmButtonText: 'Tôi xem lại!',
                })
            }
        }
        let invalids = validate(payload)
        
        if (invalids === 0) {
            let datapayload =  {
                data: [
                    {
                        id: dataEdit.id,
                        name: payload.name,
                        description: payload.description,
                        totalDayCalcu: payload.totalDayCalcu,
                        calculatorCondition: payload.calculatorCondition,
                        giatri: payload.giatri,
                        totalWorkDay: payload.totalWorkDay,
                        status: payload.status.value,
                        is_Work: payload.is_Work.value,
                        is_Vacation: payload.is_Vacation.value,
                        is_Holiday: payload.is_Holiday.value,
                        is_Law: payload.is_Law.value,
                        is_Unpaid: payload.is_Unpaid.value,
                        NhanvienId: listNhanvien,
                    }
                ]
            }
            const result = await apiEditCauhinhchuyencan(datapayload)
            if (result.data.success === 200) {
                toast.success(result.data.message)
                setShow(false)
            } else {
                toast.error("Có lỗi xảy ra!")
            }
        }
    }
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        if (statename === 'is_Work' && selected.value === 1){
            setUsedWorked(true);
        } else {
            setUsedWorked(false);
        }
        setPayload(prev => ({...prev, [statename]: selected}))
    }
    const validate = (payload) => {
        let invalids = 0
        let fields = Object.entries(payload)
        
        fields.forEach(item => {
            if (item[0] === 'is_Work' && item[1].value === 0){
                // Kho check validation  item[0] === 'totalWorkDay'
                if (item[0] !== 'totalWorkDay' && validateCauHinhChuyenCan(item[0], item[1]) !== undefined) {
                    setInvalidFields(prev => [...prev, validateCauHinhChuyenCan(item[0], item[1])])
                    invalids++
                }
            } else{
                if (validateCauHinhChuyenCan(item[0], item[1]) !== undefined) {
                    setInvalidFields(prev => [...prev, validateCauHinhChuyenCan(item[0], item[1])])
                    invalids++
                }
            }
            
        })
        return invalids
    }
    return (
        <React.Fragment>
            <Content>
                <Modal isOpen={show} toggle={setShow} className="modal-dialog-centered modal-90w">
                    <ModalBody>
                        <Icon name="cross-sm float-end fs-2" onClick={setShow}></Icon>
                        <h5 className="title mb-5"> Cập nhật cấu hình chuyên cần</h5>
                        <div className="row">
                            <div className="form-group col-md-12">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Tên cấu hình'}
                                    value={payload.name}
                                    setValue={setPayload}
                                    keyPayload={'name'}
                                    type='text'
                                    validate
                                />
                            </div>
                            <div className="form-group col-md-12 mb-2">
                                <Textarea
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Mô tả'}
                                    value={payload.description}
                                    setValue={setPayload}
                                    keyPayload={'description'}
                                    type="text"
                                    validate
                                />
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="inputZip" className="mb-2 fw-bold">Trạng thái<b
                                    className="text-danger">(*)</b></label>
                                <Select
                                    value={payload.status}
                                    onChange={handleOnSelect}
                                    options={dataSelectActive}
                                    placeholder={'Trạng thái'}
                                    name='status'
                                    onFocus={() => setInvalidFields([])}
                                />
                                {invalidFields.length > 0 && invalidFields.some(i => i.name === 'status') && <small
                                    className='text-danger'>{invalidFields.find(i => i.name === 'status')?.message}</small>}
                            </div>
                            
                            <div className="form-group col-md-4">
                                <label htmlFor="inputZip" className="mb-2 fw-bold">Đi làm đủ 100%<b
                                    className="text-danger">(*)</b></label>
                                <Select
                                    value={payload.is_Work}
                                    onChange={handleOnSelect}
                                    options={dataSelectConfirm}
                                    placeholder={'Không áp dụng'}
                                    name='is_Work'
                                    onFocus={() => setInvalidFields([])}
                                />
                                {invalidFields.length > 0 && invalidFields.some(i => i.name === 'is_Work') && <small
                                    className='text-danger'>{invalidFields.find(i => i.name === 'is_Work')?.message}</small>}
                            </div>
                            <div className="form-group col-md-4">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Số ngày công được hưởng 100% chuyên cần'}
                                    value={payload.totalDayCalcu}
                                    setValue={setPayload}
                                    keyPayload={'totalDayCalcu'}
                                    type='text'
                                    validate
                                />
                            </div>
                            <div className="form-group col-md-4">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Số ngày công không tính chuyên cần'}
                                    value={payload.totalWorkDay}
                                    setValue={setPayload}
                                    keyPayload={'totalWorkDay'}
                                    disabled={usedWorked}
                                    type='text'
                                />
                            </div>
                            <div className="form-group col-md-4">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Tỷ lệ tính chuyên cần'}
                                    placeholder={"VD: 100.50.0: Đủ: 100%, Nghỉ 1 buổi còn 50%, lớn hơn 2 buổi 0% chuyên cần"}
                                    value={payload.calculatorCondition}
                                    setValue={setPayload}
                                    keyPayload={'calculatorCondition'}
                                    disabled={usedWorked}
                                    type='text'
                                />
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="inputZip" className="mb-2 fw-bold"> Có bao gồm nghỉ phép không ?</label>
                                <Select
                                    value={payload.is_Vacation}
                                    onChange={handleOnSelect}
                                    options={dataSelectConfirm}
                                    placeholder={'Không áp dụng'}
                                    name='is_Vacation'
                                    isDisabled={usedWorked}
                                    onFocus={() => setInvalidFields([])}
                                />
                                {invalidFields.length > 0 && invalidFields.some(i => i.name === 'is_Vacation') && <small
                                    className='text-danger'>{invalidFields.find(i => i.name === 'is_Vacation')?.message}</small>}
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="inputZip" className="mb-2 fw-bold"> Có bao gồm nghỉ lễ không ?</label>
                                <Select
                                    value={payload.is_Holiday}
                                    onChange={handleOnSelect}
                                    options={dataSelectConfirm}
                                    placeholder={'Không áp dụng'}
                                    name='is_Holiday'
                                    isDisabled={usedWorked}
                                    onFocus={() => setInvalidFields([])}
                                />
                                {invalidFields.length > 0 && invalidFields.some(i => i.name === 'is_Holiday') && <small
                                    className='text-danger'>{invalidFields.find(i => i.name === 'is_Holiday')?.message}</small>}
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="inputZip" className="mb-2 fw-bold"> Có bao gồm nghỉ theo luật không
                                    ?</label>
                                <Select
                                    value={payload.is_Law}
                                    onChange={handleOnSelect}
                                    options={dataSelectConfirm}
                                    placeholder={'Không áp dụng'}
                                    name='is_Law'
                                    isDisabled={usedWorked}
                                    onFocus={() => setInvalidFields([])}
                                />
                                {invalidFields.length > 0 && invalidFields.some(i => i.name === 'is_Law') && <small
                                    className='text-danger'>{invalidFields.find(i => i.name === 'is_Law')?.message}</small>}
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="inputZip" className="mb-2 fw-bold">Có bao gồm nghỉ không lương không
                                    ?</label>
                                <Select
                                    value={payload.is_Unpaid}
                                    onChange={handleOnSelect}
                                    options={dataSelectConfirm}
                                    placeholder={'Không áp dụng'}
                                    name='is_Unpaid'
                                    isDisabled={usedWorked}
                                    onFocus={() => setInvalidFields([])}
                                />
                                {invalidFields.length > 0 && invalidFields.some(i => i.name === 'is_Unpaid') && <small
                                    className='text-danger'>{invalidFields.find(i => i.name === 'is_Unpaid')?.message}</small>}
                            </div>
                            <div className="row">
                                <div className="form-group col-md-12" onClick={() => setShowSelect(true)}>
                                    <label htmlFor="inputZip" className="mb-2 fw-bold">
                                        Chọn Nhân Viên <b className="text-danger">(*)</b>
                                    </label>
                                    <Select
                                        value={payload.NhanvienId}
                                        name="NhanvienId"
                                        isMulti
                                        onFocus={() => setInvalidFields([])}
                                    />
                                    {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'NhanvienId') && (
                                        <small className="text-danger">
                                            {invalidFields.find((i) => i.name === 'NhanvienId')?.message}
                                        </small>
                                    )}
                                </div>
                            </div>
                        </div>
                        
                        <div className='col-12 mt-5'>
                            <Button
                                text='Cập nhật'
                                bClass='btn btn-primary px-5'
                                textColor
                                onClick={handleSubmit}
                            />
                        </div>
                    </ModalBody>
                </Modal>
            </Content>
            <SelectFinNhanvien
                showSelect={showSelect}
                typeSelect={true}
                listNhanvien={listNhanvien}
                cauHinhId={dataEdit?.id}
                setlistNhanvien={setlistNhanvien}
                typeSetting={'chuyencan'} // 'chuyencan' || 'thamnien' || 'congthuc' || 'hoatdongsukien'
                setShowSelect={() => setShowSelect(false)}
            />
        
        
        </React.Fragment>
    );
}

export default ModalEditChuyenCan;

