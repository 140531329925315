import React, {useEffect, useMemo, useState} from 'react';
import {dataPagination, dataStatusNghiphep} from '~/utils/Datacustom';
import {ModalPhieutangca, ModalEditPhieutangca} from '../Admin/Modal';
import Swal from 'sweetalert2';
import {Card} from 'reactstrap';
import Content from '~/layout/content/Content';
import {Block} from '~/components/block/Block';
import {
    Button,
    DataTable,
    Icon,
    PermissionUtils,
} from '~/components/Component';
import {format, parseISO} from 'date-fns';
import {MenuTangca} from './MenuTab/Menucontent';
import {Box, IconButton, Tooltip} from "@mui/material";
import {ExportToCsv} from "export-to-csv";
import {toast} from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {MaterialReactTable, useMaterialReactTable} from "material-react-table";
import {MRT_Localization_VI} from "material-react-table/locales/vi";
import {
    apiDeletePhieutangca,
    apiGetPhieutangca,
} from "~/services";
import {useSearchParams, useNavigate, useLocation} from 'react-router-dom';

const Phieutangca = () => {
    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    //lấy giá trị từ thằng redux trả về
    const [data, setData] = useState([]);
    const [dataTangCaById, setdataTangCaById] = useState([]);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const fieldList = {
        maphieu: '',
        CauhinhtangcaId: '',
        ngaybatdau: '',
        ngayketthuc: '',
        giobatdau: '',
        gioketthuc: '',
        batdaugiovaodehieuca: '',
        ketthucgiovaodehieuca: '',
        batdaugioradehieuca: '',
        ketthucgioradehieuca: '',
        tonggio: '',
        nguoiduyet: '',
        TrangthaiId: '',
        tangcaitems: '',
        NhanvienId: '',
    };
    
    const [showPhanquyen, setShowPhanquyen] = useState({});
    let dataPermissionUtils = PermissionUtils('phieutangca', 'all');
    useEffect(() => {
        if (dataPermissionUtils) {
            setShowPhanquyen(dataPermissionUtils);
        }
    }, [dataPermissionUtils]);
    
    const fetchData = async () => {
        try {
            const datapayloads = {
                typeGet: 1, // 1: lấy theo điều kiện phân trang, 2: lấy tất cả và không cần truyền d liệu phân trang
                page: Number(pagination.pageIndex) || 0,
                limit: Number(pagination.pageSize) || 10,
                by: 'id',
                order: 'desc',
                daychuyen: 0
            };
            const response = await apiGetPhieutangca(datapayloads)
            // console.log('response', response)

            if (response?.data?.success === 200) {
                setData(response?.data?.data);
                setTotalRowCount(response?.data?.count);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    
    
    useEffect(() => {
        fetchData().then(r => r)
    }, [pagination.pageIndex, pagination.pageSize, show, showEdit]);
    const handleEdit = async (data) => {
        setShowEdit(true);
        await setdataTangCaById(data);
    };
    const handleAddNew = async () => {
        setShow(true);
    };
    const handleDelete = (data) => {
        Swal.fire({
            title: 'Bạn có chắc không?',
            text: 'Bạn sẽ không thể lấy lại khi xóa!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Không, bỏ qua!',
            confirmButtonText: 'Vâng, xóa nó đi!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                console.log('datadatadata', data)
                let newdate = new Date();
                let enddate = new Date(data.date);
                if(newdate > enddate){
                    toast.error('Đã quá ngày để hủy đơn!');
                }else{
                    const response = await apiDeletePhieutangca(data);
                    // console.log('chek api apiDeleteCongty', response?.data.success)
                    if (response?.data.success === 200) {
                        fetchData().then(r => 'err')
                        toast.success('Xóa thông tin phiếu tăng ca thành công!');
                    } else {
                        toast.error('Xóa thông tin phiếu tăng ca thất bại!');
                    }
                }
                
            }
        });
    };

        // Phần url thông báo
        const navigate = useNavigate();
        const location = useLocation();
    const [searchParams] = useSearchParams();
    useEffect(() => {
        if(searchParams.get('thong-bao')){
            const fetchData = async () => {
                try {
                    const getId = await apiGetPhieutangca({id: searchParams.get('thong-bao')});
                    if(getId?.data?.data?.length > 0){
                        handleEdit(getId?.data?.data[0]);
                        navigate(location.pathname)
                    }
                } catch (error) {
                }
            };
            fetchData().catch(console.error);
        }
    }, [searchParams.get('thong-bao'), searchParams]);
    
    // console.log(data)
    const Example = () => {
        
        let dataAb = data.length > 0 ? data : [];
        // Tạo dữ liêu export
        const typeFormat = 'dd/MM/yyyy'
        const FormatDate = (cell, type) => ( // Format Date : type = 'dd/MM/yyyy hh:mm', date = cell
            <Box>
                {format(parseISO(cell), type)}
            </Box>
        );
        const Status = (cell) => { // Function show trạng thái
            return (<Box
                component="span"
                sx={(theme) => ({
                    backgroundColor: cell.TrangthaiId === 3 ? '#1ee0ac' : '#e65100',
                    fontSize: '10px',
                    borderRadius: '0.25rem',
                    color: '#fff',
                    p: '0.25rem',
                })}
            >
                {dataStatusNghiphep.find(select => select.value === cell.TrangthaiId)?.label || dataStatusNghiphep[0].label}
            </Box>)

        };
        
        const columns = useMemo(
            () => [
                {
                    Header: '#', // Tiêu đề cột số thứ tự
                    header: 'STT', // Tiêu đề cột số thứ tự
                    id: 'rowNumber', // ID của cột
                    size: 50,
                    Cell: ({row}) => <div>{row?.index + 1}</div>, // Giá trị của cột là số thứ tự
                    enableSorting: false,
                    enablePinning: true,
                },
                {
                    accessorKey: 'maphieu',
                    enablePinning: true,
                    header: 'Mã phiếu',
                },
                {
                    accessorKey: 'createdAt',
                    header: 'Ngày tạo',
                    enablePinning: true,
                    accessorFn: (row) => <p>{FormatDate(`${row?.createdAt}`, typeFormat)}</p>,
                },
                {
                    accessorKey: 'ngaybatdau',
                    header: 'Ngày tăng ca',
                    enablePinning: true,
                    accessorFn: (row) => <p>{FormatDate(`${row?.ngaybatdau}`, typeFormat)}</p>,
                },
                {
                    accessorKey: 'TrangthaiId',
                    header: 'Trạng thái',
                    enablePinning: true,
                    Cell: ({row}) => Status(row.original),
                    
                },
            
            ],
            [],
        );
        const csvOptions = {
            fileName: 'csv-data',
            fieldSeparator: ',',
            quoteStrings: '"',
            filename: 'phieu-tang-ca',
            decimalSeparator: '.',
            showLabels: true,
            useBom: true,
            useKeysAsHeaders: false,
            headers: columns.map((c) => c.header),
        };
        
        
        const csvExporter = new ExportToCsv(csvOptions);
        
        const handleExportData = () => {
            const dataExport = dataAb?.map((item, indexx) => {
                let abc = {}
                abc.rowNumber = indexx + 1
                abc.maphieu = item?.maphieu
                abc.mota = item?.mota
                abc.createdAt = item?.createdAt
                abc.ngaybatdau = item?.ngaybatdau
                abc.ngayketthuc = item?.ngayketthuc
                abc.giobatdau = item?.giobatdau
                abc.gioketthuc = item?.gioketthuc
                abc.thoidiemlamthem = item?.thoidiemlamthem
                abc.nguoiduyet = item?.nguoiduyet
                abc.totalPhieu = Number(item?.Quanlytangcas?.length * Number(item?.tonggio))
                abc.TrangthaiId = item?.TrangthaiId
                
                return abc
            })
            if (dataExport.length === 0) {
                toast.error("Không có dữ liệu để xuất!")
                return;
            }
            csvExporter.generateCsv(dataExport);
        };
        const rowActions = ({row, table}) => ( // Thêm nút sửa xóa vào hàng
            <Box sx={{display: 'flex', gap: '1rem'}}>
                {+showPhanquyen?.sua === 1 && (
                    <Tooltip title="Edit">
                        <IconButton onClick={() => handleEdit(row.original)}>
                            <EditIcon/>
                        </IconButton>
                    </Tooltip>
                )}
                {+showPhanquyen?.sua === 1 && (
                    <Tooltip title="Delete">
                        <IconButton color="error" onClick={() => handleDelete({
                            id: row.original.id,
                            status: row.original.TrangthaiId,
                            date: row.original.ngaybatdau,
                        })}>
                            <DeleteIcon/>
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
        );
        const topToolbarCustomActions = ({table}) => ( // Thêm nút thêm mới và xuất dữ liệu
            <Box sx={{display: 'flex', gap: '1rem'}}>
                {+showPhanquyen?.them === 1 && (
                    <>
                        <Button variant="contained" color="primary" onClick={handleAddNew} sx={{mr: 2}}>
                            + Thêm mới
                        </Button>
                        <Button color="primary" onClick={handleExportData} variant="contained">
                            <Icon name="download-cloud me-2"></Icon> Xuất dữ liệu
                        </Button>
                    </>
                )}
            </Box>
        );
        const state = { // Trạng thái của bảng
            expanded: true,
            columnFilters,
            globalFilter,
            isLoading: dataAb?.length === 0,
            pagination,
            sorting,
        };
        const displayColumnDefOptions = { // Các cột hiển thị
            'mrt-row-actions': {
                sx: {width: '200px', paddingRight: '10px'},
                header: 'Thao tác',
                enableOrdering: true,
                enablePinning: true,
                enableColumnActions: true,
            }
        };
        const initialState = { // Các trạng thái ban đầu của bảng đc gim
            columnPinning: {left: ['rowNumber', 'maphieu', 'createdAt'], right: ['mrt-row-actions']},
            density: 'compact'
        };
        const table = useMaterialReactTable({
                columns,
                data,
                enableColumnResizing: false,
                enableDensityToggle: false,
                initialState: initialState,
                displayColumnDefOptions: displayColumnDefOptions,
                state: state,
                defaultDisplayColumn: {enableResizing: true},
                rowCount: totalRowCount,
                manualFiltering: true,
                manualPagination: true,
                manualSorting: true,
                onPaginationChange: setPagination,
                onColumnFiltersChange: setColumnFilters,
                onGlobalFilterChange: setGlobalFilter,
                onSortingChange: setSorting,
                localization: MRT_Localization_VI,
                enableRowActions: true,
                enableColumnPinning: true,
                renderTopToolbarCustomActions: topToolbarCustomActions,
                renderRowActions: rowActions,
                muiPaginationProps: {
                    rowsPerPageOptions: dataPagination,
                },
            }
        );
        
        
        return (
            <MaterialReactTable
                table={table}
            />
        );
    };
    
    return (
        <React.Fragment>
            {/*<Head title="User List - Regular"></Head>*/}
            <Content>
                <Card className="mt-0">
                    <div className="card-aside-wrap row">
                        <div className="card-inner card-inner-lg px-4 pt-1 pb-0">
                            <h4>Quản lý phiếu tăng ca</h4>
                            <MenuTangca/>
                        
                        </div>
                    </div>
                </Card>
                <Block>
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner position-relative card-tools-toggle">
                            <Example/>
                        </div>
                    </DataTable>
                </Block>
                <ModalPhieutangca
                    show={show}
                    fieldList={fieldList}
                    setShow={() => setShow(false)}
                />
                <ModalEditPhieutangca
                    show={showEdit}
                    fieldList={fieldList}
                    setShow={() => setShowEdit(false)}
                    dataTangCaById={dataTangCaById}
                />
            </Content>
        </React.Fragment>
    );
};
export default Phieutangca;
