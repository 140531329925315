import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '~/store/actions';
import { validateBangChamCong } from '~/request/validateBangChamCong';
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import {
    Button,
    Icon,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    PaginationComponent,
} from '~/components/Component';
import { Spinner, Card } from 'reactstrap';
import { Link } from 'react-router-dom';
import config from '~/config';
import { format, parseISO } from 'date-fns';

const ModalLog = ({ show, setShow, dataLog }) => {
    const dispatch = useDispatch();
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayload] = useState([]);
    // console.log('dataChamCong', dataChamCong);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);

    // Get current list, pagination
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItems = dataLog ? dataLog.slice(indexOfFirstItem, indexOfLastItem) : [];
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">Khắc phục các lỗi sau</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <DataTable className="card-stretch border-0">
                        <div style={{ overflowY: 'hidden' }}>
                            <DataTableBody>
                                <DataTableHead className="col-12 overflow-scroll">
                                    <DataTableRow>
                                        <span className="sub-text">Stt</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text w-150px">Họ tên</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text w-80px">Ngày</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text w-200px">Sự cố</span>
                                    </DataTableRow>
                                    <DataTableRow></DataTableRow>
                                </DataTableHead>
                                {dataLog?.length > 0
                                    ? dataLog.map((item, index) => {
                                          return (
                                              <DataTableItem key={item.id}>
                                                  <DataTableRow size=" nk-tb-col-check mb">
                                                      <span className="tb-amount">{index + 1}</span>
                                                  </DataTableRow>
                                                  <DataTableRow>
                                                      <span className="tb-amount">
                                                        {item?.hoten}
                                                      </span>
                                                  </DataTableRow>
                                                  <DataTableRow>
                                                      <span className="tb-amount">
                                                        {item?.ngay ? format(parseISO(item?.ngay), 'dd-MM-yyyy') : ''}
                                                      </span>
                                                  </DataTableRow>
                                                  <DataTableRow>
                                                      <span className="tb-amount text-danger">
                                                        {item?.log}
                                                      </span>
                                                  </DataTableRow>
                                                  <DataTableRow className="nk-tb-col-tools ">
                                                      <ul className="nk-tb-actions ">
                                                          <Link
                                                              to={`${
                                                                   config.he_thong + item?.url
                                                              }?thong-bao=${item?.id}&xem=${Math.floor(
                                                                  Math.random() * 9999999,
                                                              )}`}
                                                              className="link link-sm"
                                                          >
                                                              <button
                                                                //   onClick={() =>
                                                                //       hendEditThongbao(item?.id, dispatch, item?.UserId)
                                                                //   }
                                                                  className={
                                                                      'btn btn-primary ms-0 badge text-white p-1'
                                                                  }
                                                              >
                                                                  <Icon name="eye-fill"></Icon>
                                                              </button>
                                                          </Link>
                                                      </ul>
                                                  </DataTableRow>
                                              </DataTableItem>
                                          );
                                      })
                                    : null}
                            </DataTableBody>
                        </div>
                        <div className="card-inner">
                            {currentItems.length > 0 ? (
                                <PaginationComponent
                                    itemPerPage={itemPerPage}
                                    totalItems={dataLog.length}
                                    paginate={paginate}
                                    currentPage={currentPage}
                                />
                            ) : (
                                <div className="text-center">
                                    <div>
                                        <Spinner color="primary" />
                                    </div>
                                    <span className="text-silent">Chưa có dữ liệu!</span>
                                </div>
                            )}
                        </div>
                    </DataTable>
               
            </Modal.Body>
        </Modal>
    );
};

export default ModalLog;
