/* eslint-disable no-const-assign */
/* eslint-disable no-unused-vars */

const jdFromDate = (dd, mm, yy) => {
    var a, y, m, jd;
    a = INT((14 - mm) / 12);
    y = yy + 4800 - a;
    m = mm + 12 * a - 3;
    jd = dd + INT((153 * m + 2) / 5) + 365 * y + INT(y / 4) - INT(y / 100) + INT(y / 400) - 32045;
    if (jd < 2299161) {
        jd = dd + INT((153 * m + 2) / 5) + 365 * y + INT(y / 4) - 32083;
    }
    return jd;
};

const getNewMoonDay = (k, timeZone) => {
    var T, T2, T3, dr, Jd1, M, Mpr, F, C1, deltat, JdNew;
    T = k / 1236.85;
    T2 = T * T;
    T3 = T2 * T;
    dr = PI / 180;
    Jd1 = 2415020.75933 + 29.53058868 * k + 0.0001178 * T2 - 0.000000155 * T3;
    Jd1 = Jd1 + 0.00033 * Math.sin((166.56 + 132.87 * T - 0.009173 * T2) * dr);
    M = 359.2242 + 29.10535608 * k - 0.0000333 * T2 - 0.00000347 * T3;
    Mpr = 306.0253 + 385.81691806 * k + 0.0107306 * T2 + 0.00001236 * T3;
    F = 21.2964 + 390.67050646 * k - 0.0016528 * T2 - 0.00000239 * T3;
    C1 = (0.1734 - 0.000393 * T) * Math.sin(M * dr) + 0.0021 * Math.sin(2 * dr * M);
    C1 = C1 - 0.4068 * Math.sin(Mpr * dr) + 0.0161 * Math.sin(dr * 2 * Mpr);
    C1 = C1 - 0.0004 * Math.sin(dr * 3 * Mpr);
    C1 = C1 + 0.0104 * Math.sin(dr * 2 * F) - 0.0051 * Math.sin(dr * (M + Mpr));
    C1 = C1 - 0.0074 * Math.sin(dr * (M - Mpr)) + 0.0004 * Math.sin(dr * (2 * F + M));
    C1 = C1 - 0.0004 * Math.sin(dr * (2 * F - M)) - 0.0006 * Math.sin(dr * (2 * F + Mpr));
    C1 = C1 + 0.001 * Math.sin(dr * (2 * F - Mpr)) + 0.0005 * Math.sin(dr * (2 * Mpr + M));
    if (T < -11) {
        deltat = 0.001 + 0.000839 * T + 0.0002261 * T2 - 0.00000845 * T3 - 0.000000081 * T * T3;
    } else {
        deltat = -0.000278 + 0.000265 * T + 0.000262 * T2;
    }
    JdNew = Jd1 + C1 - deltat;
    return INT(JdNew + 0.5 + timeZone / 24);
};

const getSunLongitude = (jdn, timeZone) => {
    var T, T2, dr, M, L0, DL, L;
    T = (jdn - 2451545.5 - timeZone / 24) / 36525;
    T2 = T * T;
    dr = PI / 180;
    M = 357.5291 + 35999.0503 * T - 0.0001559 * T2 - 0.00000048 * T * T2;
    L0 = 280.46645 + 36000.76983 * T + 0.0003032 * T2;
    DL = (1.9146 - 0.004817 * T - 0.000014 * T2) * Math.sin(dr * M);
    DL = DL + (0.019993 - 0.000101 * T) * Math.sin(dr * 2 * M) + 0.00029 * Math.sin(dr * 3 * M);
    L = L0 + DL;
    L = L * dr;
    L = L - PI * 2 * INT(L / (PI * 2));
    return INT((L / PI) * 6);
};

const getLunarMonth11 = (yy, timeZone) => {
    var k, off, nm, sunLong;
    off = jdFromDate(31, 12, yy) - 2415021;
    k = INT(off / 29.530588853);
    nm = getNewMoonDay(k, timeZone);
    sunLong = getSunLongitude(nm, timeZone);
    if (sunLong >= 9) {
        nm = getNewMoonDay(k - 1, timeZone);
    }
    return nm;
};

const getLeapMonthOffset = (a11, timeZone) => {
    var k, last, arc, i;
    k = INT((a11 - 2415021.076998695) / 29.530588853 + 0.5);
    last = 0;
    i = 1;
    arc = getSunLongitude(getNewMoonDay(k + i, timeZone), timeZone);
    do {
        last = arc;
        i++;
        arc = getSunLongitude(getNewMoonDay(k + i, timeZone), timeZone);
    } while (arc !== last && i < 14);
    return i - 1;
};

export const convertSolar2Lunar = (dd, mm, yy, timeZone) => {
    var k, dayNumber, monthStart, a11, b11, lunarDay, lunarMonth, lunarYear, lunarLeap;
    dayNumber = jdFromDate(dd, mm, yy);
    k = INT((dayNumber - 2415021.076998695) / 29.530588853);
    monthStart = getNewMoonDay(k + 1, timeZone);
    if (monthStart > dayNumber) {
        monthStart = getNewMoonDay(k, timeZone);
    }
    a11 = getLunarMonth11(yy, timeZone);
    b11 = a11;
    if (a11 >= monthStart) {
        lunarYear = yy;
        a11 = getLunarMonth11(yy - 1, timeZone);
    } else {
        lunarYear = yy + 1;
        b11 = getLunarMonth11(yy + 1, timeZone);
    }
    lunarDay = dayNumber - monthStart + 1;
    const diff = INT((monthStart - a11) / 29);
    lunarLeap = 0;
    lunarMonth = diff + 11;
    if (b11 - a11 > 365) {
        const leapMonthDiff = getLeapMonthOffset(a11, timeZone);
        if (diff >= leapMonthDiff) {
            lunarMonth = diff + 10;
            if (diff === leapMonthDiff) {
                lunarLeap = 1;
            }
        }
    }
    if (lunarMonth > 12) {
        lunarMonth = lunarMonth - 12;
    }
    if (lunarMonth >= 11 && diff < 4) {
        lunarYear -= 1;
    }
    return [lunarDay, lunarMonth, lunarYear];
};

var TUAN = ['Chủ Nhật', 'Thứ Hai', 'Thứ Ba', 'Thứ Tư', 'Thứ Năm', 'Thứ Sáu', 'Thứ Bảy'];
var THANG = ['Giêng', 'Hai', 'Ba', 'Tư', 'Năm', 'Sáu', 'Bảy', 'Tám', 'Chín', 'Mười', 'Một', 'Chạp'];
var CAN = ['Giáp', 'Ất', 'Bính', 'Đinh', 'Mậu', 'Kỷ', 'Canh', 'Tân', 'Nhâm', 'Quý'];
var CHI = ['Tí', 'Sửu', 'Dần', 'Mão', 'Thìn', 'Tị', 'Ngọ', 'Mùi', 'Thân', 'Dậu', 'Tuất', 'Hợi'];
var GIO_HD = ['110100101100', '001101001011', '110011010010', '101100110100', '001011001101', '010010110011'];
var TIETKHI = [
    'Xuân phân',
    'Thanh minh',
    'Cốc vũ',
    'Lập hạ',
    'Tiểu mãn',
    'Mang chủng',
    'Hạ chí',
    'Tiểu thử',
    'Đại thử',
    'Lập thu',
    'X\u1EED th\u1EED',
    'B\u1EA1ch l\u1ED9',
    'Thu phân',
    'Hàn lộ',
    'Sương giáng',
    'Lập Đông',
    'Tiểu tuyết',
    'Đại tuyết',
    '\u0110\u00F4ng ch\u00ED',
    'Ti\u1EC3u h\u00E0n',
    '\u0110\u1EA1i h\u00E0n',
    'Lập xuân',
    'Vũ Thủy',
    'Kinh trập',
];

// const YearlyEvent = (dd, mm, infoo) => {
//   const day = dd;
//   const month = mm;
//   const info = infoo;
//   return { day: day, month: month, info: info };
// };

export const YEARLY_EVENTS_DUONG_LICH = [
    { day: 3, month: 2, info: 'Ngày Thành Lập ĐCS VN' },
    { day: 27, month: 2, info: 'Ngày Thầy Thuốc VN' },
    { day: 8, month: 3, info: 'Ngày Quốc Tế Phụ Nữ' },
    { day: 20, month: 3, info: 'Ngày Quốc Tế Hạnh Phúc' },
    { day: 22, month: 3, info: 'Ngày Nước Sạch Thế Giới' },
    { day: 26, month: 3, info: 'Ngày Thành Lập ĐTNCS HCM' },
    { day: 27, month: 3, info: 'Ngày Thể Thao VN' },
    { day: 28, month: 3, info: 'Ngày Lực Lượng DQTV' },
    { day: 22, month: 4, info: 'Ngày Trái Đất' },
    { day: 1, month: 5, info: 'Quốc Tế Lao Động' },
    { day: 7, month: 5, info: 'Chiến Thắng Điện Biên Phủ' },
    { day: 15, month: 5, info: 'Đội Thiếu Niên Tiền Phong HCM' },
    { day: 19, month: 5, info: 'Ngày Sinh Chủ Tịch HCM' },
    { day: 1, month: 6, info: 'Quốc Tế Thiếu Nhi' },
    { day: 5, month: 6, info: 'Ngày Môi Trường Thế Giới' },
    { day: 21, month: 6, info: 'Báo Chí CM Việt Nam' },
    { day: 28, month: 6, info: 'Ngày Gia Đình Việt Nam' },
    { day: 27, month: 7, info: 'Ngày Thương Binh Liệt Sĩ' },
    { day: 10, month: 8, info: 'Nạn Nhân Chất Độc Da Cam' },
    { day: 19, month: 8, info: 'Thành Lập CAND VN' },
    { day: 10, month: 10, info: 'Giải Phóng Thủ Đô' },
    { day: 14, month: 10, info: 'Hội Nông Dân VN' },
    { day: 15, month: 10, info: 'Hội Liên Hiệp Thanh Niên VN' },
    { day: 20, month: 10, info: 'Ngày Phụ Nữ Việt Nam' },
    { day: 9, month: 11, info: 'Ngày Pháp Luật VN' },
    { day: 19, month: 11, info: 'Ngày Quốc Tế Nam Giới' },
    { day: 20, month: 11, info: 'Ngày Nhà Giáo VN' },
    { day: 25, month: 12, info: 'Lễ Giáng Sinh' },
    { day: 22, month: 12, info: 'Ngày Quân Đội Nhân Dân VN' },
];

export const YEARLY_EVENTS = [
    { day: 1, month: 1, info: 'Tết Nguyên Đán' },
    { day: 3, month: 3, info: 'Tết Hàn Thực' },
    { day: 15, month: 1, info: 'Rằm Tháng Giêng' },
    { day: 10, month: 3, info: 'Giỗ Tổ Hùng Vương' },
    { day: 15, month: 4, info: 'Lễ Phật Đản' },
    { day: 5, month: 5, info: 'Lễ Đoan Ngọ' },
    { day: 15, month: 7, info: 'Lễ Vu Lan' },
    { day: 15, month: 8, info: 'Tết Trung Thu' },
    { day: 12, month: 8, info: 'Sân Khấu Việt Nam' },
    { day: 23, month: 12, info: 'Ông Táo Chầu Trời' },
];

export const findEvents = (dd, mm) => {
    var i = 0;
    while (i < YEARLY_EVENTS.length) {
        if (YEARLY_EVENTS[i].day === Number(dd) && YEARLY_EVENTS[i].month === Number(mm)) {
            return [YEARLY_EVENTS[i].info, 1];
        }
        i++;
    }
};

export const findEventsDuongLich = (dd, mm) => {
    var i = 0;
    while (i < YEARLY_EVENTS_DUONG_LICH.length) {
        if (YEARLY_EVENTS_DUONG_LICH[i].day === Number(dd) && YEARLY_EVENTS_DUONG_LICH[i].month === Number(mm)) {
            return [YEARLY_EVENTS_DUONG_LICH[i].info, 1];
        }
        i++;
    }
};

export const getDayInfo = (dd, mm) => {
    var events = findEvents(dd, mm);
    var ret = '';
    for (var i = 0; i < events.length; i++) {
        ret += events[i].info + ' ';
    }
    ret += '&nbsp;';
    return ret;
};

export const showDayInfo = (dd, mm, yy, leap, length, jd, sday, smonth, syear) => {
    //selectCell(cellId);
    var dayOfWeek = TUAN[(jd + 1) % 7];
    var nhuan = leap === 1 ? ' nhu\u1EADn' : '';
    var tenthang = 'Th\u00E1ng ' + THANG[mm - 1] + nhuan + (length === 30 ? ' (\u0110)' : ' (T)');
    var thang = CAN[(yy * 12 + mm + 3) % 10] + ' ' + CHI[(mm + 1) % 12];
    var ngay = CAN[(jd + 9) % 10] + ' ' + CHI[(jd + 1) % 12];

    return `${sday} ${syear} | ${dd}/${mm}/${yy} | ${dayOfWeek} ${ngay} ${thang} ${tenthang} | ${
        TIETKHI[getSolarTerm(jd + 1, 7.0)]
    } ${getGioHoangDao(jd)} ${getYearCanChi(yy)} ${getCanHour0(jd) + ' ' + CHI[0]} | ${getDayInfo(dd, mm)}`;
};

// function selectCell(cellId) {
//   for (var i = 0; i < 42; i++) {
//     document.getElementById("cell" + i).className = "ngaythang";
//   }
//   document.getElementById("cell" + cellId).className = "homnay";
// }

function getYearCanChi(year) {
    return CAN[(year + 6) % 10] + ' ' + CHI[(year + 8) % 12];
}

function getCanHour0(jdn) {
    return CAN[((jdn - 1) * 2) % 10];
}

function getGioHoangDao(jd) {
    var chiOfDay = (jd + 1) % 12;
    var gioHD = GIO_HD[chiOfDay % 6];
    var ret = '';
    var count = 0;
    for (var i = 0; i < 12; i++) {
        if (gioHD.charAt(i) === '1') {
            ret += CHI[i];
            ret += ' (' + ((i * 2 + 23) % 24) + '-' + ((i * 2 + 1) % 24) + ')';
            if (count++ < 5) ret += ', ';
        }
    }
    return ret;
}

var PI = Math.PI;

function INT(d) {
    return Math.floor(d);
}

function SunLongitude(jdn) {
    var T, T2, dr, M, L0, DL, lambda, theta, omega;
    T = (jdn - 2451545.0) / 36525;
    T2 = T * T;
    dr = PI / 180;
    M = 357.5291 + 35999.0503 * T - 0.0001559 * T2 - 0.00000048 * T * T2;
    L0 = 280.46645 + 36000.76983 * T + 0.0003032 * T2;
    DL = (1.9146 - 0.004817 * T - 0.000014 * T2) * Math.sin(dr * M);
    DL = DL + (0.019993 - 0.000101 * T) * Math.sin(dr * 2 * M) + 0.00029 * Math.sin(dr * 3 * M);
    theta = L0 + DL;
    omega = 125.04 - 1934.136 * T;
    lambda = theta - 0.00569 - 0.00478 * Math.sin(omega * dr);
    lambda = lambda * dr;
    lambda = lambda - PI * 2 * INT(lambda / (PI * 2));
    return lambda;
}

function getSolarTerm(dayNumber, timeZone) {
    return INT((SunLongitude(dayNumber - 0.5 - timeZone / 24.0) / PI) * 12);
}
