import actionTypes from './actionTypes'
import { apiGetMayChamCong, apiNewMayChamCong, apiEditMayChamCong, apiDeleteMayChamCong } from '~/services/maychamcong'
import { toast } from "react-toastify";
export const getListMayChamCong = () => async (dispatch) => {
    try {
        const response = await apiGetMayChamCong()
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_MAY_CHAM_CONG,
                maychamcong: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_MAY_CHAM_CONG,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_MAY_CHAM_CONG,
            maychamcong: null
        })
    }
}

export const NewMayChamCong = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiNewMayChamCong(payload)
            // console.log('chek api apiNewMayChamCong', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_MAY_CHAM_CONG,
                    msg: 'OK'
                });
                dispatch(getListMayChamCong());
                toast.success("Thêm mới máy chấm công thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_MAY_CHAM_CONG,
                    msg: 'ERR'
                })
                toast.error("Thêm mới máy chấm công thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_MAY_CHAM_CONG,
                maychamcong: null
            })
        }
    }
}
export const EditMayChamCong = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditMayChamCong(payload)
            // console.log('chek api apiEditMayChamCong', response)
            if (response?.data.success === 200) {
                dispatch(getListMayChamCong());
                toast.success("Sửa thông tin máy chấm công thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_MAY_CHAM_CONG,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_MAY_CHAM_CONG,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin máy chấm công thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_MAY_CHAM_CONG,
                maychamcong: null
            })
        }
    }
}
export const DeleteMayChamCong = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteMayChamCong(payload)
            // console.log('chek api apiDeleteMayChamCong', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListMayChamCong());
                toast.success("Xóa thông tin máy chấm công thành công!")
                dispatch({
                    type: actionTypes.DELETE_MAY_CHAM_CONG,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_MAY_CHAM_CONG,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin máy chấm công thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_MAY_CHAM_CONG,
                maychamcong: null
            })
        }
    }
}