import actionTypes from './actionTypes'
import { apiGetCauhinhnghile, apiNewCauhinhnghile, apiEditCauhinhnghile, apiDeleteCauhinhnghile } from '~/services/cauhinhnghile'
import { toast } from "react-toastify";
export const getListCauhinhnghile = () => async (dispatch) => {
    try {
        const response = await apiGetCauhinhnghile()
        // console.log('chek api lisst Cauhinhnghile', response?.data.success)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_CAU_HINH_NGHI_LE,
                cauhinhnghile: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_CAU_HINH_NGHI_LE,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_CAU_HINH_NGHI_LE,
            cauhinhnghile: null
        })
    }
}

export const NewCauhinhnghile = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewCauhinhnghile', payload)
            const response = await apiNewCauhinhnghile(payload)
            // console.log('chek api apiNewCauhinhnghile', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_CAU_HINH_NGHI_LE,
                    msg: 'OK'
                });
                dispatch(getListCauhinhnghile());
                toast.success("Thêm mới cấu hình nghỉ lễ thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_CAU_HINH_NGHI_LE,
                    msg: 'ERR'
                })
                toast.error("Thêm mới cấu hình nghỉ lễ thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_CAU_HINH_NGHI_LE,
                cauhinhnghile: null
            })
        }
    }
}
export const EditCauhinhnghile = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditCauhinhnghile(payload)
            // console.log('chek api apiEditCauhinhnghile', response)
            if (response?.data.success === 200) {
                dispatch(getListCauhinhnghile());
                toast.success("Sửa thông tin cấu hình nghỉ lễ thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_CAU_HINH_NGHI_LE,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_CAU_HINH_NGHI_LE,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin cấu hình nghỉ lễ thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_CAU_HINH_NGHI_LE,
                cauhinhnghile: null
            })
        }
    }
}
export const DeleteCauhinhnghile = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteCauhinhnghile(payload)
            // console.log('chek api apiDeleteCauhinhnghile', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListCauhinhnghile());
                toast.success("Xóa thông tin cấu hình nghỉ lễ thành công!")
                dispatch({
                    type: actionTypes.DELETE_CAU_HINH_NGHI_LE,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_CAU_HINH_NGHI_LE,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin cấu hình nghỉ lễ thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_CAU_HINH_NGHI_LE,
                cauhinhnghile: null
            })
        }
    }
}