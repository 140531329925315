import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '~/store/actions';
import { ModalDanhgia, ModalEditDanhgia } from '../Admin/Modal';
import Swal from 'sweetalert2';
import {Card, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, UncontrolledDropdown} from 'reactstrap';
import Head from '~/layout/head/Head';
import Content from '~/layout/content/Content';
import { Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle } from '~/components/block/Block';
import {
    Button,
    Col,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    Icon,
    PaginationComponent,
    Row,
    RSelect,
    TooltipComponent,
    PermissionUtils,
    ExportXlsx,
} from '~/components/Component';
import {MenuTuyenDung} from "~/pages/Admin/MenuTab/Menucontent";

const Danhgia = () => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [showEditDanhgia, setShowEditDanhgia] = useState(false);
    //lấy giá trị từ thằng redux trả về
    const { danhgia } = useSelector((state) => state.danhgia);
    const [data, setData] = useState([]);

    const fieldList = {
        PhongbanId: '',
        ThongtinungvienId: '',
        nguoidanhgia: '',
        danhgia: '',
        nhanxet: '',
        ghichu: '',
    };
    useEffect(() => {
        dispatch(actions.getListDanhgia());
    }, []);
    useEffect(() => {
        if (danhgia) {
            setData([...danhgia]);
        }
    }, [danhgia]);

    const [showPhanquyen, setShowPhanquyen] = useState({});
    let dataPermissionUtils = PermissionUtils('ungvien', 'all');
    useEffect(() => {
        if (dataPermissionUtils) {
            setShowPhanquyen(dataPermissionUtils);
        }
    }, [dataPermissionUtils]);

    const namefile = 'Thông tin đánh giá ứng viên';
    const header = ['Stt', 'Ứng viên', 'Người đánh giá', 'Đánh giá', 'Nhận xét', 'Ghi chú'];
    const [columns, setColumns] = useState(() => {
        const d = header.map((item, index) => index <= 6);
        return d;
    });
    const [dataFileMau, setDataFileMau] = useState([]);
    useEffect(() => {
        if (data.length > 0) {
            const datafilemau = data.map((item, index) => {
                return {
                    ...(columns[0] && { Stt: index + 1 }),
                    ...(columns[1] && { 'Ứng viên': item?.Thongtinungvien?.tenthuonggoi }),
                    ...(columns[2] && { 'Người đánh giá': 1 }),
                    ...(columns[3] && { 'Đánh giá': item.danhgia }),
                    ...(columns[4] && { 'Nhận xét': item.nhanxet }),
                    ...(columns[5] && { 'Ghi chú': item.ghichu }),
                };
            });
            setDataFileMau(datafilemau);
        }
    }, [data, columns]);

    const [sm, updateSm] = useState(false);
    const [tablesm, updateTableSm] = useState(false);
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [sort, setSortState] = useState('');
    // onChange function for searching name
    const onFilterChange = (e) => {
        setSearchText(e.target.value);
    };
    const toggle = () => setonSearch(!onSearch);
    const selectorCheck = (e) => {
        let newData;
        newData = data.map((item) => {
            item.checked = e.currentTarget.checked;
            return item;
        });
        setData([...newData]);
    };
    const onSelectChange = (e, id) => {
        let newData = data;
        let index = newData.findIndex((item) => item.id === id);
        newData[index].checked = e.currentTarget.checked;
        setData([...newData]);
    };
    useEffect(() => {
        if (onSearchText !== '') {
            const filteredObject = danhgia.filter((item) => {
                return (
                    item.tenthuonggoi.toLowerCase().includes(onSearchText.toLowerCase())
                );
            });
            setData([...filteredObject]);
        } else {
            setData([...danhgia]);
        }
    }, [onSearchText, setData]);
    // Get current list, pagination
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    useEffect(() => {
        (danhgia && setShow(false)) || setShowEditDanhgia(false);
    }, [danhgia]);
    const handleNewReCord = async () => {
        setShow(true);
    };
    const [dataIdDanhgia, setDataIdDanhgia] = useState([]);
    const handleEditDanhgia = async (data) => {
        setShowEditDanhgia(true);
        await setDataIdDanhgia(data);
    };
    const handleDeleteDanhgia = (data) => {
        Swal.fire({
            title: 'Bạn có chắc không?',
            text: 'Bạn sẽ không thể lấy lại khi xóa!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Không, bỏ qua!',
            confirmButtonText: 'Vâng, xóa nó đi!',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(actions.DeleteDanhgia(data));
            }
        });
    };
    const handleVitrituyendung = (data, val) => {
        return data.find((obj) => {
            return obj.id === val;
        });
    };
    return (
        <React.Fragment>
            {/*<Head title="User List - Regular"></Head>*/}
            <Content>
                <Card className="mt-0">
                    <div className="card-aside-wrap">
                        <div className="card-inner card-inner-lg px-4">
                            <h4>Tuyển dụng</h4>
                            <MenuTuyenDung />
                        </div>
                    </div>
                </Card>
                
                <Block>
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner position-relative card-tools-toggle">
                            <div className="card-title-group">
                                <div className="card-title">
                                    <h5 className="title">Đánh giá ứng viên</h5>
                                    <Button
                                            color="primary"
                                            className="btn-icon px-4 "
                                            onClick={handleNewReCord}
                                        >
                                            <Icon name="plus"></Icon> Thêm mới
                                        </Button>
                                 
                                </div>
                                <div className="card-tools me-n1">
                                    <ul className="btn-toolbar gx-1">
                                        <li>
                                            <a
                                                href="#search"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    toggle();
                                                }}
                                                className="btn btn-icon search-toggle toggle-search"
                                            >
                                                <Icon name="search"></Icon>
                                            </a>
                                        </li>
                                        <li className="btn-toolbar-sep"></li>
                                        <li>
                                            <div className="toggle-wrap">
                                                <Button
                                                    className={`btn-icon btn-trigger toggle ${tablesm ? 'active' : ''}`}
                                                    onClick={() => updateTableSm(true)}
                                                >
                                                    <Icon name="menu-right"></Icon>
                                                </Button>
                                                <div className={`toggle-content ${tablesm ? 'content-active' : ''}`}>
                                                    <ul className="btn-toolbar gx-1">
                                                        <li className="toggle-close">
                                                            <Button
                                                                className="btn-icon btn-trigger toggle"
                                                                onClick={() => updateTableSm(false)}
                                                            >
                                                                <Icon name="arrow-left"></Icon>
                                                            </Button>
                                                        </li>
                                                        <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle
                                                                    tag="a"
                                                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                                                >
                                                                    <div className="dot dot-primary"></div>
                                                                    <Icon name="filter-alt"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu
                                                                    end
                                                                    className="filter-wg dropdown-menu-xl"
                                                                    style={{ overflow: 'visible' }}
                                                                >
                                                                    <div className="dropdown-head">
                                                                        <span className="sub-title dropdown-title">
                                                                            Bộ lọc
                                                                        </span>
                                                                        <div className="dropdown">
                                                                            <a
                                                                                href="#more"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                }}
                                                                                className="btn btn-sm btn-icon"
                                                                            >
                                                                                <Icon name="more-h"></Icon>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    <div className="dropdown-body dropdown-body-rg">
                                                                        <Row className="gx-6 gy-3">
                                                                            <Col size="6">
                                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="custom-control-input"
                                                                                        id="hasBalance"
                                                                                    />
                                                                                    <label
                                                                                        className="custom-control-label"
                                                                                        htmlFor="hasBalance"
                                                                                    >
                                                                                        {' '}
                                                                                        Have Balance
                                                                                    </label>
                                                                                </div>
                                                                            </Col>
                                                                            <Col size="6">
                                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="custom-control-input"
                                                                                        id="hasKYC"
                                                                                    />
                                                                                    <label
                                                                                        className="custom-control-label"
                                                                                        htmlFor="hasKYC"
                                                                                    >
                                                                                        {' '}
                                                                                        KYC Verified
                                                                                    </label>
                                                                                </div>
                                                                            </Col>
                                                                            <Col size="6">
                                                                                <div className="form-group">
                                                                                    <label className="overline-title overline-title-alt">
                                                                                        Role
                                                                                    </label>
                                                                                    <RSelect placeholder="Any Role" />
                                                                                </div>
                                                                            </Col>
                                                                            <Col size="6">
                                                                                <div className="form-group">
                                                                                    <label className="overline-title overline-title-alt">
                                                                                        Status
                                                                                    </label>
                                                                                    <RSelect placeholder="Any Status" />
                                                                                </div>
                                                                            </Col>
                                                                            <Col size="12">
                                                                                <div className="form-group">
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-secondary"
                                                                                    >
                                                                                        Filter
                                                                                    </button>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                    <div className="dropdown-foot between">
                                                                        <a
                                                                            href="#reset"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();
                                                                            }}
                                                                            className="clickable"
                                                                        >
                                                                            Cập nhật bộ lọc 
                                                                        </a>
                                                                        <a
                                                                            href="#save"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();
                                                                            }}
                                                                        >
                                                                            Lưu bộ lọc 
                                                                        </a>
                                                                    </div>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                        <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle
                                                                    color="tranparent"
                                                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                                                >
                                                                    <Icon name="setting"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu end className="dropdown-menu-xs">
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Show</span>
                                                                        </li>
                                                                        <li
                                                                            className={
                                                                                itemPerPage === 10 ? 'active' : ''
                                                                            }
                                                                        >
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setItemPerPage(10);
                                                                                }}
                                                                            >
                                                                                10
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li
                                                                            className={
                                                                                itemPerPage === 15 ? 'active' : ''
                                                                            }
                                                                        >
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setItemPerPage(15);
                                                                                }}
                                                                            >
                                                                                15
                                                                            </DropdownItem>
                                                                        </li>
                                                                    </ul>
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Order</span>
                                                                        </li>
                                                                        <li className={sort === 'dsc' ? 'active' : ''}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setSortState('dsc');
                                                                                    //   sortFunc("dsc");
                                                                                }}
                                                                            >
                                                                                DESC
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li className={sort === 'asc' ? 'active' : ''}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setSortState('asc');
                                                                                    //   sortFunc("asc");
                                                                                }}
                                                                            >
                                                                                ASC
                                                                            </DropdownItem>
                                                                        </li>
                                                                    </ul>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className=" position-relative card-tools-toggle">
                                                <div className="toggle-wrap nk-block-tools-toggle">
                                                    <Button
                                                        className={`btn-icon btn-trigger toggle-expand me-n1 ${
                                                            sm ? 'active' : ''
                                                        }`}
                                                        onClick={() => updateSm(!sm)}
                                                    >
                                                        <Icon name="menu-alt-r"></Icon>
                                                    </Button>
                                                    <div
                                                        className="toggle-expand-content"
                                                        style={{ display: sm ? 'block' : 'none' }}
                                                    >
                                                        <ul className="nk-block-tools g-3">
                                                            <li>
                                                                <ExportXlsx
                                                                    dataFileMau={dataFileMau}
                                                                    title={header}
                                                                    namefile={namefile}
                                                                    btnname={'Xuất dữ liệu'}
                                                                />
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className={`card-search search-wrap ${!onSearch && 'active'}`}>
                                <div className="card-body">
                                    <div className="search-content">
                                        <Button
                                            className="search-back btn-icon toggle-search active"
                                            onClick={() => {
                                                setSearchText('');
                                                toggle();
                                            }}
                                        >
                                            <Icon name="arrow-left"></Icon>
                                        </Button>
                                        <input
                                            type="text"
                                            className="border-transparent form-focus-none form-control"
                                            placeholder="Tìm kiếm"
                                            value={onSearchText}
                                            onChange={(e) => onFilterChange(e)}
                                        />
                                        <Button className="search-submit btn-icon">
                                            <Icon name="search"></Icon>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DataTableBody>
                            <DataTableHead>
                                {columns[0] && (
                                    <DataTableRow size="mb">
                                        <span className="sub-text">Stt</span>
                                    </DataTableRow>
                                )}
                                <DataTableRow className="nk-tb-col-check">
                                    <div className="custom-control custom-control-sm custom-checkbox notext">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            onChange={(e) => selectorCheck(e)}
                                            id="uid"
                                        />
                                        <label className="custom-control-label" htmlFor="uid"></label>
                                    </div>
                                </DataTableRow>
                                {columns[1] && (
                                    <DataTableRow size="mb">
                                        <span className="sub-text">Ứng viên</span>
                                    </DataTableRow>
                                )}
                                {columns[2] && (
                                    <DataTableRow size="mb">
                                        <span className="sub-text">Người đánh giá</span>
                                    </DataTableRow>
                                )}
                                {columns[3] && (
                                    <DataTableRow size="mb">
                                        <span className="sub-text">Đánh giá</span>
                                    </DataTableRow>
                                )}
                                {columns[4] && (
                                    <DataTableRow size="mb">
                                        <span className="sub-text">Nhận xét</span>
                                    </DataTableRow>
                                )}
                                {columns[5] && (
                                    <DataTableRow size="mb">
                                        <span className="sub-text">Ghi chú</span>
                                    </DataTableRow>
                                )}
                                <DataTableRow className="nk-tb-col-tools text-end">
                                    <UncontrolledDropdown>
                                        <DropdownToggle
                                            color="tranparent"
                                            className="btn btn-xs btn-outline-light btn-icon dropdown-toggle"
                                        >
                                            <Icon name="plus"></Icon>
                                        </DropdownToggle>
                                        <DropdownMenu end className="dropdown-menu-xs">
                                            <ul className="link-tidy sm no-bdr">
                                                {header.length > 0 ? (
                                                    header.map((item, index) => (
                                                        <li key={index}>
                                                            <div className="custom-control custom-control-sm custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id={`column-${index}`}
                                                                    checked={columns[index]}
                                                                    onChange={(e) =>
                                                                        setColumns((prev) => {
                                                                            const newColumns = [...prev];
                                                                            newColumns[index] = e.target.checked;
                                                                            return newColumns;
                                                                        })
                                                                    }
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor={`column-${index}`}
                                                                >
                                                                    {item}
                                                                </label>
                                                            </div>
                                                        </li>
                                                    ))
                                                ) : (
                                                    <li>No data</li>
                                                )}
                                            </ul>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </DataTableRow>
                            </DataTableHead>
                            {currentItems.length > 0
                                ? currentItems.map((item, index) => {
                                      return (
                                          <DataTableItem key={item.id}>
                                              {columns[0] && (
                                                  <DataTableRow size=" nk-tb-col-check mb">
                                                      <span className="tb-amount">{index + 1}</span>
                                                  </DataTableRow>
                                              )}
                                              <DataTableRow className="nk-tb-col-check">
                                                  <div className="custom-control custom-control-sm custom-checkbox notext">
                                                      <input
                                                          type="checkbox"
                                                          className="custom-control-input"
                                                          defaultChecked={item.checked}
                                                          id={item.id + 'uid1'}
                                                          key={Math.random()}
                                                          onChange={(e) => onSelectChange(e, item.id)}
                                                      />
                                                      <label
                                                          className="custom-control-label"
                                                          htmlFor={item.id + 'uid1'}
                                                      ></label>
                                                  </div>
                                              </DataTableRow>
                                              {columns[1] && (
                                                  <DataTableRow size="mb">
                                                      <span className="tb-amount">
                                                          {item?.Thongtinungvien?.tenthuonggoi}
                                                      </span>
                                                  </DataTableRow>
                                              )}
                                              {columns[2] && (
                                                  <DataTableRow size="mb">
                                                      <span className="tb-amount">1</span>
                                                  </DataTableRow>
                                              )}
                                              {columns[3] && (
                                                  <DataTableRow size="mb">
                                                      <span className="tb-amount">{item.danhgia}</span>
                                                  </DataTableRow>
                                              )}
                                              {columns[4] && (
                                                  <DataTableRow size="mb">
                                                      <span className="tb-amount">{item.nhanxet}</span>
                                                  </DataTableRow>
                                              )}
                                              {columns[5] && (
                                                  <DataTableRow size="mb">
                                                      <span className="tb-amount">{item.ghichu}</span>
                                                  </DataTableRow>
                                              )}
                                              <DataTableRow className="nk-tb-col-tools">
                                                  <ul className="nk-tb-actions gx-1">
                                                      {+showPhanquyen?.sua === 1 && (
                                                          <li
                                                              className="nk-tb-action-hidden"
                                                              onClick={() => handleEditDanhgia(item)}
                                                          >
                                                              <TooltipComponent
                                                                  tag="a"
                                                                  containerClassName="btn btn-trigger btn-icon"
                                                                  id={'edit' + item.id}
                                                                  icon="edit-alt-fill"
                                                                  direction="top"
                                                                  text="Sửa"
                                                              />
                                                          </li>
                                                      )}
                                                      {+showPhanquyen?.xoa === 1 && (
                                                          <React.Fragment>
                                                              <li
                                                                  className="nk-tb-action-hidden"
                                                                  onClick={() => handleDeleteDanhgia(item)}
                                                              >
                                                                  <TooltipComponent
                                                                      tag="a"
                                                                      containerClassName="btn btn-trigger btn-icon"
                                                                      id={'suspend' + item.id}
                                                                      icon="user-cross-fill"
                                                                      direction="top"
                                                                      text="Xóa"
                                                                  />
                                                              </li>
                                                          </React.Fragment>
                                                      )}
                                                      <li>
                                                          <UncontrolledDropdown>
                                                              {(+showPhanquyen?.sua === 1 ||
                                                                  +showPhanquyen?.xoa === 1) && (
                                                                  <DropdownToggle
                                                                      tag="a"
                                                                      className="dropdown-toggle btn btn-icon btn-trigger"
                                                                  >
                                                                      <Icon name="more-h"></Icon>
                                                                  </DropdownToggle>
                                                              )}
                                                              <DropdownMenu end>
                                                                  <ul className="link-list-opt no-bdr">
                                                                      {+showPhanquyen?.sua === 1 && (
                                                                          <li onClick={() => handleEditDanhgia(item)}>
                                                                              <DropdownItem
                                                                                  tag="a"
                                                                                  href="#edit"
                                                                                  onClick={(ev) => {
                                                                                      ev.preventDefault();
                                                                                  }}
                                                                              >
                                                                                  <Icon name="edit"></Icon>
                                                                                  <span>Sửa</span>
                                                                              </DropdownItem>
                                                                          </li>
                                                                      )}
                                                                      {+showPhanquyen?.xoa === 1 && (
                                                                          <React.Fragment>
                                                                              <li className="divider"></li>
                                                                              <li
                                                                                  onClick={() =>
                                                                                      handleDeleteDanhgia(item)
                                                                                  }
                                                                              >
                                                                                  <DropdownItem
                                                                                      tag="a"
                                                                                      href="#suspend"
                                                                                      onClick={(ev) => {
                                                                                          ev.preventDefault();
                                                                                      }}
                                                                                  >
                                                                                      <Icon name="na"></Icon>
                                                                                      <span>Xóa</span>
                                                                                  </DropdownItem>
                                                                              </li>
                                                                          </React.Fragment>
                                                                      )}
                                                                  </ul>
                                                              </DropdownMenu>
                                                          </UncontrolledDropdown>
                                                      </li>
                                                  </ul>
                                              </DataTableRow>
                                          </DataTableItem>
                                      );
                                  })
                                : null}
                        </DataTableBody>
                        <div className="card-inner">
                            {currentItems.length > 0 ? (
                                <PaginationComponent
                                    itemPerPage={itemPerPage}
                                    totalItems={danhgia.length}
                                    paginate={paginate}
                                    currentPage={currentPage}
                                />
                            ) : (
                                <div className="text-center">
                                    <span className="text-silent">No data found</span>
                                </div>
                            )}
                        </div>
                    </DataTable>
                </Block>

                <ModalDanhgia show={show} setShow={() => setShow(false)} fieldList={fieldList} />
                <ModalEditDanhgia
                    show={showEditDanhgia}
                    setShow={() => setShowEditDanhgia(false)}
                    dataIdDanhgia={dataIdDanhgia}
                    fieldList={fieldList}
                />
            </Content>
        </React.Fragment>
    );
};

export default Danhgia;
