import React, {useEffect, useState} from 'react';
import {Button} from '~/components/Form';
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import {DataTableBody, DataTableHead, DataTableItem, DataTableRow} from "~/components/table/DataTable";
import {Card, DropdownMenu, DropdownToggle, Table, UncontrolledDropdown} from 'reactstrap';
import exportFromJSON from "export-from-json";

const ModalExportExcell = ({show, setShow, dataExport, typeExport}) => {
    const [type, setType] = useState(typeExport);
    const [payload, setPayload] = useState([]);
    const [dataTmp, setDataTmp] = useState([]);
    
    useEffect(() => {
        if (dataExport?.length > 0) {
            const listField = Object.keys(dataExport[0]);
            const listFieldTmp = listField.map(item => ({name: item}));
            setPayload(listFieldTmp);
        }
        
        setType(typeExport)
    }, [type, dataExport]);
    const handleSubmit = async () => {
        const fieldsToExtract = dataTmp.filter(item => item.checked === true);
        const newArray = dataExport.map(item => {
            const newRow = {};
            fieldsToExtract.forEach(field => {
                newRow[field.name] = item[field.name];
            });
            return newRow;
        });
        const fileName = 'data-file';
        const exportType = typeExport !== 'csv' ? exportFromJSON.types.xls : exportFromJSON.types.csv;
        
        exportFromJSON({data: newArray, fileName, exportType});
    };
    const selectorCheckAll = (e) => {
        let newData;
        newData = payload.map((item) => {
            item.checked = e.currentTarget.checked;
            return item;
        });
        setDataTmp([...newData]);
    };
    const selectorCheck = (e, i) => {
        let newData = payload;
        let index = newData.findIndex((item) => item.name === i);
        newData[index].checked = e.currentTarget.checked;
        setDataTmp([...newData]);
    };
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">Xuất Excell {typeExport}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-4">
                        <Table className="table table-bordered table-striped table-hover">
                            <thead>
                            <tr>
                                <th className="text-center">STT</th>
                                <th className="text-center">Tiêu chí</th>
                                <th className="text-center">Số liệu</th>
                                <th className="text-center">Ghi chú</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="text-center">STT</td>
                                <td className="text-center">Tiêu chí</td>
                                <td className="text-center">Số liệu</td>
                                <td className="text-center">Ghi chú</td>
                            </tr>
                            </tbody>
                        </Table>
                        
                        
                        <DataTableBody>
                            <DataTableHead>
                                <DataTableRow>
                                    <span className="text-danger">Tên trường export</span>
                                </DataTableRow>
                                <DataTableRow size="mb">
                                    <div className="custom-control custom-control-sm custom-checkbox notext">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            onChange={(e) => selectorCheckAll(e)}
                                            id="all"
                                        />
                                        <label className="custom-control-label" htmlFor="all"></label>
                                    </div>
                                </DataTableRow>
                            </DataTableHead>
                            
                            {payload.length > 0
                                ? payload.map((item, index) => {
                                    return (
                                        <DataTableItem key={index}>
                                            <DataTableRow>
                                                <span className="sub-text">{item.name}</span>
                                            </DataTableRow>
                                            <DataTableRow className="nk-tb-col-check">
                                                <div
                                                    className="custom-control custom-control-sm custom-checkbox notext">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        defaultChecked={item.checked}
                                                        onChange={(e) => selectorCheck(e, item.name)}
                                                        key={Math.random()}
                                                        id={item.name + 'ab1'}
                                                    />
                                                    <label className="custom-control-label"
                                                           htmlFor={item.name + 'ab1'}></label>
                                                </div>
                                            </DataTableRow>
                                        </DataTableItem>
                                    )
                                })
                                : null}
                        </DataTableBody>
                    </div>
                    <div className="col-6">
                        <Button text="Tiến hành xuất excell" bClass="btn btn-primary px-5" textColor
                                onClick={handleSubmit}/>
                    
                    </div>
                </div>
            
            </Modal.Body>
        </Modal>
    );
};

export default ModalExportExcell;