import actionTypes from './actionTypes'
import { apiGetCauhinhtangca, apiNewCauhinhtangca, apiEditCauhinhtangca, apiDeleteCauhinhtangca } from '~/services/cauhinhtangca'
import { toast } from "react-toastify";
export const getListCauhinhtangca = () => async (dispatch) => {
    try {
        const response = await apiGetCauhinhtangca()
        // console.log('chek api lisst Cauhinhtangca', response?.data.success)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_CAU_HINH_TANG_CA,
                cauhinhtangca: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_CAU_HINH_TANG_CA,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_CAU_HINH_TANG_CA,
            cauhinhtangca: null
        })
    }
}

export const NewCauhinhtangca = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewCauhinhtangca', payload)
            const response = await apiNewCauhinhtangca(payload)
            // console.log('chek api apiNewCauhinhtangca', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_CAU_HINH_TANG_CA,
                    msg: 'OK'
                });
                dispatch(getListCauhinhtangca());
                toast.success("Thêm mới cấu hình tăng ca thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_CAU_HINH_TANG_CA,
                    msg: 'ERR'
                })
                toast.error("Thêm mới cấu hình tăng ca thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_CAU_HINH_TANG_CA,
                cauhinhtangca: null
            })
        }
    }
}
export const EditCauhinhtangca = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditCauhinhtangca(payload)
            // console.log('chek api apiEditCauhinhtangca', response)
            if (response?.data.success === 200) {
                dispatch(getListCauhinhtangca());
                toast.success("Sửa thông tin cấu hình tăng ca thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_CAU_HINH_TANG_CA,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_CAU_HINH_TANG_CA,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin cấu hình tăng ca thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_CAU_HINH_TANG_CA,
                cauhinhtangca: null
            })
        }
    }
}
export const DeleteCauhinhtangca = (payload) => {
    return async (dispatch) => {
        try {
          
            const response = await apiDeleteCauhinhtangca(payload)
            
            if (response?.data.success === 200) {
                dispatch(getListCauhinhtangca());
                toast.success("Xóa thông tin cấu hình tăng ca thành công!")
                dispatch({
                    type: actionTypes.DELETE_CAU_HINH_TANG_CA,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_CAU_HINH_TANG_CA,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin cấu hình tăng ca thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_CAU_HINH_TANG_CA,
                cauhinhtangca: null
            })
        }
    }
}