import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'reactstrap';
import Head from '~/layout/head/Head';
import Content from '~/layout/content/Content';
import { Block } from '~/components/block/Block';
import { DataTable, PermissionUtils, Button } from '~/components/Component';
import { InputForm } from '~/components/Form';
import {
    apiEditCauhinhdoanhnghiep,
    apiGetCauhinhdoanhnghiep,
    apiNewCauhinhdoanhnghiep,
} from '~/services/cauhinhdoanhnghiep';
import { toast } from 'react-toastify';

const CauHinhBHXH_BHYT_BHTN = () => {
    const dispatch = useDispatch();
    const [invalidFields, setInvalidFields] = useState([]);
    const [payloadCauhinh, setPayloadCauhinh] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const cauHinhDoanhNghiep = await apiGetCauhinhdoanhnghiep();
                const response = cauHinhDoanhNghiep.data.data.rows[0];
                // console.log('response', response);
                const datas = {
                    id: response?.id,
                    bhtngcongtydong: response?.bhtngcongtydong,
                    bhxhcongtydong: response?.bhxhcongtydong,
                    bhytcongtydong: response?.bhytcongtydong,
                    bhytnglddong: response?.bhytnglddong,
                    bhxhnglddong: response?.bhxhnglddong,
                    bhtngnglddong: response?.bhtngnglddong,
                };
                setPayloadCauhinh(datas);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData().catch(console.error);
    }, []);

    const [showPhanquyen, setShowPhanquyen] = useState({});
    let dataPermissionUtils = PermissionUtils('cauhinhdoanhnghiep', 'all');
    useEffect(() => {
        if (dataPermissionUtils) {
            setShowPhanquyen(dataPermissionUtils);
        }
    }, [dataPermissionUtils]);

    const handleSubmit = async () => {
        const datapayload = {
            data: [
                {
                    bhtngcongtydong: payloadCauhinh?.bhtngcongtydong,
                    bhxhcongtydong: payloadCauhinh?.bhxhcongtydong,
                    bhytcongtydong: payloadCauhinh?.bhytcongtydong,
                    bhytnglddong: payloadCauhinh?.bhytnglddong,
                    bhxhnglddong: payloadCauhinh?.bhxhnglddong,
                    bhtngnglddong: payloadCauhinh?.bhtngnglddong,
                },
            ],
        };
        if (payloadCauhinh.id) {
            datapayload.data[0].id = payloadCauhinh.id;
            const response = await apiEditCauhinhdoanhnghiep(datapayload);
            response?.data.length === 0
                ? toast.error('Sửa thông tin không thành công!')
                : toast.success('Sửa thông tin thành công!');
        } else {
            // console.log('datapayload', datapayload)
            const response = await apiNewCauhinhdoanhnghiep(datapayload);
            response?.data.length === 0
                ? toast.error('Thêm thông tin không thành công!')
                : toast.success('Thêm thông tin thành công!');
        }
    };
    return (
        <React.Fragment>
            {/*<Head title="Doanh nghiệp, Chi nhánh, Phòng ban, Chức Vụ"></Head>*/}
            <Content>
                <Card className="mt-md-5">
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner card-inner-lg px-4">
                            <h4>Cấu hình BHXH, BHYT,BHTN </h4>
                        </div>
                    </DataTable>
                </Card>
                <Block>
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner position-relative card-tools-toggle pt-0">
                            <div className="row">
                                <div className="form-group col-md-12">
                                    {+showPhanquyen?.them === 1 && (
                                        <Button
                                            color="primary"
                                            className="btn-icon px-4"
                                            onClick={(e) => handleSubmit(e)}
                                        >
                                            Cập nhật
                                        </Button>
                                    )}
                                </div>

                                <div className="form-group col-md-6">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'BHXH Công Ty Đóng(%)'}
                                        value={payloadCauhinh.bhxhcongtydong}
                                        setValue={setPayloadCauhinh}
                                        keyPayload={'bhxhcongtydong'}
                                        placeholder={'BHXH Công Ty Đóng'}
                                        type="number"
                                        validate
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'BHXH Người Lao Động Đóng(%)'}
                                        value={payloadCauhinh.bhxhnglddong}
                                        setValue={setPayloadCauhinh}
                                        keyPayload={'bhxhnglddong'}
                                        placeholder={'BHXH Người Lao Động Đóng'}
                                        type="number"
                                        validate
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'BHTN Công Ty Đóng(%)'}
                                        value={payloadCauhinh.bhtngcongtydong}
                                        setValue={setPayloadCauhinh}
                                        keyPayload={'bhtngcongtydong'}
                                        placeholder={'BHTN Công ty đóng'}
                                        type="number"
                                        validate
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'BHTN Người Lao Động Đóng(%)'}
                                        value={payloadCauhinh.bhtngnglddong}
                                        setValue={setPayloadCauhinh}
                                        keyPayload={'bhtngnglddong'}
                                        placeholder={'BHTN Người Lao Động Đóng'}
                                        type="number"
                                        validate
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'BHYT Công Ty Đóng(%)'}
                                        value={payloadCauhinh.bhytcongtydong}
                                        setValue={setPayloadCauhinh}
                                        keyPayload={'bhytcongtydong'}
                                        placeholder={'BHYT Công Ty Đóng'}
                                        type="number"
                                        validate
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'BHYT Người Lao Động Đóng(%)'}
                                        value={payloadCauhinh.bhytnglddong}
                                        setValue={setPayloadCauhinh}
                                        keyPayload={'bhytnglddong'}
                                        placeholder={'BHYT Người Lao Động Đóng'}
                                        type="number"
                                        validate
                                    />
                                </div>
                            </div>
                        </div>
                    </DataTable>
                </Block>
            </Content>
        </React.Fragment>
    );
};

export default CauHinhBHXH_BHYT_BHTN;
