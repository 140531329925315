import actionTypes from './actionTypes'
import {
    apiGetNguoiPhuThuoc,
    apiNewNguoiPhuThuoc,
    apiEditNguoiPhuThuoc,
    apiDeleteNguoiPhuThuoc,
} from '~/services/nguoithannhanvien'
import { toast } from "react-toastify";
export const getListNguoiPhuThuoc = () => async (dispatch) => {
    
    try {
        const response = await apiGetNguoiPhuThuoc()
        
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_NGUOI_PHU_THUOC,
                nguoithannhanvien: response?.data.data.rows
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_NGUOI_PHU_THUOC,
                msg: response.data.msg
            })
        }
        
    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_NGUOI_PHU_THUOC,
            nguoithannhanvien: null
        })
    }
}

export const NewNguoiPhuThuoc = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewNguoiPhuThuoc', payload)
            const response = await apiNewNguoiPhuThuoc(payload)
            if (response.data.success === 200) {
                dispatch(getListNguoiPhuThuoc());
                toast.success("Thêm mới thành công!")
                dispatch({
                    type: actionTypes.POST_NEW_NGUOI_PHU_THUOC,
                    msg: 'OK'
                });
                
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_NGUOI_PHU_THUOC,
                    msg: 'ERR'
                })
                toast.error("Thêm mới thất bại!")
            }
            
        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_NGUOI_PHU_THUOC,
                nguoithannhanvien: null
            })
        }
    }
}
export const EditNguoiPhuThuoc = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiEditNguoiPhuThuoc', payload)
            const response = await apiEditNguoiPhuThuoc(payload)
            
            if (response?.data.success === 200) {
                dispatch(getListNguoiPhuThuoc());
                toast.success("Sửa thông tin thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_NGUOI_PHU_THUOC,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_NGUOI_PHU_THUOC,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin thất bại!")
            }
            
        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_NGUOI_PHU_THUOC,
                nguoithannhanvien: null
            })
        }
    }
}
export const DeleteNguoiPhuThuoc = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteNguoiPhuThuoc(payload)
            // console.log('chek api apiDeleteNguoiPhuThuoc', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListNguoiPhuThuoc());
                toast.success("Xóa thông tin thành công!")
                dispatch({
                    type: actionTypes.DELETE_NGUOI_PHU_THUOC,
                    msg: 'OK'
                });
                
                
            } else {
                dispatch({
                    type: actionTypes.DELETE_NGUOI_PHU_THUOC,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin thất bại!")
            }
            
        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_NGUOI_PHU_THUOC,
                nguoithannhanvien: null
            })
        }
    }
}