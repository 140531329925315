import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {InputForm, Button} from '~/components/Form';
import * as actions from '~/store/actions';
import {validateNhomnv} from '~/request/validateNhomnv';
import '../Modal.scss';
import {dataSelectActive} from "~/utils/Datacustom";
import Select from "react-select";
import Textarea from "~/components/Form/Textarea";
import {Icon, SelectFinNhanvienfix, SelectNhanvienCond} from "~/components/Component";
import { Modal, ModalBody } from 'reactstrap';

const ModalNhomnv = ({show, setShow, fieldList}) => {
    const dispatch = useDispatch();
    const {phongban} = useSelector((state) => state.phongban);
    const {chinhanh} = useSelector((state) => state.chinhanh);
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayload] = useState(fieldList);
    const [listTrangthai, setlistTrangthai] = useState([]);
    // const [listPhongban, setlistPhongban] = useState([]);
    // const [listChinhanh, setlistChinhanh] = useState([]);
    const [listNhanvien, setlistNhanvien] = useState([]);
    const [showSelect, setShowSelect] = useState(false);
    
    const fieldListFilter = {
        PhongbanId: '',
        ChinhanhId: '',
        NhanvienId: '',
    }
    // const [payloadUse, setPayloadUse] = useState(fieldListFilter)
    // useEffect(() => {
    //     if (
    //         chinhanh &&
    //         phongban
    //     ) {
    //         let dataSelectChinhanh = chinhanh.map((item) => ({label: item.diachi, value: item.id}));
    //         let dataSelectPhongban = phongban.map((item) => ({label: item.phongban, value: item.id}));
    //         setlistPhongban(dataSelectPhongban);
    //         setlistChinhanh(dataSelectChinhanh);
    //     }
    // }, [phongban, chinhanh]);
    useEffect(() => {
        // let TrangthaiId = dataSelectActive.map((item) => ({ label: item.label, value: item.value }));
        // setlistTrangthai(TrangthaiId);
        setPayload((prev) => ({
            ...prev,
            ['NhanvienId']: listNhanvien,
        }));
    }, [listNhanvien]);
    // const handleOnSelectNV = async (selected, name) => {
    //     let statename = name.name;
    //     setPayloadUse((prev) => ({...prev, [statename]: selected}));
    // };
    useEffect(() => {
        setlistTrangthai(dataSelectActive)
        show && setPayload(fieldList);
    }, [show]);
    
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayload((prev) => ({...prev, [statename]: selected}));
    };
    
    const handleSubmit = async () => {
   
        // let listNhanVienInNhom = []
        let listNhanVienInNhom = payload?.NhanvienId.map((item) =>{
            return item.value;
        })
        let invalids = validate(payload);
        if (invalids === 0) {
            let datapayload = {
                data: [
                    {
                        tennhom: payload.tennhom,
                        totalNhanvien: listNhanVienInNhom?.length,
                        motanhom: payload.motanhom,
                        trangthai: payload?.trangthai.value,
                        NhanvienId:listNhanVienInNhom
                    },
                ],
            };
            // console.log('check payload', datapayload)
            dispatch(actions.NewNhomnv(datapayload));
        }
    };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateNhomnv(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateNhomnv(item[0], item[1])]);
                invalids++;
            }
        });
        return invalids;
    };
    return (
        <React.Fragment>
            <Modal isOpen={show} className="modal-dialog-centered modal-90w" size="lg" toggle={setShow}>
                <ModalBody>
                    <Icon name="cross-sm float-end fs-2" onClick={setShow}></Icon>
                    <h5 className="title mb-3">Nhóm nhân viên</h5>
                    <div className="row">
                        <div className="form-group col-md-8">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Tên nhóm'}
                                value={payload.tennhom}
                                setValue={setPayload}
                                keyPayload={'tennhom'}
                                type="text"
                                validate
                            />
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                <span>Trạng thái <b className="text-danger">(*)</b></span>
                            </label>
                            <Select
                                value={payload.trangthai}
                                onChange={handleOnSelect}
                                options={listTrangthai}
                                placeholder={'Trạng thái'}
                                name="trangthai"
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'trangthai') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'trangthai')?.message}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-12">
                            <Textarea
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Mô tả'}
                                value={payload.motanhom}
                                setValue={setPayload}
                                keyPayload={'motanhom'}
                                type="text"
                                validate
                            />
                        </div>
                        {/*<div className="form-group col-md-6">*/}
                        {/*    <label htmlFor="inputZip" className="mb-2 fw-bolder">*/}
                        {/*        Chi nhánh*/}
                        {/*    </label>*/}
                        {/*    <Select*/}
                        {/*        value={payload?.ChinhanhId}*/}
                        {/*        onChange={handleOnSelectNV}*/}
                        {/*        options={listChinhanh}*/}
                        {/*        placeholder={'Chọn Chi Nhánh'}*/}
                        {/*        name="ChinhanhId"*/}
                        {/*        onFocus={() => setInvalidFields([])}*/}
                        {/*    />*/}
                        {/*    {invalidFields.length > 0 &&*/}
                        {/*        invalidFields.some((i) => i.name === 'ChinhanhId') && (*/}
                        {/*            <small className="text-danger">*/}
                        {/*                {*/}
                        {/*                    invalidFields.find((i) => i.name === 'ChinhanhId')*/}
                        {/*                        ?.message*/}
                        {/*                }*/}
                        {/*            </small>*/}
                        {/*        )}*/}
                        {/*</div>*/}
                        {/*<div className="form-group col-md-6">*/}
                        {/*    <label htmlFor="inputZip" className="mb-2 fw-bolder">*/}
                        {/*        Phòng ban*/}
                        {/*    </label>*/}
                        {/*    <Select*/}
                        {/*        value={payload?.PhongbanId}*/}
                        {/*        onChange={handleOnSelectNV}*/}
                        {/*        options={listPhongban}*/}
                        {/*        placeholder={'Chọn Phòng Ban'}*/}
                        {/*        name="PhongbanId"*/}
                        {/*        onFocus={() => setInvalidFields([])}*/}
                        {/*    />*/}
                        {/*    {invalidFields.length > 0 &&*/}
                        {/*        invalidFields.some((i) => i.name === 'PhongbanId') && (*/}
                        {/*            <small className="text-danger">*/}
                        {/*                {*/}
                        {/*                    invalidFields.find((i) => i.name === 'PhongbanId')*/}
                        {/*                        ?.message*/}
                        {/*                }*/}
                        {/*            </small>*/}
                        {/*        )}*/}
                        {/*</div>*/}
                        <div className="form-group col-md-12" onClick={() => setShowSelect(true)}>
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                Chọn Nhân Viên <b className="text-danger">(*)</b>
                            </label>
                            <Select
                                value={payload.NhanvienId}
                                name="NhanvienId"
                                isMulti
                                onFocus={() => setInvalidFields([])}
                                inputProps={{ autoComplete: "off" }}
                                // inputProps={{
                                //     autoComplete: "off",
                                //     spellCheck: "false",
                                //     autoCorrect: "off"
                                // }}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'NhanvienId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'NhanvienId')?.message}
                                </small>
                            )}
                        </div>
                    </div>
                    
                    <div className="col-12 mt-5">
                        <Button text="Thêm mới" bClass="btn btn-primary px-5" textColor onClick={handleSubmit}/>
                    </div>
                </ModalBody>
            </Modal>
            <SelectFinNhanvienfix
                showSelect={showSelect}
                typeSelect={true}
                setlistNhanvien={setlistNhanvien}
                setShowSelect={() => setShowSelect(false)}
            />
            
            {/*<SelectNhanvienCond*/}
            {/*    showSelect={showSelect}*/}
            {/*    // typeSelect = {*/}
            {/*    //  ismultiple: "Lấy nhiều"*/}
            {/*    //  single: "lấy một"*/}
            {/*    // }*/}
            {/*    fieldNhanvien={payloadUse}*/}
            {/*    typeSelect*/}
            {/*    setlistNhanvien={setlistNhanvien}*/}
            {/*    setShowSelect={() => setShowSelect(false)}*/}
            {/*/>*/}
        </React.Fragment>
    );
};

export default ModalNhomnv;
