import actionTypes from './actionTypes'
import { apiGetBangchamcong, apiNewBangchamcong, apiEditBangchamcong, apiDeleteBangchamcong } from '~/services/bangchamcong'
import { toast } from "react-toastify";
export const getListBangchamcong = () => async (dispatch) => {
    try {
        const response = await apiGetBangchamcong()
        // console.log('chek api lisst Bangchamcong', response?.data.success)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_BANG_CHAM_CONG,
                bangchamcong: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_BANG_CHAM_CONG,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_BANG_CHAM_CONG,
            bangchamcong: null
        })
    }
}

export const NewBangchamcong = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewBangchamcong', payload)
            const response = await apiNewBangchamcong(payload)
            // console.log('chek api apiNewBangchamcong', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_BANG_CHAM_CONG,
                    msg: 'OK'
                });
                dispatch(getListBangchamcong());
                toast.success("Thêm mới bảng chấm công thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_BANG_CHAM_CONG,
                    msg: 'ERR'
                })
                toast.error("Thêm mới bảng chấm công thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_BANG_CHAM_CONG,
                bangchamcong: null
            })
        }
    }
}
export const EditBangchamcong = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditBangchamcong(payload)
            // console.log('chek api response', response)
            if (response?.data.success === 200) {
               
                dispatch({
                    type: actionTypes.PUT_EDIT_BANG_CHAM_CONG,
                    msg: 'OK'
                });
                dispatch(getListBangchamcong());
                toast.success("Sửa thông tin bảng chấm công thành công!")
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_BANG_CHAM_CONG,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin bảng chấm công thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_BANG_CHAM_CONG,
                bangchamcong: null
            })
        }
    }
}
export const DeleteBangchamcong = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteBangchamcong(payload)
            // console.log('chek api apiDeleteBangchamcong', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListBangchamcong());
                toast.success("Xóa thông tin bảng chấm công thành công!")
                dispatch({
                    type: actionTypes.DELETE_BANG_CHAM_CONG,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_BANG_CHAM_CONG,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin bảng chấm công thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_BANG_CHAM_CONG,
                bangchamcong: null
            })
        }
    }
}