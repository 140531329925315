import actionTypes from './actionTypes'
import { apiGetLoaikiemdinhatvsbhld, apiNewLoaikiemdinhatvsbhld, apiEditLoaikiemdinhatvsbhld, apiDeleteLoaikiemdinhatvsbhld } from '~/services/loaikiemdinhatvsbhld'
import { toast } from "react-toastify";
export const getListLoaikiemdinhatvsbhld = () => async (dispatch) => {
    try {
        const response = await apiGetLoaikiemdinhatvsbhld()
        // console.log('chek api lisst Loaikiemdinhatvsbhld', response?.data.success)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_LOAI_KIEM_DINH_ATVSBHLD,
                loaikiemdinhatvsbhld: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_LOAI_KIEM_DINH_ATVSBHLD,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_LOAI_KIEM_DINH_ATVSBHLD,
            loaikiemdinhatvsbhld: null
        })
    }
}

export const NewLoaikiemdinhatvsbhld = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewLoaikiemdinhatvsbhld', payload)
            const response = await apiNewLoaikiemdinhatvsbhld(payload)
            // console.log('chek api apiNewLoaikiemdinhatvsbhld', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_LOAI_KIEM_DINH_ATVSBHLD,
                    msg: 'OK'
                });
                dispatch(getListLoaikiemdinhatvsbhld());
                toast.success("Thêm mới thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_LOAI_KIEM_DINH_ATVSBHLD,
                    msg: 'ERR'
                })
                toast.error("Thêm mới thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_LOAI_KIEM_DINH_ATVSBHLD,
                loaikiemdinhatvsbhld: null
            })
        }
    }
}
export const EditLoaikiemdinhatvsbhld = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditLoaikiemdinhatvsbhld(payload)
            // console.log('chek api apiEditLoaikiemdinhatvsbhld', response)
            if (response?.data.success === 200) {
                dispatch(getListLoaikiemdinhatvsbhld());
                toast.success("Sửa thông tin thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_LOAI_KIEM_DINH_ATVSBHLD,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_LOAI_KIEM_DINH_ATVSBHLD,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_LOAI_KIEM_DINH_ATVSBHLD,
                loaikiemdinhatvsbhld: null
            })
        }
    }
}
export const DeleteLoaikiemdinhatvsbhld = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteLoaikiemdinhatvsbhld(payload)
            // console.log('chek api apiDeleteLoaikiemdinhatvsbhld', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListLoaikiemdinhatvsbhld());
                toast.success("Xóa thông tin thành công!")
                dispatch({
                    type: actionTypes.DELETE_LOAI_KIEM_DINH_ATVSBHLD,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_LOAI_KIEM_DINH_ATVSBHLD,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_LOAI_KIEM_DINH_ATVSBHLD,
                loaikiemdinhatvsbhld: null
            })
        }
    }
}