import {NumericFormat} from "react-number-format";

export const formatCurrencyVND = (data) => {
        return <NumericFormat
            className="fs-10px"
            value={Number(data).toFixed(1) || 0}
            displayType={'text'}
            thousandSeparator={true}
            suffix={'đ'}
        />;
    };
export const formatCurrencyVNDDS = (data) => {
        return <NumericFormat
            value={Number(data) || 0}
            displayType={'text'}
            thousandSeparator={true}
            suffix={' đ'}
        />;
    };
