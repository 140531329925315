import {Card, Table} from "reactstrap";
import React from "react";
import ColumnMulChart from "~/components/partials/analytics/chart-column/ColumnMulChart";

export const ComponentChuyenCan = (data) => {
    const dataChuyenCanByMonth = data?.dataChuyenCan?.dataChuyenCanByMonthTmp
    const dataChuyenCanByYear = data?.dataChuyenCan?.dataChuyenCanByYear
    const datatotal_month = data?.dataChuyenCan?.total_month
    const dataChart = data?.dataChuyenCan?.dataChart
    const congTieuChuan = data?.dataChuyenCan?.congTieuChuan
    return <>
        <div className="col-md-12 mt-3 row">
            <div className="col-12 overflow-scroll position-relative" style={{height:'500px'}}>
                <Table className="table table-bordered table-striped table-hover">
                    <thead key={'theadComponentChuyenCan'}>
                    <tr>
                        <th className="text-center">Phòng ban</th>
                        <th className="text-center">Mã NV</th>
                        <th className="text-center">Tên NV</th>
                        <th className="text-center">Số ngày làm việc trong tháng
                        </th>
                        <th className="text-center">Số ngày công TT</th>
                        <th className="text-center">Chuyên cần</th>
                    </tr>
                    </thead>
                   
                    {dataChuyenCanByMonth?.length > 0
                        ? dataChuyenCanByMonth?.map((item,index) => {
                            if (item?.data?.length > 0) {
                                
                                return (
                                    <tbody key={index+'ComponentChuyenCan'}>
                                    <tr className="bg-warning">
                                        <th className="text-center" colSpan="6">
                                            Báo cáo chuyên cần tháng:
                                            {item?.time[0]}/{item?.time[1]}
                                        </th>
                                    </tr>
                                    {item?.data.map((icc) => {
                                        return (
                                            <tr>
                                                <td className="text-center">{icc?.namePhongBan}</td>
                                                <td className="text-center">{icc?.nhanvien?.uuid}</td>
                                                <td className="text-center">{icc?.nhanvien?.name}</td>
                                                <td className="text-center">{icc?.ngayCongTieuChuan}</td>
                                                <td className="text-center">{icc?.chamcong}</td>
                                                <td className="text-center">{(icc?.chamcong * 100 / icc?.ngayCongTieuChuan).toFixed(2)}%</td>
                                            </tr>
                                        )
                                    })
                                    }
                                    </tbody>
                                )
                                
                            } else {
                                return (
                                    <tbody>
                                    <tr className="bg-warning">
                                        <th className="text-center" colSpan="6">
                                            Báo cáo chuyên cần tháng:
                                            {item?.time[0]}/{item?.time[1]}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="text-center" colSpan="6">Không có dữ liệu</td>
                                    </tr>
                                    </tbody>
                                )
                            }
                        })
                        : null}
                </Table>
            </div>
        </div>
        <div className="col-md-12 mt-3 row">
            <div className="col-12">
                <Table
                    className="table table-bordered table-striped table-hover">
                    <thead key={'thead1'}>
                    <tr>
                        <th className="text-center">STT</th>
                        <th className="text-center">Phòng Ban</th>
                        {datatotal_month?.length > 0
                            ? datatotal_month?.map((item, index) => {
                                return (
                                    <th className="text-center">{item}</th>
                                )
                            })
                            : null}
                    
                    </tr>
                    
                    </thead>
                    <tbody key={'tbody1'}>
                    
                    {dataChuyenCanByYear?.length > 0
                        ? dataChuyenCanByYear?.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td className="text-center">{index + 1}</td>
                                    <td className="text-center">{item?.title}</td>
                                    {
                                        item?.data.map((items) => {
                                            let percen = Number(items?.data?.reduce((a, v) => a + Number(v.chamcong), 0))
                                            let totalTmp = Number(items?.data?.length * congTieuChuan)
                                            let totalPercen = totalTmp !== 0 ? (100 * percen / totalTmp).toFixed(2) : 0
                                            return (
                                                <td className="text-center">{totalPercen ?? 0}</td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                        : null}
                    
                    </tbody>
                </Table>
            
            </div>
        </div>
        <div className="col-12">
            <div className="py-3">
                <h6 className="overline-title text-primary">
                    Biểu đồ báo cáo tình hình chuyên cần
                </h6>
            </div>
            <Card className="pt-4">
                <ColumnMulChart
                    columnMulChart={dataChart}
                />
            </Card>
        </div>
    </>
};

