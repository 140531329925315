import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {
    ModalEditNhanvienkiemdinhatvsbhld,
    ModalNhanvienkiemdinhatvsbhld
} from './Modal';
import Swal from 'sweetalert2';
import {Card} from 'reactstrap';
import Content from '~/layout/content/Content';
import {Block} from '~/components/block/Block';
import {
    Button,
    DataTable,
    Icon,
    PermissionUtils,
} from '~/components/Component';
import {dataPagination, dataSelectActive} from '~/utils/Datacustom'; // status
import {MenuKiemDinh} from './MenuTab/Menucontent';
import {apiDeleteNhanvienkiemdinhatvsbhld, apiGetNhanvienkiemdinhatvsbhld} from "~/services";
import {toast} from "react-toastify";
import actionTypes from "~/store/actions/actionTypes";
import {ExportToCsv} from "export-to-csv";
import {MaterialReactTable, useMaterialReactTable} from "material-react-table";
import {MRT_Localization_VI} from "material-react-table/locales/vi";
import {Box, IconButton, Tooltip} from "@mui/material";
import {Delete as DeleteIcon, Edit as EditIcon} from "@mui/icons-material";

const Nhanvienkiemdinhatvsbhld = () => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    
    
    const [payload, setPayload] = useState();
    
    // console.log('data', data)
    const fieldList = {
        NhanvienId: '',
        KiemdinhatvsbhldId: '',
        chiphi: '',
        dongia: '',
        phancongthuchien: '',
        ngaybatdau: '',
        ngayketthuc: '',
        TrangthaiId: '',
        ghichu: '',
    };
    
    async function fetchData() {
        try {
            const datapayloads = {
                typeGet: 1, // 1: lấy theo điều kiện phân trang, 2: lấy tất cả và không cần truyền d liệu phân trang
                start: pagination.pageIndex || 0,
                size: pagination.pageSize || 10,
                globalFilter: 'id',
                sorting: 'desc',
            };
            const response = await apiGetNhanvienkiemdinhatvsbhld(datapayloads);
            // const response = resData.data.data.rows;
            if (response?.data?.success === 200) {
                setData(response?.data?.data?.rows);
                setTotalRowCount(response?.data?.data?.count);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    
    const [showPhanquyen, setShowPhanquyen] = useState({});
    let dataPermissionUtils = PermissionUtils('nhanvienkiemdinhatvsbhld', 'all');
    useEffect(() => {
        if (dataPermissionUtils) {
            setShowPhanquyen(dataPermissionUtils);
        }
    }, [dataPermissionUtils]);
    useEffect(() => {
        fetchData().then(r => 'err')
    }, [pagination.pageIndex, pagination.pageSize, show, showEdit]);
    const handleAddNew = async () => {
        setShow(true);
    };
    const [dataIdNhanvienkiemdinhatvsbhld, setDataIdNhanvienkiemdinhatvsbhld] = useState([]);
    const handleEdit = async (data) => {
        setShowEdit(true);
        await setDataIdNhanvienkiemdinhatvsbhld(data);
    };
    const handleDelete = (data) => {
        Swal.fire({
            title: 'Bạn có chắc không?',
            text: 'Bạn sẽ không thể lấy lại khi xóa!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Không, bỏ qua!',
            confirmButtonText: 'Vâng, xóa nó đi!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const response = await apiDeleteNhanvienkiemdinhatvsbhld(payload)
                // console.log('chek api apiDeleteNhanvienkiemdinhatvsbhld', response?.data.success)
                if (response?.data.success === 200) {
                    await fetchData()
                    toast.success("Xóa thông tin thành công!")
                    
                    
                } else {
                    dispatch({
                        type: actionTypes.DELETE_NHAN_VIEN_KIEM_DINH_ATVSBHLD,
                        msg: 'ERR'
                    })
                    toast.error("Xóa thông tin thất bại!")
                }
                
                
            }
        });
    };
    const Example = () => {
        
        let dataAb = data;
        // Tạo dữ liêu export
        const Status = (cell) => ( // Function show trạng thái
            <Box
                component="span"
                sx={(theme) => ({
                    backgroundColor: cell === 1 ? '#1ee0ac' : '#e65100',
                    fontSize: '10px',
                    borderRadius: '0.25rem',
                    color: '#fff',
                    p: '0.25rem',
                })}
            >
                {dataSelectActive.find(select => select.value === cell)?.label || dataSelectActive[0].label}
            </Box>
        );
        const columns = useMemo(
            () => [
                {
                    Header: '#', // Tiêu đề cột số thứ tự
                    header: 'STT', // Tiêu đề cột số thứ tự
                    id: 'rowNumber', // ID của cột
                    size: 50,
                    Cell: ({row}) => <div>{row?.index + 1}</div>, // Giá trị của cột là số thứ tự
                    enableSorting: false,
                    enablePinning: true,
                },
                {
                    accessorKey: 'Nhanvien.hoten',
                    enablePinning: true,
                    header: 'Họ Tên',
                },
                {
                    accessorKey: 'Nhanvien.uuid',
                    enablePinning: true,
                    header: 'Mã nhân viên',
                },
                {
                    accessorKey: 'Kiemdinhatvsbhld.tenkiemdinh',
                    enablePinning: true,
                    header: 'Thông tin kiểm định',
                },
                {
                    accessorKey: 'phancongthuchien',
                    enablePinning: true,
                    header: 'Người thực hiện',
                },
                {
                    accessorKey: 'TrangthaiId',
                    header: 'Trạng thái',
                    enablePinning: true,
                    Cell: ({cell}) => Status(Number(cell?.getValue()) || 0),
                },
            ],
            [],
        );
        const csvOptions = {
            fileName: 'csv-data',
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            useBom: true,
            useKeysAsHeaders: false,
            headers: columns.map((c) => c.header),
        };
        
        
        const csvExporter = new ExportToCsv(csvOptions);
        
        const handleExportData = () => {
            
            const dataExport = dataAb?.map((item, indexx) => {
                let abc = {}
                abc.rowNumber = indexx + 1
                abc.hoten = item?.Nhanvien?.hoten
                abc.uuid = item?.Nhanvien?.uuid
                abc.tenkiemdinh = item?.Kiemdinhatvsbhld.tenkiemdinh
                abc.phancongthuchien = item?.phancongthuchien
                
                return abc
            })
            if (dataExport.length === 0) {
                toast.error("Không có dữ liệu để xuất!")
                return;
            }
            csvExporter.generateCsv(dataExport);
        };
        
        const rowActions = ({row, table}) => ( // Thêm nút sửa xóa vào hàng
            <Box sx={{display: 'flex', gap: '1rem'}}>
                {+showPhanquyen?.sua === 1 && (
                    <Tooltip title="Edit">
                        <IconButton onClick={() => handleEdit(row.original)}>
                            <EditIcon/>
                        </IconButton>
                    </Tooltip>
                )}
                {+showPhanquyen?.xoa === 1 && (
                    <Tooltip title="Delete">
                        <IconButton color="error" onClick={() => handleDelete({
                            id: row.original.id,
                            status: row.original.TrangthaiId
                        })}>
                            <DeleteIcon/>
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
        );
        const topToolbarCustomActions = ({table}) => ( // Thêm nút thêm mới và xuất dữ liệu
            <Box sx={{display: 'flex', gap: '1rem'}}>
                {+showPhanquyen?.them === 1 && (
                    <>
                        <Button variant="contained" color="primary" onClick={handleAddNew} sx={{mr: 2}}>
                            + Thêm mới
                        </Button>
                        <Button color="primary" onClick={handleExportData} variant="contained">
                            <Icon name="download-cloud me-2"></Icon> Xuất dữ liệu
                        </Button>
                    </>
                )}
            </Box>
        );
        const state = { // Trạng thái của bảng
            expanded: true,
            columnFilters,
            globalFilter,
            isLoading: dataAb.length === 0,
            pagination,
            sorting,
        };
        const displayColumnDefOptions = { // Các cột hiển thị
            'mrt-row-actions': {
                sx: {width: '200px', paddingRight: '10px'},
                header: 'Thao tác',
                enableOrdering: true,
                enablePinning: true,
                enableColumnActions: true,
            }
        };
        const initialState = { // Các trạng thái ban đầu của bảng đc gim
            columnPinning: {left: ['rowNumber'], right: ['mrt-row-actions']},
            density: 'compact'
        };
        const table = useMaterialReactTable({
                columns,
                data,
                enableColumnResizing: false,
                enableDensityToggle: false,
                initialState: initialState,
                displayColumnDefOptions: displayColumnDefOptions,
                state: state,
                defaultDisplayColumn: {enableResizing: true},
                rowCount: totalRowCount,
                manualFiltering: true,
                manualPagination: true,
                manualSorting: true,
                onPaginationChange: setPagination,
                onColumnFiltersChange: setColumnFilters,
                onGlobalFilterChange: setGlobalFilter,
                onSortingChange: setSorting,
                localization: MRT_Localization_VI,
                enableRowActions: true,
                enableColumnPinning: true,
                renderTopToolbarCustomActions: topToolbarCustomActions,
                renderRowActions: rowActions,
                muiPaginationProps: {
                    rowsPerPageOptions: dataPagination,
                },
            }
        );
        
        
        return (
            <MaterialReactTable
                table={table}
            />
        );
    };
    return (
        <React.Fragment>
            {/*<Head title="User List - Regular"></Head>*/}
            <Content>
                <Card className="mt-0">
                    <div className="card-aside-wrap row">
                        <div className="card-inner card-inner-lg px-4 pt-1 pb-0">
                            <h4>Quản Lý An Toàn - Vệ sinh - BHLĐ</h4>
                            <MenuKiemDinh/>
                        
                        </div>
                    </div>
                </Card>
                <Block>
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner position-relative card-tools-toggle">
                            <Example/>
                        </div>
                    </DataTable>
                </Block>
                
                <ModalNhanvienkiemdinhatvsbhld show={show} fieldList={fieldList} setShow={() => setShow(false)}/>
                <ModalEditNhanvienkiemdinhatvsbhld
                    show={showEdit}
                    fieldList={fieldList}
                    setShow={() => setShowEdit(false)}
                    dataIdNhanvienkiemdinhatvsbhld={dataIdNhanvienkiemdinhatvsbhld}
                />
            </Content>
        </React.Fragment>
    );
    
};

export default Nhanvienkiemdinhatvsbhld;
