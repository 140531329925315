import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import config from '~/config';

export const MenuNhanVien = () => {
    const location = useLocation();
    const active = location?.pathname?.split('/');
    return (
        <div>
            <ul className="nk-nav nav nav-tabs">
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.cong_ty ? 'active' : ''}`}
                        to={`${config.he_thong}${config.nhan_vien}`}
                    >
                        Hồ sơ nhân viên
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.cong_ty ? 'active' : ''}`}
                        to={`${config.he_thong}${config.quyet_dinh}?type=bo-nhiem`}
                    >
                        Bổ nhiệm
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.cau_hinh_doanh_nghiep ? 'active' : ''}`}
                        to={`${config.he_thong}${config.quyet_dinh}?type=mien-nhiem`}
                    >
                        Miễn nhiệm
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.email_server ? 'active' : ''}`}
                        to={`${config.he_thong}${config.quyet_dinh}?type=thuyen-chuyen`}
                    >
                        Thuyên chuyển
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.chi_nhanh ? 'active' : ''}`}
                        to={`${config.he_thong}${config.quyet_dinh}?type=nghi-viec`}
                    >
                        Nghỉ việc
                    </Link>
                </li>
               
            </ul>
        </div>
    );
};
export const MenuDoanhnghiep = () => {
    const location = useLocation();
    const active = location?.pathname?.split('/');

    return (
        <div>
            <ul className="nk-nav nav nav-tabs">
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.cong_ty ? 'active' : ''}`}
                        to={`${config.he_thong}${config.cong_ty}`}
                    >
                        Thông tin chung
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.cau_hinh_doanh_nghiep ? 'active' : ''}`}
                        to={`${config.he_thong}${config.cau_hinh_doanh_nghiep}`}
                    >
                        Doanh nghiệp
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.email_server ? 'active' : ''}`}
                        to={`${config.he_thong}${config.email_server}`}
                    >
                        Email server
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.chi_nhanh ? 'active' : ''}`}
                        to={`${config.he_thong}${config.chi_nhanh}`}
                    >
                        Chi nhánh
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.phong_ban ? 'active' : ''}`}
                        to={`${config.he_thong}${config.phong_ban}`}
                    >
                        Phòng ban
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.chuc_vu ? 'active' : ''}`}
                        to={`${config.he_thong}${config.chuc_vu}`}
                    >
                        Chức vụ
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.nhom_nhan_vien ? 'active' : ''}`}
                        to={`${config.he_thong}${config.nhom_nhan_vien}`}
                    >
                        Nhóm nhân viên
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export const MenuLuongCapThuong = () => {
    const location = useLocation();
    const active = location?.pathname?.split('/');

    return (
        <div>
            <ul className="nk-nav nav nav-tabs">
                {/*<li className="nav-item">*/}
                {/*    <Link*/}
                {/*        className={`nav-link ${active[2] === config.kieu_luong ? 'active' : ''}`}*/}
                {/*        to={`${config.he_thong}${config.kieu_luong}`}*/}
                {/*    >*/}
                {/*        Kiểu lương*/}
                {/*    </Link>*/}
                {/*</li>*/}
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.phu_cap ? 'active' : ''}`}
                        to={`${config.he_thong}${config.phu_cap}`}
                    >
                        Phụ cấp
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.cau_hinh_thanh_phan_luong ? 'active' : ''}`}
                        to={`${config.he_thong}${config.cau_hinh_thanh_phan_luong}`}
                    >
                        Thành phần lương
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.cau_hinh_cong_thuc_tinh_luong ? 'active' : ''}`}
                        to={`${config.he_thong}${config.cau_hinh_cong_thuc_tinh_luong}`}
                    >
                        Công thức tính lương
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.cau_hinh_chuyen_can ? 'active' : ''}`}
                        to={`${config.he_thong}${config.cau_hinh_chuyen_can}`}
                    >
                        Cấu hình chuyên cần
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.cau_hinh_tham_nien ? 'active' : ''}`}
                        to={`${config.he_thong}${config.cau_hinh_tham_nien}`}
                    >
                        Cấu hình thâm niên
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.cau_hinh_day_chuyen ? 'active' : ''}`}
                        to={`${config.he_thong}${config.cau_hinh_day_chuyen}`}
                    >
                        Cấu hình phụ cấp dây chuyền
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.cau_hinh_san_luong ? 'active' : ''}`}
                        to={`${config.he_thong}${config.cau_hinh_san_luong}`}
                    >
                        Cấu hình sản lượng
                    </Link>
                </li>
            </ul>
        </div>
    );
};
// export const MenuTangcaLamviec = () => {
//     const location = useLocation();
//     const active = location?.pathname?.split('/');

//     return (
//         <div>
//             <ul className="nk-nav nav nav-tabs">
//                 <li className="nav-item">
//                     <Link
//                         className={`nav-link ${active[2] === config.cau_hinh_ca_lam_viec ? 'active' : ''}`}
//                         to={`${config.he_thong}${config.cau_hinh_ca_lam_viec}`}
//                     >
//                         Ca làm việc
//                     </Link>
//                 </li>
//                 <li className="nav-item">
//                     <Link
//                         className={`nav-link ${active[2] === config.cau_hinh_tang_ca ? 'active' : ''}`}
//                         to={`${config.he_thong}${config.cau_hinh_tang_ca}`}
//                     >
//                         Tăng ca
//                     </Link>
//                 </li>
//             </ul>
//         </div>
//     );
// };
export const MenuGiaoViecBang = () => {
    const location = useLocation();
    const active = location?.pathname?.split('/');

    return (
        <div>
            <ul className="nk-nav nav nav-tabs">
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.giao_viec ? 'active' : ''}`}
                        to={`${config.he_thong}${config.giao_viec}`}
                    >
                        Danh sách
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.bang_giao_viec ? 'active' : ''}`}
                        to={`${config.he_thong}${config.bang_giao_viec}`}
                    >
                        Bảng
                    </Link>
                </li>
            </ul>
        </div>
    );
};
export const MenuKiemDinh = () => {
    const location = useLocation();
    const active = location?.pathname?.split('/');

    return (
        <div>
            <ul className="nk-nav nav nav-tabs">
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.kiem_dinh_atvsbhld ? 'active' : ''}`}
                        to={`${config.he_thong}${config.kiem_dinh_atvsbhld}`}
                    >
                        Danh sách theo dõi ATVS - BHLD
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.loai_kiem_dinh_atvsbhld ? 'active' : ''}`}
                        to={`${config.he_thong}${config.loai_kiem_dinh_atvsbhld}`}
                    >
                        Loại ATVS - BHLD
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.nhan_vien_kiem_dinh_atvsbhld ? 'active' : ''}`}
                        to={`${config.he_thong}${config.nhan_vien_kiem_dinh_atvsbhld}`}
                    >
                        Nhân viên giám sát ATVS - BHLD
                    </Link>
                </li>
            </ul>
        </div>
    );
};
export const MenuQuyXepLoai = () => {
    const location = useLocation();
    const active = location?.pathname?.split('/');

    return (
        <div>
            <ul className="nk-nav nav nav-tabs">
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.quan_ly_quy_thuong ? 'active' : ''}`}
                        to={`${config.he_thong}${config.quan_ly_quy_thuong}`}
                    >
                        Quỹ thưởng
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.xep_loai ? 'active' : ''}`}
                        to={`${config.he_thong}${config.xep_loai}`}
                    >
                        Xếp loại
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.xep_loai_nhan_vien ? 'active' : ''}`}
                        to={`${config.he_thong}${config.xep_loai_nhan_vien}`}
                    >
                        Xếp loại nhân viên
                    </Link>
                </li>
            </ul>
        </div>
    );
};
export const MenuMoiQuanHe = () => {
    const location = useLocation();
    const active = location?.pathname?.split('/');

    return (
        <div>
            <ul className="nk-nav nav nav-tabs">
                {/*<li className="nav-item">*/}
                {/*    <Link*/}
                {/*        className={`nav-link ${active[2] === config.moi_quan_he_nhan_vien ? 'active' : ''}`}*/}
                {/*        to={`${config.he_thong}${config.moi_quan_he_nhan_vien}`}*/}
                {/*    >*/}
                {/*        Danh sách các vấn đề*/}
                {/*    </Link>*/}
                {/*</li>*/}
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.moi_quan_he_lao_dong ? 'active' : ''}`}
                        to={`${config.he_thong}${config.moi_quan_he_lao_dong}`}
                    >
                        Danh sách các vấn đề phát sinh
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export const MenuNghiphepNghile = () => {
    const location = useLocation();
    const active = location?.pathname?.split('/');

    return (
        <div>
            <ul className="nk-nav nav nav-tabs">
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.cau_hinh_nghi_phep ? 'active' : ''}`}
                        to={`${config.he_thong}${config.cau_hinh_nghi_phep}`}
                    >
                        Cấu hình nghỉ phép
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.ly_do_nghi_phep ? 'active' : ''}`}
                        to={`${config.he_thong}${config.ly_do_nghi_phep}`}
                    >
                        Cấu hình lý do nghỉ phép
                    </Link>
                </li>

                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.cau_hinh_nghi_le ? 'active' : ''}`}
                        to={`${config.he_thong}${config.cau_hinh_nghi_le}`}
                    >
                        Cấu hình nghỉ lễ
                    </Link>
                </li>
                {/* <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.cau_hinh_phe_duyet ? 'active' : ''}`}
                        to={`${config.he_thong}${config.cau_hinh_phe_duyet}`}
                    >
                        Cấu hình phê duyệt
                    </Link>
                </li> */}

                {/*<li className="nav-item">*/}
                {/*    <Link*/}
                {/*        className={`nav-link ${active[2] === config.cau_hinh_nghi_le ? 'active' : ''}`}*/}
                {/*        to={`${config.he_thong}${config.cau_hinh_nghi_le}`}>*/}
                {/*        Cấu hình nghỉ lễ*/}
                {/*    </Link>*/}
                {/*</li>*/}
            </ul>
        </div>
    );
};

export const MenuPhuongTien = () => {
    const location = useLocation();
    const active = location?.pathname?.split('/');

    return (
        <div>
            <ul className="nk-nav nav nav-tabs">
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.phuong_tien ? 'active' : ''}`}
                        to={`${config.he_thong}${config.phuong_tien}`}
                    >
                        Phương Tiện
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.loai_phuong_tien ? 'active' : ''}`}
                        to={`${config.he_thong}${config.loai_phuong_tien}`}
                    >
                        Loại phương tiện
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.phi_phu ? 'active' : ''}`}
                        to={`${config.he_thong}${config.phi_phu}`}
                    >
                        Phí phụ
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.ke_hoach_cua_xe ? 'active' : ''}`}
                        to={`${config.he_thong}${config.ke_hoach_cua_xe}`}
                    >
                        Kế hoạch xe chạy
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.dang_kiem_phuong_tien ? 'active' : ''}`}
                        to={`${config.he_thong}${config.dang_kiem_phuong_tien}`}
                    >
                        Đăng Kiểm phương tiện
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.bao_duong_phuong_tien ? 'active' : ''}`}
                        to={`${config.he_thong}${config.bao_duong_phuong_tien}`}
                    >
                        Bảo dưỡng phương tiện
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export const MenuHopdong = () => {
    const location = useLocation();
    const active = location?.pathname?.split('/');

    return (
        <div>
            <ul className="nk-nav nav nav-tabs">
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.hop_dong_lao_dong ? 'active' : ''}`}
                        to={`${config.he_thong}${config.hop_dong_lao_dong}`}
                    >
                        Hợp đồng lao động
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.loai_hop_dong ? 'active' : ''}`}
                        to={`${config.he_thong}${config.loai_hop_dong}`}
                    >
                        Loại hợp đồng
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.mau_hop_dong ? 'active' : ''}`}
                        to={`${config.he_thong}${config.mau_hop_dong}`}
                    >
                        Mẫu hợp đồng
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export const MenuTuyenDung = () => {
    const location = useLocation();
    const active = location?.pathname?.split('/');

    return (
        <div>
            <ul className="nk-nav nav nav-tabs">
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.vi_tri_tuyen_dung ? 'active' : ''}`}
                        to={`${config.he_thong}${config.vi_tri_tuyen_dung}`}
                    >
                        Vị trí tuyển dụng
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.bo_cau_hoi_phong_van ? 'active' : ''}`}
                        to={`${config.he_thong}${config.bo_cau_hoi_phong_van}`}
                    >
                        Bộ câu hỏi phỏng vấn
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.ung_vien ? 'active' : ''}`}
                        to={`${config.he_thong}${config.ung_vien}`}
                    >
                        Ứng viên
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.danh_gia ? 'active' : ''}`}
                        to={`${config.he_thong}${config.danh_gia}`}
                    >
                        Đánh giá ứng viên
                    </Link>
                </li>
            </ul>
        </div>
    );
};
export const MenuBangCong = () => {
    const location = useLocation();
    const active = location?.pathname?.split('/');

    return (
        <div>
            <ul className="nk-nav nav nav-tabs">
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.bang_chot_cong ? 'active' : ''}`}
                        to={`${config.he_thong}${config.bang_chot_cong}`}
                    >
                        Bảng chấm công chi tiết
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.bang_cham_cong_tong_hop ? 'active' : ''}`}
                        to={`${config.he_thong}${config.bang_cham_cong_tong_hop}`}
                    >
                        Bảng chấm công tổng hợp
                    </Link>
                </li>
                {/* <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.chinh_sua_cham_cong ? 'active' : ''}`}
                        to={`${config.he_thong}${config.chinh_sua_cham_cong}`}
                    >
                        Chỉnh sửa chấm công
                    </Link>
                </li> */}
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.du_lieu_cham_cong ? 'active' : ''}`}
                        to={`${config.he_thong}${config.du_lieu_cham_cong}`}
                    >
                        Dữ liệu chấm công
                    </Link>
                </li>
            </ul>
        </div>
    );
};
export const MenuTangca = () => {
    const location = useLocation();
    const active = location?.pathname?.split('/');

    return (
        <div>
            <ul className="nk-nav nav nav-tabs">
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.phieu_tang_ca ? 'active' : ''}`}
                        to={`${config.he_thong}${config.phieu_tang_ca}`}
                    >
                        Quản lý phiếu tăng ca
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.phieu_day_chuyen ? 'active' : ''}`}
                        to={`${config.he_thong}${config.phieu_day_chuyen}`}
                    >
                        Quản lý phiếu dây chuyền
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.cau_hinh_tang_ca ? 'active' : ''}`}
                        to={`${config.he_thong}${config.cau_hinh_tang_ca}`}
                    >
                        Cấu hình tăng ca
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.cau_hinh_tinh_luong_tang_ca ? 'active' : ''}`}
                        to={`${config.he_thong}${config.cau_hinh_tinh_luong_tang_ca}`}
                    >
                        Cấu hình tính lương tăng ca
                    </Link>
                </li>
            </ul>
        </div>
    );
};
export const MenuPhanca = () => {
    const location = useLocation();
    const active = location?.pathname?.split('/');
    
    return (
        <div>
            <ul className="nk-nav nav nav-tabs">
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.phan_ca_tong_hop ? 'active' : ''}`}
                        to={`${config.he_thong}${config.phan_ca_tong_hop}`}
                    >
                        Bảng phân ca tổng hợp
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.phan_ca ? 'active' : ''}`}
                        to={`${config.he_thong}${config.phan_ca}`}
                    >
                        Phân ca
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active[2] === config.cau_hinh_ca_lam_viec ? 'active' : ''}`}
                        to={`${config.he_thong}${config.cau_hinh_ca_lam_viec}`}
                    >
                        Ca làm việc
                    </Link>
                </li>
            </ul>
        </div>
    );
};
