import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputForm, Button } from '~/components/Form';
import * as actions from '~/store/actions';
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { validateChucVu } from '~/request/validateChucVu';
import {DataTableBody, DataTableHead, DataTableItem, DataTableRow} from "~/components/table/DataTable";
import exportFromJSON from "export-from-json";

const ModalEditQuanlythamnien = ({ show, setShow, dataIdQuanlythamnien, fieldList ,typeExport }) => {
    const [type, setType] = useState(typeExport);
    const [payload, setPayloadQuanlythamnien] = useState([]);
    const [dataTmp, setDataTmp] = useState([]);
   
    useEffect(() => {
        if (dataIdQuanlythamnien.length > 0 && dataIdQuanlythamnien){
            setPayloadQuanlythamnien(Object.keys(dataIdQuanlythamnien[0]))
        }
        
        setType(typeExport)
    }, [type,dataIdQuanlythamnien]);
    const handleSubmit = async () => {
        const fieldsToExtract = dataTmp;
        const newArray = [];
        for (let i = 0; i < dataIdQuanlythamnien.length; i++) {
            const newRow = {};
            for (let j = 0; j < fieldsToExtract.length; j++) {
                const field = fieldsToExtract[j];
                newRow[field] = dataIdQuanlythamnien[i][field];
            }
            newArray.push(newRow);
        }
        const fileName = 'data-file';
        const exportType = typeExport !== 'csv' ? exportFromJSON.types.xls : exportFromJSON.types.csv
    
        exportFromJSON({ data: newArray, fileName: fileName, exportType: exportType })
    };
    const selectorCheck = (e,i) => {
        let items = [...dataTmp]
        if(e.target.checked)
            setDataTmp(current => [...current, i]);
        else {
            let index = items.indexOf(i);
            items.splice(index,1);
            setDataTmp(items);
        }
    };
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">Xuất Excell {typeExport}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-4">
                        <DataTableBody>
                            <DataTableHead>
                                <DataTableRow>
                                    <span className="text-danger">Feild Name</span>
                                </DataTableRow>
                                <DataTableRow size="mb">
                                    <div className="custom-control custom-control-sm custom-checkbox notext">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            onChange={(e) => selectorCheck(e,'all')}
                                            id={'all'}
                                        />
                                        <label className="custom-control-label" htmlFor={'all'}></label>
                                    </div>
                                </DataTableRow>
                            </DataTableHead>
        
                            {payload.length > 0
                                ? payload.map((item,index) => {
                                    return (
                                        <DataTableItem key={index}>
                                            <DataTableRow>
                                                <span className="sub-text">{item}</span>
                                            </DataTableRow>
                                            <DataTableRow className="nk-tb-col-check">
                                                <div className="custom-control custom-control-sm custom-checkbox notext">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        onChange={(e) => selectorCheck(e,item)}
                                                        id={item}
                                                    />
                                                    <label className="custom-control-label" htmlFor={item}></label>
                                                </div>
                                            </DataTableRow>
                                        </DataTableItem>
                                    )
                                })
                                :null}
                        </DataTableBody>
                    </div>
                    <div className="col-6">
                        <Button text="Tiến hành xuất excell" bClass="btn btn-primary px-5" textColor onClick={handleSubmit} />

                    </div>
                </div>
                
            </Modal.Body>
        </Modal>
    );
};

export default ModalEditQuanlythamnien;
