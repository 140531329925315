import React, { useState, useEffect } from 'react';
import { InputForm, Button } from '~/components/Form';
import { Icon, SelectNhanvien } from '~/components/Component';

import { validateNhanVienKiemDinh } from '~/request/validateNhanVienKiemDinh';
import '../Modal.scss';
import { dataSelectDanhGia } from '~/utils/Datacustom';
import Select from 'react-select';
import { Modal, ModalBody, Input } from 'reactstrap';
import {apiGetKiemdinhatvsbhld, apiNewNhanvienkiemdinhatvsbhld} from "~/services";
import {toast} from "react-toastify";

const ModalNhanvienkiemdinhatvsbhld = ({ show, setShow, fieldList }) => {
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayload] = useState(fieldList);
    const [listNhanvien, setlistNhanvien] = useState([]);
    const [listKiemDinh, setLisKiemDinh] = useState([]);
    const [showSelect, setShowSelect] = useState(false);

    // set dữ liệu về [] khi click lưu mới
    useEffect(() => {
        listNhanvien.length > 0 && setPayload((prev) => ({
            ...prev,
            ['NhanvienId']: { label: listNhanvien[0]?.label, value: listNhanvien[0]?.value },
        }));
    }, [listNhanvien]);
    
    
    async function fetchDataKiemDinh() {
        try {
            const datapayloads = {
                typeGet: 2,
            };
            const response = await apiGetKiemdinhatvsbhld(datapayloads);
            // console.log('response', response)
            if (response?.data?.success === 200) {
                let KiemDinhId = response?.data?.data?.rows?.map((item) => ({ label: item.tenkiemdinh, value: item.id }));
                setLisKiemDinh(KiemDinhId);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    
    useEffect(() => {
        fetchDataKiemDinh().then(r => 'err');
    }, [show]);

    useEffect(() => {
        show && setPayload(fieldList);
    }, [show]);
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayload((prev) => ({ ...prev, [statename]: selected }));
    };
    const handleSubmit = async () => {
        let invalids = validate(payload);
        if (invalids === 0) {
            let datapayload = {
                data: [
                    {
                        NhanvienId: payload?.NhanvienId?.value,
                        KiemdinhatvsbhldId: payload?.KiemdinhatvsbhldId?.value,
                        chiphi: payload?.chiphi,
                        soluong: payload?.soluong,
                        dongia: payload?.dongia,
                        phancongthuchien: payload?.NhanvienId?.label,
                        ngaybatdau: payload?.ngaybatdau,
                        ngayketthuc: payload?.ngayketthuc,
                        TrangthaiId: payload?.TrangthaiId?.value,
                        ghichu: payload?.ghichu,
                    },
                ],
            };
            const response = await apiNewNhanvienkiemdinhatvsbhld(datapayload)
            if (response?.data.success === 200) {
                setShow(false);
                toast.success("Thêm mới thành công!")
            } else {
                toast.error("Thêm mới thất bại!")
            }
        }
    };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateNhanVienKiemDinh(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateNhanVienKiemDinh(item[0], item[1])]);
                invalids++;
            }
        });

        return invalids;
    };
    return (
        <React.Fragment>
            <Modal isOpen={show} className="modal-dialog-centered modal-90w" size="lg" toggle={setShow}>
                <ModalBody>
                <Icon name="cross-sm float-end fs-2" onClick={setShow}></Icon>
                    <h5 className="title mb-3">Thêm mới nhân viên kiểm định</h5>
                    <div className="row">
                        <div className="form-group col-md-6" onClick={() => setShowSelect(true)}>
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                Chọn Nhân Viên <b className="text-danger">(*)</b>
                            </label>
                            <Select value={payload.NhanvienId} name="NhanvienId" onFocus={() => setInvalidFields([])} />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'NhanvienId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'NhanvienId')?.message}
                                </small>
                            )}
                        </div>

                        <div className="form-group col-md-6">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                Kiểm định <b className="text-danger">(*)</b>
                            </label>
                            <Select
                                value={payload.KiemdinhatvsbhldId}
                                onChange={handleOnSelect}
                                options={listKiemDinh}
                                placeholder={'Chọn kiểm định'}
                                name="KiemdinhatvsbhldId"
                                onFocus={() => setInvalidFields([])}
                            />

                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'KiemdinhatvsbhldId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'KiemdinhatvsbhldId')?.message}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                Trạng thái <b className="text-danger">(*)</b>
                            </label>
                            <Select
                                value={payload.TrangthaiId}
                                onChange={handleOnSelect}
                                options={dataSelectDanhGia}
                                placeholder={'Trạng thái'}
                                name="TrangthaiId"
                                onFocus={() => setInvalidFields([])}
                            />

                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'TrangthaiId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'TrangthaiId')?.message}
                                </small>
                            )}
                        </div>

                        <div className="form-group col-md-4">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Chi phí'}
                                value={payload.chiphi}
                                setValue={setPayload}
                                keyPayload={'chiphi'}
                                type="number"
                                validate
                            />
                        </div>
                        <div className="form-group col-md-4">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Số lượng'}
                                value={payload.soluong}
                                setValue={setPayload}
                                keyPayload={'soluong'}
                                type="number"
                                validate
                            />
                        </div>

                        <div className="form-group col-md-4">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Đơn giá'}
                                value={payload.dongia}
                                setValue={setPayload}
                                keyPayload={'dongia'}
                                type="number"
                                validate
                            />
                        </div>
                        <div className="form-group col-md-4">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Ngày bắt đầu'}
                                value={payload.ngaybatdau}
                                setValue={setPayload}
                                keyPayload={'ngaybatdau'}
                                type="date"
                                validate
                            />
                        </div>
                        <div className="form-group col-md-4">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Ngày kết thúc'}
                                value={payload.ngayketthuc}
                                setValue={setPayload}
                                keyPayload={'ngayketthuc'}
                                type="date"
                                validate
                            />
                        </div>
                        <div className="form-group col-md-12 mt-3 mb-2">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                <span>
                                    Ghi chú <b className="text-danger">(*)</b>
                                </span>
                            </label>
                            <Input
                                value={payload.ghichu}
                                onChange={(e) => setPayload((prev) => ({ ...prev, ['ghichu']: e.target.value }))}
                                rows={'2'}
                                name="ghichu"
                                type="textarea"
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'ghichu') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'ghichu')?.message}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className="col-12 mt-5">
                        <Button text="Thêm mới" bClass="btn btn-primary px-5" textColor onClick={handleSubmit} />
                    </div>
                </ModalBody>
            </Modal>
            <SelectNhanvien
                showSelect={showSelect}
                setlistNhanvien={setlistNhanvien}
                setShowSelect={() => setShowSelect(false)}
            />
        </React.Fragment>
    );
};

export default ModalNhanvienkiemdinhatvsbhld;
