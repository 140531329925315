import React, { useState, useEffect } from 'react';
import { DropdownToggle, DropdownMenu, UncontrolledDropdown } from 'reactstrap';

import Icon from '../../../../components/icon/Icon';
import data from './NotificationData';
import { differenceInHours, differenceInMinutes, parseISO, formatDistanceToNow } from 'date-fns';
import { vi } from 'date-fns/locale'; // Import locale for Vietnamese
import './Notification.scss';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '~/store/actions';
import config from '~/config';
import { Link } from "react-router-dom";

const NotificationItem = (props) => {
    const dispatch = useDispatch();
    const { icon, iconStyle, text, time, id, status, url, loaiId, UserId } = props;
    const postTime = parseISO(time);
    return (
        <div className="nk-notification-item" key={id} id={id} >
             <Link to={`${ config.he_thong + url}?thong-bao=${loaiId}&xem=${Math.floor(Math.random() * 9999999)}`} className="link link-sm" onClick={() => hendEditThongbao(id, dispatch, UserId)}>
            <div className="nk-notification-icon">
                <Icon name={icon} className={[`icon-circle ${iconStyle ? ' ' + iconStyle : ''}`]} />
            </div>
            </Link>
            <div className="nk-notification-content">
                <Link to={`${ config.he_thong + url}?thong-bao=${loaiId}&xem=${Math.floor(Math.random() * 9999999)}`} className="link link-sm" onClick={() => hendEditThongbao(id, dispatch, UserId)}>
                    <div className={status === 0 ? 'nk-notification-text fw-bold' : 'text-muted'}>{text}</div>
                    {/* <div className="nk-notification-time">{time}</div> */}
                </Link>
                <Link to={`${ config.he_thong + url}?thong-bao=${loaiId}&xem=${Math.floor(Math.random() * 9999999)}`} className="link link-sm" onClick={() => hendEditThongbao(id, dispatch, UserId)}>
                <div className="nk-notification-time">
                    <PostNotification postTime={postTime} />
                </div>
                </Link>
            </div>
        </div>
    );
};
const hendEditThongbao = (idtb, dispatch, UserId) => {
    dispatch(actions.EditThongbao({  id: idtb, TrangthaiId: 1, UserId: UserId}));
};
const PostNotification = ({ postTime }) => {
    const [notification, setNotification] = useState('');

    useEffect(() => {
        const calculateTimeDifference = () => {
            const currentTime = new Date();
            const diffHours = differenceInHours(currentTime, postTime);
            const diffMinutes = differenceInMinutes(currentTime, postTime) % 60;

            if (diffHours === 0) {
                setNotification(`${diffMinutes} phút trước`);
            } else if (diffHours < 24) {
                setNotification(`${diffHours} giờ ${diffMinutes} phút trước`);
            } else if (diffHours < 720) {
                // 720 hours = 1 month
                setNotification(formatDistanceToNow(postTime, { locale: vi }) + ' trước');
            } else {
                setNotification(`${diffHours} giờ ${diffMinutes} phút trước`);
            }
        };

        calculateTimeDifference();
    }, [postTime]);

    return <div>{notification}</div>;
};

const Notification = () => {
    //   const notificationCount = 10;
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [dataCount, setDataCount] = useState([]);
    // console.log('dataCount', dataCount)
    const { thongbao } = useSelector((state) => state.quanly);
    // console.log('thongbao', thongbao)
    const { user } = useSelector((state) => state.app);
    useEffect(() => {
        if (thongbao && user) {
            setData([...thongbao]);
            setUser(user);
            const cu = thongbao.filter((item) => item?.TrangthaiId === 0);
            setDataCount(cu);
        }
    }, [thongbao, user]);
    const [userdata, setUser] = useState(null);
    // console.log('userdata', userdata);
    const Danhdaudadoc = () => {
        data.map((item) => {
            dispatch(actions.EditThongbao({  id: item.id, TrangthaiId: 1, UserId: userdata.id}));
        });
    }
    useEffect(() => {
        if(userdata !== null){
            dispatch(actions.getListThongbao({  UserId: userdata.id, page: '1', limit: '20', order: 'desc', by: 'id', view: 0}));
        }
    }, [userdata]);

    return (
        <UncontrolledDropdown className="user-dropdown">
            <DropdownToggle tag="a" className="dropdown-toggle nk-quick-nav-icon">
                {/* <div className="icon-status icon-status-info"> */}
                <div className="notification-status">
                    <Icon name="bell" />
                    {dataCount.length > 0 ? (<span className="notification-counter">{dataCount.length}</span>) : ('')}
                </div>
            </DropdownToggle>
            <DropdownMenu end className="dropdown-menu-xl dropdown-menu-s1">
                <div className="dropdown-head">
                    <span className="sub-title nk-dropdown-title">Thông báo</span>
                    <a href="#markasread" onClick={(ev) => {
                                                    ev.preventDefault();
                                                    Danhdaudadoc();
                                                }}>
                        Đánh dấu đã đọc
                    </a>
                </div>
                <div className="dropdown-body">
                    <div className="nk-notification">
                        {data.map((item) => {
                            return (
                                <NotificationItem
                                    key={item?.id}
                                    id={item?.id}
                                    icon={item?.icon}
                                    iconStyle={item?.iconStyle}
                                    text={item?.noidung}
                                    time={item?.createdAt}
                                    status={item?.TrangthaiId}
                                    url={item?.url}
                                    loaiId={item?.loaiId}
                                    UserId={userdata.id}
                                />
                            );
                        })}
                    </div>
                </div>
                <div className="dropdown-foot center">
                <Link to={`${ config.he_thong + config.thong_bao}`} className="link link-sm">
                    Xem tất cả
                </Link>
                </div>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};

export default Notification;
