import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputForm, Button } from '~/components/Form';
import * as actions from '~/store/actions';
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { dataSelectNhomNV } from '~/utils/Datacustom';
import { validateNhanVien } from '~/request/validateNhanVien';
import {apiGetCauhinhdoanhnghiep} from "~/services/cauhinhdoanhnghiep";
import config from "~/config";
import {Link} from "react-router-dom";

const ModalNhanvien = ({ show, setShow, fieldList }) => {
    const { phongban } = useSelector((state) => state.phongban);
    const { kieuluong } = useSelector((state) => state.kieuluong);
    const { chucvu } = useSelector((state) => state.chucvu);
    const { chinhanh } = useSelector((state) => state.chinhanh);
    const dispatch = useDispatch();
    const [payloadCauhinh, setPayloadCauhinh] = useState([]);
    
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayloadNhanvien] = useState(fieldList);
    useEffect(() => {
        const fetchData = async () => {
            const cauHinhDoanhNghiep = await apiGetCauhinhdoanhnghiep();
            const response = cauHinhDoanhNghiep.data.data.rows[0];
            setPayloadCauhinh(response);
        };
        fetchData().catch(console.error);
      
    }, []);
    useEffect(() => {
        show && setPayloadNhanvien(fieldList);
    }, [show]);
    
// console.log('payloadCauhinh',payloadCauhinh)
    const [listPhongban, setlistPhongban] = useState([]);
    const [listChucvu, setlistChucvu] = useState([]);
    const [listNhomnhanvien, setlistNhomnhanvien] = useState([]);
    const [listChiNhanh, setlistChiNhanh] = useState([]);
    useEffect(() => {
        if (phongban && kieuluong && chucvu && chinhanh) {
            let dataSelectPhongban = phongban.map((item) => ({ label: item.phongban, value: item.id }));
            let dataSelectChucvu = chucvu.map((item) => ({ label: item.chucvu, value: item.id }));
            let dataSelectChinhanh = chinhanh.map((item) => ({ label: item.diachi, value: item.id }));
            setlistChiNhanh(dataSelectChinhanh);
            setlistPhongban(dataSelectPhongban);
            setlistChucvu(dataSelectChucvu);
            setlistNhomnhanvien(dataSelectNhomNV);
        }
    }, [phongban, kieuluong, chucvu, chinhanh]);
    const handleSubmit = async () => {
        let invalids = validate(payload);
        if (invalids === 0) {
            let datapayload = {
                data: [
                    {
                        ChinhanhId: Number(payload.ChinhanhId.value),
                        PhongbanId: Number(payload.PhongbanId.value),
                        ChucvuId: Number(payload.ChucvuId.value),
                        trangthainganhang: 1,
                        hoten: payload.hoten,
                        dienthoai: payload.dienthoai,
                        email: payload.email,
                    },
                ],
            };
            // return false
            dispatch(actions.NewNhanvien(datapayload));
        }
    };
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayloadNhanvien((prev) => ({ ...prev, [statename]: selected }));
    };

    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateNhanVien(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateNhanVien(item[0], item[1])]);
                invalids++;
            }
        });
        return invalids;
    };

    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">Thêm mới nhân viên</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
                <div className="p-2">
                    <div className="row">
                        <div className="form-group col-md-4">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Họ và tên'}
                                value={payload.hoten}
                                setValue={setPayloadNhanvien}
                                keyPayload={'hoten'}
                                type="text"
                                validate
                            />
                        </div>
                        <div className="form-group col-md-4">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Số điện thoại'}
                                value={payload.dienthoai}
                                setValue={setPayloadNhanvien}
                                keyPayload={'dienthoai'}
                                type="text"
                                validate
                            />
                        </div>
                        <div className="form-group col-md-4">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Email'}
                                value={payload.email}
                                setValue={setPayloadNhanvien}
                                keyPayload={'email'}
                                type="text"
                                validate
                            />
                        </div>
                        {/* <div className="form-group col-md-4">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                <span>
                                    Nhóm nhân viên <b className="text-danger">(*)</b>
                                </span>
                            </label>
                            <Select
                                value={payload.NhomnvId}
                                onChange={handleOnSelect}
                                options={listNhomnhanvien}
                                placeholder={'Chọn Nhóm nhân viên'}
                                name="NhomnvId"
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'NhomnvId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'ChucvuId')?.message}
                                </small>
                            )}
                        </div> */}

                        <div className="form-group col-md-4">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                <span>
                                    Chi nhánh <b className="text-danger">(*)</b>
                                </span>
                            </label>
                            <Select
                                value={payload.ChinhanhId}
                                onChange={handleOnSelect}
                                options={listChiNhanh}
                                placeholder={'Chọn chi nhánh'}
                                name="ChinhanhId"
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'ChinhanhId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'ChinhanhId')?.message}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                <span>
                                    Phòng ban <b className="text-danger">(*)</b>
                                </span>
                            </label>
                            <Select
                                value={payload.PhongbanId}
                                onChange={handleOnSelect}
                                options={listPhongban}
                                placeholder={'Chọn phòng ban'}
                                name="PhongbanId"
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'PhongbanId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'PhongbanId')?.message}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                <span>
                                    Chức vụ <b className="text-danger">(*)</b>
                                </span>
                            </label>
                            <Select
                                value={payload.ChucvuId}
                                onChange={handleOnSelect}
                                options={listChucvu}
                                placeholder={'Chọn chức vụ'}
                                name="ChucvuId"
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'ChucvuId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'ChucvuId')?.message}
                                </small>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-1">
                    {payloadCauhinh?.id ? (<Button text="Lưu thông tin" bClass="btn btn-primary px-5" textColor onClick={handleSubmit} />) : ''}
                    {payloadCauhinh?.id ? '' : (
                        <Link
                            className="nav-link active btn-warning px-5 py-1 btn"
                            to={`${config.he_thong}${config.cau_hinh_doanh_nghiep}`}
                        >
                            Hãy vào đây để cập nhật "Cấu hình doanh nghiệp" trước khí thêm nhân viên
                        </Link>
                    )}
                    
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalNhanvien;
