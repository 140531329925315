import React, { useState, useEffect } from 'react';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import {
    Block,
    BlockContent,
    BlockDes,
    BlockHead,
    BlockTitle,
    Icon,
    Button,
    PreviewCard,
} from '~/components/Component';
import Logo from '~/images/logo.png';
import LogoDark from '~/images/logo-dark-color.png';
import PageContainer from '~/layout/page-container/PageContainer';
import Head from '~/layout/head/Head';
import { Form, Spinner, Alert } from 'reactstrap';
import { AuthResetPasswordServices } from '~/services';
import config from '~/config';
import Swal from 'sweetalert2';
const AuthResetPassword = () => {
    const [searchParams] = useSearchParams();
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayload] = useState({
      email: '',
      password: '',
      token: '',
      userId: '',
    });
    // console.log('payload', payload)
    const navigate = useNavigate();
    useEffect(() => {
        if (invalidFields.length === 0) {
            setLoading(false);
        }
        const params = [];
        for (let entry of searchParams.entries()) {
            params.push(entry);
        }

        if (params.length !== 0) {
            let searchParamsObject = {password: ''};
            params?.forEach((i) => {
                if (Object.keys(searchParamsObject).some((item) => item === i[0])) {
                    searchParamsObject[i[0]] = [...searchParamsObject[i[0]], i[1]];
                } else {
                    searchParamsObject[i[0]] = i[1];
                }
            });
            setPayload(searchParamsObject)
            // console.log('searchParamsObject', searchParamsObject);
           
        }
    }, [invalidFields, searchParams]);

    const [loading, setLoading] = useState(false);
    const [passState, setPassState] = useState(false);
    const handleSubmit = async () => {
        setLoading(true);
        let invalids = validate(payload);
        if (invalids === 0) {
            const fetchData = async () => {
                const response = await AuthResetPasswordServices(payload);
                // console.log('response', response);
                if (response.data.err === 0) {
                    // setLoading(false);
                    Swal.fire({
                        icon: 'success',
                        title: response.data.mes,
                        showConfirmButton: false,
                        timer: 5000,
                    });
                } else {
                    Swal.fire({
                        title: 'Có lỗi đã xảy ra?',
                        text: response.data.mes,
                        icon: 'warning',
                        showConfirmButton: false,
                        timer: 5000,
                    });
                }
                setTimeout(() => {
                    navigate(config.login);
                }, 5000)
            };
            fetchData().catch(console.error);
        }
    };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (item[1] === '') {
                setInvalidFields((prev) => [
                    ...prev,
                    {
                        name: item[0],
                        message: 'Bạn không được bỏ trống trường này.',
                    },
                ]);
                invalids++;
            }
        });
        fields.forEach((item) => {
            switch (item[0]) {
              case 'password':
                if (item[1].length < 6) {
                    setInvalidFields((prev) => [
                        ...prev,
                        {
                            name: item[0],
                            message: 'Mật khẩu phải có tối thiểu 6 kí tự.',
                        },
                    ]);
                    invalids++;
                }
                break;
                default:
                    break;
            }
        });
        return invalids;
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };
    return (
        <React.Fragment>
            {/*<Head title="AuthResetPassword" />*/}
            <PageContainer>
                <Block className="nk-block-middle nk-auth-body  wide-xs">
                    <div className="brand-logo pb-4 text-center">
                        <Link to={process.env.PUBLIC_URL + '/'} className="logo-link">
                            <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
                            <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
                        </Link>
                    </div>
                    <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
                        <BlockHead>
                            <BlockContent>
                                <BlockTitle tag="h5">Thông tin tài khoản</BlockTitle>
                            </BlockContent>
                        </BlockHead>

                        <Form className="is-alter">
                            <div className="form-group">
                                <div className="form-label-group">
                                    <label className="form-label" htmlFor="default-01">
                                        Email
                                    </label>
                                </div>
                                <div className="form-control-wrap">
                                    <input
                                        type="text"
                                        id="default-01"
                                        name="name"
                                        placeholder="Nhập địa chỉ Email"
                                        className="form-control-lg form-control"
                                        value={payload?.email}
                                        disabled={true}
                                    />
                                    
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-label-group">
                                    <label className="form-label" htmlFor="password">
                                        Mật khẩu mới
                                    </label>
                                </div>
                                <div className="form-control-wrap">
                                    <a
                                        href="#password"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            setPassState(!passState);
                                        }}
                                        className={`form-icon lg form-icon-right passcode-switch ${
                                            passState ? 'is-hidden' : 'is-shown'
                                        }`}
                                    >
                                        <Icon name="eye" className="passcode-icon icon-show"></Icon>

                                        <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                                    </a>
                                    <input
                                        type={passState ? 'text' : 'password'}
                                        id="password"
                                        name="password"
                                        placeholder="Nhập mật khẩu mới"
                                        className={`form-control-lg form-control ${
                                            passState ? 'is-hidden' : 'is-shown'
                                        }`}
                                        value={payload.password}
                                        onChange={(e) =>
                                            setPayload((prev) => ({ ...prev, ['password']: e.target.value }))
                                        }
                                        onFocus={() => setInvalidFields([])}
                                        onKeyDown={(event) => handleKeyDown(event)}
                                    />
                                    {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'password') && (
                                        <small className="text-danger">
                                            {invalidFields.find((i) => i.name === 'password')?.message}
                                        </small>
                                    )}
                                </div>
                            </div>
                            <div className="form-group mt-3">
                                {loading ? (
                                    <Button size="lg" className="btn-block" type="button" color="primary">
                                        <Spinner size="sm" color="light" /> Cập nhật mật khẩu
                                    </Button>
                                ) : (
                                    <Button
                                        size="lg"
                                        className="btn-block"
                                        type="button"
                                        color="primary"
                                        onClick={handleSubmit}
                                    >
                                        Cập nhật mật khẩu
                                    </Button>
                                )}
                            </div>
                        </Form>
                        <div className="form-note-s2 text-center pt-4">
                            <Link to={`${process.env.PUBLIC_URL}/login`}>
                                <strong>Trở về trang đăng nhập</strong>
                            </Link>
                        </div>
                    </PreviewCard>
                </Block>
            </PageContainer>
        </React.Fragment>
    );
};

export default AuthResetPassword;
