import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '~/store/actions';
import { Card } from 'reactstrap';
import Head from '~/layout/head/Head';
import Content from '~/layout/content/Content';
import { Block } from '~/components/block/Block';
import { DataTable, PermissionUtils, Icon, Button } from '~/components/Component';
import { InputForm } from '~/components/Form';
import Select from 'react-select';
import { MenuNghiphepNghile } from './MenuTab/Menucontent';
import { NumericFormat } from 'react-number-format';
import './chitietnhanvien.scss';

const Cauhinhnghiphep = () => {
    const THU_VIEC = 1;
    const CHINH_THUC = 2;
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.getListCauhinhnghiphep());
        dispatch(actions.getUser());
    }, []);
    const { cauhinhnghiphep } = useSelector((state) => state.quanly);
    console.log('cauhinhnghiphep', cauhinhnghiphep)
    const { userall } = useSelector((state) => state.app);
    const [listUserAll, setlistUserAll] = useState([]);
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayload] = useState([]);
    const [formValues, setFormValues] = useState([{ nguoiduyet: '' }]);
    useEffect(() => {
        if (userall) {
            const dataSelectUserall = userall.map((item) => ({ label: item.username, value: item.id }));
            setlistUserAll(dataSelectUserall);
        }
    }, [userall]);
    useEffect(() => {
        if (cauhinhnghiphep && cauhinhnghiphep.length > 0) {
            const datas = {
                id: cauhinhnghiphep[0]?.id,
                congdonphepdenngay: cauhinhnghiphep[0].congdonphepdenngay,
                sophepdkbinhthuong: cauhinhnghiphep[0].sophepdkbinhthuong,
                sophepdkdochai: cauhinhnghiphep[0].sophepdkdochai,
                sophepdkkhuyettat: cauhinhnghiphep[0].sophepdkkhuyettat,
                congngaypheptheothamnien: cauhinhnghiphep[0].congngaypheptheothamnien,
                sonamcongngayphepthamnien: cauhinhnghiphep[0].sonamcongngayphepthamnien,
                traluongphep: cauhinhnghiphep[0].traluongphep,
                tinhngaypheptungayvao: cauhinhnghiphep[0].tinhngaypheptungayvao,
                batdauTaiThoiDiem: cauhinhnghiphep[0].batdauTaiThoiDiem,
                nghikhongluongtruchuyencan: cauhinhnghiphep[0].nghikhongluongtruchuyencan,
                apdungthamnien: cauhinhnghiphep[0].apdungthamnien || 1,
                loaiapdungphep: cauhinhnghiphep[0].loaiapdungphep || 0,
                ngaytinhphep: cauhinhnghiphep[0].ngaytinhphep || 15,
                phepdanghiTp: cauhinhnghiphep[0].phepdanghiTp,
                nghichedoTp: cauhinhnghiphep[0].nghichedoTp,
                khongluongTp: cauhinhnghiphep[0].khongluongTp,
                // nguoiduyet: cauhinhnghiphep[0].nguoiduyet,
            };
            setPayload(datas);

            const datanguoiduyet = cauhinhnghiphep[0]?.nguoiduyet?.split('|');
            const result = (datanguoiduyet || []).map((item) => {
                return { nguoiduyet: listUserAll?.find((items) => Number(items.value) === Number(item)) };
            });
            setFormValues(result);

            // const datanguoiduyet = cauhinhnghiphep[0]?.nguoiduyet?.split('|')
            // let result = [];
            // if (datanguoiduyet !== undefined) {
            //     datanguoiduyet.map((item) => {
            //         let object = {};
            //         object.nguoiduyet = listUserAll?.find((items, i) => items.value === +item);
            //         result.push(object)
            //     })
            // }
            // setFormValues(result)
        }
    }, [cauhinhnghiphep, listUserAll]);

    const [showPhanquyen, setShowPhanquyen] = useState({});
    let dataPermissionUtils = PermissionUtils('cauhinhnghiphep', 'all');
    useEffect(() => {
        if (dataPermissionUtils) {
            setShowPhanquyen(dataPermissionUtils);
        }
    }, [dataPermissionUtils]);

    let handleChange = (i, selected) => {
        let newFormValues = [...formValues];
        newFormValues[i]['nguoiduyet'] = selected;
        setFormValues(newFormValues);
    };

    let addFormFields = () => {
        setFormValues([...formValues, { nguoiduyet: '' }]);
    };
    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues);
    };

    // console.log('payload', payload)
    const handleSubmit = async () => {
        let nguoiduyetdata = [];
        for (const i in formValues) {
            nguoiduyetdata.push(formValues[i].nguoiduyet?.value);
        }
        const datapayload = {
            data: [
                {
                    congdonphepdenngay: payload.congdonphepdenngay,
                    // sophepdkbinhthuong: payload.sophepdkbinhthuong,
                    // sophepdkdochai: payload.sophepdkdochai,
                    // sophepdkkhuyettat: payload.sophepdkkhuyettat,
                    congngaypheptheothamnien: payload.congngaypheptheothamnien,
                    sonamcongngayphepthamnien: payload.sonamcongngayphepthamnien,
                    traluongphep: payload.traluongphep === true ? 1 : 0,
                    tinhngaypheptungayvao: payload.tinhngaypheptungayvao,
                    batdauTaiThoiDiem: payload.batdauTaiThoiDiem,
                    nghikhongluongtruchuyencan: payload.nghikhongluongtruchuyencan === true ? 1 : 0,
                    nguoiduyet: nguoiduyetdata,
                    apdungthamnien: payload?.apdungthamnien,
                    loaiapdungphep: payload?.loaiapdungphep,
                    ngaytinhphep: payload?.ngaytinhphep,
                    phepdanghiTp: payload?.phepdanghiTp,
                    nghichedoTp: payload?.nghichedoTp,
                    khongluongTp: payload?.khongluongTp,
                },
            ],
        };
        // console.log('datapayload', datapayload)
        if (payload.id) {
            datapayload.data[0].id = payload.id;
            // console.log('datapayload', datapayload)
            dispatch(actions.EditCauhinhnghiphep(datapayload));
        } else {
            // console.log('datapayload', datapayload)
            dispatch(actions.NewCauhinhnghiphep(datapayload));
        }
    };
    // console.log('payload:::::::::::::::::',payload)

    return (
        <React.Fragment>
            {/*<Head title="Doanh nghiệp, Chi nhánh, Phòng ban, Chức Vụ"></Head>*/}
            <Content>
                <Card className="mt-0">
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner card-inner-lg px-4">
                            <h4>Cấu hình nghỉ ngày phép, nghỉ phép, nghỉ lễ, công tác</h4>
                            <MenuNghiphepNghile />
                        </div>
                    </DataTable>
                </Card>
                <Block>
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner position-relative card-tools-toggle pt-0">
                            <div className="row">
                                <div className="form-group col-md-12">
                                    {+showPhanquyen?.them === 1 && (
                                        <Button
                                            color="primary"
                                            className="btn-icon px-4 mt-2"
                                            onClick={(e) => handleSubmit(e)}
                                        >
                                            {payload.id ? 'Cập nhật' : 'Thêm mới'}
                                        </Button>
                                        //     <span
                                        //         className="text-white ms-0 px-5 py-2 badge justify-content-center btn btn-primary"
                                        //         onClick={(e) => handleSubmit(e)}
                                        //     >
                                        //    {payload.id ? "Cập nhật" : "Thêm mới"}
                                        // </span>
                                    )}
                                </div>
                            </div>
                            <div className="row mt-3">
                                {/* <div className="form-group col-md-6">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Cộng dồn phép đến ngày (Ví dụ: Ngày 30 tháng 12)'}
                                        value={payload.congdonphepdenngay}
                                        setValue={setPayload}
                                        keyPayload={'congdonphepdenngay'}
                                        type="text"
                                    />
                                </div> */}
                                {/* <div className="form-group col-md-6">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Số ngày phép cho công việc trong điều kiện bình thường / năm'}
                                        value={payload.sophepdkbinhthuong}
                                        setValue={setPayload}
                                        keyPayload={'sophepdkbinhthuong'}
                                        type="number"
                                    />
                                </div> */}
                            </div>
                            {/* <div className="row">
                                <div className="form-group col-md-6">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Số ngày phép cho công việc trong điều kiện nguy hiểm, độc hại / năm'}
                                        value={payload.sophepdkdochai}
                                        setValue={setPayload}
                                        keyPayload={'sophepdkdochai'}
                                        type="number"
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Số ngày phép cho người lao động chưa thành niên, khuyết tật / năm'}
                                        value={payload.sophepdkkhuyettat}
                                        setValue={setPayload}
                                        keyPayload={'sophepdkkhuyettat'}
                                        type="number"
                                    />
                                </div>
                            </div> */}
                            <div className="row mt-3">
                                <div className="form-group col-md-3 mt-2">
                                    <div className="custom-control custom-switch z-0 position-absolute ">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="traluongphep"
                                            name="traluongphep"
                                            onChange={(e) =>
                                                setPayload((prev) => ({
                                                    ...prev,
                                                    ['traluongphep']: e.target.checked,
                                                }))
                                            }
                                            checked={payload.traluongphep || false}
                                        />
                                        <label className="custom-control-label" htmlFor="traluongphep">
                                            Áp dụng được trả lương phép{' '}
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group col-md-3 mt-2">
                                    <div className="custom-control custom-switch z-0 position-absolute ">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="nghikhongluongtruchuyencan"
                                            name="nghikhongluongtruchuyencan"
                                            onChange={(e) =>
                                                setPayload((prev) => ({
                                                    ...prev,
                                                    ['nghikhongluongtruchuyencan']: e.target.checked,
                                                }))
                                            }
                                            checked={payload.nghikhongluongtruchuyencan || false}
                                        />
                                        <label className="custom-control-label" htmlFor="nghikhongluongtruchuyencan">
                                            Nghỉ không lương trừ chuyên cần{' '}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <h5 className="mb-3">Thời điểm bắt đầu tính phép</h5>
                                {/* <h6 className="mb-3">Cộng dồn phép được tính đến ngày 30-12</h6> */}
                                <div className="form-group col-md-12 col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                                            Bắt đầu sau <b className="text-danger">(*)</b>
                                        </label>
                                        <div className="form-control-wrap">
                                            <div className="form-text-hint bg-lighter">
                                                <span className="overline-title">Tháng, Kể từ ngày </span>
                                            </div>
                                            <NumericFormat
                                                className="form-control px-2"
                                                value={payload.tinhngaypheptungayvao}
                                                name="tinhngaypheptungayvao"
                                                setValue={setPayload}
                                                onChange={(e) =>
                                                    setPayload((prev) => ({
                                                        ...prev,
                                                        ['tinhngaypheptungayvao']: e.target.value,
                                                    }))
                                                }
                                            />
                                            {invalidFields.length > 0 &&
                                                invalidFields.some((i) => i.name === 'tinhngaypheptungayvao') && (
                                                    <small className="text-danger">
                                                        {
                                                            invalidFields.find(
                                                                (i) => i.name === 'tinhngaypheptungayvao',
                                                            )?.message
                                                        }
                                                    </small>
                                                )}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-md-6 col-lg-2 mt-2">
                                    <div className="custom-control custom-switch z-0 position-absolute mt-lg-4">
                                        <input
                                            type="radio"
                                            className="custom-control-input"
                                            name="ab"
                                            id="ab"
                                            onChange={(e) =>
                                                setPayload((prev) => ({
                                                    ...prev,
                                                    ['batdauTaiThoiDiem']: THU_VIEC,
                                                }))
                                            }
                                            checked={payload.batdauTaiThoiDiem === THU_VIEC}
                                        />
                                        <label className="custom-control-label" htmlFor="ab">
                                            Thử việc{' '}
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group col-md-6 col-lg-2 mt-2">
                                    <div className="custom-control custom-switch z-0 position-absolute mt-lg-4">
                                        <input
                                            type="radio"
                                            className="form-control custom-control-input"
                                            name="ab"
                                            id="abs"
                                            onChange={(e) =>
                                                setPayload((prev) => ({
                                                    ...prev,
                                                    ['batdauTaiThoiDiem']: CHINH_THUC,
                                                }))
                                            }
                                            checked={payload.batdauTaiThoiDiem === CHINH_THUC}
                                        />
                                        <label className="custom-control-label" htmlFor="abs">
                                            Chính thức{' '}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group col-md-12 mb-3 mt-3">
                                <h6>Nhân sự được hưởng một ngày phép trên tháng:</h6>
                                <div className="g-4 align-center flex-wrap">
                                    <div className="g">
                                        <div className="custom-control custom-radio">
                                            <input
                                                type="radio"
                                                className="custom-control-input"
                                                name="radioSize"
                                                id="customRadio7"
                                                onChange={(e) =>
                                                    setPayload((prev) => ({
                                                        ...prev,
                                                        ['loaiapdungphep']: 0,
                                                    }))
                                                }
                                                checked={payload?.loaiapdungphep === 0}
                                            />
                                            <label className="custom-control-label" htmlFor="customRadio7">
                                                Theo ngày trong tháng
                                            </label>
                                        </div>
                                    </div>
                                    <div className="g">
                                        <div className="custom-control custom-radio">
                                            <input
                                                type="radio"
                                                className="custom-control-input"
                                                name="radioSize"
                                                id="customRadio6"
                                                onChange={(e) =>
                                                    setPayload((prev) => ({
                                                        ...prev,
                                                        ['loaiapdungphep']: 1,
                                                    }))
                                                }
                                                checked={payload?.loaiapdungphep === 1}
                                            />
                                            <label className="custom-control-label" htmlFor="customRadio6">
                                                Theo ngày công tối thiểu
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {payload?.loaiapdungphep === 0 ? (
                                    <div className="col-lg-4">
                                        <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Ngày tối thiểu (/tháng) >= '}
                                        value={payload?.ngaytinhphep}
                                        setValue={setPayload}
                                        keyPayload={'ngaytinhphep'}
                                        type="text"
                                        />
                                    </div>
                                ) : (
                                    <>
                            <div className="form-group col-lg-12">
                                    <div className="col-lg-4 mb-2">
                                        <InputForm
                                        setInvalidFields={setInvalidFields}
                                        invalidFields={invalidFields}
                                        label={'Ngày công tối thiểu (/tháng) >= '}
                                        value={payload?.ngaytinhphep}
                                        setValue={setPayload}
                                        keyPayload={'ngaytinhphep'}
                                        type="text"
                                        />
                                    </div>
                                    <div className='mb-2'>Bao gồm cả</div>
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            defaultChecked
                                            id="customCheck4"
                                            onChange={(e) =>
                                                setPayload((prev) => ({
                                                    ...prev,
                                                    ['phepdanghiTp']: e.target.checked === true ? 1 : 0,
                                                }))
                                            }
                                            checked={payload?.phepdanghiTp || false}
                                        />
                                        <label className="custom-control-label" htmlFor="customCheck4">
                                            Ngày nghỉ phép đã nghỉ trong tháng đó
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group col-lg-12">
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            defaultChecked
                                            id="customCheck45"
                                            onChange={(e) =>
                                                setPayload((prev) => ({
                                                    ...prev,
                                                    ['nghichedoTp']: e.target.checked === true ? 1 : 0,
                                                }))
                                            }
                                            checked={payload?.nghichedoTp || false}
                                        />
                                        <label className="custom-control-label" htmlFor="customCheck45">
                                            Ngày nghỉ đã nghỉ chế độ theo luật lao động trong tháng đó
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group col-lg-12">
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            defaultChecked
                                            id="customCheck46"
                                            onChange={(e) =>
                                                setPayload((prev) => ({
                                                    ...prev,
                                                    ['khongluongTp']: e.target.checked === true ? 1 : 0,
                                                }))
                                            }
                                            checked={payload?.khongluongTp || false}
                                        />
                                        <label className="custom-control-label" htmlFor="customCheck46">
                                            Ngày nghỉ không lương được duyệt
                                        </label>
                                    </div>
                                </div>
                                    </>
                                )}
                                
                            </div>
                            {/* <div className="row mt-5">
                                <div className="card-title">
                                    <Button
                                        color="success"
                                        className="btn-icon px-4 mt-2"
                                        onClick={() => addFormFields()}
                                    >
                                        <Icon name="plus"></Icon> Thêm mới
                                    </Button>
                                   
                                </div>
                            </div>
                            {formValues.map((element, index) => (
                                <div className="row my-3" key={index}>
                                    <div className="col-9">
                                        <label>Người duyệt {index + 1}</label>
                                        <Select
                                            value={element.nguoiduyet || ''}
                                            name="nguoiduyet"
                                            options={listUserAll}
                                            onChange={(e) => handleChange(index, e)}
                                        />
                                    </div>
                                    {index ? (
                                        <div className="col-2">
                                            <button
                                                type="button"
                                                className="button remove btn btn-danger mt-4"
                                                onClick={() => removeFormFields(index)}
                                            >
                                                Xóa
                                            </button>
                                        </div>
                                    ) : null}
                                </div>
                            ))} */}
                            <div className="form-group col-md-12 mb-5 mt-5">
                            <h6>Quy tắc tăng ngày phép theo thâm niên (Số ngày / Số năm)</h6>

                                <div className="custom-control custom-switch position-absolute fixZ">
                                    <input
                                        value={payload?.apdungthamnien || 0}
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="apdungthamnien"
                                        name="apdungthamnien"
                                        onChange={(e) =>
                                            setPayload((prev) => ({
                                                ...prev,
                                                ['apdungthamnien']: e.target.checked === true ? 1 : 0,
                                            }))
                                        }
                                        checked={payload?.apdungthamnien || false}
                                    />
                                    <label className="custom-control-label" htmlFor="apdungthamnien">
                                        Áp dụng cộng phép thâm niên theo cấu hình đã làm{' '}
                                    </label>
                                </div>
                            </div>
                            {payload?.apdungthamnien === 1 && (
                                <div className="row mt-3">
                                    <div className="form-group col-md-4">
                                        <InputForm
                                            setInvalidFields={setInvalidFields}
                                            invalidFields={invalidFields}
                                            label={'Số ngày phép theo thâm niên'}
                                            value={payload.congngaypheptheothamnien}
                                            setValue={setPayload}
                                            keyPayload={'congngaypheptheothamnien'}
                                            type="number"
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <InputForm
                                            setInvalidFields={setInvalidFields}
                                            invalidFields={invalidFields}
                                            label={'Số năm cộng ngày phép thâm niên'}
                                            value={payload.sonamcongngayphepthamnien}
                                            setValue={setPayload}
                                            keyPayload={'sonamcongngayphepthamnien'}
                                            type="number"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </DataTable>
                </Block>
            </Content>
        </React.Fragment>
    );
};

export default Cauhinhnghiphep;
