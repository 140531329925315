export const validateBaoHiemNhanVien = (fieldName, value) => {
    switch (fieldName) {
        case 'NhanvienId':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'sothebhyt':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
            
        case 'nguoinhansobh':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống.'
                }
            }
            break;
        case 'ngaythamgiabh':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống.'
                }
            }
            break;
        case 'phicongdoan':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống.'
                }
            }
            break;
        case 'phicongdoanctydong':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống.'
                }
            }
            break;
            
        default:
            break;
    }
}