export const validateCongThucLuong = (fieldName, value) => {
    switch (fieldName) {
        case 'tencongthuc':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Tên công thức không được để trống.'
                }
            }
            break;
            
        default:
            break;
    }
}