import actionTypes from './actionTypes'
import {
    apiGetCongthuctinhluong,
    apiNewCongthuctinhluong,
    apiEditCongthuctinhluong,
    apiDeleteCongthuctinhluong,
    apiGetBienToanCucHeThong
} from '~/services/congthuctinhluong'
import { toast } from "react-toastify";
export const getListCongthuctinhluong = () => async (dispatch) => {
    try {
        const response = await apiGetCongthuctinhluong()
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_CONG_THUC_TINH_LUONG,
                congthuctinhluong: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_CONG_THUC_TINH_LUONG,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_CONG_THUC_TINH_LUONG,
            congthuctinhluong: null
        })
    }
}

export const NewCongthuctinhluong = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiNewCongthuctinhluong(payload)
            // console.log('chek api apiNewCongthuctinhluong', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_CONG_THUC_TINH_LUONG,
                    msg: 'OK'
                });
                dispatch(getListCongthuctinhluong());
                toast.success(response?.data?.message)
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_CONG_THUC_TINH_LUONG,
                    msg: 'ERR'
                })
                toast.error(response?.data?.data)
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_CONG_THUC_TINH_LUONG,
                congthuctinhluong: null
            })
        }
    }
}
export const EditCongthuctinhluong = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditCongthuctinhluong(payload)
            if (response?.data.success === 200) {
                dispatch(getListCongthuctinhluong());
                toast.success(response?.data?.message)
                dispatch({
                    type: actionTypes.PUT_EDIT_CONG_THUC_TINH_LUONG,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_CONG_THUC_TINH_LUONG,
                    msg: 'ERR'
                })
                toast.error(response?.data?.data)
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_CONG_THUC_TINH_LUONG,
                congthuctinhluong: null
            })
        }
    }
}
export const DeleteCongthuctinhluong = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteCongthuctinhluong(payload)
            // console.log('chek api apiDeleteCongthuctinhluong', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListCongthuctinhluong());
                toast.success(response?.data?.data)
                dispatch({
                    type: actionTypes.DELETE_CONG_THUC_TINH_LUONG,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_CONG_THUC_TINH_LUONG,
                    msg: 'ERR'
                })
                toast.error(response?.data?.data)
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_CONG_THUC_TINH_LUONG,
                congthuctinhluong: null
            })
        }
    }
}