import actionTypes from "../actions/actionTypes";
const initState = {
    vanban: [],
    msg: '',
    // count: 0,
    newvanban: []
}

const vanbanReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.GET_LIST_VAN_BAN:

            return {
                ...state,
                vanban: action?.vanban || [],
                msg: 'Ok' || '',
                // count: action.vanban.count || 0
            }
        case actionTypes.POST_NEW_VAN_BAN:
        case actionTypes.PUT_EDIT_VAN_BAN:
        case actionTypes.DELETE_VAN_BAN:
            return {
                ...state,
                msg: action?.msg || '',
                newvanban: action?.newvanban || []
            }
        default:
            return state;
    }

}

export default vanbanReducer