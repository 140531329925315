import React from "react";
import {PreviewAltCard} from "~/components/preview/Preview";
import AudienceOverview from "~/components/partials/analytics/audience-overview/AudienceOverview";
import {Table} from "reactstrap";
import {format, parseISO} from "date-fns";
import {SetDatadrawLineChart} from "~/pages/Admin/Modal/Baocao/ComponentChart";
export const ComponentTuyenDung = (data) => {
    const dataTuyenDung = data?.dataTuyenDung
    
    const {
        tmpLabelCurrent,
        tmpDataCurrent,
        tmpDataPrev,
        tmpDataCurrentActive,
        tmpDataPreActive,
        tmpDataVTTD
    } = dataTuyenDung;
    const tmpDataCurrentSum = tmpDataCurrent?.reduce((a, v) => a + v, 0);
    const tmpDataPrevSum = tmpDataPrev?.reduce((a, v) => a + v, 0);
    const tmpDataCurrentActiveSum = tmpDataCurrentActive?.reduce((a, v) => a + v, 0);
    const tmpDataPreActiveSum = tmpDataPreActive?.reduce((a, v) => a + v, 0);
    const dataCount = {
        tmpDataCurrent: tmpDataCurrentSum || 0,
        tmpDataPrev: tmpDataPrevSum || 0,
        tmpDataCurrentActive: tmpDataCurrentActiveSum || 0,
        tmpDataPreActive: tmpDataPreActiveSum || 0,
    };
    // Tạo dữ liệu cho biểu đồ dạng Line
    const dataChartLine = [
        {data: tmpDataCurrent, color: '#04f620', label: 'Tháng hiện tại'},
        {data: tmpDataPrev, color: '#9d72ff', label: 'Tháng trước'}
    ]
    
    const chartData = SetDatadrawLineChart(dataChartLine, tmpLabelCurrent, dataCount)
    const settingChart = {
        description: 'Mô tả biến động tuyển dụng lao động trong tháng',
        title: 'Tuyển dụng lao động',
        title_out1: 'Tổng ứng viên ứng tuyển',
        title_out2: 'Tổng ứng viên trúng tuyển\n',
        data: chartData,
    }
    
    
    
    
    return <div className="col-md-12 mt-3 row">
        <div className="col-6">
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th className="text-center">
                        Số lượng ứng tuyển
                    </th>
                    <th className="text-center">
                        Số lượng hồ sơ đạt
                    </th>
                    <th className="text-center">
                        Số lượng hồ sơ không đạt
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td colSpan={3}
                        className="text-center bg-info text-white fw-bold">
                        Tháng này
                    </td>
                </tr>
                <tr>
                    <td className="text-center">
                        {tmpDataVTTD?.tmpDataCurrent || 0}
                    </td>
                    <td className="text-center">
                        {tmpDataVTTD?.tmpDataCurrentActive || 0}
                    </td>
                    <td className="text-center">
                        {tmpDataVTTD?.tmpDataCurrent - dataCount?.tmpDataCurrentActive || 0}
                    </td>
                </tr>
                <tr>
                    <td colSpan={3}
                        className="text-center bg-info text-white fw-bold">
                        Tháng trước
                    </td>
                </tr>
                <tr>
                    <td className="text-center">
                        {dataCount?.tmpDataPrev || 0}
                    </td>
                    <td className="text-center">
                        {dataCount?.tmpDataPreActive || 0}
                    </td>
                    <td className="text-center">
                        {dataCount?.tmpDataPrev - dataCount?.tmpDataPreActive || 0}
                    </td>
                </tr>
                </tbody>
            </Table>
        </div>
        <div className="col-6">
            <PreviewAltCard className="h-100">
                <AudienceOverview
                    dataChart={settingChart?.data}
                    titleChart={settingChart?.title}
                    descriptionChart={settingChart?.description}
                    titleOut={[settingChart?.title_out1, settingChart?.title_out2]}
                />
            </PreviewAltCard>
        </div>
        <div className="col-12">
            <div className="row mt-3">
                <div className="col-md-12">
                    <div className="pb-0">
                        <h6 className="overline-title text-primary">Báo cáo tuyển dụng theo
                            vị trí</h6>
                    </div>
                </div>
                <div className="col-md-12 mt-3 row">
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th className="text-center">
                                Thông tin vị trí tuyển dụng
                            </th>
                            <th className="text-center">
                                Số lượng ứng tuyển
                            </th>
                            <th className="text-center">
                                Số lượng hồ sơ đạt
                            </th>
                            <th className="text-center">
                                Số lượng hồ sơ không đạt
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {dataCount.length > 0
                            ? dataCount.map((item) => {
                                return (
                                    <tr>
                                        <td className="bg-info text-white">
                                            <p className="mb-0">Vị
                                                trí: {item?.tuyendungData?.titletuyendung}</p>
                                            <p className="mb-0">Số
                                                lượng: {item?.tuyendungData?.soluongcantuyen}</p>
                                            <p className="mb-0">Thời gian kết
                                                thúc: {format(parseISO(item?.tuyendungData?.hannophoso), 'dd-MM-yyyy')}</p>
                                        </td>
                                        <td className="text-center">
                                            {item?.countUngvien}
                                        </td>
                                        <td className="text-center">
                                            {item?.countUngvienDat}
                                        </td>
                                        <td className="text-center">
                                            {item?.countUngvien - item?.countUngvienDat}
                                        </td>
                                    </tr>
                                );
                            })
                            : null}
                        
                        </tbody>
                    </Table>
                
                </div>
            </div>
        </div>
    </div>
};
