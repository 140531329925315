import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { InputForm, Button } from '~/components/Form';
import * as actions from '~/store/actions';

import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { dataSelectStatus } from '~/utils/Datacustom';
import { NumericFormat } from 'react-number-format';
import { Input } from 'reactstrap';
import { apiEditLoaisuatan } from '~/services';
import { toast } from 'react-toastify';
import { formatVietnameseToString } from '~/utils/Common/formatVietnameseToString';

const ModalEditloaiLoaisuatan = ({ show, setShow, dataId }) => {
    const dispatch = useDispatch();
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayload] = useState([]);
    useEffect(() => {
        let Trangthai = dataSelectStatus?.find((item) => {
            return item && item.value === dataId?.TrangthaiId;
        });
        show &&
            dataId &&
            setPayload({
                tieude: dataId.tieude,
                // ma_loai: dataId.ma_loai,
                giatri: '',
                giatriformat: dataId.giatri,
                ghichu: dataId.ghichu,
                TrangthaiId: { label: Trangthai?.label, value: Trangthai?.value },
            });
    }, [dataId, show]);

    const handleSubmit = async () => {
        let invalids = validate(payload);

        if (invalids === 0) {
            let maLoai = 'LSA_' + formatVietnameseToString(payload?.tieude);
            let datapayload = '';
            if (dataId?.is_default !== 1) {
                datapayload = {
                    data: [
                        {
                            id: dataId?.id,
                            tieude: payload.tieude,
                            ma_loai: maLoai.toUpperCase(),
                            giatri: payload.giatri,
                            ghichu: payload.ghichu,
                            TrangthaiId: payload.TrangthaiId.value,
                        },
                    ],
                };
            } else {
                datapayload = {
                    data: [
                        {
                            id: dataId?.id,
                            giatri: payload.giatri,
                            ghichu: payload.ghichu,
                            TrangthaiId: payload.TrangthaiId.value,
                        },
                    ],
                };
            }
            // console.log('datapayload', datapayload)
            const response = await apiEditLoaisuatan(datapayload);
            if (response?.data?.success === 200) {
                toast.success('Cập nhật thành công!');
                setShow(false);
            } else {
                toast.error('Cập nhật thất bại!');
            }
        }
    };

    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (item[1] === '') {
                setInvalidFields((prev) => [
                    ...prev,
                    {
                        name: item[0],
                        message: 'Bạn không được bỏ trống trường này.',
                    },
                ]);
                invalids++;
            }
        });

        return invalids;
    };
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">Thêm mới </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Tên loại suất ăn'}
                            value={payload.tieude}
                            setValue={setPayload}
                            keyPayload={'tieude'}
                            type="text"
                            validate
                            disabled={dataId.is_default !== 1 ? false : true}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Mã loại suất ăn'}
                            value={payload.ma_loai}
                            setValue={setPayload}
                            keyPayload={'ma_loai'}
                            type="text"
                            validate
                            disabled={true}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <div className="form-group">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                <span>Giá trị</span>
                            </label>
                            <div className="form-control-wrap">
                                <div className="form-text-hint">
                                    <span className="overline-title">VND</span>
                                </div>
                                <NumericFormat
                                    className="form-control px-2"
                                    value={payload.giatriformat}
                                    // displayType={'text'}
                                    thousandSeparator={true}
                                    // suffix={'đ'}
                                    name="giatri"
                                    onValueChange={(values) => {
                                        const { formattedValue, value } = values;
                                        setPayload((prev) => ({ ...prev, ['giatri']: value }));
                                        setPayload((prev) => ({ ...prev, ['giatriformat']: formattedValue }));
                                    }}
                                />
                                {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'giatri') && (
                                    <small className="text-danger">
                                        {invalidFields.find((i) => i.name === 'giatri')?.message}
                                    </small>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                                Trạng thái <b className="text-danger">(*)</b>
                            </span>
                        </label>
                        <Select
                            value={payload.TrangthaiId}
                            onChange={(selected) => setPayload((prev) => ({ ...prev, ['TrangthaiId']: selected }))}
                            options={dataSelectStatus}
                            placeholder={'Chọn trạng thái'}
                            name="TrangthaiId"
                            onFocus={() => setInvalidFields([])}
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'TrangthaiId') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'TrangthaiId')?.message}
                            </small>
                        )}
                    </div>
                    <div className="form-group col-md-12 mb-2">
                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>Ghi chú</span>
                        </label>
                        <Input
                            value={payload.ghichu}
                            onChange={(e) => setPayload((prev) => ({ ...prev, ['ghichu']: e.target.value }))}
                            rows={'2'}
                            name="ghichu"
                            type="textarea"
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'ghichu') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'ghichu')?.message}
                            </small>
                        )}
                    </div>
                </div>

                <div className="col-12 mt-5">
                    <Button text="Thêm mới" bClass="btn btn-primary px-5" onClick={handleSubmit} />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalEditloaiLoaisuatan;
