import actionTypes from './actionTypes'
import { apiGetUngvien, apiNewUngvien, apiEditUngvien, apiDeleteUngvien } from '~/services/ungvien'
import { toast } from "react-toastify";
export const getListUngvien = () => async (dispatch) => {
    try {
        const response = await apiGetUngvien()
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_UNG_VIEN,
                ungvien: response?.data.data.rows
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_UNG_VIEN,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_UNG_VIEN,
            ungvien: null
        })
    }
}

export const NewUngvien = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiNewUngvien(payload)
            console.log('chek api apiNewUngvien', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_UNG_VIEN,
                    msg: 'OK'
                });
                dispatch(getListUngvien());
                toast.success("Thêm mới ứng viên thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_UNG_VIEN,
                    msg: 'ERR'
                })
                toast.error("Thêm mới ứng viên thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_UNG_VIEN,
                ungvien: null
            })
        }
    }
}
export const EditUngvien = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditUngvien(payload)
            // console.log('chek api apiEditUngvien', response)
            if (response?.data.success === 200) {
                dispatch(getListUngvien());
                toast.success("Sửa thông tin ứng viên thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_UNG_VIEN,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_UNG_VIEN,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin ứng viên thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_UNG_VIEN,
                ungvien: null
            })
        }
    }
}
export const DeleteUngvien = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteUngvien(payload)
            // console.log('chek api apiDeleteUngvien', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListUngvien());
                toast.success("Xóa thông tin ứng viên thành công!")
                dispatch({
                    type: actionTypes.DELETE_UNG_VIEN,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_UNG_VIEN,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin ứng viên thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_UNG_VIEN,
                ungvien: null
            })
        }
    }
}