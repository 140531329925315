import actionTypes from "../actions/actionTypes";
const initState = {
    nguoithannhanvien: [],
    msg: '',
    // count: 0,
    newnguoithannhanvien: []
}

const nguoithannhanvienReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.GET_LIST_NGUOI_PHU_THUOC:
            return {
                ...state,
                nguoithannhanvien: action?.nguoithannhanvien || [],
                msg: 'Ok' || '',
                // count: action.nguoithannhanvien.count || 0
            }
        case actionTypes.POST_NEW_NGUOI_PHU_THUOC:
        case actionTypes.PUT_EDIT_NGUOI_PHU_THUOC:
        case actionTypes.DELETE_NGUOI_PHU_THUOC:
            return {
                ...state,
                msg: action.msg || '',
                newnguoithannhanvien: action?.newnguoithannhanvien || []
            }
        default:
            return state;
    }

}

export default nguoithannhanvienReducer