import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as actions from '~/store/actions';
import {ModalEditPhongban, ModalPhongban} from '../Admin/Modal';
import Swal from 'sweetalert2';
import {Card, DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledDropdown,} from 'reactstrap';
import Head from '~/layout/head/Head';
import Content from '~/layout/content/Content';
import {Block} from '~/components/block/Block';
import {
    Button,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    ExportXlsx,
    Icon,
    PaginationComponent,
    PermissionUtils,
    TooltipComponent,
} from '~/components/Component';
import {MenuDoanhnghiep} from './MenuTab/Menucontent';

const Phongban = () => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [showEditPhongban, setShowEditPhongban] = useState(false);
    
    //lấy giá trị từ thằng redux trả về
    const {phongban} = useSelector((state) => state.phongban);
    const [data, setData] = useState(phongban);
    const fieldList = {
        CongtyId: '',
        phongban: '',
        motavitri: '',
        parentId: 0,
    };
    
    useEffect(() => {
        // console.log('list phongban', phongban)
        phongban && phongban.length > 0 && setData([...phongban]);
        
    }, [phongban]);
// const parent = data?.find(i => i.id === 3)
    
    const parentName = (dataPB, parentId) => {
        const parent = dataPB?.find(i => i.id === parentId)
        if (parent) {
            return parent.phongban;
        }
        return '';
        
    }
    const [showPhanquyen, setShowPhanquyen] = useState({});
    let dataPermissionUtils = PermissionUtils('phongban', 'all');
    useEffect(() => {
        if (dataPermissionUtils) {
            setShowPhanquyen(dataPermissionUtils);
        }
    }, [dataPermissionUtils]);
    
    const namefile = 'Thông tin phòng ban';
    const header = ['Stt', 'Phòng ban', 'Mô tả'];
    const [columns, setColumns] = useState(() => {
        return header.map((item, index) => index <= 6);
    });
    const [dataFileMau, setDataFileMau] = useState([]);
    useEffect(() => {
        if (data.length > 0) {
            const datafilemau = data.map((item, index) => {
                return {
                    ...(columns[0] && {Stt: index + 1}),
                    ...(columns[1] && {'Phòng ban': item?.phongban}),
                    ...(columns[2] && {'Mô tả': item?.mota}),
                    ...(columns[3] && {'Điện thoại': item?.dienthoai}),
                    ...(columns[4] && {'Trực thuộc': item?.parentId}),
                };
            });
            setDataFileMau(datafilemau);
        }
    }, [data, columns]);
    
    const [sm, updateSm] = useState(false);
    const [tablesm, updateTableSm] = useState(false);
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(20);
    const [sort, setSortState] = useState('');
    // onChange function for searching name
    const onFilterChange = (e) => {
        setSearchText(e.target.value);
    };
    const toggle = () => setonSearch(!onSearch);
    const selectorCheck = (e) => {
        let newData;
        newData = data.map((item) => {
            item.checked = e.currentTarget.checked;
            return item;
        });
        setData([...newData]);
    };
    const onSelectChange = (e, id) => {
        let newData = data;
        let index = newData.findIndex((item) => item.id === id);
        newData[index].checked = e.currentTarget.checked;
        setData([...newData]);
    };
    
    useEffect(() => {
        if (onSearchText !== '') {
            const filteredObject = data.filter((item) => {
                return (
                    item.phongban.toLowerCase().includes(onSearchText.toLowerCase()) ||
                    item.motavitri.toLowerCase().includes(onSearchText.toLowerCase())
                );
            });
            setData([...filteredObject]);
        } else {
            setData([...phongban]);
        }
    }, [onSearchText, setData]);
    // console.log('data', data)
    
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    console.log('data',data);
    const datas = data?.map(item => ({...item, parent: []}));
    const map = {};
    
    datas.forEach(item => {
        map[item.id] = item;
    });
    
    datas.forEach(item => {
        if (item.parentId !== 0 && item.TrangthaiId !== 2) {
            map[item.parentId].parent.push(item);
        }
    });
    
    const result = datas.filter(item => item.parentId === 0 && item.TrangthaiId !== 2);
    const currentItems = result.slice(indexOfFirstItem, indexOfLastItem);
    // console.log('currentItems',currentItems);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    
    useEffect(() => {
        (phongban && setShow(false)) || setShowEditPhongban(false);
    }, [phongban]);
    // set state để mở đóng Modal
    const handleAddNewPhongban = async () => {
        setShow(true);
    };
    const [dataIdPhongban, setDataIdPhongban] = useState([]);
    const handleEditPhongban = async (data) => {
        setShowEditPhongban(true);
        await setDataIdPhongban(data);
    };
    
    
    const handleDeletePhongban = (data) => {
        Swal.fire({
            title: 'Bạn có chắc không?',
            text: 'Bạn sẽ không thể lấy lại khi xóa!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Không, bỏ qua!',
            confirmButtonText: 'Vâng, xóa nó đi!',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(actions.DeletePhongban(data));
            }
        });
    };
    return (
        <React.Fragment>
            <Head title="Cấu hình doanh nghiệp"></Head>
            <Content>
                <Card className="mt-0">
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner card-inner-lg px-4">
                            <h4>Cấu hình doanh nghiệp</h4>
                            <MenuDoanhnghiep/>
                        </div>
                    </DataTable>
                </Card>
                <Block>
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner position-relative card-tools-toggle pt-0">
                            <div className="card-title-group">
                                <div className="card-title">
                                    <h5 className="title">Phòng Ban</h5>
                                    {+showPhanquyen?.them === 1 && (
                                        <Button
                                            color="primary"
                                            className="btn-icon px-4 mt-2"
                                            onClick={handleAddNewPhongban}
                                        >
                                            <Icon name="plus"></Icon> Thêm mới
                                        </Button>
                                    )}
                                </div>
                                <div className="card-tools">
                                    <ul className="btn-toolbar gx-1">
                                        <li>
                                            <a
                                                href="#search"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    toggle();
                                                }}
                                                className="btn btn-icon search-toggle toggle-search"
                                            >
                                                <Icon name="search"></Icon>
                                            </a>
                                        </li>
                                        <li className="btn-toolbar-sep"></li>
                                        <li>
                                            <div className="toggle-wrap">
                                                <Button
                                                    className={`btn-icon btn-trigger toggle ${tablesm ? 'active' : ''}`}
                                                    onClick={() => updateTableSm(true)}
                                                >
                                                    <Icon name="menu-right"></Icon>
                                                </Button>
                                                <div className={`toggle-content ${tablesm ? 'content-active' : ''}`}>
                                                    <ul className="btn-toolbar gx-1">
                                                        <li className="toggle-close">
                                                            <Button
                                                                className="btn-icon btn-trigger toggle"
                                                                onClick={() => updateTableSm(false)}
                                                            >
                                                                <Icon name="arrow-left"></Icon>
                                                            </Button>
                                                        </li>
                                                        <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle
                                                                    color="tranparent"
                                                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                                                >
                                                                    <Icon name="setting"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu end className="dropdown-menu-xs">
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Show</span>
                                                                        </li>
                                                                        <li
                                                                            className={
                                                                                itemPerPage === 10 ? 'active' : ''
                                                                            }
                                                                        >
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setItemPerPage(10);
                                                                                }}
                                                                            >
                                                                                10
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li
                                                                            className={
                                                                                itemPerPage === 50 ? 'active' : ''
                                                                            }
                                                                        >
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setItemPerPage(50);
                                                                                }}
                                                                            >
                                                                                50
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li
                                                                            className={
                                                                                itemPerPage === 100 ? 'active' : ''
                                                                            }
                                                                        >
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setItemPerPage(100);
                                                                                }}
                                                                            >
                                                                                100
                                                                            </DropdownItem>
                                                                        </li>
                                                                    </ul>
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Order</span>
                                                                        </li>
                                                                        <li className={sort === 'dsc' ? 'active' : ''}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setSortState('dsc');
                                                                                    //   sortFunc("dsc");
                                                                                }}
                                                                            >
                                                                                DESC
                                                                            </DropdownItem>
                                                                        </li>
                                                                        <li className={sort === 'asc' ? 'active' : ''}>
                                                                            <DropdownItem
                                                                                tag="a"
                                                                                href="#dropdownitem"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                    setSortState('asc');
                                                                                    //   sortFunc("asc");
                                                                                }}
                                                                            >
                                                                                ASC
                                                                            </DropdownItem>
                                                                        </li>
                                                                    </ul>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="toggle-wrap nk-block-tools-toggle">
                                                <Button
                                                    className={`btn-icon btn-trigger toggle-expand me-n1 ${
                                                        sm ? 'active' : ''
                                                    }`}
                                                    onClick={() => updateSm(!sm)}
                                                >
                                                    <Icon name="upload-cloud"></Icon>
                                                </Button>
                                                <div
                                                    className="toggle-expand-content-cloud"
                                                    style={{display: sm ? 'block' : 'none'}}
                                                >
                                                    <ul className="nk-block-tools g-3">
                                                        <li className="nk-block-tools-opt">
                                                            <ExportXlsx
                                                                dataFileMau={dataFileMau}
                                                                title={header}
                                                                namefile={namefile}
                                                                btnname={'Xuất dữ liệu'}
                                                            />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className={`card-search search-wrap ${!onSearch && 'active'}`}>
                                <div className="card-body">
                                    <div className="search-content">
                                        <Button
                                            className="search-back btn-icon toggle-search active"
                                            onClick={() => {
                                                setSearchText('');
                                                toggle();
                                            }}
                                        >
                                            <Icon name="arrow-left"></Icon>
                                        </Button>
                                        <input
                                            type="text"
                                            className="border-transparent form-focus-none form-control"
                                            placeholder="Tìm kiếm"
                                            value={onSearchText}
                                            onChange={(e) => onFilterChange(e)}
                                        />
                                        <Button className="search-submit btn-icon">
                                            <Icon name="search"></Icon>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{overflowY: 'hidden'}}>
                            <DataTableBody>
                                <DataTableHead>
                                    {columns[0] && (
                                        <DataTableRow>
                                            <span className="sub-text">Stt</span>
                                        </DataTableRow>
                                    )}
                                    <DataTableRow className="nk-tb-col-check">
                                        <div className="custom-control custom-control-sm custom-checkbox notext">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                onChange={(e) => selectorCheck(e)}
                                                id="uid"
                                            />
                                            <label className="custom-control-label" htmlFor="uid"></label>
                                        </div>
                                    </DataTableRow>
                                    {columns[1] && (
                                        <DataTableRow>
                                            <span className="sub-text w-150px">Tên Phòng Ban</span>
                                        </DataTableRow>
                                    )}
                                    {columns[2] && (
                                        <DataTableRow>
                                            <span className="sub-text w-150px">Mô tả</span>
                                        </DataTableRow>
                                    )}
                                    
                                    <DataTableRow>
                                        <span className="sub-text w-150px">Trực thuộc</span>
                                    </DataTableRow>
                                    
                                    <DataTableRow className="nk-tb-col-tools text-end">
                                        <UncontrolledDropdown>
                                            <DropdownToggle
                                                color="tranparent"
                                                className="btn btn-xs btn-outline-light btn-icon dropdown-toggle"
                                            >
                                                <Icon name="plus"></Icon>
                                            </DropdownToggle>
                                            <DropdownMenu end className="dropdown-menu-xs">
                                                <ul className="link-tidy sm no-bdr">
                                                    {header.length > 0 ? (
                                                        header.map((item, index) => (
                                                            <li key={index}>
                                                                <div
                                                                    className="custom-control custom-control-sm custom-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="custom-control-input"
                                                                        id={`column-${index}`}
                                                                        checked={columns[index]}
                                                                        onChange={(e) =>
                                                                            setColumns((prev) => {
                                                                                const newColumns = [...prev];
                                                                                newColumns[index] = e.target.checked;
                                                                                return newColumns;
                                                                            })
                                                                        }
                                                                    />
                                                                    <label
                                                                        className="custom-control-label"
                                                                        htmlFor={`column-${index}`}
                                                                    >
                                                                        {item}
                                                                    </label>
                                                                </div>
                                                            </li>
                                                        ))
                                                    ) : (
                                                        <li>No data</li>
                                                    )}
                                                </ul>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </DataTableRow>
                                </DataTableHead>
                                {currentItems.length > 0
                                    ? currentItems.map((item, index) => {
                                        return (
                                            <>
                                                <DataTableItem key={item.id}>
                                                    {columns[0] && (
                                                        <DataTableRow size=" nk-tb-col-check mb">
                                                             <span
                                                                 className="tb-amount">{index + 1}</span>
                                                        
                                                        </DataTableRow>
                                                    )}
                                                    <DataTableRow className="nk-tb-col-check">
                                                        <div
                                                            className="custom-control custom-control-sm custom-checkbox notext">
                                                            <input
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                defaultChecked={item.checked}
                                                                id={item.id + 'uid1'}
                                                                key={Math.random()}
                                                                onChange={(e) => onSelectChange(e, item.id)}
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor={item.id + 'uid1'}
                                                            ></label>
                                                        </div>
                                                    </DataTableRow>
                                                    {columns[0] && (
                                                        <DataTableRow>
                                                            <span className="tb-amount">{item?.phongban}</span>
                                                        </DataTableRow>
                                                    )}
                                                    {columns[0] && (
                                                        <DataTableRow>
                                                            <span className="tb-amount">{item?.motavitri}</span>
                                                        </DataTableRow>
                                                    )}
                                                    {columns[2] && (
                                                        <DataTableRow>
                                                        <span
                                                            className="tb-amount">{parentName(data, item?.parentId)}</span>
                                                        </DataTableRow>
                                                    )}
                                                    <DataTableRow className="nk-tb-col-tools">
                                                        <ul className="nk-tb-actions gx-1">
                                                            {+showPhanquyen?.sua === 1 && (
                                                                <li
                                                                    className="nk-tb-action-hidden"
                                                                    onClick={() => handleEditPhongban(item)}
                                                                >
                                                                    <TooltipComponent
                                                                        tag="a"
                                                                        containerClassName="btn btn-trigger btn-icon"
                                                                        id={'edit' + item.id}
                                                                        icon="edit-alt-fill"
                                                                        direction="top"
                                                                        text="Sửa"
                                                                    />
                                                                </li>
                                                            )}
                                                            {+showPhanquyen?.xoa === 1 && (
                                                                <React.Fragment>
                                                                    <li
                                                                        className="nk-tb-action-hidden"
                                                                        onClick={() => handleDeletePhongban(item)}
                                                                    >
                                                                        <TooltipComponent
                                                                            tag="a"
                                                                            containerClassName="btn btn-trigger btn-icon"
                                                                            id={'suspend' + item.id}
                                                                            icon="user-cross-fill"
                                                                            direction="top"
                                                                            text="Xóa"
                                                                        />
                                                                    </li>
                                                                </React.Fragment>
                                                            )}
                                                            <li>
                                                                <UncontrolledDropdown>
                                                                    {(+showPhanquyen?.sua === 1 ||
                                                                        +showPhanquyen?.xoa === 1) && (
                                                                        <DropdownToggle
                                                                            tag="a"
                                                                            className="dropdown-toggle btn btn-icon btn-trigger"
                                                                        >
                                                                            <Icon name="more-h"></Icon>
                                                                        </DropdownToggle>
                                                                    )}
                                                                    <DropdownMenu end>
                                                                        <ul className="link-list-opt no-bdr">
                                                                            {+showPhanquyen?.sua === 1 && (
                                                                                <li onClick={() => handleEditPhongban(item)}>
                                                                                    <DropdownItem
                                                                                        tag="a"
                                                                                        href="#edit"
                                                                                        onClick={(ev) => {
                                                                                            ev.preventDefault();
                                                                                        }}
                                                                                    >
                                                                                        <Icon name="edit"></Icon>
                                                                                        <span>Sửa</span>
                                                                                    </DropdownItem>
                                                                                </li>
                                                                            )}
                                                                            {+showPhanquyen?.xoa === 1 && (
                                                                                <React.Fragment>
                                                                                    <li className="divider"></li>
                                                                                    <li
                                                                                        onClick={() =>
                                                                                            handleDeletePhongban(item)
                                                                                        }
                                                                                    >
                                                                                        <DropdownItem
                                                                                            tag="a"
                                                                                            href="#suspend"
                                                                                            onClick={(ev) => {
                                                                                                ev.preventDefault();
                                                                                            }}
                                                                                        >
                                                                                            <Icon name="na"></Icon>
                                                                                            <span>Xóa</span>
                                                                                        </DropdownItem>
                                                                                    </li>
                                                                                </React.Fragment>
                                                                            )}
                                                                        </ul>
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </li>
                                                        </ul>
                                                    </DataTableRow>
                                                </DataTableItem>
                                                {item.parent.length > 0 && item.parent.map((items, indexs) => {
                                                    return (
                                                        <>
                                                            <DataTableItem key={items.id}>
                                                                {columns[0] && (
                                                                    <DataTableRow size=" nk-tb-col-check mb">
                                                                        <span
                                                                            className="tb-amount">{index + 1}.{indexs + 1}</span>
                                                                    </DataTableRow>
                                                                )}
                                                                <DataTableRow className="nk-tb-col-check">
                                                                    <div
                                                                        className="custom-control custom-control-sm custom-checkbox notext">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="custom-control-input"
                                                                            defaultChecked={items.checked}
                                                                            id={items.id + 'uid1'}
                                                                            key={Math.random()}
                                                                            onChange={(e) => onSelectChange(e, items.id)}
                                                                        />
                                                                        <label
                                                                            className="custom-control-label"
                                                                            htmlFor={items.id + 'uid1'}
                                                                        ></label>
                                                                    </div>
                                                                </DataTableRow>
                                                                {columns[0] && (
                                                                    <DataTableRow>
                                                                        <span
                                                                            className="tb-amount">{items?.phongban}</span>
                                                                    </DataTableRow>
                                                                )}
                                                                {columns[0] && (
                                                                    <DataTableRow>
                                                                        <span
                                                                            className="tb-amount">{items?.motavitri}</span>
                                                                    </DataTableRow>
                                                                )}
                                                                {columns[2] && (
                                                                    <DataTableRow>
                                                        <span
                                                            className="tb-amount">{parentName(data, items?.parentId)}</span>
                                                                    </DataTableRow>
                                                                )}
                                                                <DataTableRow className="nk-tb-col-tools">
                                                                    <ul className="nk-tb-actions gx-1">
                                                                        {+showPhanquyen?.sua === 1 && (
                                                                            <li
                                                                                className="nk-tb-action-hidden"
                                                                                onClick={() => handleEditPhongban(items)}
                                                                            >
                                                                                <TooltipComponent
                                                                                    tag="a"
                                                                                    containerClassName="btn btn-trigger btn-icon"
                                                                                    id={'edit' + items.id}
                                                                                    icon="edit-alt-fill"
                                                                                    direction="top"
                                                                                    text="Sửa"
                                                                                />
                                                                            </li>
                                                                        )}
                                                                        {+showPhanquyen?.xoa === 1 && (
                                                                            <React.Fragment>
                                                                                <li
                                                                                    className="nk-tb-action-hidden"
                                                                                    onClick={() => handleDeletePhongban(items)}
                                                                                >
                                                                                    <TooltipComponent
                                                                                        tag="a"
                                                                                        containerClassName="btn btn-trigger btn-icon"
                                                                                        id={'suspend' + items.id}
                                                                                        icon="user-cross-fill"
                                                                                        direction="top"
                                                                                        text="Xóa"
                                                                                    />
                                                                                </li>
                                                                            </React.Fragment>
                                                                        )}
                                                                        <li>
                                                                            <UncontrolledDropdown>
                                                                                {(+showPhanquyen?.sua === 1 ||
                                                                                    +showPhanquyen?.xoa === 1) && (
                                                                                    <DropdownToggle
                                                                                        tag="a"
                                                                                        className="dropdown-toggle btn btn-icon btn-trigger"
                                                                                    >
                                                                                        <Icon name="more-h"></Icon>
                                                                                    </DropdownToggle>
                                                                                )}
                                                                                <DropdownMenu end>
                                                                                    <ul className="link-list-opt no-bdr">
                                                                                        {+showPhanquyen?.sua === 1 && (
                                                                                            <li onClick={() => handleEditPhongban(items)}>
                                                                                                <DropdownItem
                                                                                                    tag="a"
                                                                                                    href="#edit"
                                                                                                    onClick={(ev) => {
                                                                                                        ev.preventDefault();
                                                                                                    }}
                                                                                                >
                                                                                                    <Icon
                                                                                                        name="edit"></Icon>
                                                                                                    <span>Sửa</span>
                                                                                                </DropdownItem>
                                                                                            </li>
                                                                                        )}
                                                                                        {+showPhanquyen?.xoa === 1 && (
                                                                                            <React.Fragment>
                                                                                                <li className="divider"></li>
                                                                                                <li
                                                                                                    onClick={() =>
                                                                                                        handleDeletePhongban(item)
                                                                                                    }
                                                                                                >
                                                                                                    <DropdownItem
                                                                                                        tag="a"
                                                                                                        href="#suspend"
                                                                                                        onClick={(ev) => {
                                                                                                            ev.preventDefault();
                                                                                                        }}
                                                                                                    >
                                                                                                        <Icon
                                                                                                            name="na"></Icon>
                                                                                                        <span>Xóa</span>
                                                                                                    </DropdownItem>
                                                                                                </li>
                                                                                            </React.Fragment>
                                                                                        )}
                                                                                    </ul>
                                                                                </DropdownMenu>
                                                                            </UncontrolledDropdown>
                                                                        </li>
                                                                    </ul>
                                                                </DataTableRow>
                                                            </DataTableItem>
                                                        </>
                                                    )
                                                })}
                                            </>
                                        );
                                    })
                                    : null}
                            </DataTableBody>
                        </div>
                        <div className="card-inner">
                            {currentItems.length > 0 ? (
                                <PaginationComponent
                                    itemPerPage={itemPerPage}
                                    totalItems={currentItems.length}
                                    paginate={paginate}
                                    currentPage={currentPage}
                                />
                            ) : (
                                <div className="text-center">
                                    <div>
                                        <Spinner color="primary"/>
                                    </div>
                                    <span className="text-silent">Chưa có dữ liệu!</span>
                                </div>
                            )}
                        </div>
                    </DataTable>
                </Block>
                
                <ModalPhongban
                    show={show}
                    setShow={() => setShow(false)}
                    fieldList={fieldList}
                    listPhongBan={data}
                />
                <ModalEditPhongban
                    show={showEditPhongban}
                    setShow={() => setShowEditPhongban(false)}
                    dataIdPhongban={dataIdPhongban}
                    fieldList={fieldList}
                    listPhongBan={data}
                />
            </Content>
        </React.Fragment>
    );
};

export default Phongban;
