import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './DefaultLayout.module.scss';

const cx = classNames.bind(styles);

function DefaultLayoutOnly({ children }) {
    return (
            <div id="main-wrapper">
                <div className={cx('site-wrapper-reveal')}>{children}</div>
            </div>
    );
}

DefaultLayoutOnly.propTypes = {
    children: PropTypes.node.isRequired,
};

export default DefaultLayoutOnly;
