import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import './ModalNhanVien.scss';
import {Modal, ModalBody, Spinner} from 'reactstrap';
import {apiNhanvienByCondition} from '~/services';
import Select from 'react-select';
import {Button, Icon} from '~/components/Component';

const ModalSelectFinNhanvien = ({
                                    showSelect,
                                    setShowSelect,
                                    listNhanvien,
                                    setlistNhanvien,
                                    typeSelect,
                                    typeSetting,
                                    cauHinhId
                                }) => {
    
    const [data, setData] = useState([]);
    const [dataAll, setDataAll] = useState([]);
    const [dataCkb, setDataCkb] = useState([]);
    const [page, setPage] = useState(0);
    const [scrollH, setscrollH] = useState(136);
    const [countChk, setcountChk] = useState(0);
    const [payload, setPayload] = useState({ChinhanhId: '', PhongbanId: ''});
    // console.log('typeSetting',typeSetting)
    
    
    useEffect(() => {
        fetchData(page, scrollH).then(r => 'err');
    }, [page, cauHinhId]);
    // console.log('setData',page, scrollH)
    useEffect(() => {
        if (showSelect === true) {
            setPayload({ChinhanhId: '', PhongbanId: ''});
            setDataCkb([]);
        }
        
        if (listNhanvien?.length > 0 && listNhanvien[0].value !== undefined && dataAll?.length > 0) {
            let result = [];
            listNhanvien.forEach((i) => {
                let index = dataAll.findIndex((item) => item.id === i.value);
                
                // Check if the index is valid and if the object at that index exists
                if (index !== -1 && dataAll[index]) {
                    let nhanvien = {...dataAll[index]}; // Create a copy of the object to avoid modifying the original array
                    nhanvien.checked = true;
                    result.push(nhanvien);
                }
            });
            setDataCkb(result);
            // setData(result);
        }else{
            setDataCkb([]);
        }
    }, [showSelect, listNhanvien, dataAll]);
    
    const checkAndUpdateStatus = (nhanVienActive, typeSetting, cauHinhId, property) => {
        return nhanVienActive.map((it) => {
            it.checked = it[property] !== null;
            return it;
        }).filter((it) =>
            it[property] === cauHinhId ||
            it[property] === null
        );
    }
    
    async function fetchData(page, scrollH) {
        try {
            const datapayloads = {
                typeGet: 1, // 1: lấy theo điều kiện phân trang, 2: lấy tất cả và không cần truyền d liệu phân trang
                start: page || 0,
                limit: page*10+10 || 10,
                globalFilter: 'id',
                sorting: 'desc',
                chucvu: '',
                phongban: '',
            };
            const nhanvien = await apiNhanvienByCondition(datapayloads);

            let nhanVienActive = nhanvien?.data?.data?.rows
            
            // console.log('nhanVienActive',nhanVienActive)
            if (typeSetting === 'calc_overtime') { // Tinh gio lam them
                nhanVienActive = nhanVienActive.map((it) => {
                    it.checked = it.calc_overtime !== null;
                    return it;
                }).filter((it) =>
                    it.calc_overtime === cauHinhId ||
                    it.calc_overtime === null
                );
            }
            if (typeSetting === 'chuyencan') { // Chuyen can
                nhanVienActive = nhanVienActive.map((it) => {
                    it.checked = it.CauhinhchuyencanId !== null;
                    return it;
                }).filter((it) =>
                    it.CauhinhchuyencanId === cauHinhId ||
                    it.CauhinhchuyencanId === null
                );
            }
            
            if (typeSetting === 'thamnien') { // Tham nien
                nhanVienActive = nhanVienActive.map((it) => {
                    it.checked = it.CauhinhthamnienId !== null;
                    return it;
                }).filter((it) =>
                    it.CauhinhthamnienId === cauHinhId ||
                    it.CauhinhthamnienId === null
                );
            }
            
            if (typeSetting === 'daychuyen') { // daychuyen
               
                nhanVienActive = nhanVienActive.map((it) => {
                    it.checked = it.CauhinhdaychuyenId !== null;
                    return it;
                }).filter((it) =>
                    it.CauhinhdaychuyenId === cauHinhId ||
                    it.CauhinhdaychuyenId === null
                );
            }
            if (typeSetting === 'congthuc') { // Công thức tính lương nhân viên
                nhanVienActive = nhanVienActive.map((it) => {
                    it.checked = it.CongthucId !== null;
                    return it;
                }).filter((it) =>
                    it.CongthucId === cauHinhId ||
                    it.CongthucId === null
                );
            }
            if (typeSetting === 'hoatdongsukien') { // Hoạt động sự kiện nhân viên
                nhanVienActive?.forEach((it) => {
                    it.checked = !!it?.Nhanviensukiens?.some(t => t.HoatdongsukienId === cauHinhId);
                });
            }
            if (typeSetting === 'sanluong') { // Sản lượng nhân viên
                nhanVienActive?.forEach((it) => {
                    it.checked = !!it?.Sanluongnhanviens?.some(t => t.BangsanluongId === cauHinhId);
                });
            }
            if (typeSetting === 'quanhenhanvien') { // Quan hệ nhân viên
                nhanVienActive?.forEach((it) => {
                    it.checked = !!it?.Moiquanhenhanviens?.some(t => t.MoiquanhelaodongId === cauHinhId);
                });
            }
            
            setcountChk(nhanvien.data.data.count);
            if (nhanVienActive.length > 0) {
                setPage(page);
                if (page >= 2) {
                    setscrollH(scrollH + 421);
                }
                setData([...nhanVienActive]);
                setDataAll([...nhanVienActive]);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    
    const {phongban} = useSelector((state) => state.phongban);
    const {chinhanh} = useSelector((state) => state.chinhanh);
    const [listPhongban, setlistPhongban] = useState([]);
    const [listChiNhanh, setlistChiNhanh] = useState([]);
    useEffect(() => {
        if (phongban && chinhanh) {
            let dataSelectPhongban = phongban.map((item) => ({label: item.phongban, value: item.id}));
            let dataSelectChinhanh = chinhanh.map((item) => ({label: item.diachi, value: item.id}));
            setlistChiNhanh(dataSelectChinhanh);
            setlistPhongban(dataSelectPhongban);
        }
    }, [phongban, chinhanh]);
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState('');
    useEffect(() => {
        if (payload.ChinhanhId !== '') {
            const filteredObject = dataAll.filter((item) => {
                return (item.ChinhanhId === payload.ChinhanhId.value);
            });
            setData([...filteredObject]);
        }
        if (payload.PhongbanId !== '') {
            const filteredObject = dataAll.filter((item) => {
                return (item.PhongbanId === payload.PhongbanId.value);
            });
            setData([...filteredObject]);
        }
        if (onSearchText !== '') {
            
            const filteredObject = dataAll.filter((item) => {
                return (
                    item.hoten.toLowerCase().includes(onSearchText.toLowerCase()) ||
                    item.uuid.toLowerCase().includes(onSearchText.toLowerCase())
                );
            });
            setData([...filteredObject]);
        }
        if (payload.ChinhanhId === '' && payload.PhongbanId === '' && onSearchText === '') {
            setData([...dataAll]);
        }
        // console.log()
    }, [onSearchText, setData, dataAll, payload]);
    console.log('data',data)
    const onFilterChange = (e) => {
        setSearchText(e.target.value);
        setPayload({ChinhanhId: '', PhongbanId: ''});
    };
    const toggle = () => setonSearch(!onSearch);
    
    const handleScroll = (event) => {
        let scrollHeight = event.currentTarget.scrollTop;
        const pageNew = Math.floor(scrollHeight/scrollH);
        // console.log('scrollHeight', scrollHeight)
        // console.log('scrollH', scrollH)
        // console.log('page',page)
        // console.log('pageNew', Math.floor(scrollHeight/scrollH))
        if (scrollHeight === scrollH*pageNew + 1) {
            setPage(page + pageNew)
        }
    };
    // console.log('page', page)
    const onSelectChangeSL = (e, id) => {
        const {checked} = e.currentTarget;
        let index = data.findIndex((item) => item.id === id);
        let nhanvien = data[index];
        nhanvien.checked = checked;
        if (checked) {
            setDataCkb([...dataCkb.filter((people) => people.id !== id), nhanvien]);
        } else {
            setDataCkb(dataCkb.filter((people) => people.id !== id));
        }
    };
    const onSelectChangeSV = (e, id) => {
        let index = data.findIndex((item) => item.id === id);
        let nhanvien = data[index];
        setDataCkb([nhanvien]);
    };
    const selectorCheckSL = (e) => {
        const {checked} = e.currentTarget;
        data.map((item) => (item.checked = checked));
        setDataCkb(checked ? [...data] : []);
    };
    // console.log('dataCkb', dataCkb)
    const handleSubmit = () => {
        setlistNhanvien(
            dataCkb?.map((item) => ({
                label: item.hoten,
                value: item.id,
                PhongbanId: item.PhongbanId,
                email: item?.email,
                Quanlynghiphepcongtacnvs: item?.Quanlynghiphepcongtacnvs,
                // nhanVienItem: dataCkb,
            })),
        );
        setDataCkb([]);
        setShowSelect(false);
    };
    
    return (
        <React.Fragment>
            <Modal isOpen={showSelect} toggle={setShowSelect} size="lg">
                <ModalBody>
                    <a
                        href="#dropdownitem"
                        onClick={(ev) => {
                            ev.preventDefault();
                            setShowSelect(false);
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="container">
                        <div className="position-relative card-tools-toggle px-0 py-2">
                            <div className="card-title-group">
                                <div className="card-title">
                                    <h5 className="title">Danh sách nhân viên</h5>
                                </div>
                            </div>
                            <div className="card-title-group mt-3">
                                <div className="search-content">
                                    <input
                                        type="text"
                                        className=" form-control"
                                        placeholder="Tìm kiếm nhân viên"
                                        value={onSearchText}
                                        onChange={(e) => onFilterChange(e)}
                                    />
                                    <Button className="search-submit btn-icon px-2">
                                        <Icon name="search"></Icon>
                                    </Button>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div
                                    className="form-group col-md-6"
                                    onClick={() => {
                                        setSearchText('');
                                        toggle();
                                    }}
                                >
                                    <label htmlFor="inputZip" className="mb-2 fw-bold">
                                        <span>Chi nhánh</span>
                                    </label>
                                    <Select
                                        value={payload.ChinhanhId}
                                        onChange={(selected) => {
                                            setPayload((prev) => ({
                                                ...prev,
                                                ChinhanhId: selected,
                                            }));
                                        }}
                                        options={listChiNhanh}
                                        placeholder={'Chọn chi nhánh'}
                                        name="ChinhanhId"
                                        // onFocus={() => setInvalidFields([])}
                                    />
                                </div>
                                <div
                                    className="form-group col-md-6"
                                    onClick={() => {
                                        setSearchText('');
                                        toggle();
                                    }}
                                >
                                    <label htmlFor="inputZip" className="mb-2 fw-bold">
                                        <span>Phòng ban</span>
                                    </label>
                                    <Select
                                        value={payload.PhongbanId}
                                        onChange={(selected) => {
                                            setPayload((prev) => ({
                                                ...prev,
                                                PhongbanId: selected,
                                            }));
                                        }}
                                        options={listPhongban}
                                        placeholder={'Chọn phòng ban'}
                                        name="PhongbanId"
                                        // onFocus={() => setInvalidFields([])}
                                    />
                                </div>
                            </div>
                            <div className="card-title-group mt-2">
                                <span
                                    className="text-white ms-0 px-5 py-1 badge justify-content-center px-0 btn btn-primary"
                                    onClick={handleSubmit}
                                >
                                    <em className="icon ni ni-plus pe-2"></em>Chọn nhân viên
                                </span>
                            </div>
                        </div>
                        <table className="table table-fixed">
                            <thead className="d-block">
                            <tr className="d-flex">
                                <th className="col-1 border-bottom-0">#</th>
                                <th className="col-1 border-bottom-0">
                                    <div className="custom-control custom-control-sm custom-checkbox notext">
                                        {typeSelect === true ? (
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    onChange={(e) => selectorCheckSL(e)}
                                                    id="uidd"
                                                />
                                                <label className="custom-control-label" htmlFor="uidd"></label>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </th>
                                <th className="col-4 border-bottom-0">
                                    <span className="sub-text fw-bold text-info text-uppercase">TÊN NHÂN VIÊN</span>
                                </th>
                                <th className="col-3 border-bottom-0">
                                    <span className="sub-text fw-bold text-info text-uppercase">MÃ NHÂN VIÊN</span>
                                </th>
                                <th className="col-3 border-bottom-0">
                                    <span className="sub-text fw-bold text-info text-uppercase">SỐ ĐIỆN THOẠI</span>
                                </th>
                            </tr>
                            </thead>
                            <tbody
                                onScroll={handleScroll}
                                style={{height: '300px', overflow: 'scroll', display: 'block'}}
                            >
                            {data?.length > 0 ? (
                                data?.map((item, index) => {
                                    return (
                                        <tr className="d-flex" key={index}>
                                            <td className="col-1">{index + 1}</td>
                                            <td className="col-1">
                                                <div
                                                    className="custom-control custom-control-sm custom-checkbox notext">
                                                    {typeSelect ? (
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            defaultChecked={item.checked}
                                                            name="name"
                                                            id={item.id + 'uiddd'}
                                                            key={Math.random()}
                                                            onChange={(e) => onSelectChangeSL(e, item.id)}
                                                        />
                                                    ) : (
                                                        <input
                                                            type="radio"
                                                            className="custom-control-input"
                                                            defaultChecked={item.checked ? item.checked : false}
                                                            name="name"
                                                            id={item.id + 'uiddd'}
                                                            // key={Math.random()}
                                                            onChange={(e) => onSelectChangeSV(e, item.id)}
                                                        />
                                                    )}
                                                    
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor={item.id + 'uiddd'}
                                                    ></label>
                                                </div>
                                            </td>
                                            <td className="col-4">
                                                <span className="tb-amount"> {item?.hoten} </span>
                                            </td>
                                            <td className="col-3">
                                                <span className="tb-amount"> {item?.uuid} </span>
                                            </td>
                                            <td className="col-3">
                                                    <span className="tb-amount">
                                                        {' '}
                                                        {item?.Thongtinnhanvien?.dienthoai}{' '}
                                                    </span>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <div className="text-center">
                                    <div>
                                        <Spinner color="primary"/>
                                    </div>
                                    <span className="text-silent">Chưa có dữ liệu!</span>
                                </div>
                            )}
                            </tbody>
                        </table>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default ModalSelectFinNhanvien;
