import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { InputForm, Button } from '~/components/Form';
import * as actions from '~/store/actions';
import { validateChucVu } from '~/request/validateChucVu';
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';

const ModalChucvu = ({ show, setShow, fieldList }) => {
    const { phongban } = useSelector((state) => state.phongban);
    const dispatch = useDispatch();
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayloadChucvu] = useState(fieldList);
    useEffect(() => {
        show && setPayloadChucvu(fieldList);
    }, [show]);
    const [listPhongban, setlistPhongban] = useState([]);
    useEffect(() => {
        if (phongban) {
            let dataSelectPhongban = phongban.map((item) => ({ label: item.phongban, value: item.id }));
            setlistPhongban(dataSelectPhongban);
        }
    }, [phongban]);
    const handleSubmit = async () => {
        let invalids = validate(payload);
        if (invalids === 0) {
            let datapayload = {
                data: [
                    {
                        PhongbanId: payload.PhongbanId.value,
                        chucvu: payload.chucvu,
                        motavitri: payload.motavitri,
                    },
                ],
            };
            // console.log('check payload', datapayload)
            dispatch(actions.NewChucvu(datapayload));
        }
    };
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayloadChucvu((prev) => ({ ...prev, [statename]: selected }));
    };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateChucVu(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateChucVu(item[0], item[1])]);
                invalids++;
            }
        });
        return invalids;
    };
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">Thêm mới chức vụ</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Chức vụ'}
                            value={payload.chucvu}
                            setValue={setPayloadChucvu}
                            keyPayload={'chucvu'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-6 ">
                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                                Phòng ban <b className="text-danger">(*)</b>
                            </span>
                        </label>
                        <Select
                            value={payload.PhongbanId}
                            onChange={handleOnSelect}
                            options={listPhongban}
                            placeholder={'Chọn phòng ban'}
                            name="PhongbanId"
                            onFocus={() => setInvalidFields([])}
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'PhongbanId') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'PhongbanId')?.message}
                            </small>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-md-12">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Mô tả'}
                            value={payload.motavitri}
                            setValue={setPayloadChucvu}
                            keyPayload={'motavitri'}
                            type="text"
                            validate
                        />
                    </div>
                </div>

                <div className="col-12 mt-5">
                    <Button text="Thêm chức vụ" bClass="btn btn-primary px-5" textColor onClick={handleSubmit} />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalChucvu;
