import actionTypes from "../actions/actionTypes";
const initState = {
    phongban: [],
    msg: '',
    count: 0,
    newphongban: []
}

const phongbanReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.GET_LIST_PHONG_BAN:

            return {
                ...state,
                phongban: action?.phongban?.rows || [],
                msg: 'Ok' || '',
                count: action?.phongban?.count || 0
            }
        case actionTypes.POST_NEW_PHONG_BAN:
        case actionTypes.PUT_EDIT_PHONG_BAN:
        case actionTypes.DELETE_PHONG_BAN:
            return {
                ...state,
                msg: action.msg || '',
                newphongban: action?.newphongban || []
            }
        default:
            return state;
    }

}

export default phongbanReducer