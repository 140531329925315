import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '~/store/actions';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { ModalBody, Col } from 'reactstrap';
import { Icon, Button, RSelect, SelectNhanvien } from '~/components/Component';
import { getDateStructured } from '~/utils/Utils';
import { useForm } from 'react-hook-form';
import { ColorOptions } from '~/components/partials/color-select-menu/ColorMenu';
import { tagSet, teamList, themes } from './KanbanData';
import { dataSelectDouutien, dataSelectStatus } from '~/utils/Datacustom';
// import { format, parseISO } from 'date-fns';
// import Swal from 'sweetalert2';

import { apiGetGiaoviecbang, apiNewGiaoviecbang } from '~/services';
import { toast } from 'react-toastify';

export const KanbanTaskForm = ({ toggle, data, setData, taskToBoard, editTask }) => {
    // console.log('editTask', editTask);
    const dispatch = useDispatch();
    const [listNhanvien, setlistNhanvien] = useState([]);
    // console.log('listNhanvien', listNhanvien)
    useEffect(() => {
        listNhanvien.length > 0 &&
            setFormData((prev) => ({
                ...prev,
                ['NhanvienId']: listNhanvien[0]?.label,
            }));
        setFormData((prev) => ({
            ...prev,
            ['NhanvienIdVal']: listNhanvien[0]?.value,
        }));
    }, [listNhanvien]);
    const [showSelect, setShowSelect] = useState(false);

    const [formData, setFormData] = useState({
        title: editTask ? editTask?.congviec : '',
        desc: editTask ? editTask?.mota : '',
        // category: editTask ? editTask.meta.category : '',
        // date: new Date(),
        ngaybatdau: editTask ? editTask?.ngaybatdau : '',
        ngayketthuc: editTask ? editTask?.ngayketthuc : '',
        // due: '',
        board: null,
        // tags: editTask ? editTask.meta.tags : [tagSet[0]],
        // users: editTask ? editTask.meta.users : [teamList[0]],
        NhanvienId: editTask ? editTask?.Nhanvien?.hoten : '',
        NhanvienIdVal: editTask ? editTask?.NhanvienId : '',
        douutien: editTask ? [dataSelectDouutien[editTask.douutien]] : [dataSelectDouutien[0]],
        trangthai: editTask ? [dataSelectStatus[editTask.trangthai]] : [dataSelectStatus[0]],
    });
    // console.log('formData', formData)
    let boardOptions = [];
    Object.keys(data.columns).map((option) => {
        boardOptions = [
            ...boardOptions,
            {
                value: data.columns[option].text,
                label: data.columns[option].text,
                id: data.columns[option].id,
            },
        ];
    });

    const submitForm = (returnVal) => {
        let board = taskToBoard ? taskToBoard : formData.board === null ? boardOptions[0] : formData.board;
        const datacolumn = board.id?.split('-');
        // console.log('formData.value', formData)
        if (editTask) {
            let datapayload = {
                id: editTask?.id,
                congviec: formData?.title,
                mota: formData?.desc,
                ngaybatdau: formData?.ngaybatdau,
                ngayketthuc: formData?.ngayketthuc,
                // giobatdau: formData.giobatdau,
                // gioketthuc: formData.gioketthuc,
                NhanvienId: formData?.NhanvienIdVal,
                GiaoviecbangId: Number(datacolumn[1]),
                douutien: formData?.douutien[0]?.value,
                // tailieudinhkem: formData.tailieudinhkem,
                // tags: formData.tags,
                trangthai: formData?.trangthai[0]?.value,
                giobatdau: '00:00',
                gioketthuc: '00:00',
            };
            // console.log(datapayload)
            dispatch(actions.EditGiaoviec(datapayload));
        } else {
            let datapayload = {
                // data: [
                //     {
                        congviec: formData?.title,
                        mota: formData?.desc,
                        ngaybatdau: formData?.ngaybatdau,
                        ngayketthuc: formData?.ngayketthuc,
                        // giobatdau: formData.giobatdau,
                        // gioketthuc: formData.gioketthuc,
                        NhanvienId: formData?.NhanvienIdVal,
                        GiaoviecbangId: Number(datacolumn[1]),
                        douutien: formData?.douutien[0]?.value,
                        // tailieudinhkem: formData.tailieudinhkem,
                        // tags: formData.tags,
                        trangthai: formData?.trangthai[0]?.value,
                        giobatdau: '00:00',
                        gioketthuc: '00:00',
                //     },
                // ],
            };
            // console.log('datapayload', datapayload)
            dispatch(actions.NewGiaoviec(datapayload));
        }
        toggle();
    };

    // const deleteTask = () => {
    //     // let defaultData = data;
    //     // delete defaultData.task[editTask.id];

    //     // defaultData.columns[taskToBoard.id].tasks = defaultData.columns[taskToBoard.id].tasks.filter(
    //     //     (item) => item !== editTask.id,
    //     // );
    //     // setData({ ...defaultData });
    //     Swal.fire({
    //         title: 'Bạn có chắc không?',
    //         text: 'Bạn sẽ không thể lấy lại khi xóa!',
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         cancelButtonText: 'Không, bỏ qua!',
    //         confirmButtonText: 'Vâng, xóa nó đi!',
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             dispatch(actions.DeleteGiaoviec(editTask.id));
    //         }
    //     });

    // };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    return (
        <React.Fragment>
            <ModalBody>
                <a
                    href="#cancel"
                    onClick={(ev) => {
                        ev.preventDefault();
                        toggle();
                    }}
                    className="close"
                >
                    <Icon name="cross-sm"></Icon>
                </a>
                <div className="p-2">
                    <h5 className="title">{editTask ? 'Cập nhật' : 'Thêm'} nhiệm vụ</h5>
                    <div className="mt-4">
                        <form className="row gy-4" onSubmit={handleSubmit(submitForm)}>
                            <Col sm="12">
                                <div className="form-group">
                                    <label className="form-label">Tiêu đề nhiệm vụ</label>
                                    <input
                                        type="text"
                                        name="title"
                                        {...register('title', {
                                            required: 'Nội dung không được để trống',
                                        })}
                                        value={formData.title}
                                        onChange={(e) =>
                                            setFormData({
                                                ...formData,
                                                title: e.target.value,
                                            })
                                        }
                                        className="form-control"
                                    />
                                    {errors.title && <span className="invalid">{errors.title.message}</span>}
                                </div>
                            </Col>

                            <Col className="col-12">
                                <div className="form-group">
                                    <label className="form-label">Mô tả nhiệm vụ</label>
                                    <textarea
                                        name="desc"
                                        {...register('desc', {
                                            required: 'Nội dung không được để trống',
                                        })}
                                        value={formData.desc}
                                        onChange={(e) =>
                                            setFormData({
                                                ...formData,
                                                desc: e.target.value,
                                            })
                                        }
                                        className="form-control no-resize"
                                    />
                                    {errors.desc && <span className="invalid">{errors.desc.message}</span>}
                                </div>
                            </Col>

                            <Col sm="6">
                                <div className="form-group">
                                    <label className="form-label">Ngày bắt đầu</label>
                                    <input
                                        type="date"
                                        name="ngaybatdau"
                                        {...register('ngaybatdau', {
                                            required: 'Nội dung không được để trống',
                                        })}
                                        value={formData.ngaybatdau}
                                        onChange={(e) => setFormData({ ...formData, ngaybatdau: e.target.value })}
                                        className="form-control"
                                    />
                                    {errors.ngaybatdau && <span className="invalid">{errors.ngaybatdau.message}</span>}
                                </div>
                            </Col>
                            <Col sm="6">
                                <div className="form-group">
                                    <label className="form-label">Ngày kết thúc</label>
                                    <input
                                        type="date"
                                        name="ngayketthuc"
                                        {...register('ngayketthuc', {
                                            required: 'Nội dung không được để trống',
                                        })}
                                        value={formData.ngayketthuc}
                                        onChange={(e) => setFormData({ ...formData, ngayketthuc: e.target.value })}
                                        className="form-control date-picker"
                                    />
                                    {errors.ngayketthuc && (
                                        <span className="invalid">{errors.ngayketthuc.message}</span>
                                    )}
                                </div>
                            </Col>
                            <Col sm="6">
                                <div className="form-group">
                                    <label className="form-label">Chọn bảng</label>
                                    <RSelect
                                        defaultValue={
                                            taskToBoard
                                                ? boardOptions.find((item) => item.id === taskToBoard.id)
                                                : boardOptions[0]
                                        }
                                        isDisabled={taskToBoard ? true : false}
                                        options={boardOptions}
                                        placeholder="Chọn một bảng"
                                        onChange={(e) => {
                                            setFormData({ ...formData, board: e });
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col sm="6">
                                <div className="form-group" onClick={() => setShowSelect(true)}>
                                    <label className="form-label">Nhân viên chỉ định</label>
                                    <input
                                        type="text"
                                        name="NhanvienId"
                                        {...register('NhanvienId', {
                                            required: 'Nội dung không được để trống',
                                        })}
                                        value={formData.NhanvienId}
                                        onChange={(e) =>
                                            setFormData({
                                                ...formData,
                                                NhanvienId: e.target.value,
                                            })
                                        }
                                        className="form-control"
                                    />
                                    {errors.NhanvienId && <span className="invalid">{errors.NhanvienId.message}</span>}
                                </div>
                            </Col>
                            <Col sm="6">
                                <div className="form-group">
                                    <label className="form-label">Độ ưu tiên</label>
                                    <RSelect
                                        options={dataSelectDouutien}
                                        // isMulti
                                        defaultValue={formData.douutien}
                                        onChange={(e) => setFormData({ ...formData, douutien: e })}
                                    />
                                </div>
                            </Col>
                            <Col sm="6">
                                <div className="form-group">
                                    <label className="form-label">Trạng thái</label>
                                    <RSelect
                                        options={dataSelectStatus}
                                        // isMulti
                                        defaultValue={formData.trangthai}
                                        onChange={(e) => setFormData({ ...formData, trangthai: e })}
                                    />
                                </div>
                            </Col>

                            <Col className="col-12">
                                <ul className="d-flex justify-content-between mt-3">
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                        <li>
                                            <Button color="primary" size="md" type="submit">
                                                {editTask ? 'Cập nhật' : 'Thêm'} nhiệm vụ
                                            </Button>
                                        </li>
                                        <li>
                                            <Button
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    toggle();
                                                }}
                                                className="link link-light"
                                            >
                                                Hủy
                                            </Button>
                                        </li>
                                    </ul>
                                </ul>
                            </Col>
                        </form>
                    </div>
                </div>
            </ModalBody>
            <SelectNhanvien
                showSelect={showSelect}
                // typeSelect = { ismultiple: "Lấy nhiều" , single: "lấy một" }
                setlistNhanvien={setlistNhanvien}
                setShowSelect={() => setShowSelect(false)}
            />
        </React.Fragment>
    );
};

export const KanbanBoardForm = ({ toggle, data, setData, editBoard }) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        title: editBoard ? editBoard.text : '',
        color: editBoard ? themes.find((item) => item.value === editBoard.theme) : themes[0],
    });
    console.log('formData', formData)
    const submitForm = async (returnVal) => {
        if (editBoard) {
            const datacolumn = editBoard.id?.split('-');
            const datapayload = {
                id: datacolumn[1],
                text: formData.title === '' ? editBoard.text : returnVal.title,
                theme: formData.color.value,
            };
            // console.log('datapayload', datapayload);
            dispatch(actions.EditGiaoviecbang(datapayload));
            // let defaultVal = {
            //     ...data,
            //     columns: {
            //         ...data.columns,
            //         [editBoard.id]: {
            //             id: editBoard.id,
            //             text: formData.title === '' ? editBoard.text : returnVal.title,
            //             theme: formData.color.value,
            //             tasks: editBoard.tasks,
            //         },
            //     },
            // };
            // setData(defaultVal);
        } else {
            // let defaultVal = {
            //     ...data,
            //     columns: {
            //         ...data.columns,
            //         ['column-' + returnVal.title]: {
            //             id: 'column-' + returnVal.title,
            //             text: returnVal.title,
            //             theme: formData.color.value,
            //             tasks: [],
            //         },
            //     },
            //     columnOrder: [...data.columnOrder, `column-${returnVal.title}`],
            // };
            // setData(defaultVal);
            // let container = document.getElementById('kanban-container');
            // container.scrollTo(container.offsetWidth, 0);
            // console.log(returnVal)
            const datapayload = {
                data: [
                    {
                        text: returnVal?.title,
                        theme: formData.color.value,
                    },
                ],
            };
            console.log(datapayload);
            dispatch(actions.NewGiaoviecbang(datapayload));
            // const response = await apiNewGiaoviecbang(datapayload);
            // // console.log('response', response);
            // if (response.data.success === 200) {
            //     toast.success('Thêm thành công');
            //     const responseget = await apiGetGiaoviecbang();
            //     // console.log('responseget', responseget);
            //     // setData(response.data.data.rows);
            // } else {
            //     toast.error('Thêm thất bại!');
            // }
        }
        toggle();
    };

    // const { errors, register, handleSubmit } = useForm();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    return (
        <ModalBody>
            <a
                href="#cancel"
                onClick={(ev) => {
                    ev.preventDefault();
                    toggle();
                }}
                className="close"
            >
                <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
                <h5 className="title">
                    {editBoard ? 'Cập nhật' : 'Thêm'} bảng {formData.title}
                </h5>
                <div className="mt-4">
                    <form className="row gy-4" onSubmit={handleSubmit(submitForm)}>
                        <Col className="col-12">
                            <div className="form-group">
                                <label className="form-label">Tiêu đề bảng </label>
                                <input
                                    type="text"
                                    name="title"
                                    className="form-control"
                                    // ref={register({ required: "This field is required" })}
                                    {...register('title', {
                                        required: 'Nội dung không được để trống',
                                    })}
                                    value={formData.title}
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            title: e.target.value,
                                        })
                                    }
                                />
                                {errors.title && <span className="invalid">{errors.title.message}</span>}
                            </div>
                        </Col>
                        <Col className="col-12">
                            <div className="form-group">
                                <label className="form-label">Chọn màu</label>
                                <Select
                                    formatOptionLabel={ColorOptions}
                                    defaultValue={formData.color}
                                    options={themes}
                                    onChange={(e) => {
                                        setFormData({ ...formData, color: e });
                                    }}
                                />
                            </div>
                        </Col>
                        <Col className="col-12">
                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                    <Button color="primary" size="md" type="submit">
                                        {editBoard ? 'Cập nhật' : 'Thêm'} bảng
                                    </Button>
                                </li>
                                <li>
                                    <Button
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            toggle();
                                        }}
                                        className="link link-light"
                                    >
                                        Hủy
                                    </Button>
                                </li>
                            </ul>
                        </Col>
                    </form>
                </div>
            </div>
        </ModalBody>
    );
};
