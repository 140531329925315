import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputForm, Button } from '~/components/Form';
import * as actions from '~/store/actions';
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { NumericFormat } from 'react-number-format';
// import { dataSelectCapPhat } from '~/utils/Datacustom';
// import { EditTaisan, NewTaisan } from '~/store/actions';

const ModalAddPhuCap = ({ show, setShow, idNhanvien, dataPhuCapNV, uuid ,totalPhucap}) => {
    const [payload, setPayloadNhanvien] = useState({
        PhucapId: '',
        giatri: '',
    });

    const { phucap } = useSelector((state) => state.phucap);
    const [listPhuCap, setlistPhuCap] = useState([]);
    const dispatch = useDispatch();
    const [invalidFields, setInvalidFields] = useState([]);
 // không cho phụ cấp trùng nhau
 useEffect(() => {
    if (totalPhucap && totalPhucap?.length > 0 && phucap) {
        const c = phucap.filter(
            (itemB) => !totalPhucap.some((itemA) => Number(itemA?.Phucap?.id) === Number(itemB.id)),
        );
        setlistPhuCap(c.map((item) => ({ label: item.title, value: item.id })));
    } else {
        setlistPhuCap(phucap.map((item) => ({ label: item.title, value: item.id })))
    }
}, [totalPhucap, totalPhucap?.length, phucap]);
    useEffect(() => {
        // dispatch(actions.getListTaisan());
        // console.log('totalPhucap',totalPhucap)
        // show && dataPhuCapNV && setPayloadNhanvien(fieldList)
        if (show && dataPhuCapNV !== null) {
            const fieldList = {
                id: dataPhuCapNV?.id,
                PhucapId: { label: dataPhuCapNV?.Phucap?.title, value: dataPhuCapNV?.Phucap?.id },
                giatriformat: dataPhuCapNV?.giatri,
                NhanvienId: idNhanvien,
            };
            setPayloadNhanvien(fieldList);
        }else{
            const fieldList = {
                PhucapId: '',
                giatriformat: '',
            };
            setPayloadNhanvien(fieldList);
        }
    }, [show, dataPhuCapNV]);

    // useEffect(() => {
    //     if (phucap) {
    //         let dataSelectPhuCap = phucap
    //             ?.filter((i) => i.TrangthaiId === 1)
    //             .map((item) => ({
    //                 label: item?.title,
    //                 value: item?.id,
    //             }));

    //         setlistPhuCap(dataSelectPhuCap);
    //     }
    // }, [phucap]);
    // console.log('payload', payload); 
    const handleSubmit = async () => {
        let invalids = validate(payload);
        // console.log('invalids',invalids)
        if (invalids === 0) {
            if (payload?.id) {
                let datapayload = {
                    id: payload?.id,
                    NhanvienId: idNhanvien,
                    PhucapId: payload?.PhucapId?.value,
                    giatri: payload?.giatri,
                };
                // console.log('datapayload ccccccccccc', datapayload)
                dispatch(actions.EditPhucapByNhanVien(datapayload, uuid));
            } else {
                let datapayload = {
                    data: [
                        {
                            NhanvienId: idNhanvien,
                            PhucapId: payload?.PhucapId?.value,
                            giatri: payload?.giatri,
                        },
                    ],
                };
                dispatch(actions.NewPhucapByNhanVien(datapayload, uuid));
            }
            setShow(false);
        }
    };
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayloadNhanvien((prev) => ({ ...prev, [statename]: selected }));
    };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (item[1] === '') {
                setInvalidFields((prev) => [
                    ...prev,
                    {
                        name: item[0],
                        message: 'Bạn không được bỏ trống trường này.',
                    },
                ]);
                invalids++;
            }
        });

        return invalids;
    };
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">{dataPhuCapNV !== null ? 'Cập nhật phụ cấp' : 'Thêm mới phụ cấp'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-6">
                        <div className="form-group">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                Phụ cấp nhân viên
                            </label>
                            <Select
                                value={payload.PhucapId}
                                onChange={handleOnSelect}
                                options={listPhuCap}
                                placeholder={'Chọn phụ cấp'}
                                name="PhucapId"
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'PhucapId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'PhucapId')?.message}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className="form-group col-md-6">
                        <div className="form-group">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                                <span>
                                    Giá trị <b className="text-danger">(*)</b>
                                </span>
                            </label>
                            <div className="form-control-wrap">
                                <div className="form-text-hint">
                                    <span className="overline-title">VND</span>
                                </div>
                                <NumericFormat
                                    className="form-control px-2"
                                    value={payload.giatriformat}
                                    // displayType={'text'}
                                    thousandSeparator={true}
                                    // suffix={'đ'}
                                    name="giatri"
                                    onValueChange={(values) => {
                                        const { formattedValue, value } = values;
                                        setPayloadNhanvien((prev) => ({
                                            ...prev,
                                            ['giatri']: value,
                                        }));
                                        setPayloadNhanvien((prev) => ({
                                            ...prev,
                                            ['giatriformat']: formattedValue,
                                        }));
                                    }}
                                />
                                {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'giatri') && (
                                    <small className="text-danger">
                                        {invalidFields.find((i) => i.name === 'giatri')?.message}
                                    </small>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Giá trị của phụ cấp'}
                            value={payload.giatri}
                            setValue={setPayloadNhanvien}
                            keyPayload={'giatri'}
                            type='number'
                        />
                    </div> */}
                </div>

                <div className="col-12 mt-5">
                    <Button text={dataPhuCapNV === null ? 'Thêm mới' : 'Cập nhật'} bClass="btn btn-primary px-5" textColor onClick={handleSubmit} />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalAddPhuCap;
