import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { InputForm, Button } from '~/components/Form';
import { validateTamUngLuong } from '~/request/validateTamUngLuong';
import '../Modal.scss';
import { Input, Modal, ModalBody } from 'reactstrap';

import Select from 'react-select';
import Content from '~/layout/content/Content';
import { Icon, SelectNhanvien } from '~/components/Component';
import { dataSelectStatus } from '~/utils/Datacustom';
import { NumericFormat } from 'react-number-format';
import {apiNewTamungluong} from "~/services";
import {toast} from "react-toastify";

const ModalTamungluong = ({ show, setShow, fieldList }) => {
    const { nhanvien } = useSelector((state) => state.nhanvien);
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayloadTamungluong] = useState(fieldList);
    const [showSelect, setShowSelect] = useState(false);
    const [listTrangthai, setlistTrangthai] = useState([]);
    useEffect(() => {
        show && setPayloadTamungluong(fieldList);
    }, [show]);

    const [listNhanvien, setlistNhanvien] = useState([]);

    useEffect(() => {
        setPayloadTamungluong({
            NhanvienId: listNhanvien,
        });
    }, [listNhanvien]);
    useEffect(() => {
        if (nhanvien) {
            setlistTrangthai(dataSelectStatus);
        }
    }, [nhanvien]);
    const handleSubmit = async () => {
        let listNhanvienID = payload.NhanvienId;
        let invalids = validate(payload);
        if (invalids === 0) {
            let datapayload = {};
            let data = [];
            listNhanvienID.map(async (item, i) => {
                data.push({
                    NhanvienId: item.value,
                    ngaydexuat: payload.ngaydexuat,
                    giodexuat: payload.giodexuat,
                    mucdexuat: payload.mucdexuat,
                    ghichu: payload.ghichu,
                });
            });
            datapayload.data = data;
            const response = await apiNewTamungluong(payload)
            if (response.data.success === 200){
                toast.success("Thêm mới tạm ứng lương thành công!")
                setShow(false)
            } else {
                toast.error("Thêm mới tạm ứng lương thất bại!")
            }
      
        }
    };
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayloadTamungluong((prev) => ({ ...prev, [statename]: selected }));
    };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateTamUngLuong(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateTamUngLuong(item[0], item[1])]);
                invalids++;
            }
        });
        return invalids;
    };
    return (
        <React.Fragment>
            <Content>
                <Modal isOpen={show} toggle={setShow} className="modal-dialog-centered modal-90w">
                    <ModalBody>
                        <Icon name="cross-sm float-end fs-2" onClick={setShow}></Icon>
                        <h5 className="title mb-5">Thêm tạm ứng lương</h5>
                        <div className="row">
                            <div className="form-group col-md-12 mb-3" onClick={() => setShowSelect(true)}>
                                <label className="mb-2 fw-bold">
                                    <span>
                                        Chọn nhân viên <b className="text-danger">(*)</b>
                                    </span>
                                </label>
                                <Select
                                    value={payload?.NhanvienId}
                                    isMulti
                                    name="NhanvienId"
                                    onFocus={() => setInvalidFields([])}
                                />
                                {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'NhanvienId') && (
                                    <small className="text-danger">
                                        {invalidFields.find((i) => i.name === 'NhanvienId')?.message}
                                    </small>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-md-4">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Ngày đề xuất'}
                                    value={payload.ngaydexuat}
                                    setValue={setPayloadTamungluong}
                                    keyPayload={'ngaydexuat'}
                                    type="date"
                                    validate
                                />
                            </div>
                            <div className="form-group col-md-4 ">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Giờ đề xuất'}
                                    value={payload.giodexuat}
                                    setValue={setPayloadTamungluong}
                                    keyPayload={'giodexuat'}
                                    type="time"
                                    validate
                                />
                            </div>
                            <div className="form-group col-md-4 ">
                                <div className="form-group">
                                    <label className="mb-2 fw-bold">
                                        <span>
                                            Mức đề xuất <b className="text-danger">(*)</b>
                                        </span>
                                    </label>
                                    <div className="form-control-wrap">
                                        <div className="form-text-hint">
                                            <span className="overline-title">VND</span>
                                        </div>
                                        <NumericFormat
                                            className="form-control px-2"
                                            value={payload.mucdexuatformat}
                                            // displayType={'text'}
                                            thousandSeparator={true}
                                            // suffix={'đ'}
                                            name="mucdexuat"
                                            onValueChange={(values) => {
                                                const { formattedValue, value } = values;
                                                setPayloadTamungluong((prev) => ({ ...prev, ['mucdexuat']: value }));
                                                setPayloadTamungluong((prev) => ({
                                                    ...prev,
                                                    ['mucdexuatformat']: formattedValue,
                                                }));
                                            }}
                                        />
                                        {invalidFields.length > 0 &&
                                            invalidFields.some((i) => i.name === 'mucdexuat') && (
                                                <small className="text-danger">
                                                    {invalidFields.find((i) => i.name === 'mucdexuat')?.message}
                                                </small>
                                            )}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group col-md-4">
                                <label className="mb-2 fw-bold">
                                    <span>
                                        Trạn thái <b className="text-danger">(*)</b>
                                    </span>
                                </label>
                                <Select
                                    onChange={handleOnSelect}
                                    options={listTrangthai}
                                    name="TrangthaiId"
                                    onFocus={() => setInvalidFields([])}
                                />
                                {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'TrangthaiId') && (
                                    <small className="text-danger">
                                        {invalidFields.find((i) => i.name === 'TrangthaiId')?.message}
                                    </small>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-md-12 mt-2">
                                <label htmlFor="inputZip" className="mb-2 fw-bold">
                                    <span>
                                        Ghi chú <b className="text-danger">(*)</b>
                                    </span>
                                </label>
                                <Input
                                    value={payload.ghichu}
                                    onChange={(e) =>
                                        setPayloadTamungluong((prev) => ({ ...prev, ['ghichu']: e.target.value }))
                                    }
                                    rows={'2'}
                                    name="ghichu"
                                    type="textarea"
                                />
                                {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'ghichu') && (
                                    <small className="text-danger">
                                        {invalidFields.find((i) => i.name === 'ghichu')?.message}
                                    </small>
                                )}
                            </div>
                        </div>
                        <div className="col-12 mt-5">
                            <Button text="Thêm mới" bClass="btn btn-primary px-5" textColor onClick={handleSubmit} />
                        </div>
                    </ModalBody>
                </Modal>
            </Content>
            <SelectNhanvien
                showSelect={showSelect}
                // typeSelect = {
                //  ismultiple: "Lấy nhiều"
                //  single: "lấy một"
                // }
                typeSelect
                setlistNhanvien={setlistNhanvien}
                setShowSelect={() => setShowSelect(false)}
            />
        </React.Fragment>
    );
};

export default ModalTamungluong;
