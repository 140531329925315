import actionTypes from './actionTypes'
import { apiGetHoatdongsukien, apiNewHoatdongsukien, apiEditHoatdongsukien, apiDeleteHoatdongsukien } from '~/services/hoatdongsukien'
import { toast } from "react-toastify";
export const getListHoatdongsukien = () => async (dispatch) => {
    try {
        const response = await apiGetHoatdongsukien()
        // console.log('chek api lisst Hoatdongsukien', response?.data.success)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_HOAT_DONG_SU_KIEN,
                hoatdongsukien: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_HOAT_DONG_SU_KIEN,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_HOAT_DONG_SU_KIEN,
            hoatdongsukien: null
        })
    }
}

export const NewHoatdongsukien = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewHoatdongsukien', payload)
            const response = await apiNewHoatdongsukien(payload)
            // console.log('chek api apiNewHoatdongsukien', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_HOAT_DONG_SU_KIEN,
                    msg: 'OK'
                });
                dispatch(getListHoatdongsukien());
                toast.success("Thêm mới hoạt động sự kiện thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_HOAT_DONG_SU_KIEN,
                    msg: 'ERR'
                })
                toast.error("Thêm mới hoạt động sự kiện thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_HOAT_DONG_SU_KIEN,
                hoatdongsukien: null
            })
        }
    }
}
export const EditHoatdongsukien = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditHoatdongsukien(payload)
            // console.log('chek api apiEditHoatdongsukien', response)
            if (response?.data.success === 200) {
                dispatch(getListHoatdongsukien());
                toast.success("Sửa thông tin hoạt động sự kiện thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_HOAT_DONG_SU_KIEN,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_HOAT_DONG_SU_KIEN,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin hoạt động sự kiện thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_HOAT_DONG_SU_KIEN,
                hoatdongsukien: null
            })
        }
    }
}
export const DeleteHoatdongsukien = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteHoatdongsukien(payload)
            // console.log('chek api apiDeleteHoatdongsukien', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListHoatdongsukien());
                toast.success("Xóa thông tin hoạt động sự kiện thành công!")
                dispatch({
                    type: actionTypes.DELETE_HOAT_DONG_SU_KIEN,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_HOAT_DONG_SU_KIEN,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin hoạt động sự kiện thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_HOAT_DONG_SU_KIEN,
                hoatdongsukien: null
            })
        }
    }
}