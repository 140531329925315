import actionTypes from './actionTypes'
import { apiGetDexuat, apiNewDexuat, apiEditDexuat, apiDeleteDexuat } from '~/services/dexuat'
import { toast } from "react-toastify";
export const getListDexuat = () => async (dispatch) => {
    try {
        const response = await apiGetDexuat()
        // console.log('response', response)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_DE_XUAT,
                dexuat: response?.data.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_DE_XUAT,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_DE_XUAT,
            dexuat: null
        })
    }
}

export const NewDexuat = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewDexuat', payload)
            const response = await apiNewDexuat(payload)
            // console.log('chek api apiNewDexuat', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_DE_XUAT,
                    msg: 'OK'
                });
                dispatch(getListDexuat());
                toast.success("Thêm mới đề xuất thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_DE_XUAT,
                    msg: 'ERR'
                })
                toast.error("Thêm mới đề xuất thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_DE_XUAT,
                dexuat: null
            })
        }
    }
}
export const EditDexuat = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditDexuat(payload)
            // console.log('chek api apiEditDexuat', response)
            if (response?.data.success === 200) {
                dispatch(getListDexuat());
                toast.success("Sửa thông tin đề xuất thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_DE_XUAT,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_DE_XUAT,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin đề xuất thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_DE_XUAT,
                dexuat: null
            })
        }
    }
}
export const DeleteDexuat = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteDexuat(payload)
            // console.log('chek api apiDeleteDexuat', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListDexuat());
                toast.success("Xóa thông tin đề xuất thành công!")
                dispatch({
                    type: actionTypes.DELETE_DE_XUAT,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_DE_XUAT,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin đề xuất thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_DE_XUAT,
                dexuat: null
            })
        }
    }
}