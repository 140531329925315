import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {InputForm, Button} from '~/components/Form'
import * as actions from '~/store/actions'
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import {
    dataSelectActive,
} from '~/utils/Datacustom'
import {validateTaiKhoanNganHang} from "~/request/validateTaiKhoanNganHang";

const ModalEditBank = ({show, setShow, dataBank}) => {
    const [invalidFields, setInvalidFields] = useState([])
    const dispatch = useDispatch()
    const [listActive, setlistActive] = useState([])

    const [formValue, setformValue] = useState([]);
    useEffect(() => {
        let dataActive = handleSelect(dataSelectActive, 'status');
        setlistActive(dataActive)
    }, [dataSelectActive])
    useEffect(() => {
        let TrangthaiId = listActive?.find(item => {
            return item && item.value === Number(dataBank?.TrangthaiId)
        })
        
        show && dataBank && setformValue({
            id: dataBank?.id,
            tennganhang: dataBank?.tennganhang,
            sotaikhoan: dataBank?.sotaikhoan,
            nguoihuongthu: dataBank?.nguoihuongthu,
            TrangthaiId: {label: TrangthaiId?.label, value: TrangthaiId?.value},
            NhanvienId: dataBank?.NhanvienId
        })
    }, [dataBank, show])
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setformValue(prev => ({...prev, [statename]: selected}))
        
    }
    const validate = (payload) => {
        let invalids = 0
        let fields = Object.entries(payload)
        fields.forEach(item => {
            if (validateTaiKhoanNganHang(item[0], item[1]) !== undefined) {
                setInvalidFields(prev => [...prev, validateTaiKhoanNganHang(item[0], item[1])])
                invalids++
            }
        })
        return invalids
    }
    const handleSelect = (inputData, type) => {
        let result = [];
        if (type === 'status') {
            inputData?.map((item, index) => {
                let object = {};
                object.label = item.label;
                object.value = item.value;
                result.push(object)
            })
        }
        return result;
    }
    

    
    const handleSubmit = async () => {
        let invalids = validate(formValue)
        if (invalids === 0) {
            let data = {
                id: dataBank?.value,
                NhanvienId: dataBank?.NhanvienId,
                tennganhang: formValue.tennganhang,
                sotaikhoan: formValue.sotaikhoan,
                nguoihuongthu: formValue.nguoihuongthu,
                TrangthaiId: Number(formValue?.TrangthaiId?.value),
            }
            dispatch(actions.EditTaiKhoanNganHang(data))
            setShow(false)
        }
    }
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Sửa tài khoản ngân hàng
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-4">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Tên ngân hàng'}
                            value={formValue.tennganhang}
                            setValue={setformValue}
                            keyPayload={'tennganhang'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-4">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Số tài khoản'}
                            value={formValue.sotaikhoan}
                            setValue={setformValue}
                            keyPayload={'sotaikhoan'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-4">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Người thụ hưởng'}
                            value={formValue.nguoihuongthu}
                            setValue={setformValue}
                            keyPayload={'nguoihuongthu'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-4">
                        <div className="form-group">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">Trạng thái</label>
                            <Select
                                value={formValue?.TrangthaiId}
                                onChange={handleOnSelect}
                                options={listActive}
                                placeholder={'Trạng thái hoạt động'}
                                name='TrangthaiId'
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some(i => i.name === 'TrangthaiId') &&
                                <small
                                    className='text-danger'>{invalidFields.find(i => i.name === 'TrangthaiId')?.message}</small>}
                        </div>
                    </div>
                
                
                </div>
                <div className='col-12 mt-5'>
                    <Button
                        text='Cập nhật'
                        bClass='btn btn-primary px-5'
                        textColor
                        onClick={handleSubmit}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ModalEditBank;

