import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {InputForm, Button} from '~/components/Form';
import * as actions from '~/store/actions';
import '../Modal.scss';
import Select from 'react-select';
import {validatePhuongTien} from '~/request/validatePhuongTien';
import {dataSelectActive} from "~/utils/Datacustom";
import {Modal, ModalBody, Input} from 'reactstrap';
import {Icon, SelectFinNhanvien} from "~/components/Component";
import Textarea from "~/components/Form/Textarea";
import {format, parseISO} from "date-fns";

const ModalEditBaoduongphuongtien = ({show, setShow, dataIdPhuongtien, fieldList}) => {
      console.log('dataIdPhuongtien',dataIdPhuongtien)
    const dispatch = useDispatch();
    const [listTrangthai, setlistTrangthai] = useState([]);
    const {phuongtien} = useSelector((state) => state.quanly);
    const [listPhuongtien, setlistPhuongtien] = useState(phuongtien);
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayload] = useState(fieldList);
    // console.log('listLoaiphuongtien', listLoaiphuongtien)
    useEffect(() => {
        if (phuongtien && phuongtien.length > 0) {
            let PhuongtienId = phuongtien
                .filter(item => item.TrangthaiId === 1)
                .map(item => ({label: 'Xe: ' + item?.biensoxe + ' ('+item?.Loaiphuongtien?.tenloaiphuongtien + ')', value: item?.id}));
            setlistPhuongtien(PhuongtienId);
        }
      
        let TrangthaiId = dataSelectActive.map((item) => ({label: item.label, value: item.value}));
        setlistTrangthai(TrangthaiId);
    }, [phuongtien]);
    useEffect(() => {
        let TrangthaiId = dataSelectActive.find((items) => {
            return items && items.value === dataIdPhuongtien?.TrangthaiId;
        });
        let PhuongtienId = listPhuongtien?.find((items) => {
            return items && items.value === dataIdPhuongtien?.PhuongtienId;
        });
        
        show &&
        dataIdPhuongtien &&
        setPayload({
            TrangthaiId: { label: TrangthaiId?.label, value: TrangthaiId?.value },
            PhuongtienId: { label: PhuongtienId?.label, value: PhuongtienId?.value },
            ghichu: dataIdPhuongtien.ghichu,
            noidung: dataIdPhuongtien.noidung,
            ngaybaoduong: format(parseISO(dataIdPhuongtien.ngaybaoduong), 'yyyy-MM-dd'),
            chiphi: dataIdPhuongtien.chiphi,
            kmtruoc: dataIdPhuongtien.kmtruoc,
        });

    }, [dataIdPhuongtien, show]);
    useEffect(() => {
        let TrangthaiId = dataSelectActive.map((item) => ({label: item.label, value: item.value}));
        setlistTrangthai(TrangthaiId);
    }, []);
    const handleSubmit = async () => {
        let invalids = validate(payload);
        
        if (invalids === 0) {
            let datapayload = {
                data: [
                    {
                        id: dataIdPhuongtien.id,
                        TrangthaiId: payload.TrangthaiId.value,
                        PhuongtienId: payload.PhuongtienId.value,
                        ghichu: payload.ghichu,
                        noidung: payload.noidung,
                        ngaybaoduong: payload.ngaybaoduong,
                        chiphi: payload.chiphi,
                        kmtruoc: payload.kmtruoc,
                    },
                ],
            };
            //   console.log('check payload', datapayload)
            dispatch(actions.EditBaoduongphuongtien(datapayload));
        }
    };
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayload((prev) => ({...prev, [statename]: selected}));
    };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validatePhuongTien(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validatePhuongTien(item[0], item[1])]);
                invalids++;
            }
        });
        return invalids;
    };
    return (
        <React.Fragment>
            <Modal isOpen={show} className="modal-dialog-centered modal-90w" size="lg" toggle={setShow}>
                <ModalBody>
                    <Icon name="cross-sm float-end fs-2" onClick={setShow}></Icon>
                    <h5 className="title mb-3">Sửa kế hoạch bảo dưỡng cho xe</h5>
                    <div className="row">
        
                        <div className="form-group col-md-4">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                                Phương tiện<b className="text-danger">(*)</b>
                            </span>
                            </label>
                            <Select
                                value={payload.PhuongtienId}
                                onChange={handleOnSelect}
                                options={listPhuongtien}
                                placeholder={'Phương tiện'}
                                name="PhuongtienId"
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'PhuongtienId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'PhuongtienId')?.message}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                                Trạng thái <b className="text-danger">(*)</b>
                            </span>
                            </label>
                            <Select
                                value={payload.TrangthaiId}
                                onChange={handleOnSelect}
                                options={listTrangthai}
                                placeholder={'Trạng thái'}
                                name="TrangthaiId"
                                onFocus={() => setInvalidFields([])}
                            />
                            {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'TrangthaiId') && (
                                <small className="text-danger">
                                    {invalidFields.find((i) => i.name === 'TrangthaiId')?.message}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-4">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Ngày bảo dưỡng'}
                                value={payload.ngaybaoduong}
                                setValue={setPayload}
                                keyPayload={'ngaybaoduong'}
                                type="date"
                                validate
                            />
                        </div>
        
                        <div className="form-group col-md-6">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Số KM'}
                                value={payload.kmtruoc}
                                setValue={setPayload}
                                keyPayload={'kmtruoc'}
                                type="text"
                                validate
                            />
                        </div>
                        <div className="form-group col-md-6">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Chi phí'}
                                value={payload.chiphi}
                                setValue={setPayload}
                                keyPayload={'chiphi'}
                                type="text"
                                validate
                            />
                        </div>
        
                        <div className="form-group col-md-12">
                            <InputForm
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Ghi chú'}
                                value={payload.ghichu}
                                setValue={setPayload}
                                keyPayload={'ghichu'}
                                type="text"
                                validate
                            />
                        </div>
        
                        <div className="form-group col-md-12">
                            <Textarea
                                setInvalidFields={setInvalidFields}
                                invalidFields={invalidFields}
                                label={'Nội dung'}
                                value={payload.noidung}
                                setValue={setPayload}
                                keyPayload={'noidung'}
                                type="text"
                                validate
                            />
                        </div>
    
                    </div>
                
                    <div className="col-12 mt-5">
                        <Button text="Thêm mới" bClass="btn btn-primary px-5" textColor onClick={handleSubmit}/>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default ModalEditBaoduongphuongtien;
