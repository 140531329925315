import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {InputForm, Button} from '~/components/Form';
import * as actions from '~/store/actions';
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import {validateChucVu} from '~/request/validateChucVu';
import {dataSelectStatus} from "~/utils/Datacustom";
import { Input } from 'reactstrap';
import {apiEditXeploai} from "~/services";
import {toast} from "react-toastify";
import actionTypes from "~/store/actions/actionTypes";
import {getListXeploai} from "~/store/actions";

const ModalEditXeploai = ({show, setShow, dataIdXeploai, fieldList}) => {
    const [listTrangthai, setlistTrangthai] = useState([]);
    const dispatch = useDispatch();
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayloadXeploai] = useState(fieldList);
    useEffect(() => {
        let TrangthaiId = dataSelectStatus.find((items) => {
            return items && items.value === dataIdXeploai?.TrangthaiId;
        });
        show &&
        dataIdXeploai &&
        setPayloadXeploai({
            ten: dataIdXeploai.ten,
            mota: dataIdXeploai.mota,
            TrangthaiId: {label: TrangthaiId?.label, value: TrangthaiId?.value},
            
        });
    }, [dataIdXeploai, show]);
    useEffect(() => {
        let TrangthaiId = dataSelectStatus.map((item) => ({label: item.label, value: item.value}));
        setlistTrangthai(TrangthaiId);
    }, []);
    const handleSubmit = async () => {
        let invalids = validate(payload);
        
        if (invalids === 0) {
            let datapayload = {
                "data": [
                    {
                        id: dataIdXeploai.id,
                        ten: payload.ten,
                        mota: payload.mota,
                        TrangthaiId: payload.TrangthaiId.value,
                    }
                ]
            };
            const response = await apiEditXeploai(datapayload)
            // console.log('chek api apiEditXeploai', response)
            if (response?.data.success === 200) {
                toast.success("Sửa thông tin xếp loại thành công!")
                setShow(false);
            } else {
                toast.error("Sửa thông tin xếp loại thất bại!")
            }
        }
    };
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayloadXeploai((prev) => ({...prev, [statename]: selected}));
    };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateChucVu(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateChucVu(item[0], item[1])]);
                invalids++;
            }
        });
        return invalids;
    };
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">Cập nhật</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Tên xếp loại'}
                            value={payload.ten}
                            setValue={setPayloadXeploai}
                            keyPayload={'ten'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-6 ">
                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                                Trạng thái <b className="text-danger">(*)</b>
                            </span>
                        </label>
                        <Select
                            value={payload.TrangthaiId}
                            onChange={handleOnSelect}
                            options={listTrangthai}
                            placeholder={'Trạng thái'}
                            name="TrangthaiId"
                            onFocus={() => setInvalidFields([])}
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'TrangthaiId') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'TrangthaiId')?.message}
                            </small>
                        )}
                    </div>
                    <div className="form-group col-md-12 mt-3 mb-2">
                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                                Mô tả <b className="text-danger">(*)</b>
                            </span>
                        </label>
                        <Input
                            value={payload.mota}
                            onChange={(e) => setPayloadXeploai((prev) => ({ ...prev, ['mota']: e.target.value }))}
                            rows={'2'}
                            name="mota"
                            type="textarea"
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'mota') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'mota')?.message}
                            </small>
                        )}
                    </div>
                
                </div>
                
                
                <div className="col-12 mt-5">
                    <Button text="Cập nhật" bClass="btn btn-primary px-5" textColor onClick={handleSubmit}/>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalEditXeploai;
