export const validateXepLoaiNhanVien = (fieldName, value) => {
    let regNumber = /^[0-9\b]+$/;
    switch (fieldName) {
       
        case 'XeploaiId':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'NhanvienId':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'ten':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        case 'giatri':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        default:
            break;
    }
}