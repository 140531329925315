import React, {useState, useEffect, useMemo} from 'react';
import {
    ModalEditLoaikiemdinhatvsbhld,
    ModalLoaikiemdinhatvsbhld
} from './Modal';
import Swal from 'sweetalert2';
import {
    Card,
} from 'reactstrap';
import Content from '~/layout/content/Content';
import {Block} from '~/components/block/Block';
import {
    Button,
    DataTable,
    Icon,
    PermissionUtils,
} from '~/components/Component';
import {
    apiDeleteLoaikiemdinhatvsbhld
    , apiGetLoaikiemdinhatvsbhld,
} from "~/services";
import {toast} from "react-toastify";
import {ExportToCsv} from "export-to-csv";
import {MaterialReactTable, useMaterialReactTable} from "material-react-table";
import {MRT_Localization_VI} from "material-react-table/locales/vi";
import {Box, IconButton, Tooltip} from "@mui/material";
import {Delete as DeleteIcon, Edit as EditIcon} from "@mui/icons-material";
import {MenuKiemDinh} from "~/pages/Admin/MenuTab/Menucontent";
import {dataPagination} from "~/utils/Datacustom";

const Loaikiemdinhatvsbhld = () => {
    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    
    
    const [showPhanquyen, setShowPhanquyen] = useState({});
    let dataPermissionUtils = PermissionUtils('loaikiemdinhatvsbhld', 'all');
    useEffect(() => {
        if (dataPermissionUtils) {
            setShowPhanquyen(dataPermissionUtils);
        }
    }, [dataPermissionUtils]);
    
    
    async function fetchData() {
        try {
            const datapayloads = {
                typeGet: 1, // 1: lấy theo điều kiện phân trang, 2: lấy tất cả và không cần truyền d liệu phân trang
                start: pagination.pageIndex || 0,
                size: pagination.pageSize || 10,
                globalFilter: 'id',
                sorting: 'desc',
            };
            const response = await apiGetLoaikiemdinhatvsbhld(datapayloads);
            // const response = resData.data.data.rows;
            if (response?.data?.success === 200) {
                setData(response?.data?.data?.rows);
                setTotalRowCount(response?.data?.data?.count);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    
    
    useEffect(() => {
        fetchData().then(r => 'err')
    }, [pagination.pageIndex, pagination.pageSize, show, showEdit]);
    const fieldList = {
        maloaikiemdinh: '',
        tenloaikiemdinh: '',
        ghichu: '',
    };
    const handleAddNew = async () => {
        setShow(true);
    };
    const [dataIdLoaikiemdinhatvsbhld, setDataIdLoaikiemdinhatvsbhld] = useState([]);
    const handleEdit = async (data) => {
        setShowEdit(true);
        await setDataIdLoaikiemdinhatvsbhld(data);
    };
    
    const handleDelete = (data) => {
        Swal.fire({
            title: 'Bạn có chắc không?',
            text: 'Bạn sẽ không thể lấy lại khi xóa!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Không, bỏ qua!',
            confirmButtonText: 'Vâng, xóa nó đi!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const response = await apiDeleteLoaikiemdinhatvsbhld(data)
                // console.log('chek api apiDeleteLoaikiemdinhatvsbhld', response?.data.success)
                if (response?.data.success === 200) {
                    await fetchData()
                    toast.success("Xóa thông tin thành công!")
                } else {
                    toast.error("Xóa thông tin thất bại!")
                }
                
            }
        });
    };
    
    
    const Example = () => {
        
        let dataAb = data;
        // Tạo dữ liêu export
        const columns = useMemo(
            () => [
                {
                    Header: '#', // Tiêu đề cột số thứ tự
                    header: 'STT', // Tiêu đề cột số thứ tự
                    id: 'rowNumber', // ID của cột
                    size: 50,
                    Cell: ({row}) => <div>{row?.index + 1}</div>, // Giá trị của cột là số thứ tự
                    enableSorting: false,
                    enablePinning: true,
                },
                {
                    accessorKey: 'tenloaikiemdinh',
                    enablePinning: true,
                    header: 'Tên loại kiểm định',
                },
                {
                    accessorKey: 'maloaikiemdinh',
                    enablePinning: true,
                    header: 'Mã loại kiểm định',
                },
                {
                    accessorKey: 'ghichu',
                    enablePinning: true,
                    header: 'Ghi chú',
                }
            
            ],
            [],
        );
        const csvOptions = {
            fileName: 'csv-data',
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            useBom: true,
            useKeysAsHeaders: false,
            headers: columns.map((c) => c.header),
        };
        
        
        const csvExporter = new ExportToCsv(csvOptions);
        
        const handleExportData = () => {
            const dataExport = dataAb?.map((item, indexx) => {
                let abc = {}
                abc.rowNumber = indexx + 1
                abc.tenloaikiemdinh = item?.tenloaikiemdinh
                abc.maloaikiemdinh = item?.maloaikiemdinh
                abc.ghichu = item?.ghichu
                
                return abc
            })
            if (dataExport.length === 0) {
                toast.error("Không có dữ liệu để xuất!")
                return;
            }
            
            csvExporter.generateCsv(dataExport);
        };
        
        const table = useMaterialReactTable({
                columns,
                data,
                enableColumnResizing: false,
                enableDensityToggle: false,
                initialState: {
                    columnPinning: {left: ['rowNumber'], right: ['mrt-row-actions']},
                    density: 'compact'
                },
                
                state: {
                    expanded: true,
                    columnFilters,
                    globalFilter,
                    isLoading: dataAb.length === 0,
                    pagination,
                    sorting,
                },
                displayColumnDefOptions: {
                    'mrt-row-actions': {
                        sx: {width: '200px', paddingRight: '10px'},
                        header: 'Thao tác',
                        enableOrdering: true,
                        enablePinning: true,
                        enableColumnActions: true,
                    },
                },
                defaultDisplayColumn: {enableResizing: true},
                rowCount: totalRowCount,
                manualFiltering: true,
                manualPagination: true,
                manualSorting: true,
                onPaginationChange: setPagination,
                onColumnFiltersChange: setColumnFilters,
                onGlobalFilterChange: setGlobalFilter,
                onSortingChange: setSorting,
                localization: MRT_Localization_VI,
                positionActionsColumn: 'first',
                enableRowActions: true,
                enableColumnPinning: true,
                muiPaginationProps: {
                    rowsPerPageOptions: dataPagination,
                },
                renderTopToolbarCustomActions: ({table}) => (
                    <Box sx={{display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap'}}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddNew}
                            sx={{mr: 2}}
                        >
                            + Thêm mới
                        </Button>
                        <Button
                            color="primary"
                            onClick={handleExportData}
                            variant="contained"
                        >
                            <Icon name="download-cloud me-2"></Icon> Xuất dữ liệu
                        </Button>
                    </Box>
                ),
                renderRowActions: ({row, table}) => (
                    <Box sx={{display: 'flex', gap: '1rem'}}>
                        {+showPhanquyen?.sua === 1 && (
                            <Tooltip title="Edit">
                                <IconButton onClick={() => handleEdit(row.original)}>
                                    <EditIcon/>
                                </IconButton>
                            </Tooltip>
                        )}
                        {+showPhanquyen?.xoa === 1 && (
                            <Tooltip title="Delete">
                                <IconButton color="error" onClick={() => handleDelete({
                                    id: row.original.id,
                                    status: row.original.TrangthaiId
                                })}>
                                    <DeleteIcon/>
                                </IconButton>
                            </Tooltip>
                        )}
                    </Box>
                ),
            }
        );
        
        
        return (
            <MaterialReactTable
                table={table}
            />
        );
    };
    
    return (
        <React.Fragment>
            <Content>
                <Card className="mt-0">
                    <div className="card-aside-wrap row">
                        <div className="card-inner card-inner-lg px-4 pt-1 pb-0">
                            <h4>Quản Lý An Toàn - Vệ sinh - BHLĐ</h4>
                            <MenuKiemDinh/>
                        </div>
                    </div>
                </Card>
                <Block>
                    <DataTable className="card-stretch border-0">
                        <div className="card-inner position-relative card-tools-toggle">
                            <Example/>
                        </div>
                    </DataTable>
                </Block>
                
                <ModalLoaikiemdinhatvsbhld show={show} fieldList={fieldList} setShow={() => setShow(false)}/>
                <ModalEditLoaikiemdinhatvsbhld
                    show={showEdit}
                    fieldList={fieldList}
                    setShow={() => setShowEdit(false)}
                    dataIdLoaikiemdinhatvsbhld={dataIdLoaikiemdinhatvsbhld}
                />
            </Content>
        </React.Fragment>
    );
};

export default Loaikiemdinhatvsbhld;
