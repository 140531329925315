const actionTypes = {
    LOGIN: 'LOGIN',
    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    REGISTER_FAIL: 'REGISTER_FAIL',
    LOGOUT: 'LOGOUT',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAIL: 'LOGIN_FAIL',

    GET_USER: 'GET_USER',
    GET_USER_ALL: 'GET_USER_ALL',
    // GET_FUN_USER: 'GET_FUN_USER',

    GET_POSTS: "GET_POSTS",
    GET_POSTS_LIMIT: "GET_POSTS_LIMIT",
    GET_NEW_POST: "GET_NEW_POST",
    GET_DON_VI_HANH_CHINH: "GET_DON_VI_HANH_CHINH",
    //Nhân viên
    GET_NHAN_VIEN: "GET_NHAN_VIEN",
    GET_NEW_NHAN_VIEN: "GET_NEW_NHAN_VIEN",

    GET_LIST_QUYET_DINH: "GET_LIST_QUYET_DINH",
    //Cong ty
    GET_LIST_CONG_TY: "GET_LIST_CONG_TY",
    POST_NEW_CONG_TY: "POST_NEW_CONG_TY",
    PUT_EDIT_CONG_TY: "PUT_EDIT_CONG_TY",
    DELETE_CONG_TY: "DELETE_CONG_TY",
    //Phòng ban
    GET_LIST_PHONG_BAN: "GET_LIST_PHONG_BAN",
    POST_NEW_PHONG_BAN: "POST_NEW_PHONG_BAN",
    PUT_EDIT_PHONG_BAN: "PUT_EDIT_PHONG_BAN",
    DELETE_PHONG_BAN: "DELETE_PHONG_BAN",
    
    //Maý chấm công
    GET_LIST_MAY_CHAM_CONG: "GET_LIST_MAY_CHAM_CONG",
    POST_NEW_MAY_CHAM_CONG: "POST_NEW_MAY_CHAM_CONG",
    PUT_EDIT_MAY_CHAM_CONG: "PUT_EDIT_MAY_CHAM_CONG",
    DELETE_MAY_CHAM_CONG: "DELETE_MAY_CHAM_CONG",
    
    // Công thức tính lương
    GET_LIST_CONG_THUC_TINH_LUONG: "GET_LIST_CONG_THUC_TINH_LUONG",
    POST_NEW_CONG_THUC_TINH_LUONG: "POST_NEW_CONG_THUC_TINH_LUONG",
    PUT_EDIT_CONG_THUC_TINH_LUONG: "PUT_EDIT_CONG_THUC_TINH_LUONG",
    DELETE_CONG_THUC_TINH_LUONG: "DELETE_CONG_THUC_TINH_LUONG",
    //Kiểu lương
    GET_LIST_KIEU_LUONG: "GET_LIST_KIEU_LUONG",
    POST_NEW_KIEU_LUONG: "POST_NEW_KIEU_LUONG",
    PUT_EDIT_KIEU_LUONG: "PUT_EDIT_KIEU_LUONG",
    DELETE_KIEU_LUONG: "DELETE_KIEU_LUONG",
    //Chức vụ
    GET_LIST_CHUC_VU: "GET_LIST_CHUC_VU",
    POST_NEW_CHUC_VU: "POST_NEW_CHUC_VU",
    PUT_EDIT_CHUC_VU: "PUT_EDIT_CHUC_VU",
    DELETE_CHUC_VU: "DELETE_CHUC_VU",
    //Ứng viên
    GET_LIST_UNG_VIEN: "GET_LIST_UNG_VIEN",
    POST_NEW_UNG_VIEN: "POST_NEW_UNG_VIEN",
    PUT_EDIT_UNG_VIEN: "PUT_EDIT_UNG_VIEN",
    DELETE_UNG_VIEN: "DELETE_UNG_VIEN",
    //Đánh giá
    GET_LIST_DANH_GIA: "GET_LIST_DANH_GIA",
    POST_NEW_DANH_GIA: "POST_NEW_DANH_GIA",
    PUT_EDIT_DANH_GIA: "PUT_EDIT_DANH_GIA",
    DELETE_DANH_GIA: "DELETE_DANH_GIA",
    //Nhân viên
    GET_LIST_NHAN_VIEN: "GET_LIST_NHAN_VIEN",
    POST_NEW_NHAN_VIEN: "POST_NEW_NHAN_VIEN",
    PUT_EDIT_NHAN_VIEN: "PUT_EDIT_NHAN_VIEN",
    DELETE_NHAN_VIEN: "DELETE_NHAN_VIEN",
    //Nhóm Nhân viên
    GET_LIST_NHAN_VIEN_ONE: "GET_LIST_NHAN_VIEN_ONE",
    GET_NHOM_BY_NHAN_VIEN: "GET_NHOM_BY_NHAN_VIEN",
    GET_LIST_NHOM_NHAN_VIEN: "GET_LIST_NHOM_NHAN_VIEN",
    POST_NEW_NHOM_NHAN_VIEN: "POST_NEW_NHOM_NHAN_VIEN",
    PUT_EDIT_NHOM_NHAN_VIEN: "PUT_EDIT_NHOM_NHAN_VIEN",
    DELETE_NHOM_NHAN_VIEN: "DELETE_NHOM_NHAN_VIEN",
    //Tăng ca
    POST_NEW_TANG_CA: "POST_NEW_TANG_CA",
    GET_LIST_TANG_CA: "GET_LIST_TANG_CA",
    DELETE_TANG_CA: "DELETE_TANG_CA",
    PUT_EDIT_TANG_CA: "PUT_EDIT_TANG_CA",
    //Phiếu tăng ca
    POST_NEW_PHIEU_TANG_CA: "POST_NEW_PHIEU_TANG_CA",
    GET_LIST_PHIEU_TANG_CA: "GET_LIST_PHIEU_TANG_CA",
    DELETE_PHIEU_TANG_CA: "DELETE_PHIEU_TANG_CA",
    PUT_EDIT_PHIEU_TANG_CA: "PUT_EDIT_PHIEU_TANG_CA",
    //Quy định thưởng phạt
    GET_LIST_QUY_DINH_THUONG_PHAT: "GET_LIST_QUY_DINH_THUONG_PHAT",
    POST_NEW_QUY_DINH_THUONG_PHAT: "POST_NEW_QUY_DINH_THUONG_PHAT",
    PUT_EDIT_QUY_DINH_THUONG_PHAT: "PUT_EDIT_QUY_DINH_THUONG_PHAT",
    DELETE_QUY_DINH_THUONG_PHAT: "DELETE_QUY_DINH_THUONG_PHAT",
    //Nghỉ phép công tác
    GET_LIST_NGHI_PHEP_CONG_TAC: "GET_LIST_NGHI_PHEP_CONG_TAC",
    POST_NEW_NGHI_PHEP_CONG_TAC: "POST_NEW_NGHI_PHEP_CONG_TAC",
    PUT_EDIT_NGHI_PHEP_CONG_TAC: "PUT_EDIT_NGHI_PHEP_CONG_TAC",
    DELETE_NGHI_PHEP_CONG_TAC: "DELETE_NGHI_PHEP_CONG_TAC",
    //Nghỉ phép tổng hợp
    GET_LIST_BANG_TONG_HOP_PHEP: "GET_LIST_BANG_TONG_HOP_PHEP",
    POST_NEW_BANG_TONG_HOP_PHEP: "POST_NEW_BANG_TONG_HOP_PHEP",
    PUT_EDIT_BANG_TONG_HOP_PHEP: "PUT_EDIT_BANG_TONG_HOP_PHEP",
    DELETE_BANG_TONG_HOP_PHEP: "DELETE_BANG_TONG_HOP_PHEP",
    //Van ban
    GET_LIST_NGHI_VAN_BAN: "GET_LIST_NGHI_VAN_BAN",
    POST_NEW_NGHI_VAN_BAN: "POST_NEW_NGHI_VAN_BAN",
    PUT_EDIT_NGHI_VAN_BAN: "PUT_EDIT_NGHI_VAN_BAN",
    DELETE_NGHI_VAN_BAN: "DELETE_NGHI_VAN_BAN",
    //Suat an
    GET_LIST_SUAT_AN: "GET_LIST_SUAT_AN",
    POST_NEW_SUAT_AN: "POST_NEW_SUAT_AN",
    PUT_EDIT_SUAT_AN: "PUT_EDIT_SUAT_AN",
    DELETE_SUAT_AN: "DELETE_SUAT_AN",
    //Bộ câu hỏi phỏng vấn
    GET_LIST_BO_CAU_HOI_PHONG_VAN: "GET_LIST_BO_CAU_HOI_PHONG_VAN",
    POST_NEW_BO_CAU_HOI_PHONG_VAN: "POST_NEW_BO_CAU_HOI_PHONG_VAN",
    PUT_EDIT_BO_CAU_HOI_PHONG_VAN: "PUT_EDIT_BO_CAU_HOI_PHONG_VAN",
    DELETE_BO_CAU_HOI_PHONG_VAN: "DELETE_BO_CAU_HOI_PHONG_VAN",
    //Vị trí tuyển dụng
    GET_LIST_VI_TRI_TUYEN_DUNG: "GET_LIST_VI_TRI_TUYEN_DUNG",
    POST_NEW_VI_TRI_TUYEN_DUNG: "POST_NEW_VI_TRI_TUYEN_DUNG",
    PUT_EDIT_VI_TRI_TUYEN_DUNG: "PUT_EDIT_VI_TRI_TUYEN_DUNG",
    DELETE_VI_TRI_TUYEN_DUNG: "DELETE_VI_TRI_TUYEN_DUNG",
    //Cấu hình nghỉ lễ
    GET_LIST_CAU_HINH_NGHI_LE: "GET_LIST_CAU_HINH_NGHI_LE",
    POST_NEW_CAU_HINH_NGHI_LE: "POST_NEW_CAU_HINH_NGHI_LE",
    PUT_EDIT_CAU_HINH_NGHI_LE: "PUT_EDIT_CAU_HINH_NGHI_LE",
    DELETE_CAU_HINH_NGHI_LE: "DELETE_CAU_HINH_NGHI_LE",
    //Cấu hình ca làm việc
    GET_LIST_CAU_HINH_CA_LAM_VIEC: "GET_LIST_CAU_HINH_CA_LAM_VIEC",
    POST_NEW_CAU_HINH_CA_LAM_VIEC: "POST_NEW_CAU_HINH_CA_LAM_VIEC",
    PUT_EDIT_CAU_HINH_CA_LAM_VIEC: "PUT_EDIT_CAU_HINH_CA_LAM_VIEC",
    DELETE_CAU_HINH_CA_LAM_VIEC: "DELETE_CAU_HINH_CA_LAM_VIEC",
    //Giao việc
    GET_LIST_GIAO_VIEC: "GET_LIST_GIAO_VIEC",
    POST_NEW_GIAO_VIEC: "POST_NEW_GIAO_VIEC",
    PUT_EDIT_GIAO_VIEC: "PUT_EDIT_GIAO_VIEC",
    DELETE_GIAO_VIEC: "DELETE_GIAO_VIEC",
    //Giao việc bảng
    GET_LIST_GIAO_VIEC_BANG_ONE: "GET_LIST_GIAO_VIEC_BANG_ONE",
    GET_LIST_GIAO_VIEC_BANG: "GET_LIST_GIAO_VIEC_BANG",
    POST_NEW_GIAO_VIEC_BANG: "POST_NEW_GIAO_VIEC_BANG",
    PUT_EDIT_GIAO_VIEC_BANG: "PUT_EDIT_GIAO_VIEC_BANG",
    DELETE_GIAO_VIEC_BANG: "DELETE_GIAO_VIEC_BANG",
    //Hợp động lao động
    GET_LIST_HOP_DONG_LAO_DONG: "GET_LIST_HOP_DONG_LAO_DONG",
    POST_NEW_HOP_DONG_LAO_DONG: "POST_NEW_HOP_DONG_LAO_DONG",
    PUT_EDIT_HOP_DONG_LAO_DONG: "PUT_EDIT_HOP_DONG_LAO_DONG",
    DELETE_HOP_DONG_LAO_DONG: "DELETE_HOP_DONG_LAO_DONG",
    //Loại hợp đồng
    GET_LIST_LOAI_HOP_DONG: "GET_LIST_LOAI_HOP_DONG",
    POST_NEW_LOAI_HOP_DONG: "POST_NEW_LOAI_HOP_DONG",
    PUT_EDIT_LOAI_HOP_DONG: "PUT_EDIT_LOAI_HOP_DONG",
    DELETE_LOAI_HOP_DONG: "DELETE_LOAI_HOP_DONG",
    //Mẫu hợp đồng
    GET_LIST_MAU_HOP_DONG: "GET_LIST_MAU_HOP_DONG",
    POST_NEW_MAU_HOP_DONG: "POST_NEW_MAU_HOP_DONG",
    PUT_EDIT_MAU_HOP_DONG: "PUT_EDIT_MAU_HOP_DONG",
    DELETE_MAU_HOP_DONG: "DELETE_MAU_HOP_DONG",
    //Bình luận
    GET_LIST_BINH_LUAN: "GET_LIST_BINH_LUAN",
    POST_NEW_BINH_LUAN: "POST_NEW_BINH_LUAN",
    PUT_EDIT_BINH_LUAN: "PUT_EDIT_BINH_LUAN",
    DELETE_BINH_LUAN: "DELETE_BINH_LUAN",
    //Dề xuất
    GET_LIST_DE_XUAT: "GET_LIST_DE_XUAT",
    POST_NEW_DE_XUAT: "POST_NEW_DE_XUAT",
    PUT_EDIT_DE_XUAT: "PUT_EDIT_DE_XUAT",
    DELETE_DE_XUAT: "DELETE_DE_XUAT",
    //Phụ cấp
    GET_LIST_PHU_CAP: "GET_LIST_PHU_CAP",
    POST_NEW_PHU_CAP: "POST_NEW_PHU_CAP",
    PUT_EDIT_PHU_CAP: "PUT_EDIT_PHU_CAP",
    DELETE_PHU_CAP: "DELETE_PHU_CAP",
    POST_NEW_PHU_CAP_NV: "POST_NEW_PHU_CAP_NV",
    PUT_EDIT_PHU_CAP_NV: "PUT_EDIT_PHU_CAP_NV",
    DELETE_PHU_CAP_NV: "PUT_EDIT_PHU_CAP_NV",
    //Tài sản
    GET_LIST_TAI_SAN_BY_NV: "GET_LIST_TAI_SAN_BY_NV",
    GET_LIST_TAI_SAN: "GET_LIST_TAI_SAN",
    POST_NEW_TAI_SAN: "POST_NEW_TAI_SAN",
    PUT_EDIT_TAI_SAN: "PUT_EDIT_TAI_SAN",
    DELETE_TAI_SAN: "DELETE_TAI_SAN",
    //Cấu hình tăng ca
    GET_LIST_CAU_HINH_TANG_CA: "GET_LIST_CAU_HINH_TANG_CA",
    POST_NEW_CAU_HINH_TANG_CA: "POST_NEW_CAU_HINH_TANG_CA",
    PUT_EDIT_CAU_HINH_TANG_CA: "PUT_EDIT_CAU_HINH_TANG_CA",
    DELETE_CAU_HINH_TANG_CA: "DELETE_CAU_HINH_TANG_CA",
    //Cấu hình tăng ca
    GET_LIST_CAU_HINH_TRANG_THAI: "GET_LIST_CAU_HINH_TRANG_THAI",
    POST_NEW_CAU_HINH_TRANG_THAI: "POST_NEW_CAU_HINH_TRANG_THAI",
    PUT_EDIT_CAU_HINH_TRANG_THAI: "PUT_EDIT_CAU_HINH_TRANG_THAI",
    DELETE_CAU_HINH_TRANG_THAI: "DELETE_CAU_HINH_TRANG_THAI",
    // Bảng chấm công
    GET_LIST_CHAM_CONG_TONG_HOP: "GET_LIST_CHAM_CONG_TONG_HOP",
    POST_NEW_CHAM_CONG_TONG_HOP: "POST_NEW_CHAM_CONG_TONG_HOP",
    PUT_EDIT_CHAM_CONG_TONG_HOP: "PUT_EDIT_CHAM_CONG_TONG_HOP",
    DELETE_CHAM_CONG_TONG_HOP: "DELETE_CHAM_CONG_TONG_HOP",
    // Bảng chấm công
    GET_LIST_BANG_CHAM_CONG: "GET_LIST_BANG_CHAM_CONG",
    POST_NEW_BANG_CHAM_CONG: "POST_NEW_BANG_CHAM_CONG",
    PUT_EDIT_BANG_CHAM_CONG: "PUT_EDIT_BANG_CHAM_CONG",
    DELETE_BANG_CHAM_CONG: "DELETE_BANG_CHAM_CONG",
    // Bảng chốt công
    GET_LIST_BANG_CHOT_CONG: "GET_LIST_BANG_CHOT_CONG",
    POST_NEW_BANG_CHOT_CONG: "POST_NEW_BANG_CHOT_CONG",
    PUT_EDIT_BANG_CHOT_CONG: "PUT_EDIT_BANG_CHOT_CONG",
    DELETE_BANG_CHOT_CONG: "DELETE_BANG_CHOT_CONG",
    // Bảng chấm công tổng hợp
    GET_LIST_BANG_CHAM_CONG_TONG_HOP: "GET_LIST_BANG_CHAM_CONG_TONG_HOP",
    POST_NEW_BANG_CHAM_CONG_TONG_HOP: "POST_NEW_BANG_CHAM_CONG_TONG_HOP",
    PUT_EDIT_BANG_CHAM_CONG_TONG_HOP: "PU_TEDIT_BANG_CHAM_CONG_TONG_HOP",
    DELETE_BANG_CHAM_CONG_TONG_HOP: "DELETE_BANG_CHAM_CONG_TONG_HOP",
    // Bảng lương
    GET_LIST_PAYROLL: "GET_LIST_PAYROLL",
    // Bảo hiểm nhân viên
    GET_LIST_BAO_HIEM_NHAN_VIEN: "GET_LIST_BAO_HIEM_NHAN_VIEN",
    POST_NEW_BAO_HIEM_NHAN_VIEN: "POST_NEW_BAO_HIEM_NHAN_VIEN",
    PUT_EDIT_BAO_HIEM_NHAN_VIEN: "PUT_EDIT_BAO_HIEM_NHAN_VIEN",
    DELETE_BAO_HIEM_NHAN_VIEN: "DELETE_BAO_HIEM_NHAN_VIEN",
    // Chi nhánh
    GET_LIST_CHI_NHANH: "GET_LIST_CHI_NHANH",
    POST_NEW_CHI_NHANH: "POST_NEW_CHI_NHANH",
    PUT_EDIT_CHI_NHANH: "PUT_EDIT_CHI_NHANH",
    DELETE_CHI_NHANH: "DELETE_CHI_NHANH",
    // Tạm ứng lương
    GET_LIST_TAM_UNG_LUONG: "GET_LIST_TAM_UNG_LUONG",
    POST_NEW_TAM_UNG_LUONG: "POST_NEW_TAM_UNG_LUONG",
    PUT_EDIT_TAM_UNG_LUONG: "PUT_EDIT_TAM_UNG_LUONG",
    DELETE_TAM_UNG_LUONG: "DELETE_TAM_UNG_LUONG",
    // loai quy dinh phuc loi
    GET_LIST_LOAI_QUY_DINH_PHUC_LOI: "GET_LIST_LOAI_QUY_DINH_PHUC_LOI",
    POST_NEW_LOAI_QUY_DINH_PHUC_LOI: "POST_NEW_LOAI_QUY_DINH_PHUC_LOI",
    //Tài khoa ngân hàng
    GET_LIST_NGAN_HANG: "GET_LIST_NGAN_HANG",
    POST_NEW_NGAN_HANG: "POST_NEW_NGAN_HANG",
    PUT_EDIT_NGAN_HANG: "PUT_EDIT_NGAN_HANG",
    DELETE_NGAN_HANG: "DELETE_NGAN_HANG",
    //Người phụ thuộc
    GET_LIST_NGUOI_PHU_THUOC: "GET_LIST_NGUOI_PHU_THUOC",
    POST_NEW_NGUOI_PHU_THUOC: "POST_NEW_NGUOI_PHU_THUOC",
    PUT_EDIT_NGUOI_PHU_THUOC: "PUT_EDIT_NGUOI_PHU_THUOC",
    DELETE_NGUOI_PHU_THUOC: "DELETE_NGUOI_PHU_THUOC",

    //Cấu hình thuế thu nhập cnlt
    GET_LIST_CAU_HINH_THUE_THU_NHAP_CNLT: "GET_LIST_CAU_HINH_THUE_THU_NHAP_CNLT",
    POST_NEW_CAU_HINH_THUE_THU_NHAP_CNLT: "POST_NEW_CAU_HINH_THUE_THU_NHAP_CNLT",
    PUT_EDIT_CAU_HINH_THUE_THU_NHAP_CNLT: "PUT_EDIT_CAU_HINH_THUE_THU_NHAP_CNLT",
    DELETE_CAU_HINH_THUE_THU_NHAP_CNLT: "DELETE_CAU_HINH_THUE_THU_NHAP_CNLT",
    //đánh giá nhân viên
    GET_LIST_DANH_GIA_NHAN_VIEN: "GET_LIST_DANH_GIA_NHAN_VIEN",
    POST_NEW_DANH_GIA_NHAN_VIEN: "POST_NEW_DANH_GIA_NHAN_VIEN",
    PUT_EDIT_DANH_GIA_NHAN_VIEN: "PUT_EDIT_DANH_GIA_NHAN_VIEN",
    DELETE_DANH_GIA_NHAN_VIEN: "DELETE_DANH_GIA_NHAN_VIEN",
    //hoạt động sự kiện
    GET_LIST_HOAT_DONG_SU_KIEN: "GET_LIST_HOAT_DONG_SU_KIEN",
    POST_NEW_HOAT_DONG_SU_KIEN: "POST_NEW_HOAT_DONG_SU_KIEN",
    PUT_EDIT_HOAT_DONG_SU_KIEN: "PUT_EDIT_HOAT_DONG_SU_KIEN",
    DELETE_HOAT_DONG_SU_KIEN: "DELETE_HOAT_DONG_SU_KIEN",


    //Kiển định an toàn vệ sinh bảo hiểm lao động
    GET_LIST_KIEM_DINH_ATVSBHLD: "GET_LIST_KIEM_DINH_ATVSBHLD",
    POST_NEW_KIEM_DINH_ATVSBHLD: "POST_NEW_KIEM_DINH_ATVSBHLD",
    PUT_EDIT_KIEM_DINH_ATVSBHLD: "PUT_EDIT_KIEM_DINH_ATVSBHLD",
    DELETE_KIEM_DINH_ATVSBHLD: "DELETE_KIEM_DINH_ATVSBHLD",
    //Loại kiểm định an toàn vệ sinh bảo hiểm lao động
    GET_LIST_LOAI_KIEM_DINH_ATVSBHLD: "GET_LIST_LOAI_KIEM_DINH_ATVSBHLD",
    POST_NEW_LOAI_KIEM_DINH_ATVSBHLD: "POST_NEW_LOAI_KIEM_DINH_ATVSBHLD",
    PUT_EDIT_LOAI_KIEM_DINH_ATVSBHLD: "PUT_EDIT_LOAI_KIEM_DINH_ATVSBHLD",
    DELETE_LOAI_KIEM_DINH_ATVSBHLD: "DELETE_LOAI_KIEM_DINH_ATVSBHLD",
    //Nhân viên kiểm định an toàn vệ sinh bảo hiểm lao động
    GET_LIST_NHAN_VIEN_KIEM_DINH_ATVSBHLD: "GET_LIST_NHAN_VIEN_KIEM_DINH_ATVSBHLD",
    POST_NEW_NHAN_VIEN_KIEM_DINH_ATVSBHLD: "POST_NEW_NHAN_VIEN_KIEM_DINH_ATVSBHLD",
    PUT_EDIT_NHAN_VIEN_KIEM_DINH_ATVSBHLD: "PUT_EDIT_NHAN_VIEN_KIEM_DINH_ATVSBHLD",
    DELETE_NHAN_VIEN_KIEM_DINH_ATVSBHLD: "DELETE_NHAN_VIEN_KIEM_DINH_ATVSBHLD",


    //Mối quan hệ lao động
    GET_LIST_MOI_QUAN_HE_LAO_DONG: "GET_LIST_MOI_QUAN_HE_LAO_DONG",
    POST_NEW_MOI_QUAN_HE_LAO_DONG: "POST_NEW_MOI_QUAN_HE_LAO_DONG",
    PUT_EDIT_MOI_QUAN_HE_LAO_DONG: "PUT_EDIT_MOI_QUAN_HE_LAO_DONG",
    DELETE_MOI_QUAN_HE_LAO_DONG: "DELETE_MOI_QUAN_HE_LAO_DONG",
    //Mối quan hệ nhân viên
    GET_LIST_MOI_QUAN_HE_NHAN_VIEN: "GET_LIST_MOI_QUAN_HE_NHAN_VIEN",
    POST_NEW_MOI_QUAN_HE_NHAN_VIEN: "POST_NEW_MOI_QUAN_HE_NHAN_VIEN",
    PUT_EDIT_MOI_QUAN_HE_NHAN_VIEN: "PUT_EDIT_MOI_QUAN_HE_NHAN_VIEN",
    DELETE_MOI_QUAN_HE_NHAN_VIEN: "DELETE_MOI_QUAN_HE_NHAN_VIEN",
    //Phương tiện
    GET_LIST_PHUONG_TIEN: "GET_LIST_PHUONG_TIEN",
    POST_NEW_PHUONG_TIEN: "POST_NEW_PHUONG_TIEN",
    PUT_EDIT_PHUONG_TIEN: "PUT_EDIT_PHUONG_TIEN",
    DELETE_PHUONG_TIEN: "DELETE_PHUONG_TIEN",
    //Loại Phương tiện
    GET_LIST_LOAI_PHUONG_TIEN: "GET_LIST_LOAI_PHUONG_TIEN",
    POST_NEW_LOAI_PHUONG_TIEN: "POST_NEW_LOAI_PHUONG_TIEN",
    PUT_EDIT_LOAI_PHUONG_TIEN: "PUT_EDIT_LOAI_PHUONG_TIEN",
    DELETE_LOAI_PHUONG_TIEN: "DELETE_LOAI_PHUONG_TIEN",
    //Loại phí phụ
    GET_LIST_PHI_PHU: "GET_LIST_PHI_PHU",
    POST_NEW_PHI_PHU: "POST_NEW_PHI_PHU",
    PUT_EDIT_PHI_PHU: "PUT_EDIT_PHI_PHU",
    DELETE_PHI_PHU: "DELETE_PHI_PHU",
    //Kế hoặch của xe
    GET_LIST_KE_HOACH_CUA_XE: "GET_LIST_KE_HOACH_CUA_XE",
    POST_NEW_KE_HOACH_CUA_XE: "POST_NEW_KE_HOACH_CUA_XE",
    PUT_EDIT_KE_HOACH_CUA_XE: "PUT_EDIT_KE_HOACH_CUA_XE",
    DELETE_KE_HOACH_CUA_XE: "DELETE_KE_HOACH_CUA_XE",
    //Bảo dưỡng phương tiện
    GET_LIST_BAO_DUONG_PHUONG_TIEN: "GET_LIST_BAO_DUONG_PHUONG_TIEN",
    POST_NEW_BAO_DUONG_PHUONG_TIEN: "POST_NEW_BAO_DUONG_PHUONG_TIEN",
    PUT_EDIT_BAO_DUONG_PHUONG_TIEN: "PUT_EDIT_BAO_DUONG_PHUONG_TIEN",
    DELETE_BAO_DUONG_PHUONG_TIEN: "DELETE_BAO_DUONG_PHUONG_TIEN",
    //Đăng kiểm phương tiện
    GET_LIST_DANG_KIEM_PHUONG_TIEN: "GET_LIST_DANG_KIEM_PHUONG_TIEN",
    POST_NEW_DANG_KIEM_PHUONG_TIEN: "POST_NEW_DANG_KIEM_PHUONG_TIEN",
    PUT_EDIT_DANG_KIEM_PHUONG_TIEN: "PUT_EDIT_DANG_KIEM_PHUONG_TIEN",
    DELETE_DANG_KIEM_PHUONG_TIEN: "DELETE_DANG_KIEM_PHUONG_TIEN",
    //Quản lý quỹ thưởng
    GET_LIST_QUAN_LY_QUY_THUONG: "GET_LIST_QUAN_LY_QUY_THUONG",
    POST_NEW_QUAN_LY_QUY_THUONG: "POST_NEW_QUAN_LY_QUY_THUONG",
    PUT_EDIT_QUAN_LY_QUY_THUONG: "PUT_EDIT_QUAN_LY_QUY_THUONG",
    DELETE_QUAN_LY_QUY_THUONG: "DELETE_QUAN_LY_QUY_THUONG",
    //Quản lý thâm niêm
    GET_LIST_QUAN_LY_THAM_NIEM: "GET_LIST_QUAN_LY_THAM_NIEM",
    POST_NEW_QUAN_LY_THAM_NIEM: "POST_NEW_QUAN_LY_THAM_NIEM",
    PUT_EDIT_QUAN_LY_THAM_NIEM: "PUT_EDIT_QUAN_LY_THAM_NIEM",
    DELETE_QUAN_LY_THAM_NIEM: "DELETE_QUAN_LY_THAM_NIEM",
    //Thông báo nội bộ
    GET_LIST_THONG_BAO_NOI_BO: "GET_LIST_THONG_BAO_NOI_BO",
    POST_NEW_THONG_BAO_NOI_BO: "POST_NEW_THONG_BAO_NOI_BO",
    PUT_EDIT_THONG_BAO_NOI_BO: "PUT_EDIT_THONG_BAO_NOI_BO",
    DELETE_THONG_BAO_NOI_BO: "DELETE_THONG_BAO_NOI_BO",
    //Văn thư
    GET_LIST_VAN_THU: "GET_LIST_VAN_THU",
    POST_NEW_VAN_THU: "POST_NEW_VAN_THU",
    PUT_EDIT_VAN_THU: "PUT_EDIT_VAN_THU",
    DELETE_VAN_THU: "DELETE_VAN_THU",
    //Xếp loại
    GET_LIST_XEP_LOAI: "GET_LIST_XEP_LOAI",
    POST_NEW_XEP_LOAI: "POST_NEW_XEP_LOAI",
    PUT_EDIT_XEP_LOAI: "PUT_EDIT_XEP_LOAI",
    DELETE_XEP_LOAI: "DELETE_XEP_LOAI",
    //Xếp loại nhân viên
    GET_LIST_XEP_LOAI_NHAN_VIEN: "GET_LIST_XEP_LOAI_NHAN_VIEN",
    POST_NEW_XEP_LOAI_NHAN_VIEN: "POST_NEW_XEP_LOAI_NHAN_VIEN",
    PUT_EDIT_XEP_LOAI_NHAN_VIEN: "PUT_EDIT_XEP_LOAI_NHAN_VIEN",
    DELETE_XEP_LOAI_NHAN_VIEN: "DELETE_XEP_LOAI_NHAN_VIEN",
    //Cấu hình nghỉ phép
    GET_LIST_CAU_HINH_NGHI_PHEP: "GET_LIST_CAU_HINH_NGHI_PHEP",
    POST_NEW_CAU_HINH_NGHI_PHEP: "POST_NEW_CAU_HINH_NGHI_PHEP",
    PUT_EDIT_CAU_HINH_NGHI_PHEP: "PUT_EDIT_CAU_HINH_NGHI_PHEP",
    DELETE_CAU_HINH_NGHI_PHEP: "DELETE_CAU_HINH_NGHI_PHEP",
    //Lý do nghỉ Phép
    GET_LIST_LY_DO_NGHI_PHEP: "GET_LIST_LY_DO_NGHI_PHEP",
    POST_NEW_LY_DO_NGHI_PHEP: "POST_NEW_LY_DO_NGHI_PHEP",
    PUT_EDIT_LY_DO_NGHI_PHEP: "PUT_EDIT_LY_DO_NGHI_PHEP",
    DELETE_LY_DO_NGHI_PHEP: "DELETE_LY_DO_NGHI_PHEP",
    //Thong bao
    GET_LIST_THONG_BAO: "GET_LIST_THONG_BAO",
    POST_NEW_THONG_BAO: "POST_NEW_THONG_BAO",
    PUT_EDIT_THONG_BAO: "PUT_EDIT_THONG_BAO",
    DELETE_THONG_BAO: "DELETE_THONG_BAO",
    //Phân ca
    GET_LIST_PHAN_CA: "GET_LIST_PHAN_CA",
    POST_NEW_PHAN_CA: "POST_NEW_PHAN_CA",
    PUT_EDIT_PHAN_CA: "PUT_EDIT_PHAN_CA",
    DELETE_PHAN_CA: "DELETE_PHAN_CA",
    //Cấu hình phê duyệt
    GET_LIST_CAU_HINH_PHE_DUYET: "GET_LIST_CAU_HINH_PHE_DUYET",
    POST_NEW_CAU_HINH_PHE_DUYET: "POST_NEW_CAU_HINH_PHE_DUYET",
    PUT_EDIT_CAU_HINH_PHE_DUYET: "PUT_EDIT_CAU_HINH_PHE_DUYET",
    DELETE_CAU_HINH_PHE_DUYET: "DELETE_CAU_HINH_PHE_DUYET",

    GET_CATEGORIES: 'GET_CATEGORIES',
    GET_PRICES: 'GET_PRICES',
    GET_AREAS: 'GET_AREAS',
    GET_PROVINCES: 'GET_PROVINCES',

    // USER
    GET_CURRENT: 'GET_CURRENT'
}

export default actionTypes
