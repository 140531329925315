import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {InputForm, Button} from '~/components/Form';
import * as actions from '~/store/actions';
import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import {validateLyDoNghiPhep} from '~/request/validateLyDoNghiPhep';
import {dataSelectStatus} from '~/utils/Datacustom';
import Textarea from "~/components/Form/Textarea";

const ModalEditLydonghiphep = ({show, setShow, dataIdLydonghiphep, fieldList}) => {
    //   console.log('dataIdLydonghiphep',dataIdLydonghiphep)
    const dispatch = useDispatch();
    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayload] = useState(fieldList);
    useEffect(() => {
        show &&
        dataIdLydonghiphep &&
        setPayload({
            tieude: dataIdLydonghiphep?.tieude,
            songaynghitoida: dataIdLydonghiphep?.songaynghitoida,
            tylehuongluong: dataIdLydonghiphep?.tylehuongluong,
            ghichu: dataIdLydonghiphep?.ghichu,
            TrangthaiId: dataSelectStatus?.find((items) => Number(items.value) === Number(dataIdLydonghiphep.TrangthaiId)),
        });
    }, [dataIdLydonghiphep, show]);
    
    const handleSubmit = async () => {
        let invalids = validate(payload);
        if (invalids === 0) {
            let datapayload = '';
            if(dataIdLydonghiphep.is_default !== 1){
                datapayload = {
                    data: [
                        {
                            id: dataIdLydonghiphep.id,
                            songaynghitoida: payload.songaynghitoida,
                            tieude: payload.tieude,
                            ghichu: payload.ghichu,
                            tylehuongluong: payload.tylehuongluong,
                            TrangthaiId: payload.TrangthaiId.value,
                        },
                    ],
                };
            }else{
                datapayload = {
                    data: [
                        {
                            id: dataIdLydonghiphep.id,
                            songaynghitoida: payload.songaynghitoida,
                            TrangthaiId: payload.TrangthaiId.value,
                        },
                    ],
                };
            }
            
            //   console.log('check payload', datapayload)
            dispatch(actions.EditLydonghiphep(datapayload));
        }
    };
    
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateLyDoNghiPhep(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateLyDoNghiPhep(item[0], item[1])]);
                invalids++;
            }
        });
        return invalids;
    };
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">Sửa lý do nghỉ phép</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Lý do nghỉ'}
                            value={payload.tieude}
                            setValue={setPayload}
                            keyPayload={'tieude'}
                            type="text"
                            validate
                            disabled={dataIdLydonghiphep.is_default !== 1 ? false : true}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Số ngày nghỉ tối đa'}
                            value={payload.songaynghitoida}
                            setValue={setPayload}
                            keyPayload={'songaynghitoida'}
                            type="number"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Tỷ lệ hưởng lương (%)'}
                            value={payload.tylehuongluong}
                            setValue={setPayload}
                            keyPayload={'tylehuongluong'}
                            type="number"
                            validate
                            disabled={dataIdLydonghiphep.is_default !== 1 ? false : true}
                        />
                    </div>
                    
                    <div className="form-group col-md-6">
                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                                Trạng thái <b className="text-danger">(*)</b>
                            </span>
                        </label>
                        <Select
                            value={payload.TrangthaiId}
                            onChange={(selected) => setPayload((prev) => ({...prev, ['TrangthaiId']: selected}))}
                            options={dataSelectStatus}
                            placeholder={'Chọn trạng thái'}
                            name="TrangthaiId"
                            // isDisabled={dataIdLydonghiphep.is_default !== 1 ? false : true}
                            onFocus={() => setInvalidFields([])}
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'TrangthaiId') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'TrangthaiId')?.message}
                            </small>
                        )}
                    </div>
                    <div className="form-group col-md-12">
                        <Textarea
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ghi chú'}
                            value={payload.ghichu}
                            setValue={setPayload}
                            keyPayload={'ghichu'}
                            type='text'
                            disabled={dataIdLydonghiphep.is_default !== 1 ? false : true}
                        />
                    </div>
                </div>
                <div className="col-12 mt-5">
                    <Button text="Cập nhật" bClass="btn btn-primary px-5" textColor onClick={handleSubmit}/>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalEditLydonghiphep;
