export const validateNhanVien = (fieldName, value) => {
    let regNumber = /^[0-9\b]+$/;
    switch (fieldName) {
        case 'ho':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Họ không được để trống'
                }
            }
            break;
        case 'ten':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Tên không được để trống'
                }
            }
            break;
        case 'diachi':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Địa chỉ không được để trống'
                }
            }
            break;
        case 'email':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Email không được để trống'
                }
            }
            break;
        case 'dantoc':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Dân tộc không được để trống'
                }
            }
            break;
        case 'quoctich':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Quôc tịch không được để trống'
                }
            }
            break;
        // case 'nguyenquan':
        //     if (value.length === 0) {
        //         return {
        //             name: fieldName,
        //             message: 'Nguyên quán không được để trống'
        //         }
        //     }
        //     break;
        case 'tenthuonggoi':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Họ tên không được để trống'
                }
            }
            break;
            // case 'nguoilienhe':
            // if (value.length === 0) {
            //     return {
            //         name: fieldName,
            //         message: 'Thông tin người liên hệ không được để trống'
            //     }
            // }
            // break;
        // case 'socmnd':
        //     if (value.length === 0) {
        //         return {
        //             name: fieldName,
        //             message: ' Số chứng minh thư không được để trống'
        //         }
        //     }
        //     break;
        case 'KhaibaocalamviecId':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Hãy chọn Ca làm việc'
                }
            }
            break;
        case 'PhongbanId':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Hãy chọn Phòng ban'
                }
            }
            break;
        case 'ChucvuId':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Hãy chon chức vụ.'
                }
            }
            break;
        case 'KieuluongId':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Hãy chon kiểu lương.'
                }
            }
            break;
        case 'ngayvaolamviec':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Khai báo ngày vào làm việc.'
                }
            }
            break;
        case 'dienthoai':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Số điện thoại không được bỏ trống trường này.'
                }
            } else if (!regNumber.test(value)) {
                return {
                    name: fieldName,
                    message: 'Số điện thoại không đúng định dạng'
                }
            } else if (value.length < 10) {
                return {
                    name: fieldName,
                    message: 'Số điện thoại có độ dài từ 10-12'
                }
                
            }
            break;
        default:
            break;
    }
}