import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {InputForm, Button, DatePickerTime} from '~/components/Form'
import * as actions from '~/store/actions'

import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import {format} from "date-fns";
import {validateUngVien} from "~/request/validateUngVien";

const ModalEditUngvien = ({show, setShow, dataIdUngvien, param}) => {
    const {phongban} = useSelector(state => state.phongban)
    const {vitrituyendung} = useSelector(state => state.vitrituyendung)
    const dispatch = useDispatch()
    const [invalidFields, setInvalidFields] = useState([])
    const [payload, setPayloadUngvien] = useState(param)
    // const [startDate, setStartDate] = useState(new Date());
    useEffect(() => {
      /*  if (dataIdUngvien?.ngaysinh) {
            const [year, month, day] = dataIdUngvien?.ngaysinh?.split('/')
            setStartDate(new Date(`${month}/${year}/${day}`))
        }*/
        let VitrituyendungId = vitrituyendung?.find(item => {
            return item && item.id === dataIdUngvien?.VitrituyendungId
          })
        let PhongbanId = phongban?.find(item => {
            return item && item.id === dataIdUngvien?.PhongbanId
        })
        show && dataIdUngvien && setPayloadUngvien({
            VitrituyendungId: {label: VitrituyendungId?.titletuyendung, value: VitrituyendungId?.id},
            PhongbanId: {label: PhongbanId?.phongban, value: PhongbanId?.id},
            tenthuonggoi: dataIdUngvien?.tenthuonggoi,
            diachihientai: dataIdUngvien?.diachihientai,
            dienthoai: dataIdUngvien?.dienthoai,
            email: dataIdUngvien?.email,
            nguoilienhe: dataIdUngvien?.nguoilienhe,
            ngaysinh: dataIdUngvien?.ngaysinh,
            noisinh: dataIdUngvien?.noisinh,
            nguyenquan: dataIdUngvien?.nguyenquan,
            socmnd: dataIdUngvien?.socmnd,
            noicap: dataIdUngvien?.noicap,
            dantoc: dataIdUngvien?.dantoc,
            quoctich: dataIdUngvien?.quoctich,
        })
    }, [dataIdUngvien, show])
    

    const [listPhongban, setlistPhongban] = useState([])
    const [listViTriTuyenDung, setlistViTriTuyenDung] = useState([])
    useEffect(() => {
        if (phongban && vitrituyendung) {
            let dataSelectPhongban = phongban.map((item) => ({ label: item.phongban, value: item.id }));
            let dataSelectViTriTuyenDung = vitrituyendung.map((item) => ({
                label: item.titletuyendung,
                value: item.id,
            }));
            setlistPhongban(dataSelectPhongban)
            setlistViTriTuyenDung(dataSelectViTriTuyenDung)
        }
    }, [phongban,vitrituyendung])
    const handleSubmit = async () => {
        let invalids = validate(payload)
        
        if (invalids === 0) {
            let datapayload = {
                id: dataIdUngvien.id,
                VitrituyendungId: payload.VitrituyendungId.value,
                PhongbanId: payload.PhongbanId.value,
                tenthuonggoi: payload.tenthuonggoi,
                diachihientai: payload.diachihientai,
                dienthoai: payload.dienthoai,
                email: payload.email,
                nguoilienhe: payload.nguoilienhe,
                ngaysinh: payload.ngaysinh,
                noisinh: payload.noisinh,
                nguyenquan: payload.nguyenquan,
                socmnd: payload.socmnd,
                noicap: payload.noicap,
                dantoc: payload.dantoc,
                quoctich: payload.quoctich,
            }
            dispatch(actions.EditUngvien(datapayload))
        }
    }
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayloadUngvien(prev => ({...prev, [statename]: selected}))
    }
    const validate = (payload) => {
        let invalids = 0
        let fields = Object.entries(payload)
        // console.log(fields)
        fields.forEach(item => {
            if (validateUngVien(item[0], item[1]) !== undefined) {
                setInvalidFields(prev => [...prev, validateUngVien(item[0], item[1]) ])
                invalids++
            }
        })
        
        return invalids
    }
    // const validate = (payload) => {}
    
    
    
    
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Sửa thông tin ứng viên
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-6 ">
                        <label htmlFor="inputZip" className="mb-2">Vị trí tuyển dụng</label>
                        <Select
                            value={payload.VitrituyendungId}
                            onChange={handleOnSelect}
                            options={listViTriTuyenDung}
                            placeholder={'Chọn Vị trí tuyển dụng'}
                            name='VitrituyendungId'
                            onFocus={() => setInvalidFields([])}
                        />
                        {invalidFields.length > 0 && invalidFields.some(i => i.name === 'VitrituyendungId') && <small
                            className='text-danger'>{invalidFields.find(i => i.name === 'VitrituyendungId')?.message}</small>}
                    </div>
                    
        
                    <div className="form-group col-md-6 ">
                        <label htmlFor="inputZip" className="mb-2">Phòng ban</label>
                        <Select
                            value={payload.PhongbanId}
                            onChange={handleOnSelect}
                            options={listPhongban}
                            placeholder={'Chọn phòng ban'}
                            name='PhongbanId'
                            onFocus={() => setInvalidFields([])}
                        />
                        {invalidFields.length > 0 && invalidFields.some(i => i.name === 'PhongbanId') && <small
                            className='text-danger'>{invalidFields.find(i => i.name === 'PhongbanId')?.message}</small>}
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Tên thường gọi'}
                            value={payload.tenthuonggoi}
                            setValue={setPayloadUngvien}
                            keyPayload={'tenthuonggoi'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-6 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Địa chỉ hiện tại'}
                            value={payload.diachihientai}
                            setValue={setPayloadUngvien}
                            keyPayload={'diachihientai'}
                            type='text'
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Số điện thoại'}
                            value={payload.dienthoai}
                            setValue={setPayloadUngvien}
                            keyPayload={'dienthoai'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-6 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Email'}
                            value={payload.email}
                            setValue={setPayloadUngvien}
                            keyPayload={'email'}
                            type='text'
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Người liên hệ khi cần'}
                            value={payload.nguoilienhe}
                            setValue={setPayloadUngvien}
                            keyPayload={'nguoilienhe'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-6 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ngày sinh'}
                            value={payload.ngaysinh}
                            setValue={setPayloadUngvien}
                            keyPayload={'ngaysinh'}
                            type='date'
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Nơi sinh'}
                            value={payload.noisinh}
                            setValue={setPayloadUngvien}
                            keyPayload={'noisinh'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-6 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Nguyên quán'}
                            value={payload.nguyenquan}
                            setValue={setPayloadUngvien}
                            keyPayload={'nguyenquan'}
                            type='text'
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Số CMND'}
                            value={payload.socmnd}
                            setValue={setPayloadUngvien}
                            keyPayload={'socmnd'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-6 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Nơi cấp'}
                            value={payload.noicap}
                            setValue={setPayloadUngvien}
                            keyPayload={'noicap'}
                            type='text'
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-md-6">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Dân tộc'}
                            value={payload.dantoc}
                            setValue={setPayloadUngvien}
                            keyPayload={'dantoc'}
                            type='text'
                        />
                    </div>
                    <div className="form-group col-md-6 ">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Quốc tịch'}
                            value={payload.quoctich}
                            setValue={setPayloadUngvien}
                            keyPayload={'quoctich'}
                            type='text'
                        />
                    </div>
                </div>
                
                <div className='col-12 mt-5'>
                    <Button
                        text='Sửa ứng viên'
                        bClass='btn btn-primary px-5'
                        textColor
                        onClick={handleSubmit}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ModalEditUngvien;

