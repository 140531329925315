import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { InputForm, Button } from '~/components/Form';

import '../Modal.scss';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { validateTamUngLuong } from '~/request/validateTamUngLuong';
import { Input } from 'reactstrap';
import { dataSelectStatus } from '~/utils/Datacustom';
import { NumericFormat } from 'react-number-format';
import {apiEditTamungluong} from "~/services";
import {toast} from "react-toastify";

const ModalEditTamungluong = ({ show, setShow, dataIdTamungluong, fieldList }) => {
    const { nhanvien } = useSelector((state) => state.nhanvien);
    const [listTrangthai, setlistTrangthai] = useState([]);

    const [invalidFields, setInvalidFields] = useState([]);
    const [payload, setPayloadTamungluong] = useState(fieldList);
    useEffect(() => {
        let TrangthaiId = listTrangthai?.find((item) => {
            return item && item.value === dataIdTamungluong?.TrangthaiId;
        });
        show &&
            dataIdTamungluong &&
            setPayloadTamungluong({
                NhanvienId: { label: dataIdTamungluong?.Nhanvien?.hoten, value: dataIdTamungluong?.NhanvienId },
                ngaydexuat: dataIdTamungluong?.ngaydexuat,
                giodexuat: dataIdTamungluong?.giodexuat,
                mucdexuat: dataIdTamungluong?.mucdexuat,
                mucdexuatformat: dataIdTamungluong?.mucdexuat,
                ghichu: dataIdTamungluong?.ghichu,
                TrangthaiId: { label: TrangthaiId?.label, value: TrangthaiId?.value },
            });
    }, [dataIdTamungluong, show]);
    useEffect(() => {
        if (nhanvien) {
            setlistTrangthai(dataSelectStatus);
        }
    }, [nhanvien]);
    const handleSubmit = async () => {
        let invalids = validate(payload);

        if (invalids === 0) {
            let datapayload = {
                id: dataIdTamungluong.id,
                NhanvienId: payload.NhanvienId.value,
                TrangthaiId: payload.TrangthaiId.value,
                ngaydexuat: payload.ngaydexuat,
                giodexuat: payload.giodexuat,
                mucdexuat: payload.mucdexuat,
                ghichu: payload.ghichu,
            };
            //   console.log('check payload', datapayload)
            const response = await apiEditTamungluong(datapayload)
            if (response?.data.success === 200) {
                toast.success("Sửa thành công!")
                setShow(false);
            } else {
                toast.error("Sửa thất bại!")
            }
            
            
        }
    };
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayloadTamungluong((prev) => ({ ...prev, [statename]: selected }));
    };
    const validate = (payload) => {
        let invalids = 0;
        let fields = Object.entries(payload);
        fields.forEach((item) => {
            if (validateTamUngLuong(item[0], item[1]) !== undefined) {
                setInvalidFields((prev) => [...prev, validateTamUngLuong(item[0], item[1])]);
                invalids++;
            }
        });
        return invalids;
    };
    return (
        <Modal
            show={show}
            onHide={setShow}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">Sửa tạm ứng lương</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-12 mb-3">
                        <label className="mb-2 fw-bold">
                            <span>
                                Chọn nhân viên <b className="text-danger">(*)</b>
                            </span>
                        </label>
                        <Select
                            value={payload.NhanvienId}
                            onChange={handleOnSelect}
                            placeholder={'Chọn nhân viên'}
                            name="NhanvienId"
                            onFocus={() => setInvalidFields([])}
                            isDisabled={true}
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'NhanvienId') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'NhanvienId')?.message}
                            </small>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Ngày đề xuất'}
                            value={payload.ngaydexuat}
                            setValue={setPayloadTamungluong}
                            keyPayload={'ngaydexuat'}
                            type="date"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Giờ đề xuất'}
                            value={payload.giodexuat}
                            setValue={setPayloadTamungluong}
                            keyPayload={'giodexuat'}
                            type="text"
                            validate
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <div className="form-group">
                            <label className="mb-2 fw-bold">
                                <span>
                                     Mức đề xuất <b className="text-danger">(*)</b>
                                </span>
                            </label>
                            <div className="form-control-wrap">
                                <div className="form-text-hint">
                                    <span className="overline-title">VND</span>
                                </div>
                                <NumericFormat
                                    className="form-control px-2"
                                    value={payload.mucdexuatformat}
                                    // displayType={'text'}
                                    thousandSeparator={true}
                                    // suffix={'đ'}
                                    name="mucdexuat"
                                    onValueChange={(values) => {
                                        const { formattedValue, value } = values;
                                        setPayloadTamungluong((prev) => ({ ...prev, ['mucdexuat']: value }));
                                        setPayloadTamungluong((prev) => ({
                                            ...prev,
                                            ['mucdexuatformat']: formattedValue,
                                        }));
                                    }}
                                />
                                {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'mucdexuat') && (
                                    <small className="text-danger">
                                        {invalidFields.find((i) => i.name === 'mucdexuat')?.message}
                                    </small>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-3">
                        <label className="mb-2 fw-bold">
                            <span>
                                Trạn thái <b className="text-danger">(*)</b>
                            </span>
                        </label>
                        <Select
                            value={payload.TrangthaiId}
                            onChange={handleOnSelect}
                            options={listTrangthai}
                            name="TrangthaiId"
                            onFocus={() => setInvalidFields([])}
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'TrangthaiId') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'TrangthaiId')?.message}
                            </small>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-md-12 mt-2">
                        <label htmlFor="inputZip" className="mb-2 fw-bold">
                            <span>
                                Ghi chú <b className="text-danger">(*)</b>
                            </span>
                        </label>
                        <Input
                            value={payload.ghichu}
                            onChange={(e) => setPayloadTamungluong((prev) => ({ ...prev, ['ghichu']: e.target.value }))}
                            rows={'2'}
                            name="ghichu"
                            type="textarea"
                        />
                        {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'ghichu') && (
                            <small className="text-danger">
                                {invalidFields.find((i) => i.name === 'ghichu')?.message}
                            </small>
                        )}
                    </div>
                </div>

                <div className="col-12 mt-5">
                    <Button text="Cập nhật" bClass="btn btn-primary px-5" textColor onClick={handleSubmit} />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalEditTamungluong;
