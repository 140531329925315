import React, { useState, useEffect } from 'react';
import menu from './MenuData';
import { Link, useLocation } from 'react-router-dom';
import { PermissionUtils } from '~/components/Component';
const MenuItem = ({ item, headActive }) => {
    const { subMenu, text, link, module } = item;
    const location = useLocation();
    const active = location?.pathname?.split('/');

    return (
        <React.Fragment>
            {subMenu ? (
                <li className={`nk-menu-item ${subMenu ? 'has-sub' : ''} 
                ${ link === active[2] ? 'active current-page' : ''} ${headActive ? "active current-page" : ""}`}
                >
                    <a
                        href="#toggle"
                        className="nk-menu-link nk-menu-toggle"
                        onClick={(ev) => {
                            ev.preventDefault();
                        }}
                    >
                        <span className="nk-menu-text">{text}</span>
                    </a>
                    <MenuSub subMenu={subMenu} />
                </li>
            ) : (
                <MenuModule link={link} text={text} module={module} subMenu={subMenu} active= {active[2]}/>
            )}
        </React.Fragment>
    );
};

const MenuModule = ({ link, text, module, subMenu, active }) => {
    // 0 => thêm, 1 => sửa, 2=> xóa, 3=> xem
    let valuemodule = PermissionUtils(module, 'xem');
    if (+valuemodule === 1) {
        return (
            <li className={`nk-menu-item ${subMenu ? 'has-sub' : ''} 
            ${ link === active ? 'active current-page' : ''} `}
            >
                <Link className="nk-menu-link" to={link}>
                    <span className="nk-menu-text">{text}</span>
                </Link>
            </li>
        );
    }
};

const MenuSub = ({ subMenu }) => {
    return (
        <ul className="nk-menu-sub">
            {subMenu.map((sub, index) => (
                <MenuItem item={sub} key={index} />
            ))}
        </ul>
    );
};

const findActiveHead = (data) => {
    for (const item of menu) {
        if(item.link === data){
            return item.text;
        }else if (item.subMenu && item.subMenu.find((subItem) => subItem.link === data)) {
            return item.text;
        }
    }
    return null;
  };
const Menu = () => {
    const [head, setHead] = useState("Dashboards");
    const location = useLocation();
    const active = location?.pathname?.split('/');
    let findingActiveHead = findActiveHead(active[2]);
  useEffect(() => {
    if (findingActiveHead) {
      setHead(findingActiveHead);
    }
  }, [active]);
    return (
        <React.Fragment>
            <ul className="nk-menu nk-menu-main ui-s2">
                {menu.map((item, index) => {
                    if (item.text === head) {
                        return <MenuItem key={index} item={item} headActive={true} />;
                      } else return <MenuItem key={index} item={item} />;
                })}
            </ul>
        </React.Fragment>
    );
};

export default Menu;
 
