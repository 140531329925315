export const validateNhanVienChiTiet = (fieldName, value) => {
    let regNumber = /^[0-9\b]+$/;
    let regMail = /^[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
    switch (fieldName) {
      
        case 'email':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Email không được để trống'
                }
            } else if (!regMail.test(value)) {
                return {
                    name: fieldName,
                    message: 'Email không đúng định dạng'
                }
            }
            break;
            case 'hoten':
            if (value.length === 0) {
                return {
                    name: fieldName,
                    message: 'Nội dung không được để trống'
                }
            }
            break;
        default:
            break;
    }
}