import actionTypes from './actionTypes'
import { apiGetSuatan, apiNewSuatan, apiEditSuatan, apiDeleteSuatan } from '~/services/suatan'
import { toast } from "react-toastify";
export const getListSuatan = () => async (dispatch) => {
    try {
        const response = await apiGetSuatan()
        // console.log('chek api lisst Suatan', response?.data)
        if (response?.data.success === 200) {
            dispatch({
                type: actionTypes.GET_LIST_SUAT_AN,
                suatan: response?.data
            })
        } else {
            dispatch({
                type: actionTypes.GET_LIST_SUAT_AN,
                msg: response.data.msg
            })
        }

    } catch (error) {
        dispatch({
            type: actionTypes.GET_LIST_SUAT_AN,
            suatan: null
        })
    }
}

export const NewSuatan = (payload) => {
    return async (dispatch) => {
        try {
            // console.log('chek api apiNewSuatan', payload)
            const response = await apiNewSuatan(payload)
            // console.log('chek api apiNewSuatan', response)
            if (response?.data.success === 200) {
                dispatch({
                    type: actionTypes.POST_NEW_SUAT_AN,
                    msg: 'OK'
                });
                dispatch(getListSuatan());
                toast.success("Thêm mới suất ăn thành công!")
            } else {
                dispatch({
                    type: actionTypes.POST_NEW_SUAT_AN,
                    msg: 'ERR'
                })
                toast.error("Thêm mới suất ăn thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.POST_NEW_SUAT_AN,
                suatan: null
            })
        }
    }
}
export const EditSuatan = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiEditSuatan(payload)
            // console.log('chek api apiEditSuatan', response)
            if (response?.data.success === 200) {
                dispatch(getListSuatan());
                toast.success("Sửa thông tin suất ăn thành công!")
                dispatch({
                    type: actionTypes.PUT_EDIT_SUAT_AN,
                    msg: 'OK'
                });
            } else {
                dispatch({
                    type: actionTypes.PUT_EDIT_SUAT_AN,
                    msg: 'ERR'
                })
                toast.error("Sửa thông tin suất ăn thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.PUT_EDIT_SUAT_AN,
                suatan: null
            })
        }
    }
}
export const DeleteSuatan = (payload) => {
    return async (dispatch) => {
        try {
            const response = await apiDeleteSuatan(payload)
            // console.log('chek api apiDeleteSuatan', response?.data.success)
            if (response?.data.success === 200) {
                dispatch(getListSuatan());
                toast.success("Xóa thông tin suất ăn thành công!")
                dispatch({
                    type: actionTypes.DELETE_SUAT_AN,
                    msg: 'OK'
                });
               
            } else {
                dispatch({
                    type: actionTypes.DELETE_SUAT_AN,
                    msg: 'ERR'
                })
                toast.error("Xóa thông tin suất ăn thất bại!")
            }

        } catch (error) {
            dispatch({
                type: actionTypes.DELETE_SUAT_AN,
                suatan: null
            })
        }
    }
}