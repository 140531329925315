import React, {useState, useEffect} from 'react'
import {InputForm, Button} from '~/components/Form'
import '../Modal.scss';
import Select from 'react-select';
import {apiAddSanluongnhanvien, apiEditSanluongnhanvien, apiNewCauhinhsanluong} from "~/services";
import {toast} from "react-toastify";
import Content from "~/layout/content/Content";
import {Modal, ModalBody} from 'reactstrap';
// import Textarea from "~/components/Form/Textarea";
import {dataSelectActive} from "~/utils/Datacustom";
import Icon from "~/components/icon/Icon";
import {SelectFinNhanvien} from "~/components/Component";

const ModalKhac = ({show, setShow, fieldList,dataEdit,idCauHinh}) => {
    // console.log('idCauHinh', idCauHinh)
    const [invalidFields, setInvalidFields] = useState([])
    const [payload, setPayload] = useState(fieldList)
    const [showSelect, setShowSelect] = useState(false);
    const [listNhanvien, setlistNhanvien] = useState([]);
    useEffect(() => {
        show && setPayload(fieldList)
        setlistNhanvien(
            dataEdit?.map((item) => ({
                value: item?.value,
                label: item?.label,
            })),
        );
    }, [show])
    useEffect(() => {
        setPayload((prev) => ({
            ...prev,
            ['NhanvienId']: listNhanvien,
        }));
    }, [listNhanvien]);
    useEffect(() => {
        const valueQuantityDifference = (payload.quantityTarget - payload.quantityAchieve)
        setPayload(prev => ({...prev, quantityDifference: valueQuantityDifference}))
    },[payload.quantityTarget, payload.quantityAchieve])
    const handleSubmit = async () => {
        let invalids = 0
        
        if (invalids === 0) {
            const dataNhanVien = listNhanvien?.map((item) => {
                return item.value
            });
            let datapayload = {
                data: [
                    {
                        NhanvienId: dataNhanVien,
                        BangsanluongId: idCauHinh,
                        quantityTarget: payload.quantityTarget,
                        quantityAchieve: payload.quantityAchieve,
                        quantityDifference: payload.quantityDifference,
                        deductProduct: payload.deductProduct,
                        sumProduct: payload.sumProduct,
                        status: payload.status.value,
                    }
                ]
            }
            console.log('check payload', datapayload)
          
            const data = await apiAddSanluongnhanvien(datapayload)
            if (data.data.success === 200) {
                toast.success(data.data.message);
                setShow(false)
            } else {
                toast.error("Cập nhật thất bại!");
            }
        }
    }
    const handleOnSelect = async (selected, name) => {
        let statename = name.name;
        setPayload(prev => ({...prev, [statename]: selected}))
    }
    return (
        <React.Fragment>
            <Content>
                <Modal isOpen={show} toggle={setShow} className="modal-dialog-centered modal-90w">
                    <ModalBody>
                        <Icon name="cross-sm float-end fs-2" onClick={setShow}></Icon>
                        <h5 className="title my-2"> Thêm mới</h5>
                        <div className="row">
                            <div className="form-group col-md-6">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Số lượng đề xuất'}
                                    value={payload.quantityTarget}
                                    setValue={setPayload}
                                    keyPayload={'quantityTarget'}
                                    type='text'
                                    validate
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Số lượng đạt được'}
                                    value={payload.quantityAchieve}
                                    setValue={setPayload}
                                    keyPayload={'quantityAchieve'}
                                    type='text'
                                    validate
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Số lượng chênh lệch'}
                                    value={payload.quantityDifference}
                                    setValue={setPayload}
                                    keyPayload={'quantityDifference'}
                                    type='text'
                                    validate
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Giá trị cộng thêm/ 1 sản phẩm'}
                                    value={payload.sumProduct}
                                    setValue={setPayload}
                                    keyPayload={'sumProduct'}
                                    type='text'
                                    validate
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <InputForm
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    label={'Giá trị khấu trừ/ 1 sản phẩm'}
                                    value={payload.deductProduct}
                                    setValue={setPayload}
                                    keyPayload={'deductProduct'}
                                    type='text'
                                    validate
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="inputZip" className="mb-2">Trạng thái</label>
                                <Select
                                    value={payload.status}
                                    onChange={handleOnSelect}
                                    options={dataSelectActive}
                                    placeholder={'Trạng thái'}
                                    name='status'
                                    onFocus={() => setInvalidFields([])}
                                />
                                {invalidFields.length > 0 && invalidFields.some(i => i.name === 'status') && <small
                                    className='text-danger'>{invalidFields.find(i => i.name === 'status')?.message}</small>}
                            </div>
                            <div className="form-group col-md-12" onClick={() => setShowSelect(true)}>
                                <label htmlFor="inputZip" className="mb-2 fw-bold">
                                    Chọn Nhân Viên <b className="text-danger">(*)</b>
                                </label>
                                <Select
                                    value={payload.NhanvienId}
                                    name="NhanvienId"
                                    isMulti
                                    onFocus={() => setInvalidFields([])}
                                />
                                {invalidFields.length > 0 && invalidFields.some((i) => i.name === 'NhanvienId') && (
                                    <small className="text-danger">
                                        {invalidFields.find((i) => i.name === 'NhanvienId')?.message}
                                    </small>
                                )}
                            </div>
                        </div>
                        
                        <div className='col-12 mt-5'>
                            <Button
                                text='Thêm mới'
                                bClass='btn btn-primary px-5'
                                textColor
                                onClick={handleSubmit}
                            />
                        </div>
                    </ModalBody>
                </Modal>
            </Content>
            <SelectFinNhanvien
                showSelect={showSelect}
                typeSelect={true}
                listNhanvien={listNhanvien}
                setlistNhanvien={setlistNhanvien}
                cauHinhId={idCauHinh}
                typeSetting={'sanluong'} // 'chuyencan' || 'thamnien' || 'congthuc' || 'hoatdongsukien' || daychuyen
                setShowSelect={() => setShowSelect(false)}
            />
        </React.Fragment>
    );
}

export default ModalKhac;

