export const createColumn = (prefix, id, header, parent, colspan, rowspan) => {
    let objItem = {
        id: `${prefix}${id}`,
        accessorKey: `${prefix}${id}`,
        Header: header,
    };
    if (colspan !== 1) {
        objItem.colspan = colspan;
    } else if (rowspan !== 1) {
        objItem.rowspan = rowspan;
    } else if (colspan !== 1 && rowspan !== 1) {
        objItem.colspan = colspan;
        objItem.rowspan = rowspan;
    }
    if (parent !== '') {
        objItem.parent = parent;
    }
    return objItem;
}