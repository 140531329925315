import actionTypes from "../actions/actionTypes";
const initState = {
    congthuctinhluong: [],
    msg: '',
    count: 0,
    newcongthuctinhluong: []
}

const congthuctinhluongReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.GET_LIST_CONG_THUC_TINH_LUONG:
            return {
                ...state,
                congthuctinhluong: action?.congthuctinhluong?.rows || [],
                msg: 'Ok' || '',
                count: action?.congthuctinhluong?.count || 0
            }
        case actionTypes.POST_NEW_CONG_THUC_TINH_LUONG:
        case actionTypes.PUT_EDIT_CONG_THUC_TINH_LUONG:
        case actionTypes.DELETE_CONG_THUC_TINH_LUONG:
            return {
                ...state,
                msg: action.msg || '',
                newcongthuctinhluong: action?.newcongthuctinhluong || []
            }
        default:
            return state;
    }

}

export default congthuctinhluongReducer